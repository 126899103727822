*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e8eaed;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Barlow Semi Condensed, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9aa0a6;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --red: 348deg 80% 48%;
  --violet: 262deg 83% 58%;
  --blue: 221deg 83% 53%;
  --green: 86deg 77% 28%;
  --yellow: 35deg 92% 33%;
}

:root[data-mode="dark"] {
  --red: 0deg 100% 75%;
  --violet: 255deg 92% 78%;
  --blue: 213deg 90% 70%;
  --green: 82deg 72% 64%;
  --yellow: 50deg 90% 64%;
  color-scheme: dark;
}

:root[data-primary="red"] {
  --primary: var(--red);
}

:root[data-primary="violet"] {
  --primary: var(--violet);
}

:root[data-primary="blue"] {
  --primary: var(--blue);
}

:root[data-primary="green"] {
  --primary: var(--green);
}

:root[data-primary="yellow"] {
  --primary: var(--yellow);
}

:root[data-size="sm"] {
  font-size: 16px;
}

:root[data-size="md"] {
  font-size: 17px;
}

:root[data-size="lg"] {
  font-size: 18px;
}

.bi-display:before {
  content: "";
}

.bi-file-excel:before {
  content: "";
}

.bi-file-image:before {
  content: "";
}

.bi-file-richtext:before {
  content: "";
}

.bi-file-word:before {
  content: "";
}

.bi-file-zip:before {
  content: "";
}

.bi-gear:before {
  content: "";
}

.bi-house:before {
  content: "";
}

.bi-list:before {
  content: "";
}

.bi-moon:before {
  content: "";
}

.bi-phone-fill:before {
  content: "";
}

.bi-printer-fill:before {
  content: "";
}

.bi-sun:before {
  content: "";
}

.bi-telephone-fill:before {
  content: "";
}

.bi-file-pdf:before {
  content: "";
}

html {
  scroll-behavior: smooth;
  accent-color: hsl(var(--primary) / 1);
}

body {
  padding-bottom: 3.125rem;
}

@media (width >= 1024px) {
  body {
    padding-bottom: 0;
  }
}

body {
  --tw-text-opacity: 1 !important;
  color: rgb(32 33 36 / var(--tw-text-opacity)) !important;
}

:is([data-mode="dark"] body) {
  --tw-text-opacity: 1 !important;
  color: rgb(218 220 224 / var(--tw-text-opacity)) !important;
}

body {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

:is([data-mode="dark"] body) {
  font-weight: 300;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(32 33 36 / var(--tw-bg-opacity)) !important;
}

:focus, :focus-visible {
  outline-offset: 1px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--primary) / 1);
}

:focus:not(:focus-visible) {
  outline: none !important;
}

:is(input[type="search"]::-ms-clear, input[type="search"]::-ms-reveal) {
  width: 0;
  height: 0;
  display: none;
}

:is(input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration) {
  display: none;
}

::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0 !important;
  text-align: start !important;
  text-align-last: start !important;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (width >= 1024px) {
  .container {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media (width >= 1280px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.container {
  max-width: 80rem;
}

.layout {
  margin-left: auto;
  margin-right: auto;
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (width >= 1024px) {
  .layout {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media (width >= 1280px) {
  .layout {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.layout {
  max-width: 80rem;
}

.head-nav > .layout {
  flex-direction: row;
  height: 3.125rem;
  display: flex;
}

.layout {
  flex-wrap: wrap;
  gap: .75rem;
  margin-top: .5rem;
  margin-bottom: 2.5rem;
  display: flex;
}

@media (width >= 768px) {
  .layout {
    gap: 1rem;
  }
}

@media (width >= 1280px) {
  .layout {
    column-gap: 2rem;
  }
}

.layout.home {
  margin-top: 2.5rem;
}

@media print {
  .layout {
    margin-bottom: 0;
  }
}

#menu {
  width: 18rem;
}

@media (width >= 1024px) {
  #menu {
    width: 25%;
    max-width: 18.5rem;
  }
}

#sidebar {
  width: 100%;
}

@media (width >= 768px) {
  #sidebar {
    width: 25%;
    max-width: 18.5rem;
  }
}

@media print {
  #sidebar {
    width: 100%;
    max-width: 100%;
  }
}

#content {
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  display: flex;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIDAlspo5m2fA.a019a4fc.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIDAlsno5k.2ef77751.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbqmIE4Ygg.5074e994.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbqoIE4.15a339aa.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJbA1spo5m2fA.9f65b8b1.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJbA1sno5k.76da4a14.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJ3BFspo5m2fA.48c94847.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJ3BFsno5k.c5910a0a.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf06i_CWslu50.6416af5f.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf06i_B2sl.2fa96c3d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXdoqqOEo.92c4c632.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXeIqq.4499274d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6m_CWslu50.4e4dd1b4.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6m_B2sl.f9be0612.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66_CWslu50.8e6c0ddf.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66_B2sl.cf6b8365.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: block;
  font-family: bootstrap-icons;
  src: url("bootstrap-icons.ca4f272d.woff2") format("woff2"), url("bootstrap-icons.e1a6407e.woff") format("woff");
}

.bi:before, [class^="bi-"]:before, [class*=" bi-"]:before {
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-weight: normal !important;
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  z-index: 1000;
  color: inherit;
  text-align: left;
  box-shadow: none;
  background-clip: padding-box;
  min-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
}

.dropdown-menu:not(.show) {
  display: none;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: .25rem;
  top: auto;
  bottom: 100%;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: .25rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: .25rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropdown-divider {
  opacity: 1;
  border-top: 1px solid #000;
  height: 0;
  margin: .75rem 0;
  overflow: hidden;
}

.dropdown-item {
  clear: both;
  font-weight: inherit;
  color: inherit;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  width: 100%;
  padding: .5rem;
  text-decoration: none;
  display: block;
}

.dropdown-item-text {
  color: inherit;
  padding: .25rem .5rem;
  display: block;
}

.modal {
  z-index: 1055;
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  pointer-events: none;
  align-items: center;
  width: auto;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.modal-content {
  pointer-events: auto;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-bg-opacity: 1;
  background-clip: padding-box;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 1rem;
  outline: 0;
  flex-direction: column;
  width: 100%;
  margin: .5rem;
  padding: 2rem;
  display: flex;
  position: relative;
}

:is([data-mode="dark"] .modal-content) {
  --tw-bg-opacity: 1;
  background-color: rgb(32 33 36 / var(--tw-bg-opacity));
}

.modal-title {
  margin-bottom: 1rem;
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.25rem;
}

:is([data-mode="dark"] .modal-title) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.offcanvas_proto {
  z-index: var(--bs-offcanvas-zindex, 1045);
  visibility: hidden;
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

.offcanvas_proto.hiding, .offcanvas_proto.show, .offcanvas_proto.showing {
  visibility: visible;
}

.offcanvas_proto.show:not(.hiding), .offcanvas_proto.showing {
  transform: none;
}

.offcanvas-top {
  height: var(--bs-offcanvas-height, 30vh);
  z-index: var(--bs-offcanvas-zindex, 1045);
  visibility: hidden;
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  inset: 0;
  transform: translateY(-100%);
}

.offcanvas-top.hiding, .offcanvas-top.show, .offcanvas-top.showing {
  visibility: visible;
}

.offcanvas-top.show:not(.hiding), .offcanvas-top.showing {
  transform: none;
}

@media (width <= 639.98px) {
  .offcanvas-top-sm {
    height: var(--bs-offcanvas-height, 30vh);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    inset: 0;
    transform: translateY(-100%);
  }

  .offcanvas-top-sm.hiding, .offcanvas-top-sm.show, .offcanvas-top-sm.showing {
    visibility: visible;
  }

  .offcanvas-top-sm.show:not(.hiding), .offcanvas-top-sm.showing {
    transform: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-top-md {
    height: var(--bs-offcanvas-height, 30vh);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    inset: 0;
    transform: translateY(-100%);
  }

  .offcanvas-top-md.hiding, .offcanvas-top-md.show, .offcanvas-top-md.showing {
    visibility: visible;
  }

  .offcanvas-top-md.show:not(.hiding), .offcanvas-top-md.showing {
    transform: none;
  }
}

@media (width <= 1023.98px) {
  .offcanvas-top-lg {
    height: var(--bs-offcanvas-height, 30vh);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    inset: 0;
    transform: translateY(-100%);
  }

  .offcanvas-top-lg.hiding, .offcanvas-top-lg.show, .offcanvas-top-lg.showing {
    visibility: visible;
  }

  .offcanvas-top-lg.show:not(.hiding), .offcanvas-top-lg.showing {
    transform: none;
  }
}

@media (width <= 1279.98px) {
  .offcanvas-top-xl {
    height: var(--bs-offcanvas-height, 30vh);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    inset: 0;
    transform: translateY(-100%);
  }

  .offcanvas-top-xl.hiding, .offcanvas-top-xl.show, .offcanvas-top-xl.showing {
    visibility: visible;
  }

  .offcanvas-top-xl.show:not(.hiding), .offcanvas-top-xl.showing {
    transform: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-top-2xl {
    height: var(--bs-offcanvas-height, 30vh);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    inset: 0;
    transform: translateY(-100%);
  }

  .offcanvas-top-2xl.hiding, .offcanvas-top-2xl.show, .offcanvas-top-2xl.showing {
    visibility: visible;
  }

  .offcanvas-top-2xl.show:not(.hiding), .offcanvas-top-2xl.showing {
    transform: none;
  }
}

.offcanvas-bottom {
  height: var(--bs-offcanvas-height, 30vh);
  z-index: var(--bs-offcanvas-zindex, 1045);
  visibility: hidden;
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas-bottom.hiding, .offcanvas-bottom.show, .offcanvas-bottom.showing {
  visibility: visible;
}

.offcanvas-bottom.show:not(.hiding), .offcanvas-bottom.showing {
  transform: none;
}

@media (width <= 639.98px) {
  .offcanvas-bottom-sm {
    height: var(--bs-offcanvas-height, 30vh);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-bottom-sm.hiding, .offcanvas-bottom-sm.show, .offcanvas-bottom-sm.showing {
    visibility: visible;
  }

  .offcanvas-bottom-sm.show:not(.hiding), .offcanvas-bottom-sm.showing {
    transform: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-bottom-md {
    height: var(--bs-offcanvas-height, 30vh);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-bottom-md.hiding, .offcanvas-bottom-md.show, .offcanvas-bottom-md.showing {
    visibility: visible;
  }

  .offcanvas-bottom-md.show:not(.hiding), .offcanvas-bottom-md.showing {
    transform: none;
  }
}

@media (width <= 1023.98px) {
  .offcanvas-bottom-lg {
    height: var(--bs-offcanvas-height, 30vh);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-bottom-lg.hiding, .offcanvas-bottom-lg.show, .offcanvas-bottom-lg.showing {
    visibility: visible;
  }

  .offcanvas-bottom-lg.show:not(.hiding), .offcanvas-bottom-lg.showing {
    transform: none;
  }
}

@media (width <= 1279.98px) {
  .offcanvas-bottom-xl {
    height: var(--bs-offcanvas-height, 30vh);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-bottom-xl.hiding, .offcanvas-bottom-xl.show, .offcanvas-bottom-xl.showing {
    visibility: visible;
  }

  .offcanvas-bottom-xl.show:not(.hiding), .offcanvas-bottom-xl.showing {
    transform: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-bottom-2xl {
    height: var(--bs-offcanvas-height, 30vh);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-bottom-2xl.hiding, .offcanvas-bottom-2xl.show, .offcanvas-bottom-2xl.showing {
    visibility: visible;
  }

  .offcanvas-bottom-2xl.show:not(.hiding), .offcanvas-bottom-2xl.showing {
    transform: none;
  }
}

@media (width <= 639.98px) {
  .offcanvas-start-sm {
    width: var(--bs-offcanvas-width, 400px);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-start-sm.hiding, .offcanvas-start-sm.show, .offcanvas-start-sm.showing {
    visibility: visible;
  }

  .offcanvas-start-sm.show:not(.hiding), .offcanvas-start-sm.showing {
    transform: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-start-md {
    width: var(--bs-offcanvas-width, 400px);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-start-md.hiding, .offcanvas-start-md.show, .offcanvas-start-md.showing {
    visibility: visible;
  }

  .offcanvas-start-md.show:not(.hiding), .offcanvas-start-md.showing {
    transform: none;
  }
}

@media (width <= 1023.98px) {
  .offcanvas-start-lg {
    width: var(--bs-offcanvas-width, 400px);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-start-lg.hiding, .offcanvas-start-lg.show, .offcanvas-start-lg.showing {
    visibility: visible;
  }

  .offcanvas-start-lg.show:not(.hiding), .offcanvas-start-lg.showing {
    transform: none;
  }
}

@media (width <= 639.98px) {
  .offcanvas-end-sm {
    width: var(--bs-offcanvas-width, 400px);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-end-sm.hiding, .offcanvas-end-sm.show, .offcanvas-end-sm.showing {
    visibility: visible;
  }

  .offcanvas-end-sm.show:not(.hiding), .offcanvas-end-sm.showing {
    transform: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-end-md {
    width: var(--bs-offcanvas-width, 400px);
    z-index: var(--bs-offcanvas-zindex, 1045);
    visibility: hidden;
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-end-md.hiding, .offcanvas-end-md.show, .offcanvas-end-md.showing {
    visibility: visible;
  }

  .offcanvas-end-md.show:not(.hiding), .offcanvas-end-md.showing {
    transform: none;
  }
}

.cke hr {
  --tw-border-opacity: 1;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
}

:is([data-mode="dark"] .cke hr) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.cke hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cke h2, .cke h3, .cke h4, .cke h5, .cke h6 {
  margin-bottom: .5rem;
  font-weight: 500;
}

:is([data-mode="dark"] .cke h2), :is([data-mode="dark"] .cke h3), :is([data-mode="dark"] .cke h4), :is([data-mode="dark"] .cke h5), :is([data-mode="dark"] .cke h6) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.cke h1, .cke h2, .cke h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.cke h4, .cke h5 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.cke h6 {
  font-size: 1rem;
  line-height: 1.5rem;
}

.cke p {
  margin-bottom: .75rem;
}

.cke b, .cke strong {
  font-weight: 700;
}

:is([data-mode="dark"] .cke b), :is([data-mode="dark"] .cke strong) {
  font-weight: 500;
}

.cke small {
  font-size: .875rem;
  line-height: 1.25rem;
}

.cke u, .cke em {
  font-style: italic;
}

.cke a {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.cke a:hover {
  text-decoration-line: underline;
}

.cke a {
  font-weight: 500;
}

:is([data-mode="dark"] .cke a) {
  font-weight: 400;
}

.cke ol, .cke ul {
  padding-left: 2rem;
}

.cke ol {
  list-style-type: decimal;
}

.cke ol[type="a"] {
  list-style-type: lower-alpha;
}

.cke ol[type="I"] {
  list-style-type: upper-roman;
}

.cke ul {
  list-style-type: disc;
}

.cke ol, .cke ul, .cke dl {
  margin-bottom: .75rem;
}

.cke ol ol, .cke ul ul, .cke ol ul, .cke ul ol {
  margin-bottom: 0;
}

.cke dt {
  font-weight: 500;
}

.cke dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

.cke details + details {
  margin-top: 1rem;
}

.cke details summary {
  cursor: pointer;
  border-radius: .5rem;
  padding: .125rem .5rem;
  font-weight: 500;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity)) !important;
}

:is([data-mode="dark"] .cke details summary) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity)) !important;
}

.cke details summary + * {
  margin-top: .5rem;
}

.cke figure {
  margin: 0 0 1rem;
}

.cke img, .cke svg {
  vertical-align: middle;
}

.cke img {
  max-width: 100%;
  height: auto;
}

.cke .table-responsive {
  overflow-x: auto;
}

.cke table {
  text-align: left;
  vertical-align: top;
  --tw-border-opacity: 1;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
  width: 100%;
  margin-bottom: 1rem;
}

:is([data-mode="dark"] .cke table) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.cke table {
  caption-side: bottom;
}

@media print {
  .cke table {
    min-width: 100%;
  }
}

.cke table tbody, .cke table td, .cke table tfoot, .cke table th, .cke table thead, .cke table tr {
  border-style: solid;
  border-width: 1px;
  border-color: inherit;
}

.cke table > thead {
  vertical-align: bottom;
}

.cke table > tbody {
  vertical-align: inherit;
}

.cke table > :not(caption) > * > * {
  padding: .375rem .5rem;
}

.btn {
  display: inline-block;
}

:is([data-mode="dark"] .btn) {
  font-weight: 500;
}

.btn {
  white-space: nowrap;
  letter-spacing: .025em;
  border-radius: .5rem;
  padding: .25rem 1.125rem;
}

.btn:hover {
  --tw-shadow: inset 0 4rem 0;
  --tw-shadow-colored: inset 0 4rem 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.btn:active {
  --tw-shadow-color: #ffffff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.btn-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .btn-primary) {
  --tw-text-opacity: 1;
  color: rgb(32 33 36 / var(--tw-text-opacity));
}

.btn-filled, .btn-text {
  border-radius: .375rem;
  max-height: 2.5rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 2.5rem;
  display: inline-block;
  text-decoration: none !important;
}

.btn-filled:focus-visible, .btn-text:focus-visible {
  outline-width: 2px;
  outline-style: dotted;
}

.btn-filled {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from-position: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.btn-filled:hover {
  --tw-gradient-from: #0000000d var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.btn-filled:focus-visible, .btn-filled:active {
  --tw-gradient-from: #00000014 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

:is([data-mode="dark"] .btn-filled) {
  --tw-text-opacity: 1 !important;
  color: rgb(32 33 36 / var(--tw-text-opacity)) !important;
}

.btn-text {
  background-color: #0000;
  padding-left: .75rem;
  padding-right: .75rem;
  --tw-text-opacity: 1 !important;
  color: hsl(var(--primary) / var(--tw-text-opacity)) !important;
}

.btn-text:hover {
  background-color: hsl(var(--primary) / .05);
}

.btn-text:focus-visible, .btn-text:active {
  background-color: hsl(var(--primary) / 3.5%);
}

.head-skip {
  align-items: center;
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: none;
}

@media (width >= 1024px) {
  .head-skip {
    display: flex;
  }
}

.head-skip {
  font-weight: 500;
}

:is([data-mode="dark"] .head-skip) {
  font-weight: 400;
}

.head-skip-link {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .375rem;
  line-height: 1.5rem;
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

:is([data-mode="dark"] .head-skip-link) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.head-skip-chum {
  --tw-text-opacity: 1;
  color: rgb(60 64 67 / var(--tw-text-opacity));
  align-items: center;
  gap: .25rem;
  margin-left: auto;
  line-height: 1.5rem;
  display: flex;
}

:is([data-mode="dark"] .head-skip-chum) {
  --tw-text-opacity: 1;
  color: rgb(218 220 224 / var(--tw-text-opacity));
}

.head-skip-chum + .head-skip-chum {
  margin-left: .75rem;
}

.head-skip-chum:before {
  font-size: 1rem;
}

.head-brand {
  letter-spacing: -.025em;
  grid-area: brand;
  font-size: 1.125rem;
  line-height: 1;
  display: inline-block;
}

@media (width >= 640px) {
  .head-brand {
    font-size: 1.25rem;
  }
}

@media (width >= 768px) {
  .head-brand {
    font-size: 1.375rem;
  }
}

.head-brand > div {
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, auto);
  gap: .125em .5rem;
  max-width: min-content;
  display: grid;
}

.head-brand img {
  grid-area: 1 / 1 / 3 / 2;
  max-width: 3em;
  max-height: 3em;
}

:is([data-mode="dark"] .head-brand img) {
  --tw-saturate: saturate(.75);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.head-brand span {
  font-weight: 500;
}

:is([data-mode="dark"] .head-brand span) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.head-brand span {
  white-space: nowrap;
  grid-area: 1 / 2 / 1 / 3;
  align-self: end;
}

.head-brand small {
  grid-area: 2 / 2 / 3 / 3;
  align-self: start;
  font-size: .735em;
}

.head-search {
  z-index: 50;
  border-radius: .75rem;
  height: 2.5rem;
  display: flex;
  position: relative;
}

.head-search.show {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.head-search {
  --tw-bg-opacity: 1;
  background-color: rgb(240 242 243 / var(--tw-bg-opacity));
}

.head-search.show {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .head-search) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .head-search.show) {
  --tw-bg-opacity: 1;
  background-color: rgb(48 49 52 / var(--tw-bg-opacity));
}

@media print {
  .head-search {
    display: none;
  }
}

.head-search-input {
  background-color: #0000;
  flex: 1;
  order: 2;
  padding-left: .5rem;
  padding-right: .5rem;
}

.head-search-input::placeholder {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .head-search-input)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.head-search-btn {
  aspect-ratio: 1;
  background-clip: content-box;
  border-width: .25rem;
  border-color: #0000;
  border-radius: 9999px;
}

.head-search-btn:hover {
  background-color: #0000000d;
}

:is([data-mode="dark"] .head-search-btn:hover) {
  background-color: #ffffff0a;
}

.head-search-btn.bi-toggle {
  order: 4;
}

.head-search-btn.bi-toggle:before {
  --tw-content: "";
  content: var(--tw-content);
}

.head-search-btn.bi-clear {
  order: 3;
}

.head-search-btn.bi-clear:before {
  --tw-content: "";
  content: var(--tw-content);
}

.head-search-input:placeholder-shown ~ .head-search-btn.bi-clear {
  pointer-events: none;
  visibility: hidden;
}

.head-search-btn.bi-submit {
  order: 1;
}

.head-search-btn.bi-submit:before {
  --tw-content: "";
  content: var(--tw-content);
}

.head-search .dropdown-menu {
  padding: .75rem 1rem 1rem;
}

.head-search .dropdown-menu.show {
  display: flex;
}

.head-search .dropdown-menu {
  background-color: inherit;
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

:is([data-mode="dark"] .head-search .dropdown-menu) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.head-search-options {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
  flex: 1;
  font-size: .875rem;
  line-height: 1.25rem;
}

:is([data-mode="dark"] .head-search-options) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.head-search-options > legend {
  width: 100%;
  margin-bottom: .5rem;
  font-size: .75rem;
  line-height: 1rem;
  display: block;
}

.head-search-options > label {
  margin-bottom: .25rem;
  display: block;
}

.head-search-options > label:last-of-type {
  margin-bottom: 0;
}

.head-search-options > label input {
  margin-right: .5rem;
}

.head-search .btn-primary {
  margin-top: auto;
}

.head-logov img {
  width: 2.8em;
}

@media (width >= 640px) {
  .head-logov img {
    width: 3em;
  }
}

@media (width >= 768px) {
  .head-logov img {
    width: 3.125em;
  }
}

:is([data-mode="dark"] .head-logov img) {
  --tw-saturate: saturate(.75);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.head-nav {
  z-index: 50;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

@media (width >= 1024px) {
  .head-nav {
    position: static;
  }
}

.head-nav {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .head-nav) {
  --tw-bg-opacity: 1;
  background-color: rgb(60 64 67 / var(--tw-bg-opacity));
  color: inherit;
  font-weight: 400;
}

@media print {
  .head-nav {
    display: none;
  }
}

.head-nav > .container {
  flex-direction: row;
  height: 3.125rem;
  display: flex;
}

.head-nav-link {
  border-right-width: 1px;
  flex-direction: column;
  flex: auto;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  padding-left: .5rem;
  padding-right: .5rem;
  line-height: 1rem;
}

.head-nav-link:last-child {
  border-right-width: 0;
}

@media (width >= 1024px) {
  .head-nav-link:first-child {
    border-left-width: 1px;
  }
}

.head-nav-link {
  border-color: #fff6;
}

:is([data-mode="dark"] .head-nav-link) {
  border-color: #0000004d;
}

.head-nav-link:hover, .head-nav-link:focus {
  background-color: #0000000d;
}

:is([data-mode="dark"] .head-nav-link:hover) {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.head-nav-link[aria-current] {
  background-color: #0000001a;
}

:is([data-mode="dark"] .head-nav-link[aria-current]) {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.head-nav-link.xs {
  display: flex;
}

@media (width >= 1024px) {
  .head-nav-link.xs {
    display: none;
  }
}

.head-nav-link.lg {
  display: none;
}

@media (width >= 1024px) {
  .head-nav-link.lg {
    display: flex;
  }
}

.head-top {
  margin-left: auto;
  margin-right: auto;
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (width >= 1024px) {
  .head-top {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media (width >= 1280px) {
  .head-top {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.head-top {
  max-width: 80rem;
}

.head-nav > .head-top {
  flex-direction: row;
  height: 3.125rem;
  display: flex;
}

.head-top {
  flex-wrap: wrap;
  align-items: center;
  padding-top: .75rem;
  display: flex;
}

@media (width >= 768px) {
  .head-top {
    column-gap: 2rem;
    padding-bottom: .75rem;
  }
}

@media (width >= 1024px) {
  .head-top {
    padding-top: 0;
  }
}

.head-skip {
  flex-basis: 100%;
}

.head-search {
  flex-basis: 100%;
  order: 9999;
  margin-top: .75rem;
}

@media (width >= 1024px) {
  .head-search {
    order: 0;
    max-width: 30rem;
    margin-top: 0;
  }
}

@media (width >= 1280px) {
  .head-search {
    max-width: 32rem;
  }
}

@media (width >= 1024px) {
  [data-size="lg"] .head-search {
    max-width: 28rem;
  }
}

@media (width >= 1280px) {
  [data-size="lg"] .head-search {
    max-width: 32rem;
  }
}

.head-logov {
  margin-left: auto;
}

.breadcrumb {
  flex-wrap: wrap;
  display: flex;
}

.breadcrumb-item:first-child:before {
  content: var(--tw-content);
  display: none;
}

.breadcrumb-item:before {
  --tw-content: "/";
  content: var(--tw-content);
  margin-left: .375rem;
  margin-right: .375rem;
  font-size: 1.375rem;
  line-height: 0;
}

.breadcrumb-item:last-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20rem;
  overflow: hidden;
}

.breadcrumb-item:before, .breadcrumb-item[aria-current] {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .breadcrumb-item):before, :is([data-mode="dark"] .breadcrumb-item[aria-current]) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.breadcrumb-item a:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.page-category {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: .25rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
}

:is([data-mode="dark"] .page-category) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

@media print {
  .page-category {
    display: none;
  }
}

.main-nav {
  outline-width: 0;
}

@media (width <= 1023.98px) {
  .main-nav {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 1rem .5rem;
    overflow-y: auto;
  }

  :is([data-mode="dark"] .main-nav) {
    --tw-bg-opacity: 1;
    background-color: rgb(32 33 36 / var(--tw-bg-opacity));
  }
}

@media print {
  .main-nav {
    display: none;
  }
}

.main-nav-title {
  margin-bottom: .5rem;
  padding-left: .75rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
}

:is([data-mode="dark"] .main-nav-title) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.main-nav-title ~ .main-nav-title {
  margin-top: 1rem;
}

.main-nav-menu {
  flex-direction: column;
  gap: 1px;
  display: flex;
}

.main-nav-link {
  position: relative;
}

.main-nav-link:focus, .main-nav-link:focus-visible {
  z-index: 10 !important;
}

.main-nav-link {
  border-radius: .5rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: .375rem .75rem;
  display: flex;
}

.main-nav-link:before {
  opacity: 0;
  --tw-content: "";
  content: var(--tw-content);
  order: 9999;
}

.main-nav-link.show:before {
  content: var(--tw-content);
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.main-nav-link:hover:not(.show):not([aria-current]) {
  background-color: hsl(var(--primary) / 4%);
}

:is([data-mode="dark"] .main-nav-link:hover:not(.show):not([aria-current])) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.main-nav-link[aria-current="page"] {
  background-color: hsl(var(--primary) / 4%);
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

:is([data-mode="dark"] .main-nav-link[aria-current="page"]) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.main-nav-link[aria-current="page"] {
  font-weight: 500;
}

:is([data-mode="dark"] .main-nav-link[aria-current="page"]) {
  font-weight: 400;
}

.main-nav-link[aria-current="true"] {
  background-color: hsl(var(--primary) / 4%);
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

:is([data-mode="dark"] .main-nav-link[aria-current="true"]) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.main-nav-link[aria-current="true"] {
  font-weight: 500;
}

:is([data-mode="dark"] .main-nav-link[aria-current="true"]) {
  font-weight: 400;
}

.main-nav-link.show {
  background-color: hsl(var(--primary) / 4%);
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

:is([data-mode="dark"] .main-nav-link.show) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.main-nav-link.show {
  font-weight: 500;
}

:is([data-mode="dark"] .main-nav-link.show) {
  font-weight: 400;
}

.main-nav-link[data-bs-toggle]:before {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: var(--tw-content);
  opacity: 1;
}

.main-nav-drop.dropdown-menu {
  padding: .5rem .75rem;
  font-size: .875rem;
  line-height: 1.25rem;
  position: static;
}

.main-nav-drop a {
  --tw-border-opacity: 1;
  border-left-width: 1px;
  border-color: hsl(var(--primary) / var(--tw-border-opacity));
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
  padding: .25rem 2.5rem .25rem .875rem;
  display: block;
  position: relative;
}

.main-nav-drop a:focus {
  z-index: 10;
}

.main-nav-drop a:hover, .main-nav-drop a:focus, .main-nav-drop a[aria-current="page"] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .main-nav-drop a:hover), :is([data-mode="dark"] .main-nav-drop a:focus), :is([data-mode="dark"] .main-nav-drop a[aria-current="page"]) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.main-nav-drop a[aria-current="page"] {
  font-weight: 500;
}

:is([data-mode="dark"] .main-nav-drop a[aria-current="page"]) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.main-nav-drop a:hover:before, .main-nav-drop a[aria-current="page"]:before {
  --tw-border-opacity: 1;
  border-width: .3125rem 0 .3125rem .3125rem;
  border-color: #0000;
  border-left-color: hsl(var(--primary) / var(--tw-border-opacity));
  --tw-content: "";
  content: var(--tw-content);
  width: 0;
  height: 0;
  margin: auto;
  position: absolute;
  inset: 0 auto 0 0;
}

.main-nav-drop li[role="separator"][aria-label] {
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding-left: .9375rem;
}

.main-nav-drop li[role="separator"][aria-label]:before {
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: bootstrap-icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.main-nav-drop li[role="separator"][aria-label] {
  position: relative;
}

.main-nav-drop li[role="separator"][aria-label]:after {
  --tw-content: attr(aria-label);
  content: var(--tw-content);
}

.main-nav-drop li[role="separator"][aria-label] {
  font-weight: 500;
}

:is([data-mode="dark"] .main-nav-drop li[role="separator"][aria-label]) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.main-nav-drop li[role="separator"][aria-label]:before {
  --tw-content: "";
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
  height: min-content;
  margin: auto;
  position: absolute;
  inset: 0 auto 0 -.4375rem;
}

.main-nav-drop li[role="separator"]:not([aria-label]) {
  border-top-width: 1px;
  margin: .5rem 2.5rem .5rem .9375rem;
}

.widget-notice .notice-list {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

@media (width >= 1024px) {
  .widget-notice .notice-list {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }
}

.widget-notice .notice-link:before {
  content: "";
}

.widget-notice .notice-link {
  border-radius: .5rem;
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.widget-notice .notice-link:before {
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: bootstrap-icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.widget-notice .notice-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (width >= 1024px) {
  .widget-notice .notice-link {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

.widget-notice .notice-link {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

@media (width >= 640px) {
  .widget-notice .notice-link {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.widget-notice .notice-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .widget-notice .notice-link:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.widget-notice .notice-link:before {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
  margin-right: .25rem;
}

.widget-latest .latest-list {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

@media (width >= 1024px) {
  .widget-latest .latest-list {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }
}

.widget-latest .latest-item {
  padding-left: .5rem;
  padding-right: .5rem;
  position: relative;
}

@media (width >= 1024px) {
  .widget-latest .latest-item {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

.widget-latest .latest-item {
  border-radius: .5rem;
  flex-wrap: wrap;
  align-items: baseline;
  gap: .125rem .375rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

.widget-latest .latest-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .widget-latest .latest-item:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.widget-latest .latest-place {
  z-index: 10;
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

:is([data-mode="dark"] .widget-latest .latest-place) {
  font-weight: 400;
}

.widget-latest .latest-time {
  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
}

:is([data-mode="dark"] .widget-latest .latest-time) {
  font-weight: 400;
}

.widget-latest .latest-time {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .widget-latest .latest-time) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.widget-latest .latest-link {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
}

@media (width >= 640px) {
  .widget-latest .latest-link {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.widget-latest .latest-link:after {
  --tw-content: "";
  content: var(--tw-content);
  position: absolute;
  inset: 0;
}

.widget-popular .popular-list {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

@media (width >= 1024px) {
  .widget-popular .popular-list {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }
}

.widget-popular .popular-list {
  column-gap: 1.5rem;
}

@media (width >= 640px) {
  .widget-popular .popular-list {
    columns: 2;
  }
}

.widget-popular .popular-list {
  column-rule: 1px solid #dadce0;
}

:root[data-mode="dark"] .widget-popular .popular-list {
  column-rule-color: #5f6368;
}

.widget-popular .popular-item {
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (width >= 1024px) {
  .widget-popular .popular-item {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

.widget-popular .popular-item {
  break-inside: avoid;
  border-radius: .5rem;
  flex-wrap: wrap;
  align-items: baseline;
  gap: .125rem .375rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
  position: relative;
}

.widget-popular .popular-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .widget-popular .popular-item:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.widget-popular .popular-place {
  z-index: 10;
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

:is([data-mode="dark"] .widget-popular .popular-place) {
  font-weight: 400;
}

.widget-popular .popular-time {
  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
}

:is([data-mode="dark"] .widget-popular .popular-time) {
  font-weight: 400;
}

.widget-popular .popular-time {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .widget-popular .popular-time) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.widget-popular .popular-link {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
}

@media (width >= 640px) {
  .widget-popular .popular-link {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.widget-popular .popular-link:after {
  --tw-content: "";
  content: var(--tw-content);
  position: absolute;
  inset: 0;
}

.widget-epuap .epuap-logo {
  margin-bottom: 1rem;
  display: inline-block;
}

.widget-epuap .epuap-logo img {
  height: 1.5rem;
}

.widget-epuap .epuap-label {
  margin-bottom: .25rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: block;
}

.widget-epuap .epuap-number {
  cursor: default;
  width: 100%;
  font-weight: 600;
}

:is([data-mode="dark"] .widget-epuap .epuap-number) {
  font-weight: 500;
}

.widget-epuap .epuap-number {
  text-align: center;
  letter-spacing: .05em;
  background-color: #f0f2f3b3;
  border-radius: .5rem;
  padding: .625rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

:is([data-mode="dark"] .widget-epuap .epuap-number) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.widget-bok li {
  --tw-border-opacity: 1;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
  flex-direction: column;
  align-items: center;
  padding-left: .25rem;
  padding-right: .25rem;
  display: flex;
}

:is([data-mode="dark"] .widget-bok li) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.widget-bok li:before {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
  margin-bottom: .375rem;
}

:is([data-mode="dark"] .widget-bok li):before {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.widget-bok li span {
  width: max-content;
  margin-bottom: .125rem;
  line-height: 1;
}

@media (width >= 768px) {
  .widget-bok li span {
    width: min-content;
  }
}

@media (width >= 1280px) {
  .widget-bok li span {
    width: max-content;
  }
}

.widget-bok li b, .widget-bok li a {
  font-weight: 600;
}

:is([data-mode="dark"] .widget-bok li b), :is([data-mode="dark"] .widget-bok li a) {
  font-weight: 500;
}

.widget-bok li a {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.widget-migowy .widget-card {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
  border-radius: .75rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1rem .625rem;
  display: flex;
}

:is([data-mode="dark"] .widget-migowy .widget-card) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.widget-migowy .widget-lead {
  width: 100%;
  margin-bottom: .5rem;
}

.widget-migowy .widget-icon {
  fill: #dadce0;
}

:is([data-mode="dark"] .widget-migowy .widget-icon) {
  fill: #5f6368;
}

.widget + .widget {
  border-color: #0000000d;
  border-top-width: 4px;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
}

:is([data-mode="dark"] .widget + .widget) {
  border-color: #ffffff0d;
}

.widget-title {
  margin-bottom: .5rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
}

:is([data-mode="dark"] .widget-title) {
  font-weight: 400;
}

.widget-lead {
  margin-bottom: 1rem;
}

.post-head {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: .375rem;
  margin-bottom: 1.5rem;
  display: flex;
}

@media (width >= 1280px) {
  .post-head {
    margin-bottom: 2rem;
  }
}

.post-version {
  font-size: .9375rem;
  display: inline-block;
}

.post-version:before {
  content: var(--tw-content);
  font-size: 1rem;
  line-height: 1.5rem;
}

.post-version {
  font-weight: 500;
}

.post-version.bi-new:before {
  --tw-content: "";
  content: var(--tw-content);
}

.post-version.bi-new {
  --tw-text-opacity: 1;
  color: rgb(0 121 107 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .post-version.bi-new) {
  --tw-text-opacity: 1;
  color: rgb(77 182 172 / var(--tw-text-opacity));
}

.post-version.bi-old:before {
  --tw-content: "";
  content: var(--tw-content);
}

.post-version.bi-old {
  --tw-text-opacity: 1;
  color: rgb(211 47 47 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .post-version.bi-old) {
  --tw-text-opacity: 1;
  color: rgb(229 115 115 / var(--tw-text-opacity));
}

.post-version.bi-archival:before {
  --tw-content: "";
  content: var(--tw-content);
}

.post-version.bi-archival {
  --tw-text-opacity: 1;
  color: rgb(96 125 139 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .post-version.bi-archival) {
  --tw-text-opacity: 1;
  color: rgb(144 164 174 / var(--tw-text-opacity));
}

.post-printer {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
  font-size: .9375rem;
}

.post-printer:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

:is([data-mode="dark"] .post-printer) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .post-printer:hover) {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.post-printer {
  font-weight: 500;
}

:is([data-mode="dark"] .post-printer) {
  font-weight: 400;
}

@media print {
  .post-printer {
    display: none;
  }
}

.post-printer:before {
  margin-right: .25rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.post-title {
  width: 100%;
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (width >= 768px) {
  .post-title {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.post-title {
  font-weight: 500;
}

:is([data-mode="dark"] .post-title) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.post-meta {
  --tw-border-opacity: 1;
  border-left-width: 4px;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
  flex-direction: column;
  grid-area: meta;
  gap: .25rem;
  padding-left: .75rem;
  display: flex;
}

:is([data-mode="dark"] .post-meta) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.post-meta {
  margin-bottom: 1.5rem;
}

@media (width >= 1280px) {
  .post-meta {
    margin-bottom: 2rem;
  }
}

.post-meta-name:after {
  --tw-content: ":";
  content: var(--tw-content);
}

.post-meta-value {
  font-weight: 500;
}

:is([data-mode="dark"] .post-meta-value) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

span.status.post-meta-value {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--primary) / var(--tw-text-opacity)) !important;
}

a.post-meta-value {
  text-decoration-line: underline;
}

.post-body {
  grid-area: body;
  margin-bottom: 1.5rem;
}

@media (width >= 1280px) {
  .post-body {
    margin-bottom: 2rem;
  }
}

.post-body .radny {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

@media (width >= 640px) {
  .post-body .radny {
    flex-direction: row;
  }
}

.post-body .radny img {
  width: 100px;
}

.post-body .radny h3 {
  margin-bottom: .25rem;
}

.post-body .radny ul {
  margin-bottom: .5rem;
  padding: 0;
  list-style-type: none;
}

.post-body .radny p {
  margin-bottom: 0;
}

.post-body .radny + div {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
  margin-top: 1rem;
  padding-top: 1rem;
}

:is([data-mode="dark"] .post-body .radny + div) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.post-body .zarzad {
  flex-direction: column;
  gap: .5rem 1rem;
  display: flex;
}

@media (width >= 640px) {
  .post-body .zarzad {
    flex-flow: wrap;
  }
}

.post-body .zarzad > img {
  width: 100px;
  height: min-content;
}

.post-body .zarzad > div {
  flex-grow: 1;
}

.post-body .zarzad h3 {
  margin-bottom: .25rem;
}

.post-body .zarzad h3 + p {
  font-weight: 500;
}

:is([data-mode="dark"] .post-body .zarzad h3 + p) {
  font-weight: 400;
}

.post-body .zarzad h3 + p {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .post-body .zarzad h3 + p) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.post-body .zarzad h3 + p {
  margin-bottom: .25rem;
}

.post-body .zarzad p {
  margin-bottom: 0;
}

.post-body .zarzad details {
  width: 100%;
}

.post-body .zarzad + div {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
  margin-top: 1rem;
  padding-top: 1rem;
}

:is([data-mode="dark"] .post-body .zarzad + div) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.post-attach {
  grid-area: attach;
  margin-bottom: 1.5rem;
}

@media (width >= 1280px) {
  .post-attach {
    margin-bottom: 2rem;
  }
}

.post-attach > nav {
  margin-top: 1.25rem;
}

.post-attach-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
}

:is([data-mode="dark"] .post-attach-title) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.post-attach-header {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(60 64 67 / var(--tw-text-opacity));
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25;
}

:is([data-mode="dark"] .post-attach-header) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(218 220 224 / var(--tw-text-opacity));
}

.post-attach-header {
  font-weight: 500;
}

:is([data-mode="dark"] .post-attach-header) {
  font-weight: 400;
}

.post-attach-header {
  margin: .75rem 0 .375rem;
}

.post-attach-items {
  flex-direction: column;
  gap: .5rem;
  margin-top: .75rem;
  display: flex;
}

.post-attach-separator + .post-attach-items {
  margin-top: 0;
  padding-left: .9375rem;
}

.post-attach-link {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
  font-size: .9375rem;
}

.post-attach-link:hover {
  text-decoration-line: underline;
}

.post-attach-link {
  font-weight: 500;
}

:is([data-mode="dark"] .post-attach-link) {
  font-weight: 400;
}

.post-attach-link:before {
  pointer-events: none;
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
  margin-right: .25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

:is([data-mode="dark"] .post-attach-link):before {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.post-attach-meta {
  --tw-text-opacity: 1;
  color: rgb(60 64 67 / var(--tw-text-opacity));
  font-size: .75rem;
  line-height: 1.25;
}

:is([data-mode="dark"] .post-attach-meta) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.post-attach-meta > span, .post-attach-meta > time {
  --tw-text-opacity: 1;
  color: rgb(32 33 36 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .post-attach-meta > span), :is([data-mode="dark"] .post-attach-meta > time) {
  --tw-text-opacity: 1;
  color: rgb(218 220 224 / var(--tw-text-opacity));
}

.post-attach-meta > span, .post-attach-meta > time {
  font-weight: 500;
}

:is([data-mode="dark"] .post-attach-meta > span), :is([data-mode="dark"] .post-attach-meta > time) {
  font-weight: 400;
}

.post-metrics {
  grid-area: metrics;
}

@media (width >= 1280px) {
  .post-metrics {
    width: 14rem;
  }
}

@media (width <= 1279.98px) {
  .post-metrics {
    margin-bottom: 1.5rem;
  }
}

@media (width >= 1280px) {
  .post-metrics {
    height: min-content;
    position: sticky;
    top: .75rem;
    overflow-y: auto;
  }
}

@media print {
  .post-metrics {
    margin-top: 0;
  }
}

.post-metrics-title {
  margin-bottom: .5rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
}

:is([data-mode="dark"] .post-metrics-title) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.post-metrics-list {
  --tw-text-opacity: 1;
  color: rgb(60 64 67 / var(--tw-text-opacity));
  flex-direction: column;
  gap: .5rem;
  font-size: .9375rem;
  display: flex;
}

:is([data-mode="dark"] .post-metrics-list) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

@media (width >= 1280px) {
  .post-metrics-list > li {
    flex-direction: column;
    display: flex;
  }
}

.post-metrics-list time, .post-metrics-list span {
  --tw-text-opacity: 1;
  color: rgb(32 33 36 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .post-metrics-list time), :is([data-mode="dark"] .post-metrics-list span) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
}

.post-metrics-list time, .post-metrics-list span {
  font-weight: 500;
}

:is([data-mode="dark"] .post-metrics-list time), :is([data-mode="dark"] .post-metrics-list span) {
  font-weight: 400;
}

.post-metrics-list hr {
  --tw-border-opacity: 1;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
  margin-top: .25rem;
  margin-bottom: .25rem;
}

:is([data-mode="dark"] .post-metrics-list hr) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.post-register {
  grid-area: register;
}

@media print {
  .post-register {
    display: none;
  }
}

.post-register-title {
  margin-bottom: .5rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
}

:is([data-mode="dark"] .post-register-title) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.post-register-list {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
  border-radius: .375rem;
  flex-direction: column;
  gap: .375rem;
  padding: 1rem;
  font-size: .9375rem;
  line-height: 1.25rem;
  display: flex;
  overflow-y: auto;
}

:is([data-mode="dark"] .post-register-list) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.post-register-list {
  max-height: 9.57rem;
}

.post-register-list li[aria-current]:after {
  --tw-content: " - wybrana";
  content: var(--tw-content);
}

.post-register-list li[aria-current] {
  font-weight: 500;
}

:is([data-mode="dark"] .post-register-list li[aria-current]) {
  font-weight: 400;
}

.post-register-list a {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .post-register-list a) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.post {
  flex-direction: column;
  display: flex;
}

@media print {
  .post {
    display: flex !important;
  }
}

@media (width >= 1280px) {
  .post {
    grid-template: "head metrics"
                   "meta metrics" 1fr
                   "body metrics"
                   "attach metrics"
                   "register metrics"
                   / minmax(0, 680px) 1fr;
    column-gap: 1rem;
    display: grid;
  }
}

@media (width >= 1400px) {
  .post {
    column-gap: 2.25rem;
  }
}

@media (width <= 767.98px) {
  .c-grid {
    overflow-x: auto;
  }
}

.c-grid-table {
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
}

@media (width <= 767.98px) {
  .c-grid-table {
    min-width: 50rem;
  }
}

.c-grid-table {
  --tw-border-opacity: 1;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
}

:is([data-mode="dark"] .c-grid-table) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

@media print {
  .c-grid-table {
    min-width: 100%;
  }
}

.c-grid-table tbody, .c-grid-table td, .c-grid-table tfoot, .c-grid-table th, .c-grid-table thead, .c-grid-table tr {
  border-style: solid;
  border-width: 1px;
  border-color: inherit;
}

.c-grid-table th[data-col-count], .c-grid-table td[data-col-count] {
  width: 1.375rem;
  font-size: .8125rem;
  padding: .25rem 0 !important;
}

.c-grid-table th[data-col-menu], .c-grid-table td[data-col-menu] {
  width: 100px;
}

.c-grid-table th[data-col-date], .c-grid-table td[data-col-date] {
  width: 7.5rem;
}

.c-grid-table th[data-col-main], .c-grid-table td[data-col-main] {
  width: auto;
  padding: 0;
}

.c-grid-table :focus, .c-grid-table :focus-visible {
  outline-offset: -1px;
}

.c-grid-table--head {
  --tw-bg-opacity: 1;
  background-color: rgb(240 242 243 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .c-grid-table--head) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.c-grid-table--head th {
  vertical-align: middle;
  font-weight: 500;
  line-height: 1;
}

:is([data-mode="dark"] .c-grid-table--head th) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.c-grid-table--head th[aria-sort] {
  padding: 0;
  position: relative;
}

.c-grid-table--head th[aria-sort] a {
  justify-content: center;
  align-items: center;
  min-height: 4.625rem;
  padding: .625rem 1.25rem;
  display: flex;
}

.c-grid-table--head th[aria-sort]:before, .c-grid-table--head th[aria-sort]:after {
  pointer-events: none;
  --tw-content: "";
  content: var(--tw-content);
  background-color: #0000;
  border: 3px solid #0000;
  position: absolute;
  right: 7px;
}

.c-grid-table--head th[aria-sort]:before {
  --tw-border-opacity: 1;
  border-bottom-width: 4px;
  border-bottom-color: rgb(154 160 166 / var(--tw-border-opacity));
  bottom: calc(50% + 1px);
}

:is([data-mode="dark"] .c-grid-table--head th[aria-sort]):before {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.c-grid-table--head th[aria-sort]:before {
  border-top-width: 0;
}

.c-grid-table--head th[aria-sort]:after {
  --tw-border-opacity: 1;
  border-top-width: 4px;
  border-top-color: rgb(154 160 166 / var(--tw-border-opacity));
  top: calc(50% + 1px);
}

:is([data-mode="dark"] .c-grid-table--head th[aria-sort]):after {
  --tw-border-opacity: 1;
  border-top-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.c-grid-table--head th[aria-sort]:after {
  border-bottom-width: 0;
}

.c-grid-table--head th[aria-sort="ascending"]:before {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(60 64 67 / var(--tw-border-opacity));
}

:is([data-mode="dark"] .c-grid-table--head th[aria-sort="ascending"]):before {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(218 220 224 / var(--tw-border-opacity));
}

.c-grid-table--head th[aria-sort="descending"]:after {
  --tw-border-opacity: 1;
  border-top-color: rgb(60 64 67 / var(--tw-border-opacity));
}

:is([data-mode="dark"] .c-grid-table--head th[aria-sort="descending"]):after {
  --tw-border-opacity: 1;
  border-top-color: rgb(218 220 224 / var(--tw-border-opacity));
}

.c-grid-table--filter {
  font-size: .9375rem;
}

.c-grid-table--filter td {
  vertical-align: middle;
  color: inherit;
  border-bottom-width: 2px;
  padding: 0;
}

.c-grid-table--filter td[data-col-count]:before {
  content: "";
}

.c-grid-table--filter td[data-col-count] {
  font-size: 1rem;
  line-height: 1.5rem;
}

.c-grid-table--filter td[data-col-count]:before {
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: bootstrap-icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.c-grid-table--filter td[data-col-select]:before {
  content: "";
}

.c-grid-table--filter td[data-col-select] {
  position: relative;
}

.c-grid-table--filter td[data-col-select]:before {
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  pointer-events: none;
  z-index: 2;
  height: min-content;
  margin: auto;
  font-family: bootstrap-icons;
  font-size: .6875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: .5rem;
}

.c-grid-table--filter .control {
  text-overflow: ellipsis;
  color: inherit;
  width: 100%;
  height: 2.75rem;
  padding-left: .625rem;
  padding-right: .625rem;
}

.c-grid-table--filter .control::placeholder {
  --tw-text-opacity: 1;
  color: rgb(60 64 67 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .c-grid-table--filter .control)::placeholder {
  color: #dadce0bf;
}

.c-grid-table--filter .control {
  z-index: 0;
  background-color: #0000;
  position: relative;
}

.c-grid-table--filter .control:focus {
  z-index: 1;
}

.c-grid-table--filter .control.select {
  appearance: none;
  text-align-last: center;
  padding-right: 1.5rem;
}

:is([data-mode="dark"] .c-grid-table--filter .control.select option) {
  --tw-bg-opacity: 1;
  background-color: rgb(48 49 52 / var(--tw-bg-opacity));
}

.c-grid-table--filter .control[type="date"] {
  padding-left: .375rem;
  padding-right: .25rem;
}

:is([data-mode="dark"] .c-grid-table--body a) {
  font-weight: 400;
}

.c-grid-table--body tr {
  cursor: pointer;
}

.c-grid-table--body tr:hover td {
  --tw-shadow: inset 0 0 0 9999px;
  --tw-shadow-colored: inset 0 0 0 9999px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #f8f9fa;
  --tw-shadow: var(--tw-shadow-colored);
}

:is([data-mode="dark"] .c-grid-table--body tr:hover td) {
  --tw-shadow-color: #28292c;
  --tw-shadow: var(--tw-shadow-colored);
}

.c-grid-table--body td:not([data-col-main]) {
  padding: .75rem .625rem;
}

.c-grid-table--body td[data-col-main] {
  text-align: left;
}

.c-grid-table--body td[data-col-main] > a {
  padding: .75rem .625rem;
  display: block;
}

.c-grid-table--body td[data-col-date] {
  white-space: nowrap;
}

.c-grid-table--summary td {
  --tw-bg-opacity: 1;
  background-color: rgb(240 242 243 / var(--tw-bg-opacity));
  padding: .5rem .75rem;
  font-size: .9375rem;
}

:is([data-mode="dark"] .c-grid-table--summary td) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.c-grid-table--summary td:not(:first-child) {
  display: none;
}

.pagination {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
  flex-wrap: wrap;
  align-items: center;
  gap: 2px;
  display: flex;
}

:is([data-mode="dark"] .pagination) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.pagination a {
  border-radius: .375rem;
  padding-left: .5rem;
  padding-right: .5rem;
  line-height: 1.75rem;
  display: block;
}

.pagination a:before {
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: bootstrap-icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.pagination a:hover:not([aria-current]) {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(32 33 36 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .pagination a:hover:not([aria-current])) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
}

.pagination a[aria-current] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .pagination a[aria-current]) {
  --tw-text-opacity: 1;
  color: rgb(32 33 36 / var(--tw-text-opacity));
  font-weight: 500;
}

@media print {
  .pagination a[aria-current] {
    --tw-bg-opacity: 1;
    background-color: rgb(240 242 243 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--primary) / var(--tw-text-opacity));
  }
}

.pagination a[tabindex="-1"] {
  pointer-events: none;
  opacity: .62;
}

:is([data-mode="dark"] .pagination a[tabindex="-1"]) {
  opacity: .55;
}

.pagination li:first-child {
  margin-right: .5rem;
}

.pagination li:first-child a:before {
  vertical-align: 0;
  --tw-content: "";
  content: var(--tw-content);
  font-size: .75rem;
  line-height: 1rem;
}

.pagination li:last-child {
  margin-left: .5rem;
}

.pagination li:last-child a:before {
  vertical-align: 0;
  --tw-content: "";
  content: var(--tw-content);
  font-size: .75rem;
  line-height: 1rem;
}

.foot-top {
  flex-direction: column;
  gap: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
}

@media (width >= 768px) {
  .foot-top {
    flex-flow: row;
  }
}

@media (width >= 1024px) {
  .foot-top {
    gap: 2rem;
  }
}

.foot-top > .col {
  flex-basis: 25%;
}

.foot-top-title {
  margin-bottom: .75rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
}

:is([data-mode="dark"] .foot-top-title) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.foot-top-text p + p {
  margin-top: .75rem;
}

.foot-top-text a {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.foot-top-text a:hover {
  text-decoration-line: underline;
}

.foot-top-menu {
  flex-direction: column;
  gap: .3125rem;
  line-height: 1.375;
  display: flex;
}

.foot-top-menu a {
  display: inline-block;
}

.foot-top-menu a:hover {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .foot-top-menu a:hover) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.foot-middle {
  border-top-width: 1px;
  border-bottom-width: 1px;
  flex-direction: column;
  column-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

@media (width >= 768px) {
  .foot-middle {
    flex-direction: row;
  }
}

.foot-middle {
  --tw-border-opacity: 1;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
}

:is([data-mode="dark"] .foot-middle) {
  --tw-border-opacity: 1;
  border-color: rgb(95 99 104 / var(--tw-border-opacity));
}

.foot-middle > .col {
  flex: 0 auto;
}

@media (width >= 768px) {
  .foot-middle > .col:first-child {
    margin-right: auto;
  }
}

.foot-middle-title {
  margin-right: .25rem;
  display: inline;
}

.foot-middle-title:after {
  --tw-content: ":";
  content: var(--tw-content);
}

.foot-middle-title {
  font-weight: 500;
}

:is([data-mode="dark"] .foot-middle-title) {
  --tw-text-opacity: 1;
  color: rgb(232 234 237 / var(--tw-text-opacity));
  font-weight: 400;
}

.foot-bottom {
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

@media print {
  .foot-bottom {
    padding-bottom: 0;
  }
}

.foot-bottom-owner {
  font-size: .875rem;
  line-height: 1.25rem;
}

.foot-bottom-skip {
  font-size: 1.5rem;
  line-height: 1;
}

.foot-bottom-skip:before {
  --tw-content: "";
  content: var(--tw-content);
}

.foot-bottom-skip {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
}

:is([data-mode="dark"] .foot-bottom-skip) {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

.foot-bottom-skip:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

@media print {
  .foot-bottom-skip {
    display: none;
  }
}

.foot {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .foot) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

@media print {
  .foot {
    display: none;
  }
}

@keyframes dialog {
  from {
    opacity: 0;
    transform: scale(.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

dialog {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 1.5rem;
  padding: 1.5rem;
  animation: .25s both dialog;
}

dialog::backdrop {
  background-color: #00000080;
}

:is([data-mode="dark"] dialog) {
  --tw-bg-opacity: 1;
  background-color: rgb(32 33 36 / var(--tw-bg-opacity));
}

.dialog-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
}

.dialog-text {
  margin-bottom: 1.5rem;
}

dialog input[type="checkbox"] {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
}

body:has(dialog[open]) {
  overflow: hidden;
}

.config-group {
  --tw-bg-opacity: 1;
  background-color: rgb(240 242 243 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .config-group) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity));
}

.config-group {
  border-radius: .75rem;
  justify-content: space-evenly;
  align-items: center;
  gap: .5rem;
  padding: .75rem;
  display: flex;
  position: relative;
}

.config-group-desc {
  text-align: left;
  margin-top: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1 !important;
  color: rgb(95 99 104 / var(--tw-text-opacity)) !important;
}

:is([data-mode="dark"] .config-group-desc) {
  --tw-text-opacity: 1 !important;
  color: rgb(154 160 166 / var(--tw-text-opacity)) !important;
}

.config-group-desc kbd {
  --tw-bg-opacity: 1;
  background-color: rgb(240 242 243 / var(--tw-bg-opacity));
  border-radius: .25rem;
  padding-left: .25rem;
  padding-right: .25rem;
  --tw-text-opacity: 1 !important;
  color: rgb(60 64 67 / var(--tw-text-opacity)) !important;
}

:is([data-mode="dark"] .config-group-desc kbd) {
  --tw-bg-opacity: 1;
  background-color: rgb(48 49 52 / var(--tw-bg-opacity));
  --tw-text-opacity: 1 !important;
  color: rgb(218 220 224 / var(--tw-text-opacity)) !important;
}

.config-group-desc kbd {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
}

:is([data-mode="dark"] .config-group-desc kbd) {
  --tw-border-opacity: 1;
  border-color: rgb(60 64 67 / var(--tw-border-opacity));
}

.mode-control {
  flex: 1;
  height: 2.25rem;
  position: relative;
}

.mode-control-input {
  appearance: none;
  cursor: pointer;
  border-radius: .5rem;
  position: absolute;
  inset: 0;
}

.mode-control-input:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .mode-control-input:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(32 33 36 / var(--tw-bg-opacity));
}

.mode-control-input:checked {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

:is([data-mode="dark"] .mode-control-input:checked) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(32 33 36 / var(--tw-bg-opacity)) !important;
}

.mode-control-label {
  font-size: .9375rem;
  font-weight: 500;
}

:is([data-mode="dark"] .mode-control-label) {
  font-weight: 400;
}

.mode-control-label {
  pointer-events: none;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  padding: 0 1.25rem;
  display: flex;
  position: absolute;
  inset: 0;
}

@media (width <= 639.98px) {
  .mode-control-label {
    justify-content: center;
    font-size: 0;
    transform: translateX(4px);
  }
}

.mode-control-label:before {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
  font-size: 1.25rem;
  line-height: 1.75rem;
}

:is([data-mode="dark"] .mode-control-label):before {
  --tw-text-opacity: 1;
  color: rgb(154 160 166 / var(--tw-text-opacity));
}

:checked + .mode-control-label:before {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.primary-control {
  aspect-ratio: 1;
  appearance: none;
  cursor: pointer;
  border-radius: .5rem;
  width: 2rem;
}

.primary-control:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"%23fff\" viewBox=\"0 0 16 16\"%3e%3cpath d=\"M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z\"/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
}

[data-mode="dark"] .primary-control:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"%23202124\" viewBox=\"0 0 16 16\"%3e%3cpath d=\"M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z\"/%3e%3c/svg%3e");
}

.size-control {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
  aspect-ratio: 1;
  appearance: none;
  cursor: pointer;
  border-radius: 5rem;
  width: 1.375rem;
}

.size-control:not(:checked) {
  transform: scale(.7);
}

.size-control:checked {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
}

.size-control:checked ~ .size-control {
  --tw-bg-opacity: 1;
  background-color: rgb(218 220 224 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] .size-control:checked ~ .size-control) {
  --tw-bg-opacity: 1;
  background-color: rgb(95 99 104 / var(--tw-bg-opacity));
}

.size-control:hover:not(:checked), :is([data-mode="dark"] .size-control:hover:not(:checked)) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
}

.size-track {
  background-color: hsl(var(--primary) / .5);
  flex-grow: 1;
  height: .125rem;
}

:checked ~ .size-track {
  --tw-bg-opacity: 1;
  background-color: rgb(218 220 224 / var(--tw-bg-opacity));
}

:is([data-mode="dark"] :checked ~ .size-track) {
  --tw-bg-opacity: 1;
  background-color: rgb(95 99 104 / var(--tw-bg-opacity));
}

.size-label {
  pointer-events: none;
  text-align: center;
  width: 2rem;
  line-height: 1.5;
}

.size-label:first-of-type {
  margin-right: .25rem;
}

.size-label:last-of-type {
  margin-left: .25rem;
  font-size: 1.25rem;
}

dialog#cookies {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  max-width: none;
  max-height: 80vh;
  animation: none;
  top: auto;
}

dialog#cookies input[type="checkbox"] {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
}

dialog#cookies a {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

dialog#cookies a:hover {
  text-decoration-line: underline;
}

dialog#cookies svg {
  pointer-events: none;
  fill: #d3c2acbf;
  width: 7rem;
  height: 7rem;
  margin-left: auto;
  margin-right: auto;
}

:is([data-mode="dark"] dialog#cookies svg) {
  fill: #d3c2ac4d;
}

.backdrop {
  --tw-bg-opacity: 1;
  background-color: rgb(32 33 36 / var(--tw-bg-opacity));
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

:is([data-mode="dark"] .backdrop) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.backdrop.fade {
  opacity: 0;
}

.backdrop.show {
  opacity: .6;
}

.offcanvas-backdrop, .modal-backdrop {
  --tw-bg-opacity: 1;
  background-color: rgb(32 33 36 / var(--tw-bg-opacity));
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

:is([data-mode="dark"] .offcanvas-backdrop), :is([data-mode="dark"] .modal-backdrop) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.offcanvas-backdrop.fade, .modal-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show, .modal-backdrop.show {
  opacity: .6;
}

.offcanvas-backdrop {
  z-index: 1040;
}

.modal-backdrop {
  z-index: 1050;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.-inset-px {
  inset: -1px;
}

.inset-px {
  inset: 1px;
}

.-inset-x-px {
  left: -1px;
  right: -1px;
}

.-inset-y-px {
  top: -1px;
  bottom: -1px;
}

.inset-x-px {
  left: 1px;
  right: 1px;
}

.inset-y-px {
  top: 1px;
  bottom: 1px;
}

.-bottom-0 {
  bottom: 0;
}

.-bottom-0\.5 {
  bottom: -.125rem;
}

.-bottom-1 {
  bottom: -.25rem;
}

.-bottom-1\.5 {
  bottom: -.375rem;
}

.-bottom-1\/2 {
  bottom: -50%;
}

.-bottom-1\/3 {
  bottom: -33.3333%;
}

.-bottom-1\/4 {
  bottom: -25%;
}

.-bottom-10 {
  bottom: -2.5rem;
}

.-bottom-11 {
  bottom: -2.75rem;
}

.-bottom-12 {
  bottom: -3rem;
}

.-bottom-14 {
  bottom: -3.5rem;
}

.-bottom-16 {
  bottom: -4rem;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-bottom-2\.5 {
  bottom: -.625rem;
}

.-bottom-2\/3 {
  bottom: -66.6667%;
}

.-bottom-2\/4 {
  bottom: -50%;
}

.-bottom-20 {
  bottom: -5rem;
}

.-bottom-24 {
  bottom: -6rem;
}

.-bottom-28 {
  bottom: -7rem;
}

.-bottom-3 {
  bottom: -.75rem;
}

.-bottom-3\.5 {
  bottom: -.875rem;
}

.-bottom-3\/4 {
  bottom: -75%;
}

.-bottom-32 {
  bottom: -8rem;
}

.-bottom-36 {
  bottom: -9rem;
}

.-bottom-4 {
  bottom: -1rem;
}

.-bottom-40 {
  bottom: -10rem;
}

.-bottom-44 {
  bottom: -11rem;
}

.-bottom-48 {
  bottom: -12rem;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.-bottom-52 {
  bottom: -13rem;
}

.-bottom-56 {
  bottom: -14rem;
}

.-bottom-6 {
  bottom: -1.5rem;
}

.-bottom-60 {
  bottom: -15rem;
}

.-bottom-64 {
  bottom: -16rem;
}

.-bottom-7 {
  bottom: -1.75rem;
}

.-bottom-72 {
  bottom: -18rem;
}

.-bottom-8 {
  bottom: -2rem;
}

.-bottom-80 {
  bottom: -20rem;
}

.-bottom-9 {
  bottom: -2.25rem;
}

.-bottom-96 {
  bottom: -24rem;
}

.-bottom-full {
  bottom: -100%;
}

.-bottom-px {
  bottom: -1px;
}

.-end-px {
  inset-inline-end: -1px;
}

.-left-px {
  left: -1px;
}

.-right-px {
  right: -1px;
}

.-start-px {
  inset-inline-start: -1px;
}

.-top-0 {
  top: 0;
}

.-top-0\.5 {
  top: -.125rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-1\.5 {
  top: -.375rem;
}

.-top-1\/2 {
  top: -50%;
}

.-top-1\/3 {
  top: -33.3333%;
}

.-top-1\/4 {
  top: -25%;
}

.-top-10 {
  top: -2.5rem;
}

.-top-11 {
  top: -2.75rem;
}

.-top-12 {
  top: -3rem;
}

.-top-14 {
  top: -3.5rem;
}

.-top-16 {
  top: -4rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-2\.5 {
  top: -.625rem;
}

.-top-2\/3 {
  top: -66.6667%;
}

.-top-2\/4 {
  top: -50%;
}

.-top-20 {
  top: -5rem;
}

.-top-24 {
  top: -6rem;
}

.-top-28 {
  top: -7rem;
}

.-top-3 {
  top: -.75rem;
}

.-top-3\.5 {
  top: -.875rem;
}

.-top-3\/4 {
  top: -75%;
}

.-top-32 {
  top: -8rem;
}

.-top-36 {
  top: -9rem;
}

.-top-4 {
  top: -1rem;
}

.-top-40 {
  top: -10rem;
}

.-top-44 {
  top: -11rem;
}

.-top-48 {
  top: -12rem;
}

.-top-5 {
  top: -1.25rem;
}

.-top-52 {
  top: -13rem;
}

.-top-56 {
  top: -14rem;
}

.-top-6 {
  top: -1.5rem;
}

.-top-60 {
  top: -15rem;
}

.-top-64 {
  top: -16rem;
}

.-top-7 {
  top: -1.75rem;
}

.-top-72 {
  top: -18rem;
}

.-top-8 {
  top: -2rem;
}

.-top-80 {
  top: -20rem;
}

.-top-9 {
  top: -2.25rem;
}

.-top-96 {
  top: -24rem;
}

.-top-full {
  top: -100%;
}

.-top-px {
  top: -1px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-1\.5 {
  bottom: .375rem;
}

.bottom-1\/2 {
  bottom: 50%;
}

.bottom-1\/3 {
  bottom: 33.3333%;
}

.bottom-1\/4 {
  bottom: 25%;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-11 {
  bottom: 2.75rem;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-2\.5 {
  bottom: .625rem;
}

.bottom-2\/3 {
  bottom: 66.6667%;
}

.bottom-2\/4 {
  bottom: 50%;
}

.bottom-20 {
  bottom: 5rem;
}

.bottom-24 {
  bottom: 6rem;
}

.bottom-28 {
  bottom: 7rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-3\.5 {
  bottom: .875rem;
}

.bottom-3\/4 {
  bottom: 75%;
}

.bottom-32 {
  bottom: 8rem;
}

.bottom-36 {
  bottom: 9rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-40 {
  bottom: 10rem;
}

.bottom-44 {
  bottom: 11rem;
}

.bottom-48 {
  bottom: 12rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-52 {
  bottom: 13rem;
}

.bottom-56 {
  bottom: 14rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-60 {
  bottom: 15rem;
}

.bottom-64 {
  bottom: 16rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.bottom-72 {
  bottom: 18rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-80 {
  bottom: 20rem;
}

.bottom-9 {
  bottom: 2.25rem;
}

.bottom-96 {
  bottom: 24rem;
}

.bottom-auto {
  bottom: auto;
}

.bottom-full {
  bottom: 100%;
}

.bottom-px {
  bottom: 1px;
}

.end-px {
  inset-inline-end: 1px;
}

.left-px {
  left: 1px;
}

.right-px {
  right: 1px;
}

.start-px {
  inset-inline-start: 1px;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-1\/2 {
  top: 50%;
}

.top-1\/3 {
  top: 33.3333%;
}

.top-1\/4 {
  top: 25%;
}

.top-10 {
  top: 2.5rem;
}

.top-11 {
  top: 2.75rem;
}

.top-12 {
  top: 3rem;
}

.top-14 {
  top: 3.5rem;
}

.top-16 {
  top: 4rem;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-2\/3 {
  top: 66.6667%;
}

.top-2\/4 {
  top: 50%;
}

.top-20 {
  top: 5rem;
}

.top-24 {
  top: 6rem;
}

.top-28 {
  top: 7rem;
}

.top-3 {
  top: .75rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-3\/4 {
  top: 75%;
}

.top-32 {
  top: 8rem;
}

.top-36 {
  top: 9rem;
}

.top-4 {
  top: 1rem;
}

.top-40 {
  top: 10rem;
}

.top-44 {
  top: 11rem;
}

.top-48 {
  top: 12rem;
}

.top-5 {
  top: 1.25rem;
}

.top-52 {
  top: 13rem;
}

.top-56 {
  top: 14rem;
}

.top-6 {
  top: 1.5rem;
}

.top-60 {
  top: 15rem;
}

.top-64 {
  top: 16rem;
}

.top-7 {
  top: 1.75rem;
}

.top-72 {
  top: 18rem;
}

.top-8 {
  top: 2rem;
}

.top-80 {
  top: 20rem;
}

.top-9 {
  top: 2.25rem;
}

.top-96 {
  top: 24rem;
}

.top-auto {
  top: auto;
}

.top-full {
  top: 100%;
}

.top-px {
  top: 1px;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-full {
  grid-column: 1 / -1;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-full {
  grid-row: 1 / -1;
}

.-m-0 {
  margin: 0;
}

.-m-0\.5 {
  margin: -.125rem;
}

.-m-1 {
  margin: -.25rem;
}

.-m-1\.5 {
  margin: -.375rem;
}

.-m-10 {
  margin: -2.5rem;
}

.-m-11 {
  margin: -2.75rem;
}

.-m-12 {
  margin: -3rem;
}

.-m-14 {
  margin: -3.5rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-2 {
  margin: -.5rem;
}

.-m-2\.5 {
  margin: -.625rem;
}

.-m-20 {
  margin: -5rem;
}

.-m-24 {
  margin: -6rem;
}

.-m-28 {
  margin: -7rem;
}

.-m-3 {
  margin: -.75rem;
}

.-m-3\.5 {
  margin: -.875rem;
}

.-m-32 {
  margin: -8rem;
}

.-m-36 {
  margin: -9rem;
}

.-m-4 {
  margin: -1rem;
}

.-m-40 {
  margin: -10rem;
}

.-m-44 {
  margin: -11rem;
}

.-m-48 {
  margin: -12rem;
}

.-m-5 {
  margin: -1.25rem;
}

.-m-52 {
  margin: -13rem;
}

.-m-56 {
  margin: -14rem;
}

.-m-6 {
  margin: -1.5rem;
}

.-m-60 {
  margin: -15rem;
}

.-m-64 {
  margin: -16rem;
}

.-m-7 {
  margin: -1.75rem;
}

.-m-72 {
  margin: -18rem;
}

.-m-8 {
  margin: -2rem;
}

.-m-80 {
  margin: -20rem;
}

.-m-9 {
  margin: -2.25rem;
}

.-m-96 {
  margin: -24rem;
}

.-m-px {
  margin: -1px;
}

.m-0 {
  margin: 0;
}

.m-0\.5 {
  margin: .125rem;
}

.m-1 {
  margin: .25rem;
}

.m-1\.5 {
  margin: .375rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-11 {
  margin: 2.75rem;
}

.m-12 {
  margin: 3rem;
}

.m-14 {
  margin: 3.5rem;
}

.m-16 {
  margin: 4rem;
}

.m-2 {
  margin: .5rem;
}

.m-2\.5 {
  margin: .625rem;
}

.m-20 {
  margin: 5rem;
}

.m-24 {
  margin: 6rem;
}

.m-28 {
  margin: 7rem;
}

.m-3 {
  margin: .75rem;
}

.m-3\.5 {
  margin: .875rem;
}

.m-32 {
  margin: 8rem;
}

.m-36 {
  margin: 9rem;
}

.m-4 {
  margin: 1rem;
}

.m-40 {
  margin: 10rem;
}

.m-44 {
  margin: 11rem;
}

.m-48 {
  margin: 12rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-52 {
  margin: 13rem;
}

.m-56 {
  margin: 14rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-60 {
  margin: 15rem;
}

.m-64 {
  margin: 16rem;
}

.m-7 {
  margin: 1.75rem;
}

.m-72 {
  margin: 18rem;
}

.m-8 {
  margin: 2rem;
}

.m-80 {
  margin: 20rem;
}

.m-9 {
  margin: 2.25rem;
}

.m-96 {
  margin: 24rem;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.-mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.-mx-0\.5 {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-1\.5 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-mx-11 {
  margin-left: -2.75rem;
  margin-right: -2.75rem;
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.-mx-14 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-2\.5 {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.-mx-28 {
  margin-left: -7rem;
  margin-right: -7rem;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.-mx-3\.5 {
  margin-left: -.875rem;
  margin-right: -.875rem;
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.-mx-36 {
  margin-left: -9rem;
  margin-right: -9rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.-mx-44 {
  margin-left: -11rem;
  margin-right: -11rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-mx-52 {
  margin-left: -13rem;
  margin-right: -13rem;
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-mx-60 {
  margin-left: -15rem;
  margin-right: -15rem;
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.-mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}

.-mx-72 {
  margin-left: -18rem;
  margin-right: -18rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-mx-80 {
  margin-left: -20rem;
  margin-right: -20rem;
}

.-mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}

.-mx-96 {
  margin-left: -24rem;
  margin-right: -24rem;
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.-my-0\.5 {
  margin-top: -.125rem;
  margin-bottom: -.125rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.-my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.-my-14 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.-my-2\.5 {
  margin-top: -.625rem;
  margin-bottom: -.625rem;
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.-my-28 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}

.-my-3 {
  margin-top: -.75rem;
  margin-bottom: -.75rem;
}

.-my-3\.5 {
  margin-top: -.875rem;
  margin-bottom: -.875rem;
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.-my-36 {
  margin-top: -9rem;
  margin-bottom: -9rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.-my-44 {
  margin-top: -11rem;
  margin-bottom: -11rem;
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.-my-52 {
  margin-top: -13rem;
  margin-bottom: -13rem;
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.-my-60 {
  margin-top: -15rem;
  margin-bottom: -15rem;
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.-my-7 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}

.-my-72 {
  margin-top: -18rem;
  margin-bottom: -18rem;
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.-my-80 {
  margin-top: -20rem;
  margin-bottom: -20rem;
}

.-my-9 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}

.-my-96 {
  margin-top: -24rem;
  margin-bottom: -24rem;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-0\.5 {
  margin-left: .125rem;
  margin-right: .125rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-1\.5 {
  margin-left: .375rem;
  margin-right: .375rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-2\.5 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-3\.5 {
  margin-left: .875rem;
  margin-right: .875rem;
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mx-36 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.mx-44 {
  margin-left: 11rem;
  margin-right: 11rem;
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-52 {
  margin-left: 13rem;
  margin-right: 13rem;
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-60 {
  margin-left: 15rem;
  margin-right: 15rem;
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.mx-72 {
  margin-left: 18rem;
  margin-right: 18rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-80 {
  margin-left: 20rem;
  margin-right: 20rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.mx-96 {
  margin-left: 24rem;
  margin-right: 24rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-0\.5 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-1\.5 {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-2\.5 {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-3\.5 {
  margin-top: .875rem;
  margin-bottom: .875rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.my-44 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-52 {
  margin-top: 13rem;
  margin-bottom: 13rem;
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-60 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-72 {
  margin-top: 18rem;
  margin-bottom: 18rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-80 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.my-96 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.-mb-0 {
  margin-bottom: 0;
}

.-mb-0\.5 {
  margin-bottom: -.125rem;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-mb-1\.5 {
  margin-bottom: -.375rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-mb-11 {
  margin-bottom: -2.75rem;
}

.-mb-12 {
  margin-bottom: -3rem;
}

.-mb-14 {
  margin-bottom: -3.5rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-mb-2\.5 {
  margin-bottom: -.625rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-mb-24 {
  margin-bottom: -6rem;
}

.-mb-28 {
  margin-bottom: -7rem;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.-mb-3\.5 {
  margin-bottom: -.875rem;
}

.-mb-32 {
  margin-bottom: -8rem;
}

.-mb-36 {
  margin-bottom: -9rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mb-40 {
  margin-bottom: -10rem;
}

.-mb-44 {
  margin-bottom: -11rem;
}

.-mb-48 {
  margin-bottom: -12rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-mb-52 {
  margin-bottom: -13rem;
}

.-mb-56 {
  margin-bottom: -14rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-mb-60 {
  margin-bottom: -15rem;
}

.-mb-64 {
  margin-bottom: -16rem;
}

.-mb-7 {
  margin-bottom: -1.75rem;
}

.-mb-72 {
  margin-bottom: -18rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-mb-80 {
  margin-bottom: -20rem;
}

.-mb-9 {
  margin-bottom: -2.25rem;
}

.-mb-96 {
  margin-bottom: -24rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-me-0 {
  margin-inline-end: 0;
}

.-me-0\.5 {
  margin-inline-end: -.125rem;
}

.-me-1 {
  margin-inline-end: -.25rem;
}

.-me-1\.5 {
  margin-inline-end: -.375rem;
}

.-me-10 {
  margin-inline-end: -2.5rem;
}

.-me-11 {
  margin-inline-end: -2.75rem;
}

.-me-12 {
  margin-inline-end: -3rem;
}

.-me-14 {
  margin-inline-end: -3.5rem;
}

.-me-16 {
  margin-inline-end: -4rem;
}

.-me-2 {
  margin-inline-end: -.5rem;
}

.-me-2\.5 {
  margin-inline-end: -.625rem;
}

.-me-20 {
  margin-inline-end: -5rem;
}

.-me-24 {
  margin-inline-end: -6rem;
}

.-me-28 {
  margin-inline-end: -7rem;
}

.-me-3 {
  margin-inline-end: -.75rem;
}

.-me-3\.5 {
  margin-inline-end: -.875rem;
}

.-me-32 {
  margin-inline-end: -8rem;
}

.-me-36 {
  margin-inline-end: -9rem;
}

.-me-4 {
  margin-inline-end: -1rem;
}

.-me-40 {
  margin-inline-end: -10rem;
}

.-me-44 {
  margin-inline-end: -11rem;
}

.-me-48 {
  margin-inline-end: -12rem;
}

.-me-5 {
  margin-inline-end: -1.25rem;
}

.-me-52 {
  margin-inline-end: -13rem;
}

.-me-56 {
  margin-inline-end: -14rem;
}

.-me-6 {
  margin-inline-end: -1.5rem;
}

.-me-60 {
  margin-inline-end: -15rem;
}

.-me-64 {
  margin-inline-end: -16rem;
}

.-me-7 {
  margin-inline-end: -1.75rem;
}

.-me-72 {
  margin-inline-end: -18rem;
}

.-me-8 {
  margin-inline-end: -2rem;
}

.-me-80 {
  margin-inline-end: -20rem;
}

.-me-9 {
  margin-inline-end: -2.25rem;
}

.-me-96 {
  margin-inline-end: -24rem;
}

.-me-px {
  margin-inline-end: -1px;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-1\.5 {
  margin-left: -.375rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.-ml-11 {
  margin-left: -2.75rem;
}

.-ml-12 {
  margin-left: -3rem;
}

.-ml-14 {
  margin-left: -3.5rem;
}

.-ml-16 {
  margin-left: -4rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-2\.5 {
  margin-left: -.625rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.-ml-24 {
  margin-left: -6rem;
}

.-ml-28 {
  margin-left: -7rem;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-ml-3\.5 {
  margin-left: -.875rem;
}

.-ml-32 {
  margin-left: -8rem;
}

.-ml-36 {
  margin-left: -9rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-ml-40 {
  margin-left: -10rem;
}

.-ml-44 {
  margin-left: -11rem;
}

.-ml-48 {
  margin-left: -12rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-ml-52 {
  margin-left: -13rem;
}

.-ml-56 {
  margin-left: -14rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-ml-60 {
  margin-left: -15rem;
}

.-ml-64 {
  margin-left: -16rem;
}

.-ml-7 {
  margin-left: -1.75rem;
}

.-ml-72 {
  margin-left: -18rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-ml-80 {
  margin-left: -20rem;
}

.-ml-9 {
  margin-left: -2.25rem;
}

.-ml-96 {
  margin-left: -24rem;
}

.-ml-px {
  margin-left: -1px;
}

.-mr-0 {
  margin-right: 0;
}

.-mr-0\.5 {
  margin-right: -.125rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mr-1\.5 {
  margin-right: -.375rem;
}

.-mr-10 {
  margin-right: -2.5rem;
}

.-mr-11 {
  margin-right: -2.75rem;
}

.-mr-12 {
  margin-right: -3rem;
}

.-mr-14 {
  margin-right: -3.5rem;
}

.-mr-16 {
  margin-right: -4rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mr-2\.5 {
  margin-right: -.625rem;
}

.-mr-20 {
  margin-right: -5rem;
}

.-mr-24 {
  margin-right: -6rem;
}

.-mr-28 {
  margin-right: -7rem;
}

.-mr-3 {
  margin-right: -.75rem;
}

.-mr-3\.5 {
  margin-right: -.875rem;
}

.-mr-32 {
  margin-right: -8rem;
}

.-mr-36 {
  margin-right: -9rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mr-40 {
  margin-right: -10rem;
}

.-mr-44 {
  margin-right: -11rem;
}

.-mr-48 {
  margin-right: -12rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.-mr-52 {
  margin-right: -13rem;
}

.-mr-56 {
  margin-right: -14rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mr-60 {
  margin-right: -15rem;
}

.-mr-64 {
  margin-right: -16rem;
}

.-mr-7 {
  margin-right: -1.75rem;
}

.-mr-72 {
  margin-right: -18rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.-mr-80 {
  margin-right: -20rem;
}

.-mr-9 {
  margin-right: -2.25rem;
}

.-mr-96 {
  margin-right: -24rem;
}

.-mr-px {
  margin-right: -1px;
}

.-ms-0 {
  margin-inline-start: 0;
}

.-ms-0\.5 {
  margin-inline-start: -.125rem;
}

.-ms-1 {
  margin-inline-start: -.25rem;
}

.-ms-1\.5 {
  margin-inline-start: -.375rem;
}

.-ms-10 {
  margin-inline-start: -2.5rem;
}

.-ms-11 {
  margin-inline-start: -2.75rem;
}

.-ms-12 {
  margin-inline-start: -3rem;
}

.-ms-14 {
  margin-inline-start: -3.5rem;
}

.-ms-16 {
  margin-inline-start: -4rem;
}

.-ms-2 {
  margin-inline-start: -.5rem;
}

.-ms-2\.5 {
  margin-inline-start: -.625rem;
}

.-ms-20 {
  margin-inline-start: -5rem;
}

.-ms-24 {
  margin-inline-start: -6rem;
}

.-ms-28 {
  margin-inline-start: -7rem;
}

.-ms-3 {
  margin-inline-start: -.75rem;
}

.-ms-3\.5 {
  margin-inline-start: -.875rem;
}

.-ms-32 {
  margin-inline-start: -8rem;
}

.-ms-36 {
  margin-inline-start: -9rem;
}

.-ms-4 {
  margin-inline-start: -1rem;
}

.-ms-40 {
  margin-inline-start: -10rem;
}

.-ms-44 {
  margin-inline-start: -11rem;
}

.-ms-48 {
  margin-inline-start: -12rem;
}

.-ms-5 {
  margin-inline-start: -1.25rem;
}

.-ms-52 {
  margin-inline-start: -13rem;
}

.-ms-56 {
  margin-inline-start: -14rem;
}

.-ms-6 {
  margin-inline-start: -1.5rem;
}

.-ms-60 {
  margin-inline-start: -15rem;
}

.-ms-64 {
  margin-inline-start: -16rem;
}

.-ms-7 {
  margin-inline-start: -1.75rem;
}

.-ms-72 {
  margin-inline-start: -18rem;
}

.-ms-8 {
  margin-inline-start: -2rem;
}

.-ms-80 {
  margin-inline-start: -20rem;
}

.-ms-9 {
  margin-inline-start: -2.25rem;
}

.-ms-96 {
  margin-inline-start: -24rem;
}

.-ms-px {
  margin-inline-start: -1px;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-1\.5 {
  margin-top: -.375rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-11 {
  margin-top: -2.75rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-2\.5 {
  margin-top: -.625rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-28 {
  margin-top: -7rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.-mt-3\.5 {
  margin-top: -.875rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-36 {
  margin-top: -9rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-40 {
  margin-top: -10rem;
}

.-mt-44 {
  margin-top: -11rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mt-52 {
  margin-top: -13rem;
}

.-mt-56 {
  margin-top: -14rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-60 {
  margin-top: -15rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.-mt-7 {
  margin-top: -1.75rem;
}

.-mt-72 {
  margin-top: -18rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-80 {
  margin-top: -20rem;
}

.-mt-9 {
  margin-top: -2.25rem;
}

.-mt-96 {
  margin-top: -24rem;
}

.-mt-px {
  margin-top: -1px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-3\.5 {
  margin-bottom: .875rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-36 {
  margin-bottom: 9rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mb-44 {
  margin-bottom: 11rem;
}

.mb-48 {
  margin-bottom: 12rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-52 {
  margin-bottom: 13rem;
}

.mb-56 {
  margin-bottom: 14rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-60 {
  margin-bottom: 15rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-72 {
  margin-bottom: 18rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-80 {
  margin-bottom: 20rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-96 {
  margin-bottom: 24rem;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-px {
  margin-bottom: 1px;
}

.me-0 {
  margin-inline-end: 0;
}

.me-0\.5 {
  margin-inline-end: .125rem;
}

.me-1 {
  margin-inline-end: .25rem;
}

.me-1\.5 {
  margin-inline-end: .375rem;
}

.me-10 {
  margin-inline-end: 2.5rem;
}

.me-11 {
  margin-inline-end: 2.75rem;
}

.me-12 {
  margin-inline-end: 3rem;
}

.me-14 {
  margin-inline-end: 3.5rem;
}

.me-16 {
  margin-inline-end: 4rem;
}

.me-2 {
  margin-inline-end: .5rem;
}

.me-2\.5 {
  margin-inline-end: .625rem;
}

.me-20 {
  margin-inline-end: 5rem;
}

.me-24 {
  margin-inline-end: 6rem;
}

.me-28 {
  margin-inline-end: 7rem;
}

.me-3 {
  margin-inline-end: .75rem;
}

.me-3\.5 {
  margin-inline-end: .875rem;
}

.me-32 {
  margin-inline-end: 8rem;
}

.me-36 {
  margin-inline-end: 9rem;
}

.me-4 {
  margin-inline-end: 1rem;
}

.me-40 {
  margin-inline-end: 10rem;
}

.me-44 {
  margin-inline-end: 11rem;
}

.me-48 {
  margin-inline-end: 12rem;
}

.me-5 {
  margin-inline-end: 1.25rem;
}

.me-52 {
  margin-inline-end: 13rem;
}

.me-56 {
  margin-inline-end: 14rem;
}

.me-6 {
  margin-inline-end: 1.5rem;
}

.me-60 {
  margin-inline-end: 15rem;
}

.me-64 {
  margin-inline-end: 16rem;
}

.me-7 {
  margin-inline-end: 1.75rem;
}

.me-72 {
  margin-inline-end: 18rem;
}

.me-8 {
  margin-inline-end: 2rem;
}

.me-80 {
  margin-inline-end: 20rem;
}

.me-9 {
  margin-inline-end: 2.25rem;
}

.me-96 {
  margin-inline-end: 24rem;
}

.me-auto {
  margin-inline-end: auto;
}

.me-px {
  margin-inline-end: 1px;
}

.ml-0 {
  margin-left: 0;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-11 {
  margin-left: 2.75rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-24 {
  margin-left: 6rem;
}

.ml-28 {
  margin-left: 7rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-3\.5 {
  margin-left: .875rem;
}

.ml-32 {
  margin-left: 8rem;
}

.ml-36 {
  margin-left: 9rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-40 {
  margin-left: 10rem;
}

.ml-44 {
  margin-left: 11rem;
}

.ml-48 {
  margin-left: 12rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-52 {
  margin-left: 13rem;
}

.ml-56 {
  margin-left: 14rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-60 {
  margin-left: 15rem;
}

.ml-64 {
  margin-left: 16rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-72 {
  margin-left: 18rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-80 {
  margin-left: 20rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.ml-96 {
  margin-left: 24rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-px {
  margin-left: 1px;
}

.mr-0 {
  margin-right: 0;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-11 {
  margin-right: 2.75rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mr-28 {
  margin-right: 7rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-3\.5 {
  margin-right: .875rem;
}

.mr-32 {
  margin-right: 8rem;
}

.mr-36 {
  margin-right: 9rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-40 {
  margin-right: 10rem;
}

.mr-44 {
  margin-right: 11rem;
}

.mr-48 {
  margin-right: 12rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-52 {
  margin-right: 13rem;
}

.mr-56 {
  margin-right: 14rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-60 {
  margin-right: 15rem;
}

.mr-64 {
  margin-right: 16rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mr-72 {
  margin-right: 18rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-80 {
  margin-right: 20rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mr-96 {
  margin-right: 24rem;
}

.mr-auto {
  margin-right: auto;
}

.mr-px {
  margin-right: 1px;
}

.ms-0 {
  margin-inline-start: 0;
}

.ms-0\.5 {
  margin-inline-start: .125rem;
}

.ms-1 {
  margin-inline-start: .25rem;
}

.ms-1\.5 {
  margin-inline-start: .375rem;
}

.ms-10 {
  margin-inline-start: 2.5rem;
}

.ms-11 {
  margin-inline-start: 2.75rem;
}

.ms-12 {
  margin-inline-start: 3rem;
}

.ms-14 {
  margin-inline-start: 3.5rem;
}

.ms-16 {
  margin-inline-start: 4rem;
}

.ms-2 {
  margin-inline-start: .5rem;
}

.ms-2\.5 {
  margin-inline-start: .625rem;
}

.ms-20 {
  margin-inline-start: 5rem;
}

.ms-24 {
  margin-inline-start: 6rem;
}

.ms-28 {
  margin-inline-start: 7rem;
}

.ms-3 {
  margin-inline-start: .75rem;
}

.ms-3\.5 {
  margin-inline-start: .875rem;
}

.ms-32 {
  margin-inline-start: 8rem;
}

.ms-36 {
  margin-inline-start: 9rem;
}

.ms-4 {
  margin-inline-start: 1rem;
}

.ms-40 {
  margin-inline-start: 10rem;
}

.ms-44 {
  margin-inline-start: 11rem;
}

.ms-48 {
  margin-inline-start: 12rem;
}

.ms-5 {
  margin-inline-start: 1.25rem;
}

.ms-52 {
  margin-inline-start: 13rem;
}

.ms-56 {
  margin-inline-start: 14rem;
}

.ms-6 {
  margin-inline-start: 1.5rem;
}

.ms-60 {
  margin-inline-start: 15rem;
}

.ms-64 {
  margin-inline-start: 16rem;
}

.ms-7 {
  margin-inline-start: 1.75rem;
}

.ms-72 {
  margin-inline-start: 18rem;
}

.ms-8 {
  margin-inline-start: 2rem;
}

.ms-80 {
  margin-inline-start: 20rem;
}

.ms-9 {
  margin-inline-start: 2.25rem;
}

.ms-96 {
  margin-inline-start: 24rem;
}

.ms-auto {
  margin-inline-start: auto;
}

.ms-px {
  margin-inline-start: 1px;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mt-44 {
  margin-top: 11rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-52 {
  margin-top: 13rem;
}

.mt-56 {
  margin-top: 14rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-60 {
  margin-top: 15rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-72 {
  margin-top: 18rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-80 {
  margin-top: 20rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-96 {
  margin-top: 24rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-px {
  margin-top: 1px;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-6 {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-none {
  -webkit-line-clamp: none;
  -webkit-box-orient: horizontal;
  display: block;
  overflow: visible;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.table-caption {
  display: table-caption;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.grid {
  display: grid;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.aspect-auto {
  aspect-ratio: auto;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-max {
  height: max-content;
}

.h-min {
  height: min-content;
}

.h-px {
  height: 1px;
}

.max-h-0 {
  max-height: 0;
}

.max-h-0\.5 {
  max-height: .125rem;
}

.max-h-1 {
  max-height: .25rem;
}

.max-h-1\.5 {
  max-height: .375rem;
}

.max-h-10 {
  max-height: 2.5rem;
}

.max-h-11 {
  max-height: 2.75rem;
}

.max-h-12 {
  max-height: 3rem;
}

.max-h-14 {
  max-height: 3.5rem;
}

.max-h-16 {
  max-height: 4rem;
}

.max-h-2 {
  max-height: .5rem;
}

.max-h-2\.5 {
  max-height: .625rem;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-28 {
  max-height: 7rem;
}

.max-h-3 {
  max-height: .75rem;
}

.max-h-3\.5 {
  max-height: .875rem;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-36 {
  max-height: 9rem;
}

.max-h-4 {
  max-height: 1rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-44 {
  max-height: 11rem;
}

.max-h-48 {
  max-height: 12rem;
}

.max-h-5 {
  max-height: 1.25rem;
}

.max-h-52 {
  max-height: 13rem;
}

.max-h-56 {
  max-height: 14rem;
}

.max-h-6 {
  max-height: 1.5rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-7 {
  max-height: 1.75rem;
}

.max-h-72 {
  max-height: 18rem;
}

.max-h-8 {
  max-height: 2rem;
}

.max-h-80 {
  max-height: 20rem;
}

.max-h-9 {
  max-height: 2.25rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-fit {
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.max-h-full {
  max-height: 100%;
}

.max-h-max {
  max-height: max-content;
}

.max-h-min {
  max-height: min-content;
}

.max-h-none {
  max-height: none;
}

.max-h-px {
  max-height: 1px;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-0 {
  min-height: 0;
}

.min-h-fit {
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.min-h-full {
  min-height: 100%;
}

.min-h-max {
  min-height: max-content;
}

.min-h-min {
  min-height: min-content;
}

.min-h-screen {
  min-height: 100vh;
}

.w-36 {
  width: 9rem;
}

.w-\[28rem\] {
  width: 28rem;
}

.w-\[30rem\] {
  width: 30rem;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-px {
  width: 1px;
}

.min-w-0 {
  min-width: 0;
}

.min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.min-w-min {
  min-width: min-content;
}

.max-w-0 {
  max-width: 0;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-fit {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-max {
  max-width: max-content;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-min {
  max-width: min-content;
}

.max-w-none {
  max-width: none;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-2xl {
  max-width: 1400px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.basis-full {
  flex-basis: 100%;
}

.basis-px {
  flex-basis: 1px;
}

.caption-top {
  caption-side: top;
}

.caption-bottom {
  caption-side: bottom;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-0 {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-0\.5 {
  --tw-border-spacing-x: .125rem;
  --tw-border-spacing-y: .125rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-1 {
  --tw-border-spacing-x: .25rem;
  --tw-border-spacing-y: .25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-1\.5 {
  --tw-border-spacing-x: .375rem;
  --tw-border-spacing-y: .375rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-10 {
  --tw-border-spacing-x: 2.5rem;
  --tw-border-spacing-y: 2.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-11 {
  --tw-border-spacing-x: 2.75rem;
  --tw-border-spacing-y: 2.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-12 {
  --tw-border-spacing-x: 3rem;
  --tw-border-spacing-y: 3rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-14 {
  --tw-border-spacing-x: 3.5rem;
  --tw-border-spacing-y: 3.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-16 {
  --tw-border-spacing-x: 4rem;
  --tw-border-spacing-y: 4rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-2 {
  --tw-border-spacing-x: .5rem;
  --tw-border-spacing-y: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-2\.5 {
  --tw-border-spacing-x: .625rem;
  --tw-border-spacing-y: .625rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-20 {
  --tw-border-spacing-x: 5rem;
  --tw-border-spacing-y: 5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-24 {
  --tw-border-spacing-x: 6rem;
  --tw-border-spacing-y: 6rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-28 {
  --tw-border-spacing-x: 7rem;
  --tw-border-spacing-y: 7rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-3 {
  --tw-border-spacing-x: .75rem;
  --tw-border-spacing-y: .75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-3\.5 {
  --tw-border-spacing-x: .875rem;
  --tw-border-spacing-y: .875rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-32 {
  --tw-border-spacing-x: 8rem;
  --tw-border-spacing-y: 8rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-36 {
  --tw-border-spacing-x: 9rem;
  --tw-border-spacing-y: 9rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-4 {
  --tw-border-spacing-x: 1rem;
  --tw-border-spacing-y: 1rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-40 {
  --tw-border-spacing-x: 10rem;
  --tw-border-spacing-y: 10rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-44 {
  --tw-border-spacing-x: 11rem;
  --tw-border-spacing-y: 11rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-48 {
  --tw-border-spacing-x: 12rem;
  --tw-border-spacing-y: 12rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-5 {
  --tw-border-spacing-x: 1.25rem;
  --tw-border-spacing-y: 1.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-52 {
  --tw-border-spacing-x: 13rem;
  --tw-border-spacing-y: 13rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-56 {
  --tw-border-spacing-x: 14rem;
  --tw-border-spacing-y: 14rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-6 {
  --tw-border-spacing-x: 1.5rem;
  --tw-border-spacing-y: 1.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-60 {
  --tw-border-spacing-x: 15rem;
  --tw-border-spacing-y: 15rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-64 {
  --tw-border-spacing-x: 16rem;
  --tw-border-spacing-y: 16rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-7 {
  --tw-border-spacing-x: 1.75rem;
  --tw-border-spacing-y: 1.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-72 {
  --tw-border-spacing-x: 18rem;
  --tw-border-spacing-y: 18rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-8 {
  --tw-border-spacing-x: 2rem;
  --tw-border-spacing-y: 2rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-80 {
  --tw-border-spacing-x: 20rem;
  --tw-border-spacing-y: 20rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-9 {
  --tw-border-spacing-x: 2.25rem;
  --tw-border-spacing-y: 2.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-96 {
  --tw-border-spacing-x: 24rem;
  --tw-border-spacing-y: 24rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-px {
  --tw-border-spacing-x: 1px;
  --tw-border-spacing-y: 1px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-0 {
  --tw-border-spacing-x: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-0\.5 {
  --tw-border-spacing-x: .125rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-1 {
  --tw-border-spacing-x: .25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-1\.5 {
  --tw-border-spacing-x: .375rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-10 {
  --tw-border-spacing-x: 2.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-11 {
  --tw-border-spacing-x: 2.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-12 {
  --tw-border-spacing-x: 3rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-14 {
  --tw-border-spacing-x: 3.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-16 {
  --tw-border-spacing-x: 4rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-2 {
  --tw-border-spacing-x: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-2\.5 {
  --tw-border-spacing-x: .625rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-20 {
  --tw-border-spacing-x: 5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-24 {
  --tw-border-spacing-x: 6rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-28 {
  --tw-border-spacing-x: 7rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-3 {
  --tw-border-spacing-x: .75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-3\.5 {
  --tw-border-spacing-x: .875rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-32 {
  --tw-border-spacing-x: 8rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-36 {
  --tw-border-spacing-x: 9rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-4 {
  --tw-border-spacing-x: 1rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-40 {
  --tw-border-spacing-x: 10rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-44 {
  --tw-border-spacing-x: 11rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-48 {
  --tw-border-spacing-x: 12rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-5 {
  --tw-border-spacing-x: 1.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-52 {
  --tw-border-spacing-x: 13rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-56 {
  --tw-border-spacing-x: 14rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-6 {
  --tw-border-spacing-x: 1.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-60 {
  --tw-border-spacing-x: 15rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-64 {
  --tw-border-spacing-x: 16rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-7 {
  --tw-border-spacing-x: 1.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-72 {
  --tw-border-spacing-x: 18rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-8 {
  --tw-border-spacing-x: 2rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-80 {
  --tw-border-spacing-x: 20rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-9 {
  --tw-border-spacing-x: 2.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-96 {
  --tw-border-spacing-x: 24rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-px {
  --tw-border-spacing-x: 1px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-0 {
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-0\.5 {
  --tw-border-spacing-y: .125rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-1 {
  --tw-border-spacing-y: .25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-1\.5 {
  --tw-border-spacing-y: .375rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-10 {
  --tw-border-spacing-y: 2.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-11 {
  --tw-border-spacing-y: 2.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-12 {
  --tw-border-spacing-y: 3rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-14 {
  --tw-border-spacing-y: 3.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-16 {
  --tw-border-spacing-y: 4rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-2 {
  --tw-border-spacing-y: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-2\.5 {
  --tw-border-spacing-y: .625rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-20 {
  --tw-border-spacing-y: 5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-24 {
  --tw-border-spacing-y: 6rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-28 {
  --tw-border-spacing-y: 7rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-3 {
  --tw-border-spacing-y: .75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-3\.5 {
  --tw-border-spacing-y: .875rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-32 {
  --tw-border-spacing-y: 8rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-36 {
  --tw-border-spacing-y: 9rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-4 {
  --tw-border-spacing-y: 1rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-40 {
  --tw-border-spacing-y: 10rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-44 {
  --tw-border-spacing-y: 11rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-48 {
  --tw-border-spacing-y: 12rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-5 {
  --tw-border-spacing-y: 1.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-52 {
  --tw-border-spacing-y: 13rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-56 {
  --tw-border-spacing-y: 14rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-6 {
  --tw-border-spacing-y: 1.5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-60 {
  --tw-border-spacing-y: 15rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-64 {
  --tw-border-spacing-y: 16rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-7 {
  --tw-border-spacing-y: 1.75rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-72 {
  --tw-border-spacing-y: 18rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-8 {
  --tw-border-spacing-y: 2rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-80 {
  --tw-border-spacing-y: 20rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-9 {
  --tw-border-spacing-y: 2.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-96 {
  --tw-border-spacing-y: 24rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-px {
  --tw-border-spacing-y: 1px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-bottom-left {
  transform-origin: 0 100%;
}

.origin-bottom-right {
  transform-origin: 100% 100%;
}

.origin-top {
  transform-origin: top;
}

.origin-top-left {
  transform-origin: 0 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-px {
  --tw-translate-x: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-px {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-px {
  --tw-translate-x: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-px {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform, .transform-cpu {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.animate-bounce {
  animation: 1s infinite bounce;
}

.animate-dialog {
  animation: .25s both dialog;
}

.animate-none {
  animation: none;
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-copy {
  cursor: copy;
}

.cursor-help {
  cursor: help;
}

.cursor-move {
  cursor: move;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.touch-pan-x {
  --tw-pan-x: pan-x;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pan-left {
  --tw-pan-x: pan-left;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pan-right {
  --tw-pan-x: pan-right;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pan-y {
  --tw-pan-y: pan-y;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pan-up {
  --tw-pan-y: pan-up;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pan-down {
  --tw-pan-y: pan-down;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-pinch-zoom {
  --tw-pinch-zoom: pinch-zoom;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.touch-manipulation {
  touch-action: manipulation;
}

.snap-none {
  scroll-snap-type: none;
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
}

.snap-both {
  scroll-snap-type: both var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-proximity {
  --tw-scroll-snap-strictness: proximity;
}

.snap-start {
  scroll-snap-align: start;
}

.snap-end {
  scroll-snap-align: end;
}

.snap-center {
  scroll-snap-align: center;
}

.snap-align-none {
  scroll-snap-align: none;
}

.snap-normal {
  scroll-snap-stop: normal;
}

.snap-always {
  scroll-snap-stop: always;
}

.-scroll-m-0 {
  scroll-margin: 0;
}

.-scroll-m-0\.5 {
  scroll-margin: -.125rem;
}

.-scroll-m-1 {
  scroll-margin: -.25rem;
}

.-scroll-m-1\.5 {
  scroll-margin: -.375rem;
}

.-scroll-m-10 {
  scroll-margin: -2.5rem;
}

.-scroll-m-11 {
  scroll-margin: -2.75rem;
}

.-scroll-m-12 {
  scroll-margin: -3rem;
}

.-scroll-m-14 {
  scroll-margin: -3.5rem;
}

.-scroll-m-16 {
  scroll-margin: -4rem;
}

.-scroll-m-2 {
  scroll-margin: -.5rem;
}

.-scroll-m-2\.5 {
  scroll-margin: -.625rem;
}

.-scroll-m-20 {
  scroll-margin: -5rem;
}

.-scroll-m-24 {
  scroll-margin: -6rem;
}

.-scroll-m-28 {
  scroll-margin: -7rem;
}

.-scroll-m-3 {
  scroll-margin: -.75rem;
}

.-scroll-m-3\.5 {
  scroll-margin: -.875rem;
}

.-scroll-m-32 {
  scroll-margin: -8rem;
}

.-scroll-m-36 {
  scroll-margin: -9rem;
}

.-scroll-m-4 {
  scroll-margin: -1rem;
}

.-scroll-m-40 {
  scroll-margin: -10rem;
}

.-scroll-m-44 {
  scroll-margin: -11rem;
}

.-scroll-m-48 {
  scroll-margin: -12rem;
}

.-scroll-m-5 {
  scroll-margin: -1.25rem;
}

.-scroll-m-52 {
  scroll-margin: -13rem;
}

.-scroll-m-56 {
  scroll-margin: -14rem;
}

.-scroll-m-6 {
  scroll-margin: -1.5rem;
}

.-scroll-m-60 {
  scroll-margin: -15rem;
}

.-scroll-m-64 {
  scroll-margin: -16rem;
}

.-scroll-m-7 {
  scroll-margin: -1.75rem;
}

.-scroll-m-72 {
  scroll-margin: -18rem;
}

.-scroll-m-8 {
  scroll-margin: -2rem;
}

.-scroll-m-80 {
  scroll-margin: -20rem;
}

.-scroll-m-9 {
  scroll-margin: -2.25rem;
}

.-scroll-m-96 {
  scroll-margin: -24rem;
}

.-scroll-m-px {
  scroll-margin: -1px;
}

.scroll-m-0 {
  scroll-margin: 0;
}

.scroll-m-0\.5 {
  scroll-margin: .125rem;
}

.scroll-m-1 {
  scroll-margin: .25rem;
}

.scroll-m-1\.5 {
  scroll-margin: .375rem;
}

.scroll-m-10 {
  scroll-margin: 2.5rem;
}

.scroll-m-11 {
  scroll-margin: 2.75rem;
}

.scroll-m-12 {
  scroll-margin: 3rem;
}

.scroll-m-14 {
  scroll-margin: 3.5rem;
}

.scroll-m-16 {
  scroll-margin: 4rem;
}

.scroll-m-2 {
  scroll-margin: .5rem;
}

.scroll-m-2\.5 {
  scroll-margin: .625rem;
}

.scroll-m-20 {
  scroll-margin: 5rem;
}

.scroll-m-24 {
  scroll-margin: 6rem;
}

.scroll-m-28 {
  scroll-margin: 7rem;
}

.scroll-m-3 {
  scroll-margin: .75rem;
}

.scroll-m-3\.5 {
  scroll-margin: .875rem;
}

.scroll-m-32 {
  scroll-margin: 8rem;
}

.scroll-m-36 {
  scroll-margin: 9rem;
}

.scroll-m-4 {
  scroll-margin: 1rem;
}

.scroll-m-40 {
  scroll-margin: 10rem;
}

.scroll-m-44 {
  scroll-margin: 11rem;
}

.scroll-m-48 {
  scroll-margin: 12rem;
}

.scroll-m-5 {
  scroll-margin: 1.25rem;
}

.scroll-m-52 {
  scroll-margin: 13rem;
}

.scroll-m-56 {
  scroll-margin: 14rem;
}

.scroll-m-6 {
  scroll-margin: 1.5rem;
}

.scroll-m-60 {
  scroll-margin: 15rem;
}

.scroll-m-64 {
  scroll-margin: 16rem;
}

.scroll-m-7 {
  scroll-margin: 1.75rem;
}

.scroll-m-72 {
  scroll-margin: 18rem;
}

.scroll-m-8 {
  scroll-margin: 2rem;
}

.scroll-m-80 {
  scroll-margin: 20rem;
}

.scroll-m-9 {
  scroll-margin: 2.25rem;
}

.scroll-m-96 {
  scroll-margin: 24rem;
}

.scroll-m-px {
  scroll-margin: 1px;
}

.-scroll-mx-0 {
  scroll-margin-left: 0;
  scroll-margin-right: 0;
}

.-scroll-mx-0\.5 {
  scroll-margin-left: -.125rem;
  scroll-margin-right: -.125rem;
}

.-scroll-mx-1 {
  scroll-margin-left: -.25rem;
  scroll-margin-right: -.25rem;
}

.-scroll-mx-1\.5 {
  scroll-margin-left: -.375rem;
  scroll-margin-right: -.375rem;
}

.-scroll-mx-10 {
  scroll-margin-left: -2.5rem;
  scroll-margin-right: -2.5rem;
}

.-scroll-mx-11 {
  scroll-margin-left: -2.75rem;
  scroll-margin-right: -2.75rem;
}

.-scroll-mx-12 {
  scroll-margin-left: -3rem;
  scroll-margin-right: -3rem;
}

.-scroll-mx-14 {
  scroll-margin-left: -3.5rem;
  scroll-margin-right: -3.5rem;
}

.-scroll-mx-16 {
  scroll-margin-left: -4rem;
  scroll-margin-right: -4rem;
}

.-scroll-mx-2 {
  scroll-margin-left: -.5rem;
  scroll-margin-right: -.5rem;
}

.-scroll-mx-2\.5 {
  scroll-margin-left: -.625rem;
  scroll-margin-right: -.625rem;
}

.-scroll-mx-20 {
  scroll-margin-left: -5rem;
  scroll-margin-right: -5rem;
}

.-scroll-mx-24 {
  scroll-margin-left: -6rem;
  scroll-margin-right: -6rem;
}

.-scroll-mx-28 {
  scroll-margin-left: -7rem;
  scroll-margin-right: -7rem;
}

.-scroll-mx-3 {
  scroll-margin-left: -.75rem;
  scroll-margin-right: -.75rem;
}

.-scroll-mx-3\.5 {
  scroll-margin-left: -.875rem;
  scroll-margin-right: -.875rem;
}

.-scroll-mx-32 {
  scroll-margin-left: -8rem;
  scroll-margin-right: -8rem;
}

.-scroll-mx-36 {
  scroll-margin-left: -9rem;
  scroll-margin-right: -9rem;
}

.-scroll-mx-4 {
  scroll-margin-left: -1rem;
  scroll-margin-right: -1rem;
}

.-scroll-mx-40 {
  scroll-margin-left: -10rem;
  scroll-margin-right: -10rem;
}

.-scroll-mx-44 {
  scroll-margin-left: -11rem;
  scroll-margin-right: -11rem;
}

.-scroll-mx-48 {
  scroll-margin-left: -12rem;
  scroll-margin-right: -12rem;
}

.-scroll-mx-5 {
  scroll-margin-left: -1.25rem;
  scroll-margin-right: -1.25rem;
}

.-scroll-mx-52 {
  scroll-margin-left: -13rem;
  scroll-margin-right: -13rem;
}

.-scroll-mx-56 {
  scroll-margin-left: -14rem;
  scroll-margin-right: -14rem;
}

.-scroll-mx-6 {
  scroll-margin-left: -1.5rem;
  scroll-margin-right: -1.5rem;
}

.-scroll-mx-60 {
  scroll-margin-left: -15rem;
  scroll-margin-right: -15rem;
}

.-scroll-mx-64 {
  scroll-margin-left: -16rem;
  scroll-margin-right: -16rem;
}

.-scroll-mx-7 {
  scroll-margin-left: -1.75rem;
  scroll-margin-right: -1.75rem;
}

.-scroll-mx-72 {
  scroll-margin-left: -18rem;
  scroll-margin-right: -18rem;
}

.-scroll-mx-8 {
  scroll-margin-left: -2rem;
  scroll-margin-right: -2rem;
}

.-scroll-mx-80 {
  scroll-margin-left: -20rem;
  scroll-margin-right: -20rem;
}

.-scroll-mx-9 {
  scroll-margin-left: -2.25rem;
  scroll-margin-right: -2.25rem;
}

.-scroll-mx-96 {
  scroll-margin-left: -24rem;
  scroll-margin-right: -24rem;
}

.-scroll-mx-px {
  scroll-margin-left: -1px;
  scroll-margin-right: -1px;
}

.-scroll-my-0 {
  scroll-margin-top: 0;
  scroll-margin-bottom: 0;
}

.-scroll-my-0\.5 {
  scroll-margin-top: -.125rem;
  scroll-margin-bottom: -.125rem;
}

.-scroll-my-1 {
  scroll-margin-top: -.25rem;
  scroll-margin-bottom: -.25rem;
}

.-scroll-my-1\.5 {
  scroll-margin-top: -.375rem;
  scroll-margin-bottom: -.375rem;
}

.-scroll-my-10 {
  scroll-margin-top: -2.5rem;
  scroll-margin-bottom: -2.5rem;
}

.-scroll-my-11 {
  scroll-margin-top: -2.75rem;
  scroll-margin-bottom: -2.75rem;
}

.-scroll-my-12 {
  scroll-margin-top: -3rem;
  scroll-margin-bottom: -3rem;
}

.-scroll-my-14 {
  scroll-margin-top: -3.5rem;
  scroll-margin-bottom: -3.5rem;
}

.-scroll-my-16 {
  scroll-margin-top: -4rem;
  scroll-margin-bottom: -4rem;
}

.-scroll-my-2 {
  scroll-margin-top: -.5rem;
  scroll-margin-bottom: -.5rem;
}

.-scroll-my-2\.5 {
  scroll-margin-top: -.625rem;
  scroll-margin-bottom: -.625rem;
}

.-scroll-my-20 {
  scroll-margin-top: -5rem;
  scroll-margin-bottom: -5rem;
}

.-scroll-my-24 {
  scroll-margin-top: -6rem;
  scroll-margin-bottom: -6rem;
}

.-scroll-my-28 {
  scroll-margin-top: -7rem;
  scroll-margin-bottom: -7rem;
}

.-scroll-my-3 {
  scroll-margin-top: -.75rem;
  scroll-margin-bottom: -.75rem;
}

.-scroll-my-3\.5 {
  scroll-margin-top: -.875rem;
  scroll-margin-bottom: -.875rem;
}

.-scroll-my-32 {
  scroll-margin-top: -8rem;
  scroll-margin-bottom: -8rem;
}

.-scroll-my-36 {
  scroll-margin-top: -9rem;
  scroll-margin-bottom: -9rem;
}

.-scroll-my-4 {
  scroll-margin-top: -1rem;
  scroll-margin-bottom: -1rem;
}

.-scroll-my-40 {
  scroll-margin-top: -10rem;
  scroll-margin-bottom: -10rem;
}

.-scroll-my-44 {
  scroll-margin-top: -11rem;
  scroll-margin-bottom: -11rem;
}

.-scroll-my-48 {
  scroll-margin-top: -12rem;
  scroll-margin-bottom: -12rem;
}

.-scroll-my-5 {
  scroll-margin-top: -1.25rem;
  scroll-margin-bottom: -1.25rem;
}

.-scroll-my-52 {
  scroll-margin-top: -13rem;
  scroll-margin-bottom: -13rem;
}

.-scroll-my-56 {
  scroll-margin-top: -14rem;
  scroll-margin-bottom: -14rem;
}

.-scroll-my-6 {
  scroll-margin-top: -1.5rem;
  scroll-margin-bottom: -1.5rem;
}

.-scroll-my-60 {
  scroll-margin-top: -15rem;
  scroll-margin-bottom: -15rem;
}

.-scroll-my-64 {
  scroll-margin-top: -16rem;
  scroll-margin-bottom: -16rem;
}

.-scroll-my-7 {
  scroll-margin-top: -1.75rem;
  scroll-margin-bottom: -1.75rem;
}

.-scroll-my-72 {
  scroll-margin-top: -18rem;
  scroll-margin-bottom: -18rem;
}

.-scroll-my-8 {
  scroll-margin-top: -2rem;
  scroll-margin-bottom: -2rem;
}

.-scroll-my-80 {
  scroll-margin-top: -20rem;
  scroll-margin-bottom: -20rem;
}

.-scroll-my-9 {
  scroll-margin-top: -2.25rem;
  scroll-margin-bottom: -2.25rem;
}

.-scroll-my-96 {
  scroll-margin-top: -24rem;
  scroll-margin-bottom: -24rem;
}

.-scroll-my-px {
  scroll-margin-top: -1px;
  scroll-margin-bottom: -1px;
}

.scroll-mx-0 {
  scroll-margin-left: 0;
  scroll-margin-right: 0;
}

.scroll-mx-0\.5 {
  scroll-margin-left: .125rem;
  scroll-margin-right: .125rem;
}

.scroll-mx-1 {
  scroll-margin-left: .25rem;
  scroll-margin-right: .25rem;
}

.scroll-mx-1\.5 {
  scroll-margin-left: .375rem;
  scroll-margin-right: .375rem;
}

.scroll-mx-10 {
  scroll-margin-left: 2.5rem;
  scroll-margin-right: 2.5rem;
}

.scroll-mx-11 {
  scroll-margin-left: 2.75rem;
  scroll-margin-right: 2.75rem;
}

.scroll-mx-12 {
  scroll-margin-left: 3rem;
  scroll-margin-right: 3rem;
}

.scroll-mx-14 {
  scroll-margin-left: 3.5rem;
  scroll-margin-right: 3.5rem;
}

.scroll-mx-16 {
  scroll-margin-left: 4rem;
  scroll-margin-right: 4rem;
}

.scroll-mx-2 {
  scroll-margin-left: .5rem;
  scroll-margin-right: .5rem;
}

.scroll-mx-2\.5 {
  scroll-margin-left: .625rem;
  scroll-margin-right: .625rem;
}

.scroll-mx-20 {
  scroll-margin-left: 5rem;
  scroll-margin-right: 5rem;
}

.scroll-mx-24 {
  scroll-margin-left: 6rem;
  scroll-margin-right: 6rem;
}

.scroll-mx-28 {
  scroll-margin-left: 7rem;
  scroll-margin-right: 7rem;
}

.scroll-mx-3 {
  scroll-margin-left: .75rem;
  scroll-margin-right: .75rem;
}

.scroll-mx-3\.5 {
  scroll-margin-left: .875rem;
  scroll-margin-right: .875rem;
}

.scroll-mx-32 {
  scroll-margin-left: 8rem;
  scroll-margin-right: 8rem;
}

.scroll-mx-36 {
  scroll-margin-left: 9rem;
  scroll-margin-right: 9rem;
}

.scroll-mx-4 {
  scroll-margin-left: 1rem;
  scroll-margin-right: 1rem;
}

.scroll-mx-40 {
  scroll-margin-left: 10rem;
  scroll-margin-right: 10rem;
}

.scroll-mx-44 {
  scroll-margin-left: 11rem;
  scroll-margin-right: 11rem;
}

.scroll-mx-48 {
  scroll-margin-left: 12rem;
  scroll-margin-right: 12rem;
}

.scroll-mx-5 {
  scroll-margin-left: 1.25rem;
  scroll-margin-right: 1.25rem;
}

.scroll-mx-52 {
  scroll-margin-left: 13rem;
  scroll-margin-right: 13rem;
}

.scroll-mx-56 {
  scroll-margin-left: 14rem;
  scroll-margin-right: 14rem;
}

.scroll-mx-6 {
  scroll-margin-left: 1.5rem;
  scroll-margin-right: 1.5rem;
}

.scroll-mx-60 {
  scroll-margin-left: 15rem;
  scroll-margin-right: 15rem;
}

.scroll-mx-64 {
  scroll-margin-left: 16rem;
  scroll-margin-right: 16rem;
}

.scroll-mx-7 {
  scroll-margin-left: 1.75rem;
  scroll-margin-right: 1.75rem;
}

.scroll-mx-72 {
  scroll-margin-left: 18rem;
  scroll-margin-right: 18rem;
}

.scroll-mx-8 {
  scroll-margin-left: 2rem;
  scroll-margin-right: 2rem;
}

.scroll-mx-80 {
  scroll-margin-left: 20rem;
  scroll-margin-right: 20rem;
}

.scroll-mx-9 {
  scroll-margin-left: 2.25rem;
  scroll-margin-right: 2.25rem;
}

.scroll-mx-96 {
  scroll-margin-left: 24rem;
  scroll-margin-right: 24rem;
}

.scroll-mx-px {
  scroll-margin-left: 1px;
  scroll-margin-right: 1px;
}

.scroll-my-0 {
  scroll-margin-top: 0;
  scroll-margin-bottom: 0;
}

.scroll-my-0\.5 {
  scroll-margin-top: .125rem;
  scroll-margin-bottom: .125rem;
}

.scroll-my-1 {
  scroll-margin-top: .25rem;
  scroll-margin-bottom: .25rem;
}

.scroll-my-1\.5 {
  scroll-margin-top: .375rem;
  scroll-margin-bottom: .375rem;
}

.scroll-my-10 {
  scroll-margin-top: 2.5rem;
  scroll-margin-bottom: 2.5rem;
}

.scroll-my-11 {
  scroll-margin-top: 2.75rem;
  scroll-margin-bottom: 2.75rem;
}

.scroll-my-12 {
  scroll-margin-top: 3rem;
  scroll-margin-bottom: 3rem;
}

.scroll-my-14 {
  scroll-margin-top: 3.5rem;
  scroll-margin-bottom: 3.5rem;
}

.scroll-my-16 {
  scroll-margin-top: 4rem;
  scroll-margin-bottom: 4rem;
}

.scroll-my-2 {
  scroll-margin-top: .5rem;
  scroll-margin-bottom: .5rem;
}

.scroll-my-2\.5 {
  scroll-margin-top: .625rem;
  scroll-margin-bottom: .625rem;
}

.scroll-my-20 {
  scroll-margin-top: 5rem;
  scroll-margin-bottom: 5rem;
}

.scroll-my-24 {
  scroll-margin-top: 6rem;
  scroll-margin-bottom: 6rem;
}

.scroll-my-28 {
  scroll-margin-top: 7rem;
  scroll-margin-bottom: 7rem;
}

.scroll-my-3 {
  scroll-margin-top: .75rem;
  scroll-margin-bottom: .75rem;
}

.scroll-my-3\.5 {
  scroll-margin-top: .875rem;
  scroll-margin-bottom: .875rem;
}

.scroll-my-32 {
  scroll-margin-top: 8rem;
  scroll-margin-bottom: 8rem;
}

.scroll-my-36 {
  scroll-margin-top: 9rem;
  scroll-margin-bottom: 9rem;
}

.scroll-my-4 {
  scroll-margin-top: 1rem;
  scroll-margin-bottom: 1rem;
}

.scroll-my-40 {
  scroll-margin-top: 10rem;
  scroll-margin-bottom: 10rem;
}

.scroll-my-44 {
  scroll-margin-top: 11rem;
  scroll-margin-bottom: 11rem;
}

.scroll-my-48 {
  scroll-margin-top: 12rem;
  scroll-margin-bottom: 12rem;
}

.scroll-my-5 {
  scroll-margin-top: 1.25rem;
  scroll-margin-bottom: 1.25rem;
}

.scroll-my-52 {
  scroll-margin-top: 13rem;
  scroll-margin-bottom: 13rem;
}

.scroll-my-56 {
  scroll-margin-top: 14rem;
  scroll-margin-bottom: 14rem;
}

.scroll-my-6 {
  scroll-margin-top: 1.5rem;
  scroll-margin-bottom: 1.5rem;
}

.scroll-my-60 {
  scroll-margin-top: 15rem;
  scroll-margin-bottom: 15rem;
}

.scroll-my-64 {
  scroll-margin-top: 16rem;
  scroll-margin-bottom: 16rem;
}

.scroll-my-7 {
  scroll-margin-top: 1.75rem;
  scroll-margin-bottom: 1.75rem;
}

.scroll-my-72 {
  scroll-margin-top: 18rem;
  scroll-margin-bottom: 18rem;
}

.scroll-my-8 {
  scroll-margin-top: 2rem;
  scroll-margin-bottom: 2rem;
}

.scroll-my-80 {
  scroll-margin-top: 20rem;
  scroll-margin-bottom: 20rem;
}

.scroll-my-9 {
  scroll-margin-top: 2.25rem;
  scroll-margin-bottom: 2.25rem;
}

.scroll-my-96 {
  scroll-margin-top: 24rem;
  scroll-margin-bottom: 24rem;
}

.scroll-my-px {
  scroll-margin-top: 1px;
  scroll-margin-bottom: 1px;
}

.-scroll-mb-0 {
  scroll-margin-bottom: 0;
}

.-scroll-mb-0\.5 {
  scroll-margin-bottom: -.125rem;
}

.-scroll-mb-1 {
  scroll-margin-bottom: -.25rem;
}

.-scroll-mb-1\.5 {
  scroll-margin-bottom: -.375rem;
}

.-scroll-mb-10 {
  scroll-margin-bottom: -2.5rem;
}

.-scroll-mb-11 {
  scroll-margin-bottom: -2.75rem;
}

.-scroll-mb-12 {
  scroll-margin-bottom: -3rem;
}

.-scroll-mb-14 {
  scroll-margin-bottom: -3.5rem;
}

.-scroll-mb-16 {
  scroll-margin-bottom: -4rem;
}

.-scroll-mb-2 {
  scroll-margin-bottom: -.5rem;
}

.-scroll-mb-2\.5 {
  scroll-margin-bottom: -.625rem;
}

.-scroll-mb-20 {
  scroll-margin-bottom: -5rem;
}

.-scroll-mb-24 {
  scroll-margin-bottom: -6rem;
}

.-scroll-mb-28 {
  scroll-margin-bottom: -7rem;
}

.-scroll-mb-3 {
  scroll-margin-bottom: -.75rem;
}

.-scroll-mb-3\.5 {
  scroll-margin-bottom: -.875rem;
}

.-scroll-mb-32 {
  scroll-margin-bottom: -8rem;
}

.-scroll-mb-36 {
  scroll-margin-bottom: -9rem;
}

.-scroll-mb-4 {
  scroll-margin-bottom: -1rem;
}

.-scroll-mb-40 {
  scroll-margin-bottom: -10rem;
}

.-scroll-mb-44 {
  scroll-margin-bottom: -11rem;
}

.-scroll-mb-48 {
  scroll-margin-bottom: -12rem;
}

.-scroll-mb-5 {
  scroll-margin-bottom: -1.25rem;
}

.-scroll-mb-52 {
  scroll-margin-bottom: -13rem;
}

.-scroll-mb-56 {
  scroll-margin-bottom: -14rem;
}

.-scroll-mb-6 {
  scroll-margin-bottom: -1.5rem;
}

.-scroll-mb-60 {
  scroll-margin-bottom: -15rem;
}

.-scroll-mb-64 {
  scroll-margin-bottom: -16rem;
}

.-scroll-mb-7 {
  scroll-margin-bottom: -1.75rem;
}

.-scroll-mb-72 {
  scroll-margin-bottom: -18rem;
}

.-scroll-mb-8 {
  scroll-margin-bottom: -2rem;
}

.-scroll-mb-80 {
  scroll-margin-bottom: -20rem;
}

.-scroll-mb-9 {
  scroll-margin-bottom: -2.25rem;
}

.-scroll-mb-96 {
  scroll-margin-bottom: -24rem;
}

.-scroll-mb-px {
  scroll-margin-bottom: -1px;
}

.-scroll-me-0 {
  scroll-margin-inline-end: 0;
}

.-scroll-me-0\.5 {
  scroll-margin-inline-end: -.125rem;
}

.-scroll-me-1 {
  scroll-margin-inline-end: -.25rem;
}

.-scroll-me-1\.5 {
  scroll-margin-inline-end: -.375rem;
}

.-scroll-me-10 {
  scroll-margin-inline-end: -2.5rem;
}

.-scroll-me-11 {
  scroll-margin-inline-end: -2.75rem;
}

.-scroll-me-12 {
  scroll-margin-inline-end: -3rem;
}

.-scroll-me-14 {
  scroll-margin-inline-end: -3.5rem;
}

.-scroll-me-16 {
  scroll-margin-inline-end: -4rem;
}

.-scroll-me-2 {
  scroll-margin-inline-end: -.5rem;
}

.-scroll-me-2\.5 {
  scroll-margin-inline-end: -.625rem;
}

.-scroll-me-20 {
  scroll-margin-inline-end: -5rem;
}

.-scroll-me-24 {
  scroll-margin-inline-end: -6rem;
}

.-scroll-me-28 {
  scroll-margin-inline-end: -7rem;
}

.-scroll-me-3 {
  scroll-margin-inline-end: -.75rem;
}

.-scroll-me-3\.5 {
  scroll-margin-inline-end: -.875rem;
}

.-scroll-me-32 {
  scroll-margin-inline-end: -8rem;
}

.-scroll-me-36 {
  scroll-margin-inline-end: -9rem;
}

.-scroll-me-4 {
  scroll-margin-inline-end: -1rem;
}

.-scroll-me-40 {
  scroll-margin-inline-end: -10rem;
}

.-scroll-me-44 {
  scroll-margin-inline-end: -11rem;
}

.-scroll-me-48 {
  scroll-margin-inline-end: -12rem;
}

.-scroll-me-5 {
  scroll-margin-inline-end: -1.25rem;
}

.-scroll-me-52 {
  scroll-margin-inline-end: -13rem;
}

.-scroll-me-56 {
  scroll-margin-inline-end: -14rem;
}

.-scroll-me-6 {
  scroll-margin-inline-end: -1.5rem;
}

.-scroll-me-60 {
  scroll-margin-inline-end: -15rem;
}

.-scroll-me-64 {
  scroll-margin-inline-end: -16rem;
}

.-scroll-me-7 {
  scroll-margin-inline-end: -1.75rem;
}

.-scroll-me-72 {
  scroll-margin-inline-end: -18rem;
}

.-scroll-me-8 {
  scroll-margin-inline-end: -2rem;
}

.-scroll-me-80 {
  scroll-margin-inline-end: -20rem;
}

.-scroll-me-9 {
  scroll-margin-inline-end: -2.25rem;
}

.-scroll-me-96 {
  scroll-margin-inline-end: -24rem;
}

.-scroll-me-px {
  scroll-margin-inline-end: -1px;
}

.-scroll-ml-0 {
  scroll-margin-left: 0;
}

.-scroll-ml-0\.5 {
  scroll-margin-left: -.125rem;
}

.-scroll-ml-1 {
  scroll-margin-left: -.25rem;
}

.-scroll-ml-1\.5 {
  scroll-margin-left: -.375rem;
}

.-scroll-ml-10 {
  scroll-margin-left: -2.5rem;
}

.-scroll-ml-11 {
  scroll-margin-left: -2.75rem;
}

.-scroll-ml-12 {
  scroll-margin-left: -3rem;
}

.-scroll-ml-14 {
  scroll-margin-left: -3.5rem;
}

.-scroll-ml-16 {
  scroll-margin-left: -4rem;
}

.-scroll-ml-2 {
  scroll-margin-left: -.5rem;
}

.-scroll-ml-2\.5 {
  scroll-margin-left: -.625rem;
}

.-scroll-ml-20 {
  scroll-margin-left: -5rem;
}

.-scroll-ml-24 {
  scroll-margin-left: -6rem;
}

.-scroll-ml-28 {
  scroll-margin-left: -7rem;
}

.-scroll-ml-3 {
  scroll-margin-left: -.75rem;
}

.-scroll-ml-3\.5 {
  scroll-margin-left: -.875rem;
}

.-scroll-ml-32 {
  scroll-margin-left: -8rem;
}

.-scroll-ml-36 {
  scroll-margin-left: -9rem;
}

.-scroll-ml-4 {
  scroll-margin-left: -1rem;
}

.-scroll-ml-40 {
  scroll-margin-left: -10rem;
}

.-scroll-ml-44 {
  scroll-margin-left: -11rem;
}

.-scroll-ml-48 {
  scroll-margin-left: -12rem;
}

.-scroll-ml-5 {
  scroll-margin-left: -1.25rem;
}

.-scroll-ml-52 {
  scroll-margin-left: -13rem;
}

.-scroll-ml-56 {
  scroll-margin-left: -14rem;
}

.-scroll-ml-6 {
  scroll-margin-left: -1.5rem;
}

.-scroll-ml-60 {
  scroll-margin-left: -15rem;
}

.-scroll-ml-64 {
  scroll-margin-left: -16rem;
}

.-scroll-ml-7 {
  scroll-margin-left: -1.75rem;
}

.-scroll-ml-72 {
  scroll-margin-left: -18rem;
}

.-scroll-ml-8 {
  scroll-margin-left: -2rem;
}

.-scroll-ml-80 {
  scroll-margin-left: -20rem;
}

.-scroll-ml-9 {
  scroll-margin-left: -2.25rem;
}

.-scroll-ml-96 {
  scroll-margin-left: -24rem;
}

.-scroll-ml-px {
  scroll-margin-left: -1px;
}

.-scroll-mr-0 {
  scroll-margin-right: 0;
}

.-scroll-mr-0\.5 {
  scroll-margin-right: -.125rem;
}

.-scroll-mr-1 {
  scroll-margin-right: -.25rem;
}

.-scroll-mr-1\.5 {
  scroll-margin-right: -.375rem;
}

.-scroll-mr-10 {
  scroll-margin-right: -2.5rem;
}

.-scroll-mr-11 {
  scroll-margin-right: -2.75rem;
}

.-scroll-mr-12 {
  scroll-margin-right: -3rem;
}

.-scroll-mr-14 {
  scroll-margin-right: -3.5rem;
}

.-scroll-mr-16 {
  scroll-margin-right: -4rem;
}

.-scroll-mr-2 {
  scroll-margin-right: -.5rem;
}

.-scroll-mr-2\.5 {
  scroll-margin-right: -.625rem;
}

.-scroll-mr-20 {
  scroll-margin-right: -5rem;
}

.-scroll-mr-24 {
  scroll-margin-right: -6rem;
}

.-scroll-mr-28 {
  scroll-margin-right: -7rem;
}

.-scroll-mr-3 {
  scroll-margin-right: -.75rem;
}

.-scroll-mr-3\.5 {
  scroll-margin-right: -.875rem;
}

.-scroll-mr-32 {
  scroll-margin-right: -8rem;
}

.-scroll-mr-36 {
  scroll-margin-right: -9rem;
}

.-scroll-mr-4 {
  scroll-margin-right: -1rem;
}

.-scroll-mr-40 {
  scroll-margin-right: -10rem;
}

.-scroll-mr-44 {
  scroll-margin-right: -11rem;
}

.-scroll-mr-48 {
  scroll-margin-right: -12rem;
}

.-scroll-mr-5 {
  scroll-margin-right: -1.25rem;
}

.-scroll-mr-52 {
  scroll-margin-right: -13rem;
}

.-scroll-mr-56 {
  scroll-margin-right: -14rem;
}

.-scroll-mr-6 {
  scroll-margin-right: -1.5rem;
}

.-scroll-mr-60 {
  scroll-margin-right: -15rem;
}

.-scroll-mr-64 {
  scroll-margin-right: -16rem;
}

.-scroll-mr-7 {
  scroll-margin-right: -1.75rem;
}

.-scroll-mr-72 {
  scroll-margin-right: -18rem;
}

.-scroll-mr-8 {
  scroll-margin-right: -2rem;
}

.-scroll-mr-80 {
  scroll-margin-right: -20rem;
}

.-scroll-mr-9 {
  scroll-margin-right: -2.25rem;
}

.-scroll-mr-96 {
  scroll-margin-right: -24rem;
}

.-scroll-mr-px {
  scroll-margin-right: -1px;
}

.-scroll-ms-0 {
  scroll-margin-inline-start: 0;
}

.-scroll-ms-0\.5 {
  scroll-margin-inline-start: -.125rem;
}

.-scroll-ms-1 {
  scroll-margin-inline-start: -.25rem;
}

.-scroll-ms-1\.5 {
  scroll-margin-inline-start: -.375rem;
}

.-scroll-ms-10 {
  scroll-margin-inline-start: -2.5rem;
}

.-scroll-ms-11 {
  scroll-margin-inline-start: -2.75rem;
}

.-scroll-ms-12 {
  scroll-margin-inline-start: -3rem;
}

.-scroll-ms-14 {
  scroll-margin-inline-start: -3.5rem;
}

.-scroll-ms-16 {
  scroll-margin-inline-start: -4rem;
}

.-scroll-ms-2 {
  scroll-margin-inline-start: -.5rem;
}

.-scroll-ms-2\.5 {
  scroll-margin-inline-start: -.625rem;
}

.-scroll-ms-20 {
  scroll-margin-inline-start: -5rem;
}

.-scroll-ms-24 {
  scroll-margin-inline-start: -6rem;
}

.-scroll-ms-28 {
  scroll-margin-inline-start: -7rem;
}

.-scroll-ms-3 {
  scroll-margin-inline-start: -.75rem;
}

.-scroll-ms-3\.5 {
  scroll-margin-inline-start: -.875rem;
}

.-scroll-ms-32 {
  scroll-margin-inline-start: -8rem;
}

.-scroll-ms-36 {
  scroll-margin-inline-start: -9rem;
}

.-scroll-ms-4 {
  scroll-margin-inline-start: -1rem;
}

.-scroll-ms-40 {
  scroll-margin-inline-start: -10rem;
}

.-scroll-ms-44 {
  scroll-margin-inline-start: -11rem;
}

.-scroll-ms-48 {
  scroll-margin-inline-start: -12rem;
}

.-scroll-ms-5 {
  scroll-margin-inline-start: -1.25rem;
}

.-scroll-ms-52 {
  scroll-margin-inline-start: -13rem;
}

.-scroll-ms-56 {
  scroll-margin-inline-start: -14rem;
}

.-scroll-ms-6 {
  scroll-margin-inline-start: -1.5rem;
}

.-scroll-ms-60 {
  scroll-margin-inline-start: -15rem;
}

.-scroll-ms-64 {
  scroll-margin-inline-start: -16rem;
}

.-scroll-ms-7 {
  scroll-margin-inline-start: -1.75rem;
}

.-scroll-ms-72 {
  scroll-margin-inline-start: -18rem;
}

.-scroll-ms-8 {
  scroll-margin-inline-start: -2rem;
}

.-scroll-ms-80 {
  scroll-margin-inline-start: -20rem;
}

.-scroll-ms-9 {
  scroll-margin-inline-start: -2.25rem;
}

.-scroll-ms-96 {
  scroll-margin-inline-start: -24rem;
}

.-scroll-ms-px {
  scroll-margin-inline-start: -1px;
}

.-scroll-mt-0 {
  scroll-margin-top: 0;
}

.-scroll-mt-0\.5 {
  scroll-margin-top: -.125rem;
}

.-scroll-mt-1 {
  scroll-margin-top: -.25rem;
}

.-scroll-mt-1\.5 {
  scroll-margin-top: -.375rem;
}

.-scroll-mt-10 {
  scroll-margin-top: -2.5rem;
}

.-scroll-mt-11 {
  scroll-margin-top: -2.75rem;
}

.-scroll-mt-12 {
  scroll-margin-top: -3rem;
}

.-scroll-mt-14 {
  scroll-margin-top: -3.5rem;
}

.-scroll-mt-16 {
  scroll-margin-top: -4rem;
}

.-scroll-mt-2 {
  scroll-margin-top: -.5rem;
}

.-scroll-mt-2\.5 {
  scroll-margin-top: -.625rem;
}

.-scroll-mt-20 {
  scroll-margin-top: -5rem;
}

.-scroll-mt-24 {
  scroll-margin-top: -6rem;
}

.-scroll-mt-28 {
  scroll-margin-top: -7rem;
}

.-scroll-mt-3 {
  scroll-margin-top: -.75rem;
}

.-scroll-mt-3\.5 {
  scroll-margin-top: -.875rem;
}

.-scroll-mt-32 {
  scroll-margin-top: -8rem;
}

.-scroll-mt-36 {
  scroll-margin-top: -9rem;
}

.-scroll-mt-4 {
  scroll-margin-top: -1rem;
}

.-scroll-mt-40 {
  scroll-margin-top: -10rem;
}

.-scroll-mt-44 {
  scroll-margin-top: -11rem;
}

.-scroll-mt-48 {
  scroll-margin-top: -12rem;
}

.-scroll-mt-5 {
  scroll-margin-top: -1.25rem;
}

.-scroll-mt-52 {
  scroll-margin-top: -13rem;
}

.-scroll-mt-56 {
  scroll-margin-top: -14rem;
}

.-scroll-mt-6 {
  scroll-margin-top: -1.5rem;
}

.-scroll-mt-60 {
  scroll-margin-top: -15rem;
}

.-scroll-mt-64 {
  scroll-margin-top: -16rem;
}

.-scroll-mt-7 {
  scroll-margin-top: -1.75rem;
}

.-scroll-mt-72 {
  scroll-margin-top: -18rem;
}

.-scroll-mt-8 {
  scroll-margin-top: -2rem;
}

.-scroll-mt-80 {
  scroll-margin-top: -20rem;
}

.-scroll-mt-9 {
  scroll-margin-top: -2.25rem;
}

.-scroll-mt-96 {
  scroll-margin-top: -24rem;
}

.-scroll-mt-px {
  scroll-margin-top: -1px;
}

.scroll-mb-0 {
  scroll-margin-bottom: 0;
}

.scroll-mb-0\.5 {
  scroll-margin-bottom: .125rem;
}

.scroll-mb-1 {
  scroll-margin-bottom: .25rem;
}

.scroll-mb-1\.5 {
  scroll-margin-bottom: .375rem;
}

.scroll-mb-10 {
  scroll-margin-bottom: 2.5rem;
}

.scroll-mb-11 {
  scroll-margin-bottom: 2.75rem;
}

.scroll-mb-12 {
  scroll-margin-bottom: 3rem;
}

.scroll-mb-14 {
  scroll-margin-bottom: 3.5rem;
}

.scroll-mb-16 {
  scroll-margin-bottom: 4rem;
}

.scroll-mb-2 {
  scroll-margin-bottom: .5rem;
}

.scroll-mb-2\.5 {
  scroll-margin-bottom: .625rem;
}

.scroll-mb-20 {
  scroll-margin-bottom: 5rem;
}

.scroll-mb-24 {
  scroll-margin-bottom: 6rem;
}

.scroll-mb-28 {
  scroll-margin-bottom: 7rem;
}

.scroll-mb-3 {
  scroll-margin-bottom: .75rem;
}

.scroll-mb-3\.5 {
  scroll-margin-bottom: .875rem;
}

.scroll-mb-32 {
  scroll-margin-bottom: 8rem;
}

.scroll-mb-36 {
  scroll-margin-bottom: 9rem;
}

.scroll-mb-4 {
  scroll-margin-bottom: 1rem;
}

.scroll-mb-40 {
  scroll-margin-bottom: 10rem;
}

.scroll-mb-44 {
  scroll-margin-bottom: 11rem;
}

.scroll-mb-48 {
  scroll-margin-bottom: 12rem;
}

.scroll-mb-5 {
  scroll-margin-bottom: 1.25rem;
}

.scroll-mb-52 {
  scroll-margin-bottom: 13rem;
}

.scroll-mb-56 {
  scroll-margin-bottom: 14rem;
}

.scroll-mb-6 {
  scroll-margin-bottom: 1.5rem;
}

.scroll-mb-60 {
  scroll-margin-bottom: 15rem;
}

.scroll-mb-64 {
  scroll-margin-bottom: 16rem;
}

.scroll-mb-7 {
  scroll-margin-bottom: 1.75rem;
}

.scroll-mb-72 {
  scroll-margin-bottom: 18rem;
}

.scroll-mb-8 {
  scroll-margin-bottom: 2rem;
}

.scroll-mb-80 {
  scroll-margin-bottom: 20rem;
}

.scroll-mb-9 {
  scroll-margin-bottom: 2.25rem;
}

.scroll-mb-96 {
  scroll-margin-bottom: 24rem;
}

.scroll-mb-px {
  scroll-margin-bottom: 1px;
}

.scroll-me-0 {
  scroll-margin-inline-end: 0;
}

.scroll-me-0\.5 {
  scroll-margin-inline-end: .125rem;
}

.scroll-me-1 {
  scroll-margin-inline-end: .25rem;
}

.scroll-me-1\.5 {
  scroll-margin-inline-end: .375rem;
}

.scroll-me-10 {
  scroll-margin-inline-end: 2.5rem;
}

.scroll-me-11 {
  scroll-margin-inline-end: 2.75rem;
}

.scroll-me-12 {
  scroll-margin-inline-end: 3rem;
}

.scroll-me-14 {
  scroll-margin-inline-end: 3.5rem;
}

.scroll-me-16 {
  scroll-margin-inline-end: 4rem;
}

.scroll-me-2 {
  scroll-margin-inline-end: .5rem;
}

.scroll-me-2\.5 {
  scroll-margin-inline-end: .625rem;
}

.scroll-me-20 {
  scroll-margin-inline-end: 5rem;
}

.scroll-me-24 {
  scroll-margin-inline-end: 6rem;
}

.scroll-me-28 {
  scroll-margin-inline-end: 7rem;
}

.scroll-me-3 {
  scroll-margin-inline-end: .75rem;
}

.scroll-me-3\.5 {
  scroll-margin-inline-end: .875rem;
}

.scroll-me-32 {
  scroll-margin-inline-end: 8rem;
}

.scroll-me-36 {
  scroll-margin-inline-end: 9rem;
}

.scroll-me-4 {
  scroll-margin-inline-end: 1rem;
}

.scroll-me-40 {
  scroll-margin-inline-end: 10rem;
}

.scroll-me-44 {
  scroll-margin-inline-end: 11rem;
}

.scroll-me-48 {
  scroll-margin-inline-end: 12rem;
}

.scroll-me-5 {
  scroll-margin-inline-end: 1.25rem;
}

.scroll-me-52 {
  scroll-margin-inline-end: 13rem;
}

.scroll-me-56 {
  scroll-margin-inline-end: 14rem;
}

.scroll-me-6 {
  scroll-margin-inline-end: 1.5rem;
}

.scroll-me-60 {
  scroll-margin-inline-end: 15rem;
}

.scroll-me-64 {
  scroll-margin-inline-end: 16rem;
}

.scroll-me-7 {
  scroll-margin-inline-end: 1.75rem;
}

.scroll-me-72 {
  scroll-margin-inline-end: 18rem;
}

.scroll-me-8 {
  scroll-margin-inline-end: 2rem;
}

.scroll-me-80 {
  scroll-margin-inline-end: 20rem;
}

.scroll-me-9 {
  scroll-margin-inline-end: 2.25rem;
}

.scroll-me-96 {
  scroll-margin-inline-end: 24rem;
}

.scroll-me-px {
  scroll-margin-inline-end: 1px;
}

.scroll-ml-0 {
  scroll-margin-left: 0;
}

.scroll-ml-0\.5 {
  scroll-margin-left: .125rem;
}

.scroll-ml-1 {
  scroll-margin-left: .25rem;
}

.scroll-ml-1\.5 {
  scroll-margin-left: .375rem;
}

.scroll-ml-10 {
  scroll-margin-left: 2.5rem;
}

.scroll-ml-11 {
  scroll-margin-left: 2.75rem;
}

.scroll-ml-12 {
  scroll-margin-left: 3rem;
}

.scroll-ml-14 {
  scroll-margin-left: 3.5rem;
}

.scroll-ml-16 {
  scroll-margin-left: 4rem;
}

.scroll-ml-2 {
  scroll-margin-left: .5rem;
}

.scroll-ml-2\.5 {
  scroll-margin-left: .625rem;
}

.scroll-ml-20 {
  scroll-margin-left: 5rem;
}

.scroll-ml-24 {
  scroll-margin-left: 6rem;
}

.scroll-ml-28 {
  scroll-margin-left: 7rem;
}

.scroll-ml-3 {
  scroll-margin-left: .75rem;
}

.scroll-ml-3\.5 {
  scroll-margin-left: .875rem;
}

.scroll-ml-32 {
  scroll-margin-left: 8rem;
}

.scroll-ml-36 {
  scroll-margin-left: 9rem;
}

.scroll-ml-4 {
  scroll-margin-left: 1rem;
}

.scroll-ml-40 {
  scroll-margin-left: 10rem;
}

.scroll-ml-44 {
  scroll-margin-left: 11rem;
}

.scroll-ml-48 {
  scroll-margin-left: 12rem;
}

.scroll-ml-5 {
  scroll-margin-left: 1.25rem;
}

.scroll-ml-52 {
  scroll-margin-left: 13rem;
}

.scroll-ml-56 {
  scroll-margin-left: 14rem;
}

.scroll-ml-6 {
  scroll-margin-left: 1.5rem;
}

.scroll-ml-60 {
  scroll-margin-left: 15rem;
}

.scroll-ml-64 {
  scroll-margin-left: 16rem;
}

.scroll-ml-7 {
  scroll-margin-left: 1.75rem;
}

.scroll-ml-72 {
  scroll-margin-left: 18rem;
}

.scroll-ml-8 {
  scroll-margin-left: 2rem;
}

.scroll-ml-80 {
  scroll-margin-left: 20rem;
}

.scroll-ml-9 {
  scroll-margin-left: 2.25rem;
}

.scroll-ml-96 {
  scroll-margin-left: 24rem;
}

.scroll-ml-px {
  scroll-margin-left: 1px;
}

.scroll-mr-0 {
  scroll-margin-right: 0;
}

.scroll-mr-0\.5 {
  scroll-margin-right: .125rem;
}

.scroll-mr-1 {
  scroll-margin-right: .25rem;
}

.scroll-mr-1\.5 {
  scroll-margin-right: .375rem;
}

.scroll-mr-10 {
  scroll-margin-right: 2.5rem;
}

.scroll-mr-11 {
  scroll-margin-right: 2.75rem;
}

.scroll-mr-12 {
  scroll-margin-right: 3rem;
}

.scroll-mr-14 {
  scroll-margin-right: 3.5rem;
}

.scroll-mr-16 {
  scroll-margin-right: 4rem;
}

.scroll-mr-2 {
  scroll-margin-right: .5rem;
}

.scroll-mr-2\.5 {
  scroll-margin-right: .625rem;
}

.scroll-mr-20 {
  scroll-margin-right: 5rem;
}

.scroll-mr-24 {
  scroll-margin-right: 6rem;
}

.scroll-mr-28 {
  scroll-margin-right: 7rem;
}

.scroll-mr-3 {
  scroll-margin-right: .75rem;
}

.scroll-mr-3\.5 {
  scroll-margin-right: .875rem;
}

.scroll-mr-32 {
  scroll-margin-right: 8rem;
}

.scroll-mr-36 {
  scroll-margin-right: 9rem;
}

.scroll-mr-4 {
  scroll-margin-right: 1rem;
}

.scroll-mr-40 {
  scroll-margin-right: 10rem;
}

.scroll-mr-44 {
  scroll-margin-right: 11rem;
}

.scroll-mr-48 {
  scroll-margin-right: 12rem;
}

.scroll-mr-5 {
  scroll-margin-right: 1.25rem;
}

.scroll-mr-52 {
  scroll-margin-right: 13rem;
}

.scroll-mr-56 {
  scroll-margin-right: 14rem;
}

.scroll-mr-6 {
  scroll-margin-right: 1.5rem;
}

.scroll-mr-60 {
  scroll-margin-right: 15rem;
}

.scroll-mr-64 {
  scroll-margin-right: 16rem;
}

.scroll-mr-7 {
  scroll-margin-right: 1.75rem;
}

.scroll-mr-72 {
  scroll-margin-right: 18rem;
}

.scroll-mr-8 {
  scroll-margin-right: 2rem;
}

.scroll-mr-80 {
  scroll-margin-right: 20rem;
}

.scroll-mr-9 {
  scroll-margin-right: 2.25rem;
}

.scroll-mr-96 {
  scroll-margin-right: 24rem;
}

.scroll-mr-px {
  scroll-margin-right: 1px;
}

.scroll-ms-0 {
  scroll-margin-inline-start: 0;
}

.scroll-ms-0\.5 {
  scroll-margin-inline-start: .125rem;
}

.scroll-ms-1 {
  scroll-margin-inline-start: .25rem;
}

.scroll-ms-1\.5 {
  scroll-margin-inline-start: .375rem;
}

.scroll-ms-10 {
  scroll-margin-inline-start: 2.5rem;
}

.scroll-ms-11 {
  scroll-margin-inline-start: 2.75rem;
}

.scroll-ms-12 {
  scroll-margin-inline-start: 3rem;
}

.scroll-ms-14 {
  scroll-margin-inline-start: 3.5rem;
}

.scroll-ms-16 {
  scroll-margin-inline-start: 4rem;
}

.scroll-ms-2 {
  scroll-margin-inline-start: .5rem;
}

.scroll-ms-2\.5 {
  scroll-margin-inline-start: .625rem;
}

.scroll-ms-20 {
  scroll-margin-inline-start: 5rem;
}

.scroll-ms-24 {
  scroll-margin-inline-start: 6rem;
}

.scroll-ms-28 {
  scroll-margin-inline-start: 7rem;
}

.scroll-ms-3 {
  scroll-margin-inline-start: .75rem;
}

.scroll-ms-3\.5 {
  scroll-margin-inline-start: .875rem;
}

.scroll-ms-32 {
  scroll-margin-inline-start: 8rem;
}

.scroll-ms-36 {
  scroll-margin-inline-start: 9rem;
}

.scroll-ms-4 {
  scroll-margin-inline-start: 1rem;
}

.scroll-ms-40 {
  scroll-margin-inline-start: 10rem;
}

.scroll-ms-44 {
  scroll-margin-inline-start: 11rem;
}

.scroll-ms-48 {
  scroll-margin-inline-start: 12rem;
}

.scroll-ms-5 {
  scroll-margin-inline-start: 1.25rem;
}

.scroll-ms-52 {
  scroll-margin-inline-start: 13rem;
}

.scroll-ms-56 {
  scroll-margin-inline-start: 14rem;
}

.scroll-ms-6 {
  scroll-margin-inline-start: 1.5rem;
}

.scroll-ms-60 {
  scroll-margin-inline-start: 15rem;
}

.scroll-ms-64 {
  scroll-margin-inline-start: 16rem;
}

.scroll-ms-7 {
  scroll-margin-inline-start: 1.75rem;
}

.scroll-ms-72 {
  scroll-margin-inline-start: 18rem;
}

.scroll-ms-8 {
  scroll-margin-inline-start: 2rem;
}

.scroll-ms-80 {
  scroll-margin-inline-start: 20rem;
}

.scroll-ms-9 {
  scroll-margin-inline-start: 2.25rem;
}

.scroll-ms-96 {
  scroll-margin-inline-start: 24rem;
}

.scroll-ms-px {
  scroll-margin-inline-start: 1px;
}

.scroll-mt-0 {
  scroll-margin-top: 0;
}

.scroll-mt-0\.5 {
  scroll-margin-top: .125rem;
}

.scroll-mt-1 {
  scroll-margin-top: .25rem;
}

.scroll-mt-1\.5 {
  scroll-margin-top: .375rem;
}

.scroll-mt-10 {
  scroll-margin-top: 2.5rem;
}

.scroll-mt-11 {
  scroll-margin-top: 2.75rem;
}

.scroll-mt-12 {
  scroll-margin-top: 3rem;
}

.scroll-mt-14 {
  scroll-margin-top: 3.5rem;
}

.scroll-mt-16 {
  scroll-margin-top: 4rem;
}

.scroll-mt-2 {
  scroll-margin-top: .5rem;
}

.scroll-mt-2\.5 {
  scroll-margin-top: .625rem;
}

.scroll-mt-20 {
  scroll-margin-top: 5rem;
}

.scroll-mt-24 {
  scroll-margin-top: 6rem;
}

.scroll-mt-28 {
  scroll-margin-top: 7rem;
}

.scroll-mt-3 {
  scroll-margin-top: .75rem;
}

.scroll-mt-3\.5 {
  scroll-margin-top: .875rem;
}

.scroll-mt-32 {
  scroll-margin-top: 8rem;
}

.scroll-mt-36 {
  scroll-margin-top: 9rem;
}

.scroll-mt-4 {
  scroll-margin-top: 1rem;
}

.scroll-mt-40 {
  scroll-margin-top: 10rem;
}

.scroll-mt-44 {
  scroll-margin-top: 11rem;
}

.scroll-mt-48 {
  scroll-margin-top: 12rem;
}

.scroll-mt-5 {
  scroll-margin-top: 1.25rem;
}

.scroll-mt-52 {
  scroll-margin-top: 13rem;
}

.scroll-mt-56 {
  scroll-margin-top: 14rem;
}

.scroll-mt-6 {
  scroll-margin-top: 1.5rem;
}

.scroll-mt-60 {
  scroll-margin-top: 15rem;
}

.scroll-mt-64 {
  scroll-margin-top: 16rem;
}

.scroll-mt-7 {
  scroll-margin-top: 1.75rem;
}

.scroll-mt-72 {
  scroll-margin-top: 18rem;
}

.scroll-mt-8 {
  scroll-margin-top: 2rem;
}

.scroll-mt-80 {
  scroll-margin-top: 20rem;
}

.scroll-mt-9 {
  scroll-margin-top: 2.25rem;
}

.scroll-mt-96 {
  scroll-margin-top: 24rem;
}

.scroll-mt-px {
  scroll-margin-top: 1px;
}

.scroll-p-0 {
  scroll-padding: 0;
}

.scroll-p-0\.5 {
  scroll-padding: .125rem;
}

.scroll-p-1 {
  scroll-padding: .25rem;
}

.scroll-p-1\.5 {
  scroll-padding: .375rem;
}

.scroll-p-10 {
  scroll-padding: 2.5rem;
}

.scroll-p-11 {
  scroll-padding: 2.75rem;
}

.scroll-p-12 {
  scroll-padding: 3rem;
}

.scroll-p-14 {
  scroll-padding: 3.5rem;
}

.scroll-p-16 {
  scroll-padding: 4rem;
}

.scroll-p-2 {
  scroll-padding: .5rem;
}

.scroll-p-2\.5 {
  scroll-padding: .625rem;
}

.scroll-p-20 {
  scroll-padding: 5rem;
}

.scroll-p-24 {
  scroll-padding: 6rem;
}

.scroll-p-28 {
  scroll-padding: 7rem;
}

.scroll-p-3 {
  scroll-padding: .75rem;
}

.scroll-p-3\.5 {
  scroll-padding: .875rem;
}

.scroll-p-32 {
  scroll-padding: 8rem;
}

.scroll-p-36 {
  scroll-padding: 9rem;
}

.scroll-p-4 {
  scroll-padding: 1rem;
}

.scroll-p-40 {
  scroll-padding: 10rem;
}

.scroll-p-44 {
  scroll-padding: 11rem;
}

.scroll-p-48 {
  scroll-padding: 12rem;
}

.scroll-p-5 {
  scroll-padding: 1.25rem;
}

.scroll-p-52 {
  scroll-padding: 13rem;
}

.scroll-p-56 {
  scroll-padding: 14rem;
}

.scroll-p-6 {
  scroll-padding: 1.5rem;
}

.scroll-p-60 {
  scroll-padding: 15rem;
}

.scroll-p-64 {
  scroll-padding: 16rem;
}

.scroll-p-7 {
  scroll-padding: 1.75rem;
}

.scroll-p-72 {
  scroll-padding: 18rem;
}

.scroll-p-8 {
  scroll-padding: 2rem;
}

.scroll-p-80 {
  scroll-padding: 20rem;
}

.scroll-p-9 {
  scroll-padding: 2.25rem;
}

.scroll-p-96 {
  scroll-padding: 24rem;
}

.scroll-p-px {
  scroll-padding: 1px;
}

.scroll-px-0 {
  scroll-padding-left: 0;
  scroll-padding-right: 0;
}

.scroll-px-0\.5 {
  scroll-padding-left: .125rem;
  scroll-padding-right: .125rem;
}

.scroll-px-1 {
  scroll-padding-left: .25rem;
  scroll-padding-right: .25rem;
}

.scroll-px-1\.5 {
  scroll-padding-left: .375rem;
  scroll-padding-right: .375rem;
}

.scroll-px-10 {
  scroll-padding-left: 2.5rem;
  scroll-padding-right: 2.5rem;
}

.scroll-px-11 {
  scroll-padding-left: 2.75rem;
  scroll-padding-right: 2.75rem;
}

.scroll-px-12 {
  scroll-padding-left: 3rem;
  scroll-padding-right: 3rem;
}

.scroll-px-14 {
  scroll-padding-left: 3.5rem;
  scroll-padding-right: 3.5rem;
}

.scroll-px-16 {
  scroll-padding-left: 4rem;
  scroll-padding-right: 4rem;
}

.scroll-px-2 {
  scroll-padding-left: .5rem;
  scroll-padding-right: .5rem;
}

.scroll-px-2\.5 {
  scroll-padding-left: .625rem;
  scroll-padding-right: .625rem;
}

.scroll-px-20 {
  scroll-padding-left: 5rem;
  scroll-padding-right: 5rem;
}

.scroll-px-24 {
  scroll-padding-left: 6rem;
  scroll-padding-right: 6rem;
}

.scroll-px-28 {
  scroll-padding-left: 7rem;
  scroll-padding-right: 7rem;
}

.scroll-px-3 {
  scroll-padding-left: .75rem;
  scroll-padding-right: .75rem;
}

.scroll-px-3\.5 {
  scroll-padding-left: .875rem;
  scroll-padding-right: .875rem;
}

.scroll-px-32 {
  scroll-padding-left: 8rem;
  scroll-padding-right: 8rem;
}

.scroll-px-36 {
  scroll-padding-left: 9rem;
  scroll-padding-right: 9rem;
}

.scroll-px-4 {
  scroll-padding-left: 1rem;
  scroll-padding-right: 1rem;
}

.scroll-px-40 {
  scroll-padding-left: 10rem;
  scroll-padding-right: 10rem;
}

.scroll-px-44 {
  scroll-padding-left: 11rem;
  scroll-padding-right: 11rem;
}

.scroll-px-48 {
  scroll-padding-left: 12rem;
  scroll-padding-right: 12rem;
}

.scroll-px-5 {
  scroll-padding-left: 1.25rem;
  scroll-padding-right: 1.25rem;
}

.scroll-px-52 {
  scroll-padding-left: 13rem;
  scroll-padding-right: 13rem;
}

.scroll-px-56 {
  scroll-padding-left: 14rem;
  scroll-padding-right: 14rem;
}

.scroll-px-6 {
  scroll-padding-left: 1.5rem;
  scroll-padding-right: 1.5rem;
}

.scroll-px-60 {
  scroll-padding-left: 15rem;
  scroll-padding-right: 15rem;
}

.scroll-px-64 {
  scroll-padding-left: 16rem;
  scroll-padding-right: 16rem;
}

.scroll-px-7 {
  scroll-padding-left: 1.75rem;
  scroll-padding-right: 1.75rem;
}

.scroll-px-72 {
  scroll-padding-left: 18rem;
  scroll-padding-right: 18rem;
}

.scroll-px-8 {
  scroll-padding-left: 2rem;
  scroll-padding-right: 2rem;
}

.scroll-px-80 {
  scroll-padding-left: 20rem;
  scroll-padding-right: 20rem;
}

.scroll-px-9 {
  scroll-padding-left: 2.25rem;
  scroll-padding-right: 2.25rem;
}

.scroll-px-96 {
  scroll-padding-left: 24rem;
  scroll-padding-right: 24rem;
}

.scroll-px-px {
  scroll-padding-left: 1px;
  scroll-padding-right: 1px;
}

.scroll-py-0 {
  scroll-padding-top: 0;
  scroll-padding-bottom: 0;
}

.scroll-py-0\.5 {
  scroll-padding-top: .125rem;
  scroll-padding-bottom: .125rem;
}

.scroll-py-1 {
  scroll-padding-top: .25rem;
  scroll-padding-bottom: .25rem;
}

.scroll-py-1\.5 {
  scroll-padding-top: .375rem;
  scroll-padding-bottom: .375rem;
}

.scroll-py-10 {
  scroll-padding-top: 2.5rem;
  scroll-padding-bottom: 2.5rem;
}

.scroll-py-11 {
  scroll-padding-top: 2.75rem;
  scroll-padding-bottom: 2.75rem;
}

.scroll-py-12 {
  scroll-padding-top: 3rem;
  scroll-padding-bottom: 3rem;
}

.scroll-py-14 {
  scroll-padding-top: 3.5rem;
  scroll-padding-bottom: 3.5rem;
}

.scroll-py-16 {
  scroll-padding-top: 4rem;
  scroll-padding-bottom: 4rem;
}

.scroll-py-2 {
  scroll-padding-top: .5rem;
  scroll-padding-bottom: .5rem;
}

.scroll-py-2\.5 {
  scroll-padding-top: .625rem;
  scroll-padding-bottom: .625rem;
}

.scroll-py-20 {
  scroll-padding-top: 5rem;
  scroll-padding-bottom: 5rem;
}

.scroll-py-24 {
  scroll-padding-top: 6rem;
  scroll-padding-bottom: 6rem;
}

.scroll-py-28 {
  scroll-padding-top: 7rem;
  scroll-padding-bottom: 7rem;
}

.scroll-py-3 {
  scroll-padding-top: .75rem;
  scroll-padding-bottom: .75rem;
}

.scroll-py-3\.5 {
  scroll-padding-top: .875rem;
  scroll-padding-bottom: .875rem;
}

.scroll-py-32 {
  scroll-padding-top: 8rem;
  scroll-padding-bottom: 8rem;
}

.scroll-py-36 {
  scroll-padding-top: 9rem;
  scroll-padding-bottom: 9rem;
}

.scroll-py-4 {
  scroll-padding-top: 1rem;
  scroll-padding-bottom: 1rem;
}

.scroll-py-40 {
  scroll-padding-top: 10rem;
  scroll-padding-bottom: 10rem;
}

.scroll-py-44 {
  scroll-padding-top: 11rem;
  scroll-padding-bottom: 11rem;
}

.scroll-py-48 {
  scroll-padding-top: 12rem;
  scroll-padding-bottom: 12rem;
}

.scroll-py-5 {
  scroll-padding-top: 1.25rem;
  scroll-padding-bottom: 1.25rem;
}

.scroll-py-52 {
  scroll-padding-top: 13rem;
  scroll-padding-bottom: 13rem;
}

.scroll-py-56 {
  scroll-padding-top: 14rem;
  scroll-padding-bottom: 14rem;
}

.scroll-py-6 {
  scroll-padding-top: 1.5rem;
  scroll-padding-bottom: 1.5rem;
}

.scroll-py-60 {
  scroll-padding-top: 15rem;
  scroll-padding-bottom: 15rem;
}

.scroll-py-64 {
  scroll-padding-top: 16rem;
  scroll-padding-bottom: 16rem;
}

.scroll-py-7 {
  scroll-padding-top: 1.75rem;
  scroll-padding-bottom: 1.75rem;
}

.scroll-py-72 {
  scroll-padding-top: 18rem;
  scroll-padding-bottom: 18rem;
}

.scroll-py-8 {
  scroll-padding-top: 2rem;
  scroll-padding-bottom: 2rem;
}

.scroll-py-80 {
  scroll-padding-top: 20rem;
  scroll-padding-bottom: 20rem;
}

.scroll-py-9 {
  scroll-padding-top: 2.25rem;
  scroll-padding-bottom: 2.25rem;
}

.scroll-py-96 {
  scroll-padding-top: 24rem;
  scroll-padding-bottom: 24rem;
}

.scroll-py-px {
  scroll-padding-top: 1px;
  scroll-padding-bottom: 1px;
}

.scroll-pb-0 {
  scroll-padding-bottom: 0;
}

.scroll-pb-0\.5 {
  scroll-padding-bottom: .125rem;
}

.scroll-pb-1 {
  scroll-padding-bottom: .25rem;
}

.scroll-pb-1\.5 {
  scroll-padding-bottom: .375rem;
}

.scroll-pb-10 {
  scroll-padding-bottom: 2.5rem;
}

.scroll-pb-11 {
  scroll-padding-bottom: 2.75rem;
}

.scroll-pb-12 {
  scroll-padding-bottom: 3rem;
}

.scroll-pb-14 {
  scroll-padding-bottom: 3.5rem;
}

.scroll-pb-16 {
  scroll-padding-bottom: 4rem;
}

.scroll-pb-2 {
  scroll-padding-bottom: .5rem;
}

.scroll-pb-2\.5 {
  scroll-padding-bottom: .625rem;
}

.scroll-pb-20 {
  scroll-padding-bottom: 5rem;
}

.scroll-pb-24 {
  scroll-padding-bottom: 6rem;
}

.scroll-pb-28 {
  scroll-padding-bottom: 7rem;
}

.scroll-pb-3 {
  scroll-padding-bottom: .75rem;
}

.scroll-pb-3\.5 {
  scroll-padding-bottom: .875rem;
}

.scroll-pb-32 {
  scroll-padding-bottom: 8rem;
}

.scroll-pb-36 {
  scroll-padding-bottom: 9rem;
}

.scroll-pb-4 {
  scroll-padding-bottom: 1rem;
}

.scroll-pb-40 {
  scroll-padding-bottom: 10rem;
}

.scroll-pb-44 {
  scroll-padding-bottom: 11rem;
}

.scroll-pb-48 {
  scroll-padding-bottom: 12rem;
}

.scroll-pb-5 {
  scroll-padding-bottom: 1.25rem;
}

.scroll-pb-52 {
  scroll-padding-bottom: 13rem;
}

.scroll-pb-56 {
  scroll-padding-bottom: 14rem;
}

.scroll-pb-6 {
  scroll-padding-bottom: 1.5rem;
}

.scroll-pb-60 {
  scroll-padding-bottom: 15rem;
}

.scroll-pb-64 {
  scroll-padding-bottom: 16rem;
}

.scroll-pb-7 {
  scroll-padding-bottom: 1.75rem;
}

.scroll-pb-72 {
  scroll-padding-bottom: 18rem;
}

.scroll-pb-8 {
  scroll-padding-bottom: 2rem;
}

.scroll-pb-80 {
  scroll-padding-bottom: 20rem;
}

.scroll-pb-9 {
  scroll-padding-bottom: 2.25rem;
}

.scroll-pb-96 {
  scroll-padding-bottom: 24rem;
}

.scroll-pb-px {
  scroll-padding-bottom: 1px;
}

.scroll-pe-0 {
  scroll-padding-inline-end: 0;
}

.scroll-pe-0\.5 {
  scroll-padding-inline-end: .125rem;
}

.scroll-pe-1 {
  scroll-padding-inline-end: .25rem;
}

.scroll-pe-1\.5 {
  scroll-padding-inline-end: .375rem;
}

.scroll-pe-10 {
  scroll-padding-inline-end: 2.5rem;
}

.scroll-pe-11 {
  scroll-padding-inline-end: 2.75rem;
}

.scroll-pe-12 {
  scroll-padding-inline-end: 3rem;
}

.scroll-pe-14 {
  scroll-padding-inline-end: 3.5rem;
}

.scroll-pe-16 {
  scroll-padding-inline-end: 4rem;
}

.scroll-pe-2 {
  scroll-padding-inline-end: .5rem;
}

.scroll-pe-2\.5 {
  scroll-padding-inline-end: .625rem;
}

.scroll-pe-20 {
  scroll-padding-inline-end: 5rem;
}

.scroll-pe-24 {
  scroll-padding-inline-end: 6rem;
}

.scroll-pe-28 {
  scroll-padding-inline-end: 7rem;
}

.scroll-pe-3 {
  scroll-padding-inline-end: .75rem;
}

.scroll-pe-3\.5 {
  scroll-padding-inline-end: .875rem;
}

.scroll-pe-32 {
  scroll-padding-inline-end: 8rem;
}

.scroll-pe-36 {
  scroll-padding-inline-end: 9rem;
}

.scroll-pe-4 {
  scroll-padding-inline-end: 1rem;
}

.scroll-pe-40 {
  scroll-padding-inline-end: 10rem;
}

.scroll-pe-44 {
  scroll-padding-inline-end: 11rem;
}

.scroll-pe-48 {
  scroll-padding-inline-end: 12rem;
}

.scroll-pe-5 {
  scroll-padding-inline-end: 1.25rem;
}

.scroll-pe-52 {
  scroll-padding-inline-end: 13rem;
}

.scroll-pe-56 {
  scroll-padding-inline-end: 14rem;
}

.scroll-pe-6 {
  scroll-padding-inline-end: 1.5rem;
}

.scroll-pe-60 {
  scroll-padding-inline-end: 15rem;
}

.scroll-pe-64 {
  scroll-padding-inline-end: 16rem;
}

.scroll-pe-7 {
  scroll-padding-inline-end: 1.75rem;
}

.scroll-pe-72 {
  scroll-padding-inline-end: 18rem;
}

.scroll-pe-8 {
  scroll-padding-inline-end: 2rem;
}

.scroll-pe-80 {
  scroll-padding-inline-end: 20rem;
}

.scroll-pe-9 {
  scroll-padding-inline-end: 2.25rem;
}

.scroll-pe-96 {
  scroll-padding-inline-end: 24rem;
}

.scroll-pe-px {
  scroll-padding-inline-end: 1px;
}

.scroll-pl-0 {
  scroll-padding-left: 0;
}

.scroll-pl-0\.5 {
  scroll-padding-left: .125rem;
}

.scroll-pl-1 {
  scroll-padding-left: .25rem;
}

.scroll-pl-1\.5 {
  scroll-padding-left: .375rem;
}

.scroll-pl-10 {
  scroll-padding-left: 2.5rem;
}

.scroll-pl-11 {
  scroll-padding-left: 2.75rem;
}

.scroll-pl-12 {
  scroll-padding-left: 3rem;
}

.scroll-pl-14 {
  scroll-padding-left: 3.5rem;
}

.scroll-pl-16 {
  scroll-padding-left: 4rem;
}

.scroll-pl-2 {
  scroll-padding-left: .5rem;
}

.scroll-pl-2\.5 {
  scroll-padding-left: .625rem;
}

.scroll-pl-20 {
  scroll-padding-left: 5rem;
}

.scroll-pl-24 {
  scroll-padding-left: 6rem;
}

.scroll-pl-28 {
  scroll-padding-left: 7rem;
}

.scroll-pl-3 {
  scroll-padding-left: .75rem;
}

.scroll-pl-3\.5 {
  scroll-padding-left: .875rem;
}

.scroll-pl-32 {
  scroll-padding-left: 8rem;
}

.scroll-pl-36 {
  scroll-padding-left: 9rem;
}

.scroll-pl-4 {
  scroll-padding-left: 1rem;
}

.scroll-pl-40 {
  scroll-padding-left: 10rem;
}

.scroll-pl-44 {
  scroll-padding-left: 11rem;
}

.scroll-pl-48 {
  scroll-padding-left: 12rem;
}

.scroll-pl-5 {
  scroll-padding-left: 1.25rem;
}

.scroll-pl-52 {
  scroll-padding-left: 13rem;
}

.scroll-pl-56 {
  scroll-padding-left: 14rem;
}

.scroll-pl-6 {
  scroll-padding-left: 1.5rem;
}

.scroll-pl-60 {
  scroll-padding-left: 15rem;
}

.scroll-pl-64 {
  scroll-padding-left: 16rem;
}

.scroll-pl-7 {
  scroll-padding-left: 1.75rem;
}

.scroll-pl-72 {
  scroll-padding-left: 18rem;
}

.scroll-pl-8 {
  scroll-padding-left: 2rem;
}

.scroll-pl-80 {
  scroll-padding-left: 20rem;
}

.scroll-pl-9 {
  scroll-padding-left: 2.25rem;
}

.scroll-pl-96 {
  scroll-padding-left: 24rem;
}

.scroll-pl-px {
  scroll-padding-left: 1px;
}

.scroll-pr-0 {
  scroll-padding-right: 0;
}

.scroll-pr-0\.5 {
  scroll-padding-right: .125rem;
}

.scroll-pr-1 {
  scroll-padding-right: .25rem;
}

.scroll-pr-1\.5 {
  scroll-padding-right: .375rem;
}

.scroll-pr-10 {
  scroll-padding-right: 2.5rem;
}

.scroll-pr-11 {
  scroll-padding-right: 2.75rem;
}

.scroll-pr-12 {
  scroll-padding-right: 3rem;
}

.scroll-pr-14 {
  scroll-padding-right: 3.5rem;
}

.scroll-pr-16 {
  scroll-padding-right: 4rem;
}

.scroll-pr-2 {
  scroll-padding-right: .5rem;
}

.scroll-pr-2\.5 {
  scroll-padding-right: .625rem;
}

.scroll-pr-20 {
  scroll-padding-right: 5rem;
}

.scroll-pr-24 {
  scroll-padding-right: 6rem;
}

.scroll-pr-28 {
  scroll-padding-right: 7rem;
}

.scroll-pr-3 {
  scroll-padding-right: .75rem;
}

.scroll-pr-3\.5 {
  scroll-padding-right: .875rem;
}

.scroll-pr-32 {
  scroll-padding-right: 8rem;
}

.scroll-pr-36 {
  scroll-padding-right: 9rem;
}

.scroll-pr-4 {
  scroll-padding-right: 1rem;
}

.scroll-pr-40 {
  scroll-padding-right: 10rem;
}

.scroll-pr-44 {
  scroll-padding-right: 11rem;
}

.scroll-pr-48 {
  scroll-padding-right: 12rem;
}

.scroll-pr-5 {
  scroll-padding-right: 1.25rem;
}

.scroll-pr-52 {
  scroll-padding-right: 13rem;
}

.scroll-pr-56 {
  scroll-padding-right: 14rem;
}

.scroll-pr-6 {
  scroll-padding-right: 1.5rem;
}

.scroll-pr-60 {
  scroll-padding-right: 15rem;
}

.scroll-pr-64 {
  scroll-padding-right: 16rem;
}

.scroll-pr-7 {
  scroll-padding-right: 1.75rem;
}

.scroll-pr-72 {
  scroll-padding-right: 18rem;
}

.scroll-pr-8 {
  scroll-padding-right: 2rem;
}

.scroll-pr-80 {
  scroll-padding-right: 20rem;
}

.scroll-pr-9 {
  scroll-padding-right: 2.25rem;
}

.scroll-pr-96 {
  scroll-padding-right: 24rem;
}

.scroll-pr-px {
  scroll-padding-right: 1px;
}

.scroll-ps-0 {
  scroll-padding-inline-start: 0;
}

.scroll-ps-0\.5 {
  scroll-padding-inline-start: .125rem;
}

.scroll-ps-1 {
  scroll-padding-inline-start: .25rem;
}

.scroll-ps-1\.5 {
  scroll-padding-inline-start: .375rem;
}

.scroll-ps-10 {
  scroll-padding-inline-start: 2.5rem;
}

.scroll-ps-11 {
  scroll-padding-inline-start: 2.75rem;
}

.scroll-ps-12 {
  scroll-padding-inline-start: 3rem;
}

.scroll-ps-14 {
  scroll-padding-inline-start: 3.5rem;
}

.scroll-ps-16 {
  scroll-padding-inline-start: 4rem;
}

.scroll-ps-2 {
  scroll-padding-inline-start: .5rem;
}

.scroll-ps-2\.5 {
  scroll-padding-inline-start: .625rem;
}

.scroll-ps-20 {
  scroll-padding-inline-start: 5rem;
}

.scroll-ps-24 {
  scroll-padding-inline-start: 6rem;
}

.scroll-ps-28 {
  scroll-padding-inline-start: 7rem;
}

.scroll-ps-3 {
  scroll-padding-inline-start: .75rem;
}

.scroll-ps-3\.5 {
  scroll-padding-inline-start: .875rem;
}

.scroll-ps-32 {
  scroll-padding-inline-start: 8rem;
}

.scroll-ps-36 {
  scroll-padding-inline-start: 9rem;
}

.scroll-ps-4 {
  scroll-padding-inline-start: 1rem;
}

.scroll-ps-40 {
  scroll-padding-inline-start: 10rem;
}

.scroll-ps-44 {
  scroll-padding-inline-start: 11rem;
}

.scroll-ps-48 {
  scroll-padding-inline-start: 12rem;
}

.scroll-ps-5 {
  scroll-padding-inline-start: 1.25rem;
}

.scroll-ps-52 {
  scroll-padding-inline-start: 13rem;
}

.scroll-ps-56 {
  scroll-padding-inline-start: 14rem;
}

.scroll-ps-6 {
  scroll-padding-inline-start: 1.5rem;
}

.scroll-ps-60 {
  scroll-padding-inline-start: 15rem;
}

.scroll-ps-64 {
  scroll-padding-inline-start: 16rem;
}

.scroll-ps-7 {
  scroll-padding-inline-start: 1.75rem;
}

.scroll-ps-72 {
  scroll-padding-inline-start: 18rem;
}

.scroll-ps-8 {
  scroll-padding-inline-start: 2rem;
}

.scroll-ps-80 {
  scroll-padding-inline-start: 20rem;
}

.scroll-ps-9 {
  scroll-padding-inline-start: 2.25rem;
}

.scroll-ps-96 {
  scroll-padding-inline-start: 24rem;
}

.scroll-ps-px {
  scroll-padding-inline-start: 1px;
}

.scroll-pt-0 {
  scroll-padding-top: 0;
}

.scroll-pt-0\.5 {
  scroll-padding-top: .125rem;
}

.scroll-pt-1 {
  scroll-padding-top: .25rem;
}

.scroll-pt-1\.5 {
  scroll-padding-top: .375rem;
}

.scroll-pt-10 {
  scroll-padding-top: 2.5rem;
}

.scroll-pt-11 {
  scroll-padding-top: 2.75rem;
}

.scroll-pt-12 {
  scroll-padding-top: 3rem;
}

.scroll-pt-14 {
  scroll-padding-top: 3.5rem;
}

.scroll-pt-16 {
  scroll-padding-top: 4rem;
}

.scroll-pt-2 {
  scroll-padding-top: .5rem;
}

.scroll-pt-2\.5 {
  scroll-padding-top: .625rem;
}

.scroll-pt-20 {
  scroll-padding-top: 5rem;
}

.scroll-pt-24 {
  scroll-padding-top: 6rem;
}

.scroll-pt-28 {
  scroll-padding-top: 7rem;
}

.scroll-pt-3 {
  scroll-padding-top: .75rem;
}

.scroll-pt-3\.5 {
  scroll-padding-top: .875rem;
}

.scroll-pt-32 {
  scroll-padding-top: 8rem;
}

.scroll-pt-36 {
  scroll-padding-top: 9rem;
}

.scroll-pt-4 {
  scroll-padding-top: 1rem;
}

.scroll-pt-40 {
  scroll-padding-top: 10rem;
}

.scroll-pt-44 {
  scroll-padding-top: 11rem;
}

.scroll-pt-48 {
  scroll-padding-top: 12rem;
}

.scroll-pt-5 {
  scroll-padding-top: 1.25rem;
}

.scroll-pt-52 {
  scroll-padding-top: 13rem;
}

.scroll-pt-56 {
  scroll-padding-top: 14rem;
}

.scroll-pt-6 {
  scroll-padding-top: 1.5rem;
}

.scroll-pt-60 {
  scroll-padding-top: 15rem;
}

.scroll-pt-64 {
  scroll-padding-top: 16rem;
}

.scroll-pt-7 {
  scroll-padding-top: 1.75rem;
}

.scroll-pt-72 {
  scroll-padding-top: 18rem;
}

.scroll-pt-8 {
  scroll-padding-top: 2rem;
}

.scroll-pt-80 {
  scroll-padding-top: 20rem;
}

.scroll-pt-9 {
  scroll-padding-top: 2.25rem;
}

.scroll-pt-96 {
  scroll-padding-top: 24rem;
}

.scroll-pt-px {
  scroll-padding-top: 1px;
}

.list-decimal {
  list-style-type: decimal;
}

.list-image-none {
  list-style-image: none;
}

.appearance-none {
  appearance: none;
}

.columns-1 {
  columns: 1;
}

.columns-10 {
  columns: 10;
}

.columns-11 {
  columns: 11;
}

.columns-12 {
  columns: 12;
}

.columns-2 {
  columns: 2;
}

.columns-2xl {
  columns: 42rem;
}

.columns-2xs {
  columns: 18rem;
}

.columns-3 {
  columns: 3;
}

.columns-3xl {
  columns: 48rem;
}

.columns-3xs {
  columns: 16rem;
}

.columns-4 {
  columns: 4;
}

.columns-4xl {
  columns: 56rem;
}

.columns-5 {
  columns: 5;
}

.columns-5xl {
  columns: 64rem;
}

.columns-6 {
  columns: 6;
}

.columns-6xl {
  columns: 72rem;
}

.columns-7 {
  columns: 7;
}

.columns-7xl {
  columns: 80rem;
}

.columns-8 {
  columns: 8;
}

.columns-9 {
  columns: 9;
}

.columns-auto {
  columns: auto;
}

.columns-lg {
  columns: 32rem;
}

.columns-md {
  columns: 28rem;
}

.columns-sm {
  columns: 24rem;
}

.columns-xl {
  columns: 36rem;
}

.columns-xs {
  columns: 20rem;
}

.break-before-avoid-page {
  break-before: avoid-page;
}

.break-before-page {
  break-before: page;
}

.break-before-column {
  break-before: column;
}

.break-inside-avoid-page {
  break-inside: avoid-page;
}

.break-inside-avoid-column {
  break-inside: avoid-column;
}

.break-after-avoid-page {
  break-after: avoid-page;
}

.break-after-page {
  break-after: page;
}

.break-after-column {
  break-after: column;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.auto-cols-min {
  grid-auto-columns: min-content;
}

.auto-rows-max {
  grid-auto-rows: max-content;
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.place-content-start {
  place-content: start;
}

.place-content-end {
  place-content: end;
}

.place-content-between {
  place-content: space-between;
}

.place-content-around {
  place-content: space-around;
}

.place-content-evenly {
  place-content: space-evenly;
}

.place-content-baseline {
  place-content: baseline start;
}

.place-content-stretch {
  place-content: stretch;
}

.place-items-start {
  place-items: start;
}

.place-items-end {
  place-items: end;
}

.place-items-center {
  place-items: center;
}

.place-items-baseline {
  place-items: baseline;
}

.place-items-stretch {
  place-items: stretch stretch;
}

.content-normal {
  align-content: normal;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-normal {
  justify-content: normal;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-28 {
  gap: 7rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-36 {
  gap: 9rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-40 {
  gap: 10rem;
}

.gap-44 {
  gap: 11rem;
}

.gap-48 {
  gap: 12rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-52 {
  gap: 13rem;
}

.gap-56 {
  gap: 14rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-60 {
  gap: 15rem;
}

.gap-64 {
  gap: 16rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-72 {
  gap: 18rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-80 {
  gap: 20rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-96 {
  gap: 24rem;
}

.gap-px {
  gap: 1px;
}

.gap-x-0 {
  column-gap: 0;
}

.gap-x-0\.5 {
  column-gap: .125rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-1\.5 {
  column-gap: .375rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-11 {
  column-gap: 2.75rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-14 {
  column-gap: 3.5rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-2\.5 {
  column-gap: .625rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-x-28 {
  column-gap: 7rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-3\.5 {
  column-gap: .875rem;
}

.gap-x-32 {
  column-gap: 8rem;
}

.gap-x-36 {
  column-gap: 9rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-40 {
  column-gap: 10rem;
}

.gap-x-44 {
  column-gap: 11rem;
}

.gap-x-48 {
  column-gap: 12rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-52 {
  column-gap: 13rem;
}

.gap-x-56 {
  column-gap: 14rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-60 {
  column-gap: 15rem;
}

.gap-x-64 {
  column-gap: 16rem;
}

.gap-x-7 {
  column-gap: 1.75rem;
}

.gap-x-72 {
  column-gap: 18rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-x-80 {
  column-gap: 20rem;
}

.gap-x-9 {
  column-gap: 2.25rem;
}

.gap-x-96 {
  column-gap: 24rem;
}

.gap-x-px {
  column-gap: 1px;
}

.gap-y-0 {
  row-gap: 0;
}

.gap-y-0\.5 {
  row-gap: .125rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-1\.5 {
  row-gap: .375rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-11 {
  row-gap: 2.75rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-2\.5 {
  row-gap: .625rem;
}

.gap-y-20 {
  row-gap: 5rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.gap-y-28 {
  row-gap: 7rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-3\.5 {
  row-gap: .875rem;
}

.gap-y-32 {
  row-gap: 8rem;
}

.gap-y-36 {
  row-gap: 9rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-40 {
  row-gap: 10rem;
}

.gap-y-44 {
  row-gap: 11rem;
}

.gap-y-48 {
  row-gap: 12rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-52 {
  row-gap: 13rem;
}

.gap-y-56 {
  row-gap: 14rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-60 {
  row-gap: 15rem;
}

.gap-y-64 {
  row-gap: 16rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-y-72 {
  row-gap: 18rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-80 {
  row-gap: 20rem;
}

.gap-y-9 {
  row-gap: 2.25rem;
}

.gap-y-96 {
  row-gap: 24rem;
}

.gap-y-px {
  row-gap: 1px;
}

.-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0px * var(--tw-space-x-reverse));
  margin-left: calc(-0px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.125rem * var(--tw-space-x-reverse));
  margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.375rem * var(--tw-space-x-reverse));
  margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3rem * var(--tw-space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.625rem * var(--tw-space-x-reverse));
  margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5rem * var(--tw-space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-7rem * var(--tw-space-x-reverse));
  margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.875rem * var(--tw-space-x-reverse));
  margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-9rem * var(--tw-space-x-reverse));
  margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-10rem * var(--tw-space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-11rem * var(--tw-space-x-reverse));
  margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-12rem * var(--tw-space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-13rem * var(--tw-space-x-reverse));
  margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-14rem * var(--tw-space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-15rem * var(--tw-space-x-reverse));
  margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-18rem * var(--tw-space-x-reverse));
  margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-20rem * var(--tw-space-x-reverse));
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-24rem * var(--tw-space-x-reverse));
  margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0px * var(--tw-space-y-reverse));
}

.-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
}

.-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
}

.-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
}

.-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
}

.-space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
}

.-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
}

.-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
}

.-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
}

.-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
}

.-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
}

.-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
}

.-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
}

.-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
}

.-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
}

.-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
}

.-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
}

.-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
}

.-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}

.-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
}

.-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
}

.-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
}

.-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}

.-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
}

.-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
}

.-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
}

.-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
}

.-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
}

.-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
}

.-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
}

.-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
}

.-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
}

.-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
}

.-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
}

.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.375rem * var(--tw-space-x-reverse));
  margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.625rem * var(--tw-space-x-reverse));
  margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5rem * var(--tw-space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse));
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.875rem * var(--tw-space-x-reverse));
  margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(9rem * var(--tw-space-x-reverse));
  margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(11rem * var(--tw-space-x-reverse));
  margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12rem * var(--tw-space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13rem * var(--tw-space-x-reverse));
  margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(14rem * var(--tw-space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(15rem * var(--tw-space-x-reverse));
  margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(18rem * var(--tw-space-x-reverse));
  margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20rem * var(--tw-space-x-reverse));
  margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24rem * var(--tw-space-x-reverse));
  margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}

.space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(7rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
}

.space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}

.space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10rem * var(--tw-space-y-reverse));
}

.space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(11rem * var(--tw-space-y-reverse));
}

.space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13rem * var(--tw-space-y-reverse));
}

.space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15rem * var(--tw-space-y-reverse));
}

.space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}

.space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(18rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20rem * var(--tw-space-y-reverse));
}

.space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}

.space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24rem * var(--tw-space-y-reverse));
}

.space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse));
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}

.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.divide-amber-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(254 243 199 / var(--tw-divide-opacity));
}

.divide-amber-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c700;
}

.divide-amber-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c71a;
}

.divide-amber-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c7;
}

.divide-amber-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c733;
}

.divide-amber-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c740;
}

.divide-amber-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c74d;
}

.divide-amber-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c766;
}

.divide-amber-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c70d;
}

.divide-amber-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c780;
}

.divide-amber-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c799;
}

.divide-amber-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c7b3;
}

.divide-amber-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c7bf;
}

.divide-amber-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c7cc;
}

.divide-amber-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c7e6;
}

.divide-amber-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef3c7f2;
}

.divide-amber-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(253 230 138 / var(--tw-divide-opacity));
}

.divide-amber-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68a00;
}

.divide-amber-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68a1a;
}

.divide-amber-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68a;
}

.divide-amber-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68a33;
}

.divide-amber-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68a40;
}

.divide-amber-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68a4d;
}

.divide-amber-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68a66;
}

.divide-amber-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68a0d;
}

.divide-amber-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68a80;
}

.divide-amber-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68a99;
}

.divide-amber-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68ab3;
}

.divide-amber-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68abf;
}

.divide-amber-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68acc;
}

.divide-amber-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68ae6;
}

.divide-amber-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fde68af2;
}

.divide-amber-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-divide-opacity));
}

.divide-amber-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34d00;
}

.divide-amber-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34d1a;
}

.divide-amber-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34d;
}

.divide-amber-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34d33;
}

.divide-amber-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34d40;
}

.divide-amber-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34d4d;
}

.divide-amber-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34d66;
}

.divide-amber-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34d0d;
}

.divide-amber-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34d80;
}

.divide-amber-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34d99;
}

.divide-amber-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34db3;
}

.divide-amber-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34dbf;
}

.divide-amber-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34dcc;
}

.divide-amber-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34de6;
}

.divide-amber-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fcd34df2;
}

.divide-amber-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-divide-opacity));
}

.divide-amber-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf2400;
}

.divide-amber-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf241a;
}

.divide-amber-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf24;
}

.divide-amber-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf2433;
}

.divide-amber-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf2440;
}

.divide-amber-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf244d;
}

.divide-amber-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf2466;
}

.divide-amber-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf240d;
}

.divide-amber-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf2480;
}

.divide-amber-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf2499;
}

.divide-amber-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf24b3;
}

.divide-amber-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf24bf;
}

.divide-amber-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf24cc;
}

.divide-amber-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf24e6;
}

.divide-amber-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbbf24f2;
}

.divide-amber-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(255 251 235 / var(--tw-divide-opacity));
}

.divide-amber-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbeb00;
}

.divide-amber-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbeb1a;
}

.divide-amber-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbeb;
}

.divide-amber-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbeb33;
}

.divide-amber-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbeb40;
}

.divide-amber-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbeb4d;
}

.divide-amber-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbeb66;
}

.divide-amber-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbeb0d;
}

.divide-amber-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbeb80;
}

.divide-amber-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbeb99;
}

.divide-amber-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbebb3;
}

.divide-amber-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbebbf;
}

.divide-amber-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbebcc;
}

.divide-amber-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbebe6;
}

.divide-amber-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fffbebf2;
}

.divide-amber-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-divide-opacity));
}

.divide-amber-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0b00;
}

.divide-amber-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0b1a;
}

.divide-amber-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0b;
}

.divide-amber-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0b33;
}

.divide-amber-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0b40;
}

.divide-amber-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0b4d;
}

.divide-amber-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0b66;
}

.divide-amber-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0b0d;
}

.divide-amber-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0b80;
}

.divide-amber-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0b99;
}

.divide-amber-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0bb3;
}

.divide-amber-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0bbf;
}

.divide-amber-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0bcc;
}

.divide-amber-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0be6;
}

.divide-amber-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f59e0bf2;
}

.divide-amber-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-divide-opacity));
}

.divide-amber-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9770600;
}

.divide-amber-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d977061a;
}

.divide-amber-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d97706;
}

.divide-amber-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9770633;
}

.divide-amber-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9770640;
}

.divide-amber-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d977064d;
}

.divide-amber-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9770666;
}

.divide-amber-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d977060d;
}

.divide-amber-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9770680;
}

.divide-amber-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9770699;
}

.divide-amber-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d97706b3;
}

.divide-amber-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d97706bf;
}

.divide-amber-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d97706cc;
}

.divide-amber-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d97706e6;
}

.divide-amber-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d97706f2;
}

.divide-amber-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-divide-opacity));
}

.divide-amber-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b4530900;
}

.divide-amber-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b453091a;
}

.divide-amber-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b45309;
}

.divide-amber-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b4530933;
}

.divide-amber-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b4530940;
}

.divide-amber-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b453094d;
}

.divide-amber-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b4530966;
}

.divide-amber-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b453090d;
}

.divide-amber-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b4530980;
}

.divide-amber-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b4530999;
}

.divide-amber-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b45309b3;
}

.divide-amber-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b45309bf;
}

.divide-amber-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b45309cc;
}

.divide-amber-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b45309e6;
}

.divide-amber-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #b45309f2;
}

.divide-amber-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(146 64 14 / var(--tw-divide-opacity));
}

.divide-amber-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400e00;
}

.divide-amber-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400e1a;
}

.divide-amber-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400e;
}

.divide-amber-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400e33;
}

.divide-amber-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400e40;
}

.divide-amber-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400e4d;
}

.divide-amber-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400e66;
}

.divide-amber-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400e0d;
}

.divide-amber-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400e80;
}

.divide-amber-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400e99;
}

.divide-amber-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400eb3;
}

.divide-amber-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400ebf;
}

.divide-amber-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400ecc;
}

.divide-amber-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400ee6;
}

.divide-amber-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #92400ef2;
}

.divide-amber-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(120 53 15 / var(--tw-divide-opacity));
}

.divide-amber-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350f00;
}

.divide-amber-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350f1a;
}

.divide-amber-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350f;
}

.divide-amber-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350f33;
}

.divide-amber-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350f40;
}

.divide-amber-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350f4d;
}

.divide-amber-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350f66;
}

.divide-amber-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350f0d;
}

.divide-amber-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350f80;
}

.divide-amber-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350f99;
}

.divide-amber-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350fb3;
}

.divide-amber-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350fbf;
}

.divide-amber-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350fcc;
}

.divide-amber-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350fe6;
}

.divide-amber-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #78350ff2;
}

.divide-amber-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(69 26 3 / var(--tw-divide-opacity));
}

.divide-amber-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a0300;
}

.divide-amber-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a031a;
}

.divide-amber-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a03;
}

.divide-amber-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a0333;
}

.divide-amber-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a0340;
}

.divide-amber-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a034d;
}

.divide-amber-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a0366;
}

.divide-amber-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a030d;
}

.divide-amber-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a0380;
}

.divide-amber-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a0399;
}

.divide-amber-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a03b3;
}

.divide-amber-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a03bf;
}

.divide-amber-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a03cc;
}

.divide-amber-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a03e6;
}

.divide-amber-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #451a03f2;
}

.divide-emerald-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-divide-opacity));
}

.divide-emerald-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae500;
}

.divide-emerald-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae51a;
}

.divide-emerald-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae5;
}

.divide-emerald-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae533;
}

.divide-emerald-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae540;
}

.divide-emerald-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae54d;
}

.divide-emerald-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae566;
}

.divide-emerald-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae50d;
}

.divide-emerald-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae580;
}

.divide-emerald-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae599;
}

.divide-emerald-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae5b3;
}

.divide-emerald-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae5bf;
}

.divide-emerald-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae5cc;
}

.divide-emerald-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae5e6;
}

.divide-emerald-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d1fae5f2;
}

.divide-emerald-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(167 243 208 / var(--tw-divide-opacity));
}

.divide-emerald-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d000;
}

.divide-emerald-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d01a;
}

.divide-emerald-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d0;
}

.divide-emerald-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d033;
}

.divide-emerald-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d040;
}

.divide-emerald-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d04d;
}

.divide-emerald-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d066;
}

.divide-emerald-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d00d;
}

.divide-emerald-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d080;
}

.divide-emerald-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d099;
}

.divide-emerald-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d0b3;
}

.divide-emerald-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d0bf;
}

.divide-emerald-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d0cc;
}

.divide-emerald-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d0e6;
}

.divide-emerald-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a7f3d0f2;
}

.divide-emerald-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-divide-opacity));
}

.divide-emerald-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b700;
}

.divide-emerald-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b71a;
}

.divide-emerald-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b7;
}

.divide-emerald-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b733;
}

.divide-emerald-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b740;
}

.divide-emerald-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b74d;
}

.divide-emerald-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b766;
}

.divide-emerald-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b70d;
}

.divide-emerald-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b780;
}

.divide-emerald-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b799;
}

.divide-emerald-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b7b3;
}

.divide-emerald-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b7bf;
}

.divide-emerald-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b7cc;
}

.divide-emerald-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b7e6;
}

.divide-emerald-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6ee7b7f2;
}

.divide-emerald-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-divide-opacity));
}

.divide-emerald-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d39900;
}

.divide-emerald-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d3991a;
}

.divide-emerald-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d399;
}

.divide-emerald-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d39933;
}

.divide-emerald-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d39940;
}

.divide-emerald-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d3994d;
}

.divide-emerald-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d39966;
}

.divide-emerald-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d3990d;
}

.divide-emerald-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d39980;
}

.divide-emerald-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d39999;
}

.divide-emerald-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d399b3;
}

.divide-emerald-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d399bf;
}

.divide-emerald-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d399cc;
}

.divide-emerald-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d399e6;
}

.divide-emerald-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #34d399f2;
}

.divide-emerald-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(236 253 245 / var(--tw-divide-opacity));
}

.divide-emerald-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf500;
}

.divide-emerald-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf51a;
}

.divide-emerald-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf5;
}

.divide-emerald-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf533;
}

.divide-emerald-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf540;
}

.divide-emerald-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf54d;
}

.divide-emerald-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf566;
}

.divide-emerald-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf50d;
}

.divide-emerald-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf580;
}

.divide-emerald-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf599;
}

.divide-emerald-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf5b3;
}

.divide-emerald-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf5bf;
}

.divide-emerald-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf5cc;
}

.divide-emerald-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf5e6;
}

.divide-emerald-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfdf5f2;
}

.divide-emerald-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-divide-opacity));
}

.divide-emerald-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b98100;
}

.divide-emerald-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b9811a;
}

.divide-emerald-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b981;
}

.divide-emerald-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b98133;
}

.divide-emerald-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b98140;
}

.divide-emerald-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b9814d;
}

.divide-emerald-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b98166;
}

.divide-emerald-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b9810d;
}

.divide-emerald-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b98180;
}

.divide-emerald-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b98199;
}

.divide-emerald-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b981b3;
}

.divide-emerald-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b981bf;
}

.divide-emerald-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b981cc;
}

.divide-emerald-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b981e6;
}

.divide-emerald-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #10b981f2;
}

.divide-emerald-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-divide-opacity));
}

.divide-emerald-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #05966900;
}

.divide-emerald-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0596691a;
}

.divide-emerald-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #059669;
}

.divide-emerald-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #05966933;
}

.divide-emerald-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #05966940;
}

.divide-emerald-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0596694d;
}

.divide-emerald-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #05966966;
}

.divide-emerald-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0596690d;
}

.divide-emerald-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #05966980;
}

.divide-emerald-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #05966999;
}

.divide-emerald-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #059669b3;
}

.divide-emerald-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #059669bf;
}

.divide-emerald-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #059669cc;
}

.divide-emerald-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #059669e6;
}

.divide-emerald-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #059669f2;
}

.divide-emerald-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-divide-opacity));
}

.divide-emerald-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #04785700;
}

.divide-emerald-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0478571a;
}

.divide-emerald-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #047857;
}

.divide-emerald-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #04785733;
}

.divide-emerald-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #04785740;
}

.divide-emerald-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0478574d;
}

.divide-emerald-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #04785766;
}

.divide-emerald-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0478570d;
}

.divide-emerald-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #04785780;
}

.divide-emerald-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #04785799;
}

.divide-emerald-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #047857b3;
}

.divide-emerald-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #047857bf;
}

.divide-emerald-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #047857cc;
}

.divide-emerald-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #047857e6;
}

.divide-emerald-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #047857f2;
}

.divide-emerald-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(6 95 70 / var(--tw-divide-opacity));
}

.divide-emerald-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f4600;
}

.divide-emerald-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f461a;
}

.divide-emerald-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f46;
}

.divide-emerald-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f4633;
}

.divide-emerald-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f4640;
}

.divide-emerald-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f464d;
}

.divide-emerald-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f4666;
}

.divide-emerald-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f460d;
}

.divide-emerald-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f4680;
}

.divide-emerald-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f4699;
}

.divide-emerald-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f46b3;
}

.divide-emerald-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f46bf;
}

.divide-emerald-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f46cc;
}

.divide-emerald-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f46e6;
}

.divide-emerald-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #065f46f2;
}

.divide-emerald-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(6 78 59 / var(--tw-divide-opacity));
}

.divide-emerald-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3b00;
}

.divide-emerald-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3b1a;
}

.divide-emerald-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3b;
}

.divide-emerald-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3b33;
}

.divide-emerald-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3b40;
}

.divide-emerald-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3b4d;
}

.divide-emerald-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3b66;
}

.divide-emerald-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3b0d;
}

.divide-emerald-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3b80;
}

.divide-emerald-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3b99;
}

.divide-emerald-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3bb3;
}

.divide-emerald-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3bbf;
}

.divide-emerald-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3bcc;
}

.divide-emerald-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3be6;
}

.divide-emerald-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #064e3bf2;
}

.divide-emerald-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(2 44 34 / var(--tw-divide-opacity));
}

.divide-emerald-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c2200;
}

.divide-emerald-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c221a;
}

.divide-emerald-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c22;
}

.divide-emerald-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c2233;
}

.divide-emerald-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c2240;
}

.divide-emerald-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c224d;
}

.divide-emerald-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c2266;
}

.divide-emerald-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c220d;
}

.divide-emerald-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c2280;
}

.divide-emerald-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c2299;
}

.divide-emerald-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c22b3;
}

.divide-emerald-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c22bf;
}

.divide-emerald-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c22cc;
}

.divide-emerald-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c22e6;
}

.divide-emerald-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #022c22f2;
}

.divide-lime-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(236 252 203 / var(--tw-divide-opacity));
}

.divide-lime-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccb00;
}

.divide-lime-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccb1a;
}

.divide-lime-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccb;
}

.divide-lime-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccb33;
}

.divide-lime-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccb40;
}

.divide-lime-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccb4d;
}

.divide-lime-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccb66;
}

.divide-lime-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccb0d;
}

.divide-lime-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccb80;
}

.divide-lime-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccb99;
}

.divide-lime-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccbb3;
}

.divide-lime-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccbbf;
}

.divide-lime-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccbcc;
}

.divide-lime-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccbe6;
}

.divide-lime-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ecfccbf2;
}

.divide-lime-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(217 249 157 / var(--tw-divide-opacity));
}

.divide-lime-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99d00;
}

.divide-lime-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99d1a;
}

.divide-lime-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99d;
}

.divide-lime-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99d33;
}

.divide-lime-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99d40;
}

.divide-lime-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99d4d;
}

.divide-lime-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99d66;
}

.divide-lime-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99d0d;
}

.divide-lime-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99d80;
}

.divide-lime-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99d99;
}

.divide-lime-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99db3;
}

.divide-lime-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99dbf;
}

.divide-lime-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99dcc;
}

.divide-lime-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99de6;
}

.divide-lime-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d9f99df2;
}

.divide-lime-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-divide-opacity));
}

.divide-lime-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef26400;
}

.divide-lime-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef2641a;
}

.divide-lime-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef264;
}

.divide-lime-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef26433;
}

.divide-lime-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef26440;
}

.divide-lime-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef2644d;
}

.divide-lime-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef26466;
}

.divide-lime-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef2640d;
}

.divide-lime-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef26480;
}

.divide-lime-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef26499;
}

.divide-lime-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef264b3;
}

.divide-lime-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef264bf;
}

.divide-lime-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef264cc;
}

.divide-lime-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef264e6;
}

.divide-lime-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bef264f2;
}

.divide-lime-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(163 230 53 / var(--tw-divide-opacity));
}

.divide-lime-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e63500;
}

.divide-lime-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e6351a;
}

.divide-lime-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e635;
}

.divide-lime-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e63533;
}

.divide-lime-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e63540;
}

.divide-lime-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e6354d;
}

.divide-lime-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e63566;
}

.divide-lime-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e6350d;
}

.divide-lime-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e63580;
}

.divide-lime-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e63599;
}

.divide-lime-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e635b3;
}

.divide-lime-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e635bf;
}

.divide-lime-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e635cc;
}

.divide-lime-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e635e6;
}

.divide-lime-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a3e635f2;
}

.divide-lime-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(247 254 231 / var(--tw-divide-opacity));
}

.divide-lime-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee700;
}

.divide-lime-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee71a;
}

.divide-lime-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee7;
}

.divide-lime-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee733;
}

.divide-lime-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee740;
}

.divide-lime-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee74d;
}

.divide-lime-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee766;
}

.divide-lime-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee70d;
}

.divide-lime-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee780;
}

.divide-lime-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee799;
}

.divide-lime-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee7b3;
}

.divide-lime-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee7bf;
}

.divide-lime-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee7cc;
}

.divide-lime-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee7e6;
}

.divide-lime-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f7fee7f2;
}

.divide-lime-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-divide-opacity));
}

.divide-lime-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc1600;
}

.divide-lime-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc161a;
}

.divide-lime-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc16;
}

.divide-lime-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc1633;
}

.divide-lime-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc1640;
}

.divide-lime-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc164d;
}

.divide-lime-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc1666;
}

.divide-lime-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc160d;
}

.divide-lime-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc1680;
}

.divide-lime-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc1699;
}

.divide-lime-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc16b3;
}

.divide-lime-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc16bf;
}

.divide-lime-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc16cc;
}

.divide-lime-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc16e6;
}

.divide-lime-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #84cc16f2;
}

.divide-lime-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-divide-opacity));
}

.divide-lime-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30d00;
}

.divide-lime-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30d1a;
}

.divide-lime-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30d;
}

.divide-lime-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30d33;
}

.divide-lime-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30d40;
}

.divide-lime-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30d4d;
}

.divide-lime-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30d66;
}

.divide-lime-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30d0d;
}

.divide-lime-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30d80;
}

.divide-lime-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30d99;
}

.divide-lime-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30db3;
}

.divide-lime-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30dbf;
}

.divide-lime-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30dcc;
}

.divide-lime-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30de6;
}

.divide-lime-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #65a30df2;
}

.divide-lime-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-divide-opacity));
}

.divide-lime-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0f00;
}

.divide-lime-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0f1a;
}

.divide-lime-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0f;
}

.divide-lime-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0f33;
}

.divide-lime-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0f40;
}

.divide-lime-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0f4d;
}

.divide-lime-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0f66;
}

.divide-lime-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0f0d;
}

.divide-lime-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0f80;
}

.divide-lime-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0f99;
}

.divide-lime-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0fb3;
}

.divide-lime-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0fbf;
}

.divide-lime-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0fcc;
}

.divide-lime-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0fe6;
}

.divide-lime-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #4d7c0ff2;
}

.divide-lime-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-divide-opacity));
}

.divide-lime-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f621200;
}

.divide-lime-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f62121a;
}

.divide-lime-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f6212;
}

.divide-lime-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f621233;
}

.divide-lime-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f621240;
}

.divide-lime-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f62124d;
}

.divide-lime-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f621266;
}

.divide-lime-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f62120d;
}

.divide-lime-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f621280;
}

.divide-lime-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f621299;
}

.divide-lime-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f6212b3;
}

.divide-lime-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f6212bf;
}

.divide-lime-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f6212cc;
}

.divide-lime-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f6212e6;
}

.divide-lime-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3f6212f2;
}

.divide-lime-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(54 83 20 / var(--tw-divide-opacity));
}

.divide-lime-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #36531400;
}

.divide-lime-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3653141a;
}

.divide-lime-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #365314;
}

.divide-lime-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #36531433;
}

.divide-lime-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #36531440;
}

.divide-lime-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3653144d;
}

.divide-lime-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #36531466;
}

.divide-lime-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3653140d;
}

.divide-lime-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #36531480;
}

.divide-lime-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #36531499;
}

.divide-lime-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #365314b3;
}

.divide-lime-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #365314bf;
}

.divide-lime-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #365314cc;
}

.divide-lime-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #365314e6;
}

.divide-lime-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #365314f2;
}

.divide-lime-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(26 46 5 / var(--tw-divide-opacity));
}

.divide-lime-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e0500;
}

.divide-lime-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e051a;
}

.divide-lime-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e05;
}

.divide-lime-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e0533;
}

.divide-lime-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e0540;
}

.divide-lime-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e054d;
}

.divide-lime-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e0566;
}

.divide-lime-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e050d;
}

.divide-lime-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e0580;
}

.divide-lime-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e0599;
}

.divide-lime-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e05b3;
}

.divide-lime-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e05bf;
}

.divide-lime-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e05cc;
}

.divide-lime-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e05e6;
}

.divide-lime-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1a2e05f2;
}

.divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(252 231 243 / var(--tw-divide-opacity));
}

.divide-pink-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f300;
}

.divide-pink-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f31a;
}

.divide-pink-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f3;
}

.divide-pink-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f333;
}

.divide-pink-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f340;
}

.divide-pink-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f34d;
}

.divide-pink-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f366;
}

.divide-pink-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f30d;
}

.divide-pink-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f380;
}

.divide-pink-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f399;
}

.divide-pink-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f3b3;
}

.divide-pink-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f3bf;
}

.divide-pink-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f3cc;
}

.divide-pink-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f3e6;
}

.divide-pink-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fce7f3f2;
}

.divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(251 207 232 / var(--tw-divide-opacity));
}

.divide-pink-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe800;
}

.divide-pink-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe81a;
}

.divide-pink-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe8;
}

.divide-pink-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe833;
}

.divide-pink-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe840;
}

.divide-pink-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe84d;
}

.divide-pink-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe866;
}

.divide-pink-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe80d;
}

.divide-pink-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe880;
}

.divide-pink-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe899;
}

.divide-pink-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe8b3;
}

.divide-pink-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe8bf;
}

.divide-pink-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe8cc;
}

.divide-pink-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe8e6;
}

.divide-pink-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbcfe8f2;
}

.divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(249 168 212 / var(--tw-divide-opacity));
}

.divide-pink-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d400;
}

.divide-pink-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d41a;
}

.divide-pink-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d4;
}

.divide-pink-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d433;
}

.divide-pink-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d440;
}

.divide-pink-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d44d;
}

.divide-pink-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d466;
}

.divide-pink-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d40d;
}

.divide-pink-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d480;
}

.divide-pink-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d499;
}

.divide-pink-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d4b3;
}

.divide-pink-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d4bf;
}

.divide-pink-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d4cc;
}

.divide-pink-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d4e6;
}

.divide-pink-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9a8d4f2;
}

.divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-divide-opacity));
}

.divide-pink-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b600;
}

.divide-pink-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b61a;
}

.divide-pink-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b6;
}

.divide-pink-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b633;
}

.divide-pink-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b640;
}

.divide-pink-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b64d;
}

.divide-pink-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b666;
}

.divide-pink-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b60d;
}

.divide-pink-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b680;
}

.divide-pink-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b699;
}

.divide-pink-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b6b3;
}

.divide-pink-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b6bf;
}

.divide-pink-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b6cc;
}

.divide-pink-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b6e6;
}

.divide-pink-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f472b6f2;
}

.divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-divide-opacity));
}

.divide-pink-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f800;
}

.divide-pink-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f81a;
}

.divide-pink-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f8;
}

.divide-pink-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f833;
}

.divide-pink-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f840;
}

.divide-pink-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f84d;
}

.divide-pink-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f866;
}

.divide-pink-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f80d;
}

.divide-pink-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f880;
}

.divide-pink-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f899;
}

.divide-pink-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f8b3;
}

.divide-pink-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f8bf;
}

.divide-pink-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f8cc;
}

.divide-pink-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f8e6;
}

.divide-pink-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fdf2f8f2;
}

.divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-divide-opacity));
}

.divide-pink-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec489900;
}

.divide-pink-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec48991a;
}

.divide-pink-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec4899;
}

.divide-pink-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec489933;
}

.divide-pink-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec489940;
}

.divide-pink-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec48994d;
}

.divide-pink-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec489966;
}

.divide-pink-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec48990d;
}

.divide-pink-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec489980;
}

.divide-pink-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec489999;
}

.divide-pink-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec4899b3;
}

.divide-pink-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec4899bf;
}

.divide-pink-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec4899cc;
}

.divide-pink-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec4899e6;
}

.divide-pink-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ec4899f2;
}

.divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-divide-opacity));
}

.divide-pink-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db277700;
}

.divide-pink-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db27771a;
}

.divide-pink-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db2777;
}

.divide-pink-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db277733;
}

.divide-pink-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db277740;
}

.divide-pink-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db27774d;
}

.divide-pink-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db277766;
}

.divide-pink-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db27770d;
}

.divide-pink-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db277780;
}

.divide-pink-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db277799;
}

.divide-pink-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db2777b3;
}

.divide-pink-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db2777bf;
}

.divide-pink-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db2777cc;
}

.divide-pink-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db2777e6;
}

.divide-pink-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #db2777f2;
}

.divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-divide-opacity));
}

.divide-pink-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185d00;
}

.divide-pink-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185d1a;
}

.divide-pink-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185d;
}

.divide-pink-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185d33;
}

.divide-pink-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185d40;
}

.divide-pink-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185d4d;
}

.divide-pink-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185d66;
}

.divide-pink-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185d0d;
}

.divide-pink-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185d80;
}

.divide-pink-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185d99;
}

.divide-pink-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185db3;
}

.divide-pink-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185dbf;
}

.divide-pink-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185dcc;
}

.divide-pink-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185de6;
}

.divide-pink-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be185df2;
}

.divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(157 23 77 / var(--tw-divide-opacity));
}

.divide-pink-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174d00;
}

.divide-pink-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174d1a;
}

.divide-pink-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174d;
}

.divide-pink-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174d33;
}

.divide-pink-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174d40;
}

.divide-pink-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174d4d;
}

.divide-pink-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174d66;
}

.divide-pink-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174d0d;
}

.divide-pink-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174d80;
}

.divide-pink-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174d99;
}

.divide-pink-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174db3;
}

.divide-pink-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174dbf;
}

.divide-pink-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174dcc;
}

.divide-pink-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174de6;
}

.divide-pink-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9d174df2;
}

.divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-divide-opacity));
}

.divide-pink-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #83184300;
}

.divide-pink-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #8318431a;
}

.divide-pink-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #831843;
}

.divide-pink-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #83184333;
}

.divide-pink-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #83184340;
}

.divide-pink-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #8318434d;
}

.divide-pink-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #83184366;
}

.divide-pink-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #8318430d;
}

.divide-pink-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #83184380;
}

.divide-pink-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #83184399;
}

.divide-pink-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #831843b3;
}

.divide-pink-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #831843bf;
}

.divide-pink-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #831843cc;
}

.divide-pink-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #831843e6;
}

.divide-pink-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #831843f2;
}

.divide-pink-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(80 7 36 / var(--tw-divide-opacity));
}

.divide-pink-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #50072400;
}

.divide-pink-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #5007241a;
}

.divide-pink-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #500724;
}

.divide-pink-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #50072433;
}

.divide-pink-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #50072440;
}

.divide-pink-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #5007244d;
}

.divide-pink-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #50072466;
}

.divide-pink-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #5007240d;
}

.divide-pink-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #50072480;
}

.divide-pink-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #50072499;
}

.divide-pink-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #500724b3;
}

.divide-pink-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #500724bf;
}

.divide-pink-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #500724cc;
}

.divide-pink-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #500724e6;
}

.divide-pink-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #500724f2;
}

.divide-primary > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: hsl(var(--primary) / var(--tw-divide-opacity));
}

.divide-primary\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / 0);
}

.divide-primary\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .1);
}

.divide-primary\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / 1);
}

.divide-primary\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .2);
}

.divide-primary\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .25);
}

.divide-primary\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .3);
}

.divide-primary\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .4);
}

.divide-primary\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .05);
}

.divide-primary\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .5);
}

.divide-primary\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .6);
}

.divide-primary\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .7);
}

.divide-primary\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .75);
}

.divide-primary\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .8);
}

.divide-primary\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .9);
}

.divide-primary\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: hsl(var(--primary) / .95);
}

.divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 232 255 / var(--tw-divide-opacity));
}

.divide-purple-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ff00;
}

.divide-purple-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ff1a;
}

.divide-purple-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ff;
}

.divide-purple-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ff33;
}

.divide-purple-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ff40;
}

.divide-purple-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ff4d;
}

.divide-purple-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ff66;
}

.divide-purple-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ff0d;
}

.divide-purple-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ff80;
}

.divide-purple-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ff99;
}

.divide-purple-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ffb3;
}

.divide-purple-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ffbf;
}

.divide-purple-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ffcc;
}

.divide-purple-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8ffe6;
}

.divide-purple-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3e8fff2;
}

.divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(233 213 255 / var(--tw-divide-opacity));
}

.divide-purple-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ff00;
}

.divide-purple-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ff1a;
}

.divide-purple-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ff;
}

.divide-purple-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ff33;
}

.divide-purple-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ff40;
}

.divide-purple-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ff4d;
}

.divide-purple-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ff66;
}

.divide-purple-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ff0d;
}

.divide-purple-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ff80;
}

.divide-purple-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ff99;
}

.divide-purple-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ffb3;
}

.divide-purple-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ffbf;
}

.divide-purple-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ffcc;
}

.divide-purple-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5ffe6;
}

.divide-purple-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e9d5fff2;
}

.divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-divide-opacity));
}

.divide-purple-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fe00;
}

.divide-purple-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fe1a;
}

.divide-purple-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fe;
}

.divide-purple-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fe33;
}

.divide-purple-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fe40;
}

.divide-purple-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fe4d;
}

.divide-purple-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fe66;
}

.divide-purple-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fe0d;
}

.divide-purple-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fe80;
}

.divide-purple-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fe99;
}

.divide-purple-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4feb3;
}

.divide-purple-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4febf;
}

.divide-purple-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fecc;
}

.divide-purple-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fee6;
}

.divide-purple-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8b4fef2;
}

.divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-divide-opacity));
}

.divide-purple-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fc00;
}

.divide-purple-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fc1a;
}

.divide-purple-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fc;
}

.divide-purple-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fc33;
}

.divide-purple-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fc40;
}

.divide-purple-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fc4d;
}

.divide-purple-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fc66;
}

.divide-purple-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fc0d;
}

.divide-purple-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fc80;
}

.divide-purple-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fc99;
}

.divide-purple-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fcb3;
}

.divide-purple-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fcbf;
}

.divide-purple-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fccc;
}

.divide-purple-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fce6;
}

.divide-purple-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c084fcf2;
}

.divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(250 245 255 / var(--tw-divide-opacity));
}

.divide-purple-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ff00;
}

.divide-purple-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ff1a;
}

.divide-purple-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ff;
}

.divide-purple-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ff33;
}

.divide-purple-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ff40;
}

.divide-purple-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ff4d;
}

.divide-purple-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ff66;
}

.divide-purple-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ff0d;
}

.divide-purple-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ff80;
}

.divide-purple-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ff99;
}

.divide-purple-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ffb3;
}

.divide-purple-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ffbf;
}

.divide-purple-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ffcc;
}

.divide-purple-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5ffe6;
}

.divide-purple-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #faf5fff2;
}

.divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-divide-opacity));
}

.divide-purple-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f700;
}

.divide-purple-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f71a;
}

.divide-purple-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f7;
}

.divide-purple-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f733;
}

.divide-purple-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f740;
}

.divide-purple-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f74d;
}

.divide-purple-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f766;
}

.divide-purple-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f70d;
}

.divide-purple-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f780;
}

.divide-purple-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f799;
}

.divide-purple-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f7b3;
}

.divide-purple-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f7bf;
}

.divide-purple-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f7cc;
}

.divide-purple-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f7e6;
}

.divide-purple-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #a855f7f2;
}

.divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-divide-opacity));
}

.divide-purple-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333ea00;
}

.divide-purple-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333ea1a;
}

.divide-purple-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333ea;
}

.divide-purple-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333ea33;
}

.divide-purple-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333ea40;
}

.divide-purple-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333ea4d;
}

.divide-purple-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333ea66;
}

.divide-purple-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333ea0d;
}

.divide-purple-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333ea80;
}

.divide-purple-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333ea99;
}

.divide-purple-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333eab3;
}

.divide-purple-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333eabf;
}

.divide-purple-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333eacc;
}

.divide-purple-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333eae6;
}

.divide-purple-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #9333eaf2;
}

.divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-divide-opacity));
}

.divide-purple-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ce00;
}

.divide-purple-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ce1a;
}

.divide-purple-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ce;
}

.divide-purple-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ce33;
}

.divide-purple-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ce40;
}

.divide-purple-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ce4d;
}

.divide-purple-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ce66;
}

.divide-purple-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ce0d;
}

.divide-purple-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ce80;
}

.divide-purple-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ce99;
}

.divide-purple-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22ceb3;
}

.divide-purple-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22cebf;
}

.divide-purple-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22cecc;
}

.divide-purple-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22cee6;
}

.divide-purple-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7e22cef2;
}

.divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(107 33 168 / var(--tw-divide-opacity));
}

.divide-purple-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a800;
}

.divide-purple-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a81a;
}

.divide-purple-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a8;
}

.divide-purple-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a833;
}

.divide-purple-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a840;
}

.divide-purple-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a84d;
}

.divide-purple-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a866;
}

.divide-purple-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a80d;
}

.divide-purple-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a880;
}

.divide-purple-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a899;
}

.divide-purple-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a8b3;
}

.divide-purple-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a8bf;
}

.divide-purple-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a8cc;
}

.divide-purple-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a8e6;
}

.divide-purple-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #6b21a8f2;
}

.divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(88 28 135 / var(--tw-divide-opacity));
}

.divide-purple-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c8700;
}

.divide-purple-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c871a;
}

.divide-purple-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c87;
}

.divide-purple-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c8733;
}

.divide-purple-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c8740;
}

.divide-purple-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c874d;
}

.divide-purple-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c8766;
}

.divide-purple-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c870d;
}

.divide-purple-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c8780;
}

.divide-purple-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c8799;
}

.divide-purple-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c87b3;
}

.divide-purple-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c87bf;
}

.divide-purple-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c87cc;
}

.divide-purple-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c87e6;
}

.divide-purple-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #581c87f2;
}

.divide-purple-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(59 7 100 / var(--tw-divide-opacity));
}

.divide-purple-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b076400;
}

.divide-purple-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b07641a;
}

.divide-purple-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b0764;
}

.divide-purple-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b076433;
}

.divide-purple-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b076440;
}

.divide-purple-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b07644d;
}

.divide-purple-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b076466;
}

.divide-purple-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b07640d;
}

.divide-purple-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b076480;
}

.divide-purple-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b076499;
}

.divide-purple-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b0764b3;
}

.divide-purple-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b0764bf;
}

.divide-purple-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b0764cc;
}

.divide-purple-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b0764e6;
}

.divide-purple-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b0764f2;
}

.divide-transparent > :not([hidden]) ~ :not([hidden]), .divide-transparent\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000;
}

.divide-transparent\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000001a;
}

.divide-transparent\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000;
}

.divide-transparent\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0003;
}

.divide-transparent\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00000040;
}

.divide-transparent\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000004d;
}

.divide-transparent\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0006;
}

.divide-transparent\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000000d;
}

.divide-transparent\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00000080;
}

.divide-transparent\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0009;
}

.divide-transparent\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000b3;
}

.divide-transparent\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000bf;
}

.divide-transparent\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000c;
}

.divide-transparent\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000e6;
}

.divide-transparent\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000f2;
}

.divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0;
}

.divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .1;
}

.divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
}

.divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .2;
}

.divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .25;
}

.divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .3;
}

.divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .4;
}

.divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .05;
}

.divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .5;
}

.divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .6;
}

.divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .7;
}

.divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .75;
}

.divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .8;
}

.divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .9;
}

.divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .95;
}

.place-self-auto {
  place-self: auto;
}

.place-self-start {
  place-self: start;
}

.place-self-end {
  place-self: end;
}

.place-self-center {
  place-self: center;
}

.place-self-stretch {
  place-self: stretch stretch;
}

.self-end {
  align-self: flex-end;
}

.overflow-clip {
  overflow: clip;
}

.overflow-x-clip {
  overflow-x: clip;
}

.overflow-y-clip {
  overflow-y: clip;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.text-clip {
  text-overflow: clip;
}

.hyphens-none {
  -webkit-hyphens: none;
  hyphens: none;
}

.hyphens-manual {
  -webkit-hyphens: manual;
  hyphens: manual;
}

.hyphens-auto {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.whitespace-break-spaces {
  white-space: break-spaces;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-keep {
  word-break: keep-all;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-b-sm {
  border-bottom-right-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.rounded-e-md {
  border-start-end-radius: .375rem;
  border-end-end-radius: .375rem;
}

.rounded-e-sm {
  border-start-end-radius: .125rem;
  border-end-end-radius: .125rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-l-sm {
  border-top-left-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-r-sm {
  border-top-right-radius: .125rem;
  border-bottom-right-radius: .125rem;
}

.rounded-s-md {
  border-start-start-radius: .375rem;
  border-end-start-radius: .375rem;
}

.rounded-s-sm {
  border-start-start-radius: .125rem;
  border-end-start-radius: .125rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-t-sm {
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-bl-sm {
  border-bottom-left-radius: .125rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-br-sm {
  border-bottom-right-radius: .125rem;
}

.rounded-ee-md {
  border-end-end-radius: .375rem;
}

.rounded-ee-sm {
  border-end-end-radius: .125rem;
}

.rounded-es-md {
  border-end-start-radius: .375rem;
}

.rounded-es-sm {
  border-end-start-radius: .125rem;
}

.rounded-se-md {
  border-start-end-radius: .375rem;
}

.rounded-se-sm {
  border-start-end-radius: .125rem;
}

.rounded-ss-md {
  border-start-start-radius: .375rem;
}

.rounded-ss-sm {
  border-start-start-radius: .125rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tl-sm {
  border-top-left-radius: .125rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.rounded-tr-sm {
  border-top-right-radius: .125rem;
}

.border-l {
  border-left-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-amber-100\/0 {
  border-color: #fef3c700;
}

.border-amber-100\/10 {
  border-color: #fef3c71a;
}

.border-amber-100\/100 {
  border-color: #fef3c7;
}

.border-amber-100\/20 {
  border-color: #fef3c733;
}

.border-amber-100\/25 {
  border-color: #fef3c740;
}

.border-amber-100\/30 {
  border-color: #fef3c74d;
}

.border-amber-100\/40 {
  border-color: #fef3c766;
}

.border-amber-100\/5 {
  border-color: #fef3c70d;
}

.border-amber-100\/50 {
  border-color: #fef3c780;
}

.border-amber-100\/60 {
  border-color: #fef3c799;
}

.border-amber-100\/70 {
  border-color: #fef3c7b3;
}

.border-amber-100\/75 {
  border-color: #fef3c7bf;
}

.border-amber-100\/80 {
  border-color: #fef3c7cc;
}

.border-amber-100\/90 {
  border-color: #fef3c7e6;
}

.border-amber-100\/95 {
  border-color: #fef3c7f2;
}

.border-amber-200 {
  --tw-border-opacity: 1;
  border-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-amber-200\/0 {
  border-color: #fde68a00;
}

.border-amber-200\/10 {
  border-color: #fde68a1a;
}

.border-amber-200\/100 {
  border-color: #fde68a;
}

.border-amber-200\/20 {
  border-color: #fde68a33;
}

.border-amber-200\/25 {
  border-color: #fde68a40;
}

.border-amber-200\/30 {
  border-color: #fde68a4d;
}

.border-amber-200\/40 {
  border-color: #fde68a66;
}

.border-amber-200\/5 {
  border-color: #fde68a0d;
}

.border-amber-200\/50 {
  border-color: #fde68a80;
}

.border-amber-200\/60 {
  border-color: #fde68a99;
}

.border-amber-200\/70 {
  border-color: #fde68ab3;
}

.border-amber-200\/75 {
  border-color: #fde68abf;
}

.border-amber-200\/80 {
  border-color: #fde68acc;
}

.border-amber-200\/90 {
  border-color: #fde68ae6;
}

.border-amber-200\/95 {
  border-color: #fde68af2;
}

.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-amber-300\/0 {
  border-color: #fcd34d00;
}

.border-amber-300\/10 {
  border-color: #fcd34d1a;
}

.border-amber-300\/100 {
  border-color: #fcd34d;
}

.border-amber-300\/20 {
  border-color: #fcd34d33;
}

.border-amber-300\/25 {
  border-color: #fcd34d40;
}

.border-amber-300\/30 {
  border-color: #fcd34d4d;
}

.border-amber-300\/40 {
  border-color: #fcd34d66;
}

.border-amber-300\/5 {
  border-color: #fcd34d0d;
}

.border-amber-300\/50 {
  border-color: #fcd34d80;
}

.border-amber-300\/60 {
  border-color: #fcd34d99;
}

.border-amber-300\/70 {
  border-color: #fcd34db3;
}

.border-amber-300\/75 {
  border-color: #fcd34dbf;
}

.border-amber-300\/80 {
  border-color: #fcd34dcc;
}

.border-amber-300\/90 {
  border-color: #fcd34de6;
}

.border-amber-300\/95 {
  border-color: #fcd34df2;
}

.border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-amber-400\/0 {
  border-color: #fbbf2400;
}

.border-amber-400\/10 {
  border-color: #fbbf241a;
}

.border-amber-400\/100 {
  border-color: #fbbf24;
}

.border-amber-400\/20 {
  border-color: #fbbf2433;
}

.border-amber-400\/25 {
  border-color: #fbbf2440;
}

.border-amber-400\/30 {
  border-color: #fbbf244d;
}

.border-amber-400\/40 {
  border-color: #fbbf2466;
}

.border-amber-400\/5 {
  border-color: #fbbf240d;
}

.border-amber-400\/50 {
  border-color: #fbbf2480;
}

.border-amber-400\/60 {
  border-color: #fbbf2499;
}

.border-amber-400\/70 {
  border-color: #fbbf24b3;
}

.border-amber-400\/75 {
  border-color: #fbbf24bf;
}

.border-amber-400\/80 {
  border-color: #fbbf24cc;
}

.border-amber-400\/90 {
  border-color: #fbbf24e6;
}

.border-amber-400\/95 {
  border-color: #fbbf24f2;
}

.border-amber-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-amber-50\/0 {
  border-color: #fffbeb00;
}

.border-amber-50\/10 {
  border-color: #fffbeb1a;
}

.border-amber-50\/100 {
  border-color: #fffbeb;
}

.border-amber-50\/20 {
  border-color: #fffbeb33;
}

.border-amber-50\/25 {
  border-color: #fffbeb40;
}

.border-amber-50\/30 {
  border-color: #fffbeb4d;
}

.border-amber-50\/40 {
  border-color: #fffbeb66;
}

.border-amber-50\/5 {
  border-color: #fffbeb0d;
}

.border-amber-50\/50 {
  border-color: #fffbeb80;
}

.border-amber-50\/60 {
  border-color: #fffbeb99;
}

.border-amber-50\/70 {
  border-color: #fffbebb3;
}

.border-amber-50\/75 {
  border-color: #fffbebbf;
}

.border-amber-50\/80 {
  border-color: #fffbebcc;
}

.border-amber-50\/90 {
  border-color: #fffbebe6;
}

.border-amber-50\/95 {
  border-color: #fffbebf2;
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-amber-500\/0 {
  border-color: #f59e0b00;
}

.border-amber-500\/10 {
  border-color: #f59e0b1a;
}

.border-amber-500\/100 {
  border-color: #f59e0b;
}

.border-amber-500\/20 {
  border-color: #f59e0b33;
}

.border-amber-500\/25 {
  border-color: #f59e0b40;
}

.border-amber-500\/30 {
  border-color: #f59e0b4d;
}

.border-amber-500\/40 {
  border-color: #f59e0b66;
}

.border-amber-500\/5 {
  border-color: #f59e0b0d;
}

.border-amber-500\/50 {
  border-color: #f59e0b80;
}

.border-amber-500\/60 {
  border-color: #f59e0b99;
}

.border-amber-500\/70 {
  border-color: #f59e0bb3;
}

.border-amber-500\/75 {
  border-color: #f59e0bbf;
}

.border-amber-500\/80 {
  border-color: #f59e0bcc;
}

.border-amber-500\/90 {
  border-color: #f59e0be6;
}

.border-amber-500\/95 {
  border-color: #f59e0bf2;
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-amber-600\/0 {
  border-color: #d9770600;
}

.border-amber-600\/10 {
  border-color: #d977061a;
}

.border-amber-600\/100 {
  border-color: #d97706;
}

.border-amber-600\/20 {
  border-color: #d9770633;
}

.border-amber-600\/25 {
  border-color: #d9770640;
}

.border-amber-600\/30 {
  border-color: #d977064d;
}

.border-amber-600\/40 {
  border-color: #d9770666;
}

.border-amber-600\/5 {
  border-color: #d977060d;
}

.border-amber-600\/50 {
  border-color: #d9770680;
}

.border-amber-600\/60 {
  border-color: #d9770699;
}

.border-amber-600\/70 {
  border-color: #d97706b3;
}

.border-amber-600\/75 {
  border-color: #d97706bf;
}

.border-amber-600\/80 {
  border-color: #d97706cc;
}

.border-amber-600\/90 {
  border-color: #d97706e6;
}

.border-amber-600\/95 {
  border-color: #d97706f2;
}

.border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-amber-700\/0 {
  border-color: #b4530900;
}

.border-amber-700\/10 {
  border-color: #b453091a;
}

.border-amber-700\/100 {
  border-color: #b45309;
}

.border-amber-700\/20 {
  border-color: #b4530933;
}

.border-amber-700\/25 {
  border-color: #b4530940;
}

.border-amber-700\/30 {
  border-color: #b453094d;
}

.border-amber-700\/40 {
  border-color: #b4530966;
}

.border-amber-700\/5 {
  border-color: #b453090d;
}

.border-amber-700\/50 {
  border-color: #b4530980;
}

.border-amber-700\/60 {
  border-color: #b4530999;
}

.border-amber-700\/70 {
  border-color: #b45309b3;
}

.border-amber-700\/75 {
  border-color: #b45309bf;
}

.border-amber-700\/80 {
  border-color: #b45309cc;
}

.border-amber-700\/90 {
  border-color: #b45309e6;
}

.border-amber-700\/95 {
  border-color: #b45309f2;
}

.border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-amber-800\/0 {
  border-color: #92400e00;
}

.border-amber-800\/10 {
  border-color: #92400e1a;
}

.border-amber-800\/100 {
  border-color: #92400e;
}

.border-amber-800\/20 {
  border-color: #92400e33;
}

.border-amber-800\/25 {
  border-color: #92400e40;
}

.border-amber-800\/30 {
  border-color: #92400e4d;
}

.border-amber-800\/40 {
  border-color: #92400e66;
}

.border-amber-800\/5 {
  border-color: #92400e0d;
}

.border-amber-800\/50 {
  border-color: #92400e80;
}

.border-amber-800\/60 {
  border-color: #92400e99;
}

.border-amber-800\/70 {
  border-color: #92400eb3;
}

.border-amber-800\/75 {
  border-color: #92400ebf;
}

.border-amber-800\/80 {
  border-color: #92400ecc;
}

.border-amber-800\/90 {
  border-color: #92400ee6;
}

.border-amber-800\/95 {
  border-color: #92400ef2;
}

.border-amber-900 {
  --tw-border-opacity: 1;
  border-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-amber-900\/0 {
  border-color: #78350f00;
}

.border-amber-900\/10 {
  border-color: #78350f1a;
}

.border-amber-900\/100 {
  border-color: #78350f;
}

.border-amber-900\/20 {
  border-color: #78350f33;
}

.border-amber-900\/25 {
  border-color: #78350f40;
}

.border-amber-900\/30 {
  border-color: #78350f4d;
}

.border-amber-900\/40 {
  border-color: #78350f66;
}

.border-amber-900\/5 {
  border-color: #78350f0d;
}

.border-amber-900\/50 {
  border-color: #78350f80;
}

.border-amber-900\/60 {
  border-color: #78350f99;
}

.border-amber-900\/70 {
  border-color: #78350fb3;
}

.border-amber-900\/75 {
  border-color: #78350fbf;
}

.border-amber-900\/80 {
  border-color: #78350fcc;
}

.border-amber-900\/90 {
  border-color: #78350fe6;
}

.border-amber-900\/95 {
  border-color: #78350ff2;
}

.border-amber-950 {
  --tw-border-opacity: 1;
  border-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-amber-950\/0 {
  border-color: #451a0300;
}

.border-amber-950\/10 {
  border-color: #451a031a;
}

.border-amber-950\/100 {
  border-color: #451a03;
}

.border-amber-950\/20 {
  border-color: #451a0333;
}

.border-amber-950\/25 {
  border-color: #451a0340;
}

.border-amber-950\/30 {
  border-color: #451a034d;
}

.border-amber-950\/40 {
  border-color: #451a0366;
}

.border-amber-950\/5 {
  border-color: #451a030d;
}

.border-amber-950\/50 {
  border-color: #451a0380;
}

.border-amber-950\/60 {
  border-color: #451a0399;
}

.border-amber-950\/70 {
  border-color: #451a03b3;
}

.border-amber-950\/75 {
  border-color: #451a03bf;
}

.border-amber-950\/80 {
  border-color: #451a03cc;
}

.border-amber-950\/90 {
  border-color: #451a03e6;
}

.border-amber-950\/95 {
  border-color: #451a03f2;
}

.border-emerald-100 {
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-emerald-100\/0 {
  border-color: #d1fae500;
}

.border-emerald-100\/10 {
  border-color: #d1fae51a;
}

.border-emerald-100\/100 {
  border-color: #d1fae5;
}

.border-emerald-100\/20 {
  border-color: #d1fae533;
}

.border-emerald-100\/25 {
  border-color: #d1fae540;
}

.border-emerald-100\/30 {
  border-color: #d1fae54d;
}

.border-emerald-100\/40 {
  border-color: #d1fae566;
}

.border-emerald-100\/5 {
  border-color: #d1fae50d;
}

.border-emerald-100\/50 {
  border-color: #d1fae580;
}

.border-emerald-100\/60 {
  border-color: #d1fae599;
}

.border-emerald-100\/70 {
  border-color: #d1fae5b3;
}

.border-emerald-100\/75 {
  border-color: #d1fae5bf;
}

.border-emerald-100\/80 {
  border-color: #d1fae5cc;
}

.border-emerald-100\/90 {
  border-color: #d1fae5e6;
}

.border-emerald-100\/95 {
  border-color: #d1fae5f2;
}

.border-emerald-200 {
  --tw-border-opacity: 1;
  border-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-emerald-200\/0 {
  border-color: #a7f3d000;
}

.border-emerald-200\/10 {
  border-color: #a7f3d01a;
}

.border-emerald-200\/100 {
  border-color: #a7f3d0;
}

.border-emerald-200\/20 {
  border-color: #a7f3d033;
}

.border-emerald-200\/25 {
  border-color: #a7f3d040;
}

.border-emerald-200\/30 {
  border-color: #a7f3d04d;
}

.border-emerald-200\/40 {
  border-color: #a7f3d066;
}

.border-emerald-200\/5 {
  border-color: #a7f3d00d;
}

.border-emerald-200\/50 {
  border-color: #a7f3d080;
}

.border-emerald-200\/60 {
  border-color: #a7f3d099;
}

.border-emerald-200\/70 {
  border-color: #a7f3d0b3;
}

.border-emerald-200\/75 {
  border-color: #a7f3d0bf;
}

.border-emerald-200\/80 {
  border-color: #a7f3d0cc;
}

.border-emerald-200\/90 {
  border-color: #a7f3d0e6;
}

.border-emerald-200\/95 {
  border-color: #a7f3d0f2;
}

.border-emerald-300 {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-emerald-300\/0 {
  border-color: #6ee7b700;
}

.border-emerald-300\/10 {
  border-color: #6ee7b71a;
}

.border-emerald-300\/100 {
  border-color: #6ee7b7;
}

.border-emerald-300\/20 {
  border-color: #6ee7b733;
}

.border-emerald-300\/25 {
  border-color: #6ee7b740;
}

.border-emerald-300\/30 {
  border-color: #6ee7b74d;
}

.border-emerald-300\/40 {
  border-color: #6ee7b766;
}

.border-emerald-300\/5 {
  border-color: #6ee7b70d;
}

.border-emerald-300\/50 {
  border-color: #6ee7b780;
}

.border-emerald-300\/60 {
  border-color: #6ee7b799;
}

.border-emerald-300\/70 {
  border-color: #6ee7b7b3;
}

.border-emerald-300\/75 {
  border-color: #6ee7b7bf;
}

.border-emerald-300\/80 {
  border-color: #6ee7b7cc;
}

.border-emerald-300\/90 {
  border-color: #6ee7b7e6;
}

.border-emerald-300\/95 {
  border-color: #6ee7b7f2;
}

.border-emerald-400 {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-emerald-400\/0 {
  border-color: #34d39900;
}

.border-emerald-400\/10 {
  border-color: #34d3991a;
}

.border-emerald-400\/100 {
  border-color: #34d399;
}

.border-emerald-400\/20 {
  border-color: #34d39933;
}

.border-emerald-400\/25 {
  border-color: #34d39940;
}

.border-emerald-400\/30 {
  border-color: #34d3994d;
}

.border-emerald-400\/40 {
  border-color: #34d39966;
}

.border-emerald-400\/5 {
  border-color: #34d3990d;
}

.border-emerald-400\/50 {
  border-color: #34d39980;
}

.border-emerald-400\/60 {
  border-color: #34d39999;
}

.border-emerald-400\/70 {
  border-color: #34d399b3;
}

.border-emerald-400\/75 {
  border-color: #34d399bf;
}

.border-emerald-400\/80 {
  border-color: #34d399cc;
}

.border-emerald-400\/90 {
  border-color: #34d399e6;
}

.border-emerald-400\/95 {
  border-color: #34d399f2;
}

.border-emerald-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-emerald-50\/0 {
  border-color: #ecfdf500;
}

.border-emerald-50\/10 {
  border-color: #ecfdf51a;
}

.border-emerald-50\/100 {
  border-color: #ecfdf5;
}

.border-emerald-50\/20 {
  border-color: #ecfdf533;
}

.border-emerald-50\/25 {
  border-color: #ecfdf540;
}

.border-emerald-50\/30 {
  border-color: #ecfdf54d;
}

.border-emerald-50\/40 {
  border-color: #ecfdf566;
}

.border-emerald-50\/5 {
  border-color: #ecfdf50d;
}

.border-emerald-50\/50 {
  border-color: #ecfdf580;
}

.border-emerald-50\/60 {
  border-color: #ecfdf599;
}

.border-emerald-50\/70 {
  border-color: #ecfdf5b3;
}

.border-emerald-50\/75 {
  border-color: #ecfdf5bf;
}

.border-emerald-50\/80 {
  border-color: #ecfdf5cc;
}

.border-emerald-50\/90 {
  border-color: #ecfdf5e6;
}

.border-emerald-50\/95 {
  border-color: #ecfdf5f2;
}

.border-emerald-500 {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-emerald-500\/0 {
  border-color: #10b98100;
}

.border-emerald-500\/10 {
  border-color: #10b9811a;
}

.border-emerald-500\/100 {
  border-color: #10b981;
}

.border-emerald-500\/20 {
  border-color: #10b98133;
}

.border-emerald-500\/25 {
  border-color: #10b98140;
}

.border-emerald-500\/30 {
  border-color: #10b9814d;
}

.border-emerald-500\/40 {
  border-color: #10b98166;
}

.border-emerald-500\/5 {
  border-color: #10b9810d;
}

.border-emerald-500\/50 {
  border-color: #10b98180;
}

.border-emerald-500\/60 {
  border-color: #10b98199;
}

.border-emerald-500\/70 {
  border-color: #10b981b3;
}

.border-emerald-500\/75 {
  border-color: #10b981bf;
}

.border-emerald-500\/80 {
  border-color: #10b981cc;
}

.border-emerald-500\/90 {
  border-color: #10b981e6;
}

.border-emerald-500\/95 {
  border-color: #10b981f2;
}

.border-emerald-600 {
  --tw-border-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-emerald-600\/0 {
  border-color: #05966900;
}

.border-emerald-600\/10 {
  border-color: #0596691a;
}

.border-emerald-600\/100 {
  border-color: #059669;
}

.border-emerald-600\/20 {
  border-color: #05966933;
}

.border-emerald-600\/25 {
  border-color: #05966940;
}

.border-emerald-600\/30 {
  border-color: #0596694d;
}

.border-emerald-600\/40 {
  border-color: #05966966;
}

.border-emerald-600\/5 {
  border-color: #0596690d;
}

.border-emerald-600\/50 {
  border-color: #05966980;
}

.border-emerald-600\/60 {
  border-color: #05966999;
}

.border-emerald-600\/70 {
  border-color: #059669b3;
}

.border-emerald-600\/75 {
  border-color: #059669bf;
}

.border-emerald-600\/80 {
  border-color: #059669cc;
}

.border-emerald-600\/90 {
  border-color: #059669e6;
}

.border-emerald-600\/95 {
  border-color: #059669f2;
}

.border-emerald-700 {
  --tw-border-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-emerald-700\/0 {
  border-color: #04785700;
}

.border-emerald-700\/10 {
  border-color: #0478571a;
}

.border-emerald-700\/100 {
  border-color: #047857;
}

.border-emerald-700\/20 {
  border-color: #04785733;
}

.border-emerald-700\/25 {
  border-color: #04785740;
}

.border-emerald-700\/30 {
  border-color: #0478574d;
}

.border-emerald-700\/40 {
  border-color: #04785766;
}

.border-emerald-700\/5 {
  border-color: #0478570d;
}

.border-emerald-700\/50 {
  border-color: #04785780;
}

.border-emerald-700\/60 {
  border-color: #04785799;
}

.border-emerald-700\/70 {
  border-color: #047857b3;
}

.border-emerald-700\/75 {
  border-color: #047857bf;
}

.border-emerald-700\/80 {
  border-color: #047857cc;
}

.border-emerald-700\/90 {
  border-color: #047857e6;
}

.border-emerald-700\/95 {
  border-color: #047857f2;
}

.border-emerald-800 {
  --tw-border-opacity: 1;
  border-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-emerald-800\/0 {
  border-color: #065f4600;
}

.border-emerald-800\/10 {
  border-color: #065f461a;
}

.border-emerald-800\/100 {
  border-color: #065f46;
}

.border-emerald-800\/20 {
  border-color: #065f4633;
}

.border-emerald-800\/25 {
  border-color: #065f4640;
}

.border-emerald-800\/30 {
  border-color: #065f464d;
}

.border-emerald-800\/40 {
  border-color: #065f4666;
}

.border-emerald-800\/5 {
  border-color: #065f460d;
}

.border-emerald-800\/50 {
  border-color: #065f4680;
}

.border-emerald-800\/60 {
  border-color: #065f4699;
}

.border-emerald-800\/70 {
  border-color: #065f46b3;
}

.border-emerald-800\/75 {
  border-color: #065f46bf;
}

.border-emerald-800\/80 {
  border-color: #065f46cc;
}

.border-emerald-800\/90 {
  border-color: #065f46e6;
}

.border-emerald-800\/95 {
  border-color: #065f46f2;
}

.border-emerald-900 {
  --tw-border-opacity: 1;
  border-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-emerald-900\/0 {
  border-color: #064e3b00;
}

.border-emerald-900\/10 {
  border-color: #064e3b1a;
}

.border-emerald-900\/100 {
  border-color: #064e3b;
}

.border-emerald-900\/20 {
  border-color: #064e3b33;
}

.border-emerald-900\/25 {
  border-color: #064e3b40;
}

.border-emerald-900\/30 {
  border-color: #064e3b4d;
}

.border-emerald-900\/40 {
  border-color: #064e3b66;
}

.border-emerald-900\/5 {
  border-color: #064e3b0d;
}

.border-emerald-900\/50 {
  border-color: #064e3b80;
}

.border-emerald-900\/60 {
  border-color: #064e3b99;
}

.border-emerald-900\/70 {
  border-color: #064e3bb3;
}

.border-emerald-900\/75 {
  border-color: #064e3bbf;
}

.border-emerald-900\/80 {
  border-color: #064e3bcc;
}

.border-emerald-900\/90 {
  border-color: #064e3be6;
}

.border-emerald-900\/95 {
  border-color: #064e3bf2;
}

.border-emerald-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-emerald-950\/0 {
  border-color: #022c2200;
}

.border-emerald-950\/10 {
  border-color: #022c221a;
}

.border-emerald-950\/100 {
  border-color: #022c22;
}

.border-emerald-950\/20 {
  border-color: #022c2233;
}

.border-emerald-950\/25 {
  border-color: #022c2240;
}

.border-emerald-950\/30 {
  border-color: #022c224d;
}

.border-emerald-950\/40 {
  border-color: #022c2266;
}

.border-emerald-950\/5 {
  border-color: #022c220d;
}

.border-emerald-950\/50 {
  border-color: #022c2280;
}

.border-emerald-950\/60 {
  border-color: #022c2299;
}

.border-emerald-950\/70 {
  border-color: #022c22b3;
}

.border-emerald-950\/75 {
  border-color: #022c22bf;
}

.border-emerald-950\/80 {
  border-color: #022c22cc;
}

.border-emerald-950\/90 {
  border-color: #022c22e6;
}

.border-emerald-950\/95 {
  border-color: #022c22f2;
}

.border-lime-100 {
  --tw-border-opacity: 1;
  border-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-lime-100\/0 {
  border-color: #ecfccb00;
}

.border-lime-100\/10 {
  border-color: #ecfccb1a;
}

.border-lime-100\/100 {
  border-color: #ecfccb;
}

.border-lime-100\/20 {
  border-color: #ecfccb33;
}

.border-lime-100\/25 {
  border-color: #ecfccb40;
}

.border-lime-100\/30 {
  border-color: #ecfccb4d;
}

.border-lime-100\/40 {
  border-color: #ecfccb66;
}

.border-lime-100\/5 {
  border-color: #ecfccb0d;
}

.border-lime-100\/50 {
  border-color: #ecfccb80;
}

.border-lime-100\/60 {
  border-color: #ecfccb99;
}

.border-lime-100\/70 {
  border-color: #ecfccbb3;
}

.border-lime-100\/75 {
  border-color: #ecfccbbf;
}

.border-lime-100\/80 {
  border-color: #ecfccbcc;
}

.border-lime-100\/90 {
  border-color: #ecfccbe6;
}

.border-lime-100\/95 {
  border-color: #ecfccbf2;
}

.border-lime-200 {
  --tw-border-opacity: 1;
  border-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-lime-200\/0 {
  border-color: #d9f99d00;
}

.border-lime-200\/10 {
  border-color: #d9f99d1a;
}

.border-lime-200\/100 {
  border-color: #d9f99d;
}

.border-lime-200\/20 {
  border-color: #d9f99d33;
}

.border-lime-200\/25 {
  border-color: #d9f99d40;
}

.border-lime-200\/30 {
  border-color: #d9f99d4d;
}

.border-lime-200\/40 {
  border-color: #d9f99d66;
}

.border-lime-200\/5 {
  border-color: #d9f99d0d;
}

.border-lime-200\/50 {
  border-color: #d9f99d80;
}

.border-lime-200\/60 {
  border-color: #d9f99d99;
}

.border-lime-200\/70 {
  border-color: #d9f99db3;
}

.border-lime-200\/75 {
  border-color: #d9f99dbf;
}

.border-lime-200\/80 {
  border-color: #d9f99dcc;
}

.border-lime-200\/90 {
  border-color: #d9f99de6;
}

.border-lime-200\/95 {
  border-color: #d9f99df2;
}

.border-lime-300 {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-lime-300\/0 {
  border-color: #bef26400;
}

.border-lime-300\/10 {
  border-color: #bef2641a;
}

.border-lime-300\/100 {
  border-color: #bef264;
}

.border-lime-300\/20 {
  border-color: #bef26433;
}

.border-lime-300\/25 {
  border-color: #bef26440;
}

.border-lime-300\/30 {
  border-color: #bef2644d;
}

.border-lime-300\/40 {
  border-color: #bef26466;
}

.border-lime-300\/5 {
  border-color: #bef2640d;
}

.border-lime-300\/50 {
  border-color: #bef26480;
}

.border-lime-300\/60 {
  border-color: #bef26499;
}

.border-lime-300\/70 {
  border-color: #bef264b3;
}

.border-lime-300\/75 {
  border-color: #bef264bf;
}

.border-lime-300\/80 {
  border-color: #bef264cc;
}

.border-lime-300\/90 {
  border-color: #bef264e6;
}

.border-lime-300\/95 {
  border-color: #bef264f2;
}

.border-lime-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-lime-400\/0 {
  border-color: #a3e63500;
}

.border-lime-400\/10 {
  border-color: #a3e6351a;
}

.border-lime-400\/100 {
  border-color: #a3e635;
}

.border-lime-400\/20 {
  border-color: #a3e63533;
}

.border-lime-400\/25 {
  border-color: #a3e63540;
}

.border-lime-400\/30 {
  border-color: #a3e6354d;
}

.border-lime-400\/40 {
  border-color: #a3e63566;
}

.border-lime-400\/5 {
  border-color: #a3e6350d;
}

.border-lime-400\/50 {
  border-color: #a3e63580;
}

.border-lime-400\/60 {
  border-color: #a3e63599;
}

.border-lime-400\/70 {
  border-color: #a3e635b3;
}

.border-lime-400\/75 {
  border-color: #a3e635bf;
}

.border-lime-400\/80 {
  border-color: #a3e635cc;
}

.border-lime-400\/90 {
  border-color: #a3e635e6;
}

.border-lime-400\/95 {
  border-color: #a3e635f2;
}

.border-lime-50 {
  --tw-border-opacity: 1;
  border-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-lime-50\/0 {
  border-color: #f7fee700;
}

.border-lime-50\/10 {
  border-color: #f7fee71a;
}

.border-lime-50\/100 {
  border-color: #f7fee7;
}

.border-lime-50\/20 {
  border-color: #f7fee733;
}

.border-lime-50\/25 {
  border-color: #f7fee740;
}

.border-lime-50\/30 {
  border-color: #f7fee74d;
}

.border-lime-50\/40 {
  border-color: #f7fee766;
}

.border-lime-50\/5 {
  border-color: #f7fee70d;
}

.border-lime-50\/50 {
  border-color: #f7fee780;
}

.border-lime-50\/60 {
  border-color: #f7fee799;
}

.border-lime-50\/70 {
  border-color: #f7fee7b3;
}

.border-lime-50\/75 {
  border-color: #f7fee7bf;
}

.border-lime-50\/80 {
  border-color: #f7fee7cc;
}

.border-lime-50\/90 {
  border-color: #f7fee7e6;
}

.border-lime-50\/95 {
  border-color: #f7fee7f2;
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-lime-500\/0 {
  border-color: #84cc1600;
}

.border-lime-500\/10 {
  border-color: #84cc161a;
}

.border-lime-500\/100 {
  border-color: #84cc16;
}

.border-lime-500\/20 {
  border-color: #84cc1633;
}

.border-lime-500\/25 {
  border-color: #84cc1640;
}

.border-lime-500\/30 {
  border-color: #84cc164d;
}

.border-lime-500\/40 {
  border-color: #84cc1666;
}

.border-lime-500\/5 {
  border-color: #84cc160d;
}

.border-lime-500\/50 {
  border-color: #84cc1680;
}

.border-lime-500\/60 {
  border-color: #84cc1699;
}

.border-lime-500\/70 {
  border-color: #84cc16b3;
}

.border-lime-500\/75 {
  border-color: #84cc16bf;
}

.border-lime-500\/80 {
  border-color: #84cc16cc;
}

.border-lime-500\/90 {
  border-color: #84cc16e6;
}

.border-lime-500\/95 {
  border-color: #84cc16f2;
}

.border-lime-600 {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-lime-600\/0 {
  border-color: #65a30d00;
}

.border-lime-600\/10 {
  border-color: #65a30d1a;
}

.border-lime-600\/100 {
  border-color: #65a30d;
}

.border-lime-600\/20 {
  border-color: #65a30d33;
}

.border-lime-600\/25 {
  border-color: #65a30d40;
}

.border-lime-600\/30 {
  border-color: #65a30d4d;
}

.border-lime-600\/40 {
  border-color: #65a30d66;
}

.border-lime-600\/5 {
  border-color: #65a30d0d;
}

.border-lime-600\/50 {
  border-color: #65a30d80;
}

.border-lime-600\/60 {
  border-color: #65a30d99;
}

.border-lime-600\/70 {
  border-color: #65a30db3;
}

.border-lime-600\/75 {
  border-color: #65a30dbf;
}

.border-lime-600\/80 {
  border-color: #65a30dcc;
}

.border-lime-600\/90 {
  border-color: #65a30de6;
}

.border-lime-600\/95 {
  border-color: #65a30df2;
}

.border-lime-700 {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-lime-700\/0 {
  border-color: #4d7c0f00;
}

.border-lime-700\/10 {
  border-color: #4d7c0f1a;
}

.border-lime-700\/100 {
  border-color: #4d7c0f;
}

.border-lime-700\/20 {
  border-color: #4d7c0f33;
}

.border-lime-700\/25 {
  border-color: #4d7c0f40;
}

.border-lime-700\/30 {
  border-color: #4d7c0f4d;
}

.border-lime-700\/40 {
  border-color: #4d7c0f66;
}

.border-lime-700\/5 {
  border-color: #4d7c0f0d;
}

.border-lime-700\/50 {
  border-color: #4d7c0f80;
}

.border-lime-700\/60 {
  border-color: #4d7c0f99;
}

.border-lime-700\/70 {
  border-color: #4d7c0fb3;
}

.border-lime-700\/75 {
  border-color: #4d7c0fbf;
}

.border-lime-700\/80 {
  border-color: #4d7c0fcc;
}

.border-lime-700\/90 {
  border-color: #4d7c0fe6;
}

.border-lime-700\/95 {
  border-color: #4d7c0ff2;
}

.border-lime-800 {
  --tw-border-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-lime-800\/0 {
  border-color: #3f621200;
}

.border-lime-800\/10 {
  border-color: #3f62121a;
}

.border-lime-800\/100 {
  border-color: #3f6212;
}

.border-lime-800\/20 {
  border-color: #3f621233;
}

.border-lime-800\/25 {
  border-color: #3f621240;
}

.border-lime-800\/30 {
  border-color: #3f62124d;
}

.border-lime-800\/40 {
  border-color: #3f621266;
}

.border-lime-800\/5 {
  border-color: #3f62120d;
}

.border-lime-800\/50 {
  border-color: #3f621280;
}

.border-lime-800\/60 {
  border-color: #3f621299;
}

.border-lime-800\/70 {
  border-color: #3f6212b3;
}

.border-lime-800\/75 {
  border-color: #3f6212bf;
}

.border-lime-800\/80 {
  border-color: #3f6212cc;
}

.border-lime-800\/90 {
  border-color: #3f6212e6;
}

.border-lime-800\/95 {
  border-color: #3f6212f2;
}

.border-lime-900 {
  --tw-border-opacity: 1;
  border-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-lime-900\/0 {
  border-color: #36531400;
}

.border-lime-900\/10 {
  border-color: #3653141a;
}

.border-lime-900\/100 {
  border-color: #365314;
}

.border-lime-900\/20 {
  border-color: #36531433;
}

.border-lime-900\/25 {
  border-color: #36531440;
}

.border-lime-900\/30 {
  border-color: #3653144d;
}

.border-lime-900\/40 {
  border-color: #36531466;
}

.border-lime-900\/5 {
  border-color: #3653140d;
}

.border-lime-900\/50 {
  border-color: #36531480;
}

.border-lime-900\/60 {
  border-color: #36531499;
}

.border-lime-900\/70 {
  border-color: #365314b3;
}

.border-lime-900\/75 {
  border-color: #365314bf;
}

.border-lime-900\/80 {
  border-color: #365314cc;
}

.border-lime-900\/90 {
  border-color: #365314e6;
}

.border-lime-900\/95 {
  border-color: #365314f2;
}

.border-lime-950 {
  --tw-border-opacity: 1;
  border-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-lime-950\/0 {
  border-color: #1a2e0500;
}

.border-lime-950\/10 {
  border-color: #1a2e051a;
}

.border-lime-950\/100 {
  border-color: #1a2e05;
}

.border-lime-950\/20 {
  border-color: #1a2e0533;
}

.border-lime-950\/25 {
  border-color: #1a2e0540;
}

.border-lime-950\/30 {
  border-color: #1a2e054d;
}

.border-lime-950\/40 {
  border-color: #1a2e0566;
}

.border-lime-950\/5 {
  border-color: #1a2e050d;
}

.border-lime-950\/50 {
  border-color: #1a2e0580;
}

.border-lime-950\/60 {
  border-color: #1a2e0599;
}

.border-lime-950\/70 {
  border-color: #1a2e05b3;
}

.border-lime-950\/75 {
  border-color: #1a2e05bf;
}

.border-lime-950\/80 {
  border-color: #1a2e05cc;
}

.border-lime-950\/90 {
  border-color: #1a2e05e6;
}

.border-lime-950\/95 {
  border-color: #1a2e05f2;
}

.border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-pink-100\/0 {
  border-color: #fce7f300;
}

.border-pink-100\/10 {
  border-color: #fce7f31a;
}

.border-pink-100\/100 {
  border-color: #fce7f3;
}

.border-pink-100\/20 {
  border-color: #fce7f333;
}

.border-pink-100\/25 {
  border-color: #fce7f340;
}

.border-pink-100\/30 {
  border-color: #fce7f34d;
}

.border-pink-100\/40 {
  border-color: #fce7f366;
}

.border-pink-100\/5 {
  border-color: #fce7f30d;
}

.border-pink-100\/50 {
  border-color: #fce7f380;
}

.border-pink-100\/60 {
  border-color: #fce7f399;
}

.border-pink-100\/70 {
  border-color: #fce7f3b3;
}

.border-pink-100\/75 {
  border-color: #fce7f3bf;
}

.border-pink-100\/80 {
  border-color: #fce7f3cc;
}

.border-pink-100\/90 {
  border-color: #fce7f3e6;
}

.border-pink-100\/95 {
  border-color: #fce7f3f2;
}

.border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-pink-200\/0 {
  border-color: #fbcfe800;
}

.border-pink-200\/10 {
  border-color: #fbcfe81a;
}

.border-pink-200\/100 {
  border-color: #fbcfe8;
}

.border-pink-200\/20 {
  border-color: #fbcfe833;
}

.border-pink-200\/25 {
  border-color: #fbcfe840;
}

.border-pink-200\/30 {
  border-color: #fbcfe84d;
}

.border-pink-200\/40 {
  border-color: #fbcfe866;
}

.border-pink-200\/5 {
  border-color: #fbcfe80d;
}

.border-pink-200\/50 {
  border-color: #fbcfe880;
}

.border-pink-200\/60 {
  border-color: #fbcfe899;
}

.border-pink-200\/70 {
  border-color: #fbcfe8b3;
}

.border-pink-200\/75 {
  border-color: #fbcfe8bf;
}

.border-pink-200\/80 {
  border-color: #fbcfe8cc;
}

.border-pink-200\/90 {
  border-color: #fbcfe8e6;
}

.border-pink-200\/95 {
  border-color: #fbcfe8f2;
}

.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-pink-300\/0 {
  border-color: #f9a8d400;
}

.border-pink-300\/10 {
  border-color: #f9a8d41a;
}

.border-pink-300\/100 {
  border-color: #f9a8d4;
}

.border-pink-300\/20 {
  border-color: #f9a8d433;
}

.border-pink-300\/25 {
  border-color: #f9a8d440;
}

.border-pink-300\/30 {
  border-color: #f9a8d44d;
}

.border-pink-300\/40 {
  border-color: #f9a8d466;
}

.border-pink-300\/5 {
  border-color: #f9a8d40d;
}

.border-pink-300\/50 {
  border-color: #f9a8d480;
}

.border-pink-300\/60 {
  border-color: #f9a8d499;
}

.border-pink-300\/70 {
  border-color: #f9a8d4b3;
}

.border-pink-300\/75 {
  border-color: #f9a8d4bf;
}

.border-pink-300\/80 {
  border-color: #f9a8d4cc;
}

.border-pink-300\/90 {
  border-color: #f9a8d4e6;
}

.border-pink-300\/95 {
  border-color: #f9a8d4f2;
}

.border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-pink-400\/0 {
  border-color: #f472b600;
}

.border-pink-400\/10 {
  border-color: #f472b61a;
}

.border-pink-400\/100 {
  border-color: #f472b6;
}

.border-pink-400\/20 {
  border-color: #f472b633;
}

.border-pink-400\/25 {
  border-color: #f472b640;
}

.border-pink-400\/30 {
  border-color: #f472b64d;
}

.border-pink-400\/40 {
  border-color: #f472b666;
}

.border-pink-400\/5 {
  border-color: #f472b60d;
}

.border-pink-400\/50 {
  border-color: #f472b680;
}

.border-pink-400\/60 {
  border-color: #f472b699;
}

.border-pink-400\/70 {
  border-color: #f472b6b3;
}

.border-pink-400\/75 {
  border-color: #f472b6bf;
}

.border-pink-400\/80 {
  border-color: #f472b6cc;
}

.border-pink-400\/90 {
  border-color: #f472b6e6;
}

.border-pink-400\/95 {
  border-color: #f472b6f2;
}

.border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-pink-50\/0 {
  border-color: #fdf2f800;
}

.border-pink-50\/10 {
  border-color: #fdf2f81a;
}

.border-pink-50\/100 {
  border-color: #fdf2f8;
}

.border-pink-50\/20 {
  border-color: #fdf2f833;
}

.border-pink-50\/25 {
  border-color: #fdf2f840;
}

.border-pink-50\/30 {
  border-color: #fdf2f84d;
}

.border-pink-50\/40 {
  border-color: #fdf2f866;
}

.border-pink-50\/5 {
  border-color: #fdf2f80d;
}

.border-pink-50\/50 {
  border-color: #fdf2f880;
}

.border-pink-50\/60 {
  border-color: #fdf2f899;
}

.border-pink-50\/70 {
  border-color: #fdf2f8b3;
}

.border-pink-50\/75 {
  border-color: #fdf2f8bf;
}

.border-pink-50\/80 {
  border-color: #fdf2f8cc;
}

.border-pink-50\/90 {
  border-color: #fdf2f8e6;
}

.border-pink-50\/95 {
  border-color: #fdf2f8f2;
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-pink-500\/0 {
  border-color: #ec489900;
}

.border-pink-500\/10 {
  border-color: #ec48991a;
}

.border-pink-500\/100 {
  border-color: #ec4899;
}

.border-pink-500\/20 {
  border-color: #ec489933;
}

.border-pink-500\/25 {
  border-color: #ec489940;
}

.border-pink-500\/30 {
  border-color: #ec48994d;
}

.border-pink-500\/40 {
  border-color: #ec489966;
}

.border-pink-500\/5 {
  border-color: #ec48990d;
}

.border-pink-500\/50 {
  border-color: #ec489980;
}

.border-pink-500\/60 {
  border-color: #ec489999;
}

.border-pink-500\/70 {
  border-color: #ec4899b3;
}

.border-pink-500\/75 {
  border-color: #ec4899bf;
}

.border-pink-500\/80 {
  border-color: #ec4899cc;
}

.border-pink-500\/90 {
  border-color: #ec4899e6;
}

.border-pink-500\/95 {
  border-color: #ec4899f2;
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-pink-600\/0 {
  border-color: #db277700;
}

.border-pink-600\/10 {
  border-color: #db27771a;
}

.border-pink-600\/100 {
  border-color: #db2777;
}

.border-pink-600\/20 {
  border-color: #db277733;
}

.border-pink-600\/25 {
  border-color: #db277740;
}

.border-pink-600\/30 {
  border-color: #db27774d;
}

.border-pink-600\/40 {
  border-color: #db277766;
}

.border-pink-600\/5 {
  border-color: #db27770d;
}

.border-pink-600\/50 {
  border-color: #db277780;
}

.border-pink-600\/60 {
  border-color: #db277799;
}

.border-pink-600\/70 {
  border-color: #db2777b3;
}

.border-pink-600\/75 {
  border-color: #db2777bf;
}

.border-pink-600\/80 {
  border-color: #db2777cc;
}

.border-pink-600\/90 {
  border-color: #db2777e6;
}

.border-pink-600\/95 {
  border-color: #db2777f2;
}

.border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-pink-700\/0 {
  border-color: #be185d00;
}

.border-pink-700\/10 {
  border-color: #be185d1a;
}

.border-pink-700\/100 {
  border-color: #be185d;
}

.border-pink-700\/20 {
  border-color: #be185d33;
}

.border-pink-700\/25 {
  border-color: #be185d40;
}

.border-pink-700\/30 {
  border-color: #be185d4d;
}

.border-pink-700\/40 {
  border-color: #be185d66;
}

.border-pink-700\/5 {
  border-color: #be185d0d;
}

.border-pink-700\/50 {
  border-color: #be185d80;
}

.border-pink-700\/60 {
  border-color: #be185d99;
}

.border-pink-700\/70 {
  border-color: #be185db3;
}

.border-pink-700\/75 {
  border-color: #be185dbf;
}

.border-pink-700\/80 {
  border-color: #be185dcc;
}

.border-pink-700\/90 {
  border-color: #be185de6;
}

.border-pink-700\/95 {
  border-color: #be185df2;
}

.border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-pink-800\/0 {
  border-color: #9d174d00;
}

.border-pink-800\/10 {
  border-color: #9d174d1a;
}

.border-pink-800\/100 {
  border-color: #9d174d;
}

.border-pink-800\/20 {
  border-color: #9d174d33;
}

.border-pink-800\/25 {
  border-color: #9d174d40;
}

.border-pink-800\/30 {
  border-color: #9d174d4d;
}

.border-pink-800\/40 {
  border-color: #9d174d66;
}

.border-pink-800\/5 {
  border-color: #9d174d0d;
}

.border-pink-800\/50 {
  border-color: #9d174d80;
}

.border-pink-800\/60 {
  border-color: #9d174d99;
}

.border-pink-800\/70 {
  border-color: #9d174db3;
}

.border-pink-800\/75 {
  border-color: #9d174dbf;
}

.border-pink-800\/80 {
  border-color: #9d174dcc;
}

.border-pink-800\/90 {
  border-color: #9d174de6;
}

.border-pink-800\/95 {
  border-color: #9d174df2;
}

.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-pink-900\/0 {
  border-color: #83184300;
}

.border-pink-900\/10 {
  border-color: #8318431a;
}

.border-pink-900\/100 {
  border-color: #831843;
}

.border-pink-900\/20 {
  border-color: #83184333;
}

.border-pink-900\/25 {
  border-color: #83184340;
}

.border-pink-900\/30 {
  border-color: #8318434d;
}

.border-pink-900\/40 {
  border-color: #83184366;
}

.border-pink-900\/5 {
  border-color: #8318430d;
}

.border-pink-900\/50 {
  border-color: #83184380;
}

.border-pink-900\/60 {
  border-color: #83184399;
}

.border-pink-900\/70 {
  border-color: #831843b3;
}

.border-pink-900\/75 {
  border-color: #831843bf;
}

.border-pink-900\/80 {
  border-color: #831843cc;
}

.border-pink-900\/90 {
  border-color: #831843e6;
}

.border-pink-900\/95 {
  border-color: #831843f2;
}

.border-pink-950 {
  --tw-border-opacity: 1;
  border-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-pink-950\/0 {
  border-color: #50072400;
}

.border-pink-950\/10 {
  border-color: #5007241a;
}

.border-pink-950\/100 {
  border-color: #500724;
}

.border-pink-950\/20 {
  border-color: #50072433;
}

.border-pink-950\/25 {
  border-color: #50072440;
}

.border-pink-950\/30 {
  border-color: #5007244d;
}

.border-pink-950\/40 {
  border-color: #50072466;
}

.border-pink-950\/5 {
  border-color: #5007240d;
}

.border-pink-950\/50 {
  border-color: #50072480;
}

.border-pink-950\/60 {
  border-color: #50072499;
}

.border-pink-950\/70 {
  border-color: #500724b3;
}

.border-pink-950\/75 {
  border-color: #500724bf;
}

.border-pink-950\/80 {
  border-color: #500724cc;
}

.border-pink-950\/90 {
  border-color: #500724e6;
}

.border-pink-950\/95 {
  border-color: #500724f2;
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-primary\/0 {
  border-color: hsl(var(--primary) / 0);
}

.border-primary\/10 {
  border-color: hsl(var(--primary) / .1);
}

.border-primary\/100 {
  border-color: hsl(var(--primary) / 1);
}

.border-primary\/20 {
  border-color: hsl(var(--primary) / .2);
}

.border-primary\/25 {
  border-color: hsl(var(--primary) / .25);
}

.border-primary\/30 {
  border-color: hsl(var(--primary) / .3);
}

.border-primary\/40 {
  border-color: hsl(var(--primary) / .4);
}

.border-primary\/5 {
  border-color: hsl(var(--primary) / .05);
}

.border-primary\/50 {
  border-color: hsl(var(--primary) / .5);
}

.border-primary\/60 {
  border-color: hsl(var(--primary) / .6);
}

.border-primary\/70 {
  border-color: hsl(var(--primary) / .7);
}

.border-primary\/75 {
  border-color: hsl(var(--primary) / .75);
}

.border-primary\/80 {
  border-color: hsl(var(--primary) / .8);
}

.border-primary\/90 {
  border-color: hsl(var(--primary) / .9);
}

.border-primary\/95 {
  border-color: hsl(var(--primary) / .95);
}

.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-purple-100\/0 {
  border-color: #f3e8ff00;
}

.border-purple-100\/10 {
  border-color: #f3e8ff1a;
}

.border-purple-100\/100 {
  border-color: #f3e8ff;
}

.border-purple-100\/20 {
  border-color: #f3e8ff33;
}

.border-purple-100\/25 {
  border-color: #f3e8ff40;
}

.border-purple-100\/30 {
  border-color: #f3e8ff4d;
}

.border-purple-100\/40 {
  border-color: #f3e8ff66;
}

.border-purple-100\/5 {
  border-color: #f3e8ff0d;
}

.border-purple-100\/50 {
  border-color: #f3e8ff80;
}

.border-purple-100\/60 {
  border-color: #f3e8ff99;
}

.border-purple-100\/70 {
  border-color: #f3e8ffb3;
}

.border-purple-100\/75 {
  border-color: #f3e8ffbf;
}

.border-purple-100\/80 {
  border-color: #f3e8ffcc;
}

.border-purple-100\/90 {
  border-color: #f3e8ffe6;
}

.border-purple-100\/95 {
  border-color: #f3e8fff2;
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-purple-200\/0 {
  border-color: #e9d5ff00;
}

.border-purple-200\/10 {
  border-color: #e9d5ff1a;
}

.border-purple-200\/100 {
  border-color: #e9d5ff;
}

.border-purple-200\/20 {
  border-color: #e9d5ff33;
}

.border-purple-200\/25 {
  border-color: #e9d5ff40;
}

.border-purple-200\/30 {
  border-color: #e9d5ff4d;
}

.border-purple-200\/40 {
  border-color: #e9d5ff66;
}

.border-purple-200\/5 {
  border-color: #e9d5ff0d;
}

.border-purple-200\/50 {
  border-color: #e9d5ff80;
}

.border-purple-200\/60 {
  border-color: #e9d5ff99;
}

.border-purple-200\/70 {
  border-color: #e9d5ffb3;
}

.border-purple-200\/75 {
  border-color: #e9d5ffbf;
}

.border-purple-200\/80 {
  border-color: #e9d5ffcc;
}

.border-purple-200\/90 {
  border-color: #e9d5ffe6;
}

.border-purple-200\/95 {
  border-color: #e9d5fff2;
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-purple-300\/0 {
  border-color: #d8b4fe00;
}

.border-purple-300\/10 {
  border-color: #d8b4fe1a;
}

.border-purple-300\/100 {
  border-color: #d8b4fe;
}

.border-purple-300\/20 {
  border-color: #d8b4fe33;
}

.border-purple-300\/25 {
  border-color: #d8b4fe40;
}

.border-purple-300\/30 {
  border-color: #d8b4fe4d;
}

.border-purple-300\/40 {
  border-color: #d8b4fe66;
}

.border-purple-300\/5 {
  border-color: #d8b4fe0d;
}

.border-purple-300\/50 {
  border-color: #d8b4fe80;
}

.border-purple-300\/60 {
  border-color: #d8b4fe99;
}

.border-purple-300\/70 {
  border-color: #d8b4feb3;
}

.border-purple-300\/75 {
  border-color: #d8b4febf;
}

.border-purple-300\/80 {
  border-color: #d8b4fecc;
}

.border-purple-300\/90 {
  border-color: #d8b4fee6;
}

.border-purple-300\/95 {
  border-color: #d8b4fef2;
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-purple-400\/0 {
  border-color: #c084fc00;
}

.border-purple-400\/10 {
  border-color: #c084fc1a;
}

.border-purple-400\/100 {
  border-color: #c084fc;
}

.border-purple-400\/20 {
  border-color: #c084fc33;
}

.border-purple-400\/25 {
  border-color: #c084fc40;
}

.border-purple-400\/30 {
  border-color: #c084fc4d;
}

.border-purple-400\/40 {
  border-color: #c084fc66;
}

.border-purple-400\/5 {
  border-color: #c084fc0d;
}

.border-purple-400\/50 {
  border-color: #c084fc80;
}

.border-purple-400\/60 {
  border-color: #c084fc99;
}

.border-purple-400\/70 {
  border-color: #c084fcb3;
}

.border-purple-400\/75 {
  border-color: #c084fcbf;
}

.border-purple-400\/80 {
  border-color: #c084fccc;
}

.border-purple-400\/90 {
  border-color: #c084fce6;
}

.border-purple-400\/95 {
  border-color: #c084fcf2;
}

.border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-purple-50\/0 {
  border-color: #faf5ff00;
}

.border-purple-50\/10 {
  border-color: #faf5ff1a;
}

.border-purple-50\/100 {
  border-color: #faf5ff;
}

.border-purple-50\/20 {
  border-color: #faf5ff33;
}

.border-purple-50\/25 {
  border-color: #faf5ff40;
}

.border-purple-50\/30 {
  border-color: #faf5ff4d;
}

.border-purple-50\/40 {
  border-color: #faf5ff66;
}

.border-purple-50\/5 {
  border-color: #faf5ff0d;
}

.border-purple-50\/50 {
  border-color: #faf5ff80;
}

.border-purple-50\/60 {
  border-color: #faf5ff99;
}

.border-purple-50\/70 {
  border-color: #faf5ffb3;
}

.border-purple-50\/75 {
  border-color: #faf5ffbf;
}

.border-purple-50\/80 {
  border-color: #faf5ffcc;
}

.border-purple-50\/90 {
  border-color: #faf5ffe6;
}

.border-purple-50\/95 {
  border-color: #faf5fff2;
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-purple-500\/0 {
  border-color: #a855f700;
}

.border-purple-500\/10 {
  border-color: #a855f71a;
}

.border-purple-500\/100 {
  border-color: #a855f7;
}

.border-purple-500\/20 {
  border-color: #a855f733;
}

.border-purple-500\/25 {
  border-color: #a855f740;
}

.border-purple-500\/30 {
  border-color: #a855f74d;
}

.border-purple-500\/40 {
  border-color: #a855f766;
}

.border-purple-500\/5 {
  border-color: #a855f70d;
}

.border-purple-500\/50 {
  border-color: #a855f780;
}

.border-purple-500\/60 {
  border-color: #a855f799;
}

.border-purple-500\/70 {
  border-color: #a855f7b3;
}

.border-purple-500\/75 {
  border-color: #a855f7bf;
}

.border-purple-500\/80 {
  border-color: #a855f7cc;
}

.border-purple-500\/90 {
  border-color: #a855f7e6;
}

.border-purple-500\/95 {
  border-color: #a855f7f2;
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-purple-600\/0 {
  border-color: #9333ea00;
}

.border-purple-600\/10 {
  border-color: #9333ea1a;
}

.border-purple-600\/100 {
  border-color: #9333ea;
}

.border-purple-600\/20 {
  border-color: #9333ea33;
}

.border-purple-600\/25 {
  border-color: #9333ea40;
}

.border-purple-600\/30 {
  border-color: #9333ea4d;
}

.border-purple-600\/40 {
  border-color: #9333ea66;
}

.border-purple-600\/5 {
  border-color: #9333ea0d;
}

.border-purple-600\/50 {
  border-color: #9333ea80;
}

.border-purple-600\/60 {
  border-color: #9333ea99;
}

.border-purple-600\/70 {
  border-color: #9333eab3;
}

.border-purple-600\/75 {
  border-color: #9333eabf;
}

.border-purple-600\/80 {
  border-color: #9333eacc;
}

.border-purple-600\/90 {
  border-color: #9333eae6;
}

.border-purple-600\/95 {
  border-color: #9333eaf2;
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-purple-700\/0 {
  border-color: #7e22ce00;
}

.border-purple-700\/10 {
  border-color: #7e22ce1a;
}

.border-purple-700\/100 {
  border-color: #7e22ce;
}

.border-purple-700\/20 {
  border-color: #7e22ce33;
}

.border-purple-700\/25 {
  border-color: #7e22ce40;
}

.border-purple-700\/30 {
  border-color: #7e22ce4d;
}

.border-purple-700\/40 {
  border-color: #7e22ce66;
}

.border-purple-700\/5 {
  border-color: #7e22ce0d;
}

.border-purple-700\/50 {
  border-color: #7e22ce80;
}

.border-purple-700\/60 {
  border-color: #7e22ce99;
}

.border-purple-700\/70 {
  border-color: #7e22ceb3;
}

.border-purple-700\/75 {
  border-color: #7e22cebf;
}

.border-purple-700\/80 {
  border-color: #7e22cecc;
}

.border-purple-700\/90 {
  border-color: #7e22cee6;
}

.border-purple-700\/95 {
  border-color: #7e22cef2;
}

.border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-purple-800\/0 {
  border-color: #6b21a800;
}

.border-purple-800\/10 {
  border-color: #6b21a81a;
}

.border-purple-800\/100 {
  border-color: #6b21a8;
}

.border-purple-800\/20 {
  border-color: #6b21a833;
}

.border-purple-800\/25 {
  border-color: #6b21a840;
}

.border-purple-800\/30 {
  border-color: #6b21a84d;
}

.border-purple-800\/40 {
  border-color: #6b21a866;
}

.border-purple-800\/5 {
  border-color: #6b21a80d;
}

.border-purple-800\/50 {
  border-color: #6b21a880;
}

.border-purple-800\/60 {
  border-color: #6b21a899;
}

.border-purple-800\/70 {
  border-color: #6b21a8b3;
}

.border-purple-800\/75 {
  border-color: #6b21a8bf;
}

.border-purple-800\/80 {
  border-color: #6b21a8cc;
}

.border-purple-800\/90 {
  border-color: #6b21a8e6;
}

.border-purple-800\/95 {
  border-color: #6b21a8f2;
}

.border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-purple-900\/0 {
  border-color: #581c8700;
}

.border-purple-900\/10 {
  border-color: #581c871a;
}

.border-purple-900\/100 {
  border-color: #581c87;
}

.border-purple-900\/20 {
  border-color: #581c8733;
}

.border-purple-900\/25 {
  border-color: #581c8740;
}

.border-purple-900\/30 {
  border-color: #581c874d;
}

.border-purple-900\/40 {
  border-color: #581c8766;
}

.border-purple-900\/5 {
  border-color: #581c870d;
}

.border-purple-900\/50 {
  border-color: #581c8780;
}

.border-purple-900\/60 {
  border-color: #581c8799;
}

.border-purple-900\/70 {
  border-color: #581c87b3;
}

.border-purple-900\/75 {
  border-color: #581c87bf;
}

.border-purple-900\/80 {
  border-color: #581c87cc;
}

.border-purple-900\/90 {
  border-color: #581c87e6;
}

.border-purple-900\/95 {
  border-color: #581c87f2;
}

.border-purple-950 {
  --tw-border-opacity: 1;
  border-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-purple-950\/0 {
  border-color: #3b076400;
}

.border-purple-950\/10 {
  border-color: #3b07641a;
}

.border-purple-950\/100 {
  border-color: #3b0764;
}

.border-purple-950\/20 {
  border-color: #3b076433;
}

.border-purple-950\/25 {
  border-color: #3b076440;
}

.border-purple-950\/30 {
  border-color: #3b07644d;
}

.border-purple-950\/40 {
  border-color: #3b076466;
}

.border-purple-950\/5 {
  border-color: #3b07640d;
}

.border-purple-950\/50 {
  border-color: #3b076480;
}

.border-purple-950\/60 {
  border-color: #3b076499;
}

.border-purple-950\/70 {
  border-color: #3b0764b3;
}

.border-purple-950\/75 {
  border-color: #3b0764bf;
}

.border-purple-950\/80 {
  border-color: #3b0764cc;
}

.border-purple-950\/90 {
  border-color: #3b0764e6;
}

.border-purple-950\/95 {
  border-color: #3b0764f2;
}

.border-transparent, .border-transparent\/0 {
  border-color: #0000;
}

.border-transparent\/10 {
  border-color: #0000001a;
}

.border-transparent\/100 {
  border-color: #000;
}

.border-transparent\/20 {
  border-color: #0003;
}

.border-transparent\/25 {
  border-color: #00000040;
}

.border-transparent\/30 {
  border-color: #0000004d;
}

.border-transparent\/40 {
  border-color: #0006;
}

.border-transparent\/5 {
  border-color: #0000000d;
}

.border-transparent\/50 {
  border-color: #00000080;
}

.border-transparent\/60 {
  border-color: #0009;
}

.border-transparent\/70 {
  border-color: #000000b3;
}

.border-transparent\/75 {
  border-color: #000000bf;
}

.border-transparent\/80 {
  border-color: #000c;
}

.border-transparent\/90 {
  border-color: #000000e6;
}

.border-transparent\/95 {
  border-color: #000000f2;
}

.border-x-amber-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(254 243 199 / var(--tw-border-opacity));
  border-right-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-x-amber-100\/0 {
  border-left-color: #fef3c700;
  border-right-color: #fef3c700;
}

.border-x-amber-100\/10 {
  border-left-color: #fef3c71a;
  border-right-color: #fef3c71a;
}

.border-x-amber-100\/100 {
  border-left-color: #fef3c7;
  border-right-color: #fef3c7;
}

.border-x-amber-100\/20 {
  border-left-color: #fef3c733;
  border-right-color: #fef3c733;
}

.border-x-amber-100\/25 {
  border-left-color: #fef3c740;
  border-right-color: #fef3c740;
}

.border-x-amber-100\/30 {
  border-left-color: #fef3c74d;
  border-right-color: #fef3c74d;
}

.border-x-amber-100\/40 {
  border-left-color: #fef3c766;
  border-right-color: #fef3c766;
}

.border-x-amber-100\/5 {
  border-left-color: #fef3c70d;
  border-right-color: #fef3c70d;
}

.border-x-amber-100\/50 {
  border-left-color: #fef3c780;
  border-right-color: #fef3c780;
}

.border-x-amber-100\/60 {
  border-left-color: #fef3c799;
  border-right-color: #fef3c799;
}

.border-x-amber-100\/70 {
  border-left-color: #fef3c7b3;
  border-right-color: #fef3c7b3;
}

.border-x-amber-100\/75 {
  border-left-color: #fef3c7bf;
  border-right-color: #fef3c7bf;
}

.border-x-amber-100\/80 {
  border-left-color: #fef3c7cc;
  border-right-color: #fef3c7cc;
}

.border-x-amber-100\/90 {
  border-left-color: #fef3c7e6;
  border-right-color: #fef3c7e6;
}

.border-x-amber-100\/95 {
  border-left-color: #fef3c7f2;
  border-right-color: #fef3c7f2;
}

.border-x-amber-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(253 230 138 / var(--tw-border-opacity));
  border-right-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-x-amber-200\/0 {
  border-left-color: #fde68a00;
  border-right-color: #fde68a00;
}

.border-x-amber-200\/10 {
  border-left-color: #fde68a1a;
  border-right-color: #fde68a1a;
}

.border-x-amber-200\/100 {
  border-left-color: #fde68a;
  border-right-color: #fde68a;
}

.border-x-amber-200\/20 {
  border-left-color: #fde68a33;
  border-right-color: #fde68a33;
}

.border-x-amber-200\/25 {
  border-left-color: #fde68a40;
  border-right-color: #fde68a40;
}

.border-x-amber-200\/30 {
  border-left-color: #fde68a4d;
  border-right-color: #fde68a4d;
}

.border-x-amber-200\/40 {
  border-left-color: #fde68a66;
  border-right-color: #fde68a66;
}

.border-x-amber-200\/5 {
  border-left-color: #fde68a0d;
  border-right-color: #fde68a0d;
}

.border-x-amber-200\/50 {
  border-left-color: #fde68a80;
  border-right-color: #fde68a80;
}

.border-x-amber-200\/60 {
  border-left-color: #fde68a99;
  border-right-color: #fde68a99;
}

.border-x-amber-200\/70 {
  border-left-color: #fde68ab3;
  border-right-color: #fde68ab3;
}

.border-x-amber-200\/75 {
  border-left-color: #fde68abf;
  border-right-color: #fde68abf;
}

.border-x-amber-200\/80 {
  border-left-color: #fde68acc;
  border-right-color: #fde68acc;
}

.border-x-amber-200\/90 {
  border-left-color: #fde68ae6;
  border-right-color: #fde68ae6;
}

.border-x-amber-200\/95 {
  border-left-color: #fde68af2;
  border-right-color: #fde68af2;
}

.border-x-amber-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(252 211 77 / var(--tw-border-opacity));
  border-right-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-x-amber-300\/0 {
  border-left-color: #fcd34d00;
  border-right-color: #fcd34d00;
}

.border-x-amber-300\/10 {
  border-left-color: #fcd34d1a;
  border-right-color: #fcd34d1a;
}

.border-x-amber-300\/100 {
  border-left-color: #fcd34d;
  border-right-color: #fcd34d;
}

.border-x-amber-300\/20 {
  border-left-color: #fcd34d33;
  border-right-color: #fcd34d33;
}

.border-x-amber-300\/25 {
  border-left-color: #fcd34d40;
  border-right-color: #fcd34d40;
}

.border-x-amber-300\/30 {
  border-left-color: #fcd34d4d;
  border-right-color: #fcd34d4d;
}

.border-x-amber-300\/40 {
  border-left-color: #fcd34d66;
  border-right-color: #fcd34d66;
}

.border-x-amber-300\/5 {
  border-left-color: #fcd34d0d;
  border-right-color: #fcd34d0d;
}

.border-x-amber-300\/50 {
  border-left-color: #fcd34d80;
  border-right-color: #fcd34d80;
}

.border-x-amber-300\/60 {
  border-left-color: #fcd34d99;
  border-right-color: #fcd34d99;
}

.border-x-amber-300\/70 {
  border-left-color: #fcd34db3;
  border-right-color: #fcd34db3;
}

.border-x-amber-300\/75 {
  border-left-color: #fcd34dbf;
  border-right-color: #fcd34dbf;
}

.border-x-amber-300\/80 {
  border-left-color: #fcd34dcc;
  border-right-color: #fcd34dcc;
}

.border-x-amber-300\/90 {
  border-left-color: #fcd34de6;
  border-right-color: #fcd34de6;
}

.border-x-amber-300\/95 {
  border-left-color: #fcd34df2;
  border-right-color: #fcd34df2;
}

.border-x-amber-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(251 191 36 / var(--tw-border-opacity));
  border-right-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-x-amber-400\/0 {
  border-left-color: #fbbf2400;
  border-right-color: #fbbf2400;
}

.border-x-amber-400\/10 {
  border-left-color: #fbbf241a;
  border-right-color: #fbbf241a;
}

.border-x-amber-400\/100 {
  border-left-color: #fbbf24;
  border-right-color: #fbbf24;
}

.border-x-amber-400\/20 {
  border-left-color: #fbbf2433;
  border-right-color: #fbbf2433;
}

.border-x-amber-400\/25 {
  border-left-color: #fbbf2440;
  border-right-color: #fbbf2440;
}

.border-x-amber-400\/30 {
  border-left-color: #fbbf244d;
  border-right-color: #fbbf244d;
}

.border-x-amber-400\/40 {
  border-left-color: #fbbf2466;
  border-right-color: #fbbf2466;
}

.border-x-amber-400\/5 {
  border-left-color: #fbbf240d;
  border-right-color: #fbbf240d;
}

.border-x-amber-400\/50 {
  border-left-color: #fbbf2480;
  border-right-color: #fbbf2480;
}

.border-x-amber-400\/60 {
  border-left-color: #fbbf2499;
  border-right-color: #fbbf2499;
}

.border-x-amber-400\/70 {
  border-left-color: #fbbf24b3;
  border-right-color: #fbbf24b3;
}

.border-x-amber-400\/75 {
  border-left-color: #fbbf24bf;
  border-right-color: #fbbf24bf;
}

.border-x-amber-400\/80 {
  border-left-color: #fbbf24cc;
  border-right-color: #fbbf24cc;
}

.border-x-amber-400\/90 {
  border-left-color: #fbbf24e6;
  border-right-color: #fbbf24e6;
}

.border-x-amber-400\/95 {
  border-left-color: #fbbf24f2;
  border-right-color: #fbbf24f2;
}

.border-x-amber-50 {
  --tw-border-opacity: 1;
  border-left-color: rgb(255 251 235 / var(--tw-border-opacity));
  border-right-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-x-amber-50\/0 {
  border-left-color: #fffbeb00;
  border-right-color: #fffbeb00;
}

.border-x-amber-50\/10 {
  border-left-color: #fffbeb1a;
  border-right-color: #fffbeb1a;
}

.border-x-amber-50\/100 {
  border-left-color: #fffbeb;
  border-right-color: #fffbeb;
}

.border-x-amber-50\/20 {
  border-left-color: #fffbeb33;
  border-right-color: #fffbeb33;
}

.border-x-amber-50\/25 {
  border-left-color: #fffbeb40;
  border-right-color: #fffbeb40;
}

.border-x-amber-50\/30 {
  border-left-color: #fffbeb4d;
  border-right-color: #fffbeb4d;
}

.border-x-amber-50\/40 {
  border-left-color: #fffbeb66;
  border-right-color: #fffbeb66;
}

.border-x-amber-50\/5 {
  border-left-color: #fffbeb0d;
  border-right-color: #fffbeb0d;
}

.border-x-amber-50\/50 {
  border-left-color: #fffbeb80;
  border-right-color: #fffbeb80;
}

.border-x-amber-50\/60 {
  border-left-color: #fffbeb99;
  border-right-color: #fffbeb99;
}

.border-x-amber-50\/70 {
  border-left-color: #fffbebb3;
  border-right-color: #fffbebb3;
}

.border-x-amber-50\/75 {
  border-left-color: #fffbebbf;
  border-right-color: #fffbebbf;
}

.border-x-amber-50\/80 {
  border-left-color: #fffbebcc;
  border-right-color: #fffbebcc;
}

.border-x-amber-50\/90 {
  border-left-color: #fffbebe6;
  border-right-color: #fffbebe6;
}

.border-x-amber-50\/95 {
  border-left-color: #fffbebf2;
  border-right-color: #fffbebf2;
}

.border-x-amber-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(245 158 11 / var(--tw-border-opacity));
  border-right-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-x-amber-500\/0 {
  border-left-color: #f59e0b00;
  border-right-color: #f59e0b00;
}

.border-x-amber-500\/10 {
  border-left-color: #f59e0b1a;
  border-right-color: #f59e0b1a;
}

.border-x-amber-500\/100 {
  border-left-color: #f59e0b;
  border-right-color: #f59e0b;
}

.border-x-amber-500\/20 {
  border-left-color: #f59e0b33;
  border-right-color: #f59e0b33;
}

.border-x-amber-500\/25 {
  border-left-color: #f59e0b40;
  border-right-color: #f59e0b40;
}

.border-x-amber-500\/30 {
  border-left-color: #f59e0b4d;
  border-right-color: #f59e0b4d;
}

.border-x-amber-500\/40 {
  border-left-color: #f59e0b66;
  border-right-color: #f59e0b66;
}

.border-x-amber-500\/5 {
  border-left-color: #f59e0b0d;
  border-right-color: #f59e0b0d;
}

.border-x-amber-500\/50 {
  border-left-color: #f59e0b80;
  border-right-color: #f59e0b80;
}

.border-x-amber-500\/60 {
  border-left-color: #f59e0b99;
  border-right-color: #f59e0b99;
}

.border-x-amber-500\/70 {
  border-left-color: #f59e0bb3;
  border-right-color: #f59e0bb3;
}

.border-x-amber-500\/75 {
  border-left-color: #f59e0bbf;
  border-right-color: #f59e0bbf;
}

.border-x-amber-500\/80 {
  border-left-color: #f59e0bcc;
  border-right-color: #f59e0bcc;
}

.border-x-amber-500\/90 {
  border-left-color: #f59e0be6;
  border-right-color: #f59e0be6;
}

.border-x-amber-500\/95 {
  border-left-color: #f59e0bf2;
  border-right-color: #f59e0bf2;
}

.border-x-amber-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(217 119 6 / var(--tw-border-opacity));
  border-right-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-x-amber-600\/0 {
  border-left-color: #d9770600;
  border-right-color: #d9770600;
}

.border-x-amber-600\/10 {
  border-left-color: #d977061a;
  border-right-color: #d977061a;
}

.border-x-amber-600\/100 {
  border-left-color: #d97706;
  border-right-color: #d97706;
}

.border-x-amber-600\/20 {
  border-left-color: #d9770633;
  border-right-color: #d9770633;
}

.border-x-amber-600\/25 {
  border-left-color: #d9770640;
  border-right-color: #d9770640;
}

.border-x-amber-600\/30 {
  border-left-color: #d977064d;
  border-right-color: #d977064d;
}

.border-x-amber-600\/40 {
  border-left-color: #d9770666;
  border-right-color: #d9770666;
}

.border-x-amber-600\/5 {
  border-left-color: #d977060d;
  border-right-color: #d977060d;
}

.border-x-amber-600\/50 {
  border-left-color: #d9770680;
  border-right-color: #d9770680;
}

.border-x-amber-600\/60 {
  border-left-color: #d9770699;
  border-right-color: #d9770699;
}

.border-x-amber-600\/70 {
  border-left-color: #d97706b3;
  border-right-color: #d97706b3;
}

.border-x-amber-600\/75 {
  border-left-color: #d97706bf;
  border-right-color: #d97706bf;
}

.border-x-amber-600\/80 {
  border-left-color: #d97706cc;
  border-right-color: #d97706cc;
}

.border-x-amber-600\/90 {
  border-left-color: #d97706e6;
  border-right-color: #d97706e6;
}

.border-x-amber-600\/95 {
  border-left-color: #d97706f2;
  border-right-color: #d97706f2;
}

.border-x-amber-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(180 83 9 / var(--tw-border-opacity));
  border-right-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-x-amber-700\/0 {
  border-left-color: #b4530900;
  border-right-color: #b4530900;
}

.border-x-amber-700\/10 {
  border-left-color: #b453091a;
  border-right-color: #b453091a;
}

.border-x-amber-700\/100 {
  border-left-color: #b45309;
  border-right-color: #b45309;
}

.border-x-amber-700\/20 {
  border-left-color: #b4530933;
  border-right-color: #b4530933;
}

.border-x-amber-700\/25 {
  border-left-color: #b4530940;
  border-right-color: #b4530940;
}

.border-x-amber-700\/30 {
  border-left-color: #b453094d;
  border-right-color: #b453094d;
}

.border-x-amber-700\/40 {
  border-left-color: #b4530966;
  border-right-color: #b4530966;
}

.border-x-amber-700\/5 {
  border-left-color: #b453090d;
  border-right-color: #b453090d;
}

.border-x-amber-700\/50 {
  border-left-color: #b4530980;
  border-right-color: #b4530980;
}

.border-x-amber-700\/60 {
  border-left-color: #b4530999;
  border-right-color: #b4530999;
}

.border-x-amber-700\/70 {
  border-left-color: #b45309b3;
  border-right-color: #b45309b3;
}

.border-x-amber-700\/75 {
  border-left-color: #b45309bf;
  border-right-color: #b45309bf;
}

.border-x-amber-700\/80 {
  border-left-color: #b45309cc;
  border-right-color: #b45309cc;
}

.border-x-amber-700\/90 {
  border-left-color: #b45309e6;
  border-right-color: #b45309e6;
}

.border-x-amber-700\/95 {
  border-left-color: #b45309f2;
  border-right-color: #b45309f2;
}

.border-x-amber-800 {
  --tw-border-opacity: 1;
  border-left-color: rgb(146 64 14 / var(--tw-border-opacity));
  border-right-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-x-amber-800\/0 {
  border-left-color: #92400e00;
  border-right-color: #92400e00;
}

.border-x-amber-800\/10 {
  border-left-color: #92400e1a;
  border-right-color: #92400e1a;
}

.border-x-amber-800\/100 {
  border-left-color: #92400e;
  border-right-color: #92400e;
}

.border-x-amber-800\/20 {
  border-left-color: #92400e33;
  border-right-color: #92400e33;
}

.border-x-amber-800\/25 {
  border-left-color: #92400e40;
  border-right-color: #92400e40;
}

.border-x-amber-800\/30 {
  border-left-color: #92400e4d;
  border-right-color: #92400e4d;
}

.border-x-amber-800\/40 {
  border-left-color: #92400e66;
  border-right-color: #92400e66;
}

.border-x-amber-800\/5 {
  border-left-color: #92400e0d;
  border-right-color: #92400e0d;
}

.border-x-amber-800\/50 {
  border-left-color: #92400e80;
  border-right-color: #92400e80;
}

.border-x-amber-800\/60 {
  border-left-color: #92400e99;
  border-right-color: #92400e99;
}

.border-x-amber-800\/70 {
  border-left-color: #92400eb3;
  border-right-color: #92400eb3;
}

.border-x-amber-800\/75 {
  border-left-color: #92400ebf;
  border-right-color: #92400ebf;
}

.border-x-amber-800\/80 {
  border-left-color: #92400ecc;
  border-right-color: #92400ecc;
}

.border-x-amber-800\/90 {
  border-left-color: #92400ee6;
  border-right-color: #92400ee6;
}

.border-x-amber-800\/95 {
  border-left-color: #92400ef2;
  border-right-color: #92400ef2;
}

.border-x-amber-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(120 53 15 / var(--tw-border-opacity));
  border-right-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-x-amber-900\/0 {
  border-left-color: #78350f00;
  border-right-color: #78350f00;
}

.border-x-amber-900\/10 {
  border-left-color: #78350f1a;
  border-right-color: #78350f1a;
}

.border-x-amber-900\/100 {
  border-left-color: #78350f;
  border-right-color: #78350f;
}

.border-x-amber-900\/20 {
  border-left-color: #78350f33;
  border-right-color: #78350f33;
}

.border-x-amber-900\/25 {
  border-left-color: #78350f40;
  border-right-color: #78350f40;
}

.border-x-amber-900\/30 {
  border-left-color: #78350f4d;
  border-right-color: #78350f4d;
}

.border-x-amber-900\/40 {
  border-left-color: #78350f66;
  border-right-color: #78350f66;
}

.border-x-amber-900\/5 {
  border-left-color: #78350f0d;
  border-right-color: #78350f0d;
}

.border-x-amber-900\/50 {
  border-left-color: #78350f80;
  border-right-color: #78350f80;
}

.border-x-amber-900\/60 {
  border-left-color: #78350f99;
  border-right-color: #78350f99;
}

.border-x-amber-900\/70 {
  border-left-color: #78350fb3;
  border-right-color: #78350fb3;
}

.border-x-amber-900\/75 {
  border-left-color: #78350fbf;
  border-right-color: #78350fbf;
}

.border-x-amber-900\/80 {
  border-left-color: #78350fcc;
  border-right-color: #78350fcc;
}

.border-x-amber-900\/90 {
  border-left-color: #78350fe6;
  border-right-color: #78350fe6;
}

.border-x-amber-900\/95 {
  border-left-color: #78350ff2;
  border-right-color: #78350ff2;
}

.border-x-amber-950 {
  --tw-border-opacity: 1;
  border-left-color: rgb(69 26 3 / var(--tw-border-opacity));
  border-right-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-x-amber-950\/0 {
  border-left-color: #451a0300;
  border-right-color: #451a0300;
}

.border-x-amber-950\/10 {
  border-left-color: #451a031a;
  border-right-color: #451a031a;
}

.border-x-amber-950\/100 {
  border-left-color: #451a03;
  border-right-color: #451a03;
}

.border-x-amber-950\/20 {
  border-left-color: #451a0333;
  border-right-color: #451a0333;
}

.border-x-amber-950\/25 {
  border-left-color: #451a0340;
  border-right-color: #451a0340;
}

.border-x-amber-950\/30 {
  border-left-color: #451a034d;
  border-right-color: #451a034d;
}

.border-x-amber-950\/40 {
  border-left-color: #451a0366;
  border-right-color: #451a0366;
}

.border-x-amber-950\/5 {
  border-left-color: #451a030d;
  border-right-color: #451a030d;
}

.border-x-amber-950\/50 {
  border-left-color: #451a0380;
  border-right-color: #451a0380;
}

.border-x-amber-950\/60 {
  border-left-color: #451a0399;
  border-right-color: #451a0399;
}

.border-x-amber-950\/70 {
  border-left-color: #451a03b3;
  border-right-color: #451a03b3;
}

.border-x-amber-950\/75 {
  border-left-color: #451a03bf;
  border-right-color: #451a03bf;
}

.border-x-amber-950\/80 {
  border-left-color: #451a03cc;
  border-right-color: #451a03cc;
}

.border-x-amber-950\/90 {
  border-left-color: #451a03e6;
  border-right-color: #451a03e6;
}

.border-x-amber-950\/95 {
  border-left-color: #451a03f2;
  border-right-color: #451a03f2;
}

.border-x-emerald-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(209 250 229 / var(--tw-border-opacity));
  border-right-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-x-emerald-100\/0 {
  border-left-color: #d1fae500;
  border-right-color: #d1fae500;
}

.border-x-emerald-100\/10 {
  border-left-color: #d1fae51a;
  border-right-color: #d1fae51a;
}

.border-x-emerald-100\/100 {
  border-left-color: #d1fae5;
  border-right-color: #d1fae5;
}

.border-x-emerald-100\/20 {
  border-left-color: #d1fae533;
  border-right-color: #d1fae533;
}

.border-x-emerald-100\/25 {
  border-left-color: #d1fae540;
  border-right-color: #d1fae540;
}

.border-x-emerald-100\/30 {
  border-left-color: #d1fae54d;
  border-right-color: #d1fae54d;
}

.border-x-emerald-100\/40 {
  border-left-color: #d1fae566;
  border-right-color: #d1fae566;
}

.border-x-emerald-100\/5 {
  border-left-color: #d1fae50d;
  border-right-color: #d1fae50d;
}

.border-x-emerald-100\/50 {
  border-left-color: #d1fae580;
  border-right-color: #d1fae580;
}

.border-x-emerald-100\/60 {
  border-left-color: #d1fae599;
  border-right-color: #d1fae599;
}

.border-x-emerald-100\/70 {
  border-left-color: #d1fae5b3;
  border-right-color: #d1fae5b3;
}

.border-x-emerald-100\/75 {
  border-left-color: #d1fae5bf;
  border-right-color: #d1fae5bf;
}

.border-x-emerald-100\/80 {
  border-left-color: #d1fae5cc;
  border-right-color: #d1fae5cc;
}

.border-x-emerald-100\/90 {
  border-left-color: #d1fae5e6;
  border-right-color: #d1fae5e6;
}

.border-x-emerald-100\/95 {
  border-left-color: #d1fae5f2;
  border-right-color: #d1fae5f2;
}

.border-x-emerald-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(167 243 208 / var(--tw-border-opacity));
  border-right-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-x-emerald-200\/0 {
  border-left-color: #a7f3d000;
  border-right-color: #a7f3d000;
}

.border-x-emerald-200\/10 {
  border-left-color: #a7f3d01a;
  border-right-color: #a7f3d01a;
}

.border-x-emerald-200\/100 {
  border-left-color: #a7f3d0;
  border-right-color: #a7f3d0;
}

.border-x-emerald-200\/20 {
  border-left-color: #a7f3d033;
  border-right-color: #a7f3d033;
}

.border-x-emerald-200\/25 {
  border-left-color: #a7f3d040;
  border-right-color: #a7f3d040;
}

.border-x-emerald-200\/30 {
  border-left-color: #a7f3d04d;
  border-right-color: #a7f3d04d;
}

.border-x-emerald-200\/40 {
  border-left-color: #a7f3d066;
  border-right-color: #a7f3d066;
}

.border-x-emerald-200\/5 {
  border-left-color: #a7f3d00d;
  border-right-color: #a7f3d00d;
}

.border-x-emerald-200\/50 {
  border-left-color: #a7f3d080;
  border-right-color: #a7f3d080;
}

.border-x-emerald-200\/60 {
  border-left-color: #a7f3d099;
  border-right-color: #a7f3d099;
}

.border-x-emerald-200\/70 {
  border-left-color: #a7f3d0b3;
  border-right-color: #a7f3d0b3;
}

.border-x-emerald-200\/75 {
  border-left-color: #a7f3d0bf;
  border-right-color: #a7f3d0bf;
}

.border-x-emerald-200\/80 {
  border-left-color: #a7f3d0cc;
  border-right-color: #a7f3d0cc;
}

.border-x-emerald-200\/90 {
  border-left-color: #a7f3d0e6;
  border-right-color: #a7f3d0e6;
}

.border-x-emerald-200\/95 {
  border-left-color: #a7f3d0f2;
  border-right-color: #a7f3d0f2;
}

.border-x-emerald-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(110 231 183 / var(--tw-border-opacity));
  border-right-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-x-emerald-300\/0 {
  border-left-color: #6ee7b700;
  border-right-color: #6ee7b700;
}

.border-x-emerald-300\/10 {
  border-left-color: #6ee7b71a;
  border-right-color: #6ee7b71a;
}

.border-x-emerald-300\/100 {
  border-left-color: #6ee7b7;
  border-right-color: #6ee7b7;
}

.border-x-emerald-300\/20 {
  border-left-color: #6ee7b733;
  border-right-color: #6ee7b733;
}

.border-x-emerald-300\/25 {
  border-left-color: #6ee7b740;
  border-right-color: #6ee7b740;
}

.border-x-emerald-300\/30 {
  border-left-color: #6ee7b74d;
  border-right-color: #6ee7b74d;
}

.border-x-emerald-300\/40 {
  border-left-color: #6ee7b766;
  border-right-color: #6ee7b766;
}

.border-x-emerald-300\/5 {
  border-left-color: #6ee7b70d;
  border-right-color: #6ee7b70d;
}

.border-x-emerald-300\/50 {
  border-left-color: #6ee7b780;
  border-right-color: #6ee7b780;
}

.border-x-emerald-300\/60 {
  border-left-color: #6ee7b799;
  border-right-color: #6ee7b799;
}

.border-x-emerald-300\/70 {
  border-left-color: #6ee7b7b3;
  border-right-color: #6ee7b7b3;
}

.border-x-emerald-300\/75 {
  border-left-color: #6ee7b7bf;
  border-right-color: #6ee7b7bf;
}

.border-x-emerald-300\/80 {
  border-left-color: #6ee7b7cc;
  border-right-color: #6ee7b7cc;
}

.border-x-emerald-300\/90 {
  border-left-color: #6ee7b7e6;
  border-right-color: #6ee7b7e6;
}

.border-x-emerald-300\/95 {
  border-left-color: #6ee7b7f2;
  border-right-color: #6ee7b7f2;
}

.border-x-emerald-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(52 211 153 / var(--tw-border-opacity));
  border-right-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-x-emerald-400\/0 {
  border-left-color: #34d39900;
  border-right-color: #34d39900;
}

.border-x-emerald-400\/10 {
  border-left-color: #34d3991a;
  border-right-color: #34d3991a;
}

.border-x-emerald-400\/100 {
  border-left-color: #34d399;
  border-right-color: #34d399;
}

.border-x-emerald-400\/20 {
  border-left-color: #34d39933;
  border-right-color: #34d39933;
}

.border-x-emerald-400\/25 {
  border-left-color: #34d39940;
  border-right-color: #34d39940;
}

.border-x-emerald-400\/30 {
  border-left-color: #34d3994d;
  border-right-color: #34d3994d;
}

.border-x-emerald-400\/40 {
  border-left-color: #34d39966;
  border-right-color: #34d39966;
}

.border-x-emerald-400\/5 {
  border-left-color: #34d3990d;
  border-right-color: #34d3990d;
}

.border-x-emerald-400\/50 {
  border-left-color: #34d39980;
  border-right-color: #34d39980;
}

.border-x-emerald-400\/60 {
  border-left-color: #34d39999;
  border-right-color: #34d39999;
}

.border-x-emerald-400\/70 {
  border-left-color: #34d399b3;
  border-right-color: #34d399b3;
}

.border-x-emerald-400\/75 {
  border-left-color: #34d399bf;
  border-right-color: #34d399bf;
}

.border-x-emerald-400\/80 {
  border-left-color: #34d399cc;
  border-right-color: #34d399cc;
}

.border-x-emerald-400\/90 {
  border-left-color: #34d399e6;
  border-right-color: #34d399e6;
}

.border-x-emerald-400\/95 {
  border-left-color: #34d399f2;
  border-right-color: #34d399f2;
}

.border-x-emerald-50 {
  --tw-border-opacity: 1;
  border-left-color: rgb(236 253 245 / var(--tw-border-opacity));
  border-right-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-x-emerald-50\/0 {
  border-left-color: #ecfdf500;
  border-right-color: #ecfdf500;
}

.border-x-emerald-50\/10 {
  border-left-color: #ecfdf51a;
  border-right-color: #ecfdf51a;
}

.border-x-emerald-50\/100 {
  border-left-color: #ecfdf5;
  border-right-color: #ecfdf5;
}

.border-x-emerald-50\/20 {
  border-left-color: #ecfdf533;
  border-right-color: #ecfdf533;
}

.border-x-emerald-50\/25 {
  border-left-color: #ecfdf540;
  border-right-color: #ecfdf540;
}

.border-x-emerald-50\/30 {
  border-left-color: #ecfdf54d;
  border-right-color: #ecfdf54d;
}

.border-x-emerald-50\/40 {
  border-left-color: #ecfdf566;
  border-right-color: #ecfdf566;
}

.border-x-emerald-50\/5 {
  border-left-color: #ecfdf50d;
  border-right-color: #ecfdf50d;
}

.border-x-emerald-50\/50 {
  border-left-color: #ecfdf580;
  border-right-color: #ecfdf580;
}

.border-x-emerald-50\/60 {
  border-left-color: #ecfdf599;
  border-right-color: #ecfdf599;
}

.border-x-emerald-50\/70 {
  border-left-color: #ecfdf5b3;
  border-right-color: #ecfdf5b3;
}

.border-x-emerald-50\/75 {
  border-left-color: #ecfdf5bf;
  border-right-color: #ecfdf5bf;
}

.border-x-emerald-50\/80 {
  border-left-color: #ecfdf5cc;
  border-right-color: #ecfdf5cc;
}

.border-x-emerald-50\/90 {
  border-left-color: #ecfdf5e6;
  border-right-color: #ecfdf5e6;
}

.border-x-emerald-50\/95 {
  border-left-color: #ecfdf5f2;
  border-right-color: #ecfdf5f2;
}

.border-x-emerald-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(16 185 129 / var(--tw-border-opacity));
  border-right-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-x-emerald-500\/0 {
  border-left-color: #10b98100;
  border-right-color: #10b98100;
}

.border-x-emerald-500\/10 {
  border-left-color: #10b9811a;
  border-right-color: #10b9811a;
}

.border-x-emerald-500\/100 {
  border-left-color: #10b981;
  border-right-color: #10b981;
}

.border-x-emerald-500\/20 {
  border-left-color: #10b98133;
  border-right-color: #10b98133;
}

.border-x-emerald-500\/25 {
  border-left-color: #10b98140;
  border-right-color: #10b98140;
}

.border-x-emerald-500\/30 {
  border-left-color: #10b9814d;
  border-right-color: #10b9814d;
}

.border-x-emerald-500\/40 {
  border-left-color: #10b98166;
  border-right-color: #10b98166;
}

.border-x-emerald-500\/5 {
  border-left-color: #10b9810d;
  border-right-color: #10b9810d;
}

.border-x-emerald-500\/50 {
  border-left-color: #10b98180;
  border-right-color: #10b98180;
}

.border-x-emerald-500\/60 {
  border-left-color: #10b98199;
  border-right-color: #10b98199;
}

.border-x-emerald-500\/70 {
  border-left-color: #10b981b3;
  border-right-color: #10b981b3;
}

.border-x-emerald-500\/75 {
  border-left-color: #10b981bf;
  border-right-color: #10b981bf;
}

.border-x-emerald-500\/80 {
  border-left-color: #10b981cc;
  border-right-color: #10b981cc;
}

.border-x-emerald-500\/90 {
  border-left-color: #10b981e6;
  border-right-color: #10b981e6;
}

.border-x-emerald-500\/95 {
  border-left-color: #10b981f2;
  border-right-color: #10b981f2;
}

.border-x-emerald-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(5 150 105 / var(--tw-border-opacity));
  border-right-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-x-emerald-600\/0 {
  border-left-color: #05966900;
  border-right-color: #05966900;
}

.border-x-emerald-600\/10 {
  border-left-color: #0596691a;
  border-right-color: #0596691a;
}

.border-x-emerald-600\/100 {
  border-left-color: #059669;
  border-right-color: #059669;
}

.border-x-emerald-600\/20 {
  border-left-color: #05966933;
  border-right-color: #05966933;
}

.border-x-emerald-600\/25 {
  border-left-color: #05966940;
  border-right-color: #05966940;
}

.border-x-emerald-600\/30 {
  border-left-color: #0596694d;
  border-right-color: #0596694d;
}

.border-x-emerald-600\/40 {
  border-left-color: #05966966;
  border-right-color: #05966966;
}

.border-x-emerald-600\/5 {
  border-left-color: #0596690d;
  border-right-color: #0596690d;
}

.border-x-emerald-600\/50 {
  border-left-color: #05966980;
  border-right-color: #05966980;
}

.border-x-emerald-600\/60 {
  border-left-color: #05966999;
  border-right-color: #05966999;
}

.border-x-emerald-600\/70 {
  border-left-color: #059669b3;
  border-right-color: #059669b3;
}

.border-x-emerald-600\/75 {
  border-left-color: #059669bf;
  border-right-color: #059669bf;
}

.border-x-emerald-600\/80 {
  border-left-color: #059669cc;
  border-right-color: #059669cc;
}

.border-x-emerald-600\/90 {
  border-left-color: #059669e6;
  border-right-color: #059669e6;
}

.border-x-emerald-600\/95 {
  border-left-color: #059669f2;
  border-right-color: #059669f2;
}

.border-x-emerald-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(4 120 87 / var(--tw-border-opacity));
  border-right-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-x-emerald-700\/0 {
  border-left-color: #04785700;
  border-right-color: #04785700;
}

.border-x-emerald-700\/10 {
  border-left-color: #0478571a;
  border-right-color: #0478571a;
}

.border-x-emerald-700\/100 {
  border-left-color: #047857;
  border-right-color: #047857;
}

.border-x-emerald-700\/20 {
  border-left-color: #04785733;
  border-right-color: #04785733;
}

.border-x-emerald-700\/25 {
  border-left-color: #04785740;
  border-right-color: #04785740;
}

.border-x-emerald-700\/30 {
  border-left-color: #0478574d;
  border-right-color: #0478574d;
}

.border-x-emerald-700\/40 {
  border-left-color: #04785766;
  border-right-color: #04785766;
}

.border-x-emerald-700\/5 {
  border-left-color: #0478570d;
  border-right-color: #0478570d;
}

.border-x-emerald-700\/50 {
  border-left-color: #04785780;
  border-right-color: #04785780;
}

.border-x-emerald-700\/60 {
  border-left-color: #04785799;
  border-right-color: #04785799;
}

.border-x-emerald-700\/70 {
  border-left-color: #047857b3;
  border-right-color: #047857b3;
}

.border-x-emerald-700\/75 {
  border-left-color: #047857bf;
  border-right-color: #047857bf;
}

.border-x-emerald-700\/80 {
  border-left-color: #047857cc;
  border-right-color: #047857cc;
}

.border-x-emerald-700\/90 {
  border-left-color: #047857e6;
  border-right-color: #047857e6;
}

.border-x-emerald-700\/95 {
  border-left-color: #047857f2;
  border-right-color: #047857f2;
}

.border-x-emerald-800 {
  --tw-border-opacity: 1;
  border-left-color: rgb(6 95 70 / var(--tw-border-opacity));
  border-right-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-x-emerald-800\/0 {
  border-left-color: #065f4600;
  border-right-color: #065f4600;
}

.border-x-emerald-800\/10 {
  border-left-color: #065f461a;
  border-right-color: #065f461a;
}

.border-x-emerald-800\/100 {
  border-left-color: #065f46;
  border-right-color: #065f46;
}

.border-x-emerald-800\/20 {
  border-left-color: #065f4633;
  border-right-color: #065f4633;
}

.border-x-emerald-800\/25 {
  border-left-color: #065f4640;
  border-right-color: #065f4640;
}

.border-x-emerald-800\/30 {
  border-left-color: #065f464d;
  border-right-color: #065f464d;
}

.border-x-emerald-800\/40 {
  border-left-color: #065f4666;
  border-right-color: #065f4666;
}

.border-x-emerald-800\/5 {
  border-left-color: #065f460d;
  border-right-color: #065f460d;
}

.border-x-emerald-800\/50 {
  border-left-color: #065f4680;
  border-right-color: #065f4680;
}

.border-x-emerald-800\/60 {
  border-left-color: #065f4699;
  border-right-color: #065f4699;
}

.border-x-emerald-800\/70 {
  border-left-color: #065f46b3;
  border-right-color: #065f46b3;
}

.border-x-emerald-800\/75 {
  border-left-color: #065f46bf;
  border-right-color: #065f46bf;
}

.border-x-emerald-800\/80 {
  border-left-color: #065f46cc;
  border-right-color: #065f46cc;
}

.border-x-emerald-800\/90 {
  border-left-color: #065f46e6;
  border-right-color: #065f46e6;
}

.border-x-emerald-800\/95 {
  border-left-color: #065f46f2;
  border-right-color: #065f46f2;
}

.border-x-emerald-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(6 78 59 / var(--tw-border-opacity));
  border-right-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-x-emerald-900\/0 {
  border-left-color: #064e3b00;
  border-right-color: #064e3b00;
}

.border-x-emerald-900\/10 {
  border-left-color: #064e3b1a;
  border-right-color: #064e3b1a;
}

.border-x-emerald-900\/100 {
  border-left-color: #064e3b;
  border-right-color: #064e3b;
}

.border-x-emerald-900\/20 {
  border-left-color: #064e3b33;
  border-right-color: #064e3b33;
}

.border-x-emerald-900\/25 {
  border-left-color: #064e3b40;
  border-right-color: #064e3b40;
}

.border-x-emerald-900\/30 {
  border-left-color: #064e3b4d;
  border-right-color: #064e3b4d;
}

.border-x-emerald-900\/40 {
  border-left-color: #064e3b66;
  border-right-color: #064e3b66;
}

.border-x-emerald-900\/5 {
  border-left-color: #064e3b0d;
  border-right-color: #064e3b0d;
}

.border-x-emerald-900\/50 {
  border-left-color: #064e3b80;
  border-right-color: #064e3b80;
}

.border-x-emerald-900\/60 {
  border-left-color: #064e3b99;
  border-right-color: #064e3b99;
}

.border-x-emerald-900\/70 {
  border-left-color: #064e3bb3;
  border-right-color: #064e3bb3;
}

.border-x-emerald-900\/75 {
  border-left-color: #064e3bbf;
  border-right-color: #064e3bbf;
}

.border-x-emerald-900\/80 {
  border-left-color: #064e3bcc;
  border-right-color: #064e3bcc;
}

.border-x-emerald-900\/90 {
  border-left-color: #064e3be6;
  border-right-color: #064e3be6;
}

.border-x-emerald-900\/95 {
  border-left-color: #064e3bf2;
  border-right-color: #064e3bf2;
}

.border-x-emerald-950 {
  --tw-border-opacity: 1;
  border-left-color: rgb(2 44 34 / var(--tw-border-opacity));
  border-right-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-x-emerald-950\/0 {
  border-left-color: #022c2200;
  border-right-color: #022c2200;
}

.border-x-emerald-950\/10 {
  border-left-color: #022c221a;
  border-right-color: #022c221a;
}

.border-x-emerald-950\/100 {
  border-left-color: #022c22;
  border-right-color: #022c22;
}

.border-x-emerald-950\/20 {
  border-left-color: #022c2233;
  border-right-color: #022c2233;
}

.border-x-emerald-950\/25 {
  border-left-color: #022c2240;
  border-right-color: #022c2240;
}

.border-x-emerald-950\/30 {
  border-left-color: #022c224d;
  border-right-color: #022c224d;
}

.border-x-emerald-950\/40 {
  border-left-color: #022c2266;
  border-right-color: #022c2266;
}

.border-x-emerald-950\/5 {
  border-left-color: #022c220d;
  border-right-color: #022c220d;
}

.border-x-emerald-950\/50 {
  border-left-color: #022c2280;
  border-right-color: #022c2280;
}

.border-x-emerald-950\/60 {
  border-left-color: #022c2299;
  border-right-color: #022c2299;
}

.border-x-emerald-950\/70 {
  border-left-color: #022c22b3;
  border-right-color: #022c22b3;
}

.border-x-emerald-950\/75 {
  border-left-color: #022c22bf;
  border-right-color: #022c22bf;
}

.border-x-emerald-950\/80 {
  border-left-color: #022c22cc;
  border-right-color: #022c22cc;
}

.border-x-emerald-950\/90 {
  border-left-color: #022c22e6;
  border-right-color: #022c22e6;
}

.border-x-emerald-950\/95 {
  border-left-color: #022c22f2;
  border-right-color: #022c22f2;
}

.border-x-lime-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(236 252 203 / var(--tw-border-opacity));
  border-right-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-x-lime-100\/0 {
  border-left-color: #ecfccb00;
  border-right-color: #ecfccb00;
}

.border-x-lime-100\/10 {
  border-left-color: #ecfccb1a;
  border-right-color: #ecfccb1a;
}

.border-x-lime-100\/100 {
  border-left-color: #ecfccb;
  border-right-color: #ecfccb;
}

.border-x-lime-100\/20 {
  border-left-color: #ecfccb33;
  border-right-color: #ecfccb33;
}

.border-x-lime-100\/25 {
  border-left-color: #ecfccb40;
  border-right-color: #ecfccb40;
}

.border-x-lime-100\/30 {
  border-left-color: #ecfccb4d;
  border-right-color: #ecfccb4d;
}

.border-x-lime-100\/40 {
  border-left-color: #ecfccb66;
  border-right-color: #ecfccb66;
}

.border-x-lime-100\/5 {
  border-left-color: #ecfccb0d;
  border-right-color: #ecfccb0d;
}

.border-x-lime-100\/50 {
  border-left-color: #ecfccb80;
  border-right-color: #ecfccb80;
}

.border-x-lime-100\/60 {
  border-left-color: #ecfccb99;
  border-right-color: #ecfccb99;
}

.border-x-lime-100\/70 {
  border-left-color: #ecfccbb3;
  border-right-color: #ecfccbb3;
}

.border-x-lime-100\/75 {
  border-left-color: #ecfccbbf;
  border-right-color: #ecfccbbf;
}

.border-x-lime-100\/80 {
  border-left-color: #ecfccbcc;
  border-right-color: #ecfccbcc;
}

.border-x-lime-100\/90 {
  border-left-color: #ecfccbe6;
  border-right-color: #ecfccbe6;
}

.border-x-lime-100\/95 {
  border-left-color: #ecfccbf2;
  border-right-color: #ecfccbf2;
}

.border-x-lime-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(217 249 157 / var(--tw-border-opacity));
  border-right-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-x-lime-200\/0 {
  border-left-color: #d9f99d00;
  border-right-color: #d9f99d00;
}

.border-x-lime-200\/10 {
  border-left-color: #d9f99d1a;
  border-right-color: #d9f99d1a;
}

.border-x-lime-200\/100 {
  border-left-color: #d9f99d;
  border-right-color: #d9f99d;
}

.border-x-lime-200\/20 {
  border-left-color: #d9f99d33;
  border-right-color: #d9f99d33;
}

.border-x-lime-200\/25 {
  border-left-color: #d9f99d40;
  border-right-color: #d9f99d40;
}

.border-x-lime-200\/30 {
  border-left-color: #d9f99d4d;
  border-right-color: #d9f99d4d;
}

.border-x-lime-200\/40 {
  border-left-color: #d9f99d66;
  border-right-color: #d9f99d66;
}

.border-x-lime-200\/5 {
  border-left-color: #d9f99d0d;
  border-right-color: #d9f99d0d;
}

.border-x-lime-200\/50 {
  border-left-color: #d9f99d80;
  border-right-color: #d9f99d80;
}

.border-x-lime-200\/60 {
  border-left-color: #d9f99d99;
  border-right-color: #d9f99d99;
}

.border-x-lime-200\/70 {
  border-left-color: #d9f99db3;
  border-right-color: #d9f99db3;
}

.border-x-lime-200\/75 {
  border-left-color: #d9f99dbf;
  border-right-color: #d9f99dbf;
}

.border-x-lime-200\/80 {
  border-left-color: #d9f99dcc;
  border-right-color: #d9f99dcc;
}

.border-x-lime-200\/90 {
  border-left-color: #d9f99de6;
  border-right-color: #d9f99de6;
}

.border-x-lime-200\/95 {
  border-left-color: #d9f99df2;
  border-right-color: #d9f99df2;
}

.border-x-lime-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(190 242 100 / var(--tw-border-opacity));
  border-right-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-x-lime-300\/0 {
  border-left-color: #bef26400;
  border-right-color: #bef26400;
}

.border-x-lime-300\/10 {
  border-left-color: #bef2641a;
  border-right-color: #bef2641a;
}

.border-x-lime-300\/100 {
  border-left-color: #bef264;
  border-right-color: #bef264;
}

.border-x-lime-300\/20 {
  border-left-color: #bef26433;
  border-right-color: #bef26433;
}

.border-x-lime-300\/25 {
  border-left-color: #bef26440;
  border-right-color: #bef26440;
}

.border-x-lime-300\/30 {
  border-left-color: #bef2644d;
  border-right-color: #bef2644d;
}

.border-x-lime-300\/40 {
  border-left-color: #bef26466;
  border-right-color: #bef26466;
}

.border-x-lime-300\/5 {
  border-left-color: #bef2640d;
  border-right-color: #bef2640d;
}

.border-x-lime-300\/50 {
  border-left-color: #bef26480;
  border-right-color: #bef26480;
}

.border-x-lime-300\/60 {
  border-left-color: #bef26499;
  border-right-color: #bef26499;
}

.border-x-lime-300\/70 {
  border-left-color: #bef264b3;
  border-right-color: #bef264b3;
}

.border-x-lime-300\/75 {
  border-left-color: #bef264bf;
  border-right-color: #bef264bf;
}

.border-x-lime-300\/80 {
  border-left-color: #bef264cc;
  border-right-color: #bef264cc;
}

.border-x-lime-300\/90 {
  border-left-color: #bef264e6;
  border-right-color: #bef264e6;
}

.border-x-lime-300\/95 {
  border-left-color: #bef264f2;
  border-right-color: #bef264f2;
}

.border-x-lime-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(163 230 53 / var(--tw-border-opacity));
  border-right-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-x-lime-400\/0 {
  border-left-color: #a3e63500;
  border-right-color: #a3e63500;
}

.border-x-lime-400\/10 {
  border-left-color: #a3e6351a;
  border-right-color: #a3e6351a;
}

.border-x-lime-400\/100 {
  border-left-color: #a3e635;
  border-right-color: #a3e635;
}

.border-x-lime-400\/20 {
  border-left-color: #a3e63533;
  border-right-color: #a3e63533;
}

.border-x-lime-400\/25 {
  border-left-color: #a3e63540;
  border-right-color: #a3e63540;
}

.border-x-lime-400\/30 {
  border-left-color: #a3e6354d;
  border-right-color: #a3e6354d;
}

.border-x-lime-400\/40 {
  border-left-color: #a3e63566;
  border-right-color: #a3e63566;
}

.border-x-lime-400\/5 {
  border-left-color: #a3e6350d;
  border-right-color: #a3e6350d;
}

.border-x-lime-400\/50 {
  border-left-color: #a3e63580;
  border-right-color: #a3e63580;
}

.border-x-lime-400\/60 {
  border-left-color: #a3e63599;
  border-right-color: #a3e63599;
}

.border-x-lime-400\/70 {
  border-left-color: #a3e635b3;
  border-right-color: #a3e635b3;
}

.border-x-lime-400\/75 {
  border-left-color: #a3e635bf;
  border-right-color: #a3e635bf;
}

.border-x-lime-400\/80 {
  border-left-color: #a3e635cc;
  border-right-color: #a3e635cc;
}

.border-x-lime-400\/90 {
  border-left-color: #a3e635e6;
  border-right-color: #a3e635e6;
}

.border-x-lime-400\/95 {
  border-left-color: #a3e635f2;
  border-right-color: #a3e635f2;
}

.border-x-lime-50 {
  --tw-border-opacity: 1;
  border-left-color: rgb(247 254 231 / var(--tw-border-opacity));
  border-right-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-x-lime-50\/0 {
  border-left-color: #f7fee700;
  border-right-color: #f7fee700;
}

.border-x-lime-50\/10 {
  border-left-color: #f7fee71a;
  border-right-color: #f7fee71a;
}

.border-x-lime-50\/100 {
  border-left-color: #f7fee7;
  border-right-color: #f7fee7;
}

.border-x-lime-50\/20 {
  border-left-color: #f7fee733;
  border-right-color: #f7fee733;
}

.border-x-lime-50\/25 {
  border-left-color: #f7fee740;
  border-right-color: #f7fee740;
}

.border-x-lime-50\/30 {
  border-left-color: #f7fee74d;
  border-right-color: #f7fee74d;
}

.border-x-lime-50\/40 {
  border-left-color: #f7fee766;
  border-right-color: #f7fee766;
}

.border-x-lime-50\/5 {
  border-left-color: #f7fee70d;
  border-right-color: #f7fee70d;
}

.border-x-lime-50\/50 {
  border-left-color: #f7fee780;
  border-right-color: #f7fee780;
}

.border-x-lime-50\/60 {
  border-left-color: #f7fee799;
  border-right-color: #f7fee799;
}

.border-x-lime-50\/70 {
  border-left-color: #f7fee7b3;
  border-right-color: #f7fee7b3;
}

.border-x-lime-50\/75 {
  border-left-color: #f7fee7bf;
  border-right-color: #f7fee7bf;
}

.border-x-lime-50\/80 {
  border-left-color: #f7fee7cc;
  border-right-color: #f7fee7cc;
}

.border-x-lime-50\/90 {
  border-left-color: #f7fee7e6;
  border-right-color: #f7fee7e6;
}

.border-x-lime-50\/95 {
  border-left-color: #f7fee7f2;
  border-right-color: #f7fee7f2;
}

.border-x-lime-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(132 204 22 / var(--tw-border-opacity));
  border-right-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-x-lime-500\/0 {
  border-left-color: #84cc1600;
  border-right-color: #84cc1600;
}

.border-x-lime-500\/10 {
  border-left-color: #84cc161a;
  border-right-color: #84cc161a;
}

.border-x-lime-500\/100 {
  border-left-color: #84cc16;
  border-right-color: #84cc16;
}

.border-x-lime-500\/20 {
  border-left-color: #84cc1633;
  border-right-color: #84cc1633;
}

.border-x-lime-500\/25 {
  border-left-color: #84cc1640;
  border-right-color: #84cc1640;
}

.border-x-lime-500\/30 {
  border-left-color: #84cc164d;
  border-right-color: #84cc164d;
}

.border-x-lime-500\/40 {
  border-left-color: #84cc1666;
  border-right-color: #84cc1666;
}

.border-x-lime-500\/5 {
  border-left-color: #84cc160d;
  border-right-color: #84cc160d;
}

.border-x-lime-500\/50 {
  border-left-color: #84cc1680;
  border-right-color: #84cc1680;
}

.border-x-lime-500\/60 {
  border-left-color: #84cc1699;
  border-right-color: #84cc1699;
}

.border-x-lime-500\/70 {
  border-left-color: #84cc16b3;
  border-right-color: #84cc16b3;
}

.border-x-lime-500\/75 {
  border-left-color: #84cc16bf;
  border-right-color: #84cc16bf;
}

.border-x-lime-500\/80 {
  border-left-color: #84cc16cc;
  border-right-color: #84cc16cc;
}

.border-x-lime-500\/90 {
  border-left-color: #84cc16e6;
  border-right-color: #84cc16e6;
}

.border-x-lime-500\/95 {
  border-left-color: #84cc16f2;
  border-right-color: #84cc16f2;
}

.border-x-lime-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(101 163 13 / var(--tw-border-opacity));
  border-right-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-x-lime-600\/0 {
  border-left-color: #65a30d00;
  border-right-color: #65a30d00;
}

.border-x-lime-600\/10 {
  border-left-color: #65a30d1a;
  border-right-color: #65a30d1a;
}

.border-x-lime-600\/100 {
  border-left-color: #65a30d;
  border-right-color: #65a30d;
}

.border-x-lime-600\/20 {
  border-left-color: #65a30d33;
  border-right-color: #65a30d33;
}

.border-x-lime-600\/25 {
  border-left-color: #65a30d40;
  border-right-color: #65a30d40;
}

.border-x-lime-600\/30 {
  border-left-color: #65a30d4d;
  border-right-color: #65a30d4d;
}

.border-x-lime-600\/40 {
  border-left-color: #65a30d66;
  border-right-color: #65a30d66;
}

.border-x-lime-600\/5 {
  border-left-color: #65a30d0d;
  border-right-color: #65a30d0d;
}

.border-x-lime-600\/50 {
  border-left-color: #65a30d80;
  border-right-color: #65a30d80;
}

.border-x-lime-600\/60 {
  border-left-color: #65a30d99;
  border-right-color: #65a30d99;
}

.border-x-lime-600\/70 {
  border-left-color: #65a30db3;
  border-right-color: #65a30db3;
}

.border-x-lime-600\/75 {
  border-left-color: #65a30dbf;
  border-right-color: #65a30dbf;
}

.border-x-lime-600\/80 {
  border-left-color: #65a30dcc;
  border-right-color: #65a30dcc;
}

.border-x-lime-600\/90 {
  border-left-color: #65a30de6;
  border-right-color: #65a30de6;
}

.border-x-lime-600\/95 {
  border-left-color: #65a30df2;
  border-right-color: #65a30df2;
}

.border-x-lime-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(77 124 15 / var(--tw-border-opacity));
  border-right-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-x-lime-700\/0 {
  border-left-color: #4d7c0f00;
  border-right-color: #4d7c0f00;
}

.border-x-lime-700\/10 {
  border-left-color: #4d7c0f1a;
  border-right-color: #4d7c0f1a;
}

.border-x-lime-700\/100 {
  border-left-color: #4d7c0f;
  border-right-color: #4d7c0f;
}

.border-x-lime-700\/20 {
  border-left-color: #4d7c0f33;
  border-right-color: #4d7c0f33;
}

.border-x-lime-700\/25 {
  border-left-color: #4d7c0f40;
  border-right-color: #4d7c0f40;
}

.border-x-lime-700\/30 {
  border-left-color: #4d7c0f4d;
  border-right-color: #4d7c0f4d;
}

.border-x-lime-700\/40 {
  border-left-color: #4d7c0f66;
  border-right-color: #4d7c0f66;
}

.border-x-lime-700\/5 {
  border-left-color: #4d7c0f0d;
  border-right-color: #4d7c0f0d;
}

.border-x-lime-700\/50 {
  border-left-color: #4d7c0f80;
  border-right-color: #4d7c0f80;
}

.border-x-lime-700\/60 {
  border-left-color: #4d7c0f99;
  border-right-color: #4d7c0f99;
}

.border-x-lime-700\/70 {
  border-left-color: #4d7c0fb3;
  border-right-color: #4d7c0fb3;
}

.border-x-lime-700\/75 {
  border-left-color: #4d7c0fbf;
  border-right-color: #4d7c0fbf;
}

.border-x-lime-700\/80 {
  border-left-color: #4d7c0fcc;
  border-right-color: #4d7c0fcc;
}

.border-x-lime-700\/90 {
  border-left-color: #4d7c0fe6;
  border-right-color: #4d7c0fe6;
}

.border-x-lime-700\/95 {
  border-left-color: #4d7c0ff2;
  border-right-color: #4d7c0ff2;
}

.border-x-lime-800 {
  --tw-border-opacity: 1;
  border-left-color: rgb(63 98 18 / var(--tw-border-opacity));
  border-right-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-x-lime-800\/0 {
  border-left-color: #3f621200;
  border-right-color: #3f621200;
}

.border-x-lime-800\/10 {
  border-left-color: #3f62121a;
  border-right-color: #3f62121a;
}

.border-x-lime-800\/100 {
  border-left-color: #3f6212;
  border-right-color: #3f6212;
}

.border-x-lime-800\/20 {
  border-left-color: #3f621233;
  border-right-color: #3f621233;
}

.border-x-lime-800\/25 {
  border-left-color: #3f621240;
  border-right-color: #3f621240;
}

.border-x-lime-800\/30 {
  border-left-color: #3f62124d;
  border-right-color: #3f62124d;
}

.border-x-lime-800\/40 {
  border-left-color: #3f621266;
  border-right-color: #3f621266;
}

.border-x-lime-800\/5 {
  border-left-color: #3f62120d;
  border-right-color: #3f62120d;
}

.border-x-lime-800\/50 {
  border-left-color: #3f621280;
  border-right-color: #3f621280;
}

.border-x-lime-800\/60 {
  border-left-color: #3f621299;
  border-right-color: #3f621299;
}

.border-x-lime-800\/70 {
  border-left-color: #3f6212b3;
  border-right-color: #3f6212b3;
}

.border-x-lime-800\/75 {
  border-left-color: #3f6212bf;
  border-right-color: #3f6212bf;
}

.border-x-lime-800\/80 {
  border-left-color: #3f6212cc;
  border-right-color: #3f6212cc;
}

.border-x-lime-800\/90 {
  border-left-color: #3f6212e6;
  border-right-color: #3f6212e6;
}

.border-x-lime-800\/95 {
  border-left-color: #3f6212f2;
  border-right-color: #3f6212f2;
}

.border-x-lime-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(54 83 20 / var(--tw-border-opacity));
  border-right-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-x-lime-900\/0 {
  border-left-color: #36531400;
  border-right-color: #36531400;
}

.border-x-lime-900\/10 {
  border-left-color: #3653141a;
  border-right-color: #3653141a;
}

.border-x-lime-900\/100 {
  border-left-color: #365314;
  border-right-color: #365314;
}

.border-x-lime-900\/20 {
  border-left-color: #36531433;
  border-right-color: #36531433;
}

.border-x-lime-900\/25 {
  border-left-color: #36531440;
  border-right-color: #36531440;
}

.border-x-lime-900\/30 {
  border-left-color: #3653144d;
  border-right-color: #3653144d;
}

.border-x-lime-900\/40 {
  border-left-color: #36531466;
  border-right-color: #36531466;
}

.border-x-lime-900\/5 {
  border-left-color: #3653140d;
  border-right-color: #3653140d;
}

.border-x-lime-900\/50 {
  border-left-color: #36531480;
  border-right-color: #36531480;
}

.border-x-lime-900\/60 {
  border-left-color: #36531499;
  border-right-color: #36531499;
}

.border-x-lime-900\/70 {
  border-left-color: #365314b3;
  border-right-color: #365314b3;
}

.border-x-lime-900\/75 {
  border-left-color: #365314bf;
  border-right-color: #365314bf;
}

.border-x-lime-900\/80 {
  border-left-color: #365314cc;
  border-right-color: #365314cc;
}

.border-x-lime-900\/90 {
  border-left-color: #365314e6;
  border-right-color: #365314e6;
}

.border-x-lime-900\/95 {
  border-left-color: #365314f2;
  border-right-color: #365314f2;
}

.border-x-lime-950 {
  --tw-border-opacity: 1;
  border-left-color: rgb(26 46 5 / var(--tw-border-opacity));
  border-right-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-x-lime-950\/0 {
  border-left-color: #1a2e0500;
  border-right-color: #1a2e0500;
}

.border-x-lime-950\/10 {
  border-left-color: #1a2e051a;
  border-right-color: #1a2e051a;
}

.border-x-lime-950\/100 {
  border-left-color: #1a2e05;
  border-right-color: #1a2e05;
}

.border-x-lime-950\/20 {
  border-left-color: #1a2e0533;
  border-right-color: #1a2e0533;
}

.border-x-lime-950\/25 {
  border-left-color: #1a2e0540;
  border-right-color: #1a2e0540;
}

.border-x-lime-950\/30 {
  border-left-color: #1a2e054d;
  border-right-color: #1a2e054d;
}

.border-x-lime-950\/40 {
  border-left-color: #1a2e0566;
  border-right-color: #1a2e0566;
}

.border-x-lime-950\/5 {
  border-left-color: #1a2e050d;
  border-right-color: #1a2e050d;
}

.border-x-lime-950\/50 {
  border-left-color: #1a2e0580;
  border-right-color: #1a2e0580;
}

.border-x-lime-950\/60 {
  border-left-color: #1a2e0599;
  border-right-color: #1a2e0599;
}

.border-x-lime-950\/70 {
  border-left-color: #1a2e05b3;
  border-right-color: #1a2e05b3;
}

.border-x-lime-950\/75 {
  border-left-color: #1a2e05bf;
  border-right-color: #1a2e05bf;
}

.border-x-lime-950\/80 {
  border-left-color: #1a2e05cc;
  border-right-color: #1a2e05cc;
}

.border-x-lime-950\/90 {
  border-left-color: #1a2e05e6;
  border-right-color: #1a2e05e6;
}

.border-x-lime-950\/95 {
  border-left-color: #1a2e05f2;
  border-right-color: #1a2e05f2;
}

.border-x-pink-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(252 231 243 / var(--tw-border-opacity));
  border-right-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-x-pink-100\/0 {
  border-left-color: #fce7f300;
  border-right-color: #fce7f300;
}

.border-x-pink-100\/10 {
  border-left-color: #fce7f31a;
  border-right-color: #fce7f31a;
}

.border-x-pink-100\/100 {
  border-left-color: #fce7f3;
  border-right-color: #fce7f3;
}

.border-x-pink-100\/20 {
  border-left-color: #fce7f333;
  border-right-color: #fce7f333;
}

.border-x-pink-100\/25 {
  border-left-color: #fce7f340;
  border-right-color: #fce7f340;
}

.border-x-pink-100\/30 {
  border-left-color: #fce7f34d;
  border-right-color: #fce7f34d;
}

.border-x-pink-100\/40 {
  border-left-color: #fce7f366;
  border-right-color: #fce7f366;
}

.border-x-pink-100\/5 {
  border-left-color: #fce7f30d;
  border-right-color: #fce7f30d;
}

.border-x-pink-100\/50 {
  border-left-color: #fce7f380;
  border-right-color: #fce7f380;
}

.border-x-pink-100\/60 {
  border-left-color: #fce7f399;
  border-right-color: #fce7f399;
}

.border-x-pink-100\/70 {
  border-left-color: #fce7f3b3;
  border-right-color: #fce7f3b3;
}

.border-x-pink-100\/75 {
  border-left-color: #fce7f3bf;
  border-right-color: #fce7f3bf;
}

.border-x-pink-100\/80 {
  border-left-color: #fce7f3cc;
  border-right-color: #fce7f3cc;
}

.border-x-pink-100\/90 {
  border-left-color: #fce7f3e6;
  border-right-color: #fce7f3e6;
}

.border-x-pink-100\/95 {
  border-left-color: #fce7f3f2;
  border-right-color: #fce7f3f2;
}

.border-x-pink-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(251 207 232 / var(--tw-border-opacity));
  border-right-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-x-pink-200\/0 {
  border-left-color: #fbcfe800;
  border-right-color: #fbcfe800;
}

.border-x-pink-200\/10 {
  border-left-color: #fbcfe81a;
  border-right-color: #fbcfe81a;
}

.border-x-pink-200\/100 {
  border-left-color: #fbcfe8;
  border-right-color: #fbcfe8;
}

.border-x-pink-200\/20 {
  border-left-color: #fbcfe833;
  border-right-color: #fbcfe833;
}

.border-x-pink-200\/25 {
  border-left-color: #fbcfe840;
  border-right-color: #fbcfe840;
}

.border-x-pink-200\/30 {
  border-left-color: #fbcfe84d;
  border-right-color: #fbcfe84d;
}

.border-x-pink-200\/40 {
  border-left-color: #fbcfe866;
  border-right-color: #fbcfe866;
}

.border-x-pink-200\/5 {
  border-left-color: #fbcfe80d;
  border-right-color: #fbcfe80d;
}

.border-x-pink-200\/50 {
  border-left-color: #fbcfe880;
  border-right-color: #fbcfe880;
}

.border-x-pink-200\/60 {
  border-left-color: #fbcfe899;
  border-right-color: #fbcfe899;
}

.border-x-pink-200\/70 {
  border-left-color: #fbcfe8b3;
  border-right-color: #fbcfe8b3;
}

.border-x-pink-200\/75 {
  border-left-color: #fbcfe8bf;
  border-right-color: #fbcfe8bf;
}

.border-x-pink-200\/80 {
  border-left-color: #fbcfe8cc;
  border-right-color: #fbcfe8cc;
}

.border-x-pink-200\/90 {
  border-left-color: #fbcfe8e6;
  border-right-color: #fbcfe8e6;
}

.border-x-pink-200\/95 {
  border-left-color: #fbcfe8f2;
  border-right-color: #fbcfe8f2;
}

.border-x-pink-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(249 168 212 / var(--tw-border-opacity));
  border-right-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-x-pink-300\/0 {
  border-left-color: #f9a8d400;
  border-right-color: #f9a8d400;
}

.border-x-pink-300\/10 {
  border-left-color: #f9a8d41a;
  border-right-color: #f9a8d41a;
}

.border-x-pink-300\/100 {
  border-left-color: #f9a8d4;
  border-right-color: #f9a8d4;
}

.border-x-pink-300\/20 {
  border-left-color: #f9a8d433;
  border-right-color: #f9a8d433;
}

.border-x-pink-300\/25 {
  border-left-color: #f9a8d440;
  border-right-color: #f9a8d440;
}

.border-x-pink-300\/30 {
  border-left-color: #f9a8d44d;
  border-right-color: #f9a8d44d;
}

.border-x-pink-300\/40 {
  border-left-color: #f9a8d466;
  border-right-color: #f9a8d466;
}

.border-x-pink-300\/5 {
  border-left-color: #f9a8d40d;
  border-right-color: #f9a8d40d;
}

.border-x-pink-300\/50 {
  border-left-color: #f9a8d480;
  border-right-color: #f9a8d480;
}

.border-x-pink-300\/60 {
  border-left-color: #f9a8d499;
  border-right-color: #f9a8d499;
}

.border-x-pink-300\/70 {
  border-left-color: #f9a8d4b3;
  border-right-color: #f9a8d4b3;
}

.border-x-pink-300\/75 {
  border-left-color: #f9a8d4bf;
  border-right-color: #f9a8d4bf;
}

.border-x-pink-300\/80 {
  border-left-color: #f9a8d4cc;
  border-right-color: #f9a8d4cc;
}

.border-x-pink-300\/90 {
  border-left-color: #f9a8d4e6;
  border-right-color: #f9a8d4e6;
}

.border-x-pink-300\/95 {
  border-left-color: #f9a8d4f2;
  border-right-color: #f9a8d4f2;
}

.border-x-pink-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(244 114 182 / var(--tw-border-opacity));
  border-right-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-x-pink-400\/0 {
  border-left-color: #f472b600;
  border-right-color: #f472b600;
}

.border-x-pink-400\/10 {
  border-left-color: #f472b61a;
  border-right-color: #f472b61a;
}

.border-x-pink-400\/100 {
  border-left-color: #f472b6;
  border-right-color: #f472b6;
}

.border-x-pink-400\/20 {
  border-left-color: #f472b633;
  border-right-color: #f472b633;
}

.border-x-pink-400\/25 {
  border-left-color: #f472b640;
  border-right-color: #f472b640;
}

.border-x-pink-400\/30 {
  border-left-color: #f472b64d;
  border-right-color: #f472b64d;
}

.border-x-pink-400\/40 {
  border-left-color: #f472b666;
  border-right-color: #f472b666;
}

.border-x-pink-400\/5 {
  border-left-color: #f472b60d;
  border-right-color: #f472b60d;
}

.border-x-pink-400\/50 {
  border-left-color: #f472b680;
  border-right-color: #f472b680;
}

.border-x-pink-400\/60 {
  border-left-color: #f472b699;
  border-right-color: #f472b699;
}

.border-x-pink-400\/70 {
  border-left-color: #f472b6b3;
  border-right-color: #f472b6b3;
}

.border-x-pink-400\/75 {
  border-left-color: #f472b6bf;
  border-right-color: #f472b6bf;
}

.border-x-pink-400\/80 {
  border-left-color: #f472b6cc;
  border-right-color: #f472b6cc;
}

.border-x-pink-400\/90 {
  border-left-color: #f472b6e6;
  border-right-color: #f472b6e6;
}

.border-x-pink-400\/95 {
  border-left-color: #f472b6f2;
  border-right-color: #f472b6f2;
}

.border-x-pink-50 {
  --tw-border-opacity: 1;
  border-left-color: rgb(253 242 248 / var(--tw-border-opacity));
  border-right-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-x-pink-50\/0 {
  border-left-color: #fdf2f800;
  border-right-color: #fdf2f800;
}

.border-x-pink-50\/10 {
  border-left-color: #fdf2f81a;
  border-right-color: #fdf2f81a;
}

.border-x-pink-50\/100 {
  border-left-color: #fdf2f8;
  border-right-color: #fdf2f8;
}

.border-x-pink-50\/20 {
  border-left-color: #fdf2f833;
  border-right-color: #fdf2f833;
}

.border-x-pink-50\/25 {
  border-left-color: #fdf2f840;
  border-right-color: #fdf2f840;
}

.border-x-pink-50\/30 {
  border-left-color: #fdf2f84d;
  border-right-color: #fdf2f84d;
}

.border-x-pink-50\/40 {
  border-left-color: #fdf2f866;
  border-right-color: #fdf2f866;
}

.border-x-pink-50\/5 {
  border-left-color: #fdf2f80d;
  border-right-color: #fdf2f80d;
}

.border-x-pink-50\/50 {
  border-left-color: #fdf2f880;
  border-right-color: #fdf2f880;
}

.border-x-pink-50\/60 {
  border-left-color: #fdf2f899;
  border-right-color: #fdf2f899;
}

.border-x-pink-50\/70 {
  border-left-color: #fdf2f8b3;
  border-right-color: #fdf2f8b3;
}

.border-x-pink-50\/75 {
  border-left-color: #fdf2f8bf;
  border-right-color: #fdf2f8bf;
}

.border-x-pink-50\/80 {
  border-left-color: #fdf2f8cc;
  border-right-color: #fdf2f8cc;
}

.border-x-pink-50\/90 {
  border-left-color: #fdf2f8e6;
  border-right-color: #fdf2f8e6;
}

.border-x-pink-50\/95 {
  border-left-color: #fdf2f8f2;
  border-right-color: #fdf2f8f2;
}

.border-x-pink-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(236 72 153 / var(--tw-border-opacity));
  border-right-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-x-pink-500\/0 {
  border-left-color: #ec489900;
  border-right-color: #ec489900;
}

.border-x-pink-500\/10 {
  border-left-color: #ec48991a;
  border-right-color: #ec48991a;
}

.border-x-pink-500\/100 {
  border-left-color: #ec4899;
  border-right-color: #ec4899;
}

.border-x-pink-500\/20 {
  border-left-color: #ec489933;
  border-right-color: #ec489933;
}

.border-x-pink-500\/25 {
  border-left-color: #ec489940;
  border-right-color: #ec489940;
}

.border-x-pink-500\/30 {
  border-left-color: #ec48994d;
  border-right-color: #ec48994d;
}

.border-x-pink-500\/40 {
  border-left-color: #ec489966;
  border-right-color: #ec489966;
}

.border-x-pink-500\/5 {
  border-left-color: #ec48990d;
  border-right-color: #ec48990d;
}

.border-x-pink-500\/50 {
  border-left-color: #ec489980;
  border-right-color: #ec489980;
}

.border-x-pink-500\/60 {
  border-left-color: #ec489999;
  border-right-color: #ec489999;
}

.border-x-pink-500\/70 {
  border-left-color: #ec4899b3;
  border-right-color: #ec4899b3;
}

.border-x-pink-500\/75 {
  border-left-color: #ec4899bf;
  border-right-color: #ec4899bf;
}

.border-x-pink-500\/80 {
  border-left-color: #ec4899cc;
  border-right-color: #ec4899cc;
}

.border-x-pink-500\/90 {
  border-left-color: #ec4899e6;
  border-right-color: #ec4899e6;
}

.border-x-pink-500\/95 {
  border-left-color: #ec4899f2;
  border-right-color: #ec4899f2;
}

.border-x-pink-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(219 39 119 / var(--tw-border-opacity));
  border-right-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-x-pink-600\/0 {
  border-left-color: #db277700;
  border-right-color: #db277700;
}

.border-x-pink-600\/10 {
  border-left-color: #db27771a;
  border-right-color: #db27771a;
}

.border-x-pink-600\/100 {
  border-left-color: #db2777;
  border-right-color: #db2777;
}

.border-x-pink-600\/20 {
  border-left-color: #db277733;
  border-right-color: #db277733;
}

.border-x-pink-600\/25 {
  border-left-color: #db277740;
  border-right-color: #db277740;
}

.border-x-pink-600\/30 {
  border-left-color: #db27774d;
  border-right-color: #db27774d;
}

.border-x-pink-600\/40 {
  border-left-color: #db277766;
  border-right-color: #db277766;
}

.border-x-pink-600\/5 {
  border-left-color: #db27770d;
  border-right-color: #db27770d;
}

.border-x-pink-600\/50 {
  border-left-color: #db277780;
  border-right-color: #db277780;
}

.border-x-pink-600\/60 {
  border-left-color: #db277799;
  border-right-color: #db277799;
}

.border-x-pink-600\/70 {
  border-left-color: #db2777b3;
  border-right-color: #db2777b3;
}

.border-x-pink-600\/75 {
  border-left-color: #db2777bf;
  border-right-color: #db2777bf;
}

.border-x-pink-600\/80 {
  border-left-color: #db2777cc;
  border-right-color: #db2777cc;
}

.border-x-pink-600\/90 {
  border-left-color: #db2777e6;
  border-right-color: #db2777e6;
}

.border-x-pink-600\/95 {
  border-left-color: #db2777f2;
  border-right-color: #db2777f2;
}

.border-x-pink-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(190 24 93 / var(--tw-border-opacity));
  border-right-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-x-pink-700\/0 {
  border-left-color: #be185d00;
  border-right-color: #be185d00;
}

.border-x-pink-700\/10 {
  border-left-color: #be185d1a;
  border-right-color: #be185d1a;
}

.border-x-pink-700\/100 {
  border-left-color: #be185d;
  border-right-color: #be185d;
}

.border-x-pink-700\/20 {
  border-left-color: #be185d33;
  border-right-color: #be185d33;
}

.border-x-pink-700\/25 {
  border-left-color: #be185d40;
  border-right-color: #be185d40;
}

.border-x-pink-700\/30 {
  border-left-color: #be185d4d;
  border-right-color: #be185d4d;
}

.border-x-pink-700\/40 {
  border-left-color: #be185d66;
  border-right-color: #be185d66;
}

.border-x-pink-700\/5 {
  border-left-color: #be185d0d;
  border-right-color: #be185d0d;
}

.border-x-pink-700\/50 {
  border-left-color: #be185d80;
  border-right-color: #be185d80;
}

.border-x-pink-700\/60 {
  border-left-color: #be185d99;
  border-right-color: #be185d99;
}

.border-x-pink-700\/70 {
  border-left-color: #be185db3;
  border-right-color: #be185db3;
}

.border-x-pink-700\/75 {
  border-left-color: #be185dbf;
  border-right-color: #be185dbf;
}

.border-x-pink-700\/80 {
  border-left-color: #be185dcc;
  border-right-color: #be185dcc;
}

.border-x-pink-700\/90 {
  border-left-color: #be185de6;
  border-right-color: #be185de6;
}

.border-x-pink-700\/95 {
  border-left-color: #be185df2;
  border-right-color: #be185df2;
}

.border-x-pink-800 {
  --tw-border-opacity: 1;
  border-left-color: rgb(157 23 77 / var(--tw-border-opacity));
  border-right-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-x-pink-800\/0 {
  border-left-color: #9d174d00;
  border-right-color: #9d174d00;
}

.border-x-pink-800\/10 {
  border-left-color: #9d174d1a;
  border-right-color: #9d174d1a;
}

.border-x-pink-800\/100 {
  border-left-color: #9d174d;
  border-right-color: #9d174d;
}

.border-x-pink-800\/20 {
  border-left-color: #9d174d33;
  border-right-color: #9d174d33;
}

.border-x-pink-800\/25 {
  border-left-color: #9d174d40;
  border-right-color: #9d174d40;
}

.border-x-pink-800\/30 {
  border-left-color: #9d174d4d;
  border-right-color: #9d174d4d;
}

.border-x-pink-800\/40 {
  border-left-color: #9d174d66;
  border-right-color: #9d174d66;
}

.border-x-pink-800\/5 {
  border-left-color: #9d174d0d;
  border-right-color: #9d174d0d;
}

.border-x-pink-800\/50 {
  border-left-color: #9d174d80;
  border-right-color: #9d174d80;
}

.border-x-pink-800\/60 {
  border-left-color: #9d174d99;
  border-right-color: #9d174d99;
}

.border-x-pink-800\/70 {
  border-left-color: #9d174db3;
  border-right-color: #9d174db3;
}

.border-x-pink-800\/75 {
  border-left-color: #9d174dbf;
  border-right-color: #9d174dbf;
}

.border-x-pink-800\/80 {
  border-left-color: #9d174dcc;
  border-right-color: #9d174dcc;
}

.border-x-pink-800\/90 {
  border-left-color: #9d174de6;
  border-right-color: #9d174de6;
}

.border-x-pink-800\/95 {
  border-left-color: #9d174df2;
  border-right-color: #9d174df2;
}

.border-x-pink-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(131 24 67 / var(--tw-border-opacity));
  border-right-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-x-pink-900\/0 {
  border-left-color: #83184300;
  border-right-color: #83184300;
}

.border-x-pink-900\/10 {
  border-left-color: #8318431a;
  border-right-color: #8318431a;
}

.border-x-pink-900\/100 {
  border-left-color: #831843;
  border-right-color: #831843;
}

.border-x-pink-900\/20 {
  border-left-color: #83184333;
  border-right-color: #83184333;
}

.border-x-pink-900\/25 {
  border-left-color: #83184340;
  border-right-color: #83184340;
}

.border-x-pink-900\/30 {
  border-left-color: #8318434d;
  border-right-color: #8318434d;
}

.border-x-pink-900\/40 {
  border-left-color: #83184366;
  border-right-color: #83184366;
}

.border-x-pink-900\/5 {
  border-left-color: #8318430d;
  border-right-color: #8318430d;
}

.border-x-pink-900\/50 {
  border-left-color: #83184380;
  border-right-color: #83184380;
}

.border-x-pink-900\/60 {
  border-left-color: #83184399;
  border-right-color: #83184399;
}

.border-x-pink-900\/70 {
  border-left-color: #831843b3;
  border-right-color: #831843b3;
}

.border-x-pink-900\/75 {
  border-left-color: #831843bf;
  border-right-color: #831843bf;
}

.border-x-pink-900\/80 {
  border-left-color: #831843cc;
  border-right-color: #831843cc;
}

.border-x-pink-900\/90 {
  border-left-color: #831843e6;
  border-right-color: #831843e6;
}

.border-x-pink-900\/95 {
  border-left-color: #831843f2;
  border-right-color: #831843f2;
}

.border-x-pink-950 {
  --tw-border-opacity: 1;
  border-left-color: rgb(80 7 36 / var(--tw-border-opacity));
  border-right-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-x-pink-950\/0 {
  border-left-color: #50072400;
  border-right-color: #50072400;
}

.border-x-pink-950\/10 {
  border-left-color: #5007241a;
  border-right-color: #5007241a;
}

.border-x-pink-950\/100 {
  border-left-color: #500724;
  border-right-color: #500724;
}

.border-x-pink-950\/20 {
  border-left-color: #50072433;
  border-right-color: #50072433;
}

.border-x-pink-950\/25 {
  border-left-color: #50072440;
  border-right-color: #50072440;
}

.border-x-pink-950\/30 {
  border-left-color: #5007244d;
  border-right-color: #5007244d;
}

.border-x-pink-950\/40 {
  border-left-color: #50072466;
  border-right-color: #50072466;
}

.border-x-pink-950\/5 {
  border-left-color: #5007240d;
  border-right-color: #5007240d;
}

.border-x-pink-950\/50 {
  border-left-color: #50072480;
  border-right-color: #50072480;
}

.border-x-pink-950\/60 {
  border-left-color: #50072499;
  border-right-color: #50072499;
}

.border-x-pink-950\/70 {
  border-left-color: #500724b3;
  border-right-color: #500724b3;
}

.border-x-pink-950\/75 {
  border-left-color: #500724bf;
  border-right-color: #500724bf;
}

.border-x-pink-950\/80 {
  border-left-color: #500724cc;
  border-right-color: #500724cc;
}

.border-x-pink-950\/90 {
  border-left-color: #500724e6;
  border-right-color: #500724e6;
}

.border-x-pink-950\/95 {
  border-left-color: #500724f2;
  border-right-color: #500724f2;
}

.border-x-primary {
  --tw-border-opacity: 1;
  border-left-color: hsl(var(--primary) / var(--tw-border-opacity));
  border-right-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-x-primary\/0 {
  border-left-color: hsl(var(--primary) / 0);
  border-right-color: hsl(var(--primary) / 0);
}

.border-x-primary\/10 {
  border-left-color: hsl(var(--primary) / .1);
  border-right-color: hsl(var(--primary) / .1);
}

.border-x-primary\/100 {
  border-left-color: hsl(var(--primary) / 1);
  border-right-color: hsl(var(--primary) / 1);
}

.border-x-primary\/20 {
  border-left-color: hsl(var(--primary) / .2);
  border-right-color: hsl(var(--primary) / .2);
}

.border-x-primary\/25 {
  border-left-color: hsl(var(--primary) / .25);
  border-right-color: hsl(var(--primary) / .25);
}

.border-x-primary\/30 {
  border-left-color: hsl(var(--primary) / .3);
  border-right-color: hsl(var(--primary) / .3);
}

.border-x-primary\/40 {
  border-left-color: hsl(var(--primary) / .4);
  border-right-color: hsl(var(--primary) / .4);
}

.border-x-primary\/5 {
  border-left-color: hsl(var(--primary) / .05);
  border-right-color: hsl(var(--primary) / .05);
}

.border-x-primary\/50 {
  border-left-color: hsl(var(--primary) / .5);
  border-right-color: hsl(var(--primary) / .5);
}

.border-x-primary\/60 {
  border-left-color: hsl(var(--primary) / .6);
  border-right-color: hsl(var(--primary) / .6);
}

.border-x-primary\/70 {
  border-left-color: hsl(var(--primary) / .7);
  border-right-color: hsl(var(--primary) / .7);
}

.border-x-primary\/75 {
  border-left-color: hsl(var(--primary) / .75);
  border-right-color: hsl(var(--primary) / .75);
}

.border-x-primary\/80 {
  border-left-color: hsl(var(--primary) / .8);
  border-right-color: hsl(var(--primary) / .8);
}

.border-x-primary\/90 {
  border-left-color: hsl(var(--primary) / .9);
  border-right-color: hsl(var(--primary) / .9);
}

.border-x-primary\/95 {
  border-left-color: hsl(var(--primary) / .95);
  border-right-color: hsl(var(--primary) / .95);
}

.border-x-purple-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(243 232 255 / var(--tw-border-opacity));
  border-right-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-x-purple-100\/0 {
  border-left-color: #f3e8ff00;
  border-right-color: #f3e8ff00;
}

.border-x-purple-100\/10 {
  border-left-color: #f3e8ff1a;
  border-right-color: #f3e8ff1a;
}

.border-x-purple-100\/100 {
  border-left-color: #f3e8ff;
  border-right-color: #f3e8ff;
}

.border-x-purple-100\/20 {
  border-left-color: #f3e8ff33;
  border-right-color: #f3e8ff33;
}

.border-x-purple-100\/25 {
  border-left-color: #f3e8ff40;
  border-right-color: #f3e8ff40;
}

.border-x-purple-100\/30 {
  border-left-color: #f3e8ff4d;
  border-right-color: #f3e8ff4d;
}

.border-x-purple-100\/40 {
  border-left-color: #f3e8ff66;
  border-right-color: #f3e8ff66;
}

.border-x-purple-100\/5 {
  border-left-color: #f3e8ff0d;
  border-right-color: #f3e8ff0d;
}

.border-x-purple-100\/50 {
  border-left-color: #f3e8ff80;
  border-right-color: #f3e8ff80;
}

.border-x-purple-100\/60 {
  border-left-color: #f3e8ff99;
  border-right-color: #f3e8ff99;
}

.border-x-purple-100\/70 {
  border-left-color: #f3e8ffb3;
  border-right-color: #f3e8ffb3;
}

.border-x-purple-100\/75 {
  border-left-color: #f3e8ffbf;
  border-right-color: #f3e8ffbf;
}

.border-x-purple-100\/80 {
  border-left-color: #f3e8ffcc;
  border-right-color: #f3e8ffcc;
}

.border-x-purple-100\/90 {
  border-left-color: #f3e8ffe6;
  border-right-color: #f3e8ffe6;
}

.border-x-purple-100\/95 {
  border-left-color: #f3e8fff2;
  border-right-color: #f3e8fff2;
}

.border-x-purple-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(233 213 255 / var(--tw-border-opacity));
  border-right-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-x-purple-200\/0 {
  border-left-color: #e9d5ff00;
  border-right-color: #e9d5ff00;
}

.border-x-purple-200\/10 {
  border-left-color: #e9d5ff1a;
  border-right-color: #e9d5ff1a;
}

.border-x-purple-200\/100 {
  border-left-color: #e9d5ff;
  border-right-color: #e9d5ff;
}

.border-x-purple-200\/20 {
  border-left-color: #e9d5ff33;
  border-right-color: #e9d5ff33;
}

.border-x-purple-200\/25 {
  border-left-color: #e9d5ff40;
  border-right-color: #e9d5ff40;
}

.border-x-purple-200\/30 {
  border-left-color: #e9d5ff4d;
  border-right-color: #e9d5ff4d;
}

.border-x-purple-200\/40 {
  border-left-color: #e9d5ff66;
  border-right-color: #e9d5ff66;
}

.border-x-purple-200\/5 {
  border-left-color: #e9d5ff0d;
  border-right-color: #e9d5ff0d;
}

.border-x-purple-200\/50 {
  border-left-color: #e9d5ff80;
  border-right-color: #e9d5ff80;
}

.border-x-purple-200\/60 {
  border-left-color: #e9d5ff99;
  border-right-color: #e9d5ff99;
}

.border-x-purple-200\/70 {
  border-left-color: #e9d5ffb3;
  border-right-color: #e9d5ffb3;
}

.border-x-purple-200\/75 {
  border-left-color: #e9d5ffbf;
  border-right-color: #e9d5ffbf;
}

.border-x-purple-200\/80 {
  border-left-color: #e9d5ffcc;
  border-right-color: #e9d5ffcc;
}

.border-x-purple-200\/90 {
  border-left-color: #e9d5ffe6;
  border-right-color: #e9d5ffe6;
}

.border-x-purple-200\/95 {
  border-left-color: #e9d5fff2;
  border-right-color: #e9d5fff2;
}

.border-x-purple-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(216 180 254 / var(--tw-border-opacity));
  border-right-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-x-purple-300\/0 {
  border-left-color: #d8b4fe00;
  border-right-color: #d8b4fe00;
}

.border-x-purple-300\/10 {
  border-left-color: #d8b4fe1a;
  border-right-color: #d8b4fe1a;
}

.border-x-purple-300\/100 {
  border-left-color: #d8b4fe;
  border-right-color: #d8b4fe;
}

.border-x-purple-300\/20 {
  border-left-color: #d8b4fe33;
  border-right-color: #d8b4fe33;
}

.border-x-purple-300\/25 {
  border-left-color: #d8b4fe40;
  border-right-color: #d8b4fe40;
}

.border-x-purple-300\/30 {
  border-left-color: #d8b4fe4d;
  border-right-color: #d8b4fe4d;
}

.border-x-purple-300\/40 {
  border-left-color: #d8b4fe66;
  border-right-color: #d8b4fe66;
}

.border-x-purple-300\/5 {
  border-left-color: #d8b4fe0d;
  border-right-color: #d8b4fe0d;
}

.border-x-purple-300\/50 {
  border-left-color: #d8b4fe80;
  border-right-color: #d8b4fe80;
}

.border-x-purple-300\/60 {
  border-left-color: #d8b4fe99;
  border-right-color: #d8b4fe99;
}

.border-x-purple-300\/70 {
  border-left-color: #d8b4feb3;
  border-right-color: #d8b4feb3;
}

.border-x-purple-300\/75 {
  border-left-color: #d8b4febf;
  border-right-color: #d8b4febf;
}

.border-x-purple-300\/80 {
  border-left-color: #d8b4fecc;
  border-right-color: #d8b4fecc;
}

.border-x-purple-300\/90 {
  border-left-color: #d8b4fee6;
  border-right-color: #d8b4fee6;
}

.border-x-purple-300\/95 {
  border-left-color: #d8b4fef2;
  border-right-color: #d8b4fef2;
}

.border-x-purple-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(192 132 252 / var(--tw-border-opacity));
  border-right-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-x-purple-400\/0 {
  border-left-color: #c084fc00;
  border-right-color: #c084fc00;
}

.border-x-purple-400\/10 {
  border-left-color: #c084fc1a;
  border-right-color: #c084fc1a;
}

.border-x-purple-400\/100 {
  border-left-color: #c084fc;
  border-right-color: #c084fc;
}

.border-x-purple-400\/20 {
  border-left-color: #c084fc33;
  border-right-color: #c084fc33;
}

.border-x-purple-400\/25 {
  border-left-color: #c084fc40;
  border-right-color: #c084fc40;
}

.border-x-purple-400\/30 {
  border-left-color: #c084fc4d;
  border-right-color: #c084fc4d;
}

.border-x-purple-400\/40 {
  border-left-color: #c084fc66;
  border-right-color: #c084fc66;
}

.border-x-purple-400\/5 {
  border-left-color: #c084fc0d;
  border-right-color: #c084fc0d;
}

.border-x-purple-400\/50 {
  border-left-color: #c084fc80;
  border-right-color: #c084fc80;
}

.border-x-purple-400\/60 {
  border-left-color: #c084fc99;
  border-right-color: #c084fc99;
}

.border-x-purple-400\/70 {
  border-left-color: #c084fcb3;
  border-right-color: #c084fcb3;
}

.border-x-purple-400\/75 {
  border-left-color: #c084fcbf;
  border-right-color: #c084fcbf;
}

.border-x-purple-400\/80 {
  border-left-color: #c084fccc;
  border-right-color: #c084fccc;
}

.border-x-purple-400\/90 {
  border-left-color: #c084fce6;
  border-right-color: #c084fce6;
}

.border-x-purple-400\/95 {
  border-left-color: #c084fcf2;
  border-right-color: #c084fcf2;
}

.border-x-purple-50 {
  --tw-border-opacity: 1;
  border-left-color: rgb(250 245 255 / var(--tw-border-opacity));
  border-right-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-x-purple-50\/0 {
  border-left-color: #faf5ff00;
  border-right-color: #faf5ff00;
}

.border-x-purple-50\/10 {
  border-left-color: #faf5ff1a;
  border-right-color: #faf5ff1a;
}

.border-x-purple-50\/100 {
  border-left-color: #faf5ff;
  border-right-color: #faf5ff;
}

.border-x-purple-50\/20 {
  border-left-color: #faf5ff33;
  border-right-color: #faf5ff33;
}

.border-x-purple-50\/25 {
  border-left-color: #faf5ff40;
  border-right-color: #faf5ff40;
}

.border-x-purple-50\/30 {
  border-left-color: #faf5ff4d;
  border-right-color: #faf5ff4d;
}

.border-x-purple-50\/40 {
  border-left-color: #faf5ff66;
  border-right-color: #faf5ff66;
}

.border-x-purple-50\/5 {
  border-left-color: #faf5ff0d;
  border-right-color: #faf5ff0d;
}

.border-x-purple-50\/50 {
  border-left-color: #faf5ff80;
  border-right-color: #faf5ff80;
}

.border-x-purple-50\/60 {
  border-left-color: #faf5ff99;
  border-right-color: #faf5ff99;
}

.border-x-purple-50\/70 {
  border-left-color: #faf5ffb3;
  border-right-color: #faf5ffb3;
}

.border-x-purple-50\/75 {
  border-left-color: #faf5ffbf;
  border-right-color: #faf5ffbf;
}

.border-x-purple-50\/80 {
  border-left-color: #faf5ffcc;
  border-right-color: #faf5ffcc;
}

.border-x-purple-50\/90 {
  border-left-color: #faf5ffe6;
  border-right-color: #faf5ffe6;
}

.border-x-purple-50\/95 {
  border-left-color: #faf5fff2;
  border-right-color: #faf5fff2;
}

.border-x-purple-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(168 85 247 / var(--tw-border-opacity));
  border-right-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-x-purple-500\/0 {
  border-left-color: #a855f700;
  border-right-color: #a855f700;
}

.border-x-purple-500\/10 {
  border-left-color: #a855f71a;
  border-right-color: #a855f71a;
}

.border-x-purple-500\/100 {
  border-left-color: #a855f7;
  border-right-color: #a855f7;
}

.border-x-purple-500\/20 {
  border-left-color: #a855f733;
  border-right-color: #a855f733;
}

.border-x-purple-500\/25 {
  border-left-color: #a855f740;
  border-right-color: #a855f740;
}

.border-x-purple-500\/30 {
  border-left-color: #a855f74d;
  border-right-color: #a855f74d;
}

.border-x-purple-500\/40 {
  border-left-color: #a855f766;
  border-right-color: #a855f766;
}

.border-x-purple-500\/5 {
  border-left-color: #a855f70d;
  border-right-color: #a855f70d;
}

.border-x-purple-500\/50 {
  border-left-color: #a855f780;
  border-right-color: #a855f780;
}

.border-x-purple-500\/60 {
  border-left-color: #a855f799;
  border-right-color: #a855f799;
}

.border-x-purple-500\/70 {
  border-left-color: #a855f7b3;
  border-right-color: #a855f7b3;
}

.border-x-purple-500\/75 {
  border-left-color: #a855f7bf;
  border-right-color: #a855f7bf;
}

.border-x-purple-500\/80 {
  border-left-color: #a855f7cc;
  border-right-color: #a855f7cc;
}

.border-x-purple-500\/90 {
  border-left-color: #a855f7e6;
  border-right-color: #a855f7e6;
}

.border-x-purple-500\/95 {
  border-left-color: #a855f7f2;
  border-right-color: #a855f7f2;
}

.border-x-purple-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(147 51 234 / var(--tw-border-opacity));
  border-right-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-x-purple-600\/0 {
  border-left-color: #9333ea00;
  border-right-color: #9333ea00;
}

.border-x-purple-600\/10 {
  border-left-color: #9333ea1a;
  border-right-color: #9333ea1a;
}

.border-x-purple-600\/100 {
  border-left-color: #9333ea;
  border-right-color: #9333ea;
}

.border-x-purple-600\/20 {
  border-left-color: #9333ea33;
  border-right-color: #9333ea33;
}

.border-x-purple-600\/25 {
  border-left-color: #9333ea40;
  border-right-color: #9333ea40;
}

.border-x-purple-600\/30 {
  border-left-color: #9333ea4d;
  border-right-color: #9333ea4d;
}

.border-x-purple-600\/40 {
  border-left-color: #9333ea66;
  border-right-color: #9333ea66;
}

.border-x-purple-600\/5 {
  border-left-color: #9333ea0d;
  border-right-color: #9333ea0d;
}

.border-x-purple-600\/50 {
  border-left-color: #9333ea80;
  border-right-color: #9333ea80;
}

.border-x-purple-600\/60 {
  border-left-color: #9333ea99;
  border-right-color: #9333ea99;
}

.border-x-purple-600\/70 {
  border-left-color: #9333eab3;
  border-right-color: #9333eab3;
}

.border-x-purple-600\/75 {
  border-left-color: #9333eabf;
  border-right-color: #9333eabf;
}

.border-x-purple-600\/80 {
  border-left-color: #9333eacc;
  border-right-color: #9333eacc;
}

.border-x-purple-600\/90 {
  border-left-color: #9333eae6;
  border-right-color: #9333eae6;
}

.border-x-purple-600\/95 {
  border-left-color: #9333eaf2;
  border-right-color: #9333eaf2;
}

.border-x-purple-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(126 34 206 / var(--tw-border-opacity));
  border-right-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-x-purple-700\/0 {
  border-left-color: #7e22ce00;
  border-right-color: #7e22ce00;
}

.border-x-purple-700\/10 {
  border-left-color: #7e22ce1a;
  border-right-color: #7e22ce1a;
}

.border-x-purple-700\/100 {
  border-left-color: #7e22ce;
  border-right-color: #7e22ce;
}

.border-x-purple-700\/20 {
  border-left-color: #7e22ce33;
  border-right-color: #7e22ce33;
}

.border-x-purple-700\/25 {
  border-left-color: #7e22ce40;
  border-right-color: #7e22ce40;
}

.border-x-purple-700\/30 {
  border-left-color: #7e22ce4d;
  border-right-color: #7e22ce4d;
}

.border-x-purple-700\/40 {
  border-left-color: #7e22ce66;
  border-right-color: #7e22ce66;
}

.border-x-purple-700\/5 {
  border-left-color: #7e22ce0d;
  border-right-color: #7e22ce0d;
}

.border-x-purple-700\/50 {
  border-left-color: #7e22ce80;
  border-right-color: #7e22ce80;
}

.border-x-purple-700\/60 {
  border-left-color: #7e22ce99;
  border-right-color: #7e22ce99;
}

.border-x-purple-700\/70 {
  border-left-color: #7e22ceb3;
  border-right-color: #7e22ceb3;
}

.border-x-purple-700\/75 {
  border-left-color: #7e22cebf;
  border-right-color: #7e22cebf;
}

.border-x-purple-700\/80 {
  border-left-color: #7e22cecc;
  border-right-color: #7e22cecc;
}

.border-x-purple-700\/90 {
  border-left-color: #7e22cee6;
  border-right-color: #7e22cee6;
}

.border-x-purple-700\/95 {
  border-left-color: #7e22cef2;
  border-right-color: #7e22cef2;
}

.border-x-purple-800 {
  --tw-border-opacity: 1;
  border-left-color: rgb(107 33 168 / var(--tw-border-opacity));
  border-right-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-x-purple-800\/0 {
  border-left-color: #6b21a800;
  border-right-color: #6b21a800;
}

.border-x-purple-800\/10 {
  border-left-color: #6b21a81a;
  border-right-color: #6b21a81a;
}

.border-x-purple-800\/100 {
  border-left-color: #6b21a8;
  border-right-color: #6b21a8;
}

.border-x-purple-800\/20 {
  border-left-color: #6b21a833;
  border-right-color: #6b21a833;
}

.border-x-purple-800\/25 {
  border-left-color: #6b21a840;
  border-right-color: #6b21a840;
}

.border-x-purple-800\/30 {
  border-left-color: #6b21a84d;
  border-right-color: #6b21a84d;
}

.border-x-purple-800\/40 {
  border-left-color: #6b21a866;
  border-right-color: #6b21a866;
}

.border-x-purple-800\/5 {
  border-left-color: #6b21a80d;
  border-right-color: #6b21a80d;
}

.border-x-purple-800\/50 {
  border-left-color: #6b21a880;
  border-right-color: #6b21a880;
}

.border-x-purple-800\/60 {
  border-left-color: #6b21a899;
  border-right-color: #6b21a899;
}

.border-x-purple-800\/70 {
  border-left-color: #6b21a8b3;
  border-right-color: #6b21a8b3;
}

.border-x-purple-800\/75 {
  border-left-color: #6b21a8bf;
  border-right-color: #6b21a8bf;
}

.border-x-purple-800\/80 {
  border-left-color: #6b21a8cc;
  border-right-color: #6b21a8cc;
}

.border-x-purple-800\/90 {
  border-left-color: #6b21a8e6;
  border-right-color: #6b21a8e6;
}

.border-x-purple-800\/95 {
  border-left-color: #6b21a8f2;
  border-right-color: #6b21a8f2;
}

.border-x-purple-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(88 28 135 / var(--tw-border-opacity));
  border-right-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-x-purple-900\/0 {
  border-left-color: #581c8700;
  border-right-color: #581c8700;
}

.border-x-purple-900\/10 {
  border-left-color: #581c871a;
  border-right-color: #581c871a;
}

.border-x-purple-900\/100 {
  border-left-color: #581c87;
  border-right-color: #581c87;
}

.border-x-purple-900\/20 {
  border-left-color: #581c8733;
  border-right-color: #581c8733;
}

.border-x-purple-900\/25 {
  border-left-color: #581c8740;
  border-right-color: #581c8740;
}

.border-x-purple-900\/30 {
  border-left-color: #581c874d;
  border-right-color: #581c874d;
}

.border-x-purple-900\/40 {
  border-left-color: #581c8766;
  border-right-color: #581c8766;
}

.border-x-purple-900\/5 {
  border-left-color: #581c870d;
  border-right-color: #581c870d;
}

.border-x-purple-900\/50 {
  border-left-color: #581c8780;
  border-right-color: #581c8780;
}

.border-x-purple-900\/60 {
  border-left-color: #581c8799;
  border-right-color: #581c8799;
}

.border-x-purple-900\/70 {
  border-left-color: #581c87b3;
  border-right-color: #581c87b3;
}

.border-x-purple-900\/75 {
  border-left-color: #581c87bf;
  border-right-color: #581c87bf;
}

.border-x-purple-900\/80 {
  border-left-color: #581c87cc;
  border-right-color: #581c87cc;
}

.border-x-purple-900\/90 {
  border-left-color: #581c87e6;
  border-right-color: #581c87e6;
}

.border-x-purple-900\/95 {
  border-left-color: #581c87f2;
  border-right-color: #581c87f2;
}

.border-x-purple-950 {
  --tw-border-opacity: 1;
  border-left-color: rgb(59 7 100 / var(--tw-border-opacity));
  border-right-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-x-purple-950\/0 {
  border-left-color: #3b076400;
  border-right-color: #3b076400;
}

.border-x-purple-950\/10 {
  border-left-color: #3b07641a;
  border-right-color: #3b07641a;
}

.border-x-purple-950\/100 {
  border-left-color: #3b0764;
  border-right-color: #3b0764;
}

.border-x-purple-950\/20 {
  border-left-color: #3b076433;
  border-right-color: #3b076433;
}

.border-x-purple-950\/25 {
  border-left-color: #3b076440;
  border-right-color: #3b076440;
}

.border-x-purple-950\/30 {
  border-left-color: #3b07644d;
  border-right-color: #3b07644d;
}

.border-x-purple-950\/40 {
  border-left-color: #3b076466;
  border-right-color: #3b076466;
}

.border-x-purple-950\/5 {
  border-left-color: #3b07640d;
  border-right-color: #3b07640d;
}

.border-x-purple-950\/50 {
  border-left-color: #3b076480;
  border-right-color: #3b076480;
}

.border-x-purple-950\/60 {
  border-left-color: #3b076499;
  border-right-color: #3b076499;
}

.border-x-purple-950\/70 {
  border-left-color: #3b0764b3;
  border-right-color: #3b0764b3;
}

.border-x-purple-950\/75 {
  border-left-color: #3b0764bf;
  border-right-color: #3b0764bf;
}

.border-x-purple-950\/80 {
  border-left-color: #3b0764cc;
  border-right-color: #3b0764cc;
}

.border-x-purple-950\/90 {
  border-left-color: #3b0764e6;
  border-right-color: #3b0764e6;
}

.border-x-purple-950\/95 {
  border-left-color: #3b0764f2;
  border-right-color: #3b0764f2;
}

.border-x-transparent, .border-x-transparent\/0 {
  border-left-color: #0000;
  border-right-color: #0000;
}

.border-x-transparent\/10 {
  border-left-color: #0000001a;
  border-right-color: #0000001a;
}

.border-x-transparent\/100 {
  border-left-color: #000;
  border-right-color: #000;
}

.border-x-transparent\/20 {
  border-left-color: #0003;
  border-right-color: #0003;
}

.border-x-transparent\/25 {
  border-left-color: #00000040;
  border-right-color: #00000040;
}

.border-x-transparent\/30 {
  border-left-color: #0000004d;
  border-right-color: #0000004d;
}

.border-x-transparent\/40 {
  border-left-color: #0006;
  border-right-color: #0006;
}

.border-x-transparent\/5 {
  border-left-color: #0000000d;
  border-right-color: #0000000d;
}

.border-x-transparent\/50 {
  border-left-color: #00000080;
  border-right-color: #00000080;
}

.border-x-transparent\/60 {
  border-left-color: #0009;
  border-right-color: #0009;
}

.border-x-transparent\/70 {
  border-left-color: #000000b3;
  border-right-color: #000000b3;
}

.border-x-transparent\/75 {
  border-left-color: #000000bf;
  border-right-color: #000000bf;
}

.border-x-transparent\/80 {
  border-left-color: #000c;
  border-right-color: #000c;
}

.border-x-transparent\/90 {
  border-left-color: #000000e6;
  border-right-color: #000000e6;
}

.border-x-transparent\/95 {
  border-left-color: #000000f2;
  border-right-color: #000000f2;
}

.border-y-amber-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(254 243 199 / var(--tw-border-opacity));
  border-bottom-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-y-amber-100\/0 {
  border-top-color: #fef3c700;
  border-bottom-color: #fef3c700;
}

.border-y-amber-100\/10 {
  border-top-color: #fef3c71a;
  border-bottom-color: #fef3c71a;
}

.border-y-amber-100\/100 {
  border-top-color: #fef3c7;
  border-bottom-color: #fef3c7;
}

.border-y-amber-100\/20 {
  border-top-color: #fef3c733;
  border-bottom-color: #fef3c733;
}

.border-y-amber-100\/25 {
  border-top-color: #fef3c740;
  border-bottom-color: #fef3c740;
}

.border-y-amber-100\/30 {
  border-top-color: #fef3c74d;
  border-bottom-color: #fef3c74d;
}

.border-y-amber-100\/40 {
  border-top-color: #fef3c766;
  border-bottom-color: #fef3c766;
}

.border-y-amber-100\/5 {
  border-top-color: #fef3c70d;
  border-bottom-color: #fef3c70d;
}

.border-y-amber-100\/50 {
  border-top-color: #fef3c780;
  border-bottom-color: #fef3c780;
}

.border-y-amber-100\/60 {
  border-top-color: #fef3c799;
  border-bottom-color: #fef3c799;
}

.border-y-amber-100\/70 {
  border-top-color: #fef3c7b3;
  border-bottom-color: #fef3c7b3;
}

.border-y-amber-100\/75 {
  border-top-color: #fef3c7bf;
  border-bottom-color: #fef3c7bf;
}

.border-y-amber-100\/80 {
  border-top-color: #fef3c7cc;
  border-bottom-color: #fef3c7cc;
}

.border-y-amber-100\/90 {
  border-top-color: #fef3c7e6;
  border-bottom-color: #fef3c7e6;
}

.border-y-amber-100\/95 {
  border-top-color: #fef3c7f2;
  border-bottom-color: #fef3c7f2;
}

.border-y-amber-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(253 230 138 / var(--tw-border-opacity));
  border-bottom-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-y-amber-200\/0 {
  border-top-color: #fde68a00;
  border-bottom-color: #fde68a00;
}

.border-y-amber-200\/10 {
  border-top-color: #fde68a1a;
  border-bottom-color: #fde68a1a;
}

.border-y-amber-200\/100 {
  border-top-color: #fde68a;
  border-bottom-color: #fde68a;
}

.border-y-amber-200\/20 {
  border-top-color: #fde68a33;
  border-bottom-color: #fde68a33;
}

.border-y-amber-200\/25 {
  border-top-color: #fde68a40;
  border-bottom-color: #fde68a40;
}

.border-y-amber-200\/30 {
  border-top-color: #fde68a4d;
  border-bottom-color: #fde68a4d;
}

.border-y-amber-200\/40 {
  border-top-color: #fde68a66;
  border-bottom-color: #fde68a66;
}

.border-y-amber-200\/5 {
  border-top-color: #fde68a0d;
  border-bottom-color: #fde68a0d;
}

.border-y-amber-200\/50 {
  border-top-color: #fde68a80;
  border-bottom-color: #fde68a80;
}

.border-y-amber-200\/60 {
  border-top-color: #fde68a99;
  border-bottom-color: #fde68a99;
}

.border-y-amber-200\/70 {
  border-top-color: #fde68ab3;
  border-bottom-color: #fde68ab3;
}

.border-y-amber-200\/75 {
  border-top-color: #fde68abf;
  border-bottom-color: #fde68abf;
}

.border-y-amber-200\/80 {
  border-top-color: #fde68acc;
  border-bottom-color: #fde68acc;
}

.border-y-amber-200\/90 {
  border-top-color: #fde68ae6;
  border-bottom-color: #fde68ae6;
}

.border-y-amber-200\/95 {
  border-top-color: #fde68af2;
  border-bottom-color: #fde68af2;
}

.border-y-amber-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(252 211 77 / var(--tw-border-opacity));
  border-bottom-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-y-amber-300\/0 {
  border-top-color: #fcd34d00;
  border-bottom-color: #fcd34d00;
}

.border-y-amber-300\/10 {
  border-top-color: #fcd34d1a;
  border-bottom-color: #fcd34d1a;
}

.border-y-amber-300\/100 {
  border-top-color: #fcd34d;
  border-bottom-color: #fcd34d;
}

.border-y-amber-300\/20 {
  border-top-color: #fcd34d33;
  border-bottom-color: #fcd34d33;
}

.border-y-amber-300\/25 {
  border-top-color: #fcd34d40;
  border-bottom-color: #fcd34d40;
}

.border-y-amber-300\/30 {
  border-top-color: #fcd34d4d;
  border-bottom-color: #fcd34d4d;
}

.border-y-amber-300\/40 {
  border-top-color: #fcd34d66;
  border-bottom-color: #fcd34d66;
}

.border-y-amber-300\/5 {
  border-top-color: #fcd34d0d;
  border-bottom-color: #fcd34d0d;
}

.border-y-amber-300\/50 {
  border-top-color: #fcd34d80;
  border-bottom-color: #fcd34d80;
}

.border-y-amber-300\/60 {
  border-top-color: #fcd34d99;
  border-bottom-color: #fcd34d99;
}

.border-y-amber-300\/70 {
  border-top-color: #fcd34db3;
  border-bottom-color: #fcd34db3;
}

.border-y-amber-300\/75 {
  border-top-color: #fcd34dbf;
  border-bottom-color: #fcd34dbf;
}

.border-y-amber-300\/80 {
  border-top-color: #fcd34dcc;
  border-bottom-color: #fcd34dcc;
}

.border-y-amber-300\/90 {
  border-top-color: #fcd34de6;
  border-bottom-color: #fcd34de6;
}

.border-y-amber-300\/95 {
  border-top-color: #fcd34df2;
  border-bottom-color: #fcd34df2;
}

.border-y-amber-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(251 191 36 / var(--tw-border-opacity));
  border-bottom-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-y-amber-400\/0 {
  border-top-color: #fbbf2400;
  border-bottom-color: #fbbf2400;
}

.border-y-amber-400\/10 {
  border-top-color: #fbbf241a;
  border-bottom-color: #fbbf241a;
}

.border-y-amber-400\/100 {
  border-top-color: #fbbf24;
  border-bottom-color: #fbbf24;
}

.border-y-amber-400\/20 {
  border-top-color: #fbbf2433;
  border-bottom-color: #fbbf2433;
}

.border-y-amber-400\/25 {
  border-top-color: #fbbf2440;
  border-bottom-color: #fbbf2440;
}

.border-y-amber-400\/30 {
  border-top-color: #fbbf244d;
  border-bottom-color: #fbbf244d;
}

.border-y-amber-400\/40 {
  border-top-color: #fbbf2466;
  border-bottom-color: #fbbf2466;
}

.border-y-amber-400\/5 {
  border-top-color: #fbbf240d;
  border-bottom-color: #fbbf240d;
}

.border-y-amber-400\/50 {
  border-top-color: #fbbf2480;
  border-bottom-color: #fbbf2480;
}

.border-y-amber-400\/60 {
  border-top-color: #fbbf2499;
  border-bottom-color: #fbbf2499;
}

.border-y-amber-400\/70 {
  border-top-color: #fbbf24b3;
  border-bottom-color: #fbbf24b3;
}

.border-y-amber-400\/75 {
  border-top-color: #fbbf24bf;
  border-bottom-color: #fbbf24bf;
}

.border-y-amber-400\/80 {
  border-top-color: #fbbf24cc;
  border-bottom-color: #fbbf24cc;
}

.border-y-amber-400\/90 {
  border-top-color: #fbbf24e6;
  border-bottom-color: #fbbf24e6;
}

.border-y-amber-400\/95 {
  border-top-color: #fbbf24f2;
  border-bottom-color: #fbbf24f2;
}

.border-y-amber-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(255 251 235 / var(--tw-border-opacity));
  border-bottom-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-y-amber-50\/0 {
  border-top-color: #fffbeb00;
  border-bottom-color: #fffbeb00;
}

.border-y-amber-50\/10 {
  border-top-color: #fffbeb1a;
  border-bottom-color: #fffbeb1a;
}

.border-y-amber-50\/100 {
  border-top-color: #fffbeb;
  border-bottom-color: #fffbeb;
}

.border-y-amber-50\/20 {
  border-top-color: #fffbeb33;
  border-bottom-color: #fffbeb33;
}

.border-y-amber-50\/25 {
  border-top-color: #fffbeb40;
  border-bottom-color: #fffbeb40;
}

.border-y-amber-50\/30 {
  border-top-color: #fffbeb4d;
  border-bottom-color: #fffbeb4d;
}

.border-y-amber-50\/40 {
  border-top-color: #fffbeb66;
  border-bottom-color: #fffbeb66;
}

.border-y-amber-50\/5 {
  border-top-color: #fffbeb0d;
  border-bottom-color: #fffbeb0d;
}

.border-y-amber-50\/50 {
  border-top-color: #fffbeb80;
  border-bottom-color: #fffbeb80;
}

.border-y-amber-50\/60 {
  border-top-color: #fffbeb99;
  border-bottom-color: #fffbeb99;
}

.border-y-amber-50\/70 {
  border-top-color: #fffbebb3;
  border-bottom-color: #fffbebb3;
}

.border-y-amber-50\/75 {
  border-top-color: #fffbebbf;
  border-bottom-color: #fffbebbf;
}

.border-y-amber-50\/80 {
  border-top-color: #fffbebcc;
  border-bottom-color: #fffbebcc;
}

.border-y-amber-50\/90 {
  border-top-color: #fffbebe6;
  border-bottom-color: #fffbebe6;
}

.border-y-amber-50\/95 {
  border-top-color: #fffbebf2;
  border-bottom-color: #fffbebf2;
}

.border-y-amber-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(245 158 11 / var(--tw-border-opacity));
  border-bottom-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-y-amber-500\/0 {
  border-top-color: #f59e0b00;
  border-bottom-color: #f59e0b00;
}

.border-y-amber-500\/10 {
  border-top-color: #f59e0b1a;
  border-bottom-color: #f59e0b1a;
}

.border-y-amber-500\/100 {
  border-top-color: #f59e0b;
  border-bottom-color: #f59e0b;
}

.border-y-amber-500\/20 {
  border-top-color: #f59e0b33;
  border-bottom-color: #f59e0b33;
}

.border-y-amber-500\/25 {
  border-top-color: #f59e0b40;
  border-bottom-color: #f59e0b40;
}

.border-y-amber-500\/30 {
  border-top-color: #f59e0b4d;
  border-bottom-color: #f59e0b4d;
}

.border-y-amber-500\/40 {
  border-top-color: #f59e0b66;
  border-bottom-color: #f59e0b66;
}

.border-y-amber-500\/5 {
  border-top-color: #f59e0b0d;
  border-bottom-color: #f59e0b0d;
}

.border-y-amber-500\/50 {
  border-top-color: #f59e0b80;
  border-bottom-color: #f59e0b80;
}

.border-y-amber-500\/60 {
  border-top-color: #f59e0b99;
  border-bottom-color: #f59e0b99;
}

.border-y-amber-500\/70 {
  border-top-color: #f59e0bb3;
  border-bottom-color: #f59e0bb3;
}

.border-y-amber-500\/75 {
  border-top-color: #f59e0bbf;
  border-bottom-color: #f59e0bbf;
}

.border-y-amber-500\/80 {
  border-top-color: #f59e0bcc;
  border-bottom-color: #f59e0bcc;
}

.border-y-amber-500\/90 {
  border-top-color: #f59e0be6;
  border-bottom-color: #f59e0be6;
}

.border-y-amber-500\/95 {
  border-top-color: #f59e0bf2;
  border-bottom-color: #f59e0bf2;
}

.border-y-amber-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(217 119 6 / var(--tw-border-opacity));
  border-bottom-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-y-amber-600\/0 {
  border-top-color: #d9770600;
  border-bottom-color: #d9770600;
}

.border-y-amber-600\/10 {
  border-top-color: #d977061a;
  border-bottom-color: #d977061a;
}

.border-y-amber-600\/100 {
  border-top-color: #d97706;
  border-bottom-color: #d97706;
}

.border-y-amber-600\/20 {
  border-top-color: #d9770633;
  border-bottom-color: #d9770633;
}

.border-y-amber-600\/25 {
  border-top-color: #d9770640;
  border-bottom-color: #d9770640;
}

.border-y-amber-600\/30 {
  border-top-color: #d977064d;
  border-bottom-color: #d977064d;
}

.border-y-amber-600\/40 {
  border-top-color: #d9770666;
  border-bottom-color: #d9770666;
}

.border-y-amber-600\/5 {
  border-top-color: #d977060d;
  border-bottom-color: #d977060d;
}

.border-y-amber-600\/50 {
  border-top-color: #d9770680;
  border-bottom-color: #d9770680;
}

.border-y-amber-600\/60 {
  border-top-color: #d9770699;
  border-bottom-color: #d9770699;
}

.border-y-amber-600\/70 {
  border-top-color: #d97706b3;
  border-bottom-color: #d97706b3;
}

.border-y-amber-600\/75 {
  border-top-color: #d97706bf;
  border-bottom-color: #d97706bf;
}

.border-y-amber-600\/80 {
  border-top-color: #d97706cc;
  border-bottom-color: #d97706cc;
}

.border-y-amber-600\/90 {
  border-top-color: #d97706e6;
  border-bottom-color: #d97706e6;
}

.border-y-amber-600\/95 {
  border-top-color: #d97706f2;
  border-bottom-color: #d97706f2;
}

.border-y-amber-700 {
  --tw-border-opacity: 1;
  border-top-color: rgb(180 83 9 / var(--tw-border-opacity));
  border-bottom-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-y-amber-700\/0 {
  border-top-color: #b4530900;
  border-bottom-color: #b4530900;
}

.border-y-amber-700\/10 {
  border-top-color: #b453091a;
  border-bottom-color: #b453091a;
}

.border-y-amber-700\/100 {
  border-top-color: #b45309;
  border-bottom-color: #b45309;
}

.border-y-amber-700\/20 {
  border-top-color: #b4530933;
  border-bottom-color: #b4530933;
}

.border-y-amber-700\/25 {
  border-top-color: #b4530940;
  border-bottom-color: #b4530940;
}

.border-y-amber-700\/30 {
  border-top-color: #b453094d;
  border-bottom-color: #b453094d;
}

.border-y-amber-700\/40 {
  border-top-color: #b4530966;
  border-bottom-color: #b4530966;
}

.border-y-amber-700\/5 {
  border-top-color: #b453090d;
  border-bottom-color: #b453090d;
}

.border-y-amber-700\/50 {
  border-top-color: #b4530980;
  border-bottom-color: #b4530980;
}

.border-y-amber-700\/60 {
  border-top-color: #b4530999;
  border-bottom-color: #b4530999;
}

.border-y-amber-700\/70 {
  border-top-color: #b45309b3;
  border-bottom-color: #b45309b3;
}

.border-y-amber-700\/75 {
  border-top-color: #b45309bf;
  border-bottom-color: #b45309bf;
}

.border-y-amber-700\/80 {
  border-top-color: #b45309cc;
  border-bottom-color: #b45309cc;
}

.border-y-amber-700\/90 {
  border-top-color: #b45309e6;
  border-bottom-color: #b45309e6;
}

.border-y-amber-700\/95 {
  border-top-color: #b45309f2;
  border-bottom-color: #b45309f2;
}

.border-y-amber-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(146 64 14 / var(--tw-border-opacity));
  border-bottom-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-y-amber-800\/0 {
  border-top-color: #92400e00;
  border-bottom-color: #92400e00;
}

.border-y-amber-800\/10 {
  border-top-color: #92400e1a;
  border-bottom-color: #92400e1a;
}

.border-y-amber-800\/100 {
  border-top-color: #92400e;
  border-bottom-color: #92400e;
}

.border-y-amber-800\/20 {
  border-top-color: #92400e33;
  border-bottom-color: #92400e33;
}

.border-y-amber-800\/25 {
  border-top-color: #92400e40;
  border-bottom-color: #92400e40;
}

.border-y-amber-800\/30 {
  border-top-color: #92400e4d;
  border-bottom-color: #92400e4d;
}

.border-y-amber-800\/40 {
  border-top-color: #92400e66;
  border-bottom-color: #92400e66;
}

.border-y-amber-800\/5 {
  border-top-color: #92400e0d;
  border-bottom-color: #92400e0d;
}

.border-y-amber-800\/50 {
  border-top-color: #92400e80;
  border-bottom-color: #92400e80;
}

.border-y-amber-800\/60 {
  border-top-color: #92400e99;
  border-bottom-color: #92400e99;
}

.border-y-amber-800\/70 {
  border-top-color: #92400eb3;
  border-bottom-color: #92400eb3;
}

.border-y-amber-800\/75 {
  border-top-color: #92400ebf;
  border-bottom-color: #92400ebf;
}

.border-y-amber-800\/80 {
  border-top-color: #92400ecc;
  border-bottom-color: #92400ecc;
}

.border-y-amber-800\/90 {
  border-top-color: #92400ee6;
  border-bottom-color: #92400ee6;
}

.border-y-amber-800\/95 {
  border-top-color: #92400ef2;
  border-bottom-color: #92400ef2;
}

.border-y-amber-900 {
  --tw-border-opacity: 1;
  border-top-color: rgb(120 53 15 / var(--tw-border-opacity));
  border-bottom-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-y-amber-900\/0 {
  border-top-color: #78350f00;
  border-bottom-color: #78350f00;
}

.border-y-amber-900\/10 {
  border-top-color: #78350f1a;
  border-bottom-color: #78350f1a;
}

.border-y-amber-900\/100 {
  border-top-color: #78350f;
  border-bottom-color: #78350f;
}

.border-y-amber-900\/20 {
  border-top-color: #78350f33;
  border-bottom-color: #78350f33;
}

.border-y-amber-900\/25 {
  border-top-color: #78350f40;
  border-bottom-color: #78350f40;
}

.border-y-amber-900\/30 {
  border-top-color: #78350f4d;
  border-bottom-color: #78350f4d;
}

.border-y-amber-900\/40 {
  border-top-color: #78350f66;
  border-bottom-color: #78350f66;
}

.border-y-amber-900\/5 {
  border-top-color: #78350f0d;
  border-bottom-color: #78350f0d;
}

.border-y-amber-900\/50 {
  border-top-color: #78350f80;
  border-bottom-color: #78350f80;
}

.border-y-amber-900\/60 {
  border-top-color: #78350f99;
  border-bottom-color: #78350f99;
}

.border-y-amber-900\/70 {
  border-top-color: #78350fb3;
  border-bottom-color: #78350fb3;
}

.border-y-amber-900\/75 {
  border-top-color: #78350fbf;
  border-bottom-color: #78350fbf;
}

.border-y-amber-900\/80 {
  border-top-color: #78350fcc;
  border-bottom-color: #78350fcc;
}

.border-y-amber-900\/90 {
  border-top-color: #78350fe6;
  border-bottom-color: #78350fe6;
}

.border-y-amber-900\/95 {
  border-top-color: #78350ff2;
  border-bottom-color: #78350ff2;
}

.border-y-amber-950 {
  --tw-border-opacity: 1;
  border-top-color: rgb(69 26 3 / var(--tw-border-opacity));
  border-bottom-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-y-amber-950\/0 {
  border-top-color: #451a0300;
  border-bottom-color: #451a0300;
}

.border-y-amber-950\/10 {
  border-top-color: #451a031a;
  border-bottom-color: #451a031a;
}

.border-y-amber-950\/100 {
  border-top-color: #451a03;
  border-bottom-color: #451a03;
}

.border-y-amber-950\/20 {
  border-top-color: #451a0333;
  border-bottom-color: #451a0333;
}

.border-y-amber-950\/25 {
  border-top-color: #451a0340;
  border-bottom-color: #451a0340;
}

.border-y-amber-950\/30 {
  border-top-color: #451a034d;
  border-bottom-color: #451a034d;
}

.border-y-amber-950\/40 {
  border-top-color: #451a0366;
  border-bottom-color: #451a0366;
}

.border-y-amber-950\/5 {
  border-top-color: #451a030d;
  border-bottom-color: #451a030d;
}

.border-y-amber-950\/50 {
  border-top-color: #451a0380;
  border-bottom-color: #451a0380;
}

.border-y-amber-950\/60 {
  border-top-color: #451a0399;
  border-bottom-color: #451a0399;
}

.border-y-amber-950\/70 {
  border-top-color: #451a03b3;
  border-bottom-color: #451a03b3;
}

.border-y-amber-950\/75 {
  border-top-color: #451a03bf;
  border-bottom-color: #451a03bf;
}

.border-y-amber-950\/80 {
  border-top-color: #451a03cc;
  border-bottom-color: #451a03cc;
}

.border-y-amber-950\/90 {
  border-top-color: #451a03e6;
  border-bottom-color: #451a03e6;
}

.border-y-amber-950\/95 {
  border-top-color: #451a03f2;
  border-bottom-color: #451a03f2;
}

.border-y-emerald-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(209 250 229 / var(--tw-border-opacity));
  border-bottom-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-y-emerald-100\/0 {
  border-top-color: #d1fae500;
  border-bottom-color: #d1fae500;
}

.border-y-emerald-100\/10 {
  border-top-color: #d1fae51a;
  border-bottom-color: #d1fae51a;
}

.border-y-emerald-100\/100 {
  border-top-color: #d1fae5;
  border-bottom-color: #d1fae5;
}

.border-y-emerald-100\/20 {
  border-top-color: #d1fae533;
  border-bottom-color: #d1fae533;
}

.border-y-emerald-100\/25 {
  border-top-color: #d1fae540;
  border-bottom-color: #d1fae540;
}

.border-y-emerald-100\/30 {
  border-top-color: #d1fae54d;
  border-bottom-color: #d1fae54d;
}

.border-y-emerald-100\/40 {
  border-top-color: #d1fae566;
  border-bottom-color: #d1fae566;
}

.border-y-emerald-100\/5 {
  border-top-color: #d1fae50d;
  border-bottom-color: #d1fae50d;
}

.border-y-emerald-100\/50 {
  border-top-color: #d1fae580;
  border-bottom-color: #d1fae580;
}

.border-y-emerald-100\/60 {
  border-top-color: #d1fae599;
  border-bottom-color: #d1fae599;
}

.border-y-emerald-100\/70 {
  border-top-color: #d1fae5b3;
  border-bottom-color: #d1fae5b3;
}

.border-y-emerald-100\/75 {
  border-top-color: #d1fae5bf;
  border-bottom-color: #d1fae5bf;
}

.border-y-emerald-100\/80 {
  border-top-color: #d1fae5cc;
  border-bottom-color: #d1fae5cc;
}

.border-y-emerald-100\/90 {
  border-top-color: #d1fae5e6;
  border-bottom-color: #d1fae5e6;
}

.border-y-emerald-100\/95 {
  border-top-color: #d1fae5f2;
  border-bottom-color: #d1fae5f2;
}

.border-y-emerald-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(167 243 208 / var(--tw-border-opacity));
  border-bottom-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-y-emerald-200\/0 {
  border-top-color: #a7f3d000;
  border-bottom-color: #a7f3d000;
}

.border-y-emerald-200\/10 {
  border-top-color: #a7f3d01a;
  border-bottom-color: #a7f3d01a;
}

.border-y-emerald-200\/100 {
  border-top-color: #a7f3d0;
  border-bottom-color: #a7f3d0;
}

.border-y-emerald-200\/20 {
  border-top-color: #a7f3d033;
  border-bottom-color: #a7f3d033;
}

.border-y-emerald-200\/25 {
  border-top-color: #a7f3d040;
  border-bottom-color: #a7f3d040;
}

.border-y-emerald-200\/30 {
  border-top-color: #a7f3d04d;
  border-bottom-color: #a7f3d04d;
}

.border-y-emerald-200\/40 {
  border-top-color: #a7f3d066;
  border-bottom-color: #a7f3d066;
}

.border-y-emerald-200\/5 {
  border-top-color: #a7f3d00d;
  border-bottom-color: #a7f3d00d;
}

.border-y-emerald-200\/50 {
  border-top-color: #a7f3d080;
  border-bottom-color: #a7f3d080;
}

.border-y-emerald-200\/60 {
  border-top-color: #a7f3d099;
  border-bottom-color: #a7f3d099;
}

.border-y-emerald-200\/70 {
  border-top-color: #a7f3d0b3;
  border-bottom-color: #a7f3d0b3;
}

.border-y-emerald-200\/75 {
  border-top-color: #a7f3d0bf;
  border-bottom-color: #a7f3d0bf;
}

.border-y-emerald-200\/80 {
  border-top-color: #a7f3d0cc;
  border-bottom-color: #a7f3d0cc;
}

.border-y-emerald-200\/90 {
  border-top-color: #a7f3d0e6;
  border-bottom-color: #a7f3d0e6;
}

.border-y-emerald-200\/95 {
  border-top-color: #a7f3d0f2;
  border-bottom-color: #a7f3d0f2;
}

.border-y-emerald-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(110 231 183 / var(--tw-border-opacity));
  border-bottom-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-y-emerald-300\/0 {
  border-top-color: #6ee7b700;
  border-bottom-color: #6ee7b700;
}

.border-y-emerald-300\/10 {
  border-top-color: #6ee7b71a;
  border-bottom-color: #6ee7b71a;
}

.border-y-emerald-300\/100 {
  border-top-color: #6ee7b7;
  border-bottom-color: #6ee7b7;
}

.border-y-emerald-300\/20 {
  border-top-color: #6ee7b733;
  border-bottom-color: #6ee7b733;
}

.border-y-emerald-300\/25 {
  border-top-color: #6ee7b740;
  border-bottom-color: #6ee7b740;
}

.border-y-emerald-300\/30 {
  border-top-color: #6ee7b74d;
  border-bottom-color: #6ee7b74d;
}

.border-y-emerald-300\/40 {
  border-top-color: #6ee7b766;
  border-bottom-color: #6ee7b766;
}

.border-y-emerald-300\/5 {
  border-top-color: #6ee7b70d;
  border-bottom-color: #6ee7b70d;
}

.border-y-emerald-300\/50 {
  border-top-color: #6ee7b780;
  border-bottom-color: #6ee7b780;
}

.border-y-emerald-300\/60 {
  border-top-color: #6ee7b799;
  border-bottom-color: #6ee7b799;
}

.border-y-emerald-300\/70 {
  border-top-color: #6ee7b7b3;
  border-bottom-color: #6ee7b7b3;
}

.border-y-emerald-300\/75 {
  border-top-color: #6ee7b7bf;
  border-bottom-color: #6ee7b7bf;
}

.border-y-emerald-300\/80 {
  border-top-color: #6ee7b7cc;
  border-bottom-color: #6ee7b7cc;
}

.border-y-emerald-300\/90 {
  border-top-color: #6ee7b7e6;
  border-bottom-color: #6ee7b7e6;
}

.border-y-emerald-300\/95 {
  border-top-color: #6ee7b7f2;
  border-bottom-color: #6ee7b7f2;
}

.border-y-emerald-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(52 211 153 / var(--tw-border-opacity));
  border-bottom-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-y-emerald-400\/0 {
  border-top-color: #34d39900;
  border-bottom-color: #34d39900;
}

.border-y-emerald-400\/10 {
  border-top-color: #34d3991a;
  border-bottom-color: #34d3991a;
}

.border-y-emerald-400\/100 {
  border-top-color: #34d399;
  border-bottom-color: #34d399;
}

.border-y-emerald-400\/20 {
  border-top-color: #34d39933;
  border-bottom-color: #34d39933;
}

.border-y-emerald-400\/25 {
  border-top-color: #34d39940;
  border-bottom-color: #34d39940;
}

.border-y-emerald-400\/30 {
  border-top-color: #34d3994d;
  border-bottom-color: #34d3994d;
}

.border-y-emerald-400\/40 {
  border-top-color: #34d39966;
  border-bottom-color: #34d39966;
}

.border-y-emerald-400\/5 {
  border-top-color: #34d3990d;
  border-bottom-color: #34d3990d;
}

.border-y-emerald-400\/50 {
  border-top-color: #34d39980;
  border-bottom-color: #34d39980;
}

.border-y-emerald-400\/60 {
  border-top-color: #34d39999;
  border-bottom-color: #34d39999;
}

.border-y-emerald-400\/70 {
  border-top-color: #34d399b3;
  border-bottom-color: #34d399b3;
}

.border-y-emerald-400\/75 {
  border-top-color: #34d399bf;
  border-bottom-color: #34d399bf;
}

.border-y-emerald-400\/80 {
  border-top-color: #34d399cc;
  border-bottom-color: #34d399cc;
}

.border-y-emerald-400\/90 {
  border-top-color: #34d399e6;
  border-bottom-color: #34d399e6;
}

.border-y-emerald-400\/95 {
  border-top-color: #34d399f2;
  border-bottom-color: #34d399f2;
}

.border-y-emerald-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(236 253 245 / var(--tw-border-opacity));
  border-bottom-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-y-emerald-50\/0 {
  border-top-color: #ecfdf500;
  border-bottom-color: #ecfdf500;
}

.border-y-emerald-50\/10 {
  border-top-color: #ecfdf51a;
  border-bottom-color: #ecfdf51a;
}

.border-y-emerald-50\/100 {
  border-top-color: #ecfdf5;
  border-bottom-color: #ecfdf5;
}

.border-y-emerald-50\/20 {
  border-top-color: #ecfdf533;
  border-bottom-color: #ecfdf533;
}

.border-y-emerald-50\/25 {
  border-top-color: #ecfdf540;
  border-bottom-color: #ecfdf540;
}

.border-y-emerald-50\/30 {
  border-top-color: #ecfdf54d;
  border-bottom-color: #ecfdf54d;
}

.border-y-emerald-50\/40 {
  border-top-color: #ecfdf566;
  border-bottom-color: #ecfdf566;
}

.border-y-emerald-50\/5 {
  border-top-color: #ecfdf50d;
  border-bottom-color: #ecfdf50d;
}

.border-y-emerald-50\/50 {
  border-top-color: #ecfdf580;
  border-bottom-color: #ecfdf580;
}

.border-y-emerald-50\/60 {
  border-top-color: #ecfdf599;
  border-bottom-color: #ecfdf599;
}

.border-y-emerald-50\/70 {
  border-top-color: #ecfdf5b3;
  border-bottom-color: #ecfdf5b3;
}

.border-y-emerald-50\/75 {
  border-top-color: #ecfdf5bf;
  border-bottom-color: #ecfdf5bf;
}

.border-y-emerald-50\/80 {
  border-top-color: #ecfdf5cc;
  border-bottom-color: #ecfdf5cc;
}

.border-y-emerald-50\/90 {
  border-top-color: #ecfdf5e6;
  border-bottom-color: #ecfdf5e6;
}

.border-y-emerald-50\/95 {
  border-top-color: #ecfdf5f2;
  border-bottom-color: #ecfdf5f2;
}

.border-y-emerald-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(16 185 129 / var(--tw-border-opacity));
  border-bottom-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-y-emerald-500\/0 {
  border-top-color: #10b98100;
  border-bottom-color: #10b98100;
}

.border-y-emerald-500\/10 {
  border-top-color: #10b9811a;
  border-bottom-color: #10b9811a;
}

.border-y-emerald-500\/100 {
  border-top-color: #10b981;
  border-bottom-color: #10b981;
}

.border-y-emerald-500\/20 {
  border-top-color: #10b98133;
  border-bottom-color: #10b98133;
}

.border-y-emerald-500\/25 {
  border-top-color: #10b98140;
  border-bottom-color: #10b98140;
}

.border-y-emerald-500\/30 {
  border-top-color: #10b9814d;
  border-bottom-color: #10b9814d;
}

.border-y-emerald-500\/40 {
  border-top-color: #10b98166;
  border-bottom-color: #10b98166;
}

.border-y-emerald-500\/5 {
  border-top-color: #10b9810d;
  border-bottom-color: #10b9810d;
}

.border-y-emerald-500\/50 {
  border-top-color: #10b98180;
  border-bottom-color: #10b98180;
}

.border-y-emerald-500\/60 {
  border-top-color: #10b98199;
  border-bottom-color: #10b98199;
}

.border-y-emerald-500\/70 {
  border-top-color: #10b981b3;
  border-bottom-color: #10b981b3;
}

.border-y-emerald-500\/75 {
  border-top-color: #10b981bf;
  border-bottom-color: #10b981bf;
}

.border-y-emerald-500\/80 {
  border-top-color: #10b981cc;
  border-bottom-color: #10b981cc;
}

.border-y-emerald-500\/90 {
  border-top-color: #10b981e6;
  border-bottom-color: #10b981e6;
}

.border-y-emerald-500\/95 {
  border-top-color: #10b981f2;
  border-bottom-color: #10b981f2;
}

.border-y-emerald-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(5 150 105 / var(--tw-border-opacity));
  border-bottom-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-y-emerald-600\/0 {
  border-top-color: #05966900;
  border-bottom-color: #05966900;
}

.border-y-emerald-600\/10 {
  border-top-color: #0596691a;
  border-bottom-color: #0596691a;
}

.border-y-emerald-600\/100 {
  border-top-color: #059669;
  border-bottom-color: #059669;
}

.border-y-emerald-600\/20 {
  border-top-color: #05966933;
  border-bottom-color: #05966933;
}

.border-y-emerald-600\/25 {
  border-top-color: #05966940;
  border-bottom-color: #05966940;
}

.border-y-emerald-600\/30 {
  border-top-color: #0596694d;
  border-bottom-color: #0596694d;
}

.border-y-emerald-600\/40 {
  border-top-color: #05966966;
  border-bottom-color: #05966966;
}

.border-y-emerald-600\/5 {
  border-top-color: #0596690d;
  border-bottom-color: #0596690d;
}

.border-y-emerald-600\/50 {
  border-top-color: #05966980;
  border-bottom-color: #05966980;
}

.border-y-emerald-600\/60 {
  border-top-color: #05966999;
  border-bottom-color: #05966999;
}

.border-y-emerald-600\/70 {
  border-top-color: #059669b3;
  border-bottom-color: #059669b3;
}

.border-y-emerald-600\/75 {
  border-top-color: #059669bf;
  border-bottom-color: #059669bf;
}

.border-y-emerald-600\/80 {
  border-top-color: #059669cc;
  border-bottom-color: #059669cc;
}

.border-y-emerald-600\/90 {
  border-top-color: #059669e6;
  border-bottom-color: #059669e6;
}

.border-y-emerald-600\/95 {
  border-top-color: #059669f2;
  border-bottom-color: #059669f2;
}

.border-y-emerald-700 {
  --tw-border-opacity: 1;
  border-top-color: rgb(4 120 87 / var(--tw-border-opacity));
  border-bottom-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-y-emerald-700\/0 {
  border-top-color: #04785700;
  border-bottom-color: #04785700;
}

.border-y-emerald-700\/10 {
  border-top-color: #0478571a;
  border-bottom-color: #0478571a;
}

.border-y-emerald-700\/100 {
  border-top-color: #047857;
  border-bottom-color: #047857;
}

.border-y-emerald-700\/20 {
  border-top-color: #04785733;
  border-bottom-color: #04785733;
}

.border-y-emerald-700\/25 {
  border-top-color: #04785740;
  border-bottom-color: #04785740;
}

.border-y-emerald-700\/30 {
  border-top-color: #0478574d;
  border-bottom-color: #0478574d;
}

.border-y-emerald-700\/40 {
  border-top-color: #04785766;
  border-bottom-color: #04785766;
}

.border-y-emerald-700\/5 {
  border-top-color: #0478570d;
  border-bottom-color: #0478570d;
}

.border-y-emerald-700\/50 {
  border-top-color: #04785780;
  border-bottom-color: #04785780;
}

.border-y-emerald-700\/60 {
  border-top-color: #04785799;
  border-bottom-color: #04785799;
}

.border-y-emerald-700\/70 {
  border-top-color: #047857b3;
  border-bottom-color: #047857b3;
}

.border-y-emerald-700\/75 {
  border-top-color: #047857bf;
  border-bottom-color: #047857bf;
}

.border-y-emerald-700\/80 {
  border-top-color: #047857cc;
  border-bottom-color: #047857cc;
}

.border-y-emerald-700\/90 {
  border-top-color: #047857e6;
  border-bottom-color: #047857e6;
}

.border-y-emerald-700\/95 {
  border-top-color: #047857f2;
  border-bottom-color: #047857f2;
}

.border-y-emerald-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(6 95 70 / var(--tw-border-opacity));
  border-bottom-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-y-emerald-800\/0 {
  border-top-color: #065f4600;
  border-bottom-color: #065f4600;
}

.border-y-emerald-800\/10 {
  border-top-color: #065f461a;
  border-bottom-color: #065f461a;
}

.border-y-emerald-800\/100 {
  border-top-color: #065f46;
  border-bottom-color: #065f46;
}

.border-y-emerald-800\/20 {
  border-top-color: #065f4633;
  border-bottom-color: #065f4633;
}

.border-y-emerald-800\/25 {
  border-top-color: #065f4640;
  border-bottom-color: #065f4640;
}

.border-y-emerald-800\/30 {
  border-top-color: #065f464d;
  border-bottom-color: #065f464d;
}

.border-y-emerald-800\/40 {
  border-top-color: #065f4666;
  border-bottom-color: #065f4666;
}

.border-y-emerald-800\/5 {
  border-top-color: #065f460d;
  border-bottom-color: #065f460d;
}

.border-y-emerald-800\/50 {
  border-top-color: #065f4680;
  border-bottom-color: #065f4680;
}

.border-y-emerald-800\/60 {
  border-top-color: #065f4699;
  border-bottom-color: #065f4699;
}

.border-y-emerald-800\/70 {
  border-top-color: #065f46b3;
  border-bottom-color: #065f46b3;
}

.border-y-emerald-800\/75 {
  border-top-color: #065f46bf;
  border-bottom-color: #065f46bf;
}

.border-y-emerald-800\/80 {
  border-top-color: #065f46cc;
  border-bottom-color: #065f46cc;
}

.border-y-emerald-800\/90 {
  border-top-color: #065f46e6;
  border-bottom-color: #065f46e6;
}

.border-y-emerald-800\/95 {
  border-top-color: #065f46f2;
  border-bottom-color: #065f46f2;
}

.border-y-emerald-900 {
  --tw-border-opacity: 1;
  border-top-color: rgb(6 78 59 / var(--tw-border-opacity));
  border-bottom-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-y-emerald-900\/0 {
  border-top-color: #064e3b00;
  border-bottom-color: #064e3b00;
}

.border-y-emerald-900\/10 {
  border-top-color: #064e3b1a;
  border-bottom-color: #064e3b1a;
}

.border-y-emerald-900\/100 {
  border-top-color: #064e3b;
  border-bottom-color: #064e3b;
}

.border-y-emerald-900\/20 {
  border-top-color: #064e3b33;
  border-bottom-color: #064e3b33;
}

.border-y-emerald-900\/25 {
  border-top-color: #064e3b40;
  border-bottom-color: #064e3b40;
}

.border-y-emerald-900\/30 {
  border-top-color: #064e3b4d;
  border-bottom-color: #064e3b4d;
}

.border-y-emerald-900\/40 {
  border-top-color: #064e3b66;
  border-bottom-color: #064e3b66;
}

.border-y-emerald-900\/5 {
  border-top-color: #064e3b0d;
  border-bottom-color: #064e3b0d;
}

.border-y-emerald-900\/50 {
  border-top-color: #064e3b80;
  border-bottom-color: #064e3b80;
}

.border-y-emerald-900\/60 {
  border-top-color: #064e3b99;
  border-bottom-color: #064e3b99;
}

.border-y-emerald-900\/70 {
  border-top-color: #064e3bb3;
  border-bottom-color: #064e3bb3;
}

.border-y-emerald-900\/75 {
  border-top-color: #064e3bbf;
  border-bottom-color: #064e3bbf;
}

.border-y-emerald-900\/80 {
  border-top-color: #064e3bcc;
  border-bottom-color: #064e3bcc;
}

.border-y-emerald-900\/90 {
  border-top-color: #064e3be6;
  border-bottom-color: #064e3be6;
}

.border-y-emerald-900\/95 {
  border-top-color: #064e3bf2;
  border-bottom-color: #064e3bf2;
}

.border-y-emerald-950 {
  --tw-border-opacity: 1;
  border-top-color: rgb(2 44 34 / var(--tw-border-opacity));
  border-bottom-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-y-emerald-950\/0 {
  border-top-color: #022c2200;
  border-bottom-color: #022c2200;
}

.border-y-emerald-950\/10 {
  border-top-color: #022c221a;
  border-bottom-color: #022c221a;
}

.border-y-emerald-950\/100 {
  border-top-color: #022c22;
  border-bottom-color: #022c22;
}

.border-y-emerald-950\/20 {
  border-top-color: #022c2233;
  border-bottom-color: #022c2233;
}

.border-y-emerald-950\/25 {
  border-top-color: #022c2240;
  border-bottom-color: #022c2240;
}

.border-y-emerald-950\/30 {
  border-top-color: #022c224d;
  border-bottom-color: #022c224d;
}

.border-y-emerald-950\/40 {
  border-top-color: #022c2266;
  border-bottom-color: #022c2266;
}

.border-y-emerald-950\/5 {
  border-top-color: #022c220d;
  border-bottom-color: #022c220d;
}

.border-y-emerald-950\/50 {
  border-top-color: #022c2280;
  border-bottom-color: #022c2280;
}

.border-y-emerald-950\/60 {
  border-top-color: #022c2299;
  border-bottom-color: #022c2299;
}

.border-y-emerald-950\/70 {
  border-top-color: #022c22b3;
  border-bottom-color: #022c22b3;
}

.border-y-emerald-950\/75 {
  border-top-color: #022c22bf;
  border-bottom-color: #022c22bf;
}

.border-y-emerald-950\/80 {
  border-top-color: #022c22cc;
  border-bottom-color: #022c22cc;
}

.border-y-emerald-950\/90 {
  border-top-color: #022c22e6;
  border-bottom-color: #022c22e6;
}

.border-y-emerald-950\/95 {
  border-top-color: #022c22f2;
  border-bottom-color: #022c22f2;
}

.border-y-lime-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(236 252 203 / var(--tw-border-opacity));
  border-bottom-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-y-lime-100\/0 {
  border-top-color: #ecfccb00;
  border-bottom-color: #ecfccb00;
}

.border-y-lime-100\/10 {
  border-top-color: #ecfccb1a;
  border-bottom-color: #ecfccb1a;
}

.border-y-lime-100\/100 {
  border-top-color: #ecfccb;
  border-bottom-color: #ecfccb;
}

.border-y-lime-100\/20 {
  border-top-color: #ecfccb33;
  border-bottom-color: #ecfccb33;
}

.border-y-lime-100\/25 {
  border-top-color: #ecfccb40;
  border-bottom-color: #ecfccb40;
}

.border-y-lime-100\/30 {
  border-top-color: #ecfccb4d;
  border-bottom-color: #ecfccb4d;
}

.border-y-lime-100\/40 {
  border-top-color: #ecfccb66;
  border-bottom-color: #ecfccb66;
}

.border-y-lime-100\/5 {
  border-top-color: #ecfccb0d;
  border-bottom-color: #ecfccb0d;
}

.border-y-lime-100\/50 {
  border-top-color: #ecfccb80;
  border-bottom-color: #ecfccb80;
}

.border-y-lime-100\/60 {
  border-top-color: #ecfccb99;
  border-bottom-color: #ecfccb99;
}

.border-y-lime-100\/70 {
  border-top-color: #ecfccbb3;
  border-bottom-color: #ecfccbb3;
}

.border-y-lime-100\/75 {
  border-top-color: #ecfccbbf;
  border-bottom-color: #ecfccbbf;
}

.border-y-lime-100\/80 {
  border-top-color: #ecfccbcc;
  border-bottom-color: #ecfccbcc;
}

.border-y-lime-100\/90 {
  border-top-color: #ecfccbe6;
  border-bottom-color: #ecfccbe6;
}

.border-y-lime-100\/95 {
  border-top-color: #ecfccbf2;
  border-bottom-color: #ecfccbf2;
}

.border-y-lime-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(217 249 157 / var(--tw-border-opacity));
  border-bottom-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-y-lime-200\/0 {
  border-top-color: #d9f99d00;
  border-bottom-color: #d9f99d00;
}

.border-y-lime-200\/10 {
  border-top-color: #d9f99d1a;
  border-bottom-color: #d9f99d1a;
}

.border-y-lime-200\/100 {
  border-top-color: #d9f99d;
  border-bottom-color: #d9f99d;
}

.border-y-lime-200\/20 {
  border-top-color: #d9f99d33;
  border-bottom-color: #d9f99d33;
}

.border-y-lime-200\/25 {
  border-top-color: #d9f99d40;
  border-bottom-color: #d9f99d40;
}

.border-y-lime-200\/30 {
  border-top-color: #d9f99d4d;
  border-bottom-color: #d9f99d4d;
}

.border-y-lime-200\/40 {
  border-top-color: #d9f99d66;
  border-bottom-color: #d9f99d66;
}

.border-y-lime-200\/5 {
  border-top-color: #d9f99d0d;
  border-bottom-color: #d9f99d0d;
}

.border-y-lime-200\/50 {
  border-top-color: #d9f99d80;
  border-bottom-color: #d9f99d80;
}

.border-y-lime-200\/60 {
  border-top-color: #d9f99d99;
  border-bottom-color: #d9f99d99;
}

.border-y-lime-200\/70 {
  border-top-color: #d9f99db3;
  border-bottom-color: #d9f99db3;
}

.border-y-lime-200\/75 {
  border-top-color: #d9f99dbf;
  border-bottom-color: #d9f99dbf;
}

.border-y-lime-200\/80 {
  border-top-color: #d9f99dcc;
  border-bottom-color: #d9f99dcc;
}

.border-y-lime-200\/90 {
  border-top-color: #d9f99de6;
  border-bottom-color: #d9f99de6;
}

.border-y-lime-200\/95 {
  border-top-color: #d9f99df2;
  border-bottom-color: #d9f99df2;
}

.border-y-lime-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(190 242 100 / var(--tw-border-opacity));
  border-bottom-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-y-lime-300\/0 {
  border-top-color: #bef26400;
  border-bottom-color: #bef26400;
}

.border-y-lime-300\/10 {
  border-top-color: #bef2641a;
  border-bottom-color: #bef2641a;
}

.border-y-lime-300\/100 {
  border-top-color: #bef264;
  border-bottom-color: #bef264;
}

.border-y-lime-300\/20 {
  border-top-color: #bef26433;
  border-bottom-color: #bef26433;
}

.border-y-lime-300\/25 {
  border-top-color: #bef26440;
  border-bottom-color: #bef26440;
}

.border-y-lime-300\/30 {
  border-top-color: #bef2644d;
  border-bottom-color: #bef2644d;
}

.border-y-lime-300\/40 {
  border-top-color: #bef26466;
  border-bottom-color: #bef26466;
}

.border-y-lime-300\/5 {
  border-top-color: #bef2640d;
  border-bottom-color: #bef2640d;
}

.border-y-lime-300\/50 {
  border-top-color: #bef26480;
  border-bottom-color: #bef26480;
}

.border-y-lime-300\/60 {
  border-top-color: #bef26499;
  border-bottom-color: #bef26499;
}

.border-y-lime-300\/70 {
  border-top-color: #bef264b3;
  border-bottom-color: #bef264b3;
}

.border-y-lime-300\/75 {
  border-top-color: #bef264bf;
  border-bottom-color: #bef264bf;
}

.border-y-lime-300\/80 {
  border-top-color: #bef264cc;
  border-bottom-color: #bef264cc;
}

.border-y-lime-300\/90 {
  border-top-color: #bef264e6;
  border-bottom-color: #bef264e6;
}

.border-y-lime-300\/95 {
  border-top-color: #bef264f2;
  border-bottom-color: #bef264f2;
}

.border-y-lime-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(163 230 53 / var(--tw-border-opacity));
  border-bottom-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-y-lime-400\/0 {
  border-top-color: #a3e63500;
  border-bottom-color: #a3e63500;
}

.border-y-lime-400\/10 {
  border-top-color: #a3e6351a;
  border-bottom-color: #a3e6351a;
}

.border-y-lime-400\/100 {
  border-top-color: #a3e635;
  border-bottom-color: #a3e635;
}

.border-y-lime-400\/20 {
  border-top-color: #a3e63533;
  border-bottom-color: #a3e63533;
}

.border-y-lime-400\/25 {
  border-top-color: #a3e63540;
  border-bottom-color: #a3e63540;
}

.border-y-lime-400\/30 {
  border-top-color: #a3e6354d;
  border-bottom-color: #a3e6354d;
}

.border-y-lime-400\/40 {
  border-top-color: #a3e63566;
  border-bottom-color: #a3e63566;
}

.border-y-lime-400\/5 {
  border-top-color: #a3e6350d;
  border-bottom-color: #a3e6350d;
}

.border-y-lime-400\/50 {
  border-top-color: #a3e63580;
  border-bottom-color: #a3e63580;
}

.border-y-lime-400\/60 {
  border-top-color: #a3e63599;
  border-bottom-color: #a3e63599;
}

.border-y-lime-400\/70 {
  border-top-color: #a3e635b3;
  border-bottom-color: #a3e635b3;
}

.border-y-lime-400\/75 {
  border-top-color: #a3e635bf;
  border-bottom-color: #a3e635bf;
}

.border-y-lime-400\/80 {
  border-top-color: #a3e635cc;
  border-bottom-color: #a3e635cc;
}

.border-y-lime-400\/90 {
  border-top-color: #a3e635e6;
  border-bottom-color: #a3e635e6;
}

.border-y-lime-400\/95 {
  border-top-color: #a3e635f2;
  border-bottom-color: #a3e635f2;
}

.border-y-lime-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(247 254 231 / var(--tw-border-opacity));
  border-bottom-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-y-lime-50\/0 {
  border-top-color: #f7fee700;
  border-bottom-color: #f7fee700;
}

.border-y-lime-50\/10 {
  border-top-color: #f7fee71a;
  border-bottom-color: #f7fee71a;
}

.border-y-lime-50\/100 {
  border-top-color: #f7fee7;
  border-bottom-color: #f7fee7;
}

.border-y-lime-50\/20 {
  border-top-color: #f7fee733;
  border-bottom-color: #f7fee733;
}

.border-y-lime-50\/25 {
  border-top-color: #f7fee740;
  border-bottom-color: #f7fee740;
}

.border-y-lime-50\/30 {
  border-top-color: #f7fee74d;
  border-bottom-color: #f7fee74d;
}

.border-y-lime-50\/40 {
  border-top-color: #f7fee766;
  border-bottom-color: #f7fee766;
}

.border-y-lime-50\/5 {
  border-top-color: #f7fee70d;
  border-bottom-color: #f7fee70d;
}

.border-y-lime-50\/50 {
  border-top-color: #f7fee780;
  border-bottom-color: #f7fee780;
}

.border-y-lime-50\/60 {
  border-top-color: #f7fee799;
  border-bottom-color: #f7fee799;
}

.border-y-lime-50\/70 {
  border-top-color: #f7fee7b3;
  border-bottom-color: #f7fee7b3;
}

.border-y-lime-50\/75 {
  border-top-color: #f7fee7bf;
  border-bottom-color: #f7fee7bf;
}

.border-y-lime-50\/80 {
  border-top-color: #f7fee7cc;
  border-bottom-color: #f7fee7cc;
}

.border-y-lime-50\/90 {
  border-top-color: #f7fee7e6;
  border-bottom-color: #f7fee7e6;
}

.border-y-lime-50\/95 {
  border-top-color: #f7fee7f2;
  border-bottom-color: #f7fee7f2;
}

.border-y-lime-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(132 204 22 / var(--tw-border-opacity));
  border-bottom-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-y-lime-500\/0 {
  border-top-color: #84cc1600;
  border-bottom-color: #84cc1600;
}

.border-y-lime-500\/10 {
  border-top-color: #84cc161a;
  border-bottom-color: #84cc161a;
}

.border-y-lime-500\/100 {
  border-top-color: #84cc16;
  border-bottom-color: #84cc16;
}

.border-y-lime-500\/20 {
  border-top-color: #84cc1633;
  border-bottom-color: #84cc1633;
}

.border-y-lime-500\/25 {
  border-top-color: #84cc1640;
  border-bottom-color: #84cc1640;
}

.border-y-lime-500\/30 {
  border-top-color: #84cc164d;
  border-bottom-color: #84cc164d;
}

.border-y-lime-500\/40 {
  border-top-color: #84cc1666;
  border-bottom-color: #84cc1666;
}

.border-y-lime-500\/5 {
  border-top-color: #84cc160d;
  border-bottom-color: #84cc160d;
}

.border-y-lime-500\/50 {
  border-top-color: #84cc1680;
  border-bottom-color: #84cc1680;
}

.border-y-lime-500\/60 {
  border-top-color: #84cc1699;
  border-bottom-color: #84cc1699;
}

.border-y-lime-500\/70 {
  border-top-color: #84cc16b3;
  border-bottom-color: #84cc16b3;
}

.border-y-lime-500\/75 {
  border-top-color: #84cc16bf;
  border-bottom-color: #84cc16bf;
}

.border-y-lime-500\/80 {
  border-top-color: #84cc16cc;
  border-bottom-color: #84cc16cc;
}

.border-y-lime-500\/90 {
  border-top-color: #84cc16e6;
  border-bottom-color: #84cc16e6;
}

.border-y-lime-500\/95 {
  border-top-color: #84cc16f2;
  border-bottom-color: #84cc16f2;
}

.border-y-lime-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(101 163 13 / var(--tw-border-opacity));
  border-bottom-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-y-lime-600\/0 {
  border-top-color: #65a30d00;
  border-bottom-color: #65a30d00;
}

.border-y-lime-600\/10 {
  border-top-color: #65a30d1a;
  border-bottom-color: #65a30d1a;
}

.border-y-lime-600\/100 {
  border-top-color: #65a30d;
  border-bottom-color: #65a30d;
}

.border-y-lime-600\/20 {
  border-top-color: #65a30d33;
  border-bottom-color: #65a30d33;
}

.border-y-lime-600\/25 {
  border-top-color: #65a30d40;
  border-bottom-color: #65a30d40;
}

.border-y-lime-600\/30 {
  border-top-color: #65a30d4d;
  border-bottom-color: #65a30d4d;
}

.border-y-lime-600\/40 {
  border-top-color: #65a30d66;
  border-bottom-color: #65a30d66;
}

.border-y-lime-600\/5 {
  border-top-color: #65a30d0d;
  border-bottom-color: #65a30d0d;
}

.border-y-lime-600\/50 {
  border-top-color: #65a30d80;
  border-bottom-color: #65a30d80;
}

.border-y-lime-600\/60 {
  border-top-color: #65a30d99;
  border-bottom-color: #65a30d99;
}

.border-y-lime-600\/70 {
  border-top-color: #65a30db3;
  border-bottom-color: #65a30db3;
}

.border-y-lime-600\/75 {
  border-top-color: #65a30dbf;
  border-bottom-color: #65a30dbf;
}

.border-y-lime-600\/80 {
  border-top-color: #65a30dcc;
  border-bottom-color: #65a30dcc;
}

.border-y-lime-600\/90 {
  border-top-color: #65a30de6;
  border-bottom-color: #65a30de6;
}

.border-y-lime-600\/95 {
  border-top-color: #65a30df2;
  border-bottom-color: #65a30df2;
}

.border-y-lime-700 {
  --tw-border-opacity: 1;
  border-top-color: rgb(77 124 15 / var(--tw-border-opacity));
  border-bottom-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-y-lime-700\/0 {
  border-top-color: #4d7c0f00;
  border-bottom-color: #4d7c0f00;
}

.border-y-lime-700\/10 {
  border-top-color: #4d7c0f1a;
  border-bottom-color: #4d7c0f1a;
}

.border-y-lime-700\/100 {
  border-top-color: #4d7c0f;
  border-bottom-color: #4d7c0f;
}

.border-y-lime-700\/20 {
  border-top-color: #4d7c0f33;
  border-bottom-color: #4d7c0f33;
}

.border-y-lime-700\/25 {
  border-top-color: #4d7c0f40;
  border-bottom-color: #4d7c0f40;
}

.border-y-lime-700\/30 {
  border-top-color: #4d7c0f4d;
  border-bottom-color: #4d7c0f4d;
}

.border-y-lime-700\/40 {
  border-top-color: #4d7c0f66;
  border-bottom-color: #4d7c0f66;
}

.border-y-lime-700\/5 {
  border-top-color: #4d7c0f0d;
  border-bottom-color: #4d7c0f0d;
}

.border-y-lime-700\/50 {
  border-top-color: #4d7c0f80;
  border-bottom-color: #4d7c0f80;
}

.border-y-lime-700\/60 {
  border-top-color: #4d7c0f99;
  border-bottom-color: #4d7c0f99;
}

.border-y-lime-700\/70 {
  border-top-color: #4d7c0fb3;
  border-bottom-color: #4d7c0fb3;
}

.border-y-lime-700\/75 {
  border-top-color: #4d7c0fbf;
  border-bottom-color: #4d7c0fbf;
}

.border-y-lime-700\/80 {
  border-top-color: #4d7c0fcc;
  border-bottom-color: #4d7c0fcc;
}

.border-y-lime-700\/90 {
  border-top-color: #4d7c0fe6;
  border-bottom-color: #4d7c0fe6;
}

.border-y-lime-700\/95 {
  border-top-color: #4d7c0ff2;
  border-bottom-color: #4d7c0ff2;
}

.border-y-lime-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(63 98 18 / var(--tw-border-opacity));
  border-bottom-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-y-lime-800\/0 {
  border-top-color: #3f621200;
  border-bottom-color: #3f621200;
}

.border-y-lime-800\/10 {
  border-top-color: #3f62121a;
  border-bottom-color: #3f62121a;
}

.border-y-lime-800\/100 {
  border-top-color: #3f6212;
  border-bottom-color: #3f6212;
}

.border-y-lime-800\/20 {
  border-top-color: #3f621233;
  border-bottom-color: #3f621233;
}

.border-y-lime-800\/25 {
  border-top-color: #3f621240;
  border-bottom-color: #3f621240;
}

.border-y-lime-800\/30 {
  border-top-color: #3f62124d;
  border-bottom-color: #3f62124d;
}

.border-y-lime-800\/40 {
  border-top-color: #3f621266;
  border-bottom-color: #3f621266;
}

.border-y-lime-800\/5 {
  border-top-color: #3f62120d;
  border-bottom-color: #3f62120d;
}

.border-y-lime-800\/50 {
  border-top-color: #3f621280;
  border-bottom-color: #3f621280;
}

.border-y-lime-800\/60 {
  border-top-color: #3f621299;
  border-bottom-color: #3f621299;
}

.border-y-lime-800\/70 {
  border-top-color: #3f6212b3;
  border-bottom-color: #3f6212b3;
}

.border-y-lime-800\/75 {
  border-top-color: #3f6212bf;
  border-bottom-color: #3f6212bf;
}

.border-y-lime-800\/80 {
  border-top-color: #3f6212cc;
  border-bottom-color: #3f6212cc;
}

.border-y-lime-800\/90 {
  border-top-color: #3f6212e6;
  border-bottom-color: #3f6212e6;
}

.border-y-lime-800\/95 {
  border-top-color: #3f6212f2;
  border-bottom-color: #3f6212f2;
}

.border-y-lime-900 {
  --tw-border-opacity: 1;
  border-top-color: rgb(54 83 20 / var(--tw-border-opacity));
  border-bottom-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-y-lime-900\/0 {
  border-top-color: #36531400;
  border-bottom-color: #36531400;
}

.border-y-lime-900\/10 {
  border-top-color: #3653141a;
  border-bottom-color: #3653141a;
}

.border-y-lime-900\/100 {
  border-top-color: #365314;
  border-bottom-color: #365314;
}

.border-y-lime-900\/20 {
  border-top-color: #36531433;
  border-bottom-color: #36531433;
}

.border-y-lime-900\/25 {
  border-top-color: #36531440;
  border-bottom-color: #36531440;
}

.border-y-lime-900\/30 {
  border-top-color: #3653144d;
  border-bottom-color: #3653144d;
}

.border-y-lime-900\/40 {
  border-top-color: #36531466;
  border-bottom-color: #36531466;
}

.border-y-lime-900\/5 {
  border-top-color: #3653140d;
  border-bottom-color: #3653140d;
}

.border-y-lime-900\/50 {
  border-top-color: #36531480;
  border-bottom-color: #36531480;
}

.border-y-lime-900\/60 {
  border-top-color: #36531499;
  border-bottom-color: #36531499;
}

.border-y-lime-900\/70 {
  border-top-color: #365314b3;
  border-bottom-color: #365314b3;
}

.border-y-lime-900\/75 {
  border-top-color: #365314bf;
  border-bottom-color: #365314bf;
}

.border-y-lime-900\/80 {
  border-top-color: #365314cc;
  border-bottom-color: #365314cc;
}

.border-y-lime-900\/90 {
  border-top-color: #365314e6;
  border-bottom-color: #365314e6;
}

.border-y-lime-900\/95 {
  border-top-color: #365314f2;
  border-bottom-color: #365314f2;
}

.border-y-lime-950 {
  --tw-border-opacity: 1;
  border-top-color: rgb(26 46 5 / var(--tw-border-opacity));
  border-bottom-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-y-lime-950\/0 {
  border-top-color: #1a2e0500;
  border-bottom-color: #1a2e0500;
}

.border-y-lime-950\/10 {
  border-top-color: #1a2e051a;
  border-bottom-color: #1a2e051a;
}

.border-y-lime-950\/100 {
  border-top-color: #1a2e05;
  border-bottom-color: #1a2e05;
}

.border-y-lime-950\/20 {
  border-top-color: #1a2e0533;
  border-bottom-color: #1a2e0533;
}

.border-y-lime-950\/25 {
  border-top-color: #1a2e0540;
  border-bottom-color: #1a2e0540;
}

.border-y-lime-950\/30 {
  border-top-color: #1a2e054d;
  border-bottom-color: #1a2e054d;
}

.border-y-lime-950\/40 {
  border-top-color: #1a2e0566;
  border-bottom-color: #1a2e0566;
}

.border-y-lime-950\/5 {
  border-top-color: #1a2e050d;
  border-bottom-color: #1a2e050d;
}

.border-y-lime-950\/50 {
  border-top-color: #1a2e0580;
  border-bottom-color: #1a2e0580;
}

.border-y-lime-950\/60 {
  border-top-color: #1a2e0599;
  border-bottom-color: #1a2e0599;
}

.border-y-lime-950\/70 {
  border-top-color: #1a2e05b3;
  border-bottom-color: #1a2e05b3;
}

.border-y-lime-950\/75 {
  border-top-color: #1a2e05bf;
  border-bottom-color: #1a2e05bf;
}

.border-y-lime-950\/80 {
  border-top-color: #1a2e05cc;
  border-bottom-color: #1a2e05cc;
}

.border-y-lime-950\/90 {
  border-top-color: #1a2e05e6;
  border-bottom-color: #1a2e05e6;
}

.border-y-lime-950\/95 {
  border-top-color: #1a2e05f2;
  border-bottom-color: #1a2e05f2;
}

.border-y-pink-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(252 231 243 / var(--tw-border-opacity));
  border-bottom-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-y-pink-100\/0 {
  border-top-color: #fce7f300;
  border-bottom-color: #fce7f300;
}

.border-y-pink-100\/10 {
  border-top-color: #fce7f31a;
  border-bottom-color: #fce7f31a;
}

.border-y-pink-100\/100 {
  border-top-color: #fce7f3;
  border-bottom-color: #fce7f3;
}

.border-y-pink-100\/20 {
  border-top-color: #fce7f333;
  border-bottom-color: #fce7f333;
}

.border-y-pink-100\/25 {
  border-top-color: #fce7f340;
  border-bottom-color: #fce7f340;
}

.border-y-pink-100\/30 {
  border-top-color: #fce7f34d;
  border-bottom-color: #fce7f34d;
}

.border-y-pink-100\/40 {
  border-top-color: #fce7f366;
  border-bottom-color: #fce7f366;
}

.border-y-pink-100\/5 {
  border-top-color: #fce7f30d;
  border-bottom-color: #fce7f30d;
}

.border-y-pink-100\/50 {
  border-top-color: #fce7f380;
  border-bottom-color: #fce7f380;
}

.border-y-pink-100\/60 {
  border-top-color: #fce7f399;
  border-bottom-color: #fce7f399;
}

.border-y-pink-100\/70 {
  border-top-color: #fce7f3b3;
  border-bottom-color: #fce7f3b3;
}

.border-y-pink-100\/75 {
  border-top-color: #fce7f3bf;
  border-bottom-color: #fce7f3bf;
}

.border-y-pink-100\/80 {
  border-top-color: #fce7f3cc;
  border-bottom-color: #fce7f3cc;
}

.border-y-pink-100\/90 {
  border-top-color: #fce7f3e6;
  border-bottom-color: #fce7f3e6;
}

.border-y-pink-100\/95 {
  border-top-color: #fce7f3f2;
  border-bottom-color: #fce7f3f2;
}

.border-y-pink-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(251 207 232 / var(--tw-border-opacity));
  border-bottom-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-y-pink-200\/0 {
  border-top-color: #fbcfe800;
  border-bottom-color: #fbcfe800;
}

.border-y-pink-200\/10 {
  border-top-color: #fbcfe81a;
  border-bottom-color: #fbcfe81a;
}

.border-y-pink-200\/100 {
  border-top-color: #fbcfe8;
  border-bottom-color: #fbcfe8;
}

.border-y-pink-200\/20 {
  border-top-color: #fbcfe833;
  border-bottom-color: #fbcfe833;
}

.border-y-pink-200\/25 {
  border-top-color: #fbcfe840;
  border-bottom-color: #fbcfe840;
}

.border-y-pink-200\/30 {
  border-top-color: #fbcfe84d;
  border-bottom-color: #fbcfe84d;
}

.border-y-pink-200\/40 {
  border-top-color: #fbcfe866;
  border-bottom-color: #fbcfe866;
}

.border-y-pink-200\/5 {
  border-top-color: #fbcfe80d;
  border-bottom-color: #fbcfe80d;
}

.border-y-pink-200\/50 {
  border-top-color: #fbcfe880;
  border-bottom-color: #fbcfe880;
}

.border-y-pink-200\/60 {
  border-top-color: #fbcfe899;
  border-bottom-color: #fbcfe899;
}

.border-y-pink-200\/70 {
  border-top-color: #fbcfe8b3;
  border-bottom-color: #fbcfe8b3;
}

.border-y-pink-200\/75 {
  border-top-color: #fbcfe8bf;
  border-bottom-color: #fbcfe8bf;
}

.border-y-pink-200\/80 {
  border-top-color: #fbcfe8cc;
  border-bottom-color: #fbcfe8cc;
}

.border-y-pink-200\/90 {
  border-top-color: #fbcfe8e6;
  border-bottom-color: #fbcfe8e6;
}

.border-y-pink-200\/95 {
  border-top-color: #fbcfe8f2;
  border-bottom-color: #fbcfe8f2;
}

.border-y-pink-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(249 168 212 / var(--tw-border-opacity));
  border-bottom-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-y-pink-300\/0 {
  border-top-color: #f9a8d400;
  border-bottom-color: #f9a8d400;
}

.border-y-pink-300\/10 {
  border-top-color: #f9a8d41a;
  border-bottom-color: #f9a8d41a;
}

.border-y-pink-300\/100 {
  border-top-color: #f9a8d4;
  border-bottom-color: #f9a8d4;
}

.border-y-pink-300\/20 {
  border-top-color: #f9a8d433;
  border-bottom-color: #f9a8d433;
}

.border-y-pink-300\/25 {
  border-top-color: #f9a8d440;
  border-bottom-color: #f9a8d440;
}

.border-y-pink-300\/30 {
  border-top-color: #f9a8d44d;
  border-bottom-color: #f9a8d44d;
}

.border-y-pink-300\/40 {
  border-top-color: #f9a8d466;
  border-bottom-color: #f9a8d466;
}

.border-y-pink-300\/5 {
  border-top-color: #f9a8d40d;
  border-bottom-color: #f9a8d40d;
}

.border-y-pink-300\/50 {
  border-top-color: #f9a8d480;
  border-bottom-color: #f9a8d480;
}

.border-y-pink-300\/60 {
  border-top-color: #f9a8d499;
  border-bottom-color: #f9a8d499;
}

.border-y-pink-300\/70 {
  border-top-color: #f9a8d4b3;
  border-bottom-color: #f9a8d4b3;
}

.border-y-pink-300\/75 {
  border-top-color: #f9a8d4bf;
  border-bottom-color: #f9a8d4bf;
}

.border-y-pink-300\/80 {
  border-top-color: #f9a8d4cc;
  border-bottom-color: #f9a8d4cc;
}

.border-y-pink-300\/90 {
  border-top-color: #f9a8d4e6;
  border-bottom-color: #f9a8d4e6;
}

.border-y-pink-300\/95 {
  border-top-color: #f9a8d4f2;
  border-bottom-color: #f9a8d4f2;
}

.border-y-pink-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(244 114 182 / var(--tw-border-opacity));
  border-bottom-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-y-pink-400\/0 {
  border-top-color: #f472b600;
  border-bottom-color: #f472b600;
}

.border-y-pink-400\/10 {
  border-top-color: #f472b61a;
  border-bottom-color: #f472b61a;
}

.border-y-pink-400\/100 {
  border-top-color: #f472b6;
  border-bottom-color: #f472b6;
}

.border-y-pink-400\/20 {
  border-top-color: #f472b633;
  border-bottom-color: #f472b633;
}

.border-y-pink-400\/25 {
  border-top-color: #f472b640;
  border-bottom-color: #f472b640;
}

.border-y-pink-400\/30 {
  border-top-color: #f472b64d;
  border-bottom-color: #f472b64d;
}

.border-y-pink-400\/40 {
  border-top-color: #f472b666;
  border-bottom-color: #f472b666;
}

.border-y-pink-400\/5 {
  border-top-color: #f472b60d;
  border-bottom-color: #f472b60d;
}

.border-y-pink-400\/50 {
  border-top-color: #f472b680;
  border-bottom-color: #f472b680;
}

.border-y-pink-400\/60 {
  border-top-color: #f472b699;
  border-bottom-color: #f472b699;
}

.border-y-pink-400\/70 {
  border-top-color: #f472b6b3;
  border-bottom-color: #f472b6b3;
}

.border-y-pink-400\/75 {
  border-top-color: #f472b6bf;
  border-bottom-color: #f472b6bf;
}

.border-y-pink-400\/80 {
  border-top-color: #f472b6cc;
  border-bottom-color: #f472b6cc;
}

.border-y-pink-400\/90 {
  border-top-color: #f472b6e6;
  border-bottom-color: #f472b6e6;
}

.border-y-pink-400\/95 {
  border-top-color: #f472b6f2;
  border-bottom-color: #f472b6f2;
}

.border-y-pink-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(253 242 248 / var(--tw-border-opacity));
  border-bottom-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-y-pink-50\/0 {
  border-top-color: #fdf2f800;
  border-bottom-color: #fdf2f800;
}

.border-y-pink-50\/10 {
  border-top-color: #fdf2f81a;
  border-bottom-color: #fdf2f81a;
}

.border-y-pink-50\/100 {
  border-top-color: #fdf2f8;
  border-bottom-color: #fdf2f8;
}

.border-y-pink-50\/20 {
  border-top-color: #fdf2f833;
  border-bottom-color: #fdf2f833;
}

.border-y-pink-50\/25 {
  border-top-color: #fdf2f840;
  border-bottom-color: #fdf2f840;
}

.border-y-pink-50\/30 {
  border-top-color: #fdf2f84d;
  border-bottom-color: #fdf2f84d;
}

.border-y-pink-50\/40 {
  border-top-color: #fdf2f866;
  border-bottom-color: #fdf2f866;
}

.border-y-pink-50\/5 {
  border-top-color: #fdf2f80d;
  border-bottom-color: #fdf2f80d;
}

.border-y-pink-50\/50 {
  border-top-color: #fdf2f880;
  border-bottom-color: #fdf2f880;
}

.border-y-pink-50\/60 {
  border-top-color: #fdf2f899;
  border-bottom-color: #fdf2f899;
}

.border-y-pink-50\/70 {
  border-top-color: #fdf2f8b3;
  border-bottom-color: #fdf2f8b3;
}

.border-y-pink-50\/75 {
  border-top-color: #fdf2f8bf;
  border-bottom-color: #fdf2f8bf;
}

.border-y-pink-50\/80 {
  border-top-color: #fdf2f8cc;
  border-bottom-color: #fdf2f8cc;
}

.border-y-pink-50\/90 {
  border-top-color: #fdf2f8e6;
  border-bottom-color: #fdf2f8e6;
}

.border-y-pink-50\/95 {
  border-top-color: #fdf2f8f2;
  border-bottom-color: #fdf2f8f2;
}

.border-y-pink-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(236 72 153 / var(--tw-border-opacity));
  border-bottom-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-y-pink-500\/0 {
  border-top-color: #ec489900;
  border-bottom-color: #ec489900;
}

.border-y-pink-500\/10 {
  border-top-color: #ec48991a;
  border-bottom-color: #ec48991a;
}

.border-y-pink-500\/100 {
  border-top-color: #ec4899;
  border-bottom-color: #ec4899;
}

.border-y-pink-500\/20 {
  border-top-color: #ec489933;
  border-bottom-color: #ec489933;
}

.border-y-pink-500\/25 {
  border-top-color: #ec489940;
  border-bottom-color: #ec489940;
}

.border-y-pink-500\/30 {
  border-top-color: #ec48994d;
  border-bottom-color: #ec48994d;
}

.border-y-pink-500\/40 {
  border-top-color: #ec489966;
  border-bottom-color: #ec489966;
}

.border-y-pink-500\/5 {
  border-top-color: #ec48990d;
  border-bottom-color: #ec48990d;
}

.border-y-pink-500\/50 {
  border-top-color: #ec489980;
  border-bottom-color: #ec489980;
}

.border-y-pink-500\/60 {
  border-top-color: #ec489999;
  border-bottom-color: #ec489999;
}

.border-y-pink-500\/70 {
  border-top-color: #ec4899b3;
  border-bottom-color: #ec4899b3;
}

.border-y-pink-500\/75 {
  border-top-color: #ec4899bf;
  border-bottom-color: #ec4899bf;
}

.border-y-pink-500\/80 {
  border-top-color: #ec4899cc;
  border-bottom-color: #ec4899cc;
}

.border-y-pink-500\/90 {
  border-top-color: #ec4899e6;
  border-bottom-color: #ec4899e6;
}

.border-y-pink-500\/95 {
  border-top-color: #ec4899f2;
  border-bottom-color: #ec4899f2;
}

.border-y-pink-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(219 39 119 / var(--tw-border-opacity));
  border-bottom-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-y-pink-600\/0 {
  border-top-color: #db277700;
  border-bottom-color: #db277700;
}

.border-y-pink-600\/10 {
  border-top-color: #db27771a;
  border-bottom-color: #db27771a;
}

.border-y-pink-600\/100 {
  border-top-color: #db2777;
  border-bottom-color: #db2777;
}

.border-y-pink-600\/20 {
  border-top-color: #db277733;
  border-bottom-color: #db277733;
}

.border-y-pink-600\/25 {
  border-top-color: #db277740;
  border-bottom-color: #db277740;
}

.border-y-pink-600\/30 {
  border-top-color: #db27774d;
  border-bottom-color: #db27774d;
}

.border-y-pink-600\/40 {
  border-top-color: #db277766;
  border-bottom-color: #db277766;
}

.border-y-pink-600\/5 {
  border-top-color: #db27770d;
  border-bottom-color: #db27770d;
}

.border-y-pink-600\/50 {
  border-top-color: #db277780;
  border-bottom-color: #db277780;
}

.border-y-pink-600\/60 {
  border-top-color: #db277799;
  border-bottom-color: #db277799;
}

.border-y-pink-600\/70 {
  border-top-color: #db2777b3;
  border-bottom-color: #db2777b3;
}

.border-y-pink-600\/75 {
  border-top-color: #db2777bf;
  border-bottom-color: #db2777bf;
}

.border-y-pink-600\/80 {
  border-top-color: #db2777cc;
  border-bottom-color: #db2777cc;
}

.border-y-pink-600\/90 {
  border-top-color: #db2777e6;
  border-bottom-color: #db2777e6;
}

.border-y-pink-600\/95 {
  border-top-color: #db2777f2;
  border-bottom-color: #db2777f2;
}

.border-y-pink-700 {
  --tw-border-opacity: 1;
  border-top-color: rgb(190 24 93 / var(--tw-border-opacity));
  border-bottom-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-y-pink-700\/0 {
  border-top-color: #be185d00;
  border-bottom-color: #be185d00;
}

.border-y-pink-700\/10 {
  border-top-color: #be185d1a;
  border-bottom-color: #be185d1a;
}

.border-y-pink-700\/100 {
  border-top-color: #be185d;
  border-bottom-color: #be185d;
}

.border-y-pink-700\/20 {
  border-top-color: #be185d33;
  border-bottom-color: #be185d33;
}

.border-y-pink-700\/25 {
  border-top-color: #be185d40;
  border-bottom-color: #be185d40;
}

.border-y-pink-700\/30 {
  border-top-color: #be185d4d;
  border-bottom-color: #be185d4d;
}

.border-y-pink-700\/40 {
  border-top-color: #be185d66;
  border-bottom-color: #be185d66;
}

.border-y-pink-700\/5 {
  border-top-color: #be185d0d;
  border-bottom-color: #be185d0d;
}

.border-y-pink-700\/50 {
  border-top-color: #be185d80;
  border-bottom-color: #be185d80;
}

.border-y-pink-700\/60 {
  border-top-color: #be185d99;
  border-bottom-color: #be185d99;
}

.border-y-pink-700\/70 {
  border-top-color: #be185db3;
  border-bottom-color: #be185db3;
}

.border-y-pink-700\/75 {
  border-top-color: #be185dbf;
  border-bottom-color: #be185dbf;
}

.border-y-pink-700\/80 {
  border-top-color: #be185dcc;
  border-bottom-color: #be185dcc;
}

.border-y-pink-700\/90 {
  border-top-color: #be185de6;
  border-bottom-color: #be185de6;
}

.border-y-pink-700\/95 {
  border-top-color: #be185df2;
  border-bottom-color: #be185df2;
}

.border-y-pink-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(157 23 77 / var(--tw-border-opacity));
  border-bottom-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-y-pink-800\/0 {
  border-top-color: #9d174d00;
  border-bottom-color: #9d174d00;
}

.border-y-pink-800\/10 {
  border-top-color: #9d174d1a;
  border-bottom-color: #9d174d1a;
}

.border-y-pink-800\/100 {
  border-top-color: #9d174d;
  border-bottom-color: #9d174d;
}

.border-y-pink-800\/20 {
  border-top-color: #9d174d33;
  border-bottom-color: #9d174d33;
}

.border-y-pink-800\/25 {
  border-top-color: #9d174d40;
  border-bottom-color: #9d174d40;
}

.border-y-pink-800\/30 {
  border-top-color: #9d174d4d;
  border-bottom-color: #9d174d4d;
}

.border-y-pink-800\/40 {
  border-top-color: #9d174d66;
  border-bottom-color: #9d174d66;
}

.border-y-pink-800\/5 {
  border-top-color: #9d174d0d;
  border-bottom-color: #9d174d0d;
}

.border-y-pink-800\/50 {
  border-top-color: #9d174d80;
  border-bottom-color: #9d174d80;
}

.border-y-pink-800\/60 {
  border-top-color: #9d174d99;
  border-bottom-color: #9d174d99;
}

.border-y-pink-800\/70 {
  border-top-color: #9d174db3;
  border-bottom-color: #9d174db3;
}

.border-y-pink-800\/75 {
  border-top-color: #9d174dbf;
  border-bottom-color: #9d174dbf;
}

.border-y-pink-800\/80 {
  border-top-color: #9d174dcc;
  border-bottom-color: #9d174dcc;
}

.border-y-pink-800\/90 {
  border-top-color: #9d174de6;
  border-bottom-color: #9d174de6;
}

.border-y-pink-800\/95 {
  border-top-color: #9d174df2;
  border-bottom-color: #9d174df2;
}

.border-y-pink-900 {
  --tw-border-opacity: 1;
  border-top-color: rgb(131 24 67 / var(--tw-border-opacity));
  border-bottom-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-y-pink-900\/0 {
  border-top-color: #83184300;
  border-bottom-color: #83184300;
}

.border-y-pink-900\/10 {
  border-top-color: #8318431a;
  border-bottom-color: #8318431a;
}

.border-y-pink-900\/100 {
  border-top-color: #831843;
  border-bottom-color: #831843;
}

.border-y-pink-900\/20 {
  border-top-color: #83184333;
  border-bottom-color: #83184333;
}

.border-y-pink-900\/25 {
  border-top-color: #83184340;
  border-bottom-color: #83184340;
}

.border-y-pink-900\/30 {
  border-top-color: #8318434d;
  border-bottom-color: #8318434d;
}

.border-y-pink-900\/40 {
  border-top-color: #83184366;
  border-bottom-color: #83184366;
}

.border-y-pink-900\/5 {
  border-top-color: #8318430d;
  border-bottom-color: #8318430d;
}

.border-y-pink-900\/50 {
  border-top-color: #83184380;
  border-bottom-color: #83184380;
}

.border-y-pink-900\/60 {
  border-top-color: #83184399;
  border-bottom-color: #83184399;
}

.border-y-pink-900\/70 {
  border-top-color: #831843b3;
  border-bottom-color: #831843b3;
}

.border-y-pink-900\/75 {
  border-top-color: #831843bf;
  border-bottom-color: #831843bf;
}

.border-y-pink-900\/80 {
  border-top-color: #831843cc;
  border-bottom-color: #831843cc;
}

.border-y-pink-900\/90 {
  border-top-color: #831843e6;
  border-bottom-color: #831843e6;
}

.border-y-pink-900\/95 {
  border-top-color: #831843f2;
  border-bottom-color: #831843f2;
}

.border-y-pink-950 {
  --tw-border-opacity: 1;
  border-top-color: rgb(80 7 36 / var(--tw-border-opacity));
  border-bottom-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-y-pink-950\/0 {
  border-top-color: #50072400;
  border-bottom-color: #50072400;
}

.border-y-pink-950\/10 {
  border-top-color: #5007241a;
  border-bottom-color: #5007241a;
}

.border-y-pink-950\/100 {
  border-top-color: #500724;
  border-bottom-color: #500724;
}

.border-y-pink-950\/20 {
  border-top-color: #50072433;
  border-bottom-color: #50072433;
}

.border-y-pink-950\/25 {
  border-top-color: #50072440;
  border-bottom-color: #50072440;
}

.border-y-pink-950\/30 {
  border-top-color: #5007244d;
  border-bottom-color: #5007244d;
}

.border-y-pink-950\/40 {
  border-top-color: #50072466;
  border-bottom-color: #50072466;
}

.border-y-pink-950\/5 {
  border-top-color: #5007240d;
  border-bottom-color: #5007240d;
}

.border-y-pink-950\/50 {
  border-top-color: #50072480;
  border-bottom-color: #50072480;
}

.border-y-pink-950\/60 {
  border-top-color: #50072499;
  border-bottom-color: #50072499;
}

.border-y-pink-950\/70 {
  border-top-color: #500724b3;
  border-bottom-color: #500724b3;
}

.border-y-pink-950\/75 {
  border-top-color: #500724bf;
  border-bottom-color: #500724bf;
}

.border-y-pink-950\/80 {
  border-top-color: #500724cc;
  border-bottom-color: #500724cc;
}

.border-y-pink-950\/90 {
  border-top-color: #500724e6;
  border-bottom-color: #500724e6;
}

.border-y-pink-950\/95 {
  border-top-color: #500724f2;
  border-bottom-color: #500724f2;
}

.border-y-primary {
  --tw-border-opacity: 1;
  border-top-color: hsl(var(--primary) / var(--tw-border-opacity));
  border-bottom-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-y-primary\/0 {
  border-top-color: hsl(var(--primary) / 0);
  border-bottom-color: hsl(var(--primary) / 0);
}

.border-y-primary\/10 {
  border-top-color: hsl(var(--primary) / .1);
  border-bottom-color: hsl(var(--primary) / .1);
}

.border-y-primary\/100 {
  border-top-color: hsl(var(--primary) / 1);
  border-bottom-color: hsl(var(--primary) / 1);
}

.border-y-primary\/20 {
  border-top-color: hsl(var(--primary) / .2);
  border-bottom-color: hsl(var(--primary) / .2);
}

.border-y-primary\/25 {
  border-top-color: hsl(var(--primary) / .25);
  border-bottom-color: hsl(var(--primary) / .25);
}

.border-y-primary\/30 {
  border-top-color: hsl(var(--primary) / .3);
  border-bottom-color: hsl(var(--primary) / .3);
}

.border-y-primary\/40 {
  border-top-color: hsl(var(--primary) / .4);
  border-bottom-color: hsl(var(--primary) / .4);
}

.border-y-primary\/5 {
  border-top-color: hsl(var(--primary) / .05);
  border-bottom-color: hsl(var(--primary) / .05);
}

.border-y-primary\/50 {
  border-top-color: hsl(var(--primary) / .5);
  border-bottom-color: hsl(var(--primary) / .5);
}

.border-y-primary\/60 {
  border-top-color: hsl(var(--primary) / .6);
  border-bottom-color: hsl(var(--primary) / .6);
}

.border-y-primary\/70 {
  border-top-color: hsl(var(--primary) / .7);
  border-bottom-color: hsl(var(--primary) / .7);
}

.border-y-primary\/75 {
  border-top-color: hsl(var(--primary) / .75);
  border-bottom-color: hsl(var(--primary) / .75);
}

.border-y-primary\/80 {
  border-top-color: hsl(var(--primary) / .8);
  border-bottom-color: hsl(var(--primary) / .8);
}

.border-y-primary\/90 {
  border-top-color: hsl(var(--primary) / .9);
  border-bottom-color: hsl(var(--primary) / .9);
}

.border-y-primary\/95 {
  border-top-color: hsl(var(--primary) / .95);
  border-bottom-color: hsl(var(--primary) / .95);
}

.border-y-purple-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(243 232 255 / var(--tw-border-opacity));
  border-bottom-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-y-purple-100\/0 {
  border-top-color: #f3e8ff00;
  border-bottom-color: #f3e8ff00;
}

.border-y-purple-100\/10 {
  border-top-color: #f3e8ff1a;
  border-bottom-color: #f3e8ff1a;
}

.border-y-purple-100\/100 {
  border-top-color: #f3e8ff;
  border-bottom-color: #f3e8ff;
}

.border-y-purple-100\/20 {
  border-top-color: #f3e8ff33;
  border-bottom-color: #f3e8ff33;
}

.border-y-purple-100\/25 {
  border-top-color: #f3e8ff40;
  border-bottom-color: #f3e8ff40;
}

.border-y-purple-100\/30 {
  border-top-color: #f3e8ff4d;
  border-bottom-color: #f3e8ff4d;
}

.border-y-purple-100\/40 {
  border-top-color: #f3e8ff66;
  border-bottom-color: #f3e8ff66;
}

.border-y-purple-100\/5 {
  border-top-color: #f3e8ff0d;
  border-bottom-color: #f3e8ff0d;
}

.border-y-purple-100\/50 {
  border-top-color: #f3e8ff80;
  border-bottom-color: #f3e8ff80;
}

.border-y-purple-100\/60 {
  border-top-color: #f3e8ff99;
  border-bottom-color: #f3e8ff99;
}

.border-y-purple-100\/70 {
  border-top-color: #f3e8ffb3;
  border-bottom-color: #f3e8ffb3;
}

.border-y-purple-100\/75 {
  border-top-color: #f3e8ffbf;
  border-bottom-color: #f3e8ffbf;
}

.border-y-purple-100\/80 {
  border-top-color: #f3e8ffcc;
  border-bottom-color: #f3e8ffcc;
}

.border-y-purple-100\/90 {
  border-top-color: #f3e8ffe6;
  border-bottom-color: #f3e8ffe6;
}

.border-y-purple-100\/95 {
  border-top-color: #f3e8fff2;
  border-bottom-color: #f3e8fff2;
}

.border-y-purple-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(233 213 255 / var(--tw-border-opacity));
  border-bottom-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-y-purple-200\/0 {
  border-top-color: #e9d5ff00;
  border-bottom-color: #e9d5ff00;
}

.border-y-purple-200\/10 {
  border-top-color: #e9d5ff1a;
  border-bottom-color: #e9d5ff1a;
}

.border-y-purple-200\/100 {
  border-top-color: #e9d5ff;
  border-bottom-color: #e9d5ff;
}

.border-y-purple-200\/20 {
  border-top-color: #e9d5ff33;
  border-bottom-color: #e9d5ff33;
}

.border-y-purple-200\/25 {
  border-top-color: #e9d5ff40;
  border-bottom-color: #e9d5ff40;
}

.border-y-purple-200\/30 {
  border-top-color: #e9d5ff4d;
  border-bottom-color: #e9d5ff4d;
}

.border-y-purple-200\/40 {
  border-top-color: #e9d5ff66;
  border-bottom-color: #e9d5ff66;
}

.border-y-purple-200\/5 {
  border-top-color: #e9d5ff0d;
  border-bottom-color: #e9d5ff0d;
}

.border-y-purple-200\/50 {
  border-top-color: #e9d5ff80;
  border-bottom-color: #e9d5ff80;
}

.border-y-purple-200\/60 {
  border-top-color: #e9d5ff99;
  border-bottom-color: #e9d5ff99;
}

.border-y-purple-200\/70 {
  border-top-color: #e9d5ffb3;
  border-bottom-color: #e9d5ffb3;
}

.border-y-purple-200\/75 {
  border-top-color: #e9d5ffbf;
  border-bottom-color: #e9d5ffbf;
}

.border-y-purple-200\/80 {
  border-top-color: #e9d5ffcc;
  border-bottom-color: #e9d5ffcc;
}

.border-y-purple-200\/90 {
  border-top-color: #e9d5ffe6;
  border-bottom-color: #e9d5ffe6;
}

.border-y-purple-200\/95 {
  border-top-color: #e9d5fff2;
  border-bottom-color: #e9d5fff2;
}

.border-y-purple-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(216 180 254 / var(--tw-border-opacity));
  border-bottom-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-y-purple-300\/0 {
  border-top-color: #d8b4fe00;
  border-bottom-color: #d8b4fe00;
}

.border-y-purple-300\/10 {
  border-top-color: #d8b4fe1a;
  border-bottom-color: #d8b4fe1a;
}

.border-y-purple-300\/100 {
  border-top-color: #d8b4fe;
  border-bottom-color: #d8b4fe;
}

.border-y-purple-300\/20 {
  border-top-color: #d8b4fe33;
  border-bottom-color: #d8b4fe33;
}

.border-y-purple-300\/25 {
  border-top-color: #d8b4fe40;
  border-bottom-color: #d8b4fe40;
}

.border-y-purple-300\/30 {
  border-top-color: #d8b4fe4d;
  border-bottom-color: #d8b4fe4d;
}

.border-y-purple-300\/40 {
  border-top-color: #d8b4fe66;
  border-bottom-color: #d8b4fe66;
}

.border-y-purple-300\/5 {
  border-top-color: #d8b4fe0d;
  border-bottom-color: #d8b4fe0d;
}

.border-y-purple-300\/50 {
  border-top-color: #d8b4fe80;
  border-bottom-color: #d8b4fe80;
}

.border-y-purple-300\/60 {
  border-top-color: #d8b4fe99;
  border-bottom-color: #d8b4fe99;
}

.border-y-purple-300\/70 {
  border-top-color: #d8b4feb3;
  border-bottom-color: #d8b4feb3;
}

.border-y-purple-300\/75 {
  border-top-color: #d8b4febf;
  border-bottom-color: #d8b4febf;
}

.border-y-purple-300\/80 {
  border-top-color: #d8b4fecc;
  border-bottom-color: #d8b4fecc;
}

.border-y-purple-300\/90 {
  border-top-color: #d8b4fee6;
  border-bottom-color: #d8b4fee6;
}

.border-y-purple-300\/95 {
  border-top-color: #d8b4fef2;
  border-bottom-color: #d8b4fef2;
}

.border-y-purple-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(192 132 252 / var(--tw-border-opacity));
  border-bottom-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-y-purple-400\/0 {
  border-top-color: #c084fc00;
  border-bottom-color: #c084fc00;
}

.border-y-purple-400\/10 {
  border-top-color: #c084fc1a;
  border-bottom-color: #c084fc1a;
}

.border-y-purple-400\/100 {
  border-top-color: #c084fc;
  border-bottom-color: #c084fc;
}

.border-y-purple-400\/20 {
  border-top-color: #c084fc33;
  border-bottom-color: #c084fc33;
}

.border-y-purple-400\/25 {
  border-top-color: #c084fc40;
  border-bottom-color: #c084fc40;
}

.border-y-purple-400\/30 {
  border-top-color: #c084fc4d;
  border-bottom-color: #c084fc4d;
}

.border-y-purple-400\/40 {
  border-top-color: #c084fc66;
  border-bottom-color: #c084fc66;
}

.border-y-purple-400\/5 {
  border-top-color: #c084fc0d;
  border-bottom-color: #c084fc0d;
}

.border-y-purple-400\/50 {
  border-top-color: #c084fc80;
  border-bottom-color: #c084fc80;
}

.border-y-purple-400\/60 {
  border-top-color: #c084fc99;
  border-bottom-color: #c084fc99;
}

.border-y-purple-400\/70 {
  border-top-color: #c084fcb3;
  border-bottom-color: #c084fcb3;
}

.border-y-purple-400\/75 {
  border-top-color: #c084fcbf;
  border-bottom-color: #c084fcbf;
}

.border-y-purple-400\/80 {
  border-top-color: #c084fccc;
  border-bottom-color: #c084fccc;
}

.border-y-purple-400\/90 {
  border-top-color: #c084fce6;
  border-bottom-color: #c084fce6;
}

.border-y-purple-400\/95 {
  border-top-color: #c084fcf2;
  border-bottom-color: #c084fcf2;
}

.border-y-purple-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(250 245 255 / var(--tw-border-opacity));
  border-bottom-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-y-purple-50\/0 {
  border-top-color: #faf5ff00;
  border-bottom-color: #faf5ff00;
}

.border-y-purple-50\/10 {
  border-top-color: #faf5ff1a;
  border-bottom-color: #faf5ff1a;
}

.border-y-purple-50\/100 {
  border-top-color: #faf5ff;
  border-bottom-color: #faf5ff;
}

.border-y-purple-50\/20 {
  border-top-color: #faf5ff33;
  border-bottom-color: #faf5ff33;
}

.border-y-purple-50\/25 {
  border-top-color: #faf5ff40;
  border-bottom-color: #faf5ff40;
}

.border-y-purple-50\/30 {
  border-top-color: #faf5ff4d;
  border-bottom-color: #faf5ff4d;
}

.border-y-purple-50\/40 {
  border-top-color: #faf5ff66;
  border-bottom-color: #faf5ff66;
}

.border-y-purple-50\/5 {
  border-top-color: #faf5ff0d;
  border-bottom-color: #faf5ff0d;
}

.border-y-purple-50\/50 {
  border-top-color: #faf5ff80;
  border-bottom-color: #faf5ff80;
}

.border-y-purple-50\/60 {
  border-top-color: #faf5ff99;
  border-bottom-color: #faf5ff99;
}

.border-y-purple-50\/70 {
  border-top-color: #faf5ffb3;
  border-bottom-color: #faf5ffb3;
}

.border-y-purple-50\/75 {
  border-top-color: #faf5ffbf;
  border-bottom-color: #faf5ffbf;
}

.border-y-purple-50\/80 {
  border-top-color: #faf5ffcc;
  border-bottom-color: #faf5ffcc;
}

.border-y-purple-50\/90 {
  border-top-color: #faf5ffe6;
  border-bottom-color: #faf5ffe6;
}

.border-y-purple-50\/95 {
  border-top-color: #faf5fff2;
  border-bottom-color: #faf5fff2;
}

.border-y-purple-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(168 85 247 / var(--tw-border-opacity));
  border-bottom-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-y-purple-500\/0 {
  border-top-color: #a855f700;
  border-bottom-color: #a855f700;
}

.border-y-purple-500\/10 {
  border-top-color: #a855f71a;
  border-bottom-color: #a855f71a;
}

.border-y-purple-500\/100 {
  border-top-color: #a855f7;
  border-bottom-color: #a855f7;
}

.border-y-purple-500\/20 {
  border-top-color: #a855f733;
  border-bottom-color: #a855f733;
}

.border-y-purple-500\/25 {
  border-top-color: #a855f740;
  border-bottom-color: #a855f740;
}

.border-y-purple-500\/30 {
  border-top-color: #a855f74d;
  border-bottom-color: #a855f74d;
}

.border-y-purple-500\/40 {
  border-top-color: #a855f766;
  border-bottom-color: #a855f766;
}

.border-y-purple-500\/5 {
  border-top-color: #a855f70d;
  border-bottom-color: #a855f70d;
}

.border-y-purple-500\/50 {
  border-top-color: #a855f780;
  border-bottom-color: #a855f780;
}

.border-y-purple-500\/60 {
  border-top-color: #a855f799;
  border-bottom-color: #a855f799;
}

.border-y-purple-500\/70 {
  border-top-color: #a855f7b3;
  border-bottom-color: #a855f7b3;
}

.border-y-purple-500\/75 {
  border-top-color: #a855f7bf;
  border-bottom-color: #a855f7bf;
}

.border-y-purple-500\/80 {
  border-top-color: #a855f7cc;
  border-bottom-color: #a855f7cc;
}

.border-y-purple-500\/90 {
  border-top-color: #a855f7e6;
  border-bottom-color: #a855f7e6;
}

.border-y-purple-500\/95 {
  border-top-color: #a855f7f2;
  border-bottom-color: #a855f7f2;
}

.border-y-purple-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(147 51 234 / var(--tw-border-opacity));
  border-bottom-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-y-purple-600\/0 {
  border-top-color: #9333ea00;
  border-bottom-color: #9333ea00;
}

.border-y-purple-600\/10 {
  border-top-color: #9333ea1a;
  border-bottom-color: #9333ea1a;
}

.border-y-purple-600\/100 {
  border-top-color: #9333ea;
  border-bottom-color: #9333ea;
}

.border-y-purple-600\/20 {
  border-top-color: #9333ea33;
  border-bottom-color: #9333ea33;
}

.border-y-purple-600\/25 {
  border-top-color: #9333ea40;
  border-bottom-color: #9333ea40;
}

.border-y-purple-600\/30 {
  border-top-color: #9333ea4d;
  border-bottom-color: #9333ea4d;
}

.border-y-purple-600\/40 {
  border-top-color: #9333ea66;
  border-bottom-color: #9333ea66;
}

.border-y-purple-600\/5 {
  border-top-color: #9333ea0d;
  border-bottom-color: #9333ea0d;
}

.border-y-purple-600\/50 {
  border-top-color: #9333ea80;
  border-bottom-color: #9333ea80;
}

.border-y-purple-600\/60 {
  border-top-color: #9333ea99;
  border-bottom-color: #9333ea99;
}

.border-y-purple-600\/70 {
  border-top-color: #9333eab3;
  border-bottom-color: #9333eab3;
}

.border-y-purple-600\/75 {
  border-top-color: #9333eabf;
  border-bottom-color: #9333eabf;
}

.border-y-purple-600\/80 {
  border-top-color: #9333eacc;
  border-bottom-color: #9333eacc;
}

.border-y-purple-600\/90 {
  border-top-color: #9333eae6;
  border-bottom-color: #9333eae6;
}

.border-y-purple-600\/95 {
  border-top-color: #9333eaf2;
  border-bottom-color: #9333eaf2;
}

.border-y-purple-700 {
  --tw-border-opacity: 1;
  border-top-color: rgb(126 34 206 / var(--tw-border-opacity));
  border-bottom-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-y-purple-700\/0 {
  border-top-color: #7e22ce00;
  border-bottom-color: #7e22ce00;
}

.border-y-purple-700\/10 {
  border-top-color: #7e22ce1a;
  border-bottom-color: #7e22ce1a;
}

.border-y-purple-700\/100 {
  border-top-color: #7e22ce;
  border-bottom-color: #7e22ce;
}

.border-y-purple-700\/20 {
  border-top-color: #7e22ce33;
  border-bottom-color: #7e22ce33;
}

.border-y-purple-700\/25 {
  border-top-color: #7e22ce40;
  border-bottom-color: #7e22ce40;
}

.border-y-purple-700\/30 {
  border-top-color: #7e22ce4d;
  border-bottom-color: #7e22ce4d;
}

.border-y-purple-700\/40 {
  border-top-color: #7e22ce66;
  border-bottom-color: #7e22ce66;
}

.border-y-purple-700\/5 {
  border-top-color: #7e22ce0d;
  border-bottom-color: #7e22ce0d;
}

.border-y-purple-700\/50 {
  border-top-color: #7e22ce80;
  border-bottom-color: #7e22ce80;
}

.border-y-purple-700\/60 {
  border-top-color: #7e22ce99;
  border-bottom-color: #7e22ce99;
}

.border-y-purple-700\/70 {
  border-top-color: #7e22ceb3;
  border-bottom-color: #7e22ceb3;
}

.border-y-purple-700\/75 {
  border-top-color: #7e22cebf;
  border-bottom-color: #7e22cebf;
}

.border-y-purple-700\/80 {
  border-top-color: #7e22cecc;
  border-bottom-color: #7e22cecc;
}

.border-y-purple-700\/90 {
  border-top-color: #7e22cee6;
  border-bottom-color: #7e22cee6;
}

.border-y-purple-700\/95 {
  border-top-color: #7e22cef2;
  border-bottom-color: #7e22cef2;
}

.border-y-purple-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(107 33 168 / var(--tw-border-opacity));
  border-bottom-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-y-purple-800\/0 {
  border-top-color: #6b21a800;
  border-bottom-color: #6b21a800;
}

.border-y-purple-800\/10 {
  border-top-color: #6b21a81a;
  border-bottom-color: #6b21a81a;
}

.border-y-purple-800\/100 {
  border-top-color: #6b21a8;
  border-bottom-color: #6b21a8;
}

.border-y-purple-800\/20 {
  border-top-color: #6b21a833;
  border-bottom-color: #6b21a833;
}

.border-y-purple-800\/25 {
  border-top-color: #6b21a840;
  border-bottom-color: #6b21a840;
}

.border-y-purple-800\/30 {
  border-top-color: #6b21a84d;
  border-bottom-color: #6b21a84d;
}

.border-y-purple-800\/40 {
  border-top-color: #6b21a866;
  border-bottom-color: #6b21a866;
}

.border-y-purple-800\/5 {
  border-top-color: #6b21a80d;
  border-bottom-color: #6b21a80d;
}

.border-y-purple-800\/50 {
  border-top-color: #6b21a880;
  border-bottom-color: #6b21a880;
}

.border-y-purple-800\/60 {
  border-top-color: #6b21a899;
  border-bottom-color: #6b21a899;
}

.border-y-purple-800\/70 {
  border-top-color: #6b21a8b3;
  border-bottom-color: #6b21a8b3;
}

.border-y-purple-800\/75 {
  border-top-color: #6b21a8bf;
  border-bottom-color: #6b21a8bf;
}

.border-y-purple-800\/80 {
  border-top-color: #6b21a8cc;
  border-bottom-color: #6b21a8cc;
}

.border-y-purple-800\/90 {
  border-top-color: #6b21a8e6;
  border-bottom-color: #6b21a8e6;
}

.border-y-purple-800\/95 {
  border-top-color: #6b21a8f2;
  border-bottom-color: #6b21a8f2;
}

.border-y-purple-900 {
  --tw-border-opacity: 1;
  border-top-color: rgb(88 28 135 / var(--tw-border-opacity));
  border-bottom-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-y-purple-900\/0 {
  border-top-color: #581c8700;
  border-bottom-color: #581c8700;
}

.border-y-purple-900\/10 {
  border-top-color: #581c871a;
  border-bottom-color: #581c871a;
}

.border-y-purple-900\/100 {
  border-top-color: #581c87;
  border-bottom-color: #581c87;
}

.border-y-purple-900\/20 {
  border-top-color: #581c8733;
  border-bottom-color: #581c8733;
}

.border-y-purple-900\/25 {
  border-top-color: #581c8740;
  border-bottom-color: #581c8740;
}

.border-y-purple-900\/30 {
  border-top-color: #581c874d;
  border-bottom-color: #581c874d;
}

.border-y-purple-900\/40 {
  border-top-color: #581c8766;
  border-bottom-color: #581c8766;
}

.border-y-purple-900\/5 {
  border-top-color: #581c870d;
  border-bottom-color: #581c870d;
}

.border-y-purple-900\/50 {
  border-top-color: #581c8780;
  border-bottom-color: #581c8780;
}

.border-y-purple-900\/60 {
  border-top-color: #581c8799;
  border-bottom-color: #581c8799;
}

.border-y-purple-900\/70 {
  border-top-color: #581c87b3;
  border-bottom-color: #581c87b3;
}

.border-y-purple-900\/75 {
  border-top-color: #581c87bf;
  border-bottom-color: #581c87bf;
}

.border-y-purple-900\/80 {
  border-top-color: #581c87cc;
  border-bottom-color: #581c87cc;
}

.border-y-purple-900\/90 {
  border-top-color: #581c87e6;
  border-bottom-color: #581c87e6;
}

.border-y-purple-900\/95 {
  border-top-color: #581c87f2;
  border-bottom-color: #581c87f2;
}

.border-y-purple-950 {
  --tw-border-opacity: 1;
  border-top-color: rgb(59 7 100 / var(--tw-border-opacity));
  border-bottom-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-y-purple-950\/0 {
  border-top-color: #3b076400;
  border-bottom-color: #3b076400;
}

.border-y-purple-950\/10 {
  border-top-color: #3b07641a;
  border-bottom-color: #3b07641a;
}

.border-y-purple-950\/100 {
  border-top-color: #3b0764;
  border-bottom-color: #3b0764;
}

.border-y-purple-950\/20 {
  border-top-color: #3b076433;
  border-bottom-color: #3b076433;
}

.border-y-purple-950\/25 {
  border-top-color: #3b076440;
  border-bottom-color: #3b076440;
}

.border-y-purple-950\/30 {
  border-top-color: #3b07644d;
  border-bottom-color: #3b07644d;
}

.border-y-purple-950\/40 {
  border-top-color: #3b076466;
  border-bottom-color: #3b076466;
}

.border-y-purple-950\/5 {
  border-top-color: #3b07640d;
  border-bottom-color: #3b07640d;
}

.border-y-purple-950\/50 {
  border-top-color: #3b076480;
  border-bottom-color: #3b076480;
}

.border-y-purple-950\/60 {
  border-top-color: #3b076499;
  border-bottom-color: #3b076499;
}

.border-y-purple-950\/70 {
  border-top-color: #3b0764b3;
  border-bottom-color: #3b0764b3;
}

.border-y-purple-950\/75 {
  border-top-color: #3b0764bf;
  border-bottom-color: #3b0764bf;
}

.border-y-purple-950\/80 {
  border-top-color: #3b0764cc;
  border-bottom-color: #3b0764cc;
}

.border-y-purple-950\/90 {
  border-top-color: #3b0764e6;
  border-bottom-color: #3b0764e6;
}

.border-y-purple-950\/95 {
  border-top-color: #3b0764f2;
  border-bottom-color: #3b0764f2;
}

.border-y-transparent, .border-y-transparent\/0 {
  border-top-color: #0000;
  border-bottom-color: #0000;
}

.border-y-transparent\/10 {
  border-top-color: #0000001a;
  border-bottom-color: #0000001a;
}

.border-y-transparent\/100 {
  border-top-color: #000;
  border-bottom-color: #000;
}

.border-y-transparent\/20 {
  border-top-color: #0003;
  border-bottom-color: #0003;
}

.border-y-transparent\/25 {
  border-top-color: #00000040;
  border-bottom-color: #00000040;
}

.border-y-transparent\/30 {
  border-top-color: #0000004d;
  border-bottom-color: #0000004d;
}

.border-y-transparent\/40 {
  border-top-color: #0006;
  border-bottom-color: #0006;
}

.border-y-transparent\/5 {
  border-top-color: #0000000d;
  border-bottom-color: #0000000d;
}

.border-y-transparent\/50 {
  border-top-color: #00000080;
  border-bottom-color: #00000080;
}

.border-y-transparent\/60 {
  border-top-color: #0009;
  border-bottom-color: #0009;
}

.border-y-transparent\/70 {
  border-top-color: #000000b3;
  border-bottom-color: #000000b3;
}

.border-y-transparent\/75 {
  border-top-color: #000000bf;
  border-bottom-color: #000000bf;
}

.border-y-transparent\/80 {
  border-top-color: #000c;
  border-bottom-color: #000c;
}

.border-y-transparent\/90 {
  border-top-color: #000000e6;
  border-bottom-color: #000000e6;
}

.border-y-transparent\/95 {
  border-top-color: #000000f2;
  border-bottom-color: #000000f2;
}

.border-b-amber-100 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-b-amber-100\/0 {
  border-bottom-color: #fef3c700;
}

.border-b-amber-100\/10 {
  border-bottom-color: #fef3c71a;
}

.border-b-amber-100\/100 {
  border-bottom-color: #fef3c7;
}

.border-b-amber-100\/20 {
  border-bottom-color: #fef3c733;
}

.border-b-amber-100\/25 {
  border-bottom-color: #fef3c740;
}

.border-b-amber-100\/30 {
  border-bottom-color: #fef3c74d;
}

.border-b-amber-100\/40 {
  border-bottom-color: #fef3c766;
}

.border-b-amber-100\/5 {
  border-bottom-color: #fef3c70d;
}

.border-b-amber-100\/50 {
  border-bottom-color: #fef3c780;
}

.border-b-amber-100\/60 {
  border-bottom-color: #fef3c799;
}

.border-b-amber-100\/70 {
  border-bottom-color: #fef3c7b3;
}

.border-b-amber-100\/75 {
  border-bottom-color: #fef3c7bf;
}

.border-b-amber-100\/80 {
  border-bottom-color: #fef3c7cc;
}

.border-b-amber-100\/90 {
  border-bottom-color: #fef3c7e6;
}

.border-b-amber-100\/95 {
  border-bottom-color: #fef3c7f2;
}

.border-b-amber-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-b-amber-200\/0 {
  border-bottom-color: #fde68a00;
}

.border-b-amber-200\/10 {
  border-bottom-color: #fde68a1a;
}

.border-b-amber-200\/100 {
  border-bottom-color: #fde68a;
}

.border-b-amber-200\/20 {
  border-bottom-color: #fde68a33;
}

.border-b-amber-200\/25 {
  border-bottom-color: #fde68a40;
}

.border-b-amber-200\/30 {
  border-bottom-color: #fde68a4d;
}

.border-b-amber-200\/40 {
  border-bottom-color: #fde68a66;
}

.border-b-amber-200\/5 {
  border-bottom-color: #fde68a0d;
}

.border-b-amber-200\/50 {
  border-bottom-color: #fde68a80;
}

.border-b-amber-200\/60 {
  border-bottom-color: #fde68a99;
}

.border-b-amber-200\/70 {
  border-bottom-color: #fde68ab3;
}

.border-b-amber-200\/75 {
  border-bottom-color: #fde68abf;
}

.border-b-amber-200\/80 {
  border-bottom-color: #fde68acc;
}

.border-b-amber-200\/90 {
  border-bottom-color: #fde68ae6;
}

.border-b-amber-200\/95 {
  border-bottom-color: #fde68af2;
}

.border-b-amber-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-b-amber-300\/0 {
  border-bottom-color: #fcd34d00;
}

.border-b-amber-300\/10 {
  border-bottom-color: #fcd34d1a;
}

.border-b-amber-300\/100 {
  border-bottom-color: #fcd34d;
}

.border-b-amber-300\/20 {
  border-bottom-color: #fcd34d33;
}

.border-b-amber-300\/25 {
  border-bottom-color: #fcd34d40;
}

.border-b-amber-300\/30 {
  border-bottom-color: #fcd34d4d;
}

.border-b-amber-300\/40 {
  border-bottom-color: #fcd34d66;
}

.border-b-amber-300\/5 {
  border-bottom-color: #fcd34d0d;
}

.border-b-amber-300\/50 {
  border-bottom-color: #fcd34d80;
}

.border-b-amber-300\/60 {
  border-bottom-color: #fcd34d99;
}

.border-b-amber-300\/70 {
  border-bottom-color: #fcd34db3;
}

.border-b-amber-300\/75 {
  border-bottom-color: #fcd34dbf;
}

.border-b-amber-300\/80 {
  border-bottom-color: #fcd34dcc;
}

.border-b-amber-300\/90 {
  border-bottom-color: #fcd34de6;
}

.border-b-amber-300\/95 {
  border-bottom-color: #fcd34df2;
}

.border-b-amber-400 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-b-amber-400\/0 {
  border-bottom-color: #fbbf2400;
}

.border-b-amber-400\/10 {
  border-bottom-color: #fbbf241a;
}

.border-b-amber-400\/100 {
  border-bottom-color: #fbbf24;
}

.border-b-amber-400\/20 {
  border-bottom-color: #fbbf2433;
}

.border-b-amber-400\/25 {
  border-bottom-color: #fbbf2440;
}

.border-b-amber-400\/30 {
  border-bottom-color: #fbbf244d;
}

.border-b-amber-400\/40 {
  border-bottom-color: #fbbf2466;
}

.border-b-amber-400\/5 {
  border-bottom-color: #fbbf240d;
}

.border-b-amber-400\/50 {
  border-bottom-color: #fbbf2480;
}

.border-b-amber-400\/60 {
  border-bottom-color: #fbbf2499;
}

.border-b-amber-400\/70 {
  border-bottom-color: #fbbf24b3;
}

.border-b-amber-400\/75 {
  border-bottom-color: #fbbf24bf;
}

.border-b-amber-400\/80 {
  border-bottom-color: #fbbf24cc;
}

.border-b-amber-400\/90 {
  border-bottom-color: #fbbf24e6;
}

.border-b-amber-400\/95 {
  border-bottom-color: #fbbf24f2;
}

.border-b-amber-50 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-b-amber-50\/0 {
  border-bottom-color: #fffbeb00;
}

.border-b-amber-50\/10 {
  border-bottom-color: #fffbeb1a;
}

.border-b-amber-50\/100 {
  border-bottom-color: #fffbeb;
}

.border-b-amber-50\/20 {
  border-bottom-color: #fffbeb33;
}

.border-b-amber-50\/25 {
  border-bottom-color: #fffbeb40;
}

.border-b-amber-50\/30 {
  border-bottom-color: #fffbeb4d;
}

.border-b-amber-50\/40 {
  border-bottom-color: #fffbeb66;
}

.border-b-amber-50\/5 {
  border-bottom-color: #fffbeb0d;
}

.border-b-amber-50\/50 {
  border-bottom-color: #fffbeb80;
}

.border-b-amber-50\/60 {
  border-bottom-color: #fffbeb99;
}

.border-b-amber-50\/70 {
  border-bottom-color: #fffbebb3;
}

.border-b-amber-50\/75 {
  border-bottom-color: #fffbebbf;
}

.border-b-amber-50\/80 {
  border-bottom-color: #fffbebcc;
}

.border-b-amber-50\/90 {
  border-bottom-color: #fffbebe6;
}

.border-b-amber-50\/95 {
  border-bottom-color: #fffbebf2;
}

.border-b-amber-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-b-amber-500\/0 {
  border-bottom-color: #f59e0b00;
}

.border-b-amber-500\/10 {
  border-bottom-color: #f59e0b1a;
}

.border-b-amber-500\/100 {
  border-bottom-color: #f59e0b;
}

.border-b-amber-500\/20 {
  border-bottom-color: #f59e0b33;
}

.border-b-amber-500\/25 {
  border-bottom-color: #f59e0b40;
}

.border-b-amber-500\/30 {
  border-bottom-color: #f59e0b4d;
}

.border-b-amber-500\/40 {
  border-bottom-color: #f59e0b66;
}

.border-b-amber-500\/5 {
  border-bottom-color: #f59e0b0d;
}

.border-b-amber-500\/50 {
  border-bottom-color: #f59e0b80;
}

.border-b-amber-500\/60 {
  border-bottom-color: #f59e0b99;
}

.border-b-amber-500\/70 {
  border-bottom-color: #f59e0bb3;
}

.border-b-amber-500\/75 {
  border-bottom-color: #f59e0bbf;
}

.border-b-amber-500\/80 {
  border-bottom-color: #f59e0bcc;
}

.border-b-amber-500\/90 {
  border-bottom-color: #f59e0be6;
}

.border-b-amber-500\/95 {
  border-bottom-color: #f59e0bf2;
}

.border-b-amber-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-b-amber-600\/0 {
  border-bottom-color: #d9770600;
}

.border-b-amber-600\/10 {
  border-bottom-color: #d977061a;
}

.border-b-amber-600\/100 {
  border-bottom-color: #d97706;
}

.border-b-amber-600\/20 {
  border-bottom-color: #d9770633;
}

.border-b-amber-600\/25 {
  border-bottom-color: #d9770640;
}

.border-b-amber-600\/30 {
  border-bottom-color: #d977064d;
}

.border-b-amber-600\/40 {
  border-bottom-color: #d9770666;
}

.border-b-amber-600\/5 {
  border-bottom-color: #d977060d;
}

.border-b-amber-600\/50 {
  border-bottom-color: #d9770680;
}

.border-b-amber-600\/60 {
  border-bottom-color: #d9770699;
}

.border-b-amber-600\/70 {
  border-bottom-color: #d97706b3;
}

.border-b-amber-600\/75 {
  border-bottom-color: #d97706bf;
}

.border-b-amber-600\/80 {
  border-bottom-color: #d97706cc;
}

.border-b-amber-600\/90 {
  border-bottom-color: #d97706e6;
}

.border-b-amber-600\/95 {
  border-bottom-color: #d97706f2;
}

.border-b-amber-700 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-b-amber-700\/0 {
  border-bottom-color: #b4530900;
}

.border-b-amber-700\/10 {
  border-bottom-color: #b453091a;
}

.border-b-amber-700\/100 {
  border-bottom-color: #b45309;
}

.border-b-amber-700\/20 {
  border-bottom-color: #b4530933;
}

.border-b-amber-700\/25 {
  border-bottom-color: #b4530940;
}

.border-b-amber-700\/30 {
  border-bottom-color: #b453094d;
}

.border-b-amber-700\/40 {
  border-bottom-color: #b4530966;
}

.border-b-amber-700\/5 {
  border-bottom-color: #b453090d;
}

.border-b-amber-700\/50 {
  border-bottom-color: #b4530980;
}

.border-b-amber-700\/60 {
  border-bottom-color: #b4530999;
}

.border-b-amber-700\/70 {
  border-bottom-color: #b45309b3;
}

.border-b-amber-700\/75 {
  border-bottom-color: #b45309bf;
}

.border-b-amber-700\/80 {
  border-bottom-color: #b45309cc;
}

.border-b-amber-700\/90 {
  border-bottom-color: #b45309e6;
}

.border-b-amber-700\/95 {
  border-bottom-color: #b45309f2;
}

.border-b-amber-800 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-b-amber-800\/0 {
  border-bottom-color: #92400e00;
}

.border-b-amber-800\/10 {
  border-bottom-color: #92400e1a;
}

.border-b-amber-800\/100 {
  border-bottom-color: #92400e;
}

.border-b-amber-800\/20 {
  border-bottom-color: #92400e33;
}

.border-b-amber-800\/25 {
  border-bottom-color: #92400e40;
}

.border-b-amber-800\/30 {
  border-bottom-color: #92400e4d;
}

.border-b-amber-800\/40 {
  border-bottom-color: #92400e66;
}

.border-b-amber-800\/5 {
  border-bottom-color: #92400e0d;
}

.border-b-amber-800\/50 {
  border-bottom-color: #92400e80;
}

.border-b-amber-800\/60 {
  border-bottom-color: #92400e99;
}

.border-b-amber-800\/70 {
  border-bottom-color: #92400eb3;
}

.border-b-amber-800\/75 {
  border-bottom-color: #92400ebf;
}

.border-b-amber-800\/80 {
  border-bottom-color: #92400ecc;
}

.border-b-amber-800\/90 {
  border-bottom-color: #92400ee6;
}

.border-b-amber-800\/95 {
  border-bottom-color: #92400ef2;
}

.border-b-amber-900 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-b-amber-900\/0 {
  border-bottom-color: #78350f00;
}

.border-b-amber-900\/10 {
  border-bottom-color: #78350f1a;
}

.border-b-amber-900\/100 {
  border-bottom-color: #78350f;
}

.border-b-amber-900\/20 {
  border-bottom-color: #78350f33;
}

.border-b-amber-900\/25 {
  border-bottom-color: #78350f40;
}

.border-b-amber-900\/30 {
  border-bottom-color: #78350f4d;
}

.border-b-amber-900\/40 {
  border-bottom-color: #78350f66;
}

.border-b-amber-900\/5 {
  border-bottom-color: #78350f0d;
}

.border-b-amber-900\/50 {
  border-bottom-color: #78350f80;
}

.border-b-amber-900\/60 {
  border-bottom-color: #78350f99;
}

.border-b-amber-900\/70 {
  border-bottom-color: #78350fb3;
}

.border-b-amber-900\/75 {
  border-bottom-color: #78350fbf;
}

.border-b-amber-900\/80 {
  border-bottom-color: #78350fcc;
}

.border-b-amber-900\/90 {
  border-bottom-color: #78350fe6;
}

.border-b-amber-900\/95 {
  border-bottom-color: #78350ff2;
}

.border-b-amber-950 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-b-amber-950\/0 {
  border-bottom-color: #451a0300;
}

.border-b-amber-950\/10 {
  border-bottom-color: #451a031a;
}

.border-b-amber-950\/100 {
  border-bottom-color: #451a03;
}

.border-b-amber-950\/20 {
  border-bottom-color: #451a0333;
}

.border-b-amber-950\/25 {
  border-bottom-color: #451a0340;
}

.border-b-amber-950\/30 {
  border-bottom-color: #451a034d;
}

.border-b-amber-950\/40 {
  border-bottom-color: #451a0366;
}

.border-b-amber-950\/5 {
  border-bottom-color: #451a030d;
}

.border-b-amber-950\/50 {
  border-bottom-color: #451a0380;
}

.border-b-amber-950\/60 {
  border-bottom-color: #451a0399;
}

.border-b-amber-950\/70 {
  border-bottom-color: #451a03b3;
}

.border-b-amber-950\/75 {
  border-bottom-color: #451a03bf;
}

.border-b-amber-950\/80 {
  border-bottom-color: #451a03cc;
}

.border-b-amber-950\/90 {
  border-bottom-color: #451a03e6;
}

.border-b-amber-950\/95 {
  border-bottom-color: #451a03f2;
}

.border-b-emerald-100 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-b-emerald-100\/0 {
  border-bottom-color: #d1fae500;
}

.border-b-emerald-100\/10 {
  border-bottom-color: #d1fae51a;
}

.border-b-emerald-100\/100 {
  border-bottom-color: #d1fae5;
}

.border-b-emerald-100\/20 {
  border-bottom-color: #d1fae533;
}

.border-b-emerald-100\/25 {
  border-bottom-color: #d1fae540;
}

.border-b-emerald-100\/30 {
  border-bottom-color: #d1fae54d;
}

.border-b-emerald-100\/40 {
  border-bottom-color: #d1fae566;
}

.border-b-emerald-100\/5 {
  border-bottom-color: #d1fae50d;
}

.border-b-emerald-100\/50 {
  border-bottom-color: #d1fae580;
}

.border-b-emerald-100\/60 {
  border-bottom-color: #d1fae599;
}

.border-b-emerald-100\/70 {
  border-bottom-color: #d1fae5b3;
}

.border-b-emerald-100\/75 {
  border-bottom-color: #d1fae5bf;
}

.border-b-emerald-100\/80 {
  border-bottom-color: #d1fae5cc;
}

.border-b-emerald-100\/90 {
  border-bottom-color: #d1fae5e6;
}

.border-b-emerald-100\/95 {
  border-bottom-color: #d1fae5f2;
}

.border-b-emerald-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-b-emerald-200\/0 {
  border-bottom-color: #a7f3d000;
}

.border-b-emerald-200\/10 {
  border-bottom-color: #a7f3d01a;
}

.border-b-emerald-200\/100 {
  border-bottom-color: #a7f3d0;
}

.border-b-emerald-200\/20 {
  border-bottom-color: #a7f3d033;
}

.border-b-emerald-200\/25 {
  border-bottom-color: #a7f3d040;
}

.border-b-emerald-200\/30 {
  border-bottom-color: #a7f3d04d;
}

.border-b-emerald-200\/40 {
  border-bottom-color: #a7f3d066;
}

.border-b-emerald-200\/5 {
  border-bottom-color: #a7f3d00d;
}

.border-b-emerald-200\/50 {
  border-bottom-color: #a7f3d080;
}

.border-b-emerald-200\/60 {
  border-bottom-color: #a7f3d099;
}

.border-b-emerald-200\/70 {
  border-bottom-color: #a7f3d0b3;
}

.border-b-emerald-200\/75 {
  border-bottom-color: #a7f3d0bf;
}

.border-b-emerald-200\/80 {
  border-bottom-color: #a7f3d0cc;
}

.border-b-emerald-200\/90 {
  border-bottom-color: #a7f3d0e6;
}

.border-b-emerald-200\/95 {
  border-bottom-color: #a7f3d0f2;
}

.border-b-emerald-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-b-emerald-300\/0 {
  border-bottom-color: #6ee7b700;
}

.border-b-emerald-300\/10 {
  border-bottom-color: #6ee7b71a;
}

.border-b-emerald-300\/100 {
  border-bottom-color: #6ee7b7;
}

.border-b-emerald-300\/20 {
  border-bottom-color: #6ee7b733;
}

.border-b-emerald-300\/25 {
  border-bottom-color: #6ee7b740;
}

.border-b-emerald-300\/30 {
  border-bottom-color: #6ee7b74d;
}

.border-b-emerald-300\/40 {
  border-bottom-color: #6ee7b766;
}

.border-b-emerald-300\/5 {
  border-bottom-color: #6ee7b70d;
}

.border-b-emerald-300\/50 {
  border-bottom-color: #6ee7b780;
}

.border-b-emerald-300\/60 {
  border-bottom-color: #6ee7b799;
}

.border-b-emerald-300\/70 {
  border-bottom-color: #6ee7b7b3;
}

.border-b-emerald-300\/75 {
  border-bottom-color: #6ee7b7bf;
}

.border-b-emerald-300\/80 {
  border-bottom-color: #6ee7b7cc;
}

.border-b-emerald-300\/90 {
  border-bottom-color: #6ee7b7e6;
}

.border-b-emerald-300\/95 {
  border-bottom-color: #6ee7b7f2;
}

.border-b-emerald-400 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-b-emerald-400\/0 {
  border-bottom-color: #34d39900;
}

.border-b-emerald-400\/10 {
  border-bottom-color: #34d3991a;
}

.border-b-emerald-400\/100 {
  border-bottom-color: #34d399;
}

.border-b-emerald-400\/20 {
  border-bottom-color: #34d39933;
}

.border-b-emerald-400\/25 {
  border-bottom-color: #34d39940;
}

.border-b-emerald-400\/30 {
  border-bottom-color: #34d3994d;
}

.border-b-emerald-400\/40 {
  border-bottom-color: #34d39966;
}

.border-b-emerald-400\/5 {
  border-bottom-color: #34d3990d;
}

.border-b-emerald-400\/50 {
  border-bottom-color: #34d39980;
}

.border-b-emerald-400\/60 {
  border-bottom-color: #34d39999;
}

.border-b-emerald-400\/70 {
  border-bottom-color: #34d399b3;
}

.border-b-emerald-400\/75 {
  border-bottom-color: #34d399bf;
}

.border-b-emerald-400\/80 {
  border-bottom-color: #34d399cc;
}

.border-b-emerald-400\/90 {
  border-bottom-color: #34d399e6;
}

.border-b-emerald-400\/95 {
  border-bottom-color: #34d399f2;
}

.border-b-emerald-50 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-b-emerald-50\/0 {
  border-bottom-color: #ecfdf500;
}

.border-b-emerald-50\/10 {
  border-bottom-color: #ecfdf51a;
}

.border-b-emerald-50\/100 {
  border-bottom-color: #ecfdf5;
}

.border-b-emerald-50\/20 {
  border-bottom-color: #ecfdf533;
}

.border-b-emerald-50\/25 {
  border-bottom-color: #ecfdf540;
}

.border-b-emerald-50\/30 {
  border-bottom-color: #ecfdf54d;
}

.border-b-emerald-50\/40 {
  border-bottom-color: #ecfdf566;
}

.border-b-emerald-50\/5 {
  border-bottom-color: #ecfdf50d;
}

.border-b-emerald-50\/50 {
  border-bottom-color: #ecfdf580;
}

.border-b-emerald-50\/60 {
  border-bottom-color: #ecfdf599;
}

.border-b-emerald-50\/70 {
  border-bottom-color: #ecfdf5b3;
}

.border-b-emerald-50\/75 {
  border-bottom-color: #ecfdf5bf;
}

.border-b-emerald-50\/80 {
  border-bottom-color: #ecfdf5cc;
}

.border-b-emerald-50\/90 {
  border-bottom-color: #ecfdf5e6;
}

.border-b-emerald-50\/95 {
  border-bottom-color: #ecfdf5f2;
}

.border-b-emerald-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-b-emerald-500\/0 {
  border-bottom-color: #10b98100;
}

.border-b-emerald-500\/10 {
  border-bottom-color: #10b9811a;
}

.border-b-emerald-500\/100 {
  border-bottom-color: #10b981;
}

.border-b-emerald-500\/20 {
  border-bottom-color: #10b98133;
}

.border-b-emerald-500\/25 {
  border-bottom-color: #10b98140;
}

.border-b-emerald-500\/30 {
  border-bottom-color: #10b9814d;
}

.border-b-emerald-500\/40 {
  border-bottom-color: #10b98166;
}

.border-b-emerald-500\/5 {
  border-bottom-color: #10b9810d;
}

.border-b-emerald-500\/50 {
  border-bottom-color: #10b98180;
}

.border-b-emerald-500\/60 {
  border-bottom-color: #10b98199;
}

.border-b-emerald-500\/70 {
  border-bottom-color: #10b981b3;
}

.border-b-emerald-500\/75 {
  border-bottom-color: #10b981bf;
}

.border-b-emerald-500\/80 {
  border-bottom-color: #10b981cc;
}

.border-b-emerald-500\/90 {
  border-bottom-color: #10b981e6;
}

.border-b-emerald-500\/95 {
  border-bottom-color: #10b981f2;
}

.border-b-emerald-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-b-emerald-600\/0 {
  border-bottom-color: #05966900;
}

.border-b-emerald-600\/10 {
  border-bottom-color: #0596691a;
}

.border-b-emerald-600\/100 {
  border-bottom-color: #059669;
}

.border-b-emerald-600\/20 {
  border-bottom-color: #05966933;
}

.border-b-emerald-600\/25 {
  border-bottom-color: #05966940;
}

.border-b-emerald-600\/30 {
  border-bottom-color: #0596694d;
}

.border-b-emerald-600\/40 {
  border-bottom-color: #05966966;
}

.border-b-emerald-600\/5 {
  border-bottom-color: #0596690d;
}

.border-b-emerald-600\/50 {
  border-bottom-color: #05966980;
}

.border-b-emerald-600\/60 {
  border-bottom-color: #05966999;
}

.border-b-emerald-600\/70 {
  border-bottom-color: #059669b3;
}

.border-b-emerald-600\/75 {
  border-bottom-color: #059669bf;
}

.border-b-emerald-600\/80 {
  border-bottom-color: #059669cc;
}

.border-b-emerald-600\/90 {
  border-bottom-color: #059669e6;
}

.border-b-emerald-600\/95 {
  border-bottom-color: #059669f2;
}

.border-b-emerald-700 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-b-emerald-700\/0 {
  border-bottom-color: #04785700;
}

.border-b-emerald-700\/10 {
  border-bottom-color: #0478571a;
}

.border-b-emerald-700\/100 {
  border-bottom-color: #047857;
}

.border-b-emerald-700\/20 {
  border-bottom-color: #04785733;
}

.border-b-emerald-700\/25 {
  border-bottom-color: #04785740;
}

.border-b-emerald-700\/30 {
  border-bottom-color: #0478574d;
}

.border-b-emerald-700\/40 {
  border-bottom-color: #04785766;
}

.border-b-emerald-700\/5 {
  border-bottom-color: #0478570d;
}

.border-b-emerald-700\/50 {
  border-bottom-color: #04785780;
}

.border-b-emerald-700\/60 {
  border-bottom-color: #04785799;
}

.border-b-emerald-700\/70 {
  border-bottom-color: #047857b3;
}

.border-b-emerald-700\/75 {
  border-bottom-color: #047857bf;
}

.border-b-emerald-700\/80 {
  border-bottom-color: #047857cc;
}

.border-b-emerald-700\/90 {
  border-bottom-color: #047857e6;
}

.border-b-emerald-700\/95 {
  border-bottom-color: #047857f2;
}

.border-b-emerald-800 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-b-emerald-800\/0 {
  border-bottom-color: #065f4600;
}

.border-b-emerald-800\/10 {
  border-bottom-color: #065f461a;
}

.border-b-emerald-800\/100 {
  border-bottom-color: #065f46;
}

.border-b-emerald-800\/20 {
  border-bottom-color: #065f4633;
}

.border-b-emerald-800\/25 {
  border-bottom-color: #065f4640;
}

.border-b-emerald-800\/30 {
  border-bottom-color: #065f464d;
}

.border-b-emerald-800\/40 {
  border-bottom-color: #065f4666;
}

.border-b-emerald-800\/5 {
  border-bottom-color: #065f460d;
}

.border-b-emerald-800\/50 {
  border-bottom-color: #065f4680;
}

.border-b-emerald-800\/60 {
  border-bottom-color: #065f4699;
}

.border-b-emerald-800\/70 {
  border-bottom-color: #065f46b3;
}

.border-b-emerald-800\/75 {
  border-bottom-color: #065f46bf;
}

.border-b-emerald-800\/80 {
  border-bottom-color: #065f46cc;
}

.border-b-emerald-800\/90 {
  border-bottom-color: #065f46e6;
}

.border-b-emerald-800\/95 {
  border-bottom-color: #065f46f2;
}

.border-b-emerald-900 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-b-emerald-900\/0 {
  border-bottom-color: #064e3b00;
}

.border-b-emerald-900\/10 {
  border-bottom-color: #064e3b1a;
}

.border-b-emerald-900\/100 {
  border-bottom-color: #064e3b;
}

.border-b-emerald-900\/20 {
  border-bottom-color: #064e3b33;
}

.border-b-emerald-900\/25 {
  border-bottom-color: #064e3b40;
}

.border-b-emerald-900\/30 {
  border-bottom-color: #064e3b4d;
}

.border-b-emerald-900\/40 {
  border-bottom-color: #064e3b66;
}

.border-b-emerald-900\/5 {
  border-bottom-color: #064e3b0d;
}

.border-b-emerald-900\/50 {
  border-bottom-color: #064e3b80;
}

.border-b-emerald-900\/60 {
  border-bottom-color: #064e3b99;
}

.border-b-emerald-900\/70 {
  border-bottom-color: #064e3bb3;
}

.border-b-emerald-900\/75 {
  border-bottom-color: #064e3bbf;
}

.border-b-emerald-900\/80 {
  border-bottom-color: #064e3bcc;
}

.border-b-emerald-900\/90 {
  border-bottom-color: #064e3be6;
}

.border-b-emerald-900\/95 {
  border-bottom-color: #064e3bf2;
}

.border-b-emerald-950 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-b-emerald-950\/0 {
  border-bottom-color: #022c2200;
}

.border-b-emerald-950\/10 {
  border-bottom-color: #022c221a;
}

.border-b-emerald-950\/100 {
  border-bottom-color: #022c22;
}

.border-b-emerald-950\/20 {
  border-bottom-color: #022c2233;
}

.border-b-emerald-950\/25 {
  border-bottom-color: #022c2240;
}

.border-b-emerald-950\/30 {
  border-bottom-color: #022c224d;
}

.border-b-emerald-950\/40 {
  border-bottom-color: #022c2266;
}

.border-b-emerald-950\/5 {
  border-bottom-color: #022c220d;
}

.border-b-emerald-950\/50 {
  border-bottom-color: #022c2280;
}

.border-b-emerald-950\/60 {
  border-bottom-color: #022c2299;
}

.border-b-emerald-950\/70 {
  border-bottom-color: #022c22b3;
}

.border-b-emerald-950\/75 {
  border-bottom-color: #022c22bf;
}

.border-b-emerald-950\/80 {
  border-bottom-color: #022c22cc;
}

.border-b-emerald-950\/90 {
  border-bottom-color: #022c22e6;
}

.border-b-emerald-950\/95 {
  border-bottom-color: #022c22f2;
}

.border-b-lime-100 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-b-lime-100\/0 {
  border-bottom-color: #ecfccb00;
}

.border-b-lime-100\/10 {
  border-bottom-color: #ecfccb1a;
}

.border-b-lime-100\/100 {
  border-bottom-color: #ecfccb;
}

.border-b-lime-100\/20 {
  border-bottom-color: #ecfccb33;
}

.border-b-lime-100\/25 {
  border-bottom-color: #ecfccb40;
}

.border-b-lime-100\/30 {
  border-bottom-color: #ecfccb4d;
}

.border-b-lime-100\/40 {
  border-bottom-color: #ecfccb66;
}

.border-b-lime-100\/5 {
  border-bottom-color: #ecfccb0d;
}

.border-b-lime-100\/50 {
  border-bottom-color: #ecfccb80;
}

.border-b-lime-100\/60 {
  border-bottom-color: #ecfccb99;
}

.border-b-lime-100\/70 {
  border-bottom-color: #ecfccbb3;
}

.border-b-lime-100\/75 {
  border-bottom-color: #ecfccbbf;
}

.border-b-lime-100\/80 {
  border-bottom-color: #ecfccbcc;
}

.border-b-lime-100\/90 {
  border-bottom-color: #ecfccbe6;
}

.border-b-lime-100\/95 {
  border-bottom-color: #ecfccbf2;
}

.border-b-lime-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-b-lime-200\/0 {
  border-bottom-color: #d9f99d00;
}

.border-b-lime-200\/10 {
  border-bottom-color: #d9f99d1a;
}

.border-b-lime-200\/100 {
  border-bottom-color: #d9f99d;
}

.border-b-lime-200\/20 {
  border-bottom-color: #d9f99d33;
}

.border-b-lime-200\/25 {
  border-bottom-color: #d9f99d40;
}

.border-b-lime-200\/30 {
  border-bottom-color: #d9f99d4d;
}

.border-b-lime-200\/40 {
  border-bottom-color: #d9f99d66;
}

.border-b-lime-200\/5 {
  border-bottom-color: #d9f99d0d;
}

.border-b-lime-200\/50 {
  border-bottom-color: #d9f99d80;
}

.border-b-lime-200\/60 {
  border-bottom-color: #d9f99d99;
}

.border-b-lime-200\/70 {
  border-bottom-color: #d9f99db3;
}

.border-b-lime-200\/75 {
  border-bottom-color: #d9f99dbf;
}

.border-b-lime-200\/80 {
  border-bottom-color: #d9f99dcc;
}

.border-b-lime-200\/90 {
  border-bottom-color: #d9f99de6;
}

.border-b-lime-200\/95 {
  border-bottom-color: #d9f99df2;
}

.border-b-lime-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-b-lime-300\/0 {
  border-bottom-color: #bef26400;
}

.border-b-lime-300\/10 {
  border-bottom-color: #bef2641a;
}

.border-b-lime-300\/100 {
  border-bottom-color: #bef264;
}

.border-b-lime-300\/20 {
  border-bottom-color: #bef26433;
}

.border-b-lime-300\/25 {
  border-bottom-color: #bef26440;
}

.border-b-lime-300\/30 {
  border-bottom-color: #bef2644d;
}

.border-b-lime-300\/40 {
  border-bottom-color: #bef26466;
}

.border-b-lime-300\/5 {
  border-bottom-color: #bef2640d;
}

.border-b-lime-300\/50 {
  border-bottom-color: #bef26480;
}

.border-b-lime-300\/60 {
  border-bottom-color: #bef26499;
}

.border-b-lime-300\/70 {
  border-bottom-color: #bef264b3;
}

.border-b-lime-300\/75 {
  border-bottom-color: #bef264bf;
}

.border-b-lime-300\/80 {
  border-bottom-color: #bef264cc;
}

.border-b-lime-300\/90 {
  border-bottom-color: #bef264e6;
}

.border-b-lime-300\/95 {
  border-bottom-color: #bef264f2;
}

.border-b-lime-400 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-b-lime-400\/0 {
  border-bottom-color: #a3e63500;
}

.border-b-lime-400\/10 {
  border-bottom-color: #a3e6351a;
}

.border-b-lime-400\/100 {
  border-bottom-color: #a3e635;
}

.border-b-lime-400\/20 {
  border-bottom-color: #a3e63533;
}

.border-b-lime-400\/25 {
  border-bottom-color: #a3e63540;
}

.border-b-lime-400\/30 {
  border-bottom-color: #a3e6354d;
}

.border-b-lime-400\/40 {
  border-bottom-color: #a3e63566;
}

.border-b-lime-400\/5 {
  border-bottom-color: #a3e6350d;
}

.border-b-lime-400\/50 {
  border-bottom-color: #a3e63580;
}

.border-b-lime-400\/60 {
  border-bottom-color: #a3e63599;
}

.border-b-lime-400\/70 {
  border-bottom-color: #a3e635b3;
}

.border-b-lime-400\/75 {
  border-bottom-color: #a3e635bf;
}

.border-b-lime-400\/80 {
  border-bottom-color: #a3e635cc;
}

.border-b-lime-400\/90 {
  border-bottom-color: #a3e635e6;
}

.border-b-lime-400\/95 {
  border-bottom-color: #a3e635f2;
}

.border-b-lime-50 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-b-lime-50\/0 {
  border-bottom-color: #f7fee700;
}

.border-b-lime-50\/10 {
  border-bottom-color: #f7fee71a;
}

.border-b-lime-50\/100 {
  border-bottom-color: #f7fee7;
}

.border-b-lime-50\/20 {
  border-bottom-color: #f7fee733;
}

.border-b-lime-50\/25 {
  border-bottom-color: #f7fee740;
}

.border-b-lime-50\/30 {
  border-bottom-color: #f7fee74d;
}

.border-b-lime-50\/40 {
  border-bottom-color: #f7fee766;
}

.border-b-lime-50\/5 {
  border-bottom-color: #f7fee70d;
}

.border-b-lime-50\/50 {
  border-bottom-color: #f7fee780;
}

.border-b-lime-50\/60 {
  border-bottom-color: #f7fee799;
}

.border-b-lime-50\/70 {
  border-bottom-color: #f7fee7b3;
}

.border-b-lime-50\/75 {
  border-bottom-color: #f7fee7bf;
}

.border-b-lime-50\/80 {
  border-bottom-color: #f7fee7cc;
}

.border-b-lime-50\/90 {
  border-bottom-color: #f7fee7e6;
}

.border-b-lime-50\/95 {
  border-bottom-color: #f7fee7f2;
}

.border-b-lime-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-b-lime-500\/0 {
  border-bottom-color: #84cc1600;
}

.border-b-lime-500\/10 {
  border-bottom-color: #84cc161a;
}

.border-b-lime-500\/100 {
  border-bottom-color: #84cc16;
}

.border-b-lime-500\/20 {
  border-bottom-color: #84cc1633;
}

.border-b-lime-500\/25 {
  border-bottom-color: #84cc1640;
}

.border-b-lime-500\/30 {
  border-bottom-color: #84cc164d;
}

.border-b-lime-500\/40 {
  border-bottom-color: #84cc1666;
}

.border-b-lime-500\/5 {
  border-bottom-color: #84cc160d;
}

.border-b-lime-500\/50 {
  border-bottom-color: #84cc1680;
}

.border-b-lime-500\/60 {
  border-bottom-color: #84cc1699;
}

.border-b-lime-500\/70 {
  border-bottom-color: #84cc16b3;
}

.border-b-lime-500\/75 {
  border-bottom-color: #84cc16bf;
}

.border-b-lime-500\/80 {
  border-bottom-color: #84cc16cc;
}

.border-b-lime-500\/90 {
  border-bottom-color: #84cc16e6;
}

.border-b-lime-500\/95 {
  border-bottom-color: #84cc16f2;
}

.border-b-lime-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-b-lime-600\/0 {
  border-bottom-color: #65a30d00;
}

.border-b-lime-600\/10 {
  border-bottom-color: #65a30d1a;
}

.border-b-lime-600\/100 {
  border-bottom-color: #65a30d;
}

.border-b-lime-600\/20 {
  border-bottom-color: #65a30d33;
}

.border-b-lime-600\/25 {
  border-bottom-color: #65a30d40;
}

.border-b-lime-600\/30 {
  border-bottom-color: #65a30d4d;
}

.border-b-lime-600\/40 {
  border-bottom-color: #65a30d66;
}

.border-b-lime-600\/5 {
  border-bottom-color: #65a30d0d;
}

.border-b-lime-600\/50 {
  border-bottom-color: #65a30d80;
}

.border-b-lime-600\/60 {
  border-bottom-color: #65a30d99;
}

.border-b-lime-600\/70 {
  border-bottom-color: #65a30db3;
}

.border-b-lime-600\/75 {
  border-bottom-color: #65a30dbf;
}

.border-b-lime-600\/80 {
  border-bottom-color: #65a30dcc;
}

.border-b-lime-600\/90 {
  border-bottom-color: #65a30de6;
}

.border-b-lime-600\/95 {
  border-bottom-color: #65a30df2;
}

.border-b-lime-700 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-b-lime-700\/0 {
  border-bottom-color: #4d7c0f00;
}

.border-b-lime-700\/10 {
  border-bottom-color: #4d7c0f1a;
}

.border-b-lime-700\/100 {
  border-bottom-color: #4d7c0f;
}

.border-b-lime-700\/20 {
  border-bottom-color: #4d7c0f33;
}

.border-b-lime-700\/25 {
  border-bottom-color: #4d7c0f40;
}

.border-b-lime-700\/30 {
  border-bottom-color: #4d7c0f4d;
}

.border-b-lime-700\/40 {
  border-bottom-color: #4d7c0f66;
}

.border-b-lime-700\/5 {
  border-bottom-color: #4d7c0f0d;
}

.border-b-lime-700\/50 {
  border-bottom-color: #4d7c0f80;
}

.border-b-lime-700\/60 {
  border-bottom-color: #4d7c0f99;
}

.border-b-lime-700\/70 {
  border-bottom-color: #4d7c0fb3;
}

.border-b-lime-700\/75 {
  border-bottom-color: #4d7c0fbf;
}

.border-b-lime-700\/80 {
  border-bottom-color: #4d7c0fcc;
}

.border-b-lime-700\/90 {
  border-bottom-color: #4d7c0fe6;
}

.border-b-lime-700\/95 {
  border-bottom-color: #4d7c0ff2;
}

.border-b-lime-800 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-b-lime-800\/0 {
  border-bottom-color: #3f621200;
}

.border-b-lime-800\/10 {
  border-bottom-color: #3f62121a;
}

.border-b-lime-800\/100 {
  border-bottom-color: #3f6212;
}

.border-b-lime-800\/20 {
  border-bottom-color: #3f621233;
}

.border-b-lime-800\/25 {
  border-bottom-color: #3f621240;
}

.border-b-lime-800\/30 {
  border-bottom-color: #3f62124d;
}

.border-b-lime-800\/40 {
  border-bottom-color: #3f621266;
}

.border-b-lime-800\/5 {
  border-bottom-color: #3f62120d;
}

.border-b-lime-800\/50 {
  border-bottom-color: #3f621280;
}

.border-b-lime-800\/60 {
  border-bottom-color: #3f621299;
}

.border-b-lime-800\/70 {
  border-bottom-color: #3f6212b3;
}

.border-b-lime-800\/75 {
  border-bottom-color: #3f6212bf;
}

.border-b-lime-800\/80 {
  border-bottom-color: #3f6212cc;
}

.border-b-lime-800\/90 {
  border-bottom-color: #3f6212e6;
}

.border-b-lime-800\/95 {
  border-bottom-color: #3f6212f2;
}

.border-b-lime-900 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-b-lime-900\/0 {
  border-bottom-color: #36531400;
}

.border-b-lime-900\/10 {
  border-bottom-color: #3653141a;
}

.border-b-lime-900\/100 {
  border-bottom-color: #365314;
}

.border-b-lime-900\/20 {
  border-bottom-color: #36531433;
}

.border-b-lime-900\/25 {
  border-bottom-color: #36531440;
}

.border-b-lime-900\/30 {
  border-bottom-color: #3653144d;
}

.border-b-lime-900\/40 {
  border-bottom-color: #36531466;
}

.border-b-lime-900\/5 {
  border-bottom-color: #3653140d;
}

.border-b-lime-900\/50 {
  border-bottom-color: #36531480;
}

.border-b-lime-900\/60 {
  border-bottom-color: #36531499;
}

.border-b-lime-900\/70 {
  border-bottom-color: #365314b3;
}

.border-b-lime-900\/75 {
  border-bottom-color: #365314bf;
}

.border-b-lime-900\/80 {
  border-bottom-color: #365314cc;
}

.border-b-lime-900\/90 {
  border-bottom-color: #365314e6;
}

.border-b-lime-900\/95 {
  border-bottom-color: #365314f2;
}

.border-b-lime-950 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-b-lime-950\/0 {
  border-bottom-color: #1a2e0500;
}

.border-b-lime-950\/10 {
  border-bottom-color: #1a2e051a;
}

.border-b-lime-950\/100 {
  border-bottom-color: #1a2e05;
}

.border-b-lime-950\/20 {
  border-bottom-color: #1a2e0533;
}

.border-b-lime-950\/25 {
  border-bottom-color: #1a2e0540;
}

.border-b-lime-950\/30 {
  border-bottom-color: #1a2e054d;
}

.border-b-lime-950\/40 {
  border-bottom-color: #1a2e0566;
}

.border-b-lime-950\/5 {
  border-bottom-color: #1a2e050d;
}

.border-b-lime-950\/50 {
  border-bottom-color: #1a2e0580;
}

.border-b-lime-950\/60 {
  border-bottom-color: #1a2e0599;
}

.border-b-lime-950\/70 {
  border-bottom-color: #1a2e05b3;
}

.border-b-lime-950\/75 {
  border-bottom-color: #1a2e05bf;
}

.border-b-lime-950\/80 {
  border-bottom-color: #1a2e05cc;
}

.border-b-lime-950\/90 {
  border-bottom-color: #1a2e05e6;
}

.border-b-lime-950\/95 {
  border-bottom-color: #1a2e05f2;
}

.border-b-pink-100 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-b-pink-100\/0 {
  border-bottom-color: #fce7f300;
}

.border-b-pink-100\/10 {
  border-bottom-color: #fce7f31a;
}

.border-b-pink-100\/100 {
  border-bottom-color: #fce7f3;
}

.border-b-pink-100\/20 {
  border-bottom-color: #fce7f333;
}

.border-b-pink-100\/25 {
  border-bottom-color: #fce7f340;
}

.border-b-pink-100\/30 {
  border-bottom-color: #fce7f34d;
}

.border-b-pink-100\/40 {
  border-bottom-color: #fce7f366;
}

.border-b-pink-100\/5 {
  border-bottom-color: #fce7f30d;
}

.border-b-pink-100\/50 {
  border-bottom-color: #fce7f380;
}

.border-b-pink-100\/60 {
  border-bottom-color: #fce7f399;
}

.border-b-pink-100\/70 {
  border-bottom-color: #fce7f3b3;
}

.border-b-pink-100\/75 {
  border-bottom-color: #fce7f3bf;
}

.border-b-pink-100\/80 {
  border-bottom-color: #fce7f3cc;
}

.border-b-pink-100\/90 {
  border-bottom-color: #fce7f3e6;
}

.border-b-pink-100\/95 {
  border-bottom-color: #fce7f3f2;
}

.border-b-pink-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-b-pink-200\/0 {
  border-bottom-color: #fbcfe800;
}

.border-b-pink-200\/10 {
  border-bottom-color: #fbcfe81a;
}

.border-b-pink-200\/100 {
  border-bottom-color: #fbcfe8;
}

.border-b-pink-200\/20 {
  border-bottom-color: #fbcfe833;
}

.border-b-pink-200\/25 {
  border-bottom-color: #fbcfe840;
}

.border-b-pink-200\/30 {
  border-bottom-color: #fbcfe84d;
}

.border-b-pink-200\/40 {
  border-bottom-color: #fbcfe866;
}

.border-b-pink-200\/5 {
  border-bottom-color: #fbcfe80d;
}

.border-b-pink-200\/50 {
  border-bottom-color: #fbcfe880;
}

.border-b-pink-200\/60 {
  border-bottom-color: #fbcfe899;
}

.border-b-pink-200\/70 {
  border-bottom-color: #fbcfe8b3;
}

.border-b-pink-200\/75 {
  border-bottom-color: #fbcfe8bf;
}

.border-b-pink-200\/80 {
  border-bottom-color: #fbcfe8cc;
}

.border-b-pink-200\/90 {
  border-bottom-color: #fbcfe8e6;
}

.border-b-pink-200\/95 {
  border-bottom-color: #fbcfe8f2;
}

.border-b-pink-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-b-pink-300\/0 {
  border-bottom-color: #f9a8d400;
}

.border-b-pink-300\/10 {
  border-bottom-color: #f9a8d41a;
}

.border-b-pink-300\/100 {
  border-bottom-color: #f9a8d4;
}

.border-b-pink-300\/20 {
  border-bottom-color: #f9a8d433;
}

.border-b-pink-300\/25 {
  border-bottom-color: #f9a8d440;
}

.border-b-pink-300\/30 {
  border-bottom-color: #f9a8d44d;
}

.border-b-pink-300\/40 {
  border-bottom-color: #f9a8d466;
}

.border-b-pink-300\/5 {
  border-bottom-color: #f9a8d40d;
}

.border-b-pink-300\/50 {
  border-bottom-color: #f9a8d480;
}

.border-b-pink-300\/60 {
  border-bottom-color: #f9a8d499;
}

.border-b-pink-300\/70 {
  border-bottom-color: #f9a8d4b3;
}

.border-b-pink-300\/75 {
  border-bottom-color: #f9a8d4bf;
}

.border-b-pink-300\/80 {
  border-bottom-color: #f9a8d4cc;
}

.border-b-pink-300\/90 {
  border-bottom-color: #f9a8d4e6;
}

.border-b-pink-300\/95 {
  border-bottom-color: #f9a8d4f2;
}

.border-b-pink-400 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-b-pink-400\/0 {
  border-bottom-color: #f472b600;
}

.border-b-pink-400\/10 {
  border-bottom-color: #f472b61a;
}

.border-b-pink-400\/100 {
  border-bottom-color: #f472b6;
}

.border-b-pink-400\/20 {
  border-bottom-color: #f472b633;
}

.border-b-pink-400\/25 {
  border-bottom-color: #f472b640;
}

.border-b-pink-400\/30 {
  border-bottom-color: #f472b64d;
}

.border-b-pink-400\/40 {
  border-bottom-color: #f472b666;
}

.border-b-pink-400\/5 {
  border-bottom-color: #f472b60d;
}

.border-b-pink-400\/50 {
  border-bottom-color: #f472b680;
}

.border-b-pink-400\/60 {
  border-bottom-color: #f472b699;
}

.border-b-pink-400\/70 {
  border-bottom-color: #f472b6b3;
}

.border-b-pink-400\/75 {
  border-bottom-color: #f472b6bf;
}

.border-b-pink-400\/80 {
  border-bottom-color: #f472b6cc;
}

.border-b-pink-400\/90 {
  border-bottom-color: #f472b6e6;
}

.border-b-pink-400\/95 {
  border-bottom-color: #f472b6f2;
}

.border-b-pink-50 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-b-pink-50\/0 {
  border-bottom-color: #fdf2f800;
}

.border-b-pink-50\/10 {
  border-bottom-color: #fdf2f81a;
}

.border-b-pink-50\/100 {
  border-bottom-color: #fdf2f8;
}

.border-b-pink-50\/20 {
  border-bottom-color: #fdf2f833;
}

.border-b-pink-50\/25 {
  border-bottom-color: #fdf2f840;
}

.border-b-pink-50\/30 {
  border-bottom-color: #fdf2f84d;
}

.border-b-pink-50\/40 {
  border-bottom-color: #fdf2f866;
}

.border-b-pink-50\/5 {
  border-bottom-color: #fdf2f80d;
}

.border-b-pink-50\/50 {
  border-bottom-color: #fdf2f880;
}

.border-b-pink-50\/60 {
  border-bottom-color: #fdf2f899;
}

.border-b-pink-50\/70 {
  border-bottom-color: #fdf2f8b3;
}

.border-b-pink-50\/75 {
  border-bottom-color: #fdf2f8bf;
}

.border-b-pink-50\/80 {
  border-bottom-color: #fdf2f8cc;
}

.border-b-pink-50\/90 {
  border-bottom-color: #fdf2f8e6;
}

.border-b-pink-50\/95 {
  border-bottom-color: #fdf2f8f2;
}

.border-b-pink-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-b-pink-500\/0 {
  border-bottom-color: #ec489900;
}

.border-b-pink-500\/10 {
  border-bottom-color: #ec48991a;
}

.border-b-pink-500\/100 {
  border-bottom-color: #ec4899;
}

.border-b-pink-500\/20 {
  border-bottom-color: #ec489933;
}

.border-b-pink-500\/25 {
  border-bottom-color: #ec489940;
}

.border-b-pink-500\/30 {
  border-bottom-color: #ec48994d;
}

.border-b-pink-500\/40 {
  border-bottom-color: #ec489966;
}

.border-b-pink-500\/5 {
  border-bottom-color: #ec48990d;
}

.border-b-pink-500\/50 {
  border-bottom-color: #ec489980;
}

.border-b-pink-500\/60 {
  border-bottom-color: #ec489999;
}

.border-b-pink-500\/70 {
  border-bottom-color: #ec4899b3;
}

.border-b-pink-500\/75 {
  border-bottom-color: #ec4899bf;
}

.border-b-pink-500\/80 {
  border-bottom-color: #ec4899cc;
}

.border-b-pink-500\/90 {
  border-bottom-color: #ec4899e6;
}

.border-b-pink-500\/95 {
  border-bottom-color: #ec4899f2;
}

.border-b-pink-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-b-pink-600\/0 {
  border-bottom-color: #db277700;
}

.border-b-pink-600\/10 {
  border-bottom-color: #db27771a;
}

.border-b-pink-600\/100 {
  border-bottom-color: #db2777;
}

.border-b-pink-600\/20 {
  border-bottom-color: #db277733;
}

.border-b-pink-600\/25 {
  border-bottom-color: #db277740;
}

.border-b-pink-600\/30 {
  border-bottom-color: #db27774d;
}

.border-b-pink-600\/40 {
  border-bottom-color: #db277766;
}

.border-b-pink-600\/5 {
  border-bottom-color: #db27770d;
}

.border-b-pink-600\/50 {
  border-bottom-color: #db277780;
}

.border-b-pink-600\/60 {
  border-bottom-color: #db277799;
}

.border-b-pink-600\/70 {
  border-bottom-color: #db2777b3;
}

.border-b-pink-600\/75 {
  border-bottom-color: #db2777bf;
}

.border-b-pink-600\/80 {
  border-bottom-color: #db2777cc;
}

.border-b-pink-600\/90 {
  border-bottom-color: #db2777e6;
}

.border-b-pink-600\/95 {
  border-bottom-color: #db2777f2;
}

.border-b-pink-700 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-b-pink-700\/0 {
  border-bottom-color: #be185d00;
}

.border-b-pink-700\/10 {
  border-bottom-color: #be185d1a;
}

.border-b-pink-700\/100 {
  border-bottom-color: #be185d;
}

.border-b-pink-700\/20 {
  border-bottom-color: #be185d33;
}

.border-b-pink-700\/25 {
  border-bottom-color: #be185d40;
}

.border-b-pink-700\/30 {
  border-bottom-color: #be185d4d;
}

.border-b-pink-700\/40 {
  border-bottom-color: #be185d66;
}

.border-b-pink-700\/5 {
  border-bottom-color: #be185d0d;
}

.border-b-pink-700\/50 {
  border-bottom-color: #be185d80;
}

.border-b-pink-700\/60 {
  border-bottom-color: #be185d99;
}

.border-b-pink-700\/70 {
  border-bottom-color: #be185db3;
}

.border-b-pink-700\/75 {
  border-bottom-color: #be185dbf;
}

.border-b-pink-700\/80 {
  border-bottom-color: #be185dcc;
}

.border-b-pink-700\/90 {
  border-bottom-color: #be185de6;
}

.border-b-pink-700\/95 {
  border-bottom-color: #be185df2;
}

.border-b-pink-800 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-b-pink-800\/0 {
  border-bottom-color: #9d174d00;
}

.border-b-pink-800\/10 {
  border-bottom-color: #9d174d1a;
}

.border-b-pink-800\/100 {
  border-bottom-color: #9d174d;
}

.border-b-pink-800\/20 {
  border-bottom-color: #9d174d33;
}

.border-b-pink-800\/25 {
  border-bottom-color: #9d174d40;
}

.border-b-pink-800\/30 {
  border-bottom-color: #9d174d4d;
}

.border-b-pink-800\/40 {
  border-bottom-color: #9d174d66;
}

.border-b-pink-800\/5 {
  border-bottom-color: #9d174d0d;
}

.border-b-pink-800\/50 {
  border-bottom-color: #9d174d80;
}

.border-b-pink-800\/60 {
  border-bottom-color: #9d174d99;
}

.border-b-pink-800\/70 {
  border-bottom-color: #9d174db3;
}

.border-b-pink-800\/75 {
  border-bottom-color: #9d174dbf;
}

.border-b-pink-800\/80 {
  border-bottom-color: #9d174dcc;
}

.border-b-pink-800\/90 {
  border-bottom-color: #9d174de6;
}

.border-b-pink-800\/95 {
  border-bottom-color: #9d174df2;
}

.border-b-pink-900 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-b-pink-900\/0 {
  border-bottom-color: #83184300;
}

.border-b-pink-900\/10 {
  border-bottom-color: #8318431a;
}

.border-b-pink-900\/100 {
  border-bottom-color: #831843;
}

.border-b-pink-900\/20 {
  border-bottom-color: #83184333;
}

.border-b-pink-900\/25 {
  border-bottom-color: #83184340;
}

.border-b-pink-900\/30 {
  border-bottom-color: #8318434d;
}

.border-b-pink-900\/40 {
  border-bottom-color: #83184366;
}

.border-b-pink-900\/5 {
  border-bottom-color: #8318430d;
}

.border-b-pink-900\/50 {
  border-bottom-color: #83184380;
}

.border-b-pink-900\/60 {
  border-bottom-color: #83184399;
}

.border-b-pink-900\/70 {
  border-bottom-color: #831843b3;
}

.border-b-pink-900\/75 {
  border-bottom-color: #831843bf;
}

.border-b-pink-900\/80 {
  border-bottom-color: #831843cc;
}

.border-b-pink-900\/90 {
  border-bottom-color: #831843e6;
}

.border-b-pink-900\/95 {
  border-bottom-color: #831843f2;
}

.border-b-pink-950 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-b-pink-950\/0 {
  border-bottom-color: #50072400;
}

.border-b-pink-950\/10 {
  border-bottom-color: #5007241a;
}

.border-b-pink-950\/100 {
  border-bottom-color: #500724;
}

.border-b-pink-950\/20 {
  border-bottom-color: #50072433;
}

.border-b-pink-950\/25 {
  border-bottom-color: #50072440;
}

.border-b-pink-950\/30 {
  border-bottom-color: #5007244d;
}

.border-b-pink-950\/40 {
  border-bottom-color: #50072466;
}

.border-b-pink-950\/5 {
  border-bottom-color: #5007240d;
}

.border-b-pink-950\/50 {
  border-bottom-color: #50072480;
}

.border-b-pink-950\/60 {
  border-bottom-color: #50072499;
}

.border-b-pink-950\/70 {
  border-bottom-color: #500724b3;
}

.border-b-pink-950\/75 {
  border-bottom-color: #500724bf;
}

.border-b-pink-950\/80 {
  border-bottom-color: #500724cc;
}

.border-b-pink-950\/90 {
  border-bottom-color: #500724e6;
}

.border-b-pink-950\/95 {
  border-bottom-color: #500724f2;
}

.border-b-primary {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-b-primary\/0 {
  border-bottom-color: hsl(var(--primary) / 0);
}

.border-b-primary\/10 {
  border-bottom-color: hsl(var(--primary) / .1);
}

.border-b-primary\/100 {
  border-bottom-color: hsl(var(--primary) / 1);
}

.border-b-primary\/20 {
  border-bottom-color: hsl(var(--primary) / .2);
}

.border-b-primary\/25 {
  border-bottom-color: hsl(var(--primary) / .25);
}

.border-b-primary\/30 {
  border-bottom-color: hsl(var(--primary) / .3);
}

.border-b-primary\/40 {
  border-bottom-color: hsl(var(--primary) / .4);
}

.border-b-primary\/5 {
  border-bottom-color: hsl(var(--primary) / .05);
}

.border-b-primary\/50 {
  border-bottom-color: hsl(var(--primary) / .5);
}

.border-b-primary\/60 {
  border-bottom-color: hsl(var(--primary) / .6);
}

.border-b-primary\/70 {
  border-bottom-color: hsl(var(--primary) / .7);
}

.border-b-primary\/75 {
  border-bottom-color: hsl(var(--primary) / .75);
}

.border-b-primary\/80 {
  border-bottom-color: hsl(var(--primary) / .8);
}

.border-b-primary\/90 {
  border-bottom-color: hsl(var(--primary) / .9);
}

.border-b-primary\/95 {
  border-bottom-color: hsl(var(--primary) / .95);
}

.border-b-purple-100 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-b-purple-100\/0 {
  border-bottom-color: #f3e8ff00;
}

.border-b-purple-100\/10 {
  border-bottom-color: #f3e8ff1a;
}

.border-b-purple-100\/100 {
  border-bottom-color: #f3e8ff;
}

.border-b-purple-100\/20 {
  border-bottom-color: #f3e8ff33;
}

.border-b-purple-100\/25 {
  border-bottom-color: #f3e8ff40;
}

.border-b-purple-100\/30 {
  border-bottom-color: #f3e8ff4d;
}

.border-b-purple-100\/40 {
  border-bottom-color: #f3e8ff66;
}

.border-b-purple-100\/5 {
  border-bottom-color: #f3e8ff0d;
}

.border-b-purple-100\/50 {
  border-bottom-color: #f3e8ff80;
}

.border-b-purple-100\/60 {
  border-bottom-color: #f3e8ff99;
}

.border-b-purple-100\/70 {
  border-bottom-color: #f3e8ffb3;
}

.border-b-purple-100\/75 {
  border-bottom-color: #f3e8ffbf;
}

.border-b-purple-100\/80 {
  border-bottom-color: #f3e8ffcc;
}

.border-b-purple-100\/90 {
  border-bottom-color: #f3e8ffe6;
}

.border-b-purple-100\/95 {
  border-bottom-color: #f3e8fff2;
}

.border-b-purple-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-b-purple-200\/0 {
  border-bottom-color: #e9d5ff00;
}

.border-b-purple-200\/10 {
  border-bottom-color: #e9d5ff1a;
}

.border-b-purple-200\/100 {
  border-bottom-color: #e9d5ff;
}

.border-b-purple-200\/20 {
  border-bottom-color: #e9d5ff33;
}

.border-b-purple-200\/25 {
  border-bottom-color: #e9d5ff40;
}

.border-b-purple-200\/30 {
  border-bottom-color: #e9d5ff4d;
}

.border-b-purple-200\/40 {
  border-bottom-color: #e9d5ff66;
}

.border-b-purple-200\/5 {
  border-bottom-color: #e9d5ff0d;
}

.border-b-purple-200\/50 {
  border-bottom-color: #e9d5ff80;
}

.border-b-purple-200\/60 {
  border-bottom-color: #e9d5ff99;
}

.border-b-purple-200\/70 {
  border-bottom-color: #e9d5ffb3;
}

.border-b-purple-200\/75 {
  border-bottom-color: #e9d5ffbf;
}

.border-b-purple-200\/80 {
  border-bottom-color: #e9d5ffcc;
}

.border-b-purple-200\/90 {
  border-bottom-color: #e9d5ffe6;
}

.border-b-purple-200\/95 {
  border-bottom-color: #e9d5fff2;
}

.border-b-purple-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-b-purple-300\/0 {
  border-bottom-color: #d8b4fe00;
}

.border-b-purple-300\/10 {
  border-bottom-color: #d8b4fe1a;
}

.border-b-purple-300\/100 {
  border-bottom-color: #d8b4fe;
}

.border-b-purple-300\/20 {
  border-bottom-color: #d8b4fe33;
}

.border-b-purple-300\/25 {
  border-bottom-color: #d8b4fe40;
}

.border-b-purple-300\/30 {
  border-bottom-color: #d8b4fe4d;
}

.border-b-purple-300\/40 {
  border-bottom-color: #d8b4fe66;
}

.border-b-purple-300\/5 {
  border-bottom-color: #d8b4fe0d;
}

.border-b-purple-300\/50 {
  border-bottom-color: #d8b4fe80;
}

.border-b-purple-300\/60 {
  border-bottom-color: #d8b4fe99;
}

.border-b-purple-300\/70 {
  border-bottom-color: #d8b4feb3;
}

.border-b-purple-300\/75 {
  border-bottom-color: #d8b4febf;
}

.border-b-purple-300\/80 {
  border-bottom-color: #d8b4fecc;
}

.border-b-purple-300\/90 {
  border-bottom-color: #d8b4fee6;
}

.border-b-purple-300\/95 {
  border-bottom-color: #d8b4fef2;
}

.border-b-purple-400 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-b-purple-400\/0 {
  border-bottom-color: #c084fc00;
}

.border-b-purple-400\/10 {
  border-bottom-color: #c084fc1a;
}

.border-b-purple-400\/100 {
  border-bottom-color: #c084fc;
}

.border-b-purple-400\/20 {
  border-bottom-color: #c084fc33;
}

.border-b-purple-400\/25 {
  border-bottom-color: #c084fc40;
}

.border-b-purple-400\/30 {
  border-bottom-color: #c084fc4d;
}

.border-b-purple-400\/40 {
  border-bottom-color: #c084fc66;
}

.border-b-purple-400\/5 {
  border-bottom-color: #c084fc0d;
}

.border-b-purple-400\/50 {
  border-bottom-color: #c084fc80;
}

.border-b-purple-400\/60 {
  border-bottom-color: #c084fc99;
}

.border-b-purple-400\/70 {
  border-bottom-color: #c084fcb3;
}

.border-b-purple-400\/75 {
  border-bottom-color: #c084fcbf;
}

.border-b-purple-400\/80 {
  border-bottom-color: #c084fccc;
}

.border-b-purple-400\/90 {
  border-bottom-color: #c084fce6;
}

.border-b-purple-400\/95 {
  border-bottom-color: #c084fcf2;
}

.border-b-purple-50 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-b-purple-50\/0 {
  border-bottom-color: #faf5ff00;
}

.border-b-purple-50\/10 {
  border-bottom-color: #faf5ff1a;
}

.border-b-purple-50\/100 {
  border-bottom-color: #faf5ff;
}

.border-b-purple-50\/20 {
  border-bottom-color: #faf5ff33;
}

.border-b-purple-50\/25 {
  border-bottom-color: #faf5ff40;
}

.border-b-purple-50\/30 {
  border-bottom-color: #faf5ff4d;
}

.border-b-purple-50\/40 {
  border-bottom-color: #faf5ff66;
}

.border-b-purple-50\/5 {
  border-bottom-color: #faf5ff0d;
}

.border-b-purple-50\/50 {
  border-bottom-color: #faf5ff80;
}

.border-b-purple-50\/60 {
  border-bottom-color: #faf5ff99;
}

.border-b-purple-50\/70 {
  border-bottom-color: #faf5ffb3;
}

.border-b-purple-50\/75 {
  border-bottom-color: #faf5ffbf;
}

.border-b-purple-50\/80 {
  border-bottom-color: #faf5ffcc;
}

.border-b-purple-50\/90 {
  border-bottom-color: #faf5ffe6;
}

.border-b-purple-50\/95 {
  border-bottom-color: #faf5fff2;
}

.border-b-purple-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-b-purple-500\/0 {
  border-bottom-color: #a855f700;
}

.border-b-purple-500\/10 {
  border-bottom-color: #a855f71a;
}

.border-b-purple-500\/100 {
  border-bottom-color: #a855f7;
}

.border-b-purple-500\/20 {
  border-bottom-color: #a855f733;
}

.border-b-purple-500\/25 {
  border-bottom-color: #a855f740;
}

.border-b-purple-500\/30 {
  border-bottom-color: #a855f74d;
}

.border-b-purple-500\/40 {
  border-bottom-color: #a855f766;
}

.border-b-purple-500\/5 {
  border-bottom-color: #a855f70d;
}

.border-b-purple-500\/50 {
  border-bottom-color: #a855f780;
}

.border-b-purple-500\/60 {
  border-bottom-color: #a855f799;
}

.border-b-purple-500\/70 {
  border-bottom-color: #a855f7b3;
}

.border-b-purple-500\/75 {
  border-bottom-color: #a855f7bf;
}

.border-b-purple-500\/80 {
  border-bottom-color: #a855f7cc;
}

.border-b-purple-500\/90 {
  border-bottom-color: #a855f7e6;
}

.border-b-purple-500\/95 {
  border-bottom-color: #a855f7f2;
}

.border-b-purple-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-b-purple-600\/0 {
  border-bottom-color: #9333ea00;
}

.border-b-purple-600\/10 {
  border-bottom-color: #9333ea1a;
}

.border-b-purple-600\/100 {
  border-bottom-color: #9333ea;
}

.border-b-purple-600\/20 {
  border-bottom-color: #9333ea33;
}

.border-b-purple-600\/25 {
  border-bottom-color: #9333ea40;
}

.border-b-purple-600\/30 {
  border-bottom-color: #9333ea4d;
}

.border-b-purple-600\/40 {
  border-bottom-color: #9333ea66;
}

.border-b-purple-600\/5 {
  border-bottom-color: #9333ea0d;
}

.border-b-purple-600\/50 {
  border-bottom-color: #9333ea80;
}

.border-b-purple-600\/60 {
  border-bottom-color: #9333ea99;
}

.border-b-purple-600\/70 {
  border-bottom-color: #9333eab3;
}

.border-b-purple-600\/75 {
  border-bottom-color: #9333eabf;
}

.border-b-purple-600\/80 {
  border-bottom-color: #9333eacc;
}

.border-b-purple-600\/90 {
  border-bottom-color: #9333eae6;
}

.border-b-purple-600\/95 {
  border-bottom-color: #9333eaf2;
}

.border-b-purple-700 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-b-purple-700\/0 {
  border-bottom-color: #7e22ce00;
}

.border-b-purple-700\/10 {
  border-bottom-color: #7e22ce1a;
}

.border-b-purple-700\/100 {
  border-bottom-color: #7e22ce;
}

.border-b-purple-700\/20 {
  border-bottom-color: #7e22ce33;
}

.border-b-purple-700\/25 {
  border-bottom-color: #7e22ce40;
}

.border-b-purple-700\/30 {
  border-bottom-color: #7e22ce4d;
}

.border-b-purple-700\/40 {
  border-bottom-color: #7e22ce66;
}

.border-b-purple-700\/5 {
  border-bottom-color: #7e22ce0d;
}

.border-b-purple-700\/50 {
  border-bottom-color: #7e22ce80;
}

.border-b-purple-700\/60 {
  border-bottom-color: #7e22ce99;
}

.border-b-purple-700\/70 {
  border-bottom-color: #7e22ceb3;
}

.border-b-purple-700\/75 {
  border-bottom-color: #7e22cebf;
}

.border-b-purple-700\/80 {
  border-bottom-color: #7e22cecc;
}

.border-b-purple-700\/90 {
  border-bottom-color: #7e22cee6;
}

.border-b-purple-700\/95 {
  border-bottom-color: #7e22cef2;
}

.border-b-purple-800 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-b-purple-800\/0 {
  border-bottom-color: #6b21a800;
}

.border-b-purple-800\/10 {
  border-bottom-color: #6b21a81a;
}

.border-b-purple-800\/100 {
  border-bottom-color: #6b21a8;
}

.border-b-purple-800\/20 {
  border-bottom-color: #6b21a833;
}

.border-b-purple-800\/25 {
  border-bottom-color: #6b21a840;
}

.border-b-purple-800\/30 {
  border-bottom-color: #6b21a84d;
}

.border-b-purple-800\/40 {
  border-bottom-color: #6b21a866;
}

.border-b-purple-800\/5 {
  border-bottom-color: #6b21a80d;
}

.border-b-purple-800\/50 {
  border-bottom-color: #6b21a880;
}

.border-b-purple-800\/60 {
  border-bottom-color: #6b21a899;
}

.border-b-purple-800\/70 {
  border-bottom-color: #6b21a8b3;
}

.border-b-purple-800\/75 {
  border-bottom-color: #6b21a8bf;
}

.border-b-purple-800\/80 {
  border-bottom-color: #6b21a8cc;
}

.border-b-purple-800\/90 {
  border-bottom-color: #6b21a8e6;
}

.border-b-purple-800\/95 {
  border-bottom-color: #6b21a8f2;
}

.border-b-purple-900 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-b-purple-900\/0 {
  border-bottom-color: #581c8700;
}

.border-b-purple-900\/10 {
  border-bottom-color: #581c871a;
}

.border-b-purple-900\/100 {
  border-bottom-color: #581c87;
}

.border-b-purple-900\/20 {
  border-bottom-color: #581c8733;
}

.border-b-purple-900\/25 {
  border-bottom-color: #581c8740;
}

.border-b-purple-900\/30 {
  border-bottom-color: #581c874d;
}

.border-b-purple-900\/40 {
  border-bottom-color: #581c8766;
}

.border-b-purple-900\/5 {
  border-bottom-color: #581c870d;
}

.border-b-purple-900\/50 {
  border-bottom-color: #581c8780;
}

.border-b-purple-900\/60 {
  border-bottom-color: #581c8799;
}

.border-b-purple-900\/70 {
  border-bottom-color: #581c87b3;
}

.border-b-purple-900\/75 {
  border-bottom-color: #581c87bf;
}

.border-b-purple-900\/80 {
  border-bottom-color: #581c87cc;
}

.border-b-purple-900\/90 {
  border-bottom-color: #581c87e6;
}

.border-b-purple-900\/95 {
  border-bottom-color: #581c87f2;
}

.border-b-purple-950 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-b-purple-950\/0 {
  border-bottom-color: #3b076400;
}

.border-b-purple-950\/10 {
  border-bottom-color: #3b07641a;
}

.border-b-purple-950\/100 {
  border-bottom-color: #3b0764;
}

.border-b-purple-950\/20 {
  border-bottom-color: #3b076433;
}

.border-b-purple-950\/25 {
  border-bottom-color: #3b076440;
}

.border-b-purple-950\/30 {
  border-bottom-color: #3b07644d;
}

.border-b-purple-950\/40 {
  border-bottom-color: #3b076466;
}

.border-b-purple-950\/5 {
  border-bottom-color: #3b07640d;
}

.border-b-purple-950\/50 {
  border-bottom-color: #3b076480;
}

.border-b-purple-950\/60 {
  border-bottom-color: #3b076499;
}

.border-b-purple-950\/70 {
  border-bottom-color: #3b0764b3;
}

.border-b-purple-950\/75 {
  border-bottom-color: #3b0764bf;
}

.border-b-purple-950\/80 {
  border-bottom-color: #3b0764cc;
}

.border-b-purple-950\/90 {
  border-bottom-color: #3b0764e6;
}

.border-b-purple-950\/95 {
  border-bottom-color: #3b0764f2;
}

.border-b-transparent, .border-b-transparent\/0 {
  border-bottom-color: #0000;
}

.border-b-transparent\/10 {
  border-bottom-color: #0000001a;
}

.border-b-transparent\/100 {
  border-bottom-color: #000;
}

.border-b-transparent\/20 {
  border-bottom-color: #0003;
}

.border-b-transparent\/25 {
  border-bottom-color: #00000040;
}

.border-b-transparent\/30 {
  border-bottom-color: #0000004d;
}

.border-b-transparent\/40 {
  border-bottom-color: #0006;
}

.border-b-transparent\/5 {
  border-bottom-color: #0000000d;
}

.border-b-transparent\/50 {
  border-bottom-color: #00000080;
}

.border-b-transparent\/60 {
  border-bottom-color: #0009;
}

.border-b-transparent\/70 {
  border-bottom-color: #000000b3;
}

.border-b-transparent\/75 {
  border-bottom-color: #000000bf;
}

.border-b-transparent\/80 {
  border-bottom-color: #000c;
}

.border-b-transparent\/90 {
  border-bottom-color: #000000e6;
}

.border-b-transparent\/95 {
  border-bottom-color: #000000f2;
}

.border-e-amber-100 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-e-amber-100\/0 {
  border-inline-end-color: #fef3c700;
}

.border-e-amber-100\/10 {
  border-inline-end-color: #fef3c71a;
}

.border-e-amber-100\/100 {
  border-inline-end-color: #fef3c7;
}

.border-e-amber-100\/20 {
  border-inline-end-color: #fef3c733;
}

.border-e-amber-100\/25 {
  border-inline-end-color: #fef3c740;
}

.border-e-amber-100\/30 {
  border-inline-end-color: #fef3c74d;
}

.border-e-amber-100\/40 {
  border-inline-end-color: #fef3c766;
}

.border-e-amber-100\/5 {
  border-inline-end-color: #fef3c70d;
}

.border-e-amber-100\/50 {
  border-inline-end-color: #fef3c780;
}

.border-e-amber-100\/60 {
  border-inline-end-color: #fef3c799;
}

.border-e-amber-100\/70 {
  border-inline-end-color: #fef3c7b3;
}

.border-e-amber-100\/75 {
  border-inline-end-color: #fef3c7bf;
}

.border-e-amber-100\/80 {
  border-inline-end-color: #fef3c7cc;
}

.border-e-amber-100\/90 {
  border-inline-end-color: #fef3c7e6;
}

.border-e-amber-100\/95 {
  border-inline-end-color: #fef3c7f2;
}

.border-e-amber-200 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-e-amber-200\/0 {
  border-inline-end-color: #fde68a00;
}

.border-e-amber-200\/10 {
  border-inline-end-color: #fde68a1a;
}

.border-e-amber-200\/100 {
  border-inline-end-color: #fde68a;
}

.border-e-amber-200\/20 {
  border-inline-end-color: #fde68a33;
}

.border-e-amber-200\/25 {
  border-inline-end-color: #fde68a40;
}

.border-e-amber-200\/30 {
  border-inline-end-color: #fde68a4d;
}

.border-e-amber-200\/40 {
  border-inline-end-color: #fde68a66;
}

.border-e-amber-200\/5 {
  border-inline-end-color: #fde68a0d;
}

.border-e-amber-200\/50 {
  border-inline-end-color: #fde68a80;
}

.border-e-amber-200\/60 {
  border-inline-end-color: #fde68a99;
}

.border-e-amber-200\/70 {
  border-inline-end-color: #fde68ab3;
}

.border-e-amber-200\/75 {
  border-inline-end-color: #fde68abf;
}

.border-e-amber-200\/80 {
  border-inline-end-color: #fde68acc;
}

.border-e-amber-200\/90 {
  border-inline-end-color: #fde68ae6;
}

.border-e-amber-200\/95 {
  border-inline-end-color: #fde68af2;
}

.border-e-amber-300 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-e-amber-300\/0 {
  border-inline-end-color: #fcd34d00;
}

.border-e-amber-300\/10 {
  border-inline-end-color: #fcd34d1a;
}

.border-e-amber-300\/100 {
  border-inline-end-color: #fcd34d;
}

.border-e-amber-300\/20 {
  border-inline-end-color: #fcd34d33;
}

.border-e-amber-300\/25 {
  border-inline-end-color: #fcd34d40;
}

.border-e-amber-300\/30 {
  border-inline-end-color: #fcd34d4d;
}

.border-e-amber-300\/40 {
  border-inline-end-color: #fcd34d66;
}

.border-e-amber-300\/5 {
  border-inline-end-color: #fcd34d0d;
}

.border-e-amber-300\/50 {
  border-inline-end-color: #fcd34d80;
}

.border-e-amber-300\/60 {
  border-inline-end-color: #fcd34d99;
}

.border-e-amber-300\/70 {
  border-inline-end-color: #fcd34db3;
}

.border-e-amber-300\/75 {
  border-inline-end-color: #fcd34dbf;
}

.border-e-amber-300\/80 {
  border-inline-end-color: #fcd34dcc;
}

.border-e-amber-300\/90 {
  border-inline-end-color: #fcd34de6;
}

.border-e-amber-300\/95 {
  border-inline-end-color: #fcd34df2;
}

.border-e-amber-400 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-e-amber-400\/0 {
  border-inline-end-color: #fbbf2400;
}

.border-e-amber-400\/10 {
  border-inline-end-color: #fbbf241a;
}

.border-e-amber-400\/100 {
  border-inline-end-color: #fbbf24;
}

.border-e-amber-400\/20 {
  border-inline-end-color: #fbbf2433;
}

.border-e-amber-400\/25 {
  border-inline-end-color: #fbbf2440;
}

.border-e-amber-400\/30 {
  border-inline-end-color: #fbbf244d;
}

.border-e-amber-400\/40 {
  border-inline-end-color: #fbbf2466;
}

.border-e-amber-400\/5 {
  border-inline-end-color: #fbbf240d;
}

.border-e-amber-400\/50 {
  border-inline-end-color: #fbbf2480;
}

.border-e-amber-400\/60 {
  border-inline-end-color: #fbbf2499;
}

.border-e-amber-400\/70 {
  border-inline-end-color: #fbbf24b3;
}

.border-e-amber-400\/75 {
  border-inline-end-color: #fbbf24bf;
}

.border-e-amber-400\/80 {
  border-inline-end-color: #fbbf24cc;
}

.border-e-amber-400\/90 {
  border-inline-end-color: #fbbf24e6;
}

.border-e-amber-400\/95 {
  border-inline-end-color: #fbbf24f2;
}

.border-e-amber-50 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-e-amber-50\/0 {
  border-inline-end-color: #fffbeb00;
}

.border-e-amber-50\/10 {
  border-inline-end-color: #fffbeb1a;
}

.border-e-amber-50\/100 {
  border-inline-end-color: #fffbeb;
}

.border-e-amber-50\/20 {
  border-inline-end-color: #fffbeb33;
}

.border-e-amber-50\/25 {
  border-inline-end-color: #fffbeb40;
}

.border-e-amber-50\/30 {
  border-inline-end-color: #fffbeb4d;
}

.border-e-amber-50\/40 {
  border-inline-end-color: #fffbeb66;
}

.border-e-amber-50\/5 {
  border-inline-end-color: #fffbeb0d;
}

.border-e-amber-50\/50 {
  border-inline-end-color: #fffbeb80;
}

.border-e-amber-50\/60 {
  border-inline-end-color: #fffbeb99;
}

.border-e-amber-50\/70 {
  border-inline-end-color: #fffbebb3;
}

.border-e-amber-50\/75 {
  border-inline-end-color: #fffbebbf;
}

.border-e-amber-50\/80 {
  border-inline-end-color: #fffbebcc;
}

.border-e-amber-50\/90 {
  border-inline-end-color: #fffbebe6;
}

.border-e-amber-50\/95 {
  border-inline-end-color: #fffbebf2;
}

.border-e-amber-500 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-e-amber-500\/0 {
  border-inline-end-color: #f59e0b00;
}

.border-e-amber-500\/10 {
  border-inline-end-color: #f59e0b1a;
}

.border-e-amber-500\/100 {
  border-inline-end-color: #f59e0b;
}

.border-e-amber-500\/20 {
  border-inline-end-color: #f59e0b33;
}

.border-e-amber-500\/25 {
  border-inline-end-color: #f59e0b40;
}

.border-e-amber-500\/30 {
  border-inline-end-color: #f59e0b4d;
}

.border-e-amber-500\/40 {
  border-inline-end-color: #f59e0b66;
}

.border-e-amber-500\/5 {
  border-inline-end-color: #f59e0b0d;
}

.border-e-amber-500\/50 {
  border-inline-end-color: #f59e0b80;
}

.border-e-amber-500\/60 {
  border-inline-end-color: #f59e0b99;
}

.border-e-amber-500\/70 {
  border-inline-end-color: #f59e0bb3;
}

.border-e-amber-500\/75 {
  border-inline-end-color: #f59e0bbf;
}

.border-e-amber-500\/80 {
  border-inline-end-color: #f59e0bcc;
}

.border-e-amber-500\/90 {
  border-inline-end-color: #f59e0be6;
}

.border-e-amber-500\/95 {
  border-inline-end-color: #f59e0bf2;
}

.border-e-amber-600 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-e-amber-600\/0 {
  border-inline-end-color: #d9770600;
}

.border-e-amber-600\/10 {
  border-inline-end-color: #d977061a;
}

.border-e-amber-600\/100 {
  border-inline-end-color: #d97706;
}

.border-e-amber-600\/20 {
  border-inline-end-color: #d9770633;
}

.border-e-amber-600\/25 {
  border-inline-end-color: #d9770640;
}

.border-e-amber-600\/30 {
  border-inline-end-color: #d977064d;
}

.border-e-amber-600\/40 {
  border-inline-end-color: #d9770666;
}

.border-e-amber-600\/5 {
  border-inline-end-color: #d977060d;
}

.border-e-amber-600\/50 {
  border-inline-end-color: #d9770680;
}

.border-e-amber-600\/60 {
  border-inline-end-color: #d9770699;
}

.border-e-amber-600\/70 {
  border-inline-end-color: #d97706b3;
}

.border-e-amber-600\/75 {
  border-inline-end-color: #d97706bf;
}

.border-e-amber-600\/80 {
  border-inline-end-color: #d97706cc;
}

.border-e-amber-600\/90 {
  border-inline-end-color: #d97706e6;
}

.border-e-amber-600\/95 {
  border-inline-end-color: #d97706f2;
}

.border-e-amber-700 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-e-amber-700\/0 {
  border-inline-end-color: #b4530900;
}

.border-e-amber-700\/10 {
  border-inline-end-color: #b453091a;
}

.border-e-amber-700\/100 {
  border-inline-end-color: #b45309;
}

.border-e-amber-700\/20 {
  border-inline-end-color: #b4530933;
}

.border-e-amber-700\/25 {
  border-inline-end-color: #b4530940;
}

.border-e-amber-700\/30 {
  border-inline-end-color: #b453094d;
}

.border-e-amber-700\/40 {
  border-inline-end-color: #b4530966;
}

.border-e-amber-700\/5 {
  border-inline-end-color: #b453090d;
}

.border-e-amber-700\/50 {
  border-inline-end-color: #b4530980;
}

.border-e-amber-700\/60 {
  border-inline-end-color: #b4530999;
}

.border-e-amber-700\/70 {
  border-inline-end-color: #b45309b3;
}

.border-e-amber-700\/75 {
  border-inline-end-color: #b45309bf;
}

.border-e-amber-700\/80 {
  border-inline-end-color: #b45309cc;
}

.border-e-amber-700\/90 {
  border-inline-end-color: #b45309e6;
}

.border-e-amber-700\/95 {
  border-inline-end-color: #b45309f2;
}

.border-e-amber-800 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-e-amber-800\/0 {
  border-inline-end-color: #92400e00;
}

.border-e-amber-800\/10 {
  border-inline-end-color: #92400e1a;
}

.border-e-amber-800\/100 {
  border-inline-end-color: #92400e;
}

.border-e-amber-800\/20 {
  border-inline-end-color: #92400e33;
}

.border-e-amber-800\/25 {
  border-inline-end-color: #92400e40;
}

.border-e-amber-800\/30 {
  border-inline-end-color: #92400e4d;
}

.border-e-amber-800\/40 {
  border-inline-end-color: #92400e66;
}

.border-e-amber-800\/5 {
  border-inline-end-color: #92400e0d;
}

.border-e-amber-800\/50 {
  border-inline-end-color: #92400e80;
}

.border-e-amber-800\/60 {
  border-inline-end-color: #92400e99;
}

.border-e-amber-800\/70 {
  border-inline-end-color: #92400eb3;
}

.border-e-amber-800\/75 {
  border-inline-end-color: #92400ebf;
}

.border-e-amber-800\/80 {
  border-inline-end-color: #92400ecc;
}

.border-e-amber-800\/90 {
  border-inline-end-color: #92400ee6;
}

.border-e-amber-800\/95 {
  border-inline-end-color: #92400ef2;
}

.border-e-amber-900 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-e-amber-900\/0 {
  border-inline-end-color: #78350f00;
}

.border-e-amber-900\/10 {
  border-inline-end-color: #78350f1a;
}

.border-e-amber-900\/100 {
  border-inline-end-color: #78350f;
}

.border-e-amber-900\/20 {
  border-inline-end-color: #78350f33;
}

.border-e-amber-900\/25 {
  border-inline-end-color: #78350f40;
}

.border-e-amber-900\/30 {
  border-inline-end-color: #78350f4d;
}

.border-e-amber-900\/40 {
  border-inline-end-color: #78350f66;
}

.border-e-amber-900\/5 {
  border-inline-end-color: #78350f0d;
}

.border-e-amber-900\/50 {
  border-inline-end-color: #78350f80;
}

.border-e-amber-900\/60 {
  border-inline-end-color: #78350f99;
}

.border-e-amber-900\/70 {
  border-inline-end-color: #78350fb3;
}

.border-e-amber-900\/75 {
  border-inline-end-color: #78350fbf;
}

.border-e-amber-900\/80 {
  border-inline-end-color: #78350fcc;
}

.border-e-amber-900\/90 {
  border-inline-end-color: #78350fe6;
}

.border-e-amber-900\/95 {
  border-inline-end-color: #78350ff2;
}

.border-e-amber-950 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-e-amber-950\/0 {
  border-inline-end-color: #451a0300;
}

.border-e-amber-950\/10 {
  border-inline-end-color: #451a031a;
}

.border-e-amber-950\/100 {
  border-inline-end-color: #451a03;
}

.border-e-amber-950\/20 {
  border-inline-end-color: #451a0333;
}

.border-e-amber-950\/25 {
  border-inline-end-color: #451a0340;
}

.border-e-amber-950\/30 {
  border-inline-end-color: #451a034d;
}

.border-e-amber-950\/40 {
  border-inline-end-color: #451a0366;
}

.border-e-amber-950\/5 {
  border-inline-end-color: #451a030d;
}

.border-e-amber-950\/50 {
  border-inline-end-color: #451a0380;
}

.border-e-amber-950\/60 {
  border-inline-end-color: #451a0399;
}

.border-e-amber-950\/70 {
  border-inline-end-color: #451a03b3;
}

.border-e-amber-950\/75 {
  border-inline-end-color: #451a03bf;
}

.border-e-amber-950\/80 {
  border-inline-end-color: #451a03cc;
}

.border-e-amber-950\/90 {
  border-inline-end-color: #451a03e6;
}

.border-e-amber-950\/95 {
  border-inline-end-color: #451a03f2;
}

.border-e-emerald-100 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-e-emerald-100\/0 {
  border-inline-end-color: #d1fae500;
}

.border-e-emerald-100\/10 {
  border-inline-end-color: #d1fae51a;
}

.border-e-emerald-100\/100 {
  border-inline-end-color: #d1fae5;
}

.border-e-emerald-100\/20 {
  border-inline-end-color: #d1fae533;
}

.border-e-emerald-100\/25 {
  border-inline-end-color: #d1fae540;
}

.border-e-emerald-100\/30 {
  border-inline-end-color: #d1fae54d;
}

.border-e-emerald-100\/40 {
  border-inline-end-color: #d1fae566;
}

.border-e-emerald-100\/5 {
  border-inline-end-color: #d1fae50d;
}

.border-e-emerald-100\/50 {
  border-inline-end-color: #d1fae580;
}

.border-e-emerald-100\/60 {
  border-inline-end-color: #d1fae599;
}

.border-e-emerald-100\/70 {
  border-inline-end-color: #d1fae5b3;
}

.border-e-emerald-100\/75 {
  border-inline-end-color: #d1fae5bf;
}

.border-e-emerald-100\/80 {
  border-inline-end-color: #d1fae5cc;
}

.border-e-emerald-100\/90 {
  border-inline-end-color: #d1fae5e6;
}

.border-e-emerald-100\/95 {
  border-inline-end-color: #d1fae5f2;
}

.border-e-emerald-200 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-e-emerald-200\/0 {
  border-inline-end-color: #a7f3d000;
}

.border-e-emerald-200\/10 {
  border-inline-end-color: #a7f3d01a;
}

.border-e-emerald-200\/100 {
  border-inline-end-color: #a7f3d0;
}

.border-e-emerald-200\/20 {
  border-inline-end-color: #a7f3d033;
}

.border-e-emerald-200\/25 {
  border-inline-end-color: #a7f3d040;
}

.border-e-emerald-200\/30 {
  border-inline-end-color: #a7f3d04d;
}

.border-e-emerald-200\/40 {
  border-inline-end-color: #a7f3d066;
}

.border-e-emerald-200\/5 {
  border-inline-end-color: #a7f3d00d;
}

.border-e-emerald-200\/50 {
  border-inline-end-color: #a7f3d080;
}

.border-e-emerald-200\/60 {
  border-inline-end-color: #a7f3d099;
}

.border-e-emerald-200\/70 {
  border-inline-end-color: #a7f3d0b3;
}

.border-e-emerald-200\/75 {
  border-inline-end-color: #a7f3d0bf;
}

.border-e-emerald-200\/80 {
  border-inline-end-color: #a7f3d0cc;
}

.border-e-emerald-200\/90 {
  border-inline-end-color: #a7f3d0e6;
}

.border-e-emerald-200\/95 {
  border-inline-end-color: #a7f3d0f2;
}

.border-e-emerald-300 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-e-emerald-300\/0 {
  border-inline-end-color: #6ee7b700;
}

.border-e-emerald-300\/10 {
  border-inline-end-color: #6ee7b71a;
}

.border-e-emerald-300\/100 {
  border-inline-end-color: #6ee7b7;
}

.border-e-emerald-300\/20 {
  border-inline-end-color: #6ee7b733;
}

.border-e-emerald-300\/25 {
  border-inline-end-color: #6ee7b740;
}

.border-e-emerald-300\/30 {
  border-inline-end-color: #6ee7b74d;
}

.border-e-emerald-300\/40 {
  border-inline-end-color: #6ee7b766;
}

.border-e-emerald-300\/5 {
  border-inline-end-color: #6ee7b70d;
}

.border-e-emerald-300\/50 {
  border-inline-end-color: #6ee7b780;
}

.border-e-emerald-300\/60 {
  border-inline-end-color: #6ee7b799;
}

.border-e-emerald-300\/70 {
  border-inline-end-color: #6ee7b7b3;
}

.border-e-emerald-300\/75 {
  border-inline-end-color: #6ee7b7bf;
}

.border-e-emerald-300\/80 {
  border-inline-end-color: #6ee7b7cc;
}

.border-e-emerald-300\/90 {
  border-inline-end-color: #6ee7b7e6;
}

.border-e-emerald-300\/95 {
  border-inline-end-color: #6ee7b7f2;
}

.border-e-emerald-400 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-e-emerald-400\/0 {
  border-inline-end-color: #34d39900;
}

.border-e-emerald-400\/10 {
  border-inline-end-color: #34d3991a;
}

.border-e-emerald-400\/100 {
  border-inline-end-color: #34d399;
}

.border-e-emerald-400\/20 {
  border-inline-end-color: #34d39933;
}

.border-e-emerald-400\/25 {
  border-inline-end-color: #34d39940;
}

.border-e-emerald-400\/30 {
  border-inline-end-color: #34d3994d;
}

.border-e-emerald-400\/40 {
  border-inline-end-color: #34d39966;
}

.border-e-emerald-400\/5 {
  border-inline-end-color: #34d3990d;
}

.border-e-emerald-400\/50 {
  border-inline-end-color: #34d39980;
}

.border-e-emerald-400\/60 {
  border-inline-end-color: #34d39999;
}

.border-e-emerald-400\/70 {
  border-inline-end-color: #34d399b3;
}

.border-e-emerald-400\/75 {
  border-inline-end-color: #34d399bf;
}

.border-e-emerald-400\/80 {
  border-inline-end-color: #34d399cc;
}

.border-e-emerald-400\/90 {
  border-inline-end-color: #34d399e6;
}

.border-e-emerald-400\/95 {
  border-inline-end-color: #34d399f2;
}

.border-e-emerald-50 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-e-emerald-50\/0 {
  border-inline-end-color: #ecfdf500;
}

.border-e-emerald-50\/10 {
  border-inline-end-color: #ecfdf51a;
}

.border-e-emerald-50\/100 {
  border-inline-end-color: #ecfdf5;
}

.border-e-emerald-50\/20 {
  border-inline-end-color: #ecfdf533;
}

.border-e-emerald-50\/25 {
  border-inline-end-color: #ecfdf540;
}

.border-e-emerald-50\/30 {
  border-inline-end-color: #ecfdf54d;
}

.border-e-emerald-50\/40 {
  border-inline-end-color: #ecfdf566;
}

.border-e-emerald-50\/5 {
  border-inline-end-color: #ecfdf50d;
}

.border-e-emerald-50\/50 {
  border-inline-end-color: #ecfdf580;
}

.border-e-emerald-50\/60 {
  border-inline-end-color: #ecfdf599;
}

.border-e-emerald-50\/70 {
  border-inline-end-color: #ecfdf5b3;
}

.border-e-emerald-50\/75 {
  border-inline-end-color: #ecfdf5bf;
}

.border-e-emerald-50\/80 {
  border-inline-end-color: #ecfdf5cc;
}

.border-e-emerald-50\/90 {
  border-inline-end-color: #ecfdf5e6;
}

.border-e-emerald-50\/95 {
  border-inline-end-color: #ecfdf5f2;
}

.border-e-emerald-500 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-e-emerald-500\/0 {
  border-inline-end-color: #10b98100;
}

.border-e-emerald-500\/10 {
  border-inline-end-color: #10b9811a;
}

.border-e-emerald-500\/100 {
  border-inline-end-color: #10b981;
}

.border-e-emerald-500\/20 {
  border-inline-end-color: #10b98133;
}

.border-e-emerald-500\/25 {
  border-inline-end-color: #10b98140;
}

.border-e-emerald-500\/30 {
  border-inline-end-color: #10b9814d;
}

.border-e-emerald-500\/40 {
  border-inline-end-color: #10b98166;
}

.border-e-emerald-500\/5 {
  border-inline-end-color: #10b9810d;
}

.border-e-emerald-500\/50 {
  border-inline-end-color: #10b98180;
}

.border-e-emerald-500\/60 {
  border-inline-end-color: #10b98199;
}

.border-e-emerald-500\/70 {
  border-inline-end-color: #10b981b3;
}

.border-e-emerald-500\/75 {
  border-inline-end-color: #10b981bf;
}

.border-e-emerald-500\/80 {
  border-inline-end-color: #10b981cc;
}

.border-e-emerald-500\/90 {
  border-inline-end-color: #10b981e6;
}

.border-e-emerald-500\/95 {
  border-inline-end-color: #10b981f2;
}

.border-e-emerald-600 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-e-emerald-600\/0 {
  border-inline-end-color: #05966900;
}

.border-e-emerald-600\/10 {
  border-inline-end-color: #0596691a;
}

.border-e-emerald-600\/100 {
  border-inline-end-color: #059669;
}

.border-e-emerald-600\/20 {
  border-inline-end-color: #05966933;
}

.border-e-emerald-600\/25 {
  border-inline-end-color: #05966940;
}

.border-e-emerald-600\/30 {
  border-inline-end-color: #0596694d;
}

.border-e-emerald-600\/40 {
  border-inline-end-color: #05966966;
}

.border-e-emerald-600\/5 {
  border-inline-end-color: #0596690d;
}

.border-e-emerald-600\/50 {
  border-inline-end-color: #05966980;
}

.border-e-emerald-600\/60 {
  border-inline-end-color: #05966999;
}

.border-e-emerald-600\/70 {
  border-inline-end-color: #059669b3;
}

.border-e-emerald-600\/75 {
  border-inline-end-color: #059669bf;
}

.border-e-emerald-600\/80 {
  border-inline-end-color: #059669cc;
}

.border-e-emerald-600\/90 {
  border-inline-end-color: #059669e6;
}

.border-e-emerald-600\/95 {
  border-inline-end-color: #059669f2;
}

.border-e-emerald-700 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-e-emerald-700\/0 {
  border-inline-end-color: #04785700;
}

.border-e-emerald-700\/10 {
  border-inline-end-color: #0478571a;
}

.border-e-emerald-700\/100 {
  border-inline-end-color: #047857;
}

.border-e-emerald-700\/20 {
  border-inline-end-color: #04785733;
}

.border-e-emerald-700\/25 {
  border-inline-end-color: #04785740;
}

.border-e-emerald-700\/30 {
  border-inline-end-color: #0478574d;
}

.border-e-emerald-700\/40 {
  border-inline-end-color: #04785766;
}

.border-e-emerald-700\/5 {
  border-inline-end-color: #0478570d;
}

.border-e-emerald-700\/50 {
  border-inline-end-color: #04785780;
}

.border-e-emerald-700\/60 {
  border-inline-end-color: #04785799;
}

.border-e-emerald-700\/70 {
  border-inline-end-color: #047857b3;
}

.border-e-emerald-700\/75 {
  border-inline-end-color: #047857bf;
}

.border-e-emerald-700\/80 {
  border-inline-end-color: #047857cc;
}

.border-e-emerald-700\/90 {
  border-inline-end-color: #047857e6;
}

.border-e-emerald-700\/95 {
  border-inline-end-color: #047857f2;
}

.border-e-emerald-800 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-e-emerald-800\/0 {
  border-inline-end-color: #065f4600;
}

.border-e-emerald-800\/10 {
  border-inline-end-color: #065f461a;
}

.border-e-emerald-800\/100 {
  border-inline-end-color: #065f46;
}

.border-e-emerald-800\/20 {
  border-inline-end-color: #065f4633;
}

.border-e-emerald-800\/25 {
  border-inline-end-color: #065f4640;
}

.border-e-emerald-800\/30 {
  border-inline-end-color: #065f464d;
}

.border-e-emerald-800\/40 {
  border-inline-end-color: #065f4666;
}

.border-e-emerald-800\/5 {
  border-inline-end-color: #065f460d;
}

.border-e-emerald-800\/50 {
  border-inline-end-color: #065f4680;
}

.border-e-emerald-800\/60 {
  border-inline-end-color: #065f4699;
}

.border-e-emerald-800\/70 {
  border-inline-end-color: #065f46b3;
}

.border-e-emerald-800\/75 {
  border-inline-end-color: #065f46bf;
}

.border-e-emerald-800\/80 {
  border-inline-end-color: #065f46cc;
}

.border-e-emerald-800\/90 {
  border-inline-end-color: #065f46e6;
}

.border-e-emerald-800\/95 {
  border-inline-end-color: #065f46f2;
}

.border-e-emerald-900 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-e-emerald-900\/0 {
  border-inline-end-color: #064e3b00;
}

.border-e-emerald-900\/10 {
  border-inline-end-color: #064e3b1a;
}

.border-e-emerald-900\/100 {
  border-inline-end-color: #064e3b;
}

.border-e-emerald-900\/20 {
  border-inline-end-color: #064e3b33;
}

.border-e-emerald-900\/25 {
  border-inline-end-color: #064e3b40;
}

.border-e-emerald-900\/30 {
  border-inline-end-color: #064e3b4d;
}

.border-e-emerald-900\/40 {
  border-inline-end-color: #064e3b66;
}

.border-e-emerald-900\/5 {
  border-inline-end-color: #064e3b0d;
}

.border-e-emerald-900\/50 {
  border-inline-end-color: #064e3b80;
}

.border-e-emerald-900\/60 {
  border-inline-end-color: #064e3b99;
}

.border-e-emerald-900\/70 {
  border-inline-end-color: #064e3bb3;
}

.border-e-emerald-900\/75 {
  border-inline-end-color: #064e3bbf;
}

.border-e-emerald-900\/80 {
  border-inline-end-color: #064e3bcc;
}

.border-e-emerald-900\/90 {
  border-inline-end-color: #064e3be6;
}

.border-e-emerald-900\/95 {
  border-inline-end-color: #064e3bf2;
}

.border-e-emerald-950 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-e-emerald-950\/0 {
  border-inline-end-color: #022c2200;
}

.border-e-emerald-950\/10 {
  border-inline-end-color: #022c221a;
}

.border-e-emerald-950\/100 {
  border-inline-end-color: #022c22;
}

.border-e-emerald-950\/20 {
  border-inline-end-color: #022c2233;
}

.border-e-emerald-950\/25 {
  border-inline-end-color: #022c2240;
}

.border-e-emerald-950\/30 {
  border-inline-end-color: #022c224d;
}

.border-e-emerald-950\/40 {
  border-inline-end-color: #022c2266;
}

.border-e-emerald-950\/5 {
  border-inline-end-color: #022c220d;
}

.border-e-emerald-950\/50 {
  border-inline-end-color: #022c2280;
}

.border-e-emerald-950\/60 {
  border-inline-end-color: #022c2299;
}

.border-e-emerald-950\/70 {
  border-inline-end-color: #022c22b3;
}

.border-e-emerald-950\/75 {
  border-inline-end-color: #022c22bf;
}

.border-e-emerald-950\/80 {
  border-inline-end-color: #022c22cc;
}

.border-e-emerald-950\/90 {
  border-inline-end-color: #022c22e6;
}

.border-e-emerald-950\/95 {
  border-inline-end-color: #022c22f2;
}

.border-e-lime-100 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-e-lime-100\/0 {
  border-inline-end-color: #ecfccb00;
}

.border-e-lime-100\/10 {
  border-inline-end-color: #ecfccb1a;
}

.border-e-lime-100\/100 {
  border-inline-end-color: #ecfccb;
}

.border-e-lime-100\/20 {
  border-inline-end-color: #ecfccb33;
}

.border-e-lime-100\/25 {
  border-inline-end-color: #ecfccb40;
}

.border-e-lime-100\/30 {
  border-inline-end-color: #ecfccb4d;
}

.border-e-lime-100\/40 {
  border-inline-end-color: #ecfccb66;
}

.border-e-lime-100\/5 {
  border-inline-end-color: #ecfccb0d;
}

.border-e-lime-100\/50 {
  border-inline-end-color: #ecfccb80;
}

.border-e-lime-100\/60 {
  border-inline-end-color: #ecfccb99;
}

.border-e-lime-100\/70 {
  border-inline-end-color: #ecfccbb3;
}

.border-e-lime-100\/75 {
  border-inline-end-color: #ecfccbbf;
}

.border-e-lime-100\/80 {
  border-inline-end-color: #ecfccbcc;
}

.border-e-lime-100\/90 {
  border-inline-end-color: #ecfccbe6;
}

.border-e-lime-100\/95 {
  border-inline-end-color: #ecfccbf2;
}

.border-e-lime-200 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-e-lime-200\/0 {
  border-inline-end-color: #d9f99d00;
}

.border-e-lime-200\/10 {
  border-inline-end-color: #d9f99d1a;
}

.border-e-lime-200\/100 {
  border-inline-end-color: #d9f99d;
}

.border-e-lime-200\/20 {
  border-inline-end-color: #d9f99d33;
}

.border-e-lime-200\/25 {
  border-inline-end-color: #d9f99d40;
}

.border-e-lime-200\/30 {
  border-inline-end-color: #d9f99d4d;
}

.border-e-lime-200\/40 {
  border-inline-end-color: #d9f99d66;
}

.border-e-lime-200\/5 {
  border-inline-end-color: #d9f99d0d;
}

.border-e-lime-200\/50 {
  border-inline-end-color: #d9f99d80;
}

.border-e-lime-200\/60 {
  border-inline-end-color: #d9f99d99;
}

.border-e-lime-200\/70 {
  border-inline-end-color: #d9f99db3;
}

.border-e-lime-200\/75 {
  border-inline-end-color: #d9f99dbf;
}

.border-e-lime-200\/80 {
  border-inline-end-color: #d9f99dcc;
}

.border-e-lime-200\/90 {
  border-inline-end-color: #d9f99de6;
}

.border-e-lime-200\/95 {
  border-inline-end-color: #d9f99df2;
}

.border-e-lime-300 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-e-lime-300\/0 {
  border-inline-end-color: #bef26400;
}

.border-e-lime-300\/10 {
  border-inline-end-color: #bef2641a;
}

.border-e-lime-300\/100 {
  border-inline-end-color: #bef264;
}

.border-e-lime-300\/20 {
  border-inline-end-color: #bef26433;
}

.border-e-lime-300\/25 {
  border-inline-end-color: #bef26440;
}

.border-e-lime-300\/30 {
  border-inline-end-color: #bef2644d;
}

.border-e-lime-300\/40 {
  border-inline-end-color: #bef26466;
}

.border-e-lime-300\/5 {
  border-inline-end-color: #bef2640d;
}

.border-e-lime-300\/50 {
  border-inline-end-color: #bef26480;
}

.border-e-lime-300\/60 {
  border-inline-end-color: #bef26499;
}

.border-e-lime-300\/70 {
  border-inline-end-color: #bef264b3;
}

.border-e-lime-300\/75 {
  border-inline-end-color: #bef264bf;
}

.border-e-lime-300\/80 {
  border-inline-end-color: #bef264cc;
}

.border-e-lime-300\/90 {
  border-inline-end-color: #bef264e6;
}

.border-e-lime-300\/95 {
  border-inline-end-color: #bef264f2;
}

.border-e-lime-400 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-e-lime-400\/0 {
  border-inline-end-color: #a3e63500;
}

.border-e-lime-400\/10 {
  border-inline-end-color: #a3e6351a;
}

.border-e-lime-400\/100 {
  border-inline-end-color: #a3e635;
}

.border-e-lime-400\/20 {
  border-inline-end-color: #a3e63533;
}

.border-e-lime-400\/25 {
  border-inline-end-color: #a3e63540;
}

.border-e-lime-400\/30 {
  border-inline-end-color: #a3e6354d;
}

.border-e-lime-400\/40 {
  border-inline-end-color: #a3e63566;
}

.border-e-lime-400\/5 {
  border-inline-end-color: #a3e6350d;
}

.border-e-lime-400\/50 {
  border-inline-end-color: #a3e63580;
}

.border-e-lime-400\/60 {
  border-inline-end-color: #a3e63599;
}

.border-e-lime-400\/70 {
  border-inline-end-color: #a3e635b3;
}

.border-e-lime-400\/75 {
  border-inline-end-color: #a3e635bf;
}

.border-e-lime-400\/80 {
  border-inline-end-color: #a3e635cc;
}

.border-e-lime-400\/90 {
  border-inline-end-color: #a3e635e6;
}

.border-e-lime-400\/95 {
  border-inline-end-color: #a3e635f2;
}

.border-e-lime-50 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-e-lime-50\/0 {
  border-inline-end-color: #f7fee700;
}

.border-e-lime-50\/10 {
  border-inline-end-color: #f7fee71a;
}

.border-e-lime-50\/100 {
  border-inline-end-color: #f7fee7;
}

.border-e-lime-50\/20 {
  border-inline-end-color: #f7fee733;
}

.border-e-lime-50\/25 {
  border-inline-end-color: #f7fee740;
}

.border-e-lime-50\/30 {
  border-inline-end-color: #f7fee74d;
}

.border-e-lime-50\/40 {
  border-inline-end-color: #f7fee766;
}

.border-e-lime-50\/5 {
  border-inline-end-color: #f7fee70d;
}

.border-e-lime-50\/50 {
  border-inline-end-color: #f7fee780;
}

.border-e-lime-50\/60 {
  border-inline-end-color: #f7fee799;
}

.border-e-lime-50\/70 {
  border-inline-end-color: #f7fee7b3;
}

.border-e-lime-50\/75 {
  border-inline-end-color: #f7fee7bf;
}

.border-e-lime-50\/80 {
  border-inline-end-color: #f7fee7cc;
}

.border-e-lime-50\/90 {
  border-inline-end-color: #f7fee7e6;
}

.border-e-lime-50\/95 {
  border-inline-end-color: #f7fee7f2;
}

.border-e-lime-500 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-e-lime-500\/0 {
  border-inline-end-color: #84cc1600;
}

.border-e-lime-500\/10 {
  border-inline-end-color: #84cc161a;
}

.border-e-lime-500\/100 {
  border-inline-end-color: #84cc16;
}

.border-e-lime-500\/20 {
  border-inline-end-color: #84cc1633;
}

.border-e-lime-500\/25 {
  border-inline-end-color: #84cc1640;
}

.border-e-lime-500\/30 {
  border-inline-end-color: #84cc164d;
}

.border-e-lime-500\/40 {
  border-inline-end-color: #84cc1666;
}

.border-e-lime-500\/5 {
  border-inline-end-color: #84cc160d;
}

.border-e-lime-500\/50 {
  border-inline-end-color: #84cc1680;
}

.border-e-lime-500\/60 {
  border-inline-end-color: #84cc1699;
}

.border-e-lime-500\/70 {
  border-inline-end-color: #84cc16b3;
}

.border-e-lime-500\/75 {
  border-inline-end-color: #84cc16bf;
}

.border-e-lime-500\/80 {
  border-inline-end-color: #84cc16cc;
}

.border-e-lime-500\/90 {
  border-inline-end-color: #84cc16e6;
}

.border-e-lime-500\/95 {
  border-inline-end-color: #84cc16f2;
}

.border-e-lime-600 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-e-lime-600\/0 {
  border-inline-end-color: #65a30d00;
}

.border-e-lime-600\/10 {
  border-inline-end-color: #65a30d1a;
}

.border-e-lime-600\/100 {
  border-inline-end-color: #65a30d;
}

.border-e-lime-600\/20 {
  border-inline-end-color: #65a30d33;
}

.border-e-lime-600\/25 {
  border-inline-end-color: #65a30d40;
}

.border-e-lime-600\/30 {
  border-inline-end-color: #65a30d4d;
}

.border-e-lime-600\/40 {
  border-inline-end-color: #65a30d66;
}

.border-e-lime-600\/5 {
  border-inline-end-color: #65a30d0d;
}

.border-e-lime-600\/50 {
  border-inline-end-color: #65a30d80;
}

.border-e-lime-600\/60 {
  border-inline-end-color: #65a30d99;
}

.border-e-lime-600\/70 {
  border-inline-end-color: #65a30db3;
}

.border-e-lime-600\/75 {
  border-inline-end-color: #65a30dbf;
}

.border-e-lime-600\/80 {
  border-inline-end-color: #65a30dcc;
}

.border-e-lime-600\/90 {
  border-inline-end-color: #65a30de6;
}

.border-e-lime-600\/95 {
  border-inline-end-color: #65a30df2;
}

.border-e-lime-700 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-e-lime-700\/0 {
  border-inline-end-color: #4d7c0f00;
}

.border-e-lime-700\/10 {
  border-inline-end-color: #4d7c0f1a;
}

.border-e-lime-700\/100 {
  border-inline-end-color: #4d7c0f;
}

.border-e-lime-700\/20 {
  border-inline-end-color: #4d7c0f33;
}

.border-e-lime-700\/25 {
  border-inline-end-color: #4d7c0f40;
}

.border-e-lime-700\/30 {
  border-inline-end-color: #4d7c0f4d;
}

.border-e-lime-700\/40 {
  border-inline-end-color: #4d7c0f66;
}

.border-e-lime-700\/5 {
  border-inline-end-color: #4d7c0f0d;
}

.border-e-lime-700\/50 {
  border-inline-end-color: #4d7c0f80;
}

.border-e-lime-700\/60 {
  border-inline-end-color: #4d7c0f99;
}

.border-e-lime-700\/70 {
  border-inline-end-color: #4d7c0fb3;
}

.border-e-lime-700\/75 {
  border-inline-end-color: #4d7c0fbf;
}

.border-e-lime-700\/80 {
  border-inline-end-color: #4d7c0fcc;
}

.border-e-lime-700\/90 {
  border-inline-end-color: #4d7c0fe6;
}

.border-e-lime-700\/95 {
  border-inline-end-color: #4d7c0ff2;
}

.border-e-lime-800 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-e-lime-800\/0 {
  border-inline-end-color: #3f621200;
}

.border-e-lime-800\/10 {
  border-inline-end-color: #3f62121a;
}

.border-e-lime-800\/100 {
  border-inline-end-color: #3f6212;
}

.border-e-lime-800\/20 {
  border-inline-end-color: #3f621233;
}

.border-e-lime-800\/25 {
  border-inline-end-color: #3f621240;
}

.border-e-lime-800\/30 {
  border-inline-end-color: #3f62124d;
}

.border-e-lime-800\/40 {
  border-inline-end-color: #3f621266;
}

.border-e-lime-800\/5 {
  border-inline-end-color: #3f62120d;
}

.border-e-lime-800\/50 {
  border-inline-end-color: #3f621280;
}

.border-e-lime-800\/60 {
  border-inline-end-color: #3f621299;
}

.border-e-lime-800\/70 {
  border-inline-end-color: #3f6212b3;
}

.border-e-lime-800\/75 {
  border-inline-end-color: #3f6212bf;
}

.border-e-lime-800\/80 {
  border-inline-end-color: #3f6212cc;
}

.border-e-lime-800\/90 {
  border-inline-end-color: #3f6212e6;
}

.border-e-lime-800\/95 {
  border-inline-end-color: #3f6212f2;
}

.border-e-lime-900 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-e-lime-900\/0 {
  border-inline-end-color: #36531400;
}

.border-e-lime-900\/10 {
  border-inline-end-color: #3653141a;
}

.border-e-lime-900\/100 {
  border-inline-end-color: #365314;
}

.border-e-lime-900\/20 {
  border-inline-end-color: #36531433;
}

.border-e-lime-900\/25 {
  border-inline-end-color: #36531440;
}

.border-e-lime-900\/30 {
  border-inline-end-color: #3653144d;
}

.border-e-lime-900\/40 {
  border-inline-end-color: #36531466;
}

.border-e-lime-900\/5 {
  border-inline-end-color: #3653140d;
}

.border-e-lime-900\/50 {
  border-inline-end-color: #36531480;
}

.border-e-lime-900\/60 {
  border-inline-end-color: #36531499;
}

.border-e-lime-900\/70 {
  border-inline-end-color: #365314b3;
}

.border-e-lime-900\/75 {
  border-inline-end-color: #365314bf;
}

.border-e-lime-900\/80 {
  border-inline-end-color: #365314cc;
}

.border-e-lime-900\/90 {
  border-inline-end-color: #365314e6;
}

.border-e-lime-900\/95 {
  border-inline-end-color: #365314f2;
}

.border-e-lime-950 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-e-lime-950\/0 {
  border-inline-end-color: #1a2e0500;
}

.border-e-lime-950\/10 {
  border-inline-end-color: #1a2e051a;
}

.border-e-lime-950\/100 {
  border-inline-end-color: #1a2e05;
}

.border-e-lime-950\/20 {
  border-inline-end-color: #1a2e0533;
}

.border-e-lime-950\/25 {
  border-inline-end-color: #1a2e0540;
}

.border-e-lime-950\/30 {
  border-inline-end-color: #1a2e054d;
}

.border-e-lime-950\/40 {
  border-inline-end-color: #1a2e0566;
}

.border-e-lime-950\/5 {
  border-inline-end-color: #1a2e050d;
}

.border-e-lime-950\/50 {
  border-inline-end-color: #1a2e0580;
}

.border-e-lime-950\/60 {
  border-inline-end-color: #1a2e0599;
}

.border-e-lime-950\/70 {
  border-inline-end-color: #1a2e05b3;
}

.border-e-lime-950\/75 {
  border-inline-end-color: #1a2e05bf;
}

.border-e-lime-950\/80 {
  border-inline-end-color: #1a2e05cc;
}

.border-e-lime-950\/90 {
  border-inline-end-color: #1a2e05e6;
}

.border-e-lime-950\/95 {
  border-inline-end-color: #1a2e05f2;
}

.border-e-pink-100 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-e-pink-100\/0 {
  border-inline-end-color: #fce7f300;
}

.border-e-pink-100\/10 {
  border-inline-end-color: #fce7f31a;
}

.border-e-pink-100\/100 {
  border-inline-end-color: #fce7f3;
}

.border-e-pink-100\/20 {
  border-inline-end-color: #fce7f333;
}

.border-e-pink-100\/25 {
  border-inline-end-color: #fce7f340;
}

.border-e-pink-100\/30 {
  border-inline-end-color: #fce7f34d;
}

.border-e-pink-100\/40 {
  border-inline-end-color: #fce7f366;
}

.border-e-pink-100\/5 {
  border-inline-end-color: #fce7f30d;
}

.border-e-pink-100\/50 {
  border-inline-end-color: #fce7f380;
}

.border-e-pink-100\/60 {
  border-inline-end-color: #fce7f399;
}

.border-e-pink-100\/70 {
  border-inline-end-color: #fce7f3b3;
}

.border-e-pink-100\/75 {
  border-inline-end-color: #fce7f3bf;
}

.border-e-pink-100\/80 {
  border-inline-end-color: #fce7f3cc;
}

.border-e-pink-100\/90 {
  border-inline-end-color: #fce7f3e6;
}

.border-e-pink-100\/95 {
  border-inline-end-color: #fce7f3f2;
}

.border-e-pink-200 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-e-pink-200\/0 {
  border-inline-end-color: #fbcfe800;
}

.border-e-pink-200\/10 {
  border-inline-end-color: #fbcfe81a;
}

.border-e-pink-200\/100 {
  border-inline-end-color: #fbcfe8;
}

.border-e-pink-200\/20 {
  border-inline-end-color: #fbcfe833;
}

.border-e-pink-200\/25 {
  border-inline-end-color: #fbcfe840;
}

.border-e-pink-200\/30 {
  border-inline-end-color: #fbcfe84d;
}

.border-e-pink-200\/40 {
  border-inline-end-color: #fbcfe866;
}

.border-e-pink-200\/5 {
  border-inline-end-color: #fbcfe80d;
}

.border-e-pink-200\/50 {
  border-inline-end-color: #fbcfe880;
}

.border-e-pink-200\/60 {
  border-inline-end-color: #fbcfe899;
}

.border-e-pink-200\/70 {
  border-inline-end-color: #fbcfe8b3;
}

.border-e-pink-200\/75 {
  border-inline-end-color: #fbcfe8bf;
}

.border-e-pink-200\/80 {
  border-inline-end-color: #fbcfe8cc;
}

.border-e-pink-200\/90 {
  border-inline-end-color: #fbcfe8e6;
}

.border-e-pink-200\/95 {
  border-inline-end-color: #fbcfe8f2;
}

.border-e-pink-300 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-e-pink-300\/0 {
  border-inline-end-color: #f9a8d400;
}

.border-e-pink-300\/10 {
  border-inline-end-color: #f9a8d41a;
}

.border-e-pink-300\/100 {
  border-inline-end-color: #f9a8d4;
}

.border-e-pink-300\/20 {
  border-inline-end-color: #f9a8d433;
}

.border-e-pink-300\/25 {
  border-inline-end-color: #f9a8d440;
}

.border-e-pink-300\/30 {
  border-inline-end-color: #f9a8d44d;
}

.border-e-pink-300\/40 {
  border-inline-end-color: #f9a8d466;
}

.border-e-pink-300\/5 {
  border-inline-end-color: #f9a8d40d;
}

.border-e-pink-300\/50 {
  border-inline-end-color: #f9a8d480;
}

.border-e-pink-300\/60 {
  border-inline-end-color: #f9a8d499;
}

.border-e-pink-300\/70 {
  border-inline-end-color: #f9a8d4b3;
}

.border-e-pink-300\/75 {
  border-inline-end-color: #f9a8d4bf;
}

.border-e-pink-300\/80 {
  border-inline-end-color: #f9a8d4cc;
}

.border-e-pink-300\/90 {
  border-inline-end-color: #f9a8d4e6;
}

.border-e-pink-300\/95 {
  border-inline-end-color: #f9a8d4f2;
}

.border-e-pink-400 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-e-pink-400\/0 {
  border-inline-end-color: #f472b600;
}

.border-e-pink-400\/10 {
  border-inline-end-color: #f472b61a;
}

.border-e-pink-400\/100 {
  border-inline-end-color: #f472b6;
}

.border-e-pink-400\/20 {
  border-inline-end-color: #f472b633;
}

.border-e-pink-400\/25 {
  border-inline-end-color: #f472b640;
}

.border-e-pink-400\/30 {
  border-inline-end-color: #f472b64d;
}

.border-e-pink-400\/40 {
  border-inline-end-color: #f472b666;
}

.border-e-pink-400\/5 {
  border-inline-end-color: #f472b60d;
}

.border-e-pink-400\/50 {
  border-inline-end-color: #f472b680;
}

.border-e-pink-400\/60 {
  border-inline-end-color: #f472b699;
}

.border-e-pink-400\/70 {
  border-inline-end-color: #f472b6b3;
}

.border-e-pink-400\/75 {
  border-inline-end-color: #f472b6bf;
}

.border-e-pink-400\/80 {
  border-inline-end-color: #f472b6cc;
}

.border-e-pink-400\/90 {
  border-inline-end-color: #f472b6e6;
}

.border-e-pink-400\/95 {
  border-inline-end-color: #f472b6f2;
}

.border-e-pink-50 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-e-pink-50\/0 {
  border-inline-end-color: #fdf2f800;
}

.border-e-pink-50\/10 {
  border-inline-end-color: #fdf2f81a;
}

.border-e-pink-50\/100 {
  border-inline-end-color: #fdf2f8;
}

.border-e-pink-50\/20 {
  border-inline-end-color: #fdf2f833;
}

.border-e-pink-50\/25 {
  border-inline-end-color: #fdf2f840;
}

.border-e-pink-50\/30 {
  border-inline-end-color: #fdf2f84d;
}

.border-e-pink-50\/40 {
  border-inline-end-color: #fdf2f866;
}

.border-e-pink-50\/5 {
  border-inline-end-color: #fdf2f80d;
}

.border-e-pink-50\/50 {
  border-inline-end-color: #fdf2f880;
}

.border-e-pink-50\/60 {
  border-inline-end-color: #fdf2f899;
}

.border-e-pink-50\/70 {
  border-inline-end-color: #fdf2f8b3;
}

.border-e-pink-50\/75 {
  border-inline-end-color: #fdf2f8bf;
}

.border-e-pink-50\/80 {
  border-inline-end-color: #fdf2f8cc;
}

.border-e-pink-50\/90 {
  border-inline-end-color: #fdf2f8e6;
}

.border-e-pink-50\/95 {
  border-inline-end-color: #fdf2f8f2;
}

.border-e-pink-500 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-e-pink-500\/0 {
  border-inline-end-color: #ec489900;
}

.border-e-pink-500\/10 {
  border-inline-end-color: #ec48991a;
}

.border-e-pink-500\/100 {
  border-inline-end-color: #ec4899;
}

.border-e-pink-500\/20 {
  border-inline-end-color: #ec489933;
}

.border-e-pink-500\/25 {
  border-inline-end-color: #ec489940;
}

.border-e-pink-500\/30 {
  border-inline-end-color: #ec48994d;
}

.border-e-pink-500\/40 {
  border-inline-end-color: #ec489966;
}

.border-e-pink-500\/5 {
  border-inline-end-color: #ec48990d;
}

.border-e-pink-500\/50 {
  border-inline-end-color: #ec489980;
}

.border-e-pink-500\/60 {
  border-inline-end-color: #ec489999;
}

.border-e-pink-500\/70 {
  border-inline-end-color: #ec4899b3;
}

.border-e-pink-500\/75 {
  border-inline-end-color: #ec4899bf;
}

.border-e-pink-500\/80 {
  border-inline-end-color: #ec4899cc;
}

.border-e-pink-500\/90 {
  border-inline-end-color: #ec4899e6;
}

.border-e-pink-500\/95 {
  border-inline-end-color: #ec4899f2;
}

.border-e-pink-600 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-e-pink-600\/0 {
  border-inline-end-color: #db277700;
}

.border-e-pink-600\/10 {
  border-inline-end-color: #db27771a;
}

.border-e-pink-600\/100 {
  border-inline-end-color: #db2777;
}

.border-e-pink-600\/20 {
  border-inline-end-color: #db277733;
}

.border-e-pink-600\/25 {
  border-inline-end-color: #db277740;
}

.border-e-pink-600\/30 {
  border-inline-end-color: #db27774d;
}

.border-e-pink-600\/40 {
  border-inline-end-color: #db277766;
}

.border-e-pink-600\/5 {
  border-inline-end-color: #db27770d;
}

.border-e-pink-600\/50 {
  border-inline-end-color: #db277780;
}

.border-e-pink-600\/60 {
  border-inline-end-color: #db277799;
}

.border-e-pink-600\/70 {
  border-inline-end-color: #db2777b3;
}

.border-e-pink-600\/75 {
  border-inline-end-color: #db2777bf;
}

.border-e-pink-600\/80 {
  border-inline-end-color: #db2777cc;
}

.border-e-pink-600\/90 {
  border-inline-end-color: #db2777e6;
}

.border-e-pink-600\/95 {
  border-inline-end-color: #db2777f2;
}

.border-e-pink-700 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-e-pink-700\/0 {
  border-inline-end-color: #be185d00;
}

.border-e-pink-700\/10 {
  border-inline-end-color: #be185d1a;
}

.border-e-pink-700\/100 {
  border-inline-end-color: #be185d;
}

.border-e-pink-700\/20 {
  border-inline-end-color: #be185d33;
}

.border-e-pink-700\/25 {
  border-inline-end-color: #be185d40;
}

.border-e-pink-700\/30 {
  border-inline-end-color: #be185d4d;
}

.border-e-pink-700\/40 {
  border-inline-end-color: #be185d66;
}

.border-e-pink-700\/5 {
  border-inline-end-color: #be185d0d;
}

.border-e-pink-700\/50 {
  border-inline-end-color: #be185d80;
}

.border-e-pink-700\/60 {
  border-inline-end-color: #be185d99;
}

.border-e-pink-700\/70 {
  border-inline-end-color: #be185db3;
}

.border-e-pink-700\/75 {
  border-inline-end-color: #be185dbf;
}

.border-e-pink-700\/80 {
  border-inline-end-color: #be185dcc;
}

.border-e-pink-700\/90 {
  border-inline-end-color: #be185de6;
}

.border-e-pink-700\/95 {
  border-inline-end-color: #be185df2;
}

.border-e-pink-800 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-e-pink-800\/0 {
  border-inline-end-color: #9d174d00;
}

.border-e-pink-800\/10 {
  border-inline-end-color: #9d174d1a;
}

.border-e-pink-800\/100 {
  border-inline-end-color: #9d174d;
}

.border-e-pink-800\/20 {
  border-inline-end-color: #9d174d33;
}

.border-e-pink-800\/25 {
  border-inline-end-color: #9d174d40;
}

.border-e-pink-800\/30 {
  border-inline-end-color: #9d174d4d;
}

.border-e-pink-800\/40 {
  border-inline-end-color: #9d174d66;
}

.border-e-pink-800\/5 {
  border-inline-end-color: #9d174d0d;
}

.border-e-pink-800\/50 {
  border-inline-end-color: #9d174d80;
}

.border-e-pink-800\/60 {
  border-inline-end-color: #9d174d99;
}

.border-e-pink-800\/70 {
  border-inline-end-color: #9d174db3;
}

.border-e-pink-800\/75 {
  border-inline-end-color: #9d174dbf;
}

.border-e-pink-800\/80 {
  border-inline-end-color: #9d174dcc;
}

.border-e-pink-800\/90 {
  border-inline-end-color: #9d174de6;
}

.border-e-pink-800\/95 {
  border-inline-end-color: #9d174df2;
}

.border-e-pink-900 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-e-pink-900\/0 {
  border-inline-end-color: #83184300;
}

.border-e-pink-900\/10 {
  border-inline-end-color: #8318431a;
}

.border-e-pink-900\/100 {
  border-inline-end-color: #831843;
}

.border-e-pink-900\/20 {
  border-inline-end-color: #83184333;
}

.border-e-pink-900\/25 {
  border-inline-end-color: #83184340;
}

.border-e-pink-900\/30 {
  border-inline-end-color: #8318434d;
}

.border-e-pink-900\/40 {
  border-inline-end-color: #83184366;
}

.border-e-pink-900\/5 {
  border-inline-end-color: #8318430d;
}

.border-e-pink-900\/50 {
  border-inline-end-color: #83184380;
}

.border-e-pink-900\/60 {
  border-inline-end-color: #83184399;
}

.border-e-pink-900\/70 {
  border-inline-end-color: #831843b3;
}

.border-e-pink-900\/75 {
  border-inline-end-color: #831843bf;
}

.border-e-pink-900\/80 {
  border-inline-end-color: #831843cc;
}

.border-e-pink-900\/90 {
  border-inline-end-color: #831843e6;
}

.border-e-pink-900\/95 {
  border-inline-end-color: #831843f2;
}

.border-e-pink-950 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-e-pink-950\/0 {
  border-inline-end-color: #50072400;
}

.border-e-pink-950\/10 {
  border-inline-end-color: #5007241a;
}

.border-e-pink-950\/100 {
  border-inline-end-color: #500724;
}

.border-e-pink-950\/20 {
  border-inline-end-color: #50072433;
}

.border-e-pink-950\/25 {
  border-inline-end-color: #50072440;
}

.border-e-pink-950\/30 {
  border-inline-end-color: #5007244d;
}

.border-e-pink-950\/40 {
  border-inline-end-color: #50072466;
}

.border-e-pink-950\/5 {
  border-inline-end-color: #5007240d;
}

.border-e-pink-950\/50 {
  border-inline-end-color: #50072480;
}

.border-e-pink-950\/60 {
  border-inline-end-color: #50072499;
}

.border-e-pink-950\/70 {
  border-inline-end-color: #500724b3;
}

.border-e-pink-950\/75 {
  border-inline-end-color: #500724bf;
}

.border-e-pink-950\/80 {
  border-inline-end-color: #500724cc;
}

.border-e-pink-950\/90 {
  border-inline-end-color: #500724e6;
}

.border-e-pink-950\/95 {
  border-inline-end-color: #500724f2;
}

.border-e-primary {
  --tw-border-opacity: 1;
  border-inline-end-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-e-primary\/0 {
  border-inline-end-color: hsl(var(--primary) / 0);
}

.border-e-primary\/10 {
  border-inline-end-color: hsl(var(--primary) / .1);
}

.border-e-primary\/100 {
  border-inline-end-color: hsl(var(--primary) / 1);
}

.border-e-primary\/20 {
  border-inline-end-color: hsl(var(--primary) / .2);
}

.border-e-primary\/25 {
  border-inline-end-color: hsl(var(--primary) / .25);
}

.border-e-primary\/30 {
  border-inline-end-color: hsl(var(--primary) / .3);
}

.border-e-primary\/40 {
  border-inline-end-color: hsl(var(--primary) / .4);
}

.border-e-primary\/5 {
  border-inline-end-color: hsl(var(--primary) / .05);
}

.border-e-primary\/50 {
  border-inline-end-color: hsl(var(--primary) / .5);
}

.border-e-primary\/60 {
  border-inline-end-color: hsl(var(--primary) / .6);
}

.border-e-primary\/70 {
  border-inline-end-color: hsl(var(--primary) / .7);
}

.border-e-primary\/75 {
  border-inline-end-color: hsl(var(--primary) / .75);
}

.border-e-primary\/80 {
  border-inline-end-color: hsl(var(--primary) / .8);
}

.border-e-primary\/90 {
  border-inline-end-color: hsl(var(--primary) / .9);
}

.border-e-primary\/95 {
  border-inline-end-color: hsl(var(--primary) / .95);
}

.border-e-purple-100 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-e-purple-100\/0 {
  border-inline-end-color: #f3e8ff00;
}

.border-e-purple-100\/10 {
  border-inline-end-color: #f3e8ff1a;
}

.border-e-purple-100\/100 {
  border-inline-end-color: #f3e8ff;
}

.border-e-purple-100\/20 {
  border-inline-end-color: #f3e8ff33;
}

.border-e-purple-100\/25 {
  border-inline-end-color: #f3e8ff40;
}

.border-e-purple-100\/30 {
  border-inline-end-color: #f3e8ff4d;
}

.border-e-purple-100\/40 {
  border-inline-end-color: #f3e8ff66;
}

.border-e-purple-100\/5 {
  border-inline-end-color: #f3e8ff0d;
}

.border-e-purple-100\/50 {
  border-inline-end-color: #f3e8ff80;
}

.border-e-purple-100\/60 {
  border-inline-end-color: #f3e8ff99;
}

.border-e-purple-100\/70 {
  border-inline-end-color: #f3e8ffb3;
}

.border-e-purple-100\/75 {
  border-inline-end-color: #f3e8ffbf;
}

.border-e-purple-100\/80 {
  border-inline-end-color: #f3e8ffcc;
}

.border-e-purple-100\/90 {
  border-inline-end-color: #f3e8ffe6;
}

.border-e-purple-100\/95 {
  border-inline-end-color: #f3e8fff2;
}

.border-e-purple-200 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-e-purple-200\/0 {
  border-inline-end-color: #e9d5ff00;
}

.border-e-purple-200\/10 {
  border-inline-end-color: #e9d5ff1a;
}

.border-e-purple-200\/100 {
  border-inline-end-color: #e9d5ff;
}

.border-e-purple-200\/20 {
  border-inline-end-color: #e9d5ff33;
}

.border-e-purple-200\/25 {
  border-inline-end-color: #e9d5ff40;
}

.border-e-purple-200\/30 {
  border-inline-end-color: #e9d5ff4d;
}

.border-e-purple-200\/40 {
  border-inline-end-color: #e9d5ff66;
}

.border-e-purple-200\/5 {
  border-inline-end-color: #e9d5ff0d;
}

.border-e-purple-200\/50 {
  border-inline-end-color: #e9d5ff80;
}

.border-e-purple-200\/60 {
  border-inline-end-color: #e9d5ff99;
}

.border-e-purple-200\/70 {
  border-inline-end-color: #e9d5ffb3;
}

.border-e-purple-200\/75 {
  border-inline-end-color: #e9d5ffbf;
}

.border-e-purple-200\/80 {
  border-inline-end-color: #e9d5ffcc;
}

.border-e-purple-200\/90 {
  border-inline-end-color: #e9d5ffe6;
}

.border-e-purple-200\/95 {
  border-inline-end-color: #e9d5fff2;
}

.border-e-purple-300 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-e-purple-300\/0 {
  border-inline-end-color: #d8b4fe00;
}

.border-e-purple-300\/10 {
  border-inline-end-color: #d8b4fe1a;
}

.border-e-purple-300\/100 {
  border-inline-end-color: #d8b4fe;
}

.border-e-purple-300\/20 {
  border-inline-end-color: #d8b4fe33;
}

.border-e-purple-300\/25 {
  border-inline-end-color: #d8b4fe40;
}

.border-e-purple-300\/30 {
  border-inline-end-color: #d8b4fe4d;
}

.border-e-purple-300\/40 {
  border-inline-end-color: #d8b4fe66;
}

.border-e-purple-300\/5 {
  border-inline-end-color: #d8b4fe0d;
}

.border-e-purple-300\/50 {
  border-inline-end-color: #d8b4fe80;
}

.border-e-purple-300\/60 {
  border-inline-end-color: #d8b4fe99;
}

.border-e-purple-300\/70 {
  border-inline-end-color: #d8b4feb3;
}

.border-e-purple-300\/75 {
  border-inline-end-color: #d8b4febf;
}

.border-e-purple-300\/80 {
  border-inline-end-color: #d8b4fecc;
}

.border-e-purple-300\/90 {
  border-inline-end-color: #d8b4fee6;
}

.border-e-purple-300\/95 {
  border-inline-end-color: #d8b4fef2;
}

.border-e-purple-400 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-e-purple-400\/0 {
  border-inline-end-color: #c084fc00;
}

.border-e-purple-400\/10 {
  border-inline-end-color: #c084fc1a;
}

.border-e-purple-400\/100 {
  border-inline-end-color: #c084fc;
}

.border-e-purple-400\/20 {
  border-inline-end-color: #c084fc33;
}

.border-e-purple-400\/25 {
  border-inline-end-color: #c084fc40;
}

.border-e-purple-400\/30 {
  border-inline-end-color: #c084fc4d;
}

.border-e-purple-400\/40 {
  border-inline-end-color: #c084fc66;
}

.border-e-purple-400\/5 {
  border-inline-end-color: #c084fc0d;
}

.border-e-purple-400\/50 {
  border-inline-end-color: #c084fc80;
}

.border-e-purple-400\/60 {
  border-inline-end-color: #c084fc99;
}

.border-e-purple-400\/70 {
  border-inline-end-color: #c084fcb3;
}

.border-e-purple-400\/75 {
  border-inline-end-color: #c084fcbf;
}

.border-e-purple-400\/80 {
  border-inline-end-color: #c084fccc;
}

.border-e-purple-400\/90 {
  border-inline-end-color: #c084fce6;
}

.border-e-purple-400\/95 {
  border-inline-end-color: #c084fcf2;
}

.border-e-purple-50 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-e-purple-50\/0 {
  border-inline-end-color: #faf5ff00;
}

.border-e-purple-50\/10 {
  border-inline-end-color: #faf5ff1a;
}

.border-e-purple-50\/100 {
  border-inline-end-color: #faf5ff;
}

.border-e-purple-50\/20 {
  border-inline-end-color: #faf5ff33;
}

.border-e-purple-50\/25 {
  border-inline-end-color: #faf5ff40;
}

.border-e-purple-50\/30 {
  border-inline-end-color: #faf5ff4d;
}

.border-e-purple-50\/40 {
  border-inline-end-color: #faf5ff66;
}

.border-e-purple-50\/5 {
  border-inline-end-color: #faf5ff0d;
}

.border-e-purple-50\/50 {
  border-inline-end-color: #faf5ff80;
}

.border-e-purple-50\/60 {
  border-inline-end-color: #faf5ff99;
}

.border-e-purple-50\/70 {
  border-inline-end-color: #faf5ffb3;
}

.border-e-purple-50\/75 {
  border-inline-end-color: #faf5ffbf;
}

.border-e-purple-50\/80 {
  border-inline-end-color: #faf5ffcc;
}

.border-e-purple-50\/90 {
  border-inline-end-color: #faf5ffe6;
}

.border-e-purple-50\/95 {
  border-inline-end-color: #faf5fff2;
}

.border-e-purple-500 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-e-purple-500\/0 {
  border-inline-end-color: #a855f700;
}

.border-e-purple-500\/10 {
  border-inline-end-color: #a855f71a;
}

.border-e-purple-500\/100 {
  border-inline-end-color: #a855f7;
}

.border-e-purple-500\/20 {
  border-inline-end-color: #a855f733;
}

.border-e-purple-500\/25 {
  border-inline-end-color: #a855f740;
}

.border-e-purple-500\/30 {
  border-inline-end-color: #a855f74d;
}

.border-e-purple-500\/40 {
  border-inline-end-color: #a855f766;
}

.border-e-purple-500\/5 {
  border-inline-end-color: #a855f70d;
}

.border-e-purple-500\/50 {
  border-inline-end-color: #a855f780;
}

.border-e-purple-500\/60 {
  border-inline-end-color: #a855f799;
}

.border-e-purple-500\/70 {
  border-inline-end-color: #a855f7b3;
}

.border-e-purple-500\/75 {
  border-inline-end-color: #a855f7bf;
}

.border-e-purple-500\/80 {
  border-inline-end-color: #a855f7cc;
}

.border-e-purple-500\/90 {
  border-inline-end-color: #a855f7e6;
}

.border-e-purple-500\/95 {
  border-inline-end-color: #a855f7f2;
}

.border-e-purple-600 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-e-purple-600\/0 {
  border-inline-end-color: #9333ea00;
}

.border-e-purple-600\/10 {
  border-inline-end-color: #9333ea1a;
}

.border-e-purple-600\/100 {
  border-inline-end-color: #9333ea;
}

.border-e-purple-600\/20 {
  border-inline-end-color: #9333ea33;
}

.border-e-purple-600\/25 {
  border-inline-end-color: #9333ea40;
}

.border-e-purple-600\/30 {
  border-inline-end-color: #9333ea4d;
}

.border-e-purple-600\/40 {
  border-inline-end-color: #9333ea66;
}

.border-e-purple-600\/5 {
  border-inline-end-color: #9333ea0d;
}

.border-e-purple-600\/50 {
  border-inline-end-color: #9333ea80;
}

.border-e-purple-600\/60 {
  border-inline-end-color: #9333ea99;
}

.border-e-purple-600\/70 {
  border-inline-end-color: #9333eab3;
}

.border-e-purple-600\/75 {
  border-inline-end-color: #9333eabf;
}

.border-e-purple-600\/80 {
  border-inline-end-color: #9333eacc;
}

.border-e-purple-600\/90 {
  border-inline-end-color: #9333eae6;
}

.border-e-purple-600\/95 {
  border-inline-end-color: #9333eaf2;
}

.border-e-purple-700 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-e-purple-700\/0 {
  border-inline-end-color: #7e22ce00;
}

.border-e-purple-700\/10 {
  border-inline-end-color: #7e22ce1a;
}

.border-e-purple-700\/100 {
  border-inline-end-color: #7e22ce;
}

.border-e-purple-700\/20 {
  border-inline-end-color: #7e22ce33;
}

.border-e-purple-700\/25 {
  border-inline-end-color: #7e22ce40;
}

.border-e-purple-700\/30 {
  border-inline-end-color: #7e22ce4d;
}

.border-e-purple-700\/40 {
  border-inline-end-color: #7e22ce66;
}

.border-e-purple-700\/5 {
  border-inline-end-color: #7e22ce0d;
}

.border-e-purple-700\/50 {
  border-inline-end-color: #7e22ce80;
}

.border-e-purple-700\/60 {
  border-inline-end-color: #7e22ce99;
}

.border-e-purple-700\/70 {
  border-inline-end-color: #7e22ceb3;
}

.border-e-purple-700\/75 {
  border-inline-end-color: #7e22cebf;
}

.border-e-purple-700\/80 {
  border-inline-end-color: #7e22cecc;
}

.border-e-purple-700\/90 {
  border-inline-end-color: #7e22cee6;
}

.border-e-purple-700\/95 {
  border-inline-end-color: #7e22cef2;
}

.border-e-purple-800 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-e-purple-800\/0 {
  border-inline-end-color: #6b21a800;
}

.border-e-purple-800\/10 {
  border-inline-end-color: #6b21a81a;
}

.border-e-purple-800\/100 {
  border-inline-end-color: #6b21a8;
}

.border-e-purple-800\/20 {
  border-inline-end-color: #6b21a833;
}

.border-e-purple-800\/25 {
  border-inline-end-color: #6b21a840;
}

.border-e-purple-800\/30 {
  border-inline-end-color: #6b21a84d;
}

.border-e-purple-800\/40 {
  border-inline-end-color: #6b21a866;
}

.border-e-purple-800\/5 {
  border-inline-end-color: #6b21a80d;
}

.border-e-purple-800\/50 {
  border-inline-end-color: #6b21a880;
}

.border-e-purple-800\/60 {
  border-inline-end-color: #6b21a899;
}

.border-e-purple-800\/70 {
  border-inline-end-color: #6b21a8b3;
}

.border-e-purple-800\/75 {
  border-inline-end-color: #6b21a8bf;
}

.border-e-purple-800\/80 {
  border-inline-end-color: #6b21a8cc;
}

.border-e-purple-800\/90 {
  border-inline-end-color: #6b21a8e6;
}

.border-e-purple-800\/95 {
  border-inline-end-color: #6b21a8f2;
}

.border-e-purple-900 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-e-purple-900\/0 {
  border-inline-end-color: #581c8700;
}

.border-e-purple-900\/10 {
  border-inline-end-color: #581c871a;
}

.border-e-purple-900\/100 {
  border-inline-end-color: #581c87;
}

.border-e-purple-900\/20 {
  border-inline-end-color: #581c8733;
}

.border-e-purple-900\/25 {
  border-inline-end-color: #581c8740;
}

.border-e-purple-900\/30 {
  border-inline-end-color: #581c874d;
}

.border-e-purple-900\/40 {
  border-inline-end-color: #581c8766;
}

.border-e-purple-900\/5 {
  border-inline-end-color: #581c870d;
}

.border-e-purple-900\/50 {
  border-inline-end-color: #581c8780;
}

.border-e-purple-900\/60 {
  border-inline-end-color: #581c8799;
}

.border-e-purple-900\/70 {
  border-inline-end-color: #581c87b3;
}

.border-e-purple-900\/75 {
  border-inline-end-color: #581c87bf;
}

.border-e-purple-900\/80 {
  border-inline-end-color: #581c87cc;
}

.border-e-purple-900\/90 {
  border-inline-end-color: #581c87e6;
}

.border-e-purple-900\/95 {
  border-inline-end-color: #581c87f2;
}

.border-e-purple-950 {
  --tw-border-opacity: 1;
  border-inline-end-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-e-purple-950\/0 {
  border-inline-end-color: #3b076400;
}

.border-e-purple-950\/10 {
  border-inline-end-color: #3b07641a;
}

.border-e-purple-950\/100 {
  border-inline-end-color: #3b0764;
}

.border-e-purple-950\/20 {
  border-inline-end-color: #3b076433;
}

.border-e-purple-950\/25 {
  border-inline-end-color: #3b076440;
}

.border-e-purple-950\/30 {
  border-inline-end-color: #3b07644d;
}

.border-e-purple-950\/40 {
  border-inline-end-color: #3b076466;
}

.border-e-purple-950\/5 {
  border-inline-end-color: #3b07640d;
}

.border-e-purple-950\/50 {
  border-inline-end-color: #3b076480;
}

.border-e-purple-950\/60 {
  border-inline-end-color: #3b076499;
}

.border-e-purple-950\/70 {
  border-inline-end-color: #3b0764b3;
}

.border-e-purple-950\/75 {
  border-inline-end-color: #3b0764bf;
}

.border-e-purple-950\/80 {
  border-inline-end-color: #3b0764cc;
}

.border-e-purple-950\/90 {
  border-inline-end-color: #3b0764e6;
}

.border-e-purple-950\/95 {
  border-inline-end-color: #3b0764f2;
}

.border-e-transparent, .border-e-transparent\/0 {
  border-inline-end-color: #0000;
}

.border-e-transparent\/10 {
  border-inline-end-color: #0000001a;
}

.border-e-transparent\/100 {
  border-inline-end-color: #000;
}

.border-e-transparent\/20 {
  border-inline-end-color: #0003;
}

.border-e-transparent\/25 {
  border-inline-end-color: #00000040;
}

.border-e-transparent\/30 {
  border-inline-end-color: #0000004d;
}

.border-e-transparent\/40 {
  border-inline-end-color: #0006;
}

.border-e-transparent\/5 {
  border-inline-end-color: #0000000d;
}

.border-e-transparent\/50 {
  border-inline-end-color: #00000080;
}

.border-e-transparent\/60 {
  border-inline-end-color: #0009;
}

.border-e-transparent\/70 {
  border-inline-end-color: #000000b3;
}

.border-e-transparent\/75 {
  border-inline-end-color: #000000bf;
}

.border-e-transparent\/80 {
  border-inline-end-color: #000c;
}

.border-e-transparent\/90 {
  border-inline-end-color: #000000e6;
}

.border-e-transparent\/95 {
  border-inline-end-color: #000000f2;
}

.border-l-amber-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-l-amber-100\/0 {
  border-left-color: #fef3c700;
}

.border-l-amber-100\/10 {
  border-left-color: #fef3c71a;
}

.border-l-amber-100\/100 {
  border-left-color: #fef3c7;
}

.border-l-amber-100\/20 {
  border-left-color: #fef3c733;
}

.border-l-amber-100\/25 {
  border-left-color: #fef3c740;
}

.border-l-amber-100\/30 {
  border-left-color: #fef3c74d;
}

.border-l-amber-100\/40 {
  border-left-color: #fef3c766;
}

.border-l-amber-100\/5 {
  border-left-color: #fef3c70d;
}

.border-l-amber-100\/50 {
  border-left-color: #fef3c780;
}

.border-l-amber-100\/60 {
  border-left-color: #fef3c799;
}

.border-l-amber-100\/70 {
  border-left-color: #fef3c7b3;
}

.border-l-amber-100\/75 {
  border-left-color: #fef3c7bf;
}

.border-l-amber-100\/80 {
  border-left-color: #fef3c7cc;
}

.border-l-amber-100\/90 {
  border-left-color: #fef3c7e6;
}

.border-l-amber-100\/95 {
  border-left-color: #fef3c7f2;
}

.border-l-amber-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-l-amber-200\/0 {
  border-left-color: #fde68a00;
}

.border-l-amber-200\/10 {
  border-left-color: #fde68a1a;
}

.border-l-amber-200\/100 {
  border-left-color: #fde68a;
}

.border-l-amber-200\/20 {
  border-left-color: #fde68a33;
}

.border-l-amber-200\/25 {
  border-left-color: #fde68a40;
}

.border-l-amber-200\/30 {
  border-left-color: #fde68a4d;
}

.border-l-amber-200\/40 {
  border-left-color: #fde68a66;
}

.border-l-amber-200\/5 {
  border-left-color: #fde68a0d;
}

.border-l-amber-200\/50 {
  border-left-color: #fde68a80;
}

.border-l-amber-200\/60 {
  border-left-color: #fde68a99;
}

.border-l-amber-200\/70 {
  border-left-color: #fde68ab3;
}

.border-l-amber-200\/75 {
  border-left-color: #fde68abf;
}

.border-l-amber-200\/80 {
  border-left-color: #fde68acc;
}

.border-l-amber-200\/90 {
  border-left-color: #fde68ae6;
}

.border-l-amber-200\/95 {
  border-left-color: #fde68af2;
}

.border-l-amber-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-l-amber-300\/0 {
  border-left-color: #fcd34d00;
}

.border-l-amber-300\/10 {
  border-left-color: #fcd34d1a;
}

.border-l-amber-300\/100 {
  border-left-color: #fcd34d;
}

.border-l-amber-300\/20 {
  border-left-color: #fcd34d33;
}

.border-l-amber-300\/25 {
  border-left-color: #fcd34d40;
}

.border-l-amber-300\/30 {
  border-left-color: #fcd34d4d;
}

.border-l-amber-300\/40 {
  border-left-color: #fcd34d66;
}

.border-l-amber-300\/5 {
  border-left-color: #fcd34d0d;
}

.border-l-amber-300\/50 {
  border-left-color: #fcd34d80;
}

.border-l-amber-300\/60 {
  border-left-color: #fcd34d99;
}

.border-l-amber-300\/70 {
  border-left-color: #fcd34db3;
}

.border-l-amber-300\/75 {
  border-left-color: #fcd34dbf;
}

.border-l-amber-300\/80 {
  border-left-color: #fcd34dcc;
}

.border-l-amber-300\/90 {
  border-left-color: #fcd34de6;
}

.border-l-amber-300\/95 {
  border-left-color: #fcd34df2;
}

.border-l-amber-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-l-amber-400\/0 {
  border-left-color: #fbbf2400;
}

.border-l-amber-400\/10 {
  border-left-color: #fbbf241a;
}

.border-l-amber-400\/100 {
  border-left-color: #fbbf24;
}

.border-l-amber-400\/20 {
  border-left-color: #fbbf2433;
}

.border-l-amber-400\/25 {
  border-left-color: #fbbf2440;
}

.border-l-amber-400\/30 {
  border-left-color: #fbbf244d;
}

.border-l-amber-400\/40 {
  border-left-color: #fbbf2466;
}

.border-l-amber-400\/5 {
  border-left-color: #fbbf240d;
}

.border-l-amber-400\/50 {
  border-left-color: #fbbf2480;
}

.border-l-amber-400\/60 {
  border-left-color: #fbbf2499;
}

.border-l-amber-400\/70 {
  border-left-color: #fbbf24b3;
}

.border-l-amber-400\/75 {
  border-left-color: #fbbf24bf;
}

.border-l-amber-400\/80 {
  border-left-color: #fbbf24cc;
}

.border-l-amber-400\/90 {
  border-left-color: #fbbf24e6;
}

.border-l-amber-400\/95 {
  border-left-color: #fbbf24f2;
}

.border-l-amber-50 {
  --tw-border-opacity: 1;
  border-left-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-l-amber-50\/0 {
  border-left-color: #fffbeb00;
}

.border-l-amber-50\/10 {
  border-left-color: #fffbeb1a;
}

.border-l-amber-50\/100 {
  border-left-color: #fffbeb;
}

.border-l-amber-50\/20 {
  border-left-color: #fffbeb33;
}

.border-l-amber-50\/25 {
  border-left-color: #fffbeb40;
}

.border-l-amber-50\/30 {
  border-left-color: #fffbeb4d;
}

.border-l-amber-50\/40 {
  border-left-color: #fffbeb66;
}

.border-l-amber-50\/5 {
  border-left-color: #fffbeb0d;
}

.border-l-amber-50\/50 {
  border-left-color: #fffbeb80;
}

.border-l-amber-50\/60 {
  border-left-color: #fffbeb99;
}

.border-l-amber-50\/70 {
  border-left-color: #fffbebb3;
}

.border-l-amber-50\/75 {
  border-left-color: #fffbebbf;
}

.border-l-amber-50\/80 {
  border-left-color: #fffbebcc;
}

.border-l-amber-50\/90 {
  border-left-color: #fffbebe6;
}

.border-l-amber-50\/95 {
  border-left-color: #fffbebf2;
}

.border-l-amber-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-l-amber-500\/0 {
  border-left-color: #f59e0b00;
}

.border-l-amber-500\/10 {
  border-left-color: #f59e0b1a;
}

.border-l-amber-500\/100 {
  border-left-color: #f59e0b;
}

.border-l-amber-500\/20 {
  border-left-color: #f59e0b33;
}

.border-l-amber-500\/25 {
  border-left-color: #f59e0b40;
}

.border-l-amber-500\/30 {
  border-left-color: #f59e0b4d;
}

.border-l-amber-500\/40 {
  border-left-color: #f59e0b66;
}

.border-l-amber-500\/5 {
  border-left-color: #f59e0b0d;
}

.border-l-amber-500\/50 {
  border-left-color: #f59e0b80;
}

.border-l-amber-500\/60 {
  border-left-color: #f59e0b99;
}

.border-l-amber-500\/70 {
  border-left-color: #f59e0bb3;
}

.border-l-amber-500\/75 {
  border-left-color: #f59e0bbf;
}

.border-l-amber-500\/80 {
  border-left-color: #f59e0bcc;
}

.border-l-amber-500\/90 {
  border-left-color: #f59e0be6;
}

.border-l-amber-500\/95 {
  border-left-color: #f59e0bf2;
}

.border-l-amber-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-l-amber-600\/0 {
  border-left-color: #d9770600;
}

.border-l-amber-600\/10 {
  border-left-color: #d977061a;
}

.border-l-amber-600\/100 {
  border-left-color: #d97706;
}

.border-l-amber-600\/20 {
  border-left-color: #d9770633;
}

.border-l-amber-600\/25 {
  border-left-color: #d9770640;
}

.border-l-amber-600\/30 {
  border-left-color: #d977064d;
}

.border-l-amber-600\/40 {
  border-left-color: #d9770666;
}

.border-l-amber-600\/5 {
  border-left-color: #d977060d;
}

.border-l-amber-600\/50 {
  border-left-color: #d9770680;
}

.border-l-amber-600\/60 {
  border-left-color: #d9770699;
}

.border-l-amber-600\/70 {
  border-left-color: #d97706b3;
}

.border-l-amber-600\/75 {
  border-left-color: #d97706bf;
}

.border-l-amber-600\/80 {
  border-left-color: #d97706cc;
}

.border-l-amber-600\/90 {
  border-left-color: #d97706e6;
}

.border-l-amber-600\/95 {
  border-left-color: #d97706f2;
}

.border-l-amber-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-l-amber-700\/0 {
  border-left-color: #b4530900;
}

.border-l-amber-700\/10 {
  border-left-color: #b453091a;
}

.border-l-amber-700\/100 {
  border-left-color: #b45309;
}

.border-l-amber-700\/20 {
  border-left-color: #b4530933;
}

.border-l-amber-700\/25 {
  border-left-color: #b4530940;
}

.border-l-amber-700\/30 {
  border-left-color: #b453094d;
}

.border-l-amber-700\/40 {
  border-left-color: #b4530966;
}

.border-l-amber-700\/5 {
  border-left-color: #b453090d;
}

.border-l-amber-700\/50 {
  border-left-color: #b4530980;
}

.border-l-amber-700\/60 {
  border-left-color: #b4530999;
}

.border-l-amber-700\/70 {
  border-left-color: #b45309b3;
}

.border-l-amber-700\/75 {
  border-left-color: #b45309bf;
}

.border-l-amber-700\/80 {
  border-left-color: #b45309cc;
}

.border-l-amber-700\/90 {
  border-left-color: #b45309e6;
}

.border-l-amber-700\/95 {
  border-left-color: #b45309f2;
}

.border-l-amber-800 {
  --tw-border-opacity: 1;
  border-left-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-l-amber-800\/0 {
  border-left-color: #92400e00;
}

.border-l-amber-800\/10 {
  border-left-color: #92400e1a;
}

.border-l-amber-800\/100 {
  border-left-color: #92400e;
}

.border-l-amber-800\/20 {
  border-left-color: #92400e33;
}

.border-l-amber-800\/25 {
  border-left-color: #92400e40;
}

.border-l-amber-800\/30 {
  border-left-color: #92400e4d;
}

.border-l-amber-800\/40 {
  border-left-color: #92400e66;
}

.border-l-amber-800\/5 {
  border-left-color: #92400e0d;
}

.border-l-amber-800\/50 {
  border-left-color: #92400e80;
}

.border-l-amber-800\/60 {
  border-left-color: #92400e99;
}

.border-l-amber-800\/70 {
  border-left-color: #92400eb3;
}

.border-l-amber-800\/75 {
  border-left-color: #92400ebf;
}

.border-l-amber-800\/80 {
  border-left-color: #92400ecc;
}

.border-l-amber-800\/90 {
  border-left-color: #92400ee6;
}

.border-l-amber-800\/95 {
  border-left-color: #92400ef2;
}

.border-l-amber-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-l-amber-900\/0 {
  border-left-color: #78350f00;
}

.border-l-amber-900\/10 {
  border-left-color: #78350f1a;
}

.border-l-amber-900\/100 {
  border-left-color: #78350f;
}

.border-l-amber-900\/20 {
  border-left-color: #78350f33;
}

.border-l-amber-900\/25 {
  border-left-color: #78350f40;
}

.border-l-amber-900\/30 {
  border-left-color: #78350f4d;
}

.border-l-amber-900\/40 {
  border-left-color: #78350f66;
}

.border-l-amber-900\/5 {
  border-left-color: #78350f0d;
}

.border-l-amber-900\/50 {
  border-left-color: #78350f80;
}

.border-l-amber-900\/60 {
  border-left-color: #78350f99;
}

.border-l-amber-900\/70 {
  border-left-color: #78350fb3;
}

.border-l-amber-900\/75 {
  border-left-color: #78350fbf;
}

.border-l-amber-900\/80 {
  border-left-color: #78350fcc;
}

.border-l-amber-900\/90 {
  border-left-color: #78350fe6;
}

.border-l-amber-900\/95 {
  border-left-color: #78350ff2;
}

.border-l-amber-950 {
  --tw-border-opacity: 1;
  border-left-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-l-amber-950\/0 {
  border-left-color: #451a0300;
}

.border-l-amber-950\/10 {
  border-left-color: #451a031a;
}

.border-l-amber-950\/100 {
  border-left-color: #451a03;
}

.border-l-amber-950\/20 {
  border-left-color: #451a0333;
}

.border-l-amber-950\/25 {
  border-left-color: #451a0340;
}

.border-l-amber-950\/30 {
  border-left-color: #451a034d;
}

.border-l-amber-950\/40 {
  border-left-color: #451a0366;
}

.border-l-amber-950\/5 {
  border-left-color: #451a030d;
}

.border-l-amber-950\/50 {
  border-left-color: #451a0380;
}

.border-l-amber-950\/60 {
  border-left-color: #451a0399;
}

.border-l-amber-950\/70 {
  border-left-color: #451a03b3;
}

.border-l-amber-950\/75 {
  border-left-color: #451a03bf;
}

.border-l-amber-950\/80 {
  border-left-color: #451a03cc;
}

.border-l-amber-950\/90 {
  border-left-color: #451a03e6;
}

.border-l-amber-950\/95 {
  border-left-color: #451a03f2;
}

.border-l-emerald-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-l-emerald-100\/0 {
  border-left-color: #d1fae500;
}

.border-l-emerald-100\/10 {
  border-left-color: #d1fae51a;
}

.border-l-emerald-100\/100 {
  border-left-color: #d1fae5;
}

.border-l-emerald-100\/20 {
  border-left-color: #d1fae533;
}

.border-l-emerald-100\/25 {
  border-left-color: #d1fae540;
}

.border-l-emerald-100\/30 {
  border-left-color: #d1fae54d;
}

.border-l-emerald-100\/40 {
  border-left-color: #d1fae566;
}

.border-l-emerald-100\/5 {
  border-left-color: #d1fae50d;
}

.border-l-emerald-100\/50 {
  border-left-color: #d1fae580;
}

.border-l-emerald-100\/60 {
  border-left-color: #d1fae599;
}

.border-l-emerald-100\/70 {
  border-left-color: #d1fae5b3;
}

.border-l-emerald-100\/75 {
  border-left-color: #d1fae5bf;
}

.border-l-emerald-100\/80 {
  border-left-color: #d1fae5cc;
}

.border-l-emerald-100\/90 {
  border-left-color: #d1fae5e6;
}

.border-l-emerald-100\/95 {
  border-left-color: #d1fae5f2;
}

.border-l-emerald-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-l-emerald-200\/0 {
  border-left-color: #a7f3d000;
}

.border-l-emerald-200\/10 {
  border-left-color: #a7f3d01a;
}

.border-l-emerald-200\/100 {
  border-left-color: #a7f3d0;
}

.border-l-emerald-200\/20 {
  border-left-color: #a7f3d033;
}

.border-l-emerald-200\/25 {
  border-left-color: #a7f3d040;
}

.border-l-emerald-200\/30 {
  border-left-color: #a7f3d04d;
}

.border-l-emerald-200\/40 {
  border-left-color: #a7f3d066;
}

.border-l-emerald-200\/5 {
  border-left-color: #a7f3d00d;
}

.border-l-emerald-200\/50 {
  border-left-color: #a7f3d080;
}

.border-l-emerald-200\/60 {
  border-left-color: #a7f3d099;
}

.border-l-emerald-200\/70 {
  border-left-color: #a7f3d0b3;
}

.border-l-emerald-200\/75 {
  border-left-color: #a7f3d0bf;
}

.border-l-emerald-200\/80 {
  border-left-color: #a7f3d0cc;
}

.border-l-emerald-200\/90 {
  border-left-color: #a7f3d0e6;
}

.border-l-emerald-200\/95 {
  border-left-color: #a7f3d0f2;
}

.border-l-emerald-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-l-emerald-300\/0 {
  border-left-color: #6ee7b700;
}

.border-l-emerald-300\/10 {
  border-left-color: #6ee7b71a;
}

.border-l-emerald-300\/100 {
  border-left-color: #6ee7b7;
}

.border-l-emerald-300\/20 {
  border-left-color: #6ee7b733;
}

.border-l-emerald-300\/25 {
  border-left-color: #6ee7b740;
}

.border-l-emerald-300\/30 {
  border-left-color: #6ee7b74d;
}

.border-l-emerald-300\/40 {
  border-left-color: #6ee7b766;
}

.border-l-emerald-300\/5 {
  border-left-color: #6ee7b70d;
}

.border-l-emerald-300\/50 {
  border-left-color: #6ee7b780;
}

.border-l-emerald-300\/60 {
  border-left-color: #6ee7b799;
}

.border-l-emerald-300\/70 {
  border-left-color: #6ee7b7b3;
}

.border-l-emerald-300\/75 {
  border-left-color: #6ee7b7bf;
}

.border-l-emerald-300\/80 {
  border-left-color: #6ee7b7cc;
}

.border-l-emerald-300\/90 {
  border-left-color: #6ee7b7e6;
}

.border-l-emerald-300\/95 {
  border-left-color: #6ee7b7f2;
}

.border-l-emerald-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-l-emerald-400\/0 {
  border-left-color: #34d39900;
}

.border-l-emerald-400\/10 {
  border-left-color: #34d3991a;
}

.border-l-emerald-400\/100 {
  border-left-color: #34d399;
}

.border-l-emerald-400\/20 {
  border-left-color: #34d39933;
}

.border-l-emerald-400\/25 {
  border-left-color: #34d39940;
}

.border-l-emerald-400\/30 {
  border-left-color: #34d3994d;
}

.border-l-emerald-400\/40 {
  border-left-color: #34d39966;
}

.border-l-emerald-400\/5 {
  border-left-color: #34d3990d;
}

.border-l-emerald-400\/50 {
  border-left-color: #34d39980;
}

.border-l-emerald-400\/60 {
  border-left-color: #34d39999;
}

.border-l-emerald-400\/70 {
  border-left-color: #34d399b3;
}

.border-l-emerald-400\/75 {
  border-left-color: #34d399bf;
}

.border-l-emerald-400\/80 {
  border-left-color: #34d399cc;
}

.border-l-emerald-400\/90 {
  border-left-color: #34d399e6;
}

.border-l-emerald-400\/95 {
  border-left-color: #34d399f2;
}

.border-l-emerald-50 {
  --tw-border-opacity: 1;
  border-left-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-l-emerald-50\/0 {
  border-left-color: #ecfdf500;
}

.border-l-emerald-50\/10 {
  border-left-color: #ecfdf51a;
}

.border-l-emerald-50\/100 {
  border-left-color: #ecfdf5;
}

.border-l-emerald-50\/20 {
  border-left-color: #ecfdf533;
}

.border-l-emerald-50\/25 {
  border-left-color: #ecfdf540;
}

.border-l-emerald-50\/30 {
  border-left-color: #ecfdf54d;
}

.border-l-emerald-50\/40 {
  border-left-color: #ecfdf566;
}

.border-l-emerald-50\/5 {
  border-left-color: #ecfdf50d;
}

.border-l-emerald-50\/50 {
  border-left-color: #ecfdf580;
}

.border-l-emerald-50\/60 {
  border-left-color: #ecfdf599;
}

.border-l-emerald-50\/70 {
  border-left-color: #ecfdf5b3;
}

.border-l-emerald-50\/75 {
  border-left-color: #ecfdf5bf;
}

.border-l-emerald-50\/80 {
  border-left-color: #ecfdf5cc;
}

.border-l-emerald-50\/90 {
  border-left-color: #ecfdf5e6;
}

.border-l-emerald-50\/95 {
  border-left-color: #ecfdf5f2;
}

.border-l-emerald-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-l-emerald-500\/0 {
  border-left-color: #10b98100;
}

.border-l-emerald-500\/10 {
  border-left-color: #10b9811a;
}

.border-l-emerald-500\/100 {
  border-left-color: #10b981;
}

.border-l-emerald-500\/20 {
  border-left-color: #10b98133;
}

.border-l-emerald-500\/25 {
  border-left-color: #10b98140;
}

.border-l-emerald-500\/30 {
  border-left-color: #10b9814d;
}

.border-l-emerald-500\/40 {
  border-left-color: #10b98166;
}

.border-l-emerald-500\/5 {
  border-left-color: #10b9810d;
}

.border-l-emerald-500\/50 {
  border-left-color: #10b98180;
}

.border-l-emerald-500\/60 {
  border-left-color: #10b98199;
}

.border-l-emerald-500\/70 {
  border-left-color: #10b981b3;
}

.border-l-emerald-500\/75 {
  border-left-color: #10b981bf;
}

.border-l-emerald-500\/80 {
  border-left-color: #10b981cc;
}

.border-l-emerald-500\/90 {
  border-left-color: #10b981e6;
}

.border-l-emerald-500\/95 {
  border-left-color: #10b981f2;
}

.border-l-emerald-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-l-emerald-600\/0 {
  border-left-color: #05966900;
}

.border-l-emerald-600\/10 {
  border-left-color: #0596691a;
}

.border-l-emerald-600\/100 {
  border-left-color: #059669;
}

.border-l-emerald-600\/20 {
  border-left-color: #05966933;
}

.border-l-emerald-600\/25 {
  border-left-color: #05966940;
}

.border-l-emerald-600\/30 {
  border-left-color: #0596694d;
}

.border-l-emerald-600\/40 {
  border-left-color: #05966966;
}

.border-l-emerald-600\/5 {
  border-left-color: #0596690d;
}

.border-l-emerald-600\/50 {
  border-left-color: #05966980;
}

.border-l-emerald-600\/60 {
  border-left-color: #05966999;
}

.border-l-emerald-600\/70 {
  border-left-color: #059669b3;
}

.border-l-emerald-600\/75 {
  border-left-color: #059669bf;
}

.border-l-emerald-600\/80 {
  border-left-color: #059669cc;
}

.border-l-emerald-600\/90 {
  border-left-color: #059669e6;
}

.border-l-emerald-600\/95 {
  border-left-color: #059669f2;
}

.border-l-emerald-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-l-emerald-700\/0 {
  border-left-color: #04785700;
}

.border-l-emerald-700\/10 {
  border-left-color: #0478571a;
}

.border-l-emerald-700\/100 {
  border-left-color: #047857;
}

.border-l-emerald-700\/20 {
  border-left-color: #04785733;
}

.border-l-emerald-700\/25 {
  border-left-color: #04785740;
}

.border-l-emerald-700\/30 {
  border-left-color: #0478574d;
}

.border-l-emerald-700\/40 {
  border-left-color: #04785766;
}

.border-l-emerald-700\/5 {
  border-left-color: #0478570d;
}

.border-l-emerald-700\/50 {
  border-left-color: #04785780;
}

.border-l-emerald-700\/60 {
  border-left-color: #04785799;
}

.border-l-emerald-700\/70 {
  border-left-color: #047857b3;
}

.border-l-emerald-700\/75 {
  border-left-color: #047857bf;
}

.border-l-emerald-700\/80 {
  border-left-color: #047857cc;
}

.border-l-emerald-700\/90 {
  border-left-color: #047857e6;
}

.border-l-emerald-700\/95 {
  border-left-color: #047857f2;
}

.border-l-emerald-800 {
  --tw-border-opacity: 1;
  border-left-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-l-emerald-800\/0 {
  border-left-color: #065f4600;
}

.border-l-emerald-800\/10 {
  border-left-color: #065f461a;
}

.border-l-emerald-800\/100 {
  border-left-color: #065f46;
}

.border-l-emerald-800\/20 {
  border-left-color: #065f4633;
}

.border-l-emerald-800\/25 {
  border-left-color: #065f4640;
}

.border-l-emerald-800\/30 {
  border-left-color: #065f464d;
}

.border-l-emerald-800\/40 {
  border-left-color: #065f4666;
}

.border-l-emerald-800\/5 {
  border-left-color: #065f460d;
}

.border-l-emerald-800\/50 {
  border-left-color: #065f4680;
}

.border-l-emerald-800\/60 {
  border-left-color: #065f4699;
}

.border-l-emerald-800\/70 {
  border-left-color: #065f46b3;
}

.border-l-emerald-800\/75 {
  border-left-color: #065f46bf;
}

.border-l-emerald-800\/80 {
  border-left-color: #065f46cc;
}

.border-l-emerald-800\/90 {
  border-left-color: #065f46e6;
}

.border-l-emerald-800\/95 {
  border-left-color: #065f46f2;
}

.border-l-emerald-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-l-emerald-900\/0 {
  border-left-color: #064e3b00;
}

.border-l-emerald-900\/10 {
  border-left-color: #064e3b1a;
}

.border-l-emerald-900\/100 {
  border-left-color: #064e3b;
}

.border-l-emerald-900\/20 {
  border-left-color: #064e3b33;
}

.border-l-emerald-900\/25 {
  border-left-color: #064e3b40;
}

.border-l-emerald-900\/30 {
  border-left-color: #064e3b4d;
}

.border-l-emerald-900\/40 {
  border-left-color: #064e3b66;
}

.border-l-emerald-900\/5 {
  border-left-color: #064e3b0d;
}

.border-l-emerald-900\/50 {
  border-left-color: #064e3b80;
}

.border-l-emerald-900\/60 {
  border-left-color: #064e3b99;
}

.border-l-emerald-900\/70 {
  border-left-color: #064e3bb3;
}

.border-l-emerald-900\/75 {
  border-left-color: #064e3bbf;
}

.border-l-emerald-900\/80 {
  border-left-color: #064e3bcc;
}

.border-l-emerald-900\/90 {
  border-left-color: #064e3be6;
}

.border-l-emerald-900\/95 {
  border-left-color: #064e3bf2;
}

.border-l-emerald-950 {
  --tw-border-opacity: 1;
  border-left-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-l-emerald-950\/0 {
  border-left-color: #022c2200;
}

.border-l-emerald-950\/10 {
  border-left-color: #022c221a;
}

.border-l-emerald-950\/100 {
  border-left-color: #022c22;
}

.border-l-emerald-950\/20 {
  border-left-color: #022c2233;
}

.border-l-emerald-950\/25 {
  border-left-color: #022c2240;
}

.border-l-emerald-950\/30 {
  border-left-color: #022c224d;
}

.border-l-emerald-950\/40 {
  border-left-color: #022c2266;
}

.border-l-emerald-950\/5 {
  border-left-color: #022c220d;
}

.border-l-emerald-950\/50 {
  border-left-color: #022c2280;
}

.border-l-emerald-950\/60 {
  border-left-color: #022c2299;
}

.border-l-emerald-950\/70 {
  border-left-color: #022c22b3;
}

.border-l-emerald-950\/75 {
  border-left-color: #022c22bf;
}

.border-l-emerald-950\/80 {
  border-left-color: #022c22cc;
}

.border-l-emerald-950\/90 {
  border-left-color: #022c22e6;
}

.border-l-emerald-950\/95 {
  border-left-color: #022c22f2;
}

.border-l-lime-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-l-lime-100\/0 {
  border-left-color: #ecfccb00;
}

.border-l-lime-100\/10 {
  border-left-color: #ecfccb1a;
}

.border-l-lime-100\/100 {
  border-left-color: #ecfccb;
}

.border-l-lime-100\/20 {
  border-left-color: #ecfccb33;
}

.border-l-lime-100\/25 {
  border-left-color: #ecfccb40;
}

.border-l-lime-100\/30 {
  border-left-color: #ecfccb4d;
}

.border-l-lime-100\/40 {
  border-left-color: #ecfccb66;
}

.border-l-lime-100\/5 {
  border-left-color: #ecfccb0d;
}

.border-l-lime-100\/50 {
  border-left-color: #ecfccb80;
}

.border-l-lime-100\/60 {
  border-left-color: #ecfccb99;
}

.border-l-lime-100\/70 {
  border-left-color: #ecfccbb3;
}

.border-l-lime-100\/75 {
  border-left-color: #ecfccbbf;
}

.border-l-lime-100\/80 {
  border-left-color: #ecfccbcc;
}

.border-l-lime-100\/90 {
  border-left-color: #ecfccbe6;
}

.border-l-lime-100\/95 {
  border-left-color: #ecfccbf2;
}

.border-l-lime-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-l-lime-200\/0 {
  border-left-color: #d9f99d00;
}

.border-l-lime-200\/10 {
  border-left-color: #d9f99d1a;
}

.border-l-lime-200\/100 {
  border-left-color: #d9f99d;
}

.border-l-lime-200\/20 {
  border-left-color: #d9f99d33;
}

.border-l-lime-200\/25 {
  border-left-color: #d9f99d40;
}

.border-l-lime-200\/30 {
  border-left-color: #d9f99d4d;
}

.border-l-lime-200\/40 {
  border-left-color: #d9f99d66;
}

.border-l-lime-200\/5 {
  border-left-color: #d9f99d0d;
}

.border-l-lime-200\/50 {
  border-left-color: #d9f99d80;
}

.border-l-lime-200\/60 {
  border-left-color: #d9f99d99;
}

.border-l-lime-200\/70 {
  border-left-color: #d9f99db3;
}

.border-l-lime-200\/75 {
  border-left-color: #d9f99dbf;
}

.border-l-lime-200\/80 {
  border-left-color: #d9f99dcc;
}

.border-l-lime-200\/90 {
  border-left-color: #d9f99de6;
}

.border-l-lime-200\/95 {
  border-left-color: #d9f99df2;
}

.border-l-lime-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-l-lime-300\/0 {
  border-left-color: #bef26400;
}

.border-l-lime-300\/10 {
  border-left-color: #bef2641a;
}

.border-l-lime-300\/100 {
  border-left-color: #bef264;
}

.border-l-lime-300\/20 {
  border-left-color: #bef26433;
}

.border-l-lime-300\/25 {
  border-left-color: #bef26440;
}

.border-l-lime-300\/30 {
  border-left-color: #bef2644d;
}

.border-l-lime-300\/40 {
  border-left-color: #bef26466;
}

.border-l-lime-300\/5 {
  border-left-color: #bef2640d;
}

.border-l-lime-300\/50 {
  border-left-color: #bef26480;
}

.border-l-lime-300\/60 {
  border-left-color: #bef26499;
}

.border-l-lime-300\/70 {
  border-left-color: #bef264b3;
}

.border-l-lime-300\/75 {
  border-left-color: #bef264bf;
}

.border-l-lime-300\/80 {
  border-left-color: #bef264cc;
}

.border-l-lime-300\/90 {
  border-left-color: #bef264e6;
}

.border-l-lime-300\/95 {
  border-left-color: #bef264f2;
}

.border-l-lime-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-l-lime-400\/0 {
  border-left-color: #a3e63500;
}

.border-l-lime-400\/10 {
  border-left-color: #a3e6351a;
}

.border-l-lime-400\/100 {
  border-left-color: #a3e635;
}

.border-l-lime-400\/20 {
  border-left-color: #a3e63533;
}

.border-l-lime-400\/25 {
  border-left-color: #a3e63540;
}

.border-l-lime-400\/30 {
  border-left-color: #a3e6354d;
}

.border-l-lime-400\/40 {
  border-left-color: #a3e63566;
}

.border-l-lime-400\/5 {
  border-left-color: #a3e6350d;
}

.border-l-lime-400\/50 {
  border-left-color: #a3e63580;
}

.border-l-lime-400\/60 {
  border-left-color: #a3e63599;
}

.border-l-lime-400\/70 {
  border-left-color: #a3e635b3;
}

.border-l-lime-400\/75 {
  border-left-color: #a3e635bf;
}

.border-l-lime-400\/80 {
  border-left-color: #a3e635cc;
}

.border-l-lime-400\/90 {
  border-left-color: #a3e635e6;
}

.border-l-lime-400\/95 {
  border-left-color: #a3e635f2;
}

.border-l-lime-50 {
  --tw-border-opacity: 1;
  border-left-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-l-lime-50\/0 {
  border-left-color: #f7fee700;
}

.border-l-lime-50\/10 {
  border-left-color: #f7fee71a;
}

.border-l-lime-50\/100 {
  border-left-color: #f7fee7;
}

.border-l-lime-50\/20 {
  border-left-color: #f7fee733;
}

.border-l-lime-50\/25 {
  border-left-color: #f7fee740;
}

.border-l-lime-50\/30 {
  border-left-color: #f7fee74d;
}

.border-l-lime-50\/40 {
  border-left-color: #f7fee766;
}

.border-l-lime-50\/5 {
  border-left-color: #f7fee70d;
}

.border-l-lime-50\/50 {
  border-left-color: #f7fee780;
}

.border-l-lime-50\/60 {
  border-left-color: #f7fee799;
}

.border-l-lime-50\/70 {
  border-left-color: #f7fee7b3;
}

.border-l-lime-50\/75 {
  border-left-color: #f7fee7bf;
}

.border-l-lime-50\/80 {
  border-left-color: #f7fee7cc;
}

.border-l-lime-50\/90 {
  border-left-color: #f7fee7e6;
}

.border-l-lime-50\/95 {
  border-left-color: #f7fee7f2;
}

.border-l-lime-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-l-lime-500\/0 {
  border-left-color: #84cc1600;
}

.border-l-lime-500\/10 {
  border-left-color: #84cc161a;
}

.border-l-lime-500\/100 {
  border-left-color: #84cc16;
}

.border-l-lime-500\/20 {
  border-left-color: #84cc1633;
}

.border-l-lime-500\/25 {
  border-left-color: #84cc1640;
}

.border-l-lime-500\/30 {
  border-left-color: #84cc164d;
}

.border-l-lime-500\/40 {
  border-left-color: #84cc1666;
}

.border-l-lime-500\/5 {
  border-left-color: #84cc160d;
}

.border-l-lime-500\/50 {
  border-left-color: #84cc1680;
}

.border-l-lime-500\/60 {
  border-left-color: #84cc1699;
}

.border-l-lime-500\/70 {
  border-left-color: #84cc16b3;
}

.border-l-lime-500\/75 {
  border-left-color: #84cc16bf;
}

.border-l-lime-500\/80 {
  border-left-color: #84cc16cc;
}

.border-l-lime-500\/90 {
  border-left-color: #84cc16e6;
}

.border-l-lime-500\/95 {
  border-left-color: #84cc16f2;
}

.border-l-lime-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-l-lime-600\/0 {
  border-left-color: #65a30d00;
}

.border-l-lime-600\/10 {
  border-left-color: #65a30d1a;
}

.border-l-lime-600\/100 {
  border-left-color: #65a30d;
}

.border-l-lime-600\/20 {
  border-left-color: #65a30d33;
}

.border-l-lime-600\/25 {
  border-left-color: #65a30d40;
}

.border-l-lime-600\/30 {
  border-left-color: #65a30d4d;
}

.border-l-lime-600\/40 {
  border-left-color: #65a30d66;
}

.border-l-lime-600\/5 {
  border-left-color: #65a30d0d;
}

.border-l-lime-600\/50 {
  border-left-color: #65a30d80;
}

.border-l-lime-600\/60 {
  border-left-color: #65a30d99;
}

.border-l-lime-600\/70 {
  border-left-color: #65a30db3;
}

.border-l-lime-600\/75 {
  border-left-color: #65a30dbf;
}

.border-l-lime-600\/80 {
  border-left-color: #65a30dcc;
}

.border-l-lime-600\/90 {
  border-left-color: #65a30de6;
}

.border-l-lime-600\/95 {
  border-left-color: #65a30df2;
}

.border-l-lime-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-l-lime-700\/0 {
  border-left-color: #4d7c0f00;
}

.border-l-lime-700\/10 {
  border-left-color: #4d7c0f1a;
}

.border-l-lime-700\/100 {
  border-left-color: #4d7c0f;
}

.border-l-lime-700\/20 {
  border-left-color: #4d7c0f33;
}

.border-l-lime-700\/25 {
  border-left-color: #4d7c0f40;
}

.border-l-lime-700\/30 {
  border-left-color: #4d7c0f4d;
}

.border-l-lime-700\/40 {
  border-left-color: #4d7c0f66;
}

.border-l-lime-700\/5 {
  border-left-color: #4d7c0f0d;
}

.border-l-lime-700\/50 {
  border-left-color: #4d7c0f80;
}

.border-l-lime-700\/60 {
  border-left-color: #4d7c0f99;
}

.border-l-lime-700\/70 {
  border-left-color: #4d7c0fb3;
}

.border-l-lime-700\/75 {
  border-left-color: #4d7c0fbf;
}

.border-l-lime-700\/80 {
  border-left-color: #4d7c0fcc;
}

.border-l-lime-700\/90 {
  border-left-color: #4d7c0fe6;
}

.border-l-lime-700\/95 {
  border-left-color: #4d7c0ff2;
}

.border-l-lime-800 {
  --tw-border-opacity: 1;
  border-left-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-l-lime-800\/0 {
  border-left-color: #3f621200;
}

.border-l-lime-800\/10 {
  border-left-color: #3f62121a;
}

.border-l-lime-800\/100 {
  border-left-color: #3f6212;
}

.border-l-lime-800\/20 {
  border-left-color: #3f621233;
}

.border-l-lime-800\/25 {
  border-left-color: #3f621240;
}

.border-l-lime-800\/30 {
  border-left-color: #3f62124d;
}

.border-l-lime-800\/40 {
  border-left-color: #3f621266;
}

.border-l-lime-800\/5 {
  border-left-color: #3f62120d;
}

.border-l-lime-800\/50 {
  border-left-color: #3f621280;
}

.border-l-lime-800\/60 {
  border-left-color: #3f621299;
}

.border-l-lime-800\/70 {
  border-left-color: #3f6212b3;
}

.border-l-lime-800\/75 {
  border-left-color: #3f6212bf;
}

.border-l-lime-800\/80 {
  border-left-color: #3f6212cc;
}

.border-l-lime-800\/90 {
  border-left-color: #3f6212e6;
}

.border-l-lime-800\/95 {
  border-left-color: #3f6212f2;
}

.border-l-lime-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-l-lime-900\/0 {
  border-left-color: #36531400;
}

.border-l-lime-900\/10 {
  border-left-color: #3653141a;
}

.border-l-lime-900\/100 {
  border-left-color: #365314;
}

.border-l-lime-900\/20 {
  border-left-color: #36531433;
}

.border-l-lime-900\/25 {
  border-left-color: #36531440;
}

.border-l-lime-900\/30 {
  border-left-color: #3653144d;
}

.border-l-lime-900\/40 {
  border-left-color: #36531466;
}

.border-l-lime-900\/5 {
  border-left-color: #3653140d;
}

.border-l-lime-900\/50 {
  border-left-color: #36531480;
}

.border-l-lime-900\/60 {
  border-left-color: #36531499;
}

.border-l-lime-900\/70 {
  border-left-color: #365314b3;
}

.border-l-lime-900\/75 {
  border-left-color: #365314bf;
}

.border-l-lime-900\/80 {
  border-left-color: #365314cc;
}

.border-l-lime-900\/90 {
  border-left-color: #365314e6;
}

.border-l-lime-900\/95 {
  border-left-color: #365314f2;
}

.border-l-lime-950 {
  --tw-border-opacity: 1;
  border-left-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-l-lime-950\/0 {
  border-left-color: #1a2e0500;
}

.border-l-lime-950\/10 {
  border-left-color: #1a2e051a;
}

.border-l-lime-950\/100 {
  border-left-color: #1a2e05;
}

.border-l-lime-950\/20 {
  border-left-color: #1a2e0533;
}

.border-l-lime-950\/25 {
  border-left-color: #1a2e0540;
}

.border-l-lime-950\/30 {
  border-left-color: #1a2e054d;
}

.border-l-lime-950\/40 {
  border-left-color: #1a2e0566;
}

.border-l-lime-950\/5 {
  border-left-color: #1a2e050d;
}

.border-l-lime-950\/50 {
  border-left-color: #1a2e0580;
}

.border-l-lime-950\/60 {
  border-left-color: #1a2e0599;
}

.border-l-lime-950\/70 {
  border-left-color: #1a2e05b3;
}

.border-l-lime-950\/75 {
  border-left-color: #1a2e05bf;
}

.border-l-lime-950\/80 {
  border-left-color: #1a2e05cc;
}

.border-l-lime-950\/90 {
  border-left-color: #1a2e05e6;
}

.border-l-lime-950\/95 {
  border-left-color: #1a2e05f2;
}

.border-l-pink-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-l-pink-100\/0 {
  border-left-color: #fce7f300;
}

.border-l-pink-100\/10 {
  border-left-color: #fce7f31a;
}

.border-l-pink-100\/100 {
  border-left-color: #fce7f3;
}

.border-l-pink-100\/20 {
  border-left-color: #fce7f333;
}

.border-l-pink-100\/25 {
  border-left-color: #fce7f340;
}

.border-l-pink-100\/30 {
  border-left-color: #fce7f34d;
}

.border-l-pink-100\/40 {
  border-left-color: #fce7f366;
}

.border-l-pink-100\/5 {
  border-left-color: #fce7f30d;
}

.border-l-pink-100\/50 {
  border-left-color: #fce7f380;
}

.border-l-pink-100\/60 {
  border-left-color: #fce7f399;
}

.border-l-pink-100\/70 {
  border-left-color: #fce7f3b3;
}

.border-l-pink-100\/75 {
  border-left-color: #fce7f3bf;
}

.border-l-pink-100\/80 {
  border-left-color: #fce7f3cc;
}

.border-l-pink-100\/90 {
  border-left-color: #fce7f3e6;
}

.border-l-pink-100\/95 {
  border-left-color: #fce7f3f2;
}

.border-l-pink-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-l-pink-200\/0 {
  border-left-color: #fbcfe800;
}

.border-l-pink-200\/10 {
  border-left-color: #fbcfe81a;
}

.border-l-pink-200\/100 {
  border-left-color: #fbcfe8;
}

.border-l-pink-200\/20 {
  border-left-color: #fbcfe833;
}

.border-l-pink-200\/25 {
  border-left-color: #fbcfe840;
}

.border-l-pink-200\/30 {
  border-left-color: #fbcfe84d;
}

.border-l-pink-200\/40 {
  border-left-color: #fbcfe866;
}

.border-l-pink-200\/5 {
  border-left-color: #fbcfe80d;
}

.border-l-pink-200\/50 {
  border-left-color: #fbcfe880;
}

.border-l-pink-200\/60 {
  border-left-color: #fbcfe899;
}

.border-l-pink-200\/70 {
  border-left-color: #fbcfe8b3;
}

.border-l-pink-200\/75 {
  border-left-color: #fbcfe8bf;
}

.border-l-pink-200\/80 {
  border-left-color: #fbcfe8cc;
}

.border-l-pink-200\/90 {
  border-left-color: #fbcfe8e6;
}

.border-l-pink-200\/95 {
  border-left-color: #fbcfe8f2;
}

.border-l-pink-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-l-pink-300\/0 {
  border-left-color: #f9a8d400;
}

.border-l-pink-300\/10 {
  border-left-color: #f9a8d41a;
}

.border-l-pink-300\/100 {
  border-left-color: #f9a8d4;
}

.border-l-pink-300\/20 {
  border-left-color: #f9a8d433;
}

.border-l-pink-300\/25 {
  border-left-color: #f9a8d440;
}

.border-l-pink-300\/30 {
  border-left-color: #f9a8d44d;
}

.border-l-pink-300\/40 {
  border-left-color: #f9a8d466;
}

.border-l-pink-300\/5 {
  border-left-color: #f9a8d40d;
}

.border-l-pink-300\/50 {
  border-left-color: #f9a8d480;
}

.border-l-pink-300\/60 {
  border-left-color: #f9a8d499;
}

.border-l-pink-300\/70 {
  border-left-color: #f9a8d4b3;
}

.border-l-pink-300\/75 {
  border-left-color: #f9a8d4bf;
}

.border-l-pink-300\/80 {
  border-left-color: #f9a8d4cc;
}

.border-l-pink-300\/90 {
  border-left-color: #f9a8d4e6;
}

.border-l-pink-300\/95 {
  border-left-color: #f9a8d4f2;
}

.border-l-pink-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-l-pink-400\/0 {
  border-left-color: #f472b600;
}

.border-l-pink-400\/10 {
  border-left-color: #f472b61a;
}

.border-l-pink-400\/100 {
  border-left-color: #f472b6;
}

.border-l-pink-400\/20 {
  border-left-color: #f472b633;
}

.border-l-pink-400\/25 {
  border-left-color: #f472b640;
}

.border-l-pink-400\/30 {
  border-left-color: #f472b64d;
}

.border-l-pink-400\/40 {
  border-left-color: #f472b666;
}

.border-l-pink-400\/5 {
  border-left-color: #f472b60d;
}

.border-l-pink-400\/50 {
  border-left-color: #f472b680;
}

.border-l-pink-400\/60 {
  border-left-color: #f472b699;
}

.border-l-pink-400\/70 {
  border-left-color: #f472b6b3;
}

.border-l-pink-400\/75 {
  border-left-color: #f472b6bf;
}

.border-l-pink-400\/80 {
  border-left-color: #f472b6cc;
}

.border-l-pink-400\/90 {
  border-left-color: #f472b6e6;
}

.border-l-pink-400\/95 {
  border-left-color: #f472b6f2;
}

.border-l-pink-50 {
  --tw-border-opacity: 1;
  border-left-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-l-pink-50\/0 {
  border-left-color: #fdf2f800;
}

.border-l-pink-50\/10 {
  border-left-color: #fdf2f81a;
}

.border-l-pink-50\/100 {
  border-left-color: #fdf2f8;
}

.border-l-pink-50\/20 {
  border-left-color: #fdf2f833;
}

.border-l-pink-50\/25 {
  border-left-color: #fdf2f840;
}

.border-l-pink-50\/30 {
  border-left-color: #fdf2f84d;
}

.border-l-pink-50\/40 {
  border-left-color: #fdf2f866;
}

.border-l-pink-50\/5 {
  border-left-color: #fdf2f80d;
}

.border-l-pink-50\/50 {
  border-left-color: #fdf2f880;
}

.border-l-pink-50\/60 {
  border-left-color: #fdf2f899;
}

.border-l-pink-50\/70 {
  border-left-color: #fdf2f8b3;
}

.border-l-pink-50\/75 {
  border-left-color: #fdf2f8bf;
}

.border-l-pink-50\/80 {
  border-left-color: #fdf2f8cc;
}

.border-l-pink-50\/90 {
  border-left-color: #fdf2f8e6;
}

.border-l-pink-50\/95 {
  border-left-color: #fdf2f8f2;
}

.border-l-pink-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-l-pink-500\/0 {
  border-left-color: #ec489900;
}

.border-l-pink-500\/10 {
  border-left-color: #ec48991a;
}

.border-l-pink-500\/100 {
  border-left-color: #ec4899;
}

.border-l-pink-500\/20 {
  border-left-color: #ec489933;
}

.border-l-pink-500\/25 {
  border-left-color: #ec489940;
}

.border-l-pink-500\/30 {
  border-left-color: #ec48994d;
}

.border-l-pink-500\/40 {
  border-left-color: #ec489966;
}

.border-l-pink-500\/5 {
  border-left-color: #ec48990d;
}

.border-l-pink-500\/50 {
  border-left-color: #ec489980;
}

.border-l-pink-500\/60 {
  border-left-color: #ec489999;
}

.border-l-pink-500\/70 {
  border-left-color: #ec4899b3;
}

.border-l-pink-500\/75 {
  border-left-color: #ec4899bf;
}

.border-l-pink-500\/80 {
  border-left-color: #ec4899cc;
}

.border-l-pink-500\/90 {
  border-left-color: #ec4899e6;
}

.border-l-pink-500\/95 {
  border-left-color: #ec4899f2;
}

.border-l-pink-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-l-pink-600\/0 {
  border-left-color: #db277700;
}

.border-l-pink-600\/10 {
  border-left-color: #db27771a;
}

.border-l-pink-600\/100 {
  border-left-color: #db2777;
}

.border-l-pink-600\/20 {
  border-left-color: #db277733;
}

.border-l-pink-600\/25 {
  border-left-color: #db277740;
}

.border-l-pink-600\/30 {
  border-left-color: #db27774d;
}

.border-l-pink-600\/40 {
  border-left-color: #db277766;
}

.border-l-pink-600\/5 {
  border-left-color: #db27770d;
}

.border-l-pink-600\/50 {
  border-left-color: #db277780;
}

.border-l-pink-600\/60 {
  border-left-color: #db277799;
}

.border-l-pink-600\/70 {
  border-left-color: #db2777b3;
}

.border-l-pink-600\/75 {
  border-left-color: #db2777bf;
}

.border-l-pink-600\/80 {
  border-left-color: #db2777cc;
}

.border-l-pink-600\/90 {
  border-left-color: #db2777e6;
}

.border-l-pink-600\/95 {
  border-left-color: #db2777f2;
}

.border-l-pink-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-l-pink-700\/0 {
  border-left-color: #be185d00;
}

.border-l-pink-700\/10 {
  border-left-color: #be185d1a;
}

.border-l-pink-700\/100 {
  border-left-color: #be185d;
}

.border-l-pink-700\/20 {
  border-left-color: #be185d33;
}

.border-l-pink-700\/25 {
  border-left-color: #be185d40;
}

.border-l-pink-700\/30 {
  border-left-color: #be185d4d;
}

.border-l-pink-700\/40 {
  border-left-color: #be185d66;
}

.border-l-pink-700\/5 {
  border-left-color: #be185d0d;
}

.border-l-pink-700\/50 {
  border-left-color: #be185d80;
}

.border-l-pink-700\/60 {
  border-left-color: #be185d99;
}

.border-l-pink-700\/70 {
  border-left-color: #be185db3;
}

.border-l-pink-700\/75 {
  border-left-color: #be185dbf;
}

.border-l-pink-700\/80 {
  border-left-color: #be185dcc;
}

.border-l-pink-700\/90 {
  border-left-color: #be185de6;
}

.border-l-pink-700\/95 {
  border-left-color: #be185df2;
}

.border-l-pink-800 {
  --tw-border-opacity: 1;
  border-left-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-l-pink-800\/0 {
  border-left-color: #9d174d00;
}

.border-l-pink-800\/10 {
  border-left-color: #9d174d1a;
}

.border-l-pink-800\/100 {
  border-left-color: #9d174d;
}

.border-l-pink-800\/20 {
  border-left-color: #9d174d33;
}

.border-l-pink-800\/25 {
  border-left-color: #9d174d40;
}

.border-l-pink-800\/30 {
  border-left-color: #9d174d4d;
}

.border-l-pink-800\/40 {
  border-left-color: #9d174d66;
}

.border-l-pink-800\/5 {
  border-left-color: #9d174d0d;
}

.border-l-pink-800\/50 {
  border-left-color: #9d174d80;
}

.border-l-pink-800\/60 {
  border-left-color: #9d174d99;
}

.border-l-pink-800\/70 {
  border-left-color: #9d174db3;
}

.border-l-pink-800\/75 {
  border-left-color: #9d174dbf;
}

.border-l-pink-800\/80 {
  border-left-color: #9d174dcc;
}

.border-l-pink-800\/90 {
  border-left-color: #9d174de6;
}

.border-l-pink-800\/95 {
  border-left-color: #9d174df2;
}

.border-l-pink-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-l-pink-900\/0 {
  border-left-color: #83184300;
}

.border-l-pink-900\/10 {
  border-left-color: #8318431a;
}

.border-l-pink-900\/100 {
  border-left-color: #831843;
}

.border-l-pink-900\/20 {
  border-left-color: #83184333;
}

.border-l-pink-900\/25 {
  border-left-color: #83184340;
}

.border-l-pink-900\/30 {
  border-left-color: #8318434d;
}

.border-l-pink-900\/40 {
  border-left-color: #83184366;
}

.border-l-pink-900\/5 {
  border-left-color: #8318430d;
}

.border-l-pink-900\/50 {
  border-left-color: #83184380;
}

.border-l-pink-900\/60 {
  border-left-color: #83184399;
}

.border-l-pink-900\/70 {
  border-left-color: #831843b3;
}

.border-l-pink-900\/75 {
  border-left-color: #831843bf;
}

.border-l-pink-900\/80 {
  border-left-color: #831843cc;
}

.border-l-pink-900\/90 {
  border-left-color: #831843e6;
}

.border-l-pink-900\/95 {
  border-left-color: #831843f2;
}

.border-l-pink-950 {
  --tw-border-opacity: 1;
  border-left-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-l-pink-950\/0 {
  border-left-color: #50072400;
}

.border-l-pink-950\/10 {
  border-left-color: #5007241a;
}

.border-l-pink-950\/100 {
  border-left-color: #500724;
}

.border-l-pink-950\/20 {
  border-left-color: #50072433;
}

.border-l-pink-950\/25 {
  border-left-color: #50072440;
}

.border-l-pink-950\/30 {
  border-left-color: #5007244d;
}

.border-l-pink-950\/40 {
  border-left-color: #50072466;
}

.border-l-pink-950\/5 {
  border-left-color: #5007240d;
}

.border-l-pink-950\/50 {
  border-left-color: #50072480;
}

.border-l-pink-950\/60 {
  border-left-color: #50072499;
}

.border-l-pink-950\/70 {
  border-left-color: #500724b3;
}

.border-l-pink-950\/75 {
  border-left-color: #500724bf;
}

.border-l-pink-950\/80 {
  border-left-color: #500724cc;
}

.border-l-pink-950\/90 {
  border-left-color: #500724e6;
}

.border-l-pink-950\/95 {
  border-left-color: #500724f2;
}

.border-l-primary {
  --tw-border-opacity: 1;
  border-left-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-l-primary\/0 {
  border-left-color: hsl(var(--primary) / 0);
}

.border-l-primary\/10 {
  border-left-color: hsl(var(--primary) / .1);
}

.border-l-primary\/100 {
  border-left-color: hsl(var(--primary) / 1);
}

.border-l-primary\/20 {
  border-left-color: hsl(var(--primary) / .2);
}

.border-l-primary\/25 {
  border-left-color: hsl(var(--primary) / .25);
}

.border-l-primary\/30 {
  border-left-color: hsl(var(--primary) / .3);
}

.border-l-primary\/40 {
  border-left-color: hsl(var(--primary) / .4);
}

.border-l-primary\/5 {
  border-left-color: hsl(var(--primary) / .05);
}

.border-l-primary\/50 {
  border-left-color: hsl(var(--primary) / .5);
}

.border-l-primary\/60 {
  border-left-color: hsl(var(--primary) / .6);
}

.border-l-primary\/70 {
  border-left-color: hsl(var(--primary) / .7);
}

.border-l-primary\/75 {
  border-left-color: hsl(var(--primary) / .75);
}

.border-l-primary\/80 {
  border-left-color: hsl(var(--primary) / .8);
}

.border-l-primary\/90 {
  border-left-color: hsl(var(--primary) / .9);
}

.border-l-primary\/95 {
  border-left-color: hsl(var(--primary) / .95);
}

.border-l-purple-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-l-purple-100\/0 {
  border-left-color: #f3e8ff00;
}

.border-l-purple-100\/10 {
  border-left-color: #f3e8ff1a;
}

.border-l-purple-100\/100 {
  border-left-color: #f3e8ff;
}

.border-l-purple-100\/20 {
  border-left-color: #f3e8ff33;
}

.border-l-purple-100\/25 {
  border-left-color: #f3e8ff40;
}

.border-l-purple-100\/30 {
  border-left-color: #f3e8ff4d;
}

.border-l-purple-100\/40 {
  border-left-color: #f3e8ff66;
}

.border-l-purple-100\/5 {
  border-left-color: #f3e8ff0d;
}

.border-l-purple-100\/50 {
  border-left-color: #f3e8ff80;
}

.border-l-purple-100\/60 {
  border-left-color: #f3e8ff99;
}

.border-l-purple-100\/70 {
  border-left-color: #f3e8ffb3;
}

.border-l-purple-100\/75 {
  border-left-color: #f3e8ffbf;
}

.border-l-purple-100\/80 {
  border-left-color: #f3e8ffcc;
}

.border-l-purple-100\/90 {
  border-left-color: #f3e8ffe6;
}

.border-l-purple-100\/95 {
  border-left-color: #f3e8fff2;
}

.border-l-purple-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-l-purple-200\/0 {
  border-left-color: #e9d5ff00;
}

.border-l-purple-200\/10 {
  border-left-color: #e9d5ff1a;
}

.border-l-purple-200\/100 {
  border-left-color: #e9d5ff;
}

.border-l-purple-200\/20 {
  border-left-color: #e9d5ff33;
}

.border-l-purple-200\/25 {
  border-left-color: #e9d5ff40;
}

.border-l-purple-200\/30 {
  border-left-color: #e9d5ff4d;
}

.border-l-purple-200\/40 {
  border-left-color: #e9d5ff66;
}

.border-l-purple-200\/5 {
  border-left-color: #e9d5ff0d;
}

.border-l-purple-200\/50 {
  border-left-color: #e9d5ff80;
}

.border-l-purple-200\/60 {
  border-left-color: #e9d5ff99;
}

.border-l-purple-200\/70 {
  border-left-color: #e9d5ffb3;
}

.border-l-purple-200\/75 {
  border-left-color: #e9d5ffbf;
}

.border-l-purple-200\/80 {
  border-left-color: #e9d5ffcc;
}

.border-l-purple-200\/90 {
  border-left-color: #e9d5ffe6;
}

.border-l-purple-200\/95 {
  border-left-color: #e9d5fff2;
}

.border-l-purple-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-l-purple-300\/0 {
  border-left-color: #d8b4fe00;
}

.border-l-purple-300\/10 {
  border-left-color: #d8b4fe1a;
}

.border-l-purple-300\/100 {
  border-left-color: #d8b4fe;
}

.border-l-purple-300\/20 {
  border-left-color: #d8b4fe33;
}

.border-l-purple-300\/25 {
  border-left-color: #d8b4fe40;
}

.border-l-purple-300\/30 {
  border-left-color: #d8b4fe4d;
}

.border-l-purple-300\/40 {
  border-left-color: #d8b4fe66;
}

.border-l-purple-300\/5 {
  border-left-color: #d8b4fe0d;
}

.border-l-purple-300\/50 {
  border-left-color: #d8b4fe80;
}

.border-l-purple-300\/60 {
  border-left-color: #d8b4fe99;
}

.border-l-purple-300\/70 {
  border-left-color: #d8b4feb3;
}

.border-l-purple-300\/75 {
  border-left-color: #d8b4febf;
}

.border-l-purple-300\/80 {
  border-left-color: #d8b4fecc;
}

.border-l-purple-300\/90 {
  border-left-color: #d8b4fee6;
}

.border-l-purple-300\/95 {
  border-left-color: #d8b4fef2;
}

.border-l-purple-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-l-purple-400\/0 {
  border-left-color: #c084fc00;
}

.border-l-purple-400\/10 {
  border-left-color: #c084fc1a;
}

.border-l-purple-400\/100 {
  border-left-color: #c084fc;
}

.border-l-purple-400\/20 {
  border-left-color: #c084fc33;
}

.border-l-purple-400\/25 {
  border-left-color: #c084fc40;
}

.border-l-purple-400\/30 {
  border-left-color: #c084fc4d;
}

.border-l-purple-400\/40 {
  border-left-color: #c084fc66;
}

.border-l-purple-400\/5 {
  border-left-color: #c084fc0d;
}

.border-l-purple-400\/50 {
  border-left-color: #c084fc80;
}

.border-l-purple-400\/60 {
  border-left-color: #c084fc99;
}

.border-l-purple-400\/70 {
  border-left-color: #c084fcb3;
}

.border-l-purple-400\/75 {
  border-left-color: #c084fcbf;
}

.border-l-purple-400\/80 {
  border-left-color: #c084fccc;
}

.border-l-purple-400\/90 {
  border-left-color: #c084fce6;
}

.border-l-purple-400\/95 {
  border-left-color: #c084fcf2;
}

.border-l-purple-50 {
  --tw-border-opacity: 1;
  border-left-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-l-purple-50\/0 {
  border-left-color: #faf5ff00;
}

.border-l-purple-50\/10 {
  border-left-color: #faf5ff1a;
}

.border-l-purple-50\/100 {
  border-left-color: #faf5ff;
}

.border-l-purple-50\/20 {
  border-left-color: #faf5ff33;
}

.border-l-purple-50\/25 {
  border-left-color: #faf5ff40;
}

.border-l-purple-50\/30 {
  border-left-color: #faf5ff4d;
}

.border-l-purple-50\/40 {
  border-left-color: #faf5ff66;
}

.border-l-purple-50\/5 {
  border-left-color: #faf5ff0d;
}

.border-l-purple-50\/50 {
  border-left-color: #faf5ff80;
}

.border-l-purple-50\/60 {
  border-left-color: #faf5ff99;
}

.border-l-purple-50\/70 {
  border-left-color: #faf5ffb3;
}

.border-l-purple-50\/75 {
  border-left-color: #faf5ffbf;
}

.border-l-purple-50\/80 {
  border-left-color: #faf5ffcc;
}

.border-l-purple-50\/90 {
  border-left-color: #faf5ffe6;
}

.border-l-purple-50\/95 {
  border-left-color: #faf5fff2;
}

.border-l-purple-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-l-purple-500\/0 {
  border-left-color: #a855f700;
}

.border-l-purple-500\/10 {
  border-left-color: #a855f71a;
}

.border-l-purple-500\/100 {
  border-left-color: #a855f7;
}

.border-l-purple-500\/20 {
  border-left-color: #a855f733;
}

.border-l-purple-500\/25 {
  border-left-color: #a855f740;
}

.border-l-purple-500\/30 {
  border-left-color: #a855f74d;
}

.border-l-purple-500\/40 {
  border-left-color: #a855f766;
}

.border-l-purple-500\/5 {
  border-left-color: #a855f70d;
}

.border-l-purple-500\/50 {
  border-left-color: #a855f780;
}

.border-l-purple-500\/60 {
  border-left-color: #a855f799;
}

.border-l-purple-500\/70 {
  border-left-color: #a855f7b3;
}

.border-l-purple-500\/75 {
  border-left-color: #a855f7bf;
}

.border-l-purple-500\/80 {
  border-left-color: #a855f7cc;
}

.border-l-purple-500\/90 {
  border-left-color: #a855f7e6;
}

.border-l-purple-500\/95 {
  border-left-color: #a855f7f2;
}

.border-l-purple-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-l-purple-600\/0 {
  border-left-color: #9333ea00;
}

.border-l-purple-600\/10 {
  border-left-color: #9333ea1a;
}

.border-l-purple-600\/100 {
  border-left-color: #9333ea;
}

.border-l-purple-600\/20 {
  border-left-color: #9333ea33;
}

.border-l-purple-600\/25 {
  border-left-color: #9333ea40;
}

.border-l-purple-600\/30 {
  border-left-color: #9333ea4d;
}

.border-l-purple-600\/40 {
  border-left-color: #9333ea66;
}

.border-l-purple-600\/5 {
  border-left-color: #9333ea0d;
}

.border-l-purple-600\/50 {
  border-left-color: #9333ea80;
}

.border-l-purple-600\/60 {
  border-left-color: #9333ea99;
}

.border-l-purple-600\/70 {
  border-left-color: #9333eab3;
}

.border-l-purple-600\/75 {
  border-left-color: #9333eabf;
}

.border-l-purple-600\/80 {
  border-left-color: #9333eacc;
}

.border-l-purple-600\/90 {
  border-left-color: #9333eae6;
}

.border-l-purple-600\/95 {
  border-left-color: #9333eaf2;
}

.border-l-purple-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-l-purple-700\/0 {
  border-left-color: #7e22ce00;
}

.border-l-purple-700\/10 {
  border-left-color: #7e22ce1a;
}

.border-l-purple-700\/100 {
  border-left-color: #7e22ce;
}

.border-l-purple-700\/20 {
  border-left-color: #7e22ce33;
}

.border-l-purple-700\/25 {
  border-left-color: #7e22ce40;
}

.border-l-purple-700\/30 {
  border-left-color: #7e22ce4d;
}

.border-l-purple-700\/40 {
  border-left-color: #7e22ce66;
}

.border-l-purple-700\/5 {
  border-left-color: #7e22ce0d;
}

.border-l-purple-700\/50 {
  border-left-color: #7e22ce80;
}

.border-l-purple-700\/60 {
  border-left-color: #7e22ce99;
}

.border-l-purple-700\/70 {
  border-left-color: #7e22ceb3;
}

.border-l-purple-700\/75 {
  border-left-color: #7e22cebf;
}

.border-l-purple-700\/80 {
  border-left-color: #7e22cecc;
}

.border-l-purple-700\/90 {
  border-left-color: #7e22cee6;
}

.border-l-purple-700\/95 {
  border-left-color: #7e22cef2;
}

.border-l-purple-800 {
  --tw-border-opacity: 1;
  border-left-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-l-purple-800\/0 {
  border-left-color: #6b21a800;
}

.border-l-purple-800\/10 {
  border-left-color: #6b21a81a;
}

.border-l-purple-800\/100 {
  border-left-color: #6b21a8;
}

.border-l-purple-800\/20 {
  border-left-color: #6b21a833;
}

.border-l-purple-800\/25 {
  border-left-color: #6b21a840;
}

.border-l-purple-800\/30 {
  border-left-color: #6b21a84d;
}

.border-l-purple-800\/40 {
  border-left-color: #6b21a866;
}

.border-l-purple-800\/5 {
  border-left-color: #6b21a80d;
}

.border-l-purple-800\/50 {
  border-left-color: #6b21a880;
}

.border-l-purple-800\/60 {
  border-left-color: #6b21a899;
}

.border-l-purple-800\/70 {
  border-left-color: #6b21a8b3;
}

.border-l-purple-800\/75 {
  border-left-color: #6b21a8bf;
}

.border-l-purple-800\/80 {
  border-left-color: #6b21a8cc;
}

.border-l-purple-800\/90 {
  border-left-color: #6b21a8e6;
}

.border-l-purple-800\/95 {
  border-left-color: #6b21a8f2;
}

.border-l-purple-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-l-purple-900\/0 {
  border-left-color: #581c8700;
}

.border-l-purple-900\/10 {
  border-left-color: #581c871a;
}

.border-l-purple-900\/100 {
  border-left-color: #581c87;
}

.border-l-purple-900\/20 {
  border-left-color: #581c8733;
}

.border-l-purple-900\/25 {
  border-left-color: #581c8740;
}

.border-l-purple-900\/30 {
  border-left-color: #581c874d;
}

.border-l-purple-900\/40 {
  border-left-color: #581c8766;
}

.border-l-purple-900\/5 {
  border-left-color: #581c870d;
}

.border-l-purple-900\/50 {
  border-left-color: #581c8780;
}

.border-l-purple-900\/60 {
  border-left-color: #581c8799;
}

.border-l-purple-900\/70 {
  border-left-color: #581c87b3;
}

.border-l-purple-900\/75 {
  border-left-color: #581c87bf;
}

.border-l-purple-900\/80 {
  border-left-color: #581c87cc;
}

.border-l-purple-900\/90 {
  border-left-color: #581c87e6;
}

.border-l-purple-900\/95 {
  border-left-color: #581c87f2;
}

.border-l-purple-950 {
  --tw-border-opacity: 1;
  border-left-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-l-purple-950\/0 {
  border-left-color: #3b076400;
}

.border-l-purple-950\/10 {
  border-left-color: #3b07641a;
}

.border-l-purple-950\/100 {
  border-left-color: #3b0764;
}

.border-l-purple-950\/20 {
  border-left-color: #3b076433;
}

.border-l-purple-950\/25 {
  border-left-color: #3b076440;
}

.border-l-purple-950\/30 {
  border-left-color: #3b07644d;
}

.border-l-purple-950\/40 {
  border-left-color: #3b076466;
}

.border-l-purple-950\/5 {
  border-left-color: #3b07640d;
}

.border-l-purple-950\/50 {
  border-left-color: #3b076480;
}

.border-l-purple-950\/60 {
  border-left-color: #3b076499;
}

.border-l-purple-950\/70 {
  border-left-color: #3b0764b3;
}

.border-l-purple-950\/75 {
  border-left-color: #3b0764bf;
}

.border-l-purple-950\/80 {
  border-left-color: #3b0764cc;
}

.border-l-purple-950\/90 {
  border-left-color: #3b0764e6;
}

.border-l-purple-950\/95 {
  border-left-color: #3b0764f2;
}

.border-l-transparent, .border-l-transparent\/0 {
  border-left-color: #0000;
}

.border-l-transparent\/10 {
  border-left-color: #0000001a;
}

.border-l-transparent\/100 {
  border-left-color: #000;
}

.border-l-transparent\/20 {
  border-left-color: #0003;
}

.border-l-transparent\/25 {
  border-left-color: #00000040;
}

.border-l-transparent\/30 {
  border-left-color: #0000004d;
}

.border-l-transparent\/40 {
  border-left-color: #0006;
}

.border-l-transparent\/5 {
  border-left-color: #0000000d;
}

.border-l-transparent\/50 {
  border-left-color: #00000080;
}

.border-l-transparent\/60 {
  border-left-color: #0009;
}

.border-l-transparent\/70 {
  border-left-color: #000000b3;
}

.border-l-transparent\/75 {
  border-left-color: #000000bf;
}

.border-l-transparent\/80 {
  border-left-color: #000c;
}

.border-l-transparent\/90 {
  border-left-color: #000000e6;
}

.border-l-transparent\/95 {
  border-left-color: #000000f2;
}

.border-r-amber-100 {
  --tw-border-opacity: 1;
  border-right-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-r-amber-100\/0 {
  border-right-color: #fef3c700;
}

.border-r-amber-100\/10 {
  border-right-color: #fef3c71a;
}

.border-r-amber-100\/100 {
  border-right-color: #fef3c7;
}

.border-r-amber-100\/20 {
  border-right-color: #fef3c733;
}

.border-r-amber-100\/25 {
  border-right-color: #fef3c740;
}

.border-r-amber-100\/30 {
  border-right-color: #fef3c74d;
}

.border-r-amber-100\/40 {
  border-right-color: #fef3c766;
}

.border-r-amber-100\/5 {
  border-right-color: #fef3c70d;
}

.border-r-amber-100\/50 {
  border-right-color: #fef3c780;
}

.border-r-amber-100\/60 {
  border-right-color: #fef3c799;
}

.border-r-amber-100\/70 {
  border-right-color: #fef3c7b3;
}

.border-r-amber-100\/75 {
  border-right-color: #fef3c7bf;
}

.border-r-amber-100\/80 {
  border-right-color: #fef3c7cc;
}

.border-r-amber-100\/90 {
  border-right-color: #fef3c7e6;
}

.border-r-amber-100\/95 {
  border-right-color: #fef3c7f2;
}

.border-r-amber-200 {
  --tw-border-opacity: 1;
  border-right-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-r-amber-200\/0 {
  border-right-color: #fde68a00;
}

.border-r-amber-200\/10 {
  border-right-color: #fde68a1a;
}

.border-r-amber-200\/100 {
  border-right-color: #fde68a;
}

.border-r-amber-200\/20 {
  border-right-color: #fde68a33;
}

.border-r-amber-200\/25 {
  border-right-color: #fde68a40;
}

.border-r-amber-200\/30 {
  border-right-color: #fde68a4d;
}

.border-r-amber-200\/40 {
  border-right-color: #fde68a66;
}

.border-r-amber-200\/5 {
  border-right-color: #fde68a0d;
}

.border-r-amber-200\/50 {
  border-right-color: #fde68a80;
}

.border-r-amber-200\/60 {
  border-right-color: #fde68a99;
}

.border-r-amber-200\/70 {
  border-right-color: #fde68ab3;
}

.border-r-amber-200\/75 {
  border-right-color: #fde68abf;
}

.border-r-amber-200\/80 {
  border-right-color: #fde68acc;
}

.border-r-amber-200\/90 {
  border-right-color: #fde68ae6;
}

.border-r-amber-200\/95 {
  border-right-color: #fde68af2;
}

.border-r-amber-300 {
  --tw-border-opacity: 1;
  border-right-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-r-amber-300\/0 {
  border-right-color: #fcd34d00;
}

.border-r-amber-300\/10 {
  border-right-color: #fcd34d1a;
}

.border-r-amber-300\/100 {
  border-right-color: #fcd34d;
}

.border-r-amber-300\/20 {
  border-right-color: #fcd34d33;
}

.border-r-amber-300\/25 {
  border-right-color: #fcd34d40;
}

.border-r-amber-300\/30 {
  border-right-color: #fcd34d4d;
}

.border-r-amber-300\/40 {
  border-right-color: #fcd34d66;
}

.border-r-amber-300\/5 {
  border-right-color: #fcd34d0d;
}

.border-r-amber-300\/50 {
  border-right-color: #fcd34d80;
}

.border-r-amber-300\/60 {
  border-right-color: #fcd34d99;
}

.border-r-amber-300\/70 {
  border-right-color: #fcd34db3;
}

.border-r-amber-300\/75 {
  border-right-color: #fcd34dbf;
}

.border-r-amber-300\/80 {
  border-right-color: #fcd34dcc;
}

.border-r-amber-300\/90 {
  border-right-color: #fcd34de6;
}

.border-r-amber-300\/95 {
  border-right-color: #fcd34df2;
}

.border-r-amber-400 {
  --tw-border-opacity: 1;
  border-right-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-r-amber-400\/0 {
  border-right-color: #fbbf2400;
}

.border-r-amber-400\/10 {
  border-right-color: #fbbf241a;
}

.border-r-amber-400\/100 {
  border-right-color: #fbbf24;
}

.border-r-amber-400\/20 {
  border-right-color: #fbbf2433;
}

.border-r-amber-400\/25 {
  border-right-color: #fbbf2440;
}

.border-r-amber-400\/30 {
  border-right-color: #fbbf244d;
}

.border-r-amber-400\/40 {
  border-right-color: #fbbf2466;
}

.border-r-amber-400\/5 {
  border-right-color: #fbbf240d;
}

.border-r-amber-400\/50 {
  border-right-color: #fbbf2480;
}

.border-r-amber-400\/60 {
  border-right-color: #fbbf2499;
}

.border-r-amber-400\/70 {
  border-right-color: #fbbf24b3;
}

.border-r-amber-400\/75 {
  border-right-color: #fbbf24bf;
}

.border-r-amber-400\/80 {
  border-right-color: #fbbf24cc;
}

.border-r-amber-400\/90 {
  border-right-color: #fbbf24e6;
}

.border-r-amber-400\/95 {
  border-right-color: #fbbf24f2;
}

.border-r-amber-50 {
  --tw-border-opacity: 1;
  border-right-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-r-amber-50\/0 {
  border-right-color: #fffbeb00;
}

.border-r-amber-50\/10 {
  border-right-color: #fffbeb1a;
}

.border-r-amber-50\/100 {
  border-right-color: #fffbeb;
}

.border-r-amber-50\/20 {
  border-right-color: #fffbeb33;
}

.border-r-amber-50\/25 {
  border-right-color: #fffbeb40;
}

.border-r-amber-50\/30 {
  border-right-color: #fffbeb4d;
}

.border-r-amber-50\/40 {
  border-right-color: #fffbeb66;
}

.border-r-amber-50\/5 {
  border-right-color: #fffbeb0d;
}

.border-r-amber-50\/50 {
  border-right-color: #fffbeb80;
}

.border-r-amber-50\/60 {
  border-right-color: #fffbeb99;
}

.border-r-amber-50\/70 {
  border-right-color: #fffbebb3;
}

.border-r-amber-50\/75 {
  border-right-color: #fffbebbf;
}

.border-r-amber-50\/80 {
  border-right-color: #fffbebcc;
}

.border-r-amber-50\/90 {
  border-right-color: #fffbebe6;
}

.border-r-amber-50\/95 {
  border-right-color: #fffbebf2;
}

.border-r-amber-500 {
  --tw-border-opacity: 1;
  border-right-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-r-amber-500\/0 {
  border-right-color: #f59e0b00;
}

.border-r-amber-500\/10 {
  border-right-color: #f59e0b1a;
}

.border-r-amber-500\/100 {
  border-right-color: #f59e0b;
}

.border-r-amber-500\/20 {
  border-right-color: #f59e0b33;
}

.border-r-amber-500\/25 {
  border-right-color: #f59e0b40;
}

.border-r-amber-500\/30 {
  border-right-color: #f59e0b4d;
}

.border-r-amber-500\/40 {
  border-right-color: #f59e0b66;
}

.border-r-amber-500\/5 {
  border-right-color: #f59e0b0d;
}

.border-r-amber-500\/50 {
  border-right-color: #f59e0b80;
}

.border-r-amber-500\/60 {
  border-right-color: #f59e0b99;
}

.border-r-amber-500\/70 {
  border-right-color: #f59e0bb3;
}

.border-r-amber-500\/75 {
  border-right-color: #f59e0bbf;
}

.border-r-amber-500\/80 {
  border-right-color: #f59e0bcc;
}

.border-r-amber-500\/90 {
  border-right-color: #f59e0be6;
}

.border-r-amber-500\/95 {
  border-right-color: #f59e0bf2;
}

.border-r-amber-600 {
  --tw-border-opacity: 1;
  border-right-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-r-amber-600\/0 {
  border-right-color: #d9770600;
}

.border-r-amber-600\/10 {
  border-right-color: #d977061a;
}

.border-r-amber-600\/100 {
  border-right-color: #d97706;
}

.border-r-amber-600\/20 {
  border-right-color: #d9770633;
}

.border-r-amber-600\/25 {
  border-right-color: #d9770640;
}

.border-r-amber-600\/30 {
  border-right-color: #d977064d;
}

.border-r-amber-600\/40 {
  border-right-color: #d9770666;
}

.border-r-amber-600\/5 {
  border-right-color: #d977060d;
}

.border-r-amber-600\/50 {
  border-right-color: #d9770680;
}

.border-r-amber-600\/60 {
  border-right-color: #d9770699;
}

.border-r-amber-600\/70 {
  border-right-color: #d97706b3;
}

.border-r-amber-600\/75 {
  border-right-color: #d97706bf;
}

.border-r-amber-600\/80 {
  border-right-color: #d97706cc;
}

.border-r-amber-600\/90 {
  border-right-color: #d97706e6;
}

.border-r-amber-600\/95 {
  border-right-color: #d97706f2;
}

.border-r-amber-700 {
  --tw-border-opacity: 1;
  border-right-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-r-amber-700\/0 {
  border-right-color: #b4530900;
}

.border-r-amber-700\/10 {
  border-right-color: #b453091a;
}

.border-r-amber-700\/100 {
  border-right-color: #b45309;
}

.border-r-amber-700\/20 {
  border-right-color: #b4530933;
}

.border-r-amber-700\/25 {
  border-right-color: #b4530940;
}

.border-r-amber-700\/30 {
  border-right-color: #b453094d;
}

.border-r-amber-700\/40 {
  border-right-color: #b4530966;
}

.border-r-amber-700\/5 {
  border-right-color: #b453090d;
}

.border-r-amber-700\/50 {
  border-right-color: #b4530980;
}

.border-r-amber-700\/60 {
  border-right-color: #b4530999;
}

.border-r-amber-700\/70 {
  border-right-color: #b45309b3;
}

.border-r-amber-700\/75 {
  border-right-color: #b45309bf;
}

.border-r-amber-700\/80 {
  border-right-color: #b45309cc;
}

.border-r-amber-700\/90 {
  border-right-color: #b45309e6;
}

.border-r-amber-700\/95 {
  border-right-color: #b45309f2;
}

.border-r-amber-800 {
  --tw-border-opacity: 1;
  border-right-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-r-amber-800\/0 {
  border-right-color: #92400e00;
}

.border-r-amber-800\/10 {
  border-right-color: #92400e1a;
}

.border-r-amber-800\/100 {
  border-right-color: #92400e;
}

.border-r-amber-800\/20 {
  border-right-color: #92400e33;
}

.border-r-amber-800\/25 {
  border-right-color: #92400e40;
}

.border-r-amber-800\/30 {
  border-right-color: #92400e4d;
}

.border-r-amber-800\/40 {
  border-right-color: #92400e66;
}

.border-r-amber-800\/5 {
  border-right-color: #92400e0d;
}

.border-r-amber-800\/50 {
  border-right-color: #92400e80;
}

.border-r-amber-800\/60 {
  border-right-color: #92400e99;
}

.border-r-amber-800\/70 {
  border-right-color: #92400eb3;
}

.border-r-amber-800\/75 {
  border-right-color: #92400ebf;
}

.border-r-amber-800\/80 {
  border-right-color: #92400ecc;
}

.border-r-amber-800\/90 {
  border-right-color: #92400ee6;
}

.border-r-amber-800\/95 {
  border-right-color: #92400ef2;
}

.border-r-amber-900 {
  --tw-border-opacity: 1;
  border-right-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-r-amber-900\/0 {
  border-right-color: #78350f00;
}

.border-r-amber-900\/10 {
  border-right-color: #78350f1a;
}

.border-r-amber-900\/100 {
  border-right-color: #78350f;
}

.border-r-amber-900\/20 {
  border-right-color: #78350f33;
}

.border-r-amber-900\/25 {
  border-right-color: #78350f40;
}

.border-r-amber-900\/30 {
  border-right-color: #78350f4d;
}

.border-r-amber-900\/40 {
  border-right-color: #78350f66;
}

.border-r-amber-900\/5 {
  border-right-color: #78350f0d;
}

.border-r-amber-900\/50 {
  border-right-color: #78350f80;
}

.border-r-amber-900\/60 {
  border-right-color: #78350f99;
}

.border-r-amber-900\/70 {
  border-right-color: #78350fb3;
}

.border-r-amber-900\/75 {
  border-right-color: #78350fbf;
}

.border-r-amber-900\/80 {
  border-right-color: #78350fcc;
}

.border-r-amber-900\/90 {
  border-right-color: #78350fe6;
}

.border-r-amber-900\/95 {
  border-right-color: #78350ff2;
}

.border-r-amber-950 {
  --tw-border-opacity: 1;
  border-right-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-r-amber-950\/0 {
  border-right-color: #451a0300;
}

.border-r-amber-950\/10 {
  border-right-color: #451a031a;
}

.border-r-amber-950\/100 {
  border-right-color: #451a03;
}

.border-r-amber-950\/20 {
  border-right-color: #451a0333;
}

.border-r-amber-950\/25 {
  border-right-color: #451a0340;
}

.border-r-amber-950\/30 {
  border-right-color: #451a034d;
}

.border-r-amber-950\/40 {
  border-right-color: #451a0366;
}

.border-r-amber-950\/5 {
  border-right-color: #451a030d;
}

.border-r-amber-950\/50 {
  border-right-color: #451a0380;
}

.border-r-amber-950\/60 {
  border-right-color: #451a0399;
}

.border-r-amber-950\/70 {
  border-right-color: #451a03b3;
}

.border-r-amber-950\/75 {
  border-right-color: #451a03bf;
}

.border-r-amber-950\/80 {
  border-right-color: #451a03cc;
}

.border-r-amber-950\/90 {
  border-right-color: #451a03e6;
}

.border-r-amber-950\/95 {
  border-right-color: #451a03f2;
}

.border-r-emerald-100 {
  --tw-border-opacity: 1;
  border-right-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-r-emerald-100\/0 {
  border-right-color: #d1fae500;
}

.border-r-emerald-100\/10 {
  border-right-color: #d1fae51a;
}

.border-r-emerald-100\/100 {
  border-right-color: #d1fae5;
}

.border-r-emerald-100\/20 {
  border-right-color: #d1fae533;
}

.border-r-emerald-100\/25 {
  border-right-color: #d1fae540;
}

.border-r-emerald-100\/30 {
  border-right-color: #d1fae54d;
}

.border-r-emerald-100\/40 {
  border-right-color: #d1fae566;
}

.border-r-emerald-100\/5 {
  border-right-color: #d1fae50d;
}

.border-r-emerald-100\/50 {
  border-right-color: #d1fae580;
}

.border-r-emerald-100\/60 {
  border-right-color: #d1fae599;
}

.border-r-emerald-100\/70 {
  border-right-color: #d1fae5b3;
}

.border-r-emerald-100\/75 {
  border-right-color: #d1fae5bf;
}

.border-r-emerald-100\/80 {
  border-right-color: #d1fae5cc;
}

.border-r-emerald-100\/90 {
  border-right-color: #d1fae5e6;
}

.border-r-emerald-100\/95 {
  border-right-color: #d1fae5f2;
}

.border-r-emerald-200 {
  --tw-border-opacity: 1;
  border-right-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-r-emerald-200\/0 {
  border-right-color: #a7f3d000;
}

.border-r-emerald-200\/10 {
  border-right-color: #a7f3d01a;
}

.border-r-emerald-200\/100 {
  border-right-color: #a7f3d0;
}

.border-r-emerald-200\/20 {
  border-right-color: #a7f3d033;
}

.border-r-emerald-200\/25 {
  border-right-color: #a7f3d040;
}

.border-r-emerald-200\/30 {
  border-right-color: #a7f3d04d;
}

.border-r-emerald-200\/40 {
  border-right-color: #a7f3d066;
}

.border-r-emerald-200\/5 {
  border-right-color: #a7f3d00d;
}

.border-r-emerald-200\/50 {
  border-right-color: #a7f3d080;
}

.border-r-emerald-200\/60 {
  border-right-color: #a7f3d099;
}

.border-r-emerald-200\/70 {
  border-right-color: #a7f3d0b3;
}

.border-r-emerald-200\/75 {
  border-right-color: #a7f3d0bf;
}

.border-r-emerald-200\/80 {
  border-right-color: #a7f3d0cc;
}

.border-r-emerald-200\/90 {
  border-right-color: #a7f3d0e6;
}

.border-r-emerald-200\/95 {
  border-right-color: #a7f3d0f2;
}

.border-r-emerald-300 {
  --tw-border-opacity: 1;
  border-right-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-r-emerald-300\/0 {
  border-right-color: #6ee7b700;
}

.border-r-emerald-300\/10 {
  border-right-color: #6ee7b71a;
}

.border-r-emerald-300\/100 {
  border-right-color: #6ee7b7;
}

.border-r-emerald-300\/20 {
  border-right-color: #6ee7b733;
}

.border-r-emerald-300\/25 {
  border-right-color: #6ee7b740;
}

.border-r-emerald-300\/30 {
  border-right-color: #6ee7b74d;
}

.border-r-emerald-300\/40 {
  border-right-color: #6ee7b766;
}

.border-r-emerald-300\/5 {
  border-right-color: #6ee7b70d;
}

.border-r-emerald-300\/50 {
  border-right-color: #6ee7b780;
}

.border-r-emerald-300\/60 {
  border-right-color: #6ee7b799;
}

.border-r-emerald-300\/70 {
  border-right-color: #6ee7b7b3;
}

.border-r-emerald-300\/75 {
  border-right-color: #6ee7b7bf;
}

.border-r-emerald-300\/80 {
  border-right-color: #6ee7b7cc;
}

.border-r-emerald-300\/90 {
  border-right-color: #6ee7b7e6;
}

.border-r-emerald-300\/95 {
  border-right-color: #6ee7b7f2;
}

.border-r-emerald-400 {
  --tw-border-opacity: 1;
  border-right-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-r-emerald-400\/0 {
  border-right-color: #34d39900;
}

.border-r-emerald-400\/10 {
  border-right-color: #34d3991a;
}

.border-r-emerald-400\/100 {
  border-right-color: #34d399;
}

.border-r-emerald-400\/20 {
  border-right-color: #34d39933;
}

.border-r-emerald-400\/25 {
  border-right-color: #34d39940;
}

.border-r-emerald-400\/30 {
  border-right-color: #34d3994d;
}

.border-r-emerald-400\/40 {
  border-right-color: #34d39966;
}

.border-r-emerald-400\/5 {
  border-right-color: #34d3990d;
}

.border-r-emerald-400\/50 {
  border-right-color: #34d39980;
}

.border-r-emerald-400\/60 {
  border-right-color: #34d39999;
}

.border-r-emerald-400\/70 {
  border-right-color: #34d399b3;
}

.border-r-emerald-400\/75 {
  border-right-color: #34d399bf;
}

.border-r-emerald-400\/80 {
  border-right-color: #34d399cc;
}

.border-r-emerald-400\/90 {
  border-right-color: #34d399e6;
}

.border-r-emerald-400\/95 {
  border-right-color: #34d399f2;
}

.border-r-emerald-50 {
  --tw-border-opacity: 1;
  border-right-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-r-emerald-50\/0 {
  border-right-color: #ecfdf500;
}

.border-r-emerald-50\/10 {
  border-right-color: #ecfdf51a;
}

.border-r-emerald-50\/100 {
  border-right-color: #ecfdf5;
}

.border-r-emerald-50\/20 {
  border-right-color: #ecfdf533;
}

.border-r-emerald-50\/25 {
  border-right-color: #ecfdf540;
}

.border-r-emerald-50\/30 {
  border-right-color: #ecfdf54d;
}

.border-r-emerald-50\/40 {
  border-right-color: #ecfdf566;
}

.border-r-emerald-50\/5 {
  border-right-color: #ecfdf50d;
}

.border-r-emerald-50\/50 {
  border-right-color: #ecfdf580;
}

.border-r-emerald-50\/60 {
  border-right-color: #ecfdf599;
}

.border-r-emerald-50\/70 {
  border-right-color: #ecfdf5b3;
}

.border-r-emerald-50\/75 {
  border-right-color: #ecfdf5bf;
}

.border-r-emerald-50\/80 {
  border-right-color: #ecfdf5cc;
}

.border-r-emerald-50\/90 {
  border-right-color: #ecfdf5e6;
}

.border-r-emerald-50\/95 {
  border-right-color: #ecfdf5f2;
}

.border-r-emerald-500 {
  --tw-border-opacity: 1;
  border-right-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-r-emerald-500\/0 {
  border-right-color: #10b98100;
}

.border-r-emerald-500\/10 {
  border-right-color: #10b9811a;
}

.border-r-emerald-500\/100 {
  border-right-color: #10b981;
}

.border-r-emerald-500\/20 {
  border-right-color: #10b98133;
}

.border-r-emerald-500\/25 {
  border-right-color: #10b98140;
}

.border-r-emerald-500\/30 {
  border-right-color: #10b9814d;
}

.border-r-emerald-500\/40 {
  border-right-color: #10b98166;
}

.border-r-emerald-500\/5 {
  border-right-color: #10b9810d;
}

.border-r-emerald-500\/50 {
  border-right-color: #10b98180;
}

.border-r-emerald-500\/60 {
  border-right-color: #10b98199;
}

.border-r-emerald-500\/70 {
  border-right-color: #10b981b3;
}

.border-r-emerald-500\/75 {
  border-right-color: #10b981bf;
}

.border-r-emerald-500\/80 {
  border-right-color: #10b981cc;
}

.border-r-emerald-500\/90 {
  border-right-color: #10b981e6;
}

.border-r-emerald-500\/95 {
  border-right-color: #10b981f2;
}

.border-r-emerald-600 {
  --tw-border-opacity: 1;
  border-right-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-r-emerald-600\/0 {
  border-right-color: #05966900;
}

.border-r-emerald-600\/10 {
  border-right-color: #0596691a;
}

.border-r-emerald-600\/100 {
  border-right-color: #059669;
}

.border-r-emerald-600\/20 {
  border-right-color: #05966933;
}

.border-r-emerald-600\/25 {
  border-right-color: #05966940;
}

.border-r-emerald-600\/30 {
  border-right-color: #0596694d;
}

.border-r-emerald-600\/40 {
  border-right-color: #05966966;
}

.border-r-emerald-600\/5 {
  border-right-color: #0596690d;
}

.border-r-emerald-600\/50 {
  border-right-color: #05966980;
}

.border-r-emerald-600\/60 {
  border-right-color: #05966999;
}

.border-r-emerald-600\/70 {
  border-right-color: #059669b3;
}

.border-r-emerald-600\/75 {
  border-right-color: #059669bf;
}

.border-r-emerald-600\/80 {
  border-right-color: #059669cc;
}

.border-r-emerald-600\/90 {
  border-right-color: #059669e6;
}

.border-r-emerald-600\/95 {
  border-right-color: #059669f2;
}

.border-r-emerald-700 {
  --tw-border-opacity: 1;
  border-right-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-r-emerald-700\/0 {
  border-right-color: #04785700;
}

.border-r-emerald-700\/10 {
  border-right-color: #0478571a;
}

.border-r-emerald-700\/100 {
  border-right-color: #047857;
}

.border-r-emerald-700\/20 {
  border-right-color: #04785733;
}

.border-r-emerald-700\/25 {
  border-right-color: #04785740;
}

.border-r-emerald-700\/30 {
  border-right-color: #0478574d;
}

.border-r-emerald-700\/40 {
  border-right-color: #04785766;
}

.border-r-emerald-700\/5 {
  border-right-color: #0478570d;
}

.border-r-emerald-700\/50 {
  border-right-color: #04785780;
}

.border-r-emerald-700\/60 {
  border-right-color: #04785799;
}

.border-r-emerald-700\/70 {
  border-right-color: #047857b3;
}

.border-r-emerald-700\/75 {
  border-right-color: #047857bf;
}

.border-r-emerald-700\/80 {
  border-right-color: #047857cc;
}

.border-r-emerald-700\/90 {
  border-right-color: #047857e6;
}

.border-r-emerald-700\/95 {
  border-right-color: #047857f2;
}

.border-r-emerald-800 {
  --tw-border-opacity: 1;
  border-right-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-r-emerald-800\/0 {
  border-right-color: #065f4600;
}

.border-r-emerald-800\/10 {
  border-right-color: #065f461a;
}

.border-r-emerald-800\/100 {
  border-right-color: #065f46;
}

.border-r-emerald-800\/20 {
  border-right-color: #065f4633;
}

.border-r-emerald-800\/25 {
  border-right-color: #065f4640;
}

.border-r-emerald-800\/30 {
  border-right-color: #065f464d;
}

.border-r-emerald-800\/40 {
  border-right-color: #065f4666;
}

.border-r-emerald-800\/5 {
  border-right-color: #065f460d;
}

.border-r-emerald-800\/50 {
  border-right-color: #065f4680;
}

.border-r-emerald-800\/60 {
  border-right-color: #065f4699;
}

.border-r-emerald-800\/70 {
  border-right-color: #065f46b3;
}

.border-r-emerald-800\/75 {
  border-right-color: #065f46bf;
}

.border-r-emerald-800\/80 {
  border-right-color: #065f46cc;
}

.border-r-emerald-800\/90 {
  border-right-color: #065f46e6;
}

.border-r-emerald-800\/95 {
  border-right-color: #065f46f2;
}

.border-r-emerald-900 {
  --tw-border-opacity: 1;
  border-right-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-r-emerald-900\/0 {
  border-right-color: #064e3b00;
}

.border-r-emerald-900\/10 {
  border-right-color: #064e3b1a;
}

.border-r-emerald-900\/100 {
  border-right-color: #064e3b;
}

.border-r-emerald-900\/20 {
  border-right-color: #064e3b33;
}

.border-r-emerald-900\/25 {
  border-right-color: #064e3b40;
}

.border-r-emerald-900\/30 {
  border-right-color: #064e3b4d;
}

.border-r-emerald-900\/40 {
  border-right-color: #064e3b66;
}

.border-r-emerald-900\/5 {
  border-right-color: #064e3b0d;
}

.border-r-emerald-900\/50 {
  border-right-color: #064e3b80;
}

.border-r-emerald-900\/60 {
  border-right-color: #064e3b99;
}

.border-r-emerald-900\/70 {
  border-right-color: #064e3bb3;
}

.border-r-emerald-900\/75 {
  border-right-color: #064e3bbf;
}

.border-r-emerald-900\/80 {
  border-right-color: #064e3bcc;
}

.border-r-emerald-900\/90 {
  border-right-color: #064e3be6;
}

.border-r-emerald-900\/95 {
  border-right-color: #064e3bf2;
}

.border-r-emerald-950 {
  --tw-border-opacity: 1;
  border-right-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-r-emerald-950\/0 {
  border-right-color: #022c2200;
}

.border-r-emerald-950\/10 {
  border-right-color: #022c221a;
}

.border-r-emerald-950\/100 {
  border-right-color: #022c22;
}

.border-r-emerald-950\/20 {
  border-right-color: #022c2233;
}

.border-r-emerald-950\/25 {
  border-right-color: #022c2240;
}

.border-r-emerald-950\/30 {
  border-right-color: #022c224d;
}

.border-r-emerald-950\/40 {
  border-right-color: #022c2266;
}

.border-r-emerald-950\/5 {
  border-right-color: #022c220d;
}

.border-r-emerald-950\/50 {
  border-right-color: #022c2280;
}

.border-r-emerald-950\/60 {
  border-right-color: #022c2299;
}

.border-r-emerald-950\/70 {
  border-right-color: #022c22b3;
}

.border-r-emerald-950\/75 {
  border-right-color: #022c22bf;
}

.border-r-emerald-950\/80 {
  border-right-color: #022c22cc;
}

.border-r-emerald-950\/90 {
  border-right-color: #022c22e6;
}

.border-r-emerald-950\/95 {
  border-right-color: #022c22f2;
}

.border-r-lime-100 {
  --tw-border-opacity: 1;
  border-right-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-r-lime-100\/0 {
  border-right-color: #ecfccb00;
}

.border-r-lime-100\/10 {
  border-right-color: #ecfccb1a;
}

.border-r-lime-100\/100 {
  border-right-color: #ecfccb;
}

.border-r-lime-100\/20 {
  border-right-color: #ecfccb33;
}

.border-r-lime-100\/25 {
  border-right-color: #ecfccb40;
}

.border-r-lime-100\/30 {
  border-right-color: #ecfccb4d;
}

.border-r-lime-100\/40 {
  border-right-color: #ecfccb66;
}

.border-r-lime-100\/5 {
  border-right-color: #ecfccb0d;
}

.border-r-lime-100\/50 {
  border-right-color: #ecfccb80;
}

.border-r-lime-100\/60 {
  border-right-color: #ecfccb99;
}

.border-r-lime-100\/70 {
  border-right-color: #ecfccbb3;
}

.border-r-lime-100\/75 {
  border-right-color: #ecfccbbf;
}

.border-r-lime-100\/80 {
  border-right-color: #ecfccbcc;
}

.border-r-lime-100\/90 {
  border-right-color: #ecfccbe6;
}

.border-r-lime-100\/95 {
  border-right-color: #ecfccbf2;
}

.border-r-lime-200 {
  --tw-border-opacity: 1;
  border-right-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-r-lime-200\/0 {
  border-right-color: #d9f99d00;
}

.border-r-lime-200\/10 {
  border-right-color: #d9f99d1a;
}

.border-r-lime-200\/100 {
  border-right-color: #d9f99d;
}

.border-r-lime-200\/20 {
  border-right-color: #d9f99d33;
}

.border-r-lime-200\/25 {
  border-right-color: #d9f99d40;
}

.border-r-lime-200\/30 {
  border-right-color: #d9f99d4d;
}

.border-r-lime-200\/40 {
  border-right-color: #d9f99d66;
}

.border-r-lime-200\/5 {
  border-right-color: #d9f99d0d;
}

.border-r-lime-200\/50 {
  border-right-color: #d9f99d80;
}

.border-r-lime-200\/60 {
  border-right-color: #d9f99d99;
}

.border-r-lime-200\/70 {
  border-right-color: #d9f99db3;
}

.border-r-lime-200\/75 {
  border-right-color: #d9f99dbf;
}

.border-r-lime-200\/80 {
  border-right-color: #d9f99dcc;
}

.border-r-lime-200\/90 {
  border-right-color: #d9f99de6;
}

.border-r-lime-200\/95 {
  border-right-color: #d9f99df2;
}

.border-r-lime-300 {
  --tw-border-opacity: 1;
  border-right-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-r-lime-300\/0 {
  border-right-color: #bef26400;
}

.border-r-lime-300\/10 {
  border-right-color: #bef2641a;
}

.border-r-lime-300\/100 {
  border-right-color: #bef264;
}

.border-r-lime-300\/20 {
  border-right-color: #bef26433;
}

.border-r-lime-300\/25 {
  border-right-color: #bef26440;
}

.border-r-lime-300\/30 {
  border-right-color: #bef2644d;
}

.border-r-lime-300\/40 {
  border-right-color: #bef26466;
}

.border-r-lime-300\/5 {
  border-right-color: #bef2640d;
}

.border-r-lime-300\/50 {
  border-right-color: #bef26480;
}

.border-r-lime-300\/60 {
  border-right-color: #bef26499;
}

.border-r-lime-300\/70 {
  border-right-color: #bef264b3;
}

.border-r-lime-300\/75 {
  border-right-color: #bef264bf;
}

.border-r-lime-300\/80 {
  border-right-color: #bef264cc;
}

.border-r-lime-300\/90 {
  border-right-color: #bef264e6;
}

.border-r-lime-300\/95 {
  border-right-color: #bef264f2;
}

.border-r-lime-400 {
  --tw-border-opacity: 1;
  border-right-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-r-lime-400\/0 {
  border-right-color: #a3e63500;
}

.border-r-lime-400\/10 {
  border-right-color: #a3e6351a;
}

.border-r-lime-400\/100 {
  border-right-color: #a3e635;
}

.border-r-lime-400\/20 {
  border-right-color: #a3e63533;
}

.border-r-lime-400\/25 {
  border-right-color: #a3e63540;
}

.border-r-lime-400\/30 {
  border-right-color: #a3e6354d;
}

.border-r-lime-400\/40 {
  border-right-color: #a3e63566;
}

.border-r-lime-400\/5 {
  border-right-color: #a3e6350d;
}

.border-r-lime-400\/50 {
  border-right-color: #a3e63580;
}

.border-r-lime-400\/60 {
  border-right-color: #a3e63599;
}

.border-r-lime-400\/70 {
  border-right-color: #a3e635b3;
}

.border-r-lime-400\/75 {
  border-right-color: #a3e635bf;
}

.border-r-lime-400\/80 {
  border-right-color: #a3e635cc;
}

.border-r-lime-400\/90 {
  border-right-color: #a3e635e6;
}

.border-r-lime-400\/95 {
  border-right-color: #a3e635f2;
}

.border-r-lime-50 {
  --tw-border-opacity: 1;
  border-right-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-r-lime-50\/0 {
  border-right-color: #f7fee700;
}

.border-r-lime-50\/10 {
  border-right-color: #f7fee71a;
}

.border-r-lime-50\/100 {
  border-right-color: #f7fee7;
}

.border-r-lime-50\/20 {
  border-right-color: #f7fee733;
}

.border-r-lime-50\/25 {
  border-right-color: #f7fee740;
}

.border-r-lime-50\/30 {
  border-right-color: #f7fee74d;
}

.border-r-lime-50\/40 {
  border-right-color: #f7fee766;
}

.border-r-lime-50\/5 {
  border-right-color: #f7fee70d;
}

.border-r-lime-50\/50 {
  border-right-color: #f7fee780;
}

.border-r-lime-50\/60 {
  border-right-color: #f7fee799;
}

.border-r-lime-50\/70 {
  border-right-color: #f7fee7b3;
}

.border-r-lime-50\/75 {
  border-right-color: #f7fee7bf;
}

.border-r-lime-50\/80 {
  border-right-color: #f7fee7cc;
}

.border-r-lime-50\/90 {
  border-right-color: #f7fee7e6;
}

.border-r-lime-50\/95 {
  border-right-color: #f7fee7f2;
}

.border-r-lime-500 {
  --tw-border-opacity: 1;
  border-right-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-r-lime-500\/0 {
  border-right-color: #84cc1600;
}

.border-r-lime-500\/10 {
  border-right-color: #84cc161a;
}

.border-r-lime-500\/100 {
  border-right-color: #84cc16;
}

.border-r-lime-500\/20 {
  border-right-color: #84cc1633;
}

.border-r-lime-500\/25 {
  border-right-color: #84cc1640;
}

.border-r-lime-500\/30 {
  border-right-color: #84cc164d;
}

.border-r-lime-500\/40 {
  border-right-color: #84cc1666;
}

.border-r-lime-500\/5 {
  border-right-color: #84cc160d;
}

.border-r-lime-500\/50 {
  border-right-color: #84cc1680;
}

.border-r-lime-500\/60 {
  border-right-color: #84cc1699;
}

.border-r-lime-500\/70 {
  border-right-color: #84cc16b3;
}

.border-r-lime-500\/75 {
  border-right-color: #84cc16bf;
}

.border-r-lime-500\/80 {
  border-right-color: #84cc16cc;
}

.border-r-lime-500\/90 {
  border-right-color: #84cc16e6;
}

.border-r-lime-500\/95 {
  border-right-color: #84cc16f2;
}

.border-r-lime-600 {
  --tw-border-opacity: 1;
  border-right-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-r-lime-600\/0 {
  border-right-color: #65a30d00;
}

.border-r-lime-600\/10 {
  border-right-color: #65a30d1a;
}

.border-r-lime-600\/100 {
  border-right-color: #65a30d;
}

.border-r-lime-600\/20 {
  border-right-color: #65a30d33;
}

.border-r-lime-600\/25 {
  border-right-color: #65a30d40;
}

.border-r-lime-600\/30 {
  border-right-color: #65a30d4d;
}

.border-r-lime-600\/40 {
  border-right-color: #65a30d66;
}

.border-r-lime-600\/5 {
  border-right-color: #65a30d0d;
}

.border-r-lime-600\/50 {
  border-right-color: #65a30d80;
}

.border-r-lime-600\/60 {
  border-right-color: #65a30d99;
}

.border-r-lime-600\/70 {
  border-right-color: #65a30db3;
}

.border-r-lime-600\/75 {
  border-right-color: #65a30dbf;
}

.border-r-lime-600\/80 {
  border-right-color: #65a30dcc;
}

.border-r-lime-600\/90 {
  border-right-color: #65a30de6;
}

.border-r-lime-600\/95 {
  border-right-color: #65a30df2;
}

.border-r-lime-700 {
  --tw-border-opacity: 1;
  border-right-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-r-lime-700\/0 {
  border-right-color: #4d7c0f00;
}

.border-r-lime-700\/10 {
  border-right-color: #4d7c0f1a;
}

.border-r-lime-700\/100 {
  border-right-color: #4d7c0f;
}

.border-r-lime-700\/20 {
  border-right-color: #4d7c0f33;
}

.border-r-lime-700\/25 {
  border-right-color: #4d7c0f40;
}

.border-r-lime-700\/30 {
  border-right-color: #4d7c0f4d;
}

.border-r-lime-700\/40 {
  border-right-color: #4d7c0f66;
}

.border-r-lime-700\/5 {
  border-right-color: #4d7c0f0d;
}

.border-r-lime-700\/50 {
  border-right-color: #4d7c0f80;
}

.border-r-lime-700\/60 {
  border-right-color: #4d7c0f99;
}

.border-r-lime-700\/70 {
  border-right-color: #4d7c0fb3;
}

.border-r-lime-700\/75 {
  border-right-color: #4d7c0fbf;
}

.border-r-lime-700\/80 {
  border-right-color: #4d7c0fcc;
}

.border-r-lime-700\/90 {
  border-right-color: #4d7c0fe6;
}

.border-r-lime-700\/95 {
  border-right-color: #4d7c0ff2;
}

.border-r-lime-800 {
  --tw-border-opacity: 1;
  border-right-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-r-lime-800\/0 {
  border-right-color: #3f621200;
}

.border-r-lime-800\/10 {
  border-right-color: #3f62121a;
}

.border-r-lime-800\/100 {
  border-right-color: #3f6212;
}

.border-r-lime-800\/20 {
  border-right-color: #3f621233;
}

.border-r-lime-800\/25 {
  border-right-color: #3f621240;
}

.border-r-lime-800\/30 {
  border-right-color: #3f62124d;
}

.border-r-lime-800\/40 {
  border-right-color: #3f621266;
}

.border-r-lime-800\/5 {
  border-right-color: #3f62120d;
}

.border-r-lime-800\/50 {
  border-right-color: #3f621280;
}

.border-r-lime-800\/60 {
  border-right-color: #3f621299;
}

.border-r-lime-800\/70 {
  border-right-color: #3f6212b3;
}

.border-r-lime-800\/75 {
  border-right-color: #3f6212bf;
}

.border-r-lime-800\/80 {
  border-right-color: #3f6212cc;
}

.border-r-lime-800\/90 {
  border-right-color: #3f6212e6;
}

.border-r-lime-800\/95 {
  border-right-color: #3f6212f2;
}

.border-r-lime-900 {
  --tw-border-opacity: 1;
  border-right-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-r-lime-900\/0 {
  border-right-color: #36531400;
}

.border-r-lime-900\/10 {
  border-right-color: #3653141a;
}

.border-r-lime-900\/100 {
  border-right-color: #365314;
}

.border-r-lime-900\/20 {
  border-right-color: #36531433;
}

.border-r-lime-900\/25 {
  border-right-color: #36531440;
}

.border-r-lime-900\/30 {
  border-right-color: #3653144d;
}

.border-r-lime-900\/40 {
  border-right-color: #36531466;
}

.border-r-lime-900\/5 {
  border-right-color: #3653140d;
}

.border-r-lime-900\/50 {
  border-right-color: #36531480;
}

.border-r-lime-900\/60 {
  border-right-color: #36531499;
}

.border-r-lime-900\/70 {
  border-right-color: #365314b3;
}

.border-r-lime-900\/75 {
  border-right-color: #365314bf;
}

.border-r-lime-900\/80 {
  border-right-color: #365314cc;
}

.border-r-lime-900\/90 {
  border-right-color: #365314e6;
}

.border-r-lime-900\/95 {
  border-right-color: #365314f2;
}

.border-r-lime-950 {
  --tw-border-opacity: 1;
  border-right-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-r-lime-950\/0 {
  border-right-color: #1a2e0500;
}

.border-r-lime-950\/10 {
  border-right-color: #1a2e051a;
}

.border-r-lime-950\/100 {
  border-right-color: #1a2e05;
}

.border-r-lime-950\/20 {
  border-right-color: #1a2e0533;
}

.border-r-lime-950\/25 {
  border-right-color: #1a2e0540;
}

.border-r-lime-950\/30 {
  border-right-color: #1a2e054d;
}

.border-r-lime-950\/40 {
  border-right-color: #1a2e0566;
}

.border-r-lime-950\/5 {
  border-right-color: #1a2e050d;
}

.border-r-lime-950\/50 {
  border-right-color: #1a2e0580;
}

.border-r-lime-950\/60 {
  border-right-color: #1a2e0599;
}

.border-r-lime-950\/70 {
  border-right-color: #1a2e05b3;
}

.border-r-lime-950\/75 {
  border-right-color: #1a2e05bf;
}

.border-r-lime-950\/80 {
  border-right-color: #1a2e05cc;
}

.border-r-lime-950\/90 {
  border-right-color: #1a2e05e6;
}

.border-r-lime-950\/95 {
  border-right-color: #1a2e05f2;
}

.border-r-pink-100 {
  --tw-border-opacity: 1;
  border-right-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-r-pink-100\/0 {
  border-right-color: #fce7f300;
}

.border-r-pink-100\/10 {
  border-right-color: #fce7f31a;
}

.border-r-pink-100\/100 {
  border-right-color: #fce7f3;
}

.border-r-pink-100\/20 {
  border-right-color: #fce7f333;
}

.border-r-pink-100\/25 {
  border-right-color: #fce7f340;
}

.border-r-pink-100\/30 {
  border-right-color: #fce7f34d;
}

.border-r-pink-100\/40 {
  border-right-color: #fce7f366;
}

.border-r-pink-100\/5 {
  border-right-color: #fce7f30d;
}

.border-r-pink-100\/50 {
  border-right-color: #fce7f380;
}

.border-r-pink-100\/60 {
  border-right-color: #fce7f399;
}

.border-r-pink-100\/70 {
  border-right-color: #fce7f3b3;
}

.border-r-pink-100\/75 {
  border-right-color: #fce7f3bf;
}

.border-r-pink-100\/80 {
  border-right-color: #fce7f3cc;
}

.border-r-pink-100\/90 {
  border-right-color: #fce7f3e6;
}

.border-r-pink-100\/95 {
  border-right-color: #fce7f3f2;
}

.border-r-pink-200 {
  --tw-border-opacity: 1;
  border-right-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-r-pink-200\/0 {
  border-right-color: #fbcfe800;
}

.border-r-pink-200\/10 {
  border-right-color: #fbcfe81a;
}

.border-r-pink-200\/100 {
  border-right-color: #fbcfe8;
}

.border-r-pink-200\/20 {
  border-right-color: #fbcfe833;
}

.border-r-pink-200\/25 {
  border-right-color: #fbcfe840;
}

.border-r-pink-200\/30 {
  border-right-color: #fbcfe84d;
}

.border-r-pink-200\/40 {
  border-right-color: #fbcfe866;
}

.border-r-pink-200\/5 {
  border-right-color: #fbcfe80d;
}

.border-r-pink-200\/50 {
  border-right-color: #fbcfe880;
}

.border-r-pink-200\/60 {
  border-right-color: #fbcfe899;
}

.border-r-pink-200\/70 {
  border-right-color: #fbcfe8b3;
}

.border-r-pink-200\/75 {
  border-right-color: #fbcfe8bf;
}

.border-r-pink-200\/80 {
  border-right-color: #fbcfe8cc;
}

.border-r-pink-200\/90 {
  border-right-color: #fbcfe8e6;
}

.border-r-pink-200\/95 {
  border-right-color: #fbcfe8f2;
}

.border-r-pink-300 {
  --tw-border-opacity: 1;
  border-right-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-r-pink-300\/0 {
  border-right-color: #f9a8d400;
}

.border-r-pink-300\/10 {
  border-right-color: #f9a8d41a;
}

.border-r-pink-300\/100 {
  border-right-color: #f9a8d4;
}

.border-r-pink-300\/20 {
  border-right-color: #f9a8d433;
}

.border-r-pink-300\/25 {
  border-right-color: #f9a8d440;
}

.border-r-pink-300\/30 {
  border-right-color: #f9a8d44d;
}

.border-r-pink-300\/40 {
  border-right-color: #f9a8d466;
}

.border-r-pink-300\/5 {
  border-right-color: #f9a8d40d;
}

.border-r-pink-300\/50 {
  border-right-color: #f9a8d480;
}

.border-r-pink-300\/60 {
  border-right-color: #f9a8d499;
}

.border-r-pink-300\/70 {
  border-right-color: #f9a8d4b3;
}

.border-r-pink-300\/75 {
  border-right-color: #f9a8d4bf;
}

.border-r-pink-300\/80 {
  border-right-color: #f9a8d4cc;
}

.border-r-pink-300\/90 {
  border-right-color: #f9a8d4e6;
}

.border-r-pink-300\/95 {
  border-right-color: #f9a8d4f2;
}

.border-r-pink-400 {
  --tw-border-opacity: 1;
  border-right-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-r-pink-400\/0 {
  border-right-color: #f472b600;
}

.border-r-pink-400\/10 {
  border-right-color: #f472b61a;
}

.border-r-pink-400\/100 {
  border-right-color: #f472b6;
}

.border-r-pink-400\/20 {
  border-right-color: #f472b633;
}

.border-r-pink-400\/25 {
  border-right-color: #f472b640;
}

.border-r-pink-400\/30 {
  border-right-color: #f472b64d;
}

.border-r-pink-400\/40 {
  border-right-color: #f472b666;
}

.border-r-pink-400\/5 {
  border-right-color: #f472b60d;
}

.border-r-pink-400\/50 {
  border-right-color: #f472b680;
}

.border-r-pink-400\/60 {
  border-right-color: #f472b699;
}

.border-r-pink-400\/70 {
  border-right-color: #f472b6b3;
}

.border-r-pink-400\/75 {
  border-right-color: #f472b6bf;
}

.border-r-pink-400\/80 {
  border-right-color: #f472b6cc;
}

.border-r-pink-400\/90 {
  border-right-color: #f472b6e6;
}

.border-r-pink-400\/95 {
  border-right-color: #f472b6f2;
}

.border-r-pink-50 {
  --tw-border-opacity: 1;
  border-right-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-r-pink-50\/0 {
  border-right-color: #fdf2f800;
}

.border-r-pink-50\/10 {
  border-right-color: #fdf2f81a;
}

.border-r-pink-50\/100 {
  border-right-color: #fdf2f8;
}

.border-r-pink-50\/20 {
  border-right-color: #fdf2f833;
}

.border-r-pink-50\/25 {
  border-right-color: #fdf2f840;
}

.border-r-pink-50\/30 {
  border-right-color: #fdf2f84d;
}

.border-r-pink-50\/40 {
  border-right-color: #fdf2f866;
}

.border-r-pink-50\/5 {
  border-right-color: #fdf2f80d;
}

.border-r-pink-50\/50 {
  border-right-color: #fdf2f880;
}

.border-r-pink-50\/60 {
  border-right-color: #fdf2f899;
}

.border-r-pink-50\/70 {
  border-right-color: #fdf2f8b3;
}

.border-r-pink-50\/75 {
  border-right-color: #fdf2f8bf;
}

.border-r-pink-50\/80 {
  border-right-color: #fdf2f8cc;
}

.border-r-pink-50\/90 {
  border-right-color: #fdf2f8e6;
}

.border-r-pink-50\/95 {
  border-right-color: #fdf2f8f2;
}

.border-r-pink-500 {
  --tw-border-opacity: 1;
  border-right-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-r-pink-500\/0 {
  border-right-color: #ec489900;
}

.border-r-pink-500\/10 {
  border-right-color: #ec48991a;
}

.border-r-pink-500\/100 {
  border-right-color: #ec4899;
}

.border-r-pink-500\/20 {
  border-right-color: #ec489933;
}

.border-r-pink-500\/25 {
  border-right-color: #ec489940;
}

.border-r-pink-500\/30 {
  border-right-color: #ec48994d;
}

.border-r-pink-500\/40 {
  border-right-color: #ec489966;
}

.border-r-pink-500\/5 {
  border-right-color: #ec48990d;
}

.border-r-pink-500\/50 {
  border-right-color: #ec489980;
}

.border-r-pink-500\/60 {
  border-right-color: #ec489999;
}

.border-r-pink-500\/70 {
  border-right-color: #ec4899b3;
}

.border-r-pink-500\/75 {
  border-right-color: #ec4899bf;
}

.border-r-pink-500\/80 {
  border-right-color: #ec4899cc;
}

.border-r-pink-500\/90 {
  border-right-color: #ec4899e6;
}

.border-r-pink-500\/95 {
  border-right-color: #ec4899f2;
}

.border-r-pink-600 {
  --tw-border-opacity: 1;
  border-right-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-r-pink-600\/0 {
  border-right-color: #db277700;
}

.border-r-pink-600\/10 {
  border-right-color: #db27771a;
}

.border-r-pink-600\/100 {
  border-right-color: #db2777;
}

.border-r-pink-600\/20 {
  border-right-color: #db277733;
}

.border-r-pink-600\/25 {
  border-right-color: #db277740;
}

.border-r-pink-600\/30 {
  border-right-color: #db27774d;
}

.border-r-pink-600\/40 {
  border-right-color: #db277766;
}

.border-r-pink-600\/5 {
  border-right-color: #db27770d;
}

.border-r-pink-600\/50 {
  border-right-color: #db277780;
}

.border-r-pink-600\/60 {
  border-right-color: #db277799;
}

.border-r-pink-600\/70 {
  border-right-color: #db2777b3;
}

.border-r-pink-600\/75 {
  border-right-color: #db2777bf;
}

.border-r-pink-600\/80 {
  border-right-color: #db2777cc;
}

.border-r-pink-600\/90 {
  border-right-color: #db2777e6;
}

.border-r-pink-600\/95 {
  border-right-color: #db2777f2;
}

.border-r-pink-700 {
  --tw-border-opacity: 1;
  border-right-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-r-pink-700\/0 {
  border-right-color: #be185d00;
}

.border-r-pink-700\/10 {
  border-right-color: #be185d1a;
}

.border-r-pink-700\/100 {
  border-right-color: #be185d;
}

.border-r-pink-700\/20 {
  border-right-color: #be185d33;
}

.border-r-pink-700\/25 {
  border-right-color: #be185d40;
}

.border-r-pink-700\/30 {
  border-right-color: #be185d4d;
}

.border-r-pink-700\/40 {
  border-right-color: #be185d66;
}

.border-r-pink-700\/5 {
  border-right-color: #be185d0d;
}

.border-r-pink-700\/50 {
  border-right-color: #be185d80;
}

.border-r-pink-700\/60 {
  border-right-color: #be185d99;
}

.border-r-pink-700\/70 {
  border-right-color: #be185db3;
}

.border-r-pink-700\/75 {
  border-right-color: #be185dbf;
}

.border-r-pink-700\/80 {
  border-right-color: #be185dcc;
}

.border-r-pink-700\/90 {
  border-right-color: #be185de6;
}

.border-r-pink-700\/95 {
  border-right-color: #be185df2;
}

.border-r-pink-800 {
  --tw-border-opacity: 1;
  border-right-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-r-pink-800\/0 {
  border-right-color: #9d174d00;
}

.border-r-pink-800\/10 {
  border-right-color: #9d174d1a;
}

.border-r-pink-800\/100 {
  border-right-color: #9d174d;
}

.border-r-pink-800\/20 {
  border-right-color: #9d174d33;
}

.border-r-pink-800\/25 {
  border-right-color: #9d174d40;
}

.border-r-pink-800\/30 {
  border-right-color: #9d174d4d;
}

.border-r-pink-800\/40 {
  border-right-color: #9d174d66;
}

.border-r-pink-800\/5 {
  border-right-color: #9d174d0d;
}

.border-r-pink-800\/50 {
  border-right-color: #9d174d80;
}

.border-r-pink-800\/60 {
  border-right-color: #9d174d99;
}

.border-r-pink-800\/70 {
  border-right-color: #9d174db3;
}

.border-r-pink-800\/75 {
  border-right-color: #9d174dbf;
}

.border-r-pink-800\/80 {
  border-right-color: #9d174dcc;
}

.border-r-pink-800\/90 {
  border-right-color: #9d174de6;
}

.border-r-pink-800\/95 {
  border-right-color: #9d174df2;
}

.border-r-pink-900 {
  --tw-border-opacity: 1;
  border-right-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-r-pink-900\/0 {
  border-right-color: #83184300;
}

.border-r-pink-900\/10 {
  border-right-color: #8318431a;
}

.border-r-pink-900\/100 {
  border-right-color: #831843;
}

.border-r-pink-900\/20 {
  border-right-color: #83184333;
}

.border-r-pink-900\/25 {
  border-right-color: #83184340;
}

.border-r-pink-900\/30 {
  border-right-color: #8318434d;
}

.border-r-pink-900\/40 {
  border-right-color: #83184366;
}

.border-r-pink-900\/5 {
  border-right-color: #8318430d;
}

.border-r-pink-900\/50 {
  border-right-color: #83184380;
}

.border-r-pink-900\/60 {
  border-right-color: #83184399;
}

.border-r-pink-900\/70 {
  border-right-color: #831843b3;
}

.border-r-pink-900\/75 {
  border-right-color: #831843bf;
}

.border-r-pink-900\/80 {
  border-right-color: #831843cc;
}

.border-r-pink-900\/90 {
  border-right-color: #831843e6;
}

.border-r-pink-900\/95 {
  border-right-color: #831843f2;
}

.border-r-pink-950 {
  --tw-border-opacity: 1;
  border-right-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-r-pink-950\/0 {
  border-right-color: #50072400;
}

.border-r-pink-950\/10 {
  border-right-color: #5007241a;
}

.border-r-pink-950\/100 {
  border-right-color: #500724;
}

.border-r-pink-950\/20 {
  border-right-color: #50072433;
}

.border-r-pink-950\/25 {
  border-right-color: #50072440;
}

.border-r-pink-950\/30 {
  border-right-color: #5007244d;
}

.border-r-pink-950\/40 {
  border-right-color: #50072466;
}

.border-r-pink-950\/5 {
  border-right-color: #5007240d;
}

.border-r-pink-950\/50 {
  border-right-color: #50072480;
}

.border-r-pink-950\/60 {
  border-right-color: #50072499;
}

.border-r-pink-950\/70 {
  border-right-color: #500724b3;
}

.border-r-pink-950\/75 {
  border-right-color: #500724bf;
}

.border-r-pink-950\/80 {
  border-right-color: #500724cc;
}

.border-r-pink-950\/90 {
  border-right-color: #500724e6;
}

.border-r-pink-950\/95 {
  border-right-color: #500724f2;
}

.border-r-primary {
  --tw-border-opacity: 1;
  border-right-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-r-primary\/0 {
  border-right-color: hsl(var(--primary) / 0);
}

.border-r-primary\/10 {
  border-right-color: hsl(var(--primary) / .1);
}

.border-r-primary\/100 {
  border-right-color: hsl(var(--primary) / 1);
}

.border-r-primary\/20 {
  border-right-color: hsl(var(--primary) / .2);
}

.border-r-primary\/25 {
  border-right-color: hsl(var(--primary) / .25);
}

.border-r-primary\/30 {
  border-right-color: hsl(var(--primary) / .3);
}

.border-r-primary\/40 {
  border-right-color: hsl(var(--primary) / .4);
}

.border-r-primary\/5 {
  border-right-color: hsl(var(--primary) / .05);
}

.border-r-primary\/50 {
  border-right-color: hsl(var(--primary) / .5);
}

.border-r-primary\/60 {
  border-right-color: hsl(var(--primary) / .6);
}

.border-r-primary\/70 {
  border-right-color: hsl(var(--primary) / .7);
}

.border-r-primary\/75 {
  border-right-color: hsl(var(--primary) / .75);
}

.border-r-primary\/80 {
  border-right-color: hsl(var(--primary) / .8);
}

.border-r-primary\/90 {
  border-right-color: hsl(var(--primary) / .9);
}

.border-r-primary\/95 {
  border-right-color: hsl(var(--primary) / .95);
}

.border-r-purple-100 {
  --tw-border-opacity: 1;
  border-right-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-r-purple-100\/0 {
  border-right-color: #f3e8ff00;
}

.border-r-purple-100\/10 {
  border-right-color: #f3e8ff1a;
}

.border-r-purple-100\/100 {
  border-right-color: #f3e8ff;
}

.border-r-purple-100\/20 {
  border-right-color: #f3e8ff33;
}

.border-r-purple-100\/25 {
  border-right-color: #f3e8ff40;
}

.border-r-purple-100\/30 {
  border-right-color: #f3e8ff4d;
}

.border-r-purple-100\/40 {
  border-right-color: #f3e8ff66;
}

.border-r-purple-100\/5 {
  border-right-color: #f3e8ff0d;
}

.border-r-purple-100\/50 {
  border-right-color: #f3e8ff80;
}

.border-r-purple-100\/60 {
  border-right-color: #f3e8ff99;
}

.border-r-purple-100\/70 {
  border-right-color: #f3e8ffb3;
}

.border-r-purple-100\/75 {
  border-right-color: #f3e8ffbf;
}

.border-r-purple-100\/80 {
  border-right-color: #f3e8ffcc;
}

.border-r-purple-100\/90 {
  border-right-color: #f3e8ffe6;
}

.border-r-purple-100\/95 {
  border-right-color: #f3e8fff2;
}

.border-r-purple-200 {
  --tw-border-opacity: 1;
  border-right-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-r-purple-200\/0 {
  border-right-color: #e9d5ff00;
}

.border-r-purple-200\/10 {
  border-right-color: #e9d5ff1a;
}

.border-r-purple-200\/100 {
  border-right-color: #e9d5ff;
}

.border-r-purple-200\/20 {
  border-right-color: #e9d5ff33;
}

.border-r-purple-200\/25 {
  border-right-color: #e9d5ff40;
}

.border-r-purple-200\/30 {
  border-right-color: #e9d5ff4d;
}

.border-r-purple-200\/40 {
  border-right-color: #e9d5ff66;
}

.border-r-purple-200\/5 {
  border-right-color: #e9d5ff0d;
}

.border-r-purple-200\/50 {
  border-right-color: #e9d5ff80;
}

.border-r-purple-200\/60 {
  border-right-color: #e9d5ff99;
}

.border-r-purple-200\/70 {
  border-right-color: #e9d5ffb3;
}

.border-r-purple-200\/75 {
  border-right-color: #e9d5ffbf;
}

.border-r-purple-200\/80 {
  border-right-color: #e9d5ffcc;
}

.border-r-purple-200\/90 {
  border-right-color: #e9d5ffe6;
}

.border-r-purple-200\/95 {
  border-right-color: #e9d5fff2;
}

.border-r-purple-300 {
  --tw-border-opacity: 1;
  border-right-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-r-purple-300\/0 {
  border-right-color: #d8b4fe00;
}

.border-r-purple-300\/10 {
  border-right-color: #d8b4fe1a;
}

.border-r-purple-300\/100 {
  border-right-color: #d8b4fe;
}

.border-r-purple-300\/20 {
  border-right-color: #d8b4fe33;
}

.border-r-purple-300\/25 {
  border-right-color: #d8b4fe40;
}

.border-r-purple-300\/30 {
  border-right-color: #d8b4fe4d;
}

.border-r-purple-300\/40 {
  border-right-color: #d8b4fe66;
}

.border-r-purple-300\/5 {
  border-right-color: #d8b4fe0d;
}

.border-r-purple-300\/50 {
  border-right-color: #d8b4fe80;
}

.border-r-purple-300\/60 {
  border-right-color: #d8b4fe99;
}

.border-r-purple-300\/70 {
  border-right-color: #d8b4feb3;
}

.border-r-purple-300\/75 {
  border-right-color: #d8b4febf;
}

.border-r-purple-300\/80 {
  border-right-color: #d8b4fecc;
}

.border-r-purple-300\/90 {
  border-right-color: #d8b4fee6;
}

.border-r-purple-300\/95 {
  border-right-color: #d8b4fef2;
}

.border-r-purple-400 {
  --tw-border-opacity: 1;
  border-right-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-r-purple-400\/0 {
  border-right-color: #c084fc00;
}

.border-r-purple-400\/10 {
  border-right-color: #c084fc1a;
}

.border-r-purple-400\/100 {
  border-right-color: #c084fc;
}

.border-r-purple-400\/20 {
  border-right-color: #c084fc33;
}

.border-r-purple-400\/25 {
  border-right-color: #c084fc40;
}

.border-r-purple-400\/30 {
  border-right-color: #c084fc4d;
}

.border-r-purple-400\/40 {
  border-right-color: #c084fc66;
}

.border-r-purple-400\/5 {
  border-right-color: #c084fc0d;
}

.border-r-purple-400\/50 {
  border-right-color: #c084fc80;
}

.border-r-purple-400\/60 {
  border-right-color: #c084fc99;
}

.border-r-purple-400\/70 {
  border-right-color: #c084fcb3;
}

.border-r-purple-400\/75 {
  border-right-color: #c084fcbf;
}

.border-r-purple-400\/80 {
  border-right-color: #c084fccc;
}

.border-r-purple-400\/90 {
  border-right-color: #c084fce6;
}

.border-r-purple-400\/95 {
  border-right-color: #c084fcf2;
}

.border-r-purple-50 {
  --tw-border-opacity: 1;
  border-right-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-r-purple-50\/0 {
  border-right-color: #faf5ff00;
}

.border-r-purple-50\/10 {
  border-right-color: #faf5ff1a;
}

.border-r-purple-50\/100 {
  border-right-color: #faf5ff;
}

.border-r-purple-50\/20 {
  border-right-color: #faf5ff33;
}

.border-r-purple-50\/25 {
  border-right-color: #faf5ff40;
}

.border-r-purple-50\/30 {
  border-right-color: #faf5ff4d;
}

.border-r-purple-50\/40 {
  border-right-color: #faf5ff66;
}

.border-r-purple-50\/5 {
  border-right-color: #faf5ff0d;
}

.border-r-purple-50\/50 {
  border-right-color: #faf5ff80;
}

.border-r-purple-50\/60 {
  border-right-color: #faf5ff99;
}

.border-r-purple-50\/70 {
  border-right-color: #faf5ffb3;
}

.border-r-purple-50\/75 {
  border-right-color: #faf5ffbf;
}

.border-r-purple-50\/80 {
  border-right-color: #faf5ffcc;
}

.border-r-purple-50\/90 {
  border-right-color: #faf5ffe6;
}

.border-r-purple-50\/95 {
  border-right-color: #faf5fff2;
}

.border-r-purple-500 {
  --tw-border-opacity: 1;
  border-right-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-r-purple-500\/0 {
  border-right-color: #a855f700;
}

.border-r-purple-500\/10 {
  border-right-color: #a855f71a;
}

.border-r-purple-500\/100 {
  border-right-color: #a855f7;
}

.border-r-purple-500\/20 {
  border-right-color: #a855f733;
}

.border-r-purple-500\/25 {
  border-right-color: #a855f740;
}

.border-r-purple-500\/30 {
  border-right-color: #a855f74d;
}

.border-r-purple-500\/40 {
  border-right-color: #a855f766;
}

.border-r-purple-500\/5 {
  border-right-color: #a855f70d;
}

.border-r-purple-500\/50 {
  border-right-color: #a855f780;
}

.border-r-purple-500\/60 {
  border-right-color: #a855f799;
}

.border-r-purple-500\/70 {
  border-right-color: #a855f7b3;
}

.border-r-purple-500\/75 {
  border-right-color: #a855f7bf;
}

.border-r-purple-500\/80 {
  border-right-color: #a855f7cc;
}

.border-r-purple-500\/90 {
  border-right-color: #a855f7e6;
}

.border-r-purple-500\/95 {
  border-right-color: #a855f7f2;
}

.border-r-purple-600 {
  --tw-border-opacity: 1;
  border-right-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-r-purple-600\/0 {
  border-right-color: #9333ea00;
}

.border-r-purple-600\/10 {
  border-right-color: #9333ea1a;
}

.border-r-purple-600\/100 {
  border-right-color: #9333ea;
}

.border-r-purple-600\/20 {
  border-right-color: #9333ea33;
}

.border-r-purple-600\/25 {
  border-right-color: #9333ea40;
}

.border-r-purple-600\/30 {
  border-right-color: #9333ea4d;
}

.border-r-purple-600\/40 {
  border-right-color: #9333ea66;
}

.border-r-purple-600\/5 {
  border-right-color: #9333ea0d;
}

.border-r-purple-600\/50 {
  border-right-color: #9333ea80;
}

.border-r-purple-600\/60 {
  border-right-color: #9333ea99;
}

.border-r-purple-600\/70 {
  border-right-color: #9333eab3;
}

.border-r-purple-600\/75 {
  border-right-color: #9333eabf;
}

.border-r-purple-600\/80 {
  border-right-color: #9333eacc;
}

.border-r-purple-600\/90 {
  border-right-color: #9333eae6;
}

.border-r-purple-600\/95 {
  border-right-color: #9333eaf2;
}

.border-r-purple-700 {
  --tw-border-opacity: 1;
  border-right-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-r-purple-700\/0 {
  border-right-color: #7e22ce00;
}

.border-r-purple-700\/10 {
  border-right-color: #7e22ce1a;
}

.border-r-purple-700\/100 {
  border-right-color: #7e22ce;
}

.border-r-purple-700\/20 {
  border-right-color: #7e22ce33;
}

.border-r-purple-700\/25 {
  border-right-color: #7e22ce40;
}

.border-r-purple-700\/30 {
  border-right-color: #7e22ce4d;
}

.border-r-purple-700\/40 {
  border-right-color: #7e22ce66;
}

.border-r-purple-700\/5 {
  border-right-color: #7e22ce0d;
}

.border-r-purple-700\/50 {
  border-right-color: #7e22ce80;
}

.border-r-purple-700\/60 {
  border-right-color: #7e22ce99;
}

.border-r-purple-700\/70 {
  border-right-color: #7e22ceb3;
}

.border-r-purple-700\/75 {
  border-right-color: #7e22cebf;
}

.border-r-purple-700\/80 {
  border-right-color: #7e22cecc;
}

.border-r-purple-700\/90 {
  border-right-color: #7e22cee6;
}

.border-r-purple-700\/95 {
  border-right-color: #7e22cef2;
}

.border-r-purple-800 {
  --tw-border-opacity: 1;
  border-right-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-r-purple-800\/0 {
  border-right-color: #6b21a800;
}

.border-r-purple-800\/10 {
  border-right-color: #6b21a81a;
}

.border-r-purple-800\/100 {
  border-right-color: #6b21a8;
}

.border-r-purple-800\/20 {
  border-right-color: #6b21a833;
}

.border-r-purple-800\/25 {
  border-right-color: #6b21a840;
}

.border-r-purple-800\/30 {
  border-right-color: #6b21a84d;
}

.border-r-purple-800\/40 {
  border-right-color: #6b21a866;
}

.border-r-purple-800\/5 {
  border-right-color: #6b21a80d;
}

.border-r-purple-800\/50 {
  border-right-color: #6b21a880;
}

.border-r-purple-800\/60 {
  border-right-color: #6b21a899;
}

.border-r-purple-800\/70 {
  border-right-color: #6b21a8b3;
}

.border-r-purple-800\/75 {
  border-right-color: #6b21a8bf;
}

.border-r-purple-800\/80 {
  border-right-color: #6b21a8cc;
}

.border-r-purple-800\/90 {
  border-right-color: #6b21a8e6;
}

.border-r-purple-800\/95 {
  border-right-color: #6b21a8f2;
}

.border-r-purple-900 {
  --tw-border-opacity: 1;
  border-right-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-r-purple-900\/0 {
  border-right-color: #581c8700;
}

.border-r-purple-900\/10 {
  border-right-color: #581c871a;
}

.border-r-purple-900\/100 {
  border-right-color: #581c87;
}

.border-r-purple-900\/20 {
  border-right-color: #581c8733;
}

.border-r-purple-900\/25 {
  border-right-color: #581c8740;
}

.border-r-purple-900\/30 {
  border-right-color: #581c874d;
}

.border-r-purple-900\/40 {
  border-right-color: #581c8766;
}

.border-r-purple-900\/5 {
  border-right-color: #581c870d;
}

.border-r-purple-900\/50 {
  border-right-color: #581c8780;
}

.border-r-purple-900\/60 {
  border-right-color: #581c8799;
}

.border-r-purple-900\/70 {
  border-right-color: #581c87b3;
}

.border-r-purple-900\/75 {
  border-right-color: #581c87bf;
}

.border-r-purple-900\/80 {
  border-right-color: #581c87cc;
}

.border-r-purple-900\/90 {
  border-right-color: #581c87e6;
}

.border-r-purple-900\/95 {
  border-right-color: #581c87f2;
}

.border-r-purple-950 {
  --tw-border-opacity: 1;
  border-right-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-r-purple-950\/0 {
  border-right-color: #3b076400;
}

.border-r-purple-950\/10 {
  border-right-color: #3b07641a;
}

.border-r-purple-950\/100 {
  border-right-color: #3b0764;
}

.border-r-purple-950\/20 {
  border-right-color: #3b076433;
}

.border-r-purple-950\/25 {
  border-right-color: #3b076440;
}

.border-r-purple-950\/30 {
  border-right-color: #3b07644d;
}

.border-r-purple-950\/40 {
  border-right-color: #3b076466;
}

.border-r-purple-950\/5 {
  border-right-color: #3b07640d;
}

.border-r-purple-950\/50 {
  border-right-color: #3b076480;
}

.border-r-purple-950\/60 {
  border-right-color: #3b076499;
}

.border-r-purple-950\/70 {
  border-right-color: #3b0764b3;
}

.border-r-purple-950\/75 {
  border-right-color: #3b0764bf;
}

.border-r-purple-950\/80 {
  border-right-color: #3b0764cc;
}

.border-r-purple-950\/90 {
  border-right-color: #3b0764e6;
}

.border-r-purple-950\/95 {
  border-right-color: #3b0764f2;
}

.border-r-transparent, .border-r-transparent\/0 {
  border-right-color: #0000;
}

.border-r-transparent\/10 {
  border-right-color: #0000001a;
}

.border-r-transparent\/100 {
  border-right-color: #000;
}

.border-r-transparent\/20 {
  border-right-color: #0003;
}

.border-r-transparent\/25 {
  border-right-color: #00000040;
}

.border-r-transparent\/30 {
  border-right-color: #0000004d;
}

.border-r-transparent\/40 {
  border-right-color: #0006;
}

.border-r-transparent\/5 {
  border-right-color: #0000000d;
}

.border-r-transparent\/50 {
  border-right-color: #00000080;
}

.border-r-transparent\/60 {
  border-right-color: #0009;
}

.border-r-transparent\/70 {
  border-right-color: #000000b3;
}

.border-r-transparent\/75 {
  border-right-color: #000000bf;
}

.border-r-transparent\/80 {
  border-right-color: #000c;
}

.border-r-transparent\/90 {
  border-right-color: #000000e6;
}

.border-r-transparent\/95 {
  border-right-color: #000000f2;
}

.border-s-amber-100 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-s-amber-100\/0 {
  border-inline-start-color: #fef3c700;
}

.border-s-amber-100\/10 {
  border-inline-start-color: #fef3c71a;
}

.border-s-amber-100\/100 {
  border-inline-start-color: #fef3c7;
}

.border-s-amber-100\/20 {
  border-inline-start-color: #fef3c733;
}

.border-s-amber-100\/25 {
  border-inline-start-color: #fef3c740;
}

.border-s-amber-100\/30 {
  border-inline-start-color: #fef3c74d;
}

.border-s-amber-100\/40 {
  border-inline-start-color: #fef3c766;
}

.border-s-amber-100\/5 {
  border-inline-start-color: #fef3c70d;
}

.border-s-amber-100\/50 {
  border-inline-start-color: #fef3c780;
}

.border-s-amber-100\/60 {
  border-inline-start-color: #fef3c799;
}

.border-s-amber-100\/70 {
  border-inline-start-color: #fef3c7b3;
}

.border-s-amber-100\/75 {
  border-inline-start-color: #fef3c7bf;
}

.border-s-amber-100\/80 {
  border-inline-start-color: #fef3c7cc;
}

.border-s-amber-100\/90 {
  border-inline-start-color: #fef3c7e6;
}

.border-s-amber-100\/95 {
  border-inline-start-color: #fef3c7f2;
}

.border-s-amber-200 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-s-amber-200\/0 {
  border-inline-start-color: #fde68a00;
}

.border-s-amber-200\/10 {
  border-inline-start-color: #fde68a1a;
}

.border-s-amber-200\/100 {
  border-inline-start-color: #fde68a;
}

.border-s-amber-200\/20 {
  border-inline-start-color: #fde68a33;
}

.border-s-amber-200\/25 {
  border-inline-start-color: #fde68a40;
}

.border-s-amber-200\/30 {
  border-inline-start-color: #fde68a4d;
}

.border-s-amber-200\/40 {
  border-inline-start-color: #fde68a66;
}

.border-s-amber-200\/5 {
  border-inline-start-color: #fde68a0d;
}

.border-s-amber-200\/50 {
  border-inline-start-color: #fde68a80;
}

.border-s-amber-200\/60 {
  border-inline-start-color: #fde68a99;
}

.border-s-amber-200\/70 {
  border-inline-start-color: #fde68ab3;
}

.border-s-amber-200\/75 {
  border-inline-start-color: #fde68abf;
}

.border-s-amber-200\/80 {
  border-inline-start-color: #fde68acc;
}

.border-s-amber-200\/90 {
  border-inline-start-color: #fde68ae6;
}

.border-s-amber-200\/95 {
  border-inline-start-color: #fde68af2;
}

.border-s-amber-300 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-s-amber-300\/0 {
  border-inline-start-color: #fcd34d00;
}

.border-s-amber-300\/10 {
  border-inline-start-color: #fcd34d1a;
}

.border-s-amber-300\/100 {
  border-inline-start-color: #fcd34d;
}

.border-s-amber-300\/20 {
  border-inline-start-color: #fcd34d33;
}

.border-s-amber-300\/25 {
  border-inline-start-color: #fcd34d40;
}

.border-s-amber-300\/30 {
  border-inline-start-color: #fcd34d4d;
}

.border-s-amber-300\/40 {
  border-inline-start-color: #fcd34d66;
}

.border-s-amber-300\/5 {
  border-inline-start-color: #fcd34d0d;
}

.border-s-amber-300\/50 {
  border-inline-start-color: #fcd34d80;
}

.border-s-amber-300\/60 {
  border-inline-start-color: #fcd34d99;
}

.border-s-amber-300\/70 {
  border-inline-start-color: #fcd34db3;
}

.border-s-amber-300\/75 {
  border-inline-start-color: #fcd34dbf;
}

.border-s-amber-300\/80 {
  border-inline-start-color: #fcd34dcc;
}

.border-s-amber-300\/90 {
  border-inline-start-color: #fcd34de6;
}

.border-s-amber-300\/95 {
  border-inline-start-color: #fcd34df2;
}

.border-s-amber-400 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-s-amber-400\/0 {
  border-inline-start-color: #fbbf2400;
}

.border-s-amber-400\/10 {
  border-inline-start-color: #fbbf241a;
}

.border-s-amber-400\/100 {
  border-inline-start-color: #fbbf24;
}

.border-s-amber-400\/20 {
  border-inline-start-color: #fbbf2433;
}

.border-s-amber-400\/25 {
  border-inline-start-color: #fbbf2440;
}

.border-s-amber-400\/30 {
  border-inline-start-color: #fbbf244d;
}

.border-s-amber-400\/40 {
  border-inline-start-color: #fbbf2466;
}

.border-s-amber-400\/5 {
  border-inline-start-color: #fbbf240d;
}

.border-s-amber-400\/50 {
  border-inline-start-color: #fbbf2480;
}

.border-s-amber-400\/60 {
  border-inline-start-color: #fbbf2499;
}

.border-s-amber-400\/70 {
  border-inline-start-color: #fbbf24b3;
}

.border-s-amber-400\/75 {
  border-inline-start-color: #fbbf24bf;
}

.border-s-amber-400\/80 {
  border-inline-start-color: #fbbf24cc;
}

.border-s-amber-400\/90 {
  border-inline-start-color: #fbbf24e6;
}

.border-s-amber-400\/95 {
  border-inline-start-color: #fbbf24f2;
}

.border-s-amber-50 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-s-amber-50\/0 {
  border-inline-start-color: #fffbeb00;
}

.border-s-amber-50\/10 {
  border-inline-start-color: #fffbeb1a;
}

.border-s-amber-50\/100 {
  border-inline-start-color: #fffbeb;
}

.border-s-amber-50\/20 {
  border-inline-start-color: #fffbeb33;
}

.border-s-amber-50\/25 {
  border-inline-start-color: #fffbeb40;
}

.border-s-amber-50\/30 {
  border-inline-start-color: #fffbeb4d;
}

.border-s-amber-50\/40 {
  border-inline-start-color: #fffbeb66;
}

.border-s-amber-50\/5 {
  border-inline-start-color: #fffbeb0d;
}

.border-s-amber-50\/50 {
  border-inline-start-color: #fffbeb80;
}

.border-s-amber-50\/60 {
  border-inline-start-color: #fffbeb99;
}

.border-s-amber-50\/70 {
  border-inline-start-color: #fffbebb3;
}

.border-s-amber-50\/75 {
  border-inline-start-color: #fffbebbf;
}

.border-s-amber-50\/80 {
  border-inline-start-color: #fffbebcc;
}

.border-s-amber-50\/90 {
  border-inline-start-color: #fffbebe6;
}

.border-s-amber-50\/95 {
  border-inline-start-color: #fffbebf2;
}

.border-s-amber-500 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-s-amber-500\/0 {
  border-inline-start-color: #f59e0b00;
}

.border-s-amber-500\/10 {
  border-inline-start-color: #f59e0b1a;
}

.border-s-amber-500\/100 {
  border-inline-start-color: #f59e0b;
}

.border-s-amber-500\/20 {
  border-inline-start-color: #f59e0b33;
}

.border-s-amber-500\/25 {
  border-inline-start-color: #f59e0b40;
}

.border-s-amber-500\/30 {
  border-inline-start-color: #f59e0b4d;
}

.border-s-amber-500\/40 {
  border-inline-start-color: #f59e0b66;
}

.border-s-amber-500\/5 {
  border-inline-start-color: #f59e0b0d;
}

.border-s-amber-500\/50 {
  border-inline-start-color: #f59e0b80;
}

.border-s-amber-500\/60 {
  border-inline-start-color: #f59e0b99;
}

.border-s-amber-500\/70 {
  border-inline-start-color: #f59e0bb3;
}

.border-s-amber-500\/75 {
  border-inline-start-color: #f59e0bbf;
}

.border-s-amber-500\/80 {
  border-inline-start-color: #f59e0bcc;
}

.border-s-amber-500\/90 {
  border-inline-start-color: #f59e0be6;
}

.border-s-amber-500\/95 {
  border-inline-start-color: #f59e0bf2;
}

.border-s-amber-600 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-s-amber-600\/0 {
  border-inline-start-color: #d9770600;
}

.border-s-amber-600\/10 {
  border-inline-start-color: #d977061a;
}

.border-s-amber-600\/100 {
  border-inline-start-color: #d97706;
}

.border-s-amber-600\/20 {
  border-inline-start-color: #d9770633;
}

.border-s-amber-600\/25 {
  border-inline-start-color: #d9770640;
}

.border-s-amber-600\/30 {
  border-inline-start-color: #d977064d;
}

.border-s-amber-600\/40 {
  border-inline-start-color: #d9770666;
}

.border-s-amber-600\/5 {
  border-inline-start-color: #d977060d;
}

.border-s-amber-600\/50 {
  border-inline-start-color: #d9770680;
}

.border-s-amber-600\/60 {
  border-inline-start-color: #d9770699;
}

.border-s-amber-600\/70 {
  border-inline-start-color: #d97706b3;
}

.border-s-amber-600\/75 {
  border-inline-start-color: #d97706bf;
}

.border-s-amber-600\/80 {
  border-inline-start-color: #d97706cc;
}

.border-s-amber-600\/90 {
  border-inline-start-color: #d97706e6;
}

.border-s-amber-600\/95 {
  border-inline-start-color: #d97706f2;
}

.border-s-amber-700 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-s-amber-700\/0 {
  border-inline-start-color: #b4530900;
}

.border-s-amber-700\/10 {
  border-inline-start-color: #b453091a;
}

.border-s-amber-700\/100 {
  border-inline-start-color: #b45309;
}

.border-s-amber-700\/20 {
  border-inline-start-color: #b4530933;
}

.border-s-amber-700\/25 {
  border-inline-start-color: #b4530940;
}

.border-s-amber-700\/30 {
  border-inline-start-color: #b453094d;
}

.border-s-amber-700\/40 {
  border-inline-start-color: #b4530966;
}

.border-s-amber-700\/5 {
  border-inline-start-color: #b453090d;
}

.border-s-amber-700\/50 {
  border-inline-start-color: #b4530980;
}

.border-s-amber-700\/60 {
  border-inline-start-color: #b4530999;
}

.border-s-amber-700\/70 {
  border-inline-start-color: #b45309b3;
}

.border-s-amber-700\/75 {
  border-inline-start-color: #b45309bf;
}

.border-s-amber-700\/80 {
  border-inline-start-color: #b45309cc;
}

.border-s-amber-700\/90 {
  border-inline-start-color: #b45309e6;
}

.border-s-amber-700\/95 {
  border-inline-start-color: #b45309f2;
}

.border-s-amber-800 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-s-amber-800\/0 {
  border-inline-start-color: #92400e00;
}

.border-s-amber-800\/10 {
  border-inline-start-color: #92400e1a;
}

.border-s-amber-800\/100 {
  border-inline-start-color: #92400e;
}

.border-s-amber-800\/20 {
  border-inline-start-color: #92400e33;
}

.border-s-amber-800\/25 {
  border-inline-start-color: #92400e40;
}

.border-s-amber-800\/30 {
  border-inline-start-color: #92400e4d;
}

.border-s-amber-800\/40 {
  border-inline-start-color: #92400e66;
}

.border-s-amber-800\/5 {
  border-inline-start-color: #92400e0d;
}

.border-s-amber-800\/50 {
  border-inline-start-color: #92400e80;
}

.border-s-amber-800\/60 {
  border-inline-start-color: #92400e99;
}

.border-s-amber-800\/70 {
  border-inline-start-color: #92400eb3;
}

.border-s-amber-800\/75 {
  border-inline-start-color: #92400ebf;
}

.border-s-amber-800\/80 {
  border-inline-start-color: #92400ecc;
}

.border-s-amber-800\/90 {
  border-inline-start-color: #92400ee6;
}

.border-s-amber-800\/95 {
  border-inline-start-color: #92400ef2;
}

.border-s-amber-900 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-s-amber-900\/0 {
  border-inline-start-color: #78350f00;
}

.border-s-amber-900\/10 {
  border-inline-start-color: #78350f1a;
}

.border-s-amber-900\/100 {
  border-inline-start-color: #78350f;
}

.border-s-amber-900\/20 {
  border-inline-start-color: #78350f33;
}

.border-s-amber-900\/25 {
  border-inline-start-color: #78350f40;
}

.border-s-amber-900\/30 {
  border-inline-start-color: #78350f4d;
}

.border-s-amber-900\/40 {
  border-inline-start-color: #78350f66;
}

.border-s-amber-900\/5 {
  border-inline-start-color: #78350f0d;
}

.border-s-amber-900\/50 {
  border-inline-start-color: #78350f80;
}

.border-s-amber-900\/60 {
  border-inline-start-color: #78350f99;
}

.border-s-amber-900\/70 {
  border-inline-start-color: #78350fb3;
}

.border-s-amber-900\/75 {
  border-inline-start-color: #78350fbf;
}

.border-s-amber-900\/80 {
  border-inline-start-color: #78350fcc;
}

.border-s-amber-900\/90 {
  border-inline-start-color: #78350fe6;
}

.border-s-amber-900\/95 {
  border-inline-start-color: #78350ff2;
}

.border-s-amber-950 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-s-amber-950\/0 {
  border-inline-start-color: #451a0300;
}

.border-s-amber-950\/10 {
  border-inline-start-color: #451a031a;
}

.border-s-amber-950\/100 {
  border-inline-start-color: #451a03;
}

.border-s-amber-950\/20 {
  border-inline-start-color: #451a0333;
}

.border-s-amber-950\/25 {
  border-inline-start-color: #451a0340;
}

.border-s-amber-950\/30 {
  border-inline-start-color: #451a034d;
}

.border-s-amber-950\/40 {
  border-inline-start-color: #451a0366;
}

.border-s-amber-950\/5 {
  border-inline-start-color: #451a030d;
}

.border-s-amber-950\/50 {
  border-inline-start-color: #451a0380;
}

.border-s-amber-950\/60 {
  border-inline-start-color: #451a0399;
}

.border-s-amber-950\/70 {
  border-inline-start-color: #451a03b3;
}

.border-s-amber-950\/75 {
  border-inline-start-color: #451a03bf;
}

.border-s-amber-950\/80 {
  border-inline-start-color: #451a03cc;
}

.border-s-amber-950\/90 {
  border-inline-start-color: #451a03e6;
}

.border-s-amber-950\/95 {
  border-inline-start-color: #451a03f2;
}

.border-s-emerald-100 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-s-emerald-100\/0 {
  border-inline-start-color: #d1fae500;
}

.border-s-emerald-100\/10 {
  border-inline-start-color: #d1fae51a;
}

.border-s-emerald-100\/100 {
  border-inline-start-color: #d1fae5;
}

.border-s-emerald-100\/20 {
  border-inline-start-color: #d1fae533;
}

.border-s-emerald-100\/25 {
  border-inline-start-color: #d1fae540;
}

.border-s-emerald-100\/30 {
  border-inline-start-color: #d1fae54d;
}

.border-s-emerald-100\/40 {
  border-inline-start-color: #d1fae566;
}

.border-s-emerald-100\/5 {
  border-inline-start-color: #d1fae50d;
}

.border-s-emerald-100\/50 {
  border-inline-start-color: #d1fae580;
}

.border-s-emerald-100\/60 {
  border-inline-start-color: #d1fae599;
}

.border-s-emerald-100\/70 {
  border-inline-start-color: #d1fae5b3;
}

.border-s-emerald-100\/75 {
  border-inline-start-color: #d1fae5bf;
}

.border-s-emerald-100\/80 {
  border-inline-start-color: #d1fae5cc;
}

.border-s-emerald-100\/90 {
  border-inline-start-color: #d1fae5e6;
}

.border-s-emerald-100\/95 {
  border-inline-start-color: #d1fae5f2;
}

.border-s-emerald-200 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-s-emerald-200\/0 {
  border-inline-start-color: #a7f3d000;
}

.border-s-emerald-200\/10 {
  border-inline-start-color: #a7f3d01a;
}

.border-s-emerald-200\/100 {
  border-inline-start-color: #a7f3d0;
}

.border-s-emerald-200\/20 {
  border-inline-start-color: #a7f3d033;
}

.border-s-emerald-200\/25 {
  border-inline-start-color: #a7f3d040;
}

.border-s-emerald-200\/30 {
  border-inline-start-color: #a7f3d04d;
}

.border-s-emerald-200\/40 {
  border-inline-start-color: #a7f3d066;
}

.border-s-emerald-200\/5 {
  border-inline-start-color: #a7f3d00d;
}

.border-s-emerald-200\/50 {
  border-inline-start-color: #a7f3d080;
}

.border-s-emerald-200\/60 {
  border-inline-start-color: #a7f3d099;
}

.border-s-emerald-200\/70 {
  border-inline-start-color: #a7f3d0b3;
}

.border-s-emerald-200\/75 {
  border-inline-start-color: #a7f3d0bf;
}

.border-s-emerald-200\/80 {
  border-inline-start-color: #a7f3d0cc;
}

.border-s-emerald-200\/90 {
  border-inline-start-color: #a7f3d0e6;
}

.border-s-emerald-200\/95 {
  border-inline-start-color: #a7f3d0f2;
}

.border-s-emerald-300 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-s-emerald-300\/0 {
  border-inline-start-color: #6ee7b700;
}

.border-s-emerald-300\/10 {
  border-inline-start-color: #6ee7b71a;
}

.border-s-emerald-300\/100 {
  border-inline-start-color: #6ee7b7;
}

.border-s-emerald-300\/20 {
  border-inline-start-color: #6ee7b733;
}

.border-s-emerald-300\/25 {
  border-inline-start-color: #6ee7b740;
}

.border-s-emerald-300\/30 {
  border-inline-start-color: #6ee7b74d;
}

.border-s-emerald-300\/40 {
  border-inline-start-color: #6ee7b766;
}

.border-s-emerald-300\/5 {
  border-inline-start-color: #6ee7b70d;
}

.border-s-emerald-300\/50 {
  border-inline-start-color: #6ee7b780;
}

.border-s-emerald-300\/60 {
  border-inline-start-color: #6ee7b799;
}

.border-s-emerald-300\/70 {
  border-inline-start-color: #6ee7b7b3;
}

.border-s-emerald-300\/75 {
  border-inline-start-color: #6ee7b7bf;
}

.border-s-emerald-300\/80 {
  border-inline-start-color: #6ee7b7cc;
}

.border-s-emerald-300\/90 {
  border-inline-start-color: #6ee7b7e6;
}

.border-s-emerald-300\/95 {
  border-inline-start-color: #6ee7b7f2;
}

.border-s-emerald-400 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-s-emerald-400\/0 {
  border-inline-start-color: #34d39900;
}

.border-s-emerald-400\/10 {
  border-inline-start-color: #34d3991a;
}

.border-s-emerald-400\/100 {
  border-inline-start-color: #34d399;
}

.border-s-emerald-400\/20 {
  border-inline-start-color: #34d39933;
}

.border-s-emerald-400\/25 {
  border-inline-start-color: #34d39940;
}

.border-s-emerald-400\/30 {
  border-inline-start-color: #34d3994d;
}

.border-s-emerald-400\/40 {
  border-inline-start-color: #34d39966;
}

.border-s-emerald-400\/5 {
  border-inline-start-color: #34d3990d;
}

.border-s-emerald-400\/50 {
  border-inline-start-color: #34d39980;
}

.border-s-emerald-400\/60 {
  border-inline-start-color: #34d39999;
}

.border-s-emerald-400\/70 {
  border-inline-start-color: #34d399b3;
}

.border-s-emerald-400\/75 {
  border-inline-start-color: #34d399bf;
}

.border-s-emerald-400\/80 {
  border-inline-start-color: #34d399cc;
}

.border-s-emerald-400\/90 {
  border-inline-start-color: #34d399e6;
}

.border-s-emerald-400\/95 {
  border-inline-start-color: #34d399f2;
}

.border-s-emerald-50 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-s-emerald-50\/0 {
  border-inline-start-color: #ecfdf500;
}

.border-s-emerald-50\/10 {
  border-inline-start-color: #ecfdf51a;
}

.border-s-emerald-50\/100 {
  border-inline-start-color: #ecfdf5;
}

.border-s-emerald-50\/20 {
  border-inline-start-color: #ecfdf533;
}

.border-s-emerald-50\/25 {
  border-inline-start-color: #ecfdf540;
}

.border-s-emerald-50\/30 {
  border-inline-start-color: #ecfdf54d;
}

.border-s-emerald-50\/40 {
  border-inline-start-color: #ecfdf566;
}

.border-s-emerald-50\/5 {
  border-inline-start-color: #ecfdf50d;
}

.border-s-emerald-50\/50 {
  border-inline-start-color: #ecfdf580;
}

.border-s-emerald-50\/60 {
  border-inline-start-color: #ecfdf599;
}

.border-s-emerald-50\/70 {
  border-inline-start-color: #ecfdf5b3;
}

.border-s-emerald-50\/75 {
  border-inline-start-color: #ecfdf5bf;
}

.border-s-emerald-50\/80 {
  border-inline-start-color: #ecfdf5cc;
}

.border-s-emerald-50\/90 {
  border-inline-start-color: #ecfdf5e6;
}

.border-s-emerald-50\/95 {
  border-inline-start-color: #ecfdf5f2;
}

.border-s-emerald-500 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-s-emerald-500\/0 {
  border-inline-start-color: #10b98100;
}

.border-s-emerald-500\/10 {
  border-inline-start-color: #10b9811a;
}

.border-s-emerald-500\/100 {
  border-inline-start-color: #10b981;
}

.border-s-emerald-500\/20 {
  border-inline-start-color: #10b98133;
}

.border-s-emerald-500\/25 {
  border-inline-start-color: #10b98140;
}

.border-s-emerald-500\/30 {
  border-inline-start-color: #10b9814d;
}

.border-s-emerald-500\/40 {
  border-inline-start-color: #10b98166;
}

.border-s-emerald-500\/5 {
  border-inline-start-color: #10b9810d;
}

.border-s-emerald-500\/50 {
  border-inline-start-color: #10b98180;
}

.border-s-emerald-500\/60 {
  border-inline-start-color: #10b98199;
}

.border-s-emerald-500\/70 {
  border-inline-start-color: #10b981b3;
}

.border-s-emerald-500\/75 {
  border-inline-start-color: #10b981bf;
}

.border-s-emerald-500\/80 {
  border-inline-start-color: #10b981cc;
}

.border-s-emerald-500\/90 {
  border-inline-start-color: #10b981e6;
}

.border-s-emerald-500\/95 {
  border-inline-start-color: #10b981f2;
}

.border-s-emerald-600 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-s-emerald-600\/0 {
  border-inline-start-color: #05966900;
}

.border-s-emerald-600\/10 {
  border-inline-start-color: #0596691a;
}

.border-s-emerald-600\/100 {
  border-inline-start-color: #059669;
}

.border-s-emerald-600\/20 {
  border-inline-start-color: #05966933;
}

.border-s-emerald-600\/25 {
  border-inline-start-color: #05966940;
}

.border-s-emerald-600\/30 {
  border-inline-start-color: #0596694d;
}

.border-s-emerald-600\/40 {
  border-inline-start-color: #05966966;
}

.border-s-emerald-600\/5 {
  border-inline-start-color: #0596690d;
}

.border-s-emerald-600\/50 {
  border-inline-start-color: #05966980;
}

.border-s-emerald-600\/60 {
  border-inline-start-color: #05966999;
}

.border-s-emerald-600\/70 {
  border-inline-start-color: #059669b3;
}

.border-s-emerald-600\/75 {
  border-inline-start-color: #059669bf;
}

.border-s-emerald-600\/80 {
  border-inline-start-color: #059669cc;
}

.border-s-emerald-600\/90 {
  border-inline-start-color: #059669e6;
}

.border-s-emerald-600\/95 {
  border-inline-start-color: #059669f2;
}

.border-s-emerald-700 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-s-emerald-700\/0 {
  border-inline-start-color: #04785700;
}

.border-s-emerald-700\/10 {
  border-inline-start-color: #0478571a;
}

.border-s-emerald-700\/100 {
  border-inline-start-color: #047857;
}

.border-s-emerald-700\/20 {
  border-inline-start-color: #04785733;
}

.border-s-emerald-700\/25 {
  border-inline-start-color: #04785740;
}

.border-s-emerald-700\/30 {
  border-inline-start-color: #0478574d;
}

.border-s-emerald-700\/40 {
  border-inline-start-color: #04785766;
}

.border-s-emerald-700\/5 {
  border-inline-start-color: #0478570d;
}

.border-s-emerald-700\/50 {
  border-inline-start-color: #04785780;
}

.border-s-emerald-700\/60 {
  border-inline-start-color: #04785799;
}

.border-s-emerald-700\/70 {
  border-inline-start-color: #047857b3;
}

.border-s-emerald-700\/75 {
  border-inline-start-color: #047857bf;
}

.border-s-emerald-700\/80 {
  border-inline-start-color: #047857cc;
}

.border-s-emerald-700\/90 {
  border-inline-start-color: #047857e6;
}

.border-s-emerald-700\/95 {
  border-inline-start-color: #047857f2;
}

.border-s-emerald-800 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-s-emerald-800\/0 {
  border-inline-start-color: #065f4600;
}

.border-s-emerald-800\/10 {
  border-inline-start-color: #065f461a;
}

.border-s-emerald-800\/100 {
  border-inline-start-color: #065f46;
}

.border-s-emerald-800\/20 {
  border-inline-start-color: #065f4633;
}

.border-s-emerald-800\/25 {
  border-inline-start-color: #065f4640;
}

.border-s-emerald-800\/30 {
  border-inline-start-color: #065f464d;
}

.border-s-emerald-800\/40 {
  border-inline-start-color: #065f4666;
}

.border-s-emerald-800\/5 {
  border-inline-start-color: #065f460d;
}

.border-s-emerald-800\/50 {
  border-inline-start-color: #065f4680;
}

.border-s-emerald-800\/60 {
  border-inline-start-color: #065f4699;
}

.border-s-emerald-800\/70 {
  border-inline-start-color: #065f46b3;
}

.border-s-emerald-800\/75 {
  border-inline-start-color: #065f46bf;
}

.border-s-emerald-800\/80 {
  border-inline-start-color: #065f46cc;
}

.border-s-emerald-800\/90 {
  border-inline-start-color: #065f46e6;
}

.border-s-emerald-800\/95 {
  border-inline-start-color: #065f46f2;
}

.border-s-emerald-900 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-s-emerald-900\/0 {
  border-inline-start-color: #064e3b00;
}

.border-s-emerald-900\/10 {
  border-inline-start-color: #064e3b1a;
}

.border-s-emerald-900\/100 {
  border-inline-start-color: #064e3b;
}

.border-s-emerald-900\/20 {
  border-inline-start-color: #064e3b33;
}

.border-s-emerald-900\/25 {
  border-inline-start-color: #064e3b40;
}

.border-s-emerald-900\/30 {
  border-inline-start-color: #064e3b4d;
}

.border-s-emerald-900\/40 {
  border-inline-start-color: #064e3b66;
}

.border-s-emerald-900\/5 {
  border-inline-start-color: #064e3b0d;
}

.border-s-emerald-900\/50 {
  border-inline-start-color: #064e3b80;
}

.border-s-emerald-900\/60 {
  border-inline-start-color: #064e3b99;
}

.border-s-emerald-900\/70 {
  border-inline-start-color: #064e3bb3;
}

.border-s-emerald-900\/75 {
  border-inline-start-color: #064e3bbf;
}

.border-s-emerald-900\/80 {
  border-inline-start-color: #064e3bcc;
}

.border-s-emerald-900\/90 {
  border-inline-start-color: #064e3be6;
}

.border-s-emerald-900\/95 {
  border-inline-start-color: #064e3bf2;
}

.border-s-emerald-950 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-s-emerald-950\/0 {
  border-inline-start-color: #022c2200;
}

.border-s-emerald-950\/10 {
  border-inline-start-color: #022c221a;
}

.border-s-emerald-950\/100 {
  border-inline-start-color: #022c22;
}

.border-s-emerald-950\/20 {
  border-inline-start-color: #022c2233;
}

.border-s-emerald-950\/25 {
  border-inline-start-color: #022c2240;
}

.border-s-emerald-950\/30 {
  border-inline-start-color: #022c224d;
}

.border-s-emerald-950\/40 {
  border-inline-start-color: #022c2266;
}

.border-s-emerald-950\/5 {
  border-inline-start-color: #022c220d;
}

.border-s-emerald-950\/50 {
  border-inline-start-color: #022c2280;
}

.border-s-emerald-950\/60 {
  border-inline-start-color: #022c2299;
}

.border-s-emerald-950\/70 {
  border-inline-start-color: #022c22b3;
}

.border-s-emerald-950\/75 {
  border-inline-start-color: #022c22bf;
}

.border-s-emerald-950\/80 {
  border-inline-start-color: #022c22cc;
}

.border-s-emerald-950\/90 {
  border-inline-start-color: #022c22e6;
}

.border-s-emerald-950\/95 {
  border-inline-start-color: #022c22f2;
}

.border-s-lime-100 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-s-lime-100\/0 {
  border-inline-start-color: #ecfccb00;
}

.border-s-lime-100\/10 {
  border-inline-start-color: #ecfccb1a;
}

.border-s-lime-100\/100 {
  border-inline-start-color: #ecfccb;
}

.border-s-lime-100\/20 {
  border-inline-start-color: #ecfccb33;
}

.border-s-lime-100\/25 {
  border-inline-start-color: #ecfccb40;
}

.border-s-lime-100\/30 {
  border-inline-start-color: #ecfccb4d;
}

.border-s-lime-100\/40 {
  border-inline-start-color: #ecfccb66;
}

.border-s-lime-100\/5 {
  border-inline-start-color: #ecfccb0d;
}

.border-s-lime-100\/50 {
  border-inline-start-color: #ecfccb80;
}

.border-s-lime-100\/60 {
  border-inline-start-color: #ecfccb99;
}

.border-s-lime-100\/70 {
  border-inline-start-color: #ecfccbb3;
}

.border-s-lime-100\/75 {
  border-inline-start-color: #ecfccbbf;
}

.border-s-lime-100\/80 {
  border-inline-start-color: #ecfccbcc;
}

.border-s-lime-100\/90 {
  border-inline-start-color: #ecfccbe6;
}

.border-s-lime-100\/95 {
  border-inline-start-color: #ecfccbf2;
}

.border-s-lime-200 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-s-lime-200\/0 {
  border-inline-start-color: #d9f99d00;
}

.border-s-lime-200\/10 {
  border-inline-start-color: #d9f99d1a;
}

.border-s-lime-200\/100 {
  border-inline-start-color: #d9f99d;
}

.border-s-lime-200\/20 {
  border-inline-start-color: #d9f99d33;
}

.border-s-lime-200\/25 {
  border-inline-start-color: #d9f99d40;
}

.border-s-lime-200\/30 {
  border-inline-start-color: #d9f99d4d;
}

.border-s-lime-200\/40 {
  border-inline-start-color: #d9f99d66;
}

.border-s-lime-200\/5 {
  border-inline-start-color: #d9f99d0d;
}

.border-s-lime-200\/50 {
  border-inline-start-color: #d9f99d80;
}

.border-s-lime-200\/60 {
  border-inline-start-color: #d9f99d99;
}

.border-s-lime-200\/70 {
  border-inline-start-color: #d9f99db3;
}

.border-s-lime-200\/75 {
  border-inline-start-color: #d9f99dbf;
}

.border-s-lime-200\/80 {
  border-inline-start-color: #d9f99dcc;
}

.border-s-lime-200\/90 {
  border-inline-start-color: #d9f99de6;
}

.border-s-lime-200\/95 {
  border-inline-start-color: #d9f99df2;
}

.border-s-lime-300 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-s-lime-300\/0 {
  border-inline-start-color: #bef26400;
}

.border-s-lime-300\/10 {
  border-inline-start-color: #bef2641a;
}

.border-s-lime-300\/100 {
  border-inline-start-color: #bef264;
}

.border-s-lime-300\/20 {
  border-inline-start-color: #bef26433;
}

.border-s-lime-300\/25 {
  border-inline-start-color: #bef26440;
}

.border-s-lime-300\/30 {
  border-inline-start-color: #bef2644d;
}

.border-s-lime-300\/40 {
  border-inline-start-color: #bef26466;
}

.border-s-lime-300\/5 {
  border-inline-start-color: #bef2640d;
}

.border-s-lime-300\/50 {
  border-inline-start-color: #bef26480;
}

.border-s-lime-300\/60 {
  border-inline-start-color: #bef26499;
}

.border-s-lime-300\/70 {
  border-inline-start-color: #bef264b3;
}

.border-s-lime-300\/75 {
  border-inline-start-color: #bef264bf;
}

.border-s-lime-300\/80 {
  border-inline-start-color: #bef264cc;
}

.border-s-lime-300\/90 {
  border-inline-start-color: #bef264e6;
}

.border-s-lime-300\/95 {
  border-inline-start-color: #bef264f2;
}

.border-s-lime-400 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-s-lime-400\/0 {
  border-inline-start-color: #a3e63500;
}

.border-s-lime-400\/10 {
  border-inline-start-color: #a3e6351a;
}

.border-s-lime-400\/100 {
  border-inline-start-color: #a3e635;
}

.border-s-lime-400\/20 {
  border-inline-start-color: #a3e63533;
}

.border-s-lime-400\/25 {
  border-inline-start-color: #a3e63540;
}

.border-s-lime-400\/30 {
  border-inline-start-color: #a3e6354d;
}

.border-s-lime-400\/40 {
  border-inline-start-color: #a3e63566;
}

.border-s-lime-400\/5 {
  border-inline-start-color: #a3e6350d;
}

.border-s-lime-400\/50 {
  border-inline-start-color: #a3e63580;
}

.border-s-lime-400\/60 {
  border-inline-start-color: #a3e63599;
}

.border-s-lime-400\/70 {
  border-inline-start-color: #a3e635b3;
}

.border-s-lime-400\/75 {
  border-inline-start-color: #a3e635bf;
}

.border-s-lime-400\/80 {
  border-inline-start-color: #a3e635cc;
}

.border-s-lime-400\/90 {
  border-inline-start-color: #a3e635e6;
}

.border-s-lime-400\/95 {
  border-inline-start-color: #a3e635f2;
}

.border-s-lime-50 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-s-lime-50\/0 {
  border-inline-start-color: #f7fee700;
}

.border-s-lime-50\/10 {
  border-inline-start-color: #f7fee71a;
}

.border-s-lime-50\/100 {
  border-inline-start-color: #f7fee7;
}

.border-s-lime-50\/20 {
  border-inline-start-color: #f7fee733;
}

.border-s-lime-50\/25 {
  border-inline-start-color: #f7fee740;
}

.border-s-lime-50\/30 {
  border-inline-start-color: #f7fee74d;
}

.border-s-lime-50\/40 {
  border-inline-start-color: #f7fee766;
}

.border-s-lime-50\/5 {
  border-inline-start-color: #f7fee70d;
}

.border-s-lime-50\/50 {
  border-inline-start-color: #f7fee780;
}

.border-s-lime-50\/60 {
  border-inline-start-color: #f7fee799;
}

.border-s-lime-50\/70 {
  border-inline-start-color: #f7fee7b3;
}

.border-s-lime-50\/75 {
  border-inline-start-color: #f7fee7bf;
}

.border-s-lime-50\/80 {
  border-inline-start-color: #f7fee7cc;
}

.border-s-lime-50\/90 {
  border-inline-start-color: #f7fee7e6;
}

.border-s-lime-50\/95 {
  border-inline-start-color: #f7fee7f2;
}

.border-s-lime-500 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-s-lime-500\/0 {
  border-inline-start-color: #84cc1600;
}

.border-s-lime-500\/10 {
  border-inline-start-color: #84cc161a;
}

.border-s-lime-500\/100 {
  border-inline-start-color: #84cc16;
}

.border-s-lime-500\/20 {
  border-inline-start-color: #84cc1633;
}

.border-s-lime-500\/25 {
  border-inline-start-color: #84cc1640;
}

.border-s-lime-500\/30 {
  border-inline-start-color: #84cc164d;
}

.border-s-lime-500\/40 {
  border-inline-start-color: #84cc1666;
}

.border-s-lime-500\/5 {
  border-inline-start-color: #84cc160d;
}

.border-s-lime-500\/50 {
  border-inline-start-color: #84cc1680;
}

.border-s-lime-500\/60 {
  border-inline-start-color: #84cc1699;
}

.border-s-lime-500\/70 {
  border-inline-start-color: #84cc16b3;
}

.border-s-lime-500\/75 {
  border-inline-start-color: #84cc16bf;
}

.border-s-lime-500\/80 {
  border-inline-start-color: #84cc16cc;
}

.border-s-lime-500\/90 {
  border-inline-start-color: #84cc16e6;
}

.border-s-lime-500\/95 {
  border-inline-start-color: #84cc16f2;
}

.border-s-lime-600 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-s-lime-600\/0 {
  border-inline-start-color: #65a30d00;
}

.border-s-lime-600\/10 {
  border-inline-start-color: #65a30d1a;
}

.border-s-lime-600\/100 {
  border-inline-start-color: #65a30d;
}

.border-s-lime-600\/20 {
  border-inline-start-color: #65a30d33;
}

.border-s-lime-600\/25 {
  border-inline-start-color: #65a30d40;
}

.border-s-lime-600\/30 {
  border-inline-start-color: #65a30d4d;
}

.border-s-lime-600\/40 {
  border-inline-start-color: #65a30d66;
}

.border-s-lime-600\/5 {
  border-inline-start-color: #65a30d0d;
}

.border-s-lime-600\/50 {
  border-inline-start-color: #65a30d80;
}

.border-s-lime-600\/60 {
  border-inline-start-color: #65a30d99;
}

.border-s-lime-600\/70 {
  border-inline-start-color: #65a30db3;
}

.border-s-lime-600\/75 {
  border-inline-start-color: #65a30dbf;
}

.border-s-lime-600\/80 {
  border-inline-start-color: #65a30dcc;
}

.border-s-lime-600\/90 {
  border-inline-start-color: #65a30de6;
}

.border-s-lime-600\/95 {
  border-inline-start-color: #65a30df2;
}

.border-s-lime-700 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-s-lime-700\/0 {
  border-inline-start-color: #4d7c0f00;
}

.border-s-lime-700\/10 {
  border-inline-start-color: #4d7c0f1a;
}

.border-s-lime-700\/100 {
  border-inline-start-color: #4d7c0f;
}

.border-s-lime-700\/20 {
  border-inline-start-color: #4d7c0f33;
}

.border-s-lime-700\/25 {
  border-inline-start-color: #4d7c0f40;
}

.border-s-lime-700\/30 {
  border-inline-start-color: #4d7c0f4d;
}

.border-s-lime-700\/40 {
  border-inline-start-color: #4d7c0f66;
}

.border-s-lime-700\/5 {
  border-inline-start-color: #4d7c0f0d;
}

.border-s-lime-700\/50 {
  border-inline-start-color: #4d7c0f80;
}

.border-s-lime-700\/60 {
  border-inline-start-color: #4d7c0f99;
}

.border-s-lime-700\/70 {
  border-inline-start-color: #4d7c0fb3;
}

.border-s-lime-700\/75 {
  border-inline-start-color: #4d7c0fbf;
}

.border-s-lime-700\/80 {
  border-inline-start-color: #4d7c0fcc;
}

.border-s-lime-700\/90 {
  border-inline-start-color: #4d7c0fe6;
}

.border-s-lime-700\/95 {
  border-inline-start-color: #4d7c0ff2;
}

.border-s-lime-800 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-s-lime-800\/0 {
  border-inline-start-color: #3f621200;
}

.border-s-lime-800\/10 {
  border-inline-start-color: #3f62121a;
}

.border-s-lime-800\/100 {
  border-inline-start-color: #3f6212;
}

.border-s-lime-800\/20 {
  border-inline-start-color: #3f621233;
}

.border-s-lime-800\/25 {
  border-inline-start-color: #3f621240;
}

.border-s-lime-800\/30 {
  border-inline-start-color: #3f62124d;
}

.border-s-lime-800\/40 {
  border-inline-start-color: #3f621266;
}

.border-s-lime-800\/5 {
  border-inline-start-color: #3f62120d;
}

.border-s-lime-800\/50 {
  border-inline-start-color: #3f621280;
}

.border-s-lime-800\/60 {
  border-inline-start-color: #3f621299;
}

.border-s-lime-800\/70 {
  border-inline-start-color: #3f6212b3;
}

.border-s-lime-800\/75 {
  border-inline-start-color: #3f6212bf;
}

.border-s-lime-800\/80 {
  border-inline-start-color: #3f6212cc;
}

.border-s-lime-800\/90 {
  border-inline-start-color: #3f6212e6;
}

.border-s-lime-800\/95 {
  border-inline-start-color: #3f6212f2;
}

.border-s-lime-900 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-s-lime-900\/0 {
  border-inline-start-color: #36531400;
}

.border-s-lime-900\/10 {
  border-inline-start-color: #3653141a;
}

.border-s-lime-900\/100 {
  border-inline-start-color: #365314;
}

.border-s-lime-900\/20 {
  border-inline-start-color: #36531433;
}

.border-s-lime-900\/25 {
  border-inline-start-color: #36531440;
}

.border-s-lime-900\/30 {
  border-inline-start-color: #3653144d;
}

.border-s-lime-900\/40 {
  border-inline-start-color: #36531466;
}

.border-s-lime-900\/5 {
  border-inline-start-color: #3653140d;
}

.border-s-lime-900\/50 {
  border-inline-start-color: #36531480;
}

.border-s-lime-900\/60 {
  border-inline-start-color: #36531499;
}

.border-s-lime-900\/70 {
  border-inline-start-color: #365314b3;
}

.border-s-lime-900\/75 {
  border-inline-start-color: #365314bf;
}

.border-s-lime-900\/80 {
  border-inline-start-color: #365314cc;
}

.border-s-lime-900\/90 {
  border-inline-start-color: #365314e6;
}

.border-s-lime-900\/95 {
  border-inline-start-color: #365314f2;
}

.border-s-lime-950 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-s-lime-950\/0 {
  border-inline-start-color: #1a2e0500;
}

.border-s-lime-950\/10 {
  border-inline-start-color: #1a2e051a;
}

.border-s-lime-950\/100 {
  border-inline-start-color: #1a2e05;
}

.border-s-lime-950\/20 {
  border-inline-start-color: #1a2e0533;
}

.border-s-lime-950\/25 {
  border-inline-start-color: #1a2e0540;
}

.border-s-lime-950\/30 {
  border-inline-start-color: #1a2e054d;
}

.border-s-lime-950\/40 {
  border-inline-start-color: #1a2e0566;
}

.border-s-lime-950\/5 {
  border-inline-start-color: #1a2e050d;
}

.border-s-lime-950\/50 {
  border-inline-start-color: #1a2e0580;
}

.border-s-lime-950\/60 {
  border-inline-start-color: #1a2e0599;
}

.border-s-lime-950\/70 {
  border-inline-start-color: #1a2e05b3;
}

.border-s-lime-950\/75 {
  border-inline-start-color: #1a2e05bf;
}

.border-s-lime-950\/80 {
  border-inline-start-color: #1a2e05cc;
}

.border-s-lime-950\/90 {
  border-inline-start-color: #1a2e05e6;
}

.border-s-lime-950\/95 {
  border-inline-start-color: #1a2e05f2;
}

.border-s-pink-100 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-s-pink-100\/0 {
  border-inline-start-color: #fce7f300;
}

.border-s-pink-100\/10 {
  border-inline-start-color: #fce7f31a;
}

.border-s-pink-100\/100 {
  border-inline-start-color: #fce7f3;
}

.border-s-pink-100\/20 {
  border-inline-start-color: #fce7f333;
}

.border-s-pink-100\/25 {
  border-inline-start-color: #fce7f340;
}

.border-s-pink-100\/30 {
  border-inline-start-color: #fce7f34d;
}

.border-s-pink-100\/40 {
  border-inline-start-color: #fce7f366;
}

.border-s-pink-100\/5 {
  border-inline-start-color: #fce7f30d;
}

.border-s-pink-100\/50 {
  border-inline-start-color: #fce7f380;
}

.border-s-pink-100\/60 {
  border-inline-start-color: #fce7f399;
}

.border-s-pink-100\/70 {
  border-inline-start-color: #fce7f3b3;
}

.border-s-pink-100\/75 {
  border-inline-start-color: #fce7f3bf;
}

.border-s-pink-100\/80 {
  border-inline-start-color: #fce7f3cc;
}

.border-s-pink-100\/90 {
  border-inline-start-color: #fce7f3e6;
}

.border-s-pink-100\/95 {
  border-inline-start-color: #fce7f3f2;
}

.border-s-pink-200 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-s-pink-200\/0 {
  border-inline-start-color: #fbcfe800;
}

.border-s-pink-200\/10 {
  border-inline-start-color: #fbcfe81a;
}

.border-s-pink-200\/100 {
  border-inline-start-color: #fbcfe8;
}

.border-s-pink-200\/20 {
  border-inline-start-color: #fbcfe833;
}

.border-s-pink-200\/25 {
  border-inline-start-color: #fbcfe840;
}

.border-s-pink-200\/30 {
  border-inline-start-color: #fbcfe84d;
}

.border-s-pink-200\/40 {
  border-inline-start-color: #fbcfe866;
}

.border-s-pink-200\/5 {
  border-inline-start-color: #fbcfe80d;
}

.border-s-pink-200\/50 {
  border-inline-start-color: #fbcfe880;
}

.border-s-pink-200\/60 {
  border-inline-start-color: #fbcfe899;
}

.border-s-pink-200\/70 {
  border-inline-start-color: #fbcfe8b3;
}

.border-s-pink-200\/75 {
  border-inline-start-color: #fbcfe8bf;
}

.border-s-pink-200\/80 {
  border-inline-start-color: #fbcfe8cc;
}

.border-s-pink-200\/90 {
  border-inline-start-color: #fbcfe8e6;
}

.border-s-pink-200\/95 {
  border-inline-start-color: #fbcfe8f2;
}

.border-s-pink-300 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-s-pink-300\/0 {
  border-inline-start-color: #f9a8d400;
}

.border-s-pink-300\/10 {
  border-inline-start-color: #f9a8d41a;
}

.border-s-pink-300\/100 {
  border-inline-start-color: #f9a8d4;
}

.border-s-pink-300\/20 {
  border-inline-start-color: #f9a8d433;
}

.border-s-pink-300\/25 {
  border-inline-start-color: #f9a8d440;
}

.border-s-pink-300\/30 {
  border-inline-start-color: #f9a8d44d;
}

.border-s-pink-300\/40 {
  border-inline-start-color: #f9a8d466;
}

.border-s-pink-300\/5 {
  border-inline-start-color: #f9a8d40d;
}

.border-s-pink-300\/50 {
  border-inline-start-color: #f9a8d480;
}

.border-s-pink-300\/60 {
  border-inline-start-color: #f9a8d499;
}

.border-s-pink-300\/70 {
  border-inline-start-color: #f9a8d4b3;
}

.border-s-pink-300\/75 {
  border-inline-start-color: #f9a8d4bf;
}

.border-s-pink-300\/80 {
  border-inline-start-color: #f9a8d4cc;
}

.border-s-pink-300\/90 {
  border-inline-start-color: #f9a8d4e6;
}

.border-s-pink-300\/95 {
  border-inline-start-color: #f9a8d4f2;
}

.border-s-pink-400 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-s-pink-400\/0 {
  border-inline-start-color: #f472b600;
}

.border-s-pink-400\/10 {
  border-inline-start-color: #f472b61a;
}

.border-s-pink-400\/100 {
  border-inline-start-color: #f472b6;
}

.border-s-pink-400\/20 {
  border-inline-start-color: #f472b633;
}

.border-s-pink-400\/25 {
  border-inline-start-color: #f472b640;
}

.border-s-pink-400\/30 {
  border-inline-start-color: #f472b64d;
}

.border-s-pink-400\/40 {
  border-inline-start-color: #f472b666;
}

.border-s-pink-400\/5 {
  border-inline-start-color: #f472b60d;
}

.border-s-pink-400\/50 {
  border-inline-start-color: #f472b680;
}

.border-s-pink-400\/60 {
  border-inline-start-color: #f472b699;
}

.border-s-pink-400\/70 {
  border-inline-start-color: #f472b6b3;
}

.border-s-pink-400\/75 {
  border-inline-start-color: #f472b6bf;
}

.border-s-pink-400\/80 {
  border-inline-start-color: #f472b6cc;
}

.border-s-pink-400\/90 {
  border-inline-start-color: #f472b6e6;
}

.border-s-pink-400\/95 {
  border-inline-start-color: #f472b6f2;
}

.border-s-pink-50 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-s-pink-50\/0 {
  border-inline-start-color: #fdf2f800;
}

.border-s-pink-50\/10 {
  border-inline-start-color: #fdf2f81a;
}

.border-s-pink-50\/100 {
  border-inline-start-color: #fdf2f8;
}

.border-s-pink-50\/20 {
  border-inline-start-color: #fdf2f833;
}

.border-s-pink-50\/25 {
  border-inline-start-color: #fdf2f840;
}

.border-s-pink-50\/30 {
  border-inline-start-color: #fdf2f84d;
}

.border-s-pink-50\/40 {
  border-inline-start-color: #fdf2f866;
}

.border-s-pink-50\/5 {
  border-inline-start-color: #fdf2f80d;
}

.border-s-pink-50\/50 {
  border-inline-start-color: #fdf2f880;
}

.border-s-pink-50\/60 {
  border-inline-start-color: #fdf2f899;
}

.border-s-pink-50\/70 {
  border-inline-start-color: #fdf2f8b3;
}

.border-s-pink-50\/75 {
  border-inline-start-color: #fdf2f8bf;
}

.border-s-pink-50\/80 {
  border-inline-start-color: #fdf2f8cc;
}

.border-s-pink-50\/90 {
  border-inline-start-color: #fdf2f8e6;
}

.border-s-pink-50\/95 {
  border-inline-start-color: #fdf2f8f2;
}

.border-s-pink-500 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-s-pink-500\/0 {
  border-inline-start-color: #ec489900;
}

.border-s-pink-500\/10 {
  border-inline-start-color: #ec48991a;
}

.border-s-pink-500\/100 {
  border-inline-start-color: #ec4899;
}

.border-s-pink-500\/20 {
  border-inline-start-color: #ec489933;
}

.border-s-pink-500\/25 {
  border-inline-start-color: #ec489940;
}

.border-s-pink-500\/30 {
  border-inline-start-color: #ec48994d;
}

.border-s-pink-500\/40 {
  border-inline-start-color: #ec489966;
}

.border-s-pink-500\/5 {
  border-inline-start-color: #ec48990d;
}

.border-s-pink-500\/50 {
  border-inline-start-color: #ec489980;
}

.border-s-pink-500\/60 {
  border-inline-start-color: #ec489999;
}

.border-s-pink-500\/70 {
  border-inline-start-color: #ec4899b3;
}

.border-s-pink-500\/75 {
  border-inline-start-color: #ec4899bf;
}

.border-s-pink-500\/80 {
  border-inline-start-color: #ec4899cc;
}

.border-s-pink-500\/90 {
  border-inline-start-color: #ec4899e6;
}

.border-s-pink-500\/95 {
  border-inline-start-color: #ec4899f2;
}

.border-s-pink-600 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-s-pink-600\/0 {
  border-inline-start-color: #db277700;
}

.border-s-pink-600\/10 {
  border-inline-start-color: #db27771a;
}

.border-s-pink-600\/100 {
  border-inline-start-color: #db2777;
}

.border-s-pink-600\/20 {
  border-inline-start-color: #db277733;
}

.border-s-pink-600\/25 {
  border-inline-start-color: #db277740;
}

.border-s-pink-600\/30 {
  border-inline-start-color: #db27774d;
}

.border-s-pink-600\/40 {
  border-inline-start-color: #db277766;
}

.border-s-pink-600\/5 {
  border-inline-start-color: #db27770d;
}

.border-s-pink-600\/50 {
  border-inline-start-color: #db277780;
}

.border-s-pink-600\/60 {
  border-inline-start-color: #db277799;
}

.border-s-pink-600\/70 {
  border-inline-start-color: #db2777b3;
}

.border-s-pink-600\/75 {
  border-inline-start-color: #db2777bf;
}

.border-s-pink-600\/80 {
  border-inline-start-color: #db2777cc;
}

.border-s-pink-600\/90 {
  border-inline-start-color: #db2777e6;
}

.border-s-pink-600\/95 {
  border-inline-start-color: #db2777f2;
}

.border-s-pink-700 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-s-pink-700\/0 {
  border-inline-start-color: #be185d00;
}

.border-s-pink-700\/10 {
  border-inline-start-color: #be185d1a;
}

.border-s-pink-700\/100 {
  border-inline-start-color: #be185d;
}

.border-s-pink-700\/20 {
  border-inline-start-color: #be185d33;
}

.border-s-pink-700\/25 {
  border-inline-start-color: #be185d40;
}

.border-s-pink-700\/30 {
  border-inline-start-color: #be185d4d;
}

.border-s-pink-700\/40 {
  border-inline-start-color: #be185d66;
}

.border-s-pink-700\/5 {
  border-inline-start-color: #be185d0d;
}

.border-s-pink-700\/50 {
  border-inline-start-color: #be185d80;
}

.border-s-pink-700\/60 {
  border-inline-start-color: #be185d99;
}

.border-s-pink-700\/70 {
  border-inline-start-color: #be185db3;
}

.border-s-pink-700\/75 {
  border-inline-start-color: #be185dbf;
}

.border-s-pink-700\/80 {
  border-inline-start-color: #be185dcc;
}

.border-s-pink-700\/90 {
  border-inline-start-color: #be185de6;
}

.border-s-pink-700\/95 {
  border-inline-start-color: #be185df2;
}

.border-s-pink-800 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-s-pink-800\/0 {
  border-inline-start-color: #9d174d00;
}

.border-s-pink-800\/10 {
  border-inline-start-color: #9d174d1a;
}

.border-s-pink-800\/100 {
  border-inline-start-color: #9d174d;
}

.border-s-pink-800\/20 {
  border-inline-start-color: #9d174d33;
}

.border-s-pink-800\/25 {
  border-inline-start-color: #9d174d40;
}

.border-s-pink-800\/30 {
  border-inline-start-color: #9d174d4d;
}

.border-s-pink-800\/40 {
  border-inline-start-color: #9d174d66;
}

.border-s-pink-800\/5 {
  border-inline-start-color: #9d174d0d;
}

.border-s-pink-800\/50 {
  border-inline-start-color: #9d174d80;
}

.border-s-pink-800\/60 {
  border-inline-start-color: #9d174d99;
}

.border-s-pink-800\/70 {
  border-inline-start-color: #9d174db3;
}

.border-s-pink-800\/75 {
  border-inline-start-color: #9d174dbf;
}

.border-s-pink-800\/80 {
  border-inline-start-color: #9d174dcc;
}

.border-s-pink-800\/90 {
  border-inline-start-color: #9d174de6;
}

.border-s-pink-800\/95 {
  border-inline-start-color: #9d174df2;
}

.border-s-pink-900 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-s-pink-900\/0 {
  border-inline-start-color: #83184300;
}

.border-s-pink-900\/10 {
  border-inline-start-color: #8318431a;
}

.border-s-pink-900\/100 {
  border-inline-start-color: #831843;
}

.border-s-pink-900\/20 {
  border-inline-start-color: #83184333;
}

.border-s-pink-900\/25 {
  border-inline-start-color: #83184340;
}

.border-s-pink-900\/30 {
  border-inline-start-color: #8318434d;
}

.border-s-pink-900\/40 {
  border-inline-start-color: #83184366;
}

.border-s-pink-900\/5 {
  border-inline-start-color: #8318430d;
}

.border-s-pink-900\/50 {
  border-inline-start-color: #83184380;
}

.border-s-pink-900\/60 {
  border-inline-start-color: #83184399;
}

.border-s-pink-900\/70 {
  border-inline-start-color: #831843b3;
}

.border-s-pink-900\/75 {
  border-inline-start-color: #831843bf;
}

.border-s-pink-900\/80 {
  border-inline-start-color: #831843cc;
}

.border-s-pink-900\/90 {
  border-inline-start-color: #831843e6;
}

.border-s-pink-900\/95 {
  border-inline-start-color: #831843f2;
}

.border-s-pink-950 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-s-pink-950\/0 {
  border-inline-start-color: #50072400;
}

.border-s-pink-950\/10 {
  border-inline-start-color: #5007241a;
}

.border-s-pink-950\/100 {
  border-inline-start-color: #500724;
}

.border-s-pink-950\/20 {
  border-inline-start-color: #50072433;
}

.border-s-pink-950\/25 {
  border-inline-start-color: #50072440;
}

.border-s-pink-950\/30 {
  border-inline-start-color: #5007244d;
}

.border-s-pink-950\/40 {
  border-inline-start-color: #50072466;
}

.border-s-pink-950\/5 {
  border-inline-start-color: #5007240d;
}

.border-s-pink-950\/50 {
  border-inline-start-color: #50072480;
}

.border-s-pink-950\/60 {
  border-inline-start-color: #50072499;
}

.border-s-pink-950\/70 {
  border-inline-start-color: #500724b3;
}

.border-s-pink-950\/75 {
  border-inline-start-color: #500724bf;
}

.border-s-pink-950\/80 {
  border-inline-start-color: #500724cc;
}

.border-s-pink-950\/90 {
  border-inline-start-color: #500724e6;
}

.border-s-pink-950\/95 {
  border-inline-start-color: #500724f2;
}

.border-s-primary {
  --tw-border-opacity: 1;
  border-inline-start-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-s-primary\/0 {
  border-inline-start-color: hsl(var(--primary) / 0);
}

.border-s-primary\/10 {
  border-inline-start-color: hsl(var(--primary) / .1);
}

.border-s-primary\/100 {
  border-inline-start-color: hsl(var(--primary) / 1);
}

.border-s-primary\/20 {
  border-inline-start-color: hsl(var(--primary) / .2);
}

.border-s-primary\/25 {
  border-inline-start-color: hsl(var(--primary) / .25);
}

.border-s-primary\/30 {
  border-inline-start-color: hsl(var(--primary) / .3);
}

.border-s-primary\/40 {
  border-inline-start-color: hsl(var(--primary) / .4);
}

.border-s-primary\/5 {
  border-inline-start-color: hsl(var(--primary) / .05);
}

.border-s-primary\/50 {
  border-inline-start-color: hsl(var(--primary) / .5);
}

.border-s-primary\/60 {
  border-inline-start-color: hsl(var(--primary) / .6);
}

.border-s-primary\/70 {
  border-inline-start-color: hsl(var(--primary) / .7);
}

.border-s-primary\/75 {
  border-inline-start-color: hsl(var(--primary) / .75);
}

.border-s-primary\/80 {
  border-inline-start-color: hsl(var(--primary) / .8);
}

.border-s-primary\/90 {
  border-inline-start-color: hsl(var(--primary) / .9);
}

.border-s-primary\/95 {
  border-inline-start-color: hsl(var(--primary) / .95);
}

.border-s-purple-100 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-s-purple-100\/0 {
  border-inline-start-color: #f3e8ff00;
}

.border-s-purple-100\/10 {
  border-inline-start-color: #f3e8ff1a;
}

.border-s-purple-100\/100 {
  border-inline-start-color: #f3e8ff;
}

.border-s-purple-100\/20 {
  border-inline-start-color: #f3e8ff33;
}

.border-s-purple-100\/25 {
  border-inline-start-color: #f3e8ff40;
}

.border-s-purple-100\/30 {
  border-inline-start-color: #f3e8ff4d;
}

.border-s-purple-100\/40 {
  border-inline-start-color: #f3e8ff66;
}

.border-s-purple-100\/5 {
  border-inline-start-color: #f3e8ff0d;
}

.border-s-purple-100\/50 {
  border-inline-start-color: #f3e8ff80;
}

.border-s-purple-100\/60 {
  border-inline-start-color: #f3e8ff99;
}

.border-s-purple-100\/70 {
  border-inline-start-color: #f3e8ffb3;
}

.border-s-purple-100\/75 {
  border-inline-start-color: #f3e8ffbf;
}

.border-s-purple-100\/80 {
  border-inline-start-color: #f3e8ffcc;
}

.border-s-purple-100\/90 {
  border-inline-start-color: #f3e8ffe6;
}

.border-s-purple-100\/95 {
  border-inline-start-color: #f3e8fff2;
}

.border-s-purple-200 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-s-purple-200\/0 {
  border-inline-start-color: #e9d5ff00;
}

.border-s-purple-200\/10 {
  border-inline-start-color: #e9d5ff1a;
}

.border-s-purple-200\/100 {
  border-inline-start-color: #e9d5ff;
}

.border-s-purple-200\/20 {
  border-inline-start-color: #e9d5ff33;
}

.border-s-purple-200\/25 {
  border-inline-start-color: #e9d5ff40;
}

.border-s-purple-200\/30 {
  border-inline-start-color: #e9d5ff4d;
}

.border-s-purple-200\/40 {
  border-inline-start-color: #e9d5ff66;
}

.border-s-purple-200\/5 {
  border-inline-start-color: #e9d5ff0d;
}

.border-s-purple-200\/50 {
  border-inline-start-color: #e9d5ff80;
}

.border-s-purple-200\/60 {
  border-inline-start-color: #e9d5ff99;
}

.border-s-purple-200\/70 {
  border-inline-start-color: #e9d5ffb3;
}

.border-s-purple-200\/75 {
  border-inline-start-color: #e9d5ffbf;
}

.border-s-purple-200\/80 {
  border-inline-start-color: #e9d5ffcc;
}

.border-s-purple-200\/90 {
  border-inline-start-color: #e9d5ffe6;
}

.border-s-purple-200\/95 {
  border-inline-start-color: #e9d5fff2;
}

.border-s-purple-300 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-s-purple-300\/0 {
  border-inline-start-color: #d8b4fe00;
}

.border-s-purple-300\/10 {
  border-inline-start-color: #d8b4fe1a;
}

.border-s-purple-300\/100 {
  border-inline-start-color: #d8b4fe;
}

.border-s-purple-300\/20 {
  border-inline-start-color: #d8b4fe33;
}

.border-s-purple-300\/25 {
  border-inline-start-color: #d8b4fe40;
}

.border-s-purple-300\/30 {
  border-inline-start-color: #d8b4fe4d;
}

.border-s-purple-300\/40 {
  border-inline-start-color: #d8b4fe66;
}

.border-s-purple-300\/5 {
  border-inline-start-color: #d8b4fe0d;
}

.border-s-purple-300\/50 {
  border-inline-start-color: #d8b4fe80;
}

.border-s-purple-300\/60 {
  border-inline-start-color: #d8b4fe99;
}

.border-s-purple-300\/70 {
  border-inline-start-color: #d8b4feb3;
}

.border-s-purple-300\/75 {
  border-inline-start-color: #d8b4febf;
}

.border-s-purple-300\/80 {
  border-inline-start-color: #d8b4fecc;
}

.border-s-purple-300\/90 {
  border-inline-start-color: #d8b4fee6;
}

.border-s-purple-300\/95 {
  border-inline-start-color: #d8b4fef2;
}

.border-s-purple-400 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-s-purple-400\/0 {
  border-inline-start-color: #c084fc00;
}

.border-s-purple-400\/10 {
  border-inline-start-color: #c084fc1a;
}

.border-s-purple-400\/100 {
  border-inline-start-color: #c084fc;
}

.border-s-purple-400\/20 {
  border-inline-start-color: #c084fc33;
}

.border-s-purple-400\/25 {
  border-inline-start-color: #c084fc40;
}

.border-s-purple-400\/30 {
  border-inline-start-color: #c084fc4d;
}

.border-s-purple-400\/40 {
  border-inline-start-color: #c084fc66;
}

.border-s-purple-400\/5 {
  border-inline-start-color: #c084fc0d;
}

.border-s-purple-400\/50 {
  border-inline-start-color: #c084fc80;
}

.border-s-purple-400\/60 {
  border-inline-start-color: #c084fc99;
}

.border-s-purple-400\/70 {
  border-inline-start-color: #c084fcb3;
}

.border-s-purple-400\/75 {
  border-inline-start-color: #c084fcbf;
}

.border-s-purple-400\/80 {
  border-inline-start-color: #c084fccc;
}

.border-s-purple-400\/90 {
  border-inline-start-color: #c084fce6;
}

.border-s-purple-400\/95 {
  border-inline-start-color: #c084fcf2;
}

.border-s-purple-50 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-s-purple-50\/0 {
  border-inline-start-color: #faf5ff00;
}

.border-s-purple-50\/10 {
  border-inline-start-color: #faf5ff1a;
}

.border-s-purple-50\/100 {
  border-inline-start-color: #faf5ff;
}

.border-s-purple-50\/20 {
  border-inline-start-color: #faf5ff33;
}

.border-s-purple-50\/25 {
  border-inline-start-color: #faf5ff40;
}

.border-s-purple-50\/30 {
  border-inline-start-color: #faf5ff4d;
}

.border-s-purple-50\/40 {
  border-inline-start-color: #faf5ff66;
}

.border-s-purple-50\/5 {
  border-inline-start-color: #faf5ff0d;
}

.border-s-purple-50\/50 {
  border-inline-start-color: #faf5ff80;
}

.border-s-purple-50\/60 {
  border-inline-start-color: #faf5ff99;
}

.border-s-purple-50\/70 {
  border-inline-start-color: #faf5ffb3;
}

.border-s-purple-50\/75 {
  border-inline-start-color: #faf5ffbf;
}

.border-s-purple-50\/80 {
  border-inline-start-color: #faf5ffcc;
}

.border-s-purple-50\/90 {
  border-inline-start-color: #faf5ffe6;
}

.border-s-purple-50\/95 {
  border-inline-start-color: #faf5fff2;
}

.border-s-purple-500 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-s-purple-500\/0 {
  border-inline-start-color: #a855f700;
}

.border-s-purple-500\/10 {
  border-inline-start-color: #a855f71a;
}

.border-s-purple-500\/100 {
  border-inline-start-color: #a855f7;
}

.border-s-purple-500\/20 {
  border-inline-start-color: #a855f733;
}

.border-s-purple-500\/25 {
  border-inline-start-color: #a855f740;
}

.border-s-purple-500\/30 {
  border-inline-start-color: #a855f74d;
}

.border-s-purple-500\/40 {
  border-inline-start-color: #a855f766;
}

.border-s-purple-500\/5 {
  border-inline-start-color: #a855f70d;
}

.border-s-purple-500\/50 {
  border-inline-start-color: #a855f780;
}

.border-s-purple-500\/60 {
  border-inline-start-color: #a855f799;
}

.border-s-purple-500\/70 {
  border-inline-start-color: #a855f7b3;
}

.border-s-purple-500\/75 {
  border-inline-start-color: #a855f7bf;
}

.border-s-purple-500\/80 {
  border-inline-start-color: #a855f7cc;
}

.border-s-purple-500\/90 {
  border-inline-start-color: #a855f7e6;
}

.border-s-purple-500\/95 {
  border-inline-start-color: #a855f7f2;
}

.border-s-purple-600 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-s-purple-600\/0 {
  border-inline-start-color: #9333ea00;
}

.border-s-purple-600\/10 {
  border-inline-start-color: #9333ea1a;
}

.border-s-purple-600\/100 {
  border-inline-start-color: #9333ea;
}

.border-s-purple-600\/20 {
  border-inline-start-color: #9333ea33;
}

.border-s-purple-600\/25 {
  border-inline-start-color: #9333ea40;
}

.border-s-purple-600\/30 {
  border-inline-start-color: #9333ea4d;
}

.border-s-purple-600\/40 {
  border-inline-start-color: #9333ea66;
}

.border-s-purple-600\/5 {
  border-inline-start-color: #9333ea0d;
}

.border-s-purple-600\/50 {
  border-inline-start-color: #9333ea80;
}

.border-s-purple-600\/60 {
  border-inline-start-color: #9333ea99;
}

.border-s-purple-600\/70 {
  border-inline-start-color: #9333eab3;
}

.border-s-purple-600\/75 {
  border-inline-start-color: #9333eabf;
}

.border-s-purple-600\/80 {
  border-inline-start-color: #9333eacc;
}

.border-s-purple-600\/90 {
  border-inline-start-color: #9333eae6;
}

.border-s-purple-600\/95 {
  border-inline-start-color: #9333eaf2;
}

.border-s-purple-700 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-s-purple-700\/0 {
  border-inline-start-color: #7e22ce00;
}

.border-s-purple-700\/10 {
  border-inline-start-color: #7e22ce1a;
}

.border-s-purple-700\/100 {
  border-inline-start-color: #7e22ce;
}

.border-s-purple-700\/20 {
  border-inline-start-color: #7e22ce33;
}

.border-s-purple-700\/25 {
  border-inline-start-color: #7e22ce40;
}

.border-s-purple-700\/30 {
  border-inline-start-color: #7e22ce4d;
}

.border-s-purple-700\/40 {
  border-inline-start-color: #7e22ce66;
}

.border-s-purple-700\/5 {
  border-inline-start-color: #7e22ce0d;
}

.border-s-purple-700\/50 {
  border-inline-start-color: #7e22ce80;
}

.border-s-purple-700\/60 {
  border-inline-start-color: #7e22ce99;
}

.border-s-purple-700\/70 {
  border-inline-start-color: #7e22ceb3;
}

.border-s-purple-700\/75 {
  border-inline-start-color: #7e22cebf;
}

.border-s-purple-700\/80 {
  border-inline-start-color: #7e22cecc;
}

.border-s-purple-700\/90 {
  border-inline-start-color: #7e22cee6;
}

.border-s-purple-700\/95 {
  border-inline-start-color: #7e22cef2;
}

.border-s-purple-800 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-s-purple-800\/0 {
  border-inline-start-color: #6b21a800;
}

.border-s-purple-800\/10 {
  border-inline-start-color: #6b21a81a;
}

.border-s-purple-800\/100 {
  border-inline-start-color: #6b21a8;
}

.border-s-purple-800\/20 {
  border-inline-start-color: #6b21a833;
}

.border-s-purple-800\/25 {
  border-inline-start-color: #6b21a840;
}

.border-s-purple-800\/30 {
  border-inline-start-color: #6b21a84d;
}

.border-s-purple-800\/40 {
  border-inline-start-color: #6b21a866;
}

.border-s-purple-800\/5 {
  border-inline-start-color: #6b21a80d;
}

.border-s-purple-800\/50 {
  border-inline-start-color: #6b21a880;
}

.border-s-purple-800\/60 {
  border-inline-start-color: #6b21a899;
}

.border-s-purple-800\/70 {
  border-inline-start-color: #6b21a8b3;
}

.border-s-purple-800\/75 {
  border-inline-start-color: #6b21a8bf;
}

.border-s-purple-800\/80 {
  border-inline-start-color: #6b21a8cc;
}

.border-s-purple-800\/90 {
  border-inline-start-color: #6b21a8e6;
}

.border-s-purple-800\/95 {
  border-inline-start-color: #6b21a8f2;
}

.border-s-purple-900 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-s-purple-900\/0 {
  border-inline-start-color: #581c8700;
}

.border-s-purple-900\/10 {
  border-inline-start-color: #581c871a;
}

.border-s-purple-900\/100 {
  border-inline-start-color: #581c87;
}

.border-s-purple-900\/20 {
  border-inline-start-color: #581c8733;
}

.border-s-purple-900\/25 {
  border-inline-start-color: #581c8740;
}

.border-s-purple-900\/30 {
  border-inline-start-color: #581c874d;
}

.border-s-purple-900\/40 {
  border-inline-start-color: #581c8766;
}

.border-s-purple-900\/5 {
  border-inline-start-color: #581c870d;
}

.border-s-purple-900\/50 {
  border-inline-start-color: #581c8780;
}

.border-s-purple-900\/60 {
  border-inline-start-color: #581c8799;
}

.border-s-purple-900\/70 {
  border-inline-start-color: #581c87b3;
}

.border-s-purple-900\/75 {
  border-inline-start-color: #581c87bf;
}

.border-s-purple-900\/80 {
  border-inline-start-color: #581c87cc;
}

.border-s-purple-900\/90 {
  border-inline-start-color: #581c87e6;
}

.border-s-purple-900\/95 {
  border-inline-start-color: #581c87f2;
}

.border-s-purple-950 {
  --tw-border-opacity: 1;
  border-inline-start-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-s-purple-950\/0 {
  border-inline-start-color: #3b076400;
}

.border-s-purple-950\/10 {
  border-inline-start-color: #3b07641a;
}

.border-s-purple-950\/100 {
  border-inline-start-color: #3b0764;
}

.border-s-purple-950\/20 {
  border-inline-start-color: #3b076433;
}

.border-s-purple-950\/25 {
  border-inline-start-color: #3b076440;
}

.border-s-purple-950\/30 {
  border-inline-start-color: #3b07644d;
}

.border-s-purple-950\/40 {
  border-inline-start-color: #3b076466;
}

.border-s-purple-950\/5 {
  border-inline-start-color: #3b07640d;
}

.border-s-purple-950\/50 {
  border-inline-start-color: #3b076480;
}

.border-s-purple-950\/60 {
  border-inline-start-color: #3b076499;
}

.border-s-purple-950\/70 {
  border-inline-start-color: #3b0764b3;
}

.border-s-purple-950\/75 {
  border-inline-start-color: #3b0764bf;
}

.border-s-purple-950\/80 {
  border-inline-start-color: #3b0764cc;
}

.border-s-purple-950\/90 {
  border-inline-start-color: #3b0764e6;
}

.border-s-purple-950\/95 {
  border-inline-start-color: #3b0764f2;
}

.border-s-transparent, .border-s-transparent\/0 {
  border-inline-start-color: #0000;
}

.border-s-transparent\/10 {
  border-inline-start-color: #0000001a;
}

.border-s-transparent\/100 {
  border-inline-start-color: #000;
}

.border-s-transparent\/20 {
  border-inline-start-color: #0003;
}

.border-s-transparent\/25 {
  border-inline-start-color: #00000040;
}

.border-s-transparent\/30 {
  border-inline-start-color: #0000004d;
}

.border-s-transparent\/40 {
  border-inline-start-color: #0006;
}

.border-s-transparent\/5 {
  border-inline-start-color: #0000000d;
}

.border-s-transparent\/50 {
  border-inline-start-color: #00000080;
}

.border-s-transparent\/60 {
  border-inline-start-color: #0009;
}

.border-s-transparent\/70 {
  border-inline-start-color: #000000b3;
}

.border-s-transparent\/75 {
  border-inline-start-color: #000000bf;
}

.border-s-transparent\/80 {
  border-inline-start-color: #000c;
}

.border-s-transparent\/90 {
  border-inline-start-color: #000000e6;
}

.border-s-transparent\/95 {
  border-inline-start-color: #000000f2;
}

.border-t-amber-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-t-amber-100\/0 {
  border-top-color: #fef3c700;
}

.border-t-amber-100\/10 {
  border-top-color: #fef3c71a;
}

.border-t-amber-100\/100 {
  border-top-color: #fef3c7;
}

.border-t-amber-100\/20 {
  border-top-color: #fef3c733;
}

.border-t-amber-100\/25 {
  border-top-color: #fef3c740;
}

.border-t-amber-100\/30 {
  border-top-color: #fef3c74d;
}

.border-t-amber-100\/40 {
  border-top-color: #fef3c766;
}

.border-t-amber-100\/5 {
  border-top-color: #fef3c70d;
}

.border-t-amber-100\/50 {
  border-top-color: #fef3c780;
}

.border-t-amber-100\/60 {
  border-top-color: #fef3c799;
}

.border-t-amber-100\/70 {
  border-top-color: #fef3c7b3;
}

.border-t-amber-100\/75 {
  border-top-color: #fef3c7bf;
}

.border-t-amber-100\/80 {
  border-top-color: #fef3c7cc;
}

.border-t-amber-100\/90 {
  border-top-color: #fef3c7e6;
}

.border-t-amber-100\/95 {
  border-top-color: #fef3c7f2;
}

.border-t-amber-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-t-amber-200\/0 {
  border-top-color: #fde68a00;
}

.border-t-amber-200\/10 {
  border-top-color: #fde68a1a;
}

.border-t-amber-200\/100 {
  border-top-color: #fde68a;
}

.border-t-amber-200\/20 {
  border-top-color: #fde68a33;
}

.border-t-amber-200\/25 {
  border-top-color: #fde68a40;
}

.border-t-amber-200\/30 {
  border-top-color: #fde68a4d;
}

.border-t-amber-200\/40 {
  border-top-color: #fde68a66;
}

.border-t-amber-200\/5 {
  border-top-color: #fde68a0d;
}

.border-t-amber-200\/50 {
  border-top-color: #fde68a80;
}

.border-t-amber-200\/60 {
  border-top-color: #fde68a99;
}

.border-t-amber-200\/70 {
  border-top-color: #fde68ab3;
}

.border-t-amber-200\/75 {
  border-top-color: #fde68abf;
}

.border-t-amber-200\/80 {
  border-top-color: #fde68acc;
}

.border-t-amber-200\/90 {
  border-top-color: #fde68ae6;
}

.border-t-amber-200\/95 {
  border-top-color: #fde68af2;
}

.border-t-amber-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-t-amber-300\/0 {
  border-top-color: #fcd34d00;
}

.border-t-amber-300\/10 {
  border-top-color: #fcd34d1a;
}

.border-t-amber-300\/100 {
  border-top-color: #fcd34d;
}

.border-t-amber-300\/20 {
  border-top-color: #fcd34d33;
}

.border-t-amber-300\/25 {
  border-top-color: #fcd34d40;
}

.border-t-amber-300\/30 {
  border-top-color: #fcd34d4d;
}

.border-t-amber-300\/40 {
  border-top-color: #fcd34d66;
}

.border-t-amber-300\/5 {
  border-top-color: #fcd34d0d;
}

.border-t-amber-300\/50 {
  border-top-color: #fcd34d80;
}

.border-t-amber-300\/60 {
  border-top-color: #fcd34d99;
}

.border-t-amber-300\/70 {
  border-top-color: #fcd34db3;
}

.border-t-amber-300\/75 {
  border-top-color: #fcd34dbf;
}

.border-t-amber-300\/80 {
  border-top-color: #fcd34dcc;
}

.border-t-amber-300\/90 {
  border-top-color: #fcd34de6;
}

.border-t-amber-300\/95 {
  border-top-color: #fcd34df2;
}

.border-t-amber-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-t-amber-400\/0 {
  border-top-color: #fbbf2400;
}

.border-t-amber-400\/10 {
  border-top-color: #fbbf241a;
}

.border-t-amber-400\/100 {
  border-top-color: #fbbf24;
}

.border-t-amber-400\/20 {
  border-top-color: #fbbf2433;
}

.border-t-amber-400\/25 {
  border-top-color: #fbbf2440;
}

.border-t-amber-400\/30 {
  border-top-color: #fbbf244d;
}

.border-t-amber-400\/40 {
  border-top-color: #fbbf2466;
}

.border-t-amber-400\/5 {
  border-top-color: #fbbf240d;
}

.border-t-amber-400\/50 {
  border-top-color: #fbbf2480;
}

.border-t-amber-400\/60 {
  border-top-color: #fbbf2499;
}

.border-t-amber-400\/70 {
  border-top-color: #fbbf24b3;
}

.border-t-amber-400\/75 {
  border-top-color: #fbbf24bf;
}

.border-t-amber-400\/80 {
  border-top-color: #fbbf24cc;
}

.border-t-amber-400\/90 {
  border-top-color: #fbbf24e6;
}

.border-t-amber-400\/95 {
  border-top-color: #fbbf24f2;
}

.border-t-amber-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-t-amber-50\/0 {
  border-top-color: #fffbeb00;
}

.border-t-amber-50\/10 {
  border-top-color: #fffbeb1a;
}

.border-t-amber-50\/100 {
  border-top-color: #fffbeb;
}

.border-t-amber-50\/20 {
  border-top-color: #fffbeb33;
}

.border-t-amber-50\/25 {
  border-top-color: #fffbeb40;
}

.border-t-amber-50\/30 {
  border-top-color: #fffbeb4d;
}

.border-t-amber-50\/40 {
  border-top-color: #fffbeb66;
}

.border-t-amber-50\/5 {
  border-top-color: #fffbeb0d;
}

.border-t-amber-50\/50 {
  border-top-color: #fffbeb80;
}

.border-t-amber-50\/60 {
  border-top-color: #fffbeb99;
}

.border-t-amber-50\/70 {
  border-top-color: #fffbebb3;
}

.border-t-amber-50\/75 {
  border-top-color: #fffbebbf;
}

.border-t-amber-50\/80 {
  border-top-color: #fffbebcc;
}

.border-t-amber-50\/90 {
  border-top-color: #fffbebe6;
}

.border-t-amber-50\/95 {
  border-top-color: #fffbebf2;
}

.border-t-amber-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-t-amber-500\/0 {
  border-top-color: #f59e0b00;
}

.border-t-amber-500\/10 {
  border-top-color: #f59e0b1a;
}

.border-t-amber-500\/100 {
  border-top-color: #f59e0b;
}

.border-t-amber-500\/20 {
  border-top-color: #f59e0b33;
}

.border-t-amber-500\/25 {
  border-top-color: #f59e0b40;
}

.border-t-amber-500\/30 {
  border-top-color: #f59e0b4d;
}

.border-t-amber-500\/40 {
  border-top-color: #f59e0b66;
}

.border-t-amber-500\/5 {
  border-top-color: #f59e0b0d;
}

.border-t-amber-500\/50 {
  border-top-color: #f59e0b80;
}

.border-t-amber-500\/60 {
  border-top-color: #f59e0b99;
}

.border-t-amber-500\/70 {
  border-top-color: #f59e0bb3;
}

.border-t-amber-500\/75 {
  border-top-color: #f59e0bbf;
}

.border-t-amber-500\/80 {
  border-top-color: #f59e0bcc;
}

.border-t-amber-500\/90 {
  border-top-color: #f59e0be6;
}

.border-t-amber-500\/95 {
  border-top-color: #f59e0bf2;
}

.border-t-amber-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-t-amber-600\/0 {
  border-top-color: #d9770600;
}

.border-t-amber-600\/10 {
  border-top-color: #d977061a;
}

.border-t-amber-600\/100 {
  border-top-color: #d97706;
}

.border-t-amber-600\/20 {
  border-top-color: #d9770633;
}

.border-t-amber-600\/25 {
  border-top-color: #d9770640;
}

.border-t-amber-600\/30 {
  border-top-color: #d977064d;
}

.border-t-amber-600\/40 {
  border-top-color: #d9770666;
}

.border-t-amber-600\/5 {
  border-top-color: #d977060d;
}

.border-t-amber-600\/50 {
  border-top-color: #d9770680;
}

.border-t-amber-600\/60 {
  border-top-color: #d9770699;
}

.border-t-amber-600\/70 {
  border-top-color: #d97706b3;
}

.border-t-amber-600\/75 {
  border-top-color: #d97706bf;
}

.border-t-amber-600\/80 {
  border-top-color: #d97706cc;
}

.border-t-amber-600\/90 {
  border-top-color: #d97706e6;
}

.border-t-amber-600\/95 {
  border-top-color: #d97706f2;
}

.border-t-amber-700 {
  --tw-border-opacity: 1;
  border-top-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-t-amber-700\/0 {
  border-top-color: #b4530900;
}

.border-t-amber-700\/10 {
  border-top-color: #b453091a;
}

.border-t-amber-700\/100 {
  border-top-color: #b45309;
}

.border-t-amber-700\/20 {
  border-top-color: #b4530933;
}

.border-t-amber-700\/25 {
  border-top-color: #b4530940;
}

.border-t-amber-700\/30 {
  border-top-color: #b453094d;
}

.border-t-amber-700\/40 {
  border-top-color: #b4530966;
}

.border-t-amber-700\/5 {
  border-top-color: #b453090d;
}

.border-t-amber-700\/50 {
  border-top-color: #b4530980;
}

.border-t-amber-700\/60 {
  border-top-color: #b4530999;
}

.border-t-amber-700\/70 {
  border-top-color: #b45309b3;
}

.border-t-amber-700\/75 {
  border-top-color: #b45309bf;
}

.border-t-amber-700\/80 {
  border-top-color: #b45309cc;
}

.border-t-amber-700\/90 {
  border-top-color: #b45309e6;
}

.border-t-amber-700\/95 {
  border-top-color: #b45309f2;
}

.border-t-amber-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-t-amber-800\/0 {
  border-top-color: #92400e00;
}

.border-t-amber-800\/10 {
  border-top-color: #92400e1a;
}

.border-t-amber-800\/100 {
  border-top-color: #92400e;
}

.border-t-amber-800\/20 {
  border-top-color: #92400e33;
}

.border-t-amber-800\/25 {
  border-top-color: #92400e40;
}

.border-t-amber-800\/30 {
  border-top-color: #92400e4d;
}

.border-t-amber-800\/40 {
  border-top-color: #92400e66;
}

.border-t-amber-800\/5 {
  border-top-color: #92400e0d;
}

.border-t-amber-800\/50 {
  border-top-color: #92400e80;
}

.border-t-amber-800\/60 {
  border-top-color: #92400e99;
}

.border-t-amber-800\/70 {
  border-top-color: #92400eb3;
}

.border-t-amber-800\/75 {
  border-top-color: #92400ebf;
}

.border-t-amber-800\/80 {
  border-top-color: #92400ecc;
}

.border-t-amber-800\/90 {
  border-top-color: #92400ee6;
}

.border-t-amber-800\/95 {
  border-top-color: #92400ef2;
}

.border-t-amber-900 {
  --tw-border-opacity: 1;
  border-top-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-t-amber-900\/0 {
  border-top-color: #78350f00;
}

.border-t-amber-900\/10 {
  border-top-color: #78350f1a;
}

.border-t-amber-900\/100 {
  border-top-color: #78350f;
}

.border-t-amber-900\/20 {
  border-top-color: #78350f33;
}

.border-t-amber-900\/25 {
  border-top-color: #78350f40;
}

.border-t-amber-900\/30 {
  border-top-color: #78350f4d;
}

.border-t-amber-900\/40 {
  border-top-color: #78350f66;
}

.border-t-amber-900\/5 {
  border-top-color: #78350f0d;
}

.border-t-amber-900\/50 {
  border-top-color: #78350f80;
}

.border-t-amber-900\/60 {
  border-top-color: #78350f99;
}

.border-t-amber-900\/70 {
  border-top-color: #78350fb3;
}

.border-t-amber-900\/75 {
  border-top-color: #78350fbf;
}

.border-t-amber-900\/80 {
  border-top-color: #78350fcc;
}

.border-t-amber-900\/90 {
  border-top-color: #78350fe6;
}

.border-t-amber-900\/95 {
  border-top-color: #78350ff2;
}

.border-t-amber-950 {
  --tw-border-opacity: 1;
  border-top-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-t-amber-950\/0 {
  border-top-color: #451a0300;
}

.border-t-amber-950\/10 {
  border-top-color: #451a031a;
}

.border-t-amber-950\/100 {
  border-top-color: #451a03;
}

.border-t-amber-950\/20 {
  border-top-color: #451a0333;
}

.border-t-amber-950\/25 {
  border-top-color: #451a0340;
}

.border-t-amber-950\/30 {
  border-top-color: #451a034d;
}

.border-t-amber-950\/40 {
  border-top-color: #451a0366;
}

.border-t-amber-950\/5 {
  border-top-color: #451a030d;
}

.border-t-amber-950\/50 {
  border-top-color: #451a0380;
}

.border-t-amber-950\/60 {
  border-top-color: #451a0399;
}

.border-t-amber-950\/70 {
  border-top-color: #451a03b3;
}

.border-t-amber-950\/75 {
  border-top-color: #451a03bf;
}

.border-t-amber-950\/80 {
  border-top-color: #451a03cc;
}

.border-t-amber-950\/90 {
  border-top-color: #451a03e6;
}

.border-t-amber-950\/95 {
  border-top-color: #451a03f2;
}

.border-t-emerald-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-t-emerald-100\/0 {
  border-top-color: #d1fae500;
}

.border-t-emerald-100\/10 {
  border-top-color: #d1fae51a;
}

.border-t-emerald-100\/100 {
  border-top-color: #d1fae5;
}

.border-t-emerald-100\/20 {
  border-top-color: #d1fae533;
}

.border-t-emerald-100\/25 {
  border-top-color: #d1fae540;
}

.border-t-emerald-100\/30 {
  border-top-color: #d1fae54d;
}

.border-t-emerald-100\/40 {
  border-top-color: #d1fae566;
}

.border-t-emerald-100\/5 {
  border-top-color: #d1fae50d;
}

.border-t-emerald-100\/50 {
  border-top-color: #d1fae580;
}

.border-t-emerald-100\/60 {
  border-top-color: #d1fae599;
}

.border-t-emerald-100\/70 {
  border-top-color: #d1fae5b3;
}

.border-t-emerald-100\/75 {
  border-top-color: #d1fae5bf;
}

.border-t-emerald-100\/80 {
  border-top-color: #d1fae5cc;
}

.border-t-emerald-100\/90 {
  border-top-color: #d1fae5e6;
}

.border-t-emerald-100\/95 {
  border-top-color: #d1fae5f2;
}

.border-t-emerald-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-t-emerald-200\/0 {
  border-top-color: #a7f3d000;
}

.border-t-emerald-200\/10 {
  border-top-color: #a7f3d01a;
}

.border-t-emerald-200\/100 {
  border-top-color: #a7f3d0;
}

.border-t-emerald-200\/20 {
  border-top-color: #a7f3d033;
}

.border-t-emerald-200\/25 {
  border-top-color: #a7f3d040;
}

.border-t-emerald-200\/30 {
  border-top-color: #a7f3d04d;
}

.border-t-emerald-200\/40 {
  border-top-color: #a7f3d066;
}

.border-t-emerald-200\/5 {
  border-top-color: #a7f3d00d;
}

.border-t-emerald-200\/50 {
  border-top-color: #a7f3d080;
}

.border-t-emerald-200\/60 {
  border-top-color: #a7f3d099;
}

.border-t-emerald-200\/70 {
  border-top-color: #a7f3d0b3;
}

.border-t-emerald-200\/75 {
  border-top-color: #a7f3d0bf;
}

.border-t-emerald-200\/80 {
  border-top-color: #a7f3d0cc;
}

.border-t-emerald-200\/90 {
  border-top-color: #a7f3d0e6;
}

.border-t-emerald-200\/95 {
  border-top-color: #a7f3d0f2;
}

.border-t-emerald-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-t-emerald-300\/0 {
  border-top-color: #6ee7b700;
}

.border-t-emerald-300\/10 {
  border-top-color: #6ee7b71a;
}

.border-t-emerald-300\/100 {
  border-top-color: #6ee7b7;
}

.border-t-emerald-300\/20 {
  border-top-color: #6ee7b733;
}

.border-t-emerald-300\/25 {
  border-top-color: #6ee7b740;
}

.border-t-emerald-300\/30 {
  border-top-color: #6ee7b74d;
}

.border-t-emerald-300\/40 {
  border-top-color: #6ee7b766;
}

.border-t-emerald-300\/5 {
  border-top-color: #6ee7b70d;
}

.border-t-emerald-300\/50 {
  border-top-color: #6ee7b780;
}

.border-t-emerald-300\/60 {
  border-top-color: #6ee7b799;
}

.border-t-emerald-300\/70 {
  border-top-color: #6ee7b7b3;
}

.border-t-emerald-300\/75 {
  border-top-color: #6ee7b7bf;
}

.border-t-emerald-300\/80 {
  border-top-color: #6ee7b7cc;
}

.border-t-emerald-300\/90 {
  border-top-color: #6ee7b7e6;
}

.border-t-emerald-300\/95 {
  border-top-color: #6ee7b7f2;
}

.border-t-emerald-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-t-emerald-400\/0 {
  border-top-color: #34d39900;
}

.border-t-emerald-400\/10 {
  border-top-color: #34d3991a;
}

.border-t-emerald-400\/100 {
  border-top-color: #34d399;
}

.border-t-emerald-400\/20 {
  border-top-color: #34d39933;
}

.border-t-emerald-400\/25 {
  border-top-color: #34d39940;
}

.border-t-emerald-400\/30 {
  border-top-color: #34d3994d;
}

.border-t-emerald-400\/40 {
  border-top-color: #34d39966;
}

.border-t-emerald-400\/5 {
  border-top-color: #34d3990d;
}

.border-t-emerald-400\/50 {
  border-top-color: #34d39980;
}

.border-t-emerald-400\/60 {
  border-top-color: #34d39999;
}

.border-t-emerald-400\/70 {
  border-top-color: #34d399b3;
}

.border-t-emerald-400\/75 {
  border-top-color: #34d399bf;
}

.border-t-emerald-400\/80 {
  border-top-color: #34d399cc;
}

.border-t-emerald-400\/90 {
  border-top-color: #34d399e6;
}

.border-t-emerald-400\/95 {
  border-top-color: #34d399f2;
}

.border-t-emerald-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-t-emerald-50\/0 {
  border-top-color: #ecfdf500;
}

.border-t-emerald-50\/10 {
  border-top-color: #ecfdf51a;
}

.border-t-emerald-50\/100 {
  border-top-color: #ecfdf5;
}

.border-t-emerald-50\/20 {
  border-top-color: #ecfdf533;
}

.border-t-emerald-50\/25 {
  border-top-color: #ecfdf540;
}

.border-t-emerald-50\/30 {
  border-top-color: #ecfdf54d;
}

.border-t-emerald-50\/40 {
  border-top-color: #ecfdf566;
}

.border-t-emerald-50\/5 {
  border-top-color: #ecfdf50d;
}

.border-t-emerald-50\/50 {
  border-top-color: #ecfdf580;
}

.border-t-emerald-50\/60 {
  border-top-color: #ecfdf599;
}

.border-t-emerald-50\/70 {
  border-top-color: #ecfdf5b3;
}

.border-t-emerald-50\/75 {
  border-top-color: #ecfdf5bf;
}

.border-t-emerald-50\/80 {
  border-top-color: #ecfdf5cc;
}

.border-t-emerald-50\/90 {
  border-top-color: #ecfdf5e6;
}

.border-t-emerald-50\/95 {
  border-top-color: #ecfdf5f2;
}

.border-t-emerald-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-t-emerald-500\/0 {
  border-top-color: #10b98100;
}

.border-t-emerald-500\/10 {
  border-top-color: #10b9811a;
}

.border-t-emerald-500\/100 {
  border-top-color: #10b981;
}

.border-t-emerald-500\/20 {
  border-top-color: #10b98133;
}

.border-t-emerald-500\/25 {
  border-top-color: #10b98140;
}

.border-t-emerald-500\/30 {
  border-top-color: #10b9814d;
}

.border-t-emerald-500\/40 {
  border-top-color: #10b98166;
}

.border-t-emerald-500\/5 {
  border-top-color: #10b9810d;
}

.border-t-emerald-500\/50 {
  border-top-color: #10b98180;
}

.border-t-emerald-500\/60 {
  border-top-color: #10b98199;
}

.border-t-emerald-500\/70 {
  border-top-color: #10b981b3;
}

.border-t-emerald-500\/75 {
  border-top-color: #10b981bf;
}

.border-t-emerald-500\/80 {
  border-top-color: #10b981cc;
}

.border-t-emerald-500\/90 {
  border-top-color: #10b981e6;
}

.border-t-emerald-500\/95 {
  border-top-color: #10b981f2;
}

.border-t-emerald-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-t-emerald-600\/0 {
  border-top-color: #05966900;
}

.border-t-emerald-600\/10 {
  border-top-color: #0596691a;
}

.border-t-emerald-600\/100 {
  border-top-color: #059669;
}

.border-t-emerald-600\/20 {
  border-top-color: #05966933;
}

.border-t-emerald-600\/25 {
  border-top-color: #05966940;
}

.border-t-emerald-600\/30 {
  border-top-color: #0596694d;
}

.border-t-emerald-600\/40 {
  border-top-color: #05966966;
}

.border-t-emerald-600\/5 {
  border-top-color: #0596690d;
}

.border-t-emerald-600\/50 {
  border-top-color: #05966980;
}

.border-t-emerald-600\/60 {
  border-top-color: #05966999;
}

.border-t-emerald-600\/70 {
  border-top-color: #059669b3;
}

.border-t-emerald-600\/75 {
  border-top-color: #059669bf;
}

.border-t-emerald-600\/80 {
  border-top-color: #059669cc;
}

.border-t-emerald-600\/90 {
  border-top-color: #059669e6;
}

.border-t-emerald-600\/95 {
  border-top-color: #059669f2;
}

.border-t-emerald-700 {
  --tw-border-opacity: 1;
  border-top-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-t-emerald-700\/0 {
  border-top-color: #04785700;
}

.border-t-emerald-700\/10 {
  border-top-color: #0478571a;
}

.border-t-emerald-700\/100 {
  border-top-color: #047857;
}

.border-t-emerald-700\/20 {
  border-top-color: #04785733;
}

.border-t-emerald-700\/25 {
  border-top-color: #04785740;
}

.border-t-emerald-700\/30 {
  border-top-color: #0478574d;
}

.border-t-emerald-700\/40 {
  border-top-color: #04785766;
}

.border-t-emerald-700\/5 {
  border-top-color: #0478570d;
}

.border-t-emerald-700\/50 {
  border-top-color: #04785780;
}

.border-t-emerald-700\/60 {
  border-top-color: #04785799;
}

.border-t-emerald-700\/70 {
  border-top-color: #047857b3;
}

.border-t-emerald-700\/75 {
  border-top-color: #047857bf;
}

.border-t-emerald-700\/80 {
  border-top-color: #047857cc;
}

.border-t-emerald-700\/90 {
  border-top-color: #047857e6;
}

.border-t-emerald-700\/95 {
  border-top-color: #047857f2;
}

.border-t-emerald-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-t-emerald-800\/0 {
  border-top-color: #065f4600;
}

.border-t-emerald-800\/10 {
  border-top-color: #065f461a;
}

.border-t-emerald-800\/100 {
  border-top-color: #065f46;
}

.border-t-emerald-800\/20 {
  border-top-color: #065f4633;
}

.border-t-emerald-800\/25 {
  border-top-color: #065f4640;
}

.border-t-emerald-800\/30 {
  border-top-color: #065f464d;
}

.border-t-emerald-800\/40 {
  border-top-color: #065f4666;
}

.border-t-emerald-800\/5 {
  border-top-color: #065f460d;
}

.border-t-emerald-800\/50 {
  border-top-color: #065f4680;
}

.border-t-emerald-800\/60 {
  border-top-color: #065f4699;
}

.border-t-emerald-800\/70 {
  border-top-color: #065f46b3;
}

.border-t-emerald-800\/75 {
  border-top-color: #065f46bf;
}

.border-t-emerald-800\/80 {
  border-top-color: #065f46cc;
}

.border-t-emerald-800\/90 {
  border-top-color: #065f46e6;
}

.border-t-emerald-800\/95 {
  border-top-color: #065f46f2;
}

.border-t-emerald-900 {
  --tw-border-opacity: 1;
  border-top-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-t-emerald-900\/0 {
  border-top-color: #064e3b00;
}

.border-t-emerald-900\/10 {
  border-top-color: #064e3b1a;
}

.border-t-emerald-900\/100 {
  border-top-color: #064e3b;
}

.border-t-emerald-900\/20 {
  border-top-color: #064e3b33;
}

.border-t-emerald-900\/25 {
  border-top-color: #064e3b40;
}

.border-t-emerald-900\/30 {
  border-top-color: #064e3b4d;
}

.border-t-emerald-900\/40 {
  border-top-color: #064e3b66;
}

.border-t-emerald-900\/5 {
  border-top-color: #064e3b0d;
}

.border-t-emerald-900\/50 {
  border-top-color: #064e3b80;
}

.border-t-emerald-900\/60 {
  border-top-color: #064e3b99;
}

.border-t-emerald-900\/70 {
  border-top-color: #064e3bb3;
}

.border-t-emerald-900\/75 {
  border-top-color: #064e3bbf;
}

.border-t-emerald-900\/80 {
  border-top-color: #064e3bcc;
}

.border-t-emerald-900\/90 {
  border-top-color: #064e3be6;
}

.border-t-emerald-900\/95 {
  border-top-color: #064e3bf2;
}

.border-t-emerald-950 {
  --tw-border-opacity: 1;
  border-top-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-t-emerald-950\/0 {
  border-top-color: #022c2200;
}

.border-t-emerald-950\/10 {
  border-top-color: #022c221a;
}

.border-t-emerald-950\/100 {
  border-top-color: #022c22;
}

.border-t-emerald-950\/20 {
  border-top-color: #022c2233;
}

.border-t-emerald-950\/25 {
  border-top-color: #022c2240;
}

.border-t-emerald-950\/30 {
  border-top-color: #022c224d;
}

.border-t-emerald-950\/40 {
  border-top-color: #022c2266;
}

.border-t-emerald-950\/5 {
  border-top-color: #022c220d;
}

.border-t-emerald-950\/50 {
  border-top-color: #022c2280;
}

.border-t-emerald-950\/60 {
  border-top-color: #022c2299;
}

.border-t-emerald-950\/70 {
  border-top-color: #022c22b3;
}

.border-t-emerald-950\/75 {
  border-top-color: #022c22bf;
}

.border-t-emerald-950\/80 {
  border-top-color: #022c22cc;
}

.border-t-emerald-950\/90 {
  border-top-color: #022c22e6;
}

.border-t-emerald-950\/95 {
  border-top-color: #022c22f2;
}

.border-t-lime-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-t-lime-100\/0 {
  border-top-color: #ecfccb00;
}

.border-t-lime-100\/10 {
  border-top-color: #ecfccb1a;
}

.border-t-lime-100\/100 {
  border-top-color: #ecfccb;
}

.border-t-lime-100\/20 {
  border-top-color: #ecfccb33;
}

.border-t-lime-100\/25 {
  border-top-color: #ecfccb40;
}

.border-t-lime-100\/30 {
  border-top-color: #ecfccb4d;
}

.border-t-lime-100\/40 {
  border-top-color: #ecfccb66;
}

.border-t-lime-100\/5 {
  border-top-color: #ecfccb0d;
}

.border-t-lime-100\/50 {
  border-top-color: #ecfccb80;
}

.border-t-lime-100\/60 {
  border-top-color: #ecfccb99;
}

.border-t-lime-100\/70 {
  border-top-color: #ecfccbb3;
}

.border-t-lime-100\/75 {
  border-top-color: #ecfccbbf;
}

.border-t-lime-100\/80 {
  border-top-color: #ecfccbcc;
}

.border-t-lime-100\/90 {
  border-top-color: #ecfccbe6;
}

.border-t-lime-100\/95 {
  border-top-color: #ecfccbf2;
}

.border-t-lime-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-t-lime-200\/0 {
  border-top-color: #d9f99d00;
}

.border-t-lime-200\/10 {
  border-top-color: #d9f99d1a;
}

.border-t-lime-200\/100 {
  border-top-color: #d9f99d;
}

.border-t-lime-200\/20 {
  border-top-color: #d9f99d33;
}

.border-t-lime-200\/25 {
  border-top-color: #d9f99d40;
}

.border-t-lime-200\/30 {
  border-top-color: #d9f99d4d;
}

.border-t-lime-200\/40 {
  border-top-color: #d9f99d66;
}

.border-t-lime-200\/5 {
  border-top-color: #d9f99d0d;
}

.border-t-lime-200\/50 {
  border-top-color: #d9f99d80;
}

.border-t-lime-200\/60 {
  border-top-color: #d9f99d99;
}

.border-t-lime-200\/70 {
  border-top-color: #d9f99db3;
}

.border-t-lime-200\/75 {
  border-top-color: #d9f99dbf;
}

.border-t-lime-200\/80 {
  border-top-color: #d9f99dcc;
}

.border-t-lime-200\/90 {
  border-top-color: #d9f99de6;
}

.border-t-lime-200\/95 {
  border-top-color: #d9f99df2;
}

.border-t-lime-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-t-lime-300\/0 {
  border-top-color: #bef26400;
}

.border-t-lime-300\/10 {
  border-top-color: #bef2641a;
}

.border-t-lime-300\/100 {
  border-top-color: #bef264;
}

.border-t-lime-300\/20 {
  border-top-color: #bef26433;
}

.border-t-lime-300\/25 {
  border-top-color: #bef26440;
}

.border-t-lime-300\/30 {
  border-top-color: #bef2644d;
}

.border-t-lime-300\/40 {
  border-top-color: #bef26466;
}

.border-t-lime-300\/5 {
  border-top-color: #bef2640d;
}

.border-t-lime-300\/50 {
  border-top-color: #bef26480;
}

.border-t-lime-300\/60 {
  border-top-color: #bef26499;
}

.border-t-lime-300\/70 {
  border-top-color: #bef264b3;
}

.border-t-lime-300\/75 {
  border-top-color: #bef264bf;
}

.border-t-lime-300\/80 {
  border-top-color: #bef264cc;
}

.border-t-lime-300\/90 {
  border-top-color: #bef264e6;
}

.border-t-lime-300\/95 {
  border-top-color: #bef264f2;
}

.border-t-lime-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-t-lime-400\/0 {
  border-top-color: #a3e63500;
}

.border-t-lime-400\/10 {
  border-top-color: #a3e6351a;
}

.border-t-lime-400\/100 {
  border-top-color: #a3e635;
}

.border-t-lime-400\/20 {
  border-top-color: #a3e63533;
}

.border-t-lime-400\/25 {
  border-top-color: #a3e63540;
}

.border-t-lime-400\/30 {
  border-top-color: #a3e6354d;
}

.border-t-lime-400\/40 {
  border-top-color: #a3e63566;
}

.border-t-lime-400\/5 {
  border-top-color: #a3e6350d;
}

.border-t-lime-400\/50 {
  border-top-color: #a3e63580;
}

.border-t-lime-400\/60 {
  border-top-color: #a3e63599;
}

.border-t-lime-400\/70 {
  border-top-color: #a3e635b3;
}

.border-t-lime-400\/75 {
  border-top-color: #a3e635bf;
}

.border-t-lime-400\/80 {
  border-top-color: #a3e635cc;
}

.border-t-lime-400\/90 {
  border-top-color: #a3e635e6;
}

.border-t-lime-400\/95 {
  border-top-color: #a3e635f2;
}

.border-t-lime-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-t-lime-50\/0 {
  border-top-color: #f7fee700;
}

.border-t-lime-50\/10 {
  border-top-color: #f7fee71a;
}

.border-t-lime-50\/100 {
  border-top-color: #f7fee7;
}

.border-t-lime-50\/20 {
  border-top-color: #f7fee733;
}

.border-t-lime-50\/25 {
  border-top-color: #f7fee740;
}

.border-t-lime-50\/30 {
  border-top-color: #f7fee74d;
}

.border-t-lime-50\/40 {
  border-top-color: #f7fee766;
}

.border-t-lime-50\/5 {
  border-top-color: #f7fee70d;
}

.border-t-lime-50\/50 {
  border-top-color: #f7fee780;
}

.border-t-lime-50\/60 {
  border-top-color: #f7fee799;
}

.border-t-lime-50\/70 {
  border-top-color: #f7fee7b3;
}

.border-t-lime-50\/75 {
  border-top-color: #f7fee7bf;
}

.border-t-lime-50\/80 {
  border-top-color: #f7fee7cc;
}

.border-t-lime-50\/90 {
  border-top-color: #f7fee7e6;
}

.border-t-lime-50\/95 {
  border-top-color: #f7fee7f2;
}

.border-t-lime-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-t-lime-500\/0 {
  border-top-color: #84cc1600;
}

.border-t-lime-500\/10 {
  border-top-color: #84cc161a;
}

.border-t-lime-500\/100 {
  border-top-color: #84cc16;
}

.border-t-lime-500\/20 {
  border-top-color: #84cc1633;
}

.border-t-lime-500\/25 {
  border-top-color: #84cc1640;
}

.border-t-lime-500\/30 {
  border-top-color: #84cc164d;
}

.border-t-lime-500\/40 {
  border-top-color: #84cc1666;
}

.border-t-lime-500\/5 {
  border-top-color: #84cc160d;
}

.border-t-lime-500\/50 {
  border-top-color: #84cc1680;
}

.border-t-lime-500\/60 {
  border-top-color: #84cc1699;
}

.border-t-lime-500\/70 {
  border-top-color: #84cc16b3;
}

.border-t-lime-500\/75 {
  border-top-color: #84cc16bf;
}

.border-t-lime-500\/80 {
  border-top-color: #84cc16cc;
}

.border-t-lime-500\/90 {
  border-top-color: #84cc16e6;
}

.border-t-lime-500\/95 {
  border-top-color: #84cc16f2;
}

.border-t-lime-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-t-lime-600\/0 {
  border-top-color: #65a30d00;
}

.border-t-lime-600\/10 {
  border-top-color: #65a30d1a;
}

.border-t-lime-600\/100 {
  border-top-color: #65a30d;
}

.border-t-lime-600\/20 {
  border-top-color: #65a30d33;
}

.border-t-lime-600\/25 {
  border-top-color: #65a30d40;
}

.border-t-lime-600\/30 {
  border-top-color: #65a30d4d;
}

.border-t-lime-600\/40 {
  border-top-color: #65a30d66;
}

.border-t-lime-600\/5 {
  border-top-color: #65a30d0d;
}

.border-t-lime-600\/50 {
  border-top-color: #65a30d80;
}

.border-t-lime-600\/60 {
  border-top-color: #65a30d99;
}

.border-t-lime-600\/70 {
  border-top-color: #65a30db3;
}

.border-t-lime-600\/75 {
  border-top-color: #65a30dbf;
}

.border-t-lime-600\/80 {
  border-top-color: #65a30dcc;
}

.border-t-lime-600\/90 {
  border-top-color: #65a30de6;
}

.border-t-lime-600\/95 {
  border-top-color: #65a30df2;
}

.border-t-lime-700 {
  --tw-border-opacity: 1;
  border-top-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-t-lime-700\/0 {
  border-top-color: #4d7c0f00;
}

.border-t-lime-700\/10 {
  border-top-color: #4d7c0f1a;
}

.border-t-lime-700\/100 {
  border-top-color: #4d7c0f;
}

.border-t-lime-700\/20 {
  border-top-color: #4d7c0f33;
}

.border-t-lime-700\/25 {
  border-top-color: #4d7c0f40;
}

.border-t-lime-700\/30 {
  border-top-color: #4d7c0f4d;
}

.border-t-lime-700\/40 {
  border-top-color: #4d7c0f66;
}

.border-t-lime-700\/5 {
  border-top-color: #4d7c0f0d;
}

.border-t-lime-700\/50 {
  border-top-color: #4d7c0f80;
}

.border-t-lime-700\/60 {
  border-top-color: #4d7c0f99;
}

.border-t-lime-700\/70 {
  border-top-color: #4d7c0fb3;
}

.border-t-lime-700\/75 {
  border-top-color: #4d7c0fbf;
}

.border-t-lime-700\/80 {
  border-top-color: #4d7c0fcc;
}

.border-t-lime-700\/90 {
  border-top-color: #4d7c0fe6;
}

.border-t-lime-700\/95 {
  border-top-color: #4d7c0ff2;
}

.border-t-lime-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-t-lime-800\/0 {
  border-top-color: #3f621200;
}

.border-t-lime-800\/10 {
  border-top-color: #3f62121a;
}

.border-t-lime-800\/100 {
  border-top-color: #3f6212;
}

.border-t-lime-800\/20 {
  border-top-color: #3f621233;
}

.border-t-lime-800\/25 {
  border-top-color: #3f621240;
}

.border-t-lime-800\/30 {
  border-top-color: #3f62124d;
}

.border-t-lime-800\/40 {
  border-top-color: #3f621266;
}

.border-t-lime-800\/5 {
  border-top-color: #3f62120d;
}

.border-t-lime-800\/50 {
  border-top-color: #3f621280;
}

.border-t-lime-800\/60 {
  border-top-color: #3f621299;
}

.border-t-lime-800\/70 {
  border-top-color: #3f6212b3;
}

.border-t-lime-800\/75 {
  border-top-color: #3f6212bf;
}

.border-t-lime-800\/80 {
  border-top-color: #3f6212cc;
}

.border-t-lime-800\/90 {
  border-top-color: #3f6212e6;
}

.border-t-lime-800\/95 {
  border-top-color: #3f6212f2;
}

.border-t-lime-900 {
  --tw-border-opacity: 1;
  border-top-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-t-lime-900\/0 {
  border-top-color: #36531400;
}

.border-t-lime-900\/10 {
  border-top-color: #3653141a;
}

.border-t-lime-900\/100 {
  border-top-color: #365314;
}

.border-t-lime-900\/20 {
  border-top-color: #36531433;
}

.border-t-lime-900\/25 {
  border-top-color: #36531440;
}

.border-t-lime-900\/30 {
  border-top-color: #3653144d;
}

.border-t-lime-900\/40 {
  border-top-color: #36531466;
}

.border-t-lime-900\/5 {
  border-top-color: #3653140d;
}

.border-t-lime-900\/50 {
  border-top-color: #36531480;
}

.border-t-lime-900\/60 {
  border-top-color: #36531499;
}

.border-t-lime-900\/70 {
  border-top-color: #365314b3;
}

.border-t-lime-900\/75 {
  border-top-color: #365314bf;
}

.border-t-lime-900\/80 {
  border-top-color: #365314cc;
}

.border-t-lime-900\/90 {
  border-top-color: #365314e6;
}

.border-t-lime-900\/95 {
  border-top-color: #365314f2;
}

.border-t-lime-950 {
  --tw-border-opacity: 1;
  border-top-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-t-lime-950\/0 {
  border-top-color: #1a2e0500;
}

.border-t-lime-950\/10 {
  border-top-color: #1a2e051a;
}

.border-t-lime-950\/100 {
  border-top-color: #1a2e05;
}

.border-t-lime-950\/20 {
  border-top-color: #1a2e0533;
}

.border-t-lime-950\/25 {
  border-top-color: #1a2e0540;
}

.border-t-lime-950\/30 {
  border-top-color: #1a2e054d;
}

.border-t-lime-950\/40 {
  border-top-color: #1a2e0566;
}

.border-t-lime-950\/5 {
  border-top-color: #1a2e050d;
}

.border-t-lime-950\/50 {
  border-top-color: #1a2e0580;
}

.border-t-lime-950\/60 {
  border-top-color: #1a2e0599;
}

.border-t-lime-950\/70 {
  border-top-color: #1a2e05b3;
}

.border-t-lime-950\/75 {
  border-top-color: #1a2e05bf;
}

.border-t-lime-950\/80 {
  border-top-color: #1a2e05cc;
}

.border-t-lime-950\/90 {
  border-top-color: #1a2e05e6;
}

.border-t-lime-950\/95 {
  border-top-color: #1a2e05f2;
}

.border-t-pink-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-t-pink-100\/0 {
  border-top-color: #fce7f300;
}

.border-t-pink-100\/10 {
  border-top-color: #fce7f31a;
}

.border-t-pink-100\/100 {
  border-top-color: #fce7f3;
}

.border-t-pink-100\/20 {
  border-top-color: #fce7f333;
}

.border-t-pink-100\/25 {
  border-top-color: #fce7f340;
}

.border-t-pink-100\/30 {
  border-top-color: #fce7f34d;
}

.border-t-pink-100\/40 {
  border-top-color: #fce7f366;
}

.border-t-pink-100\/5 {
  border-top-color: #fce7f30d;
}

.border-t-pink-100\/50 {
  border-top-color: #fce7f380;
}

.border-t-pink-100\/60 {
  border-top-color: #fce7f399;
}

.border-t-pink-100\/70 {
  border-top-color: #fce7f3b3;
}

.border-t-pink-100\/75 {
  border-top-color: #fce7f3bf;
}

.border-t-pink-100\/80 {
  border-top-color: #fce7f3cc;
}

.border-t-pink-100\/90 {
  border-top-color: #fce7f3e6;
}

.border-t-pink-100\/95 {
  border-top-color: #fce7f3f2;
}

.border-t-pink-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-t-pink-200\/0 {
  border-top-color: #fbcfe800;
}

.border-t-pink-200\/10 {
  border-top-color: #fbcfe81a;
}

.border-t-pink-200\/100 {
  border-top-color: #fbcfe8;
}

.border-t-pink-200\/20 {
  border-top-color: #fbcfe833;
}

.border-t-pink-200\/25 {
  border-top-color: #fbcfe840;
}

.border-t-pink-200\/30 {
  border-top-color: #fbcfe84d;
}

.border-t-pink-200\/40 {
  border-top-color: #fbcfe866;
}

.border-t-pink-200\/5 {
  border-top-color: #fbcfe80d;
}

.border-t-pink-200\/50 {
  border-top-color: #fbcfe880;
}

.border-t-pink-200\/60 {
  border-top-color: #fbcfe899;
}

.border-t-pink-200\/70 {
  border-top-color: #fbcfe8b3;
}

.border-t-pink-200\/75 {
  border-top-color: #fbcfe8bf;
}

.border-t-pink-200\/80 {
  border-top-color: #fbcfe8cc;
}

.border-t-pink-200\/90 {
  border-top-color: #fbcfe8e6;
}

.border-t-pink-200\/95 {
  border-top-color: #fbcfe8f2;
}

.border-t-pink-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-t-pink-300\/0 {
  border-top-color: #f9a8d400;
}

.border-t-pink-300\/10 {
  border-top-color: #f9a8d41a;
}

.border-t-pink-300\/100 {
  border-top-color: #f9a8d4;
}

.border-t-pink-300\/20 {
  border-top-color: #f9a8d433;
}

.border-t-pink-300\/25 {
  border-top-color: #f9a8d440;
}

.border-t-pink-300\/30 {
  border-top-color: #f9a8d44d;
}

.border-t-pink-300\/40 {
  border-top-color: #f9a8d466;
}

.border-t-pink-300\/5 {
  border-top-color: #f9a8d40d;
}

.border-t-pink-300\/50 {
  border-top-color: #f9a8d480;
}

.border-t-pink-300\/60 {
  border-top-color: #f9a8d499;
}

.border-t-pink-300\/70 {
  border-top-color: #f9a8d4b3;
}

.border-t-pink-300\/75 {
  border-top-color: #f9a8d4bf;
}

.border-t-pink-300\/80 {
  border-top-color: #f9a8d4cc;
}

.border-t-pink-300\/90 {
  border-top-color: #f9a8d4e6;
}

.border-t-pink-300\/95 {
  border-top-color: #f9a8d4f2;
}

.border-t-pink-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-t-pink-400\/0 {
  border-top-color: #f472b600;
}

.border-t-pink-400\/10 {
  border-top-color: #f472b61a;
}

.border-t-pink-400\/100 {
  border-top-color: #f472b6;
}

.border-t-pink-400\/20 {
  border-top-color: #f472b633;
}

.border-t-pink-400\/25 {
  border-top-color: #f472b640;
}

.border-t-pink-400\/30 {
  border-top-color: #f472b64d;
}

.border-t-pink-400\/40 {
  border-top-color: #f472b666;
}

.border-t-pink-400\/5 {
  border-top-color: #f472b60d;
}

.border-t-pink-400\/50 {
  border-top-color: #f472b680;
}

.border-t-pink-400\/60 {
  border-top-color: #f472b699;
}

.border-t-pink-400\/70 {
  border-top-color: #f472b6b3;
}

.border-t-pink-400\/75 {
  border-top-color: #f472b6bf;
}

.border-t-pink-400\/80 {
  border-top-color: #f472b6cc;
}

.border-t-pink-400\/90 {
  border-top-color: #f472b6e6;
}

.border-t-pink-400\/95 {
  border-top-color: #f472b6f2;
}

.border-t-pink-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-t-pink-50\/0 {
  border-top-color: #fdf2f800;
}

.border-t-pink-50\/10 {
  border-top-color: #fdf2f81a;
}

.border-t-pink-50\/100 {
  border-top-color: #fdf2f8;
}

.border-t-pink-50\/20 {
  border-top-color: #fdf2f833;
}

.border-t-pink-50\/25 {
  border-top-color: #fdf2f840;
}

.border-t-pink-50\/30 {
  border-top-color: #fdf2f84d;
}

.border-t-pink-50\/40 {
  border-top-color: #fdf2f866;
}

.border-t-pink-50\/5 {
  border-top-color: #fdf2f80d;
}

.border-t-pink-50\/50 {
  border-top-color: #fdf2f880;
}

.border-t-pink-50\/60 {
  border-top-color: #fdf2f899;
}

.border-t-pink-50\/70 {
  border-top-color: #fdf2f8b3;
}

.border-t-pink-50\/75 {
  border-top-color: #fdf2f8bf;
}

.border-t-pink-50\/80 {
  border-top-color: #fdf2f8cc;
}

.border-t-pink-50\/90 {
  border-top-color: #fdf2f8e6;
}

.border-t-pink-50\/95 {
  border-top-color: #fdf2f8f2;
}

.border-t-pink-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-t-pink-500\/0 {
  border-top-color: #ec489900;
}

.border-t-pink-500\/10 {
  border-top-color: #ec48991a;
}

.border-t-pink-500\/100 {
  border-top-color: #ec4899;
}

.border-t-pink-500\/20 {
  border-top-color: #ec489933;
}

.border-t-pink-500\/25 {
  border-top-color: #ec489940;
}

.border-t-pink-500\/30 {
  border-top-color: #ec48994d;
}

.border-t-pink-500\/40 {
  border-top-color: #ec489966;
}

.border-t-pink-500\/5 {
  border-top-color: #ec48990d;
}

.border-t-pink-500\/50 {
  border-top-color: #ec489980;
}

.border-t-pink-500\/60 {
  border-top-color: #ec489999;
}

.border-t-pink-500\/70 {
  border-top-color: #ec4899b3;
}

.border-t-pink-500\/75 {
  border-top-color: #ec4899bf;
}

.border-t-pink-500\/80 {
  border-top-color: #ec4899cc;
}

.border-t-pink-500\/90 {
  border-top-color: #ec4899e6;
}

.border-t-pink-500\/95 {
  border-top-color: #ec4899f2;
}

.border-t-pink-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-t-pink-600\/0 {
  border-top-color: #db277700;
}

.border-t-pink-600\/10 {
  border-top-color: #db27771a;
}

.border-t-pink-600\/100 {
  border-top-color: #db2777;
}

.border-t-pink-600\/20 {
  border-top-color: #db277733;
}

.border-t-pink-600\/25 {
  border-top-color: #db277740;
}

.border-t-pink-600\/30 {
  border-top-color: #db27774d;
}

.border-t-pink-600\/40 {
  border-top-color: #db277766;
}

.border-t-pink-600\/5 {
  border-top-color: #db27770d;
}

.border-t-pink-600\/50 {
  border-top-color: #db277780;
}

.border-t-pink-600\/60 {
  border-top-color: #db277799;
}

.border-t-pink-600\/70 {
  border-top-color: #db2777b3;
}

.border-t-pink-600\/75 {
  border-top-color: #db2777bf;
}

.border-t-pink-600\/80 {
  border-top-color: #db2777cc;
}

.border-t-pink-600\/90 {
  border-top-color: #db2777e6;
}

.border-t-pink-600\/95 {
  border-top-color: #db2777f2;
}

.border-t-pink-700 {
  --tw-border-opacity: 1;
  border-top-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-t-pink-700\/0 {
  border-top-color: #be185d00;
}

.border-t-pink-700\/10 {
  border-top-color: #be185d1a;
}

.border-t-pink-700\/100 {
  border-top-color: #be185d;
}

.border-t-pink-700\/20 {
  border-top-color: #be185d33;
}

.border-t-pink-700\/25 {
  border-top-color: #be185d40;
}

.border-t-pink-700\/30 {
  border-top-color: #be185d4d;
}

.border-t-pink-700\/40 {
  border-top-color: #be185d66;
}

.border-t-pink-700\/5 {
  border-top-color: #be185d0d;
}

.border-t-pink-700\/50 {
  border-top-color: #be185d80;
}

.border-t-pink-700\/60 {
  border-top-color: #be185d99;
}

.border-t-pink-700\/70 {
  border-top-color: #be185db3;
}

.border-t-pink-700\/75 {
  border-top-color: #be185dbf;
}

.border-t-pink-700\/80 {
  border-top-color: #be185dcc;
}

.border-t-pink-700\/90 {
  border-top-color: #be185de6;
}

.border-t-pink-700\/95 {
  border-top-color: #be185df2;
}

.border-t-pink-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-t-pink-800\/0 {
  border-top-color: #9d174d00;
}

.border-t-pink-800\/10 {
  border-top-color: #9d174d1a;
}

.border-t-pink-800\/100 {
  border-top-color: #9d174d;
}

.border-t-pink-800\/20 {
  border-top-color: #9d174d33;
}

.border-t-pink-800\/25 {
  border-top-color: #9d174d40;
}

.border-t-pink-800\/30 {
  border-top-color: #9d174d4d;
}

.border-t-pink-800\/40 {
  border-top-color: #9d174d66;
}

.border-t-pink-800\/5 {
  border-top-color: #9d174d0d;
}

.border-t-pink-800\/50 {
  border-top-color: #9d174d80;
}

.border-t-pink-800\/60 {
  border-top-color: #9d174d99;
}

.border-t-pink-800\/70 {
  border-top-color: #9d174db3;
}

.border-t-pink-800\/75 {
  border-top-color: #9d174dbf;
}

.border-t-pink-800\/80 {
  border-top-color: #9d174dcc;
}

.border-t-pink-800\/90 {
  border-top-color: #9d174de6;
}

.border-t-pink-800\/95 {
  border-top-color: #9d174df2;
}

.border-t-pink-900 {
  --tw-border-opacity: 1;
  border-top-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-t-pink-900\/0 {
  border-top-color: #83184300;
}

.border-t-pink-900\/10 {
  border-top-color: #8318431a;
}

.border-t-pink-900\/100 {
  border-top-color: #831843;
}

.border-t-pink-900\/20 {
  border-top-color: #83184333;
}

.border-t-pink-900\/25 {
  border-top-color: #83184340;
}

.border-t-pink-900\/30 {
  border-top-color: #8318434d;
}

.border-t-pink-900\/40 {
  border-top-color: #83184366;
}

.border-t-pink-900\/5 {
  border-top-color: #8318430d;
}

.border-t-pink-900\/50 {
  border-top-color: #83184380;
}

.border-t-pink-900\/60 {
  border-top-color: #83184399;
}

.border-t-pink-900\/70 {
  border-top-color: #831843b3;
}

.border-t-pink-900\/75 {
  border-top-color: #831843bf;
}

.border-t-pink-900\/80 {
  border-top-color: #831843cc;
}

.border-t-pink-900\/90 {
  border-top-color: #831843e6;
}

.border-t-pink-900\/95 {
  border-top-color: #831843f2;
}

.border-t-pink-950 {
  --tw-border-opacity: 1;
  border-top-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-t-pink-950\/0 {
  border-top-color: #50072400;
}

.border-t-pink-950\/10 {
  border-top-color: #5007241a;
}

.border-t-pink-950\/100 {
  border-top-color: #500724;
}

.border-t-pink-950\/20 {
  border-top-color: #50072433;
}

.border-t-pink-950\/25 {
  border-top-color: #50072440;
}

.border-t-pink-950\/30 {
  border-top-color: #5007244d;
}

.border-t-pink-950\/40 {
  border-top-color: #50072466;
}

.border-t-pink-950\/5 {
  border-top-color: #5007240d;
}

.border-t-pink-950\/50 {
  border-top-color: #50072480;
}

.border-t-pink-950\/60 {
  border-top-color: #50072499;
}

.border-t-pink-950\/70 {
  border-top-color: #500724b3;
}

.border-t-pink-950\/75 {
  border-top-color: #500724bf;
}

.border-t-pink-950\/80 {
  border-top-color: #500724cc;
}

.border-t-pink-950\/90 {
  border-top-color: #500724e6;
}

.border-t-pink-950\/95 {
  border-top-color: #500724f2;
}

.border-t-primary {
  --tw-border-opacity: 1;
  border-top-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-t-primary\/0 {
  border-top-color: hsl(var(--primary) / 0);
}

.border-t-primary\/10 {
  border-top-color: hsl(var(--primary) / .1);
}

.border-t-primary\/100 {
  border-top-color: hsl(var(--primary) / 1);
}

.border-t-primary\/20 {
  border-top-color: hsl(var(--primary) / .2);
}

.border-t-primary\/25 {
  border-top-color: hsl(var(--primary) / .25);
}

.border-t-primary\/30 {
  border-top-color: hsl(var(--primary) / .3);
}

.border-t-primary\/40 {
  border-top-color: hsl(var(--primary) / .4);
}

.border-t-primary\/5 {
  border-top-color: hsl(var(--primary) / .05);
}

.border-t-primary\/50 {
  border-top-color: hsl(var(--primary) / .5);
}

.border-t-primary\/60 {
  border-top-color: hsl(var(--primary) / .6);
}

.border-t-primary\/70 {
  border-top-color: hsl(var(--primary) / .7);
}

.border-t-primary\/75 {
  border-top-color: hsl(var(--primary) / .75);
}

.border-t-primary\/80 {
  border-top-color: hsl(var(--primary) / .8);
}

.border-t-primary\/90 {
  border-top-color: hsl(var(--primary) / .9);
}

.border-t-primary\/95 {
  border-top-color: hsl(var(--primary) / .95);
}

.border-t-purple-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-t-purple-100\/0 {
  border-top-color: #f3e8ff00;
}

.border-t-purple-100\/10 {
  border-top-color: #f3e8ff1a;
}

.border-t-purple-100\/100 {
  border-top-color: #f3e8ff;
}

.border-t-purple-100\/20 {
  border-top-color: #f3e8ff33;
}

.border-t-purple-100\/25 {
  border-top-color: #f3e8ff40;
}

.border-t-purple-100\/30 {
  border-top-color: #f3e8ff4d;
}

.border-t-purple-100\/40 {
  border-top-color: #f3e8ff66;
}

.border-t-purple-100\/5 {
  border-top-color: #f3e8ff0d;
}

.border-t-purple-100\/50 {
  border-top-color: #f3e8ff80;
}

.border-t-purple-100\/60 {
  border-top-color: #f3e8ff99;
}

.border-t-purple-100\/70 {
  border-top-color: #f3e8ffb3;
}

.border-t-purple-100\/75 {
  border-top-color: #f3e8ffbf;
}

.border-t-purple-100\/80 {
  border-top-color: #f3e8ffcc;
}

.border-t-purple-100\/90 {
  border-top-color: #f3e8ffe6;
}

.border-t-purple-100\/95 {
  border-top-color: #f3e8fff2;
}

.border-t-purple-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-t-purple-200\/0 {
  border-top-color: #e9d5ff00;
}

.border-t-purple-200\/10 {
  border-top-color: #e9d5ff1a;
}

.border-t-purple-200\/100 {
  border-top-color: #e9d5ff;
}

.border-t-purple-200\/20 {
  border-top-color: #e9d5ff33;
}

.border-t-purple-200\/25 {
  border-top-color: #e9d5ff40;
}

.border-t-purple-200\/30 {
  border-top-color: #e9d5ff4d;
}

.border-t-purple-200\/40 {
  border-top-color: #e9d5ff66;
}

.border-t-purple-200\/5 {
  border-top-color: #e9d5ff0d;
}

.border-t-purple-200\/50 {
  border-top-color: #e9d5ff80;
}

.border-t-purple-200\/60 {
  border-top-color: #e9d5ff99;
}

.border-t-purple-200\/70 {
  border-top-color: #e9d5ffb3;
}

.border-t-purple-200\/75 {
  border-top-color: #e9d5ffbf;
}

.border-t-purple-200\/80 {
  border-top-color: #e9d5ffcc;
}

.border-t-purple-200\/90 {
  border-top-color: #e9d5ffe6;
}

.border-t-purple-200\/95 {
  border-top-color: #e9d5fff2;
}

.border-t-purple-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-t-purple-300\/0 {
  border-top-color: #d8b4fe00;
}

.border-t-purple-300\/10 {
  border-top-color: #d8b4fe1a;
}

.border-t-purple-300\/100 {
  border-top-color: #d8b4fe;
}

.border-t-purple-300\/20 {
  border-top-color: #d8b4fe33;
}

.border-t-purple-300\/25 {
  border-top-color: #d8b4fe40;
}

.border-t-purple-300\/30 {
  border-top-color: #d8b4fe4d;
}

.border-t-purple-300\/40 {
  border-top-color: #d8b4fe66;
}

.border-t-purple-300\/5 {
  border-top-color: #d8b4fe0d;
}

.border-t-purple-300\/50 {
  border-top-color: #d8b4fe80;
}

.border-t-purple-300\/60 {
  border-top-color: #d8b4fe99;
}

.border-t-purple-300\/70 {
  border-top-color: #d8b4feb3;
}

.border-t-purple-300\/75 {
  border-top-color: #d8b4febf;
}

.border-t-purple-300\/80 {
  border-top-color: #d8b4fecc;
}

.border-t-purple-300\/90 {
  border-top-color: #d8b4fee6;
}

.border-t-purple-300\/95 {
  border-top-color: #d8b4fef2;
}

.border-t-purple-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-t-purple-400\/0 {
  border-top-color: #c084fc00;
}

.border-t-purple-400\/10 {
  border-top-color: #c084fc1a;
}

.border-t-purple-400\/100 {
  border-top-color: #c084fc;
}

.border-t-purple-400\/20 {
  border-top-color: #c084fc33;
}

.border-t-purple-400\/25 {
  border-top-color: #c084fc40;
}

.border-t-purple-400\/30 {
  border-top-color: #c084fc4d;
}

.border-t-purple-400\/40 {
  border-top-color: #c084fc66;
}

.border-t-purple-400\/5 {
  border-top-color: #c084fc0d;
}

.border-t-purple-400\/50 {
  border-top-color: #c084fc80;
}

.border-t-purple-400\/60 {
  border-top-color: #c084fc99;
}

.border-t-purple-400\/70 {
  border-top-color: #c084fcb3;
}

.border-t-purple-400\/75 {
  border-top-color: #c084fcbf;
}

.border-t-purple-400\/80 {
  border-top-color: #c084fccc;
}

.border-t-purple-400\/90 {
  border-top-color: #c084fce6;
}

.border-t-purple-400\/95 {
  border-top-color: #c084fcf2;
}

.border-t-purple-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-t-purple-50\/0 {
  border-top-color: #faf5ff00;
}

.border-t-purple-50\/10 {
  border-top-color: #faf5ff1a;
}

.border-t-purple-50\/100 {
  border-top-color: #faf5ff;
}

.border-t-purple-50\/20 {
  border-top-color: #faf5ff33;
}

.border-t-purple-50\/25 {
  border-top-color: #faf5ff40;
}

.border-t-purple-50\/30 {
  border-top-color: #faf5ff4d;
}

.border-t-purple-50\/40 {
  border-top-color: #faf5ff66;
}

.border-t-purple-50\/5 {
  border-top-color: #faf5ff0d;
}

.border-t-purple-50\/50 {
  border-top-color: #faf5ff80;
}

.border-t-purple-50\/60 {
  border-top-color: #faf5ff99;
}

.border-t-purple-50\/70 {
  border-top-color: #faf5ffb3;
}

.border-t-purple-50\/75 {
  border-top-color: #faf5ffbf;
}

.border-t-purple-50\/80 {
  border-top-color: #faf5ffcc;
}

.border-t-purple-50\/90 {
  border-top-color: #faf5ffe6;
}

.border-t-purple-50\/95 {
  border-top-color: #faf5fff2;
}

.border-t-purple-500 {
  --tw-border-opacity: 1;
  border-top-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-t-purple-500\/0 {
  border-top-color: #a855f700;
}

.border-t-purple-500\/10 {
  border-top-color: #a855f71a;
}

.border-t-purple-500\/100 {
  border-top-color: #a855f7;
}

.border-t-purple-500\/20 {
  border-top-color: #a855f733;
}

.border-t-purple-500\/25 {
  border-top-color: #a855f740;
}

.border-t-purple-500\/30 {
  border-top-color: #a855f74d;
}

.border-t-purple-500\/40 {
  border-top-color: #a855f766;
}

.border-t-purple-500\/5 {
  border-top-color: #a855f70d;
}

.border-t-purple-500\/50 {
  border-top-color: #a855f780;
}

.border-t-purple-500\/60 {
  border-top-color: #a855f799;
}

.border-t-purple-500\/70 {
  border-top-color: #a855f7b3;
}

.border-t-purple-500\/75 {
  border-top-color: #a855f7bf;
}

.border-t-purple-500\/80 {
  border-top-color: #a855f7cc;
}

.border-t-purple-500\/90 {
  border-top-color: #a855f7e6;
}

.border-t-purple-500\/95 {
  border-top-color: #a855f7f2;
}

.border-t-purple-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-t-purple-600\/0 {
  border-top-color: #9333ea00;
}

.border-t-purple-600\/10 {
  border-top-color: #9333ea1a;
}

.border-t-purple-600\/100 {
  border-top-color: #9333ea;
}

.border-t-purple-600\/20 {
  border-top-color: #9333ea33;
}

.border-t-purple-600\/25 {
  border-top-color: #9333ea40;
}

.border-t-purple-600\/30 {
  border-top-color: #9333ea4d;
}

.border-t-purple-600\/40 {
  border-top-color: #9333ea66;
}

.border-t-purple-600\/5 {
  border-top-color: #9333ea0d;
}

.border-t-purple-600\/50 {
  border-top-color: #9333ea80;
}

.border-t-purple-600\/60 {
  border-top-color: #9333ea99;
}

.border-t-purple-600\/70 {
  border-top-color: #9333eab3;
}

.border-t-purple-600\/75 {
  border-top-color: #9333eabf;
}

.border-t-purple-600\/80 {
  border-top-color: #9333eacc;
}

.border-t-purple-600\/90 {
  border-top-color: #9333eae6;
}

.border-t-purple-600\/95 {
  border-top-color: #9333eaf2;
}

.border-t-purple-700 {
  --tw-border-opacity: 1;
  border-top-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-t-purple-700\/0 {
  border-top-color: #7e22ce00;
}

.border-t-purple-700\/10 {
  border-top-color: #7e22ce1a;
}

.border-t-purple-700\/100 {
  border-top-color: #7e22ce;
}

.border-t-purple-700\/20 {
  border-top-color: #7e22ce33;
}

.border-t-purple-700\/25 {
  border-top-color: #7e22ce40;
}

.border-t-purple-700\/30 {
  border-top-color: #7e22ce4d;
}

.border-t-purple-700\/40 {
  border-top-color: #7e22ce66;
}

.border-t-purple-700\/5 {
  border-top-color: #7e22ce0d;
}

.border-t-purple-700\/50 {
  border-top-color: #7e22ce80;
}

.border-t-purple-700\/60 {
  border-top-color: #7e22ce99;
}

.border-t-purple-700\/70 {
  border-top-color: #7e22ceb3;
}

.border-t-purple-700\/75 {
  border-top-color: #7e22cebf;
}

.border-t-purple-700\/80 {
  border-top-color: #7e22cecc;
}

.border-t-purple-700\/90 {
  border-top-color: #7e22cee6;
}

.border-t-purple-700\/95 {
  border-top-color: #7e22cef2;
}

.border-t-purple-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-t-purple-800\/0 {
  border-top-color: #6b21a800;
}

.border-t-purple-800\/10 {
  border-top-color: #6b21a81a;
}

.border-t-purple-800\/100 {
  border-top-color: #6b21a8;
}

.border-t-purple-800\/20 {
  border-top-color: #6b21a833;
}

.border-t-purple-800\/25 {
  border-top-color: #6b21a840;
}

.border-t-purple-800\/30 {
  border-top-color: #6b21a84d;
}

.border-t-purple-800\/40 {
  border-top-color: #6b21a866;
}

.border-t-purple-800\/5 {
  border-top-color: #6b21a80d;
}

.border-t-purple-800\/50 {
  border-top-color: #6b21a880;
}

.border-t-purple-800\/60 {
  border-top-color: #6b21a899;
}

.border-t-purple-800\/70 {
  border-top-color: #6b21a8b3;
}

.border-t-purple-800\/75 {
  border-top-color: #6b21a8bf;
}

.border-t-purple-800\/80 {
  border-top-color: #6b21a8cc;
}

.border-t-purple-800\/90 {
  border-top-color: #6b21a8e6;
}

.border-t-purple-800\/95 {
  border-top-color: #6b21a8f2;
}

.border-t-purple-900 {
  --tw-border-opacity: 1;
  border-top-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-t-purple-900\/0 {
  border-top-color: #581c8700;
}

.border-t-purple-900\/10 {
  border-top-color: #581c871a;
}

.border-t-purple-900\/100 {
  border-top-color: #581c87;
}

.border-t-purple-900\/20 {
  border-top-color: #581c8733;
}

.border-t-purple-900\/25 {
  border-top-color: #581c8740;
}

.border-t-purple-900\/30 {
  border-top-color: #581c874d;
}

.border-t-purple-900\/40 {
  border-top-color: #581c8766;
}

.border-t-purple-900\/5 {
  border-top-color: #581c870d;
}

.border-t-purple-900\/50 {
  border-top-color: #581c8780;
}

.border-t-purple-900\/60 {
  border-top-color: #581c8799;
}

.border-t-purple-900\/70 {
  border-top-color: #581c87b3;
}

.border-t-purple-900\/75 {
  border-top-color: #581c87bf;
}

.border-t-purple-900\/80 {
  border-top-color: #581c87cc;
}

.border-t-purple-900\/90 {
  border-top-color: #581c87e6;
}

.border-t-purple-900\/95 {
  border-top-color: #581c87f2;
}

.border-t-purple-950 {
  --tw-border-opacity: 1;
  border-top-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-t-purple-950\/0 {
  border-top-color: #3b076400;
}

.border-t-purple-950\/10 {
  border-top-color: #3b07641a;
}

.border-t-purple-950\/100 {
  border-top-color: #3b0764;
}

.border-t-purple-950\/20 {
  border-top-color: #3b076433;
}

.border-t-purple-950\/25 {
  border-top-color: #3b076440;
}

.border-t-purple-950\/30 {
  border-top-color: #3b07644d;
}

.border-t-purple-950\/40 {
  border-top-color: #3b076466;
}

.border-t-purple-950\/5 {
  border-top-color: #3b07640d;
}

.border-t-purple-950\/50 {
  border-top-color: #3b076480;
}

.border-t-purple-950\/60 {
  border-top-color: #3b076499;
}

.border-t-purple-950\/70 {
  border-top-color: #3b0764b3;
}

.border-t-purple-950\/75 {
  border-top-color: #3b0764bf;
}

.border-t-purple-950\/80 {
  border-top-color: #3b0764cc;
}

.border-t-purple-950\/90 {
  border-top-color: #3b0764e6;
}

.border-t-purple-950\/95 {
  border-top-color: #3b0764f2;
}

.border-t-transparent, .border-t-transparent\/0 {
  border-top-color: #0000;
}

.border-t-transparent\/10 {
  border-top-color: #0000001a;
}

.border-t-transparent\/100 {
  border-top-color: #000;
}

.border-t-transparent\/20 {
  border-top-color: #0003;
}

.border-t-transparent\/25 {
  border-top-color: #00000040;
}

.border-t-transparent\/30 {
  border-top-color: #0000004d;
}

.border-t-transparent\/40 {
  border-top-color: #0006;
}

.border-t-transparent\/5 {
  border-top-color: #0000000d;
}

.border-t-transparent\/50 {
  border-top-color: #00000080;
}

.border-t-transparent\/60 {
  border-top-color: #0009;
}

.border-t-transparent\/70 {
  border-top-color: #000000b3;
}

.border-t-transparent\/75 {
  border-top-color: #000000bf;
}

.border-t-transparent\/80 {
  border-top-color: #000c;
}

.border-t-transparent\/90 {
  border-top-color: #000000e6;
}

.border-t-transparent\/95 {
  border-top-color: #000000f2;
}

.border-opacity-0 {
  --tw-border-opacity: 0;
}

.border-opacity-10 {
  --tw-border-opacity: .1;
}

.border-opacity-100 {
  --tw-border-opacity: 1;
}

.border-opacity-20 {
  --tw-border-opacity: .2;
}

.border-opacity-25 {
  --tw-border-opacity: .25;
}

.border-opacity-30 {
  --tw-border-opacity: .3;
}

.border-opacity-40 {
  --tw-border-opacity: .4;
}

.border-opacity-5 {
  --tw-border-opacity: .05;
}

.border-opacity-50 {
  --tw-border-opacity: .5;
}

.border-opacity-60 {
  --tw-border-opacity: .6;
}

.border-opacity-70 {
  --tw-border-opacity: .7;
}

.border-opacity-75 {
  --tw-border-opacity: .75;
}

.border-opacity-80 {
  --tw-border-opacity: .8;
}

.border-opacity-90 {
  --tw-border-opacity: .9;
}

.border-opacity-95 {
  --tw-border-opacity: .95;
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.bg-amber-100\/0 {
  background-color: #fef3c700;
}

.bg-amber-100\/10 {
  background-color: #fef3c71a;
}

.bg-amber-100\/100 {
  background-color: #fef3c7;
}

.bg-amber-100\/20 {
  background-color: #fef3c733;
}

.bg-amber-100\/25 {
  background-color: #fef3c740;
}

.bg-amber-100\/30 {
  background-color: #fef3c74d;
}

.bg-amber-100\/40 {
  background-color: #fef3c766;
}

.bg-amber-100\/5 {
  background-color: #fef3c70d;
}

.bg-amber-100\/50 {
  background-color: #fef3c780;
}

.bg-amber-100\/60 {
  background-color: #fef3c799;
}

.bg-amber-100\/70 {
  background-color: #fef3c7b3;
}

.bg-amber-100\/75 {
  background-color: #fef3c7bf;
}

.bg-amber-100\/80 {
  background-color: #fef3c7cc;
}

.bg-amber-100\/90 {
  background-color: #fef3c7e6;
}

.bg-amber-100\/95 {
  background-color: #fef3c7f2;
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.bg-amber-200\/0 {
  background-color: #fde68a00;
}

.bg-amber-200\/10 {
  background-color: #fde68a1a;
}

.bg-amber-200\/100 {
  background-color: #fde68a;
}

.bg-amber-200\/20 {
  background-color: #fde68a33;
}

.bg-amber-200\/25 {
  background-color: #fde68a40;
}

.bg-amber-200\/30 {
  background-color: #fde68a4d;
}

.bg-amber-200\/40 {
  background-color: #fde68a66;
}

.bg-amber-200\/5 {
  background-color: #fde68a0d;
}

.bg-amber-200\/50 {
  background-color: #fde68a80;
}

.bg-amber-200\/60 {
  background-color: #fde68a99;
}

.bg-amber-200\/70 {
  background-color: #fde68ab3;
}

.bg-amber-200\/75 {
  background-color: #fde68abf;
}

.bg-amber-200\/80 {
  background-color: #fde68acc;
}

.bg-amber-200\/90 {
  background-color: #fde68ae6;
}

.bg-amber-200\/95 {
  background-color: #fde68af2;
}

.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

.bg-amber-300\/0 {
  background-color: #fcd34d00;
}

.bg-amber-300\/10 {
  background-color: #fcd34d1a;
}

.bg-amber-300\/100 {
  background-color: #fcd34d;
}

.bg-amber-300\/20 {
  background-color: #fcd34d33;
}

.bg-amber-300\/25 {
  background-color: #fcd34d40;
}

.bg-amber-300\/30 {
  background-color: #fcd34d4d;
}

.bg-amber-300\/40 {
  background-color: #fcd34d66;
}

.bg-amber-300\/5 {
  background-color: #fcd34d0d;
}

.bg-amber-300\/50 {
  background-color: #fcd34d80;
}

.bg-amber-300\/60 {
  background-color: #fcd34d99;
}

.bg-amber-300\/70 {
  background-color: #fcd34db3;
}

.bg-amber-300\/75 {
  background-color: #fcd34dbf;
}

.bg-amber-300\/80 {
  background-color: #fcd34dcc;
}

.bg-amber-300\/90 {
  background-color: #fcd34de6;
}

.bg-amber-300\/95 {
  background-color: #fcd34df2;
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.bg-amber-400\/0 {
  background-color: #fbbf2400;
}

.bg-amber-400\/10 {
  background-color: #fbbf241a;
}

.bg-amber-400\/100 {
  background-color: #fbbf24;
}

.bg-amber-400\/20 {
  background-color: #fbbf2433;
}

.bg-amber-400\/25 {
  background-color: #fbbf2440;
}

.bg-amber-400\/30 {
  background-color: #fbbf244d;
}

.bg-amber-400\/40 {
  background-color: #fbbf2466;
}

.bg-amber-400\/5 {
  background-color: #fbbf240d;
}

.bg-amber-400\/50 {
  background-color: #fbbf2480;
}

.bg-amber-400\/60 {
  background-color: #fbbf2499;
}

.bg-amber-400\/70 {
  background-color: #fbbf24b3;
}

.bg-amber-400\/75 {
  background-color: #fbbf24bf;
}

.bg-amber-400\/80 {
  background-color: #fbbf24cc;
}

.bg-amber-400\/90 {
  background-color: #fbbf24e6;
}

.bg-amber-400\/95 {
  background-color: #fbbf24f2;
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.bg-amber-50\/0 {
  background-color: #fffbeb00;
}

.bg-amber-50\/10 {
  background-color: #fffbeb1a;
}

.bg-amber-50\/100 {
  background-color: #fffbeb;
}

.bg-amber-50\/20 {
  background-color: #fffbeb33;
}

.bg-amber-50\/25 {
  background-color: #fffbeb40;
}

.bg-amber-50\/30 {
  background-color: #fffbeb4d;
}

.bg-amber-50\/40 {
  background-color: #fffbeb66;
}

.bg-amber-50\/5 {
  background-color: #fffbeb0d;
}

.bg-amber-50\/50 {
  background-color: #fffbeb80;
}

.bg-amber-50\/60 {
  background-color: #fffbeb99;
}

.bg-amber-50\/70 {
  background-color: #fffbebb3;
}

.bg-amber-50\/75 {
  background-color: #fffbebbf;
}

.bg-amber-50\/80 {
  background-color: #fffbebcc;
}

.bg-amber-50\/90 {
  background-color: #fffbebe6;
}

.bg-amber-50\/95 {
  background-color: #fffbebf2;
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-amber-500\/0 {
  background-color: #f59e0b00;
}

.bg-amber-500\/10 {
  background-color: #f59e0b1a;
}

.bg-amber-500\/100 {
  background-color: #f59e0b;
}

.bg-amber-500\/20 {
  background-color: #f59e0b33;
}

.bg-amber-500\/25 {
  background-color: #f59e0b40;
}

.bg-amber-500\/30 {
  background-color: #f59e0b4d;
}

.bg-amber-500\/40 {
  background-color: #f59e0b66;
}

.bg-amber-500\/5 {
  background-color: #f59e0b0d;
}

.bg-amber-500\/50 {
  background-color: #f59e0b80;
}

.bg-amber-500\/60 {
  background-color: #f59e0b99;
}

.bg-amber-500\/70 {
  background-color: #f59e0bb3;
}

.bg-amber-500\/75 {
  background-color: #f59e0bbf;
}

.bg-amber-500\/80 {
  background-color: #f59e0bcc;
}

.bg-amber-500\/90 {
  background-color: #f59e0be6;
}

.bg-amber-500\/95 {
  background-color: #f59e0bf2;
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.bg-amber-600\/0 {
  background-color: #d9770600;
}

.bg-amber-600\/10 {
  background-color: #d977061a;
}

.bg-amber-600\/100 {
  background-color: #d97706;
}

.bg-amber-600\/20 {
  background-color: #d9770633;
}

.bg-amber-600\/25 {
  background-color: #d9770640;
}

.bg-amber-600\/30 {
  background-color: #d977064d;
}

.bg-amber-600\/40 {
  background-color: #d9770666;
}

.bg-amber-600\/5 {
  background-color: #d977060d;
}

.bg-amber-600\/50 {
  background-color: #d9770680;
}

.bg-amber-600\/60 {
  background-color: #d9770699;
}

.bg-amber-600\/70 {
  background-color: #d97706b3;
}

.bg-amber-600\/75 {
  background-color: #d97706bf;
}

.bg-amber-600\/80 {
  background-color: #d97706cc;
}

.bg-amber-600\/90 {
  background-color: #d97706e6;
}

.bg-amber-600\/95 {
  background-color: #d97706f2;
}

.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

.bg-amber-700\/0 {
  background-color: #b4530900;
}

.bg-amber-700\/10 {
  background-color: #b453091a;
}

.bg-amber-700\/100 {
  background-color: #b45309;
}

.bg-amber-700\/20 {
  background-color: #b4530933;
}

.bg-amber-700\/25 {
  background-color: #b4530940;
}

.bg-amber-700\/30 {
  background-color: #b453094d;
}

.bg-amber-700\/40 {
  background-color: #b4530966;
}

.bg-amber-700\/5 {
  background-color: #b453090d;
}

.bg-amber-700\/50 {
  background-color: #b4530980;
}

.bg-amber-700\/60 {
  background-color: #b4530999;
}

.bg-amber-700\/70 {
  background-color: #b45309b3;
}

.bg-amber-700\/75 {
  background-color: #b45309bf;
}

.bg-amber-700\/80 {
  background-color: #b45309cc;
}

.bg-amber-700\/90 {
  background-color: #b45309e6;
}

.bg-amber-700\/95 {
  background-color: #b45309f2;
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

.bg-amber-800\/0 {
  background-color: #92400e00;
}

.bg-amber-800\/10 {
  background-color: #92400e1a;
}

.bg-amber-800\/100 {
  background-color: #92400e;
}

.bg-amber-800\/20 {
  background-color: #92400e33;
}

.bg-amber-800\/25 {
  background-color: #92400e40;
}

.bg-amber-800\/30 {
  background-color: #92400e4d;
}

.bg-amber-800\/40 {
  background-color: #92400e66;
}

.bg-amber-800\/5 {
  background-color: #92400e0d;
}

.bg-amber-800\/50 {
  background-color: #92400e80;
}

.bg-amber-800\/60 {
  background-color: #92400e99;
}

.bg-amber-800\/70 {
  background-color: #92400eb3;
}

.bg-amber-800\/75 {
  background-color: #92400ebf;
}

.bg-amber-800\/80 {
  background-color: #92400ecc;
}

.bg-amber-800\/90 {
  background-color: #92400ee6;
}

.bg-amber-800\/95 {
  background-color: #92400ef2;
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}

.bg-amber-900\/0 {
  background-color: #78350f00;
}

.bg-amber-900\/10 {
  background-color: #78350f1a;
}

.bg-amber-900\/100 {
  background-color: #78350f;
}

.bg-amber-900\/20 {
  background-color: #78350f33;
}

.bg-amber-900\/25 {
  background-color: #78350f40;
}

.bg-amber-900\/30 {
  background-color: #78350f4d;
}

.bg-amber-900\/40 {
  background-color: #78350f66;
}

.bg-amber-900\/5 {
  background-color: #78350f0d;
}

.bg-amber-900\/50 {
  background-color: #78350f80;
}

.bg-amber-900\/60 {
  background-color: #78350f99;
}

.bg-amber-900\/70 {
  background-color: #78350fb3;
}

.bg-amber-900\/75 {
  background-color: #78350fbf;
}

.bg-amber-900\/80 {
  background-color: #78350fcc;
}

.bg-amber-900\/90 {
  background-color: #78350fe6;
}

.bg-amber-900\/95 {
  background-color: #78350ff2;
}

.bg-amber-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity));
}

.bg-amber-950\/0 {
  background-color: #451a0300;
}

.bg-amber-950\/10 {
  background-color: #451a031a;
}

.bg-amber-950\/100 {
  background-color: #451a03;
}

.bg-amber-950\/20 {
  background-color: #451a0333;
}

.bg-amber-950\/25 {
  background-color: #451a0340;
}

.bg-amber-950\/30 {
  background-color: #451a034d;
}

.bg-amber-950\/40 {
  background-color: #451a0366;
}

.bg-amber-950\/5 {
  background-color: #451a030d;
}

.bg-amber-950\/50 {
  background-color: #451a0380;
}

.bg-amber-950\/60 {
  background-color: #451a0399;
}

.bg-amber-950\/70 {
  background-color: #451a03b3;
}

.bg-amber-950\/75 {
  background-color: #451a03bf;
}

.bg-amber-950\/80 {
  background-color: #451a03cc;
}

.bg-amber-950\/90 {
  background-color: #451a03e6;
}

.bg-amber-950\/95 {
  background-color: #451a03f2;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--blue) / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-emerald-100\/0 {
  background-color: #d1fae500;
}

.bg-emerald-100\/10 {
  background-color: #d1fae51a;
}

.bg-emerald-100\/100 {
  background-color: #d1fae5;
}

.bg-emerald-100\/20 {
  background-color: #d1fae533;
}

.bg-emerald-100\/25 {
  background-color: #d1fae540;
}

.bg-emerald-100\/30 {
  background-color: #d1fae54d;
}

.bg-emerald-100\/40 {
  background-color: #d1fae566;
}

.bg-emerald-100\/5 {
  background-color: #d1fae50d;
}

.bg-emerald-100\/50 {
  background-color: #d1fae580;
}

.bg-emerald-100\/60 {
  background-color: #d1fae599;
}

.bg-emerald-100\/70 {
  background-color: #d1fae5b3;
}

.bg-emerald-100\/75 {
  background-color: #d1fae5bf;
}

.bg-emerald-100\/80 {
  background-color: #d1fae5cc;
}

.bg-emerald-100\/90 {
  background-color: #d1fae5e6;
}

.bg-emerald-100\/95 {
  background-color: #d1fae5f2;
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

.bg-emerald-200\/0 {
  background-color: #a7f3d000;
}

.bg-emerald-200\/10 {
  background-color: #a7f3d01a;
}

.bg-emerald-200\/100 {
  background-color: #a7f3d0;
}

.bg-emerald-200\/20 {
  background-color: #a7f3d033;
}

.bg-emerald-200\/25 {
  background-color: #a7f3d040;
}

.bg-emerald-200\/30 {
  background-color: #a7f3d04d;
}

.bg-emerald-200\/40 {
  background-color: #a7f3d066;
}

.bg-emerald-200\/5 {
  background-color: #a7f3d00d;
}

.bg-emerald-200\/50 {
  background-color: #a7f3d080;
}

.bg-emerald-200\/60 {
  background-color: #a7f3d099;
}

.bg-emerald-200\/70 {
  background-color: #a7f3d0b3;
}

.bg-emerald-200\/75 {
  background-color: #a7f3d0bf;
}

.bg-emerald-200\/80 {
  background-color: #a7f3d0cc;
}

.bg-emerald-200\/90 {
  background-color: #a7f3d0e6;
}

.bg-emerald-200\/95 {
  background-color: #a7f3d0f2;
}

.bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.bg-emerald-300\/0 {
  background-color: #6ee7b700;
}

.bg-emerald-300\/10 {
  background-color: #6ee7b71a;
}

.bg-emerald-300\/100 {
  background-color: #6ee7b7;
}

.bg-emerald-300\/20 {
  background-color: #6ee7b733;
}

.bg-emerald-300\/25 {
  background-color: #6ee7b740;
}

.bg-emerald-300\/30 {
  background-color: #6ee7b74d;
}

.bg-emerald-300\/40 {
  background-color: #6ee7b766;
}

.bg-emerald-300\/5 {
  background-color: #6ee7b70d;
}

.bg-emerald-300\/50 {
  background-color: #6ee7b780;
}

.bg-emerald-300\/60 {
  background-color: #6ee7b799;
}

.bg-emerald-300\/70 {
  background-color: #6ee7b7b3;
}

.bg-emerald-300\/75 {
  background-color: #6ee7b7bf;
}

.bg-emerald-300\/80 {
  background-color: #6ee7b7cc;
}

.bg-emerald-300\/90 {
  background-color: #6ee7b7e6;
}

.bg-emerald-300\/95 {
  background-color: #6ee7b7f2;
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

.bg-emerald-400\/0 {
  background-color: #34d39900;
}

.bg-emerald-400\/10 {
  background-color: #34d3991a;
}

.bg-emerald-400\/100 {
  background-color: #34d399;
}

.bg-emerald-400\/20 {
  background-color: #34d39933;
}

.bg-emerald-400\/25 {
  background-color: #34d39940;
}

.bg-emerald-400\/30 {
  background-color: #34d3994d;
}

.bg-emerald-400\/40 {
  background-color: #34d39966;
}

.bg-emerald-400\/5 {
  background-color: #34d3990d;
}

.bg-emerald-400\/50 {
  background-color: #34d39980;
}

.bg-emerald-400\/60 {
  background-color: #34d39999;
}

.bg-emerald-400\/70 {
  background-color: #34d399b3;
}

.bg-emerald-400\/75 {
  background-color: #34d399bf;
}

.bg-emerald-400\/80 {
  background-color: #34d399cc;
}

.bg-emerald-400\/90 {
  background-color: #34d399e6;
}

.bg-emerald-400\/95 {
  background-color: #34d399f2;
}

.bg-emerald-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

.bg-emerald-50\/0 {
  background-color: #ecfdf500;
}

.bg-emerald-50\/10 {
  background-color: #ecfdf51a;
}

.bg-emerald-50\/100 {
  background-color: #ecfdf5;
}

.bg-emerald-50\/20 {
  background-color: #ecfdf533;
}

.bg-emerald-50\/25 {
  background-color: #ecfdf540;
}

.bg-emerald-50\/30 {
  background-color: #ecfdf54d;
}

.bg-emerald-50\/40 {
  background-color: #ecfdf566;
}

.bg-emerald-50\/5 {
  background-color: #ecfdf50d;
}

.bg-emerald-50\/50 {
  background-color: #ecfdf580;
}

.bg-emerald-50\/60 {
  background-color: #ecfdf599;
}

.bg-emerald-50\/70 {
  background-color: #ecfdf5b3;
}

.bg-emerald-50\/75 {
  background-color: #ecfdf5bf;
}

.bg-emerald-50\/80 {
  background-color: #ecfdf5cc;
}

.bg-emerald-50\/90 {
  background-color: #ecfdf5e6;
}

.bg-emerald-50\/95 {
  background-color: #ecfdf5f2;
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-emerald-500\/0 {
  background-color: #10b98100;
}

.bg-emerald-500\/10 {
  background-color: #10b9811a;
}

.bg-emerald-500\/100 {
  background-color: #10b981;
}

.bg-emerald-500\/20 {
  background-color: #10b98133;
}

.bg-emerald-500\/25 {
  background-color: #10b98140;
}

.bg-emerald-500\/30 {
  background-color: #10b9814d;
}

.bg-emerald-500\/40 {
  background-color: #10b98166;
}

.bg-emerald-500\/5 {
  background-color: #10b9810d;
}

.bg-emerald-500\/50 {
  background-color: #10b98180;
}

.bg-emerald-500\/60 {
  background-color: #10b98199;
}

.bg-emerald-500\/70 {
  background-color: #10b981b3;
}

.bg-emerald-500\/75 {
  background-color: #10b981bf;
}

.bg-emerald-500\/80 {
  background-color: #10b981cc;
}

.bg-emerald-500\/90 {
  background-color: #10b981e6;
}

.bg-emerald-500\/95 {
  background-color: #10b981f2;
}

.bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

.bg-emerald-600\/0 {
  background-color: #05966900;
}

.bg-emerald-600\/10 {
  background-color: #0596691a;
}

.bg-emerald-600\/100 {
  background-color: #059669;
}

.bg-emerald-600\/20 {
  background-color: #05966933;
}

.bg-emerald-600\/25 {
  background-color: #05966940;
}

.bg-emerald-600\/30 {
  background-color: #0596694d;
}

.bg-emerald-600\/40 {
  background-color: #05966966;
}

.bg-emerald-600\/5 {
  background-color: #0596690d;
}

.bg-emerald-600\/50 {
  background-color: #05966980;
}

.bg-emerald-600\/60 {
  background-color: #05966999;
}

.bg-emerald-600\/70 {
  background-color: #059669b3;
}

.bg-emerald-600\/75 {
  background-color: #059669bf;
}

.bg-emerald-600\/80 {
  background-color: #059669cc;
}

.bg-emerald-600\/90 {
  background-color: #059669e6;
}

.bg-emerald-600\/95 {
  background-color: #059669f2;
}

.bg-emerald-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}

.bg-emerald-700\/0 {
  background-color: #04785700;
}

.bg-emerald-700\/10 {
  background-color: #0478571a;
}

.bg-emerald-700\/100 {
  background-color: #047857;
}

.bg-emerald-700\/20 {
  background-color: #04785733;
}

.bg-emerald-700\/25 {
  background-color: #04785740;
}

.bg-emerald-700\/30 {
  background-color: #0478574d;
}

.bg-emerald-700\/40 {
  background-color: #04785766;
}

.bg-emerald-700\/5 {
  background-color: #0478570d;
}

.bg-emerald-700\/50 {
  background-color: #04785780;
}

.bg-emerald-700\/60 {
  background-color: #04785799;
}

.bg-emerald-700\/70 {
  background-color: #047857b3;
}

.bg-emerald-700\/75 {
  background-color: #047857bf;
}

.bg-emerald-700\/80 {
  background-color: #047857cc;
}

.bg-emerald-700\/90 {
  background-color: #047857e6;
}

.bg-emerald-700\/95 {
  background-color: #047857f2;
}

.bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}

.bg-emerald-800\/0 {
  background-color: #065f4600;
}

.bg-emerald-800\/10 {
  background-color: #065f461a;
}

.bg-emerald-800\/100 {
  background-color: #065f46;
}

.bg-emerald-800\/20 {
  background-color: #065f4633;
}

.bg-emerald-800\/25 {
  background-color: #065f4640;
}

.bg-emerald-800\/30 {
  background-color: #065f464d;
}

.bg-emerald-800\/40 {
  background-color: #065f4666;
}

.bg-emerald-800\/5 {
  background-color: #065f460d;
}

.bg-emerald-800\/50 {
  background-color: #065f4680;
}

.bg-emerald-800\/60 {
  background-color: #065f4699;
}

.bg-emerald-800\/70 {
  background-color: #065f46b3;
}

.bg-emerald-800\/75 {
  background-color: #065f46bf;
}

.bg-emerald-800\/80 {
  background-color: #065f46cc;
}

.bg-emerald-800\/90 {
  background-color: #065f46e6;
}

.bg-emerald-800\/95 {
  background-color: #065f46f2;
}

.bg-emerald-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}

.bg-emerald-900\/0 {
  background-color: #064e3b00;
}

.bg-emerald-900\/10 {
  background-color: #064e3b1a;
}

.bg-emerald-900\/100 {
  background-color: #064e3b;
}

.bg-emerald-900\/20 {
  background-color: #064e3b33;
}

.bg-emerald-900\/25 {
  background-color: #064e3b40;
}

.bg-emerald-900\/30 {
  background-color: #064e3b4d;
}

.bg-emerald-900\/40 {
  background-color: #064e3b66;
}

.bg-emerald-900\/5 {
  background-color: #064e3b0d;
}

.bg-emerald-900\/50 {
  background-color: #064e3b80;
}

.bg-emerald-900\/60 {
  background-color: #064e3b99;
}

.bg-emerald-900\/70 {
  background-color: #064e3bb3;
}

.bg-emerald-900\/75 {
  background-color: #064e3bbf;
}

.bg-emerald-900\/80 {
  background-color: #064e3bcc;
}

.bg-emerald-900\/90 {
  background-color: #064e3be6;
}

.bg-emerald-900\/95 {
  background-color: #064e3bf2;
}

.bg-emerald-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 44 34 / var(--tw-bg-opacity));
}

.bg-emerald-950\/0 {
  background-color: #022c2200;
}

.bg-emerald-950\/10 {
  background-color: #022c221a;
}

.bg-emerald-950\/100 {
  background-color: #022c22;
}

.bg-emerald-950\/20 {
  background-color: #022c2233;
}

.bg-emerald-950\/25 {
  background-color: #022c2240;
}

.bg-emerald-950\/30 {
  background-color: #022c224d;
}

.bg-emerald-950\/40 {
  background-color: #022c2266;
}

.bg-emerald-950\/5 {
  background-color: #022c220d;
}

.bg-emerald-950\/50 {
  background-color: #022c2280;
}

.bg-emerald-950\/60 {
  background-color: #022c2299;
}

.bg-emerald-950\/70 {
  background-color: #022c22b3;
}

.bg-emerald-950\/75 {
  background-color: #022c22bf;
}

.bg-emerald-950\/80 {
  background-color: #022c22cc;
}

.bg-emerald-950\/90 {
  background-color: #022c22e6;
}

.bg-emerald-950\/95 {
  background-color: #022c22f2;
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--green) / var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.bg-lime-100\/0 {
  background-color: #ecfccb00;
}

.bg-lime-100\/10 {
  background-color: #ecfccb1a;
}

.bg-lime-100\/100 {
  background-color: #ecfccb;
}

.bg-lime-100\/20 {
  background-color: #ecfccb33;
}

.bg-lime-100\/25 {
  background-color: #ecfccb40;
}

.bg-lime-100\/30 {
  background-color: #ecfccb4d;
}

.bg-lime-100\/40 {
  background-color: #ecfccb66;
}

.bg-lime-100\/5 {
  background-color: #ecfccb0d;
}

.bg-lime-100\/50 {
  background-color: #ecfccb80;
}

.bg-lime-100\/60 {
  background-color: #ecfccb99;
}

.bg-lime-100\/70 {
  background-color: #ecfccbb3;
}

.bg-lime-100\/75 {
  background-color: #ecfccbbf;
}

.bg-lime-100\/80 {
  background-color: #ecfccbcc;
}

.bg-lime-100\/90 {
  background-color: #ecfccbe6;
}

.bg-lime-100\/95 {
  background-color: #ecfccbf2;
}

.bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.bg-lime-200\/0 {
  background-color: #d9f99d00;
}

.bg-lime-200\/10 {
  background-color: #d9f99d1a;
}

.bg-lime-200\/100 {
  background-color: #d9f99d;
}

.bg-lime-200\/20 {
  background-color: #d9f99d33;
}

.bg-lime-200\/25 {
  background-color: #d9f99d40;
}

.bg-lime-200\/30 {
  background-color: #d9f99d4d;
}

.bg-lime-200\/40 {
  background-color: #d9f99d66;
}

.bg-lime-200\/5 {
  background-color: #d9f99d0d;
}

.bg-lime-200\/50 {
  background-color: #d9f99d80;
}

.bg-lime-200\/60 {
  background-color: #d9f99d99;
}

.bg-lime-200\/70 {
  background-color: #d9f99db3;
}

.bg-lime-200\/75 {
  background-color: #d9f99dbf;
}

.bg-lime-200\/80 {
  background-color: #d9f99dcc;
}

.bg-lime-200\/90 {
  background-color: #d9f99de6;
}

.bg-lime-200\/95 {
  background-color: #d9f99df2;
}

.bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.bg-lime-300\/0 {
  background-color: #bef26400;
}

.bg-lime-300\/10 {
  background-color: #bef2641a;
}

.bg-lime-300\/100 {
  background-color: #bef264;
}

.bg-lime-300\/20 {
  background-color: #bef26433;
}

.bg-lime-300\/25 {
  background-color: #bef26440;
}

.bg-lime-300\/30 {
  background-color: #bef2644d;
}

.bg-lime-300\/40 {
  background-color: #bef26466;
}

.bg-lime-300\/5 {
  background-color: #bef2640d;
}

.bg-lime-300\/50 {
  background-color: #bef26480;
}

.bg-lime-300\/60 {
  background-color: #bef26499;
}

.bg-lime-300\/70 {
  background-color: #bef264b3;
}

.bg-lime-300\/75 {
  background-color: #bef264bf;
}

.bg-lime-300\/80 {
  background-color: #bef264cc;
}

.bg-lime-300\/90 {
  background-color: #bef264e6;
}

.bg-lime-300\/95 {
  background-color: #bef264f2;
}

.bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.bg-lime-400\/0 {
  background-color: #a3e63500;
}

.bg-lime-400\/10 {
  background-color: #a3e6351a;
}

.bg-lime-400\/100 {
  background-color: #a3e635;
}

.bg-lime-400\/20 {
  background-color: #a3e63533;
}

.bg-lime-400\/25 {
  background-color: #a3e63540;
}

.bg-lime-400\/30 {
  background-color: #a3e6354d;
}

.bg-lime-400\/40 {
  background-color: #a3e63566;
}

.bg-lime-400\/5 {
  background-color: #a3e6350d;
}

.bg-lime-400\/50 {
  background-color: #a3e63580;
}

.bg-lime-400\/60 {
  background-color: #a3e63599;
}

.bg-lime-400\/70 {
  background-color: #a3e635b3;
}

.bg-lime-400\/75 {
  background-color: #a3e635bf;
}

.bg-lime-400\/80 {
  background-color: #a3e635cc;
}

.bg-lime-400\/90 {
  background-color: #a3e635e6;
}

.bg-lime-400\/95 {
  background-color: #a3e635f2;
}

.bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.bg-lime-50\/0 {
  background-color: #f7fee700;
}

.bg-lime-50\/10 {
  background-color: #f7fee71a;
}

.bg-lime-50\/100 {
  background-color: #f7fee7;
}

.bg-lime-50\/20 {
  background-color: #f7fee733;
}

.bg-lime-50\/25 {
  background-color: #f7fee740;
}

.bg-lime-50\/30 {
  background-color: #f7fee74d;
}

.bg-lime-50\/40 {
  background-color: #f7fee766;
}

.bg-lime-50\/5 {
  background-color: #f7fee70d;
}

.bg-lime-50\/50 {
  background-color: #f7fee780;
}

.bg-lime-50\/60 {
  background-color: #f7fee799;
}

.bg-lime-50\/70 {
  background-color: #f7fee7b3;
}

.bg-lime-50\/75 {
  background-color: #f7fee7bf;
}

.bg-lime-50\/80 {
  background-color: #f7fee7cc;
}

.bg-lime-50\/90 {
  background-color: #f7fee7e6;
}

.bg-lime-50\/95 {
  background-color: #f7fee7f2;
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.bg-lime-500\/0 {
  background-color: #84cc1600;
}

.bg-lime-500\/10 {
  background-color: #84cc161a;
}

.bg-lime-500\/100 {
  background-color: #84cc16;
}

.bg-lime-500\/20 {
  background-color: #84cc1633;
}

.bg-lime-500\/25 {
  background-color: #84cc1640;
}

.bg-lime-500\/30 {
  background-color: #84cc164d;
}

.bg-lime-500\/40 {
  background-color: #84cc1666;
}

.bg-lime-500\/5 {
  background-color: #84cc160d;
}

.bg-lime-500\/50 {
  background-color: #84cc1680;
}

.bg-lime-500\/60 {
  background-color: #84cc1699;
}

.bg-lime-500\/70 {
  background-color: #84cc16b3;
}

.bg-lime-500\/75 {
  background-color: #84cc16bf;
}

.bg-lime-500\/80 {
  background-color: #84cc16cc;
}

.bg-lime-500\/90 {
  background-color: #84cc16e6;
}

.bg-lime-500\/95 {
  background-color: #84cc16f2;
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.bg-lime-600\/0 {
  background-color: #65a30d00;
}

.bg-lime-600\/10 {
  background-color: #65a30d1a;
}

.bg-lime-600\/100 {
  background-color: #65a30d;
}

.bg-lime-600\/20 {
  background-color: #65a30d33;
}

.bg-lime-600\/25 {
  background-color: #65a30d40;
}

.bg-lime-600\/30 {
  background-color: #65a30d4d;
}

.bg-lime-600\/40 {
  background-color: #65a30d66;
}

.bg-lime-600\/5 {
  background-color: #65a30d0d;
}

.bg-lime-600\/50 {
  background-color: #65a30d80;
}

.bg-lime-600\/60 {
  background-color: #65a30d99;
}

.bg-lime-600\/70 {
  background-color: #65a30db3;
}

.bg-lime-600\/75 {
  background-color: #65a30dbf;
}

.bg-lime-600\/80 {
  background-color: #65a30dcc;
}

.bg-lime-600\/90 {
  background-color: #65a30de6;
}

.bg-lime-600\/95 {
  background-color: #65a30df2;
}

.bg-lime-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

.bg-lime-700\/0 {
  background-color: #4d7c0f00;
}

.bg-lime-700\/10 {
  background-color: #4d7c0f1a;
}

.bg-lime-700\/100 {
  background-color: #4d7c0f;
}

.bg-lime-700\/20 {
  background-color: #4d7c0f33;
}

.bg-lime-700\/25 {
  background-color: #4d7c0f40;
}

.bg-lime-700\/30 {
  background-color: #4d7c0f4d;
}

.bg-lime-700\/40 {
  background-color: #4d7c0f66;
}

.bg-lime-700\/5 {
  background-color: #4d7c0f0d;
}

.bg-lime-700\/50 {
  background-color: #4d7c0f80;
}

.bg-lime-700\/60 {
  background-color: #4d7c0f99;
}

.bg-lime-700\/70 {
  background-color: #4d7c0fb3;
}

.bg-lime-700\/75 {
  background-color: #4d7c0fbf;
}

.bg-lime-700\/80 {
  background-color: #4d7c0fcc;
}

.bg-lime-700\/90 {
  background-color: #4d7c0fe6;
}

.bg-lime-700\/95 {
  background-color: #4d7c0ff2;
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}

.bg-lime-800\/0 {
  background-color: #3f621200;
}

.bg-lime-800\/10 {
  background-color: #3f62121a;
}

.bg-lime-800\/100 {
  background-color: #3f6212;
}

.bg-lime-800\/20 {
  background-color: #3f621233;
}

.bg-lime-800\/25 {
  background-color: #3f621240;
}

.bg-lime-800\/30 {
  background-color: #3f62124d;
}

.bg-lime-800\/40 {
  background-color: #3f621266;
}

.bg-lime-800\/5 {
  background-color: #3f62120d;
}

.bg-lime-800\/50 {
  background-color: #3f621280;
}

.bg-lime-800\/60 {
  background-color: #3f621299;
}

.bg-lime-800\/70 {
  background-color: #3f6212b3;
}

.bg-lime-800\/75 {
  background-color: #3f6212bf;
}

.bg-lime-800\/80 {
  background-color: #3f6212cc;
}

.bg-lime-800\/90 {
  background-color: #3f6212e6;
}

.bg-lime-800\/95 {
  background-color: #3f6212f2;
}

.bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}

.bg-lime-900\/0 {
  background-color: #36531400;
}

.bg-lime-900\/10 {
  background-color: #3653141a;
}

.bg-lime-900\/100 {
  background-color: #365314;
}

.bg-lime-900\/20 {
  background-color: #36531433;
}

.bg-lime-900\/25 {
  background-color: #36531440;
}

.bg-lime-900\/30 {
  background-color: #3653144d;
}

.bg-lime-900\/40 {
  background-color: #36531466;
}

.bg-lime-900\/5 {
  background-color: #3653140d;
}

.bg-lime-900\/50 {
  background-color: #36531480;
}

.bg-lime-900\/60 {
  background-color: #36531499;
}

.bg-lime-900\/70 {
  background-color: #365314b3;
}

.bg-lime-900\/75 {
  background-color: #365314bf;
}

.bg-lime-900\/80 {
  background-color: #365314cc;
}

.bg-lime-900\/90 {
  background-color: #365314e6;
}

.bg-lime-900\/95 {
  background-color: #365314f2;
}

.bg-lime-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 46 5 / var(--tw-bg-opacity));
}

.bg-lime-950\/0 {
  background-color: #1a2e0500;
}

.bg-lime-950\/10 {
  background-color: #1a2e051a;
}

.bg-lime-950\/100 {
  background-color: #1a2e05;
}

.bg-lime-950\/20 {
  background-color: #1a2e0533;
}

.bg-lime-950\/25 {
  background-color: #1a2e0540;
}

.bg-lime-950\/30 {
  background-color: #1a2e054d;
}

.bg-lime-950\/40 {
  background-color: #1a2e0566;
}

.bg-lime-950\/5 {
  background-color: #1a2e050d;
}

.bg-lime-950\/50 {
  background-color: #1a2e0580;
}

.bg-lime-950\/60 {
  background-color: #1a2e0599;
}

.bg-lime-950\/70 {
  background-color: #1a2e05b3;
}

.bg-lime-950\/75 {
  background-color: #1a2e05bf;
}

.bg-lime-950\/80 {
  background-color: #1a2e05cc;
}

.bg-lime-950\/90 {
  background-color: #1a2e05e6;
}

.bg-lime-950\/95 {
  background-color: #1a2e05f2;
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

.bg-pink-100\/0 {
  background-color: #fce7f300;
}

.bg-pink-100\/10 {
  background-color: #fce7f31a;
}

.bg-pink-100\/100 {
  background-color: #fce7f3;
}

.bg-pink-100\/20 {
  background-color: #fce7f333;
}

.bg-pink-100\/25 {
  background-color: #fce7f340;
}

.bg-pink-100\/30 {
  background-color: #fce7f34d;
}

.bg-pink-100\/40 {
  background-color: #fce7f366;
}

.bg-pink-100\/5 {
  background-color: #fce7f30d;
}

.bg-pink-100\/50 {
  background-color: #fce7f380;
}

.bg-pink-100\/60 {
  background-color: #fce7f399;
}

.bg-pink-100\/70 {
  background-color: #fce7f3b3;
}

.bg-pink-100\/75 {
  background-color: #fce7f3bf;
}

.bg-pink-100\/80 {
  background-color: #fce7f3cc;
}

.bg-pink-100\/90 {
  background-color: #fce7f3e6;
}

.bg-pink-100\/95 {
  background-color: #fce7f3f2;
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.bg-pink-200\/0 {
  background-color: #fbcfe800;
}

.bg-pink-200\/10 {
  background-color: #fbcfe81a;
}

.bg-pink-200\/100 {
  background-color: #fbcfe8;
}

.bg-pink-200\/20 {
  background-color: #fbcfe833;
}

.bg-pink-200\/25 {
  background-color: #fbcfe840;
}

.bg-pink-200\/30 {
  background-color: #fbcfe84d;
}

.bg-pink-200\/40 {
  background-color: #fbcfe866;
}

.bg-pink-200\/5 {
  background-color: #fbcfe80d;
}

.bg-pink-200\/50 {
  background-color: #fbcfe880;
}

.bg-pink-200\/60 {
  background-color: #fbcfe899;
}

.bg-pink-200\/70 {
  background-color: #fbcfe8b3;
}

.bg-pink-200\/75 {
  background-color: #fbcfe8bf;
}

.bg-pink-200\/80 {
  background-color: #fbcfe8cc;
}

.bg-pink-200\/90 {
  background-color: #fbcfe8e6;
}

.bg-pink-200\/95 {
  background-color: #fbcfe8f2;
}

.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

.bg-pink-300\/0 {
  background-color: #f9a8d400;
}

.bg-pink-300\/10 {
  background-color: #f9a8d41a;
}

.bg-pink-300\/100 {
  background-color: #f9a8d4;
}

.bg-pink-300\/20 {
  background-color: #f9a8d433;
}

.bg-pink-300\/25 {
  background-color: #f9a8d440;
}

.bg-pink-300\/30 {
  background-color: #f9a8d44d;
}

.bg-pink-300\/40 {
  background-color: #f9a8d466;
}

.bg-pink-300\/5 {
  background-color: #f9a8d40d;
}

.bg-pink-300\/50 {
  background-color: #f9a8d480;
}

.bg-pink-300\/60 {
  background-color: #f9a8d499;
}

.bg-pink-300\/70 {
  background-color: #f9a8d4b3;
}

.bg-pink-300\/75 {
  background-color: #f9a8d4bf;
}

.bg-pink-300\/80 {
  background-color: #f9a8d4cc;
}

.bg-pink-300\/90 {
  background-color: #f9a8d4e6;
}

.bg-pink-300\/95 {
  background-color: #f9a8d4f2;
}

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}

.bg-pink-400\/0 {
  background-color: #f472b600;
}

.bg-pink-400\/10 {
  background-color: #f472b61a;
}

.bg-pink-400\/100 {
  background-color: #f472b6;
}

.bg-pink-400\/20 {
  background-color: #f472b633;
}

.bg-pink-400\/25 {
  background-color: #f472b640;
}

.bg-pink-400\/30 {
  background-color: #f472b64d;
}

.bg-pink-400\/40 {
  background-color: #f472b666;
}

.bg-pink-400\/5 {
  background-color: #f472b60d;
}

.bg-pink-400\/50 {
  background-color: #f472b680;
}

.bg-pink-400\/60 {
  background-color: #f472b699;
}

.bg-pink-400\/70 {
  background-color: #f472b6b3;
}

.bg-pink-400\/75 {
  background-color: #f472b6bf;
}

.bg-pink-400\/80 {
  background-color: #f472b6cc;
}

.bg-pink-400\/90 {
  background-color: #f472b6e6;
}

.bg-pink-400\/95 {
  background-color: #f472b6f2;
}

.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}

.bg-pink-50\/0 {
  background-color: #fdf2f800;
}

.bg-pink-50\/10 {
  background-color: #fdf2f81a;
}

.bg-pink-50\/100 {
  background-color: #fdf2f8;
}

.bg-pink-50\/20 {
  background-color: #fdf2f833;
}

.bg-pink-50\/25 {
  background-color: #fdf2f840;
}

.bg-pink-50\/30 {
  background-color: #fdf2f84d;
}

.bg-pink-50\/40 {
  background-color: #fdf2f866;
}

.bg-pink-50\/5 {
  background-color: #fdf2f80d;
}

.bg-pink-50\/50 {
  background-color: #fdf2f880;
}

.bg-pink-50\/60 {
  background-color: #fdf2f899;
}

.bg-pink-50\/70 {
  background-color: #fdf2f8b3;
}

.bg-pink-50\/75 {
  background-color: #fdf2f8bf;
}

.bg-pink-50\/80 {
  background-color: #fdf2f8cc;
}

.bg-pink-50\/90 {
  background-color: #fdf2f8e6;
}

.bg-pink-50\/95 {
  background-color: #fdf2f8f2;
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}

.bg-pink-500\/0 {
  background-color: #ec489900;
}

.bg-pink-500\/10 {
  background-color: #ec48991a;
}

.bg-pink-500\/100 {
  background-color: #ec4899;
}

.bg-pink-500\/20 {
  background-color: #ec489933;
}

.bg-pink-500\/25 {
  background-color: #ec489940;
}

.bg-pink-500\/30 {
  background-color: #ec48994d;
}

.bg-pink-500\/40 {
  background-color: #ec489966;
}

.bg-pink-500\/5 {
  background-color: #ec48990d;
}

.bg-pink-500\/50 {
  background-color: #ec489980;
}

.bg-pink-500\/60 {
  background-color: #ec489999;
}

.bg-pink-500\/70 {
  background-color: #ec4899b3;
}

.bg-pink-500\/75 {
  background-color: #ec4899bf;
}

.bg-pink-500\/80 {
  background-color: #ec4899cc;
}

.bg-pink-500\/90 {
  background-color: #ec4899e6;
}

.bg-pink-500\/95 {
  background-color: #ec4899f2;
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.bg-pink-600\/0 {
  background-color: #db277700;
}

.bg-pink-600\/10 {
  background-color: #db27771a;
}

.bg-pink-600\/100 {
  background-color: #db2777;
}

.bg-pink-600\/20 {
  background-color: #db277733;
}

.bg-pink-600\/25 {
  background-color: #db277740;
}

.bg-pink-600\/30 {
  background-color: #db27774d;
}

.bg-pink-600\/40 {
  background-color: #db277766;
}

.bg-pink-600\/5 {
  background-color: #db27770d;
}

.bg-pink-600\/50 {
  background-color: #db277780;
}

.bg-pink-600\/60 {
  background-color: #db277799;
}

.bg-pink-600\/70 {
  background-color: #db2777b3;
}

.bg-pink-600\/75 {
  background-color: #db2777bf;
}

.bg-pink-600\/80 {
  background-color: #db2777cc;
}

.bg-pink-600\/90 {
  background-color: #db2777e6;
}

.bg-pink-600\/95 {
  background-color: #db2777f2;
}

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

.bg-pink-700\/0 {
  background-color: #be185d00;
}

.bg-pink-700\/10 {
  background-color: #be185d1a;
}

.bg-pink-700\/100 {
  background-color: #be185d;
}

.bg-pink-700\/20 {
  background-color: #be185d33;
}

.bg-pink-700\/25 {
  background-color: #be185d40;
}

.bg-pink-700\/30 {
  background-color: #be185d4d;
}

.bg-pink-700\/40 {
  background-color: #be185d66;
}

.bg-pink-700\/5 {
  background-color: #be185d0d;
}

.bg-pink-700\/50 {
  background-color: #be185d80;
}

.bg-pink-700\/60 {
  background-color: #be185d99;
}

.bg-pink-700\/70 {
  background-color: #be185db3;
}

.bg-pink-700\/75 {
  background-color: #be185dbf;
}

.bg-pink-700\/80 {
  background-color: #be185dcc;
}

.bg-pink-700\/90 {
  background-color: #be185de6;
}

.bg-pink-700\/95 {
  background-color: #be185df2;
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity));
}

.bg-pink-800\/0 {
  background-color: #9d174d00;
}

.bg-pink-800\/10 {
  background-color: #9d174d1a;
}

.bg-pink-800\/100 {
  background-color: #9d174d;
}

.bg-pink-800\/20 {
  background-color: #9d174d33;
}

.bg-pink-800\/25 {
  background-color: #9d174d40;
}

.bg-pink-800\/30 {
  background-color: #9d174d4d;
}

.bg-pink-800\/40 {
  background-color: #9d174d66;
}

.bg-pink-800\/5 {
  background-color: #9d174d0d;
}

.bg-pink-800\/50 {
  background-color: #9d174d80;
}

.bg-pink-800\/60 {
  background-color: #9d174d99;
}

.bg-pink-800\/70 {
  background-color: #9d174db3;
}

.bg-pink-800\/75 {
  background-color: #9d174dbf;
}

.bg-pink-800\/80 {
  background-color: #9d174dcc;
}

.bg-pink-800\/90 {
  background-color: #9d174de6;
}

.bg-pink-800\/95 {
  background-color: #9d174df2;
}

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}

.bg-pink-900\/0 {
  background-color: #83184300;
}

.bg-pink-900\/10 {
  background-color: #8318431a;
}

.bg-pink-900\/100 {
  background-color: #831843;
}

.bg-pink-900\/20 {
  background-color: #83184333;
}

.bg-pink-900\/25 {
  background-color: #83184340;
}

.bg-pink-900\/30 {
  background-color: #8318434d;
}

.bg-pink-900\/40 {
  background-color: #83184366;
}

.bg-pink-900\/5 {
  background-color: #8318430d;
}

.bg-pink-900\/50 {
  background-color: #83184380;
}

.bg-pink-900\/60 {
  background-color: #83184399;
}

.bg-pink-900\/70 {
  background-color: #831843b3;
}

.bg-pink-900\/75 {
  background-color: #831843bf;
}

.bg-pink-900\/80 {
  background-color: #831843cc;
}

.bg-pink-900\/90 {
  background-color: #831843e6;
}

.bg-pink-900\/95 {
  background-color: #831843f2;
}

.bg-pink-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(80 7 36 / var(--tw-bg-opacity));
}

.bg-pink-950\/0 {
  background-color: #50072400;
}

.bg-pink-950\/10 {
  background-color: #5007241a;
}

.bg-pink-950\/100 {
  background-color: #500724;
}

.bg-pink-950\/20 {
  background-color: #50072433;
}

.bg-pink-950\/25 {
  background-color: #50072440;
}

.bg-pink-950\/30 {
  background-color: #5007244d;
}

.bg-pink-950\/40 {
  background-color: #50072466;
}

.bg-pink-950\/5 {
  background-color: #5007240d;
}

.bg-pink-950\/50 {
  background-color: #50072480;
}

.bg-pink-950\/60 {
  background-color: #50072499;
}

.bg-pink-950\/70 {
  background-color: #500724b3;
}

.bg-pink-950\/75 {
  background-color: #500724bf;
}

.bg-pink-950\/80 {
  background-color: #500724cc;
}

.bg-pink-950\/90 {
  background-color: #500724e6;
}

.bg-pink-950\/95 {
  background-color: #500724f2;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
}

.bg-primary\/0 {
  background-color: hsl(var(--primary) / 0);
}

.bg-primary\/10 {
  background-color: hsl(var(--primary) / .1);
}

.bg-primary\/100 {
  background-color: hsl(var(--primary) / 1);
}

.bg-primary\/20 {
  background-color: hsl(var(--primary) / .2);
}

.bg-primary\/25 {
  background-color: hsl(var(--primary) / .25);
}

.bg-primary\/30 {
  background-color: hsl(var(--primary) / .3);
}

.bg-primary\/40 {
  background-color: hsl(var(--primary) / .4);
}

.bg-primary\/5 {
  background-color: hsl(var(--primary) / .05);
}

.bg-primary\/50 {
  background-color: hsl(var(--primary) / .5);
}

.bg-primary\/60 {
  background-color: hsl(var(--primary) / .6);
}

.bg-primary\/70 {
  background-color: hsl(var(--primary) / .7);
}

.bg-primary\/75 {
  background-color: hsl(var(--primary) / .75);
}

.bg-primary\/80 {
  background-color: hsl(var(--primary) / .8);
}

.bg-primary\/90 {
  background-color: hsl(var(--primary) / .9);
}

.bg-primary\/95 {
  background-color: hsl(var(--primary) / .95);
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.bg-purple-100\/0 {
  background-color: #f3e8ff00;
}

.bg-purple-100\/10 {
  background-color: #f3e8ff1a;
}

.bg-purple-100\/100 {
  background-color: #f3e8ff;
}

.bg-purple-100\/20 {
  background-color: #f3e8ff33;
}

.bg-purple-100\/25 {
  background-color: #f3e8ff40;
}

.bg-purple-100\/30 {
  background-color: #f3e8ff4d;
}

.bg-purple-100\/40 {
  background-color: #f3e8ff66;
}

.bg-purple-100\/5 {
  background-color: #f3e8ff0d;
}

.bg-purple-100\/50 {
  background-color: #f3e8ff80;
}

.bg-purple-100\/60 {
  background-color: #f3e8ff99;
}

.bg-purple-100\/70 {
  background-color: #f3e8ffb3;
}

.bg-purple-100\/75 {
  background-color: #f3e8ffbf;
}

.bg-purple-100\/80 {
  background-color: #f3e8ffcc;
}

.bg-purple-100\/90 {
  background-color: #f3e8ffe6;
}

.bg-purple-100\/95 {
  background-color: #f3e8fff2;
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.bg-purple-200\/0 {
  background-color: #e9d5ff00;
}

.bg-purple-200\/10 {
  background-color: #e9d5ff1a;
}

.bg-purple-200\/100 {
  background-color: #e9d5ff;
}

.bg-purple-200\/20 {
  background-color: #e9d5ff33;
}

.bg-purple-200\/25 {
  background-color: #e9d5ff40;
}

.bg-purple-200\/30 {
  background-color: #e9d5ff4d;
}

.bg-purple-200\/40 {
  background-color: #e9d5ff66;
}

.bg-purple-200\/5 {
  background-color: #e9d5ff0d;
}

.bg-purple-200\/50 {
  background-color: #e9d5ff80;
}

.bg-purple-200\/60 {
  background-color: #e9d5ff99;
}

.bg-purple-200\/70 {
  background-color: #e9d5ffb3;
}

.bg-purple-200\/75 {
  background-color: #e9d5ffbf;
}

.bg-purple-200\/80 {
  background-color: #e9d5ffcc;
}

.bg-purple-200\/90 {
  background-color: #e9d5ffe6;
}

.bg-purple-200\/95 {
  background-color: #e9d5fff2;
}

.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

.bg-purple-300\/0 {
  background-color: #d8b4fe00;
}

.bg-purple-300\/10 {
  background-color: #d8b4fe1a;
}

.bg-purple-300\/100 {
  background-color: #d8b4fe;
}

.bg-purple-300\/20 {
  background-color: #d8b4fe33;
}

.bg-purple-300\/25 {
  background-color: #d8b4fe40;
}

.bg-purple-300\/30 {
  background-color: #d8b4fe4d;
}

.bg-purple-300\/40 {
  background-color: #d8b4fe66;
}

.bg-purple-300\/5 {
  background-color: #d8b4fe0d;
}

.bg-purple-300\/50 {
  background-color: #d8b4fe80;
}

.bg-purple-300\/60 {
  background-color: #d8b4fe99;
}

.bg-purple-300\/70 {
  background-color: #d8b4feb3;
}

.bg-purple-300\/75 {
  background-color: #d8b4febf;
}

.bg-purple-300\/80 {
  background-color: #d8b4fecc;
}

.bg-purple-300\/90 {
  background-color: #d8b4fee6;
}

.bg-purple-300\/95 {
  background-color: #d8b4fef2;
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.bg-purple-400\/0 {
  background-color: #c084fc00;
}

.bg-purple-400\/10 {
  background-color: #c084fc1a;
}

.bg-purple-400\/100 {
  background-color: #c084fc;
}

.bg-purple-400\/20 {
  background-color: #c084fc33;
}

.bg-purple-400\/25 {
  background-color: #c084fc40;
}

.bg-purple-400\/30 {
  background-color: #c084fc4d;
}

.bg-purple-400\/40 {
  background-color: #c084fc66;
}

.bg-purple-400\/5 {
  background-color: #c084fc0d;
}

.bg-purple-400\/50 {
  background-color: #c084fc80;
}

.bg-purple-400\/60 {
  background-color: #c084fc99;
}

.bg-purple-400\/70 {
  background-color: #c084fcb3;
}

.bg-purple-400\/75 {
  background-color: #c084fcbf;
}

.bg-purple-400\/80 {
  background-color: #c084fccc;
}

.bg-purple-400\/90 {
  background-color: #c084fce6;
}

.bg-purple-400\/95 {
  background-color: #c084fcf2;
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.bg-purple-50\/0 {
  background-color: #faf5ff00;
}

.bg-purple-50\/10 {
  background-color: #faf5ff1a;
}

.bg-purple-50\/100 {
  background-color: #faf5ff;
}

.bg-purple-50\/20 {
  background-color: #faf5ff33;
}

.bg-purple-50\/25 {
  background-color: #faf5ff40;
}

.bg-purple-50\/30 {
  background-color: #faf5ff4d;
}

.bg-purple-50\/40 {
  background-color: #faf5ff66;
}

.bg-purple-50\/5 {
  background-color: #faf5ff0d;
}

.bg-purple-50\/50 {
  background-color: #faf5ff80;
}

.bg-purple-50\/60 {
  background-color: #faf5ff99;
}

.bg-purple-50\/70 {
  background-color: #faf5ffb3;
}

.bg-purple-50\/75 {
  background-color: #faf5ffbf;
}

.bg-purple-50\/80 {
  background-color: #faf5ffcc;
}

.bg-purple-50\/90 {
  background-color: #faf5ffe6;
}

.bg-purple-50\/95 {
  background-color: #faf5fff2;
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

.bg-purple-500\/0 {
  background-color: #a855f700;
}

.bg-purple-500\/10 {
  background-color: #a855f71a;
}

.bg-purple-500\/100 {
  background-color: #a855f7;
}

.bg-purple-500\/20 {
  background-color: #a855f733;
}

.bg-purple-500\/25 {
  background-color: #a855f740;
}

.bg-purple-500\/30 {
  background-color: #a855f74d;
}

.bg-purple-500\/40 {
  background-color: #a855f766;
}

.bg-purple-500\/5 {
  background-color: #a855f70d;
}

.bg-purple-500\/50 {
  background-color: #a855f780;
}

.bg-purple-500\/60 {
  background-color: #a855f799;
}

.bg-purple-500\/70 {
  background-color: #a855f7b3;
}

.bg-purple-500\/75 {
  background-color: #a855f7bf;
}

.bg-purple-500\/80 {
  background-color: #a855f7cc;
}

.bg-purple-500\/90 {
  background-color: #a855f7e6;
}

.bg-purple-500\/95 {
  background-color: #a855f7f2;
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.bg-purple-600\/0 {
  background-color: #9333ea00;
}

.bg-purple-600\/10 {
  background-color: #9333ea1a;
}

.bg-purple-600\/100 {
  background-color: #9333ea;
}

.bg-purple-600\/20 {
  background-color: #9333ea33;
}

.bg-purple-600\/25 {
  background-color: #9333ea40;
}

.bg-purple-600\/30 {
  background-color: #9333ea4d;
}

.bg-purple-600\/40 {
  background-color: #9333ea66;
}

.bg-purple-600\/5 {
  background-color: #9333ea0d;
}

.bg-purple-600\/50 {
  background-color: #9333ea80;
}

.bg-purple-600\/60 {
  background-color: #9333ea99;
}

.bg-purple-600\/70 {
  background-color: #9333eab3;
}

.bg-purple-600\/75 {
  background-color: #9333eabf;
}

.bg-purple-600\/80 {
  background-color: #9333eacc;
}

.bg-purple-600\/90 {
  background-color: #9333eae6;
}

.bg-purple-600\/95 {
  background-color: #9333eaf2;
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.bg-purple-700\/0 {
  background-color: #7e22ce00;
}

.bg-purple-700\/10 {
  background-color: #7e22ce1a;
}

.bg-purple-700\/100 {
  background-color: #7e22ce;
}

.bg-purple-700\/20 {
  background-color: #7e22ce33;
}

.bg-purple-700\/25 {
  background-color: #7e22ce40;
}

.bg-purple-700\/30 {
  background-color: #7e22ce4d;
}

.bg-purple-700\/40 {
  background-color: #7e22ce66;
}

.bg-purple-700\/5 {
  background-color: #7e22ce0d;
}

.bg-purple-700\/50 {
  background-color: #7e22ce80;
}

.bg-purple-700\/60 {
  background-color: #7e22ce99;
}

.bg-purple-700\/70 {
  background-color: #7e22ceb3;
}

.bg-purple-700\/75 {
  background-color: #7e22cebf;
}

.bg-purple-700\/80 {
  background-color: #7e22cecc;
}

.bg-purple-700\/90 {
  background-color: #7e22cee6;
}

.bg-purple-700\/95 {
  background-color: #7e22cef2;
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.bg-purple-800\/0 {
  background-color: #6b21a800;
}

.bg-purple-800\/10 {
  background-color: #6b21a81a;
}

.bg-purple-800\/100 {
  background-color: #6b21a8;
}

.bg-purple-800\/20 {
  background-color: #6b21a833;
}

.bg-purple-800\/25 {
  background-color: #6b21a840;
}

.bg-purple-800\/30 {
  background-color: #6b21a84d;
}

.bg-purple-800\/40 {
  background-color: #6b21a866;
}

.bg-purple-800\/5 {
  background-color: #6b21a80d;
}

.bg-purple-800\/50 {
  background-color: #6b21a880;
}

.bg-purple-800\/60 {
  background-color: #6b21a899;
}

.bg-purple-800\/70 {
  background-color: #6b21a8b3;
}

.bg-purple-800\/75 {
  background-color: #6b21a8bf;
}

.bg-purple-800\/80 {
  background-color: #6b21a8cc;
}

.bg-purple-800\/90 {
  background-color: #6b21a8e6;
}

.bg-purple-800\/95 {
  background-color: #6b21a8f2;
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

.bg-purple-900\/0 {
  background-color: #581c8700;
}

.bg-purple-900\/10 {
  background-color: #581c871a;
}

.bg-purple-900\/100 {
  background-color: #581c87;
}

.bg-purple-900\/20 {
  background-color: #581c8733;
}

.bg-purple-900\/25 {
  background-color: #581c8740;
}

.bg-purple-900\/30 {
  background-color: #581c874d;
}

.bg-purple-900\/40 {
  background-color: #581c8766;
}

.bg-purple-900\/5 {
  background-color: #581c870d;
}

.bg-purple-900\/50 {
  background-color: #581c8780;
}

.bg-purple-900\/60 {
  background-color: #581c8799;
}

.bg-purple-900\/70 {
  background-color: #581c87b3;
}

.bg-purple-900\/75 {
  background-color: #581c87bf;
}

.bg-purple-900\/80 {
  background-color: #581c87cc;
}

.bg-purple-900\/90 {
  background-color: #581c87e6;
}

.bg-purple-900\/95 {
  background-color: #581c87f2;
}

.bg-purple-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 7 100 / var(--tw-bg-opacity));
}

.bg-purple-950\/0 {
  background-color: #3b076400;
}

.bg-purple-950\/10 {
  background-color: #3b07641a;
}

.bg-purple-950\/100 {
  background-color: #3b0764;
}

.bg-purple-950\/20 {
  background-color: #3b076433;
}

.bg-purple-950\/25 {
  background-color: #3b076440;
}

.bg-purple-950\/30 {
  background-color: #3b07644d;
}

.bg-purple-950\/40 {
  background-color: #3b076466;
}

.bg-purple-950\/5 {
  background-color: #3b07640d;
}

.bg-purple-950\/50 {
  background-color: #3b076480;
}

.bg-purple-950\/60 {
  background-color: #3b076499;
}

.bg-purple-950\/70 {
  background-color: #3b0764b3;
}

.bg-purple-950\/75 {
  background-color: #3b0764bf;
}

.bg-purple-950\/80 {
  background-color: #3b0764cc;
}

.bg-purple-950\/90 {
  background-color: #3b0764e6;
}

.bg-purple-950\/95 {
  background-color: #3b0764f2;
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--red) / var(--tw-bg-opacity));
}

.bg-transparent, .bg-transparent\/0 {
  background-color: #0000;
}

.bg-transparent\/10 {
  background-color: #0000001a;
}

.bg-transparent\/100 {
  background-color: #000;
}

.bg-transparent\/20 {
  background-color: #0003;
}

.bg-transparent\/25 {
  background-color: #00000040;
}

.bg-transparent\/30 {
  background-color: #0000004d;
}

.bg-transparent\/40 {
  background-color: #0006;
}

.bg-transparent\/5 {
  background-color: #0000000d;
}

.bg-transparent\/50 {
  background-color: #00000080;
}

.bg-transparent\/60 {
  background-color: #0009;
}

.bg-transparent\/70 {
  background-color: #000000b3;
}

.bg-transparent\/75 {
  background-color: #000000bf;
}

.bg-transparent\/80 {
  background-color: #000c;
}

.bg-transparent\/90 {
  background-color: #000000e6;
}

.bg-transparent\/95 {
  background-color: #000000f2;
}

.bg-violet {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--violet) / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--yellow) / var(--tw-bg-opacity));
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-opacity-95 {
  --tw-bg-opacity: .95;
}

.from-amber-100 {
  --tw-gradient-from: #fef3c7 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/0 {
  --tw-gradient-from: #fef3c700 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/10 {
  --tw-gradient-from: #fef3c71a var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/100 {
  --tw-gradient-from: #fef3c7 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/20 {
  --tw-gradient-from: #fef3c733 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/25 {
  --tw-gradient-from: #fef3c740 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/30 {
  --tw-gradient-from: #fef3c74d var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/40 {
  --tw-gradient-from: #fef3c766 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/5 {
  --tw-gradient-from: #fef3c70d var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/50 {
  --tw-gradient-from: #fef3c780 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/60 {
  --tw-gradient-from: #fef3c799 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/70 {
  --tw-gradient-from: #fef3c7b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/75 {
  --tw-gradient-from: #fef3c7bf var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/80 {
  --tw-gradient-from: #fef3c7cc var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/90 {
  --tw-gradient-from: #fef3c7e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-100\/95 {
  --tw-gradient-from: #fef3c7f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200 {
  --tw-gradient-from: #fde68a var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/0 {
  --tw-gradient-from: #fde68a00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/10 {
  --tw-gradient-from: #fde68a1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/100 {
  --tw-gradient-from: #fde68a var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/20 {
  --tw-gradient-from: #fde68a33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/25 {
  --tw-gradient-from: #fde68a40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/30 {
  --tw-gradient-from: #fde68a4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/40 {
  --tw-gradient-from: #fde68a66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/5 {
  --tw-gradient-from: #fde68a0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/50 {
  --tw-gradient-from: #fde68a80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/60 {
  --tw-gradient-from: #fde68a99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/70 {
  --tw-gradient-from: #fde68ab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/75 {
  --tw-gradient-from: #fde68abf var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/80 {
  --tw-gradient-from: #fde68acc var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/90 {
  --tw-gradient-from: #fde68ae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-200\/95 {
  --tw-gradient-from: #fde68af2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300 {
  --tw-gradient-from: #fcd34d var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/0 {
  --tw-gradient-from: #fcd34d00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/10 {
  --tw-gradient-from: #fcd34d1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/100 {
  --tw-gradient-from: #fcd34d var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/20 {
  --tw-gradient-from: #fcd34d33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/25 {
  --tw-gradient-from: #fcd34d40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/30 {
  --tw-gradient-from: #fcd34d4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/40 {
  --tw-gradient-from: #fcd34d66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/5 {
  --tw-gradient-from: #fcd34d0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/50 {
  --tw-gradient-from: #fcd34d80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/60 {
  --tw-gradient-from: #fcd34d99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/70 {
  --tw-gradient-from: #fcd34db3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/75 {
  --tw-gradient-from: #fcd34dbf var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/80 {
  --tw-gradient-from: #fcd34dcc var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/90 {
  --tw-gradient-from: #fcd34de6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-300\/95 {
  --tw-gradient-from: #fcd34df2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400 {
  --tw-gradient-from: #fbbf24 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/0 {
  --tw-gradient-from: #fbbf2400 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/10 {
  --tw-gradient-from: #fbbf241a var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/100 {
  --tw-gradient-from: #fbbf24 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/20 {
  --tw-gradient-from: #fbbf2433 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/25 {
  --tw-gradient-from: #fbbf2440 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/30 {
  --tw-gradient-from: #fbbf244d var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/40 {
  --tw-gradient-from: #fbbf2466 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/5 {
  --tw-gradient-from: #fbbf240d var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/50 {
  --tw-gradient-from: #fbbf2480 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/60 {
  --tw-gradient-from: #fbbf2499 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/70 {
  --tw-gradient-from: #fbbf24b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/75 {
  --tw-gradient-from: #fbbf24bf var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/80 {
  --tw-gradient-from: #fbbf24cc var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/90 {
  --tw-gradient-from: #fbbf24e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-400\/95 {
  --tw-gradient-from: #fbbf24f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50 {
  --tw-gradient-from: #fffbeb var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/0 {
  --tw-gradient-from: #fffbeb00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/10 {
  --tw-gradient-from: #fffbeb1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/100 {
  --tw-gradient-from: #fffbeb var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/20 {
  --tw-gradient-from: #fffbeb33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/25 {
  --tw-gradient-from: #fffbeb40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/30 {
  --tw-gradient-from: #fffbeb4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/40 {
  --tw-gradient-from: #fffbeb66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/5 {
  --tw-gradient-from: #fffbeb0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/50 {
  --tw-gradient-from: #fffbeb80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/60 {
  --tw-gradient-from: #fffbeb99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/70 {
  --tw-gradient-from: #fffbebb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/75 {
  --tw-gradient-from: #fffbebbf var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/80 {
  --tw-gradient-from: #fffbebcc var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/90 {
  --tw-gradient-from: #fffbebe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-50\/95 {
  --tw-gradient-from: #fffbebf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500 {
  --tw-gradient-from: #f59e0b var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/0 {
  --tw-gradient-from: #f59e0b00 var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/10 {
  --tw-gradient-from: #f59e0b1a var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/100 {
  --tw-gradient-from: #f59e0b var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/20 {
  --tw-gradient-from: #f59e0b33 var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/25 {
  --tw-gradient-from: #f59e0b40 var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/30 {
  --tw-gradient-from: #f59e0b4d var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/40 {
  --tw-gradient-from: #f59e0b66 var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/5 {
  --tw-gradient-from: #f59e0b0d var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/50 {
  --tw-gradient-from: #f59e0b80 var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/60 {
  --tw-gradient-from: #f59e0b99 var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/70 {
  --tw-gradient-from: #f59e0bb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/75 {
  --tw-gradient-from: #f59e0bbf var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/80 {
  --tw-gradient-from: #f59e0bcc var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/90 {
  --tw-gradient-from: #f59e0be6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-500\/95 {
  --tw-gradient-from: #f59e0bf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600 {
  --tw-gradient-from: #d97706 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/0 {
  --tw-gradient-from: #d9770600 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/10 {
  --tw-gradient-from: #d977061a var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/100 {
  --tw-gradient-from: #d97706 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/20 {
  --tw-gradient-from: #d9770633 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/25 {
  --tw-gradient-from: #d9770640 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/30 {
  --tw-gradient-from: #d977064d var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/40 {
  --tw-gradient-from: #d9770666 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/5 {
  --tw-gradient-from: #d977060d var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/50 {
  --tw-gradient-from: #d9770680 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/60 {
  --tw-gradient-from: #d9770699 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/70 {
  --tw-gradient-from: #d97706b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/75 {
  --tw-gradient-from: #d97706bf var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/80 {
  --tw-gradient-from: #d97706cc var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/90 {
  --tw-gradient-from: #d97706e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-600\/95 {
  --tw-gradient-from: #d97706f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700 {
  --tw-gradient-from: #b45309 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/0 {
  --tw-gradient-from: #b4530900 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/10 {
  --tw-gradient-from: #b453091a var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/100 {
  --tw-gradient-from: #b45309 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/20 {
  --tw-gradient-from: #b4530933 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/25 {
  --tw-gradient-from: #b4530940 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/30 {
  --tw-gradient-from: #b453094d var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/40 {
  --tw-gradient-from: #b4530966 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/5 {
  --tw-gradient-from: #b453090d var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/50 {
  --tw-gradient-from: #b4530980 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/60 {
  --tw-gradient-from: #b4530999 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/70 {
  --tw-gradient-from: #b45309b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/75 {
  --tw-gradient-from: #b45309bf var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/80 {
  --tw-gradient-from: #b45309cc var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/90 {
  --tw-gradient-from: #b45309e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-700\/95 {
  --tw-gradient-from: #b45309f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800 {
  --tw-gradient-from: #92400e var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/0 {
  --tw-gradient-from: #92400e00 var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/10 {
  --tw-gradient-from: #92400e1a var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/100 {
  --tw-gradient-from: #92400e var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/20 {
  --tw-gradient-from: #92400e33 var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/25 {
  --tw-gradient-from: #92400e40 var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/30 {
  --tw-gradient-from: #92400e4d var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/40 {
  --tw-gradient-from: #92400e66 var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/5 {
  --tw-gradient-from: #92400e0d var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/50 {
  --tw-gradient-from: #92400e80 var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/60 {
  --tw-gradient-from: #92400e99 var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/70 {
  --tw-gradient-from: #92400eb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/75 {
  --tw-gradient-from: #92400ebf var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/80 {
  --tw-gradient-from: #92400ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/90 {
  --tw-gradient-from: #92400ee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-800\/95 {
  --tw-gradient-from: #92400ef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900 {
  --tw-gradient-from: #78350f var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/0 {
  --tw-gradient-from: #78350f00 var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/10 {
  --tw-gradient-from: #78350f1a var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/100 {
  --tw-gradient-from: #78350f var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/20 {
  --tw-gradient-from: #78350f33 var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/25 {
  --tw-gradient-from: #78350f40 var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/30 {
  --tw-gradient-from: #78350f4d var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/40 {
  --tw-gradient-from: #78350f66 var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/5 {
  --tw-gradient-from: #78350f0d var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/50 {
  --tw-gradient-from: #78350f80 var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/60 {
  --tw-gradient-from: #78350f99 var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/70 {
  --tw-gradient-from: #78350fb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/75 {
  --tw-gradient-from: #78350fbf var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/80 {
  --tw-gradient-from: #78350fcc var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/90 {
  --tw-gradient-from: #78350fe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-900\/95 {
  --tw-gradient-from: #78350ff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950 {
  --tw-gradient-from: #451a03 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/0 {
  --tw-gradient-from: #451a0300 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/10 {
  --tw-gradient-from: #451a031a var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/100 {
  --tw-gradient-from: #451a03 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/20 {
  --tw-gradient-from: #451a0333 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/25 {
  --tw-gradient-from: #451a0340 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/30 {
  --tw-gradient-from: #451a034d var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/40 {
  --tw-gradient-from: #451a0366 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/5 {
  --tw-gradient-from: #451a030d var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/50 {
  --tw-gradient-from: #451a0380 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/60 {
  --tw-gradient-from: #451a0399 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/70 {
  --tw-gradient-from: #451a03b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/75 {
  --tw-gradient-from: #451a03bf var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/80 {
  --tw-gradient-from: #451a03cc var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/90 {
  --tw-gradient-from: #451a03e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-amber-950\/95 {
  --tw-gradient-from: #451a03f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/0 {
  --tw-gradient-from: #0000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/10 {
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/100 {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/20 {
  --tw-gradient-from: #0003 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/25 {
  --tw-gradient-from: #00000040 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/30 {
  --tw-gradient-from: #0000004d var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/40 {
  --tw-gradient-from: #0006 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/5 {
  --tw-gradient-from: #0000000d var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/50 {
  --tw-gradient-from: #00000080 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/60 {
  --tw-gradient-from: #0009 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/70 {
  --tw-gradient-from: #000000b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/75 {
  --tw-gradient-from: #000000bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/80 {
  --tw-gradient-from: #000c var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/90 {
  --tw-gradient-from: #000000e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/95 {
  --tw-gradient-from: #000000f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue {
  --tw-gradient-from: hsl(var(--blue) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/0 {
  --tw-gradient-from: hsl(var(--blue) / 0) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/10 {
  --tw-gradient-from: hsl(var(--blue) / .1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/100 {
  --tw-gradient-from: hsl(var(--blue) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/20 {
  --tw-gradient-from: hsl(var(--blue) / .2) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/25 {
  --tw-gradient-from: hsl(var(--blue) / .25) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/30 {
  --tw-gradient-from: hsl(var(--blue) / .3) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/40 {
  --tw-gradient-from: hsl(var(--blue) / .4) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/5 {
  --tw-gradient-from: hsl(var(--blue) / .05) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/50 {
  --tw-gradient-from: hsl(var(--blue) / .5) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/60 {
  --tw-gradient-from: hsl(var(--blue) / .6) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/70 {
  --tw-gradient-from: hsl(var(--blue) / .7) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/75 {
  --tw-gradient-from: hsl(var(--blue) / .75) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/80 {
  --tw-gradient-from: hsl(var(--blue) / .8) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/90 {
  --tw-gradient-from: hsl(var(--blue) / .9) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue\/95 {
  --tw-gradient-from: hsl(var(--blue) / .95) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--blue) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-current {
  --tw-gradient-from: currentColor var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100 {
  --tw-gradient-from: #cffafe var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/0 {
  --tw-gradient-from: #cffafe00 var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/10 {
  --tw-gradient-from: #cffafe1a var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/100 {
  --tw-gradient-from: #cffafe var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/20 {
  --tw-gradient-from: #cffafe33 var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/25 {
  --tw-gradient-from: #cffafe40 var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/30 {
  --tw-gradient-from: #cffafe4d var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/40 {
  --tw-gradient-from: #cffafe66 var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/5 {
  --tw-gradient-from: #cffafe0d var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/50 {
  --tw-gradient-from: #cffafe80 var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/60 {
  --tw-gradient-from: #cffafe99 var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/70 {
  --tw-gradient-from: #cffafeb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/75 {
  --tw-gradient-from: #cffafebf var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/80 {
  --tw-gradient-from: #cffafecc var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/90 {
  --tw-gradient-from: #cffafee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-100\/95 {
  --tw-gradient-from: #cffafef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #cffafe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200 {
  --tw-gradient-from: #a5f3fc var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/0 {
  --tw-gradient-from: #a5f3fc00 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/10 {
  --tw-gradient-from: #a5f3fc1a var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/100 {
  --tw-gradient-from: #a5f3fc var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/20 {
  --tw-gradient-from: #a5f3fc33 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/25 {
  --tw-gradient-from: #a5f3fc40 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/30 {
  --tw-gradient-from: #a5f3fc4d var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/40 {
  --tw-gradient-from: #a5f3fc66 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/5 {
  --tw-gradient-from: #a5f3fc0d var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/50 {
  --tw-gradient-from: #a5f3fc80 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/60 {
  --tw-gradient-from: #a5f3fc99 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/70 {
  --tw-gradient-from: #a5f3fcb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/75 {
  --tw-gradient-from: #a5f3fcbf var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/80 {
  --tw-gradient-from: #a5f3fccc var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/90 {
  --tw-gradient-from: #a5f3fce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-200\/95 {
  --tw-gradient-from: #a5f3fcf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5f3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300 {
  --tw-gradient-from: #67e8f9 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/0 {
  --tw-gradient-from: #67e8f900 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/10 {
  --tw-gradient-from: #67e8f91a var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/100 {
  --tw-gradient-from: #67e8f9 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/20 {
  --tw-gradient-from: #67e8f933 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/25 {
  --tw-gradient-from: #67e8f940 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/30 {
  --tw-gradient-from: #67e8f94d var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/40 {
  --tw-gradient-from: #67e8f966 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/5 {
  --tw-gradient-from: #67e8f90d var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/50 {
  --tw-gradient-from: #67e8f980 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/60 {
  --tw-gradient-from: #67e8f999 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/70 {
  --tw-gradient-from: #67e8f9b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/75 {
  --tw-gradient-from: #67e8f9bf var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/80 {
  --tw-gradient-from: #67e8f9cc var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/90 {
  --tw-gradient-from: #67e8f9e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-300\/95 {
  --tw-gradient-from: #67e8f9f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400 {
  --tw-gradient-from: #22d3ee var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/0 {
  --tw-gradient-from: #22d3ee00 var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/10 {
  --tw-gradient-from: #22d3ee1a var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/100 {
  --tw-gradient-from: #22d3ee var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/20 {
  --tw-gradient-from: #22d3ee33 var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/25 {
  --tw-gradient-from: #22d3ee40 var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/30 {
  --tw-gradient-from: #22d3ee4d var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/40 {
  --tw-gradient-from: #22d3ee66 var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/5 {
  --tw-gradient-from: #22d3ee0d var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/50 {
  --tw-gradient-from: #22d3ee80 var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/60 {
  --tw-gradient-from: #22d3ee99 var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/70 {
  --tw-gradient-from: #22d3eeb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/75 {
  --tw-gradient-from: #22d3eebf var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/80 {
  --tw-gradient-from: #22d3eecc var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/90 {
  --tw-gradient-from: #22d3eee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-400\/95 {
  --tw-gradient-from: #22d3eef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #22d3ee00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50 {
  --tw-gradient-from: #ecfeff var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/0 {
  --tw-gradient-from: #ecfeff00 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/10 {
  --tw-gradient-from: #ecfeff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/100 {
  --tw-gradient-from: #ecfeff var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/20 {
  --tw-gradient-from: #ecfeff33 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/25 {
  --tw-gradient-from: #ecfeff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/30 {
  --tw-gradient-from: #ecfeff4d var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/40 {
  --tw-gradient-from: #ecfeff66 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/5 {
  --tw-gradient-from: #ecfeff0d var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/50 {
  --tw-gradient-from: #ecfeff80 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/60 {
  --tw-gradient-from: #ecfeff99 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/70 {
  --tw-gradient-from: #ecfeffb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/75 {
  --tw-gradient-from: #ecfeffbf var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/80 {
  --tw-gradient-from: #ecfeffcc var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/90 {
  --tw-gradient-from: #ecfeffe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-50\/95 {
  --tw-gradient-from: #ecfefff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500 {
  --tw-gradient-from: #06b6d4 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/0 {
  --tw-gradient-from: #06b6d400 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/10 {
  --tw-gradient-from: #06b6d41a var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/100 {
  --tw-gradient-from: #06b6d4 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/20 {
  --tw-gradient-from: #06b6d433 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/25 {
  --tw-gradient-from: #06b6d440 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/30 {
  --tw-gradient-from: #06b6d44d var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/40 {
  --tw-gradient-from: #06b6d466 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/5 {
  --tw-gradient-from: #06b6d40d var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/50 {
  --tw-gradient-from: #06b6d480 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/60 {
  --tw-gradient-from: #06b6d499 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/70 {
  --tw-gradient-from: #06b6d4b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/75 {
  --tw-gradient-from: #06b6d4bf var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/80 {
  --tw-gradient-from: #06b6d4cc var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/90 {
  --tw-gradient-from: #06b6d4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-500\/95 {
  --tw-gradient-from: #06b6d4f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #06b6d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600 {
  --tw-gradient-from: #0891b2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/0 {
  --tw-gradient-from: #0891b200 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/10 {
  --tw-gradient-from: #0891b21a var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/100 {
  --tw-gradient-from: #0891b2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/20 {
  --tw-gradient-from: #0891b233 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/25 {
  --tw-gradient-from: #0891b240 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/30 {
  --tw-gradient-from: #0891b24d var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/40 {
  --tw-gradient-from: #0891b266 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/5 {
  --tw-gradient-from: #0891b20d var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/50 {
  --tw-gradient-from: #0891b280 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/60 {
  --tw-gradient-from: #0891b299 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/70 {
  --tw-gradient-from: #0891b2b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/75 {
  --tw-gradient-from: #0891b2bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/80 {
  --tw-gradient-from: #0891b2cc var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/90 {
  --tw-gradient-from: #0891b2e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600\/95 {
  --tw-gradient-from: #0891b2f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0891b200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700 {
  --tw-gradient-from: #0e7490 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/0 {
  --tw-gradient-from: #0e749000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/10 {
  --tw-gradient-from: #0e74901a var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/100 {
  --tw-gradient-from: #0e7490 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/20 {
  --tw-gradient-from: #0e749033 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/25 {
  --tw-gradient-from: #0e749040 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/30 {
  --tw-gradient-from: #0e74904d var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/40 {
  --tw-gradient-from: #0e749066 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/5 {
  --tw-gradient-from: #0e74900d var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/50 {
  --tw-gradient-from: #0e749080 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/60 {
  --tw-gradient-from: #0e749099 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/70 {
  --tw-gradient-from: #0e7490b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/75 {
  --tw-gradient-from: #0e7490bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/80 {
  --tw-gradient-from: #0e7490cc var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/90 {
  --tw-gradient-from: #0e7490e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-700\/95 {
  --tw-gradient-from: #0e7490f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0e749000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800 {
  --tw-gradient-from: #155e75 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/0 {
  --tw-gradient-from: #155e7500 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/10 {
  --tw-gradient-from: #155e751a var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/100 {
  --tw-gradient-from: #155e75 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/20 {
  --tw-gradient-from: #155e7533 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/25 {
  --tw-gradient-from: #155e7540 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/30 {
  --tw-gradient-from: #155e754d var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/40 {
  --tw-gradient-from: #155e7566 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/5 {
  --tw-gradient-from: #155e750d var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/50 {
  --tw-gradient-from: #155e7580 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/60 {
  --tw-gradient-from: #155e7599 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/70 {
  --tw-gradient-from: #155e75b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/75 {
  --tw-gradient-from: #155e75bf var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/80 {
  --tw-gradient-from: #155e75cc var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/90 {
  --tw-gradient-from: #155e75e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-800\/95 {
  --tw-gradient-from: #155e75f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #155e7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900 {
  --tw-gradient-from: #164e63 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/0 {
  --tw-gradient-from: #164e6300 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/10 {
  --tw-gradient-from: #164e631a var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/100 {
  --tw-gradient-from: #164e63 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/20 {
  --tw-gradient-from: #164e6333 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/25 {
  --tw-gradient-from: #164e6340 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/30 {
  --tw-gradient-from: #164e634d var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/40 {
  --tw-gradient-from: #164e6366 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/5 {
  --tw-gradient-from: #164e630d var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/50 {
  --tw-gradient-from: #164e6380 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/60 {
  --tw-gradient-from: #164e6399 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/70 {
  --tw-gradient-from: #164e63b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/75 {
  --tw-gradient-from: #164e63bf var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/80 {
  --tw-gradient-from: #164e63cc var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/90 {
  --tw-gradient-from: #164e63e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-900\/95 {
  --tw-gradient-from: #164e63f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #164e6300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950 {
  --tw-gradient-from: #083344 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/0 {
  --tw-gradient-from: #08334400 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/10 {
  --tw-gradient-from: #0833441a var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/100 {
  --tw-gradient-from: #083344 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/20 {
  --tw-gradient-from: #08334433 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/25 {
  --tw-gradient-from: #08334440 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/30 {
  --tw-gradient-from: #0833444d var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/40 {
  --tw-gradient-from: #08334466 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/5 {
  --tw-gradient-from: #0833440d var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/50 {
  --tw-gradient-from: #08334480 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/60 {
  --tw-gradient-from: #08334499 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/70 {
  --tw-gradient-from: #083344b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/75 {
  --tw-gradient-from: #083344bf var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/80 {
  --tw-gradient-from: #083344cc var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/90 {
  --tw-gradient-from: #083344e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-950\/95 {
  --tw-gradient-from: #083344f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #08334400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100 {
  --tw-gradient-from: #d1fae5 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/0 {
  --tw-gradient-from: #d1fae500 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/10 {
  --tw-gradient-from: #d1fae51a var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/100 {
  --tw-gradient-from: #d1fae5 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/20 {
  --tw-gradient-from: #d1fae533 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/25 {
  --tw-gradient-from: #d1fae540 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/30 {
  --tw-gradient-from: #d1fae54d var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/40 {
  --tw-gradient-from: #d1fae566 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/5 {
  --tw-gradient-from: #d1fae50d var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/50 {
  --tw-gradient-from: #d1fae580 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/60 {
  --tw-gradient-from: #d1fae599 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/70 {
  --tw-gradient-from: #d1fae5b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/75 {
  --tw-gradient-from: #d1fae5bf var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/80 {
  --tw-gradient-from: #d1fae5cc var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/90 {
  --tw-gradient-from: #d1fae5e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-100\/95 {
  --tw-gradient-from: #d1fae5f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200 {
  --tw-gradient-from: #a7f3d0 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/0 {
  --tw-gradient-from: #a7f3d000 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/10 {
  --tw-gradient-from: #a7f3d01a var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/100 {
  --tw-gradient-from: #a7f3d0 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/20 {
  --tw-gradient-from: #a7f3d033 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/25 {
  --tw-gradient-from: #a7f3d040 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/30 {
  --tw-gradient-from: #a7f3d04d var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/40 {
  --tw-gradient-from: #a7f3d066 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/5 {
  --tw-gradient-from: #a7f3d00d var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/50 {
  --tw-gradient-from: #a7f3d080 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/60 {
  --tw-gradient-from: #a7f3d099 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/70 {
  --tw-gradient-from: #a7f3d0b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/75 {
  --tw-gradient-from: #a7f3d0bf var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/80 {
  --tw-gradient-from: #a7f3d0cc var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/90 {
  --tw-gradient-from: #a7f3d0e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-200\/95 {
  --tw-gradient-from: #a7f3d0f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300 {
  --tw-gradient-from: #6ee7b7 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/0 {
  --tw-gradient-from: #6ee7b700 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/10 {
  --tw-gradient-from: #6ee7b71a var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/100 {
  --tw-gradient-from: #6ee7b7 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/20 {
  --tw-gradient-from: #6ee7b733 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/25 {
  --tw-gradient-from: #6ee7b740 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/30 {
  --tw-gradient-from: #6ee7b74d var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/40 {
  --tw-gradient-from: #6ee7b766 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/5 {
  --tw-gradient-from: #6ee7b70d var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/50 {
  --tw-gradient-from: #6ee7b780 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/60 {
  --tw-gradient-from: #6ee7b799 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/70 {
  --tw-gradient-from: #6ee7b7b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/75 {
  --tw-gradient-from: #6ee7b7bf var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/80 {
  --tw-gradient-from: #6ee7b7cc var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/90 {
  --tw-gradient-from: #6ee7b7e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-300\/95 {
  --tw-gradient-from: #6ee7b7f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400 {
  --tw-gradient-from: #34d399 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/0 {
  --tw-gradient-from: #34d39900 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/10 {
  --tw-gradient-from: #34d3991a var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/100 {
  --tw-gradient-from: #34d399 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/20 {
  --tw-gradient-from: #34d39933 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/25 {
  --tw-gradient-from: #34d39940 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/30 {
  --tw-gradient-from: #34d3994d var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/40 {
  --tw-gradient-from: #34d39966 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/5 {
  --tw-gradient-from: #34d3990d var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/50 {
  --tw-gradient-from: #34d39980 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/60 {
  --tw-gradient-from: #34d39999 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/70 {
  --tw-gradient-from: #34d399b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/75 {
  --tw-gradient-from: #34d399bf var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/80 {
  --tw-gradient-from: #34d399cc var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/90 {
  --tw-gradient-from: #34d399e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-400\/95 {
  --tw-gradient-from: #34d399f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50 {
  --tw-gradient-from: #ecfdf5 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/0 {
  --tw-gradient-from: #ecfdf500 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/10 {
  --tw-gradient-from: #ecfdf51a var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/100 {
  --tw-gradient-from: #ecfdf5 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/20 {
  --tw-gradient-from: #ecfdf533 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/25 {
  --tw-gradient-from: #ecfdf540 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/30 {
  --tw-gradient-from: #ecfdf54d var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/40 {
  --tw-gradient-from: #ecfdf566 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/5 {
  --tw-gradient-from: #ecfdf50d var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/50 {
  --tw-gradient-from: #ecfdf580 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/60 {
  --tw-gradient-from: #ecfdf599 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/70 {
  --tw-gradient-from: #ecfdf5b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/75 {
  --tw-gradient-from: #ecfdf5bf var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/80 {
  --tw-gradient-from: #ecfdf5cc var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/90 {
  --tw-gradient-from: #ecfdf5e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-50\/95 {
  --tw-gradient-from: #ecfdf5f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500 {
  --tw-gradient-from: #10b981 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/0 {
  --tw-gradient-from: #10b98100 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/10 {
  --tw-gradient-from: #10b9811a var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/100 {
  --tw-gradient-from: #10b981 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/20 {
  --tw-gradient-from: #10b98133 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/25 {
  --tw-gradient-from: #10b98140 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/30 {
  --tw-gradient-from: #10b9814d var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/40 {
  --tw-gradient-from: #10b98166 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/5 {
  --tw-gradient-from: #10b9810d var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/50 {
  --tw-gradient-from: #10b98180 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/60 {
  --tw-gradient-from: #10b98199 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/70 {
  --tw-gradient-from: #10b981b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/75 {
  --tw-gradient-from: #10b981bf var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/80 {
  --tw-gradient-from: #10b981cc var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/90 {
  --tw-gradient-from: #10b981e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-500\/95 {
  --tw-gradient-from: #10b981f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600 {
  --tw-gradient-from: #059669 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/0 {
  --tw-gradient-from: #05966900 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/10 {
  --tw-gradient-from: #0596691a var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/100 {
  --tw-gradient-from: #059669 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/20 {
  --tw-gradient-from: #05966933 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/25 {
  --tw-gradient-from: #05966940 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/30 {
  --tw-gradient-from: #0596694d var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/40 {
  --tw-gradient-from: #05966966 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/5 {
  --tw-gradient-from: #0596690d var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/50 {
  --tw-gradient-from: #05966980 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/60 {
  --tw-gradient-from: #05966999 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/70 {
  --tw-gradient-from: #059669b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/75 {
  --tw-gradient-from: #059669bf var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/80 {
  --tw-gradient-from: #059669cc var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/90 {
  --tw-gradient-from: #059669e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600\/95 {
  --tw-gradient-from: #059669f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700 {
  --tw-gradient-from: #047857 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/0 {
  --tw-gradient-from: #04785700 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/10 {
  --tw-gradient-from: #0478571a var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/100 {
  --tw-gradient-from: #047857 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/20 {
  --tw-gradient-from: #04785733 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/25 {
  --tw-gradient-from: #04785740 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/30 {
  --tw-gradient-from: #0478574d var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/40 {
  --tw-gradient-from: #04785766 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/5 {
  --tw-gradient-from: #0478570d var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/50 {
  --tw-gradient-from: #04785780 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/60 {
  --tw-gradient-from: #04785799 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/70 {
  --tw-gradient-from: #047857b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/75 {
  --tw-gradient-from: #047857bf var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/80 {
  --tw-gradient-from: #047857cc var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/90 {
  --tw-gradient-from: #047857e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-700\/95 {
  --tw-gradient-from: #047857f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800 {
  --tw-gradient-from: #065f46 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/0 {
  --tw-gradient-from: #065f4600 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/10 {
  --tw-gradient-from: #065f461a var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/100 {
  --tw-gradient-from: #065f46 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/20 {
  --tw-gradient-from: #065f4633 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/25 {
  --tw-gradient-from: #065f4640 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/30 {
  --tw-gradient-from: #065f464d var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/40 {
  --tw-gradient-from: #065f4666 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/5 {
  --tw-gradient-from: #065f460d var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/50 {
  --tw-gradient-from: #065f4680 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/60 {
  --tw-gradient-from: #065f4699 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/70 {
  --tw-gradient-from: #065f46b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/75 {
  --tw-gradient-from: #065f46bf var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/80 {
  --tw-gradient-from: #065f46cc var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/90 {
  --tw-gradient-from: #065f46e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-800\/95 {
  --tw-gradient-from: #065f46f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900 {
  --tw-gradient-from: #064e3b var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/0 {
  --tw-gradient-from: #064e3b00 var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/10 {
  --tw-gradient-from: #064e3b1a var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/100 {
  --tw-gradient-from: #064e3b var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/20 {
  --tw-gradient-from: #064e3b33 var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/25 {
  --tw-gradient-from: #064e3b40 var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/30 {
  --tw-gradient-from: #064e3b4d var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/40 {
  --tw-gradient-from: #064e3b66 var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/5 {
  --tw-gradient-from: #064e3b0d var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/50 {
  --tw-gradient-from: #064e3b80 var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/60 {
  --tw-gradient-from: #064e3b99 var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/70 {
  --tw-gradient-from: #064e3bb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/75 {
  --tw-gradient-from: #064e3bbf var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/80 {
  --tw-gradient-from: #064e3bcc var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/90 {
  --tw-gradient-from: #064e3be6 var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-900\/95 {
  --tw-gradient-from: #064e3bf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950 {
  --tw-gradient-from: #022c22 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/0 {
  --tw-gradient-from: #022c2200 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/10 {
  --tw-gradient-from: #022c221a var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/100 {
  --tw-gradient-from: #022c22 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/20 {
  --tw-gradient-from: #022c2233 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/25 {
  --tw-gradient-from: #022c2240 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/30 {
  --tw-gradient-from: #022c224d var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/40 {
  --tw-gradient-from: #022c2266 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/5 {
  --tw-gradient-from: #022c220d var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/50 {
  --tw-gradient-from: #022c2280 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/60 {
  --tw-gradient-from: #022c2299 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/70 {
  --tw-gradient-from: #022c22b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/75 {
  --tw-gradient-from: #022c22bf var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/80 {
  --tw-gradient-from: #022c22cc var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/90 {
  --tw-gradient-from: #022c22e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-950\/95 {
  --tw-gradient-from: #022c22f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100 {
  --tw-gradient-from: #fae8ff var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/0 {
  --tw-gradient-from: #fae8ff00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/10 {
  --tw-gradient-from: #fae8ff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/100 {
  --tw-gradient-from: #fae8ff var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/20 {
  --tw-gradient-from: #fae8ff33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/25 {
  --tw-gradient-from: #fae8ff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/30 {
  --tw-gradient-from: #fae8ff4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/40 {
  --tw-gradient-from: #fae8ff66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/5 {
  --tw-gradient-from: #fae8ff0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/50 {
  --tw-gradient-from: #fae8ff80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/60 {
  --tw-gradient-from: #fae8ff99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/70 {
  --tw-gradient-from: #fae8ffb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/75 {
  --tw-gradient-from: #fae8ffbf var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/80 {
  --tw-gradient-from: #fae8ffcc var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/90 {
  --tw-gradient-from: #fae8ffe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-100\/95 {
  --tw-gradient-from: #fae8fff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fae8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200 {
  --tw-gradient-from: #f5d0fe var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/0 {
  --tw-gradient-from: #f5d0fe00 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/10 {
  --tw-gradient-from: #f5d0fe1a var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/100 {
  --tw-gradient-from: #f5d0fe var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/20 {
  --tw-gradient-from: #f5d0fe33 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/25 {
  --tw-gradient-from: #f5d0fe40 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/30 {
  --tw-gradient-from: #f5d0fe4d var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/40 {
  --tw-gradient-from: #f5d0fe66 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/5 {
  --tw-gradient-from: #f5d0fe0d var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/50 {
  --tw-gradient-from: #f5d0fe80 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/60 {
  --tw-gradient-from: #f5d0fe99 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/70 {
  --tw-gradient-from: #f5d0feb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/75 {
  --tw-gradient-from: #f5d0febf var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/80 {
  --tw-gradient-from: #f5d0fecc var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/90 {
  --tw-gradient-from: #f5d0fee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200\/95 {
  --tw-gradient-from: #f5d0fef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5d0fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300 {
  --tw-gradient-from: #f0abfc var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/0 {
  --tw-gradient-from: #f0abfc00 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/10 {
  --tw-gradient-from: #f0abfc1a var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/100 {
  --tw-gradient-from: #f0abfc var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/20 {
  --tw-gradient-from: #f0abfc33 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/25 {
  --tw-gradient-from: #f0abfc40 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/30 {
  --tw-gradient-from: #f0abfc4d var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/40 {
  --tw-gradient-from: #f0abfc66 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/5 {
  --tw-gradient-from: #f0abfc0d var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/50 {
  --tw-gradient-from: #f0abfc80 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/60 {
  --tw-gradient-from: #f0abfc99 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/70 {
  --tw-gradient-from: #f0abfcb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/75 {
  --tw-gradient-from: #f0abfcbf var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/80 {
  --tw-gradient-from: #f0abfccc var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/90 {
  --tw-gradient-from: #f0abfce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-300\/95 {
  --tw-gradient-from: #f0abfcf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0abfc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400 {
  --tw-gradient-from: #e879f9 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/0 {
  --tw-gradient-from: #e879f900 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/10 {
  --tw-gradient-from: #e879f91a var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/100 {
  --tw-gradient-from: #e879f9 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/20 {
  --tw-gradient-from: #e879f933 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/25 {
  --tw-gradient-from: #e879f940 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/30 {
  --tw-gradient-from: #e879f94d var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/40 {
  --tw-gradient-from: #e879f966 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/5 {
  --tw-gradient-from: #e879f90d var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/50 {
  --tw-gradient-from: #e879f980 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/60 {
  --tw-gradient-from: #e879f999 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/70 {
  --tw-gradient-from: #e879f9b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/75 {
  --tw-gradient-from: #e879f9bf var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/80 {
  --tw-gradient-from: #e879f9cc var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/90 {
  --tw-gradient-from: #e879f9e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-400\/95 {
  --tw-gradient-from: #e879f9f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #e879f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50 {
  --tw-gradient-from: #fdf4ff var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/0 {
  --tw-gradient-from: #fdf4ff00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/10 {
  --tw-gradient-from: #fdf4ff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/100 {
  --tw-gradient-from: #fdf4ff var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/20 {
  --tw-gradient-from: #fdf4ff33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/25 {
  --tw-gradient-from: #fdf4ff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/30 {
  --tw-gradient-from: #fdf4ff4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/40 {
  --tw-gradient-from: #fdf4ff66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/5 {
  --tw-gradient-from: #fdf4ff0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/50 {
  --tw-gradient-from: #fdf4ff80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/60 {
  --tw-gradient-from: #fdf4ff99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/70 {
  --tw-gradient-from: #fdf4ffb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/75 {
  --tw-gradient-from: #fdf4ffbf var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/80 {
  --tw-gradient-from: #fdf4ffcc var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/90 {
  --tw-gradient-from: #fdf4ffe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-50\/95 {
  --tw-gradient-from: #fdf4fff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf4ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500 {
  --tw-gradient-from: #d946ef var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/0 {
  --tw-gradient-from: #d946ef00 var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/10 {
  --tw-gradient-from: #d946ef1a var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/100 {
  --tw-gradient-from: #d946ef var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/20 {
  --tw-gradient-from: #d946ef33 var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/25 {
  --tw-gradient-from: #d946ef40 var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/30 {
  --tw-gradient-from: #d946ef4d var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/40 {
  --tw-gradient-from: #d946ef66 var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/5 {
  --tw-gradient-from: #d946ef0d var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/50 {
  --tw-gradient-from: #d946ef80 var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/60 {
  --tw-gradient-from: #d946ef99 var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/70 {
  --tw-gradient-from: #d946efb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/75 {
  --tw-gradient-from: #d946efbf var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/80 {
  --tw-gradient-from: #d946efcc var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/90 {
  --tw-gradient-from: #d946efe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-500\/95 {
  --tw-gradient-from: #d946eff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #d946ef00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600 {
  --tw-gradient-from: #c026d3 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/0 {
  --tw-gradient-from: #c026d300 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/10 {
  --tw-gradient-from: #c026d31a var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/100 {
  --tw-gradient-from: #c026d3 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/20 {
  --tw-gradient-from: #c026d333 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/25 {
  --tw-gradient-from: #c026d340 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/30 {
  --tw-gradient-from: #c026d34d var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/40 {
  --tw-gradient-from: #c026d366 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/5 {
  --tw-gradient-from: #c026d30d var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/50 {
  --tw-gradient-from: #c026d380 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/60 {
  --tw-gradient-from: #c026d399 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/70 {
  --tw-gradient-from: #c026d3b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/75 {
  --tw-gradient-from: #c026d3bf var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/80 {
  --tw-gradient-from: #c026d3cc var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/90 {
  --tw-gradient-from: #c026d3e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-600\/95 {
  --tw-gradient-from: #c026d3f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #c026d300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700 {
  --tw-gradient-from: #a21caf var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/0 {
  --tw-gradient-from: #a21caf00 var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/10 {
  --tw-gradient-from: #a21caf1a var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/100 {
  --tw-gradient-from: #a21caf var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/20 {
  --tw-gradient-from: #a21caf33 var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/25 {
  --tw-gradient-from: #a21caf40 var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/30 {
  --tw-gradient-from: #a21caf4d var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/40 {
  --tw-gradient-from: #a21caf66 var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/5 {
  --tw-gradient-from: #a21caf0d var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/50 {
  --tw-gradient-from: #a21caf80 var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/60 {
  --tw-gradient-from: #a21caf99 var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/70 {
  --tw-gradient-from: #a21cafb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/75 {
  --tw-gradient-from: #a21cafbf var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/80 {
  --tw-gradient-from: #a21cafcc var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/90 {
  --tw-gradient-from: #a21cafe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-700\/95 {
  --tw-gradient-from: #a21caff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #a21caf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800 {
  --tw-gradient-from: #86198f var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/0 {
  --tw-gradient-from: #86198f00 var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/10 {
  --tw-gradient-from: #86198f1a var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/100 {
  --tw-gradient-from: #86198f var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/20 {
  --tw-gradient-from: #86198f33 var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/25 {
  --tw-gradient-from: #86198f40 var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/30 {
  --tw-gradient-from: #86198f4d var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/40 {
  --tw-gradient-from: #86198f66 var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/5 {
  --tw-gradient-from: #86198f0d var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/50 {
  --tw-gradient-from: #86198f80 var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/60 {
  --tw-gradient-from: #86198f99 var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/70 {
  --tw-gradient-from: #86198fb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/75 {
  --tw-gradient-from: #86198fbf var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/80 {
  --tw-gradient-from: #86198fcc var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/90 {
  --tw-gradient-from: #86198fe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-800\/95 {
  --tw-gradient-from: #86198ff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #86198f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900 {
  --tw-gradient-from: #701a75 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/0 {
  --tw-gradient-from: #701a7500 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/10 {
  --tw-gradient-from: #701a751a var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/100 {
  --tw-gradient-from: #701a75 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/20 {
  --tw-gradient-from: #701a7533 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/25 {
  --tw-gradient-from: #701a7540 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/30 {
  --tw-gradient-from: #701a754d var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/40 {
  --tw-gradient-from: #701a7566 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/5 {
  --tw-gradient-from: #701a750d var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/50 {
  --tw-gradient-from: #701a7580 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/60 {
  --tw-gradient-from: #701a7599 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/70 {
  --tw-gradient-from: #701a75b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/75 {
  --tw-gradient-from: #701a75bf var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/80 {
  --tw-gradient-from: #701a75cc var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/90 {
  --tw-gradient-from: #701a75e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-900\/95 {
  --tw-gradient-from: #701a75f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #701a7500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950 {
  --tw-gradient-from: #4a044e var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/0 {
  --tw-gradient-from: #4a044e00 var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/10 {
  --tw-gradient-from: #4a044e1a var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/100 {
  --tw-gradient-from: #4a044e var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/20 {
  --tw-gradient-from: #4a044e33 var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/25 {
  --tw-gradient-from: #4a044e40 var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/30 {
  --tw-gradient-from: #4a044e4d var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/40 {
  --tw-gradient-from: #4a044e66 var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/5 {
  --tw-gradient-from: #4a044e0d var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/50 {
  --tw-gradient-from: #4a044e80 var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/60 {
  --tw-gradient-from: #4a044e99 var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/70 {
  --tw-gradient-from: #4a044eb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/75 {
  --tw-gradient-from: #4a044ebf var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/80 {
  --tw-gradient-from: #4a044ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/90 {
  --tw-gradient-from: #4a044ee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-950\/95 {
  --tw-gradient-from: #4a044ef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #4a044e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100 {
  --tw-gradient-from: #f0f2f3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/0 {
  --tw-gradient-from: #f0f2f300 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/10 {
  --tw-gradient-from: #f0f2f31a var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/100 {
  --tw-gradient-from: #f0f2f3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/20 {
  --tw-gradient-from: #f0f2f333 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/25 {
  --tw-gradient-from: #f0f2f340 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/30 {
  --tw-gradient-from: #f0f2f34d var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/40 {
  --tw-gradient-from: #f0f2f366 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/5 {
  --tw-gradient-from: #f0f2f30d var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/50 {
  --tw-gradient-from: #f0f2f380 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/60 {
  --tw-gradient-from: #f0f2f399 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/70 {
  --tw-gradient-from: #f0f2f3b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/75 {
  --tw-gradient-from: #f0f2f3bf var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/80 {
  --tw-gradient-from: #f0f2f3cc var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/90 {
  --tw-gradient-from: #f0f2f3e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-100\/95 {
  --tw-gradient-from: #f0f2f3f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f2f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200 {
  --tw-gradient-from: #e8eaed var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/0 {
  --tw-gradient-from: #e8eaed00 var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/10 {
  --tw-gradient-from: #e8eaed1a var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/100 {
  --tw-gradient-from: #e8eaed var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/20 {
  --tw-gradient-from: #e8eaed33 var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/25 {
  --tw-gradient-from: #e8eaed40 var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/30 {
  --tw-gradient-from: #e8eaed4d var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/40 {
  --tw-gradient-from: #e8eaed66 var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/5 {
  --tw-gradient-from: #e8eaed0d var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/50 {
  --tw-gradient-from: #e8eaed80 var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/60 {
  --tw-gradient-from: #e8eaed99 var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/70 {
  --tw-gradient-from: #e8eaedb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/75 {
  --tw-gradient-from: #e8eaedbf var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/80 {
  --tw-gradient-from: #e8eaedcc var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/90 {
  --tw-gradient-from: #e8eaede6 var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-200\/95 {
  --tw-gradient-from: #e8eaedf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #e8eaed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300 {
  --tw-gradient-from: #dadce0 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/0 {
  --tw-gradient-from: #dadce000 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/10 {
  --tw-gradient-from: #dadce01a var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/100 {
  --tw-gradient-from: #dadce0 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/20 {
  --tw-gradient-from: #dadce033 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/25 {
  --tw-gradient-from: #dadce040 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/30 {
  --tw-gradient-from: #dadce04d var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/40 {
  --tw-gradient-from: #dadce066 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/5 {
  --tw-gradient-from: #dadce00d var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/50 {
  --tw-gradient-from: #dadce080 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/60 {
  --tw-gradient-from: #dadce099 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/70 {
  --tw-gradient-from: #dadce0b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/75 {
  --tw-gradient-from: #dadce0bf var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/80 {
  --tw-gradient-from: #dadce0cc var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/90 {
  --tw-gradient-from: #dadce0e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-300\/95 {
  --tw-gradient-from: #dadce0f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #dadce000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400 {
  --tw-gradient-from: #9aa0a6 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/0 {
  --tw-gradient-from: #9aa0a600 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/10 {
  --tw-gradient-from: #9aa0a61a var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/100 {
  --tw-gradient-from: #9aa0a6 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/20 {
  --tw-gradient-from: #9aa0a633 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/25 {
  --tw-gradient-from: #9aa0a640 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/30 {
  --tw-gradient-from: #9aa0a64d var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/40 {
  --tw-gradient-from: #9aa0a666 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/5 {
  --tw-gradient-from: #9aa0a60d var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/50 {
  --tw-gradient-from: #9aa0a680 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/60 {
  --tw-gradient-from: #9aa0a699 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/70 {
  --tw-gradient-from: #9aa0a6b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/75 {
  --tw-gradient-from: #9aa0a6bf var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/80 {
  --tw-gradient-from: #9aa0a6cc var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/90 {
  --tw-gradient-from: #9aa0a6e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400\/95 {
  --tw-gradient-from: #9aa0a6f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #9aa0a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50 {
  --tw-gradient-from: #f8f9fa var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/0 {
  --tw-gradient-from: #f8f9fa00 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/10 {
  --tw-gradient-from: #f8f9fa1a var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/100 {
  --tw-gradient-from: #f8f9fa var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/20 {
  --tw-gradient-from: #f8f9fa33 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/25 {
  --tw-gradient-from: #f8f9fa40 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/30 {
  --tw-gradient-from: #f8f9fa4d var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/40 {
  --tw-gradient-from: #f8f9fa66 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/5 {
  --tw-gradient-from: #f8f9fa0d var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/50 {
  --tw-gradient-from: #f8f9fa80 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/60 {
  --tw-gradient-from: #f8f9fa99 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/70 {
  --tw-gradient-from: #f8f9fab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/75 {
  --tw-gradient-from: #f8f9fabf var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/80 {
  --tw-gradient-from: #f8f9facc var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/90 {
  --tw-gradient-from: #f8f9fae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50\/95 {
  --tw-gradient-from: #f8f9faf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8f9fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500 {
  --tw-gradient-from: #5f6368 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/0 {
  --tw-gradient-from: #5f636800 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/10 {
  --tw-gradient-from: #5f63681a var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/100 {
  --tw-gradient-from: #5f6368 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/20 {
  --tw-gradient-from: #5f636833 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/25 {
  --tw-gradient-from: #5f636840 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/30 {
  --tw-gradient-from: #5f63684d var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/40 {
  --tw-gradient-from: #5f636866 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/5 {
  --tw-gradient-from: #5f63680d var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/50 {
  --tw-gradient-from: #5f636880 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/60 {
  --tw-gradient-from: #5f636899 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/70 {
  --tw-gradient-from: #5f6368b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/75 {
  --tw-gradient-from: #5f6368bf var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/80 {
  --tw-gradient-from: #5f6368cc var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/90 {
  --tw-gradient-from: #5f6368e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-500\/95 {
  --tw-gradient-from: #5f6368f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #5f636800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600 {
  --tw-gradient-from: #3c4043 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/0 {
  --tw-gradient-from: #3c404300 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/10 {
  --tw-gradient-from: #3c40431a var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/100 {
  --tw-gradient-from: #3c4043 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/20 {
  --tw-gradient-from: #3c404333 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/25 {
  --tw-gradient-from: #3c404340 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/30 {
  --tw-gradient-from: #3c40434d var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/40 {
  --tw-gradient-from: #3c404366 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/5 {
  --tw-gradient-from: #3c40430d var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/50 {
  --tw-gradient-from: #3c404380 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/60 {
  --tw-gradient-from: #3c404399 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/70 {
  --tw-gradient-from: #3c4043b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/75 {
  --tw-gradient-from: #3c4043bf var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/80 {
  --tw-gradient-from: #3c4043cc var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/90 {
  --tw-gradient-from: #3c4043e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600\/95 {
  --tw-gradient-from: #3c4043f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #3c404300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700 {
  --tw-gradient-from: #303134 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/0 {
  --tw-gradient-from: #30313400 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/10 {
  --tw-gradient-from: #3031341a var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/100 {
  --tw-gradient-from: #303134 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/20 {
  --tw-gradient-from: #30313433 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/25 {
  --tw-gradient-from: #30313440 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/30 {
  --tw-gradient-from: #3031344d var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/40 {
  --tw-gradient-from: #30313466 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/5 {
  --tw-gradient-from: #3031340d var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/50 {
  --tw-gradient-from: #30313480 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/60 {
  --tw-gradient-from: #30313499 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/70 {
  --tw-gradient-from: #303134b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/75 {
  --tw-gradient-from: #303134bf var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/80 {
  --tw-gradient-from: #303134cc var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/90 {
  --tw-gradient-from: #303134e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-700\/95 {
  --tw-gradient-from: #303134f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #30313400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800 {
  --tw-gradient-from: #28292c var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/0 {
  --tw-gradient-from: #28292c00 var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/10 {
  --tw-gradient-from: #28292c1a var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/100 {
  --tw-gradient-from: #28292c var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/20 {
  --tw-gradient-from: #28292c33 var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/25 {
  --tw-gradient-from: #28292c40 var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/30 {
  --tw-gradient-from: #28292c4d var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/40 {
  --tw-gradient-from: #28292c66 var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/5 {
  --tw-gradient-from: #28292c0d var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/50 {
  --tw-gradient-from: #28292c80 var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/60 {
  --tw-gradient-from: #28292c99 var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/70 {
  --tw-gradient-from: #28292cb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/75 {
  --tw-gradient-from: #28292cbf var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/80 {
  --tw-gradient-from: #28292ccc var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/90 {
  --tw-gradient-from: #28292ce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-800\/95 {
  --tw-gradient-from: #28292cf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #28292c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900 {
  --tw-gradient-from: #202124 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/0 {
  --tw-gradient-from: #20212400 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/10 {
  --tw-gradient-from: #2021241a var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/100 {
  --tw-gradient-from: #202124 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/20 {
  --tw-gradient-from: #20212433 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/25 {
  --tw-gradient-from: #20212440 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/30 {
  --tw-gradient-from: #2021244d var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/40 {
  --tw-gradient-from: #20212466 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/5 {
  --tw-gradient-from: #2021240d var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/50 {
  --tw-gradient-from: #20212480 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/60 {
  --tw-gradient-from: #20212499 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/70 {
  --tw-gradient-from: #202124b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/75 {
  --tw-gradient-from: #202124bf var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/80 {
  --tw-gradient-from: #202124cc var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/90 {
  --tw-gradient-from: #202124e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-900\/95 {
  --tw-gradient-from: #202124f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #20212400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950 {
  --tw-gradient-from: #030712 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/0 {
  --tw-gradient-from: #03071200 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/10 {
  --tw-gradient-from: #0307121a var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/100 {
  --tw-gradient-from: #030712 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/20 {
  --tw-gradient-from: #03071233 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/25 {
  --tw-gradient-from: #03071240 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/30 {
  --tw-gradient-from: #0307124d var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/40 {
  --tw-gradient-from: #03071266 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/5 {
  --tw-gradient-from: #0307120d var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/50 {
  --tw-gradient-from: #03071280 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/60 {
  --tw-gradient-from: #03071299 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/70 {
  --tw-gradient-from: #030712b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/75 {
  --tw-gradient-from: #030712bf var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/80 {
  --tw-gradient-from: #030712cc var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/90 {
  --tw-gradient-from: #030712e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-950\/95 {
  --tw-gradient-from: #030712f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #03071200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green {
  --tw-gradient-from: hsl(var(--green) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/0 {
  --tw-gradient-from: hsl(var(--green) / 0) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/10 {
  --tw-gradient-from: hsl(var(--green) / .1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/100 {
  --tw-gradient-from: hsl(var(--green) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/20 {
  --tw-gradient-from: hsl(var(--green) / .2) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/25 {
  --tw-gradient-from: hsl(var(--green) / .25) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/30 {
  --tw-gradient-from: hsl(var(--green) / .3) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/40 {
  --tw-gradient-from: hsl(var(--green) / .4) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/5 {
  --tw-gradient-from: hsl(var(--green) / .05) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/50 {
  --tw-gradient-from: hsl(var(--green) / .5) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/60 {
  --tw-gradient-from: hsl(var(--green) / .6) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/70 {
  --tw-gradient-from: hsl(var(--green) / .7) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/75 {
  --tw-gradient-from: hsl(var(--green) / .75) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/80 {
  --tw-gradient-from: hsl(var(--green) / .8) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/90 {
  --tw-gradient-from: hsl(var(--green) / .9) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green\/95 {
  --tw-gradient-from: hsl(var(--green) / .95) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--green) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100 {
  --tw-gradient-from: #e0e7ff var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/0 {
  --tw-gradient-from: #e0e7ff00 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/10 {
  --tw-gradient-from: #e0e7ff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/100 {
  --tw-gradient-from: #e0e7ff var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/20 {
  --tw-gradient-from: #e0e7ff33 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/25 {
  --tw-gradient-from: #e0e7ff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/30 {
  --tw-gradient-from: #e0e7ff4d var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/40 {
  --tw-gradient-from: #e0e7ff66 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/5 {
  --tw-gradient-from: #e0e7ff0d var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/50 {
  --tw-gradient-from: #e0e7ff80 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/60 {
  --tw-gradient-from: #e0e7ff99 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/70 {
  --tw-gradient-from: #e0e7ffb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/75 {
  --tw-gradient-from: #e0e7ffbf var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/80 {
  --tw-gradient-from: #e0e7ffcc var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/90 {
  --tw-gradient-from: #e0e7ffe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100\/95 {
  --tw-gradient-from: #e0e7fff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0e7ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200 {
  --tw-gradient-from: #c7d2fe var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/0 {
  --tw-gradient-from: #c7d2fe00 var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/10 {
  --tw-gradient-from: #c7d2fe1a var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/100 {
  --tw-gradient-from: #c7d2fe var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/20 {
  --tw-gradient-from: #c7d2fe33 var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/25 {
  --tw-gradient-from: #c7d2fe40 var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/30 {
  --tw-gradient-from: #c7d2fe4d var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/40 {
  --tw-gradient-from: #c7d2fe66 var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/5 {
  --tw-gradient-from: #c7d2fe0d var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/50 {
  --tw-gradient-from: #c7d2fe80 var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/60 {
  --tw-gradient-from: #c7d2fe99 var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/70 {
  --tw-gradient-from: #c7d2feb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/75 {
  --tw-gradient-from: #c7d2febf var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/80 {
  --tw-gradient-from: #c7d2fecc var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/90 {
  --tw-gradient-from: #c7d2fee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200\/95 {
  --tw-gradient-from: #c7d2fef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #c7d2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300 {
  --tw-gradient-from: #a5b4fc var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/0 {
  --tw-gradient-from: #a5b4fc00 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/10 {
  --tw-gradient-from: #a5b4fc1a var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/100 {
  --tw-gradient-from: #a5b4fc var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/20 {
  --tw-gradient-from: #a5b4fc33 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/25 {
  --tw-gradient-from: #a5b4fc40 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/30 {
  --tw-gradient-from: #a5b4fc4d var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/40 {
  --tw-gradient-from: #a5b4fc66 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/5 {
  --tw-gradient-from: #a5b4fc0d var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/50 {
  --tw-gradient-from: #a5b4fc80 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/60 {
  --tw-gradient-from: #a5b4fc99 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/70 {
  --tw-gradient-from: #a5b4fcb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/75 {
  --tw-gradient-from: #a5b4fcbf var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/80 {
  --tw-gradient-from: #a5b4fccc var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/90 {
  --tw-gradient-from: #a5b4fce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300\/95 {
  --tw-gradient-from: #a5b4fcf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400 {
  --tw-gradient-from: #818cf8 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/0 {
  --tw-gradient-from: #818cf800 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/10 {
  --tw-gradient-from: #818cf81a var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/100 {
  --tw-gradient-from: #818cf8 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/20 {
  --tw-gradient-from: #818cf833 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/25 {
  --tw-gradient-from: #818cf840 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/30 {
  --tw-gradient-from: #818cf84d var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/40 {
  --tw-gradient-from: #818cf866 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/5 {
  --tw-gradient-from: #818cf80d var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/50 {
  --tw-gradient-from: #818cf880 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/60 {
  --tw-gradient-from: #818cf899 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/70 {
  --tw-gradient-from: #818cf8b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/75 {
  --tw-gradient-from: #818cf8bf var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/80 {
  --tw-gradient-from: #818cf8cc var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/90 {
  --tw-gradient-from: #818cf8e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-400\/95 {
  --tw-gradient-from: #818cf8f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #818cf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50 {
  --tw-gradient-from: #eef2ff var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/0 {
  --tw-gradient-from: #eef2ff00 var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/10 {
  --tw-gradient-from: #eef2ff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/100 {
  --tw-gradient-from: #eef2ff var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/20 {
  --tw-gradient-from: #eef2ff33 var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/25 {
  --tw-gradient-from: #eef2ff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/30 {
  --tw-gradient-from: #eef2ff4d var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/40 {
  --tw-gradient-from: #eef2ff66 var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/5 {
  --tw-gradient-from: #eef2ff0d var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/50 {
  --tw-gradient-from: #eef2ff80 var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/60 {
  --tw-gradient-from: #eef2ff99 var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/70 {
  --tw-gradient-from: #eef2ffb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/75 {
  --tw-gradient-from: #eef2ffbf var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/80 {
  --tw-gradient-from: #eef2ffcc var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/90 {
  --tw-gradient-from: #eef2ffe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-50\/95 {
  --tw-gradient-from: #eef2fff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500 {
  --tw-gradient-from: #6366f1 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/0 {
  --tw-gradient-from: #6366f100 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/10 {
  --tw-gradient-from: #6366f11a var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/100 {
  --tw-gradient-from: #6366f1 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/20 {
  --tw-gradient-from: #6366f133 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/25 {
  --tw-gradient-from: #6366f140 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/30 {
  --tw-gradient-from: #6366f14d var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/40 {
  --tw-gradient-from: #6366f166 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/5 {
  --tw-gradient-from: #6366f10d var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/50 {
  --tw-gradient-from: #6366f180 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/60 {
  --tw-gradient-from: #6366f199 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/70 {
  --tw-gradient-from: #6366f1b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/75 {
  --tw-gradient-from: #6366f1bf var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/80 {
  --tw-gradient-from: #6366f1cc var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/90 {
  --tw-gradient-from: #6366f1e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500\/95 {
  --tw-gradient-from: #6366f1f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600 {
  --tw-gradient-from: #4f46e5 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/0 {
  --tw-gradient-from: #4f46e500 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/10 {
  --tw-gradient-from: #4f46e51a var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/100 {
  --tw-gradient-from: #4f46e5 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/20 {
  --tw-gradient-from: #4f46e533 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/25 {
  --tw-gradient-from: #4f46e540 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/30 {
  --tw-gradient-from: #4f46e54d var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/40 {
  --tw-gradient-from: #4f46e566 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/5 {
  --tw-gradient-from: #4f46e50d var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/50 {
  --tw-gradient-from: #4f46e580 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/60 {
  --tw-gradient-from: #4f46e599 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/70 {
  --tw-gradient-from: #4f46e5b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/75 {
  --tw-gradient-from: #4f46e5bf var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/80 {
  --tw-gradient-from: #4f46e5cc var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/90 {
  --tw-gradient-from: #4f46e5e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600\/95 {
  --tw-gradient-from: #4f46e5f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700 {
  --tw-gradient-from: #4338ca var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/0 {
  --tw-gradient-from: #4338ca00 var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/10 {
  --tw-gradient-from: #4338ca1a var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/100 {
  --tw-gradient-from: #4338ca var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/20 {
  --tw-gradient-from: #4338ca33 var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/25 {
  --tw-gradient-from: #4338ca40 var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/30 {
  --tw-gradient-from: #4338ca4d var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/40 {
  --tw-gradient-from: #4338ca66 var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/5 {
  --tw-gradient-from: #4338ca0d var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/50 {
  --tw-gradient-from: #4338ca80 var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/60 {
  --tw-gradient-from: #4338ca99 var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/70 {
  --tw-gradient-from: #4338cab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/75 {
  --tw-gradient-from: #4338cabf var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/80 {
  --tw-gradient-from: #4338cacc var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/90 {
  --tw-gradient-from: #4338cae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-700\/95 {
  --tw-gradient-from: #4338caf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #4338ca00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800 {
  --tw-gradient-from: #3730a3 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/0 {
  --tw-gradient-from: #3730a300 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/10 {
  --tw-gradient-from: #3730a31a var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/100 {
  --tw-gradient-from: #3730a3 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/20 {
  --tw-gradient-from: #3730a333 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/25 {
  --tw-gradient-from: #3730a340 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/30 {
  --tw-gradient-from: #3730a34d var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/40 {
  --tw-gradient-from: #3730a366 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/5 {
  --tw-gradient-from: #3730a30d var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/50 {
  --tw-gradient-from: #3730a380 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/60 {
  --tw-gradient-from: #3730a399 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/70 {
  --tw-gradient-from: #3730a3b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/75 {
  --tw-gradient-from: #3730a3bf var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/80 {
  --tw-gradient-from: #3730a3cc var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/90 {
  --tw-gradient-from: #3730a3e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-800\/95 {
  --tw-gradient-from: #3730a3f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #3730a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900 {
  --tw-gradient-from: #312e81 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/0 {
  --tw-gradient-from: #312e8100 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/10 {
  --tw-gradient-from: #312e811a var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/100 {
  --tw-gradient-from: #312e81 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/20 {
  --tw-gradient-from: #312e8133 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/25 {
  --tw-gradient-from: #312e8140 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/30 {
  --tw-gradient-from: #312e814d var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/40 {
  --tw-gradient-from: #312e8166 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/5 {
  --tw-gradient-from: #312e810d var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/50 {
  --tw-gradient-from: #312e8180 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/60 {
  --tw-gradient-from: #312e8199 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/70 {
  --tw-gradient-from: #312e81b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/75 {
  --tw-gradient-from: #312e81bf var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/80 {
  --tw-gradient-from: #312e81cc var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/90 {
  --tw-gradient-from: #312e81e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-900\/95 {
  --tw-gradient-from: #312e81f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #312e8100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950 {
  --tw-gradient-from: #1e1b4b var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/0 {
  --tw-gradient-from: #1e1b4b00 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/10 {
  --tw-gradient-from: #1e1b4b1a var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/100 {
  --tw-gradient-from: #1e1b4b var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/20 {
  --tw-gradient-from: #1e1b4b33 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/25 {
  --tw-gradient-from: #1e1b4b40 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/30 {
  --tw-gradient-from: #1e1b4b4d var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/40 {
  --tw-gradient-from: #1e1b4b66 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/5 {
  --tw-gradient-from: #1e1b4b0d var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/50 {
  --tw-gradient-from: #1e1b4b80 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/60 {
  --tw-gradient-from: #1e1b4b99 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/70 {
  --tw-gradient-from: #1e1b4bb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/75 {
  --tw-gradient-from: #1e1b4bbf var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/80 {
  --tw-gradient-from: #1e1b4bcc var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/90 {
  --tw-gradient-from: #1e1b4be6 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-950\/95 {
  --tw-gradient-from: #1e1b4bf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-inherit {
  --tw-gradient-from: inherit var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100 {
  --tw-gradient-from: #ecfccb var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/0 {
  --tw-gradient-from: #ecfccb00 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/10 {
  --tw-gradient-from: #ecfccb1a var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/100 {
  --tw-gradient-from: #ecfccb var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/20 {
  --tw-gradient-from: #ecfccb33 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/25 {
  --tw-gradient-from: #ecfccb40 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/30 {
  --tw-gradient-from: #ecfccb4d var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/40 {
  --tw-gradient-from: #ecfccb66 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/5 {
  --tw-gradient-from: #ecfccb0d var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/50 {
  --tw-gradient-from: #ecfccb80 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/60 {
  --tw-gradient-from: #ecfccb99 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/70 {
  --tw-gradient-from: #ecfccbb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/75 {
  --tw-gradient-from: #ecfccbbf var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/80 {
  --tw-gradient-from: #ecfccbcc var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/90 {
  --tw-gradient-from: #ecfccbe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-100\/95 {
  --tw-gradient-from: #ecfccbf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200 {
  --tw-gradient-from: #d9f99d var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/0 {
  --tw-gradient-from: #d9f99d00 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/10 {
  --tw-gradient-from: #d9f99d1a var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/100 {
  --tw-gradient-from: #d9f99d var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/20 {
  --tw-gradient-from: #d9f99d33 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/25 {
  --tw-gradient-from: #d9f99d40 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/30 {
  --tw-gradient-from: #d9f99d4d var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/40 {
  --tw-gradient-from: #d9f99d66 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/5 {
  --tw-gradient-from: #d9f99d0d var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/50 {
  --tw-gradient-from: #d9f99d80 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/60 {
  --tw-gradient-from: #d9f99d99 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/70 {
  --tw-gradient-from: #d9f99db3 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/75 {
  --tw-gradient-from: #d9f99dbf var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/80 {
  --tw-gradient-from: #d9f99dcc var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/90 {
  --tw-gradient-from: #d9f99de6 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-200\/95 {
  --tw-gradient-from: #d9f99df2 var(--tw-gradient-from-position);
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300 {
  --tw-gradient-from: #bef264 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/0 {
  --tw-gradient-from: #bef26400 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/10 {
  --tw-gradient-from: #bef2641a var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/100 {
  --tw-gradient-from: #bef264 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/20 {
  --tw-gradient-from: #bef26433 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/25 {
  --tw-gradient-from: #bef26440 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/30 {
  --tw-gradient-from: #bef2644d var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/40 {
  --tw-gradient-from: #bef26466 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/5 {
  --tw-gradient-from: #bef2640d var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/50 {
  --tw-gradient-from: #bef26480 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/60 {
  --tw-gradient-from: #bef26499 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/70 {
  --tw-gradient-from: #bef264b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/75 {
  --tw-gradient-from: #bef264bf var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/80 {
  --tw-gradient-from: #bef264cc var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/90 {
  --tw-gradient-from: #bef264e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-300\/95 {
  --tw-gradient-from: #bef264f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400 {
  --tw-gradient-from: #a3e635 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/0 {
  --tw-gradient-from: #a3e63500 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/10 {
  --tw-gradient-from: #a3e6351a var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/100 {
  --tw-gradient-from: #a3e635 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/20 {
  --tw-gradient-from: #a3e63533 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/25 {
  --tw-gradient-from: #a3e63540 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/30 {
  --tw-gradient-from: #a3e6354d var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/40 {
  --tw-gradient-from: #a3e63566 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/5 {
  --tw-gradient-from: #a3e6350d var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/50 {
  --tw-gradient-from: #a3e63580 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/60 {
  --tw-gradient-from: #a3e63599 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/70 {
  --tw-gradient-from: #a3e635b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/75 {
  --tw-gradient-from: #a3e635bf var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/80 {
  --tw-gradient-from: #a3e635cc var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/90 {
  --tw-gradient-from: #a3e635e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-400\/95 {
  --tw-gradient-from: #a3e635f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50 {
  --tw-gradient-from: #f7fee7 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/0 {
  --tw-gradient-from: #f7fee700 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/10 {
  --tw-gradient-from: #f7fee71a var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/100 {
  --tw-gradient-from: #f7fee7 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/20 {
  --tw-gradient-from: #f7fee733 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/25 {
  --tw-gradient-from: #f7fee740 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/30 {
  --tw-gradient-from: #f7fee74d var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/40 {
  --tw-gradient-from: #f7fee766 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/5 {
  --tw-gradient-from: #f7fee70d var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/50 {
  --tw-gradient-from: #f7fee780 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/60 {
  --tw-gradient-from: #f7fee799 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/70 {
  --tw-gradient-from: #f7fee7b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/75 {
  --tw-gradient-from: #f7fee7bf var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/80 {
  --tw-gradient-from: #f7fee7cc var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/90 {
  --tw-gradient-from: #f7fee7e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-50\/95 {
  --tw-gradient-from: #f7fee7f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500 {
  --tw-gradient-from: #84cc16 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/0 {
  --tw-gradient-from: #84cc1600 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/10 {
  --tw-gradient-from: #84cc161a var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/100 {
  --tw-gradient-from: #84cc16 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/20 {
  --tw-gradient-from: #84cc1633 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/25 {
  --tw-gradient-from: #84cc1640 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/30 {
  --tw-gradient-from: #84cc164d var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/40 {
  --tw-gradient-from: #84cc1666 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/5 {
  --tw-gradient-from: #84cc160d var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/50 {
  --tw-gradient-from: #84cc1680 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/60 {
  --tw-gradient-from: #84cc1699 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/70 {
  --tw-gradient-from: #84cc16b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/75 {
  --tw-gradient-from: #84cc16bf var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/80 {
  --tw-gradient-from: #84cc16cc var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/90 {
  --tw-gradient-from: #84cc16e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-500\/95 {
  --tw-gradient-from: #84cc16f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600 {
  --tw-gradient-from: #65a30d var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/0 {
  --tw-gradient-from: #65a30d00 var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/10 {
  --tw-gradient-from: #65a30d1a var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/100 {
  --tw-gradient-from: #65a30d var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/20 {
  --tw-gradient-from: #65a30d33 var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/25 {
  --tw-gradient-from: #65a30d40 var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/30 {
  --tw-gradient-from: #65a30d4d var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/40 {
  --tw-gradient-from: #65a30d66 var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/5 {
  --tw-gradient-from: #65a30d0d var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/50 {
  --tw-gradient-from: #65a30d80 var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/60 {
  --tw-gradient-from: #65a30d99 var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/70 {
  --tw-gradient-from: #65a30db3 var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/75 {
  --tw-gradient-from: #65a30dbf var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/80 {
  --tw-gradient-from: #65a30dcc var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/90 {
  --tw-gradient-from: #65a30de6 var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600\/95 {
  --tw-gradient-from: #65a30df2 var(--tw-gradient-from-position);
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700 {
  --tw-gradient-from: #4d7c0f var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/0 {
  --tw-gradient-from: #4d7c0f00 var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/10 {
  --tw-gradient-from: #4d7c0f1a var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/100 {
  --tw-gradient-from: #4d7c0f var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/20 {
  --tw-gradient-from: #4d7c0f33 var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/25 {
  --tw-gradient-from: #4d7c0f40 var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/30 {
  --tw-gradient-from: #4d7c0f4d var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/40 {
  --tw-gradient-from: #4d7c0f66 var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/5 {
  --tw-gradient-from: #4d7c0f0d var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/50 {
  --tw-gradient-from: #4d7c0f80 var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/60 {
  --tw-gradient-from: #4d7c0f99 var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/70 {
  --tw-gradient-from: #4d7c0fb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/75 {
  --tw-gradient-from: #4d7c0fbf var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/80 {
  --tw-gradient-from: #4d7c0fcc var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/90 {
  --tw-gradient-from: #4d7c0fe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-700\/95 {
  --tw-gradient-from: #4d7c0ff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800 {
  --tw-gradient-from: #3f6212 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/0 {
  --tw-gradient-from: #3f621200 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/10 {
  --tw-gradient-from: #3f62121a var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/100 {
  --tw-gradient-from: #3f6212 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/20 {
  --tw-gradient-from: #3f621233 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/25 {
  --tw-gradient-from: #3f621240 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/30 {
  --tw-gradient-from: #3f62124d var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/40 {
  --tw-gradient-from: #3f621266 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/5 {
  --tw-gradient-from: #3f62120d var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/50 {
  --tw-gradient-from: #3f621280 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/60 {
  --tw-gradient-from: #3f621299 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/70 {
  --tw-gradient-from: #3f6212b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/75 {
  --tw-gradient-from: #3f6212bf var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/80 {
  --tw-gradient-from: #3f6212cc var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/90 {
  --tw-gradient-from: #3f6212e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-800\/95 {
  --tw-gradient-from: #3f6212f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900 {
  --tw-gradient-from: #365314 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/0 {
  --tw-gradient-from: #36531400 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/10 {
  --tw-gradient-from: #3653141a var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/100 {
  --tw-gradient-from: #365314 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/20 {
  --tw-gradient-from: #36531433 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/25 {
  --tw-gradient-from: #36531440 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/30 {
  --tw-gradient-from: #3653144d var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/40 {
  --tw-gradient-from: #36531466 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/5 {
  --tw-gradient-from: #3653140d var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/50 {
  --tw-gradient-from: #36531480 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/60 {
  --tw-gradient-from: #36531499 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/70 {
  --tw-gradient-from: #365314b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/75 {
  --tw-gradient-from: #365314bf var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/80 {
  --tw-gradient-from: #365314cc var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/90 {
  --tw-gradient-from: #365314e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-900\/95 {
  --tw-gradient-from: #365314f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950 {
  --tw-gradient-from: #1a2e05 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/0 {
  --tw-gradient-from: #1a2e0500 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/10 {
  --tw-gradient-from: #1a2e051a var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/100 {
  --tw-gradient-from: #1a2e05 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/20 {
  --tw-gradient-from: #1a2e0533 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/25 {
  --tw-gradient-from: #1a2e0540 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/30 {
  --tw-gradient-from: #1a2e054d var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/40 {
  --tw-gradient-from: #1a2e0566 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/5 {
  --tw-gradient-from: #1a2e050d var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/50 {
  --tw-gradient-from: #1a2e0580 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/60 {
  --tw-gradient-from: #1a2e0599 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/70 {
  --tw-gradient-from: #1a2e05b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/75 {
  --tw-gradient-from: #1a2e05bf var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/80 {
  --tw-gradient-from: #1a2e05cc var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/90 {
  --tw-gradient-from: #1a2e05e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-950\/95 {
  --tw-gradient-from: #1a2e05f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100 {
  --tw-gradient-from: #f5f5f5 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/0 {
  --tw-gradient-from: #f5f5f500 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/10 {
  --tw-gradient-from: #f5f5f51a var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/100 {
  --tw-gradient-from: #f5f5f5 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/20 {
  --tw-gradient-from: #f5f5f533 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/25 {
  --tw-gradient-from: #f5f5f540 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/30 {
  --tw-gradient-from: #f5f5f54d var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/40 {
  --tw-gradient-from: #f5f5f566 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/5 {
  --tw-gradient-from: #f5f5f50d var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/50 {
  --tw-gradient-from: #f5f5f580 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/60 {
  --tw-gradient-from: #f5f5f599 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/70 {
  --tw-gradient-from: #f5f5f5b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/75 {
  --tw-gradient-from: #f5f5f5bf var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/80 {
  --tw-gradient-from: #f5f5f5cc var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/90 {
  --tw-gradient-from: #f5f5f5e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-100\/95 {
  --tw-gradient-from: #f5f5f5f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200 {
  --tw-gradient-from: #e5e5e5 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/0 {
  --tw-gradient-from: #e5e5e500 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/10 {
  --tw-gradient-from: #e5e5e51a var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/100 {
  --tw-gradient-from: #e5e5e5 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/20 {
  --tw-gradient-from: #e5e5e533 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/25 {
  --tw-gradient-from: #e5e5e540 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/30 {
  --tw-gradient-from: #e5e5e54d var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/40 {
  --tw-gradient-from: #e5e5e566 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/5 {
  --tw-gradient-from: #e5e5e50d var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/50 {
  --tw-gradient-from: #e5e5e580 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/60 {
  --tw-gradient-from: #e5e5e599 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/70 {
  --tw-gradient-from: #e5e5e5b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/75 {
  --tw-gradient-from: #e5e5e5bf var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/80 {
  --tw-gradient-from: #e5e5e5cc var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/90 {
  --tw-gradient-from: #e5e5e5e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-200\/95 {
  --tw-gradient-from: #e5e5e5f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300 {
  --tw-gradient-from: #d4d4d4 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/0 {
  --tw-gradient-from: #d4d4d400 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/10 {
  --tw-gradient-from: #d4d4d41a var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/100 {
  --tw-gradient-from: #d4d4d4 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/20 {
  --tw-gradient-from: #d4d4d433 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/25 {
  --tw-gradient-from: #d4d4d440 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/30 {
  --tw-gradient-from: #d4d4d44d var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/40 {
  --tw-gradient-from: #d4d4d466 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/5 {
  --tw-gradient-from: #d4d4d40d var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/50 {
  --tw-gradient-from: #d4d4d480 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/60 {
  --tw-gradient-from: #d4d4d499 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/70 {
  --tw-gradient-from: #d4d4d4b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/75 {
  --tw-gradient-from: #d4d4d4bf var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/80 {
  --tw-gradient-from: #d4d4d4cc var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/90 {
  --tw-gradient-from: #d4d4d4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-300\/95 {
  --tw-gradient-from: #d4d4d4f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400 {
  --tw-gradient-from: #a3a3a3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/0 {
  --tw-gradient-from: #a3a3a300 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/10 {
  --tw-gradient-from: #a3a3a31a var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/100 {
  --tw-gradient-from: #a3a3a3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/20 {
  --tw-gradient-from: #a3a3a333 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/25 {
  --tw-gradient-from: #a3a3a340 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/30 {
  --tw-gradient-from: #a3a3a34d var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/40 {
  --tw-gradient-from: #a3a3a366 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/5 {
  --tw-gradient-from: #a3a3a30d var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/50 {
  --tw-gradient-from: #a3a3a380 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/60 {
  --tw-gradient-from: #a3a3a399 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/70 {
  --tw-gradient-from: #a3a3a3b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/75 {
  --tw-gradient-from: #a3a3a3bf var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/80 {
  --tw-gradient-from: #a3a3a3cc var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/90 {
  --tw-gradient-from: #a3a3a3e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-400\/95 {
  --tw-gradient-from: #a3a3a3f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3a3a300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50 {
  --tw-gradient-from: #fafafa var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/0 {
  --tw-gradient-from: #fafafa00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/10 {
  --tw-gradient-from: #fafafa1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/100 {
  --tw-gradient-from: #fafafa var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/20 {
  --tw-gradient-from: #fafafa33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/25 {
  --tw-gradient-from: #fafafa40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/30 {
  --tw-gradient-from: #fafafa4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/40 {
  --tw-gradient-from: #fafafa66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/5 {
  --tw-gradient-from: #fafafa0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/50 {
  --tw-gradient-from: #fafafa80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/60 {
  --tw-gradient-from: #fafafa99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/70 {
  --tw-gradient-from: #fafafab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/75 {
  --tw-gradient-from: #fafafabf var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/80 {
  --tw-gradient-from: #fafafacc var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/90 {
  --tw-gradient-from: #fafafae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-50\/95 {
  --tw-gradient-from: #fafafaf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500 {
  --tw-gradient-from: #737373 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/0 {
  --tw-gradient-from: #73737300 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/10 {
  --tw-gradient-from: #7373731a var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/100 {
  --tw-gradient-from: #737373 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/20 {
  --tw-gradient-from: #73737333 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/25 {
  --tw-gradient-from: #73737340 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/30 {
  --tw-gradient-from: #7373734d var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/40 {
  --tw-gradient-from: #73737366 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/5 {
  --tw-gradient-from: #7373730d var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/50 {
  --tw-gradient-from: #73737380 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/60 {
  --tw-gradient-from: #73737399 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/70 {
  --tw-gradient-from: #737373b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/75 {
  --tw-gradient-from: #737373bf var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/80 {
  --tw-gradient-from: #737373cc var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/90 {
  --tw-gradient-from: #737373e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-500\/95 {
  --tw-gradient-from: #737373f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #73737300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600 {
  --tw-gradient-from: #525252 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/0 {
  --tw-gradient-from: #52525200 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/10 {
  --tw-gradient-from: #5252521a var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/100 {
  --tw-gradient-from: #525252 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/20 {
  --tw-gradient-from: #52525233 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/25 {
  --tw-gradient-from: #52525240 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/30 {
  --tw-gradient-from: #5252524d var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/40 {
  --tw-gradient-from: #52525266 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/5 {
  --tw-gradient-from: #5252520d var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/50 {
  --tw-gradient-from: #52525280 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/60 {
  --tw-gradient-from: #52525299 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/70 {
  --tw-gradient-from: #525252b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/75 {
  --tw-gradient-from: #525252bf var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/80 {
  --tw-gradient-from: #525252cc var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/90 {
  --tw-gradient-from: #525252e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-600\/95 {
  --tw-gradient-from: #525252f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700 {
  --tw-gradient-from: #404040 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/0 {
  --tw-gradient-from: #40404000 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/10 {
  --tw-gradient-from: #4040401a var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/100 {
  --tw-gradient-from: #404040 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/20 {
  --tw-gradient-from: #40404033 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/25 {
  --tw-gradient-from: #40404040 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/30 {
  --tw-gradient-from: #4040404d var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/40 {
  --tw-gradient-from: #40404066 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/5 {
  --tw-gradient-from: #4040400d var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/50 {
  --tw-gradient-from: #40404080 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/60 {
  --tw-gradient-from: #40404099 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/70 {
  --tw-gradient-from: #404040b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/75 {
  --tw-gradient-from: #404040bf var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/80 {
  --tw-gradient-from: #404040cc var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/90 {
  --tw-gradient-from: #404040e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-700\/95 {
  --tw-gradient-from: #404040f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #40404000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800 {
  --tw-gradient-from: #262626 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/0 {
  --tw-gradient-from: #26262600 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/10 {
  --tw-gradient-from: #2626261a var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/100 {
  --tw-gradient-from: #262626 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/20 {
  --tw-gradient-from: #26262633 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/25 {
  --tw-gradient-from: #26262640 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/30 {
  --tw-gradient-from: #2626264d var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/40 {
  --tw-gradient-from: #26262666 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/5 {
  --tw-gradient-from: #2626260d var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/50 {
  --tw-gradient-from: #26262680 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/60 {
  --tw-gradient-from: #26262699 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/70 {
  --tw-gradient-from: #262626b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/75 {
  --tw-gradient-from: #262626bf var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/80 {
  --tw-gradient-from: #262626cc var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/90 {
  --tw-gradient-from: #262626e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800\/95 {
  --tw-gradient-from: #262626f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900 {
  --tw-gradient-from: #171717 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/0 {
  --tw-gradient-from: #17171700 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/10 {
  --tw-gradient-from: #1717171a var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/100 {
  --tw-gradient-from: #171717 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/20 {
  --tw-gradient-from: #17171733 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/25 {
  --tw-gradient-from: #17171740 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/30 {
  --tw-gradient-from: #1717174d var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/40 {
  --tw-gradient-from: #17171766 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/5 {
  --tw-gradient-from: #1717170d var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/50 {
  --tw-gradient-from: #17171780 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/60 {
  --tw-gradient-from: #17171799 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/70 {
  --tw-gradient-from: #171717b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/75 {
  --tw-gradient-from: #171717bf var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/80 {
  --tw-gradient-from: #171717cc var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/90 {
  --tw-gradient-from: #171717e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-900\/95 {
  --tw-gradient-from: #171717f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #17171700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950 {
  --tw-gradient-from: #0a0a0a var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/0 {
  --tw-gradient-from: #0a0a0a00 var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/10 {
  --tw-gradient-from: #0a0a0a1a var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/100 {
  --tw-gradient-from: #0a0a0a var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/20 {
  --tw-gradient-from: #0a0a0a33 var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/25 {
  --tw-gradient-from: #0a0a0a40 var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/30 {
  --tw-gradient-from: #0a0a0a4d var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/40 {
  --tw-gradient-from: #0a0a0a66 var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/5 {
  --tw-gradient-from: #0a0a0a0d var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/50 {
  --tw-gradient-from: #0a0a0a80 var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/60 {
  --tw-gradient-from: #0a0a0a99 var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/70 {
  --tw-gradient-from: #0a0a0ab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/75 {
  --tw-gradient-from: #0a0a0abf var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/80 {
  --tw-gradient-from: #0a0a0acc var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/90 {
  --tw-gradient-from: #0a0a0ae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-950\/95 {
  --tw-gradient-from: #0a0a0af2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0a0a0a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100 {
  --tw-gradient-from: #ffedd5 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/0 {
  --tw-gradient-from: #ffedd500 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/10 {
  --tw-gradient-from: #ffedd51a var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/100 {
  --tw-gradient-from: #ffedd5 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/20 {
  --tw-gradient-from: #ffedd533 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/25 {
  --tw-gradient-from: #ffedd540 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/30 {
  --tw-gradient-from: #ffedd54d var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/40 {
  --tw-gradient-from: #ffedd566 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/5 {
  --tw-gradient-from: #ffedd50d var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/50 {
  --tw-gradient-from: #ffedd580 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/60 {
  --tw-gradient-from: #ffedd599 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/70 {
  --tw-gradient-from: #ffedd5b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/75 {
  --tw-gradient-from: #ffedd5bf var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/80 {
  --tw-gradient-from: #ffedd5cc var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/90 {
  --tw-gradient-from: #ffedd5e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100\/95 {
  --tw-gradient-from: #ffedd5f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffedd500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200 {
  --tw-gradient-from: #fed7aa var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/0 {
  --tw-gradient-from: #fed7aa00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/10 {
  --tw-gradient-from: #fed7aa1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/100 {
  --tw-gradient-from: #fed7aa var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/20 {
  --tw-gradient-from: #fed7aa33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/25 {
  --tw-gradient-from: #fed7aa40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/30 {
  --tw-gradient-from: #fed7aa4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/40 {
  --tw-gradient-from: #fed7aa66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/5 {
  --tw-gradient-from: #fed7aa0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/50 {
  --tw-gradient-from: #fed7aa80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/60 {
  --tw-gradient-from: #fed7aa99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/70 {
  --tw-gradient-from: #fed7aab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/75 {
  --tw-gradient-from: #fed7aabf var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/80 {
  --tw-gradient-from: #fed7aacc var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/90 {
  --tw-gradient-from: #fed7aae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-200\/95 {
  --tw-gradient-from: #fed7aaf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fed7aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300 {
  --tw-gradient-from: #fdba74 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/0 {
  --tw-gradient-from: #fdba7400 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/10 {
  --tw-gradient-from: #fdba741a var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/100 {
  --tw-gradient-from: #fdba74 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/20 {
  --tw-gradient-from: #fdba7433 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/25 {
  --tw-gradient-from: #fdba7440 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/30 {
  --tw-gradient-from: #fdba744d var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/40 {
  --tw-gradient-from: #fdba7466 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/5 {
  --tw-gradient-from: #fdba740d var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/50 {
  --tw-gradient-from: #fdba7480 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/60 {
  --tw-gradient-from: #fdba7499 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/70 {
  --tw-gradient-from: #fdba74b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/75 {
  --tw-gradient-from: #fdba74bf var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/80 {
  --tw-gradient-from: #fdba74cc var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/90 {
  --tw-gradient-from: #fdba74e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300\/95 {
  --tw-gradient-from: #fdba74f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdba7400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400 {
  --tw-gradient-from: #fb923c var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/0 {
  --tw-gradient-from: #fb923c00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/10 {
  --tw-gradient-from: #fb923c1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/100 {
  --tw-gradient-from: #fb923c var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/20 {
  --tw-gradient-from: #fb923c33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/25 {
  --tw-gradient-from: #fb923c40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/30 {
  --tw-gradient-from: #fb923c4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/40 {
  --tw-gradient-from: #fb923c66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/5 {
  --tw-gradient-from: #fb923c0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/50 {
  --tw-gradient-from: #fb923c80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/60 {
  --tw-gradient-from: #fb923c99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/70 {
  --tw-gradient-from: #fb923cb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/75 {
  --tw-gradient-from: #fb923cbf var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/80 {
  --tw-gradient-from: #fb923ccc var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/90 {
  --tw-gradient-from: #fb923ce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-400\/95 {
  --tw-gradient-from: #fb923cf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb923c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50 {
  --tw-gradient-from: #fff7ed var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/0 {
  --tw-gradient-from: #fff7ed00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/10 {
  --tw-gradient-from: #fff7ed1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/100 {
  --tw-gradient-from: #fff7ed var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/20 {
  --tw-gradient-from: #fff7ed33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/25 {
  --tw-gradient-from: #fff7ed40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/30 {
  --tw-gradient-from: #fff7ed4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/40 {
  --tw-gradient-from: #fff7ed66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/5 {
  --tw-gradient-from: #fff7ed0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/50 {
  --tw-gradient-from: #fff7ed80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/60 {
  --tw-gradient-from: #fff7ed99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/70 {
  --tw-gradient-from: #fff7edb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/75 {
  --tw-gradient-from: #fff7edbf var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/80 {
  --tw-gradient-from: #fff7edcc var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/90 {
  --tw-gradient-from: #fff7ede6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-50\/95 {
  --tw-gradient-from: #fff7edf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff7ed00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500 {
  --tw-gradient-from: #f97316 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/0 {
  --tw-gradient-from: #f9731600 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/10 {
  --tw-gradient-from: #f973161a var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/100 {
  --tw-gradient-from: #f97316 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/20 {
  --tw-gradient-from: #f9731633 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/25 {
  --tw-gradient-from: #f9731640 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/30 {
  --tw-gradient-from: #f973164d var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/40 {
  --tw-gradient-from: #f9731666 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/5 {
  --tw-gradient-from: #f973160d var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/50 {
  --tw-gradient-from: #f9731680 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/60 {
  --tw-gradient-from: #f9731699 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/70 {
  --tw-gradient-from: #f97316b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/75 {
  --tw-gradient-from: #f97316bf var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/80 {
  --tw-gradient-from: #f97316cc var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/90 {
  --tw-gradient-from: #f97316e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500\/95 {
  --tw-gradient-from: #f97316f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9731600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600 {
  --tw-gradient-from: #ea580c var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/0 {
  --tw-gradient-from: #ea580c00 var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/10 {
  --tw-gradient-from: #ea580c1a var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/100 {
  --tw-gradient-from: #ea580c var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/20 {
  --tw-gradient-from: #ea580c33 var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/25 {
  --tw-gradient-from: #ea580c40 var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/30 {
  --tw-gradient-from: #ea580c4d var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/40 {
  --tw-gradient-from: #ea580c66 var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/5 {
  --tw-gradient-from: #ea580c0d var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/50 {
  --tw-gradient-from: #ea580c80 var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/60 {
  --tw-gradient-from: #ea580c99 var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/70 {
  --tw-gradient-from: #ea580cb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/75 {
  --tw-gradient-from: #ea580cbf var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/80 {
  --tw-gradient-from: #ea580ccc var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/90 {
  --tw-gradient-from: #ea580ce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600\/95 {
  --tw-gradient-from: #ea580cf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #ea580c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700 {
  --tw-gradient-from: #c2410c var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/0 {
  --tw-gradient-from: #c2410c00 var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/10 {
  --tw-gradient-from: #c2410c1a var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/100 {
  --tw-gradient-from: #c2410c var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/20 {
  --tw-gradient-from: #c2410c33 var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/25 {
  --tw-gradient-from: #c2410c40 var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/30 {
  --tw-gradient-from: #c2410c4d var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/40 {
  --tw-gradient-from: #c2410c66 var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/5 {
  --tw-gradient-from: #c2410c0d var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/50 {
  --tw-gradient-from: #c2410c80 var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/60 {
  --tw-gradient-from: #c2410c99 var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/70 {
  --tw-gradient-from: #c2410cb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/75 {
  --tw-gradient-from: #c2410cbf var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/80 {
  --tw-gradient-from: #c2410ccc var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/90 {
  --tw-gradient-from: #c2410ce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-700\/95 {
  --tw-gradient-from: #c2410cf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #c2410c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800 {
  --tw-gradient-from: #9a3412 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/0 {
  --tw-gradient-from: #9a341200 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/10 {
  --tw-gradient-from: #9a34121a var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/100 {
  --tw-gradient-from: #9a3412 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/20 {
  --tw-gradient-from: #9a341233 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/25 {
  --tw-gradient-from: #9a341240 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/30 {
  --tw-gradient-from: #9a34124d var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/40 {
  --tw-gradient-from: #9a341266 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/5 {
  --tw-gradient-from: #9a34120d var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/50 {
  --tw-gradient-from: #9a341280 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/60 {
  --tw-gradient-from: #9a341299 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/70 {
  --tw-gradient-from: #9a3412b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/75 {
  --tw-gradient-from: #9a3412bf var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/80 {
  --tw-gradient-from: #9a3412cc var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/90 {
  --tw-gradient-from: #9a3412e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-800\/95 {
  --tw-gradient-from: #9a3412f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #9a341200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900 {
  --tw-gradient-from: #7c2d12 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/0 {
  --tw-gradient-from: #7c2d1200 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/10 {
  --tw-gradient-from: #7c2d121a var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/100 {
  --tw-gradient-from: #7c2d12 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/20 {
  --tw-gradient-from: #7c2d1233 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/25 {
  --tw-gradient-from: #7c2d1240 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/30 {
  --tw-gradient-from: #7c2d124d var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/40 {
  --tw-gradient-from: #7c2d1266 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/5 {
  --tw-gradient-from: #7c2d120d var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/50 {
  --tw-gradient-from: #7c2d1280 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/60 {
  --tw-gradient-from: #7c2d1299 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/70 {
  --tw-gradient-from: #7c2d12b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/75 {
  --tw-gradient-from: #7c2d12bf var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/80 {
  --tw-gradient-from: #7c2d12cc var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/90 {
  --tw-gradient-from: #7c2d12e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-900\/95 {
  --tw-gradient-from: #7c2d12f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #7c2d1200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950 {
  --tw-gradient-from: #431407 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/0 {
  --tw-gradient-from: #43140700 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/10 {
  --tw-gradient-from: #4314071a var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/100 {
  --tw-gradient-from: #431407 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/20 {
  --tw-gradient-from: #43140733 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/25 {
  --tw-gradient-from: #43140740 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/30 {
  --tw-gradient-from: #4314074d var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/40 {
  --tw-gradient-from: #43140766 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/5 {
  --tw-gradient-from: #4314070d var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/50 {
  --tw-gradient-from: #43140780 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/60 {
  --tw-gradient-from: #43140799 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/70 {
  --tw-gradient-from: #431407b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/75 {
  --tw-gradient-from: #431407bf var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/80 {
  --tw-gradient-from: #431407cc var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/90 {
  --tw-gradient-from: #431407e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-950\/95 {
  --tw-gradient-from: #431407f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #43140700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100 {
  --tw-gradient-from: #fce7f3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/0 {
  --tw-gradient-from: #fce7f300 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/10 {
  --tw-gradient-from: #fce7f31a var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/100 {
  --tw-gradient-from: #fce7f3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/20 {
  --tw-gradient-from: #fce7f333 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/25 {
  --tw-gradient-from: #fce7f340 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/30 {
  --tw-gradient-from: #fce7f34d var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/40 {
  --tw-gradient-from: #fce7f366 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/5 {
  --tw-gradient-from: #fce7f30d var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/50 {
  --tw-gradient-from: #fce7f380 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/60 {
  --tw-gradient-from: #fce7f399 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/70 {
  --tw-gradient-from: #fce7f3b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/75 {
  --tw-gradient-from: #fce7f3bf var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/80 {
  --tw-gradient-from: #fce7f3cc var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/90 {
  --tw-gradient-from: #fce7f3e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-100\/95 {
  --tw-gradient-from: #fce7f3f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200 {
  --tw-gradient-from: #fbcfe8 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/0 {
  --tw-gradient-from: #fbcfe800 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/10 {
  --tw-gradient-from: #fbcfe81a var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/100 {
  --tw-gradient-from: #fbcfe8 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/20 {
  --tw-gradient-from: #fbcfe833 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/25 {
  --tw-gradient-from: #fbcfe840 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/30 {
  --tw-gradient-from: #fbcfe84d var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/40 {
  --tw-gradient-from: #fbcfe866 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/5 {
  --tw-gradient-from: #fbcfe80d var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/50 {
  --tw-gradient-from: #fbcfe880 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/60 {
  --tw-gradient-from: #fbcfe899 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/70 {
  --tw-gradient-from: #fbcfe8b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/75 {
  --tw-gradient-from: #fbcfe8bf var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/80 {
  --tw-gradient-from: #fbcfe8cc var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/90 {
  --tw-gradient-from: #fbcfe8e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200\/95 {
  --tw-gradient-from: #fbcfe8f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300 {
  --tw-gradient-from: #f9a8d4 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/0 {
  --tw-gradient-from: #f9a8d400 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/10 {
  --tw-gradient-from: #f9a8d41a var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/100 {
  --tw-gradient-from: #f9a8d4 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/20 {
  --tw-gradient-from: #f9a8d433 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/25 {
  --tw-gradient-from: #f9a8d440 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/30 {
  --tw-gradient-from: #f9a8d44d var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/40 {
  --tw-gradient-from: #f9a8d466 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/5 {
  --tw-gradient-from: #f9a8d40d var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/50 {
  --tw-gradient-from: #f9a8d480 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/60 {
  --tw-gradient-from: #f9a8d499 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/70 {
  --tw-gradient-from: #f9a8d4b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/75 {
  --tw-gradient-from: #f9a8d4bf var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/80 {
  --tw-gradient-from: #f9a8d4cc var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/90 {
  --tw-gradient-from: #f9a8d4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-300\/95 {
  --tw-gradient-from: #f9a8d4f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400 {
  --tw-gradient-from: #f472b6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/0 {
  --tw-gradient-from: #f472b600 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/10 {
  --tw-gradient-from: #f472b61a var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/100 {
  --tw-gradient-from: #f472b6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/20 {
  --tw-gradient-from: #f472b633 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/25 {
  --tw-gradient-from: #f472b640 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/30 {
  --tw-gradient-from: #f472b64d var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/40 {
  --tw-gradient-from: #f472b666 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/5 {
  --tw-gradient-from: #f472b60d var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/50 {
  --tw-gradient-from: #f472b680 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/60 {
  --tw-gradient-from: #f472b699 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/70 {
  --tw-gradient-from: #f472b6b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/75 {
  --tw-gradient-from: #f472b6bf var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/80 {
  --tw-gradient-from: #f472b6cc var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/90 {
  --tw-gradient-from: #f472b6e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-400\/95 {
  --tw-gradient-from: #f472b6f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50 {
  --tw-gradient-from: #fdf2f8 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/0 {
  --tw-gradient-from: #fdf2f800 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/10 {
  --tw-gradient-from: #fdf2f81a var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/100 {
  --tw-gradient-from: #fdf2f8 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/20 {
  --tw-gradient-from: #fdf2f833 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/25 {
  --tw-gradient-from: #fdf2f840 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/30 {
  --tw-gradient-from: #fdf2f84d var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/40 {
  --tw-gradient-from: #fdf2f866 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/5 {
  --tw-gradient-from: #fdf2f80d var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/50 {
  --tw-gradient-from: #fdf2f880 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/60 {
  --tw-gradient-from: #fdf2f899 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/70 {
  --tw-gradient-from: #fdf2f8b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/75 {
  --tw-gradient-from: #fdf2f8bf var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/80 {
  --tw-gradient-from: #fdf2f8cc var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/90 {
  --tw-gradient-from: #fdf2f8e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-50\/95 {
  --tw-gradient-from: #fdf2f8f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500 {
  --tw-gradient-from: #ec4899 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/0 {
  --tw-gradient-from: #ec489900 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/10 {
  --tw-gradient-from: #ec48991a var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/100 {
  --tw-gradient-from: #ec4899 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/20 {
  --tw-gradient-from: #ec489933 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/25 {
  --tw-gradient-from: #ec489940 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/30 {
  --tw-gradient-from: #ec48994d var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/40 {
  --tw-gradient-from: #ec489966 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/5 {
  --tw-gradient-from: #ec48990d var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/50 {
  --tw-gradient-from: #ec489980 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/60 {
  --tw-gradient-from: #ec489999 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/70 {
  --tw-gradient-from: #ec4899b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/75 {
  --tw-gradient-from: #ec4899bf var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/80 {
  --tw-gradient-from: #ec4899cc var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/90 {
  --tw-gradient-from: #ec4899e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-500\/95 {
  --tw-gradient-from: #ec4899f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600 {
  --tw-gradient-from: #db2777 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/0 {
  --tw-gradient-from: #db277700 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/10 {
  --tw-gradient-from: #db27771a var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/100 {
  --tw-gradient-from: #db2777 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/20 {
  --tw-gradient-from: #db277733 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/25 {
  --tw-gradient-from: #db277740 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/30 {
  --tw-gradient-from: #db27774d var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/40 {
  --tw-gradient-from: #db277766 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/5 {
  --tw-gradient-from: #db27770d var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/50 {
  --tw-gradient-from: #db277780 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/60 {
  --tw-gradient-from: #db277799 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/70 {
  --tw-gradient-from: #db2777b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/75 {
  --tw-gradient-from: #db2777bf var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/80 {
  --tw-gradient-from: #db2777cc var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/90 {
  --tw-gradient-from: #db2777e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600\/95 {
  --tw-gradient-from: #db2777f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700 {
  --tw-gradient-from: #be185d var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/0 {
  --tw-gradient-from: #be185d00 var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/10 {
  --tw-gradient-from: #be185d1a var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/100 {
  --tw-gradient-from: #be185d var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/20 {
  --tw-gradient-from: #be185d33 var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/25 {
  --tw-gradient-from: #be185d40 var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/30 {
  --tw-gradient-from: #be185d4d var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/40 {
  --tw-gradient-from: #be185d66 var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/5 {
  --tw-gradient-from: #be185d0d var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/50 {
  --tw-gradient-from: #be185d80 var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/60 {
  --tw-gradient-from: #be185d99 var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/70 {
  --tw-gradient-from: #be185db3 var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/75 {
  --tw-gradient-from: #be185dbf var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/80 {
  --tw-gradient-from: #be185dcc var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/90 {
  --tw-gradient-from: #be185de6 var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-700\/95 {
  --tw-gradient-from: #be185df2 var(--tw-gradient-from-position);
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800 {
  --tw-gradient-from: #9d174d var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/0 {
  --tw-gradient-from: #9d174d00 var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/10 {
  --tw-gradient-from: #9d174d1a var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/100 {
  --tw-gradient-from: #9d174d var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/20 {
  --tw-gradient-from: #9d174d33 var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/25 {
  --tw-gradient-from: #9d174d40 var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/30 {
  --tw-gradient-from: #9d174d4d var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/40 {
  --tw-gradient-from: #9d174d66 var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/5 {
  --tw-gradient-from: #9d174d0d var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/50 {
  --tw-gradient-from: #9d174d80 var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/60 {
  --tw-gradient-from: #9d174d99 var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/70 {
  --tw-gradient-from: #9d174db3 var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/75 {
  --tw-gradient-from: #9d174dbf var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/80 {
  --tw-gradient-from: #9d174dcc var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/90 {
  --tw-gradient-from: #9d174de6 var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-800\/95 {
  --tw-gradient-from: #9d174df2 var(--tw-gradient-from-position);
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900 {
  --tw-gradient-from: #831843 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/0 {
  --tw-gradient-from: #83184300 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/10 {
  --tw-gradient-from: #8318431a var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/100 {
  --tw-gradient-from: #831843 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/20 {
  --tw-gradient-from: #83184333 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/25 {
  --tw-gradient-from: #83184340 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/30 {
  --tw-gradient-from: #8318434d var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/40 {
  --tw-gradient-from: #83184366 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/5 {
  --tw-gradient-from: #8318430d var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/50 {
  --tw-gradient-from: #83184380 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/60 {
  --tw-gradient-from: #83184399 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/70 {
  --tw-gradient-from: #831843b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/75 {
  --tw-gradient-from: #831843bf var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/80 {
  --tw-gradient-from: #831843cc var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/90 {
  --tw-gradient-from: #831843e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-900\/95 {
  --tw-gradient-from: #831843f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950 {
  --tw-gradient-from: #500724 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/0 {
  --tw-gradient-from: #50072400 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/10 {
  --tw-gradient-from: #5007241a var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/100 {
  --tw-gradient-from: #500724 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/20 {
  --tw-gradient-from: #50072433 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/25 {
  --tw-gradient-from: #50072440 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/30 {
  --tw-gradient-from: #5007244d var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/40 {
  --tw-gradient-from: #50072466 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/5 {
  --tw-gradient-from: #5007240d var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/50 {
  --tw-gradient-from: #50072480 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/60 {
  --tw-gradient-from: #50072499 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/70 {
  --tw-gradient-from: #500724b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/75 {
  --tw-gradient-from: #500724bf var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/80 {
  --tw-gradient-from: #500724cc var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/90 {
  --tw-gradient-from: #500724e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-950\/95 {
  --tw-gradient-from: #500724f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary {
  --tw-gradient-from: hsl(var(--primary) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/0 {
  --tw-gradient-from: hsl(var(--primary) / 0) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/10 {
  --tw-gradient-from: hsl(var(--primary) / .1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/100 {
  --tw-gradient-from: hsl(var(--primary) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/20 {
  --tw-gradient-from: hsl(var(--primary) / .2) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/25 {
  --tw-gradient-from: hsl(var(--primary) / .25) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/30 {
  --tw-gradient-from: hsl(var(--primary) / .3) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/40 {
  --tw-gradient-from: hsl(var(--primary) / .4) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/5 {
  --tw-gradient-from: hsl(var(--primary) / .05) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/50 {
  --tw-gradient-from: hsl(var(--primary) / .5) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/60 {
  --tw-gradient-from: hsl(var(--primary) / .6) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/70 {
  --tw-gradient-from: hsl(var(--primary) / .7) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/75 {
  --tw-gradient-from: hsl(var(--primary) / .75) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/80 {
  --tw-gradient-from: hsl(var(--primary) / .8) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/90 {
  --tw-gradient-from: hsl(var(--primary) / .9) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary\/95 {
  --tw-gradient-from: hsl(var(--primary) / .95) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100 {
  --tw-gradient-from: #f3e8ff var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/0 {
  --tw-gradient-from: #f3e8ff00 var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/10 {
  --tw-gradient-from: #f3e8ff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/100 {
  --tw-gradient-from: #f3e8ff var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/20 {
  --tw-gradient-from: #f3e8ff33 var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/25 {
  --tw-gradient-from: #f3e8ff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/30 {
  --tw-gradient-from: #f3e8ff4d var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/40 {
  --tw-gradient-from: #f3e8ff66 var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/5 {
  --tw-gradient-from: #f3e8ff0d var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/50 {
  --tw-gradient-from: #f3e8ff80 var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/60 {
  --tw-gradient-from: #f3e8ff99 var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/70 {
  --tw-gradient-from: #f3e8ffb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/75 {
  --tw-gradient-from: #f3e8ffbf var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/80 {
  --tw-gradient-from: #f3e8ffcc var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/90 {
  --tw-gradient-from: #f3e8ffe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100\/95 {
  --tw-gradient-from: #f3e8fff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200 {
  --tw-gradient-from: #e9d5ff var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/0 {
  --tw-gradient-from: #e9d5ff00 var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/10 {
  --tw-gradient-from: #e9d5ff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/100 {
  --tw-gradient-from: #e9d5ff var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/20 {
  --tw-gradient-from: #e9d5ff33 var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/25 {
  --tw-gradient-from: #e9d5ff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/30 {
  --tw-gradient-from: #e9d5ff4d var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/40 {
  --tw-gradient-from: #e9d5ff66 var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/5 {
  --tw-gradient-from: #e9d5ff0d var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/50 {
  --tw-gradient-from: #e9d5ff80 var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/60 {
  --tw-gradient-from: #e9d5ff99 var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/70 {
  --tw-gradient-from: #e9d5ffb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/75 {
  --tw-gradient-from: #e9d5ffbf var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/80 {
  --tw-gradient-from: #e9d5ffcc var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/90 {
  --tw-gradient-from: #e9d5ffe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200\/95 {
  --tw-gradient-from: #e9d5fff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300 {
  --tw-gradient-from: #d8b4fe var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/0 {
  --tw-gradient-from: #d8b4fe00 var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/10 {
  --tw-gradient-from: #d8b4fe1a var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/100 {
  --tw-gradient-from: #d8b4fe var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/20 {
  --tw-gradient-from: #d8b4fe33 var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/25 {
  --tw-gradient-from: #d8b4fe40 var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/30 {
  --tw-gradient-from: #d8b4fe4d var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/40 {
  --tw-gradient-from: #d8b4fe66 var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/5 {
  --tw-gradient-from: #d8b4fe0d var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/50 {
  --tw-gradient-from: #d8b4fe80 var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/60 {
  --tw-gradient-from: #d8b4fe99 var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/70 {
  --tw-gradient-from: #d8b4feb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/75 {
  --tw-gradient-from: #d8b4febf var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/80 {
  --tw-gradient-from: #d8b4fecc var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/90 {
  --tw-gradient-from: #d8b4fee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300\/95 {
  --tw-gradient-from: #d8b4fef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400 {
  --tw-gradient-from: #c084fc var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/0 {
  --tw-gradient-from: #c084fc00 var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/10 {
  --tw-gradient-from: #c084fc1a var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/100 {
  --tw-gradient-from: #c084fc var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/20 {
  --tw-gradient-from: #c084fc33 var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/25 {
  --tw-gradient-from: #c084fc40 var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/30 {
  --tw-gradient-from: #c084fc4d var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/40 {
  --tw-gradient-from: #c084fc66 var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/5 {
  --tw-gradient-from: #c084fc0d var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/50 {
  --tw-gradient-from: #c084fc80 var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/60 {
  --tw-gradient-from: #c084fc99 var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/70 {
  --tw-gradient-from: #c084fcb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/75 {
  --tw-gradient-from: #c084fcbf var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/80 {
  --tw-gradient-from: #c084fccc var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/90 {
  --tw-gradient-from: #c084fce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-400\/95 {
  --tw-gradient-from: #c084fcf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50 {
  --tw-gradient-from: #faf5ff var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/0 {
  --tw-gradient-from: #faf5ff00 var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/10 {
  --tw-gradient-from: #faf5ff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/100 {
  --tw-gradient-from: #faf5ff var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/20 {
  --tw-gradient-from: #faf5ff33 var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/25 {
  --tw-gradient-from: #faf5ff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/30 {
  --tw-gradient-from: #faf5ff4d var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/40 {
  --tw-gradient-from: #faf5ff66 var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/5 {
  --tw-gradient-from: #faf5ff0d var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/50 {
  --tw-gradient-from: #faf5ff80 var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/60 {
  --tw-gradient-from: #faf5ff99 var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/70 {
  --tw-gradient-from: #faf5ffb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/75 {
  --tw-gradient-from: #faf5ffbf var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/80 {
  --tw-gradient-from: #faf5ffcc var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/90 {
  --tw-gradient-from: #faf5ffe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50\/95 {
  --tw-gradient-from: #faf5fff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500 {
  --tw-gradient-from: #a855f7 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/0 {
  --tw-gradient-from: #a855f700 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/10 {
  --tw-gradient-from: #a855f71a var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/100 {
  --tw-gradient-from: #a855f7 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/20 {
  --tw-gradient-from: #a855f733 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/25 {
  --tw-gradient-from: #a855f740 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/30 {
  --tw-gradient-from: #a855f74d var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/40 {
  --tw-gradient-from: #a855f766 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/5 {
  --tw-gradient-from: #a855f70d var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/50 {
  --tw-gradient-from: #a855f780 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/60 {
  --tw-gradient-from: #a855f799 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/70 {
  --tw-gradient-from: #a855f7b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/75 {
  --tw-gradient-from: #a855f7bf var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/80 {
  --tw-gradient-from: #a855f7cc var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/90 {
  --tw-gradient-from: #a855f7e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500\/95 {
  --tw-gradient-from: #a855f7f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600 {
  --tw-gradient-from: #9333ea var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/0 {
  --tw-gradient-from: #9333ea00 var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/10 {
  --tw-gradient-from: #9333ea1a var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/100 {
  --tw-gradient-from: #9333ea var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/20 {
  --tw-gradient-from: #9333ea33 var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/25 {
  --tw-gradient-from: #9333ea40 var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/30 {
  --tw-gradient-from: #9333ea4d var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/40 {
  --tw-gradient-from: #9333ea66 var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/5 {
  --tw-gradient-from: #9333ea0d var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/50 {
  --tw-gradient-from: #9333ea80 var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/60 {
  --tw-gradient-from: #9333ea99 var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/70 {
  --tw-gradient-from: #9333eab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/75 {
  --tw-gradient-from: #9333eabf var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/80 {
  --tw-gradient-from: #9333eacc var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/90 {
  --tw-gradient-from: #9333eae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600\/95 {
  --tw-gradient-from: #9333eaf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700 {
  --tw-gradient-from: #7e22ce var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/0 {
  --tw-gradient-from: #7e22ce00 var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/10 {
  --tw-gradient-from: #7e22ce1a var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/100 {
  --tw-gradient-from: #7e22ce var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/20 {
  --tw-gradient-from: #7e22ce33 var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/25 {
  --tw-gradient-from: #7e22ce40 var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/30 {
  --tw-gradient-from: #7e22ce4d var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/40 {
  --tw-gradient-from: #7e22ce66 var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/5 {
  --tw-gradient-from: #7e22ce0d var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/50 {
  --tw-gradient-from: #7e22ce80 var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/60 {
  --tw-gradient-from: #7e22ce99 var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/70 {
  --tw-gradient-from: #7e22ceb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/75 {
  --tw-gradient-from: #7e22cebf var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/80 {
  --tw-gradient-from: #7e22cecc var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/90 {
  --tw-gradient-from: #7e22cee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-700\/95 {
  --tw-gradient-from: #7e22cef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800 {
  --tw-gradient-from: #6b21a8 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/0 {
  --tw-gradient-from: #6b21a800 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/10 {
  --tw-gradient-from: #6b21a81a var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/100 {
  --tw-gradient-from: #6b21a8 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/20 {
  --tw-gradient-from: #6b21a833 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/25 {
  --tw-gradient-from: #6b21a840 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/30 {
  --tw-gradient-from: #6b21a84d var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/40 {
  --tw-gradient-from: #6b21a866 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/5 {
  --tw-gradient-from: #6b21a80d var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/50 {
  --tw-gradient-from: #6b21a880 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/60 {
  --tw-gradient-from: #6b21a899 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/70 {
  --tw-gradient-from: #6b21a8b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/75 {
  --tw-gradient-from: #6b21a8bf var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/80 {
  --tw-gradient-from: #6b21a8cc var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/90 {
  --tw-gradient-from: #6b21a8e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800\/95 {
  --tw-gradient-from: #6b21a8f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900 {
  --tw-gradient-from: #581c87 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/0 {
  --tw-gradient-from: #581c8700 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/10 {
  --tw-gradient-from: #581c871a var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/100 {
  --tw-gradient-from: #581c87 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/20 {
  --tw-gradient-from: #581c8733 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/25 {
  --tw-gradient-from: #581c8740 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/30 {
  --tw-gradient-from: #581c874d var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/40 {
  --tw-gradient-from: #581c8766 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/5 {
  --tw-gradient-from: #581c870d var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/50 {
  --tw-gradient-from: #581c8780 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/60 {
  --tw-gradient-from: #581c8799 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/70 {
  --tw-gradient-from: #581c87b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/75 {
  --tw-gradient-from: #581c87bf var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/80 {
  --tw-gradient-from: #581c87cc var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/90 {
  --tw-gradient-from: #581c87e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-900\/95 {
  --tw-gradient-from: #581c87f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950 {
  --tw-gradient-from: #3b0764 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/0 {
  --tw-gradient-from: #3b076400 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/10 {
  --tw-gradient-from: #3b07641a var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/100 {
  --tw-gradient-from: #3b0764 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/20 {
  --tw-gradient-from: #3b076433 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/25 {
  --tw-gradient-from: #3b076440 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/30 {
  --tw-gradient-from: #3b07644d var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/40 {
  --tw-gradient-from: #3b076466 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/5 {
  --tw-gradient-from: #3b07640d var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/50 {
  --tw-gradient-from: #3b076480 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/60 {
  --tw-gradient-from: #3b076499 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/70 {
  --tw-gradient-from: #3b0764b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/75 {
  --tw-gradient-from: #3b0764bf var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/80 {
  --tw-gradient-from: #3b0764cc var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/90 {
  --tw-gradient-from: #3b0764e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-950\/95 {
  --tw-gradient-from: #3b0764f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red {
  --tw-gradient-from: hsl(var(--red) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/0 {
  --tw-gradient-from: hsl(var(--red) / 0) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/10 {
  --tw-gradient-from: hsl(var(--red) / .1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/100 {
  --tw-gradient-from: hsl(var(--red) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/20 {
  --tw-gradient-from: hsl(var(--red) / .2) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/25 {
  --tw-gradient-from: hsl(var(--red) / .25) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/30 {
  --tw-gradient-from: hsl(var(--red) / .3) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/40 {
  --tw-gradient-from: hsl(var(--red) / .4) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/5 {
  --tw-gradient-from: hsl(var(--red) / .05) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/50 {
  --tw-gradient-from: hsl(var(--red) / .5) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/60 {
  --tw-gradient-from: hsl(var(--red) / .6) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/70 {
  --tw-gradient-from: hsl(var(--red) / .7) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/75 {
  --tw-gradient-from: hsl(var(--red) / .75) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/80 {
  --tw-gradient-from: hsl(var(--red) / .8) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/90 {
  --tw-gradient-from: hsl(var(--red) / .9) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red\/95 {
  --tw-gradient-from: hsl(var(--red) / .95) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--red) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100 {
  --tw-gradient-from: #ffe4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/0 {
  --tw-gradient-from: #ffe4e600 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/10 {
  --tw-gradient-from: #ffe4e61a var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/100 {
  --tw-gradient-from: #ffe4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/20 {
  --tw-gradient-from: #ffe4e633 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/25 {
  --tw-gradient-from: #ffe4e640 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/30 {
  --tw-gradient-from: #ffe4e64d var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/40 {
  --tw-gradient-from: #ffe4e666 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/5 {
  --tw-gradient-from: #ffe4e60d var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/50 {
  --tw-gradient-from: #ffe4e680 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/60 {
  --tw-gradient-from: #ffe4e699 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/70 {
  --tw-gradient-from: #ffe4e6b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/75 {
  --tw-gradient-from: #ffe4e6bf var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/80 {
  --tw-gradient-from: #ffe4e6cc var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/90 {
  --tw-gradient-from: #ffe4e6e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-100\/95 {
  --tw-gradient-from: #ffe4e6f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #ffe4e600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200 {
  --tw-gradient-from: #fecdd3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/0 {
  --tw-gradient-from: #fecdd300 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/10 {
  --tw-gradient-from: #fecdd31a var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/100 {
  --tw-gradient-from: #fecdd3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/20 {
  --tw-gradient-from: #fecdd333 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/25 {
  --tw-gradient-from: #fecdd340 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/30 {
  --tw-gradient-from: #fecdd34d var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/40 {
  --tw-gradient-from: #fecdd366 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/5 {
  --tw-gradient-from: #fecdd30d var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/50 {
  --tw-gradient-from: #fecdd380 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/60 {
  --tw-gradient-from: #fecdd399 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/70 {
  --tw-gradient-from: #fecdd3b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/75 {
  --tw-gradient-from: #fecdd3bf var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/80 {
  --tw-gradient-from: #fecdd3cc var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/90 {
  --tw-gradient-from: #fecdd3e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-200\/95 {
  --tw-gradient-from: #fecdd3f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fecdd300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300 {
  --tw-gradient-from: #fda4af var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/0 {
  --tw-gradient-from: #fda4af00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/10 {
  --tw-gradient-from: #fda4af1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/100 {
  --tw-gradient-from: #fda4af var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/20 {
  --tw-gradient-from: #fda4af33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/25 {
  --tw-gradient-from: #fda4af40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/30 {
  --tw-gradient-from: #fda4af4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/40 {
  --tw-gradient-from: #fda4af66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/5 {
  --tw-gradient-from: #fda4af0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/50 {
  --tw-gradient-from: #fda4af80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/60 {
  --tw-gradient-from: #fda4af99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/70 {
  --tw-gradient-from: #fda4afb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/75 {
  --tw-gradient-from: #fda4afbf var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/80 {
  --tw-gradient-from: #fda4afcc var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/90 {
  --tw-gradient-from: #fda4afe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300\/95 {
  --tw-gradient-from: #fda4aff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fda4af00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400 {
  --tw-gradient-from: #fb7185 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/0 {
  --tw-gradient-from: #fb718500 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/10 {
  --tw-gradient-from: #fb71851a var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/100 {
  --tw-gradient-from: #fb7185 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/20 {
  --tw-gradient-from: #fb718533 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/25 {
  --tw-gradient-from: #fb718540 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/30 {
  --tw-gradient-from: #fb71854d var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/40 {
  --tw-gradient-from: #fb718566 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/5 {
  --tw-gradient-from: #fb71850d var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/50 {
  --tw-gradient-from: #fb718580 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/60 {
  --tw-gradient-from: #fb718599 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/70 {
  --tw-gradient-from: #fb7185b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/75 {
  --tw-gradient-from: #fb7185bf var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/80 {
  --tw-gradient-from: #fb7185cc var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/90 {
  --tw-gradient-from: #fb7185e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-400\/95 {
  --tw-gradient-from: #fb7185f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fb718500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50 {
  --tw-gradient-from: #fff1f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/0 {
  --tw-gradient-from: #fff1f200 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/10 {
  --tw-gradient-from: #fff1f21a var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/100 {
  --tw-gradient-from: #fff1f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/20 {
  --tw-gradient-from: #fff1f233 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/25 {
  --tw-gradient-from: #fff1f240 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/30 {
  --tw-gradient-from: #fff1f24d var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/40 {
  --tw-gradient-from: #fff1f266 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/5 {
  --tw-gradient-from: #fff1f20d var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/50 {
  --tw-gradient-from: #fff1f280 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/60 {
  --tw-gradient-from: #fff1f299 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/70 {
  --tw-gradient-from: #fff1f2b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/75 {
  --tw-gradient-from: #fff1f2bf var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/80 {
  --tw-gradient-from: #fff1f2cc var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/90 {
  --tw-gradient-from: #fff1f2e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-50\/95 {
  --tw-gradient-from: #fff1f2f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff1f200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500 {
  --tw-gradient-from: #f43f5e var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/0 {
  --tw-gradient-from: #f43f5e00 var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/10 {
  --tw-gradient-from: #f43f5e1a var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/100 {
  --tw-gradient-from: #f43f5e var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/20 {
  --tw-gradient-from: #f43f5e33 var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/25 {
  --tw-gradient-from: #f43f5e40 var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/30 {
  --tw-gradient-from: #f43f5e4d var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/40 {
  --tw-gradient-from: #f43f5e66 var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/5 {
  --tw-gradient-from: #f43f5e0d var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/50 {
  --tw-gradient-from: #f43f5e80 var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/60 {
  --tw-gradient-from: #f43f5e99 var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/70 {
  --tw-gradient-from: #f43f5eb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/75 {
  --tw-gradient-from: #f43f5ebf var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/80 {
  --tw-gradient-from: #f43f5ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/90 {
  --tw-gradient-from: #f43f5ee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-500\/95 {
  --tw-gradient-from: #f43f5ef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f43f5e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600 {
  --tw-gradient-from: #e11d48 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/0 {
  --tw-gradient-from: #e11d4800 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/10 {
  --tw-gradient-from: #e11d481a var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/100 {
  --tw-gradient-from: #e11d48 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/20 {
  --tw-gradient-from: #e11d4833 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/25 {
  --tw-gradient-from: #e11d4840 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/30 {
  --tw-gradient-from: #e11d484d var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/40 {
  --tw-gradient-from: #e11d4866 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/5 {
  --tw-gradient-from: #e11d480d var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/50 {
  --tw-gradient-from: #e11d4880 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/60 {
  --tw-gradient-from: #e11d4899 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/70 {
  --tw-gradient-from: #e11d48b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/75 {
  --tw-gradient-from: #e11d48bf var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/80 {
  --tw-gradient-from: #e11d48cc var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/90 {
  --tw-gradient-from: #e11d48e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-600\/95 {
  --tw-gradient-from: #e11d48f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #e11d4800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700 {
  --tw-gradient-from: #be123c var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/0 {
  --tw-gradient-from: #be123c00 var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/10 {
  --tw-gradient-from: #be123c1a var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/100 {
  --tw-gradient-from: #be123c var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/20 {
  --tw-gradient-from: #be123c33 var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/25 {
  --tw-gradient-from: #be123c40 var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/30 {
  --tw-gradient-from: #be123c4d var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/40 {
  --tw-gradient-from: #be123c66 var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/5 {
  --tw-gradient-from: #be123c0d var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/50 {
  --tw-gradient-from: #be123c80 var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/60 {
  --tw-gradient-from: #be123c99 var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/70 {
  --tw-gradient-from: #be123cb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/75 {
  --tw-gradient-from: #be123cbf var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/80 {
  --tw-gradient-from: #be123ccc var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/90 {
  --tw-gradient-from: #be123ce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700\/95 {
  --tw-gradient-from: #be123cf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #be123c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800 {
  --tw-gradient-from: #9f1239 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/0 {
  --tw-gradient-from: #9f123900 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/10 {
  --tw-gradient-from: #9f12391a var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/100 {
  --tw-gradient-from: #9f1239 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/20 {
  --tw-gradient-from: #9f123933 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/25 {
  --tw-gradient-from: #9f123940 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/30 {
  --tw-gradient-from: #9f12394d var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/40 {
  --tw-gradient-from: #9f123966 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/5 {
  --tw-gradient-from: #9f12390d var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/50 {
  --tw-gradient-from: #9f123980 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/60 {
  --tw-gradient-from: #9f123999 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/70 {
  --tw-gradient-from: #9f1239b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/75 {
  --tw-gradient-from: #9f1239bf var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/80 {
  --tw-gradient-from: #9f1239cc var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/90 {
  --tw-gradient-from: #9f1239e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-800\/95 {
  --tw-gradient-from: #9f1239f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #9f123900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900 {
  --tw-gradient-from: #881337 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/0 {
  --tw-gradient-from: #88133700 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/10 {
  --tw-gradient-from: #8813371a var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/100 {
  --tw-gradient-from: #881337 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/20 {
  --tw-gradient-from: #88133733 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/25 {
  --tw-gradient-from: #88133740 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/30 {
  --tw-gradient-from: #8813374d var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/40 {
  --tw-gradient-from: #88133766 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/5 {
  --tw-gradient-from: #8813370d var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/50 {
  --tw-gradient-from: #88133780 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/60 {
  --tw-gradient-from: #88133799 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/70 {
  --tw-gradient-from: #881337b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/75 {
  --tw-gradient-from: #881337bf var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/80 {
  --tw-gradient-from: #881337cc var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/90 {
  --tw-gradient-from: #881337e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-900\/95 {
  --tw-gradient-from: #881337f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #88133700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950 {
  --tw-gradient-from: #4c0519 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/0 {
  --tw-gradient-from: #4c051900 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/10 {
  --tw-gradient-from: #4c05191a var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/100 {
  --tw-gradient-from: #4c0519 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/20 {
  --tw-gradient-from: #4c051933 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/25 {
  --tw-gradient-from: #4c051940 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/30 {
  --tw-gradient-from: #4c05194d var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/40 {
  --tw-gradient-from: #4c051966 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/5 {
  --tw-gradient-from: #4c05190d var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/50 {
  --tw-gradient-from: #4c051980 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/60 {
  --tw-gradient-from: #4c051999 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/70 {
  --tw-gradient-from: #4c0519b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/75 {
  --tw-gradient-from: #4c0519bf var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/80 {
  --tw-gradient-from: #4c0519cc var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/90 {
  --tw-gradient-from: #4c0519e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-950\/95 {
  --tw-gradient-from: #4c0519f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #4c051900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100 {
  --tw-gradient-from: #e0f2fe var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/0 {
  --tw-gradient-from: #e0f2fe00 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/10 {
  --tw-gradient-from: #e0f2fe1a var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/100 {
  --tw-gradient-from: #e0f2fe var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/20 {
  --tw-gradient-from: #e0f2fe33 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/25 {
  --tw-gradient-from: #e0f2fe40 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/30 {
  --tw-gradient-from: #e0f2fe4d var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/40 {
  --tw-gradient-from: #e0f2fe66 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/5 {
  --tw-gradient-from: #e0f2fe0d var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/50 {
  --tw-gradient-from: #e0f2fe80 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/60 {
  --tw-gradient-from: #e0f2fe99 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/70 {
  --tw-gradient-from: #e0f2feb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/75 {
  --tw-gradient-from: #e0f2febf var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/80 {
  --tw-gradient-from: #e0f2fecc var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/90 {
  --tw-gradient-from: #e0f2fee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-100\/95 {
  --tw-gradient-from: #e0f2fef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #e0f2fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200 {
  --tw-gradient-from: #bae6fd var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/0 {
  --tw-gradient-from: #bae6fd00 var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/10 {
  --tw-gradient-from: #bae6fd1a var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/100 {
  --tw-gradient-from: #bae6fd var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/20 {
  --tw-gradient-from: #bae6fd33 var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/25 {
  --tw-gradient-from: #bae6fd40 var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/30 {
  --tw-gradient-from: #bae6fd4d var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/40 {
  --tw-gradient-from: #bae6fd66 var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/5 {
  --tw-gradient-from: #bae6fd0d var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/50 {
  --tw-gradient-from: #bae6fd80 var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/60 {
  --tw-gradient-from: #bae6fd99 var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/70 {
  --tw-gradient-from: #bae6fdb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/75 {
  --tw-gradient-from: #bae6fdbf var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/80 {
  --tw-gradient-from: #bae6fdcc var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/90 {
  --tw-gradient-from: #bae6fde6 var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200\/95 {
  --tw-gradient-from: #bae6fdf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #bae6fd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300 {
  --tw-gradient-from: #7dd3fc var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/0 {
  --tw-gradient-from: #7dd3fc00 var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/10 {
  --tw-gradient-from: #7dd3fc1a var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/100 {
  --tw-gradient-from: #7dd3fc var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/20 {
  --tw-gradient-from: #7dd3fc33 var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/25 {
  --tw-gradient-from: #7dd3fc40 var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/30 {
  --tw-gradient-from: #7dd3fc4d var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/40 {
  --tw-gradient-from: #7dd3fc66 var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/5 {
  --tw-gradient-from: #7dd3fc0d var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/50 {
  --tw-gradient-from: #7dd3fc80 var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/60 {
  --tw-gradient-from: #7dd3fc99 var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/70 {
  --tw-gradient-from: #7dd3fcb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/75 {
  --tw-gradient-from: #7dd3fcbf var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/80 {
  --tw-gradient-from: #7dd3fccc var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/90 {
  --tw-gradient-from: #7dd3fce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300\/95 {
  --tw-gradient-from: #7dd3fcf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #7dd3fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400 {
  --tw-gradient-from: #38bdf8 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/0 {
  --tw-gradient-from: #38bdf800 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/10 {
  --tw-gradient-from: #38bdf81a var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/100 {
  --tw-gradient-from: #38bdf8 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/20 {
  --tw-gradient-from: #38bdf833 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/25 {
  --tw-gradient-from: #38bdf840 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/30 {
  --tw-gradient-from: #38bdf84d var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/40 {
  --tw-gradient-from: #38bdf866 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/5 {
  --tw-gradient-from: #38bdf80d var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/50 {
  --tw-gradient-from: #38bdf880 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/60 {
  --tw-gradient-from: #38bdf899 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/70 {
  --tw-gradient-from: #38bdf8b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/75 {
  --tw-gradient-from: #38bdf8bf var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/80 {
  --tw-gradient-from: #38bdf8cc var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/90 {
  --tw-gradient-from: #38bdf8e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400\/95 {
  --tw-gradient-from: #38bdf8f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50 {
  --tw-gradient-from: #f0f9ff var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/0 {
  --tw-gradient-from: #f0f9ff00 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/10 {
  --tw-gradient-from: #f0f9ff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/100 {
  --tw-gradient-from: #f0f9ff var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/20 {
  --tw-gradient-from: #f0f9ff33 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/25 {
  --tw-gradient-from: #f0f9ff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/30 {
  --tw-gradient-from: #f0f9ff4d var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/40 {
  --tw-gradient-from: #f0f9ff66 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/5 {
  --tw-gradient-from: #f0f9ff0d var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/50 {
  --tw-gradient-from: #f0f9ff80 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/60 {
  --tw-gradient-from: #f0f9ff99 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/70 {
  --tw-gradient-from: #f0f9ffb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/75 {
  --tw-gradient-from: #f0f9ffbf var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/80 {
  --tw-gradient-from: #f0f9ffcc var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/90 {
  --tw-gradient-from: #f0f9ffe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-50\/95 {
  --tw-gradient-from: #f0f9fff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0f9ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500 {
  --tw-gradient-from: #0ea5e9 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/0 {
  --tw-gradient-from: #0ea5e900 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/10 {
  --tw-gradient-from: #0ea5e91a var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/100 {
  --tw-gradient-from: #0ea5e9 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/20 {
  --tw-gradient-from: #0ea5e933 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/25 {
  --tw-gradient-from: #0ea5e940 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/30 {
  --tw-gradient-from: #0ea5e94d var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/40 {
  --tw-gradient-from: #0ea5e966 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/5 {
  --tw-gradient-from: #0ea5e90d var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/50 {
  --tw-gradient-from: #0ea5e980 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/60 {
  --tw-gradient-from: #0ea5e999 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/70 {
  --tw-gradient-from: #0ea5e9b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/75 {
  --tw-gradient-from: #0ea5e9bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/80 {
  --tw-gradient-from: #0ea5e9cc var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/90 {
  --tw-gradient-from: #0ea5e9e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-500\/95 {
  --tw-gradient-from: #0ea5e9f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5e900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600 {
  --tw-gradient-from: #0284c7 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/0 {
  --tw-gradient-from: #0284c700 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/10 {
  --tw-gradient-from: #0284c71a var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/100 {
  --tw-gradient-from: #0284c7 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/20 {
  --tw-gradient-from: #0284c733 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/25 {
  --tw-gradient-from: #0284c740 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/30 {
  --tw-gradient-from: #0284c74d var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/40 {
  --tw-gradient-from: #0284c766 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/5 {
  --tw-gradient-from: #0284c70d var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/50 {
  --tw-gradient-from: #0284c780 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/60 {
  --tw-gradient-from: #0284c799 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/70 {
  --tw-gradient-from: #0284c7b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/75 {
  --tw-gradient-from: #0284c7bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/80 {
  --tw-gradient-from: #0284c7cc var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/90 {
  --tw-gradient-from: #0284c7e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-600\/95 {
  --tw-gradient-from: #0284c7f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0284c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700 {
  --tw-gradient-from: #0369a1 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/0 {
  --tw-gradient-from: #0369a100 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/10 {
  --tw-gradient-from: #0369a11a var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/100 {
  --tw-gradient-from: #0369a1 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/20 {
  --tw-gradient-from: #0369a133 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/25 {
  --tw-gradient-from: #0369a140 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/30 {
  --tw-gradient-from: #0369a14d var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/40 {
  --tw-gradient-from: #0369a166 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/5 {
  --tw-gradient-from: #0369a10d var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/50 {
  --tw-gradient-from: #0369a180 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/60 {
  --tw-gradient-from: #0369a199 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/70 {
  --tw-gradient-from: #0369a1b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/75 {
  --tw-gradient-from: #0369a1bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/80 {
  --tw-gradient-from: #0369a1cc var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/90 {
  --tw-gradient-from: #0369a1e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-700\/95 {
  --tw-gradient-from: #0369a1f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800 {
  --tw-gradient-from: #075985 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/0 {
  --tw-gradient-from: #07598500 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/10 {
  --tw-gradient-from: #0759851a var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/100 {
  --tw-gradient-from: #075985 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/20 {
  --tw-gradient-from: #07598533 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/25 {
  --tw-gradient-from: #07598540 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/30 {
  --tw-gradient-from: #0759854d var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/40 {
  --tw-gradient-from: #07598566 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/5 {
  --tw-gradient-from: #0759850d var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/50 {
  --tw-gradient-from: #07598580 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/60 {
  --tw-gradient-from: #07598599 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/70 {
  --tw-gradient-from: #075985b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/75 {
  --tw-gradient-from: #075985bf var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/80 {
  --tw-gradient-from: #075985cc var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/90 {
  --tw-gradient-from: #075985e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-800\/95 {
  --tw-gradient-from: #075985f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #07598500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900 {
  --tw-gradient-from: #0c4a6e var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/0 {
  --tw-gradient-from: #0c4a6e00 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/10 {
  --tw-gradient-from: #0c4a6e1a var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/100 {
  --tw-gradient-from: #0c4a6e var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/20 {
  --tw-gradient-from: #0c4a6e33 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/25 {
  --tw-gradient-from: #0c4a6e40 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/30 {
  --tw-gradient-from: #0c4a6e4d var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/40 {
  --tw-gradient-from: #0c4a6e66 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/5 {
  --tw-gradient-from: #0c4a6e0d var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/50 {
  --tw-gradient-from: #0c4a6e80 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/60 {
  --tw-gradient-from: #0c4a6e99 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/70 {
  --tw-gradient-from: #0c4a6eb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/75 {
  --tw-gradient-from: #0c4a6ebf var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/80 {
  --tw-gradient-from: #0c4a6ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/90 {
  --tw-gradient-from: #0c4a6ee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-900\/95 {
  --tw-gradient-from: #0c4a6ef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c4a6e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950 {
  --tw-gradient-from: #082f49 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/0 {
  --tw-gradient-from: #082f4900 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/10 {
  --tw-gradient-from: #082f491a var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/100 {
  --tw-gradient-from: #082f49 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/20 {
  --tw-gradient-from: #082f4933 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/25 {
  --tw-gradient-from: #082f4940 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/30 {
  --tw-gradient-from: #082f494d var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/40 {
  --tw-gradient-from: #082f4966 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/5 {
  --tw-gradient-from: #082f490d var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/50 {
  --tw-gradient-from: #082f4980 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/60 {
  --tw-gradient-from: #082f4999 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/70 {
  --tw-gradient-from: #082f49b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/75 {
  --tw-gradient-from: #082f49bf var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/80 {
  --tw-gradient-from: #082f49cc var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/90 {
  --tw-gradient-from: #082f49e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-950\/95 {
  --tw-gradient-from: #082f49f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #082f4900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100 {
  --tw-gradient-from: #f1f5f9 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/0 {
  --tw-gradient-from: #f1f5f900 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/10 {
  --tw-gradient-from: #f1f5f91a var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/100 {
  --tw-gradient-from: #f1f5f9 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/20 {
  --tw-gradient-from: #f1f5f933 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/25 {
  --tw-gradient-from: #f1f5f940 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/30 {
  --tw-gradient-from: #f1f5f94d var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/40 {
  --tw-gradient-from: #f1f5f966 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/5 {
  --tw-gradient-from: #f1f5f90d var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/50 {
  --tw-gradient-from: #f1f5f980 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/60 {
  --tw-gradient-from: #f1f5f999 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/70 {
  --tw-gradient-from: #f1f5f9b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/75 {
  --tw-gradient-from: #f1f5f9bf var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/80 {
  --tw-gradient-from: #f1f5f9cc var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/90 {
  --tw-gradient-from: #f1f5f9e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-100\/95 {
  --tw-gradient-from: #f1f5f9f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1f5f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200 {
  --tw-gradient-from: #e2e8f0 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/0 {
  --tw-gradient-from: #e2e8f000 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/10 {
  --tw-gradient-from: #e2e8f01a var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/100 {
  --tw-gradient-from: #e2e8f0 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/20 {
  --tw-gradient-from: #e2e8f033 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/25 {
  --tw-gradient-from: #e2e8f040 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/30 {
  --tw-gradient-from: #e2e8f04d var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/40 {
  --tw-gradient-from: #e2e8f066 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/5 {
  --tw-gradient-from: #e2e8f00d var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/50 {
  --tw-gradient-from: #e2e8f080 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/60 {
  --tw-gradient-from: #e2e8f099 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/70 {
  --tw-gradient-from: #e2e8f0b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/75 {
  --tw-gradient-from: #e2e8f0bf var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/80 {
  --tw-gradient-from: #e2e8f0cc var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/90 {
  --tw-gradient-from: #e2e8f0e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/95 {
  --tw-gradient-from: #e2e8f0f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #e2e8f000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300 {
  --tw-gradient-from: #cbd5e1 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/0 {
  --tw-gradient-from: #cbd5e100 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/10 {
  --tw-gradient-from: #cbd5e11a var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/100 {
  --tw-gradient-from: #cbd5e1 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/20 {
  --tw-gradient-from: #cbd5e133 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/25 {
  --tw-gradient-from: #cbd5e140 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/30 {
  --tw-gradient-from: #cbd5e14d var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/40 {
  --tw-gradient-from: #cbd5e166 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/5 {
  --tw-gradient-from: #cbd5e10d var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/50 {
  --tw-gradient-from: #cbd5e180 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/60 {
  --tw-gradient-from: #cbd5e199 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/70 {
  --tw-gradient-from: #cbd5e1b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/75 {
  --tw-gradient-from: #cbd5e1bf var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/80 {
  --tw-gradient-from: #cbd5e1cc var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/90 {
  --tw-gradient-from: #cbd5e1e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-300\/95 {
  --tw-gradient-from: #cbd5e1f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #cbd5e100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400 {
  --tw-gradient-from: #94a3b8 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/0 {
  --tw-gradient-from: #94a3b800 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/10 {
  --tw-gradient-from: #94a3b81a var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/100 {
  --tw-gradient-from: #94a3b8 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/20 {
  --tw-gradient-from: #94a3b833 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/25 {
  --tw-gradient-from: #94a3b840 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/30 {
  --tw-gradient-from: #94a3b84d var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/40 {
  --tw-gradient-from: #94a3b866 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/5 {
  --tw-gradient-from: #94a3b80d var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/50 {
  --tw-gradient-from: #94a3b880 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/60 {
  --tw-gradient-from: #94a3b899 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/70 {
  --tw-gradient-from: #94a3b8b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/75 {
  --tw-gradient-from: #94a3b8bf var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/80 {
  --tw-gradient-from: #94a3b8cc var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/90 {
  --tw-gradient-from: #94a3b8e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-400\/95 {
  --tw-gradient-from: #94a3b8f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50 {
  --tw-gradient-from: #f8fafc var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/0 {
  --tw-gradient-from: #f8fafc00 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/10 {
  --tw-gradient-from: #f8fafc1a var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/100 {
  --tw-gradient-from: #f8fafc var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/20 {
  --tw-gradient-from: #f8fafc33 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/25 {
  --tw-gradient-from: #f8fafc40 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/30 {
  --tw-gradient-from: #f8fafc4d var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/40 {
  --tw-gradient-from: #f8fafc66 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/5 {
  --tw-gradient-from: #f8fafc0d var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/50 {
  --tw-gradient-from: #f8fafc80 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/60 {
  --tw-gradient-from: #f8fafc99 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/70 {
  --tw-gradient-from: #f8fafcb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/75 {
  --tw-gradient-from: #f8fafcbf var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/80 {
  --tw-gradient-from: #f8fafccc var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/90 {
  --tw-gradient-from: #f8fafce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50\/95 {
  --tw-gradient-from: #f8fafcf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500 {
  --tw-gradient-from: #64748b var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/0 {
  --tw-gradient-from: #64748b00 var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/10 {
  --tw-gradient-from: #64748b1a var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/100 {
  --tw-gradient-from: #64748b var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/20 {
  --tw-gradient-from: #64748b33 var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/25 {
  --tw-gradient-from: #64748b40 var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/30 {
  --tw-gradient-from: #64748b4d var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/40 {
  --tw-gradient-from: #64748b66 var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/5 {
  --tw-gradient-from: #64748b0d var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/50 {
  --tw-gradient-from: #64748b80 var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/60 {
  --tw-gradient-from: #64748b99 var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/70 {
  --tw-gradient-from: #64748bb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/75 {
  --tw-gradient-from: #64748bbf var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/80 {
  --tw-gradient-from: #64748bcc var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/90 {
  --tw-gradient-from: #64748be6 var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-500\/95 {
  --tw-gradient-from: #64748bf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #64748b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600 {
  --tw-gradient-from: #475569 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/0 {
  --tw-gradient-from: #47556900 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/10 {
  --tw-gradient-from: #4755691a var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/100 {
  --tw-gradient-from: #475569 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/20 {
  --tw-gradient-from: #47556933 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/25 {
  --tw-gradient-from: #47556940 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/30 {
  --tw-gradient-from: #4755694d var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/40 {
  --tw-gradient-from: #47556966 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/5 {
  --tw-gradient-from: #4755690d var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/50 {
  --tw-gradient-from: #47556980 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/60 {
  --tw-gradient-from: #47556999 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/70 {
  --tw-gradient-from: #475569b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/75 {
  --tw-gradient-from: #475569bf var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/80 {
  --tw-gradient-from: #475569cc var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/90 {
  --tw-gradient-from: #475569e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-600\/95 {
  --tw-gradient-from: #475569f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #47556900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700 {
  --tw-gradient-from: #334155 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/0 {
  --tw-gradient-from: #33415500 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/10 {
  --tw-gradient-from: #3341551a var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/100 {
  --tw-gradient-from: #334155 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/20 {
  --tw-gradient-from: #33415533 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/25 {
  --tw-gradient-from: #33415540 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/30 {
  --tw-gradient-from: #3341554d var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/40 {
  --tw-gradient-from: #33415566 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/5 {
  --tw-gradient-from: #3341550d var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/50 {
  --tw-gradient-from: #33415580 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/60 {
  --tw-gradient-from: #33415599 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/70 {
  --tw-gradient-from: #334155b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/75 {
  --tw-gradient-from: #334155bf var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/80 {
  --tw-gradient-from: #334155cc var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/90 {
  --tw-gradient-from: #334155e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-700\/95 {
  --tw-gradient-from: #334155f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800 {
  --tw-gradient-from: #1e293b var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/0 {
  --tw-gradient-from: #1e293b00 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/10 {
  --tw-gradient-from: #1e293b1a var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/100 {
  --tw-gradient-from: #1e293b var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/20 {
  --tw-gradient-from: #1e293b33 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/25 {
  --tw-gradient-from: #1e293b40 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/30 {
  --tw-gradient-from: #1e293b4d var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/40 {
  --tw-gradient-from: #1e293b66 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/5 {
  --tw-gradient-from: #1e293b0d var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/50 {
  --tw-gradient-from: #1e293b80 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/60 {
  --tw-gradient-from: #1e293b99 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/70 {
  --tw-gradient-from: #1e293bb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/75 {
  --tw-gradient-from: #1e293bbf var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/80 {
  --tw-gradient-from: #1e293bcc var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/90 {
  --tw-gradient-from: #1e293be6 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-800\/95 {
  --tw-gradient-from: #1e293bf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #1e293b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900 {
  --tw-gradient-from: #0f172a var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/0 {
  --tw-gradient-from: #0f172a00 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/10 {
  --tw-gradient-from: #0f172a1a var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/100 {
  --tw-gradient-from: #0f172a var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/20 {
  --tw-gradient-from: #0f172a33 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/25 {
  --tw-gradient-from: #0f172a40 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/30 {
  --tw-gradient-from: #0f172a4d var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/40 {
  --tw-gradient-from: #0f172a66 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/5 {
  --tw-gradient-from: #0f172a0d var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/50 {
  --tw-gradient-from: #0f172a80 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/60 {
  --tw-gradient-from: #0f172a99 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/70 {
  --tw-gradient-from: #0f172ab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/75 {
  --tw-gradient-from: #0f172abf var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/80 {
  --tw-gradient-from: #0f172acc var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/90 {
  --tw-gradient-from: #0f172ae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-900\/95 {
  --tw-gradient-from: #0f172af2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950 {
  --tw-gradient-from: #020617 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/0 {
  --tw-gradient-from: #02061700 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/10 {
  --tw-gradient-from: #0206171a var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/100 {
  --tw-gradient-from: #020617 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/20 {
  --tw-gradient-from: #02061733 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/25 {
  --tw-gradient-from: #02061740 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/30 {
  --tw-gradient-from: #0206174d var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/40 {
  --tw-gradient-from: #02061766 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/5 {
  --tw-gradient-from: #0206170d var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/50 {
  --tw-gradient-from: #02061780 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/60 {
  --tw-gradient-from: #02061799 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/70 {
  --tw-gradient-from: #020617b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/75 {
  --tw-gradient-from: #020617bf var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/80 {
  --tw-gradient-from: #020617cc var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/90 {
  --tw-gradient-from: #020617e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-950\/95 {
  --tw-gradient-from: #020617f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100 {
  --tw-gradient-from: #f5f5f4 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/0 {
  --tw-gradient-from: #f5f5f400 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/10 {
  --tw-gradient-from: #f5f5f41a var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/100 {
  --tw-gradient-from: #f5f5f4 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/20 {
  --tw-gradient-from: #f5f5f433 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/25 {
  --tw-gradient-from: #f5f5f440 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/30 {
  --tw-gradient-from: #f5f5f44d var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/40 {
  --tw-gradient-from: #f5f5f466 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/5 {
  --tw-gradient-from: #f5f5f40d var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/50 {
  --tw-gradient-from: #f5f5f480 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/60 {
  --tw-gradient-from: #f5f5f499 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/70 {
  --tw-gradient-from: #f5f5f4b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/75 {
  --tw-gradient-from: #f5f5f4bf var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/80 {
  --tw-gradient-from: #f5f5f4cc var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/90 {
  --tw-gradient-from: #f5f5f4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-100\/95 {
  --tw-gradient-from: #f5f5f4f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f5f5f400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200 {
  --tw-gradient-from: #e7e5e4 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/0 {
  --tw-gradient-from: #e7e5e400 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/10 {
  --tw-gradient-from: #e7e5e41a var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/100 {
  --tw-gradient-from: #e7e5e4 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/20 {
  --tw-gradient-from: #e7e5e433 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/25 {
  --tw-gradient-from: #e7e5e440 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/30 {
  --tw-gradient-from: #e7e5e44d var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/40 {
  --tw-gradient-from: #e7e5e466 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/5 {
  --tw-gradient-from: #e7e5e40d var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/50 {
  --tw-gradient-from: #e7e5e480 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/60 {
  --tw-gradient-from: #e7e5e499 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/70 {
  --tw-gradient-from: #e7e5e4b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/75 {
  --tw-gradient-from: #e7e5e4bf var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/80 {
  --tw-gradient-from: #e7e5e4cc var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/90 {
  --tw-gradient-from: #e7e5e4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-200\/95 {
  --tw-gradient-from: #e7e5e4f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #e7e5e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300 {
  --tw-gradient-from: #d6d3d1 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/0 {
  --tw-gradient-from: #d6d3d100 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/10 {
  --tw-gradient-from: #d6d3d11a var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/100 {
  --tw-gradient-from: #d6d3d1 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/20 {
  --tw-gradient-from: #d6d3d133 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/25 {
  --tw-gradient-from: #d6d3d140 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/30 {
  --tw-gradient-from: #d6d3d14d var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/40 {
  --tw-gradient-from: #d6d3d166 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/5 {
  --tw-gradient-from: #d6d3d10d var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/50 {
  --tw-gradient-from: #d6d3d180 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/60 {
  --tw-gradient-from: #d6d3d199 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/70 {
  --tw-gradient-from: #d6d3d1b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/75 {
  --tw-gradient-from: #d6d3d1bf var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/80 {
  --tw-gradient-from: #d6d3d1cc var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/90 {
  --tw-gradient-from: #d6d3d1e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-300\/95 {
  --tw-gradient-from: #d6d3d1f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #d6d3d100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400 {
  --tw-gradient-from: #a8a29e var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/0 {
  --tw-gradient-from: #a8a29e00 var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/10 {
  --tw-gradient-from: #a8a29e1a var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/100 {
  --tw-gradient-from: #a8a29e var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/20 {
  --tw-gradient-from: #a8a29e33 var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/25 {
  --tw-gradient-from: #a8a29e40 var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/30 {
  --tw-gradient-from: #a8a29e4d var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/40 {
  --tw-gradient-from: #a8a29e66 var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/5 {
  --tw-gradient-from: #a8a29e0d var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/50 {
  --tw-gradient-from: #a8a29e80 var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/60 {
  --tw-gradient-from: #a8a29e99 var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/70 {
  --tw-gradient-from: #a8a29eb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/75 {
  --tw-gradient-from: #a8a29ebf var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/80 {
  --tw-gradient-from: #a8a29ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/90 {
  --tw-gradient-from: #a8a29ee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-400\/95 {
  --tw-gradient-from: #a8a29ef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #a8a29e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50 {
  --tw-gradient-from: #fafaf9 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/0 {
  --tw-gradient-from: #fafaf900 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/10 {
  --tw-gradient-from: #fafaf91a var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/100 {
  --tw-gradient-from: #fafaf9 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/20 {
  --tw-gradient-from: #fafaf933 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/25 {
  --tw-gradient-from: #fafaf940 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/30 {
  --tw-gradient-from: #fafaf94d var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/40 {
  --tw-gradient-from: #fafaf966 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/5 {
  --tw-gradient-from: #fafaf90d var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/50 {
  --tw-gradient-from: #fafaf980 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/60 {
  --tw-gradient-from: #fafaf999 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/70 {
  --tw-gradient-from: #fafaf9b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/75 {
  --tw-gradient-from: #fafaf9bf var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/80 {
  --tw-gradient-from: #fafaf9cc var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/90 {
  --tw-gradient-from: #fafaf9e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-50\/95 {
  --tw-gradient-from: #fafaf9f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafaf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500 {
  --tw-gradient-from: #78716c var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/0 {
  --tw-gradient-from: #78716c00 var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/10 {
  --tw-gradient-from: #78716c1a var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/100 {
  --tw-gradient-from: #78716c var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/20 {
  --tw-gradient-from: #78716c33 var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/25 {
  --tw-gradient-from: #78716c40 var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/30 {
  --tw-gradient-from: #78716c4d var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/40 {
  --tw-gradient-from: #78716c66 var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/5 {
  --tw-gradient-from: #78716c0d var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/50 {
  --tw-gradient-from: #78716c80 var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/60 {
  --tw-gradient-from: #78716c99 var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/70 {
  --tw-gradient-from: #78716cb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/75 {
  --tw-gradient-from: #78716cbf var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/80 {
  --tw-gradient-from: #78716ccc var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/90 {
  --tw-gradient-from: #78716ce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-500\/95 {
  --tw-gradient-from: #78716cf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #78716c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600 {
  --tw-gradient-from: #57534e var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/0 {
  --tw-gradient-from: #57534e00 var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/10 {
  --tw-gradient-from: #57534e1a var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/100 {
  --tw-gradient-from: #57534e var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/20 {
  --tw-gradient-from: #57534e33 var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/25 {
  --tw-gradient-from: #57534e40 var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/30 {
  --tw-gradient-from: #57534e4d var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/40 {
  --tw-gradient-from: #57534e66 var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/5 {
  --tw-gradient-from: #57534e0d var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/50 {
  --tw-gradient-from: #57534e80 var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/60 {
  --tw-gradient-from: #57534e99 var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/70 {
  --tw-gradient-from: #57534eb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/75 {
  --tw-gradient-from: #57534ebf var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/80 {
  --tw-gradient-from: #57534ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/90 {
  --tw-gradient-from: #57534ee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-600\/95 {
  --tw-gradient-from: #57534ef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #57534e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700 {
  --tw-gradient-from: #44403c var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/0 {
  --tw-gradient-from: #44403c00 var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/10 {
  --tw-gradient-from: #44403c1a var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/100 {
  --tw-gradient-from: #44403c var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/20 {
  --tw-gradient-from: #44403c33 var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/25 {
  --tw-gradient-from: #44403c40 var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/30 {
  --tw-gradient-from: #44403c4d var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/40 {
  --tw-gradient-from: #44403c66 var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/5 {
  --tw-gradient-from: #44403c0d var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/50 {
  --tw-gradient-from: #44403c80 var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/60 {
  --tw-gradient-from: #44403c99 var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/70 {
  --tw-gradient-from: #44403cb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/75 {
  --tw-gradient-from: #44403cbf var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/80 {
  --tw-gradient-from: #44403ccc var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/90 {
  --tw-gradient-from: #44403ce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-700\/95 {
  --tw-gradient-from: #44403cf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #44403c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800 {
  --tw-gradient-from: #292524 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/0 {
  --tw-gradient-from: #29252400 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/10 {
  --tw-gradient-from: #2925241a var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/100 {
  --tw-gradient-from: #292524 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/20 {
  --tw-gradient-from: #29252433 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/25 {
  --tw-gradient-from: #29252440 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/30 {
  --tw-gradient-from: #2925244d var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/40 {
  --tw-gradient-from: #29252466 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/5 {
  --tw-gradient-from: #2925240d var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/50 {
  --tw-gradient-from: #29252480 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/60 {
  --tw-gradient-from: #29252499 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/70 {
  --tw-gradient-from: #292524b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/75 {
  --tw-gradient-from: #292524bf var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/80 {
  --tw-gradient-from: #292524cc var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/90 {
  --tw-gradient-from: #292524e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-800\/95 {
  --tw-gradient-from: #292524f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #29252400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900 {
  --tw-gradient-from: #1c1917 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/0 {
  --tw-gradient-from: #1c191700 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/10 {
  --tw-gradient-from: #1c19171a var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/100 {
  --tw-gradient-from: #1c1917 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/20 {
  --tw-gradient-from: #1c191733 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/25 {
  --tw-gradient-from: #1c191740 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/30 {
  --tw-gradient-from: #1c19174d var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/40 {
  --tw-gradient-from: #1c191766 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/5 {
  --tw-gradient-from: #1c19170d var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/50 {
  --tw-gradient-from: #1c191780 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/60 {
  --tw-gradient-from: #1c191799 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/70 {
  --tw-gradient-from: #1c1917b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/75 {
  --tw-gradient-from: #1c1917bf var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/80 {
  --tw-gradient-from: #1c1917cc var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/90 {
  --tw-gradient-from: #1c1917e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-900\/95 {
  --tw-gradient-from: #1c1917f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #1c191700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950 {
  --tw-gradient-from: #0c0a09 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/0 {
  --tw-gradient-from: #0c0a0900 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/10 {
  --tw-gradient-from: #0c0a091a var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/100 {
  --tw-gradient-from: #0c0a09 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/20 {
  --tw-gradient-from: #0c0a0933 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/25 {
  --tw-gradient-from: #0c0a0940 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/30 {
  --tw-gradient-from: #0c0a094d var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/40 {
  --tw-gradient-from: #0c0a0966 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/5 {
  --tw-gradient-from: #0c0a090d var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/50 {
  --tw-gradient-from: #0c0a0980 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/60 {
  --tw-gradient-from: #0c0a0999 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/70 {
  --tw-gradient-from: #0c0a09b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/75 {
  --tw-gradient-from: #0c0a09bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/80 {
  --tw-gradient-from: #0c0a09cc var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/90 {
  --tw-gradient-from: #0c0a09e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stone-950\/95 {
  --tw-gradient-from: #0c0a09f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0c0a0900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100 {
  --tw-gradient-from: #ccfbf1 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/0 {
  --tw-gradient-from: #ccfbf100 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/10 {
  --tw-gradient-from: #ccfbf11a var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/100 {
  --tw-gradient-from: #ccfbf1 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/20 {
  --tw-gradient-from: #ccfbf133 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/25 {
  --tw-gradient-from: #ccfbf140 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/30 {
  --tw-gradient-from: #ccfbf14d var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/40 {
  --tw-gradient-from: #ccfbf166 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/5 {
  --tw-gradient-from: #ccfbf10d var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/50 {
  --tw-gradient-from: #ccfbf180 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/60 {
  --tw-gradient-from: #ccfbf199 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/70 {
  --tw-gradient-from: #ccfbf1b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/75 {
  --tw-gradient-from: #ccfbf1bf var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/80 {
  --tw-gradient-from: #ccfbf1cc var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/90 {
  --tw-gradient-from: #ccfbf1e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-100\/95 {
  --tw-gradient-from: #ccfbf1f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #ccfbf100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200 {
  --tw-gradient-from: #99f6e4 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/0 {
  --tw-gradient-from: #99f6e400 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/10 {
  --tw-gradient-from: #99f6e41a var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/100 {
  --tw-gradient-from: #99f6e4 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/20 {
  --tw-gradient-from: #99f6e433 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/25 {
  --tw-gradient-from: #99f6e440 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/30 {
  --tw-gradient-from: #99f6e44d var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/40 {
  --tw-gradient-from: #99f6e466 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/5 {
  --tw-gradient-from: #99f6e40d var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/50 {
  --tw-gradient-from: #99f6e480 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/60 {
  --tw-gradient-from: #99f6e499 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/70 {
  --tw-gradient-from: #99f6e4b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/75 {
  --tw-gradient-from: #99f6e4bf var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/80 {
  --tw-gradient-from: #99f6e4cc var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/90 {
  --tw-gradient-from: #99f6e4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-200\/95 {
  --tw-gradient-from: #99f6e4f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #99f6e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300 {
  --tw-gradient-from: #5eead4 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/0 {
  --tw-gradient-from: #5eead400 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/10 {
  --tw-gradient-from: #5eead41a var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/100 {
  --tw-gradient-from: #5eead4 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/20 {
  --tw-gradient-from: #5eead433 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/25 {
  --tw-gradient-from: #5eead440 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/30 {
  --tw-gradient-from: #5eead44d var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/40 {
  --tw-gradient-from: #5eead466 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/5 {
  --tw-gradient-from: #5eead40d var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/50 {
  --tw-gradient-from: #5eead480 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/60 {
  --tw-gradient-from: #5eead499 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/70 {
  --tw-gradient-from: #5eead4b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/75 {
  --tw-gradient-from: #5eead4bf var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/80 {
  --tw-gradient-from: #5eead4cc var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/90 {
  --tw-gradient-from: #5eead4e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-300\/95 {
  --tw-gradient-from: #5eead4f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #5eead400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400 {
  --tw-gradient-from: #2dd4bf var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/0 {
  --tw-gradient-from: #2dd4bf00 var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/10 {
  --tw-gradient-from: #2dd4bf1a var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/100 {
  --tw-gradient-from: #2dd4bf var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/20 {
  --tw-gradient-from: #2dd4bf33 var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/25 {
  --tw-gradient-from: #2dd4bf40 var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/30 {
  --tw-gradient-from: #2dd4bf4d var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/40 {
  --tw-gradient-from: #2dd4bf66 var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/5 {
  --tw-gradient-from: #2dd4bf0d var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/50 {
  --tw-gradient-from: #2dd4bf80 var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/60 {
  --tw-gradient-from: #2dd4bf99 var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/70 {
  --tw-gradient-from: #2dd4bfb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/75 {
  --tw-gradient-from: #2dd4bfbf var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/80 {
  --tw-gradient-from: #2dd4bfcc var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/90 {
  --tw-gradient-from: #2dd4bfe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-400\/95 {
  --tw-gradient-from: #2dd4bff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #2dd4bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50 {
  --tw-gradient-from: #f0fdfa var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/0 {
  --tw-gradient-from: #f0fdfa00 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/10 {
  --tw-gradient-from: #f0fdfa1a var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/100 {
  --tw-gradient-from: #f0fdfa var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/20 {
  --tw-gradient-from: #f0fdfa33 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/25 {
  --tw-gradient-from: #f0fdfa40 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/30 {
  --tw-gradient-from: #f0fdfa4d var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/40 {
  --tw-gradient-from: #f0fdfa66 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/5 {
  --tw-gradient-from: #f0fdfa0d var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/50 {
  --tw-gradient-from: #f0fdfa80 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/60 {
  --tw-gradient-from: #f0fdfa99 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/70 {
  --tw-gradient-from: #f0fdfab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/75 {
  --tw-gradient-from: #f0fdfabf var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/80 {
  --tw-gradient-from: #f0fdfacc var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/90 {
  --tw-gradient-from: #f0fdfae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-50\/95 {
  --tw-gradient-from: #f0fdfaf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f0fdfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500 {
  --tw-gradient-from: #14b8a6 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/0 {
  --tw-gradient-from: #14b8a600 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/10 {
  --tw-gradient-from: #14b8a61a var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/100 {
  --tw-gradient-from: #14b8a6 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/20 {
  --tw-gradient-from: #14b8a633 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/25 {
  --tw-gradient-from: #14b8a640 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/30 {
  --tw-gradient-from: #14b8a64d var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/40 {
  --tw-gradient-from: #14b8a666 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/5 {
  --tw-gradient-from: #14b8a60d var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/50 {
  --tw-gradient-from: #14b8a680 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/60 {
  --tw-gradient-from: #14b8a699 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/70 {
  --tw-gradient-from: #14b8a6b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/75 {
  --tw-gradient-from: #14b8a6bf var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/80 {
  --tw-gradient-from: #14b8a6cc var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/90 {
  --tw-gradient-from: #14b8a6e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-500\/95 {
  --tw-gradient-from: #14b8a6f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #14b8a600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600 {
  --tw-gradient-from: #0d9488 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/0 {
  --tw-gradient-from: #0d948800 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/10 {
  --tw-gradient-from: #0d94881a var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/100 {
  --tw-gradient-from: #0d9488 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/20 {
  --tw-gradient-from: #0d948833 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/25 {
  --tw-gradient-from: #0d948840 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/30 {
  --tw-gradient-from: #0d94884d var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/40 {
  --tw-gradient-from: #0d948866 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/5 {
  --tw-gradient-from: #0d94880d var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/50 {
  --tw-gradient-from: #0d948880 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/60 {
  --tw-gradient-from: #0d948899 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/70 {
  --tw-gradient-from: #0d9488b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/75 {
  --tw-gradient-from: #0d9488bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/80 {
  --tw-gradient-from: #0d9488cc var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/90 {
  --tw-gradient-from: #0d9488e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600\/95 {
  --tw-gradient-from: #0d9488f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0d948800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700 {
  --tw-gradient-from: #0f766e var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/0 {
  --tw-gradient-from: #0f766e00 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/10 {
  --tw-gradient-from: #0f766e1a var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/100 {
  --tw-gradient-from: #0f766e var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/20 {
  --tw-gradient-from: #0f766e33 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/25 {
  --tw-gradient-from: #0f766e40 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/30 {
  --tw-gradient-from: #0f766e4d var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/40 {
  --tw-gradient-from: #0f766e66 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/5 {
  --tw-gradient-from: #0f766e0d var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/50 {
  --tw-gradient-from: #0f766e80 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/60 {
  --tw-gradient-from: #0f766e99 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/70 {
  --tw-gradient-from: #0f766eb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/75 {
  --tw-gradient-from: #0f766ebf var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/80 {
  --tw-gradient-from: #0f766ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/90 {
  --tw-gradient-from: #0f766ee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-700\/95 {
  --tw-gradient-from: #0f766ef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f766e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800 {
  --tw-gradient-from: #115e59 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/0 {
  --tw-gradient-from: #115e5900 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/10 {
  --tw-gradient-from: #115e591a var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/100 {
  --tw-gradient-from: #115e59 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/20 {
  --tw-gradient-from: #115e5933 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/25 {
  --tw-gradient-from: #115e5940 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/30 {
  --tw-gradient-from: #115e594d var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/40 {
  --tw-gradient-from: #115e5966 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/5 {
  --tw-gradient-from: #115e590d var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/50 {
  --tw-gradient-from: #115e5980 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/60 {
  --tw-gradient-from: #115e5999 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/70 {
  --tw-gradient-from: #115e59b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/75 {
  --tw-gradient-from: #115e59bf var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/80 {
  --tw-gradient-from: #115e59cc var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/90 {
  --tw-gradient-from: #115e59e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-800\/95 {
  --tw-gradient-from: #115e59f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #115e5900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900 {
  --tw-gradient-from: #134e4a var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/0 {
  --tw-gradient-from: #134e4a00 var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/10 {
  --tw-gradient-from: #134e4a1a var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/100 {
  --tw-gradient-from: #134e4a var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/20 {
  --tw-gradient-from: #134e4a33 var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/25 {
  --tw-gradient-from: #134e4a40 var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/30 {
  --tw-gradient-from: #134e4a4d var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/40 {
  --tw-gradient-from: #134e4a66 var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/5 {
  --tw-gradient-from: #134e4a0d var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/50 {
  --tw-gradient-from: #134e4a80 var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/60 {
  --tw-gradient-from: #134e4a99 var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/70 {
  --tw-gradient-from: #134e4ab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/75 {
  --tw-gradient-from: #134e4abf var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/80 {
  --tw-gradient-from: #134e4acc var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/90 {
  --tw-gradient-from: #134e4ae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-900\/95 {
  --tw-gradient-from: #134e4af2 var(--tw-gradient-from-position);
  --tw-gradient-to: #134e4a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950 {
  --tw-gradient-from: #042f2e var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/0 {
  --tw-gradient-from: #042f2e00 var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/10 {
  --tw-gradient-from: #042f2e1a var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/100 {
  --tw-gradient-from: #042f2e var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/20 {
  --tw-gradient-from: #042f2e33 var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/25 {
  --tw-gradient-from: #042f2e40 var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/30 {
  --tw-gradient-from: #042f2e4d var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/40 {
  --tw-gradient-from: #042f2e66 var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/5 {
  --tw-gradient-from: #042f2e0d var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/50 {
  --tw-gradient-from: #042f2e80 var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/60 {
  --tw-gradient-from: #042f2e99 var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/70 {
  --tw-gradient-from: #042f2eb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/75 {
  --tw-gradient-from: #042f2ebf var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/80 {
  --tw-gradient-from: #042f2ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/90 {
  --tw-gradient-from: #042f2ee6 var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-950\/95 {
  --tw-gradient-from: #042f2ef2 var(--tw-gradient-from-position);
  --tw-gradient-to: #042f2e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/0 {
  --tw-gradient-from: #0000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/10 {
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/100 {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/20 {
  --tw-gradient-from: #0003 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/25 {
  --tw-gradient-from: #00000040 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/30 {
  --tw-gradient-from: #0000004d var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/40 {
  --tw-gradient-from: #0006 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/5 {
  --tw-gradient-from: #0000000d var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/50 {
  --tw-gradient-from: #00000080 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/60 {
  --tw-gradient-from: #0009 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/70 {
  --tw-gradient-from: #000000b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/75 {
  --tw-gradient-from: #000000bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/80 {
  --tw-gradient-from: #000c var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/90 {
  --tw-gradient-from: #000000e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent\/95 {
  --tw-gradient-from: #000000f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet {
  --tw-gradient-from: hsl(var(--violet) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/0 {
  --tw-gradient-from: hsl(var(--violet) / 0) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/10 {
  --tw-gradient-from: hsl(var(--violet) / .1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/100 {
  --tw-gradient-from: hsl(var(--violet) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/20 {
  --tw-gradient-from: hsl(var(--violet) / .2) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/25 {
  --tw-gradient-from: hsl(var(--violet) / .25) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/30 {
  --tw-gradient-from: hsl(var(--violet) / .3) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/40 {
  --tw-gradient-from: hsl(var(--violet) / .4) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/5 {
  --tw-gradient-from: hsl(var(--violet) / .05) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/50 {
  --tw-gradient-from: hsl(var(--violet) / .5) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/60 {
  --tw-gradient-from: hsl(var(--violet) / .6) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/70 {
  --tw-gradient-from: hsl(var(--violet) / .7) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/75 {
  --tw-gradient-from: hsl(var(--violet) / .75) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/80 {
  --tw-gradient-from: hsl(var(--violet) / .8) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/90 {
  --tw-gradient-from: hsl(var(--violet) / .9) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-violet\/95 {
  --tw-gradient-from: hsl(var(--violet) / .95) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--violet) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/0 {
  --tw-gradient-from: #fff0 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/10 {
  --tw-gradient-from: #ffffff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/100 {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/20 {
  --tw-gradient-from: #fff3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/25 {
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/30 {
  --tw-gradient-from: #ffffff4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/40 {
  --tw-gradient-from: #fff6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/5 {
  --tw-gradient-from: #ffffff0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/50 {
  --tw-gradient-from: #ffffff80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/60 {
  --tw-gradient-from: #fff9 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/70 {
  --tw-gradient-from: #ffffffb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/75 {
  --tw-gradient-from: #ffffffbf var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/80 {
  --tw-gradient-from: #fffc var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/90 {
  --tw-gradient-from: #ffffffe6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/95 {
  --tw-gradient-from: #fffffff2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow {
  --tw-gradient-from: hsl(var(--yellow) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/0 {
  --tw-gradient-from: hsl(var(--yellow) / 0) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/10 {
  --tw-gradient-from: hsl(var(--yellow) / .1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/100 {
  --tw-gradient-from: hsl(var(--yellow) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/20 {
  --tw-gradient-from: hsl(var(--yellow) / .2) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/25 {
  --tw-gradient-from: hsl(var(--yellow) / .25) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/30 {
  --tw-gradient-from: hsl(var(--yellow) / .3) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/40 {
  --tw-gradient-from: hsl(var(--yellow) / .4) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/5 {
  --tw-gradient-from: hsl(var(--yellow) / .05) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/50 {
  --tw-gradient-from: hsl(var(--yellow) / .5) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/60 {
  --tw-gradient-from: hsl(var(--yellow) / .6) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/70 {
  --tw-gradient-from: hsl(var(--yellow) / .7) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/75 {
  --tw-gradient-from: hsl(var(--yellow) / .75) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/80 {
  --tw-gradient-from: hsl(var(--yellow) / .8) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/90 {
  --tw-gradient-from: hsl(var(--yellow) / .9) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow\/95 {
  --tw-gradient-from: hsl(var(--yellow) / .95) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--yellow) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100 {
  --tw-gradient-from: #f4f4f5 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/0 {
  --tw-gradient-from: #f4f4f500 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/10 {
  --tw-gradient-from: #f4f4f51a var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/100 {
  --tw-gradient-from: #f4f4f5 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/20 {
  --tw-gradient-from: #f4f4f533 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/25 {
  --tw-gradient-from: #f4f4f540 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/30 {
  --tw-gradient-from: #f4f4f54d var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/40 {
  --tw-gradient-from: #f4f4f566 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/5 {
  --tw-gradient-from: #f4f4f50d var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/50 {
  --tw-gradient-from: #f4f4f580 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/60 {
  --tw-gradient-from: #f4f4f599 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/70 {
  --tw-gradient-from: #f4f4f5b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/75 {
  --tw-gradient-from: #f4f4f5bf var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/80 {
  --tw-gradient-from: #f4f4f5cc var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/90 {
  --tw-gradient-from: #f4f4f5e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100\/95 {
  --tw-gradient-from: #f4f4f5f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #f4f4f500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200 {
  --tw-gradient-from: #e4e4e7 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/0 {
  --tw-gradient-from: #e4e4e700 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/10 {
  --tw-gradient-from: #e4e4e71a var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/100 {
  --tw-gradient-from: #e4e4e7 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/20 {
  --tw-gradient-from: #e4e4e733 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/25 {
  --tw-gradient-from: #e4e4e740 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/30 {
  --tw-gradient-from: #e4e4e74d var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/40 {
  --tw-gradient-from: #e4e4e766 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/5 {
  --tw-gradient-from: #e4e4e70d var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/50 {
  --tw-gradient-from: #e4e4e780 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/60 {
  --tw-gradient-from: #e4e4e799 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/70 {
  --tw-gradient-from: #e4e4e7b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/75 {
  --tw-gradient-from: #e4e4e7bf var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/80 {
  --tw-gradient-from: #e4e4e7cc var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/90 {
  --tw-gradient-from: #e4e4e7e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-200\/95 {
  --tw-gradient-from: #e4e4e7f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #e4e4e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300 {
  --tw-gradient-from: #d4d4d8 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/0 {
  --tw-gradient-from: #d4d4d800 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/10 {
  --tw-gradient-from: #d4d4d81a var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/100 {
  --tw-gradient-from: #d4d4d8 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/20 {
  --tw-gradient-from: #d4d4d833 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/25 {
  --tw-gradient-from: #d4d4d840 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/30 {
  --tw-gradient-from: #d4d4d84d var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/40 {
  --tw-gradient-from: #d4d4d866 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/5 {
  --tw-gradient-from: #d4d4d80d var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/50 {
  --tw-gradient-from: #d4d4d880 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/60 {
  --tw-gradient-from: #d4d4d899 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/70 {
  --tw-gradient-from: #d4d4d8b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/75 {
  --tw-gradient-from: #d4d4d8bf var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/80 {
  --tw-gradient-from: #d4d4d8cc var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/90 {
  --tw-gradient-from: #d4d4d8e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-300\/95 {
  --tw-gradient-from: #d4d4d8f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #d4d4d800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400 {
  --tw-gradient-from: #a1a1aa var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/0 {
  --tw-gradient-from: #a1a1aa00 var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/10 {
  --tw-gradient-from: #a1a1aa1a var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/100 {
  --tw-gradient-from: #a1a1aa var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/20 {
  --tw-gradient-from: #a1a1aa33 var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/25 {
  --tw-gradient-from: #a1a1aa40 var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/30 {
  --tw-gradient-from: #a1a1aa4d var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/40 {
  --tw-gradient-from: #a1a1aa66 var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/5 {
  --tw-gradient-from: #a1a1aa0d var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/50 {
  --tw-gradient-from: #a1a1aa80 var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/60 {
  --tw-gradient-from: #a1a1aa99 var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/70 {
  --tw-gradient-from: #a1a1aab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/75 {
  --tw-gradient-from: #a1a1aabf var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/80 {
  --tw-gradient-from: #a1a1aacc var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/90 {
  --tw-gradient-from: #a1a1aae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-400\/95 {
  --tw-gradient-from: #a1a1aaf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #a1a1aa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50 {
  --tw-gradient-from: #fafafa var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/0 {
  --tw-gradient-from: #fafafa00 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/10 {
  --tw-gradient-from: #fafafa1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/100 {
  --tw-gradient-from: #fafafa var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/20 {
  --tw-gradient-from: #fafafa33 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/25 {
  --tw-gradient-from: #fafafa40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/30 {
  --tw-gradient-from: #fafafa4d var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/40 {
  --tw-gradient-from: #fafafa66 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/5 {
  --tw-gradient-from: #fafafa0d var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/50 {
  --tw-gradient-from: #fafafa80 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/60 {
  --tw-gradient-from: #fafafa99 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/70 {
  --tw-gradient-from: #fafafab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/75 {
  --tw-gradient-from: #fafafabf var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/80 {
  --tw-gradient-from: #fafafacc var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/90 {
  --tw-gradient-from: #fafafae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-50\/95 {
  --tw-gradient-from: #fafafaf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500 {
  --tw-gradient-from: #71717a var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/0 {
  --tw-gradient-from: #71717a00 var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/10 {
  --tw-gradient-from: #71717a1a var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/100 {
  --tw-gradient-from: #71717a var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/20 {
  --tw-gradient-from: #71717a33 var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/25 {
  --tw-gradient-from: #71717a40 var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/30 {
  --tw-gradient-from: #71717a4d var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/40 {
  --tw-gradient-from: #71717a66 var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/5 {
  --tw-gradient-from: #71717a0d var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/50 {
  --tw-gradient-from: #71717a80 var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/60 {
  --tw-gradient-from: #71717a99 var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/70 {
  --tw-gradient-from: #71717ab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/75 {
  --tw-gradient-from: #71717abf var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/80 {
  --tw-gradient-from: #71717acc var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/90 {
  --tw-gradient-from: #71717ae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-500\/95 {
  --tw-gradient-from: #71717af2 var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600 {
  --tw-gradient-from: #52525b var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/0 {
  --tw-gradient-from: #52525b00 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/10 {
  --tw-gradient-from: #52525b1a var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/100 {
  --tw-gradient-from: #52525b var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/20 {
  --tw-gradient-from: #52525b33 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/25 {
  --tw-gradient-from: #52525b40 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/30 {
  --tw-gradient-from: #52525b4d var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/40 {
  --tw-gradient-from: #52525b66 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/5 {
  --tw-gradient-from: #52525b0d var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/50 {
  --tw-gradient-from: #52525b80 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/60 {
  --tw-gradient-from: #52525b99 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/70 {
  --tw-gradient-from: #52525bb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/75 {
  --tw-gradient-from: #52525bbf var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/80 {
  --tw-gradient-from: #52525bcc var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/90 {
  --tw-gradient-from: #52525be6 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-600\/95 {
  --tw-gradient-from: #52525bf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #52525b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700 {
  --tw-gradient-from: #3f3f46 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/0 {
  --tw-gradient-from: #3f3f4600 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/10 {
  --tw-gradient-from: #3f3f461a var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/100 {
  --tw-gradient-from: #3f3f46 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/20 {
  --tw-gradient-from: #3f3f4633 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/25 {
  --tw-gradient-from: #3f3f4640 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/30 {
  --tw-gradient-from: #3f3f464d var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/40 {
  --tw-gradient-from: #3f3f4666 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/5 {
  --tw-gradient-from: #3f3f460d var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/50 {
  --tw-gradient-from: #3f3f4680 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/60 {
  --tw-gradient-from: #3f3f4699 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/70 {
  --tw-gradient-from: #3f3f46b3 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/75 {
  --tw-gradient-from: #3f3f46bf var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/80 {
  --tw-gradient-from: #3f3f46cc var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/90 {
  --tw-gradient-from: #3f3f46e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-700\/95 {
  --tw-gradient-from: #3f3f46f2 var(--tw-gradient-from-position);
  --tw-gradient-to: #3f3f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800 {
  --tw-gradient-from: #27272a var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/0 {
  --tw-gradient-from: #27272a00 var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/10 {
  --tw-gradient-from: #27272a1a var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/100 {
  --tw-gradient-from: #27272a var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/20 {
  --tw-gradient-from: #27272a33 var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/25 {
  --tw-gradient-from: #27272a40 var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/30 {
  --tw-gradient-from: #27272a4d var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/40 {
  --tw-gradient-from: #27272a66 var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/5 {
  --tw-gradient-from: #27272a0d var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/50 {
  --tw-gradient-from: #27272a80 var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/60 {
  --tw-gradient-from: #27272a99 var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/70 {
  --tw-gradient-from: #27272ab3 var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/75 {
  --tw-gradient-from: #27272abf var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/80 {
  --tw-gradient-from: #27272acc var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/90 {
  --tw-gradient-from: #27272ae6 var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800\/95 {
  --tw-gradient-from: #27272af2 var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900 {
  --tw-gradient-from: #18181b var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/0 {
  --tw-gradient-from: #18181b00 var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/10 {
  --tw-gradient-from: #18181b1a var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/100 {
  --tw-gradient-from: #18181b var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/20 {
  --tw-gradient-from: #18181b33 var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/25 {
  --tw-gradient-from: #18181b40 var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/30 {
  --tw-gradient-from: #18181b4d var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/40 {
  --tw-gradient-from: #18181b66 var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/5 {
  --tw-gradient-from: #18181b0d var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/50 {
  --tw-gradient-from: #18181b80 var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/60 {
  --tw-gradient-from: #18181b99 var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/70 {
  --tw-gradient-from: #18181bb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/75 {
  --tw-gradient-from: #18181bbf var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/80 {
  --tw-gradient-from: #18181bcc var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/90 {
  --tw-gradient-from: #18181be6 var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-900\/95 {
  --tw-gradient-from: #18181bf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #18181b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950 {
  --tw-gradient-from: #09090b var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/0 {
  --tw-gradient-from: #09090b00 var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/10 {
  --tw-gradient-from: #09090b1a var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/100 {
  --tw-gradient-from: #09090b var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/20 {
  --tw-gradient-from: #09090b33 var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/25 {
  --tw-gradient-from: #09090b40 var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/30 {
  --tw-gradient-from: #09090b4d var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/40 {
  --tw-gradient-from: #09090b66 var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/5 {
  --tw-gradient-from: #09090b0d var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/50 {
  --tw-gradient-from: #09090b80 var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/60 {
  --tw-gradient-from: #09090b99 var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/70 {
  --tw-gradient-from: #09090bb3 var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/75 {
  --tw-gradient-from: #09090bbf var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/80 {
  --tw-gradient-from: #09090bcc var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/90 {
  --tw-gradient-from: #09090be6 var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950\/95 {
  --tw-gradient-from: #09090bf2 var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-0\% {
  --tw-gradient-from-position: 0%;
}

.from-10\% {
  --tw-gradient-from-position: 10%;
}

.from-100\% {
  --tw-gradient-from-position: 100%;
}

.from-15\% {
  --tw-gradient-from-position: 15%;
}

.from-20\% {
  --tw-gradient-from-position: 20%;
}

.from-25\% {
  --tw-gradient-from-position: 25%;
}

.from-30\% {
  --tw-gradient-from-position: 30%;
}

.from-35\% {
  --tw-gradient-from-position: 35%;
}

.from-40\% {
  --tw-gradient-from-position: 40%;
}

.from-45\% {
  --tw-gradient-from-position: 45%;
}

.from-5\% {
  --tw-gradient-from-position: 5%;
}

.from-50\% {
  --tw-gradient-from-position: 50%;
}

.from-55\% {
  --tw-gradient-from-position: 55%;
}

.from-60\% {
  --tw-gradient-from-position: 60%;
}

.from-65\% {
  --tw-gradient-from-position: 65%;
}

.from-70\% {
  --tw-gradient-from-position: 70%;
}

.from-75\% {
  --tw-gradient-from-position: 75%;
}

.from-80\% {
  --tw-gradient-from-position: 80%;
}

.from-85\% {
  --tw-gradient-from-position: 85%;
}

.from-90\% {
  --tw-gradient-from-position: 90%;
}

.from-95\% {
  --tw-gradient-from-position: 95%;
}

.via-amber-100 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/0 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c700 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/10 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c71a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/100 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/20 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c733 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/25 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c740 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/30 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c74d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/40 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c766 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/5 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c70d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/50 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c780 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/60 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c799 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/70 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/75 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/80 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/90 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-100\/95 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/0 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/10 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/100 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/20 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/25 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/30 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/40 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/5 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/50 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/60 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/70 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68ab3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/75 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68abf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/80 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68acc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/90 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68ae6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-200\/95 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fde68af2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/0 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/10 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/100 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/20 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/25 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/30 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/40 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/5 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/50 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/60 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/70 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34db3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/75 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34dbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/80 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34dcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/90 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34de6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-300\/95 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34df2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/0 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf2400 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/10 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf241a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/100 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/20 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf2433 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/25 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf2440 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/30 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf244d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/40 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf2466 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/5 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf240d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/50 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf2480 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/60 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf2499 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/70 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/75 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/80 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/90 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-400\/95 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/0 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/10 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/100 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/20 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/25 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/30 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/40 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/5 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/50 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/60 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/70 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbebb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/75 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbebbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/80 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbebcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/90 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbebe6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-50\/95 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fffbebf2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/0 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/10 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/100 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/20 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/25 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/30 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/40 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/5 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/50 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/60 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/70 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0bb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/75 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0bbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/80 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0bcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/90 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0be6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-500\/95 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0bf2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d97706 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/0 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9770600 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/10 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d977061a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/100 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d97706 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/20 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9770633 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/25 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9770640 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/30 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d977064d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/40 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9770666 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/5 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d977060d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/50 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9770680 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/60 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9770699 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/70 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d97706b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/75 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d97706bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/80 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d97706cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/90 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d97706e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-600\/95 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d97706f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b45309 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/0 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b4530900 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/10 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b453091a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/100 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b45309 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/20 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b4530933 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/25 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b4530940 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/30 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b453094d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/40 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b4530966 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/5 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b453090d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/50 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b4530980 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/60 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b4530999 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/70 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b45309b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/75 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b45309bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/80 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b45309cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/90 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b45309e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-700\/95 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #b45309f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/0 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/10 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/100 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/20 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/25 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/30 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/40 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/5 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/50 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/60 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400e99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/70 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400eb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/75 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400ebf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/80 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400ecc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/90 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400ee6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-800\/95 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #92400ef2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/0 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/10 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/100 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/20 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/25 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/30 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/40 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/5 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/50 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/60 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350f99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/70 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350fb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/75 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350fbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/80 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350fcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/90 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350fe6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-900\/95 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #78350ff2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a03 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/0 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a0300 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/10 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a031a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/100 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a03 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/20 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a0333 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/25 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a0340 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/30 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a034d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/40 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a0366 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/5 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a030d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/50 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a0380 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/60 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a0399 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/70 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a03b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/75 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a03bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/80 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a03cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/90 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a03e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-amber-950\/95 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #451a03f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/0 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae500 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/10 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae51a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/100 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/20 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae533 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/25 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae540 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/30 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae54d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/40 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae566 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/5 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae50d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/50 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae580 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/60 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae599 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/70 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/75 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/80 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/90 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-100\/95 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/0 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d000 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/10 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d01a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/100 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/20 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d033 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/25 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d040 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/30 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d04d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/40 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d066 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/5 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d00d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/50 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d080 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/60 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d099 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/70 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/75 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/80 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/90 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-200\/95 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/0 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b700 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/10 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b71a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/100 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/20 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b733 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/25 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b740 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/30 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b74d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/40 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b766 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/5 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b70d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/50 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b780 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/60 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b799 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/70 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/75 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/80 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/90 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-300\/95 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d399 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/0 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d39900 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/10 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d3991a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/100 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d399 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/20 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d39933 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/25 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d39940 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/30 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d3994d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/40 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d39966 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/5 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d3990d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/50 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d39980 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/60 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d39999 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/70 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d399b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/75 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d399bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/80 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d399cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/90 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d399e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-400\/95 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d399f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/0 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf500 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/10 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf51a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/100 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/20 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf533 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/25 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf540 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/30 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf54d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/40 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf566 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/5 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf50d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/50 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf580 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/60 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf599 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/70 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/75 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/80 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/90 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-50\/95 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b981 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/0 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b98100 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/10 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b9811a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/100 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b981 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/20 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b98133 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/25 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b98140 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/30 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b9814d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/40 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b98166 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/5 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b9810d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/50 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b98180 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/60 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b98199 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/70 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b981b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/75 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b981bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/80 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b981cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/90 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b981e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-500\/95 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #10b981f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #059669 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/0 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #05966900 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/10 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0596691a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/100 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #059669 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/20 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #05966933 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/25 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #05966940 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/30 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0596694d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/40 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #05966966 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/5 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0596690d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/50 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #05966980 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/60 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #05966999 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/70 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #059669b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/75 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #059669bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/80 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #059669cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/90 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #059669e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-600\/95 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #059669f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #047857 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/0 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #04785700 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/10 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0478571a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/100 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #047857 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/20 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #04785733 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/25 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #04785740 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/30 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0478574d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/40 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #04785766 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/5 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0478570d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/50 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #04785780 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/60 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #04785799 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/70 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #047857b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/75 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #047857bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/80 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #047857cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/90 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #047857e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-700\/95 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #047857f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f46 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/0 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f4600 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/10 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f461a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/100 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f46 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/20 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f4633 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/25 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f4640 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/30 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f464d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/40 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f4666 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/5 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f460d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/50 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f4680 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/60 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f4699 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/70 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f46b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/75 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f46bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/80 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f46cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/90 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f46e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-800\/95 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #065f46f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/0 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/10 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/100 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/20 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/25 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/30 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/40 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/5 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/50 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/60 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/70 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3bb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/75 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3bbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/80 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3bcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/90 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3be6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-900\/95 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #064e3bf2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c22 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/0 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c2200 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/10 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c221a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/100 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c22 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/20 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c2233 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/25 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c2240 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/30 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c224d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/40 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c2266 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/5 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c220d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/50 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c2280 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/60 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c2299 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/70 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c22b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/75 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c22bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/80 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c22cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/90 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c22e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-emerald-950\/95 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #022c22f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/0 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/10 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/100 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/20 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/25 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/30 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/40 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/5 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/50 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/60 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/70 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccbb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/75 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccbbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/80 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccbcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/90 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccbe6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-100\/95 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccbf2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/0 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/10 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/100 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/20 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/25 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/30 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/40 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/5 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/50 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/60 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/70 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99db3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/75 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99dbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/80 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99dcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/90 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99de6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-200\/95 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99df2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef264 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/0 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef26400 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/10 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef2641a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/100 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef264 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/20 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef26433 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/25 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef26440 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/30 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef2644d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/40 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef26466 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/5 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef2640d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/50 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef26480 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/60 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef26499 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/70 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef264b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/75 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef264bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/80 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef264cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/90 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef264e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-300\/95 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #bef264f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e635 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/0 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e63500 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/10 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e6351a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/100 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e635 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/20 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e63533 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/25 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e63540 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/30 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e6354d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/40 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e63566 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/5 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e6350d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/50 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e63580 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/60 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e63599 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/70 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e635b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/75 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e635bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/80 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e635cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/90 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e635e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-400\/95 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a3e635f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/0 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee700 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/10 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee71a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/100 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/20 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee733 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/25 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee740 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/30 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee74d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/40 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee766 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/5 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee70d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/50 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee780 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/60 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee799 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/70 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee7b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/75 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee7bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/80 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee7cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/90 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee7e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-50\/95 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee7f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc16 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/0 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc1600 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/10 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc161a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/100 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc16 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/20 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc1633 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/25 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc1640 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/30 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc164d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/40 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc1666 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/5 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc160d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/50 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc1680 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/60 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc1699 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/70 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc16b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/75 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc16bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/80 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc16cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/90 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc16e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-500\/95 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #84cc16f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/0 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/10 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/100 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/20 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/25 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/30 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/40 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/5 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/50 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/60 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/70 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30db3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/75 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30dbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/80 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30dcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/90 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30de6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-600\/95 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #65a30df2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/0 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/10 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/100 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/20 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/25 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/30 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/40 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/5 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/50 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/60 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/70 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0fb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/75 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0fbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/80 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0fcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/90 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0fe6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-700\/95 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0ff2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f6212 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/0 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f621200 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/10 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f62121a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/100 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f6212 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/20 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f621233 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/25 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f621240 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/30 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f62124d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/40 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f621266 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/5 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f62120d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/50 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f621280 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/60 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f621299 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/70 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f6212b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/75 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f6212bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/80 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f6212cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/90 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f6212e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-800\/95 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3f6212f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #365314 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/0 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #36531400 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/10 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3653141a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/100 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #365314 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/20 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #36531433 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/25 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #36531440 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/30 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3653144d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/40 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #36531466 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/5 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3653140d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/50 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #36531480 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/60 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #36531499 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/70 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #365314b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/75 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #365314bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/80 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #365314cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/90 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #365314e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-900\/95 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #365314f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e05 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/0 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e0500 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/10 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e051a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/100 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e05 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/20 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e0533 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/25 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e0540 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/30 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e054d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/40 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e0566 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/5 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e050d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/50 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e0580 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/60 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e0599 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/70 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e05b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/75 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e05bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/80 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e05cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/90 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e05e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-lime-950\/95 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e05f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/0 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f300 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/10 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f31a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/100 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/20 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f333 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/25 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f340 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/30 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f34d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/40 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f366 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/5 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f30d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/50 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f380 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/60 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f399 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/70 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/75 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/80 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/90 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-100\/95 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/0 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe800 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/10 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe81a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/100 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/20 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe833 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/25 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe840 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/30 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe84d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/40 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe866 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/5 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe80d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/50 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe880 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/60 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe899 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/70 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/75 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/80 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/90 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-200\/95 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/0 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d400 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/10 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d41a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/100 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/20 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d433 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/25 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d440 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/30 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d44d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/40 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d466 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/5 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d40d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/50 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d480 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/60 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d499 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/70 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/75 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/80 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/90 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-300\/95 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/0 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b600 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/10 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b61a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/100 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/20 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b633 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/25 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b640 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/30 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b64d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/40 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b666 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/5 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b60d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/50 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b680 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/60 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b699 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/70 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/75 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/80 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/90 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-400\/95 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/0 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f800 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/10 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f81a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/100 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/20 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f833 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/25 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f840 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/30 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f84d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/40 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f866 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/5 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f80d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/50 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f880 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/60 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f899 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/70 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/75 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/80 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/90 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-50\/95 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/0 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec489900 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/10 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec48991a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/100 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/20 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec489933 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/25 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec489940 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/30 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec48994d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/40 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec489966 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/5 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec48990d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/50 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec489980 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/60 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec489999 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/70 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/75 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/80 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/90 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-500\/95 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db2777 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/0 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db277700 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/10 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db27771a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/100 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db2777 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/20 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db277733 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/25 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db277740 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/30 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db27774d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/40 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db277766 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/5 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db27770d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/50 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db277780 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/60 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db277799 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/70 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db2777b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/75 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db2777bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/80 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db2777cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/90 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db2777e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-600\/95 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #db2777f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/0 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/10 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/100 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/20 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/25 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/30 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/40 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/5 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/50 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/60 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185d99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/70 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185db3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/75 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185dbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/80 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185dcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/90 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185de6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-700\/95 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #be185df2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/0 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/10 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/100 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/20 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/25 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/30 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/40 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/5 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/50 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/60 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/70 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174db3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/75 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174dbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/80 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174dcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/90 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174de6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-800\/95 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9d174df2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #831843 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/0 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #83184300 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/10 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #8318431a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/100 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #831843 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/20 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #83184333 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/25 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #83184340 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/30 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #8318434d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/40 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #83184366 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/5 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #8318430d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/50 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #83184380 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/60 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #83184399 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/70 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #831843b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/75 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #831843bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/80 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #831843cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/90 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #831843e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-900\/95 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #831843f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #500724 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/0 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #50072400 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/10 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #5007241a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/100 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #500724 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/20 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #50072433 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/25 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #50072440 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/30 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #5007244d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/40 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #50072466 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/5 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #5007240d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/50 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #50072480 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/60 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #50072499 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/70 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #500724b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/75 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #500724bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/80 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #500724cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/90 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #500724e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-pink-950\/95 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #500724f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / 1) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/0 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / 0) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/10 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .1) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/100 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / 1) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/20 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .2) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/25 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .25) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/30 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .3) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/40 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .4) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/5 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .05) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/50 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .5) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/60 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .6) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/70 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .7) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/75 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .75) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/80 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .8) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/90 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .9) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-primary\/95 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--primary) / .95) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/0 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/10 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/100 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/20 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/25 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/30 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/40 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/5 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/50 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/60 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/70 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ffb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/75 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ffbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/80 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ffcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/90 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ffe6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-100\/95 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8fff2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/0 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/10 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/100 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/20 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/25 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/30 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/40 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/5 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/50 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/60 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/70 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ffb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/75 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ffbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/80 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ffcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/90 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ffe6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-200\/95 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5fff2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/0 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/10 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/100 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/20 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/25 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/30 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/40 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/5 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/50 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/60 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/70 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4feb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/75 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4febf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/80 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fecc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/90 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fee6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-300\/95 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fef2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/0 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/10 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/100 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/20 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/25 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/30 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/40 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/5 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/50 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/60 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/70 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fcb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/75 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fcbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/80 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fccc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/90 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fce6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-400\/95 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #c084fcf2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/0 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/10 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/100 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/20 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/25 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/30 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/40 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/5 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/50 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/60 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/70 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ffb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/75 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ffbf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/80 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ffcc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/90 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ffe6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-50\/95 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #faf5fff2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/0 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f700 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/10 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f71a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/100 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/20 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f733 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/25 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f740 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/30 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f74d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/40 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f766 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/5 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f70d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/50 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f780 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/60 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f799 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/70 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f7b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/75 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f7bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/80 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f7cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/90 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f7e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500\/95 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f7f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/0 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/10 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/100 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/20 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/25 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/30 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/40 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/5 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/50 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/60 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/70 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333eab3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/75 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333eabf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/80 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333eacc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/90 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333eae6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-600\/95 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9333eaf2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/0 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce00 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/10 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce1a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/100 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/20 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce33 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/25 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce40 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/30 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce4d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/40 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce66 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/5 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce0d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/50 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce80 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/60 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/70 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ceb3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/75 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22cebf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/80 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22cecc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/90 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22cee6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-700\/95 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #7e22cef2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a8 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/0 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a800 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/10 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a81a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/100 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a8 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/20 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a833 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/25 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a840 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/30 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a84d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/40 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a866 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/5 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a80d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/50 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a880 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/60 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a899 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/70 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a8b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/75 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a8bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/80 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a8cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/90 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a8e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-800\/95 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a8f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c87 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/0 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c8700 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/10 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c871a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/100 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c87 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/20 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c8733 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/25 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c8740 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/30 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c874d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/40 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c8766 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/5 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c870d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/50 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c8780 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/60 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c8799 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/70 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c87b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/75 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c87bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/80 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c87cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/90 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c87e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-900\/95 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #581c87f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b0764 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/0 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b076400 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/10 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b07641a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/100 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b0764 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/20 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b076433 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/25 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b076440 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/30 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b07644d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/40 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b076466 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/5 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b07640d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/50 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b076480 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/60 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b076499 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/70 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b0764b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/75 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b0764bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/80 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b0764cc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/90 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b0764e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-950\/95 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #3b0764f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/0 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0000 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/10 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0000001a var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/100 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/20 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0003 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/25 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #00000040 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/30 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0000004d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/40 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0006 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/5 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0000000d var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/50 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #00000080 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/60 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #0009 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/70 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000000b3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/75 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000000bf var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/80 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000c var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/90 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000000e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-transparent\/95 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #000000f2 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-amber-100 {
  --tw-gradient-to: #fef3c7 var(--tw-gradient-to-position);
}

.to-amber-100\/0 {
  --tw-gradient-to: #fef3c700 var(--tw-gradient-to-position);
}

.to-amber-100\/10 {
  --tw-gradient-to: #fef3c71a var(--tw-gradient-to-position);
}

.to-amber-100\/100 {
  --tw-gradient-to: #fef3c7 var(--tw-gradient-to-position);
}

.to-amber-100\/20 {
  --tw-gradient-to: #fef3c733 var(--tw-gradient-to-position);
}

.to-amber-100\/25 {
  --tw-gradient-to: #fef3c740 var(--tw-gradient-to-position);
}

.to-amber-100\/30 {
  --tw-gradient-to: #fef3c74d var(--tw-gradient-to-position);
}

.to-amber-100\/40 {
  --tw-gradient-to: #fef3c766 var(--tw-gradient-to-position);
}

.to-amber-100\/5 {
  --tw-gradient-to: #fef3c70d var(--tw-gradient-to-position);
}

.to-amber-100\/50 {
  --tw-gradient-to: #fef3c780 var(--tw-gradient-to-position);
}

.to-amber-100\/60 {
  --tw-gradient-to: #fef3c799 var(--tw-gradient-to-position);
}

.to-amber-100\/70 {
  --tw-gradient-to: #fef3c7b3 var(--tw-gradient-to-position);
}

.to-amber-100\/75 {
  --tw-gradient-to: #fef3c7bf var(--tw-gradient-to-position);
}

.to-amber-100\/80 {
  --tw-gradient-to: #fef3c7cc var(--tw-gradient-to-position);
}

.to-amber-100\/90 {
  --tw-gradient-to: #fef3c7e6 var(--tw-gradient-to-position);
}

.to-amber-100\/95 {
  --tw-gradient-to: #fef3c7f2 var(--tw-gradient-to-position);
}

.to-amber-200 {
  --tw-gradient-to: #fde68a var(--tw-gradient-to-position);
}

.to-amber-200\/0 {
  --tw-gradient-to: #fde68a00 var(--tw-gradient-to-position);
}

.to-amber-200\/10 {
  --tw-gradient-to: #fde68a1a var(--tw-gradient-to-position);
}

.to-amber-200\/100 {
  --tw-gradient-to: #fde68a var(--tw-gradient-to-position);
}

.to-amber-200\/20 {
  --tw-gradient-to: #fde68a33 var(--tw-gradient-to-position);
}

.to-amber-200\/25 {
  --tw-gradient-to: #fde68a40 var(--tw-gradient-to-position);
}

.to-amber-200\/30 {
  --tw-gradient-to: #fde68a4d var(--tw-gradient-to-position);
}

.to-amber-200\/40 {
  --tw-gradient-to: #fde68a66 var(--tw-gradient-to-position);
}

.to-amber-200\/5 {
  --tw-gradient-to: #fde68a0d var(--tw-gradient-to-position);
}

.to-amber-200\/50 {
  --tw-gradient-to: #fde68a80 var(--tw-gradient-to-position);
}

.to-amber-200\/60 {
  --tw-gradient-to: #fde68a99 var(--tw-gradient-to-position);
}

.to-amber-200\/70 {
  --tw-gradient-to: #fde68ab3 var(--tw-gradient-to-position);
}

.to-amber-200\/75 {
  --tw-gradient-to: #fde68abf var(--tw-gradient-to-position);
}

.to-amber-200\/80 {
  --tw-gradient-to: #fde68acc var(--tw-gradient-to-position);
}

.to-amber-200\/90 {
  --tw-gradient-to: #fde68ae6 var(--tw-gradient-to-position);
}

.to-amber-200\/95 {
  --tw-gradient-to: #fde68af2 var(--tw-gradient-to-position);
}

.to-amber-300 {
  --tw-gradient-to: #fcd34d var(--tw-gradient-to-position);
}

.to-amber-300\/0 {
  --tw-gradient-to: #fcd34d00 var(--tw-gradient-to-position);
}

.to-amber-300\/10 {
  --tw-gradient-to: #fcd34d1a var(--tw-gradient-to-position);
}

.to-amber-300\/100 {
  --tw-gradient-to: #fcd34d var(--tw-gradient-to-position);
}

.to-amber-300\/20 {
  --tw-gradient-to: #fcd34d33 var(--tw-gradient-to-position);
}

.to-amber-300\/25 {
  --tw-gradient-to: #fcd34d40 var(--tw-gradient-to-position);
}

.to-amber-300\/30 {
  --tw-gradient-to: #fcd34d4d var(--tw-gradient-to-position);
}

.to-amber-300\/40 {
  --tw-gradient-to: #fcd34d66 var(--tw-gradient-to-position);
}

.to-amber-300\/5 {
  --tw-gradient-to: #fcd34d0d var(--tw-gradient-to-position);
}

.to-amber-300\/50 {
  --tw-gradient-to: #fcd34d80 var(--tw-gradient-to-position);
}

.to-amber-300\/60 {
  --tw-gradient-to: #fcd34d99 var(--tw-gradient-to-position);
}

.to-amber-300\/70 {
  --tw-gradient-to: #fcd34db3 var(--tw-gradient-to-position);
}

.to-amber-300\/75 {
  --tw-gradient-to: #fcd34dbf var(--tw-gradient-to-position);
}

.to-amber-300\/80 {
  --tw-gradient-to: #fcd34dcc var(--tw-gradient-to-position);
}

.to-amber-300\/90 {
  --tw-gradient-to: #fcd34de6 var(--tw-gradient-to-position);
}

.to-amber-300\/95 {
  --tw-gradient-to: #fcd34df2 var(--tw-gradient-to-position);
}

.to-amber-400 {
  --tw-gradient-to: #fbbf24 var(--tw-gradient-to-position);
}

.to-amber-400\/0 {
  --tw-gradient-to: #fbbf2400 var(--tw-gradient-to-position);
}

.to-amber-400\/10 {
  --tw-gradient-to: #fbbf241a var(--tw-gradient-to-position);
}

.to-amber-400\/100 {
  --tw-gradient-to: #fbbf24 var(--tw-gradient-to-position);
}

.to-amber-400\/20 {
  --tw-gradient-to: #fbbf2433 var(--tw-gradient-to-position);
}

.to-amber-400\/25 {
  --tw-gradient-to: #fbbf2440 var(--tw-gradient-to-position);
}

.to-amber-400\/30 {
  --tw-gradient-to: #fbbf244d var(--tw-gradient-to-position);
}

.to-amber-400\/40 {
  --tw-gradient-to: #fbbf2466 var(--tw-gradient-to-position);
}

.to-amber-400\/5 {
  --tw-gradient-to: #fbbf240d var(--tw-gradient-to-position);
}

.to-amber-400\/50 {
  --tw-gradient-to: #fbbf2480 var(--tw-gradient-to-position);
}

.to-amber-400\/60 {
  --tw-gradient-to: #fbbf2499 var(--tw-gradient-to-position);
}

.to-amber-400\/70 {
  --tw-gradient-to: #fbbf24b3 var(--tw-gradient-to-position);
}

.to-amber-400\/75 {
  --tw-gradient-to: #fbbf24bf var(--tw-gradient-to-position);
}

.to-amber-400\/80 {
  --tw-gradient-to: #fbbf24cc var(--tw-gradient-to-position);
}

.to-amber-400\/90 {
  --tw-gradient-to: #fbbf24e6 var(--tw-gradient-to-position);
}

.to-amber-400\/95 {
  --tw-gradient-to: #fbbf24f2 var(--tw-gradient-to-position);
}

.to-amber-50 {
  --tw-gradient-to: #fffbeb var(--tw-gradient-to-position);
}

.to-amber-50\/0 {
  --tw-gradient-to: #fffbeb00 var(--tw-gradient-to-position);
}

.to-amber-50\/10 {
  --tw-gradient-to: #fffbeb1a var(--tw-gradient-to-position);
}

.to-amber-50\/100 {
  --tw-gradient-to: #fffbeb var(--tw-gradient-to-position);
}

.to-amber-50\/20 {
  --tw-gradient-to: #fffbeb33 var(--tw-gradient-to-position);
}

.to-amber-50\/25 {
  --tw-gradient-to: #fffbeb40 var(--tw-gradient-to-position);
}

.to-amber-50\/30 {
  --tw-gradient-to: #fffbeb4d var(--tw-gradient-to-position);
}

.to-amber-50\/40 {
  --tw-gradient-to: #fffbeb66 var(--tw-gradient-to-position);
}

.to-amber-50\/5 {
  --tw-gradient-to: #fffbeb0d var(--tw-gradient-to-position);
}

.to-amber-50\/50 {
  --tw-gradient-to: #fffbeb80 var(--tw-gradient-to-position);
}

.to-amber-50\/60 {
  --tw-gradient-to: #fffbeb99 var(--tw-gradient-to-position);
}

.to-amber-50\/70 {
  --tw-gradient-to: #fffbebb3 var(--tw-gradient-to-position);
}

.to-amber-50\/75 {
  --tw-gradient-to: #fffbebbf var(--tw-gradient-to-position);
}

.to-amber-50\/80 {
  --tw-gradient-to: #fffbebcc var(--tw-gradient-to-position);
}

.to-amber-50\/90 {
  --tw-gradient-to: #fffbebe6 var(--tw-gradient-to-position);
}

.to-amber-50\/95 {
  --tw-gradient-to: #fffbebf2 var(--tw-gradient-to-position);
}

.to-amber-500 {
  --tw-gradient-to: #f59e0b var(--tw-gradient-to-position);
}

.to-amber-500\/0 {
  --tw-gradient-to: #f59e0b00 var(--tw-gradient-to-position);
}

.to-amber-500\/10 {
  --tw-gradient-to: #f59e0b1a var(--tw-gradient-to-position);
}

.to-amber-500\/100 {
  --tw-gradient-to: #f59e0b var(--tw-gradient-to-position);
}

.to-amber-500\/20 {
  --tw-gradient-to: #f59e0b33 var(--tw-gradient-to-position);
}

.to-amber-500\/25 {
  --tw-gradient-to: #f59e0b40 var(--tw-gradient-to-position);
}

.to-amber-500\/30 {
  --tw-gradient-to: #f59e0b4d var(--tw-gradient-to-position);
}

.to-amber-500\/40 {
  --tw-gradient-to: #f59e0b66 var(--tw-gradient-to-position);
}

.to-amber-500\/5 {
  --tw-gradient-to: #f59e0b0d var(--tw-gradient-to-position);
}

.to-amber-500\/50 {
  --tw-gradient-to: #f59e0b80 var(--tw-gradient-to-position);
}

.to-amber-500\/60 {
  --tw-gradient-to: #f59e0b99 var(--tw-gradient-to-position);
}

.to-amber-500\/70 {
  --tw-gradient-to: #f59e0bb3 var(--tw-gradient-to-position);
}

.to-amber-500\/75 {
  --tw-gradient-to: #f59e0bbf var(--tw-gradient-to-position);
}

.to-amber-500\/80 {
  --tw-gradient-to: #f59e0bcc var(--tw-gradient-to-position);
}

.to-amber-500\/90 {
  --tw-gradient-to: #f59e0be6 var(--tw-gradient-to-position);
}

.to-amber-500\/95 {
  --tw-gradient-to: #f59e0bf2 var(--tw-gradient-to-position);
}

.to-amber-600 {
  --tw-gradient-to: #d97706 var(--tw-gradient-to-position);
}

.to-amber-600\/0 {
  --tw-gradient-to: #d9770600 var(--tw-gradient-to-position);
}

.to-amber-600\/10 {
  --tw-gradient-to: #d977061a var(--tw-gradient-to-position);
}

.to-amber-600\/100 {
  --tw-gradient-to: #d97706 var(--tw-gradient-to-position);
}

.to-amber-600\/20 {
  --tw-gradient-to: #d9770633 var(--tw-gradient-to-position);
}

.to-amber-600\/25 {
  --tw-gradient-to: #d9770640 var(--tw-gradient-to-position);
}

.to-amber-600\/30 {
  --tw-gradient-to: #d977064d var(--tw-gradient-to-position);
}

.to-amber-600\/40 {
  --tw-gradient-to: #d9770666 var(--tw-gradient-to-position);
}

.to-amber-600\/5 {
  --tw-gradient-to: #d977060d var(--tw-gradient-to-position);
}

.to-amber-600\/50 {
  --tw-gradient-to: #d9770680 var(--tw-gradient-to-position);
}

.to-amber-600\/60 {
  --tw-gradient-to: #d9770699 var(--tw-gradient-to-position);
}

.to-amber-600\/70 {
  --tw-gradient-to: #d97706b3 var(--tw-gradient-to-position);
}

.to-amber-600\/75 {
  --tw-gradient-to: #d97706bf var(--tw-gradient-to-position);
}

.to-amber-600\/80 {
  --tw-gradient-to: #d97706cc var(--tw-gradient-to-position);
}

.to-amber-600\/90 {
  --tw-gradient-to: #d97706e6 var(--tw-gradient-to-position);
}

.to-amber-600\/95 {
  --tw-gradient-to: #d97706f2 var(--tw-gradient-to-position);
}

.to-amber-700 {
  --tw-gradient-to: #b45309 var(--tw-gradient-to-position);
}

.to-amber-700\/0 {
  --tw-gradient-to: #b4530900 var(--tw-gradient-to-position);
}

.to-amber-700\/10 {
  --tw-gradient-to: #b453091a var(--tw-gradient-to-position);
}

.to-amber-700\/100 {
  --tw-gradient-to: #b45309 var(--tw-gradient-to-position);
}

.to-amber-700\/20 {
  --tw-gradient-to: #b4530933 var(--tw-gradient-to-position);
}

.to-amber-700\/25 {
  --tw-gradient-to: #b4530940 var(--tw-gradient-to-position);
}

.to-amber-700\/30 {
  --tw-gradient-to: #b453094d var(--tw-gradient-to-position);
}

.to-amber-700\/40 {
  --tw-gradient-to: #b4530966 var(--tw-gradient-to-position);
}

.to-amber-700\/5 {
  --tw-gradient-to: #b453090d var(--tw-gradient-to-position);
}

.to-amber-700\/50 {
  --tw-gradient-to: #b4530980 var(--tw-gradient-to-position);
}

.to-amber-700\/60 {
  --tw-gradient-to: #b4530999 var(--tw-gradient-to-position);
}

.to-amber-700\/70 {
  --tw-gradient-to: #b45309b3 var(--tw-gradient-to-position);
}

.to-amber-700\/75 {
  --tw-gradient-to: #b45309bf var(--tw-gradient-to-position);
}

.to-amber-700\/80 {
  --tw-gradient-to: #b45309cc var(--tw-gradient-to-position);
}

.to-amber-700\/90 {
  --tw-gradient-to: #b45309e6 var(--tw-gradient-to-position);
}

.to-amber-700\/95 {
  --tw-gradient-to: #b45309f2 var(--tw-gradient-to-position);
}

.to-amber-800 {
  --tw-gradient-to: #92400e var(--tw-gradient-to-position);
}

.to-amber-800\/0 {
  --tw-gradient-to: #92400e00 var(--tw-gradient-to-position);
}

.to-amber-800\/10 {
  --tw-gradient-to: #92400e1a var(--tw-gradient-to-position);
}

.to-amber-800\/100 {
  --tw-gradient-to: #92400e var(--tw-gradient-to-position);
}

.to-amber-800\/20 {
  --tw-gradient-to: #92400e33 var(--tw-gradient-to-position);
}

.to-amber-800\/25 {
  --tw-gradient-to: #92400e40 var(--tw-gradient-to-position);
}

.to-amber-800\/30 {
  --tw-gradient-to: #92400e4d var(--tw-gradient-to-position);
}

.to-amber-800\/40 {
  --tw-gradient-to: #92400e66 var(--tw-gradient-to-position);
}

.to-amber-800\/5 {
  --tw-gradient-to: #92400e0d var(--tw-gradient-to-position);
}

.to-amber-800\/50 {
  --tw-gradient-to: #92400e80 var(--tw-gradient-to-position);
}

.to-amber-800\/60 {
  --tw-gradient-to: #92400e99 var(--tw-gradient-to-position);
}

.to-amber-800\/70 {
  --tw-gradient-to: #92400eb3 var(--tw-gradient-to-position);
}

.to-amber-800\/75 {
  --tw-gradient-to: #92400ebf var(--tw-gradient-to-position);
}

.to-amber-800\/80 {
  --tw-gradient-to: #92400ecc var(--tw-gradient-to-position);
}

.to-amber-800\/90 {
  --tw-gradient-to: #92400ee6 var(--tw-gradient-to-position);
}

.to-amber-800\/95 {
  --tw-gradient-to: #92400ef2 var(--tw-gradient-to-position);
}

.to-amber-900 {
  --tw-gradient-to: #78350f var(--tw-gradient-to-position);
}

.to-amber-900\/0 {
  --tw-gradient-to: #78350f00 var(--tw-gradient-to-position);
}

.to-amber-900\/10 {
  --tw-gradient-to: #78350f1a var(--tw-gradient-to-position);
}

.to-amber-900\/100 {
  --tw-gradient-to: #78350f var(--tw-gradient-to-position);
}

.to-amber-900\/20 {
  --tw-gradient-to: #78350f33 var(--tw-gradient-to-position);
}

.to-amber-900\/25 {
  --tw-gradient-to: #78350f40 var(--tw-gradient-to-position);
}

.to-amber-900\/30 {
  --tw-gradient-to: #78350f4d var(--tw-gradient-to-position);
}

.to-amber-900\/40 {
  --tw-gradient-to: #78350f66 var(--tw-gradient-to-position);
}

.to-amber-900\/5 {
  --tw-gradient-to: #78350f0d var(--tw-gradient-to-position);
}

.to-amber-900\/50 {
  --tw-gradient-to: #78350f80 var(--tw-gradient-to-position);
}

.to-amber-900\/60 {
  --tw-gradient-to: #78350f99 var(--tw-gradient-to-position);
}

.to-amber-900\/70 {
  --tw-gradient-to: #78350fb3 var(--tw-gradient-to-position);
}

.to-amber-900\/75 {
  --tw-gradient-to: #78350fbf var(--tw-gradient-to-position);
}

.to-amber-900\/80 {
  --tw-gradient-to: #78350fcc var(--tw-gradient-to-position);
}

.to-amber-900\/90 {
  --tw-gradient-to: #78350fe6 var(--tw-gradient-to-position);
}

.to-amber-900\/95 {
  --tw-gradient-to: #78350ff2 var(--tw-gradient-to-position);
}

.to-amber-950 {
  --tw-gradient-to: #451a03 var(--tw-gradient-to-position);
}

.to-amber-950\/0 {
  --tw-gradient-to: #451a0300 var(--tw-gradient-to-position);
}

.to-amber-950\/10 {
  --tw-gradient-to: #451a031a var(--tw-gradient-to-position);
}

.to-amber-950\/100 {
  --tw-gradient-to: #451a03 var(--tw-gradient-to-position);
}

.to-amber-950\/20 {
  --tw-gradient-to: #451a0333 var(--tw-gradient-to-position);
}

.to-amber-950\/25 {
  --tw-gradient-to: #451a0340 var(--tw-gradient-to-position);
}

.to-amber-950\/30 {
  --tw-gradient-to: #451a034d var(--tw-gradient-to-position);
}

.to-amber-950\/40 {
  --tw-gradient-to: #451a0366 var(--tw-gradient-to-position);
}

.to-amber-950\/5 {
  --tw-gradient-to: #451a030d var(--tw-gradient-to-position);
}

.to-amber-950\/50 {
  --tw-gradient-to: #451a0380 var(--tw-gradient-to-position);
}

.to-amber-950\/60 {
  --tw-gradient-to: #451a0399 var(--tw-gradient-to-position);
}

.to-amber-950\/70 {
  --tw-gradient-to: #451a03b3 var(--tw-gradient-to-position);
}

.to-amber-950\/75 {
  --tw-gradient-to: #451a03bf var(--tw-gradient-to-position);
}

.to-amber-950\/80 {
  --tw-gradient-to: #451a03cc var(--tw-gradient-to-position);
}

.to-amber-950\/90 {
  --tw-gradient-to: #451a03e6 var(--tw-gradient-to-position);
}

.to-amber-950\/95 {
  --tw-gradient-to: #451a03f2 var(--tw-gradient-to-position);
}

.to-emerald-100 {
  --tw-gradient-to: #d1fae5 var(--tw-gradient-to-position);
}

.to-emerald-100\/0 {
  --tw-gradient-to: #d1fae500 var(--tw-gradient-to-position);
}

.to-emerald-100\/10 {
  --tw-gradient-to: #d1fae51a var(--tw-gradient-to-position);
}

.to-emerald-100\/100 {
  --tw-gradient-to: #d1fae5 var(--tw-gradient-to-position);
}

.to-emerald-100\/20 {
  --tw-gradient-to: #d1fae533 var(--tw-gradient-to-position);
}

.to-emerald-100\/25 {
  --tw-gradient-to: #d1fae540 var(--tw-gradient-to-position);
}

.to-emerald-100\/30 {
  --tw-gradient-to: #d1fae54d var(--tw-gradient-to-position);
}

.to-emerald-100\/40 {
  --tw-gradient-to: #d1fae566 var(--tw-gradient-to-position);
}

.to-emerald-100\/5 {
  --tw-gradient-to: #d1fae50d var(--tw-gradient-to-position);
}

.to-emerald-100\/50 {
  --tw-gradient-to: #d1fae580 var(--tw-gradient-to-position);
}

.to-emerald-100\/60 {
  --tw-gradient-to: #d1fae599 var(--tw-gradient-to-position);
}

.to-emerald-100\/70 {
  --tw-gradient-to: #d1fae5b3 var(--tw-gradient-to-position);
}

.to-emerald-100\/75 {
  --tw-gradient-to: #d1fae5bf var(--tw-gradient-to-position);
}

.to-emerald-100\/80 {
  --tw-gradient-to: #d1fae5cc var(--tw-gradient-to-position);
}

.to-emerald-100\/90 {
  --tw-gradient-to: #d1fae5e6 var(--tw-gradient-to-position);
}

.to-emerald-100\/95 {
  --tw-gradient-to: #d1fae5f2 var(--tw-gradient-to-position);
}

.to-emerald-200 {
  --tw-gradient-to: #a7f3d0 var(--tw-gradient-to-position);
}

.to-emerald-200\/0 {
  --tw-gradient-to: #a7f3d000 var(--tw-gradient-to-position);
}

.to-emerald-200\/10 {
  --tw-gradient-to: #a7f3d01a var(--tw-gradient-to-position);
}

.to-emerald-200\/100 {
  --tw-gradient-to: #a7f3d0 var(--tw-gradient-to-position);
}

.to-emerald-200\/20 {
  --tw-gradient-to: #a7f3d033 var(--tw-gradient-to-position);
}

.to-emerald-200\/25 {
  --tw-gradient-to: #a7f3d040 var(--tw-gradient-to-position);
}

.to-emerald-200\/30 {
  --tw-gradient-to: #a7f3d04d var(--tw-gradient-to-position);
}

.to-emerald-200\/40 {
  --tw-gradient-to: #a7f3d066 var(--tw-gradient-to-position);
}

.to-emerald-200\/5 {
  --tw-gradient-to: #a7f3d00d var(--tw-gradient-to-position);
}

.to-emerald-200\/50 {
  --tw-gradient-to: #a7f3d080 var(--tw-gradient-to-position);
}

.to-emerald-200\/60 {
  --tw-gradient-to: #a7f3d099 var(--tw-gradient-to-position);
}

.to-emerald-200\/70 {
  --tw-gradient-to: #a7f3d0b3 var(--tw-gradient-to-position);
}

.to-emerald-200\/75 {
  --tw-gradient-to: #a7f3d0bf var(--tw-gradient-to-position);
}

.to-emerald-200\/80 {
  --tw-gradient-to: #a7f3d0cc var(--tw-gradient-to-position);
}

.to-emerald-200\/90 {
  --tw-gradient-to: #a7f3d0e6 var(--tw-gradient-to-position);
}

.to-emerald-200\/95 {
  --tw-gradient-to: #a7f3d0f2 var(--tw-gradient-to-position);
}

.to-emerald-300 {
  --tw-gradient-to: #6ee7b7 var(--tw-gradient-to-position);
}

.to-emerald-300\/0 {
  --tw-gradient-to: #6ee7b700 var(--tw-gradient-to-position);
}

.to-emerald-300\/10 {
  --tw-gradient-to: #6ee7b71a var(--tw-gradient-to-position);
}

.to-emerald-300\/100 {
  --tw-gradient-to: #6ee7b7 var(--tw-gradient-to-position);
}

.to-emerald-300\/20 {
  --tw-gradient-to: #6ee7b733 var(--tw-gradient-to-position);
}

.to-emerald-300\/25 {
  --tw-gradient-to: #6ee7b740 var(--tw-gradient-to-position);
}

.to-emerald-300\/30 {
  --tw-gradient-to: #6ee7b74d var(--tw-gradient-to-position);
}

.to-emerald-300\/40 {
  --tw-gradient-to: #6ee7b766 var(--tw-gradient-to-position);
}

.to-emerald-300\/5 {
  --tw-gradient-to: #6ee7b70d var(--tw-gradient-to-position);
}

.to-emerald-300\/50 {
  --tw-gradient-to: #6ee7b780 var(--tw-gradient-to-position);
}

.to-emerald-300\/60 {
  --tw-gradient-to: #6ee7b799 var(--tw-gradient-to-position);
}

.to-emerald-300\/70 {
  --tw-gradient-to: #6ee7b7b3 var(--tw-gradient-to-position);
}

.to-emerald-300\/75 {
  --tw-gradient-to: #6ee7b7bf var(--tw-gradient-to-position);
}

.to-emerald-300\/80 {
  --tw-gradient-to: #6ee7b7cc var(--tw-gradient-to-position);
}

.to-emerald-300\/90 {
  --tw-gradient-to: #6ee7b7e6 var(--tw-gradient-to-position);
}

.to-emerald-300\/95 {
  --tw-gradient-to: #6ee7b7f2 var(--tw-gradient-to-position);
}

.to-emerald-400 {
  --tw-gradient-to: #34d399 var(--tw-gradient-to-position);
}

.to-emerald-400\/0 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
}

.to-emerald-400\/10 {
  --tw-gradient-to: #34d3991a var(--tw-gradient-to-position);
}

.to-emerald-400\/100 {
  --tw-gradient-to: #34d399 var(--tw-gradient-to-position);
}

.to-emerald-400\/20 {
  --tw-gradient-to: #34d39933 var(--tw-gradient-to-position);
}

.to-emerald-400\/25 {
  --tw-gradient-to: #34d39940 var(--tw-gradient-to-position);
}

.to-emerald-400\/30 {
  --tw-gradient-to: #34d3994d var(--tw-gradient-to-position);
}

.to-emerald-400\/40 {
  --tw-gradient-to: #34d39966 var(--tw-gradient-to-position);
}

.to-emerald-400\/5 {
  --tw-gradient-to: #34d3990d var(--tw-gradient-to-position);
}

.to-emerald-400\/50 {
  --tw-gradient-to: #34d39980 var(--tw-gradient-to-position);
}

.to-emerald-400\/60 {
  --tw-gradient-to: #34d39999 var(--tw-gradient-to-position);
}

.to-emerald-400\/70 {
  --tw-gradient-to: #34d399b3 var(--tw-gradient-to-position);
}

.to-emerald-400\/75 {
  --tw-gradient-to: #34d399bf var(--tw-gradient-to-position);
}

.to-emerald-400\/80 {
  --tw-gradient-to: #34d399cc var(--tw-gradient-to-position);
}

.to-emerald-400\/90 {
  --tw-gradient-to: #34d399e6 var(--tw-gradient-to-position);
}

.to-emerald-400\/95 {
  --tw-gradient-to: #34d399f2 var(--tw-gradient-to-position);
}

.to-emerald-50 {
  --tw-gradient-to: #ecfdf5 var(--tw-gradient-to-position);
}

.to-emerald-50\/0 {
  --tw-gradient-to: #ecfdf500 var(--tw-gradient-to-position);
}

.to-emerald-50\/10 {
  --tw-gradient-to: #ecfdf51a var(--tw-gradient-to-position);
}

.to-emerald-50\/100 {
  --tw-gradient-to: #ecfdf5 var(--tw-gradient-to-position);
}

.to-emerald-50\/20 {
  --tw-gradient-to: #ecfdf533 var(--tw-gradient-to-position);
}

.to-emerald-50\/25 {
  --tw-gradient-to: #ecfdf540 var(--tw-gradient-to-position);
}

.to-emerald-50\/30 {
  --tw-gradient-to: #ecfdf54d var(--tw-gradient-to-position);
}

.to-emerald-50\/40 {
  --tw-gradient-to: #ecfdf566 var(--tw-gradient-to-position);
}

.to-emerald-50\/5 {
  --tw-gradient-to: #ecfdf50d var(--tw-gradient-to-position);
}

.to-emerald-50\/50 {
  --tw-gradient-to: #ecfdf580 var(--tw-gradient-to-position);
}

.to-emerald-50\/60 {
  --tw-gradient-to: #ecfdf599 var(--tw-gradient-to-position);
}

.to-emerald-50\/70 {
  --tw-gradient-to: #ecfdf5b3 var(--tw-gradient-to-position);
}

.to-emerald-50\/75 {
  --tw-gradient-to: #ecfdf5bf var(--tw-gradient-to-position);
}

.to-emerald-50\/80 {
  --tw-gradient-to: #ecfdf5cc var(--tw-gradient-to-position);
}

.to-emerald-50\/90 {
  --tw-gradient-to: #ecfdf5e6 var(--tw-gradient-to-position);
}

.to-emerald-50\/95 {
  --tw-gradient-to: #ecfdf5f2 var(--tw-gradient-to-position);
}

.to-emerald-500 {
  --tw-gradient-to: #10b981 var(--tw-gradient-to-position);
}

.to-emerald-500\/0 {
  --tw-gradient-to: #10b98100 var(--tw-gradient-to-position);
}

.to-emerald-500\/10 {
  --tw-gradient-to: #10b9811a var(--tw-gradient-to-position);
}

.to-emerald-500\/100 {
  --tw-gradient-to: #10b981 var(--tw-gradient-to-position);
}

.to-emerald-500\/20 {
  --tw-gradient-to: #10b98133 var(--tw-gradient-to-position);
}

.to-emerald-500\/25 {
  --tw-gradient-to: #10b98140 var(--tw-gradient-to-position);
}

.to-emerald-500\/30 {
  --tw-gradient-to: #10b9814d var(--tw-gradient-to-position);
}

.to-emerald-500\/40 {
  --tw-gradient-to: #10b98166 var(--tw-gradient-to-position);
}

.to-emerald-500\/5 {
  --tw-gradient-to: #10b9810d var(--tw-gradient-to-position);
}

.to-emerald-500\/50 {
  --tw-gradient-to: #10b98180 var(--tw-gradient-to-position);
}

.to-emerald-500\/60 {
  --tw-gradient-to: #10b98199 var(--tw-gradient-to-position);
}

.to-emerald-500\/70 {
  --tw-gradient-to: #10b981b3 var(--tw-gradient-to-position);
}

.to-emerald-500\/75 {
  --tw-gradient-to: #10b981bf var(--tw-gradient-to-position);
}

.to-emerald-500\/80 {
  --tw-gradient-to: #10b981cc var(--tw-gradient-to-position);
}

.to-emerald-500\/90 {
  --tw-gradient-to: #10b981e6 var(--tw-gradient-to-position);
}

.to-emerald-500\/95 {
  --tw-gradient-to: #10b981f2 var(--tw-gradient-to-position);
}

.to-emerald-600 {
  --tw-gradient-to: #059669 var(--tw-gradient-to-position);
}

.to-emerald-600\/0 {
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
}

.to-emerald-600\/10 {
  --tw-gradient-to: #0596691a var(--tw-gradient-to-position);
}

.to-emerald-600\/100 {
  --tw-gradient-to: #059669 var(--tw-gradient-to-position);
}

.to-emerald-600\/20 {
  --tw-gradient-to: #05966933 var(--tw-gradient-to-position);
}

.to-emerald-600\/25 {
  --tw-gradient-to: #05966940 var(--tw-gradient-to-position);
}

.to-emerald-600\/30 {
  --tw-gradient-to: #0596694d var(--tw-gradient-to-position);
}

.to-emerald-600\/40 {
  --tw-gradient-to: #05966966 var(--tw-gradient-to-position);
}

.to-emerald-600\/5 {
  --tw-gradient-to: #0596690d var(--tw-gradient-to-position);
}

.to-emerald-600\/50 {
  --tw-gradient-to: #05966980 var(--tw-gradient-to-position);
}

.to-emerald-600\/60 {
  --tw-gradient-to: #05966999 var(--tw-gradient-to-position);
}

.to-emerald-600\/70 {
  --tw-gradient-to: #059669b3 var(--tw-gradient-to-position);
}

.to-emerald-600\/75 {
  --tw-gradient-to: #059669bf var(--tw-gradient-to-position);
}

.to-emerald-600\/80 {
  --tw-gradient-to: #059669cc var(--tw-gradient-to-position);
}

.to-emerald-600\/90 {
  --tw-gradient-to: #059669e6 var(--tw-gradient-to-position);
}

.to-emerald-600\/95 {
  --tw-gradient-to: #059669f2 var(--tw-gradient-to-position);
}

.to-emerald-700 {
  --tw-gradient-to: #047857 var(--tw-gradient-to-position);
}

.to-emerald-700\/0 {
  --tw-gradient-to: #04785700 var(--tw-gradient-to-position);
}

.to-emerald-700\/10 {
  --tw-gradient-to: #0478571a var(--tw-gradient-to-position);
}

.to-emerald-700\/100 {
  --tw-gradient-to: #047857 var(--tw-gradient-to-position);
}

.to-emerald-700\/20 {
  --tw-gradient-to: #04785733 var(--tw-gradient-to-position);
}

.to-emerald-700\/25 {
  --tw-gradient-to: #04785740 var(--tw-gradient-to-position);
}

.to-emerald-700\/30 {
  --tw-gradient-to: #0478574d var(--tw-gradient-to-position);
}

.to-emerald-700\/40 {
  --tw-gradient-to: #04785766 var(--tw-gradient-to-position);
}

.to-emerald-700\/5 {
  --tw-gradient-to: #0478570d var(--tw-gradient-to-position);
}

.to-emerald-700\/50 {
  --tw-gradient-to: #04785780 var(--tw-gradient-to-position);
}

.to-emerald-700\/60 {
  --tw-gradient-to: #04785799 var(--tw-gradient-to-position);
}

.to-emerald-700\/70 {
  --tw-gradient-to: #047857b3 var(--tw-gradient-to-position);
}

.to-emerald-700\/75 {
  --tw-gradient-to: #047857bf var(--tw-gradient-to-position);
}

.to-emerald-700\/80 {
  --tw-gradient-to: #047857cc var(--tw-gradient-to-position);
}

.to-emerald-700\/90 {
  --tw-gradient-to: #047857e6 var(--tw-gradient-to-position);
}

.to-emerald-700\/95 {
  --tw-gradient-to: #047857f2 var(--tw-gradient-to-position);
}

.to-emerald-800 {
  --tw-gradient-to: #065f46 var(--tw-gradient-to-position);
}

.to-emerald-800\/0 {
  --tw-gradient-to: #065f4600 var(--tw-gradient-to-position);
}

.to-emerald-800\/10 {
  --tw-gradient-to: #065f461a var(--tw-gradient-to-position);
}

.to-emerald-800\/100 {
  --tw-gradient-to: #065f46 var(--tw-gradient-to-position);
}

.to-emerald-800\/20 {
  --tw-gradient-to: #065f4633 var(--tw-gradient-to-position);
}

.to-emerald-800\/25 {
  --tw-gradient-to: #065f4640 var(--tw-gradient-to-position);
}

.to-emerald-800\/30 {
  --tw-gradient-to: #065f464d var(--tw-gradient-to-position);
}

.to-emerald-800\/40 {
  --tw-gradient-to: #065f4666 var(--tw-gradient-to-position);
}

.to-emerald-800\/5 {
  --tw-gradient-to: #065f460d var(--tw-gradient-to-position);
}

.to-emerald-800\/50 {
  --tw-gradient-to: #065f4680 var(--tw-gradient-to-position);
}

.to-emerald-800\/60 {
  --tw-gradient-to: #065f4699 var(--tw-gradient-to-position);
}

.to-emerald-800\/70 {
  --tw-gradient-to: #065f46b3 var(--tw-gradient-to-position);
}

.to-emerald-800\/75 {
  --tw-gradient-to: #065f46bf var(--tw-gradient-to-position);
}

.to-emerald-800\/80 {
  --tw-gradient-to: #065f46cc var(--tw-gradient-to-position);
}

.to-emerald-800\/90 {
  --tw-gradient-to: #065f46e6 var(--tw-gradient-to-position);
}

.to-emerald-800\/95 {
  --tw-gradient-to: #065f46f2 var(--tw-gradient-to-position);
}

.to-emerald-900 {
  --tw-gradient-to: #064e3b var(--tw-gradient-to-position);
}

.to-emerald-900\/0 {
  --tw-gradient-to: #064e3b00 var(--tw-gradient-to-position);
}

.to-emerald-900\/10 {
  --tw-gradient-to: #064e3b1a var(--tw-gradient-to-position);
}

.to-emerald-900\/100 {
  --tw-gradient-to: #064e3b var(--tw-gradient-to-position);
}

.to-emerald-900\/20 {
  --tw-gradient-to: #064e3b33 var(--tw-gradient-to-position);
}

.to-emerald-900\/25 {
  --tw-gradient-to: #064e3b40 var(--tw-gradient-to-position);
}

.to-emerald-900\/30 {
  --tw-gradient-to: #064e3b4d var(--tw-gradient-to-position);
}

.to-emerald-900\/40 {
  --tw-gradient-to: #064e3b66 var(--tw-gradient-to-position);
}

.to-emerald-900\/5 {
  --tw-gradient-to: #064e3b0d var(--tw-gradient-to-position);
}

.to-emerald-900\/50 {
  --tw-gradient-to: #064e3b80 var(--tw-gradient-to-position);
}

.to-emerald-900\/60 {
  --tw-gradient-to: #064e3b99 var(--tw-gradient-to-position);
}

.to-emerald-900\/70 {
  --tw-gradient-to: #064e3bb3 var(--tw-gradient-to-position);
}

.to-emerald-900\/75 {
  --tw-gradient-to: #064e3bbf var(--tw-gradient-to-position);
}

.to-emerald-900\/80 {
  --tw-gradient-to: #064e3bcc var(--tw-gradient-to-position);
}

.to-emerald-900\/90 {
  --tw-gradient-to: #064e3be6 var(--tw-gradient-to-position);
}

.to-emerald-900\/95 {
  --tw-gradient-to: #064e3bf2 var(--tw-gradient-to-position);
}

.to-emerald-950 {
  --tw-gradient-to: #022c22 var(--tw-gradient-to-position);
}

.to-emerald-950\/0 {
  --tw-gradient-to: #022c2200 var(--tw-gradient-to-position);
}

.to-emerald-950\/10 {
  --tw-gradient-to: #022c221a var(--tw-gradient-to-position);
}

.to-emerald-950\/100 {
  --tw-gradient-to: #022c22 var(--tw-gradient-to-position);
}

.to-emerald-950\/20 {
  --tw-gradient-to: #022c2233 var(--tw-gradient-to-position);
}

.to-emerald-950\/25 {
  --tw-gradient-to: #022c2240 var(--tw-gradient-to-position);
}

.to-emerald-950\/30 {
  --tw-gradient-to: #022c224d var(--tw-gradient-to-position);
}

.to-emerald-950\/40 {
  --tw-gradient-to: #022c2266 var(--tw-gradient-to-position);
}

.to-emerald-950\/5 {
  --tw-gradient-to: #022c220d var(--tw-gradient-to-position);
}

.to-emerald-950\/50 {
  --tw-gradient-to: #022c2280 var(--tw-gradient-to-position);
}

.to-emerald-950\/60 {
  --tw-gradient-to: #022c2299 var(--tw-gradient-to-position);
}

.to-emerald-950\/70 {
  --tw-gradient-to: #022c22b3 var(--tw-gradient-to-position);
}

.to-emerald-950\/75 {
  --tw-gradient-to: #022c22bf var(--tw-gradient-to-position);
}

.to-emerald-950\/80 {
  --tw-gradient-to: #022c22cc var(--tw-gradient-to-position);
}

.to-emerald-950\/90 {
  --tw-gradient-to: #022c22e6 var(--tw-gradient-to-position);
}

.to-emerald-950\/95 {
  --tw-gradient-to: #022c22f2 var(--tw-gradient-to-position);
}

.to-lime-100 {
  --tw-gradient-to: #ecfccb var(--tw-gradient-to-position);
}

.to-lime-100\/0 {
  --tw-gradient-to: #ecfccb00 var(--tw-gradient-to-position);
}

.to-lime-100\/10 {
  --tw-gradient-to: #ecfccb1a var(--tw-gradient-to-position);
}

.to-lime-100\/100 {
  --tw-gradient-to: #ecfccb var(--tw-gradient-to-position);
}

.to-lime-100\/20 {
  --tw-gradient-to: #ecfccb33 var(--tw-gradient-to-position);
}

.to-lime-100\/25 {
  --tw-gradient-to: #ecfccb40 var(--tw-gradient-to-position);
}

.to-lime-100\/30 {
  --tw-gradient-to: #ecfccb4d var(--tw-gradient-to-position);
}

.to-lime-100\/40 {
  --tw-gradient-to: #ecfccb66 var(--tw-gradient-to-position);
}

.to-lime-100\/5 {
  --tw-gradient-to: #ecfccb0d var(--tw-gradient-to-position);
}

.to-lime-100\/50 {
  --tw-gradient-to: #ecfccb80 var(--tw-gradient-to-position);
}

.to-lime-100\/60 {
  --tw-gradient-to: #ecfccb99 var(--tw-gradient-to-position);
}

.to-lime-100\/70 {
  --tw-gradient-to: #ecfccbb3 var(--tw-gradient-to-position);
}

.to-lime-100\/75 {
  --tw-gradient-to: #ecfccbbf var(--tw-gradient-to-position);
}

.to-lime-100\/80 {
  --tw-gradient-to: #ecfccbcc var(--tw-gradient-to-position);
}

.to-lime-100\/90 {
  --tw-gradient-to: #ecfccbe6 var(--tw-gradient-to-position);
}

.to-lime-100\/95 {
  --tw-gradient-to: #ecfccbf2 var(--tw-gradient-to-position);
}

.to-lime-200 {
  --tw-gradient-to: #d9f99d var(--tw-gradient-to-position);
}

.to-lime-200\/0 {
  --tw-gradient-to: #d9f99d00 var(--tw-gradient-to-position);
}

.to-lime-200\/10 {
  --tw-gradient-to: #d9f99d1a var(--tw-gradient-to-position);
}

.to-lime-200\/100 {
  --tw-gradient-to: #d9f99d var(--tw-gradient-to-position);
}

.to-lime-200\/20 {
  --tw-gradient-to: #d9f99d33 var(--tw-gradient-to-position);
}

.to-lime-200\/25 {
  --tw-gradient-to: #d9f99d40 var(--tw-gradient-to-position);
}

.to-lime-200\/30 {
  --tw-gradient-to: #d9f99d4d var(--tw-gradient-to-position);
}

.to-lime-200\/40 {
  --tw-gradient-to: #d9f99d66 var(--tw-gradient-to-position);
}

.to-lime-200\/5 {
  --tw-gradient-to: #d9f99d0d var(--tw-gradient-to-position);
}

.to-lime-200\/50 {
  --tw-gradient-to: #d9f99d80 var(--tw-gradient-to-position);
}

.to-lime-200\/60 {
  --tw-gradient-to: #d9f99d99 var(--tw-gradient-to-position);
}

.to-lime-200\/70 {
  --tw-gradient-to: #d9f99db3 var(--tw-gradient-to-position);
}

.to-lime-200\/75 {
  --tw-gradient-to: #d9f99dbf var(--tw-gradient-to-position);
}

.to-lime-200\/80 {
  --tw-gradient-to: #d9f99dcc var(--tw-gradient-to-position);
}

.to-lime-200\/90 {
  --tw-gradient-to: #d9f99de6 var(--tw-gradient-to-position);
}

.to-lime-200\/95 {
  --tw-gradient-to: #d9f99df2 var(--tw-gradient-to-position);
}

.to-lime-300 {
  --tw-gradient-to: #bef264 var(--tw-gradient-to-position);
}

.to-lime-300\/0 {
  --tw-gradient-to: #bef26400 var(--tw-gradient-to-position);
}

.to-lime-300\/10 {
  --tw-gradient-to: #bef2641a var(--tw-gradient-to-position);
}

.to-lime-300\/100 {
  --tw-gradient-to: #bef264 var(--tw-gradient-to-position);
}

.to-lime-300\/20 {
  --tw-gradient-to: #bef26433 var(--tw-gradient-to-position);
}

.to-lime-300\/25 {
  --tw-gradient-to: #bef26440 var(--tw-gradient-to-position);
}

.to-lime-300\/30 {
  --tw-gradient-to: #bef2644d var(--tw-gradient-to-position);
}

.to-lime-300\/40 {
  --tw-gradient-to: #bef26466 var(--tw-gradient-to-position);
}

.to-lime-300\/5 {
  --tw-gradient-to: #bef2640d var(--tw-gradient-to-position);
}

.to-lime-300\/50 {
  --tw-gradient-to: #bef26480 var(--tw-gradient-to-position);
}

.to-lime-300\/60 {
  --tw-gradient-to: #bef26499 var(--tw-gradient-to-position);
}

.to-lime-300\/70 {
  --tw-gradient-to: #bef264b3 var(--tw-gradient-to-position);
}

.to-lime-300\/75 {
  --tw-gradient-to: #bef264bf var(--tw-gradient-to-position);
}

.to-lime-300\/80 {
  --tw-gradient-to: #bef264cc var(--tw-gradient-to-position);
}

.to-lime-300\/90 {
  --tw-gradient-to: #bef264e6 var(--tw-gradient-to-position);
}

.to-lime-300\/95 {
  --tw-gradient-to: #bef264f2 var(--tw-gradient-to-position);
}

.to-lime-400 {
  --tw-gradient-to: #a3e635 var(--tw-gradient-to-position);
}

.to-lime-400\/0 {
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
}

.to-lime-400\/10 {
  --tw-gradient-to: #a3e6351a var(--tw-gradient-to-position);
}

.to-lime-400\/100 {
  --tw-gradient-to: #a3e635 var(--tw-gradient-to-position);
}

.to-lime-400\/20 {
  --tw-gradient-to: #a3e63533 var(--tw-gradient-to-position);
}

.to-lime-400\/25 {
  --tw-gradient-to: #a3e63540 var(--tw-gradient-to-position);
}

.to-lime-400\/30 {
  --tw-gradient-to: #a3e6354d var(--tw-gradient-to-position);
}

.to-lime-400\/40 {
  --tw-gradient-to: #a3e63566 var(--tw-gradient-to-position);
}

.to-lime-400\/5 {
  --tw-gradient-to: #a3e6350d var(--tw-gradient-to-position);
}

.to-lime-400\/50 {
  --tw-gradient-to: #a3e63580 var(--tw-gradient-to-position);
}

.to-lime-400\/60 {
  --tw-gradient-to: #a3e63599 var(--tw-gradient-to-position);
}

.to-lime-400\/70 {
  --tw-gradient-to: #a3e635b3 var(--tw-gradient-to-position);
}

.to-lime-400\/75 {
  --tw-gradient-to: #a3e635bf var(--tw-gradient-to-position);
}

.to-lime-400\/80 {
  --tw-gradient-to: #a3e635cc var(--tw-gradient-to-position);
}

.to-lime-400\/90 {
  --tw-gradient-to: #a3e635e6 var(--tw-gradient-to-position);
}

.to-lime-400\/95 {
  --tw-gradient-to: #a3e635f2 var(--tw-gradient-to-position);
}

.to-lime-50 {
  --tw-gradient-to: #f7fee7 var(--tw-gradient-to-position);
}

.to-lime-50\/0 {
  --tw-gradient-to: #f7fee700 var(--tw-gradient-to-position);
}

.to-lime-50\/10 {
  --tw-gradient-to: #f7fee71a var(--tw-gradient-to-position);
}

.to-lime-50\/100 {
  --tw-gradient-to: #f7fee7 var(--tw-gradient-to-position);
}

.to-lime-50\/20 {
  --tw-gradient-to: #f7fee733 var(--tw-gradient-to-position);
}

.to-lime-50\/25 {
  --tw-gradient-to: #f7fee740 var(--tw-gradient-to-position);
}

.to-lime-50\/30 {
  --tw-gradient-to: #f7fee74d var(--tw-gradient-to-position);
}

.to-lime-50\/40 {
  --tw-gradient-to: #f7fee766 var(--tw-gradient-to-position);
}

.to-lime-50\/5 {
  --tw-gradient-to: #f7fee70d var(--tw-gradient-to-position);
}

.to-lime-50\/50 {
  --tw-gradient-to: #f7fee780 var(--tw-gradient-to-position);
}

.to-lime-50\/60 {
  --tw-gradient-to: #f7fee799 var(--tw-gradient-to-position);
}

.to-lime-50\/70 {
  --tw-gradient-to: #f7fee7b3 var(--tw-gradient-to-position);
}

.to-lime-50\/75 {
  --tw-gradient-to: #f7fee7bf var(--tw-gradient-to-position);
}

.to-lime-50\/80 {
  --tw-gradient-to: #f7fee7cc var(--tw-gradient-to-position);
}

.to-lime-50\/90 {
  --tw-gradient-to: #f7fee7e6 var(--tw-gradient-to-position);
}

.to-lime-50\/95 {
  --tw-gradient-to: #f7fee7f2 var(--tw-gradient-to-position);
}

.to-lime-500 {
  --tw-gradient-to: #84cc16 var(--tw-gradient-to-position);
}

.to-lime-500\/0 {
  --tw-gradient-to: #84cc1600 var(--tw-gradient-to-position);
}

.to-lime-500\/10 {
  --tw-gradient-to: #84cc161a var(--tw-gradient-to-position);
}

.to-lime-500\/100 {
  --tw-gradient-to: #84cc16 var(--tw-gradient-to-position);
}

.to-lime-500\/20 {
  --tw-gradient-to: #84cc1633 var(--tw-gradient-to-position);
}

.to-lime-500\/25 {
  --tw-gradient-to: #84cc1640 var(--tw-gradient-to-position);
}

.to-lime-500\/30 {
  --tw-gradient-to: #84cc164d var(--tw-gradient-to-position);
}

.to-lime-500\/40 {
  --tw-gradient-to: #84cc1666 var(--tw-gradient-to-position);
}

.to-lime-500\/5 {
  --tw-gradient-to: #84cc160d var(--tw-gradient-to-position);
}

.to-lime-500\/50 {
  --tw-gradient-to: #84cc1680 var(--tw-gradient-to-position);
}

.to-lime-500\/60 {
  --tw-gradient-to: #84cc1699 var(--tw-gradient-to-position);
}

.to-lime-500\/70 {
  --tw-gradient-to: #84cc16b3 var(--tw-gradient-to-position);
}

.to-lime-500\/75 {
  --tw-gradient-to: #84cc16bf var(--tw-gradient-to-position);
}

.to-lime-500\/80 {
  --tw-gradient-to: #84cc16cc var(--tw-gradient-to-position);
}

.to-lime-500\/90 {
  --tw-gradient-to: #84cc16e6 var(--tw-gradient-to-position);
}

.to-lime-500\/95 {
  --tw-gradient-to: #84cc16f2 var(--tw-gradient-to-position);
}

.to-lime-600 {
  --tw-gradient-to: #65a30d var(--tw-gradient-to-position);
}

.to-lime-600\/0 {
  --tw-gradient-to: #65a30d00 var(--tw-gradient-to-position);
}

.to-lime-600\/10 {
  --tw-gradient-to: #65a30d1a var(--tw-gradient-to-position);
}

.to-lime-600\/100 {
  --tw-gradient-to: #65a30d var(--tw-gradient-to-position);
}

.to-lime-600\/20 {
  --tw-gradient-to: #65a30d33 var(--tw-gradient-to-position);
}

.to-lime-600\/25 {
  --tw-gradient-to: #65a30d40 var(--tw-gradient-to-position);
}

.to-lime-600\/30 {
  --tw-gradient-to: #65a30d4d var(--tw-gradient-to-position);
}

.to-lime-600\/40 {
  --tw-gradient-to: #65a30d66 var(--tw-gradient-to-position);
}

.to-lime-600\/5 {
  --tw-gradient-to: #65a30d0d var(--tw-gradient-to-position);
}

.to-lime-600\/50 {
  --tw-gradient-to: #65a30d80 var(--tw-gradient-to-position);
}

.to-lime-600\/60 {
  --tw-gradient-to: #65a30d99 var(--tw-gradient-to-position);
}

.to-lime-600\/70 {
  --tw-gradient-to: #65a30db3 var(--tw-gradient-to-position);
}

.to-lime-600\/75 {
  --tw-gradient-to: #65a30dbf var(--tw-gradient-to-position);
}

.to-lime-600\/80 {
  --tw-gradient-to: #65a30dcc var(--tw-gradient-to-position);
}

.to-lime-600\/90 {
  --tw-gradient-to: #65a30de6 var(--tw-gradient-to-position);
}

.to-lime-600\/95 {
  --tw-gradient-to: #65a30df2 var(--tw-gradient-to-position);
}

.to-lime-700 {
  --tw-gradient-to: #4d7c0f var(--tw-gradient-to-position);
}

.to-lime-700\/0 {
  --tw-gradient-to: #4d7c0f00 var(--tw-gradient-to-position);
}

.to-lime-700\/10 {
  --tw-gradient-to: #4d7c0f1a var(--tw-gradient-to-position);
}

.to-lime-700\/100 {
  --tw-gradient-to: #4d7c0f var(--tw-gradient-to-position);
}

.to-lime-700\/20 {
  --tw-gradient-to: #4d7c0f33 var(--tw-gradient-to-position);
}

.to-lime-700\/25 {
  --tw-gradient-to: #4d7c0f40 var(--tw-gradient-to-position);
}

.to-lime-700\/30 {
  --tw-gradient-to: #4d7c0f4d var(--tw-gradient-to-position);
}

.to-lime-700\/40 {
  --tw-gradient-to: #4d7c0f66 var(--tw-gradient-to-position);
}

.to-lime-700\/5 {
  --tw-gradient-to: #4d7c0f0d var(--tw-gradient-to-position);
}

.to-lime-700\/50 {
  --tw-gradient-to: #4d7c0f80 var(--tw-gradient-to-position);
}

.to-lime-700\/60 {
  --tw-gradient-to: #4d7c0f99 var(--tw-gradient-to-position);
}

.to-lime-700\/70 {
  --tw-gradient-to: #4d7c0fb3 var(--tw-gradient-to-position);
}

.to-lime-700\/75 {
  --tw-gradient-to: #4d7c0fbf var(--tw-gradient-to-position);
}

.to-lime-700\/80 {
  --tw-gradient-to: #4d7c0fcc var(--tw-gradient-to-position);
}

.to-lime-700\/90 {
  --tw-gradient-to: #4d7c0fe6 var(--tw-gradient-to-position);
}

.to-lime-700\/95 {
  --tw-gradient-to: #4d7c0ff2 var(--tw-gradient-to-position);
}

.to-lime-800 {
  --tw-gradient-to: #3f6212 var(--tw-gradient-to-position);
}

.to-lime-800\/0 {
  --tw-gradient-to: #3f621200 var(--tw-gradient-to-position);
}

.to-lime-800\/10 {
  --tw-gradient-to: #3f62121a var(--tw-gradient-to-position);
}

.to-lime-800\/100 {
  --tw-gradient-to: #3f6212 var(--tw-gradient-to-position);
}

.to-lime-800\/20 {
  --tw-gradient-to: #3f621233 var(--tw-gradient-to-position);
}

.to-lime-800\/25 {
  --tw-gradient-to: #3f621240 var(--tw-gradient-to-position);
}

.to-lime-800\/30 {
  --tw-gradient-to: #3f62124d var(--tw-gradient-to-position);
}

.to-lime-800\/40 {
  --tw-gradient-to: #3f621266 var(--tw-gradient-to-position);
}

.to-lime-800\/5 {
  --tw-gradient-to: #3f62120d var(--tw-gradient-to-position);
}

.to-lime-800\/50 {
  --tw-gradient-to: #3f621280 var(--tw-gradient-to-position);
}

.to-lime-800\/60 {
  --tw-gradient-to: #3f621299 var(--tw-gradient-to-position);
}

.to-lime-800\/70 {
  --tw-gradient-to: #3f6212b3 var(--tw-gradient-to-position);
}

.to-lime-800\/75 {
  --tw-gradient-to: #3f6212bf var(--tw-gradient-to-position);
}

.to-lime-800\/80 {
  --tw-gradient-to: #3f6212cc var(--tw-gradient-to-position);
}

.to-lime-800\/90 {
  --tw-gradient-to: #3f6212e6 var(--tw-gradient-to-position);
}

.to-lime-800\/95 {
  --tw-gradient-to: #3f6212f2 var(--tw-gradient-to-position);
}

.to-lime-900 {
  --tw-gradient-to: #365314 var(--tw-gradient-to-position);
}

.to-lime-900\/0 {
  --tw-gradient-to: #36531400 var(--tw-gradient-to-position);
}

.to-lime-900\/10 {
  --tw-gradient-to: #3653141a var(--tw-gradient-to-position);
}

.to-lime-900\/100 {
  --tw-gradient-to: #365314 var(--tw-gradient-to-position);
}

.to-lime-900\/20 {
  --tw-gradient-to: #36531433 var(--tw-gradient-to-position);
}

.to-lime-900\/25 {
  --tw-gradient-to: #36531440 var(--tw-gradient-to-position);
}

.to-lime-900\/30 {
  --tw-gradient-to: #3653144d var(--tw-gradient-to-position);
}

.to-lime-900\/40 {
  --tw-gradient-to: #36531466 var(--tw-gradient-to-position);
}

.to-lime-900\/5 {
  --tw-gradient-to: #3653140d var(--tw-gradient-to-position);
}

.to-lime-900\/50 {
  --tw-gradient-to: #36531480 var(--tw-gradient-to-position);
}

.to-lime-900\/60 {
  --tw-gradient-to: #36531499 var(--tw-gradient-to-position);
}

.to-lime-900\/70 {
  --tw-gradient-to: #365314b3 var(--tw-gradient-to-position);
}

.to-lime-900\/75 {
  --tw-gradient-to: #365314bf var(--tw-gradient-to-position);
}

.to-lime-900\/80 {
  --tw-gradient-to: #365314cc var(--tw-gradient-to-position);
}

.to-lime-900\/90 {
  --tw-gradient-to: #365314e6 var(--tw-gradient-to-position);
}

.to-lime-900\/95 {
  --tw-gradient-to: #365314f2 var(--tw-gradient-to-position);
}

.to-lime-950 {
  --tw-gradient-to: #1a2e05 var(--tw-gradient-to-position);
}

.to-lime-950\/0 {
  --tw-gradient-to: #1a2e0500 var(--tw-gradient-to-position);
}

.to-lime-950\/10 {
  --tw-gradient-to: #1a2e051a var(--tw-gradient-to-position);
}

.to-lime-950\/100 {
  --tw-gradient-to: #1a2e05 var(--tw-gradient-to-position);
}

.to-lime-950\/20 {
  --tw-gradient-to: #1a2e0533 var(--tw-gradient-to-position);
}

.to-lime-950\/25 {
  --tw-gradient-to: #1a2e0540 var(--tw-gradient-to-position);
}

.to-lime-950\/30 {
  --tw-gradient-to: #1a2e054d var(--tw-gradient-to-position);
}

.to-lime-950\/40 {
  --tw-gradient-to: #1a2e0566 var(--tw-gradient-to-position);
}

.to-lime-950\/5 {
  --tw-gradient-to: #1a2e050d var(--tw-gradient-to-position);
}

.to-lime-950\/50 {
  --tw-gradient-to: #1a2e0580 var(--tw-gradient-to-position);
}

.to-lime-950\/60 {
  --tw-gradient-to: #1a2e0599 var(--tw-gradient-to-position);
}

.to-lime-950\/70 {
  --tw-gradient-to: #1a2e05b3 var(--tw-gradient-to-position);
}

.to-lime-950\/75 {
  --tw-gradient-to: #1a2e05bf var(--tw-gradient-to-position);
}

.to-lime-950\/80 {
  --tw-gradient-to: #1a2e05cc var(--tw-gradient-to-position);
}

.to-lime-950\/90 {
  --tw-gradient-to: #1a2e05e6 var(--tw-gradient-to-position);
}

.to-lime-950\/95 {
  --tw-gradient-to: #1a2e05f2 var(--tw-gradient-to-position);
}

.to-pink-100 {
  --tw-gradient-to: #fce7f3 var(--tw-gradient-to-position);
}

.to-pink-100\/0 {
  --tw-gradient-to: #fce7f300 var(--tw-gradient-to-position);
}

.to-pink-100\/10 {
  --tw-gradient-to: #fce7f31a var(--tw-gradient-to-position);
}

.to-pink-100\/100 {
  --tw-gradient-to: #fce7f3 var(--tw-gradient-to-position);
}

.to-pink-100\/20 {
  --tw-gradient-to: #fce7f333 var(--tw-gradient-to-position);
}

.to-pink-100\/25 {
  --tw-gradient-to: #fce7f340 var(--tw-gradient-to-position);
}

.to-pink-100\/30 {
  --tw-gradient-to: #fce7f34d var(--tw-gradient-to-position);
}

.to-pink-100\/40 {
  --tw-gradient-to: #fce7f366 var(--tw-gradient-to-position);
}

.to-pink-100\/5 {
  --tw-gradient-to: #fce7f30d var(--tw-gradient-to-position);
}

.to-pink-100\/50 {
  --tw-gradient-to: #fce7f380 var(--tw-gradient-to-position);
}

.to-pink-100\/60 {
  --tw-gradient-to: #fce7f399 var(--tw-gradient-to-position);
}

.to-pink-100\/70 {
  --tw-gradient-to: #fce7f3b3 var(--tw-gradient-to-position);
}

.to-pink-100\/75 {
  --tw-gradient-to: #fce7f3bf var(--tw-gradient-to-position);
}

.to-pink-100\/80 {
  --tw-gradient-to: #fce7f3cc var(--tw-gradient-to-position);
}

.to-pink-100\/90 {
  --tw-gradient-to: #fce7f3e6 var(--tw-gradient-to-position);
}

.to-pink-100\/95 {
  --tw-gradient-to: #fce7f3f2 var(--tw-gradient-to-position);
}

.to-pink-200 {
  --tw-gradient-to: #fbcfe8 var(--tw-gradient-to-position);
}

.to-pink-200\/0 {
  --tw-gradient-to: #fbcfe800 var(--tw-gradient-to-position);
}

.to-pink-200\/10 {
  --tw-gradient-to: #fbcfe81a var(--tw-gradient-to-position);
}

.to-pink-200\/100 {
  --tw-gradient-to: #fbcfe8 var(--tw-gradient-to-position);
}

.to-pink-200\/20 {
  --tw-gradient-to: #fbcfe833 var(--tw-gradient-to-position);
}

.to-pink-200\/25 {
  --tw-gradient-to: #fbcfe840 var(--tw-gradient-to-position);
}

.to-pink-200\/30 {
  --tw-gradient-to: #fbcfe84d var(--tw-gradient-to-position);
}

.to-pink-200\/40 {
  --tw-gradient-to: #fbcfe866 var(--tw-gradient-to-position);
}

.to-pink-200\/5 {
  --tw-gradient-to: #fbcfe80d var(--tw-gradient-to-position);
}

.to-pink-200\/50 {
  --tw-gradient-to: #fbcfe880 var(--tw-gradient-to-position);
}

.to-pink-200\/60 {
  --tw-gradient-to: #fbcfe899 var(--tw-gradient-to-position);
}

.to-pink-200\/70 {
  --tw-gradient-to: #fbcfe8b3 var(--tw-gradient-to-position);
}

.to-pink-200\/75 {
  --tw-gradient-to: #fbcfe8bf var(--tw-gradient-to-position);
}

.to-pink-200\/80 {
  --tw-gradient-to: #fbcfe8cc var(--tw-gradient-to-position);
}

.to-pink-200\/90 {
  --tw-gradient-to: #fbcfe8e6 var(--tw-gradient-to-position);
}

.to-pink-200\/95 {
  --tw-gradient-to: #fbcfe8f2 var(--tw-gradient-to-position);
}

.to-pink-300 {
  --tw-gradient-to: #f9a8d4 var(--tw-gradient-to-position);
}

.to-pink-300\/0 {
  --tw-gradient-to: #f9a8d400 var(--tw-gradient-to-position);
}

.to-pink-300\/10 {
  --tw-gradient-to: #f9a8d41a var(--tw-gradient-to-position);
}

.to-pink-300\/100 {
  --tw-gradient-to: #f9a8d4 var(--tw-gradient-to-position);
}

.to-pink-300\/20 {
  --tw-gradient-to: #f9a8d433 var(--tw-gradient-to-position);
}

.to-pink-300\/25 {
  --tw-gradient-to: #f9a8d440 var(--tw-gradient-to-position);
}

.to-pink-300\/30 {
  --tw-gradient-to: #f9a8d44d var(--tw-gradient-to-position);
}

.to-pink-300\/40 {
  --tw-gradient-to: #f9a8d466 var(--tw-gradient-to-position);
}

.to-pink-300\/5 {
  --tw-gradient-to: #f9a8d40d var(--tw-gradient-to-position);
}

.to-pink-300\/50 {
  --tw-gradient-to: #f9a8d480 var(--tw-gradient-to-position);
}

.to-pink-300\/60 {
  --tw-gradient-to: #f9a8d499 var(--tw-gradient-to-position);
}

.to-pink-300\/70 {
  --tw-gradient-to: #f9a8d4b3 var(--tw-gradient-to-position);
}

.to-pink-300\/75 {
  --tw-gradient-to: #f9a8d4bf var(--tw-gradient-to-position);
}

.to-pink-300\/80 {
  --tw-gradient-to: #f9a8d4cc var(--tw-gradient-to-position);
}

.to-pink-300\/90 {
  --tw-gradient-to: #f9a8d4e6 var(--tw-gradient-to-position);
}

.to-pink-300\/95 {
  --tw-gradient-to: #f9a8d4f2 var(--tw-gradient-to-position);
}

.to-pink-400 {
  --tw-gradient-to: #f472b6 var(--tw-gradient-to-position);
}

.to-pink-400\/0 {
  --tw-gradient-to: #f472b600 var(--tw-gradient-to-position);
}

.to-pink-400\/10 {
  --tw-gradient-to: #f472b61a var(--tw-gradient-to-position);
}

.to-pink-400\/100 {
  --tw-gradient-to: #f472b6 var(--tw-gradient-to-position);
}

.to-pink-400\/20 {
  --tw-gradient-to: #f472b633 var(--tw-gradient-to-position);
}

.to-pink-400\/25 {
  --tw-gradient-to: #f472b640 var(--tw-gradient-to-position);
}

.to-pink-400\/30 {
  --tw-gradient-to: #f472b64d var(--tw-gradient-to-position);
}

.to-pink-400\/40 {
  --tw-gradient-to: #f472b666 var(--tw-gradient-to-position);
}

.to-pink-400\/5 {
  --tw-gradient-to: #f472b60d var(--tw-gradient-to-position);
}

.to-pink-400\/50 {
  --tw-gradient-to: #f472b680 var(--tw-gradient-to-position);
}

.to-pink-400\/60 {
  --tw-gradient-to: #f472b699 var(--tw-gradient-to-position);
}

.to-pink-400\/70 {
  --tw-gradient-to: #f472b6b3 var(--tw-gradient-to-position);
}

.to-pink-400\/75 {
  --tw-gradient-to: #f472b6bf var(--tw-gradient-to-position);
}

.to-pink-400\/80 {
  --tw-gradient-to: #f472b6cc var(--tw-gradient-to-position);
}

.to-pink-400\/90 {
  --tw-gradient-to: #f472b6e6 var(--tw-gradient-to-position);
}

.to-pink-400\/95 {
  --tw-gradient-to: #f472b6f2 var(--tw-gradient-to-position);
}

.to-pink-50 {
  --tw-gradient-to: #fdf2f8 var(--tw-gradient-to-position);
}

.to-pink-50\/0 {
  --tw-gradient-to: #fdf2f800 var(--tw-gradient-to-position);
}

.to-pink-50\/10 {
  --tw-gradient-to: #fdf2f81a var(--tw-gradient-to-position);
}

.to-pink-50\/100 {
  --tw-gradient-to: #fdf2f8 var(--tw-gradient-to-position);
}

.to-pink-50\/20 {
  --tw-gradient-to: #fdf2f833 var(--tw-gradient-to-position);
}

.to-pink-50\/25 {
  --tw-gradient-to: #fdf2f840 var(--tw-gradient-to-position);
}

.to-pink-50\/30 {
  --tw-gradient-to: #fdf2f84d var(--tw-gradient-to-position);
}

.to-pink-50\/40 {
  --tw-gradient-to: #fdf2f866 var(--tw-gradient-to-position);
}

.to-pink-50\/5 {
  --tw-gradient-to: #fdf2f80d var(--tw-gradient-to-position);
}

.to-pink-50\/50 {
  --tw-gradient-to: #fdf2f880 var(--tw-gradient-to-position);
}

.to-pink-50\/60 {
  --tw-gradient-to: #fdf2f899 var(--tw-gradient-to-position);
}

.to-pink-50\/70 {
  --tw-gradient-to: #fdf2f8b3 var(--tw-gradient-to-position);
}

.to-pink-50\/75 {
  --tw-gradient-to: #fdf2f8bf var(--tw-gradient-to-position);
}

.to-pink-50\/80 {
  --tw-gradient-to: #fdf2f8cc var(--tw-gradient-to-position);
}

.to-pink-50\/90 {
  --tw-gradient-to: #fdf2f8e6 var(--tw-gradient-to-position);
}

.to-pink-50\/95 {
  --tw-gradient-to: #fdf2f8f2 var(--tw-gradient-to-position);
}

.to-pink-500 {
  --tw-gradient-to: #ec4899 var(--tw-gradient-to-position);
}

.to-pink-500\/0 {
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
}

.to-pink-500\/10 {
  --tw-gradient-to: #ec48991a var(--tw-gradient-to-position);
}

.to-pink-500\/100 {
  --tw-gradient-to: #ec4899 var(--tw-gradient-to-position);
}

.to-pink-500\/20 {
  --tw-gradient-to: #ec489933 var(--tw-gradient-to-position);
}

.to-pink-500\/25 {
  --tw-gradient-to: #ec489940 var(--tw-gradient-to-position);
}

.to-pink-500\/30 {
  --tw-gradient-to: #ec48994d var(--tw-gradient-to-position);
}

.to-pink-500\/40 {
  --tw-gradient-to: #ec489966 var(--tw-gradient-to-position);
}

.to-pink-500\/5 {
  --tw-gradient-to: #ec48990d var(--tw-gradient-to-position);
}

.to-pink-500\/50 {
  --tw-gradient-to: #ec489980 var(--tw-gradient-to-position);
}

.to-pink-500\/60 {
  --tw-gradient-to: #ec489999 var(--tw-gradient-to-position);
}

.to-pink-500\/70 {
  --tw-gradient-to: #ec4899b3 var(--tw-gradient-to-position);
}

.to-pink-500\/75 {
  --tw-gradient-to: #ec4899bf var(--tw-gradient-to-position);
}

.to-pink-500\/80 {
  --tw-gradient-to: #ec4899cc var(--tw-gradient-to-position);
}

.to-pink-500\/90 {
  --tw-gradient-to: #ec4899e6 var(--tw-gradient-to-position);
}

.to-pink-500\/95 {
  --tw-gradient-to: #ec4899f2 var(--tw-gradient-to-position);
}

.to-pink-600 {
  --tw-gradient-to: #db2777 var(--tw-gradient-to-position);
}

.to-pink-600\/0 {
  --tw-gradient-to: #db277700 var(--tw-gradient-to-position);
}

.to-pink-600\/10 {
  --tw-gradient-to: #db27771a var(--tw-gradient-to-position);
}

.to-pink-600\/100 {
  --tw-gradient-to: #db2777 var(--tw-gradient-to-position);
}

.to-pink-600\/20 {
  --tw-gradient-to: #db277733 var(--tw-gradient-to-position);
}

.to-pink-600\/25 {
  --tw-gradient-to: #db277740 var(--tw-gradient-to-position);
}

.to-pink-600\/30 {
  --tw-gradient-to: #db27774d var(--tw-gradient-to-position);
}

.to-pink-600\/40 {
  --tw-gradient-to: #db277766 var(--tw-gradient-to-position);
}

.to-pink-600\/5 {
  --tw-gradient-to: #db27770d var(--tw-gradient-to-position);
}

.to-pink-600\/50 {
  --tw-gradient-to: #db277780 var(--tw-gradient-to-position);
}

.to-pink-600\/60 {
  --tw-gradient-to: #db277799 var(--tw-gradient-to-position);
}

.to-pink-600\/70 {
  --tw-gradient-to: #db2777b3 var(--tw-gradient-to-position);
}

.to-pink-600\/75 {
  --tw-gradient-to: #db2777bf var(--tw-gradient-to-position);
}

.to-pink-600\/80 {
  --tw-gradient-to: #db2777cc var(--tw-gradient-to-position);
}

.to-pink-600\/90 {
  --tw-gradient-to: #db2777e6 var(--tw-gradient-to-position);
}

.to-pink-600\/95 {
  --tw-gradient-to: #db2777f2 var(--tw-gradient-to-position);
}

.to-pink-700 {
  --tw-gradient-to: #be185d var(--tw-gradient-to-position);
}

.to-pink-700\/0 {
  --tw-gradient-to: #be185d00 var(--tw-gradient-to-position);
}

.to-pink-700\/10 {
  --tw-gradient-to: #be185d1a var(--tw-gradient-to-position);
}

.to-pink-700\/100 {
  --tw-gradient-to: #be185d var(--tw-gradient-to-position);
}

.to-pink-700\/20 {
  --tw-gradient-to: #be185d33 var(--tw-gradient-to-position);
}

.to-pink-700\/25 {
  --tw-gradient-to: #be185d40 var(--tw-gradient-to-position);
}

.to-pink-700\/30 {
  --tw-gradient-to: #be185d4d var(--tw-gradient-to-position);
}

.to-pink-700\/40 {
  --tw-gradient-to: #be185d66 var(--tw-gradient-to-position);
}

.to-pink-700\/5 {
  --tw-gradient-to: #be185d0d var(--tw-gradient-to-position);
}

.to-pink-700\/50 {
  --tw-gradient-to: #be185d80 var(--tw-gradient-to-position);
}

.to-pink-700\/60 {
  --tw-gradient-to: #be185d99 var(--tw-gradient-to-position);
}

.to-pink-700\/70 {
  --tw-gradient-to: #be185db3 var(--tw-gradient-to-position);
}

.to-pink-700\/75 {
  --tw-gradient-to: #be185dbf var(--tw-gradient-to-position);
}

.to-pink-700\/80 {
  --tw-gradient-to: #be185dcc var(--tw-gradient-to-position);
}

.to-pink-700\/90 {
  --tw-gradient-to: #be185de6 var(--tw-gradient-to-position);
}

.to-pink-700\/95 {
  --tw-gradient-to: #be185df2 var(--tw-gradient-to-position);
}

.to-pink-800 {
  --tw-gradient-to: #9d174d var(--tw-gradient-to-position);
}

.to-pink-800\/0 {
  --tw-gradient-to: #9d174d00 var(--tw-gradient-to-position);
}

.to-pink-800\/10 {
  --tw-gradient-to: #9d174d1a var(--tw-gradient-to-position);
}

.to-pink-800\/100 {
  --tw-gradient-to: #9d174d var(--tw-gradient-to-position);
}

.to-pink-800\/20 {
  --tw-gradient-to: #9d174d33 var(--tw-gradient-to-position);
}

.to-pink-800\/25 {
  --tw-gradient-to: #9d174d40 var(--tw-gradient-to-position);
}

.to-pink-800\/30 {
  --tw-gradient-to: #9d174d4d var(--tw-gradient-to-position);
}

.to-pink-800\/40 {
  --tw-gradient-to: #9d174d66 var(--tw-gradient-to-position);
}

.to-pink-800\/5 {
  --tw-gradient-to: #9d174d0d var(--tw-gradient-to-position);
}

.to-pink-800\/50 {
  --tw-gradient-to: #9d174d80 var(--tw-gradient-to-position);
}

.to-pink-800\/60 {
  --tw-gradient-to: #9d174d99 var(--tw-gradient-to-position);
}

.to-pink-800\/70 {
  --tw-gradient-to: #9d174db3 var(--tw-gradient-to-position);
}

.to-pink-800\/75 {
  --tw-gradient-to: #9d174dbf var(--tw-gradient-to-position);
}

.to-pink-800\/80 {
  --tw-gradient-to: #9d174dcc var(--tw-gradient-to-position);
}

.to-pink-800\/90 {
  --tw-gradient-to: #9d174de6 var(--tw-gradient-to-position);
}

.to-pink-800\/95 {
  --tw-gradient-to: #9d174df2 var(--tw-gradient-to-position);
}

.to-pink-900 {
  --tw-gradient-to: #831843 var(--tw-gradient-to-position);
}

.to-pink-900\/0 {
  --tw-gradient-to: #83184300 var(--tw-gradient-to-position);
}

.to-pink-900\/10 {
  --tw-gradient-to: #8318431a var(--tw-gradient-to-position);
}

.to-pink-900\/100 {
  --tw-gradient-to: #831843 var(--tw-gradient-to-position);
}

.to-pink-900\/20 {
  --tw-gradient-to: #83184333 var(--tw-gradient-to-position);
}

.to-pink-900\/25 {
  --tw-gradient-to: #83184340 var(--tw-gradient-to-position);
}

.to-pink-900\/30 {
  --tw-gradient-to: #8318434d var(--tw-gradient-to-position);
}

.to-pink-900\/40 {
  --tw-gradient-to: #83184366 var(--tw-gradient-to-position);
}

.to-pink-900\/5 {
  --tw-gradient-to: #8318430d var(--tw-gradient-to-position);
}

.to-pink-900\/50 {
  --tw-gradient-to: #83184380 var(--tw-gradient-to-position);
}

.to-pink-900\/60 {
  --tw-gradient-to: #83184399 var(--tw-gradient-to-position);
}

.to-pink-900\/70 {
  --tw-gradient-to: #831843b3 var(--tw-gradient-to-position);
}

.to-pink-900\/75 {
  --tw-gradient-to: #831843bf var(--tw-gradient-to-position);
}

.to-pink-900\/80 {
  --tw-gradient-to: #831843cc var(--tw-gradient-to-position);
}

.to-pink-900\/90 {
  --tw-gradient-to: #831843e6 var(--tw-gradient-to-position);
}

.to-pink-900\/95 {
  --tw-gradient-to: #831843f2 var(--tw-gradient-to-position);
}

.to-pink-950 {
  --tw-gradient-to: #500724 var(--tw-gradient-to-position);
}

.to-pink-950\/0 {
  --tw-gradient-to: #50072400 var(--tw-gradient-to-position);
}

.to-pink-950\/10 {
  --tw-gradient-to: #5007241a var(--tw-gradient-to-position);
}

.to-pink-950\/100 {
  --tw-gradient-to: #500724 var(--tw-gradient-to-position);
}

.to-pink-950\/20 {
  --tw-gradient-to: #50072433 var(--tw-gradient-to-position);
}

.to-pink-950\/25 {
  --tw-gradient-to: #50072440 var(--tw-gradient-to-position);
}

.to-pink-950\/30 {
  --tw-gradient-to: #5007244d var(--tw-gradient-to-position);
}

.to-pink-950\/40 {
  --tw-gradient-to: #50072466 var(--tw-gradient-to-position);
}

.to-pink-950\/5 {
  --tw-gradient-to: #5007240d var(--tw-gradient-to-position);
}

.to-pink-950\/50 {
  --tw-gradient-to: #50072480 var(--tw-gradient-to-position);
}

.to-pink-950\/60 {
  --tw-gradient-to: #50072499 var(--tw-gradient-to-position);
}

.to-pink-950\/70 {
  --tw-gradient-to: #500724b3 var(--tw-gradient-to-position);
}

.to-pink-950\/75 {
  --tw-gradient-to: #500724bf var(--tw-gradient-to-position);
}

.to-pink-950\/80 {
  --tw-gradient-to: #500724cc var(--tw-gradient-to-position);
}

.to-pink-950\/90 {
  --tw-gradient-to: #500724e6 var(--tw-gradient-to-position);
}

.to-pink-950\/95 {
  --tw-gradient-to: #500724f2 var(--tw-gradient-to-position);
}

.to-primary {
  --tw-gradient-to: hsl(var(--primary) / 1) var(--tw-gradient-to-position);
}

.to-primary\/0 {
  --tw-gradient-to: hsl(var(--primary) / 0) var(--tw-gradient-to-position);
}

.to-primary\/10 {
  --tw-gradient-to: hsl(var(--primary) / .1) var(--tw-gradient-to-position);
}

.to-primary\/100 {
  --tw-gradient-to: hsl(var(--primary) / 1) var(--tw-gradient-to-position);
}

.to-primary\/20 {
  --tw-gradient-to: hsl(var(--primary) / .2) var(--tw-gradient-to-position);
}

.to-primary\/25 {
  --tw-gradient-to: hsl(var(--primary) / .25) var(--tw-gradient-to-position);
}

.to-primary\/30 {
  --tw-gradient-to: hsl(var(--primary) / .3) var(--tw-gradient-to-position);
}

.to-primary\/40 {
  --tw-gradient-to: hsl(var(--primary) / .4) var(--tw-gradient-to-position);
}

.to-primary\/5 {
  --tw-gradient-to: hsl(var(--primary) / .05) var(--tw-gradient-to-position);
}

.to-primary\/50 {
  --tw-gradient-to: hsl(var(--primary) / .5) var(--tw-gradient-to-position);
}

.to-primary\/60 {
  --tw-gradient-to: hsl(var(--primary) / .6) var(--tw-gradient-to-position);
}

.to-primary\/70 {
  --tw-gradient-to: hsl(var(--primary) / .7) var(--tw-gradient-to-position);
}

.to-primary\/75 {
  --tw-gradient-to: hsl(var(--primary) / .75) var(--tw-gradient-to-position);
}

.to-primary\/80 {
  --tw-gradient-to: hsl(var(--primary) / .8) var(--tw-gradient-to-position);
}

.to-primary\/90 {
  --tw-gradient-to: hsl(var(--primary) / .9) var(--tw-gradient-to-position);
}

.to-primary\/95 {
  --tw-gradient-to: hsl(var(--primary) / .95) var(--tw-gradient-to-position);
}

.to-purple-100 {
  --tw-gradient-to: #f3e8ff var(--tw-gradient-to-position);
}

.to-purple-100\/0 {
  --tw-gradient-to: #f3e8ff00 var(--tw-gradient-to-position);
}

.to-purple-100\/10 {
  --tw-gradient-to: #f3e8ff1a var(--tw-gradient-to-position);
}

.to-purple-100\/100 {
  --tw-gradient-to: #f3e8ff var(--tw-gradient-to-position);
}

.to-purple-100\/20 {
  --tw-gradient-to: #f3e8ff33 var(--tw-gradient-to-position);
}

.to-purple-100\/25 {
  --tw-gradient-to: #f3e8ff40 var(--tw-gradient-to-position);
}

.to-purple-100\/30 {
  --tw-gradient-to: #f3e8ff4d var(--tw-gradient-to-position);
}

.to-purple-100\/40 {
  --tw-gradient-to: #f3e8ff66 var(--tw-gradient-to-position);
}

.to-purple-100\/5 {
  --tw-gradient-to: #f3e8ff0d var(--tw-gradient-to-position);
}

.to-purple-100\/50 {
  --tw-gradient-to: #f3e8ff80 var(--tw-gradient-to-position);
}

.to-purple-100\/60 {
  --tw-gradient-to: #f3e8ff99 var(--tw-gradient-to-position);
}

.to-purple-100\/70 {
  --tw-gradient-to: #f3e8ffb3 var(--tw-gradient-to-position);
}

.to-purple-100\/75 {
  --tw-gradient-to: #f3e8ffbf var(--tw-gradient-to-position);
}

.to-purple-100\/80 {
  --tw-gradient-to: #f3e8ffcc var(--tw-gradient-to-position);
}

.to-purple-100\/90 {
  --tw-gradient-to: #f3e8ffe6 var(--tw-gradient-to-position);
}

.to-purple-100\/95 {
  --tw-gradient-to: #f3e8fff2 var(--tw-gradient-to-position);
}

.to-purple-200 {
  --tw-gradient-to: #e9d5ff var(--tw-gradient-to-position);
}

.to-purple-200\/0 {
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
}

.to-purple-200\/10 {
  --tw-gradient-to: #e9d5ff1a var(--tw-gradient-to-position);
}

.to-purple-200\/100 {
  --tw-gradient-to: #e9d5ff var(--tw-gradient-to-position);
}

.to-purple-200\/20 {
  --tw-gradient-to: #e9d5ff33 var(--tw-gradient-to-position);
}

.to-purple-200\/25 {
  --tw-gradient-to: #e9d5ff40 var(--tw-gradient-to-position);
}

.to-purple-200\/30 {
  --tw-gradient-to: #e9d5ff4d var(--tw-gradient-to-position);
}

.to-purple-200\/40 {
  --tw-gradient-to: #e9d5ff66 var(--tw-gradient-to-position);
}

.to-purple-200\/5 {
  --tw-gradient-to: #e9d5ff0d var(--tw-gradient-to-position);
}

.to-purple-200\/50 {
  --tw-gradient-to: #e9d5ff80 var(--tw-gradient-to-position);
}

.to-purple-200\/60 {
  --tw-gradient-to: #e9d5ff99 var(--tw-gradient-to-position);
}

.to-purple-200\/70 {
  --tw-gradient-to: #e9d5ffb3 var(--tw-gradient-to-position);
}

.to-purple-200\/75 {
  --tw-gradient-to: #e9d5ffbf var(--tw-gradient-to-position);
}

.to-purple-200\/80 {
  --tw-gradient-to: #e9d5ffcc var(--tw-gradient-to-position);
}

.to-purple-200\/90 {
  --tw-gradient-to: #e9d5ffe6 var(--tw-gradient-to-position);
}

.to-purple-200\/95 {
  --tw-gradient-to: #e9d5fff2 var(--tw-gradient-to-position);
}

.to-purple-300 {
  --tw-gradient-to: #d8b4fe var(--tw-gradient-to-position);
}

.to-purple-300\/0 {
  --tw-gradient-to: #d8b4fe00 var(--tw-gradient-to-position);
}

.to-purple-300\/10 {
  --tw-gradient-to: #d8b4fe1a var(--tw-gradient-to-position);
}

.to-purple-300\/100 {
  --tw-gradient-to: #d8b4fe var(--tw-gradient-to-position);
}

.to-purple-300\/20 {
  --tw-gradient-to: #d8b4fe33 var(--tw-gradient-to-position);
}

.to-purple-300\/25 {
  --tw-gradient-to: #d8b4fe40 var(--tw-gradient-to-position);
}

.to-purple-300\/30 {
  --tw-gradient-to: #d8b4fe4d var(--tw-gradient-to-position);
}

.to-purple-300\/40 {
  --tw-gradient-to: #d8b4fe66 var(--tw-gradient-to-position);
}

.to-purple-300\/5 {
  --tw-gradient-to: #d8b4fe0d var(--tw-gradient-to-position);
}

.to-purple-300\/50 {
  --tw-gradient-to: #d8b4fe80 var(--tw-gradient-to-position);
}

.to-purple-300\/60 {
  --tw-gradient-to: #d8b4fe99 var(--tw-gradient-to-position);
}

.to-purple-300\/70 {
  --tw-gradient-to: #d8b4feb3 var(--tw-gradient-to-position);
}

.to-purple-300\/75 {
  --tw-gradient-to: #d8b4febf var(--tw-gradient-to-position);
}

.to-purple-300\/80 {
  --tw-gradient-to: #d8b4fecc var(--tw-gradient-to-position);
}

.to-purple-300\/90 {
  --tw-gradient-to: #d8b4fee6 var(--tw-gradient-to-position);
}

.to-purple-300\/95 {
  --tw-gradient-to: #d8b4fef2 var(--tw-gradient-to-position);
}

.to-purple-400 {
  --tw-gradient-to: #c084fc var(--tw-gradient-to-position);
}

.to-purple-400\/0 {
  --tw-gradient-to: #c084fc00 var(--tw-gradient-to-position);
}

.to-purple-400\/10 {
  --tw-gradient-to: #c084fc1a var(--tw-gradient-to-position);
}

.to-purple-400\/100 {
  --tw-gradient-to: #c084fc var(--tw-gradient-to-position);
}

.to-purple-400\/20 {
  --tw-gradient-to: #c084fc33 var(--tw-gradient-to-position);
}

.to-purple-400\/25 {
  --tw-gradient-to: #c084fc40 var(--tw-gradient-to-position);
}

.to-purple-400\/30 {
  --tw-gradient-to: #c084fc4d var(--tw-gradient-to-position);
}

.to-purple-400\/40 {
  --tw-gradient-to: #c084fc66 var(--tw-gradient-to-position);
}

.to-purple-400\/5 {
  --tw-gradient-to: #c084fc0d var(--tw-gradient-to-position);
}

.to-purple-400\/50 {
  --tw-gradient-to: #c084fc80 var(--tw-gradient-to-position);
}

.to-purple-400\/60 {
  --tw-gradient-to: #c084fc99 var(--tw-gradient-to-position);
}

.to-purple-400\/70 {
  --tw-gradient-to: #c084fcb3 var(--tw-gradient-to-position);
}

.to-purple-400\/75 {
  --tw-gradient-to: #c084fcbf var(--tw-gradient-to-position);
}

.to-purple-400\/80 {
  --tw-gradient-to: #c084fccc var(--tw-gradient-to-position);
}

.to-purple-400\/90 {
  --tw-gradient-to: #c084fce6 var(--tw-gradient-to-position);
}

.to-purple-400\/95 {
  --tw-gradient-to: #c084fcf2 var(--tw-gradient-to-position);
}

.to-purple-50 {
  --tw-gradient-to: #faf5ff var(--tw-gradient-to-position);
}

.to-purple-50\/0 {
  --tw-gradient-to: #faf5ff00 var(--tw-gradient-to-position);
}

.to-purple-50\/10 {
  --tw-gradient-to: #faf5ff1a var(--tw-gradient-to-position);
}

.to-purple-50\/100 {
  --tw-gradient-to: #faf5ff var(--tw-gradient-to-position);
}

.to-purple-50\/20 {
  --tw-gradient-to: #faf5ff33 var(--tw-gradient-to-position);
}

.to-purple-50\/25 {
  --tw-gradient-to: #faf5ff40 var(--tw-gradient-to-position);
}

.to-purple-50\/30 {
  --tw-gradient-to: #faf5ff4d var(--tw-gradient-to-position);
}

.to-purple-50\/40 {
  --tw-gradient-to: #faf5ff66 var(--tw-gradient-to-position);
}

.to-purple-50\/5 {
  --tw-gradient-to: #faf5ff0d var(--tw-gradient-to-position);
}

.to-purple-50\/50 {
  --tw-gradient-to: #faf5ff80 var(--tw-gradient-to-position);
}

.to-purple-50\/60 {
  --tw-gradient-to: #faf5ff99 var(--tw-gradient-to-position);
}

.to-purple-50\/70 {
  --tw-gradient-to: #faf5ffb3 var(--tw-gradient-to-position);
}

.to-purple-50\/75 {
  --tw-gradient-to: #faf5ffbf var(--tw-gradient-to-position);
}

.to-purple-50\/80 {
  --tw-gradient-to: #faf5ffcc var(--tw-gradient-to-position);
}

.to-purple-50\/90 {
  --tw-gradient-to: #faf5ffe6 var(--tw-gradient-to-position);
}

.to-purple-50\/95 {
  --tw-gradient-to: #faf5fff2 var(--tw-gradient-to-position);
}

.to-purple-500 {
  --tw-gradient-to: #a855f7 var(--tw-gradient-to-position);
}

.to-purple-500\/0 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
}

.to-purple-500\/10 {
  --tw-gradient-to: #a855f71a var(--tw-gradient-to-position);
}

.to-purple-500\/100 {
  --tw-gradient-to: #a855f7 var(--tw-gradient-to-position);
}

.to-purple-500\/20 {
  --tw-gradient-to: #a855f733 var(--tw-gradient-to-position);
}

.to-purple-500\/25 {
  --tw-gradient-to: #a855f740 var(--tw-gradient-to-position);
}

.to-purple-500\/30 {
  --tw-gradient-to: #a855f74d var(--tw-gradient-to-position);
}

.to-purple-500\/40 {
  --tw-gradient-to: #a855f766 var(--tw-gradient-to-position);
}

.to-purple-500\/5 {
  --tw-gradient-to: #a855f70d var(--tw-gradient-to-position);
}

.to-purple-500\/50 {
  --tw-gradient-to: #a855f780 var(--tw-gradient-to-position);
}

.to-purple-500\/60 {
  --tw-gradient-to: #a855f799 var(--tw-gradient-to-position);
}

.to-purple-500\/70 {
  --tw-gradient-to: #a855f7b3 var(--tw-gradient-to-position);
}

.to-purple-500\/75 {
  --tw-gradient-to: #a855f7bf var(--tw-gradient-to-position);
}

.to-purple-500\/80 {
  --tw-gradient-to: #a855f7cc var(--tw-gradient-to-position);
}

.to-purple-500\/90 {
  --tw-gradient-to: #a855f7e6 var(--tw-gradient-to-position);
}

.to-purple-500\/95 {
  --tw-gradient-to: #a855f7f2 var(--tw-gradient-to-position);
}

.to-purple-600 {
  --tw-gradient-to: #9333ea var(--tw-gradient-to-position);
}

.to-purple-600\/0 {
  --tw-gradient-to: #9333ea00 var(--tw-gradient-to-position);
}

.to-purple-600\/10 {
  --tw-gradient-to: #9333ea1a var(--tw-gradient-to-position);
}

.to-purple-600\/100 {
  --tw-gradient-to: #9333ea var(--tw-gradient-to-position);
}

.to-purple-600\/20 {
  --tw-gradient-to: #9333ea33 var(--tw-gradient-to-position);
}

.to-purple-600\/25 {
  --tw-gradient-to: #9333ea40 var(--tw-gradient-to-position);
}

.to-purple-600\/30 {
  --tw-gradient-to: #9333ea4d var(--tw-gradient-to-position);
}

.to-purple-600\/40 {
  --tw-gradient-to: #9333ea66 var(--tw-gradient-to-position);
}

.to-purple-600\/5 {
  --tw-gradient-to: #9333ea0d var(--tw-gradient-to-position);
}

.to-purple-600\/50 {
  --tw-gradient-to: #9333ea80 var(--tw-gradient-to-position);
}

.to-purple-600\/60 {
  --tw-gradient-to: #9333ea99 var(--tw-gradient-to-position);
}

.to-purple-600\/70 {
  --tw-gradient-to: #9333eab3 var(--tw-gradient-to-position);
}

.to-purple-600\/75 {
  --tw-gradient-to: #9333eabf var(--tw-gradient-to-position);
}

.to-purple-600\/80 {
  --tw-gradient-to: #9333eacc var(--tw-gradient-to-position);
}

.to-purple-600\/90 {
  --tw-gradient-to: #9333eae6 var(--tw-gradient-to-position);
}

.to-purple-600\/95 {
  --tw-gradient-to: #9333eaf2 var(--tw-gradient-to-position);
}

.to-purple-700 {
  --tw-gradient-to: #7e22ce var(--tw-gradient-to-position);
}

.to-purple-700\/0 {
  --tw-gradient-to: #7e22ce00 var(--tw-gradient-to-position);
}

.to-purple-700\/10 {
  --tw-gradient-to: #7e22ce1a var(--tw-gradient-to-position);
}

.to-purple-700\/100 {
  --tw-gradient-to: #7e22ce var(--tw-gradient-to-position);
}

.to-purple-700\/20 {
  --tw-gradient-to: #7e22ce33 var(--tw-gradient-to-position);
}

.to-purple-700\/25 {
  --tw-gradient-to: #7e22ce40 var(--tw-gradient-to-position);
}

.to-purple-700\/30 {
  --tw-gradient-to: #7e22ce4d var(--tw-gradient-to-position);
}

.to-purple-700\/40 {
  --tw-gradient-to: #7e22ce66 var(--tw-gradient-to-position);
}

.to-purple-700\/5 {
  --tw-gradient-to: #7e22ce0d var(--tw-gradient-to-position);
}

.to-purple-700\/50 {
  --tw-gradient-to: #7e22ce80 var(--tw-gradient-to-position);
}

.to-purple-700\/60 {
  --tw-gradient-to: #7e22ce99 var(--tw-gradient-to-position);
}

.to-purple-700\/70 {
  --tw-gradient-to: #7e22ceb3 var(--tw-gradient-to-position);
}

.to-purple-700\/75 {
  --tw-gradient-to: #7e22cebf var(--tw-gradient-to-position);
}

.to-purple-700\/80 {
  --tw-gradient-to: #7e22cecc var(--tw-gradient-to-position);
}

.to-purple-700\/90 {
  --tw-gradient-to: #7e22cee6 var(--tw-gradient-to-position);
}

.to-purple-700\/95 {
  --tw-gradient-to: #7e22cef2 var(--tw-gradient-to-position);
}

.to-purple-800 {
  --tw-gradient-to: #6b21a8 var(--tw-gradient-to-position);
}

.to-purple-800\/0 {
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
}

.to-purple-800\/10 {
  --tw-gradient-to: #6b21a81a var(--tw-gradient-to-position);
}

.to-purple-800\/100 {
  --tw-gradient-to: #6b21a8 var(--tw-gradient-to-position);
}

.to-purple-800\/20 {
  --tw-gradient-to: #6b21a833 var(--tw-gradient-to-position);
}

.to-purple-800\/25 {
  --tw-gradient-to: #6b21a840 var(--tw-gradient-to-position);
}

.to-purple-800\/30 {
  --tw-gradient-to: #6b21a84d var(--tw-gradient-to-position);
}

.to-purple-800\/40 {
  --tw-gradient-to: #6b21a866 var(--tw-gradient-to-position);
}

.to-purple-800\/5 {
  --tw-gradient-to: #6b21a80d var(--tw-gradient-to-position);
}

.to-purple-800\/50 {
  --tw-gradient-to: #6b21a880 var(--tw-gradient-to-position);
}

.to-purple-800\/60 {
  --tw-gradient-to: #6b21a899 var(--tw-gradient-to-position);
}

.to-purple-800\/70 {
  --tw-gradient-to: #6b21a8b3 var(--tw-gradient-to-position);
}

.to-purple-800\/75 {
  --tw-gradient-to: #6b21a8bf var(--tw-gradient-to-position);
}

.to-purple-800\/80 {
  --tw-gradient-to: #6b21a8cc var(--tw-gradient-to-position);
}

.to-purple-800\/90 {
  --tw-gradient-to: #6b21a8e6 var(--tw-gradient-to-position);
}

.to-purple-800\/95 {
  --tw-gradient-to: #6b21a8f2 var(--tw-gradient-to-position);
}

.to-purple-900 {
  --tw-gradient-to: #581c87 var(--tw-gradient-to-position);
}

.to-purple-900\/0 {
  --tw-gradient-to: #581c8700 var(--tw-gradient-to-position);
}

.to-purple-900\/10 {
  --tw-gradient-to: #581c871a var(--tw-gradient-to-position);
}

.to-purple-900\/100 {
  --tw-gradient-to: #581c87 var(--tw-gradient-to-position);
}

.to-purple-900\/20 {
  --tw-gradient-to: #581c8733 var(--tw-gradient-to-position);
}

.to-purple-900\/25 {
  --tw-gradient-to: #581c8740 var(--tw-gradient-to-position);
}

.to-purple-900\/30 {
  --tw-gradient-to: #581c874d var(--tw-gradient-to-position);
}

.to-purple-900\/40 {
  --tw-gradient-to: #581c8766 var(--tw-gradient-to-position);
}

.to-purple-900\/5 {
  --tw-gradient-to: #581c870d var(--tw-gradient-to-position);
}

.to-purple-900\/50 {
  --tw-gradient-to: #581c8780 var(--tw-gradient-to-position);
}

.to-purple-900\/60 {
  --tw-gradient-to: #581c8799 var(--tw-gradient-to-position);
}

.to-purple-900\/70 {
  --tw-gradient-to: #581c87b3 var(--tw-gradient-to-position);
}

.to-purple-900\/75 {
  --tw-gradient-to: #581c87bf var(--tw-gradient-to-position);
}

.to-purple-900\/80 {
  --tw-gradient-to: #581c87cc var(--tw-gradient-to-position);
}

.to-purple-900\/90 {
  --tw-gradient-to: #581c87e6 var(--tw-gradient-to-position);
}

.to-purple-900\/95 {
  --tw-gradient-to: #581c87f2 var(--tw-gradient-to-position);
}

.to-purple-950 {
  --tw-gradient-to: #3b0764 var(--tw-gradient-to-position);
}

.to-purple-950\/0 {
  --tw-gradient-to: #3b076400 var(--tw-gradient-to-position);
}

.to-purple-950\/10 {
  --tw-gradient-to: #3b07641a var(--tw-gradient-to-position);
}

.to-purple-950\/100 {
  --tw-gradient-to: #3b0764 var(--tw-gradient-to-position);
}

.to-purple-950\/20 {
  --tw-gradient-to: #3b076433 var(--tw-gradient-to-position);
}

.to-purple-950\/25 {
  --tw-gradient-to: #3b076440 var(--tw-gradient-to-position);
}

.to-purple-950\/30 {
  --tw-gradient-to: #3b07644d var(--tw-gradient-to-position);
}

.to-purple-950\/40 {
  --tw-gradient-to: #3b076466 var(--tw-gradient-to-position);
}

.to-purple-950\/5 {
  --tw-gradient-to: #3b07640d var(--tw-gradient-to-position);
}

.to-purple-950\/50 {
  --tw-gradient-to: #3b076480 var(--tw-gradient-to-position);
}

.to-purple-950\/60 {
  --tw-gradient-to: #3b076499 var(--tw-gradient-to-position);
}

.to-purple-950\/70 {
  --tw-gradient-to: #3b0764b3 var(--tw-gradient-to-position);
}

.to-purple-950\/75 {
  --tw-gradient-to: #3b0764bf var(--tw-gradient-to-position);
}

.to-purple-950\/80 {
  --tw-gradient-to: #3b0764cc var(--tw-gradient-to-position);
}

.to-purple-950\/90 {
  --tw-gradient-to: #3b0764e6 var(--tw-gradient-to-position);
}

.to-purple-950\/95 {
  --tw-gradient-to: #3b0764f2 var(--tw-gradient-to-position);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.to-transparent\/0 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
}

.to-transparent\/10 {
  --tw-gradient-to: #0000001a var(--tw-gradient-to-position);
}

.to-transparent\/100 {
  --tw-gradient-to: #000 var(--tw-gradient-to-position);
}

.to-transparent\/20 {
  --tw-gradient-to: #0003 var(--tw-gradient-to-position);
}

.to-transparent\/25 {
  --tw-gradient-to: #00000040 var(--tw-gradient-to-position);
}

.to-transparent\/30 {
  --tw-gradient-to: #0000004d var(--tw-gradient-to-position);
}

.to-transparent\/40 {
  --tw-gradient-to: #0006 var(--tw-gradient-to-position);
}

.to-transparent\/5 {
  --tw-gradient-to: #0000000d var(--tw-gradient-to-position);
}

.to-transparent\/50 {
  --tw-gradient-to: #00000080 var(--tw-gradient-to-position);
}

.to-transparent\/60 {
  --tw-gradient-to: #0009 var(--tw-gradient-to-position);
}

.to-transparent\/70 {
  --tw-gradient-to: #000000b3 var(--tw-gradient-to-position);
}

.to-transparent\/75 {
  --tw-gradient-to: #000000bf var(--tw-gradient-to-position);
}

.to-transparent\/80 {
  --tw-gradient-to: #000c var(--tw-gradient-to-position);
}

.to-transparent\/90 {
  --tw-gradient-to: #000000e6 var(--tw-gradient-to-position);
}

.to-transparent\/95 {
  --tw-gradient-to: #000000f2 var(--tw-gradient-to-position);
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-clip-content {
  background-clip: content-box;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.bg-bottom {
  background-position: bottom;
}

.bg-left-bottom {
  background-position: 0 100%;
}

.bg-left-top {
  background-position: 0 0;
}

.bg-right-bottom {
  background-position: 100% 100%;
}

.bg-right-top {
  background-position: 100% 0;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-origin-padding {
  background-origin: padding-box;
}

.fill-amber-100 {
  fill: #fef3c7;
}

.fill-amber-100\/0 {
  fill: #fef3c700;
}

.fill-amber-100\/10 {
  fill: #fef3c71a;
}

.fill-amber-100\/100 {
  fill: #fef3c7;
}

.fill-amber-100\/20 {
  fill: #fef3c733;
}

.fill-amber-100\/25 {
  fill: #fef3c740;
}

.fill-amber-100\/30 {
  fill: #fef3c74d;
}

.fill-amber-100\/40 {
  fill: #fef3c766;
}

.fill-amber-100\/5 {
  fill: #fef3c70d;
}

.fill-amber-100\/50 {
  fill: #fef3c780;
}

.fill-amber-100\/60 {
  fill: #fef3c799;
}

.fill-amber-100\/70 {
  fill: #fef3c7b3;
}

.fill-amber-100\/75 {
  fill: #fef3c7bf;
}

.fill-amber-100\/80 {
  fill: #fef3c7cc;
}

.fill-amber-100\/90 {
  fill: #fef3c7e6;
}

.fill-amber-100\/95 {
  fill: #fef3c7f2;
}

.fill-amber-200 {
  fill: #fde68a;
}

.fill-amber-200\/0 {
  fill: #fde68a00;
}

.fill-amber-200\/10 {
  fill: #fde68a1a;
}

.fill-amber-200\/100 {
  fill: #fde68a;
}

.fill-amber-200\/20 {
  fill: #fde68a33;
}

.fill-amber-200\/25 {
  fill: #fde68a40;
}

.fill-amber-200\/30 {
  fill: #fde68a4d;
}

.fill-amber-200\/40 {
  fill: #fde68a66;
}

.fill-amber-200\/5 {
  fill: #fde68a0d;
}

.fill-amber-200\/50 {
  fill: #fde68a80;
}

.fill-amber-200\/60 {
  fill: #fde68a99;
}

.fill-amber-200\/70 {
  fill: #fde68ab3;
}

.fill-amber-200\/75 {
  fill: #fde68abf;
}

.fill-amber-200\/80 {
  fill: #fde68acc;
}

.fill-amber-200\/90 {
  fill: #fde68ae6;
}

.fill-amber-200\/95 {
  fill: #fde68af2;
}

.fill-amber-300 {
  fill: #fcd34d;
}

.fill-amber-300\/0 {
  fill: #fcd34d00;
}

.fill-amber-300\/10 {
  fill: #fcd34d1a;
}

.fill-amber-300\/100 {
  fill: #fcd34d;
}

.fill-amber-300\/20 {
  fill: #fcd34d33;
}

.fill-amber-300\/25 {
  fill: #fcd34d40;
}

.fill-amber-300\/30 {
  fill: #fcd34d4d;
}

.fill-amber-300\/40 {
  fill: #fcd34d66;
}

.fill-amber-300\/5 {
  fill: #fcd34d0d;
}

.fill-amber-300\/50 {
  fill: #fcd34d80;
}

.fill-amber-300\/60 {
  fill: #fcd34d99;
}

.fill-amber-300\/70 {
  fill: #fcd34db3;
}

.fill-amber-300\/75 {
  fill: #fcd34dbf;
}

.fill-amber-300\/80 {
  fill: #fcd34dcc;
}

.fill-amber-300\/90 {
  fill: #fcd34de6;
}

.fill-amber-300\/95 {
  fill: #fcd34df2;
}

.fill-amber-400 {
  fill: #fbbf24;
}

.fill-amber-400\/0 {
  fill: #fbbf2400;
}

.fill-amber-400\/10 {
  fill: #fbbf241a;
}

.fill-amber-400\/100 {
  fill: #fbbf24;
}

.fill-amber-400\/20 {
  fill: #fbbf2433;
}

.fill-amber-400\/25 {
  fill: #fbbf2440;
}

.fill-amber-400\/30 {
  fill: #fbbf244d;
}

.fill-amber-400\/40 {
  fill: #fbbf2466;
}

.fill-amber-400\/5 {
  fill: #fbbf240d;
}

.fill-amber-400\/50 {
  fill: #fbbf2480;
}

.fill-amber-400\/60 {
  fill: #fbbf2499;
}

.fill-amber-400\/70 {
  fill: #fbbf24b3;
}

.fill-amber-400\/75 {
  fill: #fbbf24bf;
}

.fill-amber-400\/80 {
  fill: #fbbf24cc;
}

.fill-amber-400\/90 {
  fill: #fbbf24e6;
}

.fill-amber-400\/95 {
  fill: #fbbf24f2;
}

.fill-amber-50 {
  fill: #fffbeb;
}

.fill-amber-50\/0 {
  fill: #fffbeb00;
}

.fill-amber-50\/10 {
  fill: #fffbeb1a;
}

.fill-amber-50\/100 {
  fill: #fffbeb;
}

.fill-amber-50\/20 {
  fill: #fffbeb33;
}

.fill-amber-50\/25 {
  fill: #fffbeb40;
}

.fill-amber-50\/30 {
  fill: #fffbeb4d;
}

.fill-amber-50\/40 {
  fill: #fffbeb66;
}

.fill-amber-50\/5 {
  fill: #fffbeb0d;
}

.fill-amber-50\/50 {
  fill: #fffbeb80;
}

.fill-amber-50\/60 {
  fill: #fffbeb99;
}

.fill-amber-50\/70 {
  fill: #fffbebb3;
}

.fill-amber-50\/75 {
  fill: #fffbebbf;
}

.fill-amber-50\/80 {
  fill: #fffbebcc;
}

.fill-amber-50\/90 {
  fill: #fffbebe6;
}

.fill-amber-50\/95 {
  fill: #fffbebf2;
}

.fill-amber-500 {
  fill: #f59e0b;
}

.fill-amber-500\/0 {
  fill: #f59e0b00;
}

.fill-amber-500\/10 {
  fill: #f59e0b1a;
}

.fill-amber-500\/100 {
  fill: #f59e0b;
}

.fill-amber-500\/20 {
  fill: #f59e0b33;
}

.fill-amber-500\/25 {
  fill: #f59e0b40;
}

.fill-amber-500\/30 {
  fill: #f59e0b4d;
}

.fill-amber-500\/40 {
  fill: #f59e0b66;
}

.fill-amber-500\/5 {
  fill: #f59e0b0d;
}

.fill-amber-500\/50 {
  fill: #f59e0b80;
}

.fill-amber-500\/60 {
  fill: #f59e0b99;
}

.fill-amber-500\/70 {
  fill: #f59e0bb3;
}

.fill-amber-500\/75 {
  fill: #f59e0bbf;
}

.fill-amber-500\/80 {
  fill: #f59e0bcc;
}

.fill-amber-500\/90 {
  fill: #f59e0be6;
}

.fill-amber-500\/95 {
  fill: #f59e0bf2;
}

.fill-amber-600 {
  fill: #d97706;
}

.fill-amber-600\/0 {
  fill: #d9770600;
}

.fill-amber-600\/10 {
  fill: #d977061a;
}

.fill-amber-600\/100 {
  fill: #d97706;
}

.fill-amber-600\/20 {
  fill: #d9770633;
}

.fill-amber-600\/25 {
  fill: #d9770640;
}

.fill-amber-600\/30 {
  fill: #d977064d;
}

.fill-amber-600\/40 {
  fill: #d9770666;
}

.fill-amber-600\/5 {
  fill: #d977060d;
}

.fill-amber-600\/50 {
  fill: #d9770680;
}

.fill-amber-600\/60 {
  fill: #d9770699;
}

.fill-amber-600\/70 {
  fill: #d97706b3;
}

.fill-amber-600\/75 {
  fill: #d97706bf;
}

.fill-amber-600\/80 {
  fill: #d97706cc;
}

.fill-amber-600\/90 {
  fill: #d97706e6;
}

.fill-amber-600\/95 {
  fill: #d97706f2;
}

.fill-amber-700 {
  fill: #b45309;
}

.fill-amber-700\/0 {
  fill: #b4530900;
}

.fill-amber-700\/10 {
  fill: #b453091a;
}

.fill-amber-700\/100 {
  fill: #b45309;
}

.fill-amber-700\/20 {
  fill: #b4530933;
}

.fill-amber-700\/25 {
  fill: #b4530940;
}

.fill-amber-700\/30 {
  fill: #b453094d;
}

.fill-amber-700\/40 {
  fill: #b4530966;
}

.fill-amber-700\/5 {
  fill: #b453090d;
}

.fill-amber-700\/50 {
  fill: #b4530980;
}

.fill-amber-700\/60 {
  fill: #b4530999;
}

.fill-amber-700\/70 {
  fill: #b45309b3;
}

.fill-amber-700\/75 {
  fill: #b45309bf;
}

.fill-amber-700\/80 {
  fill: #b45309cc;
}

.fill-amber-700\/90 {
  fill: #b45309e6;
}

.fill-amber-700\/95 {
  fill: #b45309f2;
}

.fill-amber-800 {
  fill: #92400e;
}

.fill-amber-800\/0 {
  fill: #92400e00;
}

.fill-amber-800\/10 {
  fill: #92400e1a;
}

.fill-amber-800\/100 {
  fill: #92400e;
}

.fill-amber-800\/20 {
  fill: #92400e33;
}

.fill-amber-800\/25 {
  fill: #92400e40;
}

.fill-amber-800\/30 {
  fill: #92400e4d;
}

.fill-amber-800\/40 {
  fill: #92400e66;
}

.fill-amber-800\/5 {
  fill: #92400e0d;
}

.fill-amber-800\/50 {
  fill: #92400e80;
}

.fill-amber-800\/60 {
  fill: #92400e99;
}

.fill-amber-800\/70 {
  fill: #92400eb3;
}

.fill-amber-800\/75 {
  fill: #92400ebf;
}

.fill-amber-800\/80 {
  fill: #92400ecc;
}

.fill-amber-800\/90 {
  fill: #92400ee6;
}

.fill-amber-800\/95 {
  fill: #92400ef2;
}

.fill-amber-900 {
  fill: #78350f;
}

.fill-amber-900\/0 {
  fill: #78350f00;
}

.fill-amber-900\/10 {
  fill: #78350f1a;
}

.fill-amber-900\/100 {
  fill: #78350f;
}

.fill-amber-900\/20 {
  fill: #78350f33;
}

.fill-amber-900\/25 {
  fill: #78350f40;
}

.fill-amber-900\/30 {
  fill: #78350f4d;
}

.fill-amber-900\/40 {
  fill: #78350f66;
}

.fill-amber-900\/5 {
  fill: #78350f0d;
}

.fill-amber-900\/50 {
  fill: #78350f80;
}

.fill-amber-900\/60 {
  fill: #78350f99;
}

.fill-amber-900\/70 {
  fill: #78350fb3;
}

.fill-amber-900\/75 {
  fill: #78350fbf;
}

.fill-amber-900\/80 {
  fill: #78350fcc;
}

.fill-amber-900\/90 {
  fill: #78350fe6;
}

.fill-amber-900\/95 {
  fill: #78350ff2;
}

.fill-amber-950 {
  fill: #451a03;
}

.fill-amber-950\/0 {
  fill: #451a0300;
}

.fill-amber-950\/10 {
  fill: #451a031a;
}

.fill-amber-950\/100 {
  fill: #451a03;
}

.fill-amber-950\/20 {
  fill: #451a0333;
}

.fill-amber-950\/25 {
  fill: #451a0340;
}

.fill-amber-950\/30 {
  fill: #451a034d;
}

.fill-amber-950\/40 {
  fill: #451a0366;
}

.fill-amber-950\/5 {
  fill: #451a030d;
}

.fill-amber-950\/50 {
  fill: #451a0380;
}

.fill-amber-950\/60 {
  fill: #451a0399;
}

.fill-amber-950\/70 {
  fill: #451a03b3;
}

.fill-amber-950\/75 {
  fill: #451a03bf;
}

.fill-amber-950\/80 {
  fill: #451a03cc;
}

.fill-amber-950\/90 {
  fill: #451a03e6;
}

.fill-amber-950\/95 {
  fill: #451a03f2;
}

.fill-emerald-100 {
  fill: #d1fae5;
}

.fill-emerald-100\/0 {
  fill: #d1fae500;
}

.fill-emerald-100\/10 {
  fill: #d1fae51a;
}

.fill-emerald-100\/100 {
  fill: #d1fae5;
}

.fill-emerald-100\/20 {
  fill: #d1fae533;
}

.fill-emerald-100\/25 {
  fill: #d1fae540;
}

.fill-emerald-100\/30 {
  fill: #d1fae54d;
}

.fill-emerald-100\/40 {
  fill: #d1fae566;
}

.fill-emerald-100\/5 {
  fill: #d1fae50d;
}

.fill-emerald-100\/50 {
  fill: #d1fae580;
}

.fill-emerald-100\/60 {
  fill: #d1fae599;
}

.fill-emerald-100\/70 {
  fill: #d1fae5b3;
}

.fill-emerald-100\/75 {
  fill: #d1fae5bf;
}

.fill-emerald-100\/80 {
  fill: #d1fae5cc;
}

.fill-emerald-100\/90 {
  fill: #d1fae5e6;
}

.fill-emerald-100\/95 {
  fill: #d1fae5f2;
}

.fill-emerald-200 {
  fill: #a7f3d0;
}

.fill-emerald-200\/0 {
  fill: #a7f3d000;
}

.fill-emerald-200\/10 {
  fill: #a7f3d01a;
}

.fill-emerald-200\/100 {
  fill: #a7f3d0;
}

.fill-emerald-200\/20 {
  fill: #a7f3d033;
}

.fill-emerald-200\/25 {
  fill: #a7f3d040;
}

.fill-emerald-200\/30 {
  fill: #a7f3d04d;
}

.fill-emerald-200\/40 {
  fill: #a7f3d066;
}

.fill-emerald-200\/5 {
  fill: #a7f3d00d;
}

.fill-emerald-200\/50 {
  fill: #a7f3d080;
}

.fill-emerald-200\/60 {
  fill: #a7f3d099;
}

.fill-emerald-200\/70 {
  fill: #a7f3d0b3;
}

.fill-emerald-200\/75 {
  fill: #a7f3d0bf;
}

.fill-emerald-200\/80 {
  fill: #a7f3d0cc;
}

.fill-emerald-200\/90 {
  fill: #a7f3d0e6;
}

.fill-emerald-200\/95 {
  fill: #a7f3d0f2;
}

.fill-emerald-300 {
  fill: #6ee7b7;
}

.fill-emerald-300\/0 {
  fill: #6ee7b700;
}

.fill-emerald-300\/10 {
  fill: #6ee7b71a;
}

.fill-emerald-300\/100 {
  fill: #6ee7b7;
}

.fill-emerald-300\/20 {
  fill: #6ee7b733;
}

.fill-emerald-300\/25 {
  fill: #6ee7b740;
}

.fill-emerald-300\/30 {
  fill: #6ee7b74d;
}

.fill-emerald-300\/40 {
  fill: #6ee7b766;
}

.fill-emerald-300\/5 {
  fill: #6ee7b70d;
}

.fill-emerald-300\/50 {
  fill: #6ee7b780;
}

.fill-emerald-300\/60 {
  fill: #6ee7b799;
}

.fill-emerald-300\/70 {
  fill: #6ee7b7b3;
}

.fill-emerald-300\/75 {
  fill: #6ee7b7bf;
}

.fill-emerald-300\/80 {
  fill: #6ee7b7cc;
}

.fill-emerald-300\/90 {
  fill: #6ee7b7e6;
}

.fill-emerald-300\/95 {
  fill: #6ee7b7f2;
}

.fill-emerald-400 {
  fill: #34d399;
}

.fill-emerald-400\/0 {
  fill: #34d39900;
}

.fill-emerald-400\/10 {
  fill: #34d3991a;
}

.fill-emerald-400\/100 {
  fill: #34d399;
}

.fill-emerald-400\/20 {
  fill: #34d39933;
}

.fill-emerald-400\/25 {
  fill: #34d39940;
}

.fill-emerald-400\/30 {
  fill: #34d3994d;
}

.fill-emerald-400\/40 {
  fill: #34d39966;
}

.fill-emerald-400\/5 {
  fill: #34d3990d;
}

.fill-emerald-400\/50 {
  fill: #34d39980;
}

.fill-emerald-400\/60 {
  fill: #34d39999;
}

.fill-emerald-400\/70 {
  fill: #34d399b3;
}

.fill-emerald-400\/75 {
  fill: #34d399bf;
}

.fill-emerald-400\/80 {
  fill: #34d399cc;
}

.fill-emerald-400\/90 {
  fill: #34d399e6;
}

.fill-emerald-400\/95 {
  fill: #34d399f2;
}

.fill-emerald-50 {
  fill: #ecfdf5;
}

.fill-emerald-50\/0 {
  fill: #ecfdf500;
}

.fill-emerald-50\/10 {
  fill: #ecfdf51a;
}

.fill-emerald-50\/100 {
  fill: #ecfdf5;
}

.fill-emerald-50\/20 {
  fill: #ecfdf533;
}

.fill-emerald-50\/25 {
  fill: #ecfdf540;
}

.fill-emerald-50\/30 {
  fill: #ecfdf54d;
}

.fill-emerald-50\/40 {
  fill: #ecfdf566;
}

.fill-emerald-50\/5 {
  fill: #ecfdf50d;
}

.fill-emerald-50\/50 {
  fill: #ecfdf580;
}

.fill-emerald-50\/60 {
  fill: #ecfdf599;
}

.fill-emerald-50\/70 {
  fill: #ecfdf5b3;
}

.fill-emerald-50\/75 {
  fill: #ecfdf5bf;
}

.fill-emerald-50\/80 {
  fill: #ecfdf5cc;
}

.fill-emerald-50\/90 {
  fill: #ecfdf5e6;
}

.fill-emerald-50\/95 {
  fill: #ecfdf5f2;
}

.fill-emerald-500 {
  fill: #10b981;
}

.fill-emerald-500\/0 {
  fill: #10b98100;
}

.fill-emerald-500\/10 {
  fill: #10b9811a;
}

.fill-emerald-500\/100 {
  fill: #10b981;
}

.fill-emerald-500\/20 {
  fill: #10b98133;
}

.fill-emerald-500\/25 {
  fill: #10b98140;
}

.fill-emerald-500\/30 {
  fill: #10b9814d;
}

.fill-emerald-500\/40 {
  fill: #10b98166;
}

.fill-emerald-500\/5 {
  fill: #10b9810d;
}

.fill-emerald-500\/50 {
  fill: #10b98180;
}

.fill-emerald-500\/60 {
  fill: #10b98199;
}

.fill-emerald-500\/70 {
  fill: #10b981b3;
}

.fill-emerald-500\/75 {
  fill: #10b981bf;
}

.fill-emerald-500\/80 {
  fill: #10b981cc;
}

.fill-emerald-500\/90 {
  fill: #10b981e6;
}

.fill-emerald-500\/95 {
  fill: #10b981f2;
}

.fill-emerald-600 {
  fill: #059669;
}

.fill-emerald-600\/0 {
  fill: #05966900;
}

.fill-emerald-600\/10 {
  fill: #0596691a;
}

.fill-emerald-600\/100 {
  fill: #059669;
}

.fill-emerald-600\/20 {
  fill: #05966933;
}

.fill-emerald-600\/25 {
  fill: #05966940;
}

.fill-emerald-600\/30 {
  fill: #0596694d;
}

.fill-emerald-600\/40 {
  fill: #05966966;
}

.fill-emerald-600\/5 {
  fill: #0596690d;
}

.fill-emerald-600\/50 {
  fill: #05966980;
}

.fill-emerald-600\/60 {
  fill: #05966999;
}

.fill-emerald-600\/70 {
  fill: #059669b3;
}

.fill-emerald-600\/75 {
  fill: #059669bf;
}

.fill-emerald-600\/80 {
  fill: #059669cc;
}

.fill-emerald-600\/90 {
  fill: #059669e6;
}

.fill-emerald-600\/95 {
  fill: #059669f2;
}

.fill-emerald-700 {
  fill: #047857;
}

.fill-emerald-700\/0 {
  fill: #04785700;
}

.fill-emerald-700\/10 {
  fill: #0478571a;
}

.fill-emerald-700\/100 {
  fill: #047857;
}

.fill-emerald-700\/20 {
  fill: #04785733;
}

.fill-emerald-700\/25 {
  fill: #04785740;
}

.fill-emerald-700\/30 {
  fill: #0478574d;
}

.fill-emerald-700\/40 {
  fill: #04785766;
}

.fill-emerald-700\/5 {
  fill: #0478570d;
}

.fill-emerald-700\/50 {
  fill: #04785780;
}

.fill-emerald-700\/60 {
  fill: #04785799;
}

.fill-emerald-700\/70 {
  fill: #047857b3;
}

.fill-emerald-700\/75 {
  fill: #047857bf;
}

.fill-emerald-700\/80 {
  fill: #047857cc;
}

.fill-emerald-700\/90 {
  fill: #047857e6;
}

.fill-emerald-700\/95 {
  fill: #047857f2;
}

.fill-emerald-800 {
  fill: #065f46;
}

.fill-emerald-800\/0 {
  fill: #065f4600;
}

.fill-emerald-800\/10 {
  fill: #065f461a;
}

.fill-emerald-800\/100 {
  fill: #065f46;
}

.fill-emerald-800\/20 {
  fill: #065f4633;
}

.fill-emerald-800\/25 {
  fill: #065f4640;
}

.fill-emerald-800\/30 {
  fill: #065f464d;
}

.fill-emerald-800\/40 {
  fill: #065f4666;
}

.fill-emerald-800\/5 {
  fill: #065f460d;
}

.fill-emerald-800\/50 {
  fill: #065f4680;
}

.fill-emerald-800\/60 {
  fill: #065f4699;
}

.fill-emerald-800\/70 {
  fill: #065f46b3;
}

.fill-emerald-800\/75 {
  fill: #065f46bf;
}

.fill-emerald-800\/80 {
  fill: #065f46cc;
}

.fill-emerald-800\/90 {
  fill: #065f46e6;
}

.fill-emerald-800\/95 {
  fill: #065f46f2;
}

.fill-emerald-900 {
  fill: #064e3b;
}

.fill-emerald-900\/0 {
  fill: #064e3b00;
}

.fill-emerald-900\/10 {
  fill: #064e3b1a;
}

.fill-emerald-900\/100 {
  fill: #064e3b;
}

.fill-emerald-900\/20 {
  fill: #064e3b33;
}

.fill-emerald-900\/25 {
  fill: #064e3b40;
}

.fill-emerald-900\/30 {
  fill: #064e3b4d;
}

.fill-emerald-900\/40 {
  fill: #064e3b66;
}

.fill-emerald-900\/5 {
  fill: #064e3b0d;
}

.fill-emerald-900\/50 {
  fill: #064e3b80;
}

.fill-emerald-900\/60 {
  fill: #064e3b99;
}

.fill-emerald-900\/70 {
  fill: #064e3bb3;
}

.fill-emerald-900\/75 {
  fill: #064e3bbf;
}

.fill-emerald-900\/80 {
  fill: #064e3bcc;
}

.fill-emerald-900\/90 {
  fill: #064e3be6;
}

.fill-emerald-900\/95 {
  fill: #064e3bf2;
}

.fill-emerald-950 {
  fill: #022c22;
}

.fill-emerald-950\/0 {
  fill: #022c2200;
}

.fill-emerald-950\/10 {
  fill: #022c221a;
}

.fill-emerald-950\/100 {
  fill: #022c22;
}

.fill-emerald-950\/20 {
  fill: #022c2233;
}

.fill-emerald-950\/25 {
  fill: #022c2240;
}

.fill-emerald-950\/30 {
  fill: #022c224d;
}

.fill-emerald-950\/40 {
  fill: #022c2266;
}

.fill-emerald-950\/5 {
  fill: #022c220d;
}

.fill-emerald-950\/50 {
  fill: #022c2280;
}

.fill-emerald-950\/60 {
  fill: #022c2299;
}

.fill-emerald-950\/70 {
  fill: #022c22b3;
}

.fill-emerald-950\/75 {
  fill: #022c22bf;
}

.fill-emerald-950\/80 {
  fill: #022c22cc;
}

.fill-emerald-950\/90 {
  fill: #022c22e6;
}

.fill-emerald-950\/95 {
  fill: #022c22f2;
}

.fill-lime-100 {
  fill: #ecfccb;
}

.fill-lime-100\/0 {
  fill: #ecfccb00;
}

.fill-lime-100\/10 {
  fill: #ecfccb1a;
}

.fill-lime-100\/100 {
  fill: #ecfccb;
}

.fill-lime-100\/20 {
  fill: #ecfccb33;
}

.fill-lime-100\/25 {
  fill: #ecfccb40;
}

.fill-lime-100\/30 {
  fill: #ecfccb4d;
}

.fill-lime-100\/40 {
  fill: #ecfccb66;
}

.fill-lime-100\/5 {
  fill: #ecfccb0d;
}

.fill-lime-100\/50 {
  fill: #ecfccb80;
}

.fill-lime-100\/60 {
  fill: #ecfccb99;
}

.fill-lime-100\/70 {
  fill: #ecfccbb3;
}

.fill-lime-100\/75 {
  fill: #ecfccbbf;
}

.fill-lime-100\/80 {
  fill: #ecfccbcc;
}

.fill-lime-100\/90 {
  fill: #ecfccbe6;
}

.fill-lime-100\/95 {
  fill: #ecfccbf2;
}

.fill-lime-200 {
  fill: #d9f99d;
}

.fill-lime-200\/0 {
  fill: #d9f99d00;
}

.fill-lime-200\/10 {
  fill: #d9f99d1a;
}

.fill-lime-200\/100 {
  fill: #d9f99d;
}

.fill-lime-200\/20 {
  fill: #d9f99d33;
}

.fill-lime-200\/25 {
  fill: #d9f99d40;
}

.fill-lime-200\/30 {
  fill: #d9f99d4d;
}

.fill-lime-200\/40 {
  fill: #d9f99d66;
}

.fill-lime-200\/5 {
  fill: #d9f99d0d;
}

.fill-lime-200\/50 {
  fill: #d9f99d80;
}

.fill-lime-200\/60 {
  fill: #d9f99d99;
}

.fill-lime-200\/70 {
  fill: #d9f99db3;
}

.fill-lime-200\/75 {
  fill: #d9f99dbf;
}

.fill-lime-200\/80 {
  fill: #d9f99dcc;
}

.fill-lime-200\/90 {
  fill: #d9f99de6;
}

.fill-lime-200\/95 {
  fill: #d9f99df2;
}

.fill-lime-300 {
  fill: #bef264;
}

.fill-lime-300\/0 {
  fill: #bef26400;
}

.fill-lime-300\/10 {
  fill: #bef2641a;
}

.fill-lime-300\/100 {
  fill: #bef264;
}

.fill-lime-300\/20 {
  fill: #bef26433;
}

.fill-lime-300\/25 {
  fill: #bef26440;
}

.fill-lime-300\/30 {
  fill: #bef2644d;
}

.fill-lime-300\/40 {
  fill: #bef26466;
}

.fill-lime-300\/5 {
  fill: #bef2640d;
}

.fill-lime-300\/50 {
  fill: #bef26480;
}

.fill-lime-300\/60 {
  fill: #bef26499;
}

.fill-lime-300\/70 {
  fill: #bef264b3;
}

.fill-lime-300\/75 {
  fill: #bef264bf;
}

.fill-lime-300\/80 {
  fill: #bef264cc;
}

.fill-lime-300\/90 {
  fill: #bef264e6;
}

.fill-lime-300\/95 {
  fill: #bef264f2;
}

.fill-lime-400 {
  fill: #a3e635;
}

.fill-lime-400\/0 {
  fill: #a3e63500;
}

.fill-lime-400\/10 {
  fill: #a3e6351a;
}

.fill-lime-400\/100 {
  fill: #a3e635;
}

.fill-lime-400\/20 {
  fill: #a3e63533;
}

.fill-lime-400\/25 {
  fill: #a3e63540;
}

.fill-lime-400\/30 {
  fill: #a3e6354d;
}

.fill-lime-400\/40 {
  fill: #a3e63566;
}

.fill-lime-400\/5 {
  fill: #a3e6350d;
}

.fill-lime-400\/50 {
  fill: #a3e63580;
}

.fill-lime-400\/60 {
  fill: #a3e63599;
}

.fill-lime-400\/70 {
  fill: #a3e635b3;
}

.fill-lime-400\/75 {
  fill: #a3e635bf;
}

.fill-lime-400\/80 {
  fill: #a3e635cc;
}

.fill-lime-400\/90 {
  fill: #a3e635e6;
}

.fill-lime-400\/95 {
  fill: #a3e635f2;
}

.fill-lime-50 {
  fill: #f7fee7;
}

.fill-lime-50\/0 {
  fill: #f7fee700;
}

.fill-lime-50\/10 {
  fill: #f7fee71a;
}

.fill-lime-50\/100 {
  fill: #f7fee7;
}

.fill-lime-50\/20 {
  fill: #f7fee733;
}

.fill-lime-50\/25 {
  fill: #f7fee740;
}

.fill-lime-50\/30 {
  fill: #f7fee74d;
}

.fill-lime-50\/40 {
  fill: #f7fee766;
}

.fill-lime-50\/5 {
  fill: #f7fee70d;
}

.fill-lime-50\/50 {
  fill: #f7fee780;
}

.fill-lime-50\/60 {
  fill: #f7fee799;
}

.fill-lime-50\/70 {
  fill: #f7fee7b3;
}

.fill-lime-50\/75 {
  fill: #f7fee7bf;
}

.fill-lime-50\/80 {
  fill: #f7fee7cc;
}

.fill-lime-50\/90 {
  fill: #f7fee7e6;
}

.fill-lime-50\/95 {
  fill: #f7fee7f2;
}

.fill-lime-500 {
  fill: #84cc16;
}

.fill-lime-500\/0 {
  fill: #84cc1600;
}

.fill-lime-500\/10 {
  fill: #84cc161a;
}

.fill-lime-500\/100 {
  fill: #84cc16;
}

.fill-lime-500\/20 {
  fill: #84cc1633;
}

.fill-lime-500\/25 {
  fill: #84cc1640;
}

.fill-lime-500\/30 {
  fill: #84cc164d;
}

.fill-lime-500\/40 {
  fill: #84cc1666;
}

.fill-lime-500\/5 {
  fill: #84cc160d;
}

.fill-lime-500\/50 {
  fill: #84cc1680;
}

.fill-lime-500\/60 {
  fill: #84cc1699;
}

.fill-lime-500\/70 {
  fill: #84cc16b3;
}

.fill-lime-500\/75 {
  fill: #84cc16bf;
}

.fill-lime-500\/80 {
  fill: #84cc16cc;
}

.fill-lime-500\/90 {
  fill: #84cc16e6;
}

.fill-lime-500\/95 {
  fill: #84cc16f2;
}

.fill-lime-600 {
  fill: #65a30d;
}

.fill-lime-600\/0 {
  fill: #65a30d00;
}

.fill-lime-600\/10 {
  fill: #65a30d1a;
}

.fill-lime-600\/100 {
  fill: #65a30d;
}

.fill-lime-600\/20 {
  fill: #65a30d33;
}

.fill-lime-600\/25 {
  fill: #65a30d40;
}

.fill-lime-600\/30 {
  fill: #65a30d4d;
}

.fill-lime-600\/40 {
  fill: #65a30d66;
}

.fill-lime-600\/5 {
  fill: #65a30d0d;
}

.fill-lime-600\/50 {
  fill: #65a30d80;
}

.fill-lime-600\/60 {
  fill: #65a30d99;
}

.fill-lime-600\/70 {
  fill: #65a30db3;
}

.fill-lime-600\/75 {
  fill: #65a30dbf;
}

.fill-lime-600\/80 {
  fill: #65a30dcc;
}

.fill-lime-600\/90 {
  fill: #65a30de6;
}

.fill-lime-600\/95 {
  fill: #65a30df2;
}

.fill-lime-700 {
  fill: #4d7c0f;
}

.fill-lime-700\/0 {
  fill: #4d7c0f00;
}

.fill-lime-700\/10 {
  fill: #4d7c0f1a;
}

.fill-lime-700\/100 {
  fill: #4d7c0f;
}

.fill-lime-700\/20 {
  fill: #4d7c0f33;
}

.fill-lime-700\/25 {
  fill: #4d7c0f40;
}

.fill-lime-700\/30 {
  fill: #4d7c0f4d;
}

.fill-lime-700\/40 {
  fill: #4d7c0f66;
}

.fill-lime-700\/5 {
  fill: #4d7c0f0d;
}

.fill-lime-700\/50 {
  fill: #4d7c0f80;
}

.fill-lime-700\/60 {
  fill: #4d7c0f99;
}

.fill-lime-700\/70 {
  fill: #4d7c0fb3;
}

.fill-lime-700\/75 {
  fill: #4d7c0fbf;
}

.fill-lime-700\/80 {
  fill: #4d7c0fcc;
}

.fill-lime-700\/90 {
  fill: #4d7c0fe6;
}

.fill-lime-700\/95 {
  fill: #4d7c0ff2;
}

.fill-lime-800 {
  fill: #3f6212;
}

.fill-lime-800\/0 {
  fill: #3f621200;
}

.fill-lime-800\/10 {
  fill: #3f62121a;
}

.fill-lime-800\/100 {
  fill: #3f6212;
}

.fill-lime-800\/20 {
  fill: #3f621233;
}

.fill-lime-800\/25 {
  fill: #3f621240;
}

.fill-lime-800\/30 {
  fill: #3f62124d;
}

.fill-lime-800\/40 {
  fill: #3f621266;
}

.fill-lime-800\/5 {
  fill: #3f62120d;
}

.fill-lime-800\/50 {
  fill: #3f621280;
}

.fill-lime-800\/60 {
  fill: #3f621299;
}

.fill-lime-800\/70 {
  fill: #3f6212b3;
}

.fill-lime-800\/75 {
  fill: #3f6212bf;
}

.fill-lime-800\/80 {
  fill: #3f6212cc;
}

.fill-lime-800\/90 {
  fill: #3f6212e6;
}

.fill-lime-800\/95 {
  fill: #3f6212f2;
}

.fill-lime-900 {
  fill: #365314;
}

.fill-lime-900\/0 {
  fill: #36531400;
}

.fill-lime-900\/10 {
  fill: #3653141a;
}

.fill-lime-900\/100 {
  fill: #365314;
}

.fill-lime-900\/20 {
  fill: #36531433;
}

.fill-lime-900\/25 {
  fill: #36531440;
}

.fill-lime-900\/30 {
  fill: #3653144d;
}

.fill-lime-900\/40 {
  fill: #36531466;
}

.fill-lime-900\/5 {
  fill: #3653140d;
}

.fill-lime-900\/50 {
  fill: #36531480;
}

.fill-lime-900\/60 {
  fill: #36531499;
}

.fill-lime-900\/70 {
  fill: #365314b3;
}

.fill-lime-900\/75 {
  fill: #365314bf;
}

.fill-lime-900\/80 {
  fill: #365314cc;
}

.fill-lime-900\/90 {
  fill: #365314e6;
}

.fill-lime-900\/95 {
  fill: #365314f2;
}

.fill-lime-950 {
  fill: #1a2e05;
}

.fill-lime-950\/0 {
  fill: #1a2e0500;
}

.fill-lime-950\/10 {
  fill: #1a2e051a;
}

.fill-lime-950\/100 {
  fill: #1a2e05;
}

.fill-lime-950\/20 {
  fill: #1a2e0533;
}

.fill-lime-950\/25 {
  fill: #1a2e0540;
}

.fill-lime-950\/30 {
  fill: #1a2e054d;
}

.fill-lime-950\/40 {
  fill: #1a2e0566;
}

.fill-lime-950\/5 {
  fill: #1a2e050d;
}

.fill-lime-950\/50 {
  fill: #1a2e0580;
}

.fill-lime-950\/60 {
  fill: #1a2e0599;
}

.fill-lime-950\/70 {
  fill: #1a2e05b3;
}

.fill-lime-950\/75 {
  fill: #1a2e05bf;
}

.fill-lime-950\/80 {
  fill: #1a2e05cc;
}

.fill-lime-950\/90 {
  fill: #1a2e05e6;
}

.fill-lime-950\/95 {
  fill: #1a2e05f2;
}

.fill-pink-100 {
  fill: #fce7f3;
}

.fill-pink-100\/0 {
  fill: #fce7f300;
}

.fill-pink-100\/10 {
  fill: #fce7f31a;
}

.fill-pink-100\/100 {
  fill: #fce7f3;
}

.fill-pink-100\/20 {
  fill: #fce7f333;
}

.fill-pink-100\/25 {
  fill: #fce7f340;
}

.fill-pink-100\/30 {
  fill: #fce7f34d;
}

.fill-pink-100\/40 {
  fill: #fce7f366;
}

.fill-pink-100\/5 {
  fill: #fce7f30d;
}

.fill-pink-100\/50 {
  fill: #fce7f380;
}

.fill-pink-100\/60 {
  fill: #fce7f399;
}

.fill-pink-100\/70 {
  fill: #fce7f3b3;
}

.fill-pink-100\/75 {
  fill: #fce7f3bf;
}

.fill-pink-100\/80 {
  fill: #fce7f3cc;
}

.fill-pink-100\/90 {
  fill: #fce7f3e6;
}

.fill-pink-100\/95 {
  fill: #fce7f3f2;
}

.fill-pink-200 {
  fill: #fbcfe8;
}

.fill-pink-200\/0 {
  fill: #fbcfe800;
}

.fill-pink-200\/10 {
  fill: #fbcfe81a;
}

.fill-pink-200\/100 {
  fill: #fbcfe8;
}

.fill-pink-200\/20 {
  fill: #fbcfe833;
}

.fill-pink-200\/25 {
  fill: #fbcfe840;
}

.fill-pink-200\/30 {
  fill: #fbcfe84d;
}

.fill-pink-200\/40 {
  fill: #fbcfe866;
}

.fill-pink-200\/5 {
  fill: #fbcfe80d;
}

.fill-pink-200\/50 {
  fill: #fbcfe880;
}

.fill-pink-200\/60 {
  fill: #fbcfe899;
}

.fill-pink-200\/70 {
  fill: #fbcfe8b3;
}

.fill-pink-200\/75 {
  fill: #fbcfe8bf;
}

.fill-pink-200\/80 {
  fill: #fbcfe8cc;
}

.fill-pink-200\/90 {
  fill: #fbcfe8e6;
}

.fill-pink-200\/95 {
  fill: #fbcfe8f2;
}

.fill-pink-300 {
  fill: #f9a8d4;
}

.fill-pink-300\/0 {
  fill: #f9a8d400;
}

.fill-pink-300\/10 {
  fill: #f9a8d41a;
}

.fill-pink-300\/100 {
  fill: #f9a8d4;
}

.fill-pink-300\/20 {
  fill: #f9a8d433;
}

.fill-pink-300\/25 {
  fill: #f9a8d440;
}

.fill-pink-300\/30 {
  fill: #f9a8d44d;
}

.fill-pink-300\/40 {
  fill: #f9a8d466;
}

.fill-pink-300\/5 {
  fill: #f9a8d40d;
}

.fill-pink-300\/50 {
  fill: #f9a8d480;
}

.fill-pink-300\/60 {
  fill: #f9a8d499;
}

.fill-pink-300\/70 {
  fill: #f9a8d4b3;
}

.fill-pink-300\/75 {
  fill: #f9a8d4bf;
}

.fill-pink-300\/80 {
  fill: #f9a8d4cc;
}

.fill-pink-300\/90 {
  fill: #f9a8d4e6;
}

.fill-pink-300\/95 {
  fill: #f9a8d4f2;
}

.fill-pink-400 {
  fill: #f472b6;
}

.fill-pink-400\/0 {
  fill: #f472b600;
}

.fill-pink-400\/10 {
  fill: #f472b61a;
}

.fill-pink-400\/100 {
  fill: #f472b6;
}

.fill-pink-400\/20 {
  fill: #f472b633;
}

.fill-pink-400\/25 {
  fill: #f472b640;
}

.fill-pink-400\/30 {
  fill: #f472b64d;
}

.fill-pink-400\/40 {
  fill: #f472b666;
}

.fill-pink-400\/5 {
  fill: #f472b60d;
}

.fill-pink-400\/50 {
  fill: #f472b680;
}

.fill-pink-400\/60 {
  fill: #f472b699;
}

.fill-pink-400\/70 {
  fill: #f472b6b3;
}

.fill-pink-400\/75 {
  fill: #f472b6bf;
}

.fill-pink-400\/80 {
  fill: #f472b6cc;
}

.fill-pink-400\/90 {
  fill: #f472b6e6;
}

.fill-pink-400\/95 {
  fill: #f472b6f2;
}

.fill-pink-50 {
  fill: #fdf2f8;
}

.fill-pink-50\/0 {
  fill: #fdf2f800;
}

.fill-pink-50\/10 {
  fill: #fdf2f81a;
}

.fill-pink-50\/100 {
  fill: #fdf2f8;
}

.fill-pink-50\/20 {
  fill: #fdf2f833;
}

.fill-pink-50\/25 {
  fill: #fdf2f840;
}

.fill-pink-50\/30 {
  fill: #fdf2f84d;
}

.fill-pink-50\/40 {
  fill: #fdf2f866;
}

.fill-pink-50\/5 {
  fill: #fdf2f80d;
}

.fill-pink-50\/50 {
  fill: #fdf2f880;
}

.fill-pink-50\/60 {
  fill: #fdf2f899;
}

.fill-pink-50\/70 {
  fill: #fdf2f8b3;
}

.fill-pink-50\/75 {
  fill: #fdf2f8bf;
}

.fill-pink-50\/80 {
  fill: #fdf2f8cc;
}

.fill-pink-50\/90 {
  fill: #fdf2f8e6;
}

.fill-pink-50\/95 {
  fill: #fdf2f8f2;
}

.fill-pink-500 {
  fill: #ec4899;
}

.fill-pink-500\/0 {
  fill: #ec489900;
}

.fill-pink-500\/10 {
  fill: #ec48991a;
}

.fill-pink-500\/100 {
  fill: #ec4899;
}

.fill-pink-500\/20 {
  fill: #ec489933;
}

.fill-pink-500\/25 {
  fill: #ec489940;
}

.fill-pink-500\/30 {
  fill: #ec48994d;
}

.fill-pink-500\/40 {
  fill: #ec489966;
}

.fill-pink-500\/5 {
  fill: #ec48990d;
}

.fill-pink-500\/50 {
  fill: #ec489980;
}

.fill-pink-500\/60 {
  fill: #ec489999;
}

.fill-pink-500\/70 {
  fill: #ec4899b3;
}

.fill-pink-500\/75 {
  fill: #ec4899bf;
}

.fill-pink-500\/80 {
  fill: #ec4899cc;
}

.fill-pink-500\/90 {
  fill: #ec4899e6;
}

.fill-pink-500\/95 {
  fill: #ec4899f2;
}

.fill-pink-600 {
  fill: #db2777;
}

.fill-pink-600\/0 {
  fill: #db277700;
}

.fill-pink-600\/10 {
  fill: #db27771a;
}

.fill-pink-600\/100 {
  fill: #db2777;
}

.fill-pink-600\/20 {
  fill: #db277733;
}

.fill-pink-600\/25 {
  fill: #db277740;
}

.fill-pink-600\/30 {
  fill: #db27774d;
}

.fill-pink-600\/40 {
  fill: #db277766;
}

.fill-pink-600\/5 {
  fill: #db27770d;
}

.fill-pink-600\/50 {
  fill: #db277780;
}

.fill-pink-600\/60 {
  fill: #db277799;
}

.fill-pink-600\/70 {
  fill: #db2777b3;
}

.fill-pink-600\/75 {
  fill: #db2777bf;
}

.fill-pink-600\/80 {
  fill: #db2777cc;
}

.fill-pink-600\/90 {
  fill: #db2777e6;
}

.fill-pink-600\/95 {
  fill: #db2777f2;
}

.fill-pink-700 {
  fill: #be185d;
}

.fill-pink-700\/0 {
  fill: #be185d00;
}

.fill-pink-700\/10 {
  fill: #be185d1a;
}

.fill-pink-700\/100 {
  fill: #be185d;
}

.fill-pink-700\/20 {
  fill: #be185d33;
}

.fill-pink-700\/25 {
  fill: #be185d40;
}

.fill-pink-700\/30 {
  fill: #be185d4d;
}

.fill-pink-700\/40 {
  fill: #be185d66;
}

.fill-pink-700\/5 {
  fill: #be185d0d;
}

.fill-pink-700\/50 {
  fill: #be185d80;
}

.fill-pink-700\/60 {
  fill: #be185d99;
}

.fill-pink-700\/70 {
  fill: #be185db3;
}

.fill-pink-700\/75 {
  fill: #be185dbf;
}

.fill-pink-700\/80 {
  fill: #be185dcc;
}

.fill-pink-700\/90 {
  fill: #be185de6;
}

.fill-pink-700\/95 {
  fill: #be185df2;
}

.fill-pink-800 {
  fill: #9d174d;
}

.fill-pink-800\/0 {
  fill: #9d174d00;
}

.fill-pink-800\/10 {
  fill: #9d174d1a;
}

.fill-pink-800\/100 {
  fill: #9d174d;
}

.fill-pink-800\/20 {
  fill: #9d174d33;
}

.fill-pink-800\/25 {
  fill: #9d174d40;
}

.fill-pink-800\/30 {
  fill: #9d174d4d;
}

.fill-pink-800\/40 {
  fill: #9d174d66;
}

.fill-pink-800\/5 {
  fill: #9d174d0d;
}

.fill-pink-800\/50 {
  fill: #9d174d80;
}

.fill-pink-800\/60 {
  fill: #9d174d99;
}

.fill-pink-800\/70 {
  fill: #9d174db3;
}

.fill-pink-800\/75 {
  fill: #9d174dbf;
}

.fill-pink-800\/80 {
  fill: #9d174dcc;
}

.fill-pink-800\/90 {
  fill: #9d174de6;
}

.fill-pink-800\/95 {
  fill: #9d174df2;
}

.fill-pink-900 {
  fill: #831843;
}

.fill-pink-900\/0 {
  fill: #83184300;
}

.fill-pink-900\/10 {
  fill: #8318431a;
}

.fill-pink-900\/100 {
  fill: #831843;
}

.fill-pink-900\/20 {
  fill: #83184333;
}

.fill-pink-900\/25 {
  fill: #83184340;
}

.fill-pink-900\/30 {
  fill: #8318434d;
}

.fill-pink-900\/40 {
  fill: #83184366;
}

.fill-pink-900\/5 {
  fill: #8318430d;
}

.fill-pink-900\/50 {
  fill: #83184380;
}

.fill-pink-900\/60 {
  fill: #83184399;
}

.fill-pink-900\/70 {
  fill: #831843b3;
}

.fill-pink-900\/75 {
  fill: #831843bf;
}

.fill-pink-900\/80 {
  fill: #831843cc;
}

.fill-pink-900\/90 {
  fill: #831843e6;
}

.fill-pink-900\/95 {
  fill: #831843f2;
}

.fill-pink-950 {
  fill: #500724;
}

.fill-pink-950\/0 {
  fill: #50072400;
}

.fill-pink-950\/10 {
  fill: #5007241a;
}

.fill-pink-950\/100 {
  fill: #500724;
}

.fill-pink-950\/20 {
  fill: #50072433;
}

.fill-pink-950\/25 {
  fill: #50072440;
}

.fill-pink-950\/30 {
  fill: #5007244d;
}

.fill-pink-950\/40 {
  fill: #50072466;
}

.fill-pink-950\/5 {
  fill: #5007240d;
}

.fill-pink-950\/50 {
  fill: #50072480;
}

.fill-pink-950\/60 {
  fill: #50072499;
}

.fill-pink-950\/70 {
  fill: #500724b3;
}

.fill-pink-950\/75 {
  fill: #500724bf;
}

.fill-pink-950\/80 {
  fill: #500724cc;
}

.fill-pink-950\/90 {
  fill: #500724e6;
}

.fill-pink-950\/95 {
  fill: #500724f2;
}

.fill-primary {
  fill: hsl(var(--primary) / 1);
}

.fill-primary\/0 {
  fill: hsl(var(--primary) / 0);
}

.fill-primary\/10 {
  fill: hsl(var(--primary) / .1);
}

.fill-primary\/100 {
  fill: hsl(var(--primary) / 1);
}

.fill-primary\/20 {
  fill: hsl(var(--primary) / .2);
}

.fill-primary\/25 {
  fill: hsl(var(--primary) / .25);
}

.fill-primary\/30 {
  fill: hsl(var(--primary) / .3);
}

.fill-primary\/40 {
  fill: hsl(var(--primary) / .4);
}

.fill-primary\/5 {
  fill: hsl(var(--primary) / .05);
}

.fill-primary\/50 {
  fill: hsl(var(--primary) / .5);
}

.fill-primary\/60 {
  fill: hsl(var(--primary) / .6);
}

.fill-primary\/70 {
  fill: hsl(var(--primary) / .7);
}

.fill-primary\/75 {
  fill: hsl(var(--primary) / .75);
}

.fill-primary\/80 {
  fill: hsl(var(--primary) / .8);
}

.fill-primary\/90 {
  fill: hsl(var(--primary) / .9);
}

.fill-primary\/95 {
  fill: hsl(var(--primary) / .95);
}

.fill-purple-100 {
  fill: #f3e8ff;
}

.fill-purple-100\/0 {
  fill: #f3e8ff00;
}

.fill-purple-100\/10 {
  fill: #f3e8ff1a;
}

.fill-purple-100\/100 {
  fill: #f3e8ff;
}

.fill-purple-100\/20 {
  fill: #f3e8ff33;
}

.fill-purple-100\/25 {
  fill: #f3e8ff40;
}

.fill-purple-100\/30 {
  fill: #f3e8ff4d;
}

.fill-purple-100\/40 {
  fill: #f3e8ff66;
}

.fill-purple-100\/5 {
  fill: #f3e8ff0d;
}

.fill-purple-100\/50 {
  fill: #f3e8ff80;
}

.fill-purple-100\/60 {
  fill: #f3e8ff99;
}

.fill-purple-100\/70 {
  fill: #f3e8ffb3;
}

.fill-purple-100\/75 {
  fill: #f3e8ffbf;
}

.fill-purple-100\/80 {
  fill: #f3e8ffcc;
}

.fill-purple-100\/90 {
  fill: #f3e8ffe6;
}

.fill-purple-100\/95 {
  fill: #f3e8fff2;
}

.fill-purple-200 {
  fill: #e9d5ff;
}

.fill-purple-200\/0 {
  fill: #e9d5ff00;
}

.fill-purple-200\/10 {
  fill: #e9d5ff1a;
}

.fill-purple-200\/100 {
  fill: #e9d5ff;
}

.fill-purple-200\/20 {
  fill: #e9d5ff33;
}

.fill-purple-200\/25 {
  fill: #e9d5ff40;
}

.fill-purple-200\/30 {
  fill: #e9d5ff4d;
}

.fill-purple-200\/40 {
  fill: #e9d5ff66;
}

.fill-purple-200\/5 {
  fill: #e9d5ff0d;
}

.fill-purple-200\/50 {
  fill: #e9d5ff80;
}

.fill-purple-200\/60 {
  fill: #e9d5ff99;
}

.fill-purple-200\/70 {
  fill: #e9d5ffb3;
}

.fill-purple-200\/75 {
  fill: #e9d5ffbf;
}

.fill-purple-200\/80 {
  fill: #e9d5ffcc;
}

.fill-purple-200\/90 {
  fill: #e9d5ffe6;
}

.fill-purple-200\/95 {
  fill: #e9d5fff2;
}

.fill-purple-300 {
  fill: #d8b4fe;
}

.fill-purple-300\/0 {
  fill: #d8b4fe00;
}

.fill-purple-300\/10 {
  fill: #d8b4fe1a;
}

.fill-purple-300\/100 {
  fill: #d8b4fe;
}

.fill-purple-300\/20 {
  fill: #d8b4fe33;
}

.fill-purple-300\/25 {
  fill: #d8b4fe40;
}

.fill-purple-300\/30 {
  fill: #d8b4fe4d;
}

.fill-purple-300\/40 {
  fill: #d8b4fe66;
}

.fill-purple-300\/5 {
  fill: #d8b4fe0d;
}

.fill-purple-300\/50 {
  fill: #d8b4fe80;
}

.fill-purple-300\/60 {
  fill: #d8b4fe99;
}

.fill-purple-300\/70 {
  fill: #d8b4feb3;
}

.fill-purple-300\/75 {
  fill: #d8b4febf;
}

.fill-purple-300\/80 {
  fill: #d8b4fecc;
}

.fill-purple-300\/90 {
  fill: #d8b4fee6;
}

.fill-purple-300\/95 {
  fill: #d8b4fef2;
}

.fill-purple-400 {
  fill: #c084fc;
}

.fill-purple-400\/0 {
  fill: #c084fc00;
}

.fill-purple-400\/10 {
  fill: #c084fc1a;
}

.fill-purple-400\/100 {
  fill: #c084fc;
}

.fill-purple-400\/20 {
  fill: #c084fc33;
}

.fill-purple-400\/25 {
  fill: #c084fc40;
}

.fill-purple-400\/30 {
  fill: #c084fc4d;
}

.fill-purple-400\/40 {
  fill: #c084fc66;
}

.fill-purple-400\/5 {
  fill: #c084fc0d;
}

.fill-purple-400\/50 {
  fill: #c084fc80;
}

.fill-purple-400\/60 {
  fill: #c084fc99;
}

.fill-purple-400\/70 {
  fill: #c084fcb3;
}

.fill-purple-400\/75 {
  fill: #c084fcbf;
}

.fill-purple-400\/80 {
  fill: #c084fccc;
}

.fill-purple-400\/90 {
  fill: #c084fce6;
}

.fill-purple-400\/95 {
  fill: #c084fcf2;
}

.fill-purple-50 {
  fill: #faf5ff;
}

.fill-purple-50\/0 {
  fill: #faf5ff00;
}

.fill-purple-50\/10 {
  fill: #faf5ff1a;
}

.fill-purple-50\/100 {
  fill: #faf5ff;
}

.fill-purple-50\/20 {
  fill: #faf5ff33;
}

.fill-purple-50\/25 {
  fill: #faf5ff40;
}

.fill-purple-50\/30 {
  fill: #faf5ff4d;
}

.fill-purple-50\/40 {
  fill: #faf5ff66;
}

.fill-purple-50\/5 {
  fill: #faf5ff0d;
}

.fill-purple-50\/50 {
  fill: #faf5ff80;
}

.fill-purple-50\/60 {
  fill: #faf5ff99;
}

.fill-purple-50\/70 {
  fill: #faf5ffb3;
}

.fill-purple-50\/75 {
  fill: #faf5ffbf;
}

.fill-purple-50\/80 {
  fill: #faf5ffcc;
}

.fill-purple-50\/90 {
  fill: #faf5ffe6;
}

.fill-purple-50\/95 {
  fill: #faf5fff2;
}

.fill-purple-500 {
  fill: #a855f7;
}

.fill-purple-500\/0 {
  fill: #a855f700;
}

.fill-purple-500\/10 {
  fill: #a855f71a;
}

.fill-purple-500\/100 {
  fill: #a855f7;
}

.fill-purple-500\/20 {
  fill: #a855f733;
}

.fill-purple-500\/25 {
  fill: #a855f740;
}

.fill-purple-500\/30 {
  fill: #a855f74d;
}

.fill-purple-500\/40 {
  fill: #a855f766;
}

.fill-purple-500\/5 {
  fill: #a855f70d;
}

.fill-purple-500\/50 {
  fill: #a855f780;
}

.fill-purple-500\/60 {
  fill: #a855f799;
}

.fill-purple-500\/70 {
  fill: #a855f7b3;
}

.fill-purple-500\/75 {
  fill: #a855f7bf;
}

.fill-purple-500\/80 {
  fill: #a855f7cc;
}

.fill-purple-500\/90 {
  fill: #a855f7e6;
}

.fill-purple-500\/95 {
  fill: #a855f7f2;
}

.fill-purple-600 {
  fill: #9333ea;
}

.fill-purple-600\/0 {
  fill: #9333ea00;
}

.fill-purple-600\/10 {
  fill: #9333ea1a;
}

.fill-purple-600\/100 {
  fill: #9333ea;
}

.fill-purple-600\/20 {
  fill: #9333ea33;
}

.fill-purple-600\/25 {
  fill: #9333ea40;
}

.fill-purple-600\/30 {
  fill: #9333ea4d;
}

.fill-purple-600\/40 {
  fill: #9333ea66;
}

.fill-purple-600\/5 {
  fill: #9333ea0d;
}

.fill-purple-600\/50 {
  fill: #9333ea80;
}

.fill-purple-600\/60 {
  fill: #9333ea99;
}

.fill-purple-600\/70 {
  fill: #9333eab3;
}

.fill-purple-600\/75 {
  fill: #9333eabf;
}

.fill-purple-600\/80 {
  fill: #9333eacc;
}

.fill-purple-600\/90 {
  fill: #9333eae6;
}

.fill-purple-600\/95 {
  fill: #9333eaf2;
}

.fill-purple-700 {
  fill: #7e22ce;
}

.fill-purple-700\/0 {
  fill: #7e22ce00;
}

.fill-purple-700\/10 {
  fill: #7e22ce1a;
}

.fill-purple-700\/100 {
  fill: #7e22ce;
}

.fill-purple-700\/20 {
  fill: #7e22ce33;
}

.fill-purple-700\/25 {
  fill: #7e22ce40;
}

.fill-purple-700\/30 {
  fill: #7e22ce4d;
}

.fill-purple-700\/40 {
  fill: #7e22ce66;
}

.fill-purple-700\/5 {
  fill: #7e22ce0d;
}

.fill-purple-700\/50 {
  fill: #7e22ce80;
}

.fill-purple-700\/60 {
  fill: #7e22ce99;
}

.fill-purple-700\/70 {
  fill: #7e22ceb3;
}

.fill-purple-700\/75 {
  fill: #7e22cebf;
}

.fill-purple-700\/80 {
  fill: #7e22cecc;
}

.fill-purple-700\/90 {
  fill: #7e22cee6;
}

.fill-purple-700\/95 {
  fill: #7e22cef2;
}

.fill-purple-800 {
  fill: #6b21a8;
}

.fill-purple-800\/0 {
  fill: #6b21a800;
}

.fill-purple-800\/10 {
  fill: #6b21a81a;
}

.fill-purple-800\/100 {
  fill: #6b21a8;
}

.fill-purple-800\/20 {
  fill: #6b21a833;
}

.fill-purple-800\/25 {
  fill: #6b21a840;
}

.fill-purple-800\/30 {
  fill: #6b21a84d;
}

.fill-purple-800\/40 {
  fill: #6b21a866;
}

.fill-purple-800\/5 {
  fill: #6b21a80d;
}

.fill-purple-800\/50 {
  fill: #6b21a880;
}

.fill-purple-800\/60 {
  fill: #6b21a899;
}

.fill-purple-800\/70 {
  fill: #6b21a8b3;
}

.fill-purple-800\/75 {
  fill: #6b21a8bf;
}

.fill-purple-800\/80 {
  fill: #6b21a8cc;
}

.fill-purple-800\/90 {
  fill: #6b21a8e6;
}

.fill-purple-800\/95 {
  fill: #6b21a8f2;
}

.fill-purple-900 {
  fill: #581c87;
}

.fill-purple-900\/0 {
  fill: #581c8700;
}

.fill-purple-900\/10 {
  fill: #581c871a;
}

.fill-purple-900\/100 {
  fill: #581c87;
}

.fill-purple-900\/20 {
  fill: #581c8733;
}

.fill-purple-900\/25 {
  fill: #581c8740;
}

.fill-purple-900\/30 {
  fill: #581c874d;
}

.fill-purple-900\/40 {
  fill: #581c8766;
}

.fill-purple-900\/5 {
  fill: #581c870d;
}

.fill-purple-900\/50 {
  fill: #581c8780;
}

.fill-purple-900\/60 {
  fill: #581c8799;
}

.fill-purple-900\/70 {
  fill: #581c87b3;
}

.fill-purple-900\/75 {
  fill: #581c87bf;
}

.fill-purple-900\/80 {
  fill: #581c87cc;
}

.fill-purple-900\/90 {
  fill: #581c87e6;
}

.fill-purple-900\/95 {
  fill: #581c87f2;
}

.fill-purple-950 {
  fill: #3b0764;
}

.fill-purple-950\/0 {
  fill: #3b076400;
}

.fill-purple-950\/10 {
  fill: #3b07641a;
}

.fill-purple-950\/100 {
  fill: #3b0764;
}

.fill-purple-950\/20 {
  fill: #3b076433;
}

.fill-purple-950\/25 {
  fill: #3b076440;
}

.fill-purple-950\/30 {
  fill: #3b07644d;
}

.fill-purple-950\/40 {
  fill: #3b076466;
}

.fill-purple-950\/5 {
  fill: #3b07640d;
}

.fill-purple-950\/50 {
  fill: #3b076480;
}

.fill-purple-950\/60 {
  fill: #3b076499;
}

.fill-purple-950\/70 {
  fill: #3b0764b3;
}

.fill-purple-950\/75 {
  fill: #3b0764bf;
}

.fill-purple-950\/80 {
  fill: #3b0764cc;
}

.fill-purple-950\/90 {
  fill: #3b0764e6;
}

.fill-purple-950\/95 {
  fill: #3b0764f2;
}

.fill-transparent, .fill-transparent\/0 {
  fill: #0000;
}

.fill-transparent\/10 {
  fill: #0000001a;
}

.fill-transparent\/100 {
  fill: #000;
}

.fill-transparent\/20 {
  fill: #0003;
}

.fill-transparent\/25 {
  fill: #00000040;
}

.fill-transparent\/30 {
  fill: #0000004d;
}

.fill-transparent\/40 {
  fill: #0006;
}

.fill-transparent\/5 {
  fill: #0000000d;
}

.fill-transparent\/50 {
  fill: #00000080;
}

.fill-transparent\/60 {
  fill: #0009;
}

.fill-transparent\/70 {
  fill: #000000b3;
}

.fill-transparent\/75 {
  fill: #000000bf;
}

.fill-transparent\/80 {
  fill: #000c;
}

.fill-transparent\/90 {
  fill: #000000e6;
}

.fill-transparent\/95 {
  fill: #000000f2;
}

.stroke-amber-100 {
  stroke: #fef3c7;
}

.stroke-amber-100\/0 {
  stroke: #fef3c700;
}

.stroke-amber-100\/10 {
  stroke: #fef3c71a;
}

.stroke-amber-100\/100 {
  stroke: #fef3c7;
}

.stroke-amber-100\/20 {
  stroke: #fef3c733;
}

.stroke-amber-100\/25 {
  stroke: #fef3c740;
}

.stroke-amber-100\/30 {
  stroke: #fef3c74d;
}

.stroke-amber-100\/40 {
  stroke: #fef3c766;
}

.stroke-amber-100\/5 {
  stroke: #fef3c70d;
}

.stroke-amber-100\/50 {
  stroke: #fef3c780;
}

.stroke-amber-100\/60 {
  stroke: #fef3c799;
}

.stroke-amber-100\/70 {
  stroke: #fef3c7b3;
}

.stroke-amber-100\/75 {
  stroke: #fef3c7bf;
}

.stroke-amber-100\/80 {
  stroke: #fef3c7cc;
}

.stroke-amber-100\/90 {
  stroke: #fef3c7e6;
}

.stroke-amber-100\/95 {
  stroke: #fef3c7f2;
}

.stroke-amber-200 {
  stroke: #fde68a;
}

.stroke-amber-200\/0 {
  stroke: #fde68a00;
}

.stroke-amber-200\/10 {
  stroke: #fde68a1a;
}

.stroke-amber-200\/100 {
  stroke: #fde68a;
}

.stroke-amber-200\/20 {
  stroke: #fde68a33;
}

.stroke-amber-200\/25 {
  stroke: #fde68a40;
}

.stroke-amber-200\/30 {
  stroke: #fde68a4d;
}

.stroke-amber-200\/40 {
  stroke: #fde68a66;
}

.stroke-amber-200\/5 {
  stroke: #fde68a0d;
}

.stroke-amber-200\/50 {
  stroke: #fde68a80;
}

.stroke-amber-200\/60 {
  stroke: #fde68a99;
}

.stroke-amber-200\/70 {
  stroke: #fde68ab3;
}

.stroke-amber-200\/75 {
  stroke: #fde68abf;
}

.stroke-amber-200\/80 {
  stroke: #fde68acc;
}

.stroke-amber-200\/90 {
  stroke: #fde68ae6;
}

.stroke-amber-200\/95 {
  stroke: #fde68af2;
}

.stroke-amber-300 {
  stroke: #fcd34d;
}

.stroke-amber-300\/0 {
  stroke: #fcd34d00;
}

.stroke-amber-300\/10 {
  stroke: #fcd34d1a;
}

.stroke-amber-300\/100 {
  stroke: #fcd34d;
}

.stroke-amber-300\/20 {
  stroke: #fcd34d33;
}

.stroke-amber-300\/25 {
  stroke: #fcd34d40;
}

.stroke-amber-300\/30 {
  stroke: #fcd34d4d;
}

.stroke-amber-300\/40 {
  stroke: #fcd34d66;
}

.stroke-amber-300\/5 {
  stroke: #fcd34d0d;
}

.stroke-amber-300\/50 {
  stroke: #fcd34d80;
}

.stroke-amber-300\/60 {
  stroke: #fcd34d99;
}

.stroke-amber-300\/70 {
  stroke: #fcd34db3;
}

.stroke-amber-300\/75 {
  stroke: #fcd34dbf;
}

.stroke-amber-300\/80 {
  stroke: #fcd34dcc;
}

.stroke-amber-300\/90 {
  stroke: #fcd34de6;
}

.stroke-amber-300\/95 {
  stroke: #fcd34df2;
}

.stroke-amber-400 {
  stroke: #fbbf24;
}

.stroke-amber-400\/0 {
  stroke: #fbbf2400;
}

.stroke-amber-400\/10 {
  stroke: #fbbf241a;
}

.stroke-amber-400\/100 {
  stroke: #fbbf24;
}

.stroke-amber-400\/20 {
  stroke: #fbbf2433;
}

.stroke-amber-400\/25 {
  stroke: #fbbf2440;
}

.stroke-amber-400\/30 {
  stroke: #fbbf244d;
}

.stroke-amber-400\/40 {
  stroke: #fbbf2466;
}

.stroke-amber-400\/5 {
  stroke: #fbbf240d;
}

.stroke-amber-400\/50 {
  stroke: #fbbf2480;
}

.stroke-amber-400\/60 {
  stroke: #fbbf2499;
}

.stroke-amber-400\/70 {
  stroke: #fbbf24b3;
}

.stroke-amber-400\/75 {
  stroke: #fbbf24bf;
}

.stroke-amber-400\/80 {
  stroke: #fbbf24cc;
}

.stroke-amber-400\/90 {
  stroke: #fbbf24e6;
}

.stroke-amber-400\/95 {
  stroke: #fbbf24f2;
}

.stroke-amber-50 {
  stroke: #fffbeb;
}

.stroke-amber-50\/0 {
  stroke: #fffbeb00;
}

.stroke-amber-50\/10 {
  stroke: #fffbeb1a;
}

.stroke-amber-50\/100 {
  stroke: #fffbeb;
}

.stroke-amber-50\/20 {
  stroke: #fffbeb33;
}

.stroke-amber-50\/25 {
  stroke: #fffbeb40;
}

.stroke-amber-50\/30 {
  stroke: #fffbeb4d;
}

.stroke-amber-50\/40 {
  stroke: #fffbeb66;
}

.stroke-amber-50\/5 {
  stroke: #fffbeb0d;
}

.stroke-amber-50\/50 {
  stroke: #fffbeb80;
}

.stroke-amber-50\/60 {
  stroke: #fffbeb99;
}

.stroke-amber-50\/70 {
  stroke: #fffbebb3;
}

.stroke-amber-50\/75 {
  stroke: #fffbebbf;
}

.stroke-amber-50\/80 {
  stroke: #fffbebcc;
}

.stroke-amber-50\/90 {
  stroke: #fffbebe6;
}

.stroke-amber-50\/95 {
  stroke: #fffbebf2;
}

.stroke-amber-500 {
  stroke: #f59e0b;
}

.stroke-amber-500\/0 {
  stroke: #f59e0b00;
}

.stroke-amber-500\/10 {
  stroke: #f59e0b1a;
}

.stroke-amber-500\/100 {
  stroke: #f59e0b;
}

.stroke-amber-500\/20 {
  stroke: #f59e0b33;
}

.stroke-amber-500\/25 {
  stroke: #f59e0b40;
}

.stroke-amber-500\/30 {
  stroke: #f59e0b4d;
}

.stroke-amber-500\/40 {
  stroke: #f59e0b66;
}

.stroke-amber-500\/5 {
  stroke: #f59e0b0d;
}

.stroke-amber-500\/50 {
  stroke: #f59e0b80;
}

.stroke-amber-500\/60 {
  stroke: #f59e0b99;
}

.stroke-amber-500\/70 {
  stroke: #f59e0bb3;
}

.stroke-amber-500\/75 {
  stroke: #f59e0bbf;
}

.stroke-amber-500\/80 {
  stroke: #f59e0bcc;
}

.stroke-amber-500\/90 {
  stroke: #f59e0be6;
}

.stroke-amber-500\/95 {
  stroke: #f59e0bf2;
}

.stroke-amber-600 {
  stroke: #d97706;
}

.stroke-amber-600\/0 {
  stroke: #d9770600;
}

.stroke-amber-600\/10 {
  stroke: #d977061a;
}

.stroke-amber-600\/100 {
  stroke: #d97706;
}

.stroke-amber-600\/20 {
  stroke: #d9770633;
}

.stroke-amber-600\/25 {
  stroke: #d9770640;
}

.stroke-amber-600\/30 {
  stroke: #d977064d;
}

.stroke-amber-600\/40 {
  stroke: #d9770666;
}

.stroke-amber-600\/5 {
  stroke: #d977060d;
}

.stroke-amber-600\/50 {
  stroke: #d9770680;
}

.stroke-amber-600\/60 {
  stroke: #d9770699;
}

.stroke-amber-600\/70 {
  stroke: #d97706b3;
}

.stroke-amber-600\/75 {
  stroke: #d97706bf;
}

.stroke-amber-600\/80 {
  stroke: #d97706cc;
}

.stroke-amber-600\/90 {
  stroke: #d97706e6;
}

.stroke-amber-600\/95 {
  stroke: #d97706f2;
}

.stroke-amber-700 {
  stroke: #b45309;
}

.stroke-amber-700\/0 {
  stroke: #b4530900;
}

.stroke-amber-700\/10 {
  stroke: #b453091a;
}

.stroke-amber-700\/100 {
  stroke: #b45309;
}

.stroke-amber-700\/20 {
  stroke: #b4530933;
}

.stroke-amber-700\/25 {
  stroke: #b4530940;
}

.stroke-amber-700\/30 {
  stroke: #b453094d;
}

.stroke-amber-700\/40 {
  stroke: #b4530966;
}

.stroke-amber-700\/5 {
  stroke: #b453090d;
}

.stroke-amber-700\/50 {
  stroke: #b4530980;
}

.stroke-amber-700\/60 {
  stroke: #b4530999;
}

.stroke-amber-700\/70 {
  stroke: #b45309b3;
}

.stroke-amber-700\/75 {
  stroke: #b45309bf;
}

.stroke-amber-700\/80 {
  stroke: #b45309cc;
}

.stroke-amber-700\/90 {
  stroke: #b45309e6;
}

.stroke-amber-700\/95 {
  stroke: #b45309f2;
}

.stroke-amber-800 {
  stroke: #92400e;
}

.stroke-amber-800\/0 {
  stroke: #92400e00;
}

.stroke-amber-800\/10 {
  stroke: #92400e1a;
}

.stroke-amber-800\/100 {
  stroke: #92400e;
}

.stroke-amber-800\/20 {
  stroke: #92400e33;
}

.stroke-amber-800\/25 {
  stroke: #92400e40;
}

.stroke-amber-800\/30 {
  stroke: #92400e4d;
}

.stroke-amber-800\/40 {
  stroke: #92400e66;
}

.stroke-amber-800\/5 {
  stroke: #92400e0d;
}

.stroke-amber-800\/50 {
  stroke: #92400e80;
}

.stroke-amber-800\/60 {
  stroke: #92400e99;
}

.stroke-amber-800\/70 {
  stroke: #92400eb3;
}

.stroke-amber-800\/75 {
  stroke: #92400ebf;
}

.stroke-amber-800\/80 {
  stroke: #92400ecc;
}

.stroke-amber-800\/90 {
  stroke: #92400ee6;
}

.stroke-amber-800\/95 {
  stroke: #92400ef2;
}

.stroke-amber-900 {
  stroke: #78350f;
}

.stroke-amber-900\/0 {
  stroke: #78350f00;
}

.stroke-amber-900\/10 {
  stroke: #78350f1a;
}

.stroke-amber-900\/100 {
  stroke: #78350f;
}

.stroke-amber-900\/20 {
  stroke: #78350f33;
}

.stroke-amber-900\/25 {
  stroke: #78350f40;
}

.stroke-amber-900\/30 {
  stroke: #78350f4d;
}

.stroke-amber-900\/40 {
  stroke: #78350f66;
}

.stroke-amber-900\/5 {
  stroke: #78350f0d;
}

.stroke-amber-900\/50 {
  stroke: #78350f80;
}

.stroke-amber-900\/60 {
  stroke: #78350f99;
}

.stroke-amber-900\/70 {
  stroke: #78350fb3;
}

.stroke-amber-900\/75 {
  stroke: #78350fbf;
}

.stroke-amber-900\/80 {
  stroke: #78350fcc;
}

.stroke-amber-900\/90 {
  stroke: #78350fe6;
}

.stroke-amber-900\/95 {
  stroke: #78350ff2;
}

.stroke-amber-950 {
  stroke: #451a03;
}

.stroke-amber-950\/0 {
  stroke: #451a0300;
}

.stroke-amber-950\/10 {
  stroke: #451a031a;
}

.stroke-amber-950\/100 {
  stroke: #451a03;
}

.stroke-amber-950\/20 {
  stroke: #451a0333;
}

.stroke-amber-950\/25 {
  stroke: #451a0340;
}

.stroke-amber-950\/30 {
  stroke: #451a034d;
}

.stroke-amber-950\/40 {
  stroke: #451a0366;
}

.stroke-amber-950\/5 {
  stroke: #451a030d;
}

.stroke-amber-950\/50 {
  stroke: #451a0380;
}

.stroke-amber-950\/60 {
  stroke: #451a0399;
}

.stroke-amber-950\/70 {
  stroke: #451a03b3;
}

.stroke-amber-950\/75 {
  stroke: #451a03bf;
}

.stroke-amber-950\/80 {
  stroke: #451a03cc;
}

.stroke-amber-950\/90 {
  stroke: #451a03e6;
}

.stroke-amber-950\/95 {
  stroke: #451a03f2;
}

.stroke-emerald-100 {
  stroke: #d1fae5;
}

.stroke-emerald-100\/0 {
  stroke: #d1fae500;
}

.stroke-emerald-100\/10 {
  stroke: #d1fae51a;
}

.stroke-emerald-100\/100 {
  stroke: #d1fae5;
}

.stroke-emerald-100\/20 {
  stroke: #d1fae533;
}

.stroke-emerald-100\/25 {
  stroke: #d1fae540;
}

.stroke-emerald-100\/30 {
  stroke: #d1fae54d;
}

.stroke-emerald-100\/40 {
  stroke: #d1fae566;
}

.stroke-emerald-100\/5 {
  stroke: #d1fae50d;
}

.stroke-emerald-100\/50 {
  stroke: #d1fae580;
}

.stroke-emerald-100\/60 {
  stroke: #d1fae599;
}

.stroke-emerald-100\/70 {
  stroke: #d1fae5b3;
}

.stroke-emerald-100\/75 {
  stroke: #d1fae5bf;
}

.stroke-emerald-100\/80 {
  stroke: #d1fae5cc;
}

.stroke-emerald-100\/90 {
  stroke: #d1fae5e6;
}

.stroke-emerald-100\/95 {
  stroke: #d1fae5f2;
}

.stroke-emerald-200 {
  stroke: #a7f3d0;
}

.stroke-emerald-200\/0 {
  stroke: #a7f3d000;
}

.stroke-emerald-200\/10 {
  stroke: #a7f3d01a;
}

.stroke-emerald-200\/100 {
  stroke: #a7f3d0;
}

.stroke-emerald-200\/20 {
  stroke: #a7f3d033;
}

.stroke-emerald-200\/25 {
  stroke: #a7f3d040;
}

.stroke-emerald-200\/30 {
  stroke: #a7f3d04d;
}

.stroke-emerald-200\/40 {
  stroke: #a7f3d066;
}

.stroke-emerald-200\/5 {
  stroke: #a7f3d00d;
}

.stroke-emerald-200\/50 {
  stroke: #a7f3d080;
}

.stroke-emerald-200\/60 {
  stroke: #a7f3d099;
}

.stroke-emerald-200\/70 {
  stroke: #a7f3d0b3;
}

.stroke-emerald-200\/75 {
  stroke: #a7f3d0bf;
}

.stroke-emerald-200\/80 {
  stroke: #a7f3d0cc;
}

.stroke-emerald-200\/90 {
  stroke: #a7f3d0e6;
}

.stroke-emerald-200\/95 {
  stroke: #a7f3d0f2;
}

.stroke-emerald-300 {
  stroke: #6ee7b7;
}

.stroke-emerald-300\/0 {
  stroke: #6ee7b700;
}

.stroke-emerald-300\/10 {
  stroke: #6ee7b71a;
}

.stroke-emerald-300\/100 {
  stroke: #6ee7b7;
}

.stroke-emerald-300\/20 {
  stroke: #6ee7b733;
}

.stroke-emerald-300\/25 {
  stroke: #6ee7b740;
}

.stroke-emerald-300\/30 {
  stroke: #6ee7b74d;
}

.stroke-emerald-300\/40 {
  stroke: #6ee7b766;
}

.stroke-emerald-300\/5 {
  stroke: #6ee7b70d;
}

.stroke-emerald-300\/50 {
  stroke: #6ee7b780;
}

.stroke-emerald-300\/60 {
  stroke: #6ee7b799;
}

.stroke-emerald-300\/70 {
  stroke: #6ee7b7b3;
}

.stroke-emerald-300\/75 {
  stroke: #6ee7b7bf;
}

.stroke-emerald-300\/80 {
  stroke: #6ee7b7cc;
}

.stroke-emerald-300\/90 {
  stroke: #6ee7b7e6;
}

.stroke-emerald-300\/95 {
  stroke: #6ee7b7f2;
}

.stroke-emerald-400 {
  stroke: #34d399;
}

.stroke-emerald-400\/0 {
  stroke: #34d39900;
}

.stroke-emerald-400\/10 {
  stroke: #34d3991a;
}

.stroke-emerald-400\/100 {
  stroke: #34d399;
}

.stroke-emerald-400\/20 {
  stroke: #34d39933;
}

.stroke-emerald-400\/25 {
  stroke: #34d39940;
}

.stroke-emerald-400\/30 {
  stroke: #34d3994d;
}

.stroke-emerald-400\/40 {
  stroke: #34d39966;
}

.stroke-emerald-400\/5 {
  stroke: #34d3990d;
}

.stroke-emerald-400\/50 {
  stroke: #34d39980;
}

.stroke-emerald-400\/60 {
  stroke: #34d39999;
}

.stroke-emerald-400\/70 {
  stroke: #34d399b3;
}

.stroke-emerald-400\/75 {
  stroke: #34d399bf;
}

.stroke-emerald-400\/80 {
  stroke: #34d399cc;
}

.stroke-emerald-400\/90 {
  stroke: #34d399e6;
}

.stroke-emerald-400\/95 {
  stroke: #34d399f2;
}

.stroke-emerald-50 {
  stroke: #ecfdf5;
}

.stroke-emerald-50\/0 {
  stroke: #ecfdf500;
}

.stroke-emerald-50\/10 {
  stroke: #ecfdf51a;
}

.stroke-emerald-50\/100 {
  stroke: #ecfdf5;
}

.stroke-emerald-50\/20 {
  stroke: #ecfdf533;
}

.stroke-emerald-50\/25 {
  stroke: #ecfdf540;
}

.stroke-emerald-50\/30 {
  stroke: #ecfdf54d;
}

.stroke-emerald-50\/40 {
  stroke: #ecfdf566;
}

.stroke-emerald-50\/5 {
  stroke: #ecfdf50d;
}

.stroke-emerald-50\/50 {
  stroke: #ecfdf580;
}

.stroke-emerald-50\/60 {
  stroke: #ecfdf599;
}

.stroke-emerald-50\/70 {
  stroke: #ecfdf5b3;
}

.stroke-emerald-50\/75 {
  stroke: #ecfdf5bf;
}

.stroke-emerald-50\/80 {
  stroke: #ecfdf5cc;
}

.stroke-emerald-50\/90 {
  stroke: #ecfdf5e6;
}

.stroke-emerald-50\/95 {
  stroke: #ecfdf5f2;
}

.stroke-emerald-500 {
  stroke: #10b981;
}

.stroke-emerald-500\/0 {
  stroke: #10b98100;
}

.stroke-emerald-500\/10 {
  stroke: #10b9811a;
}

.stroke-emerald-500\/100 {
  stroke: #10b981;
}

.stroke-emerald-500\/20 {
  stroke: #10b98133;
}

.stroke-emerald-500\/25 {
  stroke: #10b98140;
}

.stroke-emerald-500\/30 {
  stroke: #10b9814d;
}

.stroke-emerald-500\/40 {
  stroke: #10b98166;
}

.stroke-emerald-500\/5 {
  stroke: #10b9810d;
}

.stroke-emerald-500\/50 {
  stroke: #10b98180;
}

.stroke-emerald-500\/60 {
  stroke: #10b98199;
}

.stroke-emerald-500\/70 {
  stroke: #10b981b3;
}

.stroke-emerald-500\/75 {
  stroke: #10b981bf;
}

.stroke-emerald-500\/80 {
  stroke: #10b981cc;
}

.stroke-emerald-500\/90 {
  stroke: #10b981e6;
}

.stroke-emerald-500\/95 {
  stroke: #10b981f2;
}

.stroke-emerald-600 {
  stroke: #059669;
}

.stroke-emerald-600\/0 {
  stroke: #05966900;
}

.stroke-emerald-600\/10 {
  stroke: #0596691a;
}

.stroke-emerald-600\/100 {
  stroke: #059669;
}

.stroke-emerald-600\/20 {
  stroke: #05966933;
}

.stroke-emerald-600\/25 {
  stroke: #05966940;
}

.stroke-emerald-600\/30 {
  stroke: #0596694d;
}

.stroke-emerald-600\/40 {
  stroke: #05966966;
}

.stroke-emerald-600\/5 {
  stroke: #0596690d;
}

.stroke-emerald-600\/50 {
  stroke: #05966980;
}

.stroke-emerald-600\/60 {
  stroke: #05966999;
}

.stroke-emerald-600\/70 {
  stroke: #059669b3;
}

.stroke-emerald-600\/75 {
  stroke: #059669bf;
}

.stroke-emerald-600\/80 {
  stroke: #059669cc;
}

.stroke-emerald-600\/90 {
  stroke: #059669e6;
}

.stroke-emerald-600\/95 {
  stroke: #059669f2;
}

.stroke-emerald-700 {
  stroke: #047857;
}

.stroke-emerald-700\/0 {
  stroke: #04785700;
}

.stroke-emerald-700\/10 {
  stroke: #0478571a;
}

.stroke-emerald-700\/100 {
  stroke: #047857;
}

.stroke-emerald-700\/20 {
  stroke: #04785733;
}

.stroke-emerald-700\/25 {
  stroke: #04785740;
}

.stroke-emerald-700\/30 {
  stroke: #0478574d;
}

.stroke-emerald-700\/40 {
  stroke: #04785766;
}

.stroke-emerald-700\/5 {
  stroke: #0478570d;
}

.stroke-emerald-700\/50 {
  stroke: #04785780;
}

.stroke-emerald-700\/60 {
  stroke: #04785799;
}

.stroke-emerald-700\/70 {
  stroke: #047857b3;
}

.stroke-emerald-700\/75 {
  stroke: #047857bf;
}

.stroke-emerald-700\/80 {
  stroke: #047857cc;
}

.stroke-emerald-700\/90 {
  stroke: #047857e6;
}

.stroke-emerald-700\/95 {
  stroke: #047857f2;
}

.stroke-emerald-800 {
  stroke: #065f46;
}

.stroke-emerald-800\/0 {
  stroke: #065f4600;
}

.stroke-emerald-800\/10 {
  stroke: #065f461a;
}

.stroke-emerald-800\/100 {
  stroke: #065f46;
}

.stroke-emerald-800\/20 {
  stroke: #065f4633;
}

.stroke-emerald-800\/25 {
  stroke: #065f4640;
}

.stroke-emerald-800\/30 {
  stroke: #065f464d;
}

.stroke-emerald-800\/40 {
  stroke: #065f4666;
}

.stroke-emerald-800\/5 {
  stroke: #065f460d;
}

.stroke-emerald-800\/50 {
  stroke: #065f4680;
}

.stroke-emerald-800\/60 {
  stroke: #065f4699;
}

.stroke-emerald-800\/70 {
  stroke: #065f46b3;
}

.stroke-emerald-800\/75 {
  stroke: #065f46bf;
}

.stroke-emerald-800\/80 {
  stroke: #065f46cc;
}

.stroke-emerald-800\/90 {
  stroke: #065f46e6;
}

.stroke-emerald-800\/95 {
  stroke: #065f46f2;
}

.stroke-emerald-900 {
  stroke: #064e3b;
}

.stroke-emerald-900\/0 {
  stroke: #064e3b00;
}

.stroke-emerald-900\/10 {
  stroke: #064e3b1a;
}

.stroke-emerald-900\/100 {
  stroke: #064e3b;
}

.stroke-emerald-900\/20 {
  stroke: #064e3b33;
}

.stroke-emerald-900\/25 {
  stroke: #064e3b40;
}

.stroke-emerald-900\/30 {
  stroke: #064e3b4d;
}

.stroke-emerald-900\/40 {
  stroke: #064e3b66;
}

.stroke-emerald-900\/5 {
  stroke: #064e3b0d;
}

.stroke-emerald-900\/50 {
  stroke: #064e3b80;
}

.stroke-emerald-900\/60 {
  stroke: #064e3b99;
}

.stroke-emerald-900\/70 {
  stroke: #064e3bb3;
}

.stroke-emerald-900\/75 {
  stroke: #064e3bbf;
}

.stroke-emerald-900\/80 {
  stroke: #064e3bcc;
}

.stroke-emerald-900\/90 {
  stroke: #064e3be6;
}

.stroke-emerald-900\/95 {
  stroke: #064e3bf2;
}

.stroke-emerald-950 {
  stroke: #022c22;
}

.stroke-emerald-950\/0 {
  stroke: #022c2200;
}

.stroke-emerald-950\/10 {
  stroke: #022c221a;
}

.stroke-emerald-950\/100 {
  stroke: #022c22;
}

.stroke-emerald-950\/20 {
  stroke: #022c2233;
}

.stroke-emerald-950\/25 {
  stroke: #022c2240;
}

.stroke-emerald-950\/30 {
  stroke: #022c224d;
}

.stroke-emerald-950\/40 {
  stroke: #022c2266;
}

.stroke-emerald-950\/5 {
  stroke: #022c220d;
}

.stroke-emerald-950\/50 {
  stroke: #022c2280;
}

.stroke-emerald-950\/60 {
  stroke: #022c2299;
}

.stroke-emerald-950\/70 {
  stroke: #022c22b3;
}

.stroke-emerald-950\/75 {
  stroke: #022c22bf;
}

.stroke-emerald-950\/80 {
  stroke: #022c22cc;
}

.stroke-emerald-950\/90 {
  stroke: #022c22e6;
}

.stroke-emerald-950\/95 {
  stroke: #022c22f2;
}

.stroke-lime-100 {
  stroke: #ecfccb;
}

.stroke-lime-100\/0 {
  stroke: #ecfccb00;
}

.stroke-lime-100\/10 {
  stroke: #ecfccb1a;
}

.stroke-lime-100\/100 {
  stroke: #ecfccb;
}

.stroke-lime-100\/20 {
  stroke: #ecfccb33;
}

.stroke-lime-100\/25 {
  stroke: #ecfccb40;
}

.stroke-lime-100\/30 {
  stroke: #ecfccb4d;
}

.stroke-lime-100\/40 {
  stroke: #ecfccb66;
}

.stroke-lime-100\/5 {
  stroke: #ecfccb0d;
}

.stroke-lime-100\/50 {
  stroke: #ecfccb80;
}

.stroke-lime-100\/60 {
  stroke: #ecfccb99;
}

.stroke-lime-100\/70 {
  stroke: #ecfccbb3;
}

.stroke-lime-100\/75 {
  stroke: #ecfccbbf;
}

.stroke-lime-100\/80 {
  stroke: #ecfccbcc;
}

.stroke-lime-100\/90 {
  stroke: #ecfccbe6;
}

.stroke-lime-100\/95 {
  stroke: #ecfccbf2;
}

.stroke-lime-200 {
  stroke: #d9f99d;
}

.stroke-lime-200\/0 {
  stroke: #d9f99d00;
}

.stroke-lime-200\/10 {
  stroke: #d9f99d1a;
}

.stroke-lime-200\/100 {
  stroke: #d9f99d;
}

.stroke-lime-200\/20 {
  stroke: #d9f99d33;
}

.stroke-lime-200\/25 {
  stroke: #d9f99d40;
}

.stroke-lime-200\/30 {
  stroke: #d9f99d4d;
}

.stroke-lime-200\/40 {
  stroke: #d9f99d66;
}

.stroke-lime-200\/5 {
  stroke: #d9f99d0d;
}

.stroke-lime-200\/50 {
  stroke: #d9f99d80;
}

.stroke-lime-200\/60 {
  stroke: #d9f99d99;
}

.stroke-lime-200\/70 {
  stroke: #d9f99db3;
}

.stroke-lime-200\/75 {
  stroke: #d9f99dbf;
}

.stroke-lime-200\/80 {
  stroke: #d9f99dcc;
}

.stroke-lime-200\/90 {
  stroke: #d9f99de6;
}

.stroke-lime-200\/95 {
  stroke: #d9f99df2;
}

.stroke-lime-300 {
  stroke: #bef264;
}

.stroke-lime-300\/0 {
  stroke: #bef26400;
}

.stroke-lime-300\/10 {
  stroke: #bef2641a;
}

.stroke-lime-300\/100 {
  stroke: #bef264;
}

.stroke-lime-300\/20 {
  stroke: #bef26433;
}

.stroke-lime-300\/25 {
  stroke: #bef26440;
}

.stroke-lime-300\/30 {
  stroke: #bef2644d;
}

.stroke-lime-300\/40 {
  stroke: #bef26466;
}

.stroke-lime-300\/5 {
  stroke: #bef2640d;
}

.stroke-lime-300\/50 {
  stroke: #bef26480;
}

.stroke-lime-300\/60 {
  stroke: #bef26499;
}

.stroke-lime-300\/70 {
  stroke: #bef264b3;
}

.stroke-lime-300\/75 {
  stroke: #bef264bf;
}

.stroke-lime-300\/80 {
  stroke: #bef264cc;
}

.stroke-lime-300\/90 {
  stroke: #bef264e6;
}

.stroke-lime-300\/95 {
  stroke: #bef264f2;
}

.stroke-lime-400 {
  stroke: #a3e635;
}

.stroke-lime-400\/0 {
  stroke: #a3e63500;
}

.stroke-lime-400\/10 {
  stroke: #a3e6351a;
}

.stroke-lime-400\/100 {
  stroke: #a3e635;
}

.stroke-lime-400\/20 {
  stroke: #a3e63533;
}

.stroke-lime-400\/25 {
  stroke: #a3e63540;
}

.stroke-lime-400\/30 {
  stroke: #a3e6354d;
}

.stroke-lime-400\/40 {
  stroke: #a3e63566;
}

.stroke-lime-400\/5 {
  stroke: #a3e6350d;
}

.stroke-lime-400\/50 {
  stroke: #a3e63580;
}

.stroke-lime-400\/60 {
  stroke: #a3e63599;
}

.stroke-lime-400\/70 {
  stroke: #a3e635b3;
}

.stroke-lime-400\/75 {
  stroke: #a3e635bf;
}

.stroke-lime-400\/80 {
  stroke: #a3e635cc;
}

.stroke-lime-400\/90 {
  stroke: #a3e635e6;
}

.stroke-lime-400\/95 {
  stroke: #a3e635f2;
}

.stroke-lime-50 {
  stroke: #f7fee7;
}

.stroke-lime-50\/0 {
  stroke: #f7fee700;
}

.stroke-lime-50\/10 {
  stroke: #f7fee71a;
}

.stroke-lime-50\/100 {
  stroke: #f7fee7;
}

.stroke-lime-50\/20 {
  stroke: #f7fee733;
}

.stroke-lime-50\/25 {
  stroke: #f7fee740;
}

.stroke-lime-50\/30 {
  stroke: #f7fee74d;
}

.stroke-lime-50\/40 {
  stroke: #f7fee766;
}

.stroke-lime-50\/5 {
  stroke: #f7fee70d;
}

.stroke-lime-50\/50 {
  stroke: #f7fee780;
}

.stroke-lime-50\/60 {
  stroke: #f7fee799;
}

.stroke-lime-50\/70 {
  stroke: #f7fee7b3;
}

.stroke-lime-50\/75 {
  stroke: #f7fee7bf;
}

.stroke-lime-50\/80 {
  stroke: #f7fee7cc;
}

.stroke-lime-50\/90 {
  stroke: #f7fee7e6;
}

.stroke-lime-50\/95 {
  stroke: #f7fee7f2;
}

.stroke-lime-500 {
  stroke: #84cc16;
}

.stroke-lime-500\/0 {
  stroke: #84cc1600;
}

.stroke-lime-500\/10 {
  stroke: #84cc161a;
}

.stroke-lime-500\/100 {
  stroke: #84cc16;
}

.stroke-lime-500\/20 {
  stroke: #84cc1633;
}

.stroke-lime-500\/25 {
  stroke: #84cc1640;
}

.stroke-lime-500\/30 {
  stroke: #84cc164d;
}

.stroke-lime-500\/40 {
  stroke: #84cc1666;
}

.stroke-lime-500\/5 {
  stroke: #84cc160d;
}

.stroke-lime-500\/50 {
  stroke: #84cc1680;
}

.stroke-lime-500\/60 {
  stroke: #84cc1699;
}

.stroke-lime-500\/70 {
  stroke: #84cc16b3;
}

.stroke-lime-500\/75 {
  stroke: #84cc16bf;
}

.stroke-lime-500\/80 {
  stroke: #84cc16cc;
}

.stroke-lime-500\/90 {
  stroke: #84cc16e6;
}

.stroke-lime-500\/95 {
  stroke: #84cc16f2;
}

.stroke-lime-600 {
  stroke: #65a30d;
}

.stroke-lime-600\/0 {
  stroke: #65a30d00;
}

.stroke-lime-600\/10 {
  stroke: #65a30d1a;
}

.stroke-lime-600\/100 {
  stroke: #65a30d;
}

.stroke-lime-600\/20 {
  stroke: #65a30d33;
}

.stroke-lime-600\/25 {
  stroke: #65a30d40;
}

.stroke-lime-600\/30 {
  stroke: #65a30d4d;
}

.stroke-lime-600\/40 {
  stroke: #65a30d66;
}

.stroke-lime-600\/5 {
  stroke: #65a30d0d;
}

.stroke-lime-600\/50 {
  stroke: #65a30d80;
}

.stroke-lime-600\/60 {
  stroke: #65a30d99;
}

.stroke-lime-600\/70 {
  stroke: #65a30db3;
}

.stroke-lime-600\/75 {
  stroke: #65a30dbf;
}

.stroke-lime-600\/80 {
  stroke: #65a30dcc;
}

.stroke-lime-600\/90 {
  stroke: #65a30de6;
}

.stroke-lime-600\/95 {
  stroke: #65a30df2;
}

.stroke-lime-700 {
  stroke: #4d7c0f;
}

.stroke-lime-700\/0 {
  stroke: #4d7c0f00;
}

.stroke-lime-700\/10 {
  stroke: #4d7c0f1a;
}

.stroke-lime-700\/100 {
  stroke: #4d7c0f;
}

.stroke-lime-700\/20 {
  stroke: #4d7c0f33;
}

.stroke-lime-700\/25 {
  stroke: #4d7c0f40;
}

.stroke-lime-700\/30 {
  stroke: #4d7c0f4d;
}

.stroke-lime-700\/40 {
  stroke: #4d7c0f66;
}

.stroke-lime-700\/5 {
  stroke: #4d7c0f0d;
}

.stroke-lime-700\/50 {
  stroke: #4d7c0f80;
}

.stroke-lime-700\/60 {
  stroke: #4d7c0f99;
}

.stroke-lime-700\/70 {
  stroke: #4d7c0fb3;
}

.stroke-lime-700\/75 {
  stroke: #4d7c0fbf;
}

.stroke-lime-700\/80 {
  stroke: #4d7c0fcc;
}

.stroke-lime-700\/90 {
  stroke: #4d7c0fe6;
}

.stroke-lime-700\/95 {
  stroke: #4d7c0ff2;
}

.stroke-lime-800 {
  stroke: #3f6212;
}

.stroke-lime-800\/0 {
  stroke: #3f621200;
}

.stroke-lime-800\/10 {
  stroke: #3f62121a;
}

.stroke-lime-800\/100 {
  stroke: #3f6212;
}

.stroke-lime-800\/20 {
  stroke: #3f621233;
}

.stroke-lime-800\/25 {
  stroke: #3f621240;
}

.stroke-lime-800\/30 {
  stroke: #3f62124d;
}

.stroke-lime-800\/40 {
  stroke: #3f621266;
}

.stroke-lime-800\/5 {
  stroke: #3f62120d;
}

.stroke-lime-800\/50 {
  stroke: #3f621280;
}

.stroke-lime-800\/60 {
  stroke: #3f621299;
}

.stroke-lime-800\/70 {
  stroke: #3f6212b3;
}

.stroke-lime-800\/75 {
  stroke: #3f6212bf;
}

.stroke-lime-800\/80 {
  stroke: #3f6212cc;
}

.stroke-lime-800\/90 {
  stroke: #3f6212e6;
}

.stroke-lime-800\/95 {
  stroke: #3f6212f2;
}

.stroke-lime-900 {
  stroke: #365314;
}

.stroke-lime-900\/0 {
  stroke: #36531400;
}

.stroke-lime-900\/10 {
  stroke: #3653141a;
}

.stroke-lime-900\/100 {
  stroke: #365314;
}

.stroke-lime-900\/20 {
  stroke: #36531433;
}

.stroke-lime-900\/25 {
  stroke: #36531440;
}

.stroke-lime-900\/30 {
  stroke: #3653144d;
}

.stroke-lime-900\/40 {
  stroke: #36531466;
}

.stroke-lime-900\/5 {
  stroke: #3653140d;
}

.stroke-lime-900\/50 {
  stroke: #36531480;
}

.stroke-lime-900\/60 {
  stroke: #36531499;
}

.stroke-lime-900\/70 {
  stroke: #365314b3;
}

.stroke-lime-900\/75 {
  stroke: #365314bf;
}

.stroke-lime-900\/80 {
  stroke: #365314cc;
}

.stroke-lime-900\/90 {
  stroke: #365314e6;
}

.stroke-lime-900\/95 {
  stroke: #365314f2;
}

.stroke-lime-950 {
  stroke: #1a2e05;
}

.stroke-lime-950\/0 {
  stroke: #1a2e0500;
}

.stroke-lime-950\/10 {
  stroke: #1a2e051a;
}

.stroke-lime-950\/100 {
  stroke: #1a2e05;
}

.stroke-lime-950\/20 {
  stroke: #1a2e0533;
}

.stroke-lime-950\/25 {
  stroke: #1a2e0540;
}

.stroke-lime-950\/30 {
  stroke: #1a2e054d;
}

.stroke-lime-950\/40 {
  stroke: #1a2e0566;
}

.stroke-lime-950\/5 {
  stroke: #1a2e050d;
}

.stroke-lime-950\/50 {
  stroke: #1a2e0580;
}

.stroke-lime-950\/60 {
  stroke: #1a2e0599;
}

.stroke-lime-950\/70 {
  stroke: #1a2e05b3;
}

.stroke-lime-950\/75 {
  stroke: #1a2e05bf;
}

.stroke-lime-950\/80 {
  stroke: #1a2e05cc;
}

.stroke-lime-950\/90 {
  stroke: #1a2e05e6;
}

.stroke-lime-950\/95 {
  stroke: #1a2e05f2;
}

.stroke-pink-100 {
  stroke: #fce7f3;
}

.stroke-pink-100\/0 {
  stroke: #fce7f300;
}

.stroke-pink-100\/10 {
  stroke: #fce7f31a;
}

.stroke-pink-100\/100 {
  stroke: #fce7f3;
}

.stroke-pink-100\/20 {
  stroke: #fce7f333;
}

.stroke-pink-100\/25 {
  stroke: #fce7f340;
}

.stroke-pink-100\/30 {
  stroke: #fce7f34d;
}

.stroke-pink-100\/40 {
  stroke: #fce7f366;
}

.stroke-pink-100\/5 {
  stroke: #fce7f30d;
}

.stroke-pink-100\/50 {
  stroke: #fce7f380;
}

.stroke-pink-100\/60 {
  stroke: #fce7f399;
}

.stroke-pink-100\/70 {
  stroke: #fce7f3b3;
}

.stroke-pink-100\/75 {
  stroke: #fce7f3bf;
}

.stroke-pink-100\/80 {
  stroke: #fce7f3cc;
}

.stroke-pink-100\/90 {
  stroke: #fce7f3e6;
}

.stroke-pink-100\/95 {
  stroke: #fce7f3f2;
}

.stroke-pink-200 {
  stroke: #fbcfe8;
}

.stroke-pink-200\/0 {
  stroke: #fbcfe800;
}

.stroke-pink-200\/10 {
  stroke: #fbcfe81a;
}

.stroke-pink-200\/100 {
  stroke: #fbcfe8;
}

.stroke-pink-200\/20 {
  stroke: #fbcfe833;
}

.stroke-pink-200\/25 {
  stroke: #fbcfe840;
}

.stroke-pink-200\/30 {
  stroke: #fbcfe84d;
}

.stroke-pink-200\/40 {
  stroke: #fbcfe866;
}

.stroke-pink-200\/5 {
  stroke: #fbcfe80d;
}

.stroke-pink-200\/50 {
  stroke: #fbcfe880;
}

.stroke-pink-200\/60 {
  stroke: #fbcfe899;
}

.stroke-pink-200\/70 {
  stroke: #fbcfe8b3;
}

.stroke-pink-200\/75 {
  stroke: #fbcfe8bf;
}

.stroke-pink-200\/80 {
  stroke: #fbcfe8cc;
}

.stroke-pink-200\/90 {
  stroke: #fbcfe8e6;
}

.stroke-pink-200\/95 {
  stroke: #fbcfe8f2;
}

.stroke-pink-300 {
  stroke: #f9a8d4;
}

.stroke-pink-300\/0 {
  stroke: #f9a8d400;
}

.stroke-pink-300\/10 {
  stroke: #f9a8d41a;
}

.stroke-pink-300\/100 {
  stroke: #f9a8d4;
}

.stroke-pink-300\/20 {
  stroke: #f9a8d433;
}

.stroke-pink-300\/25 {
  stroke: #f9a8d440;
}

.stroke-pink-300\/30 {
  stroke: #f9a8d44d;
}

.stroke-pink-300\/40 {
  stroke: #f9a8d466;
}

.stroke-pink-300\/5 {
  stroke: #f9a8d40d;
}

.stroke-pink-300\/50 {
  stroke: #f9a8d480;
}

.stroke-pink-300\/60 {
  stroke: #f9a8d499;
}

.stroke-pink-300\/70 {
  stroke: #f9a8d4b3;
}

.stroke-pink-300\/75 {
  stroke: #f9a8d4bf;
}

.stroke-pink-300\/80 {
  stroke: #f9a8d4cc;
}

.stroke-pink-300\/90 {
  stroke: #f9a8d4e6;
}

.stroke-pink-300\/95 {
  stroke: #f9a8d4f2;
}

.stroke-pink-400 {
  stroke: #f472b6;
}

.stroke-pink-400\/0 {
  stroke: #f472b600;
}

.stroke-pink-400\/10 {
  stroke: #f472b61a;
}

.stroke-pink-400\/100 {
  stroke: #f472b6;
}

.stroke-pink-400\/20 {
  stroke: #f472b633;
}

.stroke-pink-400\/25 {
  stroke: #f472b640;
}

.stroke-pink-400\/30 {
  stroke: #f472b64d;
}

.stroke-pink-400\/40 {
  stroke: #f472b666;
}

.stroke-pink-400\/5 {
  stroke: #f472b60d;
}

.stroke-pink-400\/50 {
  stroke: #f472b680;
}

.stroke-pink-400\/60 {
  stroke: #f472b699;
}

.stroke-pink-400\/70 {
  stroke: #f472b6b3;
}

.stroke-pink-400\/75 {
  stroke: #f472b6bf;
}

.stroke-pink-400\/80 {
  stroke: #f472b6cc;
}

.stroke-pink-400\/90 {
  stroke: #f472b6e6;
}

.stroke-pink-400\/95 {
  stroke: #f472b6f2;
}

.stroke-pink-50 {
  stroke: #fdf2f8;
}

.stroke-pink-50\/0 {
  stroke: #fdf2f800;
}

.stroke-pink-50\/10 {
  stroke: #fdf2f81a;
}

.stroke-pink-50\/100 {
  stroke: #fdf2f8;
}

.stroke-pink-50\/20 {
  stroke: #fdf2f833;
}

.stroke-pink-50\/25 {
  stroke: #fdf2f840;
}

.stroke-pink-50\/30 {
  stroke: #fdf2f84d;
}

.stroke-pink-50\/40 {
  stroke: #fdf2f866;
}

.stroke-pink-50\/5 {
  stroke: #fdf2f80d;
}

.stroke-pink-50\/50 {
  stroke: #fdf2f880;
}

.stroke-pink-50\/60 {
  stroke: #fdf2f899;
}

.stroke-pink-50\/70 {
  stroke: #fdf2f8b3;
}

.stroke-pink-50\/75 {
  stroke: #fdf2f8bf;
}

.stroke-pink-50\/80 {
  stroke: #fdf2f8cc;
}

.stroke-pink-50\/90 {
  stroke: #fdf2f8e6;
}

.stroke-pink-50\/95 {
  stroke: #fdf2f8f2;
}

.stroke-pink-500 {
  stroke: #ec4899;
}

.stroke-pink-500\/0 {
  stroke: #ec489900;
}

.stroke-pink-500\/10 {
  stroke: #ec48991a;
}

.stroke-pink-500\/100 {
  stroke: #ec4899;
}

.stroke-pink-500\/20 {
  stroke: #ec489933;
}

.stroke-pink-500\/25 {
  stroke: #ec489940;
}

.stroke-pink-500\/30 {
  stroke: #ec48994d;
}

.stroke-pink-500\/40 {
  stroke: #ec489966;
}

.stroke-pink-500\/5 {
  stroke: #ec48990d;
}

.stroke-pink-500\/50 {
  stroke: #ec489980;
}

.stroke-pink-500\/60 {
  stroke: #ec489999;
}

.stroke-pink-500\/70 {
  stroke: #ec4899b3;
}

.stroke-pink-500\/75 {
  stroke: #ec4899bf;
}

.stroke-pink-500\/80 {
  stroke: #ec4899cc;
}

.stroke-pink-500\/90 {
  stroke: #ec4899e6;
}

.stroke-pink-500\/95 {
  stroke: #ec4899f2;
}

.stroke-pink-600 {
  stroke: #db2777;
}

.stroke-pink-600\/0 {
  stroke: #db277700;
}

.stroke-pink-600\/10 {
  stroke: #db27771a;
}

.stroke-pink-600\/100 {
  stroke: #db2777;
}

.stroke-pink-600\/20 {
  stroke: #db277733;
}

.stroke-pink-600\/25 {
  stroke: #db277740;
}

.stroke-pink-600\/30 {
  stroke: #db27774d;
}

.stroke-pink-600\/40 {
  stroke: #db277766;
}

.stroke-pink-600\/5 {
  stroke: #db27770d;
}

.stroke-pink-600\/50 {
  stroke: #db277780;
}

.stroke-pink-600\/60 {
  stroke: #db277799;
}

.stroke-pink-600\/70 {
  stroke: #db2777b3;
}

.stroke-pink-600\/75 {
  stroke: #db2777bf;
}

.stroke-pink-600\/80 {
  stroke: #db2777cc;
}

.stroke-pink-600\/90 {
  stroke: #db2777e6;
}

.stroke-pink-600\/95 {
  stroke: #db2777f2;
}

.stroke-pink-700 {
  stroke: #be185d;
}

.stroke-pink-700\/0 {
  stroke: #be185d00;
}

.stroke-pink-700\/10 {
  stroke: #be185d1a;
}

.stroke-pink-700\/100 {
  stroke: #be185d;
}

.stroke-pink-700\/20 {
  stroke: #be185d33;
}

.stroke-pink-700\/25 {
  stroke: #be185d40;
}

.stroke-pink-700\/30 {
  stroke: #be185d4d;
}

.stroke-pink-700\/40 {
  stroke: #be185d66;
}

.stroke-pink-700\/5 {
  stroke: #be185d0d;
}

.stroke-pink-700\/50 {
  stroke: #be185d80;
}

.stroke-pink-700\/60 {
  stroke: #be185d99;
}

.stroke-pink-700\/70 {
  stroke: #be185db3;
}

.stroke-pink-700\/75 {
  stroke: #be185dbf;
}

.stroke-pink-700\/80 {
  stroke: #be185dcc;
}

.stroke-pink-700\/90 {
  stroke: #be185de6;
}

.stroke-pink-700\/95 {
  stroke: #be185df2;
}

.stroke-pink-800 {
  stroke: #9d174d;
}

.stroke-pink-800\/0 {
  stroke: #9d174d00;
}

.stroke-pink-800\/10 {
  stroke: #9d174d1a;
}

.stroke-pink-800\/100 {
  stroke: #9d174d;
}

.stroke-pink-800\/20 {
  stroke: #9d174d33;
}

.stroke-pink-800\/25 {
  stroke: #9d174d40;
}

.stroke-pink-800\/30 {
  stroke: #9d174d4d;
}

.stroke-pink-800\/40 {
  stroke: #9d174d66;
}

.stroke-pink-800\/5 {
  stroke: #9d174d0d;
}

.stroke-pink-800\/50 {
  stroke: #9d174d80;
}

.stroke-pink-800\/60 {
  stroke: #9d174d99;
}

.stroke-pink-800\/70 {
  stroke: #9d174db3;
}

.stroke-pink-800\/75 {
  stroke: #9d174dbf;
}

.stroke-pink-800\/80 {
  stroke: #9d174dcc;
}

.stroke-pink-800\/90 {
  stroke: #9d174de6;
}

.stroke-pink-800\/95 {
  stroke: #9d174df2;
}

.stroke-pink-900 {
  stroke: #831843;
}

.stroke-pink-900\/0 {
  stroke: #83184300;
}

.stroke-pink-900\/10 {
  stroke: #8318431a;
}

.stroke-pink-900\/100 {
  stroke: #831843;
}

.stroke-pink-900\/20 {
  stroke: #83184333;
}

.stroke-pink-900\/25 {
  stroke: #83184340;
}

.stroke-pink-900\/30 {
  stroke: #8318434d;
}

.stroke-pink-900\/40 {
  stroke: #83184366;
}

.stroke-pink-900\/5 {
  stroke: #8318430d;
}

.stroke-pink-900\/50 {
  stroke: #83184380;
}

.stroke-pink-900\/60 {
  stroke: #83184399;
}

.stroke-pink-900\/70 {
  stroke: #831843b3;
}

.stroke-pink-900\/75 {
  stroke: #831843bf;
}

.stroke-pink-900\/80 {
  stroke: #831843cc;
}

.stroke-pink-900\/90 {
  stroke: #831843e6;
}

.stroke-pink-900\/95 {
  stroke: #831843f2;
}

.stroke-pink-950 {
  stroke: #500724;
}

.stroke-pink-950\/0 {
  stroke: #50072400;
}

.stroke-pink-950\/10 {
  stroke: #5007241a;
}

.stroke-pink-950\/100 {
  stroke: #500724;
}

.stroke-pink-950\/20 {
  stroke: #50072433;
}

.stroke-pink-950\/25 {
  stroke: #50072440;
}

.stroke-pink-950\/30 {
  stroke: #5007244d;
}

.stroke-pink-950\/40 {
  stroke: #50072466;
}

.stroke-pink-950\/5 {
  stroke: #5007240d;
}

.stroke-pink-950\/50 {
  stroke: #50072480;
}

.stroke-pink-950\/60 {
  stroke: #50072499;
}

.stroke-pink-950\/70 {
  stroke: #500724b3;
}

.stroke-pink-950\/75 {
  stroke: #500724bf;
}

.stroke-pink-950\/80 {
  stroke: #500724cc;
}

.stroke-pink-950\/90 {
  stroke: #500724e6;
}

.stroke-pink-950\/95 {
  stroke: #500724f2;
}

.stroke-primary {
  stroke: hsl(var(--primary) / 1);
}

.stroke-primary\/0 {
  stroke: hsl(var(--primary) / 0);
}

.stroke-primary\/10 {
  stroke: hsl(var(--primary) / .1);
}

.stroke-primary\/100 {
  stroke: hsl(var(--primary) / 1);
}

.stroke-primary\/20 {
  stroke: hsl(var(--primary) / .2);
}

.stroke-primary\/25 {
  stroke: hsl(var(--primary) / .25);
}

.stroke-primary\/30 {
  stroke: hsl(var(--primary) / .3);
}

.stroke-primary\/40 {
  stroke: hsl(var(--primary) / .4);
}

.stroke-primary\/5 {
  stroke: hsl(var(--primary) / .05);
}

.stroke-primary\/50 {
  stroke: hsl(var(--primary) / .5);
}

.stroke-primary\/60 {
  stroke: hsl(var(--primary) / .6);
}

.stroke-primary\/70 {
  stroke: hsl(var(--primary) / .7);
}

.stroke-primary\/75 {
  stroke: hsl(var(--primary) / .75);
}

.stroke-primary\/80 {
  stroke: hsl(var(--primary) / .8);
}

.stroke-primary\/90 {
  stroke: hsl(var(--primary) / .9);
}

.stroke-primary\/95 {
  stroke: hsl(var(--primary) / .95);
}

.stroke-purple-100 {
  stroke: #f3e8ff;
}

.stroke-purple-100\/0 {
  stroke: #f3e8ff00;
}

.stroke-purple-100\/10 {
  stroke: #f3e8ff1a;
}

.stroke-purple-100\/100 {
  stroke: #f3e8ff;
}

.stroke-purple-100\/20 {
  stroke: #f3e8ff33;
}

.stroke-purple-100\/25 {
  stroke: #f3e8ff40;
}

.stroke-purple-100\/30 {
  stroke: #f3e8ff4d;
}

.stroke-purple-100\/40 {
  stroke: #f3e8ff66;
}

.stroke-purple-100\/5 {
  stroke: #f3e8ff0d;
}

.stroke-purple-100\/50 {
  stroke: #f3e8ff80;
}

.stroke-purple-100\/60 {
  stroke: #f3e8ff99;
}

.stroke-purple-100\/70 {
  stroke: #f3e8ffb3;
}

.stroke-purple-100\/75 {
  stroke: #f3e8ffbf;
}

.stroke-purple-100\/80 {
  stroke: #f3e8ffcc;
}

.stroke-purple-100\/90 {
  stroke: #f3e8ffe6;
}

.stroke-purple-100\/95 {
  stroke: #f3e8fff2;
}

.stroke-purple-200 {
  stroke: #e9d5ff;
}

.stroke-purple-200\/0 {
  stroke: #e9d5ff00;
}

.stroke-purple-200\/10 {
  stroke: #e9d5ff1a;
}

.stroke-purple-200\/100 {
  stroke: #e9d5ff;
}

.stroke-purple-200\/20 {
  stroke: #e9d5ff33;
}

.stroke-purple-200\/25 {
  stroke: #e9d5ff40;
}

.stroke-purple-200\/30 {
  stroke: #e9d5ff4d;
}

.stroke-purple-200\/40 {
  stroke: #e9d5ff66;
}

.stroke-purple-200\/5 {
  stroke: #e9d5ff0d;
}

.stroke-purple-200\/50 {
  stroke: #e9d5ff80;
}

.stroke-purple-200\/60 {
  stroke: #e9d5ff99;
}

.stroke-purple-200\/70 {
  stroke: #e9d5ffb3;
}

.stroke-purple-200\/75 {
  stroke: #e9d5ffbf;
}

.stroke-purple-200\/80 {
  stroke: #e9d5ffcc;
}

.stroke-purple-200\/90 {
  stroke: #e9d5ffe6;
}

.stroke-purple-200\/95 {
  stroke: #e9d5fff2;
}

.stroke-purple-300 {
  stroke: #d8b4fe;
}

.stroke-purple-300\/0 {
  stroke: #d8b4fe00;
}

.stroke-purple-300\/10 {
  stroke: #d8b4fe1a;
}

.stroke-purple-300\/100 {
  stroke: #d8b4fe;
}

.stroke-purple-300\/20 {
  stroke: #d8b4fe33;
}

.stroke-purple-300\/25 {
  stroke: #d8b4fe40;
}

.stroke-purple-300\/30 {
  stroke: #d8b4fe4d;
}

.stroke-purple-300\/40 {
  stroke: #d8b4fe66;
}

.stroke-purple-300\/5 {
  stroke: #d8b4fe0d;
}

.stroke-purple-300\/50 {
  stroke: #d8b4fe80;
}

.stroke-purple-300\/60 {
  stroke: #d8b4fe99;
}

.stroke-purple-300\/70 {
  stroke: #d8b4feb3;
}

.stroke-purple-300\/75 {
  stroke: #d8b4febf;
}

.stroke-purple-300\/80 {
  stroke: #d8b4fecc;
}

.stroke-purple-300\/90 {
  stroke: #d8b4fee6;
}

.stroke-purple-300\/95 {
  stroke: #d8b4fef2;
}

.stroke-purple-400 {
  stroke: #c084fc;
}

.stroke-purple-400\/0 {
  stroke: #c084fc00;
}

.stroke-purple-400\/10 {
  stroke: #c084fc1a;
}

.stroke-purple-400\/100 {
  stroke: #c084fc;
}

.stroke-purple-400\/20 {
  stroke: #c084fc33;
}

.stroke-purple-400\/25 {
  stroke: #c084fc40;
}

.stroke-purple-400\/30 {
  stroke: #c084fc4d;
}

.stroke-purple-400\/40 {
  stroke: #c084fc66;
}

.stroke-purple-400\/5 {
  stroke: #c084fc0d;
}

.stroke-purple-400\/50 {
  stroke: #c084fc80;
}

.stroke-purple-400\/60 {
  stroke: #c084fc99;
}

.stroke-purple-400\/70 {
  stroke: #c084fcb3;
}

.stroke-purple-400\/75 {
  stroke: #c084fcbf;
}

.stroke-purple-400\/80 {
  stroke: #c084fccc;
}

.stroke-purple-400\/90 {
  stroke: #c084fce6;
}

.stroke-purple-400\/95 {
  stroke: #c084fcf2;
}

.stroke-purple-50 {
  stroke: #faf5ff;
}

.stroke-purple-50\/0 {
  stroke: #faf5ff00;
}

.stroke-purple-50\/10 {
  stroke: #faf5ff1a;
}

.stroke-purple-50\/100 {
  stroke: #faf5ff;
}

.stroke-purple-50\/20 {
  stroke: #faf5ff33;
}

.stroke-purple-50\/25 {
  stroke: #faf5ff40;
}

.stroke-purple-50\/30 {
  stroke: #faf5ff4d;
}

.stroke-purple-50\/40 {
  stroke: #faf5ff66;
}

.stroke-purple-50\/5 {
  stroke: #faf5ff0d;
}

.stroke-purple-50\/50 {
  stroke: #faf5ff80;
}

.stroke-purple-50\/60 {
  stroke: #faf5ff99;
}

.stroke-purple-50\/70 {
  stroke: #faf5ffb3;
}

.stroke-purple-50\/75 {
  stroke: #faf5ffbf;
}

.stroke-purple-50\/80 {
  stroke: #faf5ffcc;
}

.stroke-purple-50\/90 {
  stroke: #faf5ffe6;
}

.stroke-purple-50\/95 {
  stroke: #faf5fff2;
}

.stroke-purple-500 {
  stroke: #a855f7;
}

.stroke-purple-500\/0 {
  stroke: #a855f700;
}

.stroke-purple-500\/10 {
  stroke: #a855f71a;
}

.stroke-purple-500\/100 {
  stroke: #a855f7;
}

.stroke-purple-500\/20 {
  stroke: #a855f733;
}

.stroke-purple-500\/25 {
  stroke: #a855f740;
}

.stroke-purple-500\/30 {
  stroke: #a855f74d;
}

.stroke-purple-500\/40 {
  stroke: #a855f766;
}

.stroke-purple-500\/5 {
  stroke: #a855f70d;
}

.stroke-purple-500\/50 {
  stroke: #a855f780;
}

.stroke-purple-500\/60 {
  stroke: #a855f799;
}

.stroke-purple-500\/70 {
  stroke: #a855f7b3;
}

.stroke-purple-500\/75 {
  stroke: #a855f7bf;
}

.stroke-purple-500\/80 {
  stroke: #a855f7cc;
}

.stroke-purple-500\/90 {
  stroke: #a855f7e6;
}

.stroke-purple-500\/95 {
  stroke: #a855f7f2;
}

.stroke-purple-600 {
  stroke: #9333ea;
}

.stroke-purple-600\/0 {
  stroke: #9333ea00;
}

.stroke-purple-600\/10 {
  stroke: #9333ea1a;
}

.stroke-purple-600\/100 {
  stroke: #9333ea;
}

.stroke-purple-600\/20 {
  stroke: #9333ea33;
}

.stroke-purple-600\/25 {
  stroke: #9333ea40;
}

.stroke-purple-600\/30 {
  stroke: #9333ea4d;
}

.stroke-purple-600\/40 {
  stroke: #9333ea66;
}

.stroke-purple-600\/5 {
  stroke: #9333ea0d;
}

.stroke-purple-600\/50 {
  stroke: #9333ea80;
}

.stroke-purple-600\/60 {
  stroke: #9333ea99;
}

.stroke-purple-600\/70 {
  stroke: #9333eab3;
}

.stroke-purple-600\/75 {
  stroke: #9333eabf;
}

.stroke-purple-600\/80 {
  stroke: #9333eacc;
}

.stroke-purple-600\/90 {
  stroke: #9333eae6;
}

.stroke-purple-600\/95 {
  stroke: #9333eaf2;
}

.stroke-purple-700 {
  stroke: #7e22ce;
}

.stroke-purple-700\/0 {
  stroke: #7e22ce00;
}

.stroke-purple-700\/10 {
  stroke: #7e22ce1a;
}

.stroke-purple-700\/100 {
  stroke: #7e22ce;
}

.stroke-purple-700\/20 {
  stroke: #7e22ce33;
}

.stroke-purple-700\/25 {
  stroke: #7e22ce40;
}

.stroke-purple-700\/30 {
  stroke: #7e22ce4d;
}

.stroke-purple-700\/40 {
  stroke: #7e22ce66;
}

.stroke-purple-700\/5 {
  stroke: #7e22ce0d;
}

.stroke-purple-700\/50 {
  stroke: #7e22ce80;
}

.stroke-purple-700\/60 {
  stroke: #7e22ce99;
}

.stroke-purple-700\/70 {
  stroke: #7e22ceb3;
}

.stroke-purple-700\/75 {
  stroke: #7e22cebf;
}

.stroke-purple-700\/80 {
  stroke: #7e22cecc;
}

.stroke-purple-700\/90 {
  stroke: #7e22cee6;
}

.stroke-purple-700\/95 {
  stroke: #7e22cef2;
}

.stroke-purple-800 {
  stroke: #6b21a8;
}

.stroke-purple-800\/0 {
  stroke: #6b21a800;
}

.stroke-purple-800\/10 {
  stroke: #6b21a81a;
}

.stroke-purple-800\/100 {
  stroke: #6b21a8;
}

.stroke-purple-800\/20 {
  stroke: #6b21a833;
}

.stroke-purple-800\/25 {
  stroke: #6b21a840;
}

.stroke-purple-800\/30 {
  stroke: #6b21a84d;
}

.stroke-purple-800\/40 {
  stroke: #6b21a866;
}

.stroke-purple-800\/5 {
  stroke: #6b21a80d;
}

.stroke-purple-800\/50 {
  stroke: #6b21a880;
}

.stroke-purple-800\/60 {
  stroke: #6b21a899;
}

.stroke-purple-800\/70 {
  stroke: #6b21a8b3;
}

.stroke-purple-800\/75 {
  stroke: #6b21a8bf;
}

.stroke-purple-800\/80 {
  stroke: #6b21a8cc;
}

.stroke-purple-800\/90 {
  stroke: #6b21a8e6;
}

.stroke-purple-800\/95 {
  stroke: #6b21a8f2;
}

.stroke-purple-900 {
  stroke: #581c87;
}

.stroke-purple-900\/0 {
  stroke: #581c8700;
}

.stroke-purple-900\/10 {
  stroke: #581c871a;
}

.stroke-purple-900\/100 {
  stroke: #581c87;
}

.stroke-purple-900\/20 {
  stroke: #581c8733;
}

.stroke-purple-900\/25 {
  stroke: #581c8740;
}

.stroke-purple-900\/30 {
  stroke: #581c874d;
}

.stroke-purple-900\/40 {
  stroke: #581c8766;
}

.stroke-purple-900\/5 {
  stroke: #581c870d;
}

.stroke-purple-900\/50 {
  stroke: #581c8780;
}

.stroke-purple-900\/60 {
  stroke: #581c8799;
}

.stroke-purple-900\/70 {
  stroke: #581c87b3;
}

.stroke-purple-900\/75 {
  stroke: #581c87bf;
}

.stroke-purple-900\/80 {
  stroke: #581c87cc;
}

.stroke-purple-900\/90 {
  stroke: #581c87e6;
}

.stroke-purple-900\/95 {
  stroke: #581c87f2;
}

.stroke-purple-950 {
  stroke: #3b0764;
}

.stroke-purple-950\/0 {
  stroke: #3b076400;
}

.stroke-purple-950\/10 {
  stroke: #3b07641a;
}

.stroke-purple-950\/100 {
  stroke: #3b0764;
}

.stroke-purple-950\/20 {
  stroke: #3b076433;
}

.stroke-purple-950\/25 {
  stroke: #3b076440;
}

.stroke-purple-950\/30 {
  stroke: #3b07644d;
}

.stroke-purple-950\/40 {
  stroke: #3b076466;
}

.stroke-purple-950\/5 {
  stroke: #3b07640d;
}

.stroke-purple-950\/50 {
  stroke: #3b076480;
}

.stroke-purple-950\/60 {
  stroke: #3b076499;
}

.stroke-purple-950\/70 {
  stroke: #3b0764b3;
}

.stroke-purple-950\/75 {
  stroke: #3b0764bf;
}

.stroke-purple-950\/80 {
  stroke: #3b0764cc;
}

.stroke-purple-950\/90 {
  stroke: #3b0764e6;
}

.stroke-purple-950\/95 {
  stroke: #3b0764f2;
}

.stroke-transparent, .stroke-transparent\/0 {
  stroke: #0000;
}

.stroke-transparent\/10 {
  stroke: #0000001a;
}

.stroke-transparent\/100 {
  stroke: #000;
}

.stroke-transparent\/20 {
  stroke: #0003;
}

.stroke-transparent\/25 {
  stroke: #00000040;
}

.stroke-transparent\/30 {
  stroke: #0000004d;
}

.stroke-transparent\/40 {
  stroke: #0006;
}

.stroke-transparent\/5 {
  stroke: #0000000d;
}

.stroke-transparent\/50 {
  stroke: #00000080;
}

.stroke-transparent\/60 {
  stroke: #0009;
}

.stroke-transparent\/70 {
  stroke: #000000b3;
}

.stroke-transparent\/75 {
  stroke: #000000bf;
}

.stroke-transparent\/80 {
  stroke: #000c;
}

.stroke-transparent\/90 {
  stroke: #000000e6;
}

.stroke-transparent\/95 {
  stroke: #000000f2;
}

.object-bottom {
  object-position: bottom;
}

.object-left-bottom {
  object-position: left bottom;
}

.object-left-top {
  object-position: left top;
}

.object-right-bottom {
  object-position: right bottom;
}

.object-right-top {
  object-position: right top;
}

.object-top {
  object-position: top;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-16 {
  padding: 4rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-28 {
  padding: 7rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-32 {
  padding: 8rem;
}

.p-36 {
  padding: 9rem;
}

.p-4 {
  padding: 1rem;
}

.p-40 {
  padding: 10rem;
}

.p-44 {
  padding: 11rem;
}

.p-48 {
  padding: 12rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-52 {
  padding: 13rem;
}

.p-56 {
  padding: 14rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-60 {
  padding: 15rem;
}

.p-64 {
  padding: 16rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-72 {
  padding: 18rem;
}

.p-8 {
  padding: 2rem;
}

.p-80 {
  padding: 20rem;
}

.p-9 {
  padding: 2.25rem;
}

.p-96 {
  padding: 24rem;
}

.p-px {
  padding: 1px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-3\.5 {
  padding-bottom: .875rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-52 {
  padding-bottom: 13rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-72 {
  padding-bottom: 18rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-80 {
  padding-bottom: 20rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pb-96 {
  padding-bottom: 24rem;
}

.pb-px {
  padding-bottom: 1px;
}

.pe-0 {
  padding-inline-end: 0;
}

.pe-0\.5 {
  padding-inline-end: .125rem;
}

.pe-1 {
  padding-inline-end: .25rem;
}

.pe-1\.5 {
  padding-inline-end: .375rem;
}

.pe-10 {
  padding-inline-end: 2.5rem;
}

.pe-11 {
  padding-inline-end: 2.75rem;
}

.pe-12 {
  padding-inline-end: 3rem;
}

.pe-14 {
  padding-inline-end: 3.5rem;
}

.pe-16 {
  padding-inline-end: 4rem;
}

.pe-2 {
  padding-inline-end: .5rem;
}

.pe-2\.5 {
  padding-inline-end: .625rem;
}

.pe-20 {
  padding-inline-end: 5rem;
}

.pe-24 {
  padding-inline-end: 6rem;
}

.pe-28 {
  padding-inline-end: 7rem;
}

.pe-3 {
  padding-inline-end: .75rem;
}

.pe-3\.5 {
  padding-inline-end: .875rem;
}

.pe-32 {
  padding-inline-end: 8rem;
}

.pe-36 {
  padding-inline-end: 9rem;
}

.pe-4 {
  padding-inline-end: 1rem;
}

.pe-40 {
  padding-inline-end: 10rem;
}

.pe-44 {
  padding-inline-end: 11rem;
}

.pe-48 {
  padding-inline-end: 12rem;
}

.pe-5 {
  padding-inline-end: 1.25rem;
}

.pe-52 {
  padding-inline-end: 13rem;
}

.pe-56 {
  padding-inline-end: 14rem;
}

.pe-6 {
  padding-inline-end: 1.5rem;
}

.pe-60 {
  padding-inline-end: 15rem;
}

.pe-64 {
  padding-inline-end: 16rem;
}

.pe-7 {
  padding-inline-end: 1.75rem;
}

.pe-72 {
  padding-inline-end: 18rem;
}

.pe-8 {
  padding-inline-end: 2rem;
}

.pe-80 {
  padding-inline-end: 20rem;
}

.pe-9 {
  padding-inline-end: 2.25rem;
}

.pe-96 {
  padding-inline-end: 24rem;
}

.pe-px {
  padding-inline-end: 1px;
}

.pl-0 {
  padding-left: 0;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pl-28 {
  padding-left: 7rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-3\.5 {
  padding-left: .875rem;
}

.pl-32 {
  padding-left: 8rem;
}

.pl-36 {
  padding-left: 9rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pl-44 {
  padding-left: 11rem;
}

.pl-48 {
  padding-left: 12rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-52 {
  padding-left: 13rem;
}

.pl-56 {
  padding-left: 14rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-60 {
  padding-left: 15rem;
}

.pl-64 {
  padding-left: 16rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-72 {
  padding-left: 18rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-80 {
  padding-left: 20rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pl-96 {
  padding-left: 24rem;
}

.pl-px {
  padding-left: 1px;
}

.pr-0 {
  padding-right: 0;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-1\.5 {
  padding-right: .375rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pr-28 {
  padding-right: 7rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pr-36 {
  padding-right: 9rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pr-44 {
  padding-right: 11rem;
}

.pr-48 {
  padding-right: 12rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-52 {
  padding-right: 13rem;
}

.pr-56 {
  padding-right: 14rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-60 {
  padding-right: 15rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-72 {
  padding-right: 18rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-80 {
  padding-right: 20rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pr-96 {
  padding-right: 24rem;
}

.pr-px {
  padding-right: 1px;
}

.ps-0 {
  padding-inline-start: 0;
}

.ps-0\.5 {
  padding-inline-start: .125rem;
}

.ps-1 {
  padding-inline-start: .25rem;
}

.ps-1\.5 {
  padding-inline-start: .375rem;
}

.ps-10 {
  padding-inline-start: 2.5rem;
}

.ps-11 {
  padding-inline-start: 2.75rem;
}

.ps-12 {
  padding-inline-start: 3rem;
}

.ps-14 {
  padding-inline-start: 3.5rem;
}

.ps-16 {
  padding-inline-start: 4rem;
}

.ps-2 {
  padding-inline-start: .5rem;
}

.ps-2\.5 {
  padding-inline-start: .625rem;
}

.ps-20 {
  padding-inline-start: 5rem;
}

.ps-24 {
  padding-inline-start: 6rem;
}

.ps-28 {
  padding-inline-start: 7rem;
}

.ps-3 {
  padding-inline-start: .75rem;
}

.ps-3\.5 {
  padding-inline-start: .875rem;
}

.ps-32 {
  padding-inline-start: 8rem;
}

.ps-36 {
  padding-inline-start: 9rem;
}

.ps-4 {
  padding-inline-start: 1rem;
}

.ps-40 {
  padding-inline-start: 10rem;
}

.ps-44 {
  padding-inline-start: 11rem;
}

.ps-48 {
  padding-inline-start: 12rem;
}

.ps-5 {
  padding-inline-start: 1.25rem;
}

.ps-52 {
  padding-inline-start: 13rem;
}

.ps-56 {
  padding-inline-start: 14rem;
}

.ps-6 {
  padding-inline-start: 1.5rem;
}

.ps-60 {
  padding-inline-start: 15rem;
}

.ps-64 {
  padding-inline-start: 16rem;
}

.ps-7 {
  padding-inline-start: 1.75rem;
}

.ps-72 {
  padding-inline-start: 18rem;
}

.ps-8 {
  padding-inline-start: 2rem;
}

.ps-80 {
  padding-inline-start: 20rem;
}

.ps-9 {
  padding-inline-start: 2.25rem;
}

.ps-96 {
  padding-inline-start: 24rem;
}

.ps-px {
  padding-inline-start: 1px;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-3\.5 {
  padding-top: .875rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-52 {
  padding-top: 13rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-60 {
  padding-top: 15rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-72 {
  padding-top: 18rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-80 {
  padding-top: 20rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pt-96 {
  padding-top: 24rem;
}

.pt-px {
  padding-top: 1px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.-indent-px {
  text-indent: -1px;
}

.indent-px {
  text-indent: 1px;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-super {
  vertical-align: super;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.normal-nums {
  font-variant-numeric: normal;
}

.lining-nums {
  --tw-numeric-figure: lining-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.proportional-nums {
  --tw-numeric-spacing: proportional-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-normal {
  line-height: 1.5;
}

.-tracking-normal, .tracking-normal {
  letter-spacing: 0;
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

.text-amber-100\/0 {
  color: #fef3c700;
}

.text-amber-100\/10 {
  color: #fef3c71a;
}

.text-amber-100\/100 {
  color: #fef3c7;
}

.text-amber-100\/20 {
  color: #fef3c733;
}

.text-amber-100\/25 {
  color: #fef3c740;
}

.text-amber-100\/30 {
  color: #fef3c74d;
}

.text-amber-100\/40 {
  color: #fef3c766;
}

.text-amber-100\/5 {
  color: #fef3c70d;
}

.text-amber-100\/50 {
  color: #fef3c780;
}

.text-amber-100\/60 {
  color: #fef3c799;
}

.text-amber-100\/70 {
  color: #fef3c7b3;
}

.text-amber-100\/75 {
  color: #fef3c7bf;
}

.text-amber-100\/80 {
  color: #fef3c7cc;
}

.text-amber-100\/90 {
  color: #fef3c7e6;
}

.text-amber-100\/95 {
  color: #fef3c7f2;
}

.text-amber-200 {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity));
}

.text-amber-200\/0 {
  color: #fde68a00;
}

.text-amber-200\/10 {
  color: #fde68a1a;
}

.text-amber-200\/100 {
  color: #fde68a;
}

.text-amber-200\/20 {
  color: #fde68a33;
}

.text-amber-200\/25 {
  color: #fde68a40;
}

.text-amber-200\/30 {
  color: #fde68a4d;
}

.text-amber-200\/40 {
  color: #fde68a66;
}

.text-amber-200\/5 {
  color: #fde68a0d;
}

.text-amber-200\/50 {
  color: #fde68a80;
}

.text-amber-200\/60 {
  color: #fde68a99;
}

.text-amber-200\/70 {
  color: #fde68ab3;
}

.text-amber-200\/75 {
  color: #fde68abf;
}

.text-amber-200\/80 {
  color: #fde68acc;
}

.text-amber-200\/90 {
  color: #fde68ae6;
}

.text-amber-200\/95 {
  color: #fde68af2;
}

.text-amber-300 {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

.text-amber-300\/0 {
  color: #fcd34d00;
}

.text-amber-300\/10 {
  color: #fcd34d1a;
}

.text-amber-300\/100 {
  color: #fcd34d;
}

.text-amber-300\/20 {
  color: #fcd34d33;
}

.text-amber-300\/25 {
  color: #fcd34d40;
}

.text-amber-300\/30 {
  color: #fcd34d4d;
}

.text-amber-300\/40 {
  color: #fcd34d66;
}

.text-amber-300\/5 {
  color: #fcd34d0d;
}

.text-amber-300\/50 {
  color: #fcd34d80;
}

.text-amber-300\/60 {
  color: #fcd34d99;
}

.text-amber-300\/70 {
  color: #fcd34db3;
}

.text-amber-300\/75 {
  color: #fcd34dbf;
}

.text-amber-300\/80 {
  color: #fcd34dcc;
}

.text-amber-300\/90 {
  color: #fcd34de6;
}

.text-amber-300\/95 {
  color: #fcd34df2;
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.text-amber-400\/0 {
  color: #fbbf2400;
}

.text-amber-400\/10 {
  color: #fbbf241a;
}

.text-amber-400\/100 {
  color: #fbbf24;
}

.text-amber-400\/20 {
  color: #fbbf2433;
}

.text-amber-400\/25 {
  color: #fbbf2440;
}

.text-amber-400\/30 {
  color: #fbbf244d;
}

.text-amber-400\/40 {
  color: #fbbf2466;
}

.text-amber-400\/5 {
  color: #fbbf240d;
}

.text-amber-400\/50 {
  color: #fbbf2480;
}

.text-amber-400\/60 {
  color: #fbbf2499;
}

.text-amber-400\/70 {
  color: #fbbf24b3;
}

.text-amber-400\/75 {
  color: #fbbf24bf;
}

.text-amber-400\/80 {
  color: #fbbf24cc;
}

.text-amber-400\/90 {
  color: #fbbf24e6;
}

.text-amber-400\/95 {
  color: #fbbf24f2;
}

.text-amber-50 {
  --tw-text-opacity: 1;
  color: rgb(255 251 235 / var(--tw-text-opacity));
}

.text-amber-50\/0 {
  color: #fffbeb00;
}

.text-amber-50\/10 {
  color: #fffbeb1a;
}

.text-amber-50\/100 {
  color: #fffbeb;
}

.text-amber-50\/20 {
  color: #fffbeb33;
}

.text-amber-50\/25 {
  color: #fffbeb40;
}

.text-amber-50\/30 {
  color: #fffbeb4d;
}

.text-amber-50\/40 {
  color: #fffbeb66;
}

.text-amber-50\/5 {
  color: #fffbeb0d;
}

.text-amber-50\/50 {
  color: #fffbeb80;
}

.text-amber-50\/60 {
  color: #fffbeb99;
}

.text-amber-50\/70 {
  color: #fffbebb3;
}

.text-amber-50\/75 {
  color: #fffbebbf;
}

.text-amber-50\/80 {
  color: #fffbebcc;
}

.text-amber-50\/90 {
  color: #fffbebe6;
}

.text-amber-50\/95 {
  color: #fffbebf2;
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-amber-500\/0 {
  color: #f59e0b00;
}

.text-amber-500\/10 {
  color: #f59e0b1a;
}

.text-amber-500\/100 {
  color: #f59e0b;
}

.text-amber-500\/20 {
  color: #f59e0b33;
}

.text-amber-500\/25 {
  color: #f59e0b40;
}

.text-amber-500\/30 {
  color: #f59e0b4d;
}

.text-amber-500\/40 {
  color: #f59e0b66;
}

.text-amber-500\/5 {
  color: #f59e0b0d;
}

.text-amber-500\/50 {
  color: #f59e0b80;
}

.text-amber-500\/60 {
  color: #f59e0b99;
}

.text-amber-500\/70 {
  color: #f59e0bb3;
}

.text-amber-500\/75 {
  color: #f59e0bbf;
}

.text-amber-500\/80 {
  color: #f59e0bcc;
}

.text-amber-500\/90 {
  color: #f59e0be6;
}

.text-amber-500\/95 {
  color: #f59e0bf2;
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.text-amber-600\/0 {
  color: #d9770600;
}

.text-amber-600\/10 {
  color: #d977061a;
}

.text-amber-600\/100 {
  color: #d97706;
}

.text-amber-600\/20 {
  color: #d9770633;
}

.text-amber-600\/25 {
  color: #d9770640;
}

.text-amber-600\/30 {
  color: #d977064d;
}

.text-amber-600\/40 {
  color: #d9770666;
}

.text-amber-600\/5 {
  color: #d977060d;
}

.text-amber-600\/50 {
  color: #d9770680;
}

.text-amber-600\/60 {
  color: #d9770699;
}

.text-amber-600\/70 {
  color: #d97706b3;
}

.text-amber-600\/75 {
  color: #d97706bf;
}

.text-amber-600\/80 {
  color: #d97706cc;
}

.text-amber-600\/90 {
  color: #d97706e6;
}

.text-amber-600\/95 {
  color: #d97706f2;
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.text-amber-700\/0 {
  color: #b4530900;
}

.text-amber-700\/10 {
  color: #b453091a;
}

.text-amber-700\/100 {
  color: #b45309;
}

.text-amber-700\/20 {
  color: #b4530933;
}

.text-amber-700\/25 {
  color: #b4530940;
}

.text-amber-700\/30 {
  color: #b453094d;
}

.text-amber-700\/40 {
  color: #b4530966;
}

.text-amber-700\/5 {
  color: #b453090d;
}

.text-amber-700\/50 {
  color: #b4530980;
}

.text-amber-700\/60 {
  color: #b4530999;
}

.text-amber-700\/70 {
  color: #b45309b3;
}

.text-amber-700\/75 {
  color: #b45309bf;
}

.text-amber-700\/80 {
  color: #b45309cc;
}

.text-amber-700\/90 {
  color: #b45309e6;
}

.text-amber-700\/95 {
  color: #b45309f2;
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity));
}

.text-amber-800\/0 {
  color: #92400e00;
}

.text-amber-800\/10 {
  color: #92400e1a;
}

.text-amber-800\/100 {
  color: #92400e;
}

.text-amber-800\/20 {
  color: #92400e33;
}

.text-amber-800\/25 {
  color: #92400e40;
}

.text-amber-800\/30 {
  color: #92400e4d;
}

.text-amber-800\/40 {
  color: #92400e66;
}

.text-amber-800\/5 {
  color: #92400e0d;
}

.text-amber-800\/50 {
  color: #92400e80;
}

.text-amber-800\/60 {
  color: #92400e99;
}

.text-amber-800\/70 {
  color: #92400eb3;
}

.text-amber-800\/75 {
  color: #92400ebf;
}

.text-amber-800\/80 {
  color: #92400ecc;
}

.text-amber-800\/90 {
  color: #92400ee6;
}

.text-amber-800\/95 {
  color: #92400ef2;
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity));
}

.text-amber-900\/0 {
  color: #78350f00;
}

.text-amber-900\/10 {
  color: #78350f1a;
}

.text-amber-900\/100 {
  color: #78350f;
}

.text-amber-900\/20 {
  color: #78350f33;
}

.text-amber-900\/25 {
  color: #78350f40;
}

.text-amber-900\/30 {
  color: #78350f4d;
}

.text-amber-900\/40 {
  color: #78350f66;
}

.text-amber-900\/5 {
  color: #78350f0d;
}

.text-amber-900\/50 {
  color: #78350f80;
}

.text-amber-900\/60 {
  color: #78350f99;
}

.text-amber-900\/70 {
  color: #78350fb3;
}

.text-amber-900\/75 {
  color: #78350fbf;
}

.text-amber-900\/80 {
  color: #78350fcc;
}

.text-amber-900\/90 {
  color: #78350fe6;
}

.text-amber-900\/95 {
  color: #78350ff2;
}

.text-amber-950 {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity));
}

.text-amber-950\/0 {
  color: #451a0300;
}

.text-amber-950\/10 {
  color: #451a031a;
}

.text-amber-950\/100 {
  color: #451a03;
}

.text-amber-950\/20 {
  color: #451a0333;
}

.text-amber-950\/25 {
  color: #451a0340;
}

.text-amber-950\/30 {
  color: #451a034d;
}

.text-amber-950\/40 {
  color: #451a0366;
}

.text-amber-950\/5 {
  color: #451a030d;
}

.text-amber-950\/50 {
  color: #451a0380;
}

.text-amber-950\/60 {
  color: #451a0399;
}

.text-amber-950\/70 {
  color: #451a03b3;
}

.text-amber-950\/75 {
  color: #451a03bf;
}

.text-amber-950\/80 {
  color: #451a03cc;
}

.text-amber-950\/90 {
  color: #451a03e6;
}

.text-amber-950\/95 {
  color: #451a03f2;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue {
  --tw-text-opacity: 1;
  color: hsl(var(--blue) / var(--tw-text-opacity));
}

.text-emerald-100 {
  --tw-text-opacity: 1;
  color: rgb(209 250 229 / var(--tw-text-opacity));
}

.text-emerald-100\/0 {
  color: #d1fae500;
}

.text-emerald-100\/10 {
  color: #d1fae51a;
}

.text-emerald-100\/100 {
  color: #d1fae5;
}

.text-emerald-100\/20 {
  color: #d1fae533;
}

.text-emerald-100\/25 {
  color: #d1fae540;
}

.text-emerald-100\/30 {
  color: #d1fae54d;
}

.text-emerald-100\/40 {
  color: #d1fae566;
}

.text-emerald-100\/5 {
  color: #d1fae50d;
}

.text-emerald-100\/50 {
  color: #d1fae580;
}

.text-emerald-100\/60 {
  color: #d1fae599;
}

.text-emerald-100\/70 {
  color: #d1fae5b3;
}

.text-emerald-100\/75 {
  color: #d1fae5bf;
}

.text-emerald-100\/80 {
  color: #d1fae5cc;
}

.text-emerald-100\/90 {
  color: #d1fae5e6;
}

.text-emerald-100\/95 {
  color: #d1fae5f2;
}

.text-emerald-200 {
  --tw-text-opacity: 1;
  color: rgb(167 243 208 / var(--tw-text-opacity));
}

.text-emerald-200\/0 {
  color: #a7f3d000;
}

.text-emerald-200\/10 {
  color: #a7f3d01a;
}

.text-emerald-200\/100 {
  color: #a7f3d0;
}

.text-emerald-200\/20 {
  color: #a7f3d033;
}

.text-emerald-200\/25 {
  color: #a7f3d040;
}

.text-emerald-200\/30 {
  color: #a7f3d04d;
}

.text-emerald-200\/40 {
  color: #a7f3d066;
}

.text-emerald-200\/5 {
  color: #a7f3d00d;
}

.text-emerald-200\/50 {
  color: #a7f3d080;
}

.text-emerald-200\/60 {
  color: #a7f3d099;
}

.text-emerald-200\/70 {
  color: #a7f3d0b3;
}

.text-emerald-200\/75 {
  color: #a7f3d0bf;
}

.text-emerald-200\/80 {
  color: #a7f3d0cc;
}

.text-emerald-200\/90 {
  color: #a7f3d0e6;
}

.text-emerald-200\/95 {
  color: #a7f3d0f2;
}

.text-emerald-300 {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

.text-emerald-300\/0 {
  color: #6ee7b700;
}

.text-emerald-300\/10 {
  color: #6ee7b71a;
}

.text-emerald-300\/100 {
  color: #6ee7b7;
}

.text-emerald-300\/20 {
  color: #6ee7b733;
}

.text-emerald-300\/25 {
  color: #6ee7b740;
}

.text-emerald-300\/30 {
  color: #6ee7b74d;
}

.text-emerald-300\/40 {
  color: #6ee7b766;
}

.text-emerald-300\/5 {
  color: #6ee7b70d;
}

.text-emerald-300\/50 {
  color: #6ee7b780;
}

.text-emerald-300\/60 {
  color: #6ee7b799;
}

.text-emerald-300\/70 {
  color: #6ee7b7b3;
}

.text-emerald-300\/75 {
  color: #6ee7b7bf;
}

.text-emerald-300\/80 {
  color: #6ee7b7cc;
}

.text-emerald-300\/90 {
  color: #6ee7b7e6;
}

.text-emerald-300\/95 {
  color: #6ee7b7f2;
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-emerald-400\/0 {
  color: #34d39900;
}

.text-emerald-400\/10 {
  color: #34d3991a;
}

.text-emerald-400\/100 {
  color: #34d399;
}

.text-emerald-400\/20 {
  color: #34d39933;
}

.text-emerald-400\/25 {
  color: #34d39940;
}

.text-emerald-400\/30 {
  color: #34d3994d;
}

.text-emerald-400\/40 {
  color: #34d39966;
}

.text-emerald-400\/5 {
  color: #34d3990d;
}

.text-emerald-400\/50 {
  color: #34d39980;
}

.text-emerald-400\/60 {
  color: #34d39999;
}

.text-emerald-400\/70 {
  color: #34d399b3;
}

.text-emerald-400\/75 {
  color: #34d399bf;
}

.text-emerald-400\/80 {
  color: #34d399cc;
}

.text-emerald-400\/90 {
  color: #34d399e6;
}

.text-emerald-400\/95 {
  color: #34d399f2;
}

.text-emerald-50 {
  --tw-text-opacity: 1;
  color: rgb(236 253 245 / var(--tw-text-opacity));
}

.text-emerald-50\/0 {
  color: #ecfdf500;
}

.text-emerald-50\/10 {
  color: #ecfdf51a;
}

.text-emerald-50\/100 {
  color: #ecfdf5;
}

.text-emerald-50\/20 {
  color: #ecfdf533;
}

.text-emerald-50\/25 {
  color: #ecfdf540;
}

.text-emerald-50\/30 {
  color: #ecfdf54d;
}

.text-emerald-50\/40 {
  color: #ecfdf566;
}

.text-emerald-50\/5 {
  color: #ecfdf50d;
}

.text-emerald-50\/50 {
  color: #ecfdf580;
}

.text-emerald-50\/60 {
  color: #ecfdf599;
}

.text-emerald-50\/70 {
  color: #ecfdf5b3;
}

.text-emerald-50\/75 {
  color: #ecfdf5bf;
}

.text-emerald-50\/80 {
  color: #ecfdf5cc;
}

.text-emerald-50\/90 {
  color: #ecfdf5e6;
}

.text-emerald-50\/95 {
  color: #ecfdf5f2;
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.text-emerald-500\/0 {
  color: #10b98100;
}

.text-emerald-500\/10 {
  color: #10b9811a;
}

.text-emerald-500\/100 {
  color: #10b981;
}

.text-emerald-500\/20 {
  color: #10b98133;
}

.text-emerald-500\/25 {
  color: #10b98140;
}

.text-emerald-500\/30 {
  color: #10b9814d;
}

.text-emerald-500\/40 {
  color: #10b98166;
}

.text-emerald-500\/5 {
  color: #10b9810d;
}

.text-emerald-500\/50 {
  color: #10b98180;
}

.text-emerald-500\/60 {
  color: #10b98199;
}

.text-emerald-500\/70 {
  color: #10b981b3;
}

.text-emerald-500\/75 {
  color: #10b981bf;
}

.text-emerald-500\/80 {
  color: #10b981cc;
}

.text-emerald-500\/90 {
  color: #10b981e6;
}

.text-emerald-500\/95 {
  color: #10b981f2;
}

.text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.text-emerald-600\/0 {
  color: #05966900;
}

.text-emerald-600\/10 {
  color: #0596691a;
}

.text-emerald-600\/100 {
  color: #059669;
}

.text-emerald-600\/20 {
  color: #05966933;
}

.text-emerald-600\/25 {
  color: #05966940;
}

.text-emerald-600\/30 {
  color: #0596694d;
}

.text-emerald-600\/40 {
  color: #05966966;
}

.text-emerald-600\/5 {
  color: #0596690d;
}

.text-emerald-600\/50 {
  color: #05966980;
}

.text-emerald-600\/60 {
  color: #05966999;
}

.text-emerald-600\/70 {
  color: #059669b3;
}

.text-emerald-600\/75 {
  color: #059669bf;
}

.text-emerald-600\/80 {
  color: #059669cc;
}

.text-emerald-600\/90 {
  color: #059669e6;
}

.text-emerald-600\/95 {
  color: #059669f2;
}

.text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.text-emerald-700\/0 {
  color: #04785700;
}

.text-emerald-700\/10 {
  color: #0478571a;
}

.text-emerald-700\/100 {
  color: #047857;
}

.text-emerald-700\/20 {
  color: #04785733;
}

.text-emerald-700\/25 {
  color: #04785740;
}

.text-emerald-700\/30 {
  color: #0478574d;
}

.text-emerald-700\/40 {
  color: #04785766;
}

.text-emerald-700\/5 {
  color: #0478570d;
}

.text-emerald-700\/50 {
  color: #04785780;
}

.text-emerald-700\/60 {
  color: #04785799;
}

.text-emerald-700\/70 {
  color: #047857b3;
}

.text-emerald-700\/75 {
  color: #047857bf;
}

.text-emerald-700\/80 {
  color: #047857cc;
}

.text-emerald-700\/90 {
  color: #047857e6;
}

.text-emerald-700\/95 {
  color: #047857f2;
}

.text-emerald-800 {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity));
}

.text-emerald-800\/0 {
  color: #065f4600;
}

.text-emerald-800\/10 {
  color: #065f461a;
}

.text-emerald-800\/100 {
  color: #065f46;
}

.text-emerald-800\/20 {
  color: #065f4633;
}

.text-emerald-800\/25 {
  color: #065f4640;
}

.text-emerald-800\/30 {
  color: #065f464d;
}

.text-emerald-800\/40 {
  color: #065f4666;
}

.text-emerald-800\/5 {
  color: #065f460d;
}

.text-emerald-800\/50 {
  color: #065f4680;
}

.text-emerald-800\/60 {
  color: #065f4699;
}

.text-emerald-800\/70 {
  color: #065f46b3;
}

.text-emerald-800\/75 {
  color: #065f46bf;
}

.text-emerald-800\/80 {
  color: #065f46cc;
}

.text-emerald-800\/90 {
  color: #065f46e6;
}

.text-emerald-800\/95 {
  color: #065f46f2;
}

.text-emerald-900 {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

.text-emerald-900\/0 {
  color: #064e3b00;
}

.text-emerald-900\/10 {
  color: #064e3b1a;
}

.text-emerald-900\/100 {
  color: #064e3b;
}

.text-emerald-900\/20 {
  color: #064e3b33;
}

.text-emerald-900\/25 {
  color: #064e3b40;
}

.text-emerald-900\/30 {
  color: #064e3b4d;
}

.text-emerald-900\/40 {
  color: #064e3b66;
}

.text-emerald-900\/5 {
  color: #064e3b0d;
}

.text-emerald-900\/50 {
  color: #064e3b80;
}

.text-emerald-900\/60 {
  color: #064e3b99;
}

.text-emerald-900\/70 {
  color: #064e3bb3;
}

.text-emerald-900\/75 {
  color: #064e3bbf;
}

.text-emerald-900\/80 {
  color: #064e3bcc;
}

.text-emerald-900\/90 {
  color: #064e3be6;
}

.text-emerald-900\/95 {
  color: #064e3bf2;
}

.text-emerald-950 {
  --tw-text-opacity: 1;
  color: rgb(2 44 34 / var(--tw-text-opacity));
}

.text-emerald-950\/0 {
  color: #022c2200;
}

.text-emerald-950\/10 {
  color: #022c221a;
}

.text-emerald-950\/100 {
  color: #022c22;
}

.text-emerald-950\/20 {
  color: #022c2233;
}

.text-emerald-950\/25 {
  color: #022c2240;
}

.text-emerald-950\/30 {
  color: #022c224d;
}

.text-emerald-950\/40 {
  color: #022c2266;
}

.text-emerald-950\/5 {
  color: #022c220d;
}

.text-emerald-950\/50 {
  color: #022c2280;
}

.text-emerald-950\/60 {
  color: #022c2299;
}

.text-emerald-950\/70 {
  color: #022c22b3;
}

.text-emerald-950\/75 {
  color: #022c22bf;
}

.text-emerald-950\/80 {
  color: #022c22cc;
}

.text-emerald-950\/90 {
  color: #022c22e6;
}

.text-emerald-950\/95 {
  color: #022c22f2;
}

.text-green {
  --tw-text-opacity: 1;
  color: hsl(var(--green) / var(--tw-text-opacity));
}

.text-lime-100 {
  --tw-text-opacity: 1;
  color: rgb(236 252 203 / var(--tw-text-opacity));
}

.text-lime-100\/0 {
  color: #ecfccb00;
}

.text-lime-100\/10 {
  color: #ecfccb1a;
}

.text-lime-100\/100 {
  color: #ecfccb;
}

.text-lime-100\/20 {
  color: #ecfccb33;
}

.text-lime-100\/25 {
  color: #ecfccb40;
}

.text-lime-100\/30 {
  color: #ecfccb4d;
}

.text-lime-100\/40 {
  color: #ecfccb66;
}

.text-lime-100\/5 {
  color: #ecfccb0d;
}

.text-lime-100\/50 {
  color: #ecfccb80;
}

.text-lime-100\/60 {
  color: #ecfccb99;
}

.text-lime-100\/70 {
  color: #ecfccbb3;
}

.text-lime-100\/75 {
  color: #ecfccbbf;
}

.text-lime-100\/80 {
  color: #ecfccbcc;
}

.text-lime-100\/90 {
  color: #ecfccbe6;
}

.text-lime-100\/95 {
  color: #ecfccbf2;
}

.text-lime-200 {
  --tw-text-opacity: 1;
  color: rgb(217 249 157 / var(--tw-text-opacity));
}

.text-lime-200\/0 {
  color: #d9f99d00;
}

.text-lime-200\/10 {
  color: #d9f99d1a;
}

.text-lime-200\/100 {
  color: #d9f99d;
}

.text-lime-200\/20 {
  color: #d9f99d33;
}

.text-lime-200\/25 {
  color: #d9f99d40;
}

.text-lime-200\/30 {
  color: #d9f99d4d;
}

.text-lime-200\/40 {
  color: #d9f99d66;
}

.text-lime-200\/5 {
  color: #d9f99d0d;
}

.text-lime-200\/50 {
  color: #d9f99d80;
}

.text-lime-200\/60 {
  color: #d9f99d99;
}

.text-lime-200\/70 {
  color: #d9f99db3;
}

.text-lime-200\/75 {
  color: #d9f99dbf;
}

.text-lime-200\/80 {
  color: #d9f99dcc;
}

.text-lime-200\/90 {
  color: #d9f99de6;
}

.text-lime-200\/95 {
  color: #d9f99df2;
}

.text-lime-300 {
  --tw-text-opacity: 1;
  color: rgb(190 242 100 / var(--tw-text-opacity));
}

.text-lime-300\/0 {
  color: #bef26400;
}

.text-lime-300\/10 {
  color: #bef2641a;
}

.text-lime-300\/100 {
  color: #bef264;
}

.text-lime-300\/20 {
  color: #bef26433;
}

.text-lime-300\/25 {
  color: #bef26440;
}

.text-lime-300\/30 {
  color: #bef2644d;
}

.text-lime-300\/40 {
  color: #bef26466;
}

.text-lime-300\/5 {
  color: #bef2640d;
}

.text-lime-300\/50 {
  color: #bef26480;
}

.text-lime-300\/60 {
  color: #bef26499;
}

.text-lime-300\/70 {
  color: #bef264b3;
}

.text-lime-300\/75 {
  color: #bef264bf;
}

.text-lime-300\/80 {
  color: #bef264cc;
}

.text-lime-300\/90 {
  color: #bef264e6;
}

.text-lime-300\/95 {
  color: #bef264f2;
}

.text-lime-400 {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.text-lime-400\/0 {
  color: #a3e63500;
}

.text-lime-400\/10 {
  color: #a3e6351a;
}

.text-lime-400\/100 {
  color: #a3e635;
}

.text-lime-400\/20 {
  color: #a3e63533;
}

.text-lime-400\/25 {
  color: #a3e63540;
}

.text-lime-400\/30 {
  color: #a3e6354d;
}

.text-lime-400\/40 {
  color: #a3e63566;
}

.text-lime-400\/5 {
  color: #a3e6350d;
}

.text-lime-400\/50 {
  color: #a3e63580;
}

.text-lime-400\/60 {
  color: #a3e63599;
}

.text-lime-400\/70 {
  color: #a3e635b3;
}

.text-lime-400\/75 {
  color: #a3e635bf;
}

.text-lime-400\/80 {
  color: #a3e635cc;
}

.text-lime-400\/90 {
  color: #a3e635e6;
}

.text-lime-400\/95 {
  color: #a3e635f2;
}

.text-lime-50 {
  --tw-text-opacity: 1;
  color: rgb(247 254 231 / var(--tw-text-opacity));
}

.text-lime-50\/0 {
  color: #f7fee700;
}

.text-lime-50\/10 {
  color: #f7fee71a;
}

.text-lime-50\/100 {
  color: #f7fee7;
}

.text-lime-50\/20 {
  color: #f7fee733;
}

.text-lime-50\/25 {
  color: #f7fee740;
}

.text-lime-50\/30 {
  color: #f7fee74d;
}

.text-lime-50\/40 {
  color: #f7fee766;
}

.text-lime-50\/5 {
  color: #f7fee70d;
}

.text-lime-50\/50 {
  color: #f7fee780;
}

.text-lime-50\/60 {
  color: #f7fee799;
}

.text-lime-50\/70 {
  color: #f7fee7b3;
}

.text-lime-50\/75 {
  color: #f7fee7bf;
}

.text-lime-50\/80 {
  color: #f7fee7cc;
}

.text-lime-50\/90 {
  color: #f7fee7e6;
}

.text-lime-50\/95 {
  color: #f7fee7f2;
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.text-lime-500\/0 {
  color: #84cc1600;
}

.text-lime-500\/10 {
  color: #84cc161a;
}

.text-lime-500\/100 {
  color: #84cc16;
}

.text-lime-500\/20 {
  color: #84cc1633;
}

.text-lime-500\/25 {
  color: #84cc1640;
}

.text-lime-500\/30 {
  color: #84cc164d;
}

.text-lime-500\/40 {
  color: #84cc1666;
}

.text-lime-500\/5 {
  color: #84cc160d;
}

.text-lime-500\/50 {
  color: #84cc1680;
}

.text-lime-500\/60 {
  color: #84cc1699;
}

.text-lime-500\/70 {
  color: #84cc16b3;
}

.text-lime-500\/75 {
  color: #84cc16bf;
}

.text-lime-500\/80 {
  color: #84cc16cc;
}

.text-lime-500\/90 {
  color: #84cc16e6;
}

.text-lime-500\/95 {
  color: #84cc16f2;
}

.text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

.text-lime-600\/0 {
  color: #65a30d00;
}

.text-lime-600\/10 {
  color: #65a30d1a;
}

.text-lime-600\/100 {
  color: #65a30d;
}

.text-lime-600\/20 {
  color: #65a30d33;
}

.text-lime-600\/25 {
  color: #65a30d40;
}

.text-lime-600\/30 {
  color: #65a30d4d;
}

.text-lime-600\/40 {
  color: #65a30d66;
}

.text-lime-600\/5 {
  color: #65a30d0d;
}

.text-lime-600\/50 {
  color: #65a30d80;
}

.text-lime-600\/60 {
  color: #65a30d99;
}

.text-lime-600\/70 {
  color: #65a30db3;
}

.text-lime-600\/75 {
  color: #65a30dbf;
}

.text-lime-600\/80 {
  color: #65a30dcc;
}

.text-lime-600\/90 {
  color: #65a30de6;
}

.text-lime-600\/95 {
  color: #65a30df2;
}

.text-lime-700 {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

.text-lime-700\/0 {
  color: #4d7c0f00;
}

.text-lime-700\/10 {
  color: #4d7c0f1a;
}

.text-lime-700\/100 {
  color: #4d7c0f;
}

.text-lime-700\/20 {
  color: #4d7c0f33;
}

.text-lime-700\/25 {
  color: #4d7c0f40;
}

.text-lime-700\/30 {
  color: #4d7c0f4d;
}

.text-lime-700\/40 {
  color: #4d7c0f66;
}

.text-lime-700\/5 {
  color: #4d7c0f0d;
}

.text-lime-700\/50 {
  color: #4d7c0f80;
}

.text-lime-700\/60 {
  color: #4d7c0f99;
}

.text-lime-700\/70 {
  color: #4d7c0fb3;
}

.text-lime-700\/75 {
  color: #4d7c0fbf;
}

.text-lime-700\/80 {
  color: #4d7c0fcc;
}

.text-lime-700\/90 {
  color: #4d7c0fe6;
}

.text-lime-700\/95 {
  color: #4d7c0ff2;
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.text-lime-800\/0 {
  color: #3f621200;
}

.text-lime-800\/10 {
  color: #3f62121a;
}

.text-lime-800\/100 {
  color: #3f6212;
}

.text-lime-800\/20 {
  color: #3f621233;
}

.text-lime-800\/25 {
  color: #3f621240;
}

.text-lime-800\/30 {
  color: #3f62124d;
}

.text-lime-800\/40 {
  color: #3f621266;
}

.text-lime-800\/5 {
  color: #3f62120d;
}

.text-lime-800\/50 {
  color: #3f621280;
}

.text-lime-800\/60 {
  color: #3f621299;
}

.text-lime-800\/70 {
  color: #3f6212b3;
}

.text-lime-800\/75 {
  color: #3f6212bf;
}

.text-lime-800\/80 {
  color: #3f6212cc;
}

.text-lime-800\/90 {
  color: #3f6212e6;
}

.text-lime-800\/95 {
  color: #3f6212f2;
}

.text-lime-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

.text-lime-900\/0 {
  color: #36531400;
}

.text-lime-900\/10 {
  color: #3653141a;
}

.text-lime-900\/100 {
  color: #365314;
}

.text-lime-900\/20 {
  color: #36531433;
}

.text-lime-900\/25 {
  color: #36531440;
}

.text-lime-900\/30 {
  color: #3653144d;
}

.text-lime-900\/40 {
  color: #36531466;
}

.text-lime-900\/5 {
  color: #3653140d;
}

.text-lime-900\/50 {
  color: #36531480;
}

.text-lime-900\/60 {
  color: #36531499;
}

.text-lime-900\/70 {
  color: #365314b3;
}

.text-lime-900\/75 {
  color: #365314bf;
}

.text-lime-900\/80 {
  color: #365314cc;
}

.text-lime-900\/90 {
  color: #365314e6;
}

.text-lime-900\/95 {
  color: #365314f2;
}

.text-lime-950 {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity));
}

.text-lime-950\/0 {
  color: #1a2e0500;
}

.text-lime-950\/10 {
  color: #1a2e051a;
}

.text-lime-950\/100 {
  color: #1a2e05;
}

.text-lime-950\/20 {
  color: #1a2e0533;
}

.text-lime-950\/25 {
  color: #1a2e0540;
}

.text-lime-950\/30 {
  color: #1a2e054d;
}

.text-lime-950\/40 {
  color: #1a2e0566;
}

.text-lime-950\/5 {
  color: #1a2e050d;
}

.text-lime-950\/50 {
  color: #1a2e0580;
}

.text-lime-950\/60 {
  color: #1a2e0599;
}

.text-lime-950\/70 {
  color: #1a2e05b3;
}

.text-lime-950\/75 {
  color: #1a2e05bf;
}

.text-lime-950\/80 {
  color: #1a2e05cc;
}

.text-lime-950\/90 {
  color: #1a2e05e6;
}

.text-lime-950\/95 {
  color: #1a2e05f2;
}

.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgb(252 231 243 / var(--tw-text-opacity));
}

.text-pink-100\/0 {
  color: #fce7f300;
}

.text-pink-100\/10 {
  color: #fce7f31a;
}

.text-pink-100\/100 {
  color: #fce7f3;
}

.text-pink-100\/20 {
  color: #fce7f333;
}

.text-pink-100\/25 {
  color: #fce7f340;
}

.text-pink-100\/30 {
  color: #fce7f34d;
}

.text-pink-100\/40 {
  color: #fce7f366;
}

.text-pink-100\/5 {
  color: #fce7f30d;
}

.text-pink-100\/50 {
  color: #fce7f380;
}

.text-pink-100\/60 {
  color: #fce7f399;
}

.text-pink-100\/70 {
  color: #fce7f3b3;
}

.text-pink-100\/75 {
  color: #fce7f3bf;
}

.text-pink-100\/80 {
  color: #fce7f3cc;
}

.text-pink-100\/90 {
  color: #fce7f3e6;
}

.text-pink-100\/95 {
  color: #fce7f3f2;
}

.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(251 207 232 / var(--tw-text-opacity));
}

.text-pink-200\/0 {
  color: #fbcfe800;
}

.text-pink-200\/10 {
  color: #fbcfe81a;
}

.text-pink-200\/100 {
  color: #fbcfe8;
}

.text-pink-200\/20 {
  color: #fbcfe833;
}

.text-pink-200\/25 {
  color: #fbcfe840;
}

.text-pink-200\/30 {
  color: #fbcfe84d;
}

.text-pink-200\/40 {
  color: #fbcfe866;
}

.text-pink-200\/5 {
  color: #fbcfe80d;
}

.text-pink-200\/50 {
  color: #fbcfe880;
}

.text-pink-200\/60 {
  color: #fbcfe899;
}

.text-pink-200\/70 {
  color: #fbcfe8b3;
}

.text-pink-200\/75 {
  color: #fbcfe8bf;
}

.text-pink-200\/80 {
  color: #fbcfe8cc;
}

.text-pink-200\/90 {
  color: #fbcfe8e6;
}

.text-pink-200\/95 {
  color: #fbcfe8f2;
}

.text-pink-300 {
  --tw-text-opacity: 1;
  color: rgb(249 168 212 / var(--tw-text-opacity));
}

.text-pink-300\/0 {
  color: #f9a8d400;
}

.text-pink-300\/10 {
  color: #f9a8d41a;
}

.text-pink-300\/100 {
  color: #f9a8d4;
}

.text-pink-300\/20 {
  color: #f9a8d433;
}

.text-pink-300\/25 {
  color: #f9a8d440;
}

.text-pink-300\/30 {
  color: #f9a8d44d;
}

.text-pink-300\/40 {
  color: #f9a8d466;
}

.text-pink-300\/5 {
  color: #f9a8d40d;
}

.text-pink-300\/50 {
  color: #f9a8d480;
}

.text-pink-300\/60 {
  color: #f9a8d499;
}

.text-pink-300\/70 {
  color: #f9a8d4b3;
}

.text-pink-300\/75 {
  color: #f9a8d4bf;
}

.text-pink-300\/80 {
  color: #f9a8d4cc;
}

.text-pink-300\/90 {
  color: #f9a8d4e6;
}

.text-pink-300\/95 {
  color: #f9a8d4f2;
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}

.text-pink-400\/0 {
  color: #f472b600;
}

.text-pink-400\/10 {
  color: #f472b61a;
}

.text-pink-400\/100 {
  color: #f472b6;
}

.text-pink-400\/20 {
  color: #f472b633;
}

.text-pink-400\/25 {
  color: #f472b640;
}

.text-pink-400\/30 {
  color: #f472b64d;
}

.text-pink-400\/40 {
  color: #f472b666;
}

.text-pink-400\/5 {
  color: #f472b60d;
}

.text-pink-400\/50 {
  color: #f472b680;
}

.text-pink-400\/60 {
  color: #f472b699;
}

.text-pink-400\/70 {
  color: #f472b6b3;
}

.text-pink-400\/75 {
  color: #f472b6bf;
}

.text-pink-400\/80 {
  color: #f472b6cc;
}

.text-pink-400\/90 {
  color: #f472b6e6;
}

.text-pink-400\/95 {
  color: #f472b6f2;
}

.text-pink-50 {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity));
}

.text-pink-50\/0 {
  color: #fdf2f800;
}

.text-pink-50\/10 {
  color: #fdf2f81a;
}

.text-pink-50\/100 {
  color: #fdf2f8;
}

.text-pink-50\/20 {
  color: #fdf2f833;
}

.text-pink-50\/25 {
  color: #fdf2f840;
}

.text-pink-50\/30 {
  color: #fdf2f84d;
}

.text-pink-50\/40 {
  color: #fdf2f866;
}

.text-pink-50\/5 {
  color: #fdf2f80d;
}

.text-pink-50\/50 {
  color: #fdf2f880;
}

.text-pink-50\/60 {
  color: #fdf2f899;
}

.text-pink-50\/70 {
  color: #fdf2f8b3;
}

.text-pink-50\/75 {
  color: #fdf2f8bf;
}

.text-pink-50\/80 {
  color: #fdf2f8cc;
}

.text-pink-50\/90 {
  color: #fdf2f8e6;
}

.text-pink-50\/95 {
  color: #fdf2f8f2;
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.text-pink-500\/0 {
  color: #ec489900;
}

.text-pink-500\/10 {
  color: #ec48991a;
}

.text-pink-500\/100 {
  color: #ec4899;
}

.text-pink-500\/20 {
  color: #ec489933;
}

.text-pink-500\/25 {
  color: #ec489940;
}

.text-pink-500\/30 {
  color: #ec48994d;
}

.text-pink-500\/40 {
  color: #ec489966;
}

.text-pink-500\/5 {
  color: #ec48990d;
}

.text-pink-500\/50 {
  color: #ec489980;
}

.text-pink-500\/60 {
  color: #ec489999;
}

.text-pink-500\/70 {
  color: #ec4899b3;
}

.text-pink-500\/75 {
  color: #ec4899bf;
}

.text-pink-500\/80 {
  color: #ec4899cc;
}

.text-pink-500\/90 {
  color: #ec4899e6;
}

.text-pink-500\/95 {
  color: #ec4899f2;
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

.text-pink-600\/0 {
  color: #db277700;
}

.text-pink-600\/10 {
  color: #db27771a;
}

.text-pink-600\/100 {
  color: #db2777;
}

.text-pink-600\/20 {
  color: #db277733;
}

.text-pink-600\/25 {
  color: #db277740;
}

.text-pink-600\/30 {
  color: #db27774d;
}

.text-pink-600\/40 {
  color: #db277766;
}

.text-pink-600\/5 {
  color: #db27770d;
}

.text-pink-600\/50 {
  color: #db277780;
}

.text-pink-600\/60 {
  color: #db277799;
}

.text-pink-600\/70 {
  color: #db2777b3;
}

.text-pink-600\/75 {
  color: #db2777bf;
}

.text-pink-600\/80 {
  color: #db2777cc;
}

.text-pink-600\/90 {
  color: #db2777e6;
}

.text-pink-600\/95 {
  color: #db2777f2;
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

.text-pink-700\/0 {
  color: #be185d00;
}

.text-pink-700\/10 {
  color: #be185d1a;
}

.text-pink-700\/100 {
  color: #be185d;
}

.text-pink-700\/20 {
  color: #be185d33;
}

.text-pink-700\/25 {
  color: #be185d40;
}

.text-pink-700\/30 {
  color: #be185d4d;
}

.text-pink-700\/40 {
  color: #be185d66;
}

.text-pink-700\/5 {
  color: #be185d0d;
}

.text-pink-700\/50 {
  color: #be185d80;
}

.text-pink-700\/60 {
  color: #be185d99;
}

.text-pink-700\/70 {
  color: #be185db3;
}

.text-pink-700\/75 {
  color: #be185dbf;
}

.text-pink-700\/80 {
  color: #be185dcc;
}

.text-pink-700\/90 {
  color: #be185de6;
}

.text-pink-700\/95 {
  color: #be185df2;
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

.text-pink-800\/0 {
  color: #9d174d00;
}

.text-pink-800\/10 {
  color: #9d174d1a;
}

.text-pink-800\/100 {
  color: #9d174d;
}

.text-pink-800\/20 {
  color: #9d174d33;
}

.text-pink-800\/25 {
  color: #9d174d40;
}

.text-pink-800\/30 {
  color: #9d174d4d;
}

.text-pink-800\/40 {
  color: #9d174d66;
}

.text-pink-800\/5 {
  color: #9d174d0d;
}

.text-pink-800\/50 {
  color: #9d174d80;
}

.text-pink-800\/60 {
  color: #9d174d99;
}

.text-pink-800\/70 {
  color: #9d174db3;
}

.text-pink-800\/75 {
  color: #9d174dbf;
}

.text-pink-800\/80 {
  color: #9d174dcc;
}

.text-pink-800\/90 {
  color: #9d174de6;
}

.text-pink-800\/95 {
  color: #9d174df2;
}

.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

.text-pink-900\/0 {
  color: #83184300;
}

.text-pink-900\/10 {
  color: #8318431a;
}

.text-pink-900\/100 {
  color: #831843;
}

.text-pink-900\/20 {
  color: #83184333;
}

.text-pink-900\/25 {
  color: #83184340;
}

.text-pink-900\/30 {
  color: #8318434d;
}

.text-pink-900\/40 {
  color: #83184366;
}

.text-pink-900\/5 {
  color: #8318430d;
}

.text-pink-900\/50 {
  color: #83184380;
}

.text-pink-900\/60 {
  color: #83184399;
}

.text-pink-900\/70 {
  color: #831843b3;
}

.text-pink-900\/75 {
  color: #831843bf;
}

.text-pink-900\/80 {
  color: #831843cc;
}

.text-pink-900\/90 {
  color: #831843e6;
}

.text-pink-900\/95 {
  color: #831843f2;
}

.text-pink-950 {
  --tw-text-opacity: 1;
  color: rgb(80 7 36 / var(--tw-text-opacity));
}

.text-pink-950\/0 {
  color: #50072400;
}

.text-pink-950\/10 {
  color: #5007241a;
}

.text-pink-950\/100 {
  color: #500724;
}

.text-pink-950\/20 {
  color: #50072433;
}

.text-pink-950\/25 {
  color: #50072440;
}

.text-pink-950\/30 {
  color: #5007244d;
}

.text-pink-950\/40 {
  color: #50072466;
}

.text-pink-950\/5 {
  color: #5007240d;
}

.text-pink-950\/50 {
  color: #50072480;
}

.text-pink-950\/60 {
  color: #50072499;
}

.text-pink-950\/70 {
  color: #500724b3;
}

.text-pink-950\/75 {
  color: #500724bf;
}

.text-pink-950\/80 {
  color: #500724cc;
}

.text-pink-950\/90 {
  color: #500724e6;
}

.text-pink-950\/95 {
  color: #500724f2;
}

.text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.text-primary\/0 {
  color: hsl(var(--primary) / 0);
}

.text-primary\/10 {
  color: hsl(var(--primary) / .1);
}

.text-primary\/100 {
  color: hsl(var(--primary) / 1);
}

.text-primary\/20 {
  color: hsl(var(--primary) / .2);
}

.text-primary\/25 {
  color: hsl(var(--primary) / .25);
}

.text-primary\/30 {
  color: hsl(var(--primary) / .3);
}

.text-primary\/40 {
  color: hsl(var(--primary) / .4);
}

.text-primary\/5 {
  color: hsl(var(--primary) / .05);
}

.text-primary\/50 {
  color: hsl(var(--primary) / .5);
}

.text-primary\/60 {
  color: hsl(var(--primary) / .6);
}

.text-primary\/70 {
  color: hsl(var(--primary) / .7);
}

.text-primary\/75 {
  color: hsl(var(--primary) / .75);
}

.text-primary\/80 {
  color: hsl(var(--primary) / .8);
}

.text-primary\/90 {
  color: hsl(var(--primary) / .9);
}

.text-primary\/95 {
  color: hsl(var(--primary) / .95);
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity));
}

.text-purple-100\/0 {
  color: #f3e8ff00;
}

.text-purple-100\/10 {
  color: #f3e8ff1a;
}

.text-purple-100\/100 {
  color: #f3e8ff;
}

.text-purple-100\/20 {
  color: #f3e8ff33;
}

.text-purple-100\/25 {
  color: #f3e8ff40;
}

.text-purple-100\/30 {
  color: #f3e8ff4d;
}

.text-purple-100\/40 {
  color: #f3e8ff66;
}

.text-purple-100\/5 {
  color: #f3e8ff0d;
}

.text-purple-100\/50 {
  color: #f3e8ff80;
}

.text-purple-100\/60 {
  color: #f3e8ff99;
}

.text-purple-100\/70 {
  color: #f3e8ffb3;
}

.text-purple-100\/75 {
  color: #f3e8ffbf;
}

.text-purple-100\/80 {
  color: #f3e8ffcc;
}

.text-purple-100\/90 {
  color: #f3e8ffe6;
}

.text-purple-100\/95 {
  color: #f3e8fff2;
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(233 213 255 / var(--tw-text-opacity));
}

.text-purple-200\/0 {
  color: #e9d5ff00;
}

.text-purple-200\/10 {
  color: #e9d5ff1a;
}

.text-purple-200\/100 {
  color: #e9d5ff;
}

.text-purple-200\/20 {
  color: #e9d5ff33;
}

.text-purple-200\/25 {
  color: #e9d5ff40;
}

.text-purple-200\/30 {
  color: #e9d5ff4d;
}

.text-purple-200\/40 {
  color: #e9d5ff66;
}

.text-purple-200\/5 {
  color: #e9d5ff0d;
}

.text-purple-200\/50 {
  color: #e9d5ff80;
}

.text-purple-200\/60 {
  color: #e9d5ff99;
}

.text-purple-200\/70 {
  color: #e9d5ffb3;
}

.text-purple-200\/75 {
  color: #e9d5ffbf;
}

.text-purple-200\/80 {
  color: #e9d5ffcc;
}

.text-purple-200\/90 {
  color: #e9d5ffe6;
}

.text-purple-200\/95 {
  color: #e9d5fff2;
}

.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity));
}

.text-purple-300\/0 {
  color: #d8b4fe00;
}

.text-purple-300\/10 {
  color: #d8b4fe1a;
}

.text-purple-300\/100 {
  color: #d8b4fe;
}

.text-purple-300\/20 {
  color: #d8b4fe33;
}

.text-purple-300\/25 {
  color: #d8b4fe40;
}

.text-purple-300\/30 {
  color: #d8b4fe4d;
}

.text-purple-300\/40 {
  color: #d8b4fe66;
}

.text-purple-300\/5 {
  color: #d8b4fe0d;
}

.text-purple-300\/50 {
  color: #d8b4fe80;
}

.text-purple-300\/60 {
  color: #d8b4fe99;
}

.text-purple-300\/70 {
  color: #d8b4feb3;
}

.text-purple-300\/75 {
  color: #d8b4febf;
}

.text-purple-300\/80 {
  color: #d8b4fecc;
}

.text-purple-300\/90 {
  color: #d8b4fee6;
}

.text-purple-300\/95 {
  color: #d8b4fef2;
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.text-purple-400\/0 {
  color: #c084fc00;
}

.text-purple-400\/10 {
  color: #c084fc1a;
}

.text-purple-400\/100 {
  color: #c084fc;
}

.text-purple-400\/20 {
  color: #c084fc33;
}

.text-purple-400\/25 {
  color: #c084fc40;
}

.text-purple-400\/30 {
  color: #c084fc4d;
}

.text-purple-400\/40 {
  color: #c084fc66;
}

.text-purple-400\/5 {
  color: #c084fc0d;
}

.text-purple-400\/50 {
  color: #c084fc80;
}

.text-purple-400\/60 {
  color: #c084fc99;
}

.text-purple-400\/70 {
  color: #c084fcb3;
}

.text-purple-400\/75 {
  color: #c084fcbf;
}

.text-purple-400\/80 {
  color: #c084fccc;
}

.text-purple-400\/90 {
  color: #c084fce6;
}

.text-purple-400\/95 {
  color: #c084fcf2;
}

.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgb(250 245 255 / var(--tw-text-opacity));
}

.text-purple-50\/0 {
  color: #faf5ff00;
}

.text-purple-50\/10 {
  color: #faf5ff1a;
}

.text-purple-50\/100 {
  color: #faf5ff;
}

.text-purple-50\/20 {
  color: #faf5ff33;
}

.text-purple-50\/25 {
  color: #faf5ff40;
}

.text-purple-50\/30 {
  color: #faf5ff4d;
}

.text-purple-50\/40 {
  color: #faf5ff66;
}

.text-purple-50\/5 {
  color: #faf5ff0d;
}

.text-purple-50\/50 {
  color: #faf5ff80;
}

.text-purple-50\/60 {
  color: #faf5ff99;
}

.text-purple-50\/70 {
  color: #faf5ffb3;
}

.text-purple-50\/75 {
  color: #faf5ffbf;
}

.text-purple-50\/80 {
  color: #faf5ffcc;
}

.text-purple-50\/90 {
  color: #faf5ffe6;
}

.text-purple-50\/95 {
  color: #faf5fff2;
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-purple-500\/0 {
  color: #a855f700;
}

.text-purple-500\/10 {
  color: #a855f71a;
}

.text-purple-500\/100 {
  color: #a855f7;
}

.text-purple-500\/20 {
  color: #a855f733;
}

.text-purple-500\/25 {
  color: #a855f740;
}

.text-purple-500\/30 {
  color: #a855f74d;
}

.text-purple-500\/40 {
  color: #a855f766;
}

.text-purple-500\/5 {
  color: #a855f70d;
}

.text-purple-500\/50 {
  color: #a855f780;
}

.text-purple-500\/60 {
  color: #a855f799;
}

.text-purple-500\/70 {
  color: #a855f7b3;
}

.text-purple-500\/75 {
  color: #a855f7bf;
}

.text-purple-500\/80 {
  color: #a855f7cc;
}

.text-purple-500\/90 {
  color: #a855f7e6;
}

.text-purple-500\/95 {
  color: #a855f7f2;
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.text-purple-600\/0 {
  color: #9333ea00;
}

.text-purple-600\/10 {
  color: #9333ea1a;
}

.text-purple-600\/100 {
  color: #9333ea;
}

.text-purple-600\/20 {
  color: #9333ea33;
}

.text-purple-600\/25 {
  color: #9333ea40;
}

.text-purple-600\/30 {
  color: #9333ea4d;
}

.text-purple-600\/40 {
  color: #9333ea66;
}

.text-purple-600\/5 {
  color: #9333ea0d;
}

.text-purple-600\/50 {
  color: #9333ea80;
}

.text-purple-600\/60 {
  color: #9333ea99;
}

.text-purple-600\/70 {
  color: #9333eab3;
}

.text-purple-600\/75 {
  color: #9333eabf;
}

.text-purple-600\/80 {
  color: #9333eacc;
}

.text-purple-600\/90 {
  color: #9333eae6;
}

.text-purple-600\/95 {
  color: #9333eaf2;
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

.text-purple-700\/0 {
  color: #7e22ce00;
}

.text-purple-700\/10 {
  color: #7e22ce1a;
}

.text-purple-700\/100 {
  color: #7e22ce;
}

.text-purple-700\/20 {
  color: #7e22ce33;
}

.text-purple-700\/25 {
  color: #7e22ce40;
}

.text-purple-700\/30 {
  color: #7e22ce4d;
}

.text-purple-700\/40 {
  color: #7e22ce66;
}

.text-purple-700\/5 {
  color: #7e22ce0d;
}

.text-purple-700\/50 {
  color: #7e22ce80;
}

.text-purple-700\/60 {
  color: #7e22ce99;
}

.text-purple-700\/70 {
  color: #7e22ceb3;
}

.text-purple-700\/75 {
  color: #7e22cebf;
}

.text-purple-700\/80 {
  color: #7e22cecc;
}

.text-purple-700\/90 {
  color: #7e22cee6;
}

.text-purple-700\/95 {
  color: #7e22cef2;
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.text-purple-800\/0 {
  color: #6b21a800;
}

.text-purple-800\/10 {
  color: #6b21a81a;
}

.text-purple-800\/100 {
  color: #6b21a8;
}

.text-purple-800\/20 {
  color: #6b21a833;
}

.text-purple-800\/25 {
  color: #6b21a840;
}

.text-purple-800\/30 {
  color: #6b21a84d;
}

.text-purple-800\/40 {
  color: #6b21a866;
}

.text-purple-800\/5 {
  color: #6b21a80d;
}

.text-purple-800\/50 {
  color: #6b21a880;
}

.text-purple-800\/60 {
  color: #6b21a899;
}

.text-purple-800\/70 {
  color: #6b21a8b3;
}

.text-purple-800\/75 {
  color: #6b21a8bf;
}

.text-purple-800\/80 {
  color: #6b21a8cc;
}

.text-purple-800\/90 {
  color: #6b21a8e6;
}

.text-purple-800\/95 {
  color: #6b21a8f2;
}

.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}

.text-purple-900\/0 {
  color: #581c8700;
}

.text-purple-900\/10 {
  color: #581c871a;
}

.text-purple-900\/100 {
  color: #581c87;
}

.text-purple-900\/20 {
  color: #581c8733;
}

.text-purple-900\/25 {
  color: #581c8740;
}

.text-purple-900\/30 {
  color: #581c874d;
}

.text-purple-900\/40 {
  color: #581c8766;
}

.text-purple-900\/5 {
  color: #581c870d;
}

.text-purple-900\/50 {
  color: #581c8780;
}

.text-purple-900\/60 {
  color: #581c8799;
}

.text-purple-900\/70 {
  color: #581c87b3;
}

.text-purple-900\/75 {
  color: #581c87bf;
}

.text-purple-900\/80 {
  color: #581c87cc;
}

.text-purple-900\/90 {
  color: #581c87e6;
}

.text-purple-900\/95 {
  color: #581c87f2;
}

.text-purple-950 {
  --tw-text-opacity: 1;
  color: rgb(59 7 100 / var(--tw-text-opacity));
}

.text-purple-950\/0 {
  color: #3b076400;
}

.text-purple-950\/10 {
  color: #3b07641a;
}

.text-purple-950\/100 {
  color: #3b0764;
}

.text-purple-950\/20 {
  color: #3b076433;
}

.text-purple-950\/25 {
  color: #3b076440;
}

.text-purple-950\/30 {
  color: #3b07644d;
}

.text-purple-950\/40 {
  color: #3b076466;
}

.text-purple-950\/5 {
  color: #3b07640d;
}

.text-purple-950\/50 {
  color: #3b076480;
}

.text-purple-950\/60 {
  color: #3b076499;
}

.text-purple-950\/70 {
  color: #3b0764b3;
}

.text-purple-950\/75 {
  color: #3b0764bf;
}

.text-purple-950\/80 {
  color: #3b0764cc;
}

.text-purple-950\/90 {
  color: #3b0764e6;
}

.text-purple-950\/95 {
  color: #3b0764f2;
}

.text-red {
  --tw-text-opacity: 1;
  color: hsl(var(--red) / var(--tw-text-opacity));
}

.text-transparent, .text-transparent\/0 {
  color: #0000;
}

.text-transparent\/10 {
  color: #0000001a;
}

.text-transparent\/100 {
  color: #000;
}

.text-transparent\/20 {
  color: #0003;
}

.text-transparent\/25 {
  color: #00000040;
}

.text-transparent\/30 {
  color: #0000004d;
}

.text-transparent\/40 {
  color: #0006;
}

.text-transparent\/5 {
  color: #0000000d;
}

.text-transparent\/50 {
  color: #00000080;
}

.text-transparent\/60 {
  color: #0009;
}

.text-transparent\/70 {
  color: #000000b3;
}

.text-transparent\/75 {
  color: #000000bf;
}

.text-transparent\/80 {
  color: #000c;
}

.text-transparent\/90 {
  color: #000000e6;
}

.text-transparent\/95 {
  color: #000000f2;
}

.text-violet {
  --tw-text-opacity: 1;
  color: hsl(var(--violet) / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow {
  --tw-text-opacity: 1;
  color: hsl(var(--yellow) / var(--tw-text-opacity));
}

.text-opacity-0 {
  --tw-text-opacity: 0;
}

.text-opacity-10 {
  --tw-text-opacity: .1;
}

.text-opacity-100 {
  --tw-text-opacity: 1;
}

.text-opacity-20 {
  --tw-text-opacity: .2;
}

.text-opacity-25 {
  --tw-text-opacity: .25;
}

.text-opacity-30 {
  --tw-text-opacity: .3;
}

.text-opacity-40 {
  --tw-text-opacity: .4;
}

.text-opacity-5 {
  --tw-text-opacity: .05;
}

.text-opacity-50 {
  --tw-text-opacity: .5;
}

.text-opacity-60 {
  --tw-text-opacity: .6;
}

.text-opacity-70 {
  --tw-text-opacity: .7;
}

.text-opacity-75 {
  --tw-text-opacity: .75;
}

.text-opacity-80 {
  --tw-text-opacity: .8;
}

.text-opacity-90 {
  --tw-text-opacity: .9;
}

.text-opacity-95 {
  --tw-text-opacity: .95;
}

.decoration-amber-100 {
  text-decoration-color: #fef3c7;
}

.decoration-amber-100\/0 {
  text-decoration-color: #fef3c700;
}

.decoration-amber-100\/10 {
  text-decoration-color: #fef3c71a;
}

.decoration-amber-100\/100 {
  text-decoration-color: #fef3c7;
}

.decoration-amber-100\/20 {
  text-decoration-color: #fef3c733;
}

.decoration-amber-100\/25 {
  text-decoration-color: #fef3c740;
}

.decoration-amber-100\/30 {
  text-decoration-color: #fef3c74d;
}

.decoration-amber-100\/40 {
  text-decoration-color: #fef3c766;
}

.decoration-amber-100\/5 {
  text-decoration-color: #fef3c70d;
}

.decoration-amber-100\/50 {
  text-decoration-color: #fef3c780;
}

.decoration-amber-100\/60 {
  text-decoration-color: #fef3c799;
}

.decoration-amber-100\/70 {
  text-decoration-color: #fef3c7b3;
}

.decoration-amber-100\/75 {
  text-decoration-color: #fef3c7bf;
}

.decoration-amber-100\/80 {
  text-decoration-color: #fef3c7cc;
}

.decoration-amber-100\/90 {
  text-decoration-color: #fef3c7e6;
}

.decoration-amber-100\/95 {
  text-decoration-color: #fef3c7f2;
}

.decoration-amber-200 {
  text-decoration-color: #fde68a;
}

.decoration-amber-200\/0 {
  text-decoration-color: #fde68a00;
}

.decoration-amber-200\/10 {
  text-decoration-color: #fde68a1a;
}

.decoration-amber-200\/100 {
  text-decoration-color: #fde68a;
}

.decoration-amber-200\/20 {
  text-decoration-color: #fde68a33;
}

.decoration-amber-200\/25 {
  text-decoration-color: #fde68a40;
}

.decoration-amber-200\/30 {
  text-decoration-color: #fde68a4d;
}

.decoration-amber-200\/40 {
  text-decoration-color: #fde68a66;
}

.decoration-amber-200\/5 {
  text-decoration-color: #fde68a0d;
}

.decoration-amber-200\/50 {
  text-decoration-color: #fde68a80;
}

.decoration-amber-200\/60 {
  text-decoration-color: #fde68a99;
}

.decoration-amber-200\/70 {
  text-decoration-color: #fde68ab3;
}

.decoration-amber-200\/75 {
  text-decoration-color: #fde68abf;
}

.decoration-amber-200\/80 {
  text-decoration-color: #fde68acc;
}

.decoration-amber-200\/90 {
  text-decoration-color: #fde68ae6;
}

.decoration-amber-200\/95 {
  text-decoration-color: #fde68af2;
}

.decoration-amber-300 {
  text-decoration-color: #fcd34d;
}

.decoration-amber-300\/0 {
  text-decoration-color: #fcd34d00;
}

.decoration-amber-300\/10 {
  text-decoration-color: #fcd34d1a;
}

.decoration-amber-300\/100 {
  text-decoration-color: #fcd34d;
}

.decoration-amber-300\/20 {
  text-decoration-color: #fcd34d33;
}

.decoration-amber-300\/25 {
  text-decoration-color: #fcd34d40;
}

.decoration-amber-300\/30 {
  text-decoration-color: #fcd34d4d;
}

.decoration-amber-300\/40 {
  text-decoration-color: #fcd34d66;
}

.decoration-amber-300\/5 {
  text-decoration-color: #fcd34d0d;
}

.decoration-amber-300\/50 {
  text-decoration-color: #fcd34d80;
}

.decoration-amber-300\/60 {
  text-decoration-color: #fcd34d99;
}

.decoration-amber-300\/70 {
  text-decoration-color: #fcd34db3;
}

.decoration-amber-300\/75 {
  text-decoration-color: #fcd34dbf;
}

.decoration-amber-300\/80 {
  text-decoration-color: #fcd34dcc;
}

.decoration-amber-300\/90 {
  text-decoration-color: #fcd34de6;
}

.decoration-amber-300\/95 {
  text-decoration-color: #fcd34df2;
}

.decoration-amber-400 {
  text-decoration-color: #fbbf24;
}

.decoration-amber-400\/0 {
  text-decoration-color: #fbbf2400;
}

.decoration-amber-400\/10 {
  text-decoration-color: #fbbf241a;
}

.decoration-amber-400\/100 {
  text-decoration-color: #fbbf24;
}

.decoration-amber-400\/20 {
  text-decoration-color: #fbbf2433;
}

.decoration-amber-400\/25 {
  text-decoration-color: #fbbf2440;
}

.decoration-amber-400\/30 {
  text-decoration-color: #fbbf244d;
}

.decoration-amber-400\/40 {
  text-decoration-color: #fbbf2466;
}

.decoration-amber-400\/5 {
  text-decoration-color: #fbbf240d;
}

.decoration-amber-400\/50 {
  text-decoration-color: #fbbf2480;
}

.decoration-amber-400\/60 {
  text-decoration-color: #fbbf2499;
}

.decoration-amber-400\/70 {
  text-decoration-color: #fbbf24b3;
}

.decoration-amber-400\/75 {
  text-decoration-color: #fbbf24bf;
}

.decoration-amber-400\/80 {
  text-decoration-color: #fbbf24cc;
}

.decoration-amber-400\/90 {
  text-decoration-color: #fbbf24e6;
}

.decoration-amber-400\/95 {
  text-decoration-color: #fbbf24f2;
}

.decoration-amber-50 {
  text-decoration-color: #fffbeb;
}

.decoration-amber-50\/0 {
  text-decoration-color: #fffbeb00;
}

.decoration-amber-50\/10 {
  text-decoration-color: #fffbeb1a;
}

.decoration-amber-50\/100 {
  text-decoration-color: #fffbeb;
}

.decoration-amber-50\/20 {
  text-decoration-color: #fffbeb33;
}

.decoration-amber-50\/25 {
  text-decoration-color: #fffbeb40;
}

.decoration-amber-50\/30 {
  text-decoration-color: #fffbeb4d;
}

.decoration-amber-50\/40 {
  text-decoration-color: #fffbeb66;
}

.decoration-amber-50\/5 {
  text-decoration-color: #fffbeb0d;
}

.decoration-amber-50\/50 {
  text-decoration-color: #fffbeb80;
}

.decoration-amber-50\/60 {
  text-decoration-color: #fffbeb99;
}

.decoration-amber-50\/70 {
  text-decoration-color: #fffbebb3;
}

.decoration-amber-50\/75 {
  text-decoration-color: #fffbebbf;
}

.decoration-amber-50\/80 {
  text-decoration-color: #fffbebcc;
}

.decoration-amber-50\/90 {
  text-decoration-color: #fffbebe6;
}

.decoration-amber-50\/95 {
  text-decoration-color: #fffbebf2;
}

.decoration-amber-500 {
  text-decoration-color: #f59e0b;
}

.decoration-amber-500\/0 {
  text-decoration-color: #f59e0b00;
}

.decoration-amber-500\/10 {
  text-decoration-color: #f59e0b1a;
}

.decoration-amber-500\/100 {
  text-decoration-color: #f59e0b;
}

.decoration-amber-500\/20 {
  text-decoration-color: #f59e0b33;
}

.decoration-amber-500\/25 {
  text-decoration-color: #f59e0b40;
}

.decoration-amber-500\/30 {
  text-decoration-color: #f59e0b4d;
}

.decoration-amber-500\/40 {
  text-decoration-color: #f59e0b66;
}

.decoration-amber-500\/5 {
  text-decoration-color: #f59e0b0d;
}

.decoration-amber-500\/50 {
  text-decoration-color: #f59e0b80;
}

.decoration-amber-500\/60 {
  text-decoration-color: #f59e0b99;
}

.decoration-amber-500\/70 {
  text-decoration-color: #f59e0bb3;
}

.decoration-amber-500\/75 {
  text-decoration-color: #f59e0bbf;
}

.decoration-amber-500\/80 {
  text-decoration-color: #f59e0bcc;
}

.decoration-amber-500\/90 {
  text-decoration-color: #f59e0be6;
}

.decoration-amber-500\/95 {
  text-decoration-color: #f59e0bf2;
}

.decoration-amber-600 {
  text-decoration-color: #d97706;
}

.decoration-amber-600\/0 {
  text-decoration-color: #d9770600;
}

.decoration-amber-600\/10 {
  text-decoration-color: #d977061a;
}

.decoration-amber-600\/100 {
  text-decoration-color: #d97706;
}

.decoration-amber-600\/20 {
  text-decoration-color: #d9770633;
}

.decoration-amber-600\/25 {
  text-decoration-color: #d9770640;
}

.decoration-amber-600\/30 {
  text-decoration-color: #d977064d;
}

.decoration-amber-600\/40 {
  text-decoration-color: #d9770666;
}

.decoration-amber-600\/5 {
  text-decoration-color: #d977060d;
}

.decoration-amber-600\/50 {
  text-decoration-color: #d9770680;
}

.decoration-amber-600\/60 {
  text-decoration-color: #d9770699;
}

.decoration-amber-600\/70 {
  text-decoration-color: #d97706b3;
}

.decoration-amber-600\/75 {
  text-decoration-color: #d97706bf;
}

.decoration-amber-600\/80 {
  text-decoration-color: #d97706cc;
}

.decoration-amber-600\/90 {
  text-decoration-color: #d97706e6;
}

.decoration-amber-600\/95 {
  text-decoration-color: #d97706f2;
}

.decoration-amber-700 {
  text-decoration-color: #b45309;
}

.decoration-amber-700\/0 {
  text-decoration-color: #b4530900;
}

.decoration-amber-700\/10 {
  text-decoration-color: #b453091a;
}

.decoration-amber-700\/100 {
  text-decoration-color: #b45309;
}

.decoration-amber-700\/20 {
  text-decoration-color: #b4530933;
}

.decoration-amber-700\/25 {
  text-decoration-color: #b4530940;
}

.decoration-amber-700\/30 {
  text-decoration-color: #b453094d;
}

.decoration-amber-700\/40 {
  text-decoration-color: #b4530966;
}

.decoration-amber-700\/5 {
  text-decoration-color: #b453090d;
}

.decoration-amber-700\/50 {
  text-decoration-color: #b4530980;
}

.decoration-amber-700\/60 {
  text-decoration-color: #b4530999;
}

.decoration-amber-700\/70 {
  text-decoration-color: #b45309b3;
}

.decoration-amber-700\/75 {
  text-decoration-color: #b45309bf;
}

.decoration-amber-700\/80 {
  text-decoration-color: #b45309cc;
}

.decoration-amber-700\/90 {
  text-decoration-color: #b45309e6;
}

.decoration-amber-700\/95 {
  text-decoration-color: #b45309f2;
}

.decoration-amber-800 {
  text-decoration-color: #92400e;
}

.decoration-amber-800\/0 {
  text-decoration-color: #92400e00;
}

.decoration-amber-800\/10 {
  text-decoration-color: #92400e1a;
}

.decoration-amber-800\/100 {
  text-decoration-color: #92400e;
}

.decoration-amber-800\/20 {
  text-decoration-color: #92400e33;
}

.decoration-amber-800\/25 {
  text-decoration-color: #92400e40;
}

.decoration-amber-800\/30 {
  text-decoration-color: #92400e4d;
}

.decoration-amber-800\/40 {
  text-decoration-color: #92400e66;
}

.decoration-amber-800\/5 {
  text-decoration-color: #92400e0d;
}

.decoration-amber-800\/50 {
  text-decoration-color: #92400e80;
}

.decoration-amber-800\/60 {
  text-decoration-color: #92400e99;
}

.decoration-amber-800\/70 {
  text-decoration-color: #92400eb3;
}

.decoration-amber-800\/75 {
  text-decoration-color: #92400ebf;
}

.decoration-amber-800\/80 {
  text-decoration-color: #92400ecc;
}

.decoration-amber-800\/90 {
  text-decoration-color: #92400ee6;
}

.decoration-amber-800\/95 {
  text-decoration-color: #92400ef2;
}

.decoration-amber-900 {
  text-decoration-color: #78350f;
}

.decoration-amber-900\/0 {
  text-decoration-color: #78350f00;
}

.decoration-amber-900\/10 {
  text-decoration-color: #78350f1a;
}

.decoration-amber-900\/100 {
  text-decoration-color: #78350f;
}

.decoration-amber-900\/20 {
  text-decoration-color: #78350f33;
}

.decoration-amber-900\/25 {
  text-decoration-color: #78350f40;
}

.decoration-amber-900\/30 {
  text-decoration-color: #78350f4d;
}

.decoration-amber-900\/40 {
  text-decoration-color: #78350f66;
}

.decoration-amber-900\/5 {
  text-decoration-color: #78350f0d;
}

.decoration-amber-900\/50 {
  text-decoration-color: #78350f80;
}

.decoration-amber-900\/60 {
  text-decoration-color: #78350f99;
}

.decoration-amber-900\/70 {
  text-decoration-color: #78350fb3;
}

.decoration-amber-900\/75 {
  text-decoration-color: #78350fbf;
}

.decoration-amber-900\/80 {
  text-decoration-color: #78350fcc;
}

.decoration-amber-900\/90 {
  text-decoration-color: #78350fe6;
}

.decoration-amber-900\/95 {
  text-decoration-color: #78350ff2;
}

.decoration-amber-950 {
  text-decoration-color: #451a03;
}

.decoration-amber-950\/0 {
  text-decoration-color: #451a0300;
}

.decoration-amber-950\/10 {
  text-decoration-color: #451a031a;
}

.decoration-amber-950\/100 {
  text-decoration-color: #451a03;
}

.decoration-amber-950\/20 {
  text-decoration-color: #451a0333;
}

.decoration-amber-950\/25 {
  text-decoration-color: #451a0340;
}

.decoration-amber-950\/30 {
  text-decoration-color: #451a034d;
}

.decoration-amber-950\/40 {
  text-decoration-color: #451a0366;
}

.decoration-amber-950\/5 {
  text-decoration-color: #451a030d;
}

.decoration-amber-950\/50 {
  text-decoration-color: #451a0380;
}

.decoration-amber-950\/60 {
  text-decoration-color: #451a0399;
}

.decoration-amber-950\/70 {
  text-decoration-color: #451a03b3;
}

.decoration-amber-950\/75 {
  text-decoration-color: #451a03bf;
}

.decoration-amber-950\/80 {
  text-decoration-color: #451a03cc;
}

.decoration-amber-950\/90 {
  text-decoration-color: #451a03e6;
}

.decoration-amber-950\/95 {
  text-decoration-color: #451a03f2;
}

.decoration-emerald-100 {
  text-decoration-color: #d1fae5;
}

.decoration-emerald-100\/0 {
  text-decoration-color: #d1fae500;
}

.decoration-emerald-100\/10 {
  text-decoration-color: #d1fae51a;
}

.decoration-emerald-100\/100 {
  text-decoration-color: #d1fae5;
}

.decoration-emerald-100\/20 {
  text-decoration-color: #d1fae533;
}

.decoration-emerald-100\/25 {
  text-decoration-color: #d1fae540;
}

.decoration-emerald-100\/30 {
  text-decoration-color: #d1fae54d;
}

.decoration-emerald-100\/40 {
  text-decoration-color: #d1fae566;
}

.decoration-emerald-100\/5 {
  text-decoration-color: #d1fae50d;
}

.decoration-emerald-100\/50 {
  text-decoration-color: #d1fae580;
}

.decoration-emerald-100\/60 {
  text-decoration-color: #d1fae599;
}

.decoration-emerald-100\/70 {
  text-decoration-color: #d1fae5b3;
}

.decoration-emerald-100\/75 {
  text-decoration-color: #d1fae5bf;
}

.decoration-emerald-100\/80 {
  text-decoration-color: #d1fae5cc;
}

.decoration-emerald-100\/90 {
  text-decoration-color: #d1fae5e6;
}

.decoration-emerald-100\/95 {
  text-decoration-color: #d1fae5f2;
}

.decoration-emerald-200 {
  text-decoration-color: #a7f3d0;
}

.decoration-emerald-200\/0 {
  text-decoration-color: #a7f3d000;
}

.decoration-emerald-200\/10 {
  text-decoration-color: #a7f3d01a;
}

.decoration-emerald-200\/100 {
  text-decoration-color: #a7f3d0;
}

.decoration-emerald-200\/20 {
  text-decoration-color: #a7f3d033;
}

.decoration-emerald-200\/25 {
  text-decoration-color: #a7f3d040;
}

.decoration-emerald-200\/30 {
  text-decoration-color: #a7f3d04d;
}

.decoration-emerald-200\/40 {
  text-decoration-color: #a7f3d066;
}

.decoration-emerald-200\/5 {
  text-decoration-color: #a7f3d00d;
}

.decoration-emerald-200\/50 {
  text-decoration-color: #a7f3d080;
}

.decoration-emerald-200\/60 {
  text-decoration-color: #a7f3d099;
}

.decoration-emerald-200\/70 {
  text-decoration-color: #a7f3d0b3;
}

.decoration-emerald-200\/75 {
  text-decoration-color: #a7f3d0bf;
}

.decoration-emerald-200\/80 {
  text-decoration-color: #a7f3d0cc;
}

.decoration-emerald-200\/90 {
  text-decoration-color: #a7f3d0e6;
}

.decoration-emerald-200\/95 {
  text-decoration-color: #a7f3d0f2;
}

.decoration-emerald-300 {
  text-decoration-color: #6ee7b7;
}

.decoration-emerald-300\/0 {
  text-decoration-color: #6ee7b700;
}

.decoration-emerald-300\/10 {
  text-decoration-color: #6ee7b71a;
}

.decoration-emerald-300\/100 {
  text-decoration-color: #6ee7b7;
}

.decoration-emerald-300\/20 {
  text-decoration-color: #6ee7b733;
}

.decoration-emerald-300\/25 {
  text-decoration-color: #6ee7b740;
}

.decoration-emerald-300\/30 {
  text-decoration-color: #6ee7b74d;
}

.decoration-emerald-300\/40 {
  text-decoration-color: #6ee7b766;
}

.decoration-emerald-300\/5 {
  text-decoration-color: #6ee7b70d;
}

.decoration-emerald-300\/50 {
  text-decoration-color: #6ee7b780;
}

.decoration-emerald-300\/60 {
  text-decoration-color: #6ee7b799;
}

.decoration-emerald-300\/70 {
  text-decoration-color: #6ee7b7b3;
}

.decoration-emerald-300\/75 {
  text-decoration-color: #6ee7b7bf;
}

.decoration-emerald-300\/80 {
  text-decoration-color: #6ee7b7cc;
}

.decoration-emerald-300\/90 {
  text-decoration-color: #6ee7b7e6;
}

.decoration-emerald-300\/95 {
  text-decoration-color: #6ee7b7f2;
}

.decoration-emerald-400 {
  text-decoration-color: #34d399;
}

.decoration-emerald-400\/0 {
  text-decoration-color: #34d39900;
}

.decoration-emerald-400\/10 {
  text-decoration-color: #34d3991a;
}

.decoration-emerald-400\/100 {
  text-decoration-color: #34d399;
}

.decoration-emerald-400\/20 {
  text-decoration-color: #34d39933;
}

.decoration-emerald-400\/25 {
  text-decoration-color: #34d39940;
}

.decoration-emerald-400\/30 {
  text-decoration-color: #34d3994d;
}

.decoration-emerald-400\/40 {
  text-decoration-color: #34d39966;
}

.decoration-emerald-400\/5 {
  text-decoration-color: #34d3990d;
}

.decoration-emerald-400\/50 {
  text-decoration-color: #34d39980;
}

.decoration-emerald-400\/60 {
  text-decoration-color: #34d39999;
}

.decoration-emerald-400\/70 {
  text-decoration-color: #34d399b3;
}

.decoration-emerald-400\/75 {
  text-decoration-color: #34d399bf;
}

.decoration-emerald-400\/80 {
  text-decoration-color: #34d399cc;
}

.decoration-emerald-400\/90 {
  text-decoration-color: #34d399e6;
}

.decoration-emerald-400\/95 {
  text-decoration-color: #34d399f2;
}

.decoration-emerald-50 {
  text-decoration-color: #ecfdf5;
}

.decoration-emerald-50\/0 {
  text-decoration-color: #ecfdf500;
}

.decoration-emerald-50\/10 {
  text-decoration-color: #ecfdf51a;
}

.decoration-emerald-50\/100 {
  text-decoration-color: #ecfdf5;
}

.decoration-emerald-50\/20 {
  text-decoration-color: #ecfdf533;
}

.decoration-emerald-50\/25 {
  text-decoration-color: #ecfdf540;
}

.decoration-emerald-50\/30 {
  text-decoration-color: #ecfdf54d;
}

.decoration-emerald-50\/40 {
  text-decoration-color: #ecfdf566;
}

.decoration-emerald-50\/5 {
  text-decoration-color: #ecfdf50d;
}

.decoration-emerald-50\/50 {
  text-decoration-color: #ecfdf580;
}

.decoration-emerald-50\/60 {
  text-decoration-color: #ecfdf599;
}

.decoration-emerald-50\/70 {
  text-decoration-color: #ecfdf5b3;
}

.decoration-emerald-50\/75 {
  text-decoration-color: #ecfdf5bf;
}

.decoration-emerald-50\/80 {
  text-decoration-color: #ecfdf5cc;
}

.decoration-emerald-50\/90 {
  text-decoration-color: #ecfdf5e6;
}

.decoration-emerald-50\/95 {
  text-decoration-color: #ecfdf5f2;
}

.decoration-emerald-500 {
  text-decoration-color: #10b981;
}

.decoration-emerald-500\/0 {
  text-decoration-color: #10b98100;
}

.decoration-emerald-500\/10 {
  text-decoration-color: #10b9811a;
}

.decoration-emerald-500\/100 {
  text-decoration-color: #10b981;
}

.decoration-emerald-500\/20 {
  text-decoration-color: #10b98133;
}

.decoration-emerald-500\/25 {
  text-decoration-color: #10b98140;
}

.decoration-emerald-500\/30 {
  text-decoration-color: #10b9814d;
}

.decoration-emerald-500\/40 {
  text-decoration-color: #10b98166;
}

.decoration-emerald-500\/5 {
  text-decoration-color: #10b9810d;
}

.decoration-emerald-500\/50 {
  text-decoration-color: #10b98180;
}

.decoration-emerald-500\/60 {
  text-decoration-color: #10b98199;
}

.decoration-emerald-500\/70 {
  text-decoration-color: #10b981b3;
}

.decoration-emerald-500\/75 {
  text-decoration-color: #10b981bf;
}

.decoration-emerald-500\/80 {
  text-decoration-color: #10b981cc;
}

.decoration-emerald-500\/90 {
  text-decoration-color: #10b981e6;
}

.decoration-emerald-500\/95 {
  text-decoration-color: #10b981f2;
}

.decoration-emerald-600 {
  text-decoration-color: #059669;
}

.decoration-emerald-600\/0 {
  text-decoration-color: #05966900;
}

.decoration-emerald-600\/10 {
  text-decoration-color: #0596691a;
}

.decoration-emerald-600\/100 {
  text-decoration-color: #059669;
}

.decoration-emerald-600\/20 {
  text-decoration-color: #05966933;
}

.decoration-emerald-600\/25 {
  text-decoration-color: #05966940;
}

.decoration-emerald-600\/30 {
  text-decoration-color: #0596694d;
}

.decoration-emerald-600\/40 {
  text-decoration-color: #05966966;
}

.decoration-emerald-600\/5 {
  text-decoration-color: #0596690d;
}

.decoration-emerald-600\/50 {
  text-decoration-color: #05966980;
}

.decoration-emerald-600\/60 {
  text-decoration-color: #05966999;
}

.decoration-emerald-600\/70 {
  text-decoration-color: #059669b3;
}

.decoration-emerald-600\/75 {
  text-decoration-color: #059669bf;
}

.decoration-emerald-600\/80 {
  text-decoration-color: #059669cc;
}

.decoration-emerald-600\/90 {
  text-decoration-color: #059669e6;
}

.decoration-emerald-600\/95 {
  text-decoration-color: #059669f2;
}

.decoration-emerald-700 {
  text-decoration-color: #047857;
}

.decoration-emerald-700\/0 {
  text-decoration-color: #04785700;
}

.decoration-emerald-700\/10 {
  text-decoration-color: #0478571a;
}

.decoration-emerald-700\/100 {
  text-decoration-color: #047857;
}

.decoration-emerald-700\/20 {
  text-decoration-color: #04785733;
}

.decoration-emerald-700\/25 {
  text-decoration-color: #04785740;
}

.decoration-emerald-700\/30 {
  text-decoration-color: #0478574d;
}

.decoration-emerald-700\/40 {
  text-decoration-color: #04785766;
}

.decoration-emerald-700\/5 {
  text-decoration-color: #0478570d;
}

.decoration-emerald-700\/50 {
  text-decoration-color: #04785780;
}

.decoration-emerald-700\/60 {
  text-decoration-color: #04785799;
}

.decoration-emerald-700\/70 {
  text-decoration-color: #047857b3;
}

.decoration-emerald-700\/75 {
  text-decoration-color: #047857bf;
}

.decoration-emerald-700\/80 {
  text-decoration-color: #047857cc;
}

.decoration-emerald-700\/90 {
  text-decoration-color: #047857e6;
}

.decoration-emerald-700\/95 {
  text-decoration-color: #047857f2;
}

.decoration-emerald-800 {
  text-decoration-color: #065f46;
}

.decoration-emerald-800\/0 {
  text-decoration-color: #065f4600;
}

.decoration-emerald-800\/10 {
  text-decoration-color: #065f461a;
}

.decoration-emerald-800\/100 {
  text-decoration-color: #065f46;
}

.decoration-emerald-800\/20 {
  text-decoration-color: #065f4633;
}

.decoration-emerald-800\/25 {
  text-decoration-color: #065f4640;
}

.decoration-emerald-800\/30 {
  text-decoration-color: #065f464d;
}

.decoration-emerald-800\/40 {
  text-decoration-color: #065f4666;
}

.decoration-emerald-800\/5 {
  text-decoration-color: #065f460d;
}

.decoration-emerald-800\/50 {
  text-decoration-color: #065f4680;
}

.decoration-emerald-800\/60 {
  text-decoration-color: #065f4699;
}

.decoration-emerald-800\/70 {
  text-decoration-color: #065f46b3;
}

.decoration-emerald-800\/75 {
  text-decoration-color: #065f46bf;
}

.decoration-emerald-800\/80 {
  text-decoration-color: #065f46cc;
}

.decoration-emerald-800\/90 {
  text-decoration-color: #065f46e6;
}

.decoration-emerald-800\/95 {
  text-decoration-color: #065f46f2;
}

.decoration-emerald-900 {
  text-decoration-color: #064e3b;
}

.decoration-emerald-900\/0 {
  text-decoration-color: #064e3b00;
}

.decoration-emerald-900\/10 {
  text-decoration-color: #064e3b1a;
}

.decoration-emerald-900\/100 {
  text-decoration-color: #064e3b;
}

.decoration-emerald-900\/20 {
  text-decoration-color: #064e3b33;
}

.decoration-emerald-900\/25 {
  text-decoration-color: #064e3b40;
}

.decoration-emerald-900\/30 {
  text-decoration-color: #064e3b4d;
}

.decoration-emerald-900\/40 {
  text-decoration-color: #064e3b66;
}

.decoration-emerald-900\/5 {
  text-decoration-color: #064e3b0d;
}

.decoration-emerald-900\/50 {
  text-decoration-color: #064e3b80;
}

.decoration-emerald-900\/60 {
  text-decoration-color: #064e3b99;
}

.decoration-emerald-900\/70 {
  text-decoration-color: #064e3bb3;
}

.decoration-emerald-900\/75 {
  text-decoration-color: #064e3bbf;
}

.decoration-emerald-900\/80 {
  text-decoration-color: #064e3bcc;
}

.decoration-emerald-900\/90 {
  text-decoration-color: #064e3be6;
}

.decoration-emerald-900\/95 {
  text-decoration-color: #064e3bf2;
}

.decoration-emerald-950 {
  text-decoration-color: #022c22;
}

.decoration-emerald-950\/0 {
  text-decoration-color: #022c2200;
}

.decoration-emerald-950\/10 {
  text-decoration-color: #022c221a;
}

.decoration-emerald-950\/100 {
  text-decoration-color: #022c22;
}

.decoration-emerald-950\/20 {
  text-decoration-color: #022c2233;
}

.decoration-emerald-950\/25 {
  text-decoration-color: #022c2240;
}

.decoration-emerald-950\/30 {
  text-decoration-color: #022c224d;
}

.decoration-emerald-950\/40 {
  text-decoration-color: #022c2266;
}

.decoration-emerald-950\/5 {
  text-decoration-color: #022c220d;
}

.decoration-emerald-950\/50 {
  text-decoration-color: #022c2280;
}

.decoration-emerald-950\/60 {
  text-decoration-color: #022c2299;
}

.decoration-emerald-950\/70 {
  text-decoration-color: #022c22b3;
}

.decoration-emerald-950\/75 {
  text-decoration-color: #022c22bf;
}

.decoration-emerald-950\/80 {
  text-decoration-color: #022c22cc;
}

.decoration-emerald-950\/90 {
  text-decoration-color: #022c22e6;
}

.decoration-emerald-950\/95 {
  text-decoration-color: #022c22f2;
}

.decoration-lime-100 {
  text-decoration-color: #ecfccb;
}

.decoration-lime-100\/0 {
  text-decoration-color: #ecfccb00;
}

.decoration-lime-100\/10 {
  text-decoration-color: #ecfccb1a;
}

.decoration-lime-100\/100 {
  text-decoration-color: #ecfccb;
}

.decoration-lime-100\/20 {
  text-decoration-color: #ecfccb33;
}

.decoration-lime-100\/25 {
  text-decoration-color: #ecfccb40;
}

.decoration-lime-100\/30 {
  text-decoration-color: #ecfccb4d;
}

.decoration-lime-100\/40 {
  text-decoration-color: #ecfccb66;
}

.decoration-lime-100\/5 {
  text-decoration-color: #ecfccb0d;
}

.decoration-lime-100\/50 {
  text-decoration-color: #ecfccb80;
}

.decoration-lime-100\/60 {
  text-decoration-color: #ecfccb99;
}

.decoration-lime-100\/70 {
  text-decoration-color: #ecfccbb3;
}

.decoration-lime-100\/75 {
  text-decoration-color: #ecfccbbf;
}

.decoration-lime-100\/80 {
  text-decoration-color: #ecfccbcc;
}

.decoration-lime-100\/90 {
  text-decoration-color: #ecfccbe6;
}

.decoration-lime-100\/95 {
  text-decoration-color: #ecfccbf2;
}

.decoration-lime-200 {
  text-decoration-color: #d9f99d;
}

.decoration-lime-200\/0 {
  text-decoration-color: #d9f99d00;
}

.decoration-lime-200\/10 {
  text-decoration-color: #d9f99d1a;
}

.decoration-lime-200\/100 {
  text-decoration-color: #d9f99d;
}

.decoration-lime-200\/20 {
  text-decoration-color: #d9f99d33;
}

.decoration-lime-200\/25 {
  text-decoration-color: #d9f99d40;
}

.decoration-lime-200\/30 {
  text-decoration-color: #d9f99d4d;
}

.decoration-lime-200\/40 {
  text-decoration-color: #d9f99d66;
}

.decoration-lime-200\/5 {
  text-decoration-color: #d9f99d0d;
}

.decoration-lime-200\/50 {
  text-decoration-color: #d9f99d80;
}

.decoration-lime-200\/60 {
  text-decoration-color: #d9f99d99;
}

.decoration-lime-200\/70 {
  text-decoration-color: #d9f99db3;
}

.decoration-lime-200\/75 {
  text-decoration-color: #d9f99dbf;
}

.decoration-lime-200\/80 {
  text-decoration-color: #d9f99dcc;
}

.decoration-lime-200\/90 {
  text-decoration-color: #d9f99de6;
}

.decoration-lime-200\/95 {
  text-decoration-color: #d9f99df2;
}

.decoration-lime-300 {
  text-decoration-color: #bef264;
}

.decoration-lime-300\/0 {
  text-decoration-color: #bef26400;
}

.decoration-lime-300\/10 {
  text-decoration-color: #bef2641a;
}

.decoration-lime-300\/100 {
  text-decoration-color: #bef264;
}

.decoration-lime-300\/20 {
  text-decoration-color: #bef26433;
}

.decoration-lime-300\/25 {
  text-decoration-color: #bef26440;
}

.decoration-lime-300\/30 {
  text-decoration-color: #bef2644d;
}

.decoration-lime-300\/40 {
  text-decoration-color: #bef26466;
}

.decoration-lime-300\/5 {
  text-decoration-color: #bef2640d;
}

.decoration-lime-300\/50 {
  text-decoration-color: #bef26480;
}

.decoration-lime-300\/60 {
  text-decoration-color: #bef26499;
}

.decoration-lime-300\/70 {
  text-decoration-color: #bef264b3;
}

.decoration-lime-300\/75 {
  text-decoration-color: #bef264bf;
}

.decoration-lime-300\/80 {
  text-decoration-color: #bef264cc;
}

.decoration-lime-300\/90 {
  text-decoration-color: #bef264e6;
}

.decoration-lime-300\/95 {
  text-decoration-color: #bef264f2;
}

.decoration-lime-400 {
  text-decoration-color: #a3e635;
}

.decoration-lime-400\/0 {
  text-decoration-color: #a3e63500;
}

.decoration-lime-400\/10 {
  text-decoration-color: #a3e6351a;
}

.decoration-lime-400\/100 {
  text-decoration-color: #a3e635;
}

.decoration-lime-400\/20 {
  text-decoration-color: #a3e63533;
}

.decoration-lime-400\/25 {
  text-decoration-color: #a3e63540;
}

.decoration-lime-400\/30 {
  text-decoration-color: #a3e6354d;
}

.decoration-lime-400\/40 {
  text-decoration-color: #a3e63566;
}

.decoration-lime-400\/5 {
  text-decoration-color: #a3e6350d;
}

.decoration-lime-400\/50 {
  text-decoration-color: #a3e63580;
}

.decoration-lime-400\/60 {
  text-decoration-color: #a3e63599;
}

.decoration-lime-400\/70 {
  text-decoration-color: #a3e635b3;
}

.decoration-lime-400\/75 {
  text-decoration-color: #a3e635bf;
}

.decoration-lime-400\/80 {
  text-decoration-color: #a3e635cc;
}

.decoration-lime-400\/90 {
  text-decoration-color: #a3e635e6;
}

.decoration-lime-400\/95 {
  text-decoration-color: #a3e635f2;
}

.decoration-lime-50 {
  text-decoration-color: #f7fee7;
}

.decoration-lime-50\/0 {
  text-decoration-color: #f7fee700;
}

.decoration-lime-50\/10 {
  text-decoration-color: #f7fee71a;
}

.decoration-lime-50\/100 {
  text-decoration-color: #f7fee7;
}

.decoration-lime-50\/20 {
  text-decoration-color: #f7fee733;
}

.decoration-lime-50\/25 {
  text-decoration-color: #f7fee740;
}

.decoration-lime-50\/30 {
  text-decoration-color: #f7fee74d;
}

.decoration-lime-50\/40 {
  text-decoration-color: #f7fee766;
}

.decoration-lime-50\/5 {
  text-decoration-color: #f7fee70d;
}

.decoration-lime-50\/50 {
  text-decoration-color: #f7fee780;
}

.decoration-lime-50\/60 {
  text-decoration-color: #f7fee799;
}

.decoration-lime-50\/70 {
  text-decoration-color: #f7fee7b3;
}

.decoration-lime-50\/75 {
  text-decoration-color: #f7fee7bf;
}

.decoration-lime-50\/80 {
  text-decoration-color: #f7fee7cc;
}

.decoration-lime-50\/90 {
  text-decoration-color: #f7fee7e6;
}

.decoration-lime-50\/95 {
  text-decoration-color: #f7fee7f2;
}

.decoration-lime-500 {
  text-decoration-color: #84cc16;
}

.decoration-lime-500\/0 {
  text-decoration-color: #84cc1600;
}

.decoration-lime-500\/10 {
  text-decoration-color: #84cc161a;
}

.decoration-lime-500\/100 {
  text-decoration-color: #84cc16;
}

.decoration-lime-500\/20 {
  text-decoration-color: #84cc1633;
}

.decoration-lime-500\/25 {
  text-decoration-color: #84cc1640;
}

.decoration-lime-500\/30 {
  text-decoration-color: #84cc164d;
}

.decoration-lime-500\/40 {
  text-decoration-color: #84cc1666;
}

.decoration-lime-500\/5 {
  text-decoration-color: #84cc160d;
}

.decoration-lime-500\/50 {
  text-decoration-color: #84cc1680;
}

.decoration-lime-500\/60 {
  text-decoration-color: #84cc1699;
}

.decoration-lime-500\/70 {
  text-decoration-color: #84cc16b3;
}

.decoration-lime-500\/75 {
  text-decoration-color: #84cc16bf;
}

.decoration-lime-500\/80 {
  text-decoration-color: #84cc16cc;
}

.decoration-lime-500\/90 {
  text-decoration-color: #84cc16e6;
}

.decoration-lime-500\/95 {
  text-decoration-color: #84cc16f2;
}

.decoration-lime-600 {
  text-decoration-color: #65a30d;
}

.decoration-lime-600\/0 {
  text-decoration-color: #65a30d00;
}

.decoration-lime-600\/10 {
  text-decoration-color: #65a30d1a;
}

.decoration-lime-600\/100 {
  text-decoration-color: #65a30d;
}

.decoration-lime-600\/20 {
  text-decoration-color: #65a30d33;
}

.decoration-lime-600\/25 {
  text-decoration-color: #65a30d40;
}

.decoration-lime-600\/30 {
  text-decoration-color: #65a30d4d;
}

.decoration-lime-600\/40 {
  text-decoration-color: #65a30d66;
}

.decoration-lime-600\/5 {
  text-decoration-color: #65a30d0d;
}

.decoration-lime-600\/50 {
  text-decoration-color: #65a30d80;
}

.decoration-lime-600\/60 {
  text-decoration-color: #65a30d99;
}

.decoration-lime-600\/70 {
  text-decoration-color: #65a30db3;
}

.decoration-lime-600\/75 {
  text-decoration-color: #65a30dbf;
}

.decoration-lime-600\/80 {
  text-decoration-color: #65a30dcc;
}

.decoration-lime-600\/90 {
  text-decoration-color: #65a30de6;
}

.decoration-lime-600\/95 {
  text-decoration-color: #65a30df2;
}

.decoration-lime-700 {
  text-decoration-color: #4d7c0f;
}

.decoration-lime-700\/0 {
  text-decoration-color: #4d7c0f00;
}

.decoration-lime-700\/10 {
  text-decoration-color: #4d7c0f1a;
}

.decoration-lime-700\/100 {
  text-decoration-color: #4d7c0f;
}

.decoration-lime-700\/20 {
  text-decoration-color: #4d7c0f33;
}

.decoration-lime-700\/25 {
  text-decoration-color: #4d7c0f40;
}

.decoration-lime-700\/30 {
  text-decoration-color: #4d7c0f4d;
}

.decoration-lime-700\/40 {
  text-decoration-color: #4d7c0f66;
}

.decoration-lime-700\/5 {
  text-decoration-color: #4d7c0f0d;
}

.decoration-lime-700\/50 {
  text-decoration-color: #4d7c0f80;
}

.decoration-lime-700\/60 {
  text-decoration-color: #4d7c0f99;
}

.decoration-lime-700\/70 {
  text-decoration-color: #4d7c0fb3;
}

.decoration-lime-700\/75 {
  text-decoration-color: #4d7c0fbf;
}

.decoration-lime-700\/80 {
  text-decoration-color: #4d7c0fcc;
}

.decoration-lime-700\/90 {
  text-decoration-color: #4d7c0fe6;
}

.decoration-lime-700\/95 {
  text-decoration-color: #4d7c0ff2;
}

.decoration-lime-800 {
  text-decoration-color: #3f6212;
}

.decoration-lime-800\/0 {
  text-decoration-color: #3f621200;
}

.decoration-lime-800\/10 {
  text-decoration-color: #3f62121a;
}

.decoration-lime-800\/100 {
  text-decoration-color: #3f6212;
}

.decoration-lime-800\/20 {
  text-decoration-color: #3f621233;
}

.decoration-lime-800\/25 {
  text-decoration-color: #3f621240;
}

.decoration-lime-800\/30 {
  text-decoration-color: #3f62124d;
}

.decoration-lime-800\/40 {
  text-decoration-color: #3f621266;
}

.decoration-lime-800\/5 {
  text-decoration-color: #3f62120d;
}

.decoration-lime-800\/50 {
  text-decoration-color: #3f621280;
}

.decoration-lime-800\/60 {
  text-decoration-color: #3f621299;
}

.decoration-lime-800\/70 {
  text-decoration-color: #3f6212b3;
}

.decoration-lime-800\/75 {
  text-decoration-color: #3f6212bf;
}

.decoration-lime-800\/80 {
  text-decoration-color: #3f6212cc;
}

.decoration-lime-800\/90 {
  text-decoration-color: #3f6212e6;
}

.decoration-lime-800\/95 {
  text-decoration-color: #3f6212f2;
}

.decoration-lime-900 {
  text-decoration-color: #365314;
}

.decoration-lime-900\/0 {
  text-decoration-color: #36531400;
}

.decoration-lime-900\/10 {
  text-decoration-color: #3653141a;
}

.decoration-lime-900\/100 {
  text-decoration-color: #365314;
}

.decoration-lime-900\/20 {
  text-decoration-color: #36531433;
}

.decoration-lime-900\/25 {
  text-decoration-color: #36531440;
}

.decoration-lime-900\/30 {
  text-decoration-color: #3653144d;
}

.decoration-lime-900\/40 {
  text-decoration-color: #36531466;
}

.decoration-lime-900\/5 {
  text-decoration-color: #3653140d;
}

.decoration-lime-900\/50 {
  text-decoration-color: #36531480;
}

.decoration-lime-900\/60 {
  text-decoration-color: #36531499;
}

.decoration-lime-900\/70 {
  text-decoration-color: #365314b3;
}

.decoration-lime-900\/75 {
  text-decoration-color: #365314bf;
}

.decoration-lime-900\/80 {
  text-decoration-color: #365314cc;
}

.decoration-lime-900\/90 {
  text-decoration-color: #365314e6;
}

.decoration-lime-900\/95 {
  text-decoration-color: #365314f2;
}

.decoration-lime-950 {
  text-decoration-color: #1a2e05;
}

.decoration-lime-950\/0 {
  text-decoration-color: #1a2e0500;
}

.decoration-lime-950\/10 {
  text-decoration-color: #1a2e051a;
}

.decoration-lime-950\/100 {
  text-decoration-color: #1a2e05;
}

.decoration-lime-950\/20 {
  text-decoration-color: #1a2e0533;
}

.decoration-lime-950\/25 {
  text-decoration-color: #1a2e0540;
}

.decoration-lime-950\/30 {
  text-decoration-color: #1a2e054d;
}

.decoration-lime-950\/40 {
  text-decoration-color: #1a2e0566;
}

.decoration-lime-950\/5 {
  text-decoration-color: #1a2e050d;
}

.decoration-lime-950\/50 {
  text-decoration-color: #1a2e0580;
}

.decoration-lime-950\/60 {
  text-decoration-color: #1a2e0599;
}

.decoration-lime-950\/70 {
  text-decoration-color: #1a2e05b3;
}

.decoration-lime-950\/75 {
  text-decoration-color: #1a2e05bf;
}

.decoration-lime-950\/80 {
  text-decoration-color: #1a2e05cc;
}

.decoration-lime-950\/90 {
  text-decoration-color: #1a2e05e6;
}

.decoration-lime-950\/95 {
  text-decoration-color: #1a2e05f2;
}

.decoration-pink-100 {
  text-decoration-color: #fce7f3;
}

.decoration-pink-100\/0 {
  text-decoration-color: #fce7f300;
}

.decoration-pink-100\/10 {
  text-decoration-color: #fce7f31a;
}

.decoration-pink-100\/100 {
  text-decoration-color: #fce7f3;
}

.decoration-pink-100\/20 {
  text-decoration-color: #fce7f333;
}

.decoration-pink-100\/25 {
  text-decoration-color: #fce7f340;
}

.decoration-pink-100\/30 {
  text-decoration-color: #fce7f34d;
}

.decoration-pink-100\/40 {
  text-decoration-color: #fce7f366;
}

.decoration-pink-100\/5 {
  text-decoration-color: #fce7f30d;
}

.decoration-pink-100\/50 {
  text-decoration-color: #fce7f380;
}

.decoration-pink-100\/60 {
  text-decoration-color: #fce7f399;
}

.decoration-pink-100\/70 {
  text-decoration-color: #fce7f3b3;
}

.decoration-pink-100\/75 {
  text-decoration-color: #fce7f3bf;
}

.decoration-pink-100\/80 {
  text-decoration-color: #fce7f3cc;
}

.decoration-pink-100\/90 {
  text-decoration-color: #fce7f3e6;
}

.decoration-pink-100\/95 {
  text-decoration-color: #fce7f3f2;
}

.decoration-pink-200 {
  text-decoration-color: #fbcfe8;
}

.decoration-pink-200\/0 {
  text-decoration-color: #fbcfe800;
}

.decoration-pink-200\/10 {
  text-decoration-color: #fbcfe81a;
}

.decoration-pink-200\/100 {
  text-decoration-color: #fbcfe8;
}

.decoration-pink-200\/20 {
  text-decoration-color: #fbcfe833;
}

.decoration-pink-200\/25 {
  text-decoration-color: #fbcfe840;
}

.decoration-pink-200\/30 {
  text-decoration-color: #fbcfe84d;
}

.decoration-pink-200\/40 {
  text-decoration-color: #fbcfe866;
}

.decoration-pink-200\/5 {
  text-decoration-color: #fbcfe80d;
}

.decoration-pink-200\/50 {
  text-decoration-color: #fbcfe880;
}

.decoration-pink-200\/60 {
  text-decoration-color: #fbcfe899;
}

.decoration-pink-200\/70 {
  text-decoration-color: #fbcfe8b3;
}

.decoration-pink-200\/75 {
  text-decoration-color: #fbcfe8bf;
}

.decoration-pink-200\/80 {
  text-decoration-color: #fbcfe8cc;
}

.decoration-pink-200\/90 {
  text-decoration-color: #fbcfe8e6;
}

.decoration-pink-200\/95 {
  text-decoration-color: #fbcfe8f2;
}

.decoration-pink-300 {
  text-decoration-color: #f9a8d4;
}

.decoration-pink-300\/0 {
  text-decoration-color: #f9a8d400;
}

.decoration-pink-300\/10 {
  text-decoration-color: #f9a8d41a;
}

.decoration-pink-300\/100 {
  text-decoration-color: #f9a8d4;
}

.decoration-pink-300\/20 {
  text-decoration-color: #f9a8d433;
}

.decoration-pink-300\/25 {
  text-decoration-color: #f9a8d440;
}

.decoration-pink-300\/30 {
  text-decoration-color: #f9a8d44d;
}

.decoration-pink-300\/40 {
  text-decoration-color: #f9a8d466;
}

.decoration-pink-300\/5 {
  text-decoration-color: #f9a8d40d;
}

.decoration-pink-300\/50 {
  text-decoration-color: #f9a8d480;
}

.decoration-pink-300\/60 {
  text-decoration-color: #f9a8d499;
}

.decoration-pink-300\/70 {
  text-decoration-color: #f9a8d4b3;
}

.decoration-pink-300\/75 {
  text-decoration-color: #f9a8d4bf;
}

.decoration-pink-300\/80 {
  text-decoration-color: #f9a8d4cc;
}

.decoration-pink-300\/90 {
  text-decoration-color: #f9a8d4e6;
}

.decoration-pink-300\/95 {
  text-decoration-color: #f9a8d4f2;
}

.decoration-pink-400 {
  text-decoration-color: #f472b6;
}

.decoration-pink-400\/0 {
  text-decoration-color: #f472b600;
}

.decoration-pink-400\/10 {
  text-decoration-color: #f472b61a;
}

.decoration-pink-400\/100 {
  text-decoration-color: #f472b6;
}

.decoration-pink-400\/20 {
  text-decoration-color: #f472b633;
}

.decoration-pink-400\/25 {
  text-decoration-color: #f472b640;
}

.decoration-pink-400\/30 {
  text-decoration-color: #f472b64d;
}

.decoration-pink-400\/40 {
  text-decoration-color: #f472b666;
}

.decoration-pink-400\/5 {
  text-decoration-color: #f472b60d;
}

.decoration-pink-400\/50 {
  text-decoration-color: #f472b680;
}

.decoration-pink-400\/60 {
  text-decoration-color: #f472b699;
}

.decoration-pink-400\/70 {
  text-decoration-color: #f472b6b3;
}

.decoration-pink-400\/75 {
  text-decoration-color: #f472b6bf;
}

.decoration-pink-400\/80 {
  text-decoration-color: #f472b6cc;
}

.decoration-pink-400\/90 {
  text-decoration-color: #f472b6e6;
}

.decoration-pink-400\/95 {
  text-decoration-color: #f472b6f2;
}

.decoration-pink-50 {
  text-decoration-color: #fdf2f8;
}

.decoration-pink-50\/0 {
  text-decoration-color: #fdf2f800;
}

.decoration-pink-50\/10 {
  text-decoration-color: #fdf2f81a;
}

.decoration-pink-50\/100 {
  text-decoration-color: #fdf2f8;
}

.decoration-pink-50\/20 {
  text-decoration-color: #fdf2f833;
}

.decoration-pink-50\/25 {
  text-decoration-color: #fdf2f840;
}

.decoration-pink-50\/30 {
  text-decoration-color: #fdf2f84d;
}

.decoration-pink-50\/40 {
  text-decoration-color: #fdf2f866;
}

.decoration-pink-50\/5 {
  text-decoration-color: #fdf2f80d;
}

.decoration-pink-50\/50 {
  text-decoration-color: #fdf2f880;
}

.decoration-pink-50\/60 {
  text-decoration-color: #fdf2f899;
}

.decoration-pink-50\/70 {
  text-decoration-color: #fdf2f8b3;
}

.decoration-pink-50\/75 {
  text-decoration-color: #fdf2f8bf;
}

.decoration-pink-50\/80 {
  text-decoration-color: #fdf2f8cc;
}

.decoration-pink-50\/90 {
  text-decoration-color: #fdf2f8e6;
}

.decoration-pink-50\/95 {
  text-decoration-color: #fdf2f8f2;
}

.decoration-pink-500 {
  text-decoration-color: #ec4899;
}

.decoration-pink-500\/0 {
  text-decoration-color: #ec489900;
}

.decoration-pink-500\/10 {
  text-decoration-color: #ec48991a;
}

.decoration-pink-500\/100 {
  text-decoration-color: #ec4899;
}

.decoration-pink-500\/20 {
  text-decoration-color: #ec489933;
}

.decoration-pink-500\/25 {
  text-decoration-color: #ec489940;
}

.decoration-pink-500\/30 {
  text-decoration-color: #ec48994d;
}

.decoration-pink-500\/40 {
  text-decoration-color: #ec489966;
}

.decoration-pink-500\/5 {
  text-decoration-color: #ec48990d;
}

.decoration-pink-500\/50 {
  text-decoration-color: #ec489980;
}

.decoration-pink-500\/60 {
  text-decoration-color: #ec489999;
}

.decoration-pink-500\/70 {
  text-decoration-color: #ec4899b3;
}

.decoration-pink-500\/75 {
  text-decoration-color: #ec4899bf;
}

.decoration-pink-500\/80 {
  text-decoration-color: #ec4899cc;
}

.decoration-pink-500\/90 {
  text-decoration-color: #ec4899e6;
}

.decoration-pink-500\/95 {
  text-decoration-color: #ec4899f2;
}

.decoration-pink-600 {
  text-decoration-color: #db2777;
}

.decoration-pink-600\/0 {
  text-decoration-color: #db277700;
}

.decoration-pink-600\/10 {
  text-decoration-color: #db27771a;
}

.decoration-pink-600\/100 {
  text-decoration-color: #db2777;
}

.decoration-pink-600\/20 {
  text-decoration-color: #db277733;
}

.decoration-pink-600\/25 {
  text-decoration-color: #db277740;
}

.decoration-pink-600\/30 {
  text-decoration-color: #db27774d;
}

.decoration-pink-600\/40 {
  text-decoration-color: #db277766;
}

.decoration-pink-600\/5 {
  text-decoration-color: #db27770d;
}

.decoration-pink-600\/50 {
  text-decoration-color: #db277780;
}

.decoration-pink-600\/60 {
  text-decoration-color: #db277799;
}

.decoration-pink-600\/70 {
  text-decoration-color: #db2777b3;
}

.decoration-pink-600\/75 {
  text-decoration-color: #db2777bf;
}

.decoration-pink-600\/80 {
  text-decoration-color: #db2777cc;
}

.decoration-pink-600\/90 {
  text-decoration-color: #db2777e6;
}

.decoration-pink-600\/95 {
  text-decoration-color: #db2777f2;
}

.decoration-pink-700 {
  text-decoration-color: #be185d;
}

.decoration-pink-700\/0 {
  text-decoration-color: #be185d00;
}

.decoration-pink-700\/10 {
  text-decoration-color: #be185d1a;
}

.decoration-pink-700\/100 {
  text-decoration-color: #be185d;
}

.decoration-pink-700\/20 {
  text-decoration-color: #be185d33;
}

.decoration-pink-700\/25 {
  text-decoration-color: #be185d40;
}

.decoration-pink-700\/30 {
  text-decoration-color: #be185d4d;
}

.decoration-pink-700\/40 {
  text-decoration-color: #be185d66;
}

.decoration-pink-700\/5 {
  text-decoration-color: #be185d0d;
}

.decoration-pink-700\/50 {
  text-decoration-color: #be185d80;
}

.decoration-pink-700\/60 {
  text-decoration-color: #be185d99;
}

.decoration-pink-700\/70 {
  text-decoration-color: #be185db3;
}

.decoration-pink-700\/75 {
  text-decoration-color: #be185dbf;
}

.decoration-pink-700\/80 {
  text-decoration-color: #be185dcc;
}

.decoration-pink-700\/90 {
  text-decoration-color: #be185de6;
}

.decoration-pink-700\/95 {
  text-decoration-color: #be185df2;
}

.decoration-pink-800 {
  text-decoration-color: #9d174d;
}

.decoration-pink-800\/0 {
  text-decoration-color: #9d174d00;
}

.decoration-pink-800\/10 {
  text-decoration-color: #9d174d1a;
}

.decoration-pink-800\/100 {
  text-decoration-color: #9d174d;
}

.decoration-pink-800\/20 {
  text-decoration-color: #9d174d33;
}

.decoration-pink-800\/25 {
  text-decoration-color: #9d174d40;
}

.decoration-pink-800\/30 {
  text-decoration-color: #9d174d4d;
}

.decoration-pink-800\/40 {
  text-decoration-color: #9d174d66;
}

.decoration-pink-800\/5 {
  text-decoration-color: #9d174d0d;
}

.decoration-pink-800\/50 {
  text-decoration-color: #9d174d80;
}

.decoration-pink-800\/60 {
  text-decoration-color: #9d174d99;
}

.decoration-pink-800\/70 {
  text-decoration-color: #9d174db3;
}

.decoration-pink-800\/75 {
  text-decoration-color: #9d174dbf;
}

.decoration-pink-800\/80 {
  text-decoration-color: #9d174dcc;
}

.decoration-pink-800\/90 {
  text-decoration-color: #9d174de6;
}

.decoration-pink-800\/95 {
  text-decoration-color: #9d174df2;
}

.decoration-pink-900 {
  text-decoration-color: #831843;
}

.decoration-pink-900\/0 {
  text-decoration-color: #83184300;
}

.decoration-pink-900\/10 {
  text-decoration-color: #8318431a;
}

.decoration-pink-900\/100 {
  text-decoration-color: #831843;
}

.decoration-pink-900\/20 {
  text-decoration-color: #83184333;
}

.decoration-pink-900\/25 {
  text-decoration-color: #83184340;
}

.decoration-pink-900\/30 {
  text-decoration-color: #8318434d;
}

.decoration-pink-900\/40 {
  text-decoration-color: #83184366;
}

.decoration-pink-900\/5 {
  text-decoration-color: #8318430d;
}

.decoration-pink-900\/50 {
  text-decoration-color: #83184380;
}

.decoration-pink-900\/60 {
  text-decoration-color: #83184399;
}

.decoration-pink-900\/70 {
  text-decoration-color: #831843b3;
}

.decoration-pink-900\/75 {
  text-decoration-color: #831843bf;
}

.decoration-pink-900\/80 {
  text-decoration-color: #831843cc;
}

.decoration-pink-900\/90 {
  text-decoration-color: #831843e6;
}

.decoration-pink-900\/95 {
  text-decoration-color: #831843f2;
}

.decoration-pink-950 {
  text-decoration-color: #500724;
}

.decoration-pink-950\/0 {
  text-decoration-color: #50072400;
}

.decoration-pink-950\/10 {
  text-decoration-color: #5007241a;
}

.decoration-pink-950\/100 {
  text-decoration-color: #500724;
}

.decoration-pink-950\/20 {
  text-decoration-color: #50072433;
}

.decoration-pink-950\/25 {
  text-decoration-color: #50072440;
}

.decoration-pink-950\/30 {
  text-decoration-color: #5007244d;
}

.decoration-pink-950\/40 {
  text-decoration-color: #50072466;
}

.decoration-pink-950\/5 {
  text-decoration-color: #5007240d;
}

.decoration-pink-950\/50 {
  text-decoration-color: #50072480;
}

.decoration-pink-950\/60 {
  text-decoration-color: #50072499;
}

.decoration-pink-950\/70 {
  text-decoration-color: #500724b3;
}

.decoration-pink-950\/75 {
  text-decoration-color: #500724bf;
}

.decoration-pink-950\/80 {
  text-decoration-color: #500724cc;
}

.decoration-pink-950\/90 {
  text-decoration-color: #500724e6;
}

.decoration-pink-950\/95 {
  text-decoration-color: #500724f2;
}

.decoration-primary {
  -webkit-text-decoration-color: hsl(var(--primary) / 1);
  text-decoration-color: hsl(var(--primary) / 1);
}

.decoration-primary\/0 {
  -webkit-text-decoration-color: hsl(var(--primary) / 0);
  text-decoration-color: hsl(var(--primary) / 0);
}

.decoration-primary\/10 {
  -webkit-text-decoration-color: hsl(var(--primary) / .1);
  text-decoration-color: hsl(var(--primary) / .1);
}

.decoration-primary\/100 {
  -webkit-text-decoration-color: hsl(var(--primary) / 1);
  text-decoration-color: hsl(var(--primary) / 1);
}

.decoration-primary\/20 {
  -webkit-text-decoration-color: hsl(var(--primary) / .2);
  text-decoration-color: hsl(var(--primary) / .2);
}

.decoration-primary\/25 {
  -webkit-text-decoration-color: hsl(var(--primary) / .25);
  text-decoration-color: hsl(var(--primary) / .25);
}

.decoration-primary\/30 {
  -webkit-text-decoration-color: hsl(var(--primary) / .3);
  text-decoration-color: hsl(var(--primary) / .3);
}

.decoration-primary\/40 {
  -webkit-text-decoration-color: hsl(var(--primary) / .4);
  text-decoration-color: hsl(var(--primary) / .4);
}

.decoration-primary\/5 {
  -webkit-text-decoration-color: hsl(var(--primary) / .05);
  text-decoration-color: hsl(var(--primary) / .05);
}

.decoration-primary\/50 {
  -webkit-text-decoration-color: hsl(var(--primary) / .5);
  text-decoration-color: hsl(var(--primary) / .5);
}

.decoration-primary\/60 {
  -webkit-text-decoration-color: hsl(var(--primary) / .6);
  text-decoration-color: hsl(var(--primary) / .6);
}

.decoration-primary\/70 {
  -webkit-text-decoration-color: hsl(var(--primary) / .7);
  text-decoration-color: hsl(var(--primary) / .7);
}

.decoration-primary\/75 {
  -webkit-text-decoration-color: hsl(var(--primary) / .75);
  text-decoration-color: hsl(var(--primary) / .75);
}

.decoration-primary\/80 {
  -webkit-text-decoration-color: hsl(var(--primary) / .8);
  text-decoration-color: hsl(var(--primary) / .8);
}

.decoration-primary\/90 {
  -webkit-text-decoration-color: hsl(var(--primary) / .9);
  text-decoration-color: hsl(var(--primary) / .9);
}

.decoration-primary\/95 {
  -webkit-text-decoration-color: hsl(var(--primary) / .95);
  text-decoration-color: hsl(var(--primary) / .95);
}

.decoration-purple-100 {
  text-decoration-color: #f3e8ff;
}

.decoration-purple-100\/0 {
  text-decoration-color: #f3e8ff00;
}

.decoration-purple-100\/10 {
  text-decoration-color: #f3e8ff1a;
}

.decoration-purple-100\/100 {
  text-decoration-color: #f3e8ff;
}

.decoration-purple-100\/20 {
  text-decoration-color: #f3e8ff33;
}

.decoration-purple-100\/25 {
  text-decoration-color: #f3e8ff40;
}

.decoration-purple-100\/30 {
  text-decoration-color: #f3e8ff4d;
}

.decoration-purple-100\/40 {
  text-decoration-color: #f3e8ff66;
}

.decoration-purple-100\/5 {
  text-decoration-color: #f3e8ff0d;
}

.decoration-purple-100\/50 {
  text-decoration-color: #f3e8ff80;
}

.decoration-purple-100\/60 {
  text-decoration-color: #f3e8ff99;
}

.decoration-purple-100\/70 {
  text-decoration-color: #f3e8ffb3;
}

.decoration-purple-100\/75 {
  text-decoration-color: #f3e8ffbf;
}

.decoration-purple-100\/80 {
  text-decoration-color: #f3e8ffcc;
}

.decoration-purple-100\/90 {
  text-decoration-color: #f3e8ffe6;
}

.decoration-purple-100\/95 {
  text-decoration-color: #f3e8fff2;
}

.decoration-purple-200 {
  text-decoration-color: #e9d5ff;
}

.decoration-purple-200\/0 {
  text-decoration-color: #e9d5ff00;
}

.decoration-purple-200\/10 {
  text-decoration-color: #e9d5ff1a;
}

.decoration-purple-200\/100 {
  text-decoration-color: #e9d5ff;
}

.decoration-purple-200\/20 {
  text-decoration-color: #e9d5ff33;
}

.decoration-purple-200\/25 {
  text-decoration-color: #e9d5ff40;
}

.decoration-purple-200\/30 {
  text-decoration-color: #e9d5ff4d;
}

.decoration-purple-200\/40 {
  text-decoration-color: #e9d5ff66;
}

.decoration-purple-200\/5 {
  text-decoration-color: #e9d5ff0d;
}

.decoration-purple-200\/50 {
  text-decoration-color: #e9d5ff80;
}

.decoration-purple-200\/60 {
  text-decoration-color: #e9d5ff99;
}

.decoration-purple-200\/70 {
  text-decoration-color: #e9d5ffb3;
}

.decoration-purple-200\/75 {
  text-decoration-color: #e9d5ffbf;
}

.decoration-purple-200\/80 {
  text-decoration-color: #e9d5ffcc;
}

.decoration-purple-200\/90 {
  text-decoration-color: #e9d5ffe6;
}

.decoration-purple-200\/95 {
  text-decoration-color: #e9d5fff2;
}

.decoration-purple-300 {
  text-decoration-color: #d8b4fe;
}

.decoration-purple-300\/0 {
  text-decoration-color: #d8b4fe00;
}

.decoration-purple-300\/10 {
  text-decoration-color: #d8b4fe1a;
}

.decoration-purple-300\/100 {
  text-decoration-color: #d8b4fe;
}

.decoration-purple-300\/20 {
  text-decoration-color: #d8b4fe33;
}

.decoration-purple-300\/25 {
  text-decoration-color: #d8b4fe40;
}

.decoration-purple-300\/30 {
  text-decoration-color: #d8b4fe4d;
}

.decoration-purple-300\/40 {
  text-decoration-color: #d8b4fe66;
}

.decoration-purple-300\/5 {
  text-decoration-color: #d8b4fe0d;
}

.decoration-purple-300\/50 {
  text-decoration-color: #d8b4fe80;
}

.decoration-purple-300\/60 {
  text-decoration-color: #d8b4fe99;
}

.decoration-purple-300\/70 {
  text-decoration-color: #d8b4feb3;
}

.decoration-purple-300\/75 {
  text-decoration-color: #d8b4febf;
}

.decoration-purple-300\/80 {
  text-decoration-color: #d8b4fecc;
}

.decoration-purple-300\/90 {
  text-decoration-color: #d8b4fee6;
}

.decoration-purple-300\/95 {
  text-decoration-color: #d8b4fef2;
}

.decoration-purple-400 {
  text-decoration-color: #c084fc;
}

.decoration-purple-400\/0 {
  text-decoration-color: #c084fc00;
}

.decoration-purple-400\/10 {
  text-decoration-color: #c084fc1a;
}

.decoration-purple-400\/100 {
  text-decoration-color: #c084fc;
}

.decoration-purple-400\/20 {
  text-decoration-color: #c084fc33;
}

.decoration-purple-400\/25 {
  text-decoration-color: #c084fc40;
}

.decoration-purple-400\/30 {
  text-decoration-color: #c084fc4d;
}

.decoration-purple-400\/40 {
  text-decoration-color: #c084fc66;
}

.decoration-purple-400\/5 {
  text-decoration-color: #c084fc0d;
}

.decoration-purple-400\/50 {
  text-decoration-color: #c084fc80;
}

.decoration-purple-400\/60 {
  text-decoration-color: #c084fc99;
}

.decoration-purple-400\/70 {
  text-decoration-color: #c084fcb3;
}

.decoration-purple-400\/75 {
  text-decoration-color: #c084fcbf;
}

.decoration-purple-400\/80 {
  text-decoration-color: #c084fccc;
}

.decoration-purple-400\/90 {
  text-decoration-color: #c084fce6;
}

.decoration-purple-400\/95 {
  text-decoration-color: #c084fcf2;
}

.decoration-purple-50 {
  text-decoration-color: #faf5ff;
}

.decoration-purple-50\/0 {
  text-decoration-color: #faf5ff00;
}

.decoration-purple-50\/10 {
  text-decoration-color: #faf5ff1a;
}

.decoration-purple-50\/100 {
  text-decoration-color: #faf5ff;
}

.decoration-purple-50\/20 {
  text-decoration-color: #faf5ff33;
}

.decoration-purple-50\/25 {
  text-decoration-color: #faf5ff40;
}

.decoration-purple-50\/30 {
  text-decoration-color: #faf5ff4d;
}

.decoration-purple-50\/40 {
  text-decoration-color: #faf5ff66;
}

.decoration-purple-50\/5 {
  text-decoration-color: #faf5ff0d;
}

.decoration-purple-50\/50 {
  text-decoration-color: #faf5ff80;
}

.decoration-purple-50\/60 {
  text-decoration-color: #faf5ff99;
}

.decoration-purple-50\/70 {
  text-decoration-color: #faf5ffb3;
}

.decoration-purple-50\/75 {
  text-decoration-color: #faf5ffbf;
}

.decoration-purple-50\/80 {
  text-decoration-color: #faf5ffcc;
}

.decoration-purple-50\/90 {
  text-decoration-color: #faf5ffe6;
}

.decoration-purple-50\/95 {
  text-decoration-color: #faf5fff2;
}

.decoration-purple-500 {
  text-decoration-color: #a855f7;
}

.decoration-purple-500\/0 {
  text-decoration-color: #a855f700;
}

.decoration-purple-500\/10 {
  text-decoration-color: #a855f71a;
}

.decoration-purple-500\/100 {
  text-decoration-color: #a855f7;
}

.decoration-purple-500\/20 {
  text-decoration-color: #a855f733;
}

.decoration-purple-500\/25 {
  text-decoration-color: #a855f740;
}

.decoration-purple-500\/30 {
  text-decoration-color: #a855f74d;
}

.decoration-purple-500\/40 {
  text-decoration-color: #a855f766;
}

.decoration-purple-500\/5 {
  text-decoration-color: #a855f70d;
}

.decoration-purple-500\/50 {
  text-decoration-color: #a855f780;
}

.decoration-purple-500\/60 {
  text-decoration-color: #a855f799;
}

.decoration-purple-500\/70 {
  text-decoration-color: #a855f7b3;
}

.decoration-purple-500\/75 {
  text-decoration-color: #a855f7bf;
}

.decoration-purple-500\/80 {
  text-decoration-color: #a855f7cc;
}

.decoration-purple-500\/90 {
  text-decoration-color: #a855f7e6;
}

.decoration-purple-500\/95 {
  text-decoration-color: #a855f7f2;
}

.decoration-purple-600 {
  text-decoration-color: #9333ea;
}

.decoration-purple-600\/0 {
  text-decoration-color: #9333ea00;
}

.decoration-purple-600\/10 {
  text-decoration-color: #9333ea1a;
}

.decoration-purple-600\/100 {
  text-decoration-color: #9333ea;
}

.decoration-purple-600\/20 {
  text-decoration-color: #9333ea33;
}

.decoration-purple-600\/25 {
  text-decoration-color: #9333ea40;
}

.decoration-purple-600\/30 {
  text-decoration-color: #9333ea4d;
}

.decoration-purple-600\/40 {
  text-decoration-color: #9333ea66;
}

.decoration-purple-600\/5 {
  text-decoration-color: #9333ea0d;
}

.decoration-purple-600\/50 {
  text-decoration-color: #9333ea80;
}

.decoration-purple-600\/60 {
  text-decoration-color: #9333ea99;
}

.decoration-purple-600\/70 {
  text-decoration-color: #9333eab3;
}

.decoration-purple-600\/75 {
  text-decoration-color: #9333eabf;
}

.decoration-purple-600\/80 {
  text-decoration-color: #9333eacc;
}

.decoration-purple-600\/90 {
  text-decoration-color: #9333eae6;
}

.decoration-purple-600\/95 {
  text-decoration-color: #9333eaf2;
}

.decoration-purple-700 {
  text-decoration-color: #7e22ce;
}

.decoration-purple-700\/0 {
  text-decoration-color: #7e22ce00;
}

.decoration-purple-700\/10 {
  text-decoration-color: #7e22ce1a;
}

.decoration-purple-700\/100 {
  text-decoration-color: #7e22ce;
}

.decoration-purple-700\/20 {
  text-decoration-color: #7e22ce33;
}

.decoration-purple-700\/25 {
  text-decoration-color: #7e22ce40;
}

.decoration-purple-700\/30 {
  text-decoration-color: #7e22ce4d;
}

.decoration-purple-700\/40 {
  text-decoration-color: #7e22ce66;
}

.decoration-purple-700\/5 {
  text-decoration-color: #7e22ce0d;
}

.decoration-purple-700\/50 {
  text-decoration-color: #7e22ce80;
}

.decoration-purple-700\/60 {
  text-decoration-color: #7e22ce99;
}

.decoration-purple-700\/70 {
  text-decoration-color: #7e22ceb3;
}

.decoration-purple-700\/75 {
  text-decoration-color: #7e22cebf;
}

.decoration-purple-700\/80 {
  text-decoration-color: #7e22cecc;
}

.decoration-purple-700\/90 {
  text-decoration-color: #7e22cee6;
}

.decoration-purple-700\/95 {
  text-decoration-color: #7e22cef2;
}

.decoration-purple-800 {
  text-decoration-color: #6b21a8;
}

.decoration-purple-800\/0 {
  text-decoration-color: #6b21a800;
}

.decoration-purple-800\/10 {
  text-decoration-color: #6b21a81a;
}

.decoration-purple-800\/100 {
  text-decoration-color: #6b21a8;
}

.decoration-purple-800\/20 {
  text-decoration-color: #6b21a833;
}

.decoration-purple-800\/25 {
  text-decoration-color: #6b21a840;
}

.decoration-purple-800\/30 {
  text-decoration-color: #6b21a84d;
}

.decoration-purple-800\/40 {
  text-decoration-color: #6b21a866;
}

.decoration-purple-800\/5 {
  text-decoration-color: #6b21a80d;
}

.decoration-purple-800\/50 {
  text-decoration-color: #6b21a880;
}

.decoration-purple-800\/60 {
  text-decoration-color: #6b21a899;
}

.decoration-purple-800\/70 {
  text-decoration-color: #6b21a8b3;
}

.decoration-purple-800\/75 {
  text-decoration-color: #6b21a8bf;
}

.decoration-purple-800\/80 {
  text-decoration-color: #6b21a8cc;
}

.decoration-purple-800\/90 {
  text-decoration-color: #6b21a8e6;
}

.decoration-purple-800\/95 {
  text-decoration-color: #6b21a8f2;
}

.decoration-purple-900 {
  text-decoration-color: #581c87;
}

.decoration-purple-900\/0 {
  text-decoration-color: #581c8700;
}

.decoration-purple-900\/10 {
  text-decoration-color: #581c871a;
}

.decoration-purple-900\/100 {
  text-decoration-color: #581c87;
}

.decoration-purple-900\/20 {
  text-decoration-color: #581c8733;
}

.decoration-purple-900\/25 {
  text-decoration-color: #581c8740;
}

.decoration-purple-900\/30 {
  text-decoration-color: #581c874d;
}

.decoration-purple-900\/40 {
  text-decoration-color: #581c8766;
}

.decoration-purple-900\/5 {
  text-decoration-color: #581c870d;
}

.decoration-purple-900\/50 {
  text-decoration-color: #581c8780;
}

.decoration-purple-900\/60 {
  text-decoration-color: #581c8799;
}

.decoration-purple-900\/70 {
  text-decoration-color: #581c87b3;
}

.decoration-purple-900\/75 {
  text-decoration-color: #581c87bf;
}

.decoration-purple-900\/80 {
  text-decoration-color: #581c87cc;
}

.decoration-purple-900\/90 {
  text-decoration-color: #581c87e6;
}

.decoration-purple-900\/95 {
  text-decoration-color: #581c87f2;
}

.decoration-purple-950 {
  text-decoration-color: #3b0764;
}

.decoration-purple-950\/0 {
  text-decoration-color: #3b076400;
}

.decoration-purple-950\/10 {
  text-decoration-color: #3b07641a;
}

.decoration-purple-950\/100 {
  text-decoration-color: #3b0764;
}

.decoration-purple-950\/20 {
  text-decoration-color: #3b076433;
}

.decoration-purple-950\/25 {
  text-decoration-color: #3b076440;
}

.decoration-purple-950\/30 {
  text-decoration-color: #3b07644d;
}

.decoration-purple-950\/40 {
  text-decoration-color: #3b076466;
}

.decoration-purple-950\/5 {
  text-decoration-color: #3b07640d;
}

.decoration-purple-950\/50 {
  text-decoration-color: #3b076480;
}

.decoration-purple-950\/60 {
  text-decoration-color: #3b076499;
}

.decoration-purple-950\/70 {
  text-decoration-color: #3b0764b3;
}

.decoration-purple-950\/75 {
  text-decoration-color: #3b0764bf;
}

.decoration-purple-950\/80 {
  text-decoration-color: #3b0764cc;
}

.decoration-purple-950\/90 {
  text-decoration-color: #3b0764e6;
}

.decoration-purple-950\/95 {
  text-decoration-color: #3b0764f2;
}

.decoration-transparent, .decoration-transparent\/0 {
  text-decoration-color: #0000;
}

.decoration-transparent\/10 {
  text-decoration-color: #0000001a;
}

.decoration-transparent\/100 {
  text-decoration-color: #000;
}

.decoration-transparent\/20 {
  text-decoration-color: #0003;
}

.decoration-transparent\/25 {
  text-decoration-color: #00000040;
}

.decoration-transparent\/30 {
  text-decoration-color: #0000004d;
}

.decoration-transparent\/40 {
  text-decoration-color: #0006;
}

.decoration-transparent\/5 {
  text-decoration-color: #0000000d;
}

.decoration-transparent\/50 {
  text-decoration-color: #00000080;
}

.decoration-transparent\/60 {
  text-decoration-color: #0009;
}

.decoration-transparent\/70 {
  text-decoration-color: #000000b3;
}

.decoration-transparent\/75 {
  text-decoration-color: #000000bf;
}

.decoration-transparent\/80 {
  text-decoration-color: #000c;
}

.decoration-transparent\/90 {
  text-decoration-color: #000000e6;
}

.decoration-transparent\/95 {
  text-decoration-color: #000000f2;
}

.decoration-from-font {
  text-decoration-thickness: from-font;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.placeholder-amber-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(254 243 199 / var(--tw-placeholder-opacity));
}

.placeholder-amber-100\/0::placeholder {
  color: #fef3c700;
}

.placeholder-amber-100\/10::placeholder {
  color: #fef3c71a;
}

.placeholder-amber-100\/100::placeholder {
  color: #fef3c7;
}

.placeholder-amber-100\/20::placeholder {
  color: #fef3c733;
}

.placeholder-amber-100\/25::placeholder {
  color: #fef3c740;
}

.placeholder-amber-100\/30::placeholder {
  color: #fef3c74d;
}

.placeholder-amber-100\/40::placeholder {
  color: #fef3c766;
}

.placeholder-amber-100\/5::placeholder {
  color: #fef3c70d;
}

.placeholder-amber-100\/50::placeholder {
  color: #fef3c780;
}

.placeholder-amber-100\/60::placeholder {
  color: #fef3c799;
}

.placeholder-amber-100\/70::placeholder {
  color: #fef3c7b3;
}

.placeholder-amber-100\/75::placeholder {
  color: #fef3c7bf;
}

.placeholder-amber-100\/80::placeholder {
  color: #fef3c7cc;
}

.placeholder-amber-100\/90::placeholder {
  color: #fef3c7e6;
}

.placeholder-amber-100\/95::placeholder {
  color: #fef3c7f2;
}

.placeholder-amber-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(253 230 138 / var(--tw-placeholder-opacity));
}

.placeholder-amber-200\/0::placeholder {
  color: #fde68a00;
}

.placeholder-amber-200\/10::placeholder {
  color: #fde68a1a;
}

.placeholder-amber-200\/100::placeholder {
  color: #fde68a;
}

.placeholder-amber-200\/20::placeholder {
  color: #fde68a33;
}

.placeholder-amber-200\/25::placeholder {
  color: #fde68a40;
}

.placeholder-amber-200\/30::placeholder {
  color: #fde68a4d;
}

.placeholder-amber-200\/40::placeholder {
  color: #fde68a66;
}

.placeholder-amber-200\/5::placeholder {
  color: #fde68a0d;
}

.placeholder-amber-200\/50::placeholder {
  color: #fde68a80;
}

.placeholder-amber-200\/60::placeholder {
  color: #fde68a99;
}

.placeholder-amber-200\/70::placeholder {
  color: #fde68ab3;
}

.placeholder-amber-200\/75::placeholder {
  color: #fde68abf;
}

.placeholder-amber-200\/80::placeholder {
  color: #fde68acc;
}

.placeholder-amber-200\/90::placeholder {
  color: #fde68ae6;
}

.placeholder-amber-200\/95::placeholder {
  color: #fde68af2;
}

.placeholder-amber-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(252 211 77 / var(--tw-placeholder-opacity));
}

.placeholder-amber-300\/0::placeholder {
  color: #fcd34d00;
}

.placeholder-amber-300\/10::placeholder {
  color: #fcd34d1a;
}

.placeholder-amber-300\/100::placeholder {
  color: #fcd34d;
}

.placeholder-amber-300\/20::placeholder {
  color: #fcd34d33;
}

.placeholder-amber-300\/25::placeholder {
  color: #fcd34d40;
}

.placeholder-amber-300\/30::placeholder {
  color: #fcd34d4d;
}

.placeholder-amber-300\/40::placeholder {
  color: #fcd34d66;
}

.placeholder-amber-300\/5::placeholder {
  color: #fcd34d0d;
}

.placeholder-amber-300\/50::placeholder {
  color: #fcd34d80;
}

.placeholder-amber-300\/60::placeholder {
  color: #fcd34d99;
}

.placeholder-amber-300\/70::placeholder {
  color: #fcd34db3;
}

.placeholder-amber-300\/75::placeholder {
  color: #fcd34dbf;
}

.placeholder-amber-300\/80::placeholder {
  color: #fcd34dcc;
}

.placeholder-amber-300\/90::placeholder {
  color: #fcd34de6;
}

.placeholder-amber-300\/95::placeholder {
  color: #fcd34df2;
}

.placeholder-amber-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(251 191 36 / var(--tw-placeholder-opacity));
}

.placeholder-amber-400\/0::placeholder {
  color: #fbbf2400;
}

.placeholder-amber-400\/10::placeholder {
  color: #fbbf241a;
}

.placeholder-amber-400\/100::placeholder {
  color: #fbbf24;
}

.placeholder-amber-400\/20::placeholder {
  color: #fbbf2433;
}

.placeholder-amber-400\/25::placeholder {
  color: #fbbf2440;
}

.placeholder-amber-400\/30::placeholder {
  color: #fbbf244d;
}

.placeholder-amber-400\/40::placeholder {
  color: #fbbf2466;
}

.placeholder-amber-400\/5::placeholder {
  color: #fbbf240d;
}

.placeholder-amber-400\/50::placeholder {
  color: #fbbf2480;
}

.placeholder-amber-400\/60::placeholder {
  color: #fbbf2499;
}

.placeholder-amber-400\/70::placeholder {
  color: #fbbf24b3;
}

.placeholder-amber-400\/75::placeholder {
  color: #fbbf24bf;
}

.placeholder-amber-400\/80::placeholder {
  color: #fbbf24cc;
}

.placeholder-amber-400\/90::placeholder {
  color: #fbbf24e6;
}

.placeholder-amber-400\/95::placeholder {
  color: #fbbf24f2;
}

.placeholder-amber-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 251 235 / var(--tw-placeholder-opacity));
}

.placeholder-amber-50\/0::placeholder {
  color: #fffbeb00;
}

.placeholder-amber-50\/10::placeholder {
  color: #fffbeb1a;
}

.placeholder-amber-50\/100::placeholder {
  color: #fffbeb;
}

.placeholder-amber-50\/20::placeholder {
  color: #fffbeb33;
}

.placeholder-amber-50\/25::placeholder {
  color: #fffbeb40;
}

.placeholder-amber-50\/30::placeholder {
  color: #fffbeb4d;
}

.placeholder-amber-50\/40::placeholder {
  color: #fffbeb66;
}

.placeholder-amber-50\/5::placeholder {
  color: #fffbeb0d;
}

.placeholder-amber-50\/50::placeholder {
  color: #fffbeb80;
}

.placeholder-amber-50\/60::placeholder {
  color: #fffbeb99;
}

.placeholder-amber-50\/70::placeholder {
  color: #fffbebb3;
}

.placeholder-amber-50\/75::placeholder {
  color: #fffbebbf;
}

.placeholder-amber-50\/80::placeholder {
  color: #fffbebcc;
}

.placeholder-amber-50\/90::placeholder {
  color: #fffbebe6;
}

.placeholder-amber-50\/95::placeholder {
  color: #fffbebf2;
}

.placeholder-amber-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(245 158 11 / var(--tw-placeholder-opacity));
}

.placeholder-amber-500\/0::placeholder {
  color: #f59e0b00;
}

.placeholder-amber-500\/10::placeholder {
  color: #f59e0b1a;
}

.placeholder-amber-500\/100::placeholder {
  color: #f59e0b;
}

.placeholder-amber-500\/20::placeholder {
  color: #f59e0b33;
}

.placeholder-amber-500\/25::placeholder {
  color: #f59e0b40;
}

.placeholder-amber-500\/30::placeholder {
  color: #f59e0b4d;
}

.placeholder-amber-500\/40::placeholder {
  color: #f59e0b66;
}

.placeholder-amber-500\/5::placeholder {
  color: #f59e0b0d;
}

.placeholder-amber-500\/50::placeholder {
  color: #f59e0b80;
}

.placeholder-amber-500\/60::placeholder {
  color: #f59e0b99;
}

.placeholder-amber-500\/70::placeholder {
  color: #f59e0bb3;
}

.placeholder-amber-500\/75::placeholder {
  color: #f59e0bbf;
}

.placeholder-amber-500\/80::placeholder {
  color: #f59e0bcc;
}

.placeholder-amber-500\/90::placeholder {
  color: #f59e0be6;
}

.placeholder-amber-500\/95::placeholder {
  color: #f59e0bf2;
}

.placeholder-amber-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(217 119 6 / var(--tw-placeholder-opacity));
}

.placeholder-amber-600\/0::placeholder {
  color: #d9770600;
}

.placeholder-amber-600\/10::placeholder {
  color: #d977061a;
}

.placeholder-amber-600\/100::placeholder {
  color: #d97706;
}

.placeholder-amber-600\/20::placeholder {
  color: #d9770633;
}

.placeholder-amber-600\/25::placeholder {
  color: #d9770640;
}

.placeholder-amber-600\/30::placeholder {
  color: #d977064d;
}

.placeholder-amber-600\/40::placeholder {
  color: #d9770666;
}

.placeholder-amber-600\/5::placeholder {
  color: #d977060d;
}

.placeholder-amber-600\/50::placeholder {
  color: #d9770680;
}

.placeholder-amber-600\/60::placeholder {
  color: #d9770699;
}

.placeholder-amber-600\/70::placeholder {
  color: #d97706b3;
}

.placeholder-amber-600\/75::placeholder {
  color: #d97706bf;
}

.placeholder-amber-600\/80::placeholder {
  color: #d97706cc;
}

.placeholder-amber-600\/90::placeholder {
  color: #d97706e6;
}

.placeholder-amber-600\/95::placeholder {
  color: #d97706f2;
}

.placeholder-amber-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(180 83 9 / var(--tw-placeholder-opacity));
}

.placeholder-amber-700\/0::placeholder {
  color: #b4530900;
}

.placeholder-amber-700\/10::placeholder {
  color: #b453091a;
}

.placeholder-amber-700\/100::placeholder {
  color: #b45309;
}

.placeholder-amber-700\/20::placeholder {
  color: #b4530933;
}

.placeholder-amber-700\/25::placeholder {
  color: #b4530940;
}

.placeholder-amber-700\/30::placeholder {
  color: #b453094d;
}

.placeholder-amber-700\/40::placeholder {
  color: #b4530966;
}

.placeholder-amber-700\/5::placeholder {
  color: #b453090d;
}

.placeholder-amber-700\/50::placeholder {
  color: #b4530980;
}

.placeholder-amber-700\/60::placeholder {
  color: #b4530999;
}

.placeholder-amber-700\/70::placeholder {
  color: #b45309b3;
}

.placeholder-amber-700\/75::placeholder {
  color: #b45309bf;
}

.placeholder-amber-700\/80::placeholder {
  color: #b45309cc;
}

.placeholder-amber-700\/90::placeholder {
  color: #b45309e6;
}

.placeholder-amber-700\/95::placeholder {
  color: #b45309f2;
}

.placeholder-amber-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(146 64 14 / var(--tw-placeholder-opacity));
}

.placeholder-amber-800\/0::placeholder {
  color: #92400e00;
}

.placeholder-amber-800\/10::placeholder {
  color: #92400e1a;
}

.placeholder-amber-800\/100::placeholder {
  color: #92400e;
}

.placeholder-amber-800\/20::placeholder {
  color: #92400e33;
}

.placeholder-amber-800\/25::placeholder {
  color: #92400e40;
}

.placeholder-amber-800\/30::placeholder {
  color: #92400e4d;
}

.placeholder-amber-800\/40::placeholder {
  color: #92400e66;
}

.placeholder-amber-800\/5::placeholder {
  color: #92400e0d;
}

.placeholder-amber-800\/50::placeholder {
  color: #92400e80;
}

.placeholder-amber-800\/60::placeholder {
  color: #92400e99;
}

.placeholder-amber-800\/70::placeholder {
  color: #92400eb3;
}

.placeholder-amber-800\/75::placeholder {
  color: #92400ebf;
}

.placeholder-amber-800\/80::placeholder {
  color: #92400ecc;
}

.placeholder-amber-800\/90::placeholder {
  color: #92400ee6;
}

.placeholder-amber-800\/95::placeholder {
  color: #92400ef2;
}

.placeholder-amber-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(120 53 15 / var(--tw-placeholder-opacity));
}

.placeholder-amber-900\/0::placeholder {
  color: #78350f00;
}

.placeholder-amber-900\/10::placeholder {
  color: #78350f1a;
}

.placeholder-amber-900\/100::placeholder {
  color: #78350f;
}

.placeholder-amber-900\/20::placeholder {
  color: #78350f33;
}

.placeholder-amber-900\/25::placeholder {
  color: #78350f40;
}

.placeholder-amber-900\/30::placeholder {
  color: #78350f4d;
}

.placeholder-amber-900\/40::placeholder {
  color: #78350f66;
}

.placeholder-amber-900\/5::placeholder {
  color: #78350f0d;
}

.placeholder-amber-900\/50::placeholder {
  color: #78350f80;
}

.placeholder-amber-900\/60::placeholder {
  color: #78350f99;
}

.placeholder-amber-900\/70::placeholder {
  color: #78350fb3;
}

.placeholder-amber-900\/75::placeholder {
  color: #78350fbf;
}

.placeholder-amber-900\/80::placeholder {
  color: #78350fcc;
}

.placeholder-amber-900\/90::placeholder {
  color: #78350fe6;
}

.placeholder-amber-900\/95::placeholder {
  color: #78350ff2;
}

.placeholder-amber-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(69 26 3 / var(--tw-placeholder-opacity));
}

.placeholder-amber-950\/0::placeholder {
  color: #451a0300;
}

.placeholder-amber-950\/10::placeholder {
  color: #451a031a;
}

.placeholder-amber-950\/100::placeholder {
  color: #451a03;
}

.placeholder-amber-950\/20::placeholder {
  color: #451a0333;
}

.placeholder-amber-950\/25::placeholder {
  color: #451a0340;
}

.placeholder-amber-950\/30::placeholder {
  color: #451a034d;
}

.placeholder-amber-950\/40::placeholder {
  color: #451a0366;
}

.placeholder-amber-950\/5::placeholder {
  color: #451a030d;
}

.placeholder-amber-950\/50::placeholder {
  color: #451a0380;
}

.placeholder-amber-950\/60::placeholder {
  color: #451a0399;
}

.placeholder-amber-950\/70::placeholder {
  color: #451a03b3;
}

.placeholder-amber-950\/75::placeholder {
  color: #451a03bf;
}

.placeholder-amber-950\/80::placeholder {
  color: #451a03cc;
}

.placeholder-amber-950\/90::placeholder {
  color: #451a03e6;
}

.placeholder-amber-950\/95::placeholder {
  color: #451a03f2;
}

.placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(0 0 0 / var(--tw-placeholder-opacity));
}

.placeholder-black\/0::placeholder {
  color: #0000;
}

.placeholder-black\/10::placeholder {
  color: #0000001a;
}

.placeholder-black\/100::placeholder {
  color: #000;
}

.placeholder-black\/20::placeholder {
  color: #0003;
}

.placeholder-black\/25::placeholder {
  color: #00000040;
}

.placeholder-black\/30::placeholder {
  color: #0000004d;
}

.placeholder-black\/40::placeholder {
  color: #0006;
}

.placeholder-black\/5::placeholder {
  color: #0000000d;
}

.placeholder-black\/50::placeholder {
  color: #00000080;
}

.placeholder-black\/60::placeholder {
  color: #0009;
}

.placeholder-black\/70::placeholder {
  color: #000000b3;
}

.placeholder-black\/75::placeholder {
  color: #000000bf;
}

.placeholder-black\/80::placeholder {
  color: #000c;
}

.placeholder-black\/90::placeholder {
  color: #000000e6;
}

.placeholder-black\/95::placeholder {
  color: #000000f2;
}

.placeholder-blue::placeholder {
  --tw-placeholder-opacity: 1;
  color: hsl(var(--blue) / var(--tw-placeholder-opacity));
}

.placeholder-blue\/0::placeholder {
  color: hsl(var(--blue) / 0);
}

.placeholder-blue\/10::placeholder {
  color: hsl(var(--blue) / .1);
}

.placeholder-blue\/100::placeholder {
  color: hsl(var(--blue) / 1);
}

.placeholder-blue\/20::placeholder {
  color: hsl(var(--blue) / .2);
}

.placeholder-blue\/25::placeholder {
  color: hsl(var(--blue) / .25);
}

.placeholder-blue\/30::placeholder {
  color: hsl(var(--blue) / .3);
}

.placeholder-blue\/40::placeholder {
  color: hsl(var(--blue) / .4);
}

.placeholder-blue\/5::placeholder {
  color: hsl(var(--blue) / .05);
}

.placeholder-blue\/50::placeholder {
  color: hsl(var(--blue) / .5);
}

.placeholder-blue\/60::placeholder {
  color: hsl(var(--blue) / .6);
}

.placeholder-blue\/70::placeholder {
  color: hsl(var(--blue) / .7);
}

.placeholder-blue\/75::placeholder {
  color: hsl(var(--blue) / .75);
}

.placeholder-blue\/80::placeholder {
  color: hsl(var(--blue) / .8);
}

.placeholder-blue\/90::placeholder {
  color: hsl(var(--blue) / .9);
}

.placeholder-blue\/95::placeholder {
  color: hsl(var(--blue) / .95);
}

.placeholder-current::placeholder {
  color: currentColor;
}

.placeholder-cyan-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(207 250 254 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-100\/0::placeholder {
  color: #cffafe00;
}

.placeholder-cyan-100\/10::placeholder {
  color: #cffafe1a;
}

.placeholder-cyan-100\/100::placeholder {
  color: #cffafe;
}

.placeholder-cyan-100\/20::placeholder {
  color: #cffafe33;
}

.placeholder-cyan-100\/25::placeholder {
  color: #cffafe40;
}

.placeholder-cyan-100\/30::placeholder {
  color: #cffafe4d;
}

.placeholder-cyan-100\/40::placeholder {
  color: #cffafe66;
}

.placeholder-cyan-100\/5::placeholder {
  color: #cffafe0d;
}

.placeholder-cyan-100\/50::placeholder {
  color: #cffafe80;
}

.placeholder-cyan-100\/60::placeholder {
  color: #cffafe99;
}

.placeholder-cyan-100\/70::placeholder {
  color: #cffafeb3;
}

.placeholder-cyan-100\/75::placeholder {
  color: #cffafebf;
}

.placeholder-cyan-100\/80::placeholder {
  color: #cffafecc;
}

.placeholder-cyan-100\/90::placeholder {
  color: #cffafee6;
}

.placeholder-cyan-100\/95::placeholder {
  color: #cffafef2;
}

.placeholder-cyan-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(165 243 252 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-200\/0::placeholder {
  color: #a5f3fc00;
}

.placeholder-cyan-200\/10::placeholder {
  color: #a5f3fc1a;
}

.placeholder-cyan-200\/100::placeholder {
  color: #a5f3fc;
}

.placeholder-cyan-200\/20::placeholder {
  color: #a5f3fc33;
}

.placeholder-cyan-200\/25::placeholder {
  color: #a5f3fc40;
}

.placeholder-cyan-200\/30::placeholder {
  color: #a5f3fc4d;
}

.placeholder-cyan-200\/40::placeholder {
  color: #a5f3fc66;
}

.placeholder-cyan-200\/5::placeholder {
  color: #a5f3fc0d;
}

.placeholder-cyan-200\/50::placeholder {
  color: #a5f3fc80;
}

.placeholder-cyan-200\/60::placeholder {
  color: #a5f3fc99;
}

.placeholder-cyan-200\/70::placeholder {
  color: #a5f3fcb3;
}

.placeholder-cyan-200\/75::placeholder {
  color: #a5f3fcbf;
}

.placeholder-cyan-200\/80::placeholder {
  color: #a5f3fccc;
}

.placeholder-cyan-200\/90::placeholder {
  color: #a5f3fce6;
}

.placeholder-cyan-200\/95::placeholder {
  color: #a5f3fcf2;
}

.placeholder-cyan-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(103 232 249 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-300\/0::placeholder {
  color: #67e8f900;
}

.placeholder-cyan-300\/10::placeholder {
  color: #67e8f91a;
}

.placeholder-cyan-300\/100::placeholder {
  color: #67e8f9;
}

.placeholder-cyan-300\/20::placeholder {
  color: #67e8f933;
}

.placeholder-cyan-300\/25::placeholder {
  color: #67e8f940;
}

.placeholder-cyan-300\/30::placeholder {
  color: #67e8f94d;
}

.placeholder-cyan-300\/40::placeholder {
  color: #67e8f966;
}

.placeholder-cyan-300\/5::placeholder {
  color: #67e8f90d;
}

.placeholder-cyan-300\/50::placeholder {
  color: #67e8f980;
}

.placeholder-cyan-300\/60::placeholder {
  color: #67e8f999;
}

.placeholder-cyan-300\/70::placeholder {
  color: #67e8f9b3;
}

.placeholder-cyan-300\/75::placeholder {
  color: #67e8f9bf;
}

.placeholder-cyan-300\/80::placeholder {
  color: #67e8f9cc;
}

.placeholder-cyan-300\/90::placeholder {
  color: #67e8f9e6;
}

.placeholder-cyan-300\/95::placeholder {
  color: #67e8f9f2;
}

.placeholder-cyan-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(34 211 238 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-400\/0::placeholder {
  color: #22d3ee00;
}

.placeholder-cyan-400\/10::placeholder {
  color: #22d3ee1a;
}

.placeholder-cyan-400\/100::placeholder {
  color: #22d3ee;
}

.placeholder-cyan-400\/20::placeholder {
  color: #22d3ee33;
}

.placeholder-cyan-400\/25::placeholder {
  color: #22d3ee40;
}

.placeholder-cyan-400\/30::placeholder {
  color: #22d3ee4d;
}

.placeholder-cyan-400\/40::placeholder {
  color: #22d3ee66;
}

.placeholder-cyan-400\/5::placeholder {
  color: #22d3ee0d;
}

.placeholder-cyan-400\/50::placeholder {
  color: #22d3ee80;
}

.placeholder-cyan-400\/60::placeholder {
  color: #22d3ee99;
}

.placeholder-cyan-400\/70::placeholder {
  color: #22d3eeb3;
}

.placeholder-cyan-400\/75::placeholder {
  color: #22d3eebf;
}

.placeholder-cyan-400\/80::placeholder {
  color: #22d3eecc;
}

.placeholder-cyan-400\/90::placeholder {
  color: #22d3eee6;
}

.placeholder-cyan-400\/95::placeholder {
  color: #22d3eef2;
}

.placeholder-cyan-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(236 254 255 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-50\/0::placeholder {
  color: #ecfeff00;
}

.placeholder-cyan-50\/10::placeholder {
  color: #ecfeff1a;
}

.placeholder-cyan-50\/100::placeholder {
  color: #ecfeff;
}

.placeholder-cyan-50\/20::placeholder {
  color: #ecfeff33;
}

.placeholder-cyan-50\/25::placeholder {
  color: #ecfeff40;
}

.placeholder-cyan-50\/30::placeholder {
  color: #ecfeff4d;
}

.placeholder-cyan-50\/40::placeholder {
  color: #ecfeff66;
}

.placeholder-cyan-50\/5::placeholder {
  color: #ecfeff0d;
}

.placeholder-cyan-50\/50::placeholder {
  color: #ecfeff80;
}

.placeholder-cyan-50\/60::placeholder {
  color: #ecfeff99;
}

.placeholder-cyan-50\/70::placeholder {
  color: #ecfeffb3;
}

.placeholder-cyan-50\/75::placeholder {
  color: #ecfeffbf;
}

.placeholder-cyan-50\/80::placeholder {
  color: #ecfeffcc;
}

.placeholder-cyan-50\/90::placeholder {
  color: #ecfeffe6;
}

.placeholder-cyan-50\/95::placeholder {
  color: #ecfefff2;
}

.placeholder-cyan-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(6 182 212 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-500\/0::placeholder {
  color: #06b6d400;
}

.placeholder-cyan-500\/10::placeholder {
  color: #06b6d41a;
}

.placeholder-cyan-500\/100::placeholder {
  color: #06b6d4;
}

.placeholder-cyan-500\/20::placeholder {
  color: #06b6d433;
}

.placeholder-cyan-500\/25::placeholder {
  color: #06b6d440;
}

.placeholder-cyan-500\/30::placeholder {
  color: #06b6d44d;
}

.placeholder-cyan-500\/40::placeholder {
  color: #06b6d466;
}

.placeholder-cyan-500\/5::placeholder {
  color: #06b6d40d;
}

.placeholder-cyan-500\/50::placeholder {
  color: #06b6d480;
}

.placeholder-cyan-500\/60::placeholder {
  color: #06b6d499;
}

.placeholder-cyan-500\/70::placeholder {
  color: #06b6d4b3;
}

.placeholder-cyan-500\/75::placeholder {
  color: #06b6d4bf;
}

.placeholder-cyan-500\/80::placeholder {
  color: #06b6d4cc;
}

.placeholder-cyan-500\/90::placeholder {
  color: #06b6d4e6;
}

.placeholder-cyan-500\/95::placeholder {
  color: #06b6d4f2;
}

.placeholder-cyan-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(8 145 178 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-600\/0::placeholder {
  color: #0891b200;
}

.placeholder-cyan-600\/10::placeholder {
  color: #0891b21a;
}

.placeholder-cyan-600\/100::placeholder {
  color: #0891b2;
}

.placeholder-cyan-600\/20::placeholder {
  color: #0891b233;
}

.placeholder-cyan-600\/25::placeholder {
  color: #0891b240;
}

.placeholder-cyan-600\/30::placeholder {
  color: #0891b24d;
}

.placeholder-cyan-600\/40::placeholder {
  color: #0891b266;
}

.placeholder-cyan-600\/5::placeholder {
  color: #0891b20d;
}

.placeholder-cyan-600\/50::placeholder {
  color: #0891b280;
}

.placeholder-cyan-600\/60::placeholder {
  color: #0891b299;
}

.placeholder-cyan-600\/70::placeholder {
  color: #0891b2b3;
}

.placeholder-cyan-600\/75::placeholder {
  color: #0891b2bf;
}

.placeholder-cyan-600\/80::placeholder {
  color: #0891b2cc;
}

.placeholder-cyan-600\/90::placeholder {
  color: #0891b2e6;
}

.placeholder-cyan-600\/95::placeholder {
  color: #0891b2f2;
}

.placeholder-cyan-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(14 116 144 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-700\/0::placeholder {
  color: #0e749000;
}

.placeholder-cyan-700\/10::placeholder {
  color: #0e74901a;
}

.placeholder-cyan-700\/100::placeholder {
  color: #0e7490;
}

.placeholder-cyan-700\/20::placeholder {
  color: #0e749033;
}

.placeholder-cyan-700\/25::placeholder {
  color: #0e749040;
}

.placeholder-cyan-700\/30::placeholder {
  color: #0e74904d;
}

.placeholder-cyan-700\/40::placeholder {
  color: #0e749066;
}

.placeholder-cyan-700\/5::placeholder {
  color: #0e74900d;
}

.placeholder-cyan-700\/50::placeholder {
  color: #0e749080;
}

.placeholder-cyan-700\/60::placeholder {
  color: #0e749099;
}

.placeholder-cyan-700\/70::placeholder {
  color: #0e7490b3;
}

.placeholder-cyan-700\/75::placeholder {
  color: #0e7490bf;
}

.placeholder-cyan-700\/80::placeholder {
  color: #0e7490cc;
}

.placeholder-cyan-700\/90::placeholder {
  color: #0e7490e6;
}

.placeholder-cyan-700\/95::placeholder {
  color: #0e7490f2;
}

.placeholder-cyan-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(21 94 117 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-800\/0::placeholder {
  color: #155e7500;
}

.placeholder-cyan-800\/10::placeholder {
  color: #155e751a;
}

.placeholder-cyan-800\/100::placeholder {
  color: #155e75;
}

.placeholder-cyan-800\/20::placeholder {
  color: #155e7533;
}

.placeholder-cyan-800\/25::placeholder {
  color: #155e7540;
}

.placeholder-cyan-800\/30::placeholder {
  color: #155e754d;
}

.placeholder-cyan-800\/40::placeholder {
  color: #155e7566;
}

.placeholder-cyan-800\/5::placeholder {
  color: #155e750d;
}

.placeholder-cyan-800\/50::placeholder {
  color: #155e7580;
}

.placeholder-cyan-800\/60::placeholder {
  color: #155e7599;
}

.placeholder-cyan-800\/70::placeholder {
  color: #155e75b3;
}

.placeholder-cyan-800\/75::placeholder {
  color: #155e75bf;
}

.placeholder-cyan-800\/80::placeholder {
  color: #155e75cc;
}

.placeholder-cyan-800\/90::placeholder {
  color: #155e75e6;
}

.placeholder-cyan-800\/95::placeholder {
  color: #155e75f2;
}

.placeholder-cyan-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(22 78 99 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-900\/0::placeholder {
  color: #164e6300;
}

.placeholder-cyan-900\/10::placeholder {
  color: #164e631a;
}

.placeholder-cyan-900\/100::placeholder {
  color: #164e63;
}

.placeholder-cyan-900\/20::placeholder {
  color: #164e6333;
}

.placeholder-cyan-900\/25::placeholder {
  color: #164e6340;
}

.placeholder-cyan-900\/30::placeholder {
  color: #164e634d;
}

.placeholder-cyan-900\/40::placeholder {
  color: #164e6366;
}

.placeholder-cyan-900\/5::placeholder {
  color: #164e630d;
}

.placeholder-cyan-900\/50::placeholder {
  color: #164e6380;
}

.placeholder-cyan-900\/60::placeholder {
  color: #164e6399;
}

.placeholder-cyan-900\/70::placeholder {
  color: #164e63b3;
}

.placeholder-cyan-900\/75::placeholder {
  color: #164e63bf;
}

.placeholder-cyan-900\/80::placeholder {
  color: #164e63cc;
}

.placeholder-cyan-900\/90::placeholder {
  color: #164e63e6;
}

.placeholder-cyan-900\/95::placeholder {
  color: #164e63f2;
}

.placeholder-cyan-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(8 51 68 / var(--tw-placeholder-opacity));
}

.placeholder-cyan-950\/0::placeholder {
  color: #08334400;
}

.placeholder-cyan-950\/10::placeholder {
  color: #0833441a;
}

.placeholder-cyan-950\/100::placeholder {
  color: #083344;
}

.placeholder-cyan-950\/20::placeholder {
  color: #08334433;
}

.placeholder-cyan-950\/25::placeholder {
  color: #08334440;
}

.placeholder-cyan-950\/30::placeholder {
  color: #0833444d;
}

.placeholder-cyan-950\/40::placeholder {
  color: #08334466;
}

.placeholder-cyan-950\/5::placeholder {
  color: #0833440d;
}

.placeholder-cyan-950\/50::placeholder {
  color: #08334480;
}

.placeholder-cyan-950\/60::placeholder {
  color: #08334499;
}

.placeholder-cyan-950\/70::placeholder {
  color: #083344b3;
}

.placeholder-cyan-950\/75::placeholder {
  color: #083344bf;
}

.placeholder-cyan-950\/80::placeholder {
  color: #083344cc;
}

.placeholder-cyan-950\/90::placeholder {
  color: #083344e6;
}

.placeholder-cyan-950\/95::placeholder {
  color: #083344f2;
}

.placeholder-emerald-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(209 250 229 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-100\/0::placeholder {
  color: #d1fae500;
}

.placeholder-emerald-100\/10::placeholder {
  color: #d1fae51a;
}

.placeholder-emerald-100\/100::placeholder {
  color: #d1fae5;
}

.placeholder-emerald-100\/20::placeholder {
  color: #d1fae533;
}

.placeholder-emerald-100\/25::placeholder {
  color: #d1fae540;
}

.placeholder-emerald-100\/30::placeholder {
  color: #d1fae54d;
}

.placeholder-emerald-100\/40::placeholder {
  color: #d1fae566;
}

.placeholder-emerald-100\/5::placeholder {
  color: #d1fae50d;
}

.placeholder-emerald-100\/50::placeholder {
  color: #d1fae580;
}

.placeholder-emerald-100\/60::placeholder {
  color: #d1fae599;
}

.placeholder-emerald-100\/70::placeholder {
  color: #d1fae5b3;
}

.placeholder-emerald-100\/75::placeholder {
  color: #d1fae5bf;
}

.placeholder-emerald-100\/80::placeholder {
  color: #d1fae5cc;
}

.placeholder-emerald-100\/90::placeholder {
  color: #d1fae5e6;
}

.placeholder-emerald-100\/95::placeholder {
  color: #d1fae5f2;
}

.placeholder-emerald-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(167 243 208 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-200\/0::placeholder {
  color: #a7f3d000;
}

.placeholder-emerald-200\/10::placeholder {
  color: #a7f3d01a;
}

.placeholder-emerald-200\/100::placeholder {
  color: #a7f3d0;
}

.placeholder-emerald-200\/20::placeholder {
  color: #a7f3d033;
}

.placeholder-emerald-200\/25::placeholder {
  color: #a7f3d040;
}

.placeholder-emerald-200\/30::placeholder {
  color: #a7f3d04d;
}

.placeholder-emerald-200\/40::placeholder {
  color: #a7f3d066;
}

.placeholder-emerald-200\/5::placeholder {
  color: #a7f3d00d;
}

.placeholder-emerald-200\/50::placeholder {
  color: #a7f3d080;
}

.placeholder-emerald-200\/60::placeholder {
  color: #a7f3d099;
}

.placeholder-emerald-200\/70::placeholder {
  color: #a7f3d0b3;
}

.placeholder-emerald-200\/75::placeholder {
  color: #a7f3d0bf;
}

.placeholder-emerald-200\/80::placeholder {
  color: #a7f3d0cc;
}

.placeholder-emerald-200\/90::placeholder {
  color: #a7f3d0e6;
}

.placeholder-emerald-200\/95::placeholder {
  color: #a7f3d0f2;
}

.placeholder-emerald-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(110 231 183 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-300\/0::placeholder {
  color: #6ee7b700;
}

.placeholder-emerald-300\/10::placeholder {
  color: #6ee7b71a;
}

.placeholder-emerald-300\/100::placeholder {
  color: #6ee7b7;
}

.placeholder-emerald-300\/20::placeholder {
  color: #6ee7b733;
}

.placeholder-emerald-300\/25::placeholder {
  color: #6ee7b740;
}

.placeholder-emerald-300\/30::placeholder {
  color: #6ee7b74d;
}

.placeholder-emerald-300\/40::placeholder {
  color: #6ee7b766;
}

.placeholder-emerald-300\/5::placeholder {
  color: #6ee7b70d;
}

.placeholder-emerald-300\/50::placeholder {
  color: #6ee7b780;
}

.placeholder-emerald-300\/60::placeholder {
  color: #6ee7b799;
}

.placeholder-emerald-300\/70::placeholder {
  color: #6ee7b7b3;
}

.placeholder-emerald-300\/75::placeholder {
  color: #6ee7b7bf;
}

.placeholder-emerald-300\/80::placeholder {
  color: #6ee7b7cc;
}

.placeholder-emerald-300\/90::placeholder {
  color: #6ee7b7e6;
}

.placeholder-emerald-300\/95::placeholder {
  color: #6ee7b7f2;
}

.placeholder-emerald-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(52 211 153 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-400\/0::placeholder {
  color: #34d39900;
}

.placeholder-emerald-400\/10::placeholder {
  color: #34d3991a;
}

.placeholder-emerald-400\/100::placeholder {
  color: #34d399;
}

.placeholder-emerald-400\/20::placeholder {
  color: #34d39933;
}

.placeholder-emerald-400\/25::placeholder {
  color: #34d39940;
}

.placeholder-emerald-400\/30::placeholder {
  color: #34d3994d;
}

.placeholder-emerald-400\/40::placeholder {
  color: #34d39966;
}

.placeholder-emerald-400\/5::placeholder {
  color: #34d3990d;
}

.placeholder-emerald-400\/50::placeholder {
  color: #34d39980;
}

.placeholder-emerald-400\/60::placeholder {
  color: #34d39999;
}

.placeholder-emerald-400\/70::placeholder {
  color: #34d399b3;
}

.placeholder-emerald-400\/75::placeholder {
  color: #34d399bf;
}

.placeholder-emerald-400\/80::placeholder {
  color: #34d399cc;
}

.placeholder-emerald-400\/90::placeholder {
  color: #34d399e6;
}

.placeholder-emerald-400\/95::placeholder {
  color: #34d399f2;
}

.placeholder-emerald-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(236 253 245 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-50\/0::placeholder {
  color: #ecfdf500;
}

.placeholder-emerald-50\/10::placeholder {
  color: #ecfdf51a;
}

.placeholder-emerald-50\/100::placeholder {
  color: #ecfdf5;
}

.placeholder-emerald-50\/20::placeholder {
  color: #ecfdf533;
}

.placeholder-emerald-50\/25::placeholder {
  color: #ecfdf540;
}

.placeholder-emerald-50\/30::placeholder {
  color: #ecfdf54d;
}

.placeholder-emerald-50\/40::placeholder {
  color: #ecfdf566;
}

.placeholder-emerald-50\/5::placeholder {
  color: #ecfdf50d;
}

.placeholder-emerald-50\/50::placeholder {
  color: #ecfdf580;
}

.placeholder-emerald-50\/60::placeholder {
  color: #ecfdf599;
}

.placeholder-emerald-50\/70::placeholder {
  color: #ecfdf5b3;
}

.placeholder-emerald-50\/75::placeholder {
  color: #ecfdf5bf;
}

.placeholder-emerald-50\/80::placeholder {
  color: #ecfdf5cc;
}

.placeholder-emerald-50\/90::placeholder {
  color: #ecfdf5e6;
}

.placeholder-emerald-50\/95::placeholder {
  color: #ecfdf5f2;
}

.placeholder-emerald-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(16 185 129 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-500\/0::placeholder {
  color: #10b98100;
}

.placeholder-emerald-500\/10::placeholder {
  color: #10b9811a;
}

.placeholder-emerald-500\/100::placeholder {
  color: #10b981;
}

.placeholder-emerald-500\/20::placeholder {
  color: #10b98133;
}

.placeholder-emerald-500\/25::placeholder {
  color: #10b98140;
}

.placeholder-emerald-500\/30::placeholder {
  color: #10b9814d;
}

.placeholder-emerald-500\/40::placeholder {
  color: #10b98166;
}

.placeholder-emerald-500\/5::placeholder {
  color: #10b9810d;
}

.placeholder-emerald-500\/50::placeholder {
  color: #10b98180;
}

.placeholder-emerald-500\/60::placeholder {
  color: #10b98199;
}

.placeholder-emerald-500\/70::placeholder {
  color: #10b981b3;
}

.placeholder-emerald-500\/75::placeholder {
  color: #10b981bf;
}

.placeholder-emerald-500\/80::placeholder {
  color: #10b981cc;
}

.placeholder-emerald-500\/90::placeholder {
  color: #10b981e6;
}

.placeholder-emerald-500\/95::placeholder {
  color: #10b981f2;
}

.placeholder-emerald-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(5 150 105 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-600\/0::placeholder {
  color: #05966900;
}

.placeholder-emerald-600\/10::placeholder {
  color: #0596691a;
}

.placeholder-emerald-600\/100::placeholder {
  color: #059669;
}

.placeholder-emerald-600\/20::placeholder {
  color: #05966933;
}

.placeholder-emerald-600\/25::placeholder {
  color: #05966940;
}

.placeholder-emerald-600\/30::placeholder {
  color: #0596694d;
}

.placeholder-emerald-600\/40::placeholder {
  color: #05966966;
}

.placeholder-emerald-600\/5::placeholder {
  color: #0596690d;
}

.placeholder-emerald-600\/50::placeholder {
  color: #05966980;
}

.placeholder-emerald-600\/60::placeholder {
  color: #05966999;
}

.placeholder-emerald-600\/70::placeholder {
  color: #059669b3;
}

.placeholder-emerald-600\/75::placeholder {
  color: #059669bf;
}

.placeholder-emerald-600\/80::placeholder {
  color: #059669cc;
}

.placeholder-emerald-600\/90::placeholder {
  color: #059669e6;
}

.placeholder-emerald-600\/95::placeholder {
  color: #059669f2;
}

.placeholder-emerald-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(4 120 87 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-700\/0::placeholder {
  color: #04785700;
}

.placeholder-emerald-700\/10::placeholder {
  color: #0478571a;
}

.placeholder-emerald-700\/100::placeholder {
  color: #047857;
}

.placeholder-emerald-700\/20::placeholder {
  color: #04785733;
}

.placeholder-emerald-700\/25::placeholder {
  color: #04785740;
}

.placeholder-emerald-700\/30::placeholder {
  color: #0478574d;
}

.placeholder-emerald-700\/40::placeholder {
  color: #04785766;
}

.placeholder-emerald-700\/5::placeholder {
  color: #0478570d;
}

.placeholder-emerald-700\/50::placeholder {
  color: #04785780;
}

.placeholder-emerald-700\/60::placeholder {
  color: #04785799;
}

.placeholder-emerald-700\/70::placeholder {
  color: #047857b3;
}

.placeholder-emerald-700\/75::placeholder {
  color: #047857bf;
}

.placeholder-emerald-700\/80::placeholder {
  color: #047857cc;
}

.placeholder-emerald-700\/90::placeholder {
  color: #047857e6;
}

.placeholder-emerald-700\/95::placeholder {
  color: #047857f2;
}

.placeholder-emerald-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(6 95 70 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-800\/0::placeholder {
  color: #065f4600;
}

.placeholder-emerald-800\/10::placeholder {
  color: #065f461a;
}

.placeholder-emerald-800\/100::placeholder {
  color: #065f46;
}

.placeholder-emerald-800\/20::placeholder {
  color: #065f4633;
}

.placeholder-emerald-800\/25::placeholder {
  color: #065f4640;
}

.placeholder-emerald-800\/30::placeholder {
  color: #065f464d;
}

.placeholder-emerald-800\/40::placeholder {
  color: #065f4666;
}

.placeholder-emerald-800\/5::placeholder {
  color: #065f460d;
}

.placeholder-emerald-800\/50::placeholder {
  color: #065f4680;
}

.placeholder-emerald-800\/60::placeholder {
  color: #065f4699;
}

.placeholder-emerald-800\/70::placeholder {
  color: #065f46b3;
}

.placeholder-emerald-800\/75::placeholder {
  color: #065f46bf;
}

.placeholder-emerald-800\/80::placeholder {
  color: #065f46cc;
}

.placeholder-emerald-800\/90::placeholder {
  color: #065f46e6;
}

.placeholder-emerald-800\/95::placeholder {
  color: #065f46f2;
}

.placeholder-emerald-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(6 78 59 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-900\/0::placeholder {
  color: #064e3b00;
}

.placeholder-emerald-900\/10::placeholder {
  color: #064e3b1a;
}

.placeholder-emerald-900\/100::placeholder {
  color: #064e3b;
}

.placeholder-emerald-900\/20::placeholder {
  color: #064e3b33;
}

.placeholder-emerald-900\/25::placeholder {
  color: #064e3b40;
}

.placeholder-emerald-900\/30::placeholder {
  color: #064e3b4d;
}

.placeholder-emerald-900\/40::placeholder {
  color: #064e3b66;
}

.placeholder-emerald-900\/5::placeholder {
  color: #064e3b0d;
}

.placeholder-emerald-900\/50::placeholder {
  color: #064e3b80;
}

.placeholder-emerald-900\/60::placeholder {
  color: #064e3b99;
}

.placeholder-emerald-900\/70::placeholder {
  color: #064e3bb3;
}

.placeholder-emerald-900\/75::placeholder {
  color: #064e3bbf;
}

.placeholder-emerald-900\/80::placeholder {
  color: #064e3bcc;
}

.placeholder-emerald-900\/90::placeholder {
  color: #064e3be6;
}

.placeholder-emerald-900\/95::placeholder {
  color: #064e3bf2;
}

.placeholder-emerald-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(2 44 34 / var(--tw-placeholder-opacity));
}

.placeholder-emerald-950\/0::placeholder {
  color: #022c2200;
}

.placeholder-emerald-950\/10::placeholder {
  color: #022c221a;
}

.placeholder-emerald-950\/100::placeholder {
  color: #022c22;
}

.placeholder-emerald-950\/20::placeholder {
  color: #022c2233;
}

.placeholder-emerald-950\/25::placeholder {
  color: #022c2240;
}

.placeholder-emerald-950\/30::placeholder {
  color: #022c224d;
}

.placeholder-emerald-950\/40::placeholder {
  color: #022c2266;
}

.placeholder-emerald-950\/5::placeholder {
  color: #022c220d;
}

.placeholder-emerald-950\/50::placeholder {
  color: #022c2280;
}

.placeholder-emerald-950\/60::placeholder {
  color: #022c2299;
}

.placeholder-emerald-950\/70::placeholder {
  color: #022c22b3;
}

.placeholder-emerald-950\/75::placeholder {
  color: #022c22bf;
}

.placeholder-emerald-950\/80::placeholder {
  color: #022c22cc;
}

.placeholder-emerald-950\/90::placeholder {
  color: #022c22e6;
}

.placeholder-emerald-950\/95::placeholder {
  color: #022c22f2;
}

.placeholder-fuchsia-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(250 232 255 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-100\/0::placeholder {
  color: #fae8ff00;
}

.placeholder-fuchsia-100\/10::placeholder {
  color: #fae8ff1a;
}

.placeholder-fuchsia-100\/100::placeholder {
  color: #fae8ff;
}

.placeholder-fuchsia-100\/20::placeholder {
  color: #fae8ff33;
}

.placeholder-fuchsia-100\/25::placeholder {
  color: #fae8ff40;
}

.placeholder-fuchsia-100\/30::placeholder {
  color: #fae8ff4d;
}

.placeholder-fuchsia-100\/40::placeholder {
  color: #fae8ff66;
}

.placeholder-fuchsia-100\/5::placeholder {
  color: #fae8ff0d;
}

.placeholder-fuchsia-100\/50::placeholder {
  color: #fae8ff80;
}

.placeholder-fuchsia-100\/60::placeholder {
  color: #fae8ff99;
}

.placeholder-fuchsia-100\/70::placeholder {
  color: #fae8ffb3;
}

.placeholder-fuchsia-100\/75::placeholder {
  color: #fae8ffbf;
}

.placeholder-fuchsia-100\/80::placeholder {
  color: #fae8ffcc;
}

.placeholder-fuchsia-100\/90::placeholder {
  color: #fae8ffe6;
}

.placeholder-fuchsia-100\/95::placeholder {
  color: #fae8fff2;
}

.placeholder-fuchsia-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(245 208 254 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-200\/0::placeholder {
  color: #f5d0fe00;
}

.placeholder-fuchsia-200\/10::placeholder {
  color: #f5d0fe1a;
}

.placeholder-fuchsia-200\/100::placeholder {
  color: #f5d0fe;
}

.placeholder-fuchsia-200\/20::placeholder {
  color: #f5d0fe33;
}

.placeholder-fuchsia-200\/25::placeholder {
  color: #f5d0fe40;
}

.placeholder-fuchsia-200\/30::placeholder {
  color: #f5d0fe4d;
}

.placeholder-fuchsia-200\/40::placeholder {
  color: #f5d0fe66;
}

.placeholder-fuchsia-200\/5::placeholder {
  color: #f5d0fe0d;
}

.placeholder-fuchsia-200\/50::placeholder {
  color: #f5d0fe80;
}

.placeholder-fuchsia-200\/60::placeholder {
  color: #f5d0fe99;
}

.placeholder-fuchsia-200\/70::placeholder {
  color: #f5d0feb3;
}

.placeholder-fuchsia-200\/75::placeholder {
  color: #f5d0febf;
}

.placeholder-fuchsia-200\/80::placeholder {
  color: #f5d0fecc;
}

.placeholder-fuchsia-200\/90::placeholder {
  color: #f5d0fee6;
}

.placeholder-fuchsia-200\/95::placeholder {
  color: #f5d0fef2;
}

.placeholder-fuchsia-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(240 171 252 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-300\/0::placeholder {
  color: #f0abfc00;
}

.placeholder-fuchsia-300\/10::placeholder {
  color: #f0abfc1a;
}

.placeholder-fuchsia-300\/100::placeholder {
  color: #f0abfc;
}

.placeholder-fuchsia-300\/20::placeholder {
  color: #f0abfc33;
}

.placeholder-fuchsia-300\/25::placeholder {
  color: #f0abfc40;
}

.placeholder-fuchsia-300\/30::placeholder {
  color: #f0abfc4d;
}

.placeholder-fuchsia-300\/40::placeholder {
  color: #f0abfc66;
}

.placeholder-fuchsia-300\/5::placeholder {
  color: #f0abfc0d;
}

.placeholder-fuchsia-300\/50::placeholder {
  color: #f0abfc80;
}

.placeholder-fuchsia-300\/60::placeholder {
  color: #f0abfc99;
}

.placeholder-fuchsia-300\/70::placeholder {
  color: #f0abfcb3;
}

.placeholder-fuchsia-300\/75::placeholder {
  color: #f0abfcbf;
}

.placeholder-fuchsia-300\/80::placeholder {
  color: #f0abfccc;
}

.placeholder-fuchsia-300\/90::placeholder {
  color: #f0abfce6;
}

.placeholder-fuchsia-300\/95::placeholder {
  color: #f0abfcf2;
}

.placeholder-fuchsia-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(232 121 249 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-400\/0::placeholder {
  color: #e879f900;
}

.placeholder-fuchsia-400\/10::placeholder {
  color: #e879f91a;
}

.placeholder-fuchsia-400\/100::placeholder {
  color: #e879f9;
}

.placeholder-fuchsia-400\/20::placeholder {
  color: #e879f933;
}

.placeholder-fuchsia-400\/25::placeholder {
  color: #e879f940;
}

.placeholder-fuchsia-400\/30::placeholder {
  color: #e879f94d;
}

.placeholder-fuchsia-400\/40::placeholder {
  color: #e879f966;
}

.placeholder-fuchsia-400\/5::placeholder {
  color: #e879f90d;
}

.placeholder-fuchsia-400\/50::placeholder {
  color: #e879f980;
}

.placeholder-fuchsia-400\/60::placeholder {
  color: #e879f999;
}

.placeholder-fuchsia-400\/70::placeholder {
  color: #e879f9b3;
}

.placeholder-fuchsia-400\/75::placeholder {
  color: #e879f9bf;
}

.placeholder-fuchsia-400\/80::placeholder {
  color: #e879f9cc;
}

.placeholder-fuchsia-400\/90::placeholder {
  color: #e879f9e6;
}

.placeholder-fuchsia-400\/95::placeholder {
  color: #e879f9f2;
}

.placeholder-fuchsia-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(253 244 255 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-50\/0::placeholder {
  color: #fdf4ff00;
}

.placeholder-fuchsia-50\/10::placeholder {
  color: #fdf4ff1a;
}

.placeholder-fuchsia-50\/100::placeholder {
  color: #fdf4ff;
}

.placeholder-fuchsia-50\/20::placeholder {
  color: #fdf4ff33;
}

.placeholder-fuchsia-50\/25::placeholder {
  color: #fdf4ff40;
}

.placeholder-fuchsia-50\/30::placeholder {
  color: #fdf4ff4d;
}

.placeholder-fuchsia-50\/40::placeholder {
  color: #fdf4ff66;
}

.placeholder-fuchsia-50\/5::placeholder {
  color: #fdf4ff0d;
}

.placeholder-fuchsia-50\/50::placeholder {
  color: #fdf4ff80;
}

.placeholder-fuchsia-50\/60::placeholder {
  color: #fdf4ff99;
}

.placeholder-fuchsia-50\/70::placeholder {
  color: #fdf4ffb3;
}

.placeholder-fuchsia-50\/75::placeholder {
  color: #fdf4ffbf;
}

.placeholder-fuchsia-50\/80::placeholder {
  color: #fdf4ffcc;
}

.placeholder-fuchsia-50\/90::placeholder {
  color: #fdf4ffe6;
}

.placeholder-fuchsia-50\/95::placeholder {
  color: #fdf4fff2;
}

.placeholder-fuchsia-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(217 70 239 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-500\/0::placeholder {
  color: #d946ef00;
}

.placeholder-fuchsia-500\/10::placeholder {
  color: #d946ef1a;
}

.placeholder-fuchsia-500\/100::placeholder {
  color: #d946ef;
}

.placeholder-fuchsia-500\/20::placeholder {
  color: #d946ef33;
}

.placeholder-fuchsia-500\/25::placeholder {
  color: #d946ef40;
}

.placeholder-fuchsia-500\/30::placeholder {
  color: #d946ef4d;
}

.placeholder-fuchsia-500\/40::placeholder {
  color: #d946ef66;
}

.placeholder-fuchsia-500\/5::placeholder {
  color: #d946ef0d;
}

.placeholder-fuchsia-500\/50::placeholder {
  color: #d946ef80;
}

.placeholder-fuchsia-500\/60::placeholder {
  color: #d946ef99;
}

.placeholder-fuchsia-500\/70::placeholder {
  color: #d946efb3;
}

.placeholder-fuchsia-500\/75::placeholder {
  color: #d946efbf;
}

.placeholder-fuchsia-500\/80::placeholder {
  color: #d946efcc;
}

.placeholder-fuchsia-500\/90::placeholder {
  color: #d946efe6;
}

.placeholder-fuchsia-500\/95::placeholder {
  color: #d946eff2;
}

.placeholder-fuchsia-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(192 38 211 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-600\/0::placeholder {
  color: #c026d300;
}

.placeholder-fuchsia-600\/10::placeholder {
  color: #c026d31a;
}

.placeholder-fuchsia-600\/100::placeholder {
  color: #c026d3;
}

.placeholder-fuchsia-600\/20::placeholder {
  color: #c026d333;
}

.placeholder-fuchsia-600\/25::placeholder {
  color: #c026d340;
}

.placeholder-fuchsia-600\/30::placeholder {
  color: #c026d34d;
}

.placeholder-fuchsia-600\/40::placeholder {
  color: #c026d366;
}

.placeholder-fuchsia-600\/5::placeholder {
  color: #c026d30d;
}

.placeholder-fuchsia-600\/50::placeholder {
  color: #c026d380;
}

.placeholder-fuchsia-600\/60::placeholder {
  color: #c026d399;
}

.placeholder-fuchsia-600\/70::placeholder {
  color: #c026d3b3;
}

.placeholder-fuchsia-600\/75::placeholder {
  color: #c026d3bf;
}

.placeholder-fuchsia-600\/80::placeholder {
  color: #c026d3cc;
}

.placeholder-fuchsia-600\/90::placeholder {
  color: #c026d3e6;
}

.placeholder-fuchsia-600\/95::placeholder {
  color: #c026d3f2;
}

.placeholder-fuchsia-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(162 28 175 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-700\/0::placeholder {
  color: #a21caf00;
}

.placeholder-fuchsia-700\/10::placeholder {
  color: #a21caf1a;
}

.placeholder-fuchsia-700\/100::placeholder {
  color: #a21caf;
}

.placeholder-fuchsia-700\/20::placeholder {
  color: #a21caf33;
}

.placeholder-fuchsia-700\/25::placeholder {
  color: #a21caf40;
}

.placeholder-fuchsia-700\/30::placeholder {
  color: #a21caf4d;
}

.placeholder-fuchsia-700\/40::placeholder {
  color: #a21caf66;
}

.placeholder-fuchsia-700\/5::placeholder {
  color: #a21caf0d;
}

.placeholder-fuchsia-700\/50::placeholder {
  color: #a21caf80;
}

.placeholder-fuchsia-700\/60::placeholder {
  color: #a21caf99;
}

.placeholder-fuchsia-700\/70::placeholder {
  color: #a21cafb3;
}

.placeholder-fuchsia-700\/75::placeholder {
  color: #a21cafbf;
}

.placeholder-fuchsia-700\/80::placeholder {
  color: #a21cafcc;
}

.placeholder-fuchsia-700\/90::placeholder {
  color: #a21cafe6;
}

.placeholder-fuchsia-700\/95::placeholder {
  color: #a21caff2;
}

.placeholder-fuchsia-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(134 25 143 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-800\/0::placeholder {
  color: #86198f00;
}

.placeholder-fuchsia-800\/10::placeholder {
  color: #86198f1a;
}

.placeholder-fuchsia-800\/100::placeholder {
  color: #86198f;
}

.placeholder-fuchsia-800\/20::placeholder {
  color: #86198f33;
}

.placeholder-fuchsia-800\/25::placeholder {
  color: #86198f40;
}

.placeholder-fuchsia-800\/30::placeholder {
  color: #86198f4d;
}

.placeholder-fuchsia-800\/40::placeholder {
  color: #86198f66;
}

.placeholder-fuchsia-800\/5::placeholder {
  color: #86198f0d;
}

.placeholder-fuchsia-800\/50::placeholder {
  color: #86198f80;
}

.placeholder-fuchsia-800\/60::placeholder {
  color: #86198f99;
}

.placeholder-fuchsia-800\/70::placeholder {
  color: #86198fb3;
}

.placeholder-fuchsia-800\/75::placeholder {
  color: #86198fbf;
}

.placeholder-fuchsia-800\/80::placeholder {
  color: #86198fcc;
}

.placeholder-fuchsia-800\/90::placeholder {
  color: #86198fe6;
}

.placeholder-fuchsia-800\/95::placeholder {
  color: #86198ff2;
}

.placeholder-fuchsia-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(112 26 117 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-900\/0::placeholder {
  color: #701a7500;
}

.placeholder-fuchsia-900\/10::placeholder {
  color: #701a751a;
}

.placeholder-fuchsia-900\/100::placeholder {
  color: #701a75;
}

.placeholder-fuchsia-900\/20::placeholder {
  color: #701a7533;
}

.placeholder-fuchsia-900\/25::placeholder {
  color: #701a7540;
}

.placeholder-fuchsia-900\/30::placeholder {
  color: #701a754d;
}

.placeholder-fuchsia-900\/40::placeholder {
  color: #701a7566;
}

.placeholder-fuchsia-900\/5::placeholder {
  color: #701a750d;
}

.placeholder-fuchsia-900\/50::placeholder {
  color: #701a7580;
}

.placeholder-fuchsia-900\/60::placeholder {
  color: #701a7599;
}

.placeholder-fuchsia-900\/70::placeholder {
  color: #701a75b3;
}

.placeholder-fuchsia-900\/75::placeholder {
  color: #701a75bf;
}

.placeholder-fuchsia-900\/80::placeholder {
  color: #701a75cc;
}

.placeholder-fuchsia-900\/90::placeholder {
  color: #701a75e6;
}

.placeholder-fuchsia-900\/95::placeholder {
  color: #701a75f2;
}

.placeholder-fuchsia-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(74 4 78 / var(--tw-placeholder-opacity));
}

.placeholder-fuchsia-950\/0::placeholder {
  color: #4a044e00;
}

.placeholder-fuchsia-950\/10::placeholder {
  color: #4a044e1a;
}

.placeholder-fuchsia-950\/100::placeholder {
  color: #4a044e;
}

.placeholder-fuchsia-950\/20::placeholder {
  color: #4a044e33;
}

.placeholder-fuchsia-950\/25::placeholder {
  color: #4a044e40;
}

.placeholder-fuchsia-950\/30::placeholder {
  color: #4a044e4d;
}

.placeholder-fuchsia-950\/40::placeholder {
  color: #4a044e66;
}

.placeholder-fuchsia-950\/5::placeholder {
  color: #4a044e0d;
}

.placeholder-fuchsia-950\/50::placeholder {
  color: #4a044e80;
}

.placeholder-fuchsia-950\/60::placeholder {
  color: #4a044e99;
}

.placeholder-fuchsia-950\/70::placeholder {
  color: #4a044eb3;
}

.placeholder-fuchsia-950\/75::placeholder {
  color: #4a044ebf;
}

.placeholder-fuchsia-950\/80::placeholder {
  color: #4a044ecc;
}

.placeholder-fuchsia-950\/90::placeholder {
  color: #4a044ee6;
}

.placeholder-fuchsia-950\/95::placeholder {
  color: #4a044ef2;
}

.placeholder-gray-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(240 242 243 / var(--tw-placeholder-opacity));
}

.placeholder-gray-100\/0::placeholder {
  color: #f0f2f300;
}

.placeholder-gray-100\/10::placeholder {
  color: #f0f2f31a;
}

.placeholder-gray-100\/100::placeholder {
  color: #f0f2f3;
}

.placeholder-gray-100\/20::placeholder {
  color: #f0f2f333;
}

.placeholder-gray-100\/25::placeholder {
  color: #f0f2f340;
}

.placeholder-gray-100\/30::placeholder {
  color: #f0f2f34d;
}

.placeholder-gray-100\/40::placeholder {
  color: #f0f2f366;
}

.placeholder-gray-100\/5::placeholder {
  color: #f0f2f30d;
}

.placeholder-gray-100\/50::placeholder {
  color: #f0f2f380;
}

.placeholder-gray-100\/60::placeholder {
  color: #f0f2f399;
}

.placeholder-gray-100\/70::placeholder {
  color: #f0f2f3b3;
}

.placeholder-gray-100\/75::placeholder {
  color: #f0f2f3bf;
}

.placeholder-gray-100\/80::placeholder {
  color: #f0f2f3cc;
}

.placeholder-gray-100\/90::placeholder {
  color: #f0f2f3e6;
}

.placeholder-gray-100\/95::placeholder {
  color: #f0f2f3f2;
}

.placeholder-gray-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(232 234 237 / var(--tw-placeholder-opacity));
}

.placeholder-gray-200\/0::placeholder {
  color: #e8eaed00;
}

.placeholder-gray-200\/10::placeholder {
  color: #e8eaed1a;
}

.placeholder-gray-200\/100::placeholder {
  color: #e8eaed;
}

.placeholder-gray-200\/20::placeholder {
  color: #e8eaed33;
}

.placeholder-gray-200\/25::placeholder {
  color: #e8eaed40;
}

.placeholder-gray-200\/30::placeholder {
  color: #e8eaed4d;
}

.placeholder-gray-200\/40::placeholder {
  color: #e8eaed66;
}

.placeholder-gray-200\/5::placeholder {
  color: #e8eaed0d;
}

.placeholder-gray-200\/50::placeholder {
  color: #e8eaed80;
}

.placeholder-gray-200\/60::placeholder {
  color: #e8eaed99;
}

.placeholder-gray-200\/70::placeholder {
  color: #e8eaedb3;
}

.placeholder-gray-200\/75::placeholder {
  color: #e8eaedbf;
}

.placeholder-gray-200\/80::placeholder {
  color: #e8eaedcc;
}

.placeholder-gray-200\/90::placeholder {
  color: #e8eaede6;
}

.placeholder-gray-200\/95::placeholder {
  color: #e8eaedf2;
}

.placeholder-gray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(218 220 224 / var(--tw-placeholder-opacity));
}

.placeholder-gray-300\/0::placeholder {
  color: #dadce000;
}

.placeholder-gray-300\/10::placeholder {
  color: #dadce01a;
}

.placeholder-gray-300\/100::placeholder {
  color: #dadce0;
}

.placeholder-gray-300\/20::placeholder {
  color: #dadce033;
}

.placeholder-gray-300\/25::placeholder {
  color: #dadce040;
}

.placeholder-gray-300\/30::placeholder {
  color: #dadce04d;
}

.placeholder-gray-300\/40::placeholder {
  color: #dadce066;
}

.placeholder-gray-300\/5::placeholder {
  color: #dadce00d;
}

.placeholder-gray-300\/50::placeholder {
  color: #dadce080;
}

.placeholder-gray-300\/60::placeholder {
  color: #dadce099;
}

.placeholder-gray-300\/70::placeholder {
  color: #dadce0b3;
}

.placeholder-gray-300\/75::placeholder {
  color: #dadce0bf;
}

.placeholder-gray-300\/80::placeholder {
  color: #dadce0cc;
}

.placeholder-gray-300\/90::placeholder {
  color: #dadce0e6;
}

.placeholder-gray-300\/95::placeholder {
  color: #dadce0f2;
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(154 160 166 / var(--tw-placeholder-opacity));
}

.placeholder-gray-400\/0::placeholder {
  color: #9aa0a600;
}

.placeholder-gray-400\/10::placeholder {
  color: #9aa0a61a;
}

.placeholder-gray-400\/100::placeholder {
  color: #9aa0a6;
}

.placeholder-gray-400\/20::placeholder {
  color: #9aa0a633;
}

.placeholder-gray-400\/25::placeholder {
  color: #9aa0a640;
}

.placeholder-gray-400\/30::placeholder {
  color: #9aa0a64d;
}

.placeholder-gray-400\/40::placeholder {
  color: #9aa0a666;
}

.placeholder-gray-400\/5::placeholder {
  color: #9aa0a60d;
}

.placeholder-gray-400\/50::placeholder {
  color: #9aa0a680;
}

.placeholder-gray-400\/60::placeholder {
  color: #9aa0a699;
}

.placeholder-gray-400\/70::placeholder {
  color: #9aa0a6b3;
}

.placeholder-gray-400\/75::placeholder {
  color: #9aa0a6bf;
}

.placeholder-gray-400\/80::placeholder {
  color: #9aa0a6cc;
}

.placeholder-gray-400\/90::placeholder {
  color: #9aa0a6e6;
}

.placeholder-gray-400\/95::placeholder {
  color: #9aa0a6f2;
}

.placeholder-gray-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(248 249 250 / var(--tw-placeholder-opacity));
}

.placeholder-gray-50\/0::placeholder {
  color: #f8f9fa00;
}

.placeholder-gray-50\/10::placeholder {
  color: #f8f9fa1a;
}

.placeholder-gray-50\/100::placeholder {
  color: #f8f9fa;
}

.placeholder-gray-50\/20::placeholder {
  color: #f8f9fa33;
}

.placeholder-gray-50\/25::placeholder {
  color: #f8f9fa40;
}

.placeholder-gray-50\/30::placeholder {
  color: #f8f9fa4d;
}

.placeholder-gray-50\/40::placeholder {
  color: #f8f9fa66;
}

.placeholder-gray-50\/5::placeholder {
  color: #f8f9fa0d;
}

.placeholder-gray-50\/50::placeholder {
  color: #f8f9fa80;
}

.placeholder-gray-50\/60::placeholder {
  color: #f8f9fa99;
}

.placeholder-gray-50\/70::placeholder {
  color: #f8f9fab3;
}

.placeholder-gray-50\/75::placeholder {
  color: #f8f9fabf;
}

.placeholder-gray-50\/80::placeholder {
  color: #f8f9facc;
}

.placeholder-gray-50\/90::placeholder {
  color: #f8f9fae6;
}

.placeholder-gray-50\/95::placeholder {
  color: #f8f9faf2;
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(95 99 104 / var(--tw-placeholder-opacity));
}

.placeholder-gray-500\/0::placeholder {
  color: #5f636800;
}

.placeholder-gray-500\/10::placeholder {
  color: #5f63681a;
}

.placeholder-gray-500\/100::placeholder {
  color: #5f6368;
}

.placeholder-gray-500\/20::placeholder {
  color: #5f636833;
}

.placeholder-gray-500\/25::placeholder {
  color: #5f636840;
}

.placeholder-gray-500\/30::placeholder {
  color: #5f63684d;
}

.placeholder-gray-500\/40::placeholder {
  color: #5f636866;
}

.placeholder-gray-500\/5::placeholder {
  color: #5f63680d;
}

.placeholder-gray-500\/50::placeholder {
  color: #5f636880;
}

.placeholder-gray-500\/60::placeholder {
  color: #5f636899;
}

.placeholder-gray-500\/70::placeholder {
  color: #5f6368b3;
}

.placeholder-gray-500\/75::placeholder {
  color: #5f6368bf;
}

.placeholder-gray-500\/80::placeholder {
  color: #5f6368cc;
}

.placeholder-gray-500\/90::placeholder {
  color: #5f6368e6;
}

.placeholder-gray-500\/95::placeholder {
  color: #5f6368f2;
}

.placeholder-gray-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(60 64 67 / var(--tw-placeholder-opacity));
}

.placeholder-gray-600\/0::placeholder {
  color: #3c404300;
}

.placeholder-gray-600\/10::placeholder {
  color: #3c40431a;
}

.placeholder-gray-600\/100::placeholder {
  color: #3c4043;
}

.placeholder-gray-600\/20::placeholder {
  color: #3c404333;
}

.placeholder-gray-600\/25::placeholder {
  color: #3c404340;
}

.placeholder-gray-600\/30::placeholder {
  color: #3c40434d;
}

.placeholder-gray-600\/40::placeholder {
  color: #3c404366;
}

.placeholder-gray-600\/5::placeholder {
  color: #3c40430d;
}

.placeholder-gray-600\/50::placeholder {
  color: #3c404380;
}

.placeholder-gray-600\/60::placeholder {
  color: #3c404399;
}

.placeholder-gray-600\/70::placeholder {
  color: #3c4043b3;
}

.placeholder-gray-600\/75::placeholder {
  color: #3c4043bf;
}

.placeholder-gray-600\/80::placeholder {
  color: #3c4043cc;
}

.placeholder-gray-600\/90::placeholder {
  color: #3c4043e6;
}

.placeholder-gray-600\/95::placeholder {
  color: #3c4043f2;
}

.placeholder-gray-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(48 49 52 / var(--tw-placeholder-opacity));
}

.placeholder-gray-700\/0::placeholder {
  color: #30313400;
}

.placeholder-gray-700\/10::placeholder {
  color: #3031341a;
}

.placeholder-gray-700\/100::placeholder {
  color: #303134;
}

.placeholder-gray-700\/20::placeholder {
  color: #30313433;
}

.placeholder-gray-700\/25::placeholder {
  color: #30313440;
}

.placeholder-gray-700\/30::placeholder {
  color: #3031344d;
}

.placeholder-gray-700\/40::placeholder {
  color: #30313466;
}

.placeholder-gray-700\/5::placeholder {
  color: #3031340d;
}

.placeholder-gray-700\/50::placeholder {
  color: #30313480;
}

.placeholder-gray-700\/60::placeholder {
  color: #30313499;
}

.placeholder-gray-700\/70::placeholder {
  color: #303134b3;
}

.placeholder-gray-700\/75::placeholder {
  color: #303134bf;
}

.placeholder-gray-700\/80::placeholder {
  color: #303134cc;
}

.placeholder-gray-700\/90::placeholder {
  color: #303134e6;
}

.placeholder-gray-700\/95::placeholder {
  color: #303134f2;
}

.placeholder-gray-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(40 41 44 / var(--tw-placeholder-opacity));
}

.placeholder-gray-800\/0::placeholder {
  color: #28292c00;
}

.placeholder-gray-800\/10::placeholder {
  color: #28292c1a;
}

.placeholder-gray-800\/100::placeholder {
  color: #28292c;
}

.placeholder-gray-800\/20::placeholder {
  color: #28292c33;
}

.placeholder-gray-800\/25::placeholder {
  color: #28292c40;
}

.placeholder-gray-800\/30::placeholder {
  color: #28292c4d;
}

.placeholder-gray-800\/40::placeholder {
  color: #28292c66;
}

.placeholder-gray-800\/5::placeholder {
  color: #28292c0d;
}

.placeholder-gray-800\/50::placeholder {
  color: #28292c80;
}

.placeholder-gray-800\/60::placeholder {
  color: #28292c99;
}

.placeholder-gray-800\/70::placeholder {
  color: #28292cb3;
}

.placeholder-gray-800\/75::placeholder {
  color: #28292cbf;
}

.placeholder-gray-800\/80::placeholder {
  color: #28292ccc;
}

.placeholder-gray-800\/90::placeholder {
  color: #28292ce6;
}

.placeholder-gray-800\/95::placeholder {
  color: #28292cf2;
}

.placeholder-gray-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(32 33 36 / var(--tw-placeholder-opacity));
}

.placeholder-gray-900\/0::placeholder {
  color: #20212400;
}

.placeholder-gray-900\/10::placeholder {
  color: #2021241a;
}

.placeholder-gray-900\/100::placeholder {
  color: #202124;
}

.placeholder-gray-900\/20::placeholder {
  color: #20212433;
}

.placeholder-gray-900\/25::placeholder {
  color: #20212440;
}

.placeholder-gray-900\/30::placeholder {
  color: #2021244d;
}

.placeholder-gray-900\/40::placeholder {
  color: #20212466;
}

.placeholder-gray-900\/5::placeholder {
  color: #2021240d;
}

.placeholder-gray-900\/50::placeholder {
  color: #20212480;
}

.placeholder-gray-900\/60::placeholder {
  color: #20212499;
}

.placeholder-gray-900\/70::placeholder {
  color: #202124b3;
}

.placeholder-gray-900\/75::placeholder {
  color: #202124bf;
}

.placeholder-gray-900\/80::placeholder {
  color: #202124cc;
}

.placeholder-gray-900\/90::placeholder {
  color: #202124e6;
}

.placeholder-gray-900\/95::placeholder {
  color: #202124f2;
}

.placeholder-gray-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(3 7 18 / var(--tw-placeholder-opacity));
}

.placeholder-gray-950\/0::placeholder {
  color: #03071200;
}

.placeholder-gray-950\/10::placeholder {
  color: #0307121a;
}

.placeholder-gray-950\/100::placeholder {
  color: #030712;
}

.placeholder-gray-950\/20::placeholder {
  color: #03071233;
}

.placeholder-gray-950\/25::placeholder {
  color: #03071240;
}

.placeholder-gray-950\/30::placeholder {
  color: #0307124d;
}

.placeholder-gray-950\/40::placeholder {
  color: #03071266;
}

.placeholder-gray-950\/5::placeholder {
  color: #0307120d;
}

.placeholder-gray-950\/50::placeholder {
  color: #03071280;
}

.placeholder-gray-950\/60::placeholder {
  color: #03071299;
}

.placeholder-gray-950\/70::placeholder {
  color: #030712b3;
}

.placeholder-gray-950\/75::placeholder {
  color: #030712bf;
}

.placeholder-gray-950\/80::placeholder {
  color: #030712cc;
}

.placeholder-gray-950\/90::placeholder {
  color: #030712e6;
}

.placeholder-gray-950\/95::placeholder {
  color: #030712f2;
}

.placeholder-green::placeholder {
  --tw-placeholder-opacity: 1;
  color: hsl(var(--green) / var(--tw-placeholder-opacity));
}

.placeholder-green\/0::placeholder {
  color: hsl(var(--green) / 0);
}

.placeholder-green\/10::placeholder {
  color: hsl(var(--green) / .1);
}

.placeholder-green\/100::placeholder {
  color: hsl(var(--green) / 1);
}

.placeholder-green\/20::placeholder {
  color: hsl(var(--green) / .2);
}

.placeholder-green\/25::placeholder {
  color: hsl(var(--green) / .25);
}

.placeholder-green\/30::placeholder {
  color: hsl(var(--green) / .3);
}

.placeholder-green\/40::placeholder {
  color: hsl(var(--green) / .4);
}

.placeholder-green\/5::placeholder {
  color: hsl(var(--green) / .05);
}

.placeholder-green\/50::placeholder {
  color: hsl(var(--green) / .5);
}

.placeholder-green\/60::placeholder {
  color: hsl(var(--green) / .6);
}

.placeholder-green\/70::placeholder {
  color: hsl(var(--green) / .7);
}

.placeholder-green\/75::placeholder {
  color: hsl(var(--green) / .75);
}

.placeholder-green\/80::placeholder {
  color: hsl(var(--green) / .8);
}

.placeholder-green\/90::placeholder {
  color: hsl(var(--green) / .9);
}

.placeholder-green\/95::placeholder {
  color: hsl(var(--green) / .95);
}

.placeholder-indigo-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(224 231 255 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-100\/0::placeholder {
  color: #e0e7ff00;
}

.placeholder-indigo-100\/10::placeholder {
  color: #e0e7ff1a;
}

.placeholder-indigo-100\/100::placeholder {
  color: #e0e7ff;
}

.placeholder-indigo-100\/20::placeholder {
  color: #e0e7ff33;
}

.placeholder-indigo-100\/25::placeholder {
  color: #e0e7ff40;
}

.placeholder-indigo-100\/30::placeholder {
  color: #e0e7ff4d;
}

.placeholder-indigo-100\/40::placeholder {
  color: #e0e7ff66;
}

.placeholder-indigo-100\/5::placeholder {
  color: #e0e7ff0d;
}

.placeholder-indigo-100\/50::placeholder {
  color: #e0e7ff80;
}

.placeholder-indigo-100\/60::placeholder {
  color: #e0e7ff99;
}

.placeholder-indigo-100\/70::placeholder {
  color: #e0e7ffb3;
}

.placeholder-indigo-100\/75::placeholder {
  color: #e0e7ffbf;
}

.placeholder-indigo-100\/80::placeholder {
  color: #e0e7ffcc;
}

.placeholder-indigo-100\/90::placeholder {
  color: #e0e7ffe6;
}

.placeholder-indigo-100\/95::placeholder {
  color: #e0e7fff2;
}

.placeholder-indigo-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(199 210 254 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-200\/0::placeholder {
  color: #c7d2fe00;
}

.placeholder-indigo-200\/10::placeholder {
  color: #c7d2fe1a;
}

.placeholder-indigo-200\/100::placeholder {
  color: #c7d2fe;
}

.placeholder-indigo-200\/20::placeholder {
  color: #c7d2fe33;
}

.placeholder-indigo-200\/25::placeholder {
  color: #c7d2fe40;
}

.placeholder-indigo-200\/30::placeholder {
  color: #c7d2fe4d;
}

.placeholder-indigo-200\/40::placeholder {
  color: #c7d2fe66;
}

.placeholder-indigo-200\/5::placeholder {
  color: #c7d2fe0d;
}

.placeholder-indigo-200\/50::placeholder {
  color: #c7d2fe80;
}

.placeholder-indigo-200\/60::placeholder {
  color: #c7d2fe99;
}

.placeholder-indigo-200\/70::placeholder {
  color: #c7d2feb3;
}

.placeholder-indigo-200\/75::placeholder {
  color: #c7d2febf;
}

.placeholder-indigo-200\/80::placeholder {
  color: #c7d2fecc;
}

.placeholder-indigo-200\/90::placeholder {
  color: #c7d2fee6;
}

.placeholder-indigo-200\/95::placeholder {
  color: #c7d2fef2;
}

.placeholder-indigo-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(165 180 252 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-300\/0::placeholder {
  color: #a5b4fc00;
}

.placeholder-indigo-300\/10::placeholder {
  color: #a5b4fc1a;
}

.placeholder-indigo-300\/100::placeholder {
  color: #a5b4fc;
}

.placeholder-indigo-300\/20::placeholder {
  color: #a5b4fc33;
}

.placeholder-indigo-300\/25::placeholder {
  color: #a5b4fc40;
}

.placeholder-indigo-300\/30::placeholder {
  color: #a5b4fc4d;
}

.placeholder-indigo-300\/40::placeholder {
  color: #a5b4fc66;
}

.placeholder-indigo-300\/5::placeholder {
  color: #a5b4fc0d;
}

.placeholder-indigo-300\/50::placeholder {
  color: #a5b4fc80;
}

.placeholder-indigo-300\/60::placeholder {
  color: #a5b4fc99;
}

.placeholder-indigo-300\/70::placeholder {
  color: #a5b4fcb3;
}

.placeholder-indigo-300\/75::placeholder {
  color: #a5b4fcbf;
}

.placeholder-indigo-300\/80::placeholder {
  color: #a5b4fccc;
}

.placeholder-indigo-300\/90::placeholder {
  color: #a5b4fce6;
}

.placeholder-indigo-300\/95::placeholder {
  color: #a5b4fcf2;
}

.placeholder-indigo-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(129 140 248 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-400\/0::placeholder {
  color: #818cf800;
}

.placeholder-indigo-400\/10::placeholder {
  color: #818cf81a;
}

.placeholder-indigo-400\/100::placeholder {
  color: #818cf8;
}

.placeholder-indigo-400\/20::placeholder {
  color: #818cf833;
}

.placeholder-indigo-400\/25::placeholder {
  color: #818cf840;
}

.placeholder-indigo-400\/30::placeholder {
  color: #818cf84d;
}

.placeholder-indigo-400\/40::placeholder {
  color: #818cf866;
}

.placeholder-indigo-400\/5::placeholder {
  color: #818cf80d;
}

.placeholder-indigo-400\/50::placeholder {
  color: #818cf880;
}

.placeholder-indigo-400\/60::placeholder {
  color: #818cf899;
}

.placeholder-indigo-400\/70::placeholder {
  color: #818cf8b3;
}

.placeholder-indigo-400\/75::placeholder {
  color: #818cf8bf;
}

.placeholder-indigo-400\/80::placeholder {
  color: #818cf8cc;
}

.placeholder-indigo-400\/90::placeholder {
  color: #818cf8e6;
}

.placeholder-indigo-400\/95::placeholder {
  color: #818cf8f2;
}

.placeholder-indigo-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(238 242 255 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-50\/0::placeholder {
  color: #eef2ff00;
}

.placeholder-indigo-50\/10::placeholder {
  color: #eef2ff1a;
}

.placeholder-indigo-50\/100::placeholder {
  color: #eef2ff;
}

.placeholder-indigo-50\/20::placeholder {
  color: #eef2ff33;
}

.placeholder-indigo-50\/25::placeholder {
  color: #eef2ff40;
}

.placeholder-indigo-50\/30::placeholder {
  color: #eef2ff4d;
}

.placeholder-indigo-50\/40::placeholder {
  color: #eef2ff66;
}

.placeholder-indigo-50\/5::placeholder {
  color: #eef2ff0d;
}

.placeholder-indigo-50\/50::placeholder {
  color: #eef2ff80;
}

.placeholder-indigo-50\/60::placeholder {
  color: #eef2ff99;
}

.placeholder-indigo-50\/70::placeholder {
  color: #eef2ffb3;
}

.placeholder-indigo-50\/75::placeholder {
  color: #eef2ffbf;
}

.placeholder-indigo-50\/80::placeholder {
  color: #eef2ffcc;
}

.placeholder-indigo-50\/90::placeholder {
  color: #eef2ffe6;
}

.placeholder-indigo-50\/95::placeholder {
  color: #eef2fff2;
}

.placeholder-indigo-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(99 102 241 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-500\/0::placeholder {
  color: #6366f100;
}

.placeholder-indigo-500\/10::placeholder {
  color: #6366f11a;
}

.placeholder-indigo-500\/100::placeholder {
  color: #6366f1;
}

.placeholder-indigo-500\/20::placeholder {
  color: #6366f133;
}

.placeholder-indigo-500\/25::placeholder {
  color: #6366f140;
}

.placeholder-indigo-500\/30::placeholder {
  color: #6366f14d;
}

.placeholder-indigo-500\/40::placeholder {
  color: #6366f166;
}

.placeholder-indigo-500\/5::placeholder {
  color: #6366f10d;
}

.placeholder-indigo-500\/50::placeholder {
  color: #6366f180;
}

.placeholder-indigo-500\/60::placeholder {
  color: #6366f199;
}

.placeholder-indigo-500\/70::placeholder {
  color: #6366f1b3;
}

.placeholder-indigo-500\/75::placeholder {
  color: #6366f1bf;
}

.placeholder-indigo-500\/80::placeholder {
  color: #6366f1cc;
}

.placeholder-indigo-500\/90::placeholder {
  color: #6366f1e6;
}

.placeholder-indigo-500\/95::placeholder {
  color: #6366f1f2;
}

.placeholder-indigo-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(79 70 229 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-600\/0::placeholder {
  color: #4f46e500;
}

.placeholder-indigo-600\/10::placeholder {
  color: #4f46e51a;
}

.placeholder-indigo-600\/100::placeholder {
  color: #4f46e5;
}

.placeholder-indigo-600\/20::placeholder {
  color: #4f46e533;
}

.placeholder-indigo-600\/25::placeholder {
  color: #4f46e540;
}

.placeholder-indigo-600\/30::placeholder {
  color: #4f46e54d;
}

.placeholder-indigo-600\/40::placeholder {
  color: #4f46e566;
}

.placeholder-indigo-600\/5::placeholder {
  color: #4f46e50d;
}

.placeholder-indigo-600\/50::placeholder {
  color: #4f46e580;
}

.placeholder-indigo-600\/60::placeholder {
  color: #4f46e599;
}

.placeholder-indigo-600\/70::placeholder {
  color: #4f46e5b3;
}

.placeholder-indigo-600\/75::placeholder {
  color: #4f46e5bf;
}

.placeholder-indigo-600\/80::placeholder {
  color: #4f46e5cc;
}

.placeholder-indigo-600\/90::placeholder {
  color: #4f46e5e6;
}

.placeholder-indigo-600\/95::placeholder {
  color: #4f46e5f2;
}

.placeholder-indigo-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(67 56 202 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-700\/0::placeholder {
  color: #4338ca00;
}

.placeholder-indigo-700\/10::placeholder {
  color: #4338ca1a;
}

.placeholder-indigo-700\/100::placeholder {
  color: #4338ca;
}

.placeholder-indigo-700\/20::placeholder {
  color: #4338ca33;
}

.placeholder-indigo-700\/25::placeholder {
  color: #4338ca40;
}

.placeholder-indigo-700\/30::placeholder {
  color: #4338ca4d;
}

.placeholder-indigo-700\/40::placeholder {
  color: #4338ca66;
}

.placeholder-indigo-700\/5::placeholder {
  color: #4338ca0d;
}

.placeholder-indigo-700\/50::placeholder {
  color: #4338ca80;
}

.placeholder-indigo-700\/60::placeholder {
  color: #4338ca99;
}

.placeholder-indigo-700\/70::placeholder {
  color: #4338cab3;
}

.placeholder-indigo-700\/75::placeholder {
  color: #4338cabf;
}

.placeholder-indigo-700\/80::placeholder {
  color: #4338cacc;
}

.placeholder-indigo-700\/90::placeholder {
  color: #4338cae6;
}

.placeholder-indigo-700\/95::placeholder {
  color: #4338caf2;
}

.placeholder-indigo-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(55 48 163 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-800\/0::placeholder {
  color: #3730a300;
}

.placeholder-indigo-800\/10::placeholder {
  color: #3730a31a;
}

.placeholder-indigo-800\/100::placeholder {
  color: #3730a3;
}

.placeholder-indigo-800\/20::placeholder {
  color: #3730a333;
}

.placeholder-indigo-800\/25::placeholder {
  color: #3730a340;
}

.placeholder-indigo-800\/30::placeholder {
  color: #3730a34d;
}

.placeholder-indigo-800\/40::placeholder {
  color: #3730a366;
}

.placeholder-indigo-800\/5::placeholder {
  color: #3730a30d;
}

.placeholder-indigo-800\/50::placeholder {
  color: #3730a380;
}

.placeholder-indigo-800\/60::placeholder {
  color: #3730a399;
}

.placeholder-indigo-800\/70::placeholder {
  color: #3730a3b3;
}

.placeholder-indigo-800\/75::placeholder {
  color: #3730a3bf;
}

.placeholder-indigo-800\/80::placeholder {
  color: #3730a3cc;
}

.placeholder-indigo-800\/90::placeholder {
  color: #3730a3e6;
}

.placeholder-indigo-800\/95::placeholder {
  color: #3730a3f2;
}

.placeholder-indigo-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(49 46 129 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-900\/0::placeholder {
  color: #312e8100;
}

.placeholder-indigo-900\/10::placeholder {
  color: #312e811a;
}

.placeholder-indigo-900\/100::placeholder {
  color: #312e81;
}

.placeholder-indigo-900\/20::placeholder {
  color: #312e8133;
}

.placeholder-indigo-900\/25::placeholder {
  color: #312e8140;
}

.placeholder-indigo-900\/30::placeholder {
  color: #312e814d;
}

.placeholder-indigo-900\/40::placeholder {
  color: #312e8166;
}

.placeholder-indigo-900\/5::placeholder {
  color: #312e810d;
}

.placeholder-indigo-900\/50::placeholder {
  color: #312e8180;
}

.placeholder-indigo-900\/60::placeholder {
  color: #312e8199;
}

.placeholder-indigo-900\/70::placeholder {
  color: #312e81b3;
}

.placeholder-indigo-900\/75::placeholder {
  color: #312e81bf;
}

.placeholder-indigo-900\/80::placeholder {
  color: #312e81cc;
}

.placeholder-indigo-900\/90::placeholder {
  color: #312e81e6;
}

.placeholder-indigo-900\/95::placeholder {
  color: #312e81f2;
}

.placeholder-indigo-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(30 27 75 / var(--tw-placeholder-opacity));
}

.placeholder-indigo-950\/0::placeholder {
  color: #1e1b4b00;
}

.placeholder-indigo-950\/10::placeholder {
  color: #1e1b4b1a;
}

.placeholder-indigo-950\/100::placeholder {
  color: #1e1b4b;
}

.placeholder-indigo-950\/20::placeholder {
  color: #1e1b4b33;
}

.placeholder-indigo-950\/25::placeholder {
  color: #1e1b4b40;
}

.placeholder-indigo-950\/30::placeholder {
  color: #1e1b4b4d;
}

.placeholder-indigo-950\/40::placeholder {
  color: #1e1b4b66;
}

.placeholder-indigo-950\/5::placeholder {
  color: #1e1b4b0d;
}

.placeholder-indigo-950\/50::placeholder {
  color: #1e1b4b80;
}

.placeholder-indigo-950\/60::placeholder {
  color: #1e1b4b99;
}

.placeholder-indigo-950\/70::placeholder {
  color: #1e1b4bb3;
}

.placeholder-indigo-950\/75::placeholder {
  color: #1e1b4bbf;
}

.placeholder-indigo-950\/80::placeholder {
  color: #1e1b4bcc;
}

.placeholder-indigo-950\/90::placeholder {
  color: #1e1b4be6;
}

.placeholder-indigo-950\/95::placeholder {
  color: #1e1b4bf2;
}

.placeholder-inherit::placeholder {
  color: inherit;
}

.placeholder-lime-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(236 252 203 / var(--tw-placeholder-opacity));
}

.placeholder-lime-100\/0::placeholder {
  color: #ecfccb00;
}

.placeholder-lime-100\/10::placeholder {
  color: #ecfccb1a;
}

.placeholder-lime-100\/100::placeholder {
  color: #ecfccb;
}

.placeholder-lime-100\/20::placeholder {
  color: #ecfccb33;
}

.placeholder-lime-100\/25::placeholder {
  color: #ecfccb40;
}

.placeholder-lime-100\/30::placeholder {
  color: #ecfccb4d;
}

.placeholder-lime-100\/40::placeholder {
  color: #ecfccb66;
}

.placeholder-lime-100\/5::placeholder {
  color: #ecfccb0d;
}

.placeholder-lime-100\/50::placeholder {
  color: #ecfccb80;
}

.placeholder-lime-100\/60::placeholder {
  color: #ecfccb99;
}

.placeholder-lime-100\/70::placeholder {
  color: #ecfccbb3;
}

.placeholder-lime-100\/75::placeholder {
  color: #ecfccbbf;
}

.placeholder-lime-100\/80::placeholder {
  color: #ecfccbcc;
}

.placeholder-lime-100\/90::placeholder {
  color: #ecfccbe6;
}

.placeholder-lime-100\/95::placeholder {
  color: #ecfccbf2;
}

.placeholder-lime-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(217 249 157 / var(--tw-placeholder-opacity));
}

.placeholder-lime-200\/0::placeholder {
  color: #d9f99d00;
}

.placeholder-lime-200\/10::placeholder {
  color: #d9f99d1a;
}

.placeholder-lime-200\/100::placeholder {
  color: #d9f99d;
}

.placeholder-lime-200\/20::placeholder {
  color: #d9f99d33;
}

.placeholder-lime-200\/25::placeholder {
  color: #d9f99d40;
}

.placeholder-lime-200\/30::placeholder {
  color: #d9f99d4d;
}

.placeholder-lime-200\/40::placeholder {
  color: #d9f99d66;
}

.placeholder-lime-200\/5::placeholder {
  color: #d9f99d0d;
}

.placeholder-lime-200\/50::placeholder {
  color: #d9f99d80;
}

.placeholder-lime-200\/60::placeholder {
  color: #d9f99d99;
}

.placeholder-lime-200\/70::placeholder {
  color: #d9f99db3;
}

.placeholder-lime-200\/75::placeholder {
  color: #d9f99dbf;
}

.placeholder-lime-200\/80::placeholder {
  color: #d9f99dcc;
}

.placeholder-lime-200\/90::placeholder {
  color: #d9f99de6;
}

.placeholder-lime-200\/95::placeholder {
  color: #d9f99df2;
}

.placeholder-lime-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(190 242 100 / var(--tw-placeholder-opacity));
}

.placeholder-lime-300\/0::placeholder {
  color: #bef26400;
}

.placeholder-lime-300\/10::placeholder {
  color: #bef2641a;
}

.placeholder-lime-300\/100::placeholder {
  color: #bef264;
}

.placeholder-lime-300\/20::placeholder {
  color: #bef26433;
}

.placeholder-lime-300\/25::placeholder {
  color: #bef26440;
}

.placeholder-lime-300\/30::placeholder {
  color: #bef2644d;
}

.placeholder-lime-300\/40::placeholder {
  color: #bef26466;
}

.placeholder-lime-300\/5::placeholder {
  color: #bef2640d;
}

.placeholder-lime-300\/50::placeholder {
  color: #bef26480;
}

.placeholder-lime-300\/60::placeholder {
  color: #bef26499;
}

.placeholder-lime-300\/70::placeholder {
  color: #bef264b3;
}

.placeholder-lime-300\/75::placeholder {
  color: #bef264bf;
}

.placeholder-lime-300\/80::placeholder {
  color: #bef264cc;
}

.placeholder-lime-300\/90::placeholder {
  color: #bef264e6;
}

.placeholder-lime-300\/95::placeholder {
  color: #bef264f2;
}

.placeholder-lime-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(163 230 53 / var(--tw-placeholder-opacity));
}

.placeholder-lime-400\/0::placeholder {
  color: #a3e63500;
}

.placeholder-lime-400\/10::placeholder {
  color: #a3e6351a;
}

.placeholder-lime-400\/100::placeholder {
  color: #a3e635;
}

.placeholder-lime-400\/20::placeholder {
  color: #a3e63533;
}

.placeholder-lime-400\/25::placeholder {
  color: #a3e63540;
}

.placeholder-lime-400\/30::placeholder {
  color: #a3e6354d;
}

.placeholder-lime-400\/40::placeholder {
  color: #a3e63566;
}

.placeholder-lime-400\/5::placeholder {
  color: #a3e6350d;
}

.placeholder-lime-400\/50::placeholder {
  color: #a3e63580;
}

.placeholder-lime-400\/60::placeholder {
  color: #a3e63599;
}

.placeholder-lime-400\/70::placeholder {
  color: #a3e635b3;
}

.placeholder-lime-400\/75::placeholder {
  color: #a3e635bf;
}

.placeholder-lime-400\/80::placeholder {
  color: #a3e635cc;
}

.placeholder-lime-400\/90::placeholder {
  color: #a3e635e6;
}

.placeholder-lime-400\/95::placeholder {
  color: #a3e635f2;
}

.placeholder-lime-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(247 254 231 / var(--tw-placeholder-opacity));
}

.placeholder-lime-50\/0::placeholder {
  color: #f7fee700;
}

.placeholder-lime-50\/10::placeholder {
  color: #f7fee71a;
}

.placeholder-lime-50\/100::placeholder {
  color: #f7fee7;
}

.placeholder-lime-50\/20::placeholder {
  color: #f7fee733;
}

.placeholder-lime-50\/25::placeholder {
  color: #f7fee740;
}

.placeholder-lime-50\/30::placeholder {
  color: #f7fee74d;
}

.placeholder-lime-50\/40::placeholder {
  color: #f7fee766;
}

.placeholder-lime-50\/5::placeholder {
  color: #f7fee70d;
}

.placeholder-lime-50\/50::placeholder {
  color: #f7fee780;
}

.placeholder-lime-50\/60::placeholder {
  color: #f7fee799;
}

.placeholder-lime-50\/70::placeholder {
  color: #f7fee7b3;
}

.placeholder-lime-50\/75::placeholder {
  color: #f7fee7bf;
}

.placeholder-lime-50\/80::placeholder {
  color: #f7fee7cc;
}

.placeholder-lime-50\/90::placeholder {
  color: #f7fee7e6;
}

.placeholder-lime-50\/95::placeholder {
  color: #f7fee7f2;
}

.placeholder-lime-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(132 204 22 / var(--tw-placeholder-opacity));
}

.placeholder-lime-500\/0::placeholder {
  color: #84cc1600;
}

.placeholder-lime-500\/10::placeholder {
  color: #84cc161a;
}

.placeholder-lime-500\/100::placeholder {
  color: #84cc16;
}

.placeholder-lime-500\/20::placeholder {
  color: #84cc1633;
}

.placeholder-lime-500\/25::placeholder {
  color: #84cc1640;
}

.placeholder-lime-500\/30::placeholder {
  color: #84cc164d;
}

.placeholder-lime-500\/40::placeholder {
  color: #84cc1666;
}

.placeholder-lime-500\/5::placeholder {
  color: #84cc160d;
}

.placeholder-lime-500\/50::placeholder {
  color: #84cc1680;
}

.placeholder-lime-500\/60::placeholder {
  color: #84cc1699;
}

.placeholder-lime-500\/70::placeholder {
  color: #84cc16b3;
}

.placeholder-lime-500\/75::placeholder {
  color: #84cc16bf;
}

.placeholder-lime-500\/80::placeholder {
  color: #84cc16cc;
}

.placeholder-lime-500\/90::placeholder {
  color: #84cc16e6;
}

.placeholder-lime-500\/95::placeholder {
  color: #84cc16f2;
}

.placeholder-lime-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(101 163 13 / var(--tw-placeholder-opacity));
}

.placeholder-lime-600\/0::placeholder {
  color: #65a30d00;
}

.placeholder-lime-600\/10::placeholder {
  color: #65a30d1a;
}

.placeholder-lime-600\/100::placeholder {
  color: #65a30d;
}

.placeholder-lime-600\/20::placeholder {
  color: #65a30d33;
}

.placeholder-lime-600\/25::placeholder {
  color: #65a30d40;
}

.placeholder-lime-600\/30::placeholder {
  color: #65a30d4d;
}

.placeholder-lime-600\/40::placeholder {
  color: #65a30d66;
}

.placeholder-lime-600\/5::placeholder {
  color: #65a30d0d;
}

.placeholder-lime-600\/50::placeholder {
  color: #65a30d80;
}

.placeholder-lime-600\/60::placeholder {
  color: #65a30d99;
}

.placeholder-lime-600\/70::placeholder {
  color: #65a30db3;
}

.placeholder-lime-600\/75::placeholder {
  color: #65a30dbf;
}

.placeholder-lime-600\/80::placeholder {
  color: #65a30dcc;
}

.placeholder-lime-600\/90::placeholder {
  color: #65a30de6;
}

.placeholder-lime-600\/95::placeholder {
  color: #65a30df2;
}

.placeholder-lime-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(77 124 15 / var(--tw-placeholder-opacity));
}

.placeholder-lime-700\/0::placeholder {
  color: #4d7c0f00;
}

.placeholder-lime-700\/10::placeholder {
  color: #4d7c0f1a;
}

.placeholder-lime-700\/100::placeholder {
  color: #4d7c0f;
}

.placeholder-lime-700\/20::placeholder {
  color: #4d7c0f33;
}

.placeholder-lime-700\/25::placeholder {
  color: #4d7c0f40;
}

.placeholder-lime-700\/30::placeholder {
  color: #4d7c0f4d;
}

.placeholder-lime-700\/40::placeholder {
  color: #4d7c0f66;
}

.placeholder-lime-700\/5::placeholder {
  color: #4d7c0f0d;
}

.placeholder-lime-700\/50::placeholder {
  color: #4d7c0f80;
}

.placeholder-lime-700\/60::placeholder {
  color: #4d7c0f99;
}

.placeholder-lime-700\/70::placeholder {
  color: #4d7c0fb3;
}

.placeholder-lime-700\/75::placeholder {
  color: #4d7c0fbf;
}

.placeholder-lime-700\/80::placeholder {
  color: #4d7c0fcc;
}

.placeholder-lime-700\/90::placeholder {
  color: #4d7c0fe6;
}

.placeholder-lime-700\/95::placeholder {
  color: #4d7c0ff2;
}

.placeholder-lime-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(63 98 18 / var(--tw-placeholder-opacity));
}

.placeholder-lime-800\/0::placeholder {
  color: #3f621200;
}

.placeholder-lime-800\/10::placeholder {
  color: #3f62121a;
}

.placeholder-lime-800\/100::placeholder {
  color: #3f6212;
}

.placeholder-lime-800\/20::placeholder {
  color: #3f621233;
}

.placeholder-lime-800\/25::placeholder {
  color: #3f621240;
}

.placeholder-lime-800\/30::placeholder {
  color: #3f62124d;
}

.placeholder-lime-800\/40::placeholder {
  color: #3f621266;
}

.placeholder-lime-800\/5::placeholder {
  color: #3f62120d;
}

.placeholder-lime-800\/50::placeholder {
  color: #3f621280;
}

.placeholder-lime-800\/60::placeholder {
  color: #3f621299;
}

.placeholder-lime-800\/70::placeholder {
  color: #3f6212b3;
}

.placeholder-lime-800\/75::placeholder {
  color: #3f6212bf;
}

.placeholder-lime-800\/80::placeholder {
  color: #3f6212cc;
}

.placeholder-lime-800\/90::placeholder {
  color: #3f6212e6;
}

.placeholder-lime-800\/95::placeholder {
  color: #3f6212f2;
}

.placeholder-lime-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(54 83 20 / var(--tw-placeholder-opacity));
}

.placeholder-lime-900\/0::placeholder {
  color: #36531400;
}

.placeholder-lime-900\/10::placeholder {
  color: #3653141a;
}

.placeholder-lime-900\/100::placeholder {
  color: #365314;
}

.placeholder-lime-900\/20::placeholder {
  color: #36531433;
}

.placeholder-lime-900\/25::placeholder {
  color: #36531440;
}

.placeholder-lime-900\/30::placeholder {
  color: #3653144d;
}

.placeholder-lime-900\/40::placeholder {
  color: #36531466;
}

.placeholder-lime-900\/5::placeholder {
  color: #3653140d;
}

.placeholder-lime-900\/50::placeholder {
  color: #36531480;
}

.placeholder-lime-900\/60::placeholder {
  color: #36531499;
}

.placeholder-lime-900\/70::placeholder {
  color: #365314b3;
}

.placeholder-lime-900\/75::placeholder {
  color: #365314bf;
}

.placeholder-lime-900\/80::placeholder {
  color: #365314cc;
}

.placeholder-lime-900\/90::placeholder {
  color: #365314e6;
}

.placeholder-lime-900\/95::placeholder {
  color: #365314f2;
}

.placeholder-lime-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(26 46 5 / var(--tw-placeholder-opacity));
}

.placeholder-lime-950\/0::placeholder {
  color: #1a2e0500;
}

.placeholder-lime-950\/10::placeholder {
  color: #1a2e051a;
}

.placeholder-lime-950\/100::placeholder {
  color: #1a2e05;
}

.placeholder-lime-950\/20::placeholder {
  color: #1a2e0533;
}

.placeholder-lime-950\/25::placeholder {
  color: #1a2e0540;
}

.placeholder-lime-950\/30::placeholder {
  color: #1a2e054d;
}

.placeholder-lime-950\/40::placeholder {
  color: #1a2e0566;
}

.placeholder-lime-950\/5::placeholder {
  color: #1a2e050d;
}

.placeholder-lime-950\/50::placeholder {
  color: #1a2e0580;
}

.placeholder-lime-950\/60::placeholder {
  color: #1a2e0599;
}

.placeholder-lime-950\/70::placeholder {
  color: #1a2e05b3;
}

.placeholder-lime-950\/75::placeholder {
  color: #1a2e05bf;
}

.placeholder-lime-950\/80::placeholder {
  color: #1a2e05cc;
}

.placeholder-lime-950\/90::placeholder {
  color: #1a2e05e6;
}

.placeholder-lime-950\/95::placeholder {
  color: #1a2e05f2;
}

.placeholder-neutral-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(245 245 245 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-100\/0::placeholder {
  color: #f5f5f500;
}

.placeholder-neutral-100\/10::placeholder {
  color: #f5f5f51a;
}

.placeholder-neutral-100\/100::placeholder {
  color: #f5f5f5;
}

.placeholder-neutral-100\/20::placeholder {
  color: #f5f5f533;
}

.placeholder-neutral-100\/25::placeholder {
  color: #f5f5f540;
}

.placeholder-neutral-100\/30::placeholder {
  color: #f5f5f54d;
}

.placeholder-neutral-100\/40::placeholder {
  color: #f5f5f566;
}

.placeholder-neutral-100\/5::placeholder {
  color: #f5f5f50d;
}

.placeholder-neutral-100\/50::placeholder {
  color: #f5f5f580;
}

.placeholder-neutral-100\/60::placeholder {
  color: #f5f5f599;
}

.placeholder-neutral-100\/70::placeholder {
  color: #f5f5f5b3;
}

.placeholder-neutral-100\/75::placeholder {
  color: #f5f5f5bf;
}

.placeholder-neutral-100\/80::placeholder {
  color: #f5f5f5cc;
}

.placeholder-neutral-100\/90::placeholder {
  color: #f5f5f5e6;
}

.placeholder-neutral-100\/95::placeholder {
  color: #f5f5f5f2;
}

.placeholder-neutral-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(229 229 229 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-200\/0::placeholder {
  color: #e5e5e500;
}

.placeholder-neutral-200\/10::placeholder {
  color: #e5e5e51a;
}

.placeholder-neutral-200\/100::placeholder {
  color: #e5e5e5;
}

.placeholder-neutral-200\/20::placeholder {
  color: #e5e5e533;
}

.placeholder-neutral-200\/25::placeholder {
  color: #e5e5e540;
}

.placeholder-neutral-200\/30::placeholder {
  color: #e5e5e54d;
}

.placeholder-neutral-200\/40::placeholder {
  color: #e5e5e566;
}

.placeholder-neutral-200\/5::placeholder {
  color: #e5e5e50d;
}

.placeholder-neutral-200\/50::placeholder {
  color: #e5e5e580;
}

.placeholder-neutral-200\/60::placeholder {
  color: #e5e5e599;
}

.placeholder-neutral-200\/70::placeholder {
  color: #e5e5e5b3;
}

.placeholder-neutral-200\/75::placeholder {
  color: #e5e5e5bf;
}

.placeholder-neutral-200\/80::placeholder {
  color: #e5e5e5cc;
}

.placeholder-neutral-200\/90::placeholder {
  color: #e5e5e5e6;
}

.placeholder-neutral-200\/95::placeholder {
  color: #e5e5e5f2;
}

.placeholder-neutral-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(212 212 212 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-300\/0::placeholder {
  color: #d4d4d400;
}

.placeholder-neutral-300\/10::placeholder {
  color: #d4d4d41a;
}

.placeholder-neutral-300\/100::placeholder {
  color: #d4d4d4;
}

.placeholder-neutral-300\/20::placeholder {
  color: #d4d4d433;
}

.placeholder-neutral-300\/25::placeholder {
  color: #d4d4d440;
}

.placeholder-neutral-300\/30::placeholder {
  color: #d4d4d44d;
}

.placeholder-neutral-300\/40::placeholder {
  color: #d4d4d466;
}

.placeholder-neutral-300\/5::placeholder {
  color: #d4d4d40d;
}

.placeholder-neutral-300\/50::placeholder {
  color: #d4d4d480;
}

.placeholder-neutral-300\/60::placeholder {
  color: #d4d4d499;
}

.placeholder-neutral-300\/70::placeholder {
  color: #d4d4d4b3;
}

.placeholder-neutral-300\/75::placeholder {
  color: #d4d4d4bf;
}

.placeholder-neutral-300\/80::placeholder {
  color: #d4d4d4cc;
}

.placeholder-neutral-300\/90::placeholder {
  color: #d4d4d4e6;
}

.placeholder-neutral-300\/95::placeholder {
  color: #d4d4d4f2;
}

.placeholder-neutral-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(163 163 163 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-400\/0::placeholder {
  color: #a3a3a300;
}

.placeholder-neutral-400\/10::placeholder {
  color: #a3a3a31a;
}

.placeholder-neutral-400\/100::placeholder {
  color: #a3a3a3;
}

.placeholder-neutral-400\/20::placeholder {
  color: #a3a3a333;
}

.placeholder-neutral-400\/25::placeholder {
  color: #a3a3a340;
}

.placeholder-neutral-400\/30::placeholder {
  color: #a3a3a34d;
}

.placeholder-neutral-400\/40::placeholder {
  color: #a3a3a366;
}

.placeholder-neutral-400\/5::placeholder {
  color: #a3a3a30d;
}

.placeholder-neutral-400\/50::placeholder {
  color: #a3a3a380;
}

.placeholder-neutral-400\/60::placeholder {
  color: #a3a3a399;
}

.placeholder-neutral-400\/70::placeholder {
  color: #a3a3a3b3;
}

.placeholder-neutral-400\/75::placeholder {
  color: #a3a3a3bf;
}

.placeholder-neutral-400\/80::placeholder {
  color: #a3a3a3cc;
}

.placeholder-neutral-400\/90::placeholder {
  color: #a3a3a3e6;
}

.placeholder-neutral-400\/95::placeholder {
  color: #a3a3a3f2;
}

.placeholder-neutral-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(250 250 250 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-50\/0::placeholder {
  color: #fafafa00;
}

.placeholder-neutral-50\/10::placeholder {
  color: #fafafa1a;
}

.placeholder-neutral-50\/100::placeholder {
  color: #fafafa;
}

.placeholder-neutral-50\/20::placeholder {
  color: #fafafa33;
}

.placeholder-neutral-50\/25::placeholder {
  color: #fafafa40;
}

.placeholder-neutral-50\/30::placeholder {
  color: #fafafa4d;
}

.placeholder-neutral-50\/40::placeholder {
  color: #fafafa66;
}

.placeholder-neutral-50\/5::placeholder {
  color: #fafafa0d;
}

.placeholder-neutral-50\/50::placeholder {
  color: #fafafa80;
}

.placeholder-neutral-50\/60::placeholder {
  color: #fafafa99;
}

.placeholder-neutral-50\/70::placeholder {
  color: #fafafab3;
}

.placeholder-neutral-50\/75::placeholder {
  color: #fafafabf;
}

.placeholder-neutral-50\/80::placeholder {
  color: #fafafacc;
}

.placeholder-neutral-50\/90::placeholder {
  color: #fafafae6;
}

.placeholder-neutral-50\/95::placeholder {
  color: #fafafaf2;
}

.placeholder-neutral-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(115 115 115 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-500\/0::placeholder {
  color: #73737300;
}

.placeholder-neutral-500\/10::placeholder {
  color: #7373731a;
}

.placeholder-neutral-500\/100::placeholder {
  color: #737373;
}

.placeholder-neutral-500\/20::placeholder {
  color: #73737333;
}

.placeholder-neutral-500\/25::placeholder {
  color: #73737340;
}

.placeholder-neutral-500\/30::placeholder {
  color: #7373734d;
}

.placeholder-neutral-500\/40::placeholder {
  color: #73737366;
}

.placeholder-neutral-500\/5::placeholder {
  color: #7373730d;
}

.placeholder-neutral-500\/50::placeholder {
  color: #73737380;
}

.placeholder-neutral-500\/60::placeholder {
  color: #73737399;
}

.placeholder-neutral-500\/70::placeholder {
  color: #737373b3;
}

.placeholder-neutral-500\/75::placeholder {
  color: #737373bf;
}

.placeholder-neutral-500\/80::placeholder {
  color: #737373cc;
}

.placeholder-neutral-500\/90::placeholder {
  color: #737373e6;
}

.placeholder-neutral-500\/95::placeholder {
  color: #737373f2;
}

.placeholder-neutral-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(82 82 82 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-600\/0::placeholder {
  color: #52525200;
}

.placeholder-neutral-600\/10::placeholder {
  color: #5252521a;
}

.placeholder-neutral-600\/100::placeholder {
  color: #525252;
}

.placeholder-neutral-600\/20::placeholder {
  color: #52525233;
}

.placeholder-neutral-600\/25::placeholder {
  color: #52525240;
}

.placeholder-neutral-600\/30::placeholder {
  color: #5252524d;
}

.placeholder-neutral-600\/40::placeholder {
  color: #52525266;
}

.placeholder-neutral-600\/5::placeholder {
  color: #5252520d;
}

.placeholder-neutral-600\/50::placeholder {
  color: #52525280;
}

.placeholder-neutral-600\/60::placeholder {
  color: #52525299;
}

.placeholder-neutral-600\/70::placeholder {
  color: #525252b3;
}

.placeholder-neutral-600\/75::placeholder {
  color: #525252bf;
}

.placeholder-neutral-600\/80::placeholder {
  color: #525252cc;
}

.placeholder-neutral-600\/90::placeholder {
  color: #525252e6;
}

.placeholder-neutral-600\/95::placeholder {
  color: #525252f2;
}

.placeholder-neutral-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(64 64 64 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-700\/0::placeholder {
  color: #40404000;
}

.placeholder-neutral-700\/10::placeholder {
  color: #4040401a;
}

.placeholder-neutral-700\/100::placeholder {
  color: #404040;
}

.placeholder-neutral-700\/20::placeholder {
  color: #40404033;
}

.placeholder-neutral-700\/25::placeholder {
  color: #40404040;
}

.placeholder-neutral-700\/30::placeholder {
  color: #4040404d;
}

.placeholder-neutral-700\/40::placeholder {
  color: #40404066;
}

.placeholder-neutral-700\/5::placeholder {
  color: #4040400d;
}

.placeholder-neutral-700\/50::placeholder {
  color: #40404080;
}

.placeholder-neutral-700\/60::placeholder {
  color: #40404099;
}

.placeholder-neutral-700\/70::placeholder {
  color: #404040b3;
}

.placeholder-neutral-700\/75::placeholder {
  color: #404040bf;
}

.placeholder-neutral-700\/80::placeholder {
  color: #404040cc;
}

.placeholder-neutral-700\/90::placeholder {
  color: #404040e6;
}

.placeholder-neutral-700\/95::placeholder {
  color: #404040f2;
}

.placeholder-neutral-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(38 38 38 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-800\/0::placeholder {
  color: #26262600;
}

.placeholder-neutral-800\/10::placeholder {
  color: #2626261a;
}

.placeholder-neutral-800\/100::placeholder {
  color: #262626;
}

.placeholder-neutral-800\/20::placeholder {
  color: #26262633;
}

.placeholder-neutral-800\/25::placeholder {
  color: #26262640;
}

.placeholder-neutral-800\/30::placeholder {
  color: #2626264d;
}

.placeholder-neutral-800\/40::placeholder {
  color: #26262666;
}

.placeholder-neutral-800\/5::placeholder {
  color: #2626260d;
}

.placeholder-neutral-800\/50::placeholder {
  color: #26262680;
}

.placeholder-neutral-800\/60::placeholder {
  color: #26262699;
}

.placeholder-neutral-800\/70::placeholder {
  color: #262626b3;
}

.placeholder-neutral-800\/75::placeholder {
  color: #262626bf;
}

.placeholder-neutral-800\/80::placeholder {
  color: #262626cc;
}

.placeholder-neutral-800\/90::placeholder {
  color: #262626e6;
}

.placeholder-neutral-800\/95::placeholder {
  color: #262626f2;
}

.placeholder-neutral-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(23 23 23 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-900\/0::placeholder {
  color: #17171700;
}

.placeholder-neutral-900\/10::placeholder {
  color: #1717171a;
}

.placeholder-neutral-900\/100::placeholder {
  color: #171717;
}

.placeholder-neutral-900\/20::placeholder {
  color: #17171733;
}

.placeholder-neutral-900\/25::placeholder {
  color: #17171740;
}

.placeholder-neutral-900\/30::placeholder {
  color: #1717174d;
}

.placeholder-neutral-900\/40::placeholder {
  color: #17171766;
}

.placeholder-neutral-900\/5::placeholder {
  color: #1717170d;
}

.placeholder-neutral-900\/50::placeholder {
  color: #17171780;
}

.placeholder-neutral-900\/60::placeholder {
  color: #17171799;
}

.placeholder-neutral-900\/70::placeholder {
  color: #171717b3;
}

.placeholder-neutral-900\/75::placeholder {
  color: #171717bf;
}

.placeholder-neutral-900\/80::placeholder {
  color: #171717cc;
}

.placeholder-neutral-900\/90::placeholder {
  color: #171717e6;
}

.placeholder-neutral-900\/95::placeholder {
  color: #171717f2;
}

.placeholder-neutral-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(10 10 10 / var(--tw-placeholder-opacity));
}

.placeholder-neutral-950\/0::placeholder {
  color: #0a0a0a00;
}

.placeholder-neutral-950\/10::placeholder {
  color: #0a0a0a1a;
}

.placeholder-neutral-950\/100::placeholder {
  color: #0a0a0a;
}

.placeholder-neutral-950\/20::placeholder {
  color: #0a0a0a33;
}

.placeholder-neutral-950\/25::placeholder {
  color: #0a0a0a40;
}

.placeholder-neutral-950\/30::placeholder {
  color: #0a0a0a4d;
}

.placeholder-neutral-950\/40::placeholder {
  color: #0a0a0a66;
}

.placeholder-neutral-950\/5::placeholder {
  color: #0a0a0a0d;
}

.placeholder-neutral-950\/50::placeholder {
  color: #0a0a0a80;
}

.placeholder-neutral-950\/60::placeholder {
  color: #0a0a0a99;
}

.placeholder-neutral-950\/70::placeholder {
  color: #0a0a0ab3;
}

.placeholder-neutral-950\/75::placeholder {
  color: #0a0a0abf;
}

.placeholder-neutral-950\/80::placeholder {
  color: #0a0a0acc;
}

.placeholder-neutral-950\/90::placeholder {
  color: #0a0a0ae6;
}

.placeholder-neutral-950\/95::placeholder {
  color: #0a0a0af2;
}

.placeholder-orange-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 237 213 / var(--tw-placeholder-opacity));
}

.placeholder-orange-100\/0::placeholder {
  color: #ffedd500;
}

.placeholder-orange-100\/10::placeholder {
  color: #ffedd51a;
}

.placeholder-orange-100\/100::placeholder {
  color: #ffedd5;
}

.placeholder-orange-100\/20::placeholder {
  color: #ffedd533;
}

.placeholder-orange-100\/25::placeholder {
  color: #ffedd540;
}

.placeholder-orange-100\/30::placeholder {
  color: #ffedd54d;
}

.placeholder-orange-100\/40::placeholder {
  color: #ffedd566;
}

.placeholder-orange-100\/5::placeholder {
  color: #ffedd50d;
}

.placeholder-orange-100\/50::placeholder {
  color: #ffedd580;
}

.placeholder-orange-100\/60::placeholder {
  color: #ffedd599;
}

.placeholder-orange-100\/70::placeholder {
  color: #ffedd5b3;
}

.placeholder-orange-100\/75::placeholder {
  color: #ffedd5bf;
}

.placeholder-orange-100\/80::placeholder {
  color: #ffedd5cc;
}

.placeholder-orange-100\/90::placeholder {
  color: #ffedd5e6;
}

.placeholder-orange-100\/95::placeholder {
  color: #ffedd5f2;
}

.placeholder-orange-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(254 215 170 / var(--tw-placeholder-opacity));
}

.placeholder-orange-200\/0::placeholder {
  color: #fed7aa00;
}

.placeholder-orange-200\/10::placeholder {
  color: #fed7aa1a;
}

.placeholder-orange-200\/100::placeholder {
  color: #fed7aa;
}

.placeholder-orange-200\/20::placeholder {
  color: #fed7aa33;
}

.placeholder-orange-200\/25::placeholder {
  color: #fed7aa40;
}

.placeholder-orange-200\/30::placeholder {
  color: #fed7aa4d;
}

.placeholder-orange-200\/40::placeholder {
  color: #fed7aa66;
}

.placeholder-orange-200\/5::placeholder {
  color: #fed7aa0d;
}

.placeholder-orange-200\/50::placeholder {
  color: #fed7aa80;
}

.placeholder-orange-200\/60::placeholder {
  color: #fed7aa99;
}

.placeholder-orange-200\/70::placeholder {
  color: #fed7aab3;
}

.placeholder-orange-200\/75::placeholder {
  color: #fed7aabf;
}

.placeholder-orange-200\/80::placeholder {
  color: #fed7aacc;
}

.placeholder-orange-200\/90::placeholder {
  color: #fed7aae6;
}

.placeholder-orange-200\/95::placeholder {
  color: #fed7aaf2;
}

.placeholder-orange-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(253 186 116 / var(--tw-placeholder-opacity));
}

.placeholder-orange-300\/0::placeholder {
  color: #fdba7400;
}

.placeholder-orange-300\/10::placeholder {
  color: #fdba741a;
}

.placeholder-orange-300\/100::placeholder {
  color: #fdba74;
}

.placeholder-orange-300\/20::placeholder {
  color: #fdba7433;
}

.placeholder-orange-300\/25::placeholder {
  color: #fdba7440;
}

.placeholder-orange-300\/30::placeholder {
  color: #fdba744d;
}

.placeholder-orange-300\/40::placeholder {
  color: #fdba7466;
}

.placeholder-orange-300\/5::placeholder {
  color: #fdba740d;
}

.placeholder-orange-300\/50::placeholder {
  color: #fdba7480;
}

.placeholder-orange-300\/60::placeholder {
  color: #fdba7499;
}

.placeholder-orange-300\/70::placeholder {
  color: #fdba74b3;
}

.placeholder-orange-300\/75::placeholder {
  color: #fdba74bf;
}

.placeholder-orange-300\/80::placeholder {
  color: #fdba74cc;
}

.placeholder-orange-300\/90::placeholder {
  color: #fdba74e6;
}

.placeholder-orange-300\/95::placeholder {
  color: #fdba74f2;
}

.placeholder-orange-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(251 146 60 / var(--tw-placeholder-opacity));
}

.placeholder-orange-400\/0::placeholder {
  color: #fb923c00;
}

.placeholder-orange-400\/10::placeholder {
  color: #fb923c1a;
}

.placeholder-orange-400\/100::placeholder {
  color: #fb923c;
}

.placeholder-orange-400\/20::placeholder {
  color: #fb923c33;
}

.placeholder-orange-400\/25::placeholder {
  color: #fb923c40;
}

.placeholder-orange-400\/30::placeholder {
  color: #fb923c4d;
}

.placeholder-orange-400\/40::placeholder {
  color: #fb923c66;
}

.placeholder-orange-400\/5::placeholder {
  color: #fb923c0d;
}

.placeholder-orange-400\/50::placeholder {
  color: #fb923c80;
}

.placeholder-orange-400\/60::placeholder {
  color: #fb923c99;
}

.placeholder-orange-400\/70::placeholder {
  color: #fb923cb3;
}

.placeholder-orange-400\/75::placeholder {
  color: #fb923cbf;
}

.placeholder-orange-400\/80::placeholder {
  color: #fb923ccc;
}

.placeholder-orange-400\/90::placeholder {
  color: #fb923ce6;
}

.placeholder-orange-400\/95::placeholder {
  color: #fb923cf2;
}

.placeholder-orange-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 247 237 / var(--tw-placeholder-opacity));
}

.placeholder-orange-50\/0::placeholder {
  color: #fff7ed00;
}

.placeholder-orange-50\/10::placeholder {
  color: #fff7ed1a;
}

.placeholder-orange-50\/100::placeholder {
  color: #fff7ed;
}

.placeholder-orange-50\/20::placeholder {
  color: #fff7ed33;
}

.placeholder-orange-50\/25::placeholder {
  color: #fff7ed40;
}

.placeholder-orange-50\/30::placeholder {
  color: #fff7ed4d;
}

.placeholder-orange-50\/40::placeholder {
  color: #fff7ed66;
}

.placeholder-orange-50\/5::placeholder {
  color: #fff7ed0d;
}

.placeholder-orange-50\/50::placeholder {
  color: #fff7ed80;
}

.placeholder-orange-50\/60::placeholder {
  color: #fff7ed99;
}

.placeholder-orange-50\/70::placeholder {
  color: #fff7edb3;
}

.placeholder-orange-50\/75::placeholder {
  color: #fff7edbf;
}

.placeholder-orange-50\/80::placeholder {
  color: #fff7edcc;
}

.placeholder-orange-50\/90::placeholder {
  color: #fff7ede6;
}

.placeholder-orange-50\/95::placeholder {
  color: #fff7edf2;
}

.placeholder-orange-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(249 115 22 / var(--tw-placeholder-opacity));
}

.placeholder-orange-500\/0::placeholder {
  color: #f9731600;
}

.placeholder-orange-500\/10::placeholder {
  color: #f973161a;
}

.placeholder-orange-500\/100::placeholder {
  color: #f97316;
}

.placeholder-orange-500\/20::placeholder {
  color: #f9731633;
}

.placeholder-orange-500\/25::placeholder {
  color: #f9731640;
}

.placeholder-orange-500\/30::placeholder {
  color: #f973164d;
}

.placeholder-orange-500\/40::placeholder {
  color: #f9731666;
}

.placeholder-orange-500\/5::placeholder {
  color: #f973160d;
}

.placeholder-orange-500\/50::placeholder {
  color: #f9731680;
}

.placeholder-orange-500\/60::placeholder {
  color: #f9731699;
}

.placeholder-orange-500\/70::placeholder {
  color: #f97316b3;
}

.placeholder-orange-500\/75::placeholder {
  color: #f97316bf;
}

.placeholder-orange-500\/80::placeholder {
  color: #f97316cc;
}

.placeholder-orange-500\/90::placeholder {
  color: #f97316e6;
}

.placeholder-orange-500\/95::placeholder {
  color: #f97316f2;
}

.placeholder-orange-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(234 88 12 / var(--tw-placeholder-opacity));
}

.placeholder-orange-600\/0::placeholder {
  color: #ea580c00;
}

.placeholder-orange-600\/10::placeholder {
  color: #ea580c1a;
}

.placeholder-orange-600\/100::placeholder {
  color: #ea580c;
}

.placeholder-orange-600\/20::placeholder {
  color: #ea580c33;
}

.placeholder-orange-600\/25::placeholder {
  color: #ea580c40;
}

.placeholder-orange-600\/30::placeholder {
  color: #ea580c4d;
}

.placeholder-orange-600\/40::placeholder {
  color: #ea580c66;
}

.placeholder-orange-600\/5::placeholder {
  color: #ea580c0d;
}

.placeholder-orange-600\/50::placeholder {
  color: #ea580c80;
}

.placeholder-orange-600\/60::placeholder {
  color: #ea580c99;
}

.placeholder-orange-600\/70::placeholder {
  color: #ea580cb3;
}

.placeholder-orange-600\/75::placeholder {
  color: #ea580cbf;
}

.placeholder-orange-600\/80::placeholder {
  color: #ea580ccc;
}

.placeholder-orange-600\/90::placeholder {
  color: #ea580ce6;
}

.placeholder-orange-600\/95::placeholder {
  color: #ea580cf2;
}

.placeholder-orange-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(194 65 12 / var(--tw-placeholder-opacity));
}

.placeholder-orange-700\/0::placeholder {
  color: #c2410c00;
}

.placeholder-orange-700\/10::placeholder {
  color: #c2410c1a;
}

.placeholder-orange-700\/100::placeholder {
  color: #c2410c;
}

.placeholder-orange-700\/20::placeholder {
  color: #c2410c33;
}

.placeholder-orange-700\/25::placeholder {
  color: #c2410c40;
}

.placeholder-orange-700\/30::placeholder {
  color: #c2410c4d;
}

.placeholder-orange-700\/40::placeholder {
  color: #c2410c66;
}

.placeholder-orange-700\/5::placeholder {
  color: #c2410c0d;
}

.placeholder-orange-700\/50::placeholder {
  color: #c2410c80;
}

.placeholder-orange-700\/60::placeholder {
  color: #c2410c99;
}

.placeholder-orange-700\/70::placeholder {
  color: #c2410cb3;
}

.placeholder-orange-700\/75::placeholder {
  color: #c2410cbf;
}

.placeholder-orange-700\/80::placeholder {
  color: #c2410ccc;
}

.placeholder-orange-700\/90::placeholder {
  color: #c2410ce6;
}

.placeholder-orange-700\/95::placeholder {
  color: #c2410cf2;
}

.placeholder-orange-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(154 52 18 / var(--tw-placeholder-opacity));
}

.placeholder-orange-800\/0::placeholder {
  color: #9a341200;
}

.placeholder-orange-800\/10::placeholder {
  color: #9a34121a;
}

.placeholder-orange-800\/100::placeholder {
  color: #9a3412;
}

.placeholder-orange-800\/20::placeholder {
  color: #9a341233;
}

.placeholder-orange-800\/25::placeholder {
  color: #9a341240;
}

.placeholder-orange-800\/30::placeholder {
  color: #9a34124d;
}

.placeholder-orange-800\/40::placeholder {
  color: #9a341266;
}

.placeholder-orange-800\/5::placeholder {
  color: #9a34120d;
}

.placeholder-orange-800\/50::placeholder {
  color: #9a341280;
}

.placeholder-orange-800\/60::placeholder {
  color: #9a341299;
}

.placeholder-orange-800\/70::placeholder {
  color: #9a3412b3;
}

.placeholder-orange-800\/75::placeholder {
  color: #9a3412bf;
}

.placeholder-orange-800\/80::placeholder {
  color: #9a3412cc;
}

.placeholder-orange-800\/90::placeholder {
  color: #9a3412e6;
}

.placeholder-orange-800\/95::placeholder {
  color: #9a3412f2;
}

.placeholder-orange-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(124 45 18 / var(--tw-placeholder-opacity));
}

.placeholder-orange-900\/0::placeholder {
  color: #7c2d1200;
}

.placeholder-orange-900\/10::placeholder {
  color: #7c2d121a;
}

.placeholder-orange-900\/100::placeholder {
  color: #7c2d12;
}

.placeholder-orange-900\/20::placeholder {
  color: #7c2d1233;
}

.placeholder-orange-900\/25::placeholder {
  color: #7c2d1240;
}

.placeholder-orange-900\/30::placeholder {
  color: #7c2d124d;
}

.placeholder-orange-900\/40::placeholder {
  color: #7c2d1266;
}

.placeholder-orange-900\/5::placeholder {
  color: #7c2d120d;
}

.placeholder-orange-900\/50::placeholder {
  color: #7c2d1280;
}

.placeholder-orange-900\/60::placeholder {
  color: #7c2d1299;
}

.placeholder-orange-900\/70::placeholder {
  color: #7c2d12b3;
}

.placeholder-orange-900\/75::placeholder {
  color: #7c2d12bf;
}

.placeholder-orange-900\/80::placeholder {
  color: #7c2d12cc;
}

.placeholder-orange-900\/90::placeholder {
  color: #7c2d12e6;
}

.placeholder-orange-900\/95::placeholder {
  color: #7c2d12f2;
}

.placeholder-orange-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(67 20 7 / var(--tw-placeholder-opacity));
}

.placeholder-orange-950\/0::placeholder {
  color: #43140700;
}

.placeholder-orange-950\/10::placeholder {
  color: #4314071a;
}

.placeholder-orange-950\/100::placeholder {
  color: #431407;
}

.placeholder-orange-950\/20::placeholder {
  color: #43140733;
}

.placeholder-orange-950\/25::placeholder {
  color: #43140740;
}

.placeholder-orange-950\/30::placeholder {
  color: #4314074d;
}

.placeholder-orange-950\/40::placeholder {
  color: #43140766;
}

.placeholder-orange-950\/5::placeholder {
  color: #4314070d;
}

.placeholder-orange-950\/50::placeholder {
  color: #43140780;
}

.placeholder-orange-950\/60::placeholder {
  color: #43140799;
}

.placeholder-orange-950\/70::placeholder {
  color: #431407b3;
}

.placeholder-orange-950\/75::placeholder {
  color: #431407bf;
}

.placeholder-orange-950\/80::placeholder {
  color: #431407cc;
}

.placeholder-orange-950\/90::placeholder {
  color: #431407e6;
}

.placeholder-orange-950\/95::placeholder {
  color: #431407f2;
}

.placeholder-pink-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(252 231 243 / var(--tw-placeholder-opacity));
}

.placeholder-pink-100\/0::placeholder {
  color: #fce7f300;
}

.placeholder-pink-100\/10::placeholder {
  color: #fce7f31a;
}

.placeholder-pink-100\/100::placeholder {
  color: #fce7f3;
}

.placeholder-pink-100\/20::placeholder {
  color: #fce7f333;
}

.placeholder-pink-100\/25::placeholder {
  color: #fce7f340;
}

.placeholder-pink-100\/30::placeholder {
  color: #fce7f34d;
}

.placeholder-pink-100\/40::placeholder {
  color: #fce7f366;
}

.placeholder-pink-100\/5::placeholder {
  color: #fce7f30d;
}

.placeholder-pink-100\/50::placeholder {
  color: #fce7f380;
}

.placeholder-pink-100\/60::placeholder {
  color: #fce7f399;
}

.placeholder-pink-100\/70::placeholder {
  color: #fce7f3b3;
}

.placeholder-pink-100\/75::placeholder {
  color: #fce7f3bf;
}

.placeholder-pink-100\/80::placeholder {
  color: #fce7f3cc;
}

.placeholder-pink-100\/90::placeholder {
  color: #fce7f3e6;
}

.placeholder-pink-100\/95::placeholder {
  color: #fce7f3f2;
}

.placeholder-pink-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(251 207 232 / var(--tw-placeholder-opacity));
}

.placeholder-pink-200\/0::placeholder {
  color: #fbcfe800;
}

.placeholder-pink-200\/10::placeholder {
  color: #fbcfe81a;
}

.placeholder-pink-200\/100::placeholder {
  color: #fbcfe8;
}

.placeholder-pink-200\/20::placeholder {
  color: #fbcfe833;
}

.placeholder-pink-200\/25::placeholder {
  color: #fbcfe840;
}

.placeholder-pink-200\/30::placeholder {
  color: #fbcfe84d;
}

.placeholder-pink-200\/40::placeholder {
  color: #fbcfe866;
}

.placeholder-pink-200\/5::placeholder {
  color: #fbcfe80d;
}

.placeholder-pink-200\/50::placeholder {
  color: #fbcfe880;
}

.placeholder-pink-200\/60::placeholder {
  color: #fbcfe899;
}

.placeholder-pink-200\/70::placeholder {
  color: #fbcfe8b3;
}

.placeholder-pink-200\/75::placeholder {
  color: #fbcfe8bf;
}

.placeholder-pink-200\/80::placeholder {
  color: #fbcfe8cc;
}

.placeholder-pink-200\/90::placeholder {
  color: #fbcfe8e6;
}

.placeholder-pink-200\/95::placeholder {
  color: #fbcfe8f2;
}

.placeholder-pink-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(249 168 212 / var(--tw-placeholder-opacity));
}

.placeholder-pink-300\/0::placeholder {
  color: #f9a8d400;
}

.placeholder-pink-300\/10::placeholder {
  color: #f9a8d41a;
}

.placeholder-pink-300\/100::placeholder {
  color: #f9a8d4;
}

.placeholder-pink-300\/20::placeholder {
  color: #f9a8d433;
}

.placeholder-pink-300\/25::placeholder {
  color: #f9a8d440;
}

.placeholder-pink-300\/30::placeholder {
  color: #f9a8d44d;
}

.placeholder-pink-300\/40::placeholder {
  color: #f9a8d466;
}

.placeholder-pink-300\/5::placeholder {
  color: #f9a8d40d;
}

.placeholder-pink-300\/50::placeholder {
  color: #f9a8d480;
}

.placeholder-pink-300\/60::placeholder {
  color: #f9a8d499;
}

.placeholder-pink-300\/70::placeholder {
  color: #f9a8d4b3;
}

.placeholder-pink-300\/75::placeholder {
  color: #f9a8d4bf;
}

.placeholder-pink-300\/80::placeholder {
  color: #f9a8d4cc;
}

.placeholder-pink-300\/90::placeholder {
  color: #f9a8d4e6;
}

.placeholder-pink-300\/95::placeholder {
  color: #f9a8d4f2;
}

.placeholder-pink-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(244 114 182 / var(--tw-placeholder-opacity));
}

.placeholder-pink-400\/0::placeholder {
  color: #f472b600;
}

.placeholder-pink-400\/10::placeholder {
  color: #f472b61a;
}

.placeholder-pink-400\/100::placeholder {
  color: #f472b6;
}

.placeholder-pink-400\/20::placeholder {
  color: #f472b633;
}

.placeholder-pink-400\/25::placeholder {
  color: #f472b640;
}

.placeholder-pink-400\/30::placeholder {
  color: #f472b64d;
}

.placeholder-pink-400\/40::placeholder {
  color: #f472b666;
}

.placeholder-pink-400\/5::placeholder {
  color: #f472b60d;
}

.placeholder-pink-400\/50::placeholder {
  color: #f472b680;
}

.placeholder-pink-400\/60::placeholder {
  color: #f472b699;
}

.placeholder-pink-400\/70::placeholder {
  color: #f472b6b3;
}

.placeholder-pink-400\/75::placeholder {
  color: #f472b6bf;
}

.placeholder-pink-400\/80::placeholder {
  color: #f472b6cc;
}

.placeholder-pink-400\/90::placeholder {
  color: #f472b6e6;
}

.placeholder-pink-400\/95::placeholder {
  color: #f472b6f2;
}

.placeholder-pink-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(253 242 248 / var(--tw-placeholder-opacity));
}

.placeholder-pink-50\/0::placeholder {
  color: #fdf2f800;
}

.placeholder-pink-50\/10::placeholder {
  color: #fdf2f81a;
}

.placeholder-pink-50\/100::placeholder {
  color: #fdf2f8;
}

.placeholder-pink-50\/20::placeholder {
  color: #fdf2f833;
}

.placeholder-pink-50\/25::placeholder {
  color: #fdf2f840;
}

.placeholder-pink-50\/30::placeholder {
  color: #fdf2f84d;
}

.placeholder-pink-50\/40::placeholder {
  color: #fdf2f866;
}

.placeholder-pink-50\/5::placeholder {
  color: #fdf2f80d;
}

.placeholder-pink-50\/50::placeholder {
  color: #fdf2f880;
}

.placeholder-pink-50\/60::placeholder {
  color: #fdf2f899;
}

.placeholder-pink-50\/70::placeholder {
  color: #fdf2f8b3;
}

.placeholder-pink-50\/75::placeholder {
  color: #fdf2f8bf;
}

.placeholder-pink-50\/80::placeholder {
  color: #fdf2f8cc;
}

.placeholder-pink-50\/90::placeholder {
  color: #fdf2f8e6;
}

.placeholder-pink-50\/95::placeholder {
  color: #fdf2f8f2;
}

.placeholder-pink-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(236 72 153 / var(--tw-placeholder-opacity));
}

.placeholder-pink-500\/0::placeholder {
  color: #ec489900;
}

.placeholder-pink-500\/10::placeholder {
  color: #ec48991a;
}

.placeholder-pink-500\/100::placeholder {
  color: #ec4899;
}

.placeholder-pink-500\/20::placeholder {
  color: #ec489933;
}

.placeholder-pink-500\/25::placeholder {
  color: #ec489940;
}

.placeholder-pink-500\/30::placeholder {
  color: #ec48994d;
}

.placeholder-pink-500\/40::placeholder {
  color: #ec489966;
}

.placeholder-pink-500\/5::placeholder {
  color: #ec48990d;
}

.placeholder-pink-500\/50::placeholder {
  color: #ec489980;
}

.placeholder-pink-500\/60::placeholder {
  color: #ec489999;
}

.placeholder-pink-500\/70::placeholder {
  color: #ec4899b3;
}

.placeholder-pink-500\/75::placeholder {
  color: #ec4899bf;
}

.placeholder-pink-500\/80::placeholder {
  color: #ec4899cc;
}

.placeholder-pink-500\/90::placeholder {
  color: #ec4899e6;
}

.placeholder-pink-500\/95::placeholder {
  color: #ec4899f2;
}

.placeholder-pink-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(219 39 119 / var(--tw-placeholder-opacity));
}

.placeholder-pink-600\/0::placeholder {
  color: #db277700;
}

.placeholder-pink-600\/10::placeholder {
  color: #db27771a;
}

.placeholder-pink-600\/100::placeholder {
  color: #db2777;
}

.placeholder-pink-600\/20::placeholder {
  color: #db277733;
}

.placeholder-pink-600\/25::placeholder {
  color: #db277740;
}

.placeholder-pink-600\/30::placeholder {
  color: #db27774d;
}

.placeholder-pink-600\/40::placeholder {
  color: #db277766;
}

.placeholder-pink-600\/5::placeholder {
  color: #db27770d;
}

.placeholder-pink-600\/50::placeholder {
  color: #db277780;
}

.placeholder-pink-600\/60::placeholder {
  color: #db277799;
}

.placeholder-pink-600\/70::placeholder {
  color: #db2777b3;
}

.placeholder-pink-600\/75::placeholder {
  color: #db2777bf;
}

.placeholder-pink-600\/80::placeholder {
  color: #db2777cc;
}

.placeholder-pink-600\/90::placeholder {
  color: #db2777e6;
}

.placeholder-pink-600\/95::placeholder {
  color: #db2777f2;
}

.placeholder-pink-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(190 24 93 / var(--tw-placeholder-opacity));
}

.placeholder-pink-700\/0::placeholder {
  color: #be185d00;
}

.placeholder-pink-700\/10::placeholder {
  color: #be185d1a;
}

.placeholder-pink-700\/100::placeholder {
  color: #be185d;
}

.placeholder-pink-700\/20::placeholder {
  color: #be185d33;
}

.placeholder-pink-700\/25::placeholder {
  color: #be185d40;
}

.placeholder-pink-700\/30::placeholder {
  color: #be185d4d;
}

.placeholder-pink-700\/40::placeholder {
  color: #be185d66;
}

.placeholder-pink-700\/5::placeholder {
  color: #be185d0d;
}

.placeholder-pink-700\/50::placeholder {
  color: #be185d80;
}

.placeholder-pink-700\/60::placeholder {
  color: #be185d99;
}

.placeholder-pink-700\/70::placeholder {
  color: #be185db3;
}

.placeholder-pink-700\/75::placeholder {
  color: #be185dbf;
}

.placeholder-pink-700\/80::placeholder {
  color: #be185dcc;
}

.placeholder-pink-700\/90::placeholder {
  color: #be185de6;
}

.placeholder-pink-700\/95::placeholder {
  color: #be185df2;
}

.placeholder-pink-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(157 23 77 / var(--tw-placeholder-opacity));
}

.placeholder-pink-800\/0::placeholder {
  color: #9d174d00;
}

.placeholder-pink-800\/10::placeholder {
  color: #9d174d1a;
}

.placeholder-pink-800\/100::placeholder {
  color: #9d174d;
}

.placeholder-pink-800\/20::placeholder {
  color: #9d174d33;
}

.placeholder-pink-800\/25::placeholder {
  color: #9d174d40;
}

.placeholder-pink-800\/30::placeholder {
  color: #9d174d4d;
}

.placeholder-pink-800\/40::placeholder {
  color: #9d174d66;
}

.placeholder-pink-800\/5::placeholder {
  color: #9d174d0d;
}

.placeholder-pink-800\/50::placeholder {
  color: #9d174d80;
}

.placeholder-pink-800\/60::placeholder {
  color: #9d174d99;
}

.placeholder-pink-800\/70::placeholder {
  color: #9d174db3;
}

.placeholder-pink-800\/75::placeholder {
  color: #9d174dbf;
}

.placeholder-pink-800\/80::placeholder {
  color: #9d174dcc;
}

.placeholder-pink-800\/90::placeholder {
  color: #9d174de6;
}

.placeholder-pink-800\/95::placeholder {
  color: #9d174df2;
}

.placeholder-pink-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(131 24 67 / var(--tw-placeholder-opacity));
}

.placeholder-pink-900\/0::placeholder {
  color: #83184300;
}

.placeholder-pink-900\/10::placeholder {
  color: #8318431a;
}

.placeholder-pink-900\/100::placeholder {
  color: #831843;
}

.placeholder-pink-900\/20::placeholder {
  color: #83184333;
}

.placeholder-pink-900\/25::placeholder {
  color: #83184340;
}

.placeholder-pink-900\/30::placeholder {
  color: #8318434d;
}

.placeholder-pink-900\/40::placeholder {
  color: #83184366;
}

.placeholder-pink-900\/5::placeholder {
  color: #8318430d;
}

.placeholder-pink-900\/50::placeholder {
  color: #83184380;
}

.placeholder-pink-900\/60::placeholder {
  color: #83184399;
}

.placeholder-pink-900\/70::placeholder {
  color: #831843b3;
}

.placeholder-pink-900\/75::placeholder {
  color: #831843bf;
}

.placeholder-pink-900\/80::placeholder {
  color: #831843cc;
}

.placeholder-pink-900\/90::placeholder {
  color: #831843e6;
}

.placeholder-pink-900\/95::placeholder {
  color: #831843f2;
}

.placeholder-pink-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(80 7 36 / var(--tw-placeholder-opacity));
}

.placeholder-pink-950\/0::placeholder {
  color: #50072400;
}

.placeholder-pink-950\/10::placeholder {
  color: #5007241a;
}

.placeholder-pink-950\/100::placeholder {
  color: #500724;
}

.placeholder-pink-950\/20::placeholder {
  color: #50072433;
}

.placeholder-pink-950\/25::placeholder {
  color: #50072440;
}

.placeholder-pink-950\/30::placeholder {
  color: #5007244d;
}

.placeholder-pink-950\/40::placeholder {
  color: #50072466;
}

.placeholder-pink-950\/5::placeholder {
  color: #5007240d;
}

.placeholder-pink-950\/50::placeholder {
  color: #50072480;
}

.placeholder-pink-950\/60::placeholder {
  color: #50072499;
}

.placeholder-pink-950\/70::placeholder {
  color: #500724b3;
}

.placeholder-pink-950\/75::placeholder {
  color: #500724bf;
}

.placeholder-pink-950\/80::placeholder {
  color: #500724cc;
}

.placeholder-pink-950\/90::placeholder {
  color: #500724e6;
}

.placeholder-pink-950\/95::placeholder {
  color: #500724f2;
}

.placeholder-primary::placeholder {
  --tw-placeholder-opacity: 1;
  color: hsl(var(--primary) / var(--tw-placeholder-opacity));
}

.placeholder-primary\/0::placeholder {
  color: hsl(var(--primary) / 0);
}

.placeholder-primary\/10::placeholder {
  color: hsl(var(--primary) / .1);
}

.placeholder-primary\/100::placeholder {
  color: hsl(var(--primary) / 1);
}

.placeholder-primary\/20::placeholder {
  color: hsl(var(--primary) / .2);
}

.placeholder-primary\/25::placeholder {
  color: hsl(var(--primary) / .25);
}

.placeholder-primary\/30::placeholder {
  color: hsl(var(--primary) / .3);
}

.placeholder-primary\/40::placeholder {
  color: hsl(var(--primary) / .4);
}

.placeholder-primary\/5::placeholder {
  color: hsl(var(--primary) / .05);
}

.placeholder-primary\/50::placeholder {
  color: hsl(var(--primary) / .5);
}

.placeholder-primary\/60::placeholder {
  color: hsl(var(--primary) / .6);
}

.placeholder-primary\/70::placeholder {
  color: hsl(var(--primary) / .7);
}

.placeholder-primary\/75::placeholder {
  color: hsl(var(--primary) / .75);
}

.placeholder-primary\/80::placeholder {
  color: hsl(var(--primary) / .8);
}

.placeholder-primary\/90::placeholder {
  color: hsl(var(--primary) / .9);
}

.placeholder-primary\/95::placeholder {
  color: hsl(var(--primary) / .95);
}

.placeholder-purple-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(243 232 255 / var(--tw-placeholder-opacity));
}

.placeholder-purple-100\/0::placeholder {
  color: #f3e8ff00;
}

.placeholder-purple-100\/10::placeholder {
  color: #f3e8ff1a;
}

.placeholder-purple-100\/100::placeholder {
  color: #f3e8ff;
}

.placeholder-purple-100\/20::placeholder {
  color: #f3e8ff33;
}

.placeholder-purple-100\/25::placeholder {
  color: #f3e8ff40;
}

.placeholder-purple-100\/30::placeholder {
  color: #f3e8ff4d;
}

.placeholder-purple-100\/40::placeholder {
  color: #f3e8ff66;
}

.placeholder-purple-100\/5::placeholder {
  color: #f3e8ff0d;
}

.placeholder-purple-100\/50::placeholder {
  color: #f3e8ff80;
}

.placeholder-purple-100\/60::placeholder {
  color: #f3e8ff99;
}

.placeholder-purple-100\/70::placeholder {
  color: #f3e8ffb3;
}

.placeholder-purple-100\/75::placeholder {
  color: #f3e8ffbf;
}

.placeholder-purple-100\/80::placeholder {
  color: #f3e8ffcc;
}

.placeholder-purple-100\/90::placeholder {
  color: #f3e8ffe6;
}

.placeholder-purple-100\/95::placeholder {
  color: #f3e8fff2;
}

.placeholder-purple-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(233 213 255 / var(--tw-placeholder-opacity));
}

.placeholder-purple-200\/0::placeholder {
  color: #e9d5ff00;
}

.placeholder-purple-200\/10::placeholder {
  color: #e9d5ff1a;
}

.placeholder-purple-200\/100::placeholder {
  color: #e9d5ff;
}

.placeholder-purple-200\/20::placeholder {
  color: #e9d5ff33;
}

.placeholder-purple-200\/25::placeholder {
  color: #e9d5ff40;
}

.placeholder-purple-200\/30::placeholder {
  color: #e9d5ff4d;
}

.placeholder-purple-200\/40::placeholder {
  color: #e9d5ff66;
}

.placeholder-purple-200\/5::placeholder {
  color: #e9d5ff0d;
}

.placeholder-purple-200\/50::placeholder {
  color: #e9d5ff80;
}

.placeholder-purple-200\/60::placeholder {
  color: #e9d5ff99;
}

.placeholder-purple-200\/70::placeholder {
  color: #e9d5ffb3;
}

.placeholder-purple-200\/75::placeholder {
  color: #e9d5ffbf;
}

.placeholder-purple-200\/80::placeholder {
  color: #e9d5ffcc;
}

.placeholder-purple-200\/90::placeholder {
  color: #e9d5ffe6;
}

.placeholder-purple-200\/95::placeholder {
  color: #e9d5fff2;
}

.placeholder-purple-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(216 180 254 / var(--tw-placeholder-opacity));
}

.placeholder-purple-300\/0::placeholder {
  color: #d8b4fe00;
}

.placeholder-purple-300\/10::placeholder {
  color: #d8b4fe1a;
}

.placeholder-purple-300\/100::placeholder {
  color: #d8b4fe;
}

.placeholder-purple-300\/20::placeholder {
  color: #d8b4fe33;
}

.placeholder-purple-300\/25::placeholder {
  color: #d8b4fe40;
}

.placeholder-purple-300\/30::placeholder {
  color: #d8b4fe4d;
}

.placeholder-purple-300\/40::placeholder {
  color: #d8b4fe66;
}

.placeholder-purple-300\/5::placeholder {
  color: #d8b4fe0d;
}

.placeholder-purple-300\/50::placeholder {
  color: #d8b4fe80;
}

.placeholder-purple-300\/60::placeholder {
  color: #d8b4fe99;
}

.placeholder-purple-300\/70::placeholder {
  color: #d8b4feb3;
}

.placeholder-purple-300\/75::placeholder {
  color: #d8b4febf;
}

.placeholder-purple-300\/80::placeholder {
  color: #d8b4fecc;
}

.placeholder-purple-300\/90::placeholder {
  color: #d8b4fee6;
}

.placeholder-purple-300\/95::placeholder {
  color: #d8b4fef2;
}

.placeholder-purple-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(192 132 252 / var(--tw-placeholder-opacity));
}

.placeholder-purple-400\/0::placeholder {
  color: #c084fc00;
}

.placeholder-purple-400\/10::placeholder {
  color: #c084fc1a;
}

.placeholder-purple-400\/100::placeholder {
  color: #c084fc;
}

.placeholder-purple-400\/20::placeholder {
  color: #c084fc33;
}

.placeholder-purple-400\/25::placeholder {
  color: #c084fc40;
}

.placeholder-purple-400\/30::placeholder {
  color: #c084fc4d;
}

.placeholder-purple-400\/40::placeholder {
  color: #c084fc66;
}

.placeholder-purple-400\/5::placeholder {
  color: #c084fc0d;
}

.placeholder-purple-400\/50::placeholder {
  color: #c084fc80;
}

.placeholder-purple-400\/60::placeholder {
  color: #c084fc99;
}

.placeholder-purple-400\/70::placeholder {
  color: #c084fcb3;
}

.placeholder-purple-400\/75::placeholder {
  color: #c084fcbf;
}

.placeholder-purple-400\/80::placeholder {
  color: #c084fccc;
}

.placeholder-purple-400\/90::placeholder {
  color: #c084fce6;
}

.placeholder-purple-400\/95::placeholder {
  color: #c084fcf2;
}

.placeholder-purple-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(250 245 255 / var(--tw-placeholder-opacity));
}

.placeholder-purple-50\/0::placeholder {
  color: #faf5ff00;
}

.placeholder-purple-50\/10::placeholder {
  color: #faf5ff1a;
}

.placeholder-purple-50\/100::placeholder {
  color: #faf5ff;
}

.placeholder-purple-50\/20::placeholder {
  color: #faf5ff33;
}

.placeholder-purple-50\/25::placeholder {
  color: #faf5ff40;
}

.placeholder-purple-50\/30::placeholder {
  color: #faf5ff4d;
}

.placeholder-purple-50\/40::placeholder {
  color: #faf5ff66;
}

.placeholder-purple-50\/5::placeholder {
  color: #faf5ff0d;
}

.placeholder-purple-50\/50::placeholder {
  color: #faf5ff80;
}

.placeholder-purple-50\/60::placeholder {
  color: #faf5ff99;
}

.placeholder-purple-50\/70::placeholder {
  color: #faf5ffb3;
}

.placeholder-purple-50\/75::placeholder {
  color: #faf5ffbf;
}

.placeholder-purple-50\/80::placeholder {
  color: #faf5ffcc;
}

.placeholder-purple-50\/90::placeholder {
  color: #faf5ffe6;
}

.placeholder-purple-50\/95::placeholder {
  color: #faf5fff2;
}

.placeholder-purple-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(168 85 247 / var(--tw-placeholder-opacity));
}

.placeholder-purple-500\/0::placeholder {
  color: #a855f700;
}

.placeholder-purple-500\/10::placeholder {
  color: #a855f71a;
}

.placeholder-purple-500\/100::placeholder {
  color: #a855f7;
}

.placeholder-purple-500\/20::placeholder {
  color: #a855f733;
}

.placeholder-purple-500\/25::placeholder {
  color: #a855f740;
}

.placeholder-purple-500\/30::placeholder {
  color: #a855f74d;
}

.placeholder-purple-500\/40::placeholder {
  color: #a855f766;
}

.placeholder-purple-500\/5::placeholder {
  color: #a855f70d;
}

.placeholder-purple-500\/50::placeholder {
  color: #a855f780;
}

.placeholder-purple-500\/60::placeholder {
  color: #a855f799;
}

.placeholder-purple-500\/70::placeholder {
  color: #a855f7b3;
}

.placeholder-purple-500\/75::placeholder {
  color: #a855f7bf;
}

.placeholder-purple-500\/80::placeholder {
  color: #a855f7cc;
}

.placeholder-purple-500\/90::placeholder {
  color: #a855f7e6;
}

.placeholder-purple-500\/95::placeholder {
  color: #a855f7f2;
}

.placeholder-purple-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(147 51 234 / var(--tw-placeholder-opacity));
}

.placeholder-purple-600\/0::placeholder {
  color: #9333ea00;
}

.placeholder-purple-600\/10::placeholder {
  color: #9333ea1a;
}

.placeholder-purple-600\/100::placeholder {
  color: #9333ea;
}

.placeholder-purple-600\/20::placeholder {
  color: #9333ea33;
}

.placeholder-purple-600\/25::placeholder {
  color: #9333ea40;
}

.placeholder-purple-600\/30::placeholder {
  color: #9333ea4d;
}

.placeholder-purple-600\/40::placeholder {
  color: #9333ea66;
}

.placeholder-purple-600\/5::placeholder {
  color: #9333ea0d;
}

.placeholder-purple-600\/50::placeholder {
  color: #9333ea80;
}

.placeholder-purple-600\/60::placeholder {
  color: #9333ea99;
}

.placeholder-purple-600\/70::placeholder {
  color: #9333eab3;
}

.placeholder-purple-600\/75::placeholder {
  color: #9333eabf;
}

.placeholder-purple-600\/80::placeholder {
  color: #9333eacc;
}

.placeholder-purple-600\/90::placeholder {
  color: #9333eae6;
}

.placeholder-purple-600\/95::placeholder {
  color: #9333eaf2;
}

.placeholder-purple-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(126 34 206 / var(--tw-placeholder-opacity));
}

.placeholder-purple-700\/0::placeholder {
  color: #7e22ce00;
}

.placeholder-purple-700\/10::placeholder {
  color: #7e22ce1a;
}

.placeholder-purple-700\/100::placeholder {
  color: #7e22ce;
}

.placeholder-purple-700\/20::placeholder {
  color: #7e22ce33;
}

.placeholder-purple-700\/25::placeholder {
  color: #7e22ce40;
}

.placeholder-purple-700\/30::placeholder {
  color: #7e22ce4d;
}

.placeholder-purple-700\/40::placeholder {
  color: #7e22ce66;
}

.placeholder-purple-700\/5::placeholder {
  color: #7e22ce0d;
}

.placeholder-purple-700\/50::placeholder {
  color: #7e22ce80;
}

.placeholder-purple-700\/60::placeholder {
  color: #7e22ce99;
}

.placeholder-purple-700\/70::placeholder {
  color: #7e22ceb3;
}

.placeholder-purple-700\/75::placeholder {
  color: #7e22cebf;
}

.placeholder-purple-700\/80::placeholder {
  color: #7e22cecc;
}

.placeholder-purple-700\/90::placeholder {
  color: #7e22cee6;
}

.placeholder-purple-700\/95::placeholder {
  color: #7e22cef2;
}

.placeholder-purple-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 33 168 / var(--tw-placeholder-opacity));
}

.placeholder-purple-800\/0::placeholder {
  color: #6b21a800;
}

.placeholder-purple-800\/10::placeholder {
  color: #6b21a81a;
}

.placeholder-purple-800\/100::placeholder {
  color: #6b21a8;
}

.placeholder-purple-800\/20::placeholder {
  color: #6b21a833;
}

.placeholder-purple-800\/25::placeholder {
  color: #6b21a840;
}

.placeholder-purple-800\/30::placeholder {
  color: #6b21a84d;
}

.placeholder-purple-800\/40::placeholder {
  color: #6b21a866;
}

.placeholder-purple-800\/5::placeholder {
  color: #6b21a80d;
}

.placeholder-purple-800\/50::placeholder {
  color: #6b21a880;
}

.placeholder-purple-800\/60::placeholder {
  color: #6b21a899;
}

.placeholder-purple-800\/70::placeholder {
  color: #6b21a8b3;
}

.placeholder-purple-800\/75::placeholder {
  color: #6b21a8bf;
}

.placeholder-purple-800\/80::placeholder {
  color: #6b21a8cc;
}

.placeholder-purple-800\/90::placeholder {
  color: #6b21a8e6;
}

.placeholder-purple-800\/95::placeholder {
  color: #6b21a8f2;
}

.placeholder-purple-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(88 28 135 / var(--tw-placeholder-opacity));
}

.placeholder-purple-900\/0::placeholder {
  color: #581c8700;
}

.placeholder-purple-900\/10::placeholder {
  color: #581c871a;
}

.placeholder-purple-900\/100::placeholder {
  color: #581c87;
}

.placeholder-purple-900\/20::placeholder {
  color: #581c8733;
}

.placeholder-purple-900\/25::placeholder {
  color: #581c8740;
}

.placeholder-purple-900\/30::placeholder {
  color: #581c874d;
}

.placeholder-purple-900\/40::placeholder {
  color: #581c8766;
}

.placeholder-purple-900\/5::placeholder {
  color: #581c870d;
}

.placeholder-purple-900\/50::placeholder {
  color: #581c8780;
}

.placeholder-purple-900\/60::placeholder {
  color: #581c8799;
}

.placeholder-purple-900\/70::placeholder {
  color: #581c87b3;
}

.placeholder-purple-900\/75::placeholder {
  color: #581c87bf;
}

.placeholder-purple-900\/80::placeholder {
  color: #581c87cc;
}

.placeholder-purple-900\/90::placeholder {
  color: #581c87e6;
}

.placeholder-purple-900\/95::placeholder {
  color: #581c87f2;
}

.placeholder-purple-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(59 7 100 / var(--tw-placeholder-opacity));
}

.placeholder-purple-950\/0::placeholder {
  color: #3b076400;
}

.placeholder-purple-950\/10::placeholder {
  color: #3b07641a;
}

.placeholder-purple-950\/100::placeholder {
  color: #3b0764;
}

.placeholder-purple-950\/20::placeholder {
  color: #3b076433;
}

.placeholder-purple-950\/25::placeholder {
  color: #3b076440;
}

.placeholder-purple-950\/30::placeholder {
  color: #3b07644d;
}

.placeholder-purple-950\/40::placeholder {
  color: #3b076466;
}

.placeholder-purple-950\/5::placeholder {
  color: #3b07640d;
}

.placeholder-purple-950\/50::placeholder {
  color: #3b076480;
}

.placeholder-purple-950\/60::placeholder {
  color: #3b076499;
}

.placeholder-purple-950\/70::placeholder {
  color: #3b0764b3;
}

.placeholder-purple-950\/75::placeholder {
  color: #3b0764bf;
}

.placeholder-purple-950\/80::placeholder {
  color: #3b0764cc;
}

.placeholder-purple-950\/90::placeholder {
  color: #3b0764e6;
}

.placeholder-purple-950\/95::placeholder {
  color: #3b0764f2;
}

.placeholder-red::placeholder {
  --tw-placeholder-opacity: 1;
  color: hsl(var(--red) / var(--tw-placeholder-opacity));
}

.placeholder-red\/0::placeholder {
  color: hsl(var(--red) / 0);
}

.placeholder-red\/10::placeholder {
  color: hsl(var(--red) / .1);
}

.placeholder-red\/100::placeholder {
  color: hsl(var(--red) / 1);
}

.placeholder-red\/20::placeholder {
  color: hsl(var(--red) / .2);
}

.placeholder-red\/25::placeholder {
  color: hsl(var(--red) / .25);
}

.placeholder-red\/30::placeholder {
  color: hsl(var(--red) / .3);
}

.placeholder-red\/40::placeholder {
  color: hsl(var(--red) / .4);
}

.placeholder-red\/5::placeholder {
  color: hsl(var(--red) / .05);
}

.placeholder-red\/50::placeholder {
  color: hsl(var(--red) / .5);
}

.placeholder-red\/60::placeholder {
  color: hsl(var(--red) / .6);
}

.placeholder-red\/70::placeholder {
  color: hsl(var(--red) / .7);
}

.placeholder-red\/75::placeholder {
  color: hsl(var(--red) / .75);
}

.placeholder-red\/80::placeholder {
  color: hsl(var(--red) / .8);
}

.placeholder-red\/90::placeholder {
  color: hsl(var(--red) / .9);
}

.placeholder-red\/95::placeholder {
  color: hsl(var(--red) / .95);
}

.placeholder-rose-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 228 230 / var(--tw-placeholder-opacity));
}

.placeholder-rose-100\/0::placeholder {
  color: #ffe4e600;
}

.placeholder-rose-100\/10::placeholder {
  color: #ffe4e61a;
}

.placeholder-rose-100\/100::placeholder {
  color: #ffe4e6;
}

.placeholder-rose-100\/20::placeholder {
  color: #ffe4e633;
}

.placeholder-rose-100\/25::placeholder {
  color: #ffe4e640;
}

.placeholder-rose-100\/30::placeholder {
  color: #ffe4e64d;
}

.placeholder-rose-100\/40::placeholder {
  color: #ffe4e666;
}

.placeholder-rose-100\/5::placeholder {
  color: #ffe4e60d;
}

.placeholder-rose-100\/50::placeholder {
  color: #ffe4e680;
}

.placeholder-rose-100\/60::placeholder {
  color: #ffe4e699;
}

.placeholder-rose-100\/70::placeholder {
  color: #ffe4e6b3;
}

.placeholder-rose-100\/75::placeholder {
  color: #ffe4e6bf;
}

.placeholder-rose-100\/80::placeholder {
  color: #ffe4e6cc;
}

.placeholder-rose-100\/90::placeholder {
  color: #ffe4e6e6;
}

.placeholder-rose-100\/95::placeholder {
  color: #ffe4e6f2;
}

.placeholder-rose-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(254 205 211 / var(--tw-placeholder-opacity));
}

.placeholder-rose-200\/0::placeholder {
  color: #fecdd300;
}

.placeholder-rose-200\/10::placeholder {
  color: #fecdd31a;
}

.placeholder-rose-200\/100::placeholder {
  color: #fecdd3;
}

.placeholder-rose-200\/20::placeholder {
  color: #fecdd333;
}

.placeholder-rose-200\/25::placeholder {
  color: #fecdd340;
}

.placeholder-rose-200\/30::placeholder {
  color: #fecdd34d;
}

.placeholder-rose-200\/40::placeholder {
  color: #fecdd366;
}

.placeholder-rose-200\/5::placeholder {
  color: #fecdd30d;
}

.placeholder-rose-200\/50::placeholder {
  color: #fecdd380;
}

.placeholder-rose-200\/60::placeholder {
  color: #fecdd399;
}

.placeholder-rose-200\/70::placeholder {
  color: #fecdd3b3;
}

.placeholder-rose-200\/75::placeholder {
  color: #fecdd3bf;
}

.placeholder-rose-200\/80::placeholder {
  color: #fecdd3cc;
}

.placeholder-rose-200\/90::placeholder {
  color: #fecdd3e6;
}

.placeholder-rose-200\/95::placeholder {
  color: #fecdd3f2;
}

.placeholder-rose-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(253 164 175 / var(--tw-placeholder-opacity));
}

.placeholder-rose-300\/0::placeholder {
  color: #fda4af00;
}

.placeholder-rose-300\/10::placeholder {
  color: #fda4af1a;
}

.placeholder-rose-300\/100::placeholder {
  color: #fda4af;
}

.placeholder-rose-300\/20::placeholder {
  color: #fda4af33;
}

.placeholder-rose-300\/25::placeholder {
  color: #fda4af40;
}

.placeholder-rose-300\/30::placeholder {
  color: #fda4af4d;
}

.placeholder-rose-300\/40::placeholder {
  color: #fda4af66;
}

.placeholder-rose-300\/5::placeholder {
  color: #fda4af0d;
}

.placeholder-rose-300\/50::placeholder {
  color: #fda4af80;
}

.placeholder-rose-300\/60::placeholder {
  color: #fda4af99;
}

.placeholder-rose-300\/70::placeholder {
  color: #fda4afb3;
}

.placeholder-rose-300\/75::placeholder {
  color: #fda4afbf;
}

.placeholder-rose-300\/80::placeholder {
  color: #fda4afcc;
}

.placeholder-rose-300\/90::placeholder {
  color: #fda4afe6;
}

.placeholder-rose-300\/95::placeholder {
  color: #fda4aff2;
}

.placeholder-rose-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(251 113 133 / var(--tw-placeholder-opacity));
}

.placeholder-rose-400\/0::placeholder {
  color: #fb718500;
}

.placeholder-rose-400\/10::placeholder {
  color: #fb71851a;
}

.placeholder-rose-400\/100::placeholder {
  color: #fb7185;
}

.placeholder-rose-400\/20::placeholder {
  color: #fb718533;
}

.placeholder-rose-400\/25::placeholder {
  color: #fb718540;
}

.placeholder-rose-400\/30::placeholder {
  color: #fb71854d;
}

.placeholder-rose-400\/40::placeholder {
  color: #fb718566;
}

.placeholder-rose-400\/5::placeholder {
  color: #fb71850d;
}

.placeholder-rose-400\/50::placeholder {
  color: #fb718580;
}

.placeholder-rose-400\/60::placeholder {
  color: #fb718599;
}

.placeholder-rose-400\/70::placeholder {
  color: #fb7185b3;
}

.placeholder-rose-400\/75::placeholder {
  color: #fb7185bf;
}

.placeholder-rose-400\/80::placeholder {
  color: #fb7185cc;
}

.placeholder-rose-400\/90::placeholder {
  color: #fb7185e6;
}

.placeholder-rose-400\/95::placeholder {
  color: #fb7185f2;
}

.placeholder-rose-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 241 242 / var(--tw-placeholder-opacity));
}

.placeholder-rose-50\/0::placeholder {
  color: #fff1f200;
}

.placeholder-rose-50\/10::placeholder {
  color: #fff1f21a;
}

.placeholder-rose-50\/100::placeholder {
  color: #fff1f2;
}

.placeholder-rose-50\/20::placeholder {
  color: #fff1f233;
}

.placeholder-rose-50\/25::placeholder {
  color: #fff1f240;
}

.placeholder-rose-50\/30::placeholder {
  color: #fff1f24d;
}

.placeholder-rose-50\/40::placeholder {
  color: #fff1f266;
}

.placeholder-rose-50\/5::placeholder {
  color: #fff1f20d;
}

.placeholder-rose-50\/50::placeholder {
  color: #fff1f280;
}

.placeholder-rose-50\/60::placeholder {
  color: #fff1f299;
}

.placeholder-rose-50\/70::placeholder {
  color: #fff1f2b3;
}

.placeholder-rose-50\/75::placeholder {
  color: #fff1f2bf;
}

.placeholder-rose-50\/80::placeholder {
  color: #fff1f2cc;
}

.placeholder-rose-50\/90::placeholder {
  color: #fff1f2e6;
}

.placeholder-rose-50\/95::placeholder {
  color: #fff1f2f2;
}

.placeholder-rose-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(244 63 94 / var(--tw-placeholder-opacity));
}

.placeholder-rose-500\/0::placeholder {
  color: #f43f5e00;
}

.placeholder-rose-500\/10::placeholder {
  color: #f43f5e1a;
}

.placeholder-rose-500\/100::placeholder {
  color: #f43f5e;
}

.placeholder-rose-500\/20::placeholder {
  color: #f43f5e33;
}

.placeholder-rose-500\/25::placeholder {
  color: #f43f5e40;
}

.placeholder-rose-500\/30::placeholder {
  color: #f43f5e4d;
}

.placeholder-rose-500\/40::placeholder {
  color: #f43f5e66;
}

.placeholder-rose-500\/5::placeholder {
  color: #f43f5e0d;
}

.placeholder-rose-500\/50::placeholder {
  color: #f43f5e80;
}

.placeholder-rose-500\/60::placeholder {
  color: #f43f5e99;
}

.placeholder-rose-500\/70::placeholder {
  color: #f43f5eb3;
}

.placeholder-rose-500\/75::placeholder {
  color: #f43f5ebf;
}

.placeholder-rose-500\/80::placeholder {
  color: #f43f5ecc;
}

.placeholder-rose-500\/90::placeholder {
  color: #f43f5ee6;
}

.placeholder-rose-500\/95::placeholder {
  color: #f43f5ef2;
}

.placeholder-rose-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(225 29 72 / var(--tw-placeholder-opacity));
}

.placeholder-rose-600\/0::placeholder {
  color: #e11d4800;
}

.placeholder-rose-600\/10::placeholder {
  color: #e11d481a;
}

.placeholder-rose-600\/100::placeholder {
  color: #e11d48;
}

.placeholder-rose-600\/20::placeholder {
  color: #e11d4833;
}

.placeholder-rose-600\/25::placeholder {
  color: #e11d4840;
}

.placeholder-rose-600\/30::placeholder {
  color: #e11d484d;
}

.placeholder-rose-600\/40::placeholder {
  color: #e11d4866;
}

.placeholder-rose-600\/5::placeholder {
  color: #e11d480d;
}

.placeholder-rose-600\/50::placeholder {
  color: #e11d4880;
}

.placeholder-rose-600\/60::placeholder {
  color: #e11d4899;
}

.placeholder-rose-600\/70::placeholder {
  color: #e11d48b3;
}

.placeholder-rose-600\/75::placeholder {
  color: #e11d48bf;
}

.placeholder-rose-600\/80::placeholder {
  color: #e11d48cc;
}

.placeholder-rose-600\/90::placeholder {
  color: #e11d48e6;
}

.placeholder-rose-600\/95::placeholder {
  color: #e11d48f2;
}

.placeholder-rose-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(190 18 60 / var(--tw-placeholder-opacity));
}

.placeholder-rose-700\/0::placeholder {
  color: #be123c00;
}

.placeholder-rose-700\/10::placeholder {
  color: #be123c1a;
}

.placeholder-rose-700\/100::placeholder {
  color: #be123c;
}

.placeholder-rose-700\/20::placeholder {
  color: #be123c33;
}

.placeholder-rose-700\/25::placeholder {
  color: #be123c40;
}

.placeholder-rose-700\/30::placeholder {
  color: #be123c4d;
}

.placeholder-rose-700\/40::placeholder {
  color: #be123c66;
}

.placeholder-rose-700\/5::placeholder {
  color: #be123c0d;
}

.placeholder-rose-700\/50::placeholder {
  color: #be123c80;
}

.placeholder-rose-700\/60::placeholder {
  color: #be123c99;
}

.placeholder-rose-700\/70::placeholder {
  color: #be123cb3;
}

.placeholder-rose-700\/75::placeholder {
  color: #be123cbf;
}

.placeholder-rose-700\/80::placeholder {
  color: #be123ccc;
}

.placeholder-rose-700\/90::placeholder {
  color: #be123ce6;
}

.placeholder-rose-700\/95::placeholder {
  color: #be123cf2;
}

.placeholder-rose-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(159 18 57 / var(--tw-placeholder-opacity));
}

.placeholder-rose-800\/0::placeholder {
  color: #9f123900;
}

.placeholder-rose-800\/10::placeholder {
  color: #9f12391a;
}

.placeholder-rose-800\/100::placeholder {
  color: #9f1239;
}

.placeholder-rose-800\/20::placeholder {
  color: #9f123933;
}

.placeholder-rose-800\/25::placeholder {
  color: #9f123940;
}

.placeholder-rose-800\/30::placeholder {
  color: #9f12394d;
}

.placeholder-rose-800\/40::placeholder {
  color: #9f123966;
}

.placeholder-rose-800\/5::placeholder {
  color: #9f12390d;
}

.placeholder-rose-800\/50::placeholder {
  color: #9f123980;
}

.placeholder-rose-800\/60::placeholder {
  color: #9f123999;
}

.placeholder-rose-800\/70::placeholder {
  color: #9f1239b3;
}

.placeholder-rose-800\/75::placeholder {
  color: #9f1239bf;
}

.placeholder-rose-800\/80::placeholder {
  color: #9f1239cc;
}

.placeholder-rose-800\/90::placeholder {
  color: #9f1239e6;
}

.placeholder-rose-800\/95::placeholder {
  color: #9f1239f2;
}

.placeholder-rose-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(136 19 55 / var(--tw-placeholder-opacity));
}

.placeholder-rose-900\/0::placeholder {
  color: #88133700;
}

.placeholder-rose-900\/10::placeholder {
  color: #8813371a;
}

.placeholder-rose-900\/100::placeholder {
  color: #881337;
}

.placeholder-rose-900\/20::placeholder {
  color: #88133733;
}

.placeholder-rose-900\/25::placeholder {
  color: #88133740;
}

.placeholder-rose-900\/30::placeholder {
  color: #8813374d;
}

.placeholder-rose-900\/40::placeholder {
  color: #88133766;
}

.placeholder-rose-900\/5::placeholder {
  color: #8813370d;
}

.placeholder-rose-900\/50::placeholder {
  color: #88133780;
}

.placeholder-rose-900\/60::placeholder {
  color: #88133799;
}

.placeholder-rose-900\/70::placeholder {
  color: #881337b3;
}

.placeholder-rose-900\/75::placeholder {
  color: #881337bf;
}

.placeholder-rose-900\/80::placeholder {
  color: #881337cc;
}

.placeholder-rose-900\/90::placeholder {
  color: #881337e6;
}

.placeholder-rose-900\/95::placeholder {
  color: #881337f2;
}

.placeholder-rose-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(76 5 25 / var(--tw-placeholder-opacity));
}

.placeholder-rose-950\/0::placeholder {
  color: #4c051900;
}

.placeholder-rose-950\/10::placeholder {
  color: #4c05191a;
}

.placeholder-rose-950\/100::placeholder {
  color: #4c0519;
}

.placeholder-rose-950\/20::placeholder {
  color: #4c051933;
}

.placeholder-rose-950\/25::placeholder {
  color: #4c051940;
}

.placeholder-rose-950\/30::placeholder {
  color: #4c05194d;
}

.placeholder-rose-950\/40::placeholder {
  color: #4c051966;
}

.placeholder-rose-950\/5::placeholder {
  color: #4c05190d;
}

.placeholder-rose-950\/50::placeholder {
  color: #4c051980;
}

.placeholder-rose-950\/60::placeholder {
  color: #4c051999;
}

.placeholder-rose-950\/70::placeholder {
  color: #4c0519b3;
}

.placeholder-rose-950\/75::placeholder {
  color: #4c0519bf;
}

.placeholder-rose-950\/80::placeholder {
  color: #4c0519cc;
}

.placeholder-rose-950\/90::placeholder {
  color: #4c0519e6;
}

.placeholder-rose-950\/95::placeholder {
  color: #4c0519f2;
}

.placeholder-sky-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(224 242 254 / var(--tw-placeholder-opacity));
}

.placeholder-sky-100\/0::placeholder {
  color: #e0f2fe00;
}

.placeholder-sky-100\/10::placeholder {
  color: #e0f2fe1a;
}

.placeholder-sky-100\/100::placeholder {
  color: #e0f2fe;
}

.placeholder-sky-100\/20::placeholder {
  color: #e0f2fe33;
}

.placeholder-sky-100\/25::placeholder {
  color: #e0f2fe40;
}

.placeholder-sky-100\/30::placeholder {
  color: #e0f2fe4d;
}

.placeholder-sky-100\/40::placeholder {
  color: #e0f2fe66;
}

.placeholder-sky-100\/5::placeholder {
  color: #e0f2fe0d;
}

.placeholder-sky-100\/50::placeholder {
  color: #e0f2fe80;
}

.placeholder-sky-100\/60::placeholder {
  color: #e0f2fe99;
}

.placeholder-sky-100\/70::placeholder {
  color: #e0f2feb3;
}

.placeholder-sky-100\/75::placeholder {
  color: #e0f2febf;
}

.placeholder-sky-100\/80::placeholder {
  color: #e0f2fecc;
}

.placeholder-sky-100\/90::placeholder {
  color: #e0f2fee6;
}

.placeholder-sky-100\/95::placeholder {
  color: #e0f2fef2;
}

.placeholder-sky-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(186 230 253 / var(--tw-placeholder-opacity));
}

.placeholder-sky-200\/0::placeholder {
  color: #bae6fd00;
}

.placeholder-sky-200\/10::placeholder {
  color: #bae6fd1a;
}

.placeholder-sky-200\/100::placeholder {
  color: #bae6fd;
}

.placeholder-sky-200\/20::placeholder {
  color: #bae6fd33;
}

.placeholder-sky-200\/25::placeholder {
  color: #bae6fd40;
}

.placeholder-sky-200\/30::placeholder {
  color: #bae6fd4d;
}

.placeholder-sky-200\/40::placeholder {
  color: #bae6fd66;
}

.placeholder-sky-200\/5::placeholder {
  color: #bae6fd0d;
}

.placeholder-sky-200\/50::placeholder {
  color: #bae6fd80;
}

.placeholder-sky-200\/60::placeholder {
  color: #bae6fd99;
}

.placeholder-sky-200\/70::placeholder {
  color: #bae6fdb3;
}

.placeholder-sky-200\/75::placeholder {
  color: #bae6fdbf;
}

.placeholder-sky-200\/80::placeholder {
  color: #bae6fdcc;
}

.placeholder-sky-200\/90::placeholder {
  color: #bae6fde6;
}

.placeholder-sky-200\/95::placeholder {
  color: #bae6fdf2;
}

.placeholder-sky-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(125 211 252 / var(--tw-placeholder-opacity));
}

.placeholder-sky-300\/0::placeholder {
  color: #7dd3fc00;
}

.placeholder-sky-300\/10::placeholder {
  color: #7dd3fc1a;
}

.placeholder-sky-300\/100::placeholder {
  color: #7dd3fc;
}

.placeholder-sky-300\/20::placeholder {
  color: #7dd3fc33;
}

.placeholder-sky-300\/25::placeholder {
  color: #7dd3fc40;
}

.placeholder-sky-300\/30::placeholder {
  color: #7dd3fc4d;
}

.placeholder-sky-300\/40::placeholder {
  color: #7dd3fc66;
}

.placeholder-sky-300\/5::placeholder {
  color: #7dd3fc0d;
}

.placeholder-sky-300\/50::placeholder {
  color: #7dd3fc80;
}

.placeholder-sky-300\/60::placeholder {
  color: #7dd3fc99;
}

.placeholder-sky-300\/70::placeholder {
  color: #7dd3fcb3;
}

.placeholder-sky-300\/75::placeholder {
  color: #7dd3fcbf;
}

.placeholder-sky-300\/80::placeholder {
  color: #7dd3fccc;
}

.placeholder-sky-300\/90::placeholder {
  color: #7dd3fce6;
}

.placeholder-sky-300\/95::placeholder {
  color: #7dd3fcf2;
}

.placeholder-sky-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(56 189 248 / var(--tw-placeholder-opacity));
}

.placeholder-sky-400\/0::placeholder {
  color: #38bdf800;
}

.placeholder-sky-400\/10::placeholder {
  color: #38bdf81a;
}

.placeholder-sky-400\/100::placeholder {
  color: #38bdf8;
}

.placeholder-sky-400\/20::placeholder {
  color: #38bdf833;
}

.placeholder-sky-400\/25::placeholder {
  color: #38bdf840;
}

.placeholder-sky-400\/30::placeholder {
  color: #38bdf84d;
}

.placeholder-sky-400\/40::placeholder {
  color: #38bdf866;
}

.placeholder-sky-400\/5::placeholder {
  color: #38bdf80d;
}

.placeholder-sky-400\/50::placeholder {
  color: #38bdf880;
}

.placeholder-sky-400\/60::placeholder {
  color: #38bdf899;
}

.placeholder-sky-400\/70::placeholder {
  color: #38bdf8b3;
}

.placeholder-sky-400\/75::placeholder {
  color: #38bdf8bf;
}

.placeholder-sky-400\/80::placeholder {
  color: #38bdf8cc;
}

.placeholder-sky-400\/90::placeholder {
  color: #38bdf8e6;
}

.placeholder-sky-400\/95::placeholder {
  color: #38bdf8f2;
}

.placeholder-sky-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(240 249 255 / var(--tw-placeholder-opacity));
}

.placeholder-sky-50\/0::placeholder {
  color: #f0f9ff00;
}

.placeholder-sky-50\/10::placeholder {
  color: #f0f9ff1a;
}

.placeholder-sky-50\/100::placeholder {
  color: #f0f9ff;
}

.placeholder-sky-50\/20::placeholder {
  color: #f0f9ff33;
}

.placeholder-sky-50\/25::placeholder {
  color: #f0f9ff40;
}

.placeholder-sky-50\/30::placeholder {
  color: #f0f9ff4d;
}

.placeholder-sky-50\/40::placeholder {
  color: #f0f9ff66;
}

.placeholder-sky-50\/5::placeholder {
  color: #f0f9ff0d;
}

.placeholder-sky-50\/50::placeholder {
  color: #f0f9ff80;
}

.placeholder-sky-50\/60::placeholder {
  color: #f0f9ff99;
}

.placeholder-sky-50\/70::placeholder {
  color: #f0f9ffb3;
}

.placeholder-sky-50\/75::placeholder {
  color: #f0f9ffbf;
}

.placeholder-sky-50\/80::placeholder {
  color: #f0f9ffcc;
}

.placeholder-sky-50\/90::placeholder {
  color: #f0f9ffe6;
}

.placeholder-sky-50\/95::placeholder {
  color: #f0f9fff2;
}

.placeholder-sky-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(14 165 233 / var(--tw-placeholder-opacity));
}

.placeholder-sky-500\/0::placeholder {
  color: #0ea5e900;
}

.placeholder-sky-500\/10::placeholder {
  color: #0ea5e91a;
}

.placeholder-sky-500\/100::placeholder {
  color: #0ea5e9;
}

.placeholder-sky-500\/20::placeholder {
  color: #0ea5e933;
}

.placeholder-sky-500\/25::placeholder {
  color: #0ea5e940;
}

.placeholder-sky-500\/30::placeholder {
  color: #0ea5e94d;
}

.placeholder-sky-500\/40::placeholder {
  color: #0ea5e966;
}

.placeholder-sky-500\/5::placeholder {
  color: #0ea5e90d;
}

.placeholder-sky-500\/50::placeholder {
  color: #0ea5e980;
}

.placeholder-sky-500\/60::placeholder {
  color: #0ea5e999;
}

.placeholder-sky-500\/70::placeholder {
  color: #0ea5e9b3;
}

.placeholder-sky-500\/75::placeholder {
  color: #0ea5e9bf;
}

.placeholder-sky-500\/80::placeholder {
  color: #0ea5e9cc;
}

.placeholder-sky-500\/90::placeholder {
  color: #0ea5e9e6;
}

.placeholder-sky-500\/95::placeholder {
  color: #0ea5e9f2;
}

.placeholder-sky-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(2 132 199 / var(--tw-placeholder-opacity));
}

.placeholder-sky-600\/0::placeholder {
  color: #0284c700;
}

.placeholder-sky-600\/10::placeholder {
  color: #0284c71a;
}

.placeholder-sky-600\/100::placeholder {
  color: #0284c7;
}

.placeholder-sky-600\/20::placeholder {
  color: #0284c733;
}

.placeholder-sky-600\/25::placeholder {
  color: #0284c740;
}

.placeholder-sky-600\/30::placeholder {
  color: #0284c74d;
}

.placeholder-sky-600\/40::placeholder {
  color: #0284c766;
}

.placeholder-sky-600\/5::placeholder {
  color: #0284c70d;
}

.placeholder-sky-600\/50::placeholder {
  color: #0284c780;
}

.placeholder-sky-600\/60::placeholder {
  color: #0284c799;
}

.placeholder-sky-600\/70::placeholder {
  color: #0284c7b3;
}

.placeholder-sky-600\/75::placeholder {
  color: #0284c7bf;
}

.placeholder-sky-600\/80::placeholder {
  color: #0284c7cc;
}

.placeholder-sky-600\/90::placeholder {
  color: #0284c7e6;
}

.placeholder-sky-600\/95::placeholder {
  color: #0284c7f2;
}

.placeholder-sky-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(3 105 161 / var(--tw-placeholder-opacity));
}

.placeholder-sky-700\/0::placeholder {
  color: #0369a100;
}

.placeholder-sky-700\/10::placeholder {
  color: #0369a11a;
}

.placeholder-sky-700\/100::placeholder {
  color: #0369a1;
}

.placeholder-sky-700\/20::placeholder {
  color: #0369a133;
}

.placeholder-sky-700\/25::placeholder {
  color: #0369a140;
}

.placeholder-sky-700\/30::placeholder {
  color: #0369a14d;
}

.placeholder-sky-700\/40::placeholder {
  color: #0369a166;
}

.placeholder-sky-700\/5::placeholder {
  color: #0369a10d;
}

.placeholder-sky-700\/50::placeholder {
  color: #0369a180;
}

.placeholder-sky-700\/60::placeholder {
  color: #0369a199;
}

.placeholder-sky-700\/70::placeholder {
  color: #0369a1b3;
}

.placeholder-sky-700\/75::placeholder {
  color: #0369a1bf;
}

.placeholder-sky-700\/80::placeholder {
  color: #0369a1cc;
}

.placeholder-sky-700\/90::placeholder {
  color: #0369a1e6;
}

.placeholder-sky-700\/95::placeholder {
  color: #0369a1f2;
}

.placeholder-sky-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(7 89 133 / var(--tw-placeholder-opacity));
}

.placeholder-sky-800\/0::placeholder {
  color: #07598500;
}

.placeholder-sky-800\/10::placeholder {
  color: #0759851a;
}

.placeholder-sky-800\/100::placeholder {
  color: #075985;
}

.placeholder-sky-800\/20::placeholder {
  color: #07598533;
}

.placeholder-sky-800\/25::placeholder {
  color: #07598540;
}

.placeholder-sky-800\/30::placeholder {
  color: #0759854d;
}

.placeholder-sky-800\/40::placeholder {
  color: #07598566;
}

.placeholder-sky-800\/5::placeholder {
  color: #0759850d;
}

.placeholder-sky-800\/50::placeholder {
  color: #07598580;
}

.placeholder-sky-800\/60::placeholder {
  color: #07598599;
}

.placeholder-sky-800\/70::placeholder {
  color: #075985b3;
}

.placeholder-sky-800\/75::placeholder {
  color: #075985bf;
}

.placeholder-sky-800\/80::placeholder {
  color: #075985cc;
}

.placeholder-sky-800\/90::placeholder {
  color: #075985e6;
}

.placeholder-sky-800\/95::placeholder {
  color: #075985f2;
}

.placeholder-sky-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(12 74 110 / var(--tw-placeholder-opacity));
}

.placeholder-sky-900\/0::placeholder {
  color: #0c4a6e00;
}

.placeholder-sky-900\/10::placeholder {
  color: #0c4a6e1a;
}

.placeholder-sky-900\/100::placeholder {
  color: #0c4a6e;
}

.placeholder-sky-900\/20::placeholder {
  color: #0c4a6e33;
}

.placeholder-sky-900\/25::placeholder {
  color: #0c4a6e40;
}

.placeholder-sky-900\/30::placeholder {
  color: #0c4a6e4d;
}

.placeholder-sky-900\/40::placeholder {
  color: #0c4a6e66;
}

.placeholder-sky-900\/5::placeholder {
  color: #0c4a6e0d;
}

.placeholder-sky-900\/50::placeholder {
  color: #0c4a6e80;
}

.placeholder-sky-900\/60::placeholder {
  color: #0c4a6e99;
}

.placeholder-sky-900\/70::placeholder {
  color: #0c4a6eb3;
}

.placeholder-sky-900\/75::placeholder {
  color: #0c4a6ebf;
}

.placeholder-sky-900\/80::placeholder {
  color: #0c4a6ecc;
}

.placeholder-sky-900\/90::placeholder {
  color: #0c4a6ee6;
}

.placeholder-sky-900\/95::placeholder {
  color: #0c4a6ef2;
}

.placeholder-sky-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(8 47 73 / var(--tw-placeholder-opacity));
}

.placeholder-sky-950\/0::placeholder {
  color: #082f4900;
}

.placeholder-sky-950\/10::placeholder {
  color: #082f491a;
}

.placeholder-sky-950\/100::placeholder {
  color: #082f49;
}

.placeholder-sky-950\/20::placeholder {
  color: #082f4933;
}

.placeholder-sky-950\/25::placeholder {
  color: #082f4940;
}

.placeholder-sky-950\/30::placeholder {
  color: #082f494d;
}

.placeholder-sky-950\/40::placeholder {
  color: #082f4966;
}

.placeholder-sky-950\/5::placeholder {
  color: #082f490d;
}

.placeholder-sky-950\/50::placeholder {
  color: #082f4980;
}

.placeholder-sky-950\/60::placeholder {
  color: #082f4999;
}

.placeholder-sky-950\/70::placeholder {
  color: #082f49b3;
}

.placeholder-sky-950\/75::placeholder {
  color: #082f49bf;
}

.placeholder-sky-950\/80::placeholder {
  color: #082f49cc;
}

.placeholder-sky-950\/90::placeholder {
  color: #082f49e6;
}

.placeholder-sky-950\/95::placeholder {
  color: #082f49f2;
}

.placeholder-slate-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(241 245 249 / var(--tw-placeholder-opacity));
}

.placeholder-slate-100\/0::placeholder {
  color: #f1f5f900;
}

.placeholder-slate-100\/10::placeholder {
  color: #f1f5f91a;
}

.placeholder-slate-100\/100::placeholder {
  color: #f1f5f9;
}

.placeholder-slate-100\/20::placeholder {
  color: #f1f5f933;
}

.placeholder-slate-100\/25::placeholder {
  color: #f1f5f940;
}

.placeholder-slate-100\/30::placeholder {
  color: #f1f5f94d;
}

.placeholder-slate-100\/40::placeholder {
  color: #f1f5f966;
}

.placeholder-slate-100\/5::placeholder {
  color: #f1f5f90d;
}

.placeholder-slate-100\/50::placeholder {
  color: #f1f5f980;
}

.placeholder-slate-100\/60::placeholder {
  color: #f1f5f999;
}

.placeholder-slate-100\/70::placeholder {
  color: #f1f5f9b3;
}

.placeholder-slate-100\/75::placeholder {
  color: #f1f5f9bf;
}

.placeholder-slate-100\/80::placeholder {
  color: #f1f5f9cc;
}

.placeholder-slate-100\/90::placeholder {
  color: #f1f5f9e6;
}

.placeholder-slate-100\/95::placeholder {
  color: #f1f5f9f2;
}

.placeholder-slate-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(226 232 240 / var(--tw-placeholder-opacity));
}

.placeholder-slate-200\/0::placeholder {
  color: #e2e8f000;
}

.placeholder-slate-200\/10::placeholder {
  color: #e2e8f01a;
}

.placeholder-slate-200\/100::placeholder {
  color: #e2e8f0;
}

.placeholder-slate-200\/20::placeholder {
  color: #e2e8f033;
}

.placeholder-slate-200\/25::placeholder {
  color: #e2e8f040;
}

.placeholder-slate-200\/30::placeholder {
  color: #e2e8f04d;
}

.placeholder-slate-200\/40::placeholder {
  color: #e2e8f066;
}

.placeholder-slate-200\/5::placeholder {
  color: #e2e8f00d;
}

.placeholder-slate-200\/50::placeholder {
  color: #e2e8f080;
}

.placeholder-slate-200\/60::placeholder {
  color: #e2e8f099;
}

.placeholder-slate-200\/70::placeholder {
  color: #e2e8f0b3;
}

.placeholder-slate-200\/75::placeholder {
  color: #e2e8f0bf;
}

.placeholder-slate-200\/80::placeholder {
  color: #e2e8f0cc;
}

.placeholder-slate-200\/90::placeholder {
  color: #e2e8f0e6;
}

.placeholder-slate-200\/95::placeholder {
  color: #e2e8f0f2;
}

.placeholder-slate-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(203 213 225 / var(--tw-placeholder-opacity));
}

.placeholder-slate-300\/0::placeholder {
  color: #cbd5e100;
}

.placeholder-slate-300\/10::placeholder {
  color: #cbd5e11a;
}

.placeholder-slate-300\/100::placeholder {
  color: #cbd5e1;
}

.placeholder-slate-300\/20::placeholder {
  color: #cbd5e133;
}

.placeholder-slate-300\/25::placeholder {
  color: #cbd5e140;
}

.placeholder-slate-300\/30::placeholder {
  color: #cbd5e14d;
}

.placeholder-slate-300\/40::placeholder {
  color: #cbd5e166;
}

.placeholder-slate-300\/5::placeholder {
  color: #cbd5e10d;
}

.placeholder-slate-300\/50::placeholder {
  color: #cbd5e180;
}

.placeholder-slate-300\/60::placeholder {
  color: #cbd5e199;
}

.placeholder-slate-300\/70::placeholder {
  color: #cbd5e1b3;
}

.placeholder-slate-300\/75::placeholder {
  color: #cbd5e1bf;
}

.placeholder-slate-300\/80::placeholder {
  color: #cbd5e1cc;
}

.placeholder-slate-300\/90::placeholder {
  color: #cbd5e1e6;
}

.placeholder-slate-300\/95::placeholder {
  color: #cbd5e1f2;
}

.placeholder-slate-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}

.placeholder-slate-400\/0::placeholder {
  color: #94a3b800;
}

.placeholder-slate-400\/10::placeholder {
  color: #94a3b81a;
}

.placeholder-slate-400\/100::placeholder {
  color: #94a3b8;
}

.placeholder-slate-400\/20::placeholder {
  color: #94a3b833;
}

.placeholder-slate-400\/25::placeholder {
  color: #94a3b840;
}

.placeholder-slate-400\/30::placeholder {
  color: #94a3b84d;
}

.placeholder-slate-400\/40::placeholder {
  color: #94a3b866;
}

.placeholder-slate-400\/5::placeholder {
  color: #94a3b80d;
}

.placeholder-slate-400\/50::placeholder {
  color: #94a3b880;
}

.placeholder-slate-400\/60::placeholder {
  color: #94a3b899;
}

.placeholder-slate-400\/70::placeholder {
  color: #94a3b8b3;
}

.placeholder-slate-400\/75::placeholder {
  color: #94a3b8bf;
}

.placeholder-slate-400\/80::placeholder {
  color: #94a3b8cc;
}

.placeholder-slate-400\/90::placeholder {
  color: #94a3b8e6;
}

.placeholder-slate-400\/95::placeholder {
  color: #94a3b8f2;
}

.placeholder-slate-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(248 250 252 / var(--tw-placeholder-opacity));
}

.placeholder-slate-50\/0::placeholder {
  color: #f8fafc00;
}

.placeholder-slate-50\/10::placeholder {
  color: #f8fafc1a;
}

.placeholder-slate-50\/100::placeholder {
  color: #f8fafc;
}

.placeholder-slate-50\/20::placeholder {
  color: #f8fafc33;
}

.placeholder-slate-50\/25::placeholder {
  color: #f8fafc40;
}

.placeholder-slate-50\/30::placeholder {
  color: #f8fafc4d;
}

.placeholder-slate-50\/40::placeholder {
  color: #f8fafc66;
}

.placeholder-slate-50\/5::placeholder {
  color: #f8fafc0d;
}

.placeholder-slate-50\/50::placeholder {
  color: #f8fafc80;
}

.placeholder-slate-50\/60::placeholder {
  color: #f8fafc99;
}

.placeholder-slate-50\/70::placeholder {
  color: #f8fafcb3;
}

.placeholder-slate-50\/75::placeholder {
  color: #f8fafcbf;
}

.placeholder-slate-50\/80::placeholder {
  color: #f8fafccc;
}

.placeholder-slate-50\/90::placeholder {
  color: #f8fafce6;
}

.placeholder-slate-50\/95::placeholder {
  color: #f8fafcf2;
}

.placeholder-slate-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(100 116 139 / var(--tw-placeholder-opacity));
}

.placeholder-slate-500\/0::placeholder {
  color: #64748b00;
}

.placeholder-slate-500\/10::placeholder {
  color: #64748b1a;
}

.placeholder-slate-500\/100::placeholder {
  color: #64748b;
}

.placeholder-slate-500\/20::placeholder {
  color: #64748b33;
}

.placeholder-slate-500\/25::placeholder {
  color: #64748b40;
}

.placeholder-slate-500\/30::placeholder {
  color: #64748b4d;
}

.placeholder-slate-500\/40::placeholder {
  color: #64748b66;
}

.placeholder-slate-500\/5::placeholder {
  color: #64748b0d;
}

.placeholder-slate-500\/50::placeholder {
  color: #64748b80;
}

.placeholder-slate-500\/60::placeholder {
  color: #64748b99;
}

.placeholder-slate-500\/70::placeholder {
  color: #64748bb3;
}

.placeholder-slate-500\/75::placeholder {
  color: #64748bbf;
}

.placeholder-slate-500\/80::placeholder {
  color: #64748bcc;
}

.placeholder-slate-500\/90::placeholder {
  color: #64748be6;
}

.placeholder-slate-500\/95::placeholder {
  color: #64748bf2;
}

.placeholder-slate-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(71 85 105 / var(--tw-placeholder-opacity));
}

.placeholder-slate-600\/0::placeholder {
  color: #47556900;
}

.placeholder-slate-600\/10::placeholder {
  color: #4755691a;
}

.placeholder-slate-600\/100::placeholder {
  color: #475569;
}

.placeholder-slate-600\/20::placeholder {
  color: #47556933;
}

.placeholder-slate-600\/25::placeholder {
  color: #47556940;
}

.placeholder-slate-600\/30::placeholder {
  color: #4755694d;
}

.placeholder-slate-600\/40::placeholder {
  color: #47556966;
}

.placeholder-slate-600\/5::placeholder {
  color: #4755690d;
}

.placeholder-slate-600\/50::placeholder {
  color: #47556980;
}

.placeholder-slate-600\/60::placeholder {
  color: #47556999;
}

.placeholder-slate-600\/70::placeholder {
  color: #475569b3;
}

.placeholder-slate-600\/75::placeholder {
  color: #475569bf;
}

.placeholder-slate-600\/80::placeholder {
  color: #475569cc;
}

.placeholder-slate-600\/90::placeholder {
  color: #475569e6;
}

.placeholder-slate-600\/95::placeholder {
  color: #475569f2;
}

.placeholder-slate-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(51 65 85 / var(--tw-placeholder-opacity));
}

.placeholder-slate-700\/0::placeholder {
  color: #33415500;
}

.placeholder-slate-700\/10::placeholder {
  color: #3341551a;
}

.placeholder-slate-700\/100::placeholder {
  color: #334155;
}

.placeholder-slate-700\/20::placeholder {
  color: #33415533;
}

.placeholder-slate-700\/25::placeholder {
  color: #33415540;
}

.placeholder-slate-700\/30::placeholder {
  color: #3341554d;
}

.placeholder-slate-700\/40::placeholder {
  color: #33415566;
}

.placeholder-slate-700\/5::placeholder {
  color: #3341550d;
}

.placeholder-slate-700\/50::placeholder {
  color: #33415580;
}

.placeholder-slate-700\/60::placeholder {
  color: #33415599;
}

.placeholder-slate-700\/70::placeholder {
  color: #334155b3;
}

.placeholder-slate-700\/75::placeholder {
  color: #334155bf;
}

.placeholder-slate-700\/80::placeholder {
  color: #334155cc;
}

.placeholder-slate-700\/90::placeholder {
  color: #334155e6;
}

.placeholder-slate-700\/95::placeholder {
  color: #334155f2;
}

.placeholder-slate-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(30 41 59 / var(--tw-placeholder-opacity));
}

.placeholder-slate-800\/0::placeholder {
  color: #1e293b00;
}

.placeholder-slate-800\/10::placeholder {
  color: #1e293b1a;
}

.placeholder-slate-800\/100::placeholder {
  color: #1e293b;
}

.placeholder-slate-800\/20::placeholder {
  color: #1e293b33;
}

.placeholder-slate-800\/25::placeholder {
  color: #1e293b40;
}

.placeholder-slate-800\/30::placeholder {
  color: #1e293b4d;
}

.placeholder-slate-800\/40::placeholder {
  color: #1e293b66;
}

.placeholder-slate-800\/5::placeholder {
  color: #1e293b0d;
}

.placeholder-slate-800\/50::placeholder {
  color: #1e293b80;
}

.placeholder-slate-800\/60::placeholder {
  color: #1e293b99;
}

.placeholder-slate-800\/70::placeholder {
  color: #1e293bb3;
}

.placeholder-slate-800\/75::placeholder {
  color: #1e293bbf;
}

.placeholder-slate-800\/80::placeholder {
  color: #1e293bcc;
}

.placeholder-slate-800\/90::placeholder {
  color: #1e293be6;
}

.placeholder-slate-800\/95::placeholder {
  color: #1e293bf2;
}

.placeholder-slate-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(15 23 42 / var(--tw-placeholder-opacity));
}

.placeholder-slate-900\/0::placeholder {
  color: #0f172a00;
}

.placeholder-slate-900\/10::placeholder {
  color: #0f172a1a;
}

.placeholder-slate-900\/100::placeholder {
  color: #0f172a;
}

.placeholder-slate-900\/20::placeholder {
  color: #0f172a33;
}

.placeholder-slate-900\/25::placeholder {
  color: #0f172a40;
}

.placeholder-slate-900\/30::placeholder {
  color: #0f172a4d;
}

.placeholder-slate-900\/40::placeholder {
  color: #0f172a66;
}

.placeholder-slate-900\/5::placeholder {
  color: #0f172a0d;
}

.placeholder-slate-900\/50::placeholder {
  color: #0f172a80;
}

.placeholder-slate-900\/60::placeholder {
  color: #0f172a99;
}

.placeholder-slate-900\/70::placeholder {
  color: #0f172ab3;
}

.placeholder-slate-900\/75::placeholder {
  color: #0f172abf;
}

.placeholder-slate-900\/80::placeholder {
  color: #0f172acc;
}

.placeholder-slate-900\/90::placeholder {
  color: #0f172ae6;
}

.placeholder-slate-900\/95::placeholder {
  color: #0f172af2;
}

.placeholder-slate-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(2 6 23 / var(--tw-placeholder-opacity));
}

.placeholder-slate-950\/0::placeholder {
  color: #02061700;
}

.placeholder-slate-950\/10::placeholder {
  color: #0206171a;
}

.placeholder-slate-950\/100::placeholder {
  color: #020617;
}

.placeholder-slate-950\/20::placeholder {
  color: #02061733;
}

.placeholder-slate-950\/25::placeholder {
  color: #02061740;
}

.placeholder-slate-950\/30::placeholder {
  color: #0206174d;
}

.placeholder-slate-950\/40::placeholder {
  color: #02061766;
}

.placeholder-slate-950\/5::placeholder {
  color: #0206170d;
}

.placeholder-slate-950\/50::placeholder {
  color: #02061780;
}

.placeholder-slate-950\/60::placeholder {
  color: #02061799;
}

.placeholder-slate-950\/70::placeholder {
  color: #020617b3;
}

.placeholder-slate-950\/75::placeholder {
  color: #020617bf;
}

.placeholder-slate-950\/80::placeholder {
  color: #020617cc;
}

.placeholder-slate-950\/90::placeholder {
  color: #020617e6;
}

.placeholder-slate-950\/95::placeholder {
  color: #020617f2;
}

.placeholder-stone-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(245 245 244 / var(--tw-placeholder-opacity));
}

.placeholder-stone-100\/0::placeholder {
  color: #f5f5f400;
}

.placeholder-stone-100\/10::placeholder {
  color: #f5f5f41a;
}

.placeholder-stone-100\/100::placeholder {
  color: #f5f5f4;
}

.placeholder-stone-100\/20::placeholder {
  color: #f5f5f433;
}

.placeholder-stone-100\/25::placeholder {
  color: #f5f5f440;
}

.placeholder-stone-100\/30::placeholder {
  color: #f5f5f44d;
}

.placeholder-stone-100\/40::placeholder {
  color: #f5f5f466;
}

.placeholder-stone-100\/5::placeholder {
  color: #f5f5f40d;
}

.placeholder-stone-100\/50::placeholder {
  color: #f5f5f480;
}

.placeholder-stone-100\/60::placeholder {
  color: #f5f5f499;
}

.placeholder-stone-100\/70::placeholder {
  color: #f5f5f4b3;
}

.placeholder-stone-100\/75::placeholder {
  color: #f5f5f4bf;
}

.placeholder-stone-100\/80::placeholder {
  color: #f5f5f4cc;
}

.placeholder-stone-100\/90::placeholder {
  color: #f5f5f4e6;
}

.placeholder-stone-100\/95::placeholder {
  color: #f5f5f4f2;
}

.placeholder-stone-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(231 229 228 / var(--tw-placeholder-opacity));
}

.placeholder-stone-200\/0::placeholder {
  color: #e7e5e400;
}

.placeholder-stone-200\/10::placeholder {
  color: #e7e5e41a;
}

.placeholder-stone-200\/100::placeholder {
  color: #e7e5e4;
}

.placeholder-stone-200\/20::placeholder {
  color: #e7e5e433;
}

.placeholder-stone-200\/25::placeholder {
  color: #e7e5e440;
}

.placeholder-stone-200\/30::placeholder {
  color: #e7e5e44d;
}

.placeholder-stone-200\/40::placeholder {
  color: #e7e5e466;
}

.placeholder-stone-200\/5::placeholder {
  color: #e7e5e40d;
}

.placeholder-stone-200\/50::placeholder {
  color: #e7e5e480;
}

.placeholder-stone-200\/60::placeholder {
  color: #e7e5e499;
}

.placeholder-stone-200\/70::placeholder {
  color: #e7e5e4b3;
}

.placeholder-stone-200\/75::placeholder {
  color: #e7e5e4bf;
}

.placeholder-stone-200\/80::placeholder {
  color: #e7e5e4cc;
}

.placeholder-stone-200\/90::placeholder {
  color: #e7e5e4e6;
}

.placeholder-stone-200\/95::placeholder {
  color: #e7e5e4f2;
}

.placeholder-stone-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(214 211 209 / var(--tw-placeholder-opacity));
}

.placeholder-stone-300\/0::placeholder {
  color: #d6d3d100;
}

.placeholder-stone-300\/10::placeholder {
  color: #d6d3d11a;
}

.placeholder-stone-300\/100::placeholder {
  color: #d6d3d1;
}

.placeholder-stone-300\/20::placeholder {
  color: #d6d3d133;
}

.placeholder-stone-300\/25::placeholder {
  color: #d6d3d140;
}

.placeholder-stone-300\/30::placeholder {
  color: #d6d3d14d;
}

.placeholder-stone-300\/40::placeholder {
  color: #d6d3d166;
}

.placeholder-stone-300\/5::placeholder {
  color: #d6d3d10d;
}

.placeholder-stone-300\/50::placeholder {
  color: #d6d3d180;
}

.placeholder-stone-300\/60::placeholder {
  color: #d6d3d199;
}

.placeholder-stone-300\/70::placeholder {
  color: #d6d3d1b3;
}

.placeholder-stone-300\/75::placeholder {
  color: #d6d3d1bf;
}

.placeholder-stone-300\/80::placeholder {
  color: #d6d3d1cc;
}

.placeholder-stone-300\/90::placeholder {
  color: #d6d3d1e6;
}

.placeholder-stone-300\/95::placeholder {
  color: #d6d3d1f2;
}

.placeholder-stone-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(168 162 158 / var(--tw-placeholder-opacity));
}

.placeholder-stone-400\/0::placeholder {
  color: #a8a29e00;
}

.placeholder-stone-400\/10::placeholder {
  color: #a8a29e1a;
}

.placeholder-stone-400\/100::placeholder {
  color: #a8a29e;
}

.placeholder-stone-400\/20::placeholder {
  color: #a8a29e33;
}

.placeholder-stone-400\/25::placeholder {
  color: #a8a29e40;
}

.placeholder-stone-400\/30::placeholder {
  color: #a8a29e4d;
}

.placeholder-stone-400\/40::placeholder {
  color: #a8a29e66;
}

.placeholder-stone-400\/5::placeholder {
  color: #a8a29e0d;
}

.placeholder-stone-400\/50::placeholder {
  color: #a8a29e80;
}

.placeholder-stone-400\/60::placeholder {
  color: #a8a29e99;
}

.placeholder-stone-400\/70::placeholder {
  color: #a8a29eb3;
}

.placeholder-stone-400\/75::placeholder {
  color: #a8a29ebf;
}

.placeholder-stone-400\/80::placeholder {
  color: #a8a29ecc;
}

.placeholder-stone-400\/90::placeholder {
  color: #a8a29ee6;
}

.placeholder-stone-400\/95::placeholder {
  color: #a8a29ef2;
}

.placeholder-stone-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(250 250 249 / var(--tw-placeholder-opacity));
}

.placeholder-stone-50\/0::placeholder {
  color: #fafaf900;
}

.placeholder-stone-50\/10::placeholder {
  color: #fafaf91a;
}

.placeholder-stone-50\/100::placeholder {
  color: #fafaf9;
}

.placeholder-stone-50\/20::placeholder {
  color: #fafaf933;
}

.placeholder-stone-50\/25::placeholder {
  color: #fafaf940;
}

.placeholder-stone-50\/30::placeholder {
  color: #fafaf94d;
}

.placeholder-stone-50\/40::placeholder {
  color: #fafaf966;
}

.placeholder-stone-50\/5::placeholder {
  color: #fafaf90d;
}

.placeholder-stone-50\/50::placeholder {
  color: #fafaf980;
}

.placeholder-stone-50\/60::placeholder {
  color: #fafaf999;
}

.placeholder-stone-50\/70::placeholder {
  color: #fafaf9b3;
}

.placeholder-stone-50\/75::placeholder {
  color: #fafaf9bf;
}

.placeholder-stone-50\/80::placeholder {
  color: #fafaf9cc;
}

.placeholder-stone-50\/90::placeholder {
  color: #fafaf9e6;
}

.placeholder-stone-50\/95::placeholder {
  color: #fafaf9f2;
}

.placeholder-stone-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(120 113 108 / var(--tw-placeholder-opacity));
}

.placeholder-stone-500\/0::placeholder {
  color: #78716c00;
}

.placeholder-stone-500\/10::placeholder {
  color: #78716c1a;
}

.placeholder-stone-500\/100::placeholder {
  color: #78716c;
}

.placeholder-stone-500\/20::placeholder {
  color: #78716c33;
}

.placeholder-stone-500\/25::placeholder {
  color: #78716c40;
}

.placeholder-stone-500\/30::placeholder {
  color: #78716c4d;
}

.placeholder-stone-500\/40::placeholder {
  color: #78716c66;
}

.placeholder-stone-500\/5::placeholder {
  color: #78716c0d;
}

.placeholder-stone-500\/50::placeholder {
  color: #78716c80;
}

.placeholder-stone-500\/60::placeholder {
  color: #78716c99;
}

.placeholder-stone-500\/70::placeholder {
  color: #78716cb3;
}

.placeholder-stone-500\/75::placeholder {
  color: #78716cbf;
}

.placeholder-stone-500\/80::placeholder {
  color: #78716ccc;
}

.placeholder-stone-500\/90::placeholder {
  color: #78716ce6;
}

.placeholder-stone-500\/95::placeholder {
  color: #78716cf2;
}

.placeholder-stone-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(87 83 78 / var(--tw-placeholder-opacity));
}

.placeholder-stone-600\/0::placeholder {
  color: #57534e00;
}

.placeholder-stone-600\/10::placeholder {
  color: #57534e1a;
}

.placeholder-stone-600\/100::placeholder {
  color: #57534e;
}

.placeholder-stone-600\/20::placeholder {
  color: #57534e33;
}

.placeholder-stone-600\/25::placeholder {
  color: #57534e40;
}

.placeholder-stone-600\/30::placeholder {
  color: #57534e4d;
}

.placeholder-stone-600\/40::placeholder {
  color: #57534e66;
}

.placeholder-stone-600\/5::placeholder {
  color: #57534e0d;
}

.placeholder-stone-600\/50::placeholder {
  color: #57534e80;
}

.placeholder-stone-600\/60::placeholder {
  color: #57534e99;
}

.placeholder-stone-600\/70::placeholder {
  color: #57534eb3;
}

.placeholder-stone-600\/75::placeholder {
  color: #57534ebf;
}

.placeholder-stone-600\/80::placeholder {
  color: #57534ecc;
}

.placeholder-stone-600\/90::placeholder {
  color: #57534ee6;
}

.placeholder-stone-600\/95::placeholder {
  color: #57534ef2;
}

.placeholder-stone-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(68 64 60 / var(--tw-placeholder-opacity));
}

.placeholder-stone-700\/0::placeholder {
  color: #44403c00;
}

.placeholder-stone-700\/10::placeholder {
  color: #44403c1a;
}

.placeholder-stone-700\/100::placeholder {
  color: #44403c;
}

.placeholder-stone-700\/20::placeholder {
  color: #44403c33;
}

.placeholder-stone-700\/25::placeholder {
  color: #44403c40;
}

.placeholder-stone-700\/30::placeholder {
  color: #44403c4d;
}

.placeholder-stone-700\/40::placeholder {
  color: #44403c66;
}

.placeholder-stone-700\/5::placeholder {
  color: #44403c0d;
}

.placeholder-stone-700\/50::placeholder {
  color: #44403c80;
}

.placeholder-stone-700\/60::placeholder {
  color: #44403c99;
}

.placeholder-stone-700\/70::placeholder {
  color: #44403cb3;
}

.placeholder-stone-700\/75::placeholder {
  color: #44403cbf;
}

.placeholder-stone-700\/80::placeholder {
  color: #44403ccc;
}

.placeholder-stone-700\/90::placeholder {
  color: #44403ce6;
}

.placeholder-stone-700\/95::placeholder {
  color: #44403cf2;
}

.placeholder-stone-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(41 37 36 / var(--tw-placeholder-opacity));
}

.placeholder-stone-800\/0::placeholder {
  color: #29252400;
}

.placeholder-stone-800\/10::placeholder {
  color: #2925241a;
}

.placeholder-stone-800\/100::placeholder {
  color: #292524;
}

.placeholder-stone-800\/20::placeholder {
  color: #29252433;
}

.placeholder-stone-800\/25::placeholder {
  color: #29252440;
}

.placeholder-stone-800\/30::placeholder {
  color: #2925244d;
}

.placeholder-stone-800\/40::placeholder {
  color: #29252466;
}

.placeholder-stone-800\/5::placeholder {
  color: #2925240d;
}

.placeholder-stone-800\/50::placeholder {
  color: #29252480;
}

.placeholder-stone-800\/60::placeholder {
  color: #29252499;
}

.placeholder-stone-800\/70::placeholder {
  color: #292524b3;
}

.placeholder-stone-800\/75::placeholder {
  color: #292524bf;
}

.placeholder-stone-800\/80::placeholder {
  color: #292524cc;
}

.placeholder-stone-800\/90::placeholder {
  color: #292524e6;
}

.placeholder-stone-800\/95::placeholder {
  color: #292524f2;
}

.placeholder-stone-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(28 25 23 / var(--tw-placeholder-opacity));
}

.placeholder-stone-900\/0::placeholder {
  color: #1c191700;
}

.placeholder-stone-900\/10::placeholder {
  color: #1c19171a;
}

.placeholder-stone-900\/100::placeholder {
  color: #1c1917;
}

.placeholder-stone-900\/20::placeholder {
  color: #1c191733;
}

.placeholder-stone-900\/25::placeholder {
  color: #1c191740;
}

.placeholder-stone-900\/30::placeholder {
  color: #1c19174d;
}

.placeholder-stone-900\/40::placeholder {
  color: #1c191766;
}

.placeholder-stone-900\/5::placeholder {
  color: #1c19170d;
}

.placeholder-stone-900\/50::placeholder {
  color: #1c191780;
}

.placeholder-stone-900\/60::placeholder {
  color: #1c191799;
}

.placeholder-stone-900\/70::placeholder {
  color: #1c1917b3;
}

.placeholder-stone-900\/75::placeholder {
  color: #1c1917bf;
}

.placeholder-stone-900\/80::placeholder {
  color: #1c1917cc;
}

.placeholder-stone-900\/90::placeholder {
  color: #1c1917e6;
}

.placeholder-stone-900\/95::placeholder {
  color: #1c1917f2;
}

.placeholder-stone-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(12 10 9 / var(--tw-placeholder-opacity));
}

.placeholder-stone-950\/0::placeholder {
  color: #0c0a0900;
}

.placeholder-stone-950\/10::placeholder {
  color: #0c0a091a;
}

.placeholder-stone-950\/100::placeholder {
  color: #0c0a09;
}

.placeholder-stone-950\/20::placeholder {
  color: #0c0a0933;
}

.placeholder-stone-950\/25::placeholder {
  color: #0c0a0940;
}

.placeholder-stone-950\/30::placeholder {
  color: #0c0a094d;
}

.placeholder-stone-950\/40::placeholder {
  color: #0c0a0966;
}

.placeholder-stone-950\/5::placeholder {
  color: #0c0a090d;
}

.placeholder-stone-950\/50::placeholder {
  color: #0c0a0980;
}

.placeholder-stone-950\/60::placeholder {
  color: #0c0a0999;
}

.placeholder-stone-950\/70::placeholder {
  color: #0c0a09b3;
}

.placeholder-stone-950\/75::placeholder {
  color: #0c0a09bf;
}

.placeholder-stone-950\/80::placeholder {
  color: #0c0a09cc;
}

.placeholder-stone-950\/90::placeholder {
  color: #0c0a09e6;
}

.placeholder-stone-950\/95::placeholder {
  color: #0c0a09f2;
}

.placeholder-teal-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(204 251 241 / var(--tw-placeholder-opacity));
}

.placeholder-teal-100\/0::placeholder {
  color: #ccfbf100;
}

.placeholder-teal-100\/10::placeholder {
  color: #ccfbf11a;
}

.placeholder-teal-100\/100::placeholder {
  color: #ccfbf1;
}

.placeholder-teal-100\/20::placeholder {
  color: #ccfbf133;
}

.placeholder-teal-100\/25::placeholder {
  color: #ccfbf140;
}

.placeholder-teal-100\/30::placeholder {
  color: #ccfbf14d;
}

.placeholder-teal-100\/40::placeholder {
  color: #ccfbf166;
}

.placeholder-teal-100\/5::placeholder {
  color: #ccfbf10d;
}

.placeholder-teal-100\/50::placeholder {
  color: #ccfbf180;
}

.placeholder-teal-100\/60::placeholder {
  color: #ccfbf199;
}

.placeholder-teal-100\/70::placeholder {
  color: #ccfbf1b3;
}

.placeholder-teal-100\/75::placeholder {
  color: #ccfbf1bf;
}

.placeholder-teal-100\/80::placeholder {
  color: #ccfbf1cc;
}

.placeholder-teal-100\/90::placeholder {
  color: #ccfbf1e6;
}

.placeholder-teal-100\/95::placeholder {
  color: #ccfbf1f2;
}

.placeholder-teal-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(153 246 228 / var(--tw-placeholder-opacity));
}

.placeholder-teal-200\/0::placeholder {
  color: #99f6e400;
}

.placeholder-teal-200\/10::placeholder {
  color: #99f6e41a;
}

.placeholder-teal-200\/100::placeholder {
  color: #99f6e4;
}

.placeholder-teal-200\/20::placeholder {
  color: #99f6e433;
}

.placeholder-teal-200\/25::placeholder {
  color: #99f6e440;
}

.placeholder-teal-200\/30::placeholder {
  color: #99f6e44d;
}

.placeholder-teal-200\/40::placeholder {
  color: #99f6e466;
}

.placeholder-teal-200\/5::placeholder {
  color: #99f6e40d;
}

.placeholder-teal-200\/50::placeholder {
  color: #99f6e480;
}

.placeholder-teal-200\/60::placeholder {
  color: #99f6e499;
}

.placeholder-teal-200\/70::placeholder {
  color: #99f6e4b3;
}

.placeholder-teal-200\/75::placeholder {
  color: #99f6e4bf;
}

.placeholder-teal-200\/80::placeholder {
  color: #99f6e4cc;
}

.placeholder-teal-200\/90::placeholder {
  color: #99f6e4e6;
}

.placeholder-teal-200\/95::placeholder {
  color: #99f6e4f2;
}

.placeholder-teal-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(94 234 212 / var(--tw-placeholder-opacity));
}

.placeholder-teal-300\/0::placeholder {
  color: #5eead400;
}

.placeholder-teal-300\/10::placeholder {
  color: #5eead41a;
}

.placeholder-teal-300\/100::placeholder {
  color: #5eead4;
}

.placeholder-teal-300\/20::placeholder {
  color: #5eead433;
}

.placeholder-teal-300\/25::placeholder {
  color: #5eead440;
}

.placeholder-teal-300\/30::placeholder {
  color: #5eead44d;
}

.placeholder-teal-300\/40::placeholder {
  color: #5eead466;
}

.placeholder-teal-300\/5::placeholder {
  color: #5eead40d;
}

.placeholder-teal-300\/50::placeholder {
  color: #5eead480;
}

.placeholder-teal-300\/60::placeholder {
  color: #5eead499;
}

.placeholder-teal-300\/70::placeholder {
  color: #5eead4b3;
}

.placeholder-teal-300\/75::placeholder {
  color: #5eead4bf;
}

.placeholder-teal-300\/80::placeholder {
  color: #5eead4cc;
}

.placeholder-teal-300\/90::placeholder {
  color: #5eead4e6;
}

.placeholder-teal-300\/95::placeholder {
  color: #5eead4f2;
}

.placeholder-teal-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(45 212 191 / var(--tw-placeholder-opacity));
}

.placeholder-teal-400\/0::placeholder {
  color: #2dd4bf00;
}

.placeholder-teal-400\/10::placeholder {
  color: #2dd4bf1a;
}

.placeholder-teal-400\/100::placeholder {
  color: #2dd4bf;
}

.placeholder-teal-400\/20::placeholder {
  color: #2dd4bf33;
}

.placeholder-teal-400\/25::placeholder {
  color: #2dd4bf40;
}

.placeholder-teal-400\/30::placeholder {
  color: #2dd4bf4d;
}

.placeholder-teal-400\/40::placeholder {
  color: #2dd4bf66;
}

.placeholder-teal-400\/5::placeholder {
  color: #2dd4bf0d;
}

.placeholder-teal-400\/50::placeholder {
  color: #2dd4bf80;
}

.placeholder-teal-400\/60::placeholder {
  color: #2dd4bf99;
}

.placeholder-teal-400\/70::placeholder {
  color: #2dd4bfb3;
}

.placeholder-teal-400\/75::placeholder {
  color: #2dd4bfbf;
}

.placeholder-teal-400\/80::placeholder {
  color: #2dd4bfcc;
}

.placeholder-teal-400\/90::placeholder {
  color: #2dd4bfe6;
}

.placeholder-teal-400\/95::placeholder {
  color: #2dd4bff2;
}

.placeholder-teal-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(240 253 250 / var(--tw-placeholder-opacity));
}

.placeholder-teal-50\/0::placeholder {
  color: #f0fdfa00;
}

.placeholder-teal-50\/10::placeholder {
  color: #f0fdfa1a;
}

.placeholder-teal-50\/100::placeholder {
  color: #f0fdfa;
}

.placeholder-teal-50\/20::placeholder {
  color: #f0fdfa33;
}

.placeholder-teal-50\/25::placeholder {
  color: #f0fdfa40;
}

.placeholder-teal-50\/30::placeholder {
  color: #f0fdfa4d;
}

.placeholder-teal-50\/40::placeholder {
  color: #f0fdfa66;
}

.placeholder-teal-50\/5::placeholder {
  color: #f0fdfa0d;
}

.placeholder-teal-50\/50::placeholder {
  color: #f0fdfa80;
}

.placeholder-teal-50\/60::placeholder {
  color: #f0fdfa99;
}

.placeholder-teal-50\/70::placeholder {
  color: #f0fdfab3;
}

.placeholder-teal-50\/75::placeholder {
  color: #f0fdfabf;
}

.placeholder-teal-50\/80::placeholder {
  color: #f0fdfacc;
}

.placeholder-teal-50\/90::placeholder {
  color: #f0fdfae6;
}

.placeholder-teal-50\/95::placeholder {
  color: #f0fdfaf2;
}

.placeholder-teal-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(20 184 166 / var(--tw-placeholder-opacity));
}

.placeholder-teal-500\/0::placeholder {
  color: #14b8a600;
}

.placeholder-teal-500\/10::placeholder {
  color: #14b8a61a;
}

.placeholder-teal-500\/100::placeholder {
  color: #14b8a6;
}

.placeholder-teal-500\/20::placeholder {
  color: #14b8a633;
}

.placeholder-teal-500\/25::placeholder {
  color: #14b8a640;
}

.placeholder-teal-500\/30::placeholder {
  color: #14b8a64d;
}

.placeholder-teal-500\/40::placeholder {
  color: #14b8a666;
}

.placeholder-teal-500\/5::placeholder {
  color: #14b8a60d;
}

.placeholder-teal-500\/50::placeholder {
  color: #14b8a680;
}

.placeholder-teal-500\/60::placeholder {
  color: #14b8a699;
}

.placeholder-teal-500\/70::placeholder {
  color: #14b8a6b3;
}

.placeholder-teal-500\/75::placeholder {
  color: #14b8a6bf;
}

.placeholder-teal-500\/80::placeholder {
  color: #14b8a6cc;
}

.placeholder-teal-500\/90::placeholder {
  color: #14b8a6e6;
}

.placeholder-teal-500\/95::placeholder {
  color: #14b8a6f2;
}

.placeholder-teal-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(13 148 136 / var(--tw-placeholder-opacity));
}

.placeholder-teal-600\/0::placeholder {
  color: #0d948800;
}

.placeholder-teal-600\/10::placeholder {
  color: #0d94881a;
}

.placeholder-teal-600\/100::placeholder {
  color: #0d9488;
}

.placeholder-teal-600\/20::placeholder {
  color: #0d948833;
}

.placeholder-teal-600\/25::placeholder {
  color: #0d948840;
}

.placeholder-teal-600\/30::placeholder {
  color: #0d94884d;
}

.placeholder-teal-600\/40::placeholder {
  color: #0d948866;
}

.placeholder-teal-600\/5::placeholder {
  color: #0d94880d;
}

.placeholder-teal-600\/50::placeholder {
  color: #0d948880;
}

.placeholder-teal-600\/60::placeholder {
  color: #0d948899;
}

.placeholder-teal-600\/70::placeholder {
  color: #0d9488b3;
}

.placeholder-teal-600\/75::placeholder {
  color: #0d9488bf;
}

.placeholder-teal-600\/80::placeholder {
  color: #0d9488cc;
}

.placeholder-teal-600\/90::placeholder {
  color: #0d9488e6;
}

.placeholder-teal-600\/95::placeholder {
  color: #0d9488f2;
}

.placeholder-teal-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(15 118 110 / var(--tw-placeholder-opacity));
}

.placeholder-teal-700\/0::placeholder {
  color: #0f766e00;
}

.placeholder-teal-700\/10::placeholder {
  color: #0f766e1a;
}

.placeholder-teal-700\/100::placeholder {
  color: #0f766e;
}

.placeholder-teal-700\/20::placeholder {
  color: #0f766e33;
}

.placeholder-teal-700\/25::placeholder {
  color: #0f766e40;
}

.placeholder-teal-700\/30::placeholder {
  color: #0f766e4d;
}

.placeholder-teal-700\/40::placeholder {
  color: #0f766e66;
}

.placeholder-teal-700\/5::placeholder {
  color: #0f766e0d;
}

.placeholder-teal-700\/50::placeholder {
  color: #0f766e80;
}

.placeholder-teal-700\/60::placeholder {
  color: #0f766e99;
}

.placeholder-teal-700\/70::placeholder {
  color: #0f766eb3;
}

.placeholder-teal-700\/75::placeholder {
  color: #0f766ebf;
}

.placeholder-teal-700\/80::placeholder {
  color: #0f766ecc;
}

.placeholder-teal-700\/90::placeholder {
  color: #0f766ee6;
}

.placeholder-teal-700\/95::placeholder {
  color: #0f766ef2;
}

.placeholder-teal-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(17 94 89 / var(--tw-placeholder-opacity));
}

.placeholder-teal-800\/0::placeholder {
  color: #115e5900;
}

.placeholder-teal-800\/10::placeholder {
  color: #115e591a;
}

.placeholder-teal-800\/100::placeholder {
  color: #115e59;
}

.placeholder-teal-800\/20::placeholder {
  color: #115e5933;
}

.placeholder-teal-800\/25::placeholder {
  color: #115e5940;
}

.placeholder-teal-800\/30::placeholder {
  color: #115e594d;
}

.placeholder-teal-800\/40::placeholder {
  color: #115e5966;
}

.placeholder-teal-800\/5::placeholder {
  color: #115e590d;
}

.placeholder-teal-800\/50::placeholder {
  color: #115e5980;
}

.placeholder-teal-800\/60::placeholder {
  color: #115e5999;
}

.placeholder-teal-800\/70::placeholder {
  color: #115e59b3;
}

.placeholder-teal-800\/75::placeholder {
  color: #115e59bf;
}

.placeholder-teal-800\/80::placeholder {
  color: #115e59cc;
}

.placeholder-teal-800\/90::placeholder {
  color: #115e59e6;
}

.placeholder-teal-800\/95::placeholder {
  color: #115e59f2;
}

.placeholder-teal-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(19 78 74 / var(--tw-placeholder-opacity));
}

.placeholder-teal-900\/0::placeholder {
  color: #134e4a00;
}

.placeholder-teal-900\/10::placeholder {
  color: #134e4a1a;
}

.placeholder-teal-900\/100::placeholder {
  color: #134e4a;
}

.placeholder-teal-900\/20::placeholder {
  color: #134e4a33;
}

.placeholder-teal-900\/25::placeholder {
  color: #134e4a40;
}

.placeholder-teal-900\/30::placeholder {
  color: #134e4a4d;
}

.placeholder-teal-900\/40::placeholder {
  color: #134e4a66;
}

.placeholder-teal-900\/5::placeholder {
  color: #134e4a0d;
}

.placeholder-teal-900\/50::placeholder {
  color: #134e4a80;
}

.placeholder-teal-900\/60::placeholder {
  color: #134e4a99;
}

.placeholder-teal-900\/70::placeholder {
  color: #134e4ab3;
}

.placeholder-teal-900\/75::placeholder {
  color: #134e4abf;
}

.placeholder-teal-900\/80::placeholder {
  color: #134e4acc;
}

.placeholder-teal-900\/90::placeholder {
  color: #134e4ae6;
}

.placeholder-teal-900\/95::placeholder {
  color: #134e4af2;
}

.placeholder-teal-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(4 47 46 / var(--tw-placeholder-opacity));
}

.placeholder-teal-950\/0::placeholder {
  color: #042f2e00;
}

.placeholder-teal-950\/10::placeholder {
  color: #042f2e1a;
}

.placeholder-teal-950\/100::placeholder {
  color: #042f2e;
}

.placeholder-teal-950\/20::placeholder {
  color: #042f2e33;
}

.placeholder-teal-950\/25::placeholder {
  color: #042f2e40;
}

.placeholder-teal-950\/30::placeholder {
  color: #042f2e4d;
}

.placeholder-teal-950\/40::placeholder {
  color: #042f2e66;
}

.placeholder-teal-950\/5::placeholder {
  color: #042f2e0d;
}

.placeholder-teal-950\/50::placeholder {
  color: #042f2e80;
}

.placeholder-teal-950\/60::placeholder {
  color: #042f2e99;
}

.placeholder-teal-950\/70::placeholder {
  color: #042f2eb3;
}

.placeholder-teal-950\/75::placeholder {
  color: #042f2ebf;
}

.placeholder-teal-950\/80::placeholder {
  color: #042f2ecc;
}

.placeholder-teal-950\/90::placeholder {
  color: #042f2ee6;
}

.placeholder-teal-950\/95::placeholder {
  color: #042f2ef2;
}

.placeholder-transparent::placeholder {
  color: #0000;
}

.placeholder-transparent\/10::placeholder {
  color: #0000001a;
}

.placeholder-transparent\/100::placeholder {
  color: #000;
}

.placeholder-transparent\/20::placeholder {
  color: #0003;
}

.placeholder-transparent\/25::placeholder {
  color: #00000040;
}

.placeholder-transparent\/30::placeholder {
  color: #0000004d;
}

.placeholder-transparent\/40::placeholder {
  color: #0006;
}

.placeholder-transparent\/5::placeholder {
  color: #0000000d;
}

.placeholder-transparent\/50::placeholder {
  color: #00000080;
}

.placeholder-transparent\/60::placeholder {
  color: #0009;
}

.placeholder-transparent\/70::placeholder {
  color: #000000b3;
}

.placeholder-transparent\/75::placeholder {
  color: #000000bf;
}

.placeholder-transparent\/80::placeholder {
  color: #000c;
}

.placeholder-transparent\/90::placeholder {
  color: #000000e6;
}

.placeholder-transparent\/95::placeholder {
  color: #000000f2;
}

.placeholder-violet::placeholder {
  --tw-placeholder-opacity: 1;
  color: hsl(var(--violet) / var(--tw-placeholder-opacity));
}

.placeholder-violet\/0::placeholder {
  color: hsl(var(--violet) / 0);
}

.placeholder-violet\/10::placeholder {
  color: hsl(var(--violet) / .1);
}

.placeholder-violet\/100::placeholder {
  color: hsl(var(--violet) / 1);
}

.placeholder-violet\/20::placeholder {
  color: hsl(var(--violet) / .2);
}

.placeholder-violet\/25::placeholder {
  color: hsl(var(--violet) / .25);
}

.placeholder-violet\/30::placeholder {
  color: hsl(var(--violet) / .3);
}

.placeholder-violet\/40::placeholder {
  color: hsl(var(--violet) / .4);
}

.placeholder-violet\/5::placeholder {
  color: hsl(var(--violet) / .05);
}

.placeholder-violet\/50::placeholder {
  color: hsl(var(--violet) / .5);
}

.placeholder-violet\/60::placeholder {
  color: hsl(var(--violet) / .6);
}

.placeholder-violet\/70::placeholder {
  color: hsl(var(--violet) / .7);
}

.placeholder-violet\/75::placeholder {
  color: hsl(var(--violet) / .75);
}

.placeholder-violet\/80::placeholder {
  color: hsl(var(--violet) / .8);
}

.placeholder-violet\/90::placeholder {
  color: hsl(var(--violet) / .9);
}

.placeholder-violet\/95::placeholder {
  color: hsl(var(--violet) / .95);
}

.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 255 255 / var(--tw-placeholder-opacity));
}

.placeholder-white\/0::placeholder {
  color: #fff0;
}

.placeholder-white\/10::placeholder {
  color: #ffffff1a;
}

.placeholder-white\/100::placeholder {
  color: #fff;
}

.placeholder-white\/20::placeholder {
  color: #fff3;
}

.placeholder-white\/25::placeholder {
  color: #ffffff40;
}

.placeholder-white\/30::placeholder {
  color: #ffffff4d;
}

.placeholder-white\/40::placeholder {
  color: #fff6;
}

.placeholder-white\/5::placeholder {
  color: #ffffff0d;
}

.placeholder-white\/50::placeholder {
  color: #ffffff80;
}

.placeholder-white\/60::placeholder {
  color: #fff9;
}

.placeholder-white\/70::placeholder {
  color: #ffffffb3;
}

.placeholder-white\/75::placeholder {
  color: #ffffffbf;
}

.placeholder-white\/80::placeholder {
  color: #fffc;
}

.placeholder-white\/90::placeholder {
  color: #ffffffe6;
}

.placeholder-white\/95::placeholder {
  color: #fffffff2;
}

.placeholder-yellow::placeholder {
  --tw-placeholder-opacity: 1;
  color: hsl(var(--yellow) / var(--tw-placeholder-opacity));
}

.placeholder-yellow\/0::placeholder {
  color: hsl(var(--yellow) / 0);
}

.placeholder-yellow\/10::placeholder {
  color: hsl(var(--yellow) / .1);
}

.placeholder-yellow\/100::placeholder {
  color: hsl(var(--yellow) / 1);
}

.placeholder-yellow\/20::placeholder {
  color: hsl(var(--yellow) / .2);
}

.placeholder-yellow\/25::placeholder {
  color: hsl(var(--yellow) / .25);
}

.placeholder-yellow\/30::placeholder {
  color: hsl(var(--yellow) / .3);
}

.placeholder-yellow\/40::placeholder {
  color: hsl(var(--yellow) / .4);
}

.placeholder-yellow\/5::placeholder {
  color: hsl(var(--yellow) / .05);
}

.placeholder-yellow\/50::placeholder {
  color: hsl(var(--yellow) / .5);
}

.placeholder-yellow\/60::placeholder {
  color: hsl(var(--yellow) / .6);
}

.placeholder-yellow\/70::placeholder {
  color: hsl(var(--yellow) / .7);
}

.placeholder-yellow\/75::placeholder {
  color: hsl(var(--yellow) / .75);
}

.placeholder-yellow\/80::placeholder {
  color: hsl(var(--yellow) / .8);
}

.placeholder-yellow\/90::placeholder {
  color: hsl(var(--yellow) / .9);
}

.placeholder-yellow\/95::placeholder {
  color: hsl(var(--yellow) / .95);
}

.placeholder-zinc-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(244 244 245 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-100\/0::placeholder {
  color: #f4f4f500;
}

.placeholder-zinc-100\/10::placeholder {
  color: #f4f4f51a;
}

.placeholder-zinc-100\/100::placeholder {
  color: #f4f4f5;
}

.placeholder-zinc-100\/20::placeholder {
  color: #f4f4f533;
}

.placeholder-zinc-100\/25::placeholder {
  color: #f4f4f540;
}

.placeholder-zinc-100\/30::placeholder {
  color: #f4f4f54d;
}

.placeholder-zinc-100\/40::placeholder {
  color: #f4f4f566;
}

.placeholder-zinc-100\/5::placeholder {
  color: #f4f4f50d;
}

.placeholder-zinc-100\/50::placeholder {
  color: #f4f4f580;
}

.placeholder-zinc-100\/60::placeholder {
  color: #f4f4f599;
}

.placeholder-zinc-100\/70::placeholder {
  color: #f4f4f5b3;
}

.placeholder-zinc-100\/75::placeholder {
  color: #f4f4f5bf;
}

.placeholder-zinc-100\/80::placeholder {
  color: #f4f4f5cc;
}

.placeholder-zinc-100\/90::placeholder {
  color: #f4f4f5e6;
}

.placeholder-zinc-100\/95::placeholder {
  color: #f4f4f5f2;
}

.placeholder-zinc-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(228 228 231 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-200\/0::placeholder {
  color: #e4e4e700;
}

.placeholder-zinc-200\/10::placeholder {
  color: #e4e4e71a;
}

.placeholder-zinc-200\/100::placeholder {
  color: #e4e4e7;
}

.placeholder-zinc-200\/20::placeholder {
  color: #e4e4e733;
}

.placeholder-zinc-200\/25::placeholder {
  color: #e4e4e740;
}

.placeholder-zinc-200\/30::placeholder {
  color: #e4e4e74d;
}

.placeholder-zinc-200\/40::placeholder {
  color: #e4e4e766;
}

.placeholder-zinc-200\/5::placeholder {
  color: #e4e4e70d;
}

.placeholder-zinc-200\/50::placeholder {
  color: #e4e4e780;
}

.placeholder-zinc-200\/60::placeholder {
  color: #e4e4e799;
}

.placeholder-zinc-200\/70::placeholder {
  color: #e4e4e7b3;
}

.placeholder-zinc-200\/75::placeholder {
  color: #e4e4e7bf;
}

.placeholder-zinc-200\/80::placeholder {
  color: #e4e4e7cc;
}

.placeholder-zinc-200\/90::placeholder {
  color: #e4e4e7e6;
}

.placeholder-zinc-200\/95::placeholder {
  color: #e4e4e7f2;
}

.placeholder-zinc-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(212 212 216 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-300\/0::placeholder {
  color: #d4d4d800;
}

.placeholder-zinc-300\/10::placeholder {
  color: #d4d4d81a;
}

.placeholder-zinc-300\/100::placeholder {
  color: #d4d4d8;
}

.placeholder-zinc-300\/20::placeholder {
  color: #d4d4d833;
}

.placeholder-zinc-300\/25::placeholder {
  color: #d4d4d840;
}

.placeholder-zinc-300\/30::placeholder {
  color: #d4d4d84d;
}

.placeholder-zinc-300\/40::placeholder {
  color: #d4d4d866;
}

.placeholder-zinc-300\/5::placeholder {
  color: #d4d4d80d;
}

.placeholder-zinc-300\/50::placeholder {
  color: #d4d4d880;
}

.placeholder-zinc-300\/60::placeholder {
  color: #d4d4d899;
}

.placeholder-zinc-300\/70::placeholder {
  color: #d4d4d8b3;
}

.placeholder-zinc-300\/75::placeholder {
  color: #d4d4d8bf;
}

.placeholder-zinc-300\/80::placeholder {
  color: #d4d4d8cc;
}

.placeholder-zinc-300\/90::placeholder {
  color: #d4d4d8e6;
}

.placeholder-zinc-300\/95::placeholder {
  color: #d4d4d8f2;
}

.placeholder-zinc-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(161 161 170 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-400\/0::placeholder {
  color: #a1a1aa00;
}

.placeholder-zinc-400\/10::placeholder {
  color: #a1a1aa1a;
}

.placeholder-zinc-400\/100::placeholder {
  color: #a1a1aa;
}

.placeholder-zinc-400\/20::placeholder {
  color: #a1a1aa33;
}

.placeholder-zinc-400\/25::placeholder {
  color: #a1a1aa40;
}

.placeholder-zinc-400\/30::placeholder {
  color: #a1a1aa4d;
}

.placeholder-zinc-400\/40::placeholder {
  color: #a1a1aa66;
}

.placeholder-zinc-400\/5::placeholder {
  color: #a1a1aa0d;
}

.placeholder-zinc-400\/50::placeholder {
  color: #a1a1aa80;
}

.placeholder-zinc-400\/60::placeholder {
  color: #a1a1aa99;
}

.placeholder-zinc-400\/70::placeholder {
  color: #a1a1aab3;
}

.placeholder-zinc-400\/75::placeholder {
  color: #a1a1aabf;
}

.placeholder-zinc-400\/80::placeholder {
  color: #a1a1aacc;
}

.placeholder-zinc-400\/90::placeholder {
  color: #a1a1aae6;
}

.placeholder-zinc-400\/95::placeholder {
  color: #a1a1aaf2;
}

.placeholder-zinc-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(250 250 250 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-50\/0::placeholder {
  color: #fafafa00;
}

.placeholder-zinc-50\/10::placeholder {
  color: #fafafa1a;
}

.placeholder-zinc-50\/100::placeholder {
  color: #fafafa;
}

.placeholder-zinc-50\/20::placeholder {
  color: #fafafa33;
}

.placeholder-zinc-50\/25::placeholder {
  color: #fafafa40;
}

.placeholder-zinc-50\/30::placeholder {
  color: #fafafa4d;
}

.placeholder-zinc-50\/40::placeholder {
  color: #fafafa66;
}

.placeholder-zinc-50\/5::placeholder {
  color: #fafafa0d;
}

.placeholder-zinc-50\/50::placeholder {
  color: #fafafa80;
}

.placeholder-zinc-50\/60::placeholder {
  color: #fafafa99;
}

.placeholder-zinc-50\/70::placeholder {
  color: #fafafab3;
}

.placeholder-zinc-50\/75::placeholder {
  color: #fafafabf;
}

.placeholder-zinc-50\/80::placeholder {
  color: #fafafacc;
}

.placeholder-zinc-50\/90::placeholder {
  color: #fafafae6;
}

.placeholder-zinc-50\/95::placeholder {
  color: #fafafaf2;
}

.placeholder-zinc-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(113 113 122 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-500\/0::placeholder {
  color: #71717a00;
}

.placeholder-zinc-500\/10::placeholder {
  color: #71717a1a;
}

.placeholder-zinc-500\/100::placeholder {
  color: #71717a;
}

.placeholder-zinc-500\/20::placeholder {
  color: #71717a33;
}

.placeholder-zinc-500\/25::placeholder {
  color: #71717a40;
}

.placeholder-zinc-500\/30::placeholder {
  color: #71717a4d;
}

.placeholder-zinc-500\/40::placeholder {
  color: #71717a66;
}

.placeholder-zinc-500\/5::placeholder {
  color: #71717a0d;
}

.placeholder-zinc-500\/50::placeholder {
  color: #71717a80;
}

.placeholder-zinc-500\/60::placeholder {
  color: #71717a99;
}

.placeholder-zinc-500\/70::placeholder {
  color: #71717ab3;
}

.placeholder-zinc-500\/75::placeholder {
  color: #71717abf;
}

.placeholder-zinc-500\/80::placeholder {
  color: #71717acc;
}

.placeholder-zinc-500\/90::placeholder {
  color: #71717ae6;
}

.placeholder-zinc-500\/95::placeholder {
  color: #71717af2;
}

.placeholder-zinc-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(82 82 91 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-600\/0::placeholder {
  color: #52525b00;
}

.placeholder-zinc-600\/10::placeholder {
  color: #52525b1a;
}

.placeholder-zinc-600\/100::placeholder {
  color: #52525b;
}

.placeholder-zinc-600\/20::placeholder {
  color: #52525b33;
}

.placeholder-zinc-600\/25::placeholder {
  color: #52525b40;
}

.placeholder-zinc-600\/30::placeholder {
  color: #52525b4d;
}

.placeholder-zinc-600\/40::placeholder {
  color: #52525b66;
}

.placeholder-zinc-600\/5::placeholder {
  color: #52525b0d;
}

.placeholder-zinc-600\/50::placeholder {
  color: #52525b80;
}

.placeholder-zinc-600\/60::placeholder {
  color: #52525b99;
}

.placeholder-zinc-600\/70::placeholder {
  color: #52525bb3;
}

.placeholder-zinc-600\/75::placeholder {
  color: #52525bbf;
}

.placeholder-zinc-600\/80::placeholder {
  color: #52525bcc;
}

.placeholder-zinc-600\/90::placeholder {
  color: #52525be6;
}

.placeholder-zinc-600\/95::placeholder {
  color: #52525bf2;
}

.placeholder-zinc-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(63 63 70 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-700\/0::placeholder {
  color: #3f3f4600;
}

.placeholder-zinc-700\/10::placeholder {
  color: #3f3f461a;
}

.placeholder-zinc-700\/100::placeholder {
  color: #3f3f46;
}

.placeholder-zinc-700\/20::placeholder {
  color: #3f3f4633;
}

.placeholder-zinc-700\/25::placeholder {
  color: #3f3f4640;
}

.placeholder-zinc-700\/30::placeholder {
  color: #3f3f464d;
}

.placeholder-zinc-700\/40::placeholder {
  color: #3f3f4666;
}

.placeholder-zinc-700\/5::placeholder {
  color: #3f3f460d;
}

.placeholder-zinc-700\/50::placeholder {
  color: #3f3f4680;
}

.placeholder-zinc-700\/60::placeholder {
  color: #3f3f4699;
}

.placeholder-zinc-700\/70::placeholder {
  color: #3f3f46b3;
}

.placeholder-zinc-700\/75::placeholder {
  color: #3f3f46bf;
}

.placeholder-zinc-700\/80::placeholder {
  color: #3f3f46cc;
}

.placeholder-zinc-700\/90::placeholder {
  color: #3f3f46e6;
}

.placeholder-zinc-700\/95::placeholder {
  color: #3f3f46f2;
}

.placeholder-zinc-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(39 39 42 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-800\/0::placeholder {
  color: #27272a00;
}

.placeholder-zinc-800\/10::placeholder {
  color: #27272a1a;
}

.placeholder-zinc-800\/100::placeholder {
  color: #27272a;
}

.placeholder-zinc-800\/20::placeholder {
  color: #27272a33;
}

.placeholder-zinc-800\/25::placeholder {
  color: #27272a40;
}

.placeholder-zinc-800\/30::placeholder {
  color: #27272a4d;
}

.placeholder-zinc-800\/40::placeholder {
  color: #27272a66;
}

.placeholder-zinc-800\/5::placeholder {
  color: #27272a0d;
}

.placeholder-zinc-800\/50::placeholder {
  color: #27272a80;
}

.placeholder-zinc-800\/60::placeholder {
  color: #27272a99;
}

.placeholder-zinc-800\/70::placeholder {
  color: #27272ab3;
}

.placeholder-zinc-800\/75::placeholder {
  color: #27272abf;
}

.placeholder-zinc-800\/80::placeholder {
  color: #27272acc;
}

.placeholder-zinc-800\/90::placeholder {
  color: #27272ae6;
}

.placeholder-zinc-800\/95::placeholder {
  color: #27272af2;
}

.placeholder-zinc-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(24 24 27 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-900\/0::placeholder {
  color: #18181b00;
}

.placeholder-zinc-900\/10::placeholder {
  color: #18181b1a;
}

.placeholder-zinc-900\/100::placeholder {
  color: #18181b;
}

.placeholder-zinc-900\/20::placeholder {
  color: #18181b33;
}

.placeholder-zinc-900\/25::placeholder {
  color: #18181b40;
}

.placeholder-zinc-900\/30::placeholder {
  color: #18181b4d;
}

.placeholder-zinc-900\/40::placeholder {
  color: #18181b66;
}

.placeholder-zinc-900\/5::placeholder {
  color: #18181b0d;
}

.placeholder-zinc-900\/50::placeholder {
  color: #18181b80;
}

.placeholder-zinc-900\/60::placeholder {
  color: #18181b99;
}

.placeholder-zinc-900\/70::placeholder {
  color: #18181bb3;
}

.placeholder-zinc-900\/75::placeholder {
  color: #18181bbf;
}

.placeholder-zinc-900\/80::placeholder {
  color: #18181bcc;
}

.placeholder-zinc-900\/90::placeholder {
  color: #18181be6;
}

.placeholder-zinc-900\/95::placeholder {
  color: #18181bf2;
}

.placeholder-zinc-950::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(9 9 11 / var(--tw-placeholder-opacity));
}

.placeholder-zinc-950\/0::placeholder {
  color: #09090b00;
}

.placeholder-zinc-950\/10::placeholder {
  color: #09090b1a;
}

.placeholder-zinc-950\/100::placeholder {
  color: #09090b;
}

.placeholder-zinc-950\/20::placeholder {
  color: #09090b33;
}

.placeholder-zinc-950\/25::placeholder {
  color: #09090b40;
}

.placeholder-zinc-950\/30::placeholder {
  color: #09090b4d;
}

.placeholder-zinc-950\/40::placeholder {
  color: #09090b66;
}

.placeholder-zinc-950\/5::placeholder {
  color: #09090b0d;
}

.placeholder-zinc-950\/50::placeholder {
  color: #09090b80;
}

.placeholder-zinc-950\/60::placeholder {
  color: #09090b99;
}

.placeholder-zinc-950\/70::placeholder {
  color: #09090bb3;
}

.placeholder-zinc-950\/75::placeholder {
  color: #09090bbf;
}

.placeholder-zinc-950\/80::placeholder {
  color: #09090bcc;
}

.placeholder-zinc-950\/90::placeholder {
  color: #09090be6;
}

.placeholder-zinc-950\/95::placeholder {
  color: #09090bf2;
}

.placeholder-opacity-0::placeholder {
  --tw-placeholder-opacity: 0;
}

.placeholder-opacity-10::placeholder {
  --tw-placeholder-opacity: .1;
}

.placeholder-opacity-100::placeholder {
  --tw-placeholder-opacity: 1;
}

.placeholder-opacity-20::placeholder {
  --tw-placeholder-opacity: .2;
}

.placeholder-opacity-25::placeholder {
  --tw-placeholder-opacity: .25;
}

.placeholder-opacity-30::placeholder {
  --tw-placeholder-opacity: .3;
}

.placeholder-opacity-40::placeholder {
  --tw-placeholder-opacity: .4;
}

.placeholder-opacity-5::placeholder {
  --tw-placeholder-opacity: .05;
}

.placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: .5;
}

.placeholder-opacity-60::placeholder {
  --tw-placeholder-opacity: .6;
}

.placeholder-opacity-70::placeholder {
  --tw-placeholder-opacity: .7;
}

.placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: .75;
}

.placeholder-opacity-80::placeholder {
  --tw-placeholder-opacity: .8;
}

.placeholder-opacity-90::placeholder {
  --tw-placeholder-opacity: .9;
}

.placeholder-opacity-95::placeholder {
  --tw-placeholder-opacity: .95;
}

.caret-amber-100 {
  caret-color: #fef3c7;
}

.caret-amber-100\/0 {
  caret-color: #fef3c700;
}

.caret-amber-100\/10 {
  caret-color: #fef3c71a;
}

.caret-amber-100\/100 {
  caret-color: #fef3c7;
}

.caret-amber-100\/20 {
  caret-color: #fef3c733;
}

.caret-amber-100\/25 {
  caret-color: #fef3c740;
}

.caret-amber-100\/30 {
  caret-color: #fef3c74d;
}

.caret-amber-100\/40 {
  caret-color: #fef3c766;
}

.caret-amber-100\/5 {
  caret-color: #fef3c70d;
}

.caret-amber-100\/50 {
  caret-color: #fef3c780;
}

.caret-amber-100\/60 {
  caret-color: #fef3c799;
}

.caret-amber-100\/70 {
  caret-color: #fef3c7b3;
}

.caret-amber-100\/75 {
  caret-color: #fef3c7bf;
}

.caret-amber-100\/80 {
  caret-color: #fef3c7cc;
}

.caret-amber-100\/90 {
  caret-color: #fef3c7e6;
}

.caret-amber-100\/95 {
  caret-color: #fef3c7f2;
}

.caret-amber-200 {
  caret-color: #fde68a;
}

.caret-amber-200\/0 {
  caret-color: #fde68a00;
}

.caret-amber-200\/10 {
  caret-color: #fde68a1a;
}

.caret-amber-200\/100 {
  caret-color: #fde68a;
}

.caret-amber-200\/20 {
  caret-color: #fde68a33;
}

.caret-amber-200\/25 {
  caret-color: #fde68a40;
}

.caret-amber-200\/30 {
  caret-color: #fde68a4d;
}

.caret-amber-200\/40 {
  caret-color: #fde68a66;
}

.caret-amber-200\/5 {
  caret-color: #fde68a0d;
}

.caret-amber-200\/50 {
  caret-color: #fde68a80;
}

.caret-amber-200\/60 {
  caret-color: #fde68a99;
}

.caret-amber-200\/70 {
  caret-color: #fde68ab3;
}

.caret-amber-200\/75 {
  caret-color: #fde68abf;
}

.caret-amber-200\/80 {
  caret-color: #fde68acc;
}

.caret-amber-200\/90 {
  caret-color: #fde68ae6;
}

.caret-amber-200\/95 {
  caret-color: #fde68af2;
}

.caret-amber-300 {
  caret-color: #fcd34d;
}

.caret-amber-300\/0 {
  caret-color: #fcd34d00;
}

.caret-amber-300\/10 {
  caret-color: #fcd34d1a;
}

.caret-amber-300\/100 {
  caret-color: #fcd34d;
}

.caret-amber-300\/20 {
  caret-color: #fcd34d33;
}

.caret-amber-300\/25 {
  caret-color: #fcd34d40;
}

.caret-amber-300\/30 {
  caret-color: #fcd34d4d;
}

.caret-amber-300\/40 {
  caret-color: #fcd34d66;
}

.caret-amber-300\/5 {
  caret-color: #fcd34d0d;
}

.caret-amber-300\/50 {
  caret-color: #fcd34d80;
}

.caret-amber-300\/60 {
  caret-color: #fcd34d99;
}

.caret-amber-300\/70 {
  caret-color: #fcd34db3;
}

.caret-amber-300\/75 {
  caret-color: #fcd34dbf;
}

.caret-amber-300\/80 {
  caret-color: #fcd34dcc;
}

.caret-amber-300\/90 {
  caret-color: #fcd34de6;
}

.caret-amber-300\/95 {
  caret-color: #fcd34df2;
}

.caret-amber-400 {
  caret-color: #fbbf24;
}

.caret-amber-400\/0 {
  caret-color: #fbbf2400;
}

.caret-amber-400\/10 {
  caret-color: #fbbf241a;
}

.caret-amber-400\/100 {
  caret-color: #fbbf24;
}

.caret-amber-400\/20 {
  caret-color: #fbbf2433;
}

.caret-amber-400\/25 {
  caret-color: #fbbf2440;
}

.caret-amber-400\/30 {
  caret-color: #fbbf244d;
}

.caret-amber-400\/40 {
  caret-color: #fbbf2466;
}

.caret-amber-400\/5 {
  caret-color: #fbbf240d;
}

.caret-amber-400\/50 {
  caret-color: #fbbf2480;
}

.caret-amber-400\/60 {
  caret-color: #fbbf2499;
}

.caret-amber-400\/70 {
  caret-color: #fbbf24b3;
}

.caret-amber-400\/75 {
  caret-color: #fbbf24bf;
}

.caret-amber-400\/80 {
  caret-color: #fbbf24cc;
}

.caret-amber-400\/90 {
  caret-color: #fbbf24e6;
}

.caret-amber-400\/95 {
  caret-color: #fbbf24f2;
}

.caret-amber-50 {
  caret-color: #fffbeb;
}

.caret-amber-50\/0 {
  caret-color: #fffbeb00;
}

.caret-amber-50\/10 {
  caret-color: #fffbeb1a;
}

.caret-amber-50\/100 {
  caret-color: #fffbeb;
}

.caret-amber-50\/20 {
  caret-color: #fffbeb33;
}

.caret-amber-50\/25 {
  caret-color: #fffbeb40;
}

.caret-amber-50\/30 {
  caret-color: #fffbeb4d;
}

.caret-amber-50\/40 {
  caret-color: #fffbeb66;
}

.caret-amber-50\/5 {
  caret-color: #fffbeb0d;
}

.caret-amber-50\/50 {
  caret-color: #fffbeb80;
}

.caret-amber-50\/60 {
  caret-color: #fffbeb99;
}

.caret-amber-50\/70 {
  caret-color: #fffbebb3;
}

.caret-amber-50\/75 {
  caret-color: #fffbebbf;
}

.caret-amber-50\/80 {
  caret-color: #fffbebcc;
}

.caret-amber-50\/90 {
  caret-color: #fffbebe6;
}

.caret-amber-50\/95 {
  caret-color: #fffbebf2;
}

.caret-amber-500 {
  caret-color: #f59e0b;
}

.caret-amber-500\/0 {
  caret-color: #f59e0b00;
}

.caret-amber-500\/10 {
  caret-color: #f59e0b1a;
}

.caret-amber-500\/100 {
  caret-color: #f59e0b;
}

.caret-amber-500\/20 {
  caret-color: #f59e0b33;
}

.caret-amber-500\/25 {
  caret-color: #f59e0b40;
}

.caret-amber-500\/30 {
  caret-color: #f59e0b4d;
}

.caret-amber-500\/40 {
  caret-color: #f59e0b66;
}

.caret-amber-500\/5 {
  caret-color: #f59e0b0d;
}

.caret-amber-500\/50 {
  caret-color: #f59e0b80;
}

.caret-amber-500\/60 {
  caret-color: #f59e0b99;
}

.caret-amber-500\/70 {
  caret-color: #f59e0bb3;
}

.caret-amber-500\/75 {
  caret-color: #f59e0bbf;
}

.caret-amber-500\/80 {
  caret-color: #f59e0bcc;
}

.caret-amber-500\/90 {
  caret-color: #f59e0be6;
}

.caret-amber-500\/95 {
  caret-color: #f59e0bf2;
}

.caret-amber-600 {
  caret-color: #d97706;
}

.caret-amber-600\/0 {
  caret-color: #d9770600;
}

.caret-amber-600\/10 {
  caret-color: #d977061a;
}

.caret-amber-600\/100 {
  caret-color: #d97706;
}

.caret-amber-600\/20 {
  caret-color: #d9770633;
}

.caret-amber-600\/25 {
  caret-color: #d9770640;
}

.caret-amber-600\/30 {
  caret-color: #d977064d;
}

.caret-amber-600\/40 {
  caret-color: #d9770666;
}

.caret-amber-600\/5 {
  caret-color: #d977060d;
}

.caret-amber-600\/50 {
  caret-color: #d9770680;
}

.caret-amber-600\/60 {
  caret-color: #d9770699;
}

.caret-amber-600\/70 {
  caret-color: #d97706b3;
}

.caret-amber-600\/75 {
  caret-color: #d97706bf;
}

.caret-amber-600\/80 {
  caret-color: #d97706cc;
}

.caret-amber-600\/90 {
  caret-color: #d97706e6;
}

.caret-amber-600\/95 {
  caret-color: #d97706f2;
}

.caret-amber-700 {
  caret-color: #b45309;
}

.caret-amber-700\/0 {
  caret-color: #b4530900;
}

.caret-amber-700\/10 {
  caret-color: #b453091a;
}

.caret-amber-700\/100 {
  caret-color: #b45309;
}

.caret-amber-700\/20 {
  caret-color: #b4530933;
}

.caret-amber-700\/25 {
  caret-color: #b4530940;
}

.caret-amber-700\/30 {
  caret-color: #b453094d;
}

.caret-amber-700\/40 {
  caret-color: #b4530966;
}

.caret-amber-700\/5 {
  caret-color: #b453090d;
}

.caret-amber-700\/50 {
  caret-color: #b4530980;
}

.caret-amber-700\/60 {
  caret-color: #b4530999;
}

.caret-amber-700\/70 {
  caret-color: #b45309b3;
}

.caret-amber-700\/75 {
  caret-color: #b45309bf;
}

.caret-amber-700\/80 {
  caret-color: #b45309cc;
}

.caret-amber-700\/90 {
  caret-color: #b45309e6;
}

.caret-amber-700\/95 {
  caret-color: #b45309f2;
}

.caret-amber-800 {
  caret-color: #92400e;
}

.caret-amber-800\/0 {
  caret-color: #92400e00;
}

.caret-amber-800\/10 {
  caret-color: #92400e1a;
}

.caret-amber-800\/100 {
  caret-color: #92400e;
}

.caret-amber-800\/20 {
  caret-color: #92400e33;
}

.caret-amber-800\/25 {
  caret-color: #92400e40;
}

.caret-amber-800\/30 {
  caret-color: #92400e4d;
}

.caret-amber-800\/40 {
  caret-color: #92400e66;
}

.caret-amber-800\/5 {
  caret-color: #92400e0d;
}

.caret-amber-800\/50 {
  caret-color: #92400e80;
}

.caret-amber-800\/60 {
  caret-color: #92400e99;
}

.caret-amber-800\/70 {
  caret-color: #92400eb3;
}

.caret-amber-800\/75 {
  caret-color: #92400ebf;
}

.caret-amber-800\/80 {
  caret-color: #92400ecc;
}

.caret-amber-800\/90 {
  caret-color: #92400ee6;
}

.caret-amber-800\/95 {
  caret-color: #92400ef2;
}

.caret-amber-900 {
  caret-color: #78350f;
}

.caret-amber-900\/0 {
  caret-color: #78350f00;
}

.caret-amber-900\/10 {
  caret-color: #78350f1a;
}

.caret-amber-900\/100 {
  caret-color: #78350f;
}

.caret-amber-900\/20 {
  caret-color: #78350f33;
}

.caret-amber-900\/25 {
  caret-color: #78350f40;
}

.caret-amber-900\/30 {
  caret-color: #78350f4d;
}

.caret-amber-900\/40 {
  caret-color: #78350f66;
}

.caret-amber-900\/5 {
  caret-color: #78350f0d;
}

.caret-amber-900\/50 {
  caret-color: #78350f80;
}

.caret-amber-900\/60 {
  caret-color: #78350f99;
}

.caret-amber-900\/70 {
  caret-color: #78350fb3;
}

.caret-amber-900\/75 {
  caret-color: #78350fbf;
}

.caret-amber-900\/80 {
  caret-color: #78350fcc;
}

.caret-amber-900\/90 {
  caret-color: #78350fe6;
}

.caret-amber-900\/95 {
  caret-color: #78350ff2;
}

.caret-amber-950 {
  caret-color: #451a03;
}

.caret-amber-950\/0 {
  caret-color: #451a0300;
}

.caret-amber-950\/10 {
  caret-color: #451a031a;
}

.caret-amber-950\/100 {
  caret-color: #451a03;
}

.caret-amber-950\/20 {
  caret-color: #451a0333;
}

.caret-amber-950\/25 {
  caret-color: #451a0340;
}

.caret-amber-950\/30 {
  caret-color: #451a034d;
}

.caret-amber-950\/40 {
  caret-color: #451a0366;
}

.caret-amber-950\/5 {
  caret-color: #451a030d;
}

.caret-amber-950\/50 {
  caret-color: #451a0380;
}

.caret-amber-950\/60 {
  caret-color: #451a0399;
}

.caret-amber-950\/70 {
  caret-color: #451a03b3;
}

.caret-amber-950\/75 {
  caret-color: #451a03bf;
}

.caret-amber-950\/80 {
  caret-color: #451a03cc;
}

.caret-amber-950\/90 {
  caret-color: #451a03e6;
}

.caret-amber-950\/95 {
  caret-color: #451a03f2;
}

.caret-emerald-100 {
  caret-color: #d1fae5;
}

.caret-emerald-100\/0 {
  caret-color: #d1fae500;
}

.caret-emerald-100\/10 {
  caret-color: #d1fae51a;
}

.caret-emerald-100\/100 {
  caret-color: #d1fae5;
}

.caret-emerald-100\/20 {
  caret-color: #d1fae533;
}

.caret-emerald-100\/25 {
  caret-color: #d1fae540;
}

.caret-emerald-100\/30 {
  caret-color: #d1fae54d;
}

.caret-emerald-100\/40 {
  caret-color: #d1fae566;
}

.caret-emerald-100\/5 {
  caret-color: #d1fae50d;
}

.caret-emerald-100\/50 {
  caret-color: #d1fae580;
}

.caret-emerald-100\/60 {
  caret-color: #d1fae599;
}

.caret-emerald-100\/70 {
  caret-color: #d1fae5b3;
}

.caret-emerald-100\/75 {
  caret-color: #d1fae5bf;
}

.caret-emerald-100\/80 {
  caret-color: #d1fae5cc;
}

.caret-emerald-100\/90 {
  caret-color: #d1fae5e6;
}

.caret-emerald-100\/95 {
  caret-color: #d1fae5f2;
}

.caret-emerald-200 {
  caret-color: #a7f3d0;
}

.caret-emerald-200\/0 {
  caret-color: #a7f3d000;
}

.caret-emerald-200\/10 {
  caret-color: #a7f3d01a;
}

.caret-emerald-200\/100 {
  caret-color: #a7f3d0;
}

.caret-emerald-200\/20 {
  caret-color: #a7f3d033;
}

.caret-emerald-200\/25 {
  caret-color: #a7f3d040;
}

.caret-emerald-200\/30 {
  caret-color: #a7f3d04d;
}

.caret-emerald-200\/40 {
  caret-color: #a7f3d066;
}

.caret-emerald-200\/5 {
  caret-color: #a7f3d00d;
}

.caret-emerald-200\/50 {
  caret-color: #a7f3d080;
}

.caret-emerald-200\/60 {
  caret-color: #a7f3d099;
}

.caret-emerald-200\/70 {
  caret-color: #a7f3d0b3;
}

.caret-emerald-200\/75 {
  caret-color: #a7f3d0bf;
}

.caret-emerald-200\/80 {
  caret-color: #a7f3d0cc;
}

.caret-emerald-200\/90 {
  caret-color: #a7f3d0e6;
}

.caret-emerald-200\/95 {
  caret-color: #a7f3d0f2;
}

.caret-emerald-300 {
  caret-color: #6ee7b7;
}

.caret-emerald-300\/0 {
  caret-color: #6ee7b700;
}

.caret-emerald-300\/10 {
  caret-color: #6ee7b71a;
}

.caret-emerald-300\/100 {
  caret-color: #6ee7b7;
}

.caret-emerald-300\/20 {
  caret-color: #6ee7b733;
}

.caret-emerald-300\/25 {
  caret-color: #6ee7b740;
}

.caret-emerald-300\/30 {
  caret-color: #6ee7b74d;
}

.caret-emerald-300\/40 {
  caret-color: #6ee7b766;
}

.caret-emerald-300\/5 {
  caret-color: #6ee7b70d;
}

.caret-emerald-300\/50 {
  caret-color: #6ee7b780;
}

.caret-emerald-300\/60 {
  caret-color: #6ee7b799;
}

.caret-emerald-300\/70 {
  caret-color: #6ee7b7b3;
}

.caret-emerald-300\/75 {
  caret-color: #6ee7b7bf;
}

.caret-emerald-300\/80 {
  caret-color: #6ee7b7cc;
}

.caret-emerald-300\/90 {
  caret-color: #6ee7b7e6;
}

.caret-emerald-300\/95 {
  caret-color: #6ee7b7f2;
}

.caret-emerald-400 {
  caret-color: #34d399;
}

.caret-emerald-400\/0 {
  caret-color: #34d39900;
}

.caret-emerald-400\/10 {
  caret-color: #34d3991a;
}

.caret-emerald-400\/100 {
  caret-color: #34d399;
}

.caret-emerald-400\/20 {
  caret-color: #34d39933;
}

.caret-emerald-400\/25 {
  caret-color: #34d39940;
}

.caret-emerald-400\/30 {
  caret-color: #34d3994d;
}

.caret-emerald-400\/40 {
  caret-color: #34d39966;
}

.caret-emerald-400\/5 {
  caret-color: #34d3990d;
}

.caret-emerald-400\/50 {
  caret-color: #34d39980;
}

.caret-emerald-400\/60 {
  caret-color: #34d39999;
}

.caret-emerald-400\/70 {
  caret-color: #34d399b3;
}

.caret-emerald-400\/75 {
  caret-color: #34d399bf;
}

.caret-emerald-400\/80 {
  caret-color: #34d399cc;
}

.caret-emerald-400\/90 {
  caret-color: #34d399e6;
}

.caret-emerald-400\/95 {
  caret-color: #34d399f2;
}

.caret-emerald-50 {
  caret-color: #ecfdf5;
}

.caret-emerald-50\/0 {
  caret-color: #ecfdf500;
}

.caret-emerald-50\/10 {
  caret-color: #ecfdf51a;
}

.caret-emerald-50\/100 {
  caret-color: #ecfdf5;
}

.caret-emerald-50\/20 {
  caret-color: #ecfdf533;
}

.caret-emerald-50\/25 {
  caret-color: #ecfdf540;
}

.caret-emerald-50\/30 {
  caret-color: #ecfdf54d;
}

.caret-emerald-50\/40 {
  caret-color: #ecfdf566;
}

.caret-emerald-50\/5 {
  caret-color: #ecfdf50d;
}

.caret-emerald-50\/50 {
  caret-color: #ecfdf580;
}

.caret-emerald-50\/60 {
  caret-color: #ecfdf599;
}

.caret-emerald-50\/70 {
  caret-color: #ecfdf5b3;
}

.caret-emerald-50\/75 {
  caret-color: #ecfdf5bf;
}

.caret-emerald-50\/80 {
  caret-color: #ecfdf5cc;
}

.caret-emerald-50\/90 {
  caret-color: #ecfdf5e6;
}

.caret-emerald-50\/95 {
  caret-color: #ecfdf5f2;
}

.caret-emerald-500 {
  caret-color: #10b981;
}

.caret-emerald-500\/0 {
  caret-color: #10b98100;
}

.caret-emerald-500\/10 {
  caret-color: #10b9811a;
}

.caret-emerald-500\/100 {
  caret-color: #10b981;
}

.caret-emerald-500\/20 {
  caret-color: #10b98133;
}

.caret-emerald-500\/25 {
  caret-color: #10b98140;
}

.caret-emerald-500\/30 {
  caret-color: #10b9814d;
}

.caret-emerald-500\/40 {
  caret-color: #10b98166;
}

.caret-emerald-500\/5 {
  caret-color: #10b9810d;
}

.caret-emerald-500\/50 {
  caret-color: #10b98180;
}

.caret-emerald-500\/60 {
  caret-color: #10b98199;
}

.caret-emerald-500\/70 {
  caret-color: #10b981b3;
}

.caret-emerald-500\/75 {
  caret-color: #10b981bf;
}

.caret-emerald-500\/80 {
  caret-color: #10b981cc;
}

.caret-emerald-500\/90 {
  caret-color: #10b981e6;
}

.caret-emerald-500\/95 {
  caret-color: #10b981f2;
}

.caret-emerald-600 {
  caret-color: #059669;
}

.caret-emerald-600\/0 {
  caret-color: #05966900;
}

.caret-emerald-600\/10 {
  caret-color: #0596691a;
}

.caret-emerald-600\/100 {
  caret-color: #059669;
}

.caret-emerald-600\/20 {
  caret-color: #05966933;
}

.caret-emerald-600\/25 {
  caret-color: #05966940;
}

.caret-emerald-600\/30 {
  caret-color: #0596694d;
}

.caret-emerald-600\/40 {
  caret-color: #05966966;
}

.caret-emerald-600\/5 {
  caret-color: #0596690d;
}

.caret-emerald-600\/50 {
  caret-color: #05966980;
}

.caret-emerald-600\/60 {
  caret-color: #05966999;
}

.caret-emerald-600\/70 {
  caret-color: #059669b3;
}

.caret-emerald-600\/75 {
  caret-color: #059669bf;
}

.caret-emerald-600\/80 {
  caret-color: #059669cc;
}

.caret-emerald-600\/90 {
  caret-color: #059669e6;
}

.caret-emerald-600\/95 {
  caret-color: #059669f2;
}

.caret-emerald-700 {
  caret-color: #047857;
}

.caret-emerald-700\/0 {
  caret-color: #04785700;
}

.caret-emerald-700\/10 {
  caret-color: #0478571a;
}

.caret-emerald-700\/100 {
  caret-color: #047857;
}

.caret-emerald-700\/20 {
  caret-color: #04785733;
}

.caret-emerald-700\/25 {
  caret-color: #04785740;
}

.caret-emerald-700\/30 {
  caret-color: #0478574d;
}

.caret-emerald-700\/40 {
  caret-color: #04785766;
}

.caret-emerald-700\/5 {
  caret-color: #0478570d;
}

.caret-emerald-700\/50 {
  caret-color: #04785780;
}

.caret-emerald-700\/60 {
  caret-color: #04785799;
}

.caret-emerald-700\/70 {
  caret-color: #047857b3;
}

.caret-emerald-700\/75 {
  caret-color: #047857bf;
}

.caret-emerald-700\/80 {
  caret-color: #047857cc;
}

.caret-emerald-700\/90 {
  caret-color: #047857e6;
}

.caret-emerald-700\/95 {
  caret-color: #047857f2;
}

.caret-emerald-800 {
  caret-color: #065f46;
}

.caret-emerald-800\/0 {
  caret-color: #065f4600;
}

.caret-emerald-800\/10 {
  caret-color: #065f461a;
}

.caret-emerald-800\/100 {
  caret-color: #065f46;
}

.caret-emerald-800\/20 {
  caret-color: #065f4633;
}

.caret-emerald-800\/25 {
  caret-color: #065f4640;
}

.caret-emerald-800\/30 {
  caret-color: #065f464d;
}

.caret-emerald-800\/40 {
  caret-color: #065f4666;
}

.caret-emerald-800\/5 {
  caret-color: #065f460d;
}

.caret-emerald-800\/50 {
  caret-color: #065f4680;
}

.caret-emerald-800\/60 {
  caret-color: #065f4699;
}

.caret-emerald-800\/70 {
  caret-color: #065f46b3;
}

.caret-emerald-800\/75 {
  caret-color: #065f46bf;
}

.caret-emerald-800\/80 {
  caret-color: #065f46cc;
}

.caret-emerald-800\/90 {
  caret-color: #065f46e6;
}

.caret-emerald-800\/95 {
  caret-color: #065f46f2;
}

.caret-emerald-900 {
  caret-color: #064e3b;
}

.caret-emerald-900\/0 {
  caret-color: #064e3b00;
}

.caret-emerald-900\/10 {
  caret-color: #064e3b1a;
}

.caret-emerald-900\/100 {
  caret-color: #064e3b;
}

.caret-emerald-900\/20 {
  caret-color: #064e3b33;
}

.caret-emerald-900\/25 {
  caret-color: #064e3b40;
}

.caret-emerald-900\/30 {
  caret-color: #064e3b4d;
}

.caret-emerald-900\/40 {
  caret-color: #064e3b66;
}

.caret-emerald-900\/5 {
  caret-color: #064e3b0d;
}

.caret-emerald-900\/50 {
  caret-color: #064e3b80;
}

.caret-emerald-900\/60 {
  caret-color: #064e3b99;
}

.caret-emerald-900\/70 {
  caret-color: #064e3bb3;
}

.caret-emerald-900\/75 {
  caret-color: #064e3bbf;
}

.caret-emerald-900\/80 {
  caret-color: #064e3bcc;
}

.caret-emerald-900\/90 {
  caret-color: #064e3be6;
}

.caret-emerald-900\/95 {
  caret-color: #064e3bf2;
}

.caret-emerald-950 {
  caret-color: #022c22;
}

.caret-emerald-950\/0 {
  caret-color: #022c2200;
}

.caret-emerald-950\/10 {
  caret-color: #022c221a;
}

.caret-emerald-950\/100 {
  caret-color: #022c22;
}

.caret-emerald-950\/20 {
  caret-color: #022c2233;
}

.caret-emerald-950\/25 {
  caret-color: #022c2240;
}

.caret-emerald-950\/30 {
  caret-color: #022c224d;
}

.caret-emerald-950\/40 {
  caret-color: #022c2266;
}

.caret-emerald-950\/5 {
  caret-color: #022c220d;
}

.caret-emerald-950\/50 {
  caret-color: #022c2280;
}

.caret-emerald-950\/60 {
  caret-color: #022c2299;
}

.caret-emerald-950\/70 {
  caret-color: #022c22b3;
}

.caret-emerald-950\/75 {
  caret-color: #022c22bf;
}

.caret-emerald-950\/80 {
  caret-color: #022c22cc;
}

.caret-emerald-950\/90 {
  caret-color: #022c22e6;
}

.caret-emerald-950\/95 {
  caret-color: #022c22f2;
}

.caret-lime-100 {
  caret-color: #ecfccb;
}

.caret-lime-100\/0 {
  caret-color: #ecfccb00;
}

.caret-lime-100\/10 {
  caret-color: #ecfccb1a;
}

.caret-lime-100\/100 {
  caret-color: #ecfccb;
}

.caret-lime-100\/20 {
  caret-color: #ecfccb33;
}

.caret-lime-100\/25 {
  caret-color: #ecfccb40;
}

.caret-lime-100\/30 {
  caret-color: #ecfccb4d;
}

.caret-lime-100\/40 {
  caret-color: #ecfccb66;
}

.caret-lime-100\/5 {
  caret-color: #ecfccb0d;
}

.caret-lime-100\/50 {
  caret-color: #ecfccb80;
}

.caret-lime-100\/60 {
  caret-color: #ecfccb99;
}

.caret-lime-100\/70 {
  caret-color: #ecfccbb3;
}

.caret-lime-100\/75 {
  caret-color: #ecfccbbf;
}

.caret-lime-100\/80 {
  caret-color: #ecfccbcc;
}

.caret-lime-100\/90 {
  caret-color: #ecfccbe6;
}

.caret-lime-100\/95 {
  caret-color: #ecfccbf2;
}

.caret-lime-200 {
  caret-color: #d9f99d;
}

.caret-lime-200\/0 {
  caret-color: #d9f99d00;
}

.caret-lime-200\/10 {
  caret-color: #d9f99d1a;
}

.caret-lime-200\/100 {
  caret-color: #d9f99d;
}

.caret-lime-200\/20 {
  caret-color: #d9f99d33;
}

.caret-lime-200\/25 {
  caret-color: #d9f99d40;
}

.caret-lime-200\/30 {
  caret-color: #d9f99d4d;
}

.caret-lime-200\/40 {
  caret-color: #d9f99d66;
}

.caret-lime-200\/5 {
  caret-color: #d9f99d0d;
}

.caret-lime-200\/50 {
  caret-color: #d9f99d80;
}

.caret-lime-200\/60 {
  caret-color: #d9f99d99;
}

.caret-lime-200\/70 {
  caret-color: #d9f99db3;
}

.caret-lime-200\/75 {
  caret-color: #d9f99dbf;
}

.caret-lime-200\/80 {
  caret-color: #d9f99dcc;
}

.caret-lime-200\/90 {
  caret-color: #d9f99de6;
}

.caret-lime-200\/95 {
  caret-color: #d9f99df2;
}

.caret-lime-300 {
  caret-color: #bef264;
}

.caret-lime-300\/0 {
  caret-color: #bef26400;
}

.caret-lime-300\/10 {
  caret-color: #bef2641a;
}

.caret-lime-300\/100 {
  caret-color: #bef264;
}

.caret-lime-300\/20 {
  caret-color: #bef26433;
}

.caret-lime-300\/25 {
  caret-color: #bef26440;
}

.caret-lime-300\/30 {
  caret-color: #bef2644d;
}

.caret-lime-300\/40 {
  caret-color: #bef26466;
}

.caret-lime-300\/5 {
  caret-color: #bef2640d;
}

.caret-lime-300\/50 {
  caret-color: #bef26480;
}

.caret-lime-300\/60 {
  caret-color: #bef26499;
}

.caret-lime-300\/70 {
  caret-color: #bef264b3;
}

.caret-lime-300\/75 {
  caret-color: #bef264bf;
}

.caret-lime-300\/80 {
  caret-color: #bef264cc;
}

.caret-lime-300\/90 {
  caret-color: #bef264e6;
}

.caret-lime-300\/95 {
  caret-color: #bef264f2;
}

.caret-lime-400 {
  caret-color: #a3e635;
}

.caret-lime-400\/0 {
  caret-color: #a3e63500;
}

.caret-lime-400\/10 {
  caret-color: #a3e6351a;
}

.caret-lime-400\/100 {
  caret-color: #a3e635;
}

.caret-lime-400\/20 {
  caret-color: #a3e63533;
}

.caret-lime-400\/25 {
  caret-color: #a3e63540;
}

.caret-lime-400\/30 {
  caret-color: #a3e6354d;
}

.caret-lime-400\/40 {
  caret-color: #a3e63566;
}

.caret-lime-400\/5 {
  caret-color: #a3e6350d;
}

.caret-lime-400\/50 {
  caret-color: #a3e63580;
}

.caret-lime-400\/60 {
  caret-color: #a3e63599;
}

.caret-lime-400\/70 {
  caret-color: #a3e635b3;
}

.caret-lime-400\/75 {
  caret-color: #a3e635bf;
}

.caret-lime-400\/80 {
  caret-color: #a3e635cc;
}

.caret-lime-400\/90 {
  caret-color: #a3e635e6;
}

.caret-lime-400\/95 {
  caret-color: #a3e635f2;
}

.caret-lime-50 {
  caret-color: #f7fee7;
}

.caret-lime-50\/0 {
  caret-color: #f7fee700;
}

.caret-lime-50\/10 {
  caret-color: #f7fee71a;
}

.caret-lime-50\/100 {
  caret-color: #f7fee7;
}

.caret-lime-50\/20 {
  caret-color: #f7fee733;
}

.caret-lime-50\/25 {
  caret-color: #f7fee740;
}

.caret-lime-50\/30 {
  caret-color: #f7fee74d;
}

.caret-lime-50\/40 {
  caret-color: #f7fee766;
}

.caret-lime-50\/5 {
  caret-color: #f7fee70d;
}

.caret-lime-50\/50 {
  caret-color: #f7fee780;
}

.caret-lime-50\/60 {
  caret-color: #f7fee799;
}

.caret-lime-50\/70 {
  caret-color: #f7fee7b3;
}

.caret-lime-50\/75 {
  caret-color: #f7fee7bf;
}

.caret-lime-50\/80 {
  caret-color: #f7fee7cc;
}

.caret-lime-50\/90 {
  caret-color: #f7fee7e6;
}

.caret-lime-50\/95 {
  caret-color: #f7fee7f2;
}

.caret-lime-500 {
  caret-color: #84cc16;
}

.caret-lime-500\/0 {
  caret-color: #84cc1600;
}

.caret-lime-500\/10 {
  caret-color: #84cc161a;
}

.caret-lime-500\/100 {
  caret-color: #84cc16;
}

.caret-lime-500\/20 {
  caret-color: #84cc1633;
}

.caret-lime-500\/25 {
  caret-color: #84cc1640;
}

.caret-lime-500\/30 {
  caret-color: #84cc164d;
}

.caret-lime-500\/40 {
  caret-color: #84cc1666;
}

.caret-lime-500\/5 {
  caret-color: #84cc160d;
}

.caret-lime-500\/50 {
  caret-color: #84cc1680;
}

.caret-lime-500\/60 {
  caret-color: #84cc1699;
}

.caret-lime-500\/70 {
  caret-color: #84cc16b3;
}

.caret-lime-500\/75 {
  caret-color: #84cc16bf;
}

.caret-lime-500\/80 {
  caret-color: #84cc16cc;
}

.caret-lime-500\/90 {
  caret-color: #84cc16e6;
}

.caret-lime-500\/95 {
  caret-color: #84cc16f2;
}

.caret-lime-600 {
  caret-color: #65a30d;
}

.caret-lime-600\/0 {
  caret-color: #65a30d00;
}

.caret-lime-600\/10 {
  caret-color: #65a30d1a;
}

.caret-lime-600\/100 {
  caret-color: #65a30d;
}

.caret-lime-600\/20 {
  caret-color: #65a30d33;
}

.caret-lime-600\/25 {
  caret-color: #65a30d40;
}

.caret-lime-600\/30 {
  caret-color: #65a30d4d;
}

.caret-lime-600\/40 {
  caret-color: #65a30d66;
}

.caret-lime-600\/5 {
  caret-color: #65a30d0d;
}

.caret-lime-600\/50 {
  caret-color: #65a30d80;
}

.caret-lime-600\/60 {
  caret-color: #65a30d99;
}

.caret-lime-600\/70 {
  caret-color: #65a30db3;
}

.caret-lime-600\/75 {
  caret-color: #65a30dbf;
}

.caret-lime-600\/80 {
  caret-color: #65a30dcc;
}

.caret-lime-600\/90 {
  caret-color: #65a30de6;
}

.caret-lime-600\/95 {
  caret-color: #65a30df2;
}

.caret-lime-700 {
  caret-color: #4d7c0f;
}

.caret-lime-700\/0 {
  caret-color: #4d7c0f00;
}

.caret-lime-700\/10 {
  caret-color: #4d7c0f1a;
}

.caret-lime-700\/100 {
  caret-color: #4d7c0f;
}

.caret-lime-700\/20 {
  caret-color: #4d7c0f33;
}

.caret-lime-700\/25 {
  caret-color: #4d7c0f40;
}

.caret-lime-700\/30 {
  caret-color: #4d7c0f4d;
}

.caret-lime-700\/40 {
  caret-color: #4d7c0f66;
}

.caret-lime-700\/5 {
  caret-color: #4d7c0f0d;
}

.caret-lime-700\/50 {
  caret-color: #4d7c0f80;
}

.caret-lime-700\/60 {
  caret-color: #4d7c0f99;
}

.caret-lime-700\/70 {
  caret-color: #4d7c0fb3;
}

.caret-lime-700\/75 {
  caret-color: #4d7c0fbf;
}

.caret-lime-700\/80 {
  caret-color: #4d7c0fcc;
}

.caret-lime-700\/90 {
  caret-color: #4d7c0fe6;
}

.caret-lime-700\/95 {
  caret-color: #4d7c0ff2;
}

.caret-lime-800 {
  caret-color: #3f6212;
}

.caret-lime-800\/0 {
  caret-color: #3f621200;
}

.caret-lime-800\/10 {
  caret-color: #3f62121a;
}

.caret-lime-800\/100 {
  caret-color: #3f6212;
}

.caret-lime-800\/20 {
  caret-color: #3f621233;
}

.caret-lime-800\/25 {
  caret-color: #3f621240;
}

.caret-lime-800\/30 {
  caret-color: #3f62124d;
}

.caret-lime-800\/40 {
  caret-color: #3f621266;
}

.caret-lime-800\/5 {
  caret-color: #3f62120d;
}

.caret-lime-800\/50 {
  caret-color: #3f621280;
}

.caret-lime-800\/60 {
  caret-color: #3f621299;
}

.caret-lime-800\/70 {
  caret-color: #3f6212b3;
}

.caret-lime-800\/75 {
  caret-color: #3f6212bf;
}

.caret-lime-800\/80 {
  caret-color: #3f6212cc;
}

.caret-lime-800\/90 {
  caret-color: #3f6212e6;
}

.caret-lime-800\/95 {
  caret-color: #3f6212f2;
}

.caret-lime-900 {
  caret-color: #365314;
}

.caret-lime-900\/0 {
  caret-color: #36531400;
}

.caret-lime-900\/10 {
  caret-color: #3653141a;
}

.caret-lime-900\/100 {
  caret-color: #365314;
}

.caret-lime-900\/20 {
  caret-color: #36531433;
}

.caret-lime-900\/25 {
  caret-color: #36531440;
}

.caret-lime-900\/30 {
  caret-color: #3653144d;
}

.caret-lime-900\/40 {
  caret-color: #36531466;
}

.caret-lime-900\/5 {
  caret-color: #3653140d;
}

.caret-lime-900\/50 {
  caret-color: #36531480;
}

.caret-lime-900\/60 {
  caret-color: #36531499;
}

.caret-lime-900\/70 {
  caret-color: #365314b3;
}

.caret-lime-900\/75 {
  caret-color: #365314bf;
}

.caret-lime-900\/80 {
  caret-color: #365314cc;
}

.caret-lime-900\/90 {
  caret-color: #365314e6;
}

.caret-lime-900\/95 {
  caret-color: #365314f2;
}

.caret-lime-950 {
  caret-color: #1a2e05;
}

.caret-lime-950\/0 {
  caret-color: #1a2e0500;
}

.caret-lime-950\/10 {
  caret-color: #1a2e051a;
}

.caret-lime-950\/100 {
  caret-color: #1a2e05;
}

.caret-lime-950\/20 {
  caret-color: #1a2e0533;
}

.caret-lime-950\/25 {
  caret-color: #1a2e0540;
}

.caret-lime-950\/30 {
  caret-color: #1a2e054d;
}

.caret-lime-950\/40 {
  caret-color: #1a2e0566;
}

.caret-lime-950\/5 {
  caret-color: #1a2e050d;
}

.caret-lime-950\/50 {
  caret-color: #1a2e0580;
}

.caret-lime-950\/60 {
  caret-color: #1a2e0599;
}

.caret-lime-950\/70 {
  caret-color: #1a2e05b3;
}

.caret-lime-950\/75 {
  caret-color: #1a2e05bf;
}

.caret-lime-950\/80 {
  caret-color: #1a2e05cc;
}

.caret-lime-950\/90 {
  caret-color: #1a2e05e6;
}

.caret-lime-950\/95 {
  caret-color: #1a2e05f2;
}

.caret-pink-100 {
  caret-color: #fce7f3;
}

.caret-pink-100\/0 {
  caret-color: #fce7f300;
}

.caret-pink-100\/10 {
  caret-color: #fce7f31a;
}

.caret-pink-100\/100 {
  caret-color: #fce7f3;
}

.caret-pink-100\/20 {
  caret-color: #fce7f333;
}

.caret-pink-100\/25 {
  caret-color: #fce7f340;
}

.caret-pink-100\/30 {
  caret-color: #fce7f34d;
}

.caret-pink-100\/40 {
  caret-color: #fce7f366;
}

.caret-pink-100\/5 {
  caret-color: #fce7f30d;
}

.caret-pink-100\/50 {
  caret-color: #fce7f380;
}

.caret-pink-100\/60 {
  caret-color: #fce7f399;
}

.caret-pink-100\/70 {
  caret-color: #fce7f3b3;
}

.caret-pink-100\/75 {
  caret-color: #fce7f3bf;
}

.caret-pink-100\/80 {
  caret-color: #fce7f3cc;
}

.caret-pink-100\/90 {
  caret-color: #fce7f3e6;
}

.caret-pink-100\/95 {
  caret-color: #fce7f3f2;
}

.caret-pink-200 {
  caret-color: #fbcfe8;
}

.caret-pink-200\/0 {
  caret-color: #fbcfe800;
}

.caret-pink-200\/10 {
  caret-color: #fbcfe81a;
}

.caret-pink-200\/100 {
  caret-color: #fbcfe8;
}

.caret-pink-200\/20 {
  caret-color: #fbcfe833;
}

.caret-pink-200\/25 {
  caret-color: #fbcfe840;
}

.caret-pink-200\/30 {
  caret-color: #fbcfe84d;
}

.caret-pink-200\/40 {
  caret-color: #fbcfe866;
}

.caret-pink-200\/5 {
  caret-color: #fbcfe80d;
}

.caret-pink-200\/50 {
  caret-color: #fbcfe880;
}

.caret-pink-200\/60 {
  caret-color: #fbcfe899;
}

.caret-pink-200\/70 {
  caret-color: #fbcfe8b3;
}

.caret-pink-200\/75 {
  caret-color: #fbcfe8bf;
}

.caret-pink-200\/80 {
  caret-color: #fbcfe8cc;
}

.caret-pink-200\/90 {
  caret-color: #fbcfe8e6;
}

.caret-pink-200\/95 {
  caret-color: #fbcfe8f2;
}

.caret-pink-300 {
  caret-color: #f9a8d4;
}

.caret-pink-300\/0 {
  caret-color: #f9a8d400;
}

.caret-pink-300\/10 {
  caret-color: #f9a8d41a;
}

.caret-pink-300\/100 {
  caret-color: #f9a8d4;
}

.caret-pink-300\/20 {
  caret-color: #f9a8d433;
}

.caret-pink-300\/25 {
  caret-color: #f9a8d440;
}

.caret-pink-300\/30 {
  caret-color: #f9a8d44d;
}

.caret-pink-300\/40 {
  caret-color: #f9a8d466;
}

.caret-pink-300\/5 {
  caret-color: #f9a8d40d;
}

.caret-pink-300\/50 {
  caret-color: #f9a8d480;
}

.caret-pink-300\/60 {
  caret-color: #f9a8d499;
}

.caret-pink-300\/70 {
  caret-color: #f9a8d4b3;
}

.caret-pink-300\/75 {
  caret-color: #f9a8d4bf;
}

.caret-pink-300\/80 {
  caret-color: #f9a8d4cc;
}

.caret-pink-300\/90 {
  caret-color: #f9a8d4e6;
}

.caret-pink-300\/95 {
  caret-color: #f9a8d4f2;
}

.caret-pink-400 {
  caret-color: #f472b6;
}

.caret-pink-400\/0 {
  caret-color: #f472b600;
}

.caret-pink-400\/10 {
  caret-color: #f472b61a;
}

.caret-pink-400\/100 {
  caret-color: #f472b6;
}

.caret-pink-400\/20 {
  caret-color: #f472b633;
}

.caret-pink-400\/25 {
  caret-color: #f472b640;
}

.caret-pink-400\/30 {
  caret-color: #f472b64d;
}

.caret-pink-400\/40 {
  caret-color: #f472b666;
}

.caret-pink-400\/5 {
  caret-color: #f472b60d;
}

.caret-pink-400\/50 {
  caret-color: #f472b680;
}

.caret-pink-400\/60 {
  caret-color: #f472b699;
}

.caret-pink-400\/70 {
  caret-color: #f472b6b3;
}

.caret-pink-400\/75 {
  caret-color: #f472b6bf;
}

.caret-pink-400\/80 {
  caret-color: #f472b6cc;
}

.caret-pink-400\/90 {
  caret-color: #f472b6e6;
}

.caret-pink-400\/95 {
  caret-color: #f472b6f2;
}

.caret-pink-50 {
  caret-color: #fdf2f8;
}

.caret-pink-50\/0 {
  caret-color: #fdf2f800;
}

.caret-pink-50\/10 {
  caret-color: #fdf2f81a;
}

.caret-pink-50\/100 {
  caret-color: #fdf2f8;
}

.caret-pink-50\/20 {
  caret-color: #fdf2f833;
}

.caret-pink-50\/25 {
  caret-color: #fdf2f840;
}

.caret-pink-50\/30 {
  caret-color: #fdf2f84d;
}

.caret-pink-50\/40 {
  caret-color: #fdf2f866;
}

.caret-pink-50\/5 {
  caret-color: #fdf2f80d;
}

.caret-pink-50\/50 {
  caret-color: #fdf2f880;
}

.caret-pink-50\/60 {
  caret-color: #fdf2f899;
}

.caret-pink-50\/70 {
  caret-color: #fdf2f8b3;
}

.caret-pink-50\/75 {
  caret-color: #fdf2f8bf;
}

.caret-pink-50\/80 {
  caret-color: #fdf2f8cc;
}

.caret-pink-50\/90 {
  caret-color: #fdf2f8e6;
}

.caret-pink-50\/95 {
  caret-color: #fdf2f8f2;
}

.caret-pink-500 {
  caret-color: #ec4899;
}

.caret-pink-500\/0 {
  caret-color: #ec489900;
}

.caret-pink-500\/10 {
  caret-color: #ec48991a;
}

.caret-pink-500\/100 {
  caret-color: #ec4899;
}

.caret-pink-500\/20 {
  caret-color: #ec489933;
}

.caret-pink-500\/25 {
  caret-color: #ec489940;
}

.caret-pink-500\/30 {
  caret-color: #ec48994d;
}

.caret-pink-500\/40 {
  caret-color: #ec489966;
}

.caret-pink-500\/5 {
  caret-color: #ec48990d;
}

.caret-pink-500\/50 {
  caret-color: #ec489980;
}

.caret-pink-500\/60 {
  caret-color: #ec489999;
}

.caret-pink-500\/70 {
  caret-color: #ec4899b3;
}

.caret-pink-500\/75 {
  caret-color: #ec4899bf;
}

.caret-pink-500\/80 {
  caret-color: #ec4899cc;
}

.caret-pink-500\/90 {
  caret-color: #ec4899e6;
}

.caret-pink-500\/95 {
  caret-color: #ec4899f2;
}

.caret-pink-600 {
  caret-color: #db2777;
}

.caret-pink-600\/0 {
  caret-color: #db277700;
}

.caret-pink-600\/10 {
  caret-color: #db27771a;
}

.caret-pink-600\/100 {
  caret-color: #db2777;
}

.caret-pink-600\/20 {
  caret-color: #db277733;
}

.caret-pink-600\/25 {
  caret-color: #db277740;
}

.caret-pink-600\/30 {
  caret-color: #db27774d;
}

.caret-pink-600\/40 {
  caret-color: #db277766;
}

.caret-pink-600\/5 {
  caret-color: #db27770d;
}

.caret-pink-600\/50 {
  caret-color: #db277780;
}

.caret-pink-600\/60 {
  caret-color: #db277799;
}

.caret-pink-600\/70 {
  caret-color: #db2777b3;
}

.caret-pink-600\/75 {
  caret-color: #db2777bf;
}

.caret-pink-600\/80 {
  caret-color: #db2777cc;
}

.caret-pink-600\/90 {
  caret-color: #db2777e6;
}

.caret-pink-600\/95 {
  caret-color: #db2777f2;
}

.caret-pink-700 {
  caret-color: #be185d;
}

.caret-pink-700\/0 {
  caret-color: #be185d00;
}

.caret-pink-700\/10 {
  caret-color: #be185d1a;
}

.caret-pink-700\/100 {
  caret-color: #be185d;
}

.caret-pink-700\/20 {
  caret-color: #be185d33;
}

.caret-pink-700\/25 {
  caret-color: #be185d40;
}

.caret-pink-700\/30 {
  caret-color: #be185d4d;
}

.caret-pink-700\/40 {
  caret-color: #be185d66;
}

.caret-pink-700\/5 {
  caret-color: #be185d0d;
}

.caret-pink-700\/50 {
  caret-color: #be185d80;
}

.caret-pink-700\/60 {
  caret-color: #be185d99;
}

.caret-pink-700\/70 {
  caret-color: #be185db3;
}

.caret-pink-700\/75 {
  caret-color: #be185dbf;
}

.caret-pink-700\/80 {
  caret-color: #be185dcc;
}

.caret-pink-700\/90 {
  caret-color: #be185de6;
}

.caret-pink-700\/95 {
  caret-color: #be185df2;
}

.caret-pink-800 {
  caret-color: #9d174d;
}

.caret-pink-800\/0 {
  caret-color: #9d174d00;
}

.caret-pink-800\/10 {
  caret-color: #9d174d1a;
}

.caret-pink-800\/100 {
  caret-color: #9d174d;
}

.caret-pink-800\/20 {
  caret-color: #9d174d33;
}

.caret-pink-800\/25 {
  caret-color: #9d174d40;
}

.caret-pink-800\/30 {
  caret-color: #9d174d4d;
}

.caret-pink-800\/40 {
  caret-color: #9d174d66;
}

.caret-pink-800\/5 {
  caret-color: #9d174d0d;
}

.caret-pink-800\/50 {
  caret-color: #9d174d80;
}

.caret-pink-800\/60 {
  caret-color: #9d174d99;
}

.caret-pink-800\/70 {
  caret-color: #9d174db3;
}

.caret-pink-800\/75 {
  caret-color: #9d174dbf;
}

.caret-pink-800\/80 {
  caret-color: #9d174dcc;
}

.caret-pink-800\/90 {
  caret-color: #9d174de6;
}

.caret-pink-800\/95 {
  caret-color: #9d174df2;
}

.caret-pink-900 {
  caret-color: #831843;
}

.caret-pink-900\/0 {
  caret-color: #83184300;
}

.caret-pink-900\/10 {
  caret-color: #8318431a;
}

.caret-pink-900\/100 {
  caret-color: #831843;
}

.caret-pink-900\/20 {
  caret-color: #83184333;
}

.caret-pink-900\/25 {
  caret-color: #83184340;
}

.caret-pink-900\/30 {
  caret-color: #8318434d;
}

.caret-pink-900\/40 {
  caret-color: #83184366;
}

.caret-pink-900\/5 {
  caret-color: #8318430d;
}

.caret-pink-900\/50 {
  caret-color: #83184380;
}

.caret-pink-900\/60 {
  caret-color: #83184399;
}

.caret-pink-900\/70 {
  caret-color: #831843b3;
}

.caret-pink-900\/75 {
  caret-color: #831843bf;
}

.caret-pink-900\/80 {
  caret-color: #831843cc;
}

.caret-pink-900\/90 {
  caret-color: #831843e6;
}

.caret-pink-900\/95 {
  caret-color: #831843f2;
}

.caret-pink-950 {
  caret-color: #500724;
}

.caret-pink-950\/0 {
  caret-color: #50072400;
}

.caret-pink-950\/10 {
  caret-color: #5007241a;
}

.caret-pink-950\/100 {
  caret-color: #500724;
}

.caret-pink-950\/20 {
  caret-color: #50072433;
}

.caret-pink-950\/25 {
  caret-color: #50072440;
}

.caret-pink-950\/30 {
  caret-color: #5007244d;
}

.caret-pink-950\/40 {
  caret-color: #50072466;
}

.caret-pink-950\/5 {
  caret-color: #5007240d;
}

.caret-pink-950\/50 {
  caret-color: #50072480;
}

.caret-pink-950\/60 {
  caret-color: #50072499;
}

.caret-pink-950\/70 {
  caret-color: #500724b3;
}

.caret-pink-950\/75 {
  caret-color: #500724bf;
}

.caret-pink-950\/80 {
  caret-color: #500724cc;
}

.caret-pink-950\/90 {
  caret-color: #500724e6;
}

.caret-pink-950\/95 {
  caret-color: #500724f2;
}

.caret-primary {
  caret-color: hsl(var(--primary) / 1);
}

.caret-primary\/0 {
  caret-color: hsl(var(--primary) / 0);
}

.caret-primary\/10 {
  caret-color: hsl(var(--primary) / .1);
}

.caret-primary\/100 {
  caret-color: hsl(var(--primary) / 1);
}

.caret-primary\/20 {
  caret-color: hsl(var(--primary) / .2);
}

.caret-primary\/25 {
  caret-color: hsl(var(--primary) / .25);
}

.caret-primary\/30 {
  caret-color: hsl(var(--primary) / .3);
}

.caret-primary\/40 {
  caret-color: hsl(var(--primary) / .4);
}

.caret-primary\/5 {
  caret-color: hsl(var(--primary) / .05);
}

.caret-primary\/50 {
  caret-color: hsl(var(--primary) / .5);
}

.caret-primary\/60 {
  caret-color: hsl(var(--primary) / .6);
}

.caret-primary\/70 {
  caret-color: hsl(var(--primary) / .7);
}

.caret-primary\/75 {
  caret-color: hsl(var(--primary) / .75);
}

.caret-primary\/80 {
  caret-color: hsl(var(--primary) / .8);
}

.caret-primary\/90 {
  caret-color: hsl(var(--primary) / .9);
}

.caret-primary\/95 {
  caret-color: hsl(var(--primary) / .95);
}

.caret-purple-100 {
  caret-color: #f3e8ff;
}

.caret-purple-100\/0 {
  caret-color: #f3e8ff00;
}

.caret-purple-100\/10 {
  caret-color: #f3e8ff1a;
}

.caret-purple-100\/100 {
  caret-color: #f3e8ff;
}

.caret-purple-100\/20 {
  caret-color: #f3e8ff33;
}

.caret-purple-100\/25 {
  caret-color: #f3e8ff40;
}

.caret-purple-100\/30 {
  caret-color: #f3e8ff4d;
}

.caret-purple-100\/40 {
  caret-color: #f3e8ff66;
}

.caret-purple-100\/5 {
  caret-color: #f3e8ff0d;
}

.caret-purple-100\/50 {
  caret-color: #f3e8ff80;
}

.caret-purple-100\/60 {
  caret-color: #f3e8ff99;
}

.caret-purple-100\/70 {
  caret-color: #f3e8ffb3;
}

.caret-purple-100\/75 {
  caret-color: #f3e8ffbf;
}

.caret-purple-100\/80 {
  caret-color: #f3e8ffcc;
}

.caret-purple-100\/90 {
  caret-color: #f3e8ffe6;
}

.caret-purple-100\/95 {
  caret-color: #f3e8fff2;
}

.caret-purple-200 {
  caret-color: #e9d5ff;
}

.caret-purple-200\/0 {
  caret-color: #e9d5ff00;
}

.caret-purple-200\/10 {
  caret-color: #e9d5ff1a;
}

.caret-purple-200\/100 {
  caret-color: #e9d5ff;
}

.caret-purple-200\/20 {
  caret-color: #e9d5ff33;
}

.caret-purple-200\/25 {
  caret-color: #e9d5ff40;
}

.caret-purple-200\/30 {
  caret-color: #e9d5ff4d;
}

.caret-purple-200\/40 {
  caret-color: #e9d5ff66;
}

.caret-purple-200\/5 {
  caret-color: #e9d5ff0d;
}

.caret-purple-200\/50 {
  caret-color: #e9d5ff80;
}

.caret-purple-200\/60 {
  caret-color: #e9d5ff99;
}

.caret-purple-200\/70 {
  caret-color: #e9d5ffb3;
}

.caret-purple-200\/75 {
  caret-color: #e9d5ffbf;
}

.caret-purple-200\/80 {
  caret-color: #e9d5ffcc;
}

.caret-purple-200\/90 {
  caret-color: #e9d5ffe6;
}

.caret-purple-200\/95 {
  caret-color: #e9d5fff2;
}

.caret-purple-300 {
  caret-color: #d8b4fe;
}

.caret-purple-300\/0 {
  caret-color: #d8b4fe00;
}

.caret-purple-300\/10 {
  caret-color: #d8b4fe1a;
}

.caret-purple-300\/100 {
  caret-color: #d8b4fe;
}

.caret-purple-300\/20 {
  caret-color: #d8b4fe33;
}

.caret-purple-300\/25 {
  caret-color: #d8b4fe40;
}

.caret-purple-300\/30 {
  caret-color: #d8b4fe4d;
}

.caret-purple-300\/40 {
  caret-color: #d8b4fe66;
}

.caret-purple-300\/5 {
  caret-color: #d8b4fe0d;
}

.caret-purple-300\/50 {
  caret-color: #d8b4fe80;
}

.caret-purple-300\/60 {
  caret-color: #d8b4fe99;
}

.caret-purple-300\/70 {
  caret-color: #d8b4feb3;
}

.caret-purple-300\/75 {
  caret-color: #d8b4febf;
}

.caret-purple-300\/80 {
  caret-color: #d8b4fecc;
}

.caret-purple-300\/90 {
  caret-color: #d8b4fee6;
}

.caret-purple-300\/95 {
  caret-color: #d8b4fef2;
}

.caret-purple-400 {
  caret-color: #c084fc;
}

.caret-purple-400\/0 {
  caret-color: #c084fc00;
}

.caret-purple-400\/10 {
  caret-color: #c084fc1a;
}

.caret-purple-400\/100 {
  caret-color: #c084fc;
}

.caret-purple-400\/20 {
  caret-color: #c084fc33;
}

.caret-purple-400\/25 {
  caret-color: #c084fc40;
}

.caret-purple-400\/30 {
  caret-color: #c084fc4d;
}

.caret-purple-400\/40 {
  caret-color: #c084fc66;
}

.caret-purple-400\/5 {
  caret-color: #c084fc0d;
}

.caret-purple-400\/50 {
  caret-color: #c084fc80;
}

.caret-purple-400\/60 {
  caret-color: #c084fc99;
}

.caret-purple-400\/70 {
  caret-color: #c084fcb3;
}

.caret-purple-400\/75 {
  caret-color: #c084fcbf;
}

.caret-purple-400\/80 {
  caret-color: #c084fccc;
}

.caret-purple-400\/90 {
  caret-color: #c084fce6;
}

.caret-purple-400\/95 {
  caret-color: #c084fcf2;
}

.caret-purple-50 {
  caret-color: #faf5ff;
}

.caret-purple-50\/0 {
  caret-color: #faf5ff00;
}

.caret-purple-50\/10 {
  caret-color: #faf5ff1a;
}

.caret-purple-50\/100 {
  caret-color: #faf5ff;
}

.caret-purple-50\/20 {
  caret-color: #faf5ff33;
}

.caret-purple-50\/25 {
  caret-color: #faf5ff40;
}

.caret-purple-50\/30 {
  caret-color: #faf5ff4d;
}

.caret-purple-50\/40 {
  caret-color: #faf5ff66;
}

.caret-purple-50\/5 {
  caret-color: #faf5ff0d;
}

.caret-purple-50\/50 {
  caret-color: #faf5ff80;
}

.caret-purple-50\/60 {
  caret-color: #faf5ff99;
}

.caret-purple-50\/70 {
  caret-color: #faf5ffb3;
}

.caret-purple-50\/75 {
  caret-color: #faf5ffbf;
}

.caret-purple-50\/80 {
  caret-color: #faf5ffcc;
}

.caret-purple-50\/90 {
  caret-color: #faf5ffe6;
}

.caret-purple-50\/95 {
  caret-color: #faf5fff2;
}

.caret-purple-500 {
  caret-color: #a855f7;
}

.caret-purple-500\/0 {
  caret-color: #a855f700;
}

.caret-purple-500\/10 {
  caret-color: #a855f71a;
}

.caret-purple-500\/100 {
  caret-color: #a855f7;
}

.caret-purple-500\/20 {
  caret-color: #a855f733;
}

.caret-purple-500\/25 {
  caret-color: #a855f740;
}

.caret-purple-500\/30 {
  caret-color: #a855f74d;
}

.caret-purple-500\/40 {
  caret-color: #a855f766;
}

.caret-purple-500\/5 {
  caret-color: #a855f70d;
}

.caret-purple-500\/50 {
  caret-color: #a855f780;
}

.caret-purple-500\/60 {
  caret-color: #a855f799;
}

.caret-purple-500\/70 {
  caret-color: #a855f7b3;
}

.caret-purple-500\/75 {
  caret-color: #a855f7bf;
}

.caret-purple-500\/80 {
  caret-color: #a855f7cc;
}

.caret-purple-500\/90 {
  caret-color: #a855f7e6;
}

.caret-purple-500\/95 {
  caret-color: #a855f7f2;
}

.caret-purple-600 {
  caret-color: #9333ea;
}

.caret-purple-600\/0 {
  caret-color: #9333ea00;
}

.caret-purple-600\/10 {
  caret-color: #9333ea1a;
}

.caret-purple-600\/100 {
  caret-color: #9333ea;
}

.caret-purple-600\/20 {
  caret-color: #9333ea33;
}

.caret-purple-600\/25 {
  caret-color: #9333ea40;
}

.caret-purple-600\/30 {
  caret-color: #9333ea4d;
}

.caret-purple-600\/40 {
  caret-color: #9333ea66;
}

.caret-purple-600\/5 {
  caret-color: #9333ea0d;
}

.caret-purple-600\/50 {
  caret-color: #9333ea80;
}

.caret-purple-600\/60 {
  caret-color: #9333ea99;
}

.caret-purple-600\/70 {
  caret-color: #9333eab3;
}

.caret-purple-600\/75 {
  caret-color: #9333eabf;
}

.caret-purple-600\/80 {
  caret-color: #9333eacc;
}

.caret-purple-600\/90 {
  caret-color: #9333eae6;
}

.caret-purple-600\/95 {
  caret-color: #9333eaf2;
}

.caret-purple-700 {
  caret-color: #7e22ce;
}

.caret-purple-700\/0 {
  caret-color: #7e22ce00;
}

.caret-purple-700\/10 {
  caret-color: #7e22ce1a;
}

.caret-purple-700\/100 {
  caret-color: #7e22ce;
}

.caret-purple-700\/20 {
  caret-color: #7e22ce33;
}

.caret-purple-700\/25 {
  caret-color: #7e22ce40;
}

.caret-purple-700\/30 {
  caret-color: #7e22ce4d;
}

.caret-purple-700\/40 {
  caret-color: #7e22ce66;
}

.caret-purple-700\/5 {
  caret-color: #7e22ce0d;
}

.caret-purple-700\/50 {
  caret-color: #7e22ce80;
}

.caret-purple-700\/60 {
  caret-color: #7e22ce99;
}

.caret-purple-700\/70 {
  caret-color: #7e22ceb3;
}

.caret-purple-700\/75 {
  caret-color: #7e22cebf;
}

.caret-purple-700\/80 {
  caret-color: #7e22cecc;
}

.caret-purple-700\/90 {
  caret-color: #7e22cee6;
}

.caret-purple-700\/95 {
  caret-color: #7e22cef2;
}

.caret-purple-800 {
  caret-color: #6b21a8;
}

.caret-purple-800\/0 {
  caret-color: #6b21a800;
}

.caret-purple-800\/10 {
  caret-color: #6b21a81a;
}

.caret-purple-800\/100 {
  caret-color: #6b21a8;
}

.caret-purple-800\/20 {
  caret-color: #6b21a833;
}

.caret-purple-800\/25 {
  caret-color: #6b21a840;
}

.caret-purple-800\/30 {
  caret-color: #6b21a84d;
}

.caret-purple-800\/40 {
  caret-color: #6b21a866;
}

.caret-purple-800\/5 {
  caret-color: #6b21a80d;
}

.caret-purple-800\/50 {
  caret-color: #6b21a880;
}

.caret-purple-800\/60 {
  caret-color: #6b21a899;
}

.caret-purple-800\/70 {
  caret-color: #6b21a8b3;
}

.caret-purple-800\/75 {
  caret-color: #6b21a8bf;
}

.caret-purple-800\/80 {
  caret-color: #6b21a8cc;
}

.caret-purple-800\/90 {
  caret-color: #6b21a8e6;
}

.caret-purple-800\/95 {
  caret-color: #6b21a8f2;
}

.caret-purple-900 {
  caret-color: #581c87;
}

.caret-purple-900\/0 {
  caret-color: #581c8700;
}

.caret-purple-900\/10 {
  caret-color: #581c871a;
}

.caret-purple-900\/100 {
  caret-color: #581c87;
}

.caret-purple-900\/20 {
  caret-color: #581c8733;
}

.caret-purple-900\/25 {
  caret-color: #581c8740;
}

.caret-purple-900\/30 {
  caret-color: #581c874d;
}

.caret-purple-900\/40 {
  caret-color: #581c8766;
}

.caret-purple-900\/5 {
  caret-color: #581c870d;
}

.caret-purple-900\/50 {
  caret-color: #581c8780;
}

.caret-purple-900\/60 {
  caret-color: #581c8799;
}

.caret-purple-900\/70 {
  caret-color: #581c87b3;
}

.caret-purple-900\/75 {
  caret-color: #581c87bf;
}

.caret-purple-900\/80 {
  caret-color: #581c87cc;
}

.caret-purple-900\/90 {
  caret-color: #581c87e6;
}

.caret-purple-900\/95 {
  caret-color: #581c87f2;
}

.caret-purple-950 {
  caret-color: #3b0764;
}

.caret-purple-950\/0 {
  caret-color: #3b076400;
}

.caret-purple-950\/10 {
  caret-color: #3b07641a;
}

.caret-purple-950\/100 {
  caret-color: #3b0764;
}

.caret-purple-950\/20 {
  caret-color: #3b076433;
}

.caret-purple-950\/25 {
  caret-color: #3b076440;
}

.caret-purple-950\/30 {
  caret-color: #3b07644d;
}

.caret-purple-950\/40 {
  caret-color: #3b076466;
}

.caret-purple-950\/5 {
  caret-color: #3b07640d;
}

.caret-purple-950\/50 {
  caret-color: #3b076480;
}

.caret-purple-950\/60 {
  caret-color: #3b076499;
}

.caret-purple-950\/70 {
  caret-color: #3b0764b3;
}

.caret-purple-950\/75 {
  caret-color: #3b0764bf;
}

.caret-purple-950\/80 {
  caret-color: #3b0764cc;
}

.caret-purple-950\/90 {
  caret-color: #3b0764e6;
}

.caret-purple-950\/95 {
  caret-color: #3b0764f2;
}

.caret-transparent, .caret-transparent\/0 {
  caret-color: #0000;
}

.caret-transparent\/10 {
  caret-color: #0000001a;
}

.caret-transparent\/100 {
  caret-color: #000;
}

.caret-transparent\/20 {
  caret-color: #0003;
}

.caret-transparent\/25 {
  caret-color: #00000040;
}

.caret-transparent\/30 {
  caret-color: #0000004d;
}

.caret-transparent\/40 {
  caret-color: #0006;
}

.caret-transparent\/5 {
  caret-color: #0000000d;
}

.caret-transparent\/50 {
  caret-color: #00000080;
}

.caret-transparent\/60 {
  caret-color: #0009;
}

.caret-transparent\/70 {
  caret-color: #000000b3;
}

.caret-transparent\/75 {
  caret-color: #000000bf;
}

.caret-transparent\/80 {
  caret-color: #000c;
}

.caret-transparent\/90 {
  caret-color: #000000e6;
}

.caret-transparent\/95 {
  caret-color: #000000f2;
}

.accent-amber-100 {
  accent-color: #fef3c7;
}

.accent-amber-100\/0 {
  accent-color: #fef3c700;
}

.accent-amber-100\/10 {
  accent-color: #fef3c71a;
}

.accent-amber-100\/100 {
  accent-color: #fef3c7;
}

.accent-amber-100\/20 {
  accent-color: #fef3c733;
}

.accent-amber-100\/25 {
  accent-color: #fef3c740;
}

.accent-amber-100\/30 {
  accent-color: #fef3c74d;
}

.accent-amber-100\/40 {
  accent-color: #fef3c766;
}

.accent-amber-100\/5 {
  accent-color: #fef3c70d;
}

.accent-amber-100\/50 {
  accent-color: #fef3c780;
}

.accent-amber-100\/60 {
  accent-color: #fef3c799;
}

.accent-amber-100\/70 {
  accent-color: #fef3c7b3;
}

.accent-amber-100\/75 {
  accent-color: #fef3c7bf;
}

.accent-amber-100\/80 {
  accent-color: #fef3c7cc;
}

.accent-amber-100\/90 {
  accent-color: #fef3c7e6;
}

.accent-amber-100\/95 {
  accent-color: #fef3c7f2;
}

.accent-amber-200 {
  accent-color: #fde68a;
}

.accent-amber-200\/0 {
  accent-color: #fde68a00;
}

.accent-amber-200\/10 {
  accent-color: #fde68a1a;
}

.accent-amber-200\/100 {
  accent-color: #fde68a;
}

.accent-amber-200\/20 {
  accent-color: #fde68a33;
}

.accent-amber-200\/25 {
  accent-color: #fde68a40;
}

.accent-amber-200\/30 {
  accent-color: #fde68a4d;
}

.accent-amber-200\/40 {
  accent-color: #fde68a66;
}

.accent-amber-200\/5 {
  accent-color: #fde68a0d;
}

.accent-amber-200\/50 {
  accent-color: #fde68a80;
}

.accent-amber-200\/60 {
  accent-color: #fde68a99;
}

.accent-amber-200\/70 {
  accent-color: #fde68ab3;
}

.accent-amber-200\/75 {
  accent-color: #fde68abf;
}

.accent-amber-200\/80 {
  accent-color: #fde68acc;
}

.accent-amber-200\/90 {
  accent-color: #fde68ae6;
}

.accent-amber-200\/95 {
  accent-color: #fde68af2;
}

.accent-amber-300 {
  accent-color: #fcd34d;
}

.accent-amber-300\/0 {
  accent-color: #fcd34d00;
}

.accent-amber-300\/10 {
  accent-color: #fcd34d1a;
}

.accent-amber-300\/100 {
  accent-color: #fcd34d;
}

.accent-amber-300\/20 {
  accent-color: #fcd34d33;
}

.accent-amber-300\/25 {
  accent-color: #fcd34d40;
}

.accent-amber-300\/30 {
  accent-color: #fcd34d4d;
}

.accent-amber-300\/40 {
  accent-color: #fcd34d66;
}

.accent-amber-300\/5 {
  accent-color: #fcd34d0d;
}

.accent-amber-300\/50 {
  accent-color: #fcd34d80;
}

.accent-amber-300\/60 {
  accent-color: #fcd34d99;
}

.accent-amber-300\/70 {
  accent-color: #fcd34db3;
}

.accent-amber-300\/75 {
  accent-color: #fcd34dbf;
}

.accent-amber-300\/80 {
  accent-color: #fcd34dcc;
}

.accent-amber-300\/90 {
  accent-color: #fcd34de6;
}

.accent-amber-300\/95 {
  accent-color: #fcd34df2;
}

.accent-amber-400 {
  accent-color: #fbbf24;
}

.accent-amber-400\/0 {
  accent-color: #fbbf2400;
}

.accent-amber-400\/10 {
  accent-color: #fbbf241a;
}

.accent-amber-400\/100 {
  accent-color: #fbbf24;
}

.accent-amber-400\/20 {
  accent-color: #fbbf2433;
}

.accent-amber-400\/25 {
  accent-color: #fbbf2440;
}

.accent-amber-400\/30 {
  accent-color: #fbbf244d;
}

.accent-amber-400\/40 {
  accent-color: #fbbf2466;
}

.accent-amber-400\/5 {
  accent-color: #fbbf240d;
}

.accent-amber-400\/50 {
  accent-color: #fbbf2480;
}

.accent-amber-400\/60 {
  accent-color: #fbbf2499;
}

.accent-amber-400\/70 {
  accent-color: #fbbf24b3;
}

.accent-amber-400\/75 {
  accent-color: #fbbf24bf;
}

.accent-amber-400\/80 {
  accent-color: #fbbf24cc;
}

.accent-amber-400\/90 {
  accent-color: #fbbf24e6;
}

.accent-amber-400\/95 {
  accent-color: #fbbf24f2;
}

.accent-amber-50 {
  accent-color: #fffbeb;
}

.accent-amber-50\/0 {
  accent-color: #fffbeb00;
}

.accent-amber-50\/10 {
  accent-color: #fffbeb1a;
}

.accent-amber-50\/100 {
  accent-color: #fffbeb;
}

.accent-amber-50\/20 {
  accent-color: #fffbeb33;
}

.accent-amber-50\/25 {
  accent-color: #fffbeb40;
}

.accent-amber-50\/30 {
  accent-color: #fffbeb4d;
}

.accent-amber-50\/40 {
  accent-color: #fffbeb66;
}

.accent-amber-50\/5 {
  accent-color: #fffbeb0d;
}

.accent-amber-50\/50 {
  accent-color: #fffbeb80;
}

.accent-amber-50\/60 {
  accent-color: #fffbeb99;
}

.accent-amber-50\/70 {
  accent-color: #fffbebb3;
}

.accent-amber-50\/75 {
  accent-color: #fffbebbf;
}

.accent-amber-50\/80 {
  accent-color: #fffbebcc;
}

.accent-amber-50\/90 {
  accent-color: #fffbebe6;
}

.accent-amber-50\/95 {
  accent-color: #fffbebf2;
}

.accent-amber-500 {
  accent-color: #f59e0b;
}

.accent-amber-500\/0 {
  accent-color: #f59e0b00;
}

.accent-amber-500\/10 {
  accent-color: #f59e0b1a;
}

.accent-amber-500\/100 {
  accent-color: #f59e0b;
}

.accent-amber-500\/20 {
  accent-color: #f59e0b33;
}

.accent-amber-500\/25 {
  accent-color: #f59e0b40;
}

.accent-amber-500\/30 {
  accent-color: #f59e0b4d;
}

.accent-amber-500\/40 {
  accent-color: #f59e0b66;
}

.accent-amber-500\/5 {
  accent-color: #f59e0b0d;
}

.accent-amber-500\/50 {
  accent-color: #f59e0b80;
}

.accent-amber-500\/60 {
  accent-color: #f59e0b99;
}

.accent-amber-500\/70 {
  accent-color: #f59e0bb3;
}

.accent-amber-500\/75 {
  accent-color: #f59e0bbf;
}

.accent-amber-500\/80 {
  accent-color: #f59e0bcc;
}

.accent-amber-500\/90 {
  accent-color: #f59e0be6;
}

.accent-amber-500\/95 {
  accent-color: #f59e0bf2;
}

.accent-amber-600 {
  accent-color: #d97706;
}

.accent-amber-600\/0 {
  accent-color: #d9770600;
}

.accent-amber-600\/10 {
  accent-color: #d977061a;
}

.accent-amber-600\/100 {
  accent-color: #d97706;
}

.accent-amber-600\/20 {
  accent-color: #d9770633;
}

.accent-amber-600\/25 {
  accent-color: #d9770640;
}

.accent-amber-600\/30 {
  accent-color: #d977064d;
}

.accent-amber-600\/40 {
  accent-color: #d9770666;
}

.accent-amber-600\/5 {
  accent-color: #d977060d;
}

.accent-amber-600\/50 {
  accent-color: #d9770680;
}

.accent-amber-600\/60 {
  accent-color: #d9770699;
}

.accent-amber-600\/70 {
  accent-color: #d97706b3;
}

.accent-amber-600\/75 {
  accent-color: #d97706bf;
}

.accent-amber-600\/80 {
  accent-color: #d97706cc;
}

.accent-amber-600\/90 {
  accent-color: #d97706e6;
}

.accent-amber-600\/95 {
  accent-color: #d97706f2;
}

.accent-amber-700 {
  accent-color: #b45309;
}

.accent-amber-700\/0 {
  accent-color: #b4530900;
}

.accent-amber-700\/10 {
  accent-color: #b453091a;
}

.accent-amber-700\/100 {
  accent-color: #b45309;
}

.accent-amber-700\/20 {
  accent-color: #b4530933;
}

.accent-amber-700\/25 {
  accent-color: #b4530940;
}

.accent-amber-700\/30 {
  accent-color: #b453094d;
}

.accent-amber-700\/40 {
  accent-color: #b4530966;
}

.accent-amber-700\/5 {
  accent-color: #b453090d;
}

.accent-amber-700\/50 {
  accent-color: #b4530980;
}

.accent-amber-700\/60 {
  accent-color: #b4530999;
}

.accent-amber-700\/70 {
  accent-color: #b45309b3;
}

.accent-amber-700\/75 {
  accent-color: #b45309bf;
}

.accent-amber-700\/80 {
  accent-color: #b45309cc;
}

.accent-amber-700\/90 {
  accent-color: #b45309e6;
}

.accent-amber-700\/95 {
  accent-color: #b45309f2;
}

.accent-amber-800 {
  accent-color: #92400e;
}

.accent-amber-800\/0 {
  accent-color: #92400e00;
}

.accent-amber-800\/10 {
  accent-color: #92400e1a;
}

.accent-amber-800\/100 {
  accent-color: #92400e;
}

.accent-amber-800\/20 {
  accent-color: #92400e33;
}

.accent-amber-800\/25 {
  accent-color: #92400e40;
}

.accent-amber-800\/30 {
  accent-color: #92400e4d;
}

.accent-amber-800\/40 {
  accent-color: #92400e66;
}

.accent-amber-800\/5 {
  accent-color: #92400e0d;
}

.accent-amber-800\/50 {
  accent-color: #92400e80;
}

.accent-amber-800\/60 {
  accent-color: #92400e99;
}

.accent-amber-800\/70 {
  accent-color: #92400eb3;
}

.accent-amber-800\/75 {
  accent-color: #92400ebf;
}

.accent-amber-800\/80 {
  accent-color: #92400ecc;
}

.accent-amber-800\/90 {
  accent-color: #92400ee6;
}

.accent-amber-800\/95 {
  accent-color: #92400ef2;
}

.accent-amber-900 {
  accent-color: #78350f;
}

.accent-amber-900\/0 {
  accent-color: #78350f00;
}

.accent-amber-900\/10 {
  accent-color: #78350f1a;
}

.accent-amber-900\/100 {
  accent-color: #78350f;
}

.accent-amber-900\/20 {
  accent-color: #78350f33;
}

.accent-amber-900\/25 {
  accent-color: #78350f40;
}

.accent-amber-900\/30 {
  accent-color: #78350f4d;
}

.accent-amber-900\/40 {
  accent-color: #78350f66;
}

.accent-amber-900\/5 {
  accent-color: #78350f0d;
}

.accent-amber-900\/50 {
  accent-color: #78350f80;
}

.accent-amber-900\/60 {
  accent-color: #78350f99;
}

.accent-amber-900\/70 {
  accent-color: #78350fb3;
}

.accent-amber-900\/75 {
  accent-color: #78350fbf;
}

.accent-amber-900\/80 {
  accent-color: #78350fcc;
}

.accent-amber-900\/90 {
  accent-color: #78350fe6;
}

.accent-amber-900\/95 {
  accent-color: #78350ff2;
}

.accent-amber-950 {
  accent-color: #451a03;
}

.accent-amber-950\/0 {
  accent-color: #451a0300;
}

.accent-amber-950\/10 {
  accent-color: #451a031a;
}

.accent-amber-950\/100 {
  accent-color: #451a03;
}

.accent-amber-950\/20 {
  accent-color: #451a0333;
}

.accent-amber-950\/25 {
  accent-color: #451a0340;
}

.accent-amber-950\/30 {
  accent-color: #451a034d;
}

.accent-amber-950\/40 {
  accent-color: #451a0366;
}

.accent-amber-950\/5 {
  accent-color: #451a030d;
}

.accent-amber-950\/50 {
  accent-color: #451a0380;
}

.accent-amber-950\/60 {
  accent-color: #451a0399;
}

.accent-amber-950\/70 {
  accent-color: #451a03b3;
}

.accent-amber-950\/75 {
  accent-color: #451a03bf;
}

.accent-amber-950\/80 {
  accent-color: #451a03cc;
}

.accent-amber-950\/90 {
  accent-color: #451a03e6;
}

.accent-amber-950\/95 {
  accent-color: #451a03f2;
}

.accent-emerald-100 {
  accent-color: #d1fae5;
}

.accent-emerald-100\/0 {
  accent-color: #d1fae500;
}

.accent-emerald-100\/10 {
  accent-color: #d1fae51a;
}

.accent-emerald-100\/100 {
  accent-color: #d1fae5;
}

.accent-emerald-100\/20 {
  accent-color: #d1fae533;
}

.accent-emerald-100\/25 {
  accent-color: #d1fae540;
}

.accent-emerald-100\/30 {
  accent-color: #d1fae54d;
}

.accent-emerald-100\/40 {
  accent-color: #d1fae566;
}

.accent-emerald-100\/5 {
  accent-color: #d1fae50d;
}

.accent-emerald-100\/50 {
  accent-color: #d1fae580;
}

.accent-emerald-100\/60 {
  accent-color: #d1fae599;
}

.accent-emerald-100\/70 {
  accent-color: #d1fae5b3;
}

.accent-emerald-100\/75 {
  accent-color: #d1fae5bf;
}

.accent-emerald-100\/80 {
  accent-color: #d1fae5cc;
}

.accent-emerald-100\/90 {
  accent-color: #d1fae5e6;
}

.accent-emerald-100\/95 {
  accent-color: #d1fae5f2;
}

.accent-emerald-200 {
  accent-color: #a7f3d0;
}

.accent-emerald-200\/0 {
  accent-color: #a7f3d000;
}

.accent-emerald-200\/10 {
  accent-color: #a7f3d01a;
}

.accent-emerald-200\/100 {
  accent-color: #a7f3d0;
}

.accent-emerald-200\/20 {
  accent-color: #a7f3d033;
}

.accent-emerald-200\/25 {
  accent-color: #a7f3d040;
}

.accent-emerald-200\/30 {
  accent-color: #a7f3d04d;
}

.accent-emerald-200\/40 {
  accent-color: #a7f3d066;
}

.accent-emerald-200\/5 {
  accent-color: #a7f3d00d;
}

.accent-emerald-200\/50 {
  accent-color: #a7f3d080;
}

.accent-emerald-200\/60 {
  accent-color: #a7f3d099;
}

.accent-emerald-200\/70 {
  accent-color: #a7f3d0b3;
}

.accent-emerald-200\/75 {
  accent-color: #a7f3d0bf;
}

.accent-emerald-200\/80 {
  accent-color: #a7f3d0cc;
}

.accent-emerald-200\/90 {
  accent-color: #a7f3d0e6;
}

.accent-emerald-200\/95 {
  accent-color: #a7f3d0f2;
}

.accent-emerald-300 {
  accent-color: #6ee7b7;
}

.accent-emerald-300\/0 {
  accent-color: #6ee7b700;
}

.accent-emerald-300\/10 {
  accent-color: #6ee7b71a;
}

.accent-emerald-300\/100 {
  accent-color: #6ee7b7;
}

.accent-emerald-300\/20 {
  accent-color: #6ee7b733;
}

.accent-emerald-300\/25 {
  accent-color: #6ee7b740;
}

.accent-emerald-300\/30 {
  accent-color: #6ee7b74d;
}

.accent-emerald-300\/40 {
  accent-color: #6ee7b766;
}

.accent-emerald-300\/5 {
  accent-color: #6ee7b70d;
}

.accent-emerald-300\/50 {
  accent-color: #6ee7b780;
}

.accent-emerald-300\/60 {
  accent-color: #6ee7b799;
}

.accent-emerald-300\/70 {
  accent-color: #6ee7b7b3;
}

.accent-emerald-300\/75 {
  accent-color: #6ee7b7bf;
}

.accent-emerald-300\/80 {
  accent-color: #6ee7b7cc;
}

.accent-emerald-300\/90 {
  accent-color: #6ee7b7e6;
}

.accent-emerald-300\/95 {
  accent-color: #6ee7b7f2;
}

.accent-emerald-400 {
  accent-color: #34d399;
}

.accent-emerald-400\/0 {
  accent-color: #34d39900;
}

.accent-emerald-400\/10 {
  accent-color: #34d3991a;
}

.accent-emerald-400\/100 {
  accent-color: #34d399;
}

.accent-emerald-400\/20 {
  accent-color: #34d39933;
}

.accent-emerald-400\/25 {
  accent-color: #34d39940;
}

.accent-emerald-400\/30 {
  accent-color: #34d3994d;
}

.accent-emerald-400\/40 {
  accent-color: #34d39966;
}

.accent-emerald-400\/5 {
  accent-color: #34d3990d;
}

.accent-emerald-400\/50 {
  accent-color: #34d39980;
}

.accent-emerald-400\/60 {
  accent-color: #34d39999;
}

.accent-emerald-400\/70 {
  accent-color: #34d399b3;
}

.accent-emerald-400\/75 {
  accent-color: #34d399bf;
}

.accent-emerald-400\/80 {
  accent-color: #34d399cc;
}

.accent-emerald-400\/90 {
  accent-color: #34d399e6;
}

.accent-emerald-400\/95 {
  accent-color: #34d399f2;
}

.accent-emerald-50 {
  accent-color: #ecfdf5;
}

.accent-emerald-50\/0 {
  accent-color: #ecfdf500;
}

.accent-emerald-50\/10 {
  accent-color: #ecfdf51a;
}

.accent-emerald-50\/100 {
  accent-color: #ecfdf5;
}

.accent-emerald-50\/20 {
  accent-color: #ecfdf533;
}

.accent-emerald-50\/25 {
  accent-color: #ecfdf540;
}

.accent-emerald-50\/30 {
  accent-color: #ecfdf54d;
}

.accent-emerald-50\/40 {
  accent-color: #ecfdf566;
}

.accent-emerald-50\/5 {
  accent-color: #ecfdf50d;
}

.accent-emerald-50\/50 {
  accent-color: #ecfdf580;
}

.accent-emerald-50\/60 {
  accent-color: #ecfdf599;
}

.accent-emerald-50\/70 {
  accent-color: #ecfdf5b3;
}

.accent-emerald-50\/75 {
  accent-color: #ecfdf5bf;
}

.accent-emerald-50\/80 {
  accent-color: #ecfdf5cc;
}

.accent-emerald-50\/90 {
  accent-color: #ecfdf5e6;
}

.accent-emerald-50\/95 {
  accent-color: #ecfdf5f2;
}

.accent-emerald-500 {
  accent-color: #10b981;
}

.accent-emerald-500\/0 {
  accent-color: #10b98100;
}

.accent-emerald-500\/10 {
  accent-color: #10b9811a;
}

.accent-emerald-500\/100 {
  accent-color: #10b981;
}

.accent-emerald-500\/20 {
  accent-color: #10b98133;
}

.accent-emerald-500\/25 {
  accent-color: #10b98140;
}

.accent-emerald-500\/30 {
  accent-color: #10b9814d;
}

.accent-emerald-500\/40 {
  accent-color: #10b98166;
}

.accent-emerald-500\/5 {
  accent-color: #10b9810d;
}

.accent-emerald-500\/50 {
  accent-color: #10b98180;
}

.accent-emerald-500\/60 {
  accent-color: #10b98199;
}

.accent-emerald-500\/70 {
  accent-color: #10b981b3;
}

.accent-emerald-500\/75 {
  accent-color: #10b981bf;
}

.accent-emerald-500\/80 {
  accent-color: #10b981cc;
}

.accent-emerald-500\/90 {
  accent-color: #10b981e6;
}

.accent-emerald-500\/95 {
  accent-color: #10b981f2;
}

.accent-emerald-600 {
  accent-color: #059669;
}

.accent-emerald-600\/0 {
  accent-color: #05966900;
}

.accent-emerald-600\/10 {
  accent-color: #0596691a;
}

.accent-emerald-600\/100 {
  accent-color: #059669;
}

.accent-emerald-600\/20 {
  accent-color: #05966933;
}

.accent-emerald-600\/25 {
  accent-color: #05966940;
}

.accent-emerald-600\/30 {
  accent-color: #0596694d;
}

.accent-emerald-600\/40 {
  accent-color: #05966966;
}

.accent-emerald-600\/5 {
  accent-color: #0596690d;
}

.accent-emerald-600\/50 {
  accent-color: #05966980;
}

.accent-emerald-600\/60 {
  accent-color: #05966999;
}

.accent-emerald-600\/70 {
  accent-color: #059669b3;
}

.accent-emerald-600\/75 {
  accent-color: #059669bf;
}

.accent-emerald-600\/80 {
  accent-color: #059669cc;
}

.accent-emerald-600\/90 {
  accent-color: #059669e6;
}

.accent-emerald-600\/95 {
  accent-color: #059669f2;
}

.accent-emerald-700 {
  accent-color: #047857;
}

.accent-emerald-700\/0 {
  accent-color: #04785700;
}

.accent-emerald-700\/10 {
  accent-color: #0478571a;
}

.accent-emerald-700\/100 {
  accent-color: #047857;
}

.accent-emerald-700\/20 {
  accent-color: #04785733;
}

.accent-emerald-700\/25 {
  accent-color: #04785740;
}

.accent-emerald-700\/30 {
  accent-color: #0478574d;
}

.accent-emerald-700\/40 {
  accent-color: #04785766;
}

.accent-emerald-700\/5 {
  accent-color: #0478570d;
}

.accent-emerald-700\/50 {
  accent-color: #04785780;
}

.accent-emerald-700\/60 {
  accent-color: #04785799;
}

.accent-emerald-700\/70 {
  accent-color: #047857b3;
}

.accent-emerald-700\/75 {
  accent-color: #047857bf;
}

.accent-emerald-700\/80 {
  accent-color: #047857cc;
}

.accent-emerald-700\/90 {
  accent-color: #047857e6;
}

.accent-emerald-700\/95 {
  accent-color: #047857f2;
}

.accent-emerald-800 {
  accent-color: #065f46;
}

.accent-emerald-800\/0 {
  accent-color: #065f4600;
}

.accent-emerald-800\/10 {
  accent-color: #065f461a;
}

.accent-emerald-800\/100 {
  accent-color: #065f46;
}

.accent-emerald-800\/20 {
  accent-color: #065f4633;
}

.accent-emerald-800\/25 {
  accent-color: #065f4640;
}

.accent-emerald-800\/30 {
  accent-color: #065f464d;
}

.accent-emerald-800\/40 {
  accent-color: #065f4666;
}

.accent-emerald-800\/5 {
  accent-color: #065f460d;
}

.accent-emerald-800\/50 {
  accent-color: #065f4680;
}

.accent-emerald-800\/60 {
  accent-color: #065f4699;
}

.accent-emerald-800\/70 {
  accent-color: #065f46b3;
}

.accent-emerald-800\/75 {
  accent-color: #065f46bf;
}

.accent-emerald-800\/80 {
  accent-color: #065f46cc;
}

.accent-emerald-800\/90 {
  accent-color: #065f46e6;
}

.accent-emerald-800\/95 {
  accent-color: #065f46f2;
}

.accent-emerald-900 {
  accent-color: #064e3b;
}

.accent-emerald-900\/0 {
  accent-color: #064e3b00;
}

.accent-emerald-900\/10 {
  accent-color: #064e3b1a;
}

.accent-emerald-900\/100 {
  accent-color: #064e3b;
}

.accent-emerald-900\/20 {
  accent-color: #064e3b33;
}

.accent-emerald-900\/25 {
  accent-color: #064e3b40;
}

.accent-emerald-900\/30 {
  accent-color: #064e3b4d;
}

.accent-emerald-900\/40 {
  accent-color: #064e3b66;
}

.accent-emerald-900\/5 {
  accent-color: #064e3b0d;
}

.accent-emerald-900\/50 {
  accent-color: #064e3b80;
}

.accent-emerald-900\/60 {
  accent-color: #064e3b99;
}

.accent-emerald-900\/70 {
  accent-color: #064e3bb3;
}

.accent-emerald-900\/75 {
  accent-color: #064e3bbf;
}

.accent-emerald-900\/80 {
  accent-color: #064e3bcc;
}

.accent-emerald-900\/90 {
  accent-color: #064e3be6;
}

.accent-emerald-900\/95 {
  accent-color: #064e3bf2;
}

.accent-emerald-950 {
  accent-color: #022c22;
}

.accent-emerald-950\/0 {
  accent-color: #022c2200;
}

.accent-emerald-950\/10 {
  accent-color: #022c221a;
}

.accent-emerald-950\/100 {
  accent-color: #022c22;
}

.accent-emerald-950\/20 {
  accent-color: #022c2233;
}

.accent-emerald-950\/25 {
  accent-color: #022c2240;
}

.accent-emerald-950\/30 {
  accent-color: #022c224d;
}

.accent-emerald-950\/40 {
  accent-color: #022c2266;
}

.accent-emerald-950\/5 {
  accent-color: #022c220d;
}

.accent-emerald-950\/50 {
  accent-color: #022c2280;
}

.accent-emerald-950\/60 {
  accent-color: #022c2299;
}

.accent-emerald-950\/70 {
  accent-color: #022c22b3;
}

.accent-emerald-950\/75 {
  accent-color: #022c22bf;
}

.accent-emerald-950\/80 {
  accent-color: #022c22cc;
}

.accent-emerald-950\/90 {
  accent-color: #022c22e6;
}

.accent-emerald-950\/95 {
  accent-color: #022c22f2;
}

.accent-lime-100 {
  accent-color: #ecfccb;
}

.accent-lime-100\/0 {
  accent-color: #ecfccb00;
}

.accent-lime-100\/10 {
  accent-color: #ecfccb1a;
}

.accent-lime-100\/100 {
  accent-color: #ecfccb;
}

.accent-lime-100\/20 {
  accent-color: #ecfccb33;
}

.accent-lime-100\/25 {
  accent-color: #ecfccb40;
}

.accent-lime-100\/30 {
  accent-color: #ecfccb4d;
}

.accent-lime-100\/40 {
  accent-color: #ecfccb66;
}

.accent-lime-100\/5 {
  accent-color: #ecfccb0d;
}

.accent-lime-100\/50 {
  accent-color: #ecfccb80;
}

.accent-lime-100\/60 {
  accent-color: #ecfccb99;
}

.accent-lime-100\/70 {
  accent-color: #ecfccbb3;
}

.accent-lime-100\/75 {
  accent-color: #ecfccbbf;
}

.accent-lime-100\/80 {
  accent-color: #ecfccbcc;
}

.accent-lime-100\/90 {
  accent-color: #ecfccbe6;
}

.accent-lime-100\/95 {
  accent-color: #ecfccbf2;
}

.accent-lime-200 {
  accent-color: #d9f99d;
}

.accent-lime-200\/0 {
  accent-color: #d9f99d00;
}

.accent-lime-200\/10 {
  accent-color: #d9f99d1a;
}

.accent-lime-200\/100 {
  accent-color: #d9f99d;
}

.accent-lime-200\/20 {
  accent-color: #d9f99d33;
}

.accent-lime-200\/25 {
  accent-color: #d9f99d40;
}

.accent-lime-200\/30 {
  accent-color: #d9f99d4d;
}

.accent-lime-200\/40 {
  accent-color: #d9f99d66;
}

.accent-lime-200\/5 {
  accent-color: #d9f99d0d;
}

.accent-lime-200\/50 {
  accent-color: #d9f99d80;
}

.accent-lime-200\/60 {
  accent-color: #d9f99d99;
}

.accent-lime-200\/70 {
  accent-color: #d9f99db3;
}

.accent-lime-200\/75 {
  accent-color: #d9f99dbf;
}

.accent-lime-200\/80 {
  accent-color: #d9f99dcc;
}

.accent-lime-200\/90 {
  accent-color: #d9f99de6;
}

.accent-lime-200\/95 {
  accent-color: #d9f99df2;
}

.accent-lime-300 {
  accent-color: #bef264;
}

.accent-lime-300\/0 {
  accent-color: #bef26400;
}

.accent-lime-300\/10 {
  accent-color: #bef2641a;
}

.accent-lime-300\/100 {
  accent-color: #bef264;
}

.accent-lime-300\/20 {
  accent-color: #bef26433;
}

.accent-lime-300\/25 {
  accent-color: #bef26440;
}

.accent-lime-300\/30 {
  accent-color: #bef2644d;
}

.accent-lime-300\/40 {
  accent-color: #bef26466;
}

.accent-lime-300\/5 {
  accent-color: #bef2640d;
}

.accent-lime-300\/50 {
  accent-color: #bef26480;
}

.accent-lime-300\/60 {
  accent-color: #bef26499;
}

.accent-lime-300\/70 {
  accent-color: #bef264b3;
}

.accent-lime-300\/75 {
  accent-color: #bef264bf;
}

.accent-lime-300\/80 {
  accent-color: #bef264cc;
}

.accent-lime-300\/90 {
  accent-color: #bef264e6;
}

.accent-lime-300\/95 {
  accent-color: #bef264f2;
}

.accent-lime-400 {
  accent-color: #a3e635;
}

.accent-lime-400\/0 {
  accent-color: #a3e63500;
}

.accent-lime-400\/10 {
  accent-color: #a3e6351a;
}

.accent-lime-400\/100 {
  accent-color: #a3e635;
}

.accent-lime-400\/20 {
  accent-color: #a3e63533;
}

.accent-lime-400\/25 {
  accent-color: #a3e63540;
}

.accent-lime-400\/30 {
  accent-color: #a3e6354d;
}

.accent-lime-400\/40 {
  accent-color: #a3e63566;
}

.accent-lime-400\/5 {
  accent-color: #a3e6350d;
}

.accent-lime-400\/50 {
  accent-color: #a3e63580;
}

.accent-lime-400\/60 {
  accent-color: #a3e63599;
}

.accent-lime-400\/70 {
  accent-color: #a3e635b3;
}

.accent-lime-400\/75 {
  accent-color: #a3e635bf;
}

.accent-lime-400\/80 {
  accent-color: #a3e635cc;
}

.accent-lime-400\/90 {
  accent-color: #a3e635e6;
}

.accent-lime-400\/95 {
  accent-color: #a3e635f2;
}

.accent-lime-50 {
  accent-color: #f7fee7;
}

.accent-lime-50\/0 {
  accent-color: #f7fee700;
}

.accent-lime-50\/10 {
  accent-color: #f7fee71a;
}

.accent-lime-50\/100 {
  accent-color: #f7fee7;
}

.accent-lime-50\/20 {
  accent-color: #f7fee733;
}

.accent-lime-50\/25 {
  accent-color: #f7fee740;
}

.accent-lime-50\/30 {
  accent-color: #f7fee74d;
}

.accent-lime-50\/40 {
  accent-color: #f7fee766;
}

.accent-lime-50\/5 {
  accent-color: #f7fee70d;
}

.accent-lime-50\/50 {
  accent-color: #f7fee780;
}

.accent-lime-50\/60 {
  accent-color: #f7fee799;
}

.accent-lime-50\/70 {
  accent-color: #f7fee7b3;
}

.accent-lime-50\/75 {
  accent-color: #f7fee7bf;
}

.accent-lime-50\/80 {
  accent-color: #f7fee7cc;
}

.accent-lime-50\/90 {
  accent-color: #f7fee7e6;
}

.accent-lime-50\/95 {
  accent-color: #f7fee7f2;
}

.accent-lime-500 {
  accent-color: #84cc16;
}

.accent-lime-500\/0 {
  accent-color: #84cc1600;
}

.accent-lime-500\/10 {
  accent-color: #84cc161a;
}

.accent-lime-500\/100 {
  accent-color: #84cc16;
}

.accent-lime-500\/20 {
  accent-color: #84cc1633;
}

.accent-lime-500\/25 {
  accent-color: #84cc1640;
}

.accent-lime-500\/30 {
  accent-color: #84cc164d;
}

.accent-lime-500\/40 {
  accent-color: #84cc1666;
}

.accent-lime-500\/5 {
  accent-color: #84cc160d;
}

.accent-lime-500\/50 {
  accent-color: #84cc1680;
}

.accent-lime-500\/60 {
  accent-color: #84cc1699;
}

.accent-lime-500\/70 {
  accent-color: #84cc16b3;
}

.accent-lime-500\/75 {
  accent-color: #84cc16bf;
}

.accent-lime-500\/80 {
  accent-color: #84cc16cc;
}

.accent-lime-500\/90 {
  accent-color: #84cc16e6;
}

.accent-lime-500\/95 {
  accent-color: #84cc16f2;
}

.accent-lime-600 {
  accent-color: #65a30d;
}

.accent-lime-600\/0 {
  accent-color: #65a30d00;
}

.accent-lime-600\/10 {
  accent-color: #65a30d1a;
}

.accent-lime-600\/100 {
  accent-color: #65a30d;
}

.accent-lime-600\/20 {
  accent-color: #65a30d33;
}

.accent-lime-600\/25 {
  accent-color: #65a30d40;
}

.accent-lime-600\/30 {
  accent-color: #65a30d4d;
}

.accent-lime-600\/40 {
  accent-color: #65a30d66;
}

.accent-lime-600\/5 {
  accent-color: #65a30d0d;
}

.accent-lime-600\/50 {
  accent-color: #65a30d80;
}

.accent-lime-600\/60 {
  accent-color: #65a30d99;
}

.accent-lime-600\/70 {
  accent-color: #65a30db3;
}

.accent-lime-600\/75 {
  accent-color: #65a30dbf;
}

.accent-lime-600\/80 {
  accent-color: #65a30dcc;
}

.accent-lime-600\/90 {
  accent-color: #65a30de6;
}

.accent-lime-600\/95 {
  accent-color: #65a30df2;
}

.accent-lime-700 {
  accent-color: #4d7c0f;
}

.accent-lime-700\/0 {
  accent-color: #4d7c0f00;
}

.accent-lime-700\/10 {
  accent-color: #4d7c0f1a;
}

.accent-lime-700\/100 {
  accent-color: #4d7c0f;
}

.accent-lime-700\/20 {
  accent-color: #4d7c0f33;
}

.accent-lime-700\/25 {
  accent-color: #4d7c0f40;
}

.accent-lime-700\/30 {
  accent-color: #4d7c0f4d;
}

.accent-lime-700\/40 {
  accent-color: #4d7c0f66;
}

.accent-lime-700\/5 {
  accent-color: #4d7c0f0d;
}

.accent-lime-700\/50 {
  accent-color: #4d7c0f80;
}

.accent-lime-700\/60 {
  accent-color: #4d7c0f99;
}

.accent-lime-700\/70 {
  accent-color: #4d7c0fb3;
}

.accent-lime-700\/75 {
  accent-color: #4d7c0fbf;
}

.accent-lime-700\/80 {
  accent-color: #4d7c0fcc;
}

.accent-lime-700\/90 {
  accent-color: #4d7c0fe6;
}

.accent-lime-700\/95 {
  accent-color: #4d7c0ff2;
}

.accent-lime-800 {
  accent-color: #3f6212;
}

.accent-lime-800\/0 {
  accent-color: #3f621200;
}

.accent-lime-800\/10 {
  accent-color: #3f62121a;
}

.accent-lime-800\/100 {
  accent-color: #3f6212;
}

.accent-lime-800\/20 {
  accent-color: #3f621233;
}

.accent-lime-800\/25 {
  accent-color: #3f621240;
}

.accent-lime-800\/30 {
  accent-color: #3f62124d;
}

.accent-lime-800\/40 {
  accent-color: #3f621266;
}

.accent-lime-800\/5 {
  accent-color: #3f62120d;
}

.accent-lime-800\/50 {
  accent-color: #3f621280;
}

.accent-lime-800\/60 {
  accent-color: #3f621299;
}

.accent-lime-800\/70 {
  accent-color: #3f6212b3;
}

.accent-lime-800\/75 {
  accent-color: #3f6212bf;
}

.accent-lime-800\/80 {
  accent-color: #3f6212cc;
}

.accent-lime-800\/90 {
  accent-color: #3f6212e6;
}

.accent-lime-800\/95 {
  accent-color: #3f6212f2;
}

.accent-lime-900 {
  accent-color: #365314;
}

.accent-lime-900\/0 {
  accent-color: #36531400;
}

.accent-lime-900\/10 {
  accent-color: #3653141a;
}

.accent-lime-900\/100 {
  accent-color: #365314;
}

.accent-lime-900\/20 {
  accent-color: #36531433;
}

.accent-lime-900\/25 {
  accent-color: #36531440;
}

.accent-lime-900\/30 {
  accent-color: #3653144d;
}

.accent-lime-900\/40 {
  accent-color: #36531466;
}

.accent-lime-900\/5 {
  accent-color: #3653140d;
}

.accent-lime-900\/50 {
  accent-color: #36531480;
}

.accent-lime-900\/60 {
  accent-color: #36531499;
}

.accent-lime-900\/70 {
  accent-color: #365314b3;
}

.accent-lime-900\/75 {
  accent-color: #365314bf;
}

.accent-lime-900\/80 {
  accent-color: #365314cc;
}

.accent-lime-900\/90 {
  accent-color: #365314e6;
}

.accent-lime-900\/95 {
  accent-color: #365314f2;
}

.accent-lime-950 {
  accent-color: #1a2e05;
}

.accent-lime-950\/0 {
  accent-color: #1a2e0500;
}

.accent-lime-950\/10 {
  accent-color: #1a2e051a;
}

.accent-lime-950\/100 {
  accent-color: #1a2e05;
}

.accent-lime-950\/20 {
  accent-color: #1a2e0533;
}

.accent-lime-950\/25 {
  accent-color: #1a2e0540;
}

.accent-lime-950\/30 {
  accent-color: #1a2e054d;
}

.accent-lime-950\/40 {
  accent-color: #1a2e0566;
}

.accent-lime-950\/5 {
  accent-color: #1a2e050d;
}

.accent-lime-950\/50 {
  accent-color: #1a2e0580;
}

.accent-lime-950\/60 {
  accent-color: #1a2e0599;
}

.accent-lime-950\/70 {
  accent-color: #1a2e05b3;
}

.accent-lime-950\/75 {
  accent-color: #1a2e05bf;
}

.accent-lime-950\/80 {
  accent-color: #1a2e05cc;
}

.accent-lime-950\/90 {
  accent-color: #1a2e05e6;
}

.accent-lime-950\/95 {
  accent-color: #1a2e05f2;
}

.accent-pink-100 {
  accent-color: #fce7f3;
}

.accent-pink-100\/0 {
  accent-color: #fce7f300;
}

.accent-pink-100\/10 {
  accent-color: #fce7f31a;
}

.accent-pink-100\/100 {
  accent-color: #fce7f3;
}

.accent-pink-100\/20 {
  accent-color: #fce7f333;
}

.accent-pink-100\/25 {
  accent-color: #fce7f340;
}

.accent-pink-100\/30 {
  accent-color: #fce7f34d;
}

.accent-pink-100\/40 {
  accent-color: #fce7f366;
}

.accent-pink-100\/5 {
  accent-color: #fce7f30d;
}

.accent-pink-100\/50 {
  accent-color: #fce7f380;
}

.accent-pink-100\/60 {
  accent-color: #fce7f399;
}

.accent-pink-100\/70 {
  accent-color: #fce7f3b3;
}

.accent-pink-100\/75 {
  accent-color: #fce7f3bf;
}

.accent-pink-100\/80 {
  accent-color: #fce7f3cc;
}

.accent-pink-100\/90 {
  accent-color: #fce7f3e6;
}

.accent-pink-100\/95 {
  accent-color: #fce7f3f2;
}

.accent-pink-200 {
  accent-color: #fbcfe8;
}

.accent-pink-200\/0 {
  accent-color: #fbcfe800;
}

.accent-pink-200\/10 {
  accent-color: #fbcfe81a;
}

.accent-pink-200\/100 {
  accent-color: #fbcfe8;
}

.accent-pink-200\/20 {
  accent-color: #fbcfe833;
}

.accent-pink-200\/25 {
  accent-color: #fbcfe840;
}

.accent-pink-200\/30 {
  accent-color: #fbcfe84d;
}

.accent-pink-200\/40 {
  accent-color: #fbcfe866;
}

.accent-pink-200\/5 {
  accent-color: #fbcfe80d;
}

.accent-pink-200\/50 {
  accent-color: #fbcfe880;
}

.accent-pink-200\/60 {
  accent-color: #fbcfe899;
}

.accent-pink-200\/70 {
  accent-color: #fbcfe8b3;
}

.accent-pink-200\/75 {
  accent-color: #fbcfe8bf;
}

.accent-pink-200\/80 {
  accent-color: #fbcfe8cc;
}

.accent-pink-200\/90 {
  accent-color: #fbcfe8e6;
}

.accent-pink-200\/95 {
  accent-color: #fbcfe8f2;
}

.accent-pink-300 {
  accent-color: #f9a8d4;
}

.accent-pink-300\/0 {
  accent-color: #f9a8d400;
}

.accent-pink-300\/10 {
  accent-color: #f9a8d41a;
}

.accent-pink-300\/100 {
  accent-color: #f9a8d4;
}

.accent-pink-300\/20 {
  accent-color: #f9a8d433;
}

.accent-pink-300\/25 {
  accent-color: #f9a8d440;
}

.accent-pink-300\/30 {
  accent-color: #f9a8d44d;
}

.accent-pink-300\/40 {
  accent-color: #f9a8d466;
}

.accent-pink-300\/5 {
  accent-color: #f9a8d40d;
}

.accent-pink-300\/50 {
  accent-color: #f9a8d480;
}

.accent-pink-300\/60 {
  accent-color: #f9a8d499;
}

.accent-pink-300\/70 {
  accent-color: #f9a8d4b3;
}

.accent-pink-300\/75 {
  accent-color: #f9a8d4bf;
}

.accent-pink-300\/80 {
  accent-color: #f9a8d4cc;
}

.accent-pink-300\/90 {
  accent-color: #f9a8d4e6;
}

.accent-pink-300\/95 {
  accent-color: #f9a8d4f2;
}

.accent-pink-400 {
  accent-color: #f472b6;
}

.accent-pink-400\/0 {
  accent-color: #f472b600;
}

.accent-pink-400\/10 {
  accent-color: #f472b61a;
}

.accent-pink-400\/100 {
  accent-color: #f472b6;
}

.accent-pink-400\/20 {
  accent-color: #f472b633;
}

.accent-pink-400\/25 {
  accent-color: #f472b640;
}

.accent-pink-400\/30 {
  accent-color: #f472b64d;
}

.accent-pink-400\/40 {
  accent-color: #f472b666;
}

.accent-pink-400\/5 {
  accent-color: #f472b60d;
}

.accent-pink-400\/50 {
  accent-color: #f472b680;
}

.accent-pink-400\/60 {
  accent-color: #f472b699;
}

.accent-pink-400\/70 {
  accent-color: #f472b6b3;
}

.accent-pink-400\/75 {
  accent-color: #f472b6bf;
}

.accent-pink-400\/80 {
  accent-color: #f472b6cc;
}

.accent-pink-400\/90 {
  accent-color: #f472b6e6;
}

.accent-pink-400\/95 {
  accent-color: #f472b6f2;
}

.accent-pink-50 {
  accent-color: #fdf2f8;
}

.accent-pink-50\/0 {
  accent-color: #fdf2f800;
}

.accent-pink-50\/10 {
  accent-color: #fdf2f81a;
}

.accent-pink-50\/100 {
  accent-color: #fdf2f8;
}

.accent-pink-50\/20 {
  accent-color: #fdf2f833;
}

.accent-pink-50\/25 {
  accent-color: #fdf2f840;
}

.accent-pink-50\/30 {
  accent-color: #fdf2f84d;
}

.accent-pink-50\/40 {
  accent-color: #fdf2f866;
}

.accent-pink-50\/5 {
  accent-color: #fdf2f80d;
}

.accent-pink-50\/50 {
  accent-color: #fdf2f880;
}

.accent-pink-50\/60 {
  accent-color: #fdf2f899;
}

.accent-pink-50\/70 {
  accent-color: #fdf2f8b3;
}

.accent-pink-50\/75 {
  accent-color: #fdf2f8bf;
}

.accent-pink-50\/80 {
  accent-color: #fdf2f8cc;
}

.accent-pink-50\/90 {
  accent-color: #fdf2f8e6;
}

.accent-pink-50\/95 {
  accent-color: #fdf2f8f2;
}

.accent-pink-500 {
  accent-color: #ec4899;
}

.accent-pink-500\/0 {
  accent-color: #ec489900;
}

.accent-pink-500\/10 {
  accent-color: #ec48991a;
}

.accent-pink-500\/100 {
  accent-color: #ec4899;
}

.accent-pink-500\/20 {
  accent-color: #ec489933;
}

.accent-pink-500\/25 {
  accent-color: #ec489940;
}

.accent-pink-500\/30 {
  accent-color: #ec48994d;
}

.accent-pink-500\/40 {
  accent-color: #ec489966;
}

.accent-pink-500\/5 {
  accent-color: #ec48990d;
}

.accent-pink-500\/50 {
  accent-color: #ec489980;
}

.accent-pink-500\/60 {
  accent-color: #ec489999;
}

.accent-pink-500\/70 {
  accent-color: #ec4899b3;
}

.accent-pink-500\/75 {
  accent-color: #ec4899bf;
}

.accent-pink-500\/80 {
  accent-color: #ec4899cc;
}

.accent-pink-500\/90 {
  accent-color: #ec4899e6;
}

.accent-pink-500\/95 {
  accent-color: #ec4899f2;
}

.accent-pink-600 {
  accent-color: #db2777;
}

.accent-pink-600\/0 {
  accent-color: #db277700;
}

.accent-pink-600\/10 {
  accent-color: #db27771a;
}

.accent-pink-600\/100 {
  accent-color: #db2777;
}

.accent-pink-600\/20 {
  accent-color: #db277733;
}

.accent-pink-600\/25 {
  accent-color: #db277740;
}

.accent-pink-600\/30 {
  accent-color: #db27774d;
}

.accent-pink-600\/40 {
  accent-color: #db277766;
}

.accent-pink-600\/5 {
  accent-color: #db27770d;
}

.accent-pink-600\/50 {
  accent-color: #db277780;
}

.accent-pink-600\/60 {
  accent-color: #db277799;
}

.accent-pink-600\/70 {
  accent-color: #db2777b3;
}

.accent-pink-600\/75 {
  accent-color: #db2777bf;
}

.accent-pink-600\/80 {
  accent-color: #db2777cc;
}

.accent-pink-600\/90 {
  accent-color: #db2777e6;
}

.accent-pink-600\/95 {
  accent-color: #db2777f2;
}

.accent-pink-700 {
  accent-color: #be185d;
}

.accent-pink-700\/0 {
  accent-color: #be185d00;
}

.accent-pink-700\/10 {
  accent-color: #be185d1a;
}

.accent-pink-700\/100 {
  accent-color: #be185d;
}

.accent-pink-700\/20 {
  accent-color: #be185d33;
}

.accent-pink-700\/25 {
  accent-color: #be185d40;
}

.accent-pink-700\/30 {
  accent-color: #be185d4d;
}

.accent-pink-700\/40 {
  accent-color: #be185d66;
}

.accent-pink-700\/5 {
  accent-color: #be185d0d;
}

.accent-pink-700\/50 {
  accent-color: #be185d80;
}

.accent-pink-700\/60 {
  accent-color: #be185d99;
}

.accent-pink-700\/70 {
  accent-color: #be185db3;
}

.accent-pink-700\/75 {
  accent-color: #be185dbf;
}

.accent-pink-700\/80 {
  accent-color: #be185dcc;
}

.accent-pink-700\/90 {
  accent-color: #be185de6;
}

.accent-pink-700\/95 {
  accent-color: #be185df2;
}

.accent-pink-800 {
  accent-color: #9d174d;
}

.accent-pink-800\/0 {
  accent-color: #9d174d00;
}

.accent-pink-800\/10 {
  accent-color: #9d174d1a;
}

.accent-pink-800\/100 {
  accent-color: #9d174d;
}

.accent-pink-800\/20 {
  accent-color: #9d174d33;
}

.accent-pink-800\/25 {
  accent-color: #9d174d40;
}

.accent-pink-800\/30 {
  accent-color: #9d174d4d;
}

.accent-pink-800\/40 {
  accent-color: #9d174d66;
}

.accent-pink-800\/5 {
  accent-color: #9d174d0d;
}

.accent-pink-800\/50 {
  accent-color: #9d174d80;
}

.accent-pink-800\/60 {
  accent-color: #9d174d99;
}

.accent-pink-800\/70 {
  accent-color: #9d174db3;
}

.accent-pink-800\/75 {
  accent-color: #9d174dbf;
}

.accent-pink-800\/80 {
  accent-color: #9d174dcc;
}

.accent-pink-800\/90 {
  accent-color: #9d174de6;
}

.accent-pink-800\/95 {
  accent-color: #9d174df2;
}

.accent-pink-900 {
  accent-color: #831843;
}

.accent-pink-900\/0 {
  accent-color: #83184300;
}

.accent-pink-900\/10 {
  accent-color: #8318431a;
}

.accent-pink-900\/100 {
  accent-color: #831843;
}

.accent-pink-900\/20 {
  accent-color: #83184333;
}

.accent-pink-900\/25 {
  accent-color: #83184340;
}

.accent-pink-900\/30 {
  accent-color: #8318434d;
}

.accent-pink-900\/40 {
  accent-color: #83184366;
}

.accent-pink-900\/5 {
  accent-color: #8318430d;
}

.accent-pink-900\/50 {
  accent-color: #83184380;
}

.accent-pink-900\/60 {
  accent-color: #83184399;
}

.accent-pink-900\/70 {
  accent-color: #831843b3;
}

.accent-pink-900\/75 {
  accent-color: #831843bf;
}

.accent-pink-900\/80 {
  accent-color: #831843cc;
}

.accent-pink-900\/90 {
  accent-color: #831843e6;
}

.accent-pink-900\/95 {
  accent-color: #831843f2;
}

.accent-pink-950 {
  accent-color: #500724;
}

.accent-pink-950\/0 {
  accent-color: #50072400;
}

.accent-pink-950\/10 {
  accent-color: #5007241a;
}

.accent-pink-950\/100 {
  accent-color: #500724;
}

.accent-pink-950\/20 {
  accent-color: #50072433;
}

.accent-pink-950\/25 {
  accent-color: #50072440;
}

.accent-pink-950\/30 {
  accent-color: #5007244d;
}

.accent-pink-950\/40 {
  accent-color: #50072466;
}

.accent-pink-950\/5 {
  accent-color: #5007240d;
}

.accent-pink-950\/50 {
  accent-color: #50072480;
}

.accent-pink-950\/60 {
  accent-color: #50072499;
}

.accent-pink-950\/70 {
  accent-color: #500724b3;
}

.accent-pink-950\/75 {
  accent-color: #500724bf;
}

.accent-pink-950\/80 {
  accent-color: #500724cc;
}

.accent-pink-950\/90 {
  accent-color: #500724e6;
}

.accent-pink-950\/95 {
  accent-color: #500724f2;
}

.accent-primary {
  accent-color: hsl(var(--primary) / 1);
}

.accent-primary\/0 {
  accent-color: hsl(var(--primary) / 0);
}

.accent-primary\/10 {
  accent-color: hsl(var(--primary) / .1);
}

.accent-primary\/100 {
  accent-color: hsl(var(--primary) / 1);
}

.accent-primary\/20 {
  accent-color: hsl(var(--primary) / .2);
}

.accent-primary\/25 {
  accent-color: hsl(var(--primary) / .25);
}

.accent-primary\/30 {
  accent-color: hsl(var(--primary) / .3);
}

.accent-primary\/40 {
  accent-color: hsl(var(--primary) / .4);
}

.accent-primary\/5 {
  accent-color: hsl(var(--primary) / .05);
}

.accent-primary\/50 {
  accent-color: hsl(var(--primary) / .5);
}

.accent-primary\/60 {
  accent-color: hsl(var(--primary) / .6);
}

.accent-primary\/70 {
  accent-color: hsl(var(--primary) / .7);
}

.accent-primary\/75 {
  accent-color: hsl(var(--primary) / .75);
}

.accent-primary\/80 {
  accent-color: hsl(var(--primary) / .8);
}

.accent-primary\/90 {
  accent-color: hsl(var(--primary) / .9);
}

.accent-primary\/95 {
  accent-color: hsl(var(--primary) / .95);
}

.accent-purple-100 {
  accent-color: #f3e8ff;
}

.accent-purple-100\/0 {
  accent-color: #f3e8ff00;
}

.accent-purple-100\/10 {
  accent-color: #f3e8ff1a;
}

.accent-purple-100\/100 {
  accent-color: #f3e8ff;
}

.accent-purple-100\/20 {
  accent-color: #f3e8ff33;
}

.accent-purple-100\/25 {
  accent-color: #f3e8ff40;
}

.accent-purple-100\/30 {
  accent-color: #f3e8ff4d;
}

.accent-purple-100\/40 {
  accent-color: #f3e8ff66;
}

.accent-purple-100\/5 {
  accent-color: #f3e8ff0d;
}

.accent-purple-100\/50 {
  accent-color: #f3e8ff80;
}

.accent-purple-100\/60 {
  accent-color: #f3e8ff99;
}

.accent-purple-100\/70 {
  accent-color: #f3e8ffb3;
}

.accent-purple-100\/75 {
  accent-color: #f3e8ffbf;
}

.accent-purple-100\/80 {
  accent-color: #f3e8ffcc;
}

.accent-purple-100\/90 {
  accent-color: #f3e8ffe6;
}

.accent-purple-100\/95 {
  accent-color: #f3e8fff2;
}

.accent-purple-200 {
  accent-color: #e9d5ff;
}

.accent-purple-200\/0 {
  accent-color: #e9d5ff00;
}

.accent-purple-200\/10 {
  accent-color: #e9d5ff1a;
}

.accent-purple-200\/100 {
  accent-color: #e9d5ff;
}

.accent-purple-200\/20 {
  accent-color: #e9d5ff33;
}

.accent-purple-200\/25 {
  accent-color: #e9d5ff40;
}

.accent-purple-200\/30 {
  accent-color: #e9d5ff4d;
}

.accent-purple-200\/40 {
  accent-color: #e9d5ff66;
}

.accent-purple-200\/5 {
  accent-color: #e9d5ff0d;
}

.accent-purple-200\/50 {
  accent-color: #e9d5ff80;
}

.accent-purple-200\/60 {
  accent-color: #e9d5ff99;
}

.accent-purple-200\/70 {
  accent-color: #e9d5ffb3;
}

.accent-purple-200\/75 {
  accent-color: #e9d5ffbf;
}

.accent-purple-200\/80 {
  accent-color: #e9d5ffcc;
}

.accent-purple-200\/90 {
  accent-color: #e9d5ffe6;
}

.accent-purple-200\/95 {
  accent-color: #e9d5fff2;
}

.accent-purple-300 {
  accent-color: #d8b4fe;
}

.accent-purple-300\/0 {
  accent-color: #d8b4fe00;
}

.accent-purple-300\/10 {
  accent-color: #d8b4fe1a;
}

.accent-purple-300\/100 {
  accent-color: #d8b4fe;
}

.accent-purple-300\/20 {
  accent-color: #d8b4fe33;
}

.accent-purple-300\/25 {
  accent-color: #d8b4fe40;
}

.accent-purple-300\/30 {
  accent-color: #d8b4fe4d;
}

.accent-purple-300\/40 {
  accent-color: #d8b4fe66;
}

.accent-purple-300\/5 {
  accent-color: #d8b4fe0d;
}

.accent-purple-300\/50 {
  accent-color: #d8b4fe80;
}

.accent-purple-300\/60 {
  accent-color: #d8b4fe99;
}

.accent-purple-300\/70 {
  accent-color: #d8b4feb3;
}

.accent-purple-300\/75 {
  accent-color: #d8b4febf;
}

.accent-purple-300\/80 {
  accent-color: #d8b4fecc;
}

.accent-purple-300\/90 {
  accent-color: #d8b4fee6;
}

.accent-purple-300\/95 {
  accent-color: #d8b4fef2;
}

.accent-purple-400 {
  accent-color: #c084fc;
}

.accent-purple-400\/0 {
  accent-color: #c084fc00;
}

.accent-purple-400\/10 {
  accent-color: #c084fc1a;
}

.accent-purple-400\/100 {
  accent-color: #c084fc;
}

.accent-purple-400\/20 {
  accent-color: #c084fc33;
}

.accent-purple-400\/25 {
  accent-color: #c084fc40;
}

.accent-purple-400\/30 {
  accent-color: #c084fc4d;
}

.accent-purple-400\/40 {
  accent-color: #c084fc66;
}

.accent-purple-400\/5 {
  accent-color: #c084fc0d;
}

.accent-purple-400\/50 {
  accent-color: #c084fc80;
}

.accent-purple-400\/60 {
  accent-color: #c084fc99;
}

.accent-purple-400\/70 {
  accent-color: #c084fcb3;
}

.accent-purple-400\/75 {
  accent-color: #c084fcbf;
}

.accent-purple-400\/80 {
  accent-color: #c084fccc;
}

.accent-purple-400\/90 {
  accent-color: #c084fce6;
}

.accent-purple-400\/95 {
  accent-color: #c084fcf2;
}

.accent-purple-50 {
  accent-color: #faf5ff;
}

.accent-purple-50\/0 {
  accent-color: #faf5ff00;
}

.accent-purple-50\/10 {
  accent-color: #faf5ff1a;
}

.accent-purple-50\/100 {
  accent-color: #faf5ff;
}

.accent-purple-50\/20 {
  accent-color: #faf5ff33;
}

.accent-purple-50\/25 {
  accent-color: #faf5ff40;
}

.accent-purple-50\/30 {
  accent-color: #faf5ff4d;
}

.accent-purple-50\/40 {
  accent-color: #faf5ff66;
}

.accent-purple-50\/5 {
  accent-color: #faf5ff0d;
}

.accent-purple-50\/50 {
  accent-color: #faf5ff80;
}

.accent-purple-50\/60 {
  accent-color: #faf5ff99;
}

.accent-purple-50\/70 {
  accent-color: #faf5ffb3;
}

.accent-purple-50\/75 {
  accent-color: #faf5ffbf;
}

.accent-purple-50\/80 {
  accent-color: #faf5ffcc;
}

.accent-purple-50\/90 {
  accent-color: #faf5ffe6;
}

.accent-purple-50\/95 {
  accent-color: #faf5fff2;
}

.accent-purple-500 {
  accent-color: #a855f7;
}

.accent-purple-500\/0 {
  accent-color: #a855f700;
}

.accent-purple-500\/10 {
  accent-color: #a855f71a;
}

.accent-purple-500\/100 {
  accent-color: #a855f7;
}

.accent-purple-500\/20 {
  accent-color: #a855f733;
}

.accent-purple-500\/25 {
  accent-color: #a855f740;
}

.accent-purple-500\/30 {
  accent-color: #a855f74d;
}

.accent-purple-500\/40 {
  accent-color: #a855f766;
}

.accent-purple-500\/5 {
  accent-color: #a855f70d;
}

.accent-purple-500\/50 {
  accent-color: #a855f780;
}

.accent-purple-500\/60 {
  accent-color: #a855f799;
}

.accent-purple-500\/70 {
  accent-color: #a855f7b3;
}

.accent-purple-500\/75 {
  accent-color: #a855f7bf;
}

.accent-purple-500\/80 {
  accent-color: #a855f7cc;
}

.accent-purple-500\/90 {
  accent-color: #a855f7e6;
}

.accent-purple-500\/95 {
  accent-color: #a855f7f2;
}

.accent-purple-600 {
  accent-color: #9333ea;
}

.accent-purple-600\/0 {
  accent-color: #9333ea00;
}

.accent-purple-600\/10 {
  accent-color: #9333ea1a;
}

.accent-purple-600\/100 {
  accent-color: #9333ea;
}

.accent-purple-600\/20 {
  accent-color: #9333ea33;
}

.accent-purple-600\/25 {
  accent-color: #9333ea40;
}

.accent-purple-600\/30 {
  accent-color: #9333ea4d;
}

.accent-purple-600\/40 {
  accent-color: #9333ea66;
}

.accent-purple-600\/5 {
  accent-color: #9333ea0d;
}

.accent-purple-600\/50 {
  accent-color: #9333ea80;
}

.accent-purple-600\/60 {
  accent-color: #9333ea99;
}

.accent-purple-600\/70 {
  accent-color: #9333eab3;
}

.accent-purple-600\/75 {
  accent-color: #9333eabf;
}

.accent-purple-600\/80 {
  accent-color: #9333eacc;
}

.accent-purple-600\/90 {
  accent-color: #9333eae6;
}

.accent-purple-600\/95 {
  accent-color: #9333eaf2;
}

.accent-purple-700 {
  accent-color: #7e22ce;
}

.accent-purple-700\/0 {
  accent-color: #7e22ce00;
}

.accent-purple-700\/10 {
  accent-color: #7e22ce1a;
}

.accent-purple-700\/100 {
  accent-color: #7e22ce;
}

.accent-purple-700\/20 {
  accent-color: #7e22ce33;
}

.accent-purple-700\/25 {
  accent-color: #7e22ce40;
}

.accent-purple-700\/30 {
  accent-color: #7e22ce4d;
}

.accent-purple-700\/40 {
  accent-color: #7e22ce66;
}

.accent-purple-700\/5 {
  accent-color: #7e22ce0d;
}

.accent-purple-700\/50 {
  accent-color: #7e22ce80;
}

.accent-purple-700\/60 {
  accent-color: #7e22ce99;
}

.accent-purple-700\/70 {
  accent-color: #7e22ceb3;
}

.accent-purple-700\/75 {
  accent-color: #7e22cebf;
}

.accent-purple-700\/80 {
  accent-color: #7e22cecc;
}

.accent-purple-700\/90 {
  accent-color: #7e22cee6;
}

.accent-purple-700\/95 {
  accent-color: #7e22cef2;
}

.accent-purple-800 {
  accent-color: #6b21a8;
}

.accent-purple-800\/0 {
  accent-color: #6b21a800;
}

.accent-purple-800\/10 {
  accent-color: #6b21a81a;
}

.accent-purple-800\/100 {
  accent-color: #6b21a8;
}

.accent-purple-800\/20 {
  accent-color: #6b21a833;
}

.accent-purple-800\/25 {
  accent-color: #6b21a840;
}

.accent-purple-800\/30 {
  accent-color: #6b21a84d;
}

.accent-purple-800\/40 {
  accent-color: #6b21a866;
}

.accent-purple-800\/5 {
  accent-color: #6b21a80d;
}

.accent-purple-800\/50 {
  accent-color: #6b21a880;
}

.accent-purple-800\/60 {
  accent-color: #6b21a899;
}

.accent-purple-800\/70 {
  accent-color: #6b21a8b3;
}

.accent-purple-800\/75 {
  accent-color: #6b21a8bf;
}

.accent-purple-800\/80 {
  accent-color: #6b21a8cc;
}

.accent-purple-800\/90 {
  accent-color: #6b21a8e6;
}

.accent-purple-800\/95 {
  accent-color: #6b21a8f2;
}

.accent-purple-900 {
  accent-color: #581c87;
}

.accent-purple-900\/0 {
  accent-color: #581c8700;
}

.accent-purple-900\/10 {
  accent-color: #581c871a;
}

.accent-purple-900\/100 {
  accent-color: #581c87;
}

.accent-purple-900\/20 {
  accent-color: #581c8733;
}

.accent-purple-900\/25 {
  accent-color: #581c8740;
}

.accent-purple-900\/30 {
  accent-color: #581c874d;
}

.accent-purple-900\/40 {
  accent-color: #581c8766;
}

.accent-purple-900\/5 {
  accent-color: #581c870d;
}

.accent-purple-900\/50 {
  accent-color: #581c8780;
}

.accent-purple-900\/60 {
  accent-color: #581c8799;
}

.accent-purple-900\/70 {
  accent-color: #581c87b3;
}

.accent-purple-900\/75 {
  accent-color: #581c87bf;
}

.accent-purple-900\/80 {
  accent-color: #581c87cc;
}

.accent-purple-900\/90 {
  accent-color: #581c87e6;
}

.accent-purple-900\/95 {
  accent-color: #581c87f2;
}

.accent-purple-950 {
  accent-color: #3b0764;
}

.accent-purple-950\/0 {
  accent-color: #3b076400;
}

.accent-purple-950\/10 {
  accent-color: #3b07641a;
}

.accent-purple-950\/100 {
  accent-color: #3b0764;
}

.accent-purple-950\/20 {
  accent-color: #3b076433;
}

.accent-purple-950\/25 {
  accent-color: #3b076440;
}

.accent-purple-950\/30 {
  accent-color: #3b07644d;
}

.accent-purple-950\/40 {
  accent-color: #3b076466;
}

.accent-purple-950\/5 {
  accent-color: #3b07640d;
}

.accent-purple-950\/50 {
  accent-color: #3b076480;
}

.accent-purple-950\/60 {
  accent-color: #3b076499;
}

.accent-purple-950\/70 {
  accent-color: #3b0764b3;
}

.accent-purple-950\/75 {
  accent-color: #3b0764bf;
}

.accent-purple-950\/80 {
  accent-color: #3b0764cc;
}

.accent-purple-950\/90 {
  accent-color: #3b0764e6;
}

.accent-purple-950\/95 {
  accent-color: #3b0764f2;
}

.accent-transparent, .accent-transparent\/0 {
  accent-color: #0000;
}

.accent-transparent\/10 {
  accent-color: #0000001a;
}

.accent-transparent\/100 {
  accent-color: #000;
}

.accent-transparent\/20 {
  accent-color: #0003;
}

.accent-transparent\/25 {
  accent-color: #00000040;
}

.accent-transparent\/30 {
  accent-color: #0000004d;
}

.accent-transparent\/40 {
  accent-color: #0006;
}

.accent-transparent\/5 {
  accent-color: #0000000d;
}

.accent-transparent\/50 {
  accent-color: #00000080;
}

.accent-transparent\/60 {
  accent-color: #0009;
}

.accent-transparent\/70 {
  accent-color: #000000b3;
}

.accent-transparent\/75 {
  accent-color: #000000bf;
}

.accent-transparent\/80 {
  accent-color: #000c;
}

.accent-transparent\/90 {
  accent-color: #000000e6;
}

.accent-transparent\/95 {
  accent-color: #000000f2;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-5 {
  opacity: .05;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.opacity-95 {
  opacity: .95;
}

.bg-blend-normal {
  background-blend-mode: normal;
}

.bg-blend-multiply {
  background-blend-mode: multiply;
}

.bg-blend-luminosity {
  background-blend-mode: luminosity;
}

.mix-blend-normal {
  mix-blend-mode: normal;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.mix-blend-screen {
  mix-blend-mode: screen;
}

.mix-blend-overlay {
  mix-blend-mode: overlay;
}

.mix-blend-darken {
  mix-blend-mode: darken;
}

.mix-blend-lighten {
  mix-blend-mode: lighten;
}

.mix-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.mix-blend-color-burn {
  mix-blend-mode: color-burn;
}

.mix-blend-hard-light {
  mix-blend-mode: hard-light;
}

.mix-blend-soft-light {
  mix-blend-mode: soft-light;
}

.mix-blend-difference {
  mix-blend-mode: difference;
}

.mix-blend-exclusion {
  mix-blend-mode: exclusion;
}

.mix-blend-hue {
  mix-blend-mode: hue;
}

.mix-blend-saturation {
  mix-blend-mode: saturation;
}

.mix-blend-color {
  mix-blend-mode: color;
}

.mix-blend-luminosity {
  mix-blend-mode: luminosity;
}

.mix-blend-plus-lighter {
  mix-blend-mode: plus-lighter;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-amber-100 {
  --tw-shadow-color: #fef3c7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/0 {
  --tw-shadow-color: #fef3c700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/10 {
  --tw-shadow-color: #fef3c71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/100 {
  --tw-shadow-color: #fef3c7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/20 {
  --tw-shadow-color: #fef3c733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/25 {
  --tw-shadow-color: #fef3c740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/30 {
  --tw-shadow-color: #fef3c74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/40 {
  --tw-shadow-color: #fef3c766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/5 {
  --tw-shadow-color: #fef3c70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/50 {
  --tw-shadow-color: #fef3c780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/60 {
  --tw-shadow-color: #fef3c799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/70 {
  --tw-shadow-color: #fef3c7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/75 {
  --tw-shadow-color: #fef3c7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/80 {
  --tw-shadow-color: #fef3c7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/90 {
  --tw-shadow-color: #fef3c7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-100\/95 {
  --tw-shadow-color: #fef3c7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200 {
  --tw-shadow-color: #fde68a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/0 {
  --tw-shadow-color: #fde68a00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/10 {
  --tw-shadow-color: #fde68a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/100 {
  --tw-shadow-color: #fde68a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/20 {
  --tw-shadow-color: #fde68a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/25 {
  --tw-shadow-color: #fde68a40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/30 {
  --tw-shadow-color: #fde68a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/40 {
  --tw-shadow-color: #fde68a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/5 {
  --tw-shadow-color: #fde68a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/50 {
  --tw-shadow-color: #fde68a80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/60 {
  --tw-shadow-color: #fde68a99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/70 {
  --tw-shadow-color: #fde68ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/75 {
  --tw-shadow-color: #fde68abf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/80 {
  --tw-shadow-color: #fde68acc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/90 {
  --tw-shadow-color: #fde68ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-200\/95 {
  --tw-shadow-color: #fde68af2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300 {
  --tw-shadow-color: #fcd34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/0 {
  --tw-shadow-color: #fcd34d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/10 {
  --tw-shadow-color: #fcd34d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/100 {
  --tw-shadow-color: #fcd34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/20 {
  --tw-shadow-color: #fcd34d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/25 {
  --tw-shadow-color: #fcd34d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/30 {
  --tw-shadow-color: #fcd34d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/40 {
  --tw-shadow-color: #fcd34d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/5 {
  --tw-shadow-color: #fcd34d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/50 {
  --tw-shadow-color: #fcd34d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/60 {
  --tw-shadow-color: #fcd34d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/70 {
  --tw-shadow-color: #fcd34db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/75 {
  --tw-shadow-color: #fcd34dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/80 {
  --tw-shadow-color: #fcd34dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/90 {
  --tw-shadow-color: #fcd34de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-300\/95 {
  --tw-shadow-color: #fcd34df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400 {
  --tw-shadow-color: #fbbf24;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/0 {
  --tw-shadow-color: #fbbf2400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/10 {
  --tw-shadow-color: #fbbf241a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/100 {
  --tw-shadow-color: #fbbf24;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/20 {
  --tw-shadow-color: #fbbf2433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/25 {
  --tw-shadow-color: #fbbf2440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/30 {
  --tw-shadow-color: #fbbf244d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/40 {
  --tw-shadow-color: #fbbf2466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/5 {
  --tw-shadow-color: #fbbf240d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/50 {
  --tw-shadow-color: #fbbf2480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/60 {
  --tw-shadow-color: #fbbf2499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/70 {
  --tw-shadow-color: #fbbf24b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/75 {
  --tw-shadow-color: #fbbf24bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/80 {
  --tw-shadow-color: #fbbf24cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/90 {
  --tw-shadow-color: #fbbf24e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-400\/95 {
  --tw-shadow-color: #fbbf24f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50 {
  --tw-shadow-color: #fffbeb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/0 {
  --tw-shadow-color: #fffbeb00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/10 {
  --tw-shadow-color: #fffbeb1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/100 {
  --tw-shadow-color: #fffbeb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/20 {
  --tw-shadow-color: #fffbeb33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/25 {
  --tw-shadow-color: #fffbeb40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/30 {
  --tw-shadow-color: #fffbeb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/40 {
  --tw-shadow-color: #fffbeb66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/5 {
  --tw-shadow-color: #fffbeb0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/50 {
  --tw-shadow-color: #fffbeb80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/60 {
  --tw-shadow-color: #fffbeb99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/70 {
  --tw-shadow-color: #fffbebb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/75 {
  --tw-shadow-color: #fffbebbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/80 {
  --tw-shadow-color: #fffbebcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/90 {
  --tw-shadow-color: #fffbebe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-50\/95 {
  --tw-shadow-color: #fffbebf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500 {
  --tw-shadow-color: #f59e0b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/0 {
  --tw-shadow-color: #f59e0b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/10 {
  --tw-shadow-color: #f59e0b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/100 {
  --tw-shadow-color: #f59e0b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/20 {
  --tw-shadow-color: #f59e0b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/25 {
  --tw-shadow-color: #f59e0b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/30 {
  --tw-shadow-color: #f59e0b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/40 {
  --tw-shadow-color: #f59e0b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/5 {
  --tw-shadow-color: #f59e0b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/50 {
  --tw-shadow-color: #f59e0b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/60 {
  --tw-shadow-color: #f59e0b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/70 {
  --tw-shadow-color: #f59e0bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/75 {
  --tw-shadow-color: #f59e0bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/80 {
  --tw-shadow-color: #f59e0bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/90 {
  --tw-shadow-color: #f59e0be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/95 {
  --tw-shadow-color: #f59e0bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600 {
  --tw-shadow-color: #d97706;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/0 {
  --tw-shadow-color: #d9770600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/10 {
  --tw-shadow-color: #d977061a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/100 {
  --tw-shadow-color: #d97706;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/20 {
  --tw-shadow-color: #d9770633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/25 {
  --tw-shadow-color: #d9770640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/30 {
  --tw-shadow-color: #d977064d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/40 {
  --tw-shadow-color: #d9770666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/5 {
  --tw-shadow-color: #d977060d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/50 {
  --tw-shadow-color: #d9770680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/60 {
  --tw-shadow-color: #d9770699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/70 {
  --tw-shadow-color: #d97706b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/75 {
  --tw-shadow-color: #d97706bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/80 {
  --tw-shadow-color: #d97706cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/90 {
  --tw-shadow-color: #d97706e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-600\/95 {
  --tw-shadow-color: #d97706f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700 {
  --tw-shadow-color: #b45309;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/0 {
  --tw-shadow-color: #b4530900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/10 {
  --tw-shadow-color: #b453091a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/100 {
  --tw-shadow-color: #b45309;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/20 {
  --tw-shadow-color: #b4530933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/25 {
  --tw-shadow-color: #b4530940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/30 {
  --tw-shadow-color: #b453094d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/40 {
  --tw-shadow-color: #b4530966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/5 {
  --tw-shadow-color: #b453090d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/50 {
  --tw-shadow-color: #b4530980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/60 {
  --tw-shadow-color: #b4530999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/70 {
  --tw-shadow-color: #b45309b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/75 {
  --tw-shadow-color: #b45309bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/80 {
  --tw-shadow-color: #b45309cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/90 {
  --tw-shadow-color: #b45309e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-700\/95 {
  --tw-shadow-color: #b45309f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800 {
  --tw-shadow-color: #92400e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/0 {
  --tw-shadow-color: #92400e00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/10 {
  --tw-shadow-color: #92400e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/100 {
  --tw-shadow-color: #92400e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/20 {
  --tw-shadow-color: #92400e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/25 {
  --tw-shadow-color: #92400e40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/30 {
  --tw-shadow-color: #92400e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/40 {
  --tw-shadow-color: #92400e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/5 {
  --tw-shadow-color: #92400e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/50 {
  --tw-shadow-color: #92400e80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/60 {
  --tw-shadow-color: #92400e99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/70 {
  --tw-shadow-color: #92400eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/75 {
  --tw-shadow-color: #92400ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/80 {
  --tw-shadow-color: #92400ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/90 {
  --tw-shadow-color: #92400ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-800\/95 {
  --tw-shadow-color: #92400ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900 {
  --tw-shadow-color: #78350f;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/0 {
  --tw-shadow-color: #78350f00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/10 {
  --tw-shadow-color: #78350f1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/100 {
  --tw-shadow-color: #78350f;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/20 {
  --tw-shadow-color: #78350f33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/25 {
  --tw-shadow-color: #78350f40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/30 {
  --tw-shadow-color: #78350f4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/40 {
  --tw-shadow-color: #78350f66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/5 {
  --tw-shadow-color: #78350f0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/50 {
  --tw-shadow-color: #78350f80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/60 {
  --tw-shadow-color: #78350f99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/70 {
  --tw-shadow-color: #78350fb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/75 {
  --tw-shadow-color: #78350fbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/80 {
  --tw-shadow-color: #78350fcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/90 {
  --tw-shadow-color: #78350fe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-900\/95 {
  --tw-shadow-color: #78350ff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950 {
  --tw-shadow-color: #451a03;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/0 {
  --tw-shadow-color: #451a0300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/10 {
  --tw-shadow-color: #451a031a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/100 {
  --tw-shadow-color: #451a03;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/20 {
  --tw-shadow-color: #451a0333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/25 {
  --tw-shadow-color: #451a0340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/30 {
  --tw-shadow-color: #451a034d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/40 {
  --tw-shadow-color: #451a0366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/5 {
  --tw-shadow-color: #451a030d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/50 {
  --tw-shadow-color: #451a0380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/60 {
  --tw-shadow-color: #451a0399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/70 {
  --tw-shadow-color: #451a03b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/75 {
  --tw-shadow-color: #451a03bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/80 {
  --tw-shadow-color: #451a03cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/90 {
  --tw-shadow-color: #451a03e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-950\/95 {
  --tw-shadow-color: #451a03f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100 {
  --tw-shadow-color: #d1fae5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/0 {
  --tw-shadow-color: #d1fae500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/10 {
  --tw-shadow-color: #d1fae51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/100 {
  --tw-shadow-color: #d1fae5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/20 {
  --tw-shadow-color: #d1fae533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/25 {
  --tw-shadow-color: #d1fae540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/30 {
  --tw-shadow-color: #d1fae54d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/40 {
  --tw-shadow-color: #d1fae566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/5 {
  --tw-shadow-color: #d1fae50d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/50 {
  --tw-shadow-color: #d1fae580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/60 {
  --tw-shadow-color: #d1fae599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/70 {
  --tw-shadow-color: #d1fae5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/75 {
  --tw-shadow-color: #d1fae5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/80 {
  --tw-shadow-color: #d1fae5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/90 {
  --tw-shadow-color: #d1fae5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-100\/95 {
  --tw-shadow-color: #d1fae5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200 {
  --tw-shadow-color: #a7f3d0;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/0 {
  --tw-shadow-color: #a7f3d000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/10 {
  --tw-shadow-color: #a7f3d01a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/100 {
  --tw-shadow-color: #a7f3d0;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/20 {
  --tw-shadow-color: #a7f3d033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/25 {
  --tw-shadow-color: #a7f3d040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/30 {
  --tw-shadow-color: #a7f3d04d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/40 {
  --tw-shadow-color: #a7f3d066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/5 {
  --tw-shadow-color: #a7f3d00d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/50 {
  --tw-shadow-color: #a7f3d080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/60 {
  --tw-shadow-color: #a7f3d099;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/70 {
  --tw-shadow-color: #a7f3d0b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/75 {
  --tw-shadow-color: #a7f3d0bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/80 {
  --tw-shadow-color: #a7f3d0cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/90 {
  --tw-shadow-color: #a7f3d0e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-200\/95 {
  --tw-shadow-color: #a7f3d0f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300 {
  --tw-shadow-color: #6ee7b7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/0 {
  --tw-shadow-color: #6ee7b700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/10 {
  --tw-shadow-color: #6ee7b71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/100 {
  --tw-shadow-color: #6ee7b7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/20 {
  --tw-shadow-color: #6ee7b733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/25 {
  --tw-shadow-color: #6ee7b740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/30 {
  --tw-shadow-color: #6ee7b74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/40 {
  --tw-shadow-color: #6ee7b766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/5 {
  --tw-shadow-color: #6ee7b70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/50 {
  --tw-shadow-color: #6ee7b780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/60 {
  --tw-shadow-color: #6ee7b799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/70 {
  --tw-shadow-color: #6ee7b7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/75 {
  --tw-shadow-color: #6ee7b7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/80 {
  --tw-shadow-color: #6ee7b7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/90 {
  --tw-shadow-color: #6ee7b7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-300\/95 {
  --tw-shadow-color: #6ee7b7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400 {
  --tw-shadow-color: #34d399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/0 {
  --tw-shadow-color: #34d39900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/10 {
  --tw-shadow-color: #34d3991a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/100 {
  --tw-shadow-color: #34d399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/20 {
  --tw-shadow-color: #34d39933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/25 {
  --tw-shadow-color: #34d39940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/30 {
  --tw-shadow-color: #34d3994d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/40 {
  --tw-shadow-color: #34d39966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/5 {
  --tw-shadow-color: #34d3990d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/50 {
  --tw-shadow-color: #34d39980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/60 {
  --tw-shadow-color: #34d39999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/70 {
  --tw-shadow-color: #34d399b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/75 {
  --tw-shadow-color: #34d399bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/80 {
  --tw-shadow-color: #34d399cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/90 {
  --tw-shadow-color: #34d399e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-400\/95 {
  --tw-shadow-color: #34d399f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50 {
  --tw-shadow-color: #ecfdf5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/0 {
  --tw-shadow-color: #ecfdf500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/10 {
  --tw-shadow-color: #ecfdf51a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/100 {
  --tw-shadow-color: #ecfdf5;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/20 {
  --tw-shadow-color: #ecfdf533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/25 {
  --tw-shadow-color: #ecfdf540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/30 {
  --tw-shadow-color: #ecfdf54d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/40 {
  --tw-shadow-color: #ecfdf566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/5 {
  --tw-shadow-color: #ecfdf50d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/50 {
  --tw-shadow-color: #ecfdf580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/60 {
  --tw-shadow-color: #ecfdf599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/70 {
  --tw-shadow-color: #ecfdf5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/75 {
  --tw-shadow-color: #ecfdf5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/80 {
  --tw-shadow-color: #ecfdf5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/90 {
  --tw-shadow-color: #ecfdf5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-50\/95 {
  --tw-shadow-color: #ecfdf5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500 {
  --tw-shadow-color: #10b981;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/0 {
  --tw-shadow-color: #10b98100;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/10 {
  --tw-shadow-color: #10b9811a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/100 {
  --tw-shadow-color: #10b981;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/20 {
  --tw-shadow-color: #10b98133;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/25 {
  --tw-shadow-color: #10b98140;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/30 {
  --tw-shadow-color: #10b9814d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/40 {
  --tw-shadow-color: #10b98166;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/5 {
  --tw-shadow-color: #10b9810d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/50 {
  --tw-shadow-color: #10b98180;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/60 {
  --tw-shadow-color: #10b98199;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/70 {
  --tw-shadow-color: #10b981b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/75 {
  --tw-shadow-color: #10b981bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/80 {
  --tw-shadow-color: #10b981cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/90 {
  --tw-shadow-color: #10b981e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-500\/95 {
  --tw-shadow-color: #10b981f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600 {
  --tw-shadow-color: #059669;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/0 {
  --tw-shadow-color: #05966900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/10 {
  --tw-shadow-color: #0596691a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/100 {
  --tw-shadow-color: #059669;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/20 {
  --tw-shadow-color: #05966933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/25 {
  --tw-shadow-color: #05966940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/30 {
  --tw-shadow-color: #0596694d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/40 {
  --tw-shadow-color: #05966966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/5 {
  --tw-shadow-color: #0596690d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/50 {
  --tw-shadow-color: #05966980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/60 {
  --tw-shadow-color: #05966999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/70 {
  --tw-shadow-color: #059669b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/75 {
  --tw-shadow-color: #059669bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/80 {
  --tw-shadow-color: #059669cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/90 {
  --tw-shadow-color: #059669e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-600\/95 {
  --tw-shadow-color: #059669f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700 {
  --tw-shadow-color: #047857;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/0 {
  --tw-shadow-color: #04785700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/10 {
  --tw-shadow-color: #0478571a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/100 {
  --tw-shadow-color: #047857;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/20 {
  --tw-shadow-color: #04785733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/25 {
  --tw-shadow-color: #04785740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/30 {
  --tw-shadow-color: #0478574d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/40 {
  --tw-shadow-color: #04785766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/5 {
  --tw-shadow-color: #0478570d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/50 {
  --tw-shadow-color: #04785780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/60 {
  --tw-shadow-color: #04785799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/70 {
  --tw-shadow-color: #047857b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/75 {
  --tw-shadow-color: #047857bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/80 {
  --tw-shadow-color: #047857cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/90 {
  --tw-shadow-color: #047857e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-700\/95 {
  --tw-shadow-color: #047857f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800 {
  --tw-shadow-color: #065f46;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/0 {
  --tw-shadow-color: #065f4600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/10 {
  --tw-shadow-color: #065f461a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/100 {
  --tw-shadow-color: #065f46;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/20 {
  --tw-shadow-color: #065f4633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/25 {
  --tw-shadow-color: #065f4640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/30 {
  --tw-shadow-color: #065f464d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/40 {
  --tw-shadow-color: #065f4666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/5 {
  --tw-shadow-color: #065f460d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/50 {
  --tw-shadow-color: #065f4680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/60 {
  --tw-shadow-color: #065f4699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/70 {
  --tw-shadow-color: #065f46b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/75 {
  --tw-shadow-color: #065f46bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/80 {
  --tw-shadow-color: #065f46cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/90 {
  --tw-shadow-color: #065f46e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-800\/95 {
  --tw-shadow-color: #065f46f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900 {
  --tw-shadow-color: #064e3b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/0 {
  --tw-shadow-color: #064e3b00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/10 {
  --tw-shadow-color: #064e3b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/100 {
  --tw-shadow-color: #064e3b;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/20 {
  --tw-shadow-color: #064e3b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/25 {
  --tw-shadow-color: #064e3b40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/30 {
  --tw-shadow-color: #064e3b4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/40 {
  --tw-shadow-color: #064e3b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/5 {
  --tw-shadow-color: #064e3b0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/50 {
  --tw-shadow-color: #064e3b80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/60 {
  --tw-shadow-color: #064e3b99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/70 {
  --tw-shadow-color: #064e3bb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/75 {
  --tw-shadow-color: #064e3bbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/80 {
  --tw-shadow-color: #064e3bcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/90 {
  --tw-shadow-color: #064e3be6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-900\/95 {
  --tw-shadow-color: #064e3bf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950 {
  --tw-shadow-color: #022c22;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/0 {
  --tw-shadow-color: #022c2200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/10 {
  --tw-shadow-color: #022c221a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/100 {
  --tw-shadow-color: #022c22;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/20 {
  --tw-shadow-color: #022c2233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/25 {
  --tw-shadow-color: #022c2240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/30 {
  --tw-shadow-color: #022c224d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/40 {
  --tw-shadow-color: #022c2266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/5 {
  --tw-shadow-color: #022c220d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/50 {
  --tw-shadow-color: #022c2280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/60 {
  --tw-shadow-color: #022c2299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/70 {
  --tw-shadow-color: #022c22b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/75 {
  --tw-shadow-color: #022c22bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/80 {
  --tw-shadow-color: #022c22cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/90 {
  --tw-shadow-color: #022c22e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-emerald-950\/95 {
  --tw-shadow-color: #022c22f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100 {
  --tw-shadow-color: #ecfccb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/0 {
  --tw-shadow-color: #ecfccb00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/10 {
  --tw-shadow-color: #ecfccb1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/100 {
  --tw-shadow-color: #ecfccb;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/20 {
  --tw-shadow-color: #ecfccb33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/25 {
  --tw-shadow-color: #ecfccb40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/30 {
  --tw-shadow-color: #ecfccb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/40 {
  --tw-shadow-color: #ecfccb66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/5 {
  --tw-shadow-color: #ecfccb0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/50 {
  --tw-shadow-color: #ecfccb80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/60 {
  --tw-shadow-color: #ecfccb99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/70 {
  --tw-shadow-color: #ecfccbb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/75 {
  --tw-shadow-color: #ecfccbbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/80 {
  --tw-shadow-color: #ecfccbcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/90 {
  --tw-shadow-color: #ecfccbe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-100\/95 {
  --tw-shadow-color: #ecfccbf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200 {
  --tw-shadow-color: #d9f99d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/0 {
  --tw-shadow-color: #d9f99d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/10 {
  --tw-shadow-color: #d9f99d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/100 {
  --tw-shadow-color: #d9f99d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/20 {
  --tw-shadow-color: #d9f99d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/25 {
  --tw-shadow-color: #d9f99d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/30 {
  --tw-shadow-color: #d9f99d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/40 {
  --tw-shadow-color: #d9f99d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/5 {
  --tw-shadow-color: #d9f99d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/50 {
  --tw-shadow-color: #d9f99d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/60 {
  --tw-shadow-color: #d9f99d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/70 {
  --tw-shadow-color: #d9f99db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/75 {
  --tw-shadow-color: #d9f99dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/80 {
  --tw-shadow-color: #d9f99dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/90 {
  --tw-shadow-color: #d9f99de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-200\/95 {
  --tw-shadow-color: #d9f99df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300 {
  --tw-shadow-color: #bef264;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/0 {
  --tw-shadow-color: #bef26400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/10 {
  --tw-shadow-color: #bef2641a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/100 {
  --tw-shadow-color: #bef264;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/20 {
  --tw-shadow-color: #bef26433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/25 {
  --tw-shadow-color: #bef26440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/30 {
  --tw-shadow-color: #bef2644d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/40 {
  --tw-shadow-color: #bef26466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/5 {
  --tw-shadow-color: #bef2640d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/50 {
  --tw-shadow-color: #bef26480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/60 {
  --tw-shadow-color: #bef26499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/70 {
  --tw-shadow-color: #bef264b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/75 {
  --tw-shadow-color: #bef264bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/80 {
  --tw-shadow-color: #bef264cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/90 {
  --tw-shadow-color: #bef264e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-300\/95 {
  --tw-shadow-color: #bef264f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400 {
  --tw-shadow-color: #a3e635;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/0 {
  --tw-shadow-color: #a3e63500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/10 {
  --tw-shadow-color: #a3e6351a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/100 {
  --tw-shadow-color: #a3e635;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/20 {
  --tw-shadow-color: #a3e63533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/25 {
  --tw-shadow-color: #a3e63540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/30 {
  --tw-shadow-color: #a3e6354d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/40 {
  --tw-shadow-color: #a3e63566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/5 {
  --tw-shadow-color: #a3e6350d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/50 {
  --tw-shadow-color: #a3e63580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/60 {
  --tw-shadow-color: #a3e63599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/70 {
  --tw-shadow-color: #a3e635b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/75 {
  --tw-shadow-color: #a3e635bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/80 {
  --tw-shadow-color: #a3e635cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/90 {
  --tw-shadow-color: #a3e635e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-400\/95 {
  --tw-shadow-color: #a3e635f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50 {
  --tw-shadow-color: #f7fee7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/0 {
  --tw-shadow-color: #f7fee700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/10 {
  --tw-shadow-color: #f7fee71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/100 {
  --tw-shadow-color: #f7fee7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/20 {
  --tw-shadow-color: #f7fee733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/25 {
  --tw-shadow-color: #f7fee740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/30 {
  --tw-shadow-color: #f7fee74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/40 {
  --tw-shadow-color: #f7fee766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/5 {
  --tw-shadow-color: #f7fee70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/50 {
  --tw-shadow-color: #f7fee780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/60 {
  --tw-shadow-color: #f7fee799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/70 {
  --tw-shadow-color: #f7fee7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/75 {
  --tw-shadow-color: #f7fee7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/80 {
  --tw-shadow-color: #f7fee7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/90 {
  --tw-shadow-color: #f7fee7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-50\/95 {
  --tw-shadow-color: #f7fee7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500 {
  --tw-shadow-color: #84cc16;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/0 {
  --tw-shadow-color: #84cc1600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/10 {
  --tw-shadow-color: #84cc161a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/100 {
  --tw-shadow-color: #84cc16;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/20 {
  --tw-shadow-color: #84cc1633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/25 {
  --tw-shadow-color: #84cc1640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/30 {
  --tw-shadow-color: #84cc164d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/40 {
  --tw-shadow-color: #84cc1666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/5 {
  --tw-shadow-color: #84cc160d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/50 {
  --tw-shadow-color: #84cc1680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/60 {
  --tw-shadow-color: #84cc1699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/70 {
  --tw-shadow-color: #84cc16b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/75 {
  --tw-shadow-color: #84cc16bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/80 {
  --tw-shadow-color: #84cc16cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/90 {
  --tw-shadow-color: #84cc16e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/95 {
  --tw-shadow-color: #84cc16f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600 {
  --tw-shadow-color: #65a30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/0 {
  --tw-shadow-color: #65a30d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/10 {
  --tw-shadow-color: #65a30d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/100 {
  --tw-shadow-color: #65a30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/20 {
  --tw-shadow-color: #65a30d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/25 {
  --tw-shadow-color: #65a30d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/30 {
  --tw-shadow-color: #65a30d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/40 {
  --tw-shadow-color: #65a30d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/5 {
  --tw-shadow-color: #65a30d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/50 {
  --tw-shadow-color: #65a30d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/60 {
  --tw-shadow-color: #65a30d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/70 {
  --tw-shadow-color: #65a30db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/75 {
  --tw-shadow-color: #65a30dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/80 {
  --tw-shadow-color: #65a30dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/90 {
  --tw-shadow-color: #65a30de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-600\/95 {
  --tw-shadow-color: #65a30df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700 {
  --tw-shadow-color: #4d7c0f;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/0 {
  --tw-shadow-color: #4d7c0f00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/10 {
  --tw-shadow-color: #4d7c0f1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/100 {
  --tw-shadow-color: #4d7c0f;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/20 {
  --tw-shadow-color: #4d7c0f33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/25 {
  --tw-shadow-color: #4d7c0f40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/30 {
  --tw-shadow-color: #4d7c0f4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/40 {
  --tw-shadow-color: #4d7c0f66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/5 {
  --tw-shadow-color: #4d7c0f0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/50 {
  --tw-shadow-color: #4d7c0f80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/60 {
  --tw-shadow-color: #4d7c0f99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/70 {
  --tw-shadow-color: #4d7c0fb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/75 {
  --tw-shadow-color: #4d7c0fbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/80 {
  --tw-shadow-color: #4d7c0fcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/90 {
  --tw-shadow-color: #4d7c0fe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-700\/95 {
  --tw-shadow-color: #4d7c0ff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800 {
  --tw-shadow-color: #3f6212;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/0 {
  --tw-shadow-color: #3f621200;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/10 {
  --tw-shadow-color: #3f62121a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/100 {
  --tw-shadow-color: #3f6212;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/20 {
  --tw-shadow-color: #3f621233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/25 {
  --tw-shadow-color: #3f621240;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/30 {
  --tw-shadow-color: #3f62124d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/40 {
  --tw-shadow-color: #3f621266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/5 {
  --tw-shadow-color: #3f62120d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/50 {
  --tw-shadow-color: #3f621280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/60 {
  --tw-shadow-color: #3f621299;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/70 {
  --tw-shadow-color: #3f6212b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/75 {
  --tw-shadow-color: #3f6212bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/80 {
  --tw-shadow-color: #3f6212cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/90 {
  --tw-shadow-color: #3f6212e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-800\/95 {
  --tw-shadow-color: #3f6212f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900 {
  --tw-shadow-color: #365314;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/0 {
  --tw-shadow-color: #36531400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/10 {
  --tw-shadow-color: #3653141a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/100 {
  --tw-shadow-color: #365314;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/20 {
  --tw-shadow-color: #36531433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/25 {
  --tw-shadow-color: #36531440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/30 {
  --tw-shadow-color: #3653144d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/40 {
  --tw-shadow-color: #36531466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/5 {
  --tw-shadow-color: #3653140d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/50 {
  --tw-shadow-color: #36531480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/60 {
  --tw-shadow-color: #36531499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/70 {
  --tw-shadow-color: #365314b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/75 {
  --tw-shadow-color: #365314bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/80 {
  --tw-shadow-color: #365314cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/90 {
  --tw-shadow-color: #365314e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-900\/95 {
  --tw-shadow-color: #365314f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950 {
  --tw-shadow-color: #1a2e05;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/0 {
  --tw-shadow-color: #1a2e0500;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/10 {
  --tw-shadow-color: #1a2e051a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/100 {
  --tw-shadow-color: #1a2e05;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/20 {
  --tw-shadow-color: #1a2e0533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/25 {
  --tw-shadow-color: #1a2e0540;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/30 {
  --tw-shadow-color: #1a2e054d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/40 {
  --tw-shadow-color: #1a2e0566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/5 {
  --tw-shadow-color: #1a2e050d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/50 {
  --tw-shadow-color: #1a2e0580;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/60 {
  --tw-shadow-color: #1a2e0599;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/70 {
  --tw-shadow-color: #1a2e05b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/75 {
  --tw-shadow-color: #1a2e05bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/80 {
  --tw-shadow-color: #1a2e05cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/90 {
  --tw-shadow-color: #1a2e05e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-950\/95 {
  --tw-shadow-color: #1a2e05f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100 {
  --tw-shadow-color: #fce7f3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/0 {
  --tw-shadow-color: #fce7f300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/10 {
  --tw-shadow-color: #fce7f31a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/100 {
  --tw-shadow-color: #fce7f3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/20 {
  --tw-shadow-color: #fce7f333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/25 {
  --tw-shadow-color: #fce7f340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/30 {
  --tw-shadow-color: #fce7f34d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/40 {
  --tw-shadow-color: #fce7f366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/5 {
  --tw-shadow-color: #fce7f30d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/50 {
  --tw-shadow-color: #fce7f380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/60 {
  --tw-shadow-color: #fce7f399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/70 {
  --tw-shadow-color: #fce7f3b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/75 {
  --tw-shadow-color: #fce7f3bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/80 {
  --tw-shadow-color: #fce7f3cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/90 {
  --tw-shadow-color: #fce7f3e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-100\/95 {
  --tw-shadow-color: #fce7f3f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200 {
  --tw-shadow-color: #fbcfe8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/0 {
  --tw-shadow-color: #fbcfe800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/10 {
  --tw-shadow-color: #fbcfe81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/100 {
  --tw-shadow-color: #fbcfe8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/20 {
  --tw-shadow-color: #fbcfe833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/25 {
  --tw-shadow-color: #fbcfe840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/30 {
  --tw-shadow-color: #fbcfe84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/40 {
  --tw-shadow-color: #fbcfe866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/5 {
  --tw-shadow-color: #fbcfe80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/50 {
  --tw-shadow-color: #fbcfe880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/60 {
  --tw-shadow-color: #fbcfe899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/70 {
  --tw-shadow-color: #fbcfe8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/75 {
  --tw-shadow-color: #fbcfe8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/80 {
  --tw-shadow-color: #fbcfe8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/90 {
  --tw-shadow-color: #fbcfe8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-200\/95 {
  --tw-shadow-color: #fbcfe8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300 {
  --tw-shadow-color: #f9a8d4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/0 {
  --tw-shadow-color: #f9a8d400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/10 {
  --tw-shadow-color: #f9a8d41a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/100 {
  --tw-shadow-color: #f9a8d4;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/20 {
  --tw-shadow-color: #f9a8d433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/25 {
  --tw-shadow-color: #f9a8d440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/30 {
  --tw-shadow-color: #f9a8d44d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/40 {
  --tw-shadow-color: #f9a8d466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/5 {
  --tw-shadow-color: #f9a8d40d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/50 {
  --tw-shadow-color: #f9a8d480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/60 {
  --tw-shadow-color: #f9a8d499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/70 {
  --tw-shadow-color: #f9a8d4b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/75 {
  --tw-shadow-color: #f9a8d4bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/80 {
  --tw-shadow-color: #f9a8d4cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/90 {
  --tw-shadow-color: #f9a8d4e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-300\/95 {
  --tw-shadow-color: #f9a8d4f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400 {
  --tw-shadow-color: #f472b6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/0 {
  --tw-shadow-color: #f472b600;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/10 {
  --tw-shadow-color: #f472b61a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/100 {
  --tw-shadow-color: #f472b6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/20 {
  --tw-shadow-color: #f472b633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/25 {
  --tw-shadow-color: #f472b640;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/30 {
  --tw-shadow-color: #f472b64d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/40 {
  --tw-shadow-color: #f472b666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/5 {
  --tw-shadow-color: #f472b60d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/50 {
  --tw-shadow-color: #f472b680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/60 {
  --tw-shadow-color: #f472b699;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/70 {
  --tw-shadow-color: #f472b6b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/75 {
  --tw-shadow-color: #f472b6bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/80 {
  --tw-shadow-color: #f472b6cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/90 {
  --tw-shadow-color: #f472b6e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-400\/95 {
  --tw-shadow-color: #f472b6f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50 {
  --tw-shadow-color: #fdf2f8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/0 {
  --tw-shadow-color: #fdf2f800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/10 {
  --tw-shadow-color: #fdf2f81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/100 {
  --tw-shadow-color: #fdf2f8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/20 {
  --tw-shadow-color: #fdf2f833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/25 {
  --tw-shadow-color: #fdf2f840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/30 {
  --tw-shadow-color: #fdf2f84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/40 {
  --tw-shadow-color: #fdf2f866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/5 {
  --tw-shadow-color: #fdf2f80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/50 {
  --tw-shadow-color: #fdf2f880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/60 {
  --tw-shadow-color: #fdf2f899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/70 {
  --tw-shadow-color: #fdf2f8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/75 {
  --tw-shadow-color: #fdf2f8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/80 {
  --tw-shadow-color: #fdf2f8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/90 {
  --tw-shadow-color: #fdf2f8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-50\/95 {
  --tw-shadow-color: #fdf2f8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500 {
  --tw-shadow-color: #ec4899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/0 {
  --tw-shadow-color: #ec489900;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/10 {
  --tw-shadow-color: #ec48991a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/100 {
  --tw-shadow-color: #ec4899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/20 {
  --tw-shadow-color: #ec489933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/25 {
  --tw-shadow-color: #ec489940;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/30 {
  --tw-shadow-color: #ec48994d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/40 {
  --tw-shadow-color: #ec489966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/5 {
  --tw-shadow-color: #ec48990d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/50 {
  --tw-shadow-color: #ec489980;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/60 {
  --tw-shadow-color: #ec489999;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/70 {
  --tw-shadow-color: #ec4899b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/75 {
  --tw-shadow-color: #ec4899bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/80 {
  --tw-shadow-color: #ec4899cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/90 {
  --tw-shadow-color: #ec4899e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/95 {
  --tw-shadow-color: #ec4899f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600 {
  --tw-shadow-color: #db2777;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/0 {
  --tw-shadow-color: #db277700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/10 {
  --tw-shadow-color: #db27771a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/100 {
  --tw-shadow-color: #db2777;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/20 {
  --tw-shadow-color: #db277733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/25 {
  --tw-shadow-color: #db277740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/30 {
  --tw-shadow-color: #db27774d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/40 {
  --tw-shadow-color: #db277766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/5 {
  --tw-shadow-color: #db27770d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/50 {
  --tw-shadow-color: #db277780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/60 {
  --tw-shadow-color: #db277799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/70 {
  --tw-shadow-color: #db2777b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/75 {
  --tw-shadow-color: #db2777bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/80 {
  --tw-shadow-color: #db2777cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/90 {
  --tw-shadow-color: #db2777e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-600\/95 {
  --tw-shadow-color: #db2777f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700 {
  --tw-shadow-color: #be185d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/0 {
  --tw-shadow-color: #be185d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/10 {
  --tw-shadow-color: #be185d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/100 {
  --tw-shadow-color: #be185d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/20 {
  --tw-shadow-color: #be185d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/25 {
  --tw-shadow-color: #be185d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/30 {
  --tw-shadow-color: #be185d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/40 {
  --tw-shadow-color: #be185d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/5 {
  --tw-shadow-color: #be185d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/50 {
  --tw-shadow-color: #be185d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/60 {
  --tw-shadow-color: #be185d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/70 {
  --tw-shadow-color: #be185db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/75 {
  --tw-shadow-color: #be185dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/80 {
  --tw-shadow-color: #be185dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/90 {
  --tw-shadow-color: #be185de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-700\/95 {
  --tw-shadow-color: #be185df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800 {
  --tw-shadow-color: #9d174d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/0 {
  --tw-shadow-color: #9d174d00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/10 {
  --tw-shadow-color: #9d174d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/100 {
  --tw-shadow-color: #9d174d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/20 {
  --tw-shadow-color: #9d174d33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/25 {
  --tw-shadow-color: #9d174d40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/30 {
  --tw-shadow-color: #9d174d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/40 {
  --tw-shadow-color: #9d174d66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/5 {
  --tw-shadow-color: #9d174d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/50 {
  --tw-shadow-color: #9d174d80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/60 {
  --tw-shadow-color: #9d174d99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/70 {
  --tw-shadow-color: #9d174db3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/75 {
  --tw-shadow-color: #9d174dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/80 {
  --tw-shadow-color: #9d174dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/90 {
  --tw-shadow-color: #9d174de6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-800\/95 {
  --tw-shadow-color: #9d174df2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900 {
  --tw-shadow-color: #831843;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/0 {
  --tw-shadow-color: #83184300;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/10 {
  --tw-shadow-color: #8318431a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/100 {
  --tw-shadow-color: #831843;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/20 {
  --tw-shadow-color: #83184333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/25 {
  --tw-shadow-color: #83184340;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/30 {
  --tw-shadow-color: #8318434d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/40 {
  --tw-shadow-color: #83184366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/5 {
  --tw-shadow-color: #8318430d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/50 {
  --tw-shadow-color: #83184380;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/60 {
  --tw-shadow-color: #83184399;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/70 {
  --tw-shadow-color: #831843b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/75 {
  --tw-shadow-color: #831843bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/80 {
  --tw-shadow-color: #831843cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/90 {
  --tw-shadow-color: #831843e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-900\/95 {
  --tw-shadow-color: #831843f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950 {
  --tw-shadow-color: #500724;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/0 {
  --tw-shadow-color: #50072400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/10 {
  --tw-shadow-color: #5007241a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/100 {
  --tw-shadow-color: #500724;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/20 {
  --tw-shadow-color: #50072433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/25 {
  --tw-shadow-color: #50072440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/30 {
  --tw-shadow-color: #5007244d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/40 {
  --tw-shadow-color: #50072466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/5 {
  --tw-shadow-color: #5007240d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/50 {
  --tw-shadow-color: #50072480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/60 {
  --tw-shadow-color: #50072499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/70 {
  --tw-shadow-color: #500724b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/75 {
  --tw-shadow-color: #500724bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/80 {
  --tw-shadow-color: #500724cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/90 {
  --tw-shadow-color: #500724e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-950\/95 {
  --tw-shadow-color: #500724f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary {
  --tw-shadow-color: hsl(var(--primary) / 1);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/0 {
  --tw-shadow-color: hsl(var(--primary) / 0);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/10 {
  --tw-shadow-color: hsl(var(--primary) / .1);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/100 {
  --tw-shadow-color: hsl(var(--primary) / 1);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/20 {
  --tw-shadow-color: hsl(var(--primary) / .2);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/25 {
  --tw-shadow-color: hsl(var(--primary) / .25);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/30 {
  --tw-shadow-color: hsl(var(--primary) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/40 {
  --tw-shadow-color: hsl(var(--primary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/5 {
  --tw-shadow-color: hsl(var(--primary) / .05);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/50 {
  --tw-shadow-color: hsl(var(--primary) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/60 {
  --tw-shadow-color: hsl(var(--primary) / .6);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/70 {
  --tw-shadow-color: hsl(var(--primary) / .7);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/75 {
  --tw-shadow-color: hsl(var(--primary) / .75);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/80 {
  --tw-shadow-color: hsl(var(--primary) / .8);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/90 {
  --tw-shadow-color: hsl(var(--primary) / .9);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/95 {
  --tw-shadow-color: hsl(var(--primary) / .95);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100 {
  --tw-shadow-color: #f3e8ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/0 {
  --tw-shadow-color: #f3e8ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/10 {
  --tw-shadow-color: #f3e8ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/100 {
  --tw-shadow-color: #f3e8ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/20 {
  --tw-shadow-color: #f3e8ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/25 {
  --tw-shadow-color: #f3e8ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/30 {
  --tw-shadow-color: #f3e8ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/40 {
  --tw-shadow-color: #f3e8ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/5 {
  --tw-shadow-color: #f3e8ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/50 {
  --tw-shadow-color: #f3e8ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/60 {
  --tw-shadow-color: #f3e8ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/70 {
  --tw-shadow-color: #f3e8ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/75 {
  --tw-shadow-color: #f3e8ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/80 {
  --tw-shadow-color: #f3e8ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/90 {
  --tw-shadow-color: #f3e8ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-100\/95 {
  --tw-shadow-color: #f3e8fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200 {
  --tw-shadow-color: #e9d5ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/0 {
  --tw-shadow-color: #e9d5ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/10 {
  --tw-shadow-color: #e9d5ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/100 {
  --tw-shadow-color: #e9d5ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/20 {
  --tw-shadow-color: #e9d5ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/25 {
  --tw-shadow-color: #e9d5ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/30 {
  --tw-shadow-color: #e9d5ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/40 {
  --tw-shadow-color: #e9d5ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/5 {
  --tw-shadow-color: #e9d5ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/50 {
  --tw-shadow-color: #e9d5ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/60 {
  --tw-shadow-color: #e9d5ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/70 {
  --tw-shadow-color: #e9d5ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/75 {
  --tw-shadow-color: #e9d5ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/80 {
  --tw-shadow-color: #e9d5ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/90 {
  --tw-shadow-color: #e9d5ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-200\/95 {
  --tw-shadow-color: #e9d5fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300 {
  --tw-shadow-color: #d8b4fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/0 {
  --tw-shadow-color: #d8b4fe00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/10 {
  --tw-shadow-color: #d8b4fe1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/100 {
  --tw-shadow-color: #d8b4fe;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/20 {
  --tw-shadow-color: #d8b4fe33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/25 {
  --tw-shadow-color: #d8b4fe40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/30 {
  --tw-shadow-color: #d8b4fe4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/40 {
  --tw-shadow-color: #d8b4fe66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/5 {
  --tw-shadow-color: #d8b4fe0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/50 {
  --tw-shadow-color: #d8b4fe80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/60 {
  --tw-shadow-color: #d8b4fe99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/70 {
  --tw-shadow-color: #d8b4feb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/75 {
  --tw-shadow-color: #d8b4febf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/80 {
  --tw-shadow-color: #d8b4fecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/90 {
  --tw-shadow-color: #d8b4fee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-300\/95 {
  --tw-shadow-color: #d8b4fef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400 {
  --tw-shadow-color: #c084fc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/0 {
  --tw-shadow-color: #c084fc00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/10 {
  --tw-shadow-color: #c084fc1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/100 {
  --tw-shadow-color: #c084fc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/20 {
  --tw-shadow-color: #c084fc33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/25 {
  --tw-shadow-color: #c084fc40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/30 {
  --tw-shadow-color: #c084fc4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/40 {
  --tw-shadow-color: #c084fc66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/5 {
  --tw-shadow-color: #c084fc0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/50 {
  --tw-shadow-color: #c084fc80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/60 {
  --tw-shadow-color: #c084fc99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/70 {
  --tw-shadow-color: #c084fcb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/75 {
  --tw-shadow-color: #c084fcbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/80 {
  --tw-shadow-color: #c084fccc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/90 {
  --tw-shadow-color: #c084fce6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-400\/95 {
  --tw-shadow-color: #c084fcf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50 {
  --tw-shadow-color: #faf5ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/0 {
  --tw-shadow-color: #faf5ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/10 {
  --tw-shadow-color: #faf5ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/100 {
  --tw-shadow-color: #faf5ff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/20 {
  --tw-shadow-color: #faf5ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/25 {
  --tw-shadow-color: #faf5ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/30 {
  --tw-shadow-color: #faf5ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/40 {
  --tw-shadow-color: #faf5ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/5 {
  --tw-shadow-color: #faf5ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/50 {
  --tw-shadow-color: #faf5ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/60 {
  --tw-shadow-color: #faf5ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/70 {
  --tw-shadow-color: #faf5ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/75 {
  --tw-shadow-color: #faf5ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/80 {
  --tw-shadow-color: #faf5ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/90 {
  --tw-shadow-color: #faf5ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-50\/95 {
  --tw-shadow-color: #faf5fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500 {
  --tw-shadow-color: #a855f7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/0 {
  --tw-shadow-color: #a855f700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/10 {
  --tw-shadow-color: #a855f71a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/100 {
  --tw-shadow-color: #a855f7;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/20 {
  --tw-shadow-color: #a855f733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/25 {
  --tw-shadow-color: #a855f740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/30 {
  --tw-shadow-color: #a855f74d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/40 {
  --tw-shadow-color: #a855f766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/5 {
  --tw-shadow-color: #a855f70d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/50 {
  --tw-shadow-color: #a855f780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/60 {
  --tw-shadow-color: #a855f799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/70 {
  --tw-shadow-color: #a855f7b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/75 {
  --tw-shadow-color: #a855f7bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/80 {
  --tw-shadow-color: #a855f7cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/90 {
  --tw-shadow-color: #a855f7e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/95 {
  --tw-shadow-color: #a855f7f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600 {
  --tw-shadow-color: #9333ea;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/0 {
  --tw-shadow-color: #9333ea00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/10 {
  --tw-shadow-color: #9333ea1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/100 {
  --tw-shadow-color: #9333ea;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/20 {
  --tw-shadow-color: #9333ea33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/25 {
  --tw-shadow-color: #9333ea40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/30 {
  --tw-shadow-color: #9333ea4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/40 {
  --tw-shadow-color: #9333ea66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/5 {
  --tw-shadow-color: #9333ea0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/50 {
  --tw-shadow-color: #9333ea80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/60 {
  --tw-shadow-color: #9333ea99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/70 {
  --tw-shadow-color: #9333eab3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/75 {
  --tw-shadow-color: #9333eabf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/80 {
  --tw-shadow-color: #9333eacc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/90 {
  --tw-shadow-color: #9333eae6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-600\/95 {
  --tw-shadow-color: #9333eaf2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700 {
  --tw-shadow-color: #7e22ce;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/0 {
  --tw-shadow-color: #7e22ce00;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/10 {
  --tw-shadow-color: #7e22ce1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/100 {
  --tw-shadow-color: #7e22ce;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/20 {
  --tw-shadow-color: #7e22ce33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/25 {
  --tw-shadow-color: #7e22ce40;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/30 {
  --tw-shadow-color: #7e22ce4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/40 {
  --tw-shadow-color: #7e22ce66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/5 {
  --tw-shadow-color: #7e22ce0d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/50 {
  --tw-shadow-color: #7e22ce80;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/60 {
  --tw-shadow-color: #7e22ce99;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/70 {
  --tw-shadow-color: #7e22ceb3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/75 {
  --tw-shadow-color: #7e22cebf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/80 {
  --tw-shadow-color: #7e22cecc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/90 {
  --tw-shadow-color: #7e22cee6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-700\/95 {
  --tw-shadow-color: #7e22cef2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800 {
  --tw-shadow-color: #6b21a8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/0 {
  --tw-shadow-color: #6b21a800;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/10 {
  --tw-shadow-color: #6b21a81a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/100 {
  --tw-shadow-color: #6b21a8;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/20 {
  --tw-shadow-color: #6b21a833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/25 {
  --tw-shadow-color: #6b21a840;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/30 {
  --tw-shadow-color: #6b21a84d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/40 {
  --tw-shadow-color: #6b21a866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/5 {
  --tw-shadow-color: #6b21a80d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/50 {
  --tw-shadow-color: #6b21a880;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/60 {
  --tw-shadow-color: #6b21a899;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/70 {
  --tw-shadow-color: #6b21a8b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/75 {
  --tw-shadow-color: #6b21a8bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/80 {
  --tw-shadow-color: #6b21a8cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/90 {
  --tw-shadow-color: #6b21a8e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-800\/95 {
  --tw-shadow-color: #6b21a8f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900 {
  --tw-shadow-color: #581c87;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/0 {
  --tw-shadow-color: #581c8700;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/10 {
  --tw-shadow-color: #581c871a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/100 {
  --tw-shadow-color: #581c87;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/20 {
  --tw-shadow-color: #581c8733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/25 {
  --tw-shadow-color: #581c8740;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/30 {
  --tw-shadow-color: #581c874d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/40 {
  --tw-shadow-color: #581c8766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/5 {
  --tw-shadow-color: #581c870d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/50 {
  --tw-shadow-color: #581c8780;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/60 {
  --tw-shadow-color: #581c8799;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/70 {
  --tw-shadow-color: #581c87b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/75 {
  --tw-shadow-color: #581c87bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/80 {
  --tw-shadow-color: #581c87cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/90 {
  --tw-shadow-color: #581c87e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-900\/95 {
  --tw-shadow-color: #581c87f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950 {
  --tw-shadow-color: #3b0764;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/0 {
  --tw-shadow-color: #3b076400;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/10 {
  --tw-shadow-color: #3b07641a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/100 {
  --tw-shadow-color: #3b0764;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/20 {
  --tw-shadow-color: #3b076433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/25 {
  --tw-shadow-color: #3b076440;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/30 {
  --tw-shadow-color: #3b07644d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/40 {
  --tw-shadow-color: #3b076466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/5 {
  --tw-shadow-color: #3b07640d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/50 {
  --tw-shadow-color: #3b076480;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/60 {
  --tw-shadow-color: #3b076499;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/70 {
  --tw-shadow-color: #3b0764b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/75 {
  --tw-shadow-color: #3b0764bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/80 {
  --tw-shadow-color: #3b0764cc;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/90 {
  --tw-shadow-color: #3b0764e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-950\/95 {
  --tw-shadow-color: #3b0764f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent {
  --tw-shadow-color: transparent;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/0 {
  --tw-shadow-color: #0000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/100 {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/25 {
  --tw-shadow-color: #00000040;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/30 {
  --tw-shadow-color: #0000004d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/40 {
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/50 {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/60 {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/70 {
  --tw-shadow-color: #000000b3;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/75 {
  --tw-shadow-color: #000000bf;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/80 {
  --tw-shadow-color: #000c;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/90 {
  --tw-shadow-color: #000000e6;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-transparent\/95 {
  --tw-shadow-color: #000000f2;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-amber-100 {
  outline-color: #fef3c7;
}

.outline-amber-100\/0 {
  outline-color: #fef3c700;
}

.outline-amber-100\/10 {
  outline-color: #fef3c71a;
}

.outline-amber-100\/100 {
  outline-color: #fef3c7;
}

.outline-amber-100\/20 {
  outline-color: #fef3c733;
}

.outline-amber-100\/25 {
  outline-color: #fef3c740;
}

.outline-amber-100\/30 {
  outline-color: #fef3c74d;
}

.outline-amber-100\/40 {
  outline-color: #fef3c766;
}

.outline-amber-100\/5 {
  outline-color: #fef3c70d;
}

.outline-amber-100\/50 {
  outline-color: #fef3c780;
}

.outline-amber-100\/60 {
  outline-color: #fef3c799;
}

.outline-amber-100\/70 {
  outline-color: #fef3c7b3;
}

.outline-amber-100\/75 {
  outline-color: #fef3c7bf;
}

.outline-amber-100\/80 {
  outline-color: #fef3c7cc;
}

.outline-amber-100\/90 {
  outline-color: #fef3c7e6;
}

.outline-amber-100\/95 {
  outline-color: #fef3c7f2;
}

.outline-amber-200 {
  outline-color: #fde68a;
}

.outline-amber-200\/0 {
  outline-color: #fde68a00;
}

.outline-amber-200\/10 {
  outline-color: #fde68a1a;
}

.outline-amber-200\/100 {
  outline-color: #fde68a;
}

.outline-amber-200\/20 {
  outline-color: #fde68a33;
}

.outline-amber-200\/25 {
  outline-color: #fde68a40;
}

.outline-amber-200\/30 {
  outline-color: #fde68a4d;
}

.outline-amber-200\/40 {
  outline-color: #fde68a66;
}

.outline-amber-200\/5 {
  outline-color: #fde68a0d;
}

.outline-amber-200\/50 {
  outline-color: #fde68a80;
}

.outline-amber-200\/60 {
  outline-color: #fde68a99;
}

.outline-amber-200\/70 {
  outline-color: #fde68ab3;
}

.outline-amber-200\/75 {
  outline-color: #fde68abf;
}

.outline-amber-200\/80 {
  outline-color: #fde68acc;
}

.outline-amber-200\/90 {
  outline-color: #fde68ae6;
}

.outline-amber-200\/95 {
  outline-color: #fde68af2;
}

.outline-amber-300 {
  outline-color: #fcd34d;
}

.outline-amber-300\/0 {
  outline-color: #fcd34d00;
}

.outline-amber-300\/10 {
  outline-color: #fcd34d1a;
}

.outline-amber-300\/100 {
  outline-color: #fcd34d;
}

.outline-amber-300\/20 {
  outline-color: #fcd34d33;
}

.outline-amber-300\/25 {
  outline-color: #fcd34d40;
}

.outline-amber-300\/30 {
  outline-color: #fcd34d4d;
}

.outline-amber-300\/40 {
  outline-color: #fcd34d66;
}

.outline-amber-300\/5 {
  outline-color: #fcd34d0d;
}

.outline-amber-300\/50 {
  outline-color: #fcd34d80;
}

.outline-amber-300\/60 {
  outline-color: #fcd34d99;
}

.outline-amber-300\/70 {
  outline-color: #fcd34db3;
}

.outline-amber-300\/75 {
  outline-color: #fcd34dbf;
}

.outline-amber-300\/80 {
  outline-color: #fcd34dcc;
}

.outline-amber-300\/90 {
  outline-color: #fcd34de6;
}

.outline-amber-300\/95 {
  outline-color: #fcd34df2;
}

.outline-amber-400 {
  outline-color: #fbbf24;
}

.outline-amber-400\/0 {
  outline-color: #fbbf2400;
}

.outline-amber-400\/10 {
  outline-color: #fbbf241a;
}

.outline-amber-400\/100 {
  outline-color: #fbbf24;
}

.outline-amber-400\/20 {
  outline-color: #fbbf2433;
}

.outline-amber-400\/25 {
  outline-color: #fbbf2440;
}

.outline-amber-400\/30 {
  outline-color: #fbbf244d;
}

.outline-amber-400\/40 {
  outline-color: #fbbf2466;
}

.outline-amber-400\/5 {
  outline-color: #fbbf240d;
}

.outline-amber-400\/50 {
  outline-color: #fbbf2480;
}

.outline-amber-400\/60 {
  outline-color: #fbbf2499;
}

.outline-amber-400\/70 {
  outline-color: #fbbf24b3;
}

.outline-amber-400\/75 {
  outline-color: #fbbf24bf;
}

.outline-amber-400\/80 {
  outline-color: #fbbf24cc;
}

.outline-amber-400\/90 {
  outline-color: #fbbf24e6;
}

.outline-amber-400\/95 {
  outline-color: #fbbf24f2;
}

.outline-amber-50 {
  outline-color: #fffbeb;
}

.outline-amber-50\/0 {
  outline-color: #fffbeb00;
}

.outline-amber-50\/10 {
  outline-color: #fffbeb1a;
}

.outline-amber-50\/100 {
  outline-color: #fffbeb;
}

.outline-amber-50\/20 {
  outline-color: #fffbeb33;
}

.outline-amber-50\/25 {
  outline-color: #fffbeb40;
}

.outline-amber-50\/30 {
  outline-color: #fffbeb4d;
}

.outline-amber-50\/40 {
  outline-color: #fffbeb66;
}

.outline-amber-50\/5 {
  outline-color: #fffbeb0d;
}

.outline-amber-50\/50 {
  outline-color: #fffbeb80;
}

.outline-amber-50\/60 {
  outline-color: #fffbeb99;
}

.outline-amber-50\/70 {
  outline-color: #fffbebb3;
}

.outline-amber-50\/75 {
  outline-color: #fffbebbf;
}

.outline-amber-50\/80 {
  outline-color: #fffbebcc;
}

.outline-amber-50\/90 {
  outline-color: #fffbebe6;
}

.outline-amber-50\/95 {
  outline-color: #fffbebf2;
}

.outline-amber-500 {
  outline-color: #f59e0b;
}

.outline-amber-500\/0 {
  outline-color: #f59e0b00;
}

.outline-amber-500\/10 {
  outline-color: #f59e0b1a;
}

.outline-amber-500\/100 {
  outline-color: #f59e0b;
}

.outline-amber-500\/20 {
  outline-color: #f59e0b33;
}

.outline-amber-500\/25 {
  outline-color: #f59e0b40;
}

.outline-amber-500\/30 {
  outline-color: #f59e0b4d;
}

.outline-amber-500\/40 {
  outline-color: #f59e0b66;
}

.outline-amber-500\/5 {
  outline-color: #f59e0b0d;
}

.outline-amber-500\/50 {
  outline-color: #f59e0b80;
}

.outline-amber-500\/60 {
  outline-color: #f59e0b99;
}

.outline-amber-500\/70 {
  outline-color: #f59e0bb3;
}

.outline-amber-500\/75 {
  outline-color: #f59e0bbf;
}

.outline-amber-500\/80 {
  outline-color: #f59e0bcc;
}

.outline-amber-500\/90 {
  outline-color: #f59e0be6;
}

.outline-amber-500\/95 {
  outline-color: #f59e0bf2;
}

.outline-amber-600 {
  outline-color: #d97706;
}

.outline-amber-600\/0 {
  outline-color: #d9770600;
}

.outline-amber-600\/10 {
  outline-color: #d977061a;
}

.outline-amber-600\/100 {
  outline-color: #d97706;
}

.outline-amber-600\/20 {
  outline-color: #d9770633;
}

.outline-amber-600\/25 {
  outline-color: #d9770640;
}

.outline-amber-600\/30 {
  outline-color: #d977064d;
}

.outline-amber-600\/40 {
  outline-color: #d9770666;
}

.outline-amber-600\/5 {
  outline-color: #d977060d;
}

.outline-amber-600\/50 {
  outline-color: #d9770680;
}

.outline-amber-600\/60 {
  outline-color: #d9770699;
}

.outline-amber-600\/70 {
  outline-color: #d97706b3;
}

.outline-amber-600\/75 {
  outline-color: #d97706bf;
}

.outline-amber-600\/80 {
  outline-color: #d97706cc;
}

.outline-amber-600\/90 {
  outline-color: #d97706e6;
}

.outline-amber-600\/95 {
  outline-color: #d97706f2;
}

.outline-amber-700 {
  outline-color: #b45309;
}

.outline-amber-700\/0 {
  outline-color: #b4530900;
}

.outline-amber-700\/10 {
  outline-color: #b453091a;
}

.outline-amber-700\/100 {
  outline-color: #b45309;
}

.outline-amber-700\/20 {
  outline-color: #b4530933;
}

.outline-amber-700\/25 {
  outline-color: #b4530940;
}

.outline-amber-700\/30 {
  outline-color: #b453094d;
}

.outline-amber-700\/40 {
  outline-color: #b4530966;
}

.outline-amber-700\/5 {
  outline-color: #b453090d;
}

.outline-amber-700\/50 {
  outline-color: #b4530980;
}

.outline-amber-700\/60 {
  outline-color: #b4530999;
}

.outline-amber-700\/70 {
  outline-color: #b45309b3;
}

.outline-amber-700\/75 {
  outline-color: #b45309bf;
}

.outline-amber-700\/80 {
  outline-color: #b45309cc;
}

.outline-amber-700\/90 {
  outline-color: #b45309e6;
}

.outline-amber-700\/95 {
  outline-color: #b45309f2;
}

.outline-amber-800 {
  outline-color: #92400e;
}

.outline-amber-800\/0 {
  outline-color: #92400e00;
}

.outline-amber-800\/10 {
  outline-color: #92400e1a;
}

.outline-amber-800\/100 {
  outline-color: #92400e;
}

.outline-amber-800\/20 {
  outline-color: #92400e33;
}

.outline-amber-800\/25 {
  outline-color: #92400e40;
}

.outline-amber-800\/30 {
  outline-color: #92400e4d;
}

.outline-amber-800\/40 {
  outline-color: #92400e66;
}

.outline-amber-800\/5 {
  outline-color: #92400e0d;
}

.outline-amber-800\/50 {
  outline-color: #92400e80;
}

.outline-amber-800\/60 {
  outline-color: #92400e99;
}

.outline-amber-800\/70 {
  outline-color: #92400eb3;
}

.outline-amber-800\/75 {
  outline-color: #92400ebf;
}

.outline-amber-800\/80 {
  outline-color: #92400ecc;
}

.outline-amber-800\/90 {
  outline-color: #92400ee6;
}

.outline-amber-800\/95 {
  outline-color: #92400ef2;
}

.outline-amber-900 {
  outline-color: #78350f;
}

.outline-amber-900\/0 {
  outline-color: #78350f00;
}

.outline-amber-900\/10 {
  outline-color: #78350f1a;
}

.outline-amber-900\/100 {
  outline-color: #78350f;
}

.outline-amber-900\/20 {
  outline-color: #78350f33;
}

.outline-amber-900\/25 {
  outline-color: #78350f40;
}

.outline-amber-900\/30 {
  outline-color: #78350f4d;
}

.outline-amber-900\/40 {
  outline-color: #78350f66;
}

.outline-amber-900\/5 {
  outline-color: #78350f0d;
}

.outline-amber-900\/50 {
  outline-color: #78350f80;
}

.outline-amber-900\/60 {
  outline-color: #78350f99;
}

.outline-amber-900\/70 {
  outline-color: #78350fb3;
}

.outline-amber-900\/75 {
  outline-color: #78350fbf;
}

.outline-amber-900\/80 {
  outline-color: #78350fcc;
}

.outline-amber-900\/90 {
  outline-color: #78350fe6;
}

.outline-amber-900\/95 {
  outline-color: #78350ff2;
}

.outline-amber-950 {
  outline-color: #451a03;
}

.outline-amber-950\/0 {
  outline-color: #451a0300;
}

.outline-amber-950\/10 {
  outline-color: #451a031a;
}

.outline-amber-950\/100 {
  outline-color: #451a03;
}

.outline-amber-950\/20 {
  outline-color: #451a0333;
}

.outline-amber-950\/25 {
  outline-color: #451a0340;
}

.outline-amber-950\/30 {
  outline-color: #451a034d;
}

.outline-amber-950\/40 {
  outline-color: #451a0366;
}

.outline-amber-950\/5 {
  outline-color: #451a030d;
}

.outline-amber-950\/50 {
  outline-color: #451a0380;
}

.outline-amber-950\/60 {
  outline-color: #451a0399;
}

.outline-amber-950\/70 {
  outline-color: #451a03b3;
}

.outline-amber-950\/75 {
  outline-color: #451a03bf;
}

.outline-amber-950\/80 {
  outline-color: #451a03cc;
}

.outline-amber-950\/90 {
  outline-color: #451a03e6;
}

.outline-amber-950\/95 {
  outline-color: #451a03f2;
}

.outline-emerald-100 {
  outline-color: #d1fae5;
}

.outline-emerald-100\/0 {
  outline-color: #d1fae500;
}

.outline-emerald-100\/10 {
  outline-color: #d1fae51a;
}

.outline-emerald-100\/100 {
  outline-color: #d1fae5;
}

.outline-emerald-100\/20 {
  outline-color: #d1fae533;
}

.outline-emerald-100\/25 {
  outline-color: #d1fae540;
}

.outline-emerald-100\/30 {
  outline-color: #d1fae54d;
}

.outline-emerald-100\/40 {
  outline-color: #d1fae566;
}

.outline-emerald-100\/5 {
  outline-color: #d1fae50d;
}

.outline-emerald-100\/50 {
  outline-color: #d1fae580;
}

.outline-emerald-100\/60 {
  outline-color: #d1fae599;
}

.outline-emerald-100\/70 {
  outline-color: #d1fae5b3;
}

.outline-emerald-100\/75 {
  outline-color: #d1fae5bf;
}

.outline-emerald-100\/80 {
  outline-color: #d1fae5cc;
}

.outline-emerald-100\/90 {
  outline-color: #d1fae5e6;
}

.outline-emerald-100\/95 {
  outline-color: #d1fae5f2;
}

.outline-emerald-200 {
  outline-color: #a7f3d0;
}

.outline-emerald-200\/0 {
  outline-color: #a7f3d000;
}

.outline-emerald-200\/10 {
  outline-color: #a7f3d01a;
}

.outline-emerald-200\/100 {
  outline-color: #a7f3d0;
}

.outline-emerald-200\/20 {
  outline-color: #a7f3d033;
}

.outline-emerald-200\/25 {
  outline-color: #a7f3d040;
}

.outline-emerald-200\/30 {
  outline-color: #a7f3d04d;
}

.outline-emerald-200\/40 {
  outline-color: #a7f3d066;
}

.outline-emerald-200\/5 {
  outline-color: #a7f3d00d;
}

.outline-emerald-200\/50 {
  outline-color: #a7f3d080;
}

.outline-emerald-200\/60 {
  outline-color: #a7f3d099;
}

.outline-emerald-200\/70 {
  outline-color: #a7f3d0b3;
}

.outline-emerald-200\/75 {
  outline-color: #a7f3d0bf;
}

.outline-emerald-200\/80 {
  outline-color: #a7f3d0cc;
}

.outline-emerald-200\/90 {
  outline-color: #a7f3d0e6;
}

.outline-emerald-200\/95 {
  outline-color: #a7f3d0f2;
}

.outline-emerald-300 {
  outline-color: #6ee7b7;
}

.outline-emerald-300\/0 {
  outline-color: #6ee7b700;
}

.outline-emerald-300\/10 {
  outline-color: #6ee7b71a;
}

.outline-emerald-300\/100 {
  outline-color: #6ee7b7;
}

.outline-emerald-300\/20 {
  outline-color: #6ee7b733;
}

.outline-emerald-300\/25 {
  outline-color: #6ee7b740;
}

.outline-emerald-300\/30 {
  outline-color: #6ee7b74d;
}

.outline-emerald-300\/40 {
  outline-color: #6ee7b766;
}

.outline-emerald-300\/5 {
  outline-color: #6ee7b70d;
}

.outline-emerald-300\/50 {
  outline-color: #6ee7b780;
}

.outline-emerald-300\/60 {
  outline-color: #6ee7b799;
}

.outline-emerald-300\/70 {
  outline-color: #6ee7b7b3;
}

.outline-emerald-300\/75 {
  outline-color: #6ee7b7bf;
}

.outline-emerald-300\/80 {
  outline-color: #6ee7b7cc;
}

.outline-emerald-300\/90 {
  outline-color: #6ee7b7e6;
}

.outline-emerald-300\/95 {
  outline-color: #6ee7b7f2;
}

.outline-emerald-400 {
  outline-color: #34d399;
}

.outline-emerald-400\/0 {
  outline-color: #34d39900;
}

.outline-emerald-400\/10 {
  outline-color: #34d3991a;
}

.outline-emerald-400\/100 {
  outline-color: #34d399;
}

.outline-emerald-400\/20 {
  outline-color: #34d39933;
}

.outline-emerald-400\/25 {
  outline-color: #34d39940;
}

.outline-emerald-400\/30 {
  outline-color: #34d3994d;
}

.outline-emerald-400\/40 {
  outline-color: #34d39966;
}

.outline-emerald-400\/5 {
  outline-color: #34d3990d;
}

.outline-emerald-400\/50 {
  outline-color: #34d39980;
}

.outline-emerald-400\/60 {
  outline-color: #34d39999;
}

.outline-emerald-400\/70 {
  outline-color: #34d399b3;
}

.outline-emerald-400\/75 {
  outline-color: #34d399bf;
}

.outline-emerald-400\/80 {
  outline-color: #34d399cc;
}

.outline-emerald-400\/90 {
  outline-color: #34d399e6;
}

.outline-emerald-400\/95 {
  outline-color: #34d399f2;
}

.outline-emerald-50 {
  outline-color: #ecfdf5;
}

.outline-emerald-50\/0 {
  outline-color: #ecfdf500;
}

.outline-emerald-50\/10 {
  outline-color: #ecfdf51a;
}

.outline-emerald-50\/100 {
  outline-color: #ecfdf5;
}

.outline-emerald-50\/20 {
  outline-color: #ecfdf533;
}

.outline-emerald-50\/25 {
  outline-color: #ecfdf540;
}

.outline-emerald-50\/30 {
  outline-color: #ecfdf54d;
}

.outline-emerald-50\/40 {
  outline-color: #ecfdf566;
}

.outline-emerald-50\/5 {
  outline-color: #ecfdf50d;
}

.outline-emerald-50\/50 {
  outline-color: #ecfdf580;
}

.outline-emerald-50\/60 {
  outline-color: #ecfdf599;
}

.outline-emerald-50\/70 {
  outline-color: #ecfdf5b3;
}

.outline-emerald-50\/75 {
  outline-color: #ecfdf5bf;
}

.outline-emerald-50\/80 {
  outline-color: #ecfdf5cc;
}

.outline-emerald-50\/90 {
  outline-color: #ecfdf5e6;
}

.outline-emerald-50\/95 {
  outline-color: #ecfdf5f2;
}

.outline-emerald-500 {
  outline-color: #10b981;
}

.outline-emerald-500\/0 {
  outline-color: #10b98100;
}

.outline-emerald-500\/10 {
  outline-color: #10b9811a;
}

.outline-emerald-500\/100 {
  outline-color: #10b981;
}

.outline-emerald-500\/20 {
  outline-color: #10b98133;
}

.outline-emerald-500\/25 {
  outline-color: #10b98140;
}

.outline-emerald-500\/30 {
  outline-color: #10b9814d;
}

.outline-emerald-500\/40 {
  outline-color: #10b98166;
}

.outline-emerald-500\/5 {
  outline-color: #10b9810d;
}

.outline-emerald-500\/50 {
  outline-color: #10b98180;
}

.outline-emerald-500\/60 {
  outline-color: #10b98199;
}

.outline-emerald-500\/70 {
  outline-color: #10b981b3;
}

.outline-emerald-500\/75 {
  outline-color: #10b981bf;
}

.outline-emerald-500\/80 {
  outline-color: #10b981cc;
}

.outline-emerald-500\/90 {
  outline-color: #10b981e6;
}

.outline-emerald-500\/95 {
  outline-color: #10b981f2;
}

.outline-emerald-600 {
  outline-color: #059669;
}

.outline-emerald-600\/0 {
  outline-color: #05966900;
}

.outline-emerald-600\/10 {
  outline-color: #0596691a;
}

.outline-emerald-600\/100 {
  outline-color: #059669;
}

.outline-emerald-600\/20 {
  outline-color: #05966933;
}

.outline-emerald-600\/25 {
  outline-color: #05966940;
}

.outline-emerald-600\/30 {
  outline-color: #0596694d;
}

.outline-emerald-600\/40 {
  outline-color: #05966966;
}

.outline-emerald-600\/5 {
  outline-color: #0596690d;
}

.outline-emerald-600\/50 {
  outline-color: #05966980;
}

.outline-emerald-600\/60 {
  outline-color: #05966999;
}

.outline-emerald-600\/70 {
  outline-color: #059669b3;
}

.outline-emerald-600\/75 {
  outline-color: #059669bf;
}

.outline-emerald-600\/80 {
  outline-color: #059669cc;
}

.outline-emerald-600\/90 {
  outline-color: #059669e6;
}

.outline-emerald-600\/95 {
  outline-color: #059669f2;
}

.outline-emerald-700 {
  outline-color: #047857;
}

.outline-emerald-700\/0 {
  outline-color: #04785700;
}

.outline-emerald-700\/10 {
  outline-color: #0478571a;
}

.outline-emerald-700\/100 {
  outline-color: #047857;
}

.outline-emerald-700\/20 {
  outline-color: #04785733;
}

.outline-emerald-700\/25 {
  outline-color: #04785740;
}

.outline-emerald-700\/30 {
  outline-color: #0478574d;
}

.outline-emerald-700\/40 {
  outline-color: #04785766;
}

.outline-emerald-700\/5 {
  outline-color: #0478570d;
}

.outline-emerald-700\/50 {
  outline-color: #04785780;
}

.outline-emerald-700\/60 {
  outline-color: #04785799;
}

.outline-emerald-700\/70 {
  outline-color: #047857b3;
}

.outline-emerald-700\/75 {
  outline-color: #047857bf;
}

.outline-emerald-700\/80 {
  outline-color: #047857cc;
}

.outline-emerald-700\/90 {
  outline-color: #047857e6;
}

.outline-emerald-700\/95 {
  outline-color: #047857f2;
}

.outline-emerald-800 {
  outline-color: #065f46;
}

.outline-emerald-800\/0 {
  outline-color: #065f4600;
}

.outline-emerald-800\/10 {
  outline-color: #065f461a;
}

.outline-emerald-800\/100 {
  outline-color: #065f46;
}

.outline-emerald-800\/20 {
  outline-color: #065f4633;
}

.outline-emerald-800\/25 {
  outline-color: #065f4640;
}

.outline-emerald-800\/30 {
  outline-color: #065f464d;
}

.outline-emerald-800\/40 {
  outline-color: #065f4666;
}

.outline-emerald-800\/5 {
  outline-color: #065f460d;
}

.outline-emerald-800\/50 {
  outline-color: #065f4680;
}

.outline-emerald-800\/60 {
  outline-color: #065f4699;
}

.outline-emerald-800\/70 {
  outline-color: #065f46b3;
}

.outline-emerald-800\/75 {
  outline-color: #065f46bf;
}

.outline-emerald-800\/80 {
  outline-color: #065f46cc;
}

.outline-emerald-800\/90 {
  outline-color: #065f46e6;
}

.outline-emerald-800\/95 {
  outline-color: #065f46f2;
}

.outline-emerald-900 {
  outline-color: #064e3b;
}

.outline-emerald-900\/0 {
  outline-color: #064e3b00;
}

.outline-emerald-900\/10 {
  outline-color: #064e3b1a;
}

.outline-emerald-900\/100 {
  outline-color: #064e3b;
}

.outline-emerald-900\/20 {
  outline-color: #064e3b33;
}

.outline-emerald-900\/25 {
  outline-color: #064e3b40;
}

.outline-emerald-900\/30 {
  outline-color: #064e3b4d;
}

.outline-emerald-900\/40 {
  outline-color: #064e3b66;
}

.outline-emerald-900\/5 {
  outline-color: #064e3b0d;
}

.outline-emerald-900\/50 {
  outline-color: #064e3b80;
}

.outline-emerald-900\/60 {
  outline-color: #064e3b99;
}

.outline-emerald-900\/70 {
  outline-color: #064e3bb3;
}

.outline-emerald-900\/75 {
  outline-color: #064e3bbf;
}

.outline-emerald-900\/80 {
  outline-color: #064e3bcc;
}

.outline-emerald-900\/90 {
  outline-color: #064e3be6;
}

.outline-emerald-900\/95 {
  outline-color: #064e3bf2;
}

.outline-emerald-950 {
  outline-color: #022c22;
}

.outline-emerald-950\/0 {
  outline-color: #022c2200;
}

.outline-emerald-950\/10 {
  outline-color: #022c221a;
}

.outline-emerald-950\/100 {
  outline-color: #022c22;
}

.outline-emerald-950\/20 {
  outline-color: #022c2233;
}

.outline-emerald-950\/25 {
  outline-color: #022c2240;
}

.outline-emerald-950\/30 {
  outline-color: #022c224d;
}

.outline-emerald-950\/40 {
  outline-color: #022c2266;
}

.outline-emerald-950\/5 {
  outline-color: #022c220d;
}

.outline-emerald-950\/50 {
  outline-color: #022c2280;
}

.outline-emerald-950\/60 {
  outline-color: #022c2299;
}

.outline-emerald-950\/70 {
  outline-color: #022c22b3;
}

.outline-emerald-950\/75 {
  outline-color: #022c22bf;
}

.outline-emerald-950\/80 {
  outline-color: #022c22cc;
}

.outline-emerald-950\/90 {
  outline-color: #022c22e6;
}

.outline-emerald-950\/95 {
  outline-color: #022c22f2;
}

.outline-lime-100 {
  outline-color: #ecfccb;
}

.outline-lime-100\/0 {
  outline-color: #ecfccb00;
}

.outline-lime-100\/10 {
  outline-color: #ecfccb1a;
}

.outline-lime-100\/100 {
  outline-color: #ecfccb;
}

.outline-lime-100\/20 {
  outline-color: #ecfccb33;
}

.outline-lime-100\/25 {
  outline-color: #ecfccb40;
}

.outline-lime-100\/30 {
  outline-color: #ecfccb4d;
}

.outline-lime-100\/40 {
  outline-color: #ecfccb66;
}

.outline-lime-100\/5 {
  outline-color: #ecfccb0d;
}

.outline-lime-100\/50 {
  outline-color: #ecfccb80;
}

.outline-lime-100\/60 {
  outline-color: #ecfccb99;
}

.outline-lime-100\/70 {
  outline-color: #ecfccbb3;
}

.outline-lime-100\/75 {
  outline-color: #ecfccbbf;
}

.outline-lime-100\/80 {
  outline-color: #ecfccbcc;
}

.outline-lime-100\/90 {
  outline-color: #ecfccbe6;
}

.outline-lime-100\/95 {
  outline-color: #ecfccbf2;
}

.outline-lime-200 {
  outline-color: #d9f99d;
}

.outline-lime-200\/0 {
  outline-color: #d9f99d00;
}

.outline-lime-200\/10 {
  outline-color: #d9f99d1a;
}

.outline-lime-200\/100 {
  outline-color: #d9f99d;
}

.outline-lime-200\/20 {
  outline-color: #d9f99d33;
}

.outline-lime-200\/25 {
  outline-color: #d9f99d40;
}

.outline-lime-200\/30 {
  outline-color: #d9f99d4d;
}

.outline-lime-200\/40 {
  outline-color: #d9f99d66;
}

.outline-lime-200\/5 {
  outline-color: #d9f99d0d;
}

.outline-lime-200\/50 {
  outline-color: #d9f99d80;
}

.outline-lime-200\/60 {
  outline-color: #d9f99d99;
}

.outline-lime-200\/70 {
  outline-color: #d9f99db3;
}

.outline-lime-200\/75 {
  outline-color: #d9f99dbf;
}

.outline-lime-200\/80 {
  outline-color: #d9f99dcc;
}

.outline-lime-200\/90 {
  outline-color: #d9f99de6;
}

.outline-lime-200\/95 {
  outline-color: #d9f99df2;
}

.outline-lime-300 {
  outline-color: #bef264;
}

.outline-lime-300\/0 {
  outline-color: #bef26400;
}

.outline-lime-300\/10 {
  outline-color: #bef2641a;
}

.outline-lime-300\/100 {
  outline-color: #bef264;
}

.outline-lime-300\/20 {
  outline-color: #bef26433;
}

.outline-lime-300\/25 {
  outline-color: #bef26440;
}

.outline-lime-300\/30 {
  outline-color: #bef2644d;
}

.outline-lime-300\/40 {
  outline-color: #bef26466;
}

.outline-lime-300\/5 {
  outline-color: #bef2640d;
}

.outline-lime-300\/50 {
  outline-color: #bef26480;
}

.outline-lime-300\/60 {
  outline-color: #bef26499;
}

.outline-lime-300\/70 {
  outline-color: #bef264b3;
}

.outline-lime-300\/75 {
  outline-color: #bef264bf;
}

.outline-lime-300\/80 {
  outline-color: #bef264cc;
}

.outline-lime-300\/90 {
  outline-color: #bef264e6;
}

.outline-lime-300\/95 {
  outline-color: #bef264f2;
}

.outline-lime-400 {
  outline-color: #a3e635;
}

.outline-lime-400\/0 {
  outline-color: #a3e63500;
}

.outline-lime-400\/10 {
  outline-color: #a3e6351a;
}

.outline-lime-400\/100 {
  outline-color: #a3e635;
}

.outline-lime-400\/20 {
  outline-color: #a3e63533;
}

.outline-lime-400\/25 {
  outline-color: #a3e63540;
}

.outline-lime-400\/30 {
  outline-color: #a3e6354d;
}

.outline-lime-400\/40 {
  outline-color: #a3e63566;
}

.outline-lime-400\/5 {
  outline-color: #a3e6350d;
}

.outline-lime-400\/50 {
  outline-color: #a3e63580;
}

.outline-lime-400\/60 {
  outline-color: #a3e63599;
}

.outline-lime-400\/70 {
  outline-color: #a3e635b3;
}

.outline-lime-400\/75 {
  outline-color: #a3e635bf;
}

.outline-lime-400\/80 {
  outline-color: #a3e635cc;
}

.outline-lime-400\/90 {
  outline-color: #a3e635e6;
}

.outline-lime-400\/95 {
  outline-color: #a3e635f2;
}

.outline-lime-50 {
  outline-color: #f7fee7;
}

.outline-lime-50\/0 {
  outline-color: #f7fee700;
}

.outline-lime-50\/10 {
  outline-color: #f7fee71a;
}

.outline-lime-50\/100 {
  outline-color: #f7fee7;
}

.outline-lime-50\/20 {
  outline-color: #f7fee733;
}

.outline-lime-50\/25 {
  outline-color: #f7fee740;
}

.outline-lime-50\/30 {
  outline-color: #f7fee74d;
}

.outline-lime-50\/40 {
  outline-color: #f7fee766;
}

.outline-lime-50\/5 {
  outline-color: #f7fee70d;
}

.outline-lime-50\/50 {
  outline-color: #f7fee780;
}

.outline-lime-50\/60 {
  outline-color: #f7fee799;
}

.outline-lime-50\/70 {
  outline-color: #f7fee7b3;
}

.outline-lime-50\/75 {
  outline-color: #f7fee7bf;
}

.outline-lime-50\/80 {
  outline-color: #f7fee7cc;
}

.outline-lime-50\/90 {
  outline-color: #f7fee7e6;
}

.outline-lime-50\/95 {
  outline-color: #f7fee7f2;
}

.outline-lime-500 {
  outline-color: #84cc16;
}

.outline-lime-500\/0 {
  outline-color: #84cc1600;
}

.outline-lime-500\/10 {
  outline-color: #84cc161a;
}

.outline-lime-500\/100 {
  outline-color: #84cc16;
}

.outline-lime-500\/20 {
  outline-color: #84cc1633;
}

.outline-lime-500\/25 {
  outline-color: #84cc1640;
}

.outline-lime-500\/30 {
  outline-color: #84cc164d;
}

.outline-lime-500\/40 {
  outline-color: #84cc1666;
}

.outline-lime-500\/5 {
  outline-color: #84cc160d;
}

.outline-lime-500\/50 {
  outline-color: #84cc1680;
}

.outline-lime-500\/60 {
  outline-color: #84cc1699;
}

.outline-lime-500\/70 {
  outline-color: #84cc16b3;
}

.outline-lime-500\/75 {
  outline-color: #84cc16bf;
}

.outline-lime-500\/80 {
  outline-color: #84cc16cc;
}

.outline-lime-500\/90 {
  outline-color: #84cc16e6;
}

.outline-lime-500\/95 {
  outline-color: #84cc16f2;
}

.outline-lime-600 {
  outline-color: #65a30d;
}

.outline-lime-600\/0 {
  outline-color: #65a30d00;
}

.outline-lime-600\/10 {
  outline-color: #65a30d1a;
}

.outline-lime-600\/100 {
  outline-color: #65a30d;
}

.outline-lime-600\/20 {
  outline-color: #65a30d33;
}

.outline-lime-600\/25 {
  outline-color: #65a30d40;
}

.outline-lime-600\/30 {
  outline-color: #65a30d4d;
}

.outline-lime-600\/40 {
  outline-color: #65a30d66;
}

.outline-lime-600\/5 {
  outline-color: #65a30d0d;
}

.outline-lime-600\/50 {
  outline-color: #65a30d80;
}

.outline-lime-600\/60 {
  outline-color: #65a30d99;
}

.outline-lime-600\/70 {
  outline-color: #65a30db3;
}

.outline-lime-600\/75 {
  outline-color: #65a30dbf;
}

.outline-lime-600\/80 {
  outline-color: #65a30dcc;
}

.outline-lime-600\/90 {
  outline-color: #65a30de6;
}

.outline-lime-600\/95 {
  outline-color: #65a30df2;
}

.outline-lime-700 {
  outline-color: #4d7c0f;
}

.outline-lime-700\/0 {
  outline-color: #4d7c0f00;
}

.outline-lime-700\/10 {
  outline-color: #4d7c0f1a;
}

.outline-lime-700\/100 {
  outline-color: #4d7c0f;
}

.outline-lime-700\/20 {
  outline-color: #4d7c0f33;
}

.outline-lime-700\/25 {
  outline-color: #4d7c0f40;
}

.outline-lime-700\/30 {
  outline-color: #4d7c0f4d;
}

.outline-lime-700\/40 {
  outline-color: #4d7c0f66;
}

.outline-lime-700\/5 {
  outline-color: #4d7c0f0d;
}

.outline-lime-700\/50 {
  outline-color: #4d7c0f80;
}

.outline-lime-700\/60 {
  outline-color: #4d7c0f99;
}

.outline-lime-700\/70 {
  outline-color: #4d7c0fb3;
}

.outline-lime-700\/75 {
  outline-color: #4d7c0fbf;
}

.outline-lime-700\/80 {
  outline-color: #4d7c0fcc;
}

.outline-lime-700\/90 {
  outline-color: #4d7c0fe6;
}

.outline-lime-700\/95 {
  outline-color: #4d7c0ff2;
}

.outline-lime-800 {
  outline-color: #3f6212;
}

.outline-lime-800\/0 {
  outline-color: #3f621200;
}

.outline-lime-800\/10 {
  outline-color: #3f62121a;
}

.outline-lime-800\/100 {
  outline-color: #3f6212;
}

.outline-lime-800\/20 {
  outline-color: #3f621233;
}

.outline-lime-800\/25 {
  outline-color: #3f621240;
}

.outline-lime-800\/30 {
  outline-color: #3f62124d;
}

.outline-lime-800\/40 {
  outline-color: #3f621266;
}

.outline-lime-800\/5 {
  outline-color: #3f62120d;
}

.outline-lime-800\/50 {
  outline-color: #3f621280;
}

.outline-lime-800\/60 {
  outline-color: #3f621299;
}

.outline-lime-800\/70 {
  outline-color: #3f6212b3;
}

.outline-lime-800\/75 {
  outline-color: #3f6212bf;
}

.outline-lime-800\/80 {
  outline-color: #3f6212cc;
}

.outline-lime-800\/90 {
  outline-color: #3f6212e6;
}

.outline-lime-800\/95 {
  outline-color: #3f6212f2;
}

.outline-lime-900 {
  outline-color: #365314;
}

.outline-lime-900\/0 {
  outline-color: #36531400;
}

.outline-lime-900\/10 {
  outline-color: #3653141a;
}

.outline-lime-900\/100 {
  outline-color: #365314;
}

.outline-lime-900\/20 {
  outline-color: #36531433;
}

.outline-lime-900\/25 {
  outline-color: #36531440;
}

.outline-lime-900\/30 {
  outline-color: #3653144d;
}

.outline-lime-900\/40 {
  outline-color: #36531466;
}

.outline-lime-900\/5 {
  outline-color: #3653140d;
}

.outline-lime-900\/50 {
  outline-color: #36531480;
}

.outline-lime-900\/60 {
  outline-color: #36531499;
}

.outline-lime-900\/70 {
  outline-color: #365314b3;
}

.outline-lime-900\/75 {
  outline-color: #365314bf;
}

.outline-lime-900\/80 {
  outline-color: #365314cc;
}

.outline-lime-900\/90 {
  outline-color: #365314e6;
}

.outline-lime-900\/95 {
  outline-color: #365314f2;
}

.outline-lime-950 {
  outline-color: #1a2e05;
}

.outline-lime-950\/0 {
  outline-color: #1a2e0500;
}

.outline-lime-950\/10 {
  outline-color: #1a2e051a;
}

.outline-lime-950\/100 {
  outline-color: #1a2e05;
}

.outline-lime-950\/20 {
  outline-color: #1a2e0533;
}

.outline-lime-950\/25 {
  outline-color: #1a2e0540;
}

.outline-lime-950\/30 {
  outline-color: #1a2e054d;
}

.outline-lime-950\/40 {
  outline-color: #1a2e0566;
}

.outline-lime-950\/5 {
  outline-color: #1a2e050d;
}

.outline-lime-950\/50 {
  outline-color: #1a2e0580;
}

.outline-lime-950\/60 {
  outline-color: #1a2e0599;
}

.outline-lime-950\/70 {
  outline-color: #1a2e05b3;
}

.outline-lime-950\/75 {
  outline-color: #1a2e05bf;
}

.outline-lime-950\/80 {
  outline-color: #1a2e05cc;
}

.outline-lime-950\/90 {
  outline-color: #1a2e05e6;
}

.outline-lime-950\/95 {
  outline-color: #1a2e05f2;
}

.outline-pink-100 {
  outline-color: #fce7f3;
}

.outline-pink-100\/0 {
  outline-color: #fce7f300;
}

.outline-pink-100\/10 {
  outline-color: #fce7f31a;
}

.outline-pink-100\/100 {
  outline-color: #fce7f3;
}

.outline-pink-100\/20 {
  outline-color: #fce7f333;
}

.outline-pink-100\/25 {
  outline-color: #fce7f340;
}

.outline-pink-100\/30 {
  outline-color: #fce7f34d;
}

.outline-pink-100\/40 {
  outline-color: #fce7f366;
}

.outline-pink-100\/5 {
  outline-color: #fce7f30d;
}

.outline-pink-100\/50 {
  outline-color: #fce7f380;
}

.outline-pink-100\/60 {
  outline-color: #fce7f399;
}

.outline-pink-100\/70 {
  outline-color: #fce7f3b3;
}

.outline-pink-100\/75 {
  outline-color: #fce7f3bf;
}

.outline-pink-100\/80 {
  outline-color: #fce7f3cc;
}

.outline-pink-100\/90 {
  outline-color: #fce7f3e6;
}

.outline-pink-100\/95 {
  outline-color: #fce7f3f2;
}

.outline-pink-200 {
  outline-color: #fbcfe8;
}

.outline-pink-200\/0 {
  outline-color: #fbcfe800;
}

.outline-pink-200\/10 {
  outline-color: #fbcfe81a;
}

.outline-pink-200\/100 {
  outline-color: #fbcfe8;
}

.outline-pink-200\/20 {
  outline-color: #fbcfe833;
}

.outline-pink-200\/25 {
  outline-color: #fbcfe840;
}

.outline-pink-200\/30 {
  outline-color: #fbcfe84d;
}

.outline-pink-200\/40 {
  outline-color: #fbcfe866;
}

.outline-pink-200\/5 {
  outline-color: #fbcfe80d;
}

.outline-pink-200\/50 {
  outline-color: #fbcfe880;
}

.outline-pink-200\/60 {
  outline-color: #fbcfe899;
}

.outline-pink-200\/70 {
  outline-color: #fbcfe8b3;
}

.outline-pink-200\/75 {
  outline-color: #fbcfe8bf;
}

.outline-pink-200\/80 {
  outline-color: #fbcfe8cc;
}

.outline-pink-200\/90 {
  outline-color: #fbcfe8e6;
}

.outline-pink-200\/95 {
  outline-color: #fbcfe8f2;
}

.outline-pink-300 {
  outline-color: #f9a8d4;
}

.outline-pink-300\/0 {
  outline-color: #f9a8d400;
}

.outline-pink-300\/10 {
  outline-color: #f9a8d41a;
}

.outline-pink-300\/100 {
  outline-color: #f9a8d4;
}

.outline-pink-300\/20 {
  outline-color: #f9a8d433;
}

.outline-pink-300\/25 {
  outline-color: #f9a8d440;
}

.outline-pink-300\/30 {
  outline-color: #f9a8d44d;
}

.outline-pink-300\/40 {
  outline-color: #f9a8d466;
}

.outline-pink-300\/5 {
  outline-color: #f9a8d40d;
}

.outline-pink-300\/50 {
  outline-color: #f9a8d480;
}

.outline-pink-300\/60 {
  outline-color: #f9a8d499;
}

.outline-pink-300\/70 {
  outline-color: #f9a8d4b3;
}

.outline-pink-300\/75 {
  outline-color: #f9a8d4bf;
}

.outline-pink-300\/80 {
  outline-color: #f9a8d4cc;
}

.outline-pink-300\/90 {
  outline-color: #f9a8d4e6;
}

.outline-pink-300\/95 {
  outline-color: #f9a8d4f2;
}

.outline-pink-400 {
  outline-color: #f472b6;
}

.outline-pink-400\/0 {
  outline-color: #f472b600;
}

.outline-pink-400\/10 {
  outline-color: #f472b61a;
}

.outline-pink-400\/100 {
  outline-color: #f472b6;
}

.outline-pink-400\/20 {
  outline-color: #f472b633;
}

.outline-pink-400\/25 {
  outline-color: #f472b640;
}

.outline-pink-400\/30 {
  outline-color: #f472b64d;
}

.outline-pink-400\/40 {
  outline-color: #f472b666;
}

.outline-pink-400\/5 {
  outline-color: #f472b60d;
}

.outline-pink-400\/50 {
  outline-color: #f472b680;
}

.outline-pink-400\/60 {
  outline-color: #f472b699;
}

.outline-pink-400\/70 {
  outline-color: #f472b6b3;
}

.outline-pink-400\/75 {
  outline-color: #f472b6bf;
}

.outline-pink-400\/80 {
  outline-color: #f472b6cc;
}

.outline-pink-400\/90 {
  outline-color: #f472b6e6;
}

.outline-pink-400\/95 {
  outline-color: #f472b6f2;
}

.outline-pink-50 {
  outline-color: #fdf2f8;
}

.outline-pink-50\/0 {
  outline-color: #fdf2f800;
}

.outline-pink-50\/10 {
  outline-color: #fdf2f81a;
}

.outline-pink-50\/100 {
  outline-color: #fdf2f8;
}

.outline-pink-50\/20 {
  outline-color: #fdf2f833;
}

.outline-pink-50\/25 {
  outline-color: #fdf2f840;
}

.outline-pink-50\/30 {
  outline-color: #fdf2f84d;
}

.outline-pink-50\/40 {
  outline-color: #fdf2f866;
}

.outline-pink-50\/5 {
  outline-color: #fdf2f80d;
}

.outline-pink-50\/50 {
  outline-color: #fdf2f880;
}

.outline-pink-50\/60 {
  outline-color: #fdf2f899;
}

.outline-pink-50\/70 {
  outline-color: #fdf2f8b3;
}

.outline-pink-50\/75 {
  outline-color: #fdf2f8bf;
}

.outline-pink-50\/80 {
  outline-color: #fdf2f8cc;
}

.outline-pink-50\/90 {
  outline-color: #fdf2f8e6;
}

.outline-pink-50\/95 {
  outline-color: #fdf2f8f2;
}

.outline-pink-500 {
  outline-color: #ec4899;
}

.outline-pink-500\/0 {
  outline-color: #ec489900;
}

.outline-pink-500\/10 {
  outline-color: #ec48991a;
}

.outline-pink-500\/100 {
  outline-color: #ec4899;
}

.outline-pink-500\/20 {
  outline-color: #ec489933;
}

.outline-pink-500\/25 {
  outline-color: #ec489940;
}

.outline-pink-500\/30 {
  outline-color: #ec48994d;
}

.outline-pink-500\/40 {
  outline-color: #ec489966;
}

.outline-pink-500\/5 {
  outline-color: #ec48990d;
}

.outline-pink-500\/50 {
  outline-color: #ec489980;
}

.outline-pink-500\/60 {
  outline-color: #ec489999;
}

.outline-pink-500\/70 {
  outline-color: #ec4899b3;
}

.outline-pink-500\/75 {
  outline-color: #ec4899bf;
}

.outline-pink-500\/80 {
  outline-color: #ec4899cc;
}

.outline-pink-500\/90 {
  outline-color: #ec4899e6;
}

.outline-pink-500\/95 {
  outline-color: #ec4899f2;
}

.outline-pink-600 {
  outline-color: #db2777;
}

.outline-pink-600\/0 {
  outline-color: #db277700;
}

.outline-pink-600\/10 {
  outline-color: #db27771a;
}

.outline-pink-600\/100 {
  outline-color: #db2777;
}

.outline-pink-600\/20 {
  outline-color: #db277733;
}

.outline-pink-600\/25 {
  outline-color: #db277740;
}

.outline-pink-600\/30 {
  outline-color: #db27774d;
}

.outline-pink-600\/40 {
  outline-color: #db277766;
}

.outline-pink-600\/5 {
  outline-color: #db27770d;
}

.outline-pink-600\/50 {
  outline-color: #db277780;
}

.outline-pink-600\/60 {
  outline-color: #db277799;
}

.outline-pink-600\/70 {
  outline-color: #db2777b3;
}

.outline-pink-600\/75 {
  outline-color: #db2777bf;
}

.outline-pink-600\/80 {
  outline-color: #db2777cc;
}

.outline-pink-600\/90 {
  outline-color: #db2777e6;
}

.outline-pink-600\/95 {
  outline-color: #db2777f2;
}

.outline-pink-700 {
  outline-color: #be185d;
}

.outline-pink-700\/0 {
  outline-color: #be185d00;
}

.outline-pink-700\/10 {
  outline-color: #be185d1a;
}

.outline-pink-700\/100 {
  outline-color: #be185d;
}

.outline-pink-700\/20 {
  outline-color: #be185d33;
}

.outline-pink-700\/25 {
  outline-color: #be185d40;
}

.outline-pink-700\/30 {
  outline-color: #be185d4d;
}

.outline-pink-700\/40 {
  outline-color: #be185d66;
}

.outline-pink-700\/5 {
  outline-color: #be185d0d;
}

.outline-pink-700\/50 {
  outline-color: #be185d80;
}

.outline-pink-700\/60 {
  outline-color: #be185d99;
}

.outline-pink-700\/70 {
  outline-color: #be185db3;
}

.outline-pink-700\/75 {
  outline-color: #be185dbf;
}

.outline-pink-700\/80 {
  outline-color: #be185dcc;
}

.outline-pink-700\/90 {
  outline-color: #be185de6;
}

.outline-pink-700\/95 {
  outline-color: #be185df2;
}

.outline-pink-800 {
  outline-color: #9d174d;
}

.outline-pink-800\/0 {
  outline-color: #9d174d00;
}

.outline-pink-800\/10 {
  outline-color: #9d174d1a;
}

.outline-pink-800\/100 {
  outline-color: #9d174d;
}

.outline-pink-800\/20 {
  outline-color: #9d174d33;
}

.outline-pink-800\/25 {
  outline-color: #9d174d40;
}

.outline-pink-800\/30 {
  outline-color: #9d174d4d;
}

.outline-pink-800\/40 {
  outline-color: #9d174d66;
}

.outline-pink-800\/5 {
  outline-color: #9d174d0d;
}

.outline-pink-800\/50 {
  outline-color: #9d174d80;
}

.outline-pink-800\/60 {
  outline-color: #9d174d99;
}

.outline-pink-800\/70 {
  outline-color: #9d174db3;
}

.outline-pink-800\/75 {
  outline-color: #9d174dbf;
}

.outline-pink-800\/80 {
  outline-color: #9d174dcc;
}

.outline-pink-800\/90 {
  outline-color: #9d174de6;
}

.outline-pink-800\/95 {
  outline-color: #9d174df2;
}

.outline-pink-900 {
  outline-color: #831843;
}

.outline-pink-900\/0 {
  outline-color: #83184300;
}

.outline-pink-900\/10 {
  outline-color: #8318431a;
}

.outline-pink-900\/100 {
  outline-color: #831843;
}

.outline-pink-900\/20 {
  outline-color: #83184333;
}

.outline-pink-900\/25 {
  outline-color: #83184340;
}

.outline-pink-900\/30 {
  outline-color: #8318434d;
}

.outline-pink-900\/40 {
  outline-color: #83184366;
}

.outline-pink-900\/5 {
  outline-color: #8318430d;
}

.outline-pink-900\/50 {
  outline-color: #83184380;
}

.outline-pink-900\/60 {
  outline-color: #83184399;
}

.outline-pink-900\/70 {
  outline-color: #831843b3;
}

.outline-pink-900\/75 {
  outline-color: #831843bf;
}

.outline-pink-900\/80 {
  outline-color: #831843cc;
}

.outline-pink-900\/90 {
  outline-color: #831843e6;
}

.outline-pink-900\/95 {
  outline-color: #831843f2;
}

.outline-pink-950 {
  outline-color: #500724;
}

.outline-pink-950\/0 {
  outline-color: #50072400;
}

.outline-pink-950\/10 {
  outline-color: #5007241a;
}

.outline-pink-950\/100 {
  outline-color: #500724;
}

.outline-pink-950\/20 {
  outline-color: #50072433;
}

.outline-pink-950\/25 {
  outline-color: #50072440;
}

.outline-pink-950\/30 {
  outline-color: #5007244d;
}

.outline-pink-950\/40 {
  outline-color: #50072466;
}

.outline-pink-950\/5 {
  outline-color: #5007240d;
}

.outline-pink-950\/50 {
  outline-color: #50072480;
}

.outline-pink-950\/60 {
  outline-color: #50072499;
}

.outline-pink-950\/70 {
  outline-color: #500724b3;
}

.outline-pink-950\/75 {
  outline-color: #500724bf;
}

.outline-pink-950\/80 {
  outline-color: #500724cc;
}

.outline-pink-950\/90 {
  outline-color: #500724e6;
}

.outline-pink-950\/95 {
  outline-color: #500724f2;
}

.outline-primary {
  outline-color: hsl(var(--primary) / 1);
}

.outline-primary\/0 {
  outline-color: hsl(var(--primary) / 0);
}

.outline-primary\/10 {
  outline-color: hsl(var(--primary) / .1);
}

.outline-primary\/100 {
  outline-color: hsl(var(--primary) / 1);
}

.outline-primary\/20 {
  outline-color: hsl(var(--primary) / .2);
}

.outline-primary\/25 {
  outline-color: hsl(var(--primary) / .25);
}

.outline-primary\/30 {
  outline-color: hsl(var(--primary) / .3);
}

.outline-primary\/40 {
  outline-color: hsl(var(--primary) / .4);
}

.outline-primary\/5 {
  outline-color: hsl(var(--primary) / .05);
}

.outline-primary\/50 {
  outline-color: hsl(var(--primary) / .5);
}

.outline-primary\/60 {
  outline-color: hsl(var(--primary) / .6);
}

.outline-primary\/70 {
  outline-color: hsl(var(--primary) / .7);
}

.outline-primary\/75 {
  outline-color: hsl(var(--primary) / .75);
}

.outline-primary\/80 {
  outline-color: hsl(var(--primary) / .8);
}

.outline-primary\/90 {
  outline-color: hsl(var(--primary) / .9);
}

.outline-primary\/95 {
  outline-color: hsl(var(--primary) / .95);
}

.outline-purple-100 {
  outline-color: #f3e8ff;
}

.outline-purple-100\/0 {
  outline-color: #f3e8ff00;
}

.outline-purple-100\/10 {
  outline-color: #f3e8ff1a;
}

.outline-purple-100\/100 {
  outline-color: #f3e8ff;
}

.outline-purple-100\/20 {
  outline-color: #f3e8ff33;
}

.outline-purple-100\/25 {
  outline-color: #f3e8ff40;
}

.outline-purple-100\/30 {
  outline-color: #f3e8ff4d;
}

.outline-purple-100\/40 {
  outline-color: #f3e8ff66;
}

.outline-purple-100\/5 {
  outline-color: #f3e8ff0d;
}

.outline-purple-100\/50 {
  outline-color: #f3e8ff80;
}

.outline-purple-100\/60 {
  outline-color: #f3e8ff99;
}

.outline-purple-100\/70 {
  outline-color: #f3e8ffb3;
}

.outline-purple-100\/75 {
  outline-color: #f3e8ffbf;
}

.outline-purple-100\/80 {
  outline-color: #f3e8ffcc;
}

.outline-purple-100\/90 {
  outline-color: #f3e8ffe6;
}

.outline-purple-100\/95 {
  outline-color: #f3e8fff2;
}

.outline-purple-200 {
  outline-color: #e9d5ff;
}

.outline-purple-200\/0 {
  outline-color: #e9d5ff00;
}

.outline-purple-200\/10 {
  outline-color: #e9d5ff1a;
}

.outline-purple-200\/100 {
  outline-color: #e9d5ff;
}

.outline-purple-200\/20 {
  outline-color: #e9d5ff33;
}

.outline-purple-200\/25 {
  outline-color: #e9d5ff40;
}

.outline-purple-200\/30 {
  outline-color: #e9d5ff4d;
}

.outline-purple-200\/40 {
  outline-color: #e9d5ff66;
}

.outline-purple-200\/5 {
  outline-color: #e9d5ff0d;
}

.outline-purple-200\/50 {
  outline-color: #e9d5ff80;
}

.outline-purple-200\/60 {
  outline-color: #e9d5ff99;
}

.outline-purple-200\/70 {
  outline-color: #e9d5ffb3;
}

.outline-purple-200\/75 {
  outline-color: #e9d5ffbf;
}

.outline-purple-200\/80 {
  outline-color: #e9d5ffcc;
}

.outline-purple-200\/90 {
  outline-color: #e9d5ffe6;
}

.outline-purple-200\/95 {
  outline-color: #e9d5fff2;
}

.outline-purple-300 {
  outline-color: #d8b4fe;
}

.outline-purple-300\/0 {
  outline-color: #d8b4fe00;
}

.outline-purple-300\/10 {
  outline-color: #d8b4fe1a;
}

.outline-purple-300\/100 {
  outline-color: #d8b4fe;
}

.outline-purple-300\/20 {
  outline-color: #d8b4fe33;
}

.outline-purple-300\/25 {
  outline-color: #d8b4fe40;
}

.outline-purple-300\/30 {
  outline-color: #d8b4fe4d;
}

.outline-purple-300\/40 {
  outline-color: #d8b4fe66;
}

.outline-purple-300\/5 {
  outline-color: #d8b4fe0d;
}

.outline-purple-300\/50 {
  outline-color: #d8b4fe80;
}

.outline-purple-300\/60 {
  outline-color: #d8b4fe99;
}

.outline-purple-300\/70 {
  outline-color: #d8b4feb3;
}

.outline-purple-300\/75 {
  outline-color: #d8b4febf;
}

.outline-purple-300\/80 {
  outline-color: #d8b4fecc;
}

.outline-purple-300\/90 {
  outline-color: #d8b4fee6;
}

.outline-purple-300\/95 {
  outline-color: #d8b4fef2;
}

.outline-purple-400 {
  outline-color: #c084fc;
}

.outline-purple-400\/0 {
  outline-color: #c084fc00;
}

.outline-purple-400\/10 {
  outline-color: #c084fc1a;
}

.outline-purple-400\/100 {
  outline-color: #c084fc;
}

.outline-purple-400\/20 {
  outline-color: #c084fc33;
}

.outline-purple-400\/25 {
  outline-color: #c084fc40;
}

.outline-purple-400\/30 {
  outline-color: #c084fc4d;
}

.outline-purple-400\/40 {
  outline-color: #c084fc66;
}

.outline-purple-400\/5 {
  outline-color: #c084fc0d;
}

.outline-purple-400\/50 {
  outline-color: #c084fc80;
}

.outline-purple-400\/60 {
  outline-color: #c084fc99;
}

.outline-purple-400\/70 {
  outline-color: #c084fcb3;
}

.outline-purple-400\/75 {
  outline-color: #c084fcbf;
}

.outline-purple-400\/80 {
  outline-color: #c084fccc;
}

.outline-purple-400\/90 {
  outline-color: #c084fce6;
}

.outline-purple-400\/95 {
  outline-color: #c084fcf2;
}

.outline-purple-50 {
  outline-color: #faf5ff;
}

.outline-purple-50\/0 {
  outline-color: #faf5ff00;
}

.outline-purple-50\/10 {
  outline-color: #faf5ff1a;
}

.outline-purple-50\/100 {
  outline-color: #faf5ff;
}

.outline-purple-50\/20 {
  outline-color: #faf5ff33;
}

.outline-purple-50\/25 {
  outline-color: #faf5ff40;
}

.outline-purple-50\/30 {
  outline-color: #faf5ff4d;
}

.outline-purple-50\/40 {
  outline-color: #faf5ff66;
}

.outline-purple-50\/5 {
  outline-color: #faf5ff0d;
}

.outline-purple-50\/50 {
  outline-color: #faf5ff80;
}

.outline-purple-50\/60 {
  outline-color: #faf5ff99;
}

.outline-purple-50\/70 {
  outline-color: #faf5ffb3;
}

.outline-purple-50\/75 {
  outline-color: #faf5ffbf;
}

.outline-purple-50\/80 {
  outline-color: #faf5ffcc;
}

.outline-purple-50\/90 {
  outline-color: #faf5ffe6;
}

.outline-purple-50\/95 {
  outline-color: #faf5fff2;
}

.outline-purple-500 {
  outline-color: #a855f7;
}

.outline-purple-500\/0 {
  outline-color: #a855f700;
}

.outline-purple-500\/10 {
  outline-color: #a855f71a;
}

.outline-purple-500\/100 {
  outline-color: #a855f7;
}

.outline-purple-500\/20 {
  outline-color: #a855f733;
}

.outline-purple-500\/25 {
  outline-color: #a855f740;
}

.outline-purple-500\/30 {
  outline-color: #a855f74d;
}

.outline-purple-500\/40 {
  outline-color: #a855f766;
}

.outline-purple-500\/5 {
  outline-color: #a855f70d;
}

.outline-purple-500\/50 {
  outline-color: #a855f780;
}

.outline-purple-500\/60 {
  outline-color: #a855f799;
}

.outline-purple-500\/70 {
  outline-color: #a855f7b3;
}

.outline-purple-500\/75 {
  outline-color: #a855f7bf;
}

.outline-purple-500\/80 {
  outline-color: #a855f7cc;
}

.outline-purple-500\/90 {
  outline-color: #a855f7e6;
}

.outline-purple-500\/95 {
  outline-color: #a855f7f2;
}

.outline-purple-600 {
  outline-color: #9333ea;
}

.outline-purple-600\/0 {
  outline-color: #9333ea00;
}

.outline-purple-600\/10 {
  outline-color: #9333ea1a;
}

.outline-purple-600\/100 {
  outline-color: #9333ea;
}

.outline-purple-600\/20 {
  outline-color: #9333ea33;
}

.outline-purple-600\/25 {
  outline-color: #9333ea40;
}

.outline-purple-600\/30 {
  outline-color: #9333ea4d;
}

.outline-purple-600\/40 {
  outline-color: #9333ea66;
}

.outline-purple-600\/5 {
  outline-color: #9333ea0d;
}

.outline-purple-600\/50 {
  outline-color: #9333ea80;
}

.outline-purple-600\/60 {
  outline-color: #9333ea99;
}

.outline-purple-600\/70 {
  outline-color: #9333eab3;
}

.outline-purple-600\/75 {
  outline-color: #9333eabf;
}

.outline-purple-600\/80 {
  outline-color: #9333eacc;
}

.outline-purple-600\/90 {
  outline-color: #9333eae6;
}

.outline-purple-600\/95 {
  outline-color: #9333eaf2;
}

.outline-purple-700 {
  outline-color: #7e22ce;
}

.outline-purple-700\/0 {
  outline-color: #7e22ce00;
}

.outline-purple-700\/10 {
  outline-color: #7e22ce1a;
}

.outline-purple-700\/100 {
  outline-color: #7e22ce;
}

.outline-purple-700\/20 {
  outline-color: #7e22ce33;
}

.outline-purple-700\/25 {
  outline-color: #7e22ce40;
}

.outline-purple-700\/30 {
  outline-color: #7e22ce4d;
}

.outline-purple-700\/40 {
  outline-color: #7e22ce66;
}

.outline-purple-700\/5 {
  outline-color: #7e22ce0d;
}

.outline-purple-700\/50 {
  outline-color: #7e22ce80;
}

.outline-purple-700\/60 {
  outline-color: #7e22ce99;
}

.outline-purple-700\/70 {
  outline-color: #7e22ceb3;
}

.outline-purple-700\/75 {
  outline-color: #7e22cebf;
}

.outline-purple-700\/80 {
  outline-color: #7e22cecc;
}

.outline-purple-700\/90 {
  outline-color: #7e22cee6;
}

.outline-purple-700\/95 {
  outline-color: #7e22cef2;
}

.outline-purple-800 {
  outline-color: #6b21a8;
}

.outline-purple-800\/0 {
  outline-color: #6b21a800;
}

.outline-purple-800\/10 {
  outline-color: #6b21a81a;
}

.outline-purple-800\/100 {
  outline-color: #6b21a8;
}

.outline-purple-800\/20 {
  outline-color: #6b21a833;
}

.outline-purple-800\/25 {
  outline-color: #6b21a840;
}

.outline-purple-800\/30 {
  outline-color: #6b21a84d;
}

.outline-purple-800\/40 {
  outline-color: #6b21a866;
}

.outline-purple-800\/5 {
  outline-color: #6b21a80d;
}

.outline-purple-800\/50 {
  outline-color: #6b21a880;
}

.outline-purple-800\/60 {
  outline-color: #6b21a899;
}

.outline-purple-800\/70 {
  outline-color: #6b21a8b3;
}

.outline-purple-800\/75 {
  outline-color: #6b21a8bf;
}

.outline-purple-800\/80 {
  outline-color: #6b21a8cc;
}

.outline-purple-800\/90 {
  outline-color: #6b21a8e6;
}

.outline-purple-800\/95 {
  outline-color: #6b21a8f2;
}

.outline-purple-900 {
  outline-color: #581c87;
}

.outline-purple-900\/0 {
  outline-color: #581c8700;
}

.outline-purple-900\/10 {
  outline-color: #581c871a;
}

.outline-purple-900\/100 {
  outline-color: #581c87;
}

.outline-purple-900\/20 {
  outline-color: #581c8733;
}

.outline-purple-900\/25 {
  outline-color: #581c8740;
}

.outline-purple-900\/30 {
  outline-color: #581c874d;
}

.outline-purple-900\/40 {
  outline-color: #581c8766;
}

.outline-purple-900\/5 {
  outline-color: #581c870d;
}

.outline-purple-900\/50 {
  outline-color: #581c8780;
}

.outline-purple-900\/60 {
  outline-color: #581c8799;
}

.outline-purple-900\/70 {
  outline-color: #581c87b3;
}

.outline-purple-900\/75 {
  outline-color: #581c87bf;
}

.outline-purple-900\/80 {
  outline-color: #581c87cc;
}

.outline-purple-900\/90 {
  outline-color: #581c87e6;
}

.outline-purple-900\/95 {
  outline-color: #581c87f2;
}

.outline-purple-950 {
  outline-color: #3b0764;
}

.outline-purple-950\/0 {
  outline-color: #3b076400;
}

.outline-purple-950\/10 {
  outline-color: #3b07641a;
}

.outline-purple-950\/100 {
  outline-color: #3b0764;
}

.outline-purple-950\/20 {
  outline-color: #3b076433;
}

.outline-purple-950\/25 {
  outline-color: #3b076440;
}

.outline-purple-950\/30 {
  outline-color: #3b07644d;
}

.outline-purple-950\/40 {
  outline-color: #3b076466;
}

.outline-purple-950\/5 {
  outline-color: #3b07640d;
}

.outline-purple-950\/50 {
  outline-color: #3b076480;
}

.outline-purple-950\/60 {
  outline-color: #3b076499;
}

.outline-purple-950\/70 {
  outline-color: #3b0764b3;
}

.outline-purple-950\/75 {
  outline-color: #3b0764bf;
}

.outline-purple-950\/80 {
  outline-color: #3b0764cc;
}

.outline-purple-950\/90 {
  outline-color: #3b0764e6;
}

.outline-purple-950\/95 {
  outline-color: #3b0764f2;
}

.outline-transparent, .outline-transparent\/0 {
  outline-color: #0000;
}

.outline-transparent\/10 {
  outline-color: #0000001a;
}

.outline-transparent\/100 {
  outline-color: #000;
}

.outline-transparent\/20 {
  outline-color: #0003;
}

.outline-transparent\/25 {
  outline-color: #00000040;
}

.outline-transparent\/30 {
  outline-color: #0000004d;
}

.outline-transparent\/40 {
  outline-color: #0006;
}

.outline-transparent\/5 {
  outline-color: #0000000d;
}

.outline-transparent\/50 {
  outline-color: #00000080;
}

.outline-transparent\/60 {
  outline-color: #0009;
}

.outline-transparent\/70 {
  outline-color: #000000b3;
}

.outline-transparent\/75 {
  outline-color: #000000bf;
}

.outline-transparent\/80 {
  outline-color: #000c;
}

.outline-transparent\/90 {
  outline-color: #000000e6;
}

.outline-transparent\/95 {
  outline-color: #000000f2;
}

.ring-amber-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 243 199 / var(--tw-ring-opacity));
}

.ring-amber-100\/0 {
  --tw-ring-color: #fef3c700;
}

.ring-amber-100\/10 {
  --tw-ring-color: #fef3c71a;
}

.ring-amber-100\/100 {
  --tw-ring-color: #fef3c7;
}

.ring-amber-100\/20 {
  --tw-ring-color: #fef3c733;
}

.ring-amber-100\/25 {
  --tw-ring-color: #fef3c740;
}

.ring-amber-100\/30 {
  --tw-ring-color: #fef3c74d;
}

.ring-amber-100\/40 {
  --tw-ring-color: #fef3c766;
}

.ring-amber-100\/5 {
  --tw-ring-color: #fef3c70d;
}

.ring-amber-100\/50 {
  --tw-ring-color: #fef3c780;
}

.ring-amber-100\/60 {
  --tw-ring-color: #fef3c799;
}

.ring-amber-100\/70 {
  --tw-ring-color: #fef3c7b3;
}

.ring-amber-100\/75 {
  --tw-ring-color: #fef3c7bf;
}

.ring-amber-100\/80 {
  --tw-ring-color: #fef3c7cc;
}

.ring-amber-100\/90 {
  --tw-ring-color: #fef3c7e6;
}

.ring-amber-100\/95 {
  --tw-ring-color: #fef3c7f2;
}

.ring-amber-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 230 138 / var(--tw-ring-opacity));
}

.ring-amber-200\/0 {
  --tw-ring-color: #fde68a00;
}

.ring-amber-200\/10 {
  --tw-ring-color: #fde68a1a;
}

.ring-amber-200\/100 {
  --tw-ring-color: #fde68a;
}

.ring-amber-200\/20 {
  --tw-ring-color: #fde68a33;
}

.ring-amber-200\/25 {
  --tw-ring-color: #fde68a40;
}

.ring-amber-200\/30 {
  --tw-ring-color: #fde68a4d;
}

.ring-amber-200\/40 {
  --tw-ring-color: #fde68a66;
}

.ring-amber-200\/5 {
  --tw-ring-color: #fde68a0d;
}

.ring-amber-200\/50 {
  --tw-ring-color: #fde68a80;
}

.ring-amber-200\/60 {
  --tw-ring-color: #fde68a99;
}

.ring-amber-200\/70 {
  --tw-ring-color: #fde68ab3;
}

.ring-amber-200\/75 {
  --tw-ring-color: #fde68abf;
}

.ring-amber-200\/80 {
  --tw-ring-color: #fde68acc;
}

.ring-amber-200\/90 {
  --tw-ring-color: #fde68ae6;
}

.ring-amber-200\/95 {
  --tw-ring-color: #fde68af2;
}

.ring-amber-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity));
}

.ring-amber-300\/0 {
  --tw-ring-color: #fcd34d00;
}

.ring-amber-300\/10 {
  --tw-ring-color: #fcd34d1a;
}

.ring-amber-300\/100 {
  --tw-ring-color: #fcd34d;
}

.ring-amber-300\/20 {
  --tw-ring-color: #fcd34d33;
}

.ring-amber-300\/25 {
  --tw-ring-color: #fcd34d40;
}

.ring-amber-300\/30 {
  --tw-ring-color: #fcd34d4d;
}

.ring-amber-300\/40 {
  --tw-ring-color: #fcd34d66;
}

.ring-amber-300\/5 {
  --tw-ring-color: #fcd34d0d;
}

.ring-amber-300\/50 {
  --tw-ring-color: #fcd34d80;
}

.ring-amber-300\/60 {
  --tw-ring-color: #fcd34d99;
}

.ring-amber-300\/70 {
  --tw-ring-color: #fcd34db3;
}

.ring-amber-300\/75 {
  --tw-ring-color: #fcd34dbf;
}

.ring-amber-300\/80 {
  --tw-ring-color: #fcd34dcc;
}

.ring-amber-300\/90 {
  --tw-ring-color: #fcd34de6;
}

.ring-amber-300\/95 {
  --tw-ring-color: #fcd34df2;
}

.ring-amber-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity));
}

.ring-amber-400\/0 {
  --tw-ring-color: #fbbf2400;
}

.ring-amber-400\/10 {
  --tw-ring-color: #fbbf241a;
}

.ring-amber-400\/100 {
  --tw-ring-color: #fbbf24;
}

.ring-amber-400\/20 {
  --tw-ring-color: #fbbf2433;
}

.ring-amber-400\/25 {
  --tw-ring-color: #fbbf2440;
}

.ring-amber-400\/30 {
  --tw-ring-color: #fbbf244d;
}

.ring-amber-400\/40 {
  --tw-ring-color: #fbbf2466;
}

.ring-amber-400\/5 {
  --tw-ring-color: #fbbf240d;
}

.ring-amber-400\/50 {
  --tw-ring-color: #fbbf2480;
}

.ring-amber-400\/60 {
  --tw-ring-color: #fbbf2499;
}

.ring-amber-400\/70 {
  --tw-ring-color: #fbbf24b3;
}

.ring-amber-400\/75 {
  --tw-ring-color: #fbbf24bf;
}

.ring-amber-400\/80 {
  --tw-ring-color: #fbbf24cc;
}

.ring-amber-400\/90 {
  --tw-ring-color: #fbbf24e6;
}

.ring-amber-400\/95 {
  --tw-ring-color: #fbbf24f2;
}

.ring-amber-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 251 235 / var(--tw-ring-opacity));
}

.ring-amber-50\/0 {
  --tw-ring-color: #fffbeb00;
}

.ring-amber-50\/10 {
  --tw-ring-color: #fffbeb1a;
}

.ring-amber-50\/100 {
  --tw-ring-color: #fffbeb;
}

.ring-amber-50\/20 {
  --tw-ring-color: #fffbeb33;
}

.ring-amber-50\/25 {
  --tw-ring-color: #fffbeb40;
}

.ring-amber-50\/30 {
  --tw-ring-color: #fffbeb4d;
}

.ring-amber-50\/40 {
  --tw-ring-color: #fffbeb66;
}

.ring-amber-50\/5 {
  --tw-ring-color: #fffbeb0d;
}

.ring-amber-50\/50 {
  --tw-ring-color: #fffbeb80;
}

.ring-amber-50\/60 {
  --tw-ring-color: #fffbeb99;
}

.ring-amber-50\/70 {
  --tw-ring-color: #fffbebb3;
}

.ring-amber-50\/75 {
  --tw-ring-color: #fffbebbf;
}

.ring-amber-50\/80 {
  --tw-ring-color: #fffbebcc;
}

.ring-amber-50\/90 {
  --tw-ring-color: #fffbebe6;
}

.ring-amber-50\/95 {
  --tw-ring-color: #fffbebf2;
}

.ring-amber-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity));
}

.ring-amber-500\/0 {
  --tw-ring-color: #f59e0b00;
}

.ring-amber-500\/10 {
  --tw-ring-color: #f59e0b1a;
}

.ring-amber-500\/100 {
  --tw-ring-color: #f59e0b;
}

.ring-amber-500\/20 {
  --tw-ring-color: #f59e0b33;
}

.ring-amber-500\/25 {
  --tw-ring-color: #f59e0b40;
}

.ring-amber-500\/30 {
  --tw-ring-color: #f59e0b4d;
}

.ring-amber-500\/40 {
  --tw-ring-color: #f59e0b66;
}

.ring-amber-500\/5 {
  --tw-ring-color: #f59e0b0d;
}

.ring-amber-500\/50 {
  --tw-ring-color: #f59e0b80;
}

.ring-amber-500\/60 {
  --tw-ring-color: #f59e0b99;
}

.ring-amber-500\/70 {
  --tw-ring-color: #f59e0bb3;
}

.ring-amber-500\/75 {
  --tw-ring-color: #f59e0bbf;
}

.ring-amber-500\/80 {
  --tw-ring-color: #f59e0bcc;
}

.ring-amber-500\/90 {
  --tw-ring-color: #f59e0be6;
}

.ring-amber-500\/95 {
  --tw-ring-color: #f59e0bf2;
}

.ring-amber-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity));
}

.ring-amber-600\/0 {
  --tw-ring-color: #d9770600;
}

.ring-amber-600\/10 {
  --tw-ring-color: #d977061a;
}

.ring-amber-600\/100 {
  --tw-ring-color: #d97706;
}

.ring-amber-600\/20 {
  --tw-ring-color: #d9770633;
}

.ring-amber-600\/25 {
  --tw-ring-color: #d9770640;
}

.ring-amber-600\/30 {
  --tw-ring-color: #d977064d;
}

.ring-amber-600\/40 {
  --tw-ring-color: #d9770666;
}

.ring-amber-600\/5 {
  --tw-ring-color: #d977060d;
}

.ring-amber-600\/50 {
  --tw-ring-color: #d9770680;
}

.ring-amber-600\/60 {
  --tw-ring-color: #d9770699;
}

.ring-amber-600\/70 {
  --tw-ring-color: #d97706b3;
}

.ring-amber-600\/75 {
  --tw-ring-color: #d97706bf;
}

.ring-amber-600\/80 {
  --tw-ring-color: #d97706cc;
}

.ring-amber-600\/90 {
  --tw-ring-color: #d97706e6;
}

.ring-amber-600\/95 {
  --tw-ring-color: #d97706f2;
}

.ring-amber-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(180 83 9 / var(--tw-ring-opacity));
}

.ring-amber-700\/0 {
  --tw-ring-color: #b4530900;
}

.ring-amber-700\/10 {
  --tw-ring-color: #b453091a;
}

.ring-amber-700\/100 {
  --tw-ring-color: #b45309;
}

.ring-amber-700\/20 {
  --tw-ring-color: #b4530933;
}

.ring-amber-700\/25 {
  --tw-ring-color: #b4530940;
}

.ring-amber-700\/30 {
  --tw-ring-color: #b453094d;
}

.ring-amber-700\/40 {
  --tw-ring-color: #b4530966;
}

.ring-amber-700\/5 {
  --tw-ring-color: #b453090d;
}

.ring-amber-700\/50 {
  --tw-ring-color: #b4530980;
}

.ring-amber-700\/60 {
  --tw-ring-color: #b4530999;
}

.ring-amber-700\/70 {
  --tw-ring-color: #b45309b3;
}

.ring-amber-700\/75 {
  --tw-ring-color: #b45309bf;
}

.ring-amber-700\/80 {
  --tw-ring-color: #b45309cc;
}

.ring-amber-700\/90 {
  --tw-ring-color: #b45309e6;
}

.ring-amber-700\/95 {
  --tw-ring-color: #b45309f2;
}

.ring-amber-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(146 64 14 / var(--tw-ring-opacity));
}

.ring-amber-800\/0 {
  --tw-ring-color: #92400e00;
}

.ring-amber-800\/10 {
  --tw-ring-color: #92400e1a;
}

.ring-amber-800\/100 {
  --tw-ring-color: #92400e;
}

.ring-amber-800\/20 {
  --tw-ring-color: #92400e33;
}

.ring-amber-800\/25 {
  --tw-ring-color: #92400e40;
}

.ring-amber-800\/30 {
  --tw-ring-color: #92400e4d;
}

.ring-amber-800\/40 {
  --tw-ring-color: #92400e66;
}

.ring-amber-800\/5 {
  --tw-ring-color: #92400e0d;
}

.ring-amber-800\/50 {
  --tw-ring-color: #92400e80;
}

.ring-amber-800\/60 {
  --tw-ring-color: #92400e99;
}

.ring-amber-800\/70 {
  --tw-ring-color: #92400eb3;
}

.ring-amber-800\/75 {
  --tw-ring-color: #92400ebf;
}

.ring-amber-800\/80 {
  --tw-ring-color: #92400ecc;
}

.ring-amber-800\/90 {
  --tw-ring-color: #92400ee6;
}

.ring-amber-800\/95 {
  --tw-ring-color: #92400ef2;
}

.ring-amber-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(120 53 15 / var(--tw-ring-opacity));
}

.ring-amber-900\/0 {
  --tw-ring-color: #78350f00;
}

.ring-amber-900\/10 {
  --tw-ring-color: #78350f1a;
}

.ring-amber-900\/100 {
  --tw-ring-color: #78350f;
}

.ring-amber-900\/20 {
  --tw-ring-color: #78350f33;
}

.ring-amber-900\/25 {
  --tw-ring-color: #78350f40;
}

.ring-amber-900\/30 {
  --tw-ring-color: #78350f4d;
}

.ring-amber-900\/40 {
  --tw-ring-color: #78350f66;
}

.ring-amber-900\/5 {
  --tw-ring-color: #78350f0d;
}

.ring-amber-900\/50 {
  --tw-ring-color: #78350f80;
}

.ring-amber-900\/60 {
  --tw-ring-color: #78350f99;
}

.ring-amber-900\/70 {
  --tw-ring-color: #78350fb3;
}

.ring-amber-900\/75 {
  --tw-ring-color: #78350fbf;
}

.ring-amber-900\/80 {
  --tw-ring-color: #78350fcc;
}

.ring-amber-900\/90 {
  --tw-ring-color: #78350fe6;
}

.ring-amber-900\/95 {
  --tw-ring-color: #78350ff2;
}

.ring-amber-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(69 26 3 / var(--tw-ring-opacity));
}

.ring-amber-950\/0 {
  --tw-ring-color: #451a0300;
}

.ring-amber-950\/10 {
  --tw-ring-color: #451a031a;
}

.ring-amber-950\/100 {
  --tw-ring-color: #451a03;
}

.ring-amber-950\/20 {
  --tw-ring-color: #451a0333;
}

.ring-amber-950\/25 {
  --tw-ring-color: #451a0340;
}

.ring-amber-950\/30 {
  --tw-ring-color: #451a034d;
}

.ring-amber-950\/40 {
  --tw-ring-color: #451a0366;
}

.ring-amber-950\/5 {
  --tw-ring-color: #451a030d;
}

.ring-amber-950\/50 {
  --tw-ring-color: #451a0380;
}

.ring-amber-950\/60 {
  --tw-ring-color: #451a0399;
}

.ring-amber-950\/70 {
  --tw-ring-color: #451a03b3;
}

.ring-amber-950\/75 {
  --tw-ring-color: #451a03bf;
}

.ring-amber-950\/80 {
  --tw-ring-color: #451a03cc;
}

.ring-amber-950\/90 {
  --tw-ring-color: #451a03e6;
}

.ring-amber-950\/95 {
  --tw-ring-color: #451a03f2;
}

.ring-emerald-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 250 229 / var(--tw-ring-opacity));
}

.ring-emerald-100\/0 {
  --tw-ring-color: #d1fae500;
}

.ring-emerald-100\/10 {
  --tw-ring-color: #d1fae51a;
}

.ring-emerald-100\/100 {
  --tw-ring-color: #d1fae5;
}

.ring-emerald-100\/20 {
  --tw-ring-color: #d1fae533;
}

.ring-emerald-100\/25 {
  --tw-ring-color: #d1fae540;
}

.ring-emerald-100\/30 {
  --tw-ring-color: #d1fae54d;
}

.ring-emerald-100\/40 {
  --tw-ring-color: #d1fae566;
}

.ring-emerald-100\/5 {
  --tw-ring-color: #d1fae50d;
}

.ring-emerald-100\/50 {
  --tw-ring-color: #d1fae580;
}

.ring-emerald-100\/60 {
  --tw-ring-color: #d1fae599;
}

.ring-emerald-100\/70 {
  --tw-ring-color: #d1fae5b3;
}

.ring-emerald-100\/75 {
  --tw-ring-color: #d1fae5bf;
}

.ring-emerald-100\/80 {
  --tw-ring-color: #d1fae5cc;
}

.ring-emerald-100\/90 {
  --tw-ring-color: #d1fae5e6;
}

.ring-emerald-100\/95 {
  --tw-ring-color: #d1fae5f2;
}

.ring-emerald-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(167 243 208 / var(--tw-ring-opacity));
}

.ring-emerald-200\/0 {
  --tw-ring-color: #a7f3d000;
}

.ring-emerald-200\/10 {
  --tw-ring-color: #a7f3d01a;
}

.ring-emerald-200\/100 {
  --tw-ring-color: #a7f3d0;
}

.ring-emerald-200\/20 {
  --tw-ring-color: #a7f3d033;
}

.ring-emerald-200\/25 {
  --tw-ring-color: #a7f3d040;
}

.ring-emerald-200\/30 {
  --tw-ring-color: #a7f3d04d;
}

.ring-emerald-200\/40 {
  --tw-ring-color: #a7f3d066;
}

.ring-emerald-200\/5 {
  --tw-ring-color: #a7f3d00d;
}

.ring-emerald-200\/50 {
  --tw-ring-color: #a7f3d080;
}

.ring-emerald-200\/60 {
  --tw-ring-color: #a7f3d099;
}

.ring-emerald-200\/70 {
  --tw-ring-color: #a7f3d0b3;
}

.ring-emerald-200\/75 {
  --tw-ring-color: #a7f3d0bf;
}

.ring-emerald-200\/80 {
  --tw-ring-color: #a7f3d0cc;
}

.ring-emerald-200\/90 {
  --tw-ring-color: #a7f3d0e6;
}

.ring-emerald-200\/95 {
  --tw-ring-color: #a7f3d0f2;
}

.ring-emerald-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(110 231 183 / var(--tw-ring-opacity));
}

.ring-emerald-300\/0 {
  --tw-ring-color: #6ee7b700;
}

.ring-emerald-300\/10 {
  --tw-ring-color: #6ee7b71a;
}

.ring-emerald-300\/100 {
  --tw-ring-color: #6ee7b7;
}

.ring-emerald-300\/20 {
  --tw-ring-color: #6ee7b733;
}

.ring-emerald-300\/25 {
  --tw-ring-color: #6ee7b740;
}

.ring-emerald-300\/30 {
  --tw-ring-color: #6ee7b74d;
}

.ring-emerald-300\/40 {
  --tw-ring-color: #6ee7b766;
}

.ring-emerald-300\/5 {
  --tw-ring-color: #6ee7b70d;
}

.ring-emerald-300\/50 {
  --tw-ring-color: #6ee7b780;
}

.ring-emerald-300\/60 {
  --tw-ring-color: #6ee7b799;
}

.ring-emerald-300\/70 {
  --tw-ring-color: #6ee7b7b3;
}

.ring-emerald-300\/75 {
  --tw-ring-color: #6ee7b7bf;
}

.ring-emerald-300\/80 {
  --tw-ring-color: #6ee7b7cc;
}

.ring-emerald-300\/90 {
  --tw-ring-color: #6ee7b7e6;
}

.ring-emerald-300\/95 {
  --tw-ring-color: #6ee7b7f2;
}

.ring-emerald-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(52 211 153 / var(--tw-ring-opacity));
}

.ring-emerald-400\/0 {
  --tw-ring-color: #34d39900;
}

.ring-emerald-400\/10 {
  --tw-ring-color: #34d3991a;
}

.ring-emerald-400\/100 {
  --tw-ring-color: #34d399;
}

.ring-emerald-400\/20 {
  --tw-ring-color: #34d39933;
}

.ring-emerald-400\/25 {
  --tw-ring-color: #34d39940;
}

.ring-emerald-400\/30 {
  --tw-ring-color: #34d3994d;
}

.ring-emerald-400\/40 {
  --tw-ring-color: #34d39966;
}

.ring-emerald-400\/5 {
  --tw-ring-color: #34d3990d;
}

.ring-emerald-400\/50 {
  --tw-ring-color: #34d39980;
}

.ring-emerald-400\/60 {
  --tw-ring-color: #34d39999;
}

.ring-emerald-400\/70 {
  --tw-ring-color: #34d399b3;
}

.ring-emerald-400\/75 {
  --tw-ring-color: #34d399bf;
}

.ring-emerald-400\/80 {
  --tw-ring-color: #34d399cc;
}

.ring-emerald-400\/90 {
  --tw-ring-color: #34d399e6;
}

.ring-emerald-400\/95 {
  --tw-ring-color: #34d399f2;
}

.ring-emerald-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 253 245 / var(--tw-ring-opacity));
}

.ring-emerald-50\/0 {
  --tw-ring-color: #ecfdf500;
}

.ring-emerald-50\/10 {
  --tw-ring-color: #ecfdf51a;
}

.ring-emerald-50\/100 {
  --tw-ring-color: #ecfdf5;
}

.ring-emerald-50\/20 {
  --tw-ring-color: #ecfdf533;
}

.ring-emerald-50\/25 {
  --tw-ring-color: #ecfdf540;
}

.ring-emerald-50\/30 {
  --tw-ring-color: #ecfdf54d;
}

.ring-emerald-50\/40 {
  --tw-ring-color: #ecfdf566;
}

.ring-emerald-50\/5 {
  --tw-ring-color: #ecfdf50d;
}

.ring-emerald-50\/50 {
  --tw-ring-color: #ecfdf580;
}

.ring-emerald-50\/60 {
  --tw-ring-color: #ecfdf599;
}

.ring-emerald-50\/70 {
  --tw-ring-color: #ecfdf5b3;
}

.ring-emerald-50\/75 {
  --tw-ring-color: #ecfdf5bf;
}

.ring-emerald-50\/80 {
  --tw-ring-color: #ecfdf5cc;
}

.ring-emerald-50\/90 {
  --tw-ring-color: #ecfdf5e6;
}

.ring-emerald-50\/95 {
  --tw-ring-color: #ecfdf5f2;
}

.ring-emerald-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity));
}

.ring-emerald-500\/0 {
  --tw-ring-color: #10b98100;
}

.ring-emerald-500\/10 {
  --tw-ring-color: #10b9811a;
}

.ring-emerald-500\/100 {
  --tw-ring-color: #10b981;
}

.ring-emerald-500\/20 {
  --tw-ring-color: #10b98133;
}

.ring-emerald-500\/25 {
  --tw-ring-color: #10b98140;
}

.ring-emerald-500\/30 {
  --tw-ring-color: #10b9814d;
}

.ring-emerald-500\/40 {
  --tw-ring-color: #10b98166;
}

.ring-emerald-500\/5 {
  --tw-ring-color: #10b9810d;
}

.ring-emerald-500\/50 {
  --tw-ring-color: #10b98180;
}

.ring-emerald-500\/60 {
  --tw-ring-color: #10b98199;
}

.ring-emerald-500\/70 {
  --tw-ring-color: #10b981b3;
}

.ring-emerald-500\/75 {
  --tw-ring-color: #10b981bf;
}

.ring-emerald-500\/80 {
  --tw-ring-color: #10b981cc;
}

.ring-emerald-500\/90 {
  --tw-ring-color: #10b981e6;
}

.ring-emerald-500\/95 {
  --tw-ring-color: #10b981f2;
}

.ring-emerald-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity));
}

.ring-emerald-600\/0 {
  --tw-ring-color: #05966900;
}

.ring-emerald-600\/10 {
  --tw-ring-color: #0596691a;
}

.ring-emerald-600\/100 {
  --tw-ring-color: #059669;
}

.ring-emerald-600\/20 {
  --tw-ring-color: #05966933;
}

.ring-emerald-600\/25 {
  --tw-ring-color: #05966940;
}

.ring-emerald-600\/30 {
  --tw-ring-color: #0596694d;
}

.ring-emerald-600\/40 {
  --tw-ring-color: #05966966;
}

.ring-emerald-600\/5 {
  --tw-ring-color: #0596690d;
}

.ring-emerald-600\/50 {
  --tw-ring-color: #05966980;
}

.ring-emerald-600\/60 {
  --tw-ring-color: #05966999;
}

.ring-emerald-600\/70 {
  --tw-ring-color: #059669b3;
}

.ring-emerald-600\/75 {
  --tw-ring-color: #059669bf;
}

.ring-emerald-600\/80 {
  --tw-ring-color: #059669cc;
}

.ring-emerald-600\/90 {
  --tw-ring-color: #059669e6;
}

.ring-emerald-600\/95 {
  --tw-ring-color: #059669f2;
}

.ring-emerald-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(4 120 87 / var(--tw-ring-opacity));
}

.ring-emerald-700\/0 {
  --tw-ring-color: #04785700;
}

.ring-emerald-700\/10 {
  --tw-ring-color: #0478571a;
}

.ring-emerald-700\/100 {
  --tw-ring-color: #047857;
}

.ring-emerald-700\/20 {
  --tw-ring-color: #04785733;
}

.ring-emerald-700\/25 {
  --tw-ring-color: #04785740;
}

.ring-emerald-700\/30 {
  --tw-ring-color: #0478574d;
}

.ring-emerald-700\/40 {
  --tw-ring-color: #04785766;
}

.ring-emerald-700\/5 {
  --tw-ring-color: #0478570d;
}

.ring-emerald-700\/50 {
  --tw-ring-color: #04785780;
}

.ring-emerald-700\/60 {
  --tw-ring-color: #04785799;
}

.ring-emerald-700\/70 {
  --tw-ring-color: #047857b3;
}

.ring-emerald-700\/75 {
  --tw-ring-color: #047857bf;
}

.ring-emerald-700\/80 {
  --tw-ring-color: #047857cc;
}

.ring-emerald-700\/90 {
  --tw-ring-color: #047857e6;
}

.ring-emerald-700\/95 {
  --tw-ring-color: #047857f2;
}

.ring-emerald-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 95 70 / var(--tw-ring-opacity));
}

.ring-emerald-800\/0 {
  --tw-ring-color: #065f4600;
}

.ring-emerald-800\/10 {
  --tw-ring-color: #065f461a;
}

.ring-emerald-800\/100 {
  --tw-ring-color: #065f46;
}

.ring-emerald-800\/20 {
  --tw-ring-color: #065f4633;
}

.ring-emerald-800\/25 {
  --tw-ring-color: #065f4640;
}

.ring-emerald-800\/30 {
  --tw-ring-color: #065f464d;
}

.ring-emerald-800\/40 {
  --tw-ring-color: #065f4666;
}

.ring-emerald-800\/5 {
  --tw-ring-color: #065f460d;
}

.ring-emerald-800\/50 {
  --tw-ring-color: #065f4680;
}

.ring-emerald-800\/60 {
  --tw-ring-color: #065f4699;
}

.ring-emerald-800\/70 {
  --tw-ring-color: #065f46b3;
}

.ring-emerald-800\/75 {
  --tw-ring-color: #065f46bf;
}

.ring-emerald-800\/80 {
  --tw-ring-color: #065f46cc;
}

.ring-emerald-800\/90 {
  --tw-ring-color: #065f46e6;
}

.ring-emerald-800\/95 {
  --tw-ring-color: #065f46f2;
}

.ring-emerald-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 78 59 / var(--tw-ring-opacity));
}

.ring-emerald-900\/0 {
  --tw-ring-color: #064e3b00;
}

.ring-emerald-900\/10 {
  --tw-ring-color: #064e3b1a;
}

.ring-emerald-900\/100 {
  --tw-ring-color: #064e3b;
}

.ring-emerald-900\/20 {
  --tw-ring-color: #064e3b33;
}

.ring-emerald-900\/25 {
  --tw-ring-color: #064e3b40;
}

.ring-emerald-900\/30 {
  --tw-ring-color: #064e3b4d;
}

.ring-emerald-900\/40 {
  --tw-ring-color: #064e3b66;
}

.ring-emerald-900\/5 {
  --tw-ring-color: #064e3b0d;
}

.ring-emerald-900\/50 {
  --tw-ring-color: #064e3b80;
}

.ring-emerald-900\/60 {
  --tw-ring-color: #064e3b99;
}

.ring-emerald-900\/70 {
  --tw-ring-color: #064e3bb3;
}

.ring-emerald-900\/75 {
  --tw-ring-color: #064e3bbf;
}

.ring-emerald-900\/80 {
  --tw-ring-color: #064e3bcc;
}

.ring-emerald-900\/90 {
  --tw-ring-color: #064e3be6;
}

.ring-emerald-900\/95 {
  --tw-ring-color: #064e3bf2;
}

.ring-emerald-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(2 44 34 / var(--tw-ring-opacity));
}

.ring-emerald-950\/0 {
  --tw-ring-color: #022c2200;
}

.ring-emerald-950\/10 {
  --tw-ring-color: #022c221a;
}

.ring-emerald-950\/100 {
  --tw-ring-color: #022c22;
}

.ring-emerald-950\/20 {
  --tw-ring-color: #022c2233;
}

.ring-emerald-950\/25 {
  --tw-ring-color: #022c2240;
}

.ring-emerald-950\/30 {
  --tw-ring-color: #022c224d;
}

.ring-emerald-950\/40 {
  --tw-ring-color: #022c2266;
}

.ring-emerald-950\/5 {
  --tw-ring-color: #022c220d;
}

.ring-emerald-950\/50 {
  --tw-ring-color: #022c2280;
}

.ring-emerald-950\/60 {
  --tw-ring-color: #022c2299;
}

.ring-emerald-950\/70 {
  --tw-ring-color: #022c22b3;
}

.ring-emerald-950\/75 {
  --tw-ring-color: #022c22bf;
}

.ring-emerald-950\/80 {
  --tw-ring-color: #022c22cc;
}

.ring-emerald-950\/90 {
  --tw-ring-color: #022c22e6;
}

.ring-emerald-950\/95 {
  --tw-ring-color: #022c22f2;
}

.ring-lime-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 252 203 / var(--tw-ring-opacity));
}

.ring-lime-100\/0 {
  --tw-ring-color: #ecfccb00;
}

.ring-lime-100\/10 {
  --tw-ring-color: #ecfccb1a;
}

.ring-lime-100\/100 {
  --tw-ring-color: #ecfccb;
}

.ring-lime-100\/20 {
  --tw-ring-color: #ecfccb33;
}

.ring-lime-100\/25 {
  --tw-ring-color: #ecfccb40;
}

.ring-lime-100\/30 {
  --tw-ring-color: #ecfccb4d;
}

.ring-lime-100\/40 {
  --tw-ring-color: #ecfccb66;
}

.ring-lime-100\/5 {
  --tw-ring-color: #ecfccb0d;
}

.ring-lime-100\/50 {
  --tw-ring-color: #ecfccb80;
}

.ring-lime-100\/60 {
  --tw-ring-color: #ecfccb99;
}

.ring-lime-100\/70 {
  --tw-ring-color: #ecfccbb3;
}

.ring-lime-100\/75 {
  --tw-ring-color: #ecfccbbf;
}

.ring-lime-100\/80 {
  --tw-ring-color: #ecfccbcc;
}

.ring-lime-100\/90 {
  --tw-ring-color: #ecfccbe6;
}

.ring-lime-100\/95 {
  --tw-ring-color: #ecfccbf2;
}

.ring-lime-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity));
}

.ring-lime-200\/0 {
  --tw-ring-color: #d9f99d00;
}

.ring-lime-200\/10 {
  --tw-ring-color: #d9f99d1a;
}

.ring-lime-200\/100 {
  --tw-ring-color: #d9f99d;
}

.ring-lime-200\/20 {
  --tw-ring-color: #d9f99d33;
}

.ring-lime-200\/25 {
  --tw-ring-color: #d9f99d40;
}

.ring-lime-200\/30 {
  --tw-ring-color: #d9f99d4d;
}

.ring-lime-200\/40 {
  --tw-ring-color: #d9f99d66;
}

.ring-lime-200\/5 {
  --tw-ring-color: #d9f99d0d;
}

.ring-lime-200\/50 {
  --tw-ring-color: #d9f99d80;
}

.ring-lime-200\/60 {
  --tw-ring-color: #d9f99d99;
}

.ring-lime-200\/70 {
  --tw-ring-color: #d9f99db3;
}

.ring-lime-200\/75 {
  --tw-ring-color: #d9f99dbf;
}

.ring-lime-200\/80 {
  --tw-ring-color: #d9f99dcc;
}

.ring-lime-200\/90 {
  --tw-ring-color: #d9f99de6;
}

.ring-lime-200\/95 {
  --tw-ring-color: #d9f99df2;
}

.ring-lime-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity));
}

.ring-lime-300\/0 {
  --tw-ring-color: #bef26400;
}

.ring-lime-300\/10 {
  --tw-ring-color: #bef2641a;
}

.ring-lime-300\/100 {
  --tw-ring-color: #bef264;
}

.ring-lime-300\/20 {
  --tw-ring-color: #bef26433;
}

.ring-lime-300\/25 {
  --tw-ring-color: #bef26440;
}

.ring-lime-300\/30 {
  --tw-ring-color: #bef2644d;
}

.ring-lime-300\/40 {
  --tw-ring-color: #bef26466;
}

.ring-lime-300\/5 {
  --tw-ring-color: #bef2640d;
}

.ring-lime-300\/50 {
  --tw-ring-color: #bef26480;
}

.ring-lime-300\/60 {
  --tw-ring-color: #bef26499;
}

.ring-lime-300\/70 {
  --tw-ring-color: #bef264b3;
}

.ring-lime-300\/75 {
  --tw-ring-color: #bef264bf;
}

.ring-lime-300\/80 {
  --tw-ring-color: #bef264cc;
}

.ring-lime-300\/90 {
  --tw-ring-color: #bef264e6;
}

.ring-lime-300\/95 {
  --tw-ring-color: #bef264f2;
}

.ring-lime-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity));
}

.ring-lime-400\/0 {
  --tw-ring-color: #a3e63500;
}

.ring-lime-400\/10 {
  --tw-ring-color: #a3e6351a;
}

.ring-lime-400\/100 {
  --tw-ring-color: #a3e635;
}

.ring-lime-400\/20 {
  --tw-ring-color: #a3e63533;
}

.ring-lime-400\/25 {
  --tw-ring-color: #a3e63540;
}

.ring-lime-400\/30 {
  --tw-ring-color: #a3e6354d;
}

.ring-lime-400\/40 {
  --tw-ring-color: #a3e63566;
}

.ring-lime-400\/5 {
  --tw-ring-color: #a3e6350d;
}

.ring-lime-400\/50 {
  --tw-ring-color: #a3e63580;
}

.ring-lime-400\/60 {
  --tw-ring-color: #a3e63599;
}

.ring-lime-400\/70 {
  --tw-ring-color: #a3e635b3;
}

.ring-lime-400\/75 {
  --tw-ring-color: #a3e635bf;
}

.ring-lime-400\/80 {
  --tw-ring-color: #a3e635cc;
}

.ring-lime-400\/90 {
  --tw-ring-color: #a3e635e6;
}

.ring-lime-400\/95 {
  --tw-ring-color: #a3e635f2;
}

.ring-lime-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(247 254 231 / var(--tw-ring-opacity));
}

.ring-lime-50\/0 {
  --tw-ring-color: #f7fee700;
}

.ring-lime-50\/10 {
  --tw-ring-color: #f7fee71a;
}

.ring-lime-50\/100 {
  --tw-ring-color: #f7fee7;
}

.ring-lime-50\/20 {
  --tw-ring-color: #f7fee733;
}

.ring-lime-50\/25 {
  --tw-ring-color: #f7fee740;
}

.ring-lime-50\/30 {
  --tw-ring-color: #f7fee74d;
}

.ring-lime-50\/40 {
  --tw-ring-color: #f7fee766;
}

.ring-lime-50\/5 {
  --tw-ring-color: #f7fee70d;
}

.ring-lime-50\/50 {
  --tw-ring-color: #f7fee780;
}

.ring-lime-50\/60 {
  --tw-ring-color: #f7fee799;
}

.ring-lime-50\/70 {
  --tw-ring-color: #f7fee7b3;
}

.ring-lime-50\/75 {
  --tw-ring-color: #f7fee7bf;
}

.ring-lime-50\/80 {
  --tw-ring-color: #f7fee7cc;
}

.ring-lime-50\/90 {
  --tw-ring-color: #f7fee7e6;
}

.ring-lime-50\/95 {
  --tw-ring-color: #f7fee7f2;
}

.ring-lime-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity));
}

.ring-lime-500\/0 {
  --tw-ring-color: #84cc1600;
}

.ring-lime-500\/10 {
  --tw-ring-color: #84cc161a;
}

.ring-lime-500\/100 {
  --tw-ring-color: #84cc16;
}

.ring-lime-500\/20 {
  --tw-ring-color: #84cc1633;
}

.ring-lime-500\/25 {
  --tw-ring-color: #84cc1640;
}

.ring-lime-500\/30 {
  --tw-ring-color: #84cc164d;
}

.ring-lime-500\/40 {
  --tw-ring-color: #84cc1666;
}

.ring-lime-500\/5 {
  --tw-ring-color: #84cc160d;
}

.ring-lime-500\/50 {
  --tw-ring-color: #84cc1680;
}

.ring-lime-500\/60 {
  --tw-ring-color: #84cc1699;
}

.ring-lime-500\/70 {
  --tw-ring-color: #84cc16b3;
}

.ring-lime-500\/75 {
  --tw-ring-color: #84cc16bf;
}

.ring-lime-500\/80 {
  --tw-ring-color: #84cc16cc;
}

.ring-lime-500\/90 {
  --tw-ring-color: #84cc16e6;
}

.ring-lime-500\/95 {
  --tw-ring-color: #84cc16f2;
}

.ring-lime-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity));
}

.ring-lime-600\/0 {
  --tw-ring-color: #65a30d00;
}

.ring-lime-600\/10 {
  --tw-ring-color: #65a30d1a;
}

.ring-lime-600\/100 {
  --tw-ring-color: #65a30d;
}

.ring-lime-600\/20 {
  --tw-ring-color: #65a30d33;
}

.ring-lime-600\/25 {
  --tw-ring-color: #65a30d40;
}

.ring-lime-600\/30 {
  --tw-ring-color: #65a30d4d;
}

.ring-lime-600\/40 {
  --tw-ring-color: #65a30d66;
}

.ring-lime-600\/5 {
  --tw-ring-color: #65a30d0d;
}

.ring-lime-600\/50 {
  --tw-ring-color: #65a30d80;
}

.ring-lime-600\/60 {
  --tw-ring-color: #65a30d99;
}

.ring-lime-600\/70 {
  --tw-ring-color: #65a30db3;
}

.ring-lime-600\/75 {
  --tw-ring-color: #65a30dbf;
}

.ring-lime-600\/80 {
  --tw-ring-color: #65a30dcc;
}

.ring-lime-600\/90 {
  --tw-ring-color: #65a30de6;
}

.ring-lime-600\/95 {
  --tw-ring-color: #65a30df2;
}

.ring-lime-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity));
}

.ring-lime-700\/0 {
  --tw-ring-color: #4d7c0f00;
}

.ring-lime-700\/10 {
  --tw-ring-color: #4d7c0f1a;
}

.ring-lime-700\/100 {
  --tw-ring-color: #4d7c0f;
}

.ring-lime-700\/20 {
  --tw-ring-color: #4d7c0f33;
}

.ring-lime-700\/25 {
  --tw-ring-color: #4d7c0f40;
}

.ring-lime-700\/30 {
  --tw-ring-color: #4d7c0f4d;
}

.ring-lime-700\/40 {
  --tw-ring-color: #4d7c0f66;
}

.ring-lime-700\/5 {
  --tw-ring-color: #4d7c0f0d;
}

.ring-lime-700\/50 {
  --tw-ring-color: #4d7c0f80;
}

.ring-lime-700\/60 {
  --tw-ring-color: #4d7c0f99;
}

.ring-lime-700\/70 {
  --tw-ring-color: #4d7c0fb3;
}

.ring-lime-700\/75 {
  --tw-ring-color: #4d7c0fbf;
}

.ring-lime-700\/80 {
  --tw-ring-color: #4d7c0fcc;
}

.ring-lime-700\/90 {
  --tw-ring-color: #4d7c0fe6;
}

.ring-lime-700\/95 {
  --tw-ring-color: #4d7c0ff2;
}

.ring-lime-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity));
}

.ring-lime-800\/0 {
  --tw-ring-color: #3f621200;
}

.ring-lime-800\/10 {
  --tw-ring-color: #3f62121a;
}

.ring-lime-800\/100 {
  --tw-ring-color: #3f6212;
}

.ring-lime-800\/20 {
  --tw-ring-color: #3f621233;
}

.ring-lime-800\/25 {
  --tw-ring-color: #3f621240;
}

.ring-lime-800\/30 {
  --tw-ring-color: #3f62124d;
}

.ring-lime-800\/40 {
  --tw-ring-color: #3f621266;
}

.ring-lime-800\/5 {
  --tw-ring-color: #3f62120d;
}

.ring-lime-800\/50 {
  --tw-ring-color: #3f621280;
}

.ring-lime-800\/60 {
  --tw-ring-color: #3f621299;
}

.ring-lime-800\/70 {
  --tw-ring-color: #3f6212b3;
}

.ring-lime-800\/75 {
  --tw-ring-color: #3f6212bf;
}

.ring-lime-800\/80 {
  --tw-ring-color: #3f6212cc;
}

.ring-lime-800\/90 {
  --tw-ring-color: #3f6212e6;
}

.ring-lime-800\/95 {
  --tw-ring-color: #3f6212f2;
}

.ring-lime-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(54 83 20 / var(--tw-ring-opacity));
}

.ring-lime-900\/0 {
  --tw-ring-color: #36531400;
}

.ring-lime-900\/10 {
  --tw-ring-color: #3653141a;
}

.ring-lime-900\/100 {
  --tw-ring-color: #365314;
}

.ring-lime-900\/20 {
  --tw-ring-color: #36531433;
}

.ring-lime-900\/25 {
  --tw-ring-color: #36531440;
}

.ring-lime-900\/30 {
  --tw-ring-color: #3653144d;
}

.ring-lime-900\/40 {
  --tw-ring-color: #36531466;
}

.ring-lime-900\/5 {
  --tw-ring-color: #3653140d;
}

.ring-lime-900\/50 {
  --tw-ring-color: #36531480;
}

.ring-lime-900\/60 {
  --tw-ring-color: #36531499;
}

.ring-lime-900\/70 {
  --tw-ring-color: #365314b3;
}

.ring-lime-900\/75 {
  --tw-ring-color: #365314bf;
}

.ring-lime-900\/80 {
  --tw-ring-color: #365314cc;
}

.ring-lime-900\/90 {
  --tw-ring-color: #365314e6;
}

.ring-lime-900\/95 {
  --tw-ring-color: #365314f2;
}

.ring-lime-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(26 46 5 / var(--tw-ring-opacity));
}

.ring-lime-950\/0 {
  --tw-ring-color: #1a2e0500;
}

.ring-lime-950\/10 {
  --tw-ring-color: #1a2e051a;
}

.ring-lime-950\/100 {
  --tw-ring-color: #1a2e05;
}

.ring-lime-950\/20 {
  --tw-ring-color: #1a2e0533;
}

.ring-lime-950\/25 {
  --tw-ring-color: #1a2e0540;
}

.ring-lime-950\/30 {
  --tw-ring-color: #1a2e054d;
}

.ring-lime-950\/40 {
  --tw-ring-color: #1a2e0566;
}

.ring-lime-950\/5 {
  --tw-ring-color: #1a2e050d;
}

.ring-lime-950\/50 {
  --tw-ring-color: #1a2e0580;
}

.ring-lime-950\/60 {
  --tw-ring-color: #1a2e0599;
}

.ring-lime-950\/70 {
  --tw-ring-color: #1a2e05b3;
}

.ring-lime-950\/75 {
  --tw-ring-color: #1a2e05bf;
}

.ring-lime-950\/80 {
  --tw-ring-color: #1a2e05cc;
}

.ring-lime-950\/90 {
  --tw-ring-color: #1a2e05e6;
}

.ring-lime-950\/95 {
  --tw-ring-color: #1a2e05f2;
}

.ring-pink-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 231 243 / var(--tw-ring-opacity));
}

.ring-pink-100\/0 {
  --tw-ring-color: #fce7f300;
}

.ring-pink-100\/10 {
  --tw-ring-color: #fce7f31a;
}

.ring-pink-100\/100 {
  --tw-ring-color: #fce7f3;
}

.ring-pink-100\/20 {
  --tw-ring-color: #fce7f333;
}

.ring-pink-100\/25 {
  --tw-ring-color: #fce7f340;
}

.ring-pink-100\/30 {
  --tw-ring-color: #fce7f34d;
}

.ring-pink-100\/40 {
  --tw-ring-color: #fce7f366;
}

.ring-pink-100\/5 {
  --tw-ring-color: #fce7f30d;
}

.ring-pink-100\/50 {
  --tw-ring-color: #fce7f380;
}

.ring-pink-100\/60 {
  --tw-ring-color: #fce7f399;
}

.ring-pink-100\/70 {
  --tw-ring-color: #fce7f3b3;
}

.ring-pink-100\/75 {
  --tw-ring-color: #fce7f3bf;
}

.ring-pink-100\/80 {
  --tw-ring-color: #fce7f3cc;
}

.ring-pink-100\/90 {
  --tw-ring-color: #fce7f3e6;
}

.ring-pink-100\/95 {
  --tw-ring-color: #fce7f3f2;
}

.ring-pink-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity));
}

.ring-pink-200\/0 {
  --tw-ring-color: #fbcfe800;
}

.ring-pink-200\/10 {
  --tw-ring-color: #fbcfe81a;
}

.ring-pink-200\/100 {
  --tw-ring-color: #fbcfe8;
}

.ring-pink-200\/20 {
  --tw-ring-color: #fbcfe833;
}

.ring-pink-200\/25 {
  --tw-ring-color: #fbcfe840;
}

.ring-pink-200\/30 {
  --tw-ring-color: #fbcfe84d;
}

.ring-pink-200\/40 {
  --tw-ring-color: #fbcfe866;
}

.ring-pink-200\/5 {
  --tw-ring-color: #fbcfe80d;
}

.ring-pink-200\/50 {
  --tw-ring-color: #fbcfe880;
}

.ring-pink-200\/60 {
  --tw-ring-color: #fbcfe899;
}

.ring-pink-200\/70 {
  --tw-ring-color: #fbcfe8b3;
}

.ring-pink-200\/75 {
  --tw-ring-color: #fbcfe8bf;
}

.ring-pink-200\/80 {
  --tw-ring-color: #fbcfe8cc;
}

.ring-pink-200\/90 {
  --tw-ring-color: #fbcfe8e6;
}

.ring-pink-200\/95 {
  --tw-ring-color: #fbcfe8f2;
}

.ring-pink-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity));
}

.ring-pink-300\/0 {
  --tw-ring-color: #f9a8d400;
}

.ring-pink-300\/10 {
  --tw-ring-color: #f9a8d41a;
}

.ring-pink-300\/100 {
  --tw-ring-color: #f9a8d4;
}

.ring-pink-300\/20 {
  --tw-ring-color: #f9a8d433;
}

.ring-pink-300\/25 {
  --tw-ring-color: #f9a8d440;
}

.ring-pink-300\/30 {
  --tw-ring-color: #f9a8d44d;
}

.ring-pink-300\/40 {
  --tw-ring-color: #f9a8d466;
}

.ring-pink-300\/5 {
  --tw-ring-color: #f9a8d40d;
}

.ring-pink-300\/50 {
  --tw-ring-color: #f9a8d480;
}

.ring-pink-300\/60 {
  --tw-ring-color: #f9a8d499;
}

.ring-pink-300\/70 {
  --tw-ring-color: #f9a8d4b3;
}

.ring-pink-300\/75 {
  --tw-ring-color: #f9a8d4bf;
}

.ring-pink-300\/80 {
  --tw-ring-color: #f9a8d4cc;
}

.ring-pink-300\/90 {
  --tw-ring-color: #f9a8d4e6;
}

.ring-pink-300\/95 {
  --tw-ring-color: #f9a8d4f2;
}

.ring-pink-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity));
}

.ring-pink-400\/0 {
  --tw-ring-color: #f472b600;
}

.ring-pink-400\/10 {
  --tw-ring-color: #f472b61a;
}

.ring-pink-400\/100 {
  --tw-ring-color: #f472b6;
}

.ring-pink-400\/20 {
  --tw-ring-color: #f472b633;
}

.ring-pink-400\/25 {
  --tw-ring-color: #f472b640;
}

.ring-pink-400\/30 {
  --tw-ring-color: #f472b64d;
}

.ring-pink-400\/40 {
  --tw-ring-color: #f472b666;
}

.ring-pink-400\/5 {
  --tw-ring-color: #f472b60d;
}

.ring-pink-400\/50 {
  --tw-ring-color: #f472b680;
}

.ring-pink-400\/60 {
  --tw-ring-color: #f472b699;
}

.ring-pink-400\/70 {
  --tw-ring-color: #f472b6b3;
}

.ring-pink-400\/75 {
  --tw-ring-color: #f472b6bf;
}

.ring-pink-400\/80 {
  --tw-ring-color: #f472b6cc;
}

.ring-pink-400\/90 {
  --tw-ring-color: #f472b6e6;
}

.ring-pink-400\/95 {
  --tw-ring-color: #f472b6f2;
}

.ring-pink-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity));
}

.ring-pink-50\/0 {
  --tw-ring-color: #fdf2f800;
}

.ring-pink-50\/10 {
  --tw-ring-color: #fdf2f81a;
}

.ring-pink-50\/100 {
  --tw-ring-color: #fdf2f8;
}

.ring-pink-50\/20 {
  --tw-ring-color: #fdf2f833;
}

.ring-pink-50\/25 {
  --tw-ring-color: #fdf2f840;
}

.ring-pink-50\/30 {
  --tw-ring-color: #fdf2f84d;
}

.ring-pink-50\/40 {
  --tw-ring-color: #fdf2f866;
}

.ring-pink-50\/5 {
  --tw-ring-color: #fdf2f80d;
}

.ring-pink-50\/50 {
  --tw-ring-color: #fdf2f880;
}

.ring-pink-50\/60 {
  --tw-ring-color: #fdf2f899;
}

.ring-pink-50\/70 {
  --tw-ring-color: #fdf2f8b3;
}

.ring-pink-50\/75 {
  --tw-ring-color: #fdf2f8bf;
}

.ring-pink-50\/80 {
  --tw-ring-color: #fdf2f8cc;
}

.ring-pink-50\/90 {
  --tw-ring-color: #fdf2f8e6;
}

.ring-pink-50\/95 {
  --tw-ring-color: #fdf2f8f2;
}

.ring-pink-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}

.ring-pink-500\/0 {
  --tw-ring-color: #ec489900;
}

.ring-pink-500\/10 {
  --tw-ring-color: #ec48991a;
}

.ring-pink-500\/100 {
  --tw-ring-color: #ec4899;
}

.ring-pink-500\/20 {
  --tw-ring-color: #ec489933;
}

.ring-pink-500\/25 {
  --tw-ring-color: #ec489940;
}

.ring-pink-500\/30 {
  --tw-ring-color: #ec48994d;
}

.ring-pink-500\/40 {
  --tw-ring-color: #ec489966;
}

.ring-pink-500\/5 {
  --tw-ring-color: #ec48990d;
}

.ring-pink-500\/50 {
  --tw-ring-color: #ec489980;
}

.ring-pink-500\/60 {
  --tw-ring-color: #ec489999;
}

.ring-pink-500\/70 {
  --tw-ring-color: #ec4899b3;
}

.ring-pink-500\/75 {
  --tw-ring-color: #ec4899bf;
}

.ring-pink-500\/80 {
  --tw-ring-color: #ec4899cc;
}

.ring-pink-500\/90 {
  --tw-ring-color: #ec4899e6;
}

.ring-pink-500\/95 {
  --tw-ring-color: #ec4899f2;
}

.ring-pink-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity));
}

.ring-pink-600\/0 {
  --tw-ring-color: #db277700;
}

.ring-pink-600\/10 {
  --tw-ring-color: #db27771a;
}

.ring-pink-600\/100 {
  --tw-ring-color: #db2777;
}

.ring-pink-600\/20 {
  --tw-ring-color: #db277733;
}

.ring-pink-600\/25 {
  --tw-ring-color: #db277740;
}

.ring-pink-600\/30 {
  --tw-ring-color: #db27774d;
}

.ring-pink-600\/40 {
  --tw-ring-color: #db277766;
}

.ring-pink-600\/5 {
  --tw-ring-color: #db27770d;
}

.ring-pink-600\/50 {
  --tw-ring-color: #db277780;
}

.ring-pink-600\/60 {
  --tw-ring-color: #db277799;
}

.ring-pink-600\/70 {
  --tw-ring-color: #db2777b3;
}

.ring-pink-600\/75 {
  --tw-ring-color: #db2777bf;
}

.ring-pink-600\/80 {
  --tw-ring-color: #db2777cc;
}

.ring-pink-600\/90 {
  --tw-ring-color: #db2777e6;
}

.ring-pink-600\/95 {
  --tw-ring-color: #db2777f2;
}

.ring-pink-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity));
}

.ring-pink-700\/0 {
  --tw-ring-color: #be185d00;
}

.ring-pink-700\/10 {
  --tw-ring-color: #be185d1a;
}

.ring-pink-700\/100 {
  --tw-ring-color: #be185d;
}

.ring-pink-700\/20 {
  --tw-ring-color: #be185d33;
}

.ring-pink-700\/25 {
  --tw-ring-color: #be185d40;
}

.ring-pink-700\/30 {
  --tw-ring-color: #be185d4d;
}

.ring-pink-700\/40 {
  --tw-ring-color: #be185d66;
}

.ring-pink-700\/5 {
  --tw-ring-color: #be185d0d;
}

.ring-pink-700\/50 {
  --tw-ring-color: #be185d80;
}

.ring-pink-700\/60 {
  --tw-ring-color: #be185d99;
}

.ring-pink-700\/70 {
  --tw-ring-color: #be185db3;
}

.ring-pink-700\/75 {
  --tw-ring-color: #be185dbf;
}

.ring-pink-700\/80 {
  --tw-ring-color: #be185dcc;
}

.ring-pink-700\/90 {
  --tw-ring-color: #be185de6;
}

.ring-pink-700\/95 {
  --tw-ring-color: #be185df2;
}

.ring-pink-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(157 23 77 / var(--tw-ring-opacity));
}

.ring-pink-800\/0 {
  --tw-ring-color: #9d174d00;
}

.ring-pink-800\/10 {
  --tw-ring-color: #9d174d1a;
}

.ring-pink-800\/100 {
  --tw-ring-color: #9d174d;
}

.ring-pink-800\/20 {
  --tw-ring-color: #9d174d33;
}

.ring-pink-800\/25 {
  --tw-ring-color: #9d174d40;
}

.ring-pink-800\/30 {
  --tw-ring-color: #9d174d4d;
}

.ring-pink-800\/40 {
  --tw-ring-color: #9d174d66;
}

.ring-pink-800\/5 {
  --tw-ring-color: #9d174d0d;
}

.ring-pink-800\/50 {
  --tw-ring-color: #9d174d80;
}

.ring-pink-800\/60 {
  --tw-ring-color: #9d174d99;
}

.ring-pink-800\/70 {
  --tw-ring-color: #9d174db3;
}

.ring-pink-800\/75 {
  --tw-ring-color: #9d174dbf;
}

.ring-pink-800\/80 {
  --tw-ring-color: #9d174dcc;
}

.ring-pink-800\/90 {
  --tw-ring-color: #9d174de6;
}

.ring-pink-800\/95 {
  --tw-ring-color: #9d174df2;
}

.ring-pink-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(131 24 67 / var(--tw-ring-opacity));
}

.ring-pink-900\/0 {
  --tw-ring-color: #83184300;
}

.ring-pink-900\/10 {
  --tw-ring-color: #8318431a;
}

.ring-pink-900\/100 {
  --tw-ring-color: #831843;
}

.ring-pink-900\/20 {
  --tw-ring-color: #83184333;
}

.ring-pink-900\/25 {
  --tw-ring-color: #83184340;
}

.ring-pink-900\/30 {
  --tw-ring-color: #8318434d;
}

.ring-pink-900\/40 {
  --tw-ring-color: #83184366;
}

.ring-pink-900\/5 {
  --tw-ring-color: #8318430d;
}

.ring-pink-900\/50 {
  --tw-ring-color: #83184380;
}

.ring-pink-900\/60 {
  --tw-ring-color: #83184399;
}

.ring-pink-900\/70 {
  --tw-ring-color: #831843b3;
}

.ring-pink-900\/75 {
  --tw-ring-color: #831843bf;
}

.ring-pink-900\/80 {
  --tw-ring-color: #831843cc;
}

.ring-pink-900\/90 {
  --tw-ring-color: #831843e6;
}

.ring-pink-900\/95 {
  --tw-ring-color: #831843f2;
}

.ring-pink-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(80 7 36 / var(--tw-ring-opacity));
}

.ring-pink-950\/0 {
  --tw-ring-color: #50072400;
}

.ring-pink-950\/10 {
  --tw-ring-color: #5007241a;
}

.ring-pink-950\/100 {
  --tw-ring-color: #500724;
}

.ring-pink-950\/20 {
  --tw-ring-color: #50072433;
}

.ring-pink-950\/25 {
  --tw-ring-color: #50072440;
}

.ring-pink-950\/30 {
  --tw-ring-color: #5007244d;
}

.ring-pink-950\/40 {
  --tw-ring-color: #50072466;
}

.ring-pink-950\/5 {
  --tw-ring-color: #5007240d;
}

.ring-pink-950\/50 {
  --tw-ring-color: #50072480;
}

.ring-pink-950\/60 {
  --tw-ring-color: #50072499;
}

.ring-pink-950\/70 {
  --tw-ring-color: #500724b3;
}

.ring-pink-950\/75 {
  --tw-ring-color: #500724bf;
}

.ring-pink-950\/80 {
  --tw-ring-color: #500724cc;
}

.ring-pink-950\/90 {
  --tw-ring-color: #500724e6;
}

.ring-pink-950\/95 {
  --tw-ring-color: #500724f2;
}

.ring-primary {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--primary) / var(--tw-ring-opacity));
}

.ring-primary\/0 {
  --tw-ring-color: hsl(var(--primary) / 0);
}

.ring-primary\/10 {
  --tw-ring-color: hsl(var(--primary) / .1);
}

.ring-primary\/100 {
  --tw-ring-color: hsl(var(--primary) / 1);
}

.ring-primary\/20 {
  --tw-ring-color: hsl(var(--primary) / .2);
}

.ring-primary\/25 {
  --tw-ring-color: hsl(var(--primary) / .25);
}

.ring-primary\/30 {
  --tw-ring-color: hsl(var(--primary) / .3);
}

.ring-primary\/40 {
  --tw-ring-color: hsl(var(--primary) / .4);
}

.ring-primary\/5 {
  --tw-ring-color: hsl(var(--primary) / .05);
}

.ring-primary\/50 {
  --tw-ring-color: hsl(var(--primary) / .5);
}

.ring-primary\/60 {
  --tw-ring-color: hsl(var(--primary) / .6);
}

.ring-primary\/70 {
  --tw-ring-color: hsl(var(--primary) / .7);
}

.ring-primary\/75 {
  --tw-ring-color: hsl(var(--primary) / .75);
}

.ring-primary\/80 {
  --tw-ring-color: hsl(var(--primary) / .8);
}

.ring-primary\/90 {
  --tw-ring-color: hsl(var(--primary) / .9);
}

.ring-primary\/95 {
  --tw-ring-color: hsl(var(--primary) / .95);
}

.ring-purple-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 232 255 / var(--tw-ring-opacity));
}

.ring-purple-100\/0 {
  --tw-ring-color: #f3e8ff00;
}

.ring-purple-100\/10 {
  --tw-ring-color: #f3e8ff1a;
}

.ring-purple-100\/100 {
  --tw-ring-color: #f3e8ff;
}

.ring-purple-100\/20 {
  --tw-ring-color: #f3e8ff33;
}

.ring-purple-100\/25 {
  --tw-ring-color: #f3e8ff40;
}

.ring-purple-100\/30 {
  --tw-ring-color: #f3e8ff4d;
}

.ring-purple-100\/40 {
  --tw-ring-color: #f3e8ff66;
}

.ring-purple-100\/5 {
  --tw-ring-color: #f3e8ff0d;
}

.ring-purple-100\/50 {
  --tw-ring-color: #f3e8ff80;
}

.ring-purple-100\/60 {
  --tw-ring-color: #f3e8ff99;
}

.ring-purple-100\/70 {
  --tw-ring-color: #f3e8ffb3;
}

.ring-purple-100\/75 {
  --tw-ring-color: #f3e8ffbf;
}

.ring-purple-100\/80 {
  --tw-ring-color: #f3e8ffcc;
}

.ring-purple-100\/90 {
  --tw-ring-color: #f3e8ffe6;
}

.ring-purple-100\/95 {
  --tw-ring-color: #f3e8fff2;
}

.ring-purple-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity));
}

.ring-purple-200\/0 {
  --tw-ring-color: #e9d5ff00;
}

.ring-purple-200\/10 {
  --tw-ring-color: #e9d5ff1a;
}

.ring-purple-200\/100 {
  --tw-ring-color: #e9d5ff;
}

.ring-purple-200\/20 {
  --tw-ring-color: #e9d5ff33;
}

.ring-purple-200\/25 {
  --tw-ring-color: #e9d5ff40;
}

.ring-purple-200\/30 {
  --tw-ring-color: #e9d5ff4d;
}

.ring-purple-200\/40 {
  --tw-ring-color: #e9d5ff66;
}

.ring-purple-200\/5 {
  --tw-ring-color: #e9d5ff0d;
}

.ring-purple-200\/50 {
  --tw-ring-color: #e9d5ff80;
}

.ring-purple-200\/60 {
  --tw-ring-color: #e9d5ff99;
}

.ring-purple-200\/70 {
  --tw-ring-color: #e9d5ffb3;
}

.ring-purple-200\/75 {
  --tw-ring-color: #e9d5ffbf;
}

.ring-purple-200\/80 {
  --tw-ring-color: #e9d5ffcc;
}

.ring-purple-200\/90 {
  --tw-ring-color: #e9d5ffe6;
}

.ring-purple-200\/95 {
  --tw-ring-color: #e9d5fff2;
}

.ring-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity));
}

.ring-purple-300\/0 {
  --tw-ring-color: #d8b4fe00;
}

.ring-purple-300\/10 {
  --tw-ring-color: #d8b4fe1a;
}

.ring-purple-300\/100 {
  --tw-ring-color: #d8b4fe;
}

.ring-purple-300\/20 {
  --tw-ring-color: #d8b4fe33;
}

.ring-purple-300\/25 {
  --tw-ring-color: #d8b4fe40;
}

.ring-purple-300\/30 {
  --tw-ring-color: #d8b4fe4d;
}

.ring-purple-300\/40 {
  --tw-ring-color: #d8b4fe66;
}

.ring-purple-300\/5 {
  --tw-ring-color: #d8b4fe0d;
}

.ring-purple-300\/50 {
  --tw-ring-color: #d8b4fe80;
}

.ring-purple-300\/60 {
  --tw-ring-color: #d8b4fe99;
}

.ring-purple-300\/70 {
  --tw-ring-color: #d8b4feb3;
}

.ring-purple-300\/75 {
  --tw-ring-color: #d8b4febf;
}

.ring-purple-300\/80 {
  --tw-ring-color: #d8b4fecc;
}

.ring-purple-300\/90 {
  --tw-ring-color: #d8b4fee6;
}

.ring-purple-300\/95 {
  --tw-ring-color: #d8b4fef2;
}

.ring-purple-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity));
}

.ring-purple-400\/0 {
  --tw-ring-color: #c084fc00;
}

.ring-purple-400\/10 {
  --tw-ring-color: #c084fc1a;
}

.ring-purple-400\/100 {
  --tw-ring-color: #c084fc;
}

.ring-purple-400\/20 {
  --tw-ring-color: #c084fc33;
}

.ring-purple-400\/25 {
  --tw-ring-color: #c084fc40;
}

.ring-purple-400\/30 {
  --tw-ring-color: #c084fc4d;
}

.ring-purple-400\/40 {
  --tw-ring-color: #c084fc66;
}

.ring-purple-400\/5 {
  --tw-ring-color: #c084fc0d;
}

.ring-purple-400\/50 {
  --tw-ring-color: #c084fc80;
}

.ring-purple-400\/60 {
  --tw-ring-color: #c084fc99;
}

.ring-purple-400\/70 {
  --tw-ring-color: #c084fcb3;
}

.ring-purple-400\/75 {
  --tw-ring-color: #c084fcbf;
}

.ring-purple-400\/80 {
  --tw-ring-color: #c084fccc;
}

.ring-purple-400\/90 {
  --tw-ring-color: #c084fce6;
}

.ring-purple-400\/95 {
  --tw-ring-color: #c084fcf2;
}

.ring-purple-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(250 245 255 / var(--tw-ring-opacity));
}

.ring-purple-50\/0 {
  --tw-ring-color: #faf5ff00;
}

.ring-purple-50\/10 {
  --tw-ring-color: #faf5ff1a;
}

.ring-purple-50\/100 {
  --tw-ring-color: #faf5ff;
}

.ring-purple-50\/20 {
  --tw-ring-color: #faf5ff33;
}

.ring-purple-50\/25 {
  --tw-ring-color: #faf5ff40;
}

.ring-purple-50\/30 {
  --tw-ring-color: #faf5ff4d;
}

.ring-purple-50\/40 {
  --tw-ring-color: #faf5ff66;
}

.ring-purple-50\/5 {
  --tw-ring-color: #faf5ff0d;
}

.ring-purple-50\/50 {
  --tw-ring-color: #faf5ff80;
}

.ring-purple-50\/60 {
  --tw-ring-color: #faf5ff99;
}

.ring-purple-50\/70 {
  --tw-ring-color: #faf5ffb3;
}

.ring-purple-50\/75 {
  --tw-ring-color: #faf5ffbf;
}

.ring-purple-50\/80 {
  --tw-ring-color: #faf5ffcc;
}

.ring-purple-50\/90 {
  --tw-ring-color: #faf5ffe6;
}

.ring-purple-50\/95 {
  --tw-ring-color: #faf5fff2;
}

.ring-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}

.ring-purple-500\/0 {
  --tw-ring-color: #a855f700;
}

.ring-purple-500\/10 {
  --tw-ring-color: #a855f71a;
}

.ring-purple-500\/100 {
  --tw-ring-color: #a855f7;
}

.ring-purple-500\/20 {
  --tw-ring-color: #a855f733;
}

.ring-purple-500\/25 {
  --tw-ring-color: #a855f740;
}

.ring-purple-500\/30 {
  --tw-ring-color: #a855f74d;
}

.ring-purple-500\/40 {
  --tw-ring-color: #a855f766;
}

.ring-purple-500\/5 {
  --tw-ring-color: #a855f70d;
}

.ring-purple-500\/50 {
  --tw-ring-color: #a855f780;
}

.ring-purple-500\/60 {
  --tw-ring-color: #a855f799;
}

.ring-purple-500\/70 {
  --tw-ring-color: #a855f7b3;
}

.ring-purple-500\/75 {
  --tw-ring-color: #a855f7bf;
}

.ring-purple-500\/80 {
  --tw-ring-color: #a855f7cc;
}

.ring-purple-500\/90 {
  --tw-ring-color: #a855f7e6;
}

.ring-purple-500\/95 {
  --tw-ring-color: #a855f7f2;
}

.ring-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity));
}

.ring-purple-600\/0 {
  --tw-ring-color: #9333ea00;
}

.ring-purple-600\/10 {
  --tw-ring-color: #9333ea1a;
}

.ring-purple-600\/100 {
  --tw-ring-color: #9333ea;
}

.ring-purple-600\/20 {
  --tw-ring-color: #9333ea33;
}

.ring-purple-600\/25 {
  --tw-ring-color: #9333ea40;
}

.ring-purple-600\/30 {
  --tw-ring-color: #9333ea4d;
}

.ring-purple-600\/40 {
  --tw-ring-color: #9333ea66;
}

.ring-purple-600\/5 {
  --tw-ring-color: #9333ea0d;
}

.ring-purple-600\/50 {
  --tw-ring-color: #9333ea80;
}

.ring-purple-600\/60 {
  --tw-ring-color: #9333ea99;
}

.ring-purple-600\/70 {
  --tw-ring-color: #9333eab3;
}

.ring-purple-600\/75 {
  --tw-ring-color: #9333eabf;
}

.ring-purple-600\/80 {
  --tw-ring-color: #9333eacc;
}

.ring-purple-600\/90 {
  --tw-ring-color: #9333eae6;
}

.ring-purple-600\/95 {
  --tw-ring-color: #9333eaf2;
}

.ring-purple-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(126 34 206 / var(--tw-ring-opacity));
}

.ring-purple-700\/0 {
  --tw-ring-color: #7e22ce00;
}

.ring-purple-700\/10 {
  --tw-ring-color: #7e22ce1a;
}

.ring-purple-700\/100 {
  --tw-ring-color: #7e22ce;
}

.ring-purple-700\/20 {
  --tw-ring-color: #7e22ce33;
}

.ring-purple-700\/25 {
  --tw-ring-color: #7e22ce40;
}

.ring-purple-700\/30 {
  --tw-ring-color: #7e22ce4d;
}

.ring-purple-700\/40 {
  --tw-ring-color: #7e22ce66;
}

.ring-purple-700\/5 {
  --tw-ring-color: #7e22ce0d;
}

.ring-purple-700\/50 {
  --tw-ring-color: #7e22ce80;
}

.ring-purple-700\/60 {
  --tw-ring-color: #7e22ce99;
}

.ring-purple-700\/70 {
  --tw-ring-color: #7e22ceb3;
}

.ring-purple-700\/75 {
  --tw-ring-color: #7e22cebf;
}

.ring-purple-700\/80 {
  --tw-ring-color: #7e22cecc;
}

.ring-purple-700\/90 {
  --tw-ring-color: #7e22cee6;
}

.ring-purple-700\/95 {
  --tw-ring-color: #7e22cef2;
}

.ring-purple-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity));
}

.ring-purple-800\/0 {
  --tw-ring-color: #6b21a800;
}

.ring-purple-800\/10 {
  --tw-ring-color: #6b21a81a;
}

.ring-purple-800\/100 {
  --tw-ring-color: #6b21a8;
}

.ring-purple-800\/20 {
  --tw-ring-color: #6b21a833;
}

.ring-purple-800\/25 {
  --tw-ring-color: #6b21a840;
}

.ring-purple-800\/30 {
  --tw-ring-color: #6b21a84d;
}

.ring-purple-800\/40 {
  --tw-ring-color: #6b21a866;
}

.ring-purple-800\/5 {
  --tw-ring-color: #6b21a80d;
}

.ring-purple-800\/50 {
  --tw-ring-color: #6b21a880;
}

.ring-purple-800\/60 {
  --tw-ring-color: #6b21a899;
}

.ring-purple-800\/70 {
  --tw-ring-color: #6b21a8b3;
}

.ring-purple-800\/75 {
  --tw-ring-color: #6b21a8bf;
}

.ring-purple-800\/80 {
  --tw-ring-color: #6b21a8cc;
}

.ring-purple-800\/90 {
  --tw-ring-color: #6b21a8e6;
}

.ring-purple-800\/95 {
  --tw-ring-color: #6b21a8f2;
}

.ring-purple-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity));
}

.ring-purple-900\/0 {
  --tw-ring-color: #581c8700;
}

.ring-purple-900\/10 {
  --tw-ring-color: #581c871a;
}

.ring-purple-900\/100 {
  --tw-ring-color: #581c87;
}

.ring-purple-900\/20 {
  --tw-ring-color: #581c8733;
}

.ring-purple-900\/25 {
  --tw-ring-color: #581c8740;
}

.ring-purple-900\/30 {
  --tw-ring-color: #581c874d;
}

.ring-purple-900\/40 {
  --tw-ring-color: #581c8766;
}

.ring-purple-900\/5 {
  --tw-ring-color: #581c870d;
}

.ring-purple-900\/50 {
  --tw-ring-color: #581c8780;
}

.ring-purple-900\/60 {
  --tw-ring-color: #581c8799;
}

.ring-purple-900\/70 {
  --tw-ring-color: #581c87b3;
}

.ring-purple-900\/75 {
  --tw-ring-color: #581c87bf;
}

.ring-purple-900\/80 {
  --tw-ring-color: #581c87cc;
}

.ring-purple-900\/90 {
  --tw-ring-color: #581c87e6;
}

.ring-purple-900\/95 {
  --tw-ring-color: #581c87f2;
}

.ring-purple-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 7 100 / var(--tw-ring-opacity));
}

.ring-purple-950\/0 {
  --tw-ring-color: #3b076400;
}

.ring-purple-950\/10 {
  --tw-ring-color: #3b07641a;
}

.ring-purple-950\/100 {
  --tw-ring-color: #3b0764;
}

.ring-purple-950\/20 {
  --tw-ring-color: #3b076433;
}

.ring-purple-950\/25 {
  --tw-ring-color: #3b076440;
}

.ring-purple-950\/30 {
  --tw-ring-color: #3b07644d;
}

.ring-purple-950\/40 {
  --tw-ring-color: #3b076466;
}

.ring-purple-950\/5 {
  --tw-ring-color: #3b07640d;
}

.ring-purple-950\/50 {
  --tw-ring-color: #3b076480;
}

.ring-purple-950\/60 {
  --tw-ring-color: #3b076499;
}

.ring-purple-950\/70 {
  --tw-ring-color: #3b0764b3;
}

.ring-purple-950\/75 {
  --tw-ring-color: #3b0764bf;
}

.ring-purple-950\/80 {
  --tw-ring-color: #3b0764cc;
}

.ring-purple-950\/90 {
  --tw-ring-color: #3b0764e6;
}

.ring-purple-950\/95 {
  --tw-ring-color: #3b0764f2;
}

.ring-transparent {
  --tw-ring-color: transparent;
}

.ring-transparent\/0 {
  --tw-ring-color: #0000;
}

.ring-transparent\/10 {
  --tw-ring-color: #0000001a;
}

.ring-transparent\/100 {
  --tw-ring-color: #000;
}

.ring-transparent\/20 {
  --tw-ring-color: #0003;
}

.ring-transparent\/25 {
  --tw-ring-color: #00000040;
}

.ring-transparent\/30 {
  --tw-ring-color: #0000004d;
}

.ring-transparent\/40 {
  --tw-ring-color: #0006;
}

.ring-transparent\/5 {
  --tw-ring-color: #0000000d;
}

.ring-transparent\/50 {
  --tw-ring-color: #00000080;
}

.ring-transparent\/60 {
  --tw-ring-color: #0009;
}

.ring-transparent\/70 {
  --tw-ring-color: #000000b3;
}

.ring-transparent\/75 {
  --tw-ring-color: #000000bf;
}

.ring-transparent\/80 {
  --tw-ring-color: #000c;
}

.ring-transparent\/90 {
  --tw-ring-color: #000000e6;
}

.ring-transparent\/95 {
  --tw-ring-color: #000000f2;
}

.ring-opacity-0 {
  --tw-ring-opacity: 0;
}

.ring-opacity-10 {
  --tw-ring-opacity: .1;
}

.ring-opacity-100 {
  --tw-ring-opacity: 1;
}

.ring-opacity-20 {
  --tw-ring-opacity: .2;
}

.ring-opacity-25 {
  --tw-ring-opacity: .25;
}

.ring-opacity-30 {
  --tw-ring-opacity: .3;
}

.ring-opacity-40 {
  --tw-ring-opacity: .4;
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.ring-opacity-50 {
  --tw-ring-opacity: .5;
}

.ring-opacity-60 {
  --tw-ring-opacity: .6;
}

.ring-opacity-70 {
  --tw-ring-opacity: .7;
}

.ring-opacity-75 {
  --tw-ring-opacity: .75;
}

.ring-opacity-80 {
  --tw-ring-opacity: .8;
}

.ring-opacity-90 {
  --tw-ring-opacity: .9;
}

.ring-opacity-95 {
  --tw-ring-opacity: .95;
}

.ring-offset-amber-100 {
  --tw-ring-offset-color: #fef3c7;
}

.ring-offset-amber-100\/0 {
  --tw-ring-offset-color: #fef3c700;
}

.ring-offset-amber-100\/10 {
  --tw-ring-offset-color: #fef3c71a;
}

.ring-offset-amber-100\/100 {
  --tw-ring-offset-color: #fef3c7;
}

.ring-offset-amber-100\/20 {
  --tw-ring-offset-color: #fef3c733;
}

.ring-offset-amber-100\/25 {
  --tw-ring-offset-color: #fef3c740;
}

.ring-offset-amber-100\/30 {
  --tw-ring-offset-color: #fef3c74d;
}

.ring-offset-amber-100\/40 {
  --tw-ring-offset-color: #fef3c766;
}

.ring-offset-amber-100\/5 {
  --tw-ring-offset-color: #fef3c70d;
}

.ring-offset-amber-100\/50 {
  --tw-ring-offset-color: #fef3c780;
}

.ring-offset-amber-100\/60 {
  --tw-ring-offset-color: #fef3c799;
}

.ring-offset-amber-100\/70 {
  --tw-ring-offset-color: #fef3c7b3;
}

.ring-offset-amber-100\/75 {
  --tw-ring-offset-color: #fef3c7bf;
}

.ring-offset-amber-100\/80 {
  --tw-ring-offset-color: #fef3c7cc;
}

.ring-offset-amber-100\/90 {
  --tw-ring-offset-color: #fef3c7e6;
}

.ring-offset-amber-100\/95 {
  --tw-ring-offset-color: #fef3c7f2;
}

.ring-offset-amber-200 {
  --tw-ring-offset-color: #fde68a;
}

.ring-offset-amber-200\/0 {
  --tw-ring-offset-color: #fde68a00;
}

.ring-offset-amber-200\/10 {
  --tw-ring-offset-color: #fde68a1a;
}

.ring-offset-amber-200\/100 {
  --tw-ring-offset-color: #fde68a;
}

.ring-offset-amber-200\/20 {
  --tw-ring-offset-color: #fde68a33;
}

.ring-offset-amber-200\/25 {
  --tw-ring-offset-color: #fde68a40;
}

.ring-offset-amber-200\/30 {
  --tw-ring-offset-color: #fde68a4d;
}

.ring-offset-amber-200\/40 {
  --tw-ring-offset-color: #fde68a66;
}

.ring-offset-amber-200\/5 {
  --tw-ring-offset-color: #fde68a0d;
}

.ring-offset-amber-200\/50 {
  --tw-ring-offset-color: #fde68a80;
}

.ring-offset-amber-200\/60 {
  --tw-ring-offset-color: #fde68a99;
}

.ring-offset-amber-200\/70 {
  --tw-ring-offset-color: #fde68ab3;
}

.ring-offset-amber-200\/75 {
  --tw-ring-offset-color: #fde68abf;
}

.ring-offset-amber-200\/80 {
  --tw-ring-offset-color: #fde68acc;
}

.ring-offset-amber-200\/90 {
  --tw-ring-offset-color: #fde68ae6;
}

.ring-offset-amber-200\/95 {
  --tw-ring-offset-color: #fde68af2;
}

.ring-offset-amber-300 {
  --tw-ring-offset-color: #fcd34d;
}

.ring-offset-amber-300\/0 {
  --tw-ring-offset-color: #fcd34d00;
}

.ring-offset-amber-300\/10 {
  --tw-ring-offset-color: #fcd34d1a;
}

.ring-offset-amber-300\/100 {
  --tw-ring-offset-color: #fcd34d;
}

.ring-offset-amber-300\/20 {
  --tw-ring-offset-color: #fcd34d33;
}

.ring-offset-amber-300\/25 {
  --tw-ring-offset-color: #fcd34d40;
}

.ring-offset-amber-300\/30 {
  --tw-ring-offset-color: #fcd34d4d;
}

.ring-offset-amber-300\/40 {
  --tw-ring-offset-color: #fcd34d66;
}

.ring-offset-amber-300\/5 {
  --tw-ring-offset-color: #fcd34d0d;
}

.ring-offset-amber-300\/50 {
  --tw-ring-offset-color: #fcd34d80;
}

.ring-offset-amber-300\/60 {
  --tw-ring-offset-color: #fcd34d99;
}

.ring-offset-amber-300\/70 {
  --tw-ring-offset-color: #fcd34db3;
}

.ring-offset-amber-300\/75 {
  --tw-ring-offset-color: #fcd34dbf;
}

.ring-offset-amber-300\/80 {
  --tw-ring-offset-color: #fcd34dcc;
}

.ring-offset-amber-300\/90 {
  --tw-ring-offset-color: #fcd34de6;
}

.ring-offset-amber-300\/95 {
  --tw-ring-offset-color: #fcd34df2;
}

.ring-offset-amber-400 {
  --tw-ring-offset-color: #fbbf24;
}

.ring-offset-amber-400\/0 {
  --tw-ring-offset-color: #fbbf2400;
}

.ring-offset-amber-400\/10 {
  --tw-ring-offset-color: #fbbf241a;
}

.ring-offset-amber-400\/100 {
  --tw-ring-offset-color: #fbbf24;
}

.ring-offset-amber-400\/20 {
  --tw-ring-offset-color: #fbbf2433;
}

.ring-offset-amber-400\/25 {
  --tw-ring-offset-color: #fbbf2440;
}

.ring-offset-amber-400\/30 {
  --tw-ring-offset-color: #fbbf244d;
}

.ring-offset-amber-400\/40 {
  --tw-ring-offset-color: #fbbf2466;
}

.ring-offset-amber-400\/5 {
  --tw-ring-offset-color: #fbbf240d;
}

.ring-offset-amber-400\/50 {
  --tw-ring-offset-color: #fbbf2480;
}

.ring-offset-amber-400\/60 {
  --tw-ring-offset-color: #fbbf2499;
}

.ring-offset-amber-400\/70 {
  --tw-ring-offset-color: #fbbf24b3;
}

.ring-offset-amber-400\/75 {
  --tw-ring-offset-color: #fbbf24bf;
}

.ring-offset-amber-400\/80 {
  --tw-ring-offset-color: #fbbf24cc;
}

.ring-offset-amber-400\/90 {
  --tw-ring-offset-color: #fbbf24e6;
}

.ring-offset-amber-400\/95 {
  --tw-ring-offset-color: #fbbf24f2;
}

.ring-offset-amber-50 {
  --tw-ring-offset-color: #fffbeb;
}

.ring-offset-amber-50\/0 {
  --tw-ring-offset-color: #fffbeb00;
}

.ring-offset-amber-50\/10 {
  --tw-ring-offset-color: #fffbeb1a;
}

.ring-offset-amber-50\/100 {
  --tw-ring-offset-color: #fffbeb;
}

.ring-offset-amber-50\/20 {
  --tw-ring-offset-color: #fffbeb33;
}

.ring-offset-amber-50\/25 {
  --tw-ring-offset-color: #fffbeb40;
}

.ring-offset-amber-50\/30 {
  --tw-ring-offset-color: #fffbeb4d;
}

.ring-offset-amber-50\/40 {
  --tw-ring-offset-color: #fffbeb66;
}

.ring-offset-amber-50\/5 {
  --tw-ring-offset-color: #fffbeb0d;
}

.ring-offset-amber-50\/50 {
  --tw-ring-offset-color: #fffbeb80;
}

.ring-offset-amber-50\/60 {
  --tw-ring-offset-color: #fffbeb99;
}

.ring-offset-amber-50\/70 {
  --tw-ring-offset-color: #fffbebb3;
}

.ring-offset-amber-50\/75 {
  --tw-ring-offset-color: #fffbebbf;
}

.ring-offset-amber-50\/80 {
  --tw-ring-offset-color: #fffbebcc;
}

.ring-offset-amber-50\/90 {
  --tw-ring-offset-color: #fffbebe6;
}

.ring-offset-amber-50\/95 {
  --tw-ring-offset-color: #fffbebf2;
}

.ring-offset-amber-500 {
  --tw-ring-offset-color: #f59e0b;
}

.ring-offset-amber-500\/0 {
  --tw-ring-offset-color: #f59e0b00;
}

.ring-offset-amber-500\/10 {
  --tw-ring-offset-color: #f59e0b1a;
}

.ring-offset-amber-500\/100 {
  --tw-ring-offset-color: #f59e0b;
}

.ring-offset-amber-500\/20 {
  --tw-ring-offset-color: #f59e0b33;
}

.ring-offset-amber-500\/25 {
  --tw-ring-offset-color: #f59e0b40;
}

.ring-offset-amber-500\/30 {
  --tw-ring-offset-color: #f59e0b4d;
}

.ring-offset-amber-500\/40 {
  --tw-ring-offset-color: #f59e0b66;
}

.ring-offset-amber-500\/5 {
  --tw-ring-offset-color: #f59e0b0d;
}

.ring-offset-amber-500\/50 {
  --tw-ring-offset-color: #f59e0b80;
}

.ring-offset-amber-500\/60 {
  --tw-ring-offset-color: #f59e0b99;
}

.ring-offset-amber-500\/70 {
  --tw-ring-offset-color: #f59e0bb3;
}

.ring-offset-amber-500\/75 {
  --tw-ring-offset-color: #f59e0bbf;
}

.ring-offset-amber-500\/80 {
  --tw-ring-offset-color: #f59e0bcc;
}

.ring-offset-amber-500\/90 {
  --tw-ring-offset-color: #f59e0be6;
}

.ring-offset-amber-500\/95 {
  --tw-ring-offset-color: #f59e0bf2;
}

.ring-offset-amber-600 {
  --tw-ring-offset-color: #d97706;
}

.ring-offset-amber-600\/0 {
  --tw-ring-offset-color: #d9770600;
}

.ring-offset-amber-600\/10 {
  --tw-ring-offset-color: #d977061a;
}

.ring-offset-amber-600\/100 {
  --tw-ring-offset-color: #d97706;
}

.ring-offset-amber-600\/20 {
  --tw-ring-offset-color: #d9770633;
}

.ring-offset-amber-600\/25 {
  --tw-ring-offset-color: #d9770640;
}

.ring-offset-amber-600\/30 {
  --tw-ring-offset-color: #d977064d;
}

.ring-offset-amber-600\/40 {
  --tw-ring-offset-color: #d9770666;
}

.ring-offset-amber-600\/5 {
  --tw-ring-offset-color: #d977060d;
}

.ring-offset-amber-600\/50 {
  --tw-ring-offset-color: #d9770680;
}

.ring-offset-amber-600\/60 {
  --tw-ring-offset-color: #d9770699;
}

.ring-offset-amber-600\/70 {
  --tw-ring-offset-color: #d97706b3;
}

.ring-offset-amber-600\/75 {
  --tw-ring-offset-color: #d97706bf;
}

.ring-offset-amber-600\/80 {
  --tw-ring-offset-color: #d97706cc;
}

.ring-offset-amber-600\/90 {
  --tw-ring-offset-color: #d97706e6;
}

.ring-offset-amber-600\/95 {
  --tw-ring-offset-color: #d97706f2;
}

.ring-offset-amber-700 {
  --tw-ring-offset-color: #b45309;
}

.ring-offset-amber-700\/0 {
  --tw-ring-offset-color: #b4530900;
}

.ring-offset-amber-700\/10 {
  --tw-ring-offset-color: #b453091a;
}

.ring-offset-amber-700\/100 {
  --tw-ring-offset-color: #b45309;
}

.ring-offset-amber-700\/20 {
  --tw-ring-offset-color: #b4530933;
}

.ring-offset-amber-700\/25 {
  --tw-ring-offset-color: #b4530940;
}

.ring-offset-amber-700\/30 {
  --tw-ring-offset-color: #b453094d;
}

.ring-offset-amber-700\/40 {
  --tw-ring-offset-color: #b4530966;
}

.ring-offset-amber-700\/5 {
  --tw-ring-offset-color: #b453090d;
}

.ring-offset-amber-700\/50 {
  --tw-ring-offset-color: #b4530980;
}

.ring-offset-amber-700\/60 {
  --tw-ring-offset-color: #b4530999;
}

.ring-offset-amber-700\/70 {
  --tw-ring-offset-color: #b45309b3;
}

.ring-offset-amber-700\/75 {
  --tw-ring-offset-color: #b45309bf;
}

.ring-offset-amber-700\/80 {
  --tw-ring-offset-color: #b45309cc;
}

.ring-offset-amber-700\/90 {
  --tw-ring-offset-color: #b45309e6;
}

.ring-offset-amber-700\/95 {
  --tw-ring-offset-color: #b45309f2;
}

.ring-offset-amber-800 {
  --tw-ring-offset-color: #92400e;
}

.ring-offset-amber-800\/0 {
  --tw-ring-offset-color: #92400e00;
}

.ring-offset-amber-800\/10 {
  --tw-ring-offset-color: #92400e1a;
}

.ring-offset-amber-800\/100 {
  --tw-ring-offset-color: #92400e;
}

.ring-offset-amber-800\/20 {
  --tw-ring-offset-color: #92400e33;
}

.ring-offset-amber-800\/25 {
  --tw-ring-offset-color: #92400e40;
}

.ring-offset-amber-800\/30 {
  --tw-ring-offset-color: #92400e4d;
}

.ring-offset-amber-800\/40 {
  --tw-ring-offset-color: #92400e66;
}

.ring-offset-amber-800\/5 {
  --tw-ring-offset-color: #92400e0d;
}

.ring-offset-amber-800\/50 {
  --tw-ring-offset-color: #92400e80;
}

.ring-offset-amber-800\/60 {
  --tw-ring-offset-color: #92400e99;
}

.ring-offset-amber-800\/70 {
  --tw-ring-offset-color: #92400eb3;
}

.ring-offset-amber-800\/75 {
  --tw-ring-offset-color: #92400ebf;
}

.ring-offset-amber-800\/80 {
  --tw-ring-offset-color: #92400ecc;
}

.ring-offset-amber-800\/90 {
  --tw-ring-offset-color: #92400ee6;
}

.ring-offset-amber-800\/95 {
  --tw-ring-offset-color: #92400ef2;
}

.ring-offset-amber-900 {
  --tw-ring-offset-color: #78350f;
}

.ring-offset-amber-900\/0 {
  --tw-ring-offset-color: #78350f00;
}

.ring-offset-amber-900\/10 {
  --tw-ring-offset-color: #78350f1a;
}

.ring-offset-amber-900\/100 {
  --tw-ring-offset-color: #78350f;
}

.ring-offset-amber-900\/20 {
  --tw-ring-offset-color: #78350f33;
}

.ring-offset-amber-900\/25 {
  --tw-ring-offset-color: #78350f40;
}

.ring-offset-amber-900\/30 {
  --tw-ring-offset-color: #78350f4d;
}

.ring-offset-amber-900\/40 {
  --tw-ring-offset-color: #78350f66;
}

.ring-offset-amber-900\/5 {
  --tw-ring-offset-color: #78350f0d;
}

.ring-offset-amber-900\/50 {
  --tw-ring-offset-color: #78350f80;
}

.ring-offset-amber-900\/60 {
  --tw-ring-offset-color: #78350f99;
}

.ring-offset-amber-900\/70 {
  --tw-ring-offset-color: #78350fb3;
}

.ring-offset-amber-900\/75 {
  --tw-ring-offset-color: #78350fbf;
}

.ring-offset-amber-900\/80 {
  --tw-ring-offset-color: #78350fcc;
}

.ring-offset-amber-900\/90 {
  --tw-ring-offset-color: #78350fe6;
}

.ring-offset-amber-900\/95 {
  --tw-ring-offset-color: #78350ff2;
}

.ring-offset-amber-950 {
  --tw-ring-offset-color: #451a03;
}

.ring-offset-amber-950\/0 {
  --tw-ring-offset-color: #451a0300;
}

.ring-offset-amber-950\/10 {
  --tw-ring-offset-color: #451a031a;
}

.ring-offset-amber-950\/100 {
  --tw-ring-offset-color: #451a03;
}

.ring-offset-amber-950\/20 {
  --tw-ring-offset-color: #451a0333;
}

.ring-offset-amber-950\/25 {
  --tw-ring-offset-color: #451a0340;
}

.ring-offset-amber-950\/30 {
  --tw-ring-offset-color: #451a034d;
}

.ring-offset-amber-950\/40 {
  --tw-ring-offset-color: #451a0366;
}

.ring-offset-amber-950\/5 {
  --tw-ring-offset-color: #451a030d;
}

.ring-offset-amber-950\/50 {
  --tw-ring-offset-color: #451a0380;
}

.ring-offset-amber-950\/60 {
  --tw-ring-offset-color: #451a0399;
}

.ring-offset-amber-950\/70 {
  --tw-ring-offset-color: #451a03b3;
}

.ring-offset-amber-950\/75 {
  --tw-ring-offset-color: #451a03bf;
}

.ring-offset-amber-950\/80 {
  --tw-ring-offset-color: #451a03cc;
}

.ring-offset-amber-950\/90 {
  --tw-ring-offset-color: #451a03e6;
}

.ring-offset-amber-950\/95 {
  --tw-ring-offset-color: #451a03f2;
}

.ring-offset-emerald-100 {
  --tw-ring-offset-color: #d1fae5;
}

.ring-offset-emerald-100\/0 {
  --tw-ring-offset-color: #d1fae500;
}

.ring-offset-emerald-100\/10 {
  --tw-ring-offset-color: #d1fae51a;
}

.ring-offset-emerald-100\/100 {
  --tw-ring-offset-color: #d1fae5;
}

.ring-offset-emerald-100\/20 {
  --tw-ring-offset-color: #d1fae533;
}

.ring-offset-emerald-100\/25 {
  --tw-ring-offset-color: #d1fae540;
}

.ring-offset-emerald-100\/30 {
  --tw-ring-offset-color: #d1fae54d;
}

.ring-offset-emerald-100\/40 {
  --tw-ring-offset-color: #d1fae566;
}

.ring-offset-emerald-100\/5 {
  --tw-ring-offset-color: #d1fae50d;
}

.ring-offset-emerald-100\/50 {
  --tw-ring-offset-color: #d1fae580;
}

.ring-offset-emerald-100\/60 {
  --tw-ring-offset-color: #d1fae599;
}

.ring-offset-emerald-100\/70 {
  --tw-ring-offset-color: #d1fae5b3;
}

.ring-offset-emerald-100\/75 {
  --tw-ring-offset-color: #d1fae5bf;
}

.ring-offset-emerald-100\/80 {
  --tw-ring-offset-color: #d1fae5cc;
}

.ring-offset-emerald-100\/90 {
  --tw-ring-offset-color: #d1fae5e6;
}

.ring-offset-emerald-100\/95 {
  --tw-ring-offset-color: #d1fae5f2;
}

.ring-offset-emerald-200 {
  --tw-ring-offset-color: #a7f3d0;
}

.ring-offset-emerald-200\/0 {
  --tw-ring-offset-color: #a7f3d000;
}

.ring-offset-emerald-200\/10 {
  --tw-ring-offset-color: #a7f3d01a;
}

.ring-offset-emerald-200\/100 {
  --tw-ring-offset-color: #a7f3d0;
}

.ring-offset-emerald-200\/20 {
  --tw-ring-offset-color: #a7f3d033;
}

.ring-offset-emerald-200\/25 {
  --tw-ring-offset-color: #a7f3d040;
}

.ring-offset-emerald-200\/30 {
  --tw-ring-offset-color: #a7f3d04d;
}

.ring-offset-emerald-200\/40 {
  --tw-ring-offset-color: #a7f3d066;
}

.ring-offset-emerald-200\/5 {
  --tw-ring-offset-color: #a7f3d00d;
}

.ring-offset-emerald-200\/50 {
  --tw-ring-offset-color: #a7f3d080;
}

.ring-offset-emerald-200\/60 {
  --tw-ring-offset-color: #a7f3d099;
}

.ring-offset-emerald-200\/70 {
  --tw-ring-offset-color: #a7f3d0b3;
}

.ring-offset-emerald-200\/75 {
  --tw-ring-offset-color: #a7f3d0bf;
}

.ring-offset-emerald-200\/80 {
  --tw-ring-offset-color: #a7f3d0cc;
}

.ring-offset-emerald-200\/90 {
  --tw-ring-offset-color: #a7f3d0e6;
}

.ring-offset-emerald-200\/95 {
  --tw-ring-offset-color: #a7f3d0f2;
}

.ring-offset-emerald-300 {
  --tw-ring-offset-color: #6ee7b7;
}

.ring-offset-emerald-300\/0 {
  --tw-ring-offset-color: #6ee7b700;
}

.ring-offset-emerald-300\/10 {
  --tw-ring-offset-color: #6ee7b71a;
}

.ring-offset-emerald-300\/100 {
  --tw-ring-offset-color: #6ee7b7;
}

.ring-offset-emerald-300\/20 {
  --tw-ring-offset-color: #6ee7b733;
}

.ring-offset-emerald-300\/25 {
  --tw-ring-offset-color: #6ee7b740;
}

.ring-offset-emerald-300\/30 {
  --tw-ring-offset-color: #6ee7b74d;
}

.ring-offset-emerald-300\/40 {
  --tw-ring-offset-color: #6ee7b766;
}

.ring-offset-emerald-300\/5 {
  --tw-ring-offset-color: #6ee7b70d;
}

.ring-offset-emerald-300\/50 {
  --tw-ring-offset-color: #6ee7b780;
}

.ring-offset-emerald-300\/60 {
  --tw-ring-offset-color: #6ee7b799;
}

.ring-offset-emerald-300\/70 {
  --tw-ring-offset-color: #6ee7b7b3;
}

.ring-offset-emerald-300\/75 {
  --tw-ring-offset-color: #6ee7b7bf;
}

.ring-offset-emerald-300\/80 {
  --tw-ring-offset-color: #6ee7b7cc;
}

.ring-offset-emerald-300\/90 {
  --tw-ring-offset-color: #6ee7b7e6;
}

.ring-offset-emerald-300\/95 {
  --tw-ring-offset-color: #6ee7b7f2;
}

.ring-offset-emerald-400 {
  --tw-ring-offset-color: #34d399;
}

.ring-offset-emerald-400\/0 {
  --tw-ring-offset-color: #34d39900;
}

.ring-offset-emerald-400\/10 {
  --tw-ring-offset-color: #34d3991a;
}

.ring-offset-emerald-400\/100 {
  --tw-ring-offset-color: #34d399;
}

.ring-offset-emerald-400\/20 {
  --tw-ring-offset-color: #34d39933;
}

.ring-offset-emerald-400\/25 {
  --tw-ring-offset-color: #34d39940;
}

.ring-offset-emerald-400\/30 {
  --tw-ring-offset-color: #34d3994d;
}

.ring-offset-emerald-400\/40 {
  --tw-ring-offset-color: #34d39966;
}

.ring-offset-emerald-400\/5 {
  --tw-ring-offset-color: #34d3990d;
}

.ring-offset-emerald-400\/50 {
  --tw-ring-offset-color: #34d39980;
}

.ring-offset-emerald-400\/60 {
  --tw-ring-offset-color: #34d39999;
}

.ring-offset-emerald-400\/70 {
  --tw-ring-offset-color: #34d399b3;
}

.ring-offset-emerald-400\/75 {
  --tw-ring-offset-color: #34d399bf;
}

.ring-offset-emerald-400\/80 {
  --tw-ring-offset-color: #34d399cc;
}

.ring-offset-emerald-400\/90 {
  --tw-ring-offset-color: #34d399e6;
}

.ring-offset-emerald-400\/95 {
  --tw-ring-offset-color: #34d399f2;
}

.ring-offset-emerald-50 {
  --tw-ring-offset-color: #ecfdf5;
}

.ring-offset-emerald-50\/0 {
  --tw-ring-offset-color: #ecfdf500;
}

.ring-offset-emerald-50\/10 {
  --tw-ring-offset-color: #ecfdf51a;
}

.ring-offset-emerald-50\/100 {
  --tw-ring-offset-color: #ecfdf5;
}

.ring-offset-emerald-50\/20 {
  --tw-ring-offset-color: #ecfdf533;
}

.ring-offset-emerald-50\/25 {
  --tw-ring-offset-color: #ecfdf540;
}

.ring-offset-emerald-50\/30 {
  --tw-ring-offset-color: #ecfdf54d;
}

.ring-offset-emerald-50\/40 {
  --tw-ring-offset-color: #ecfdf566;
}

.ring-offset-emerald-50\/5 {
  --tw-ring-offset-color: #ecfdf50d;
}

.ring-offset-emerald-50\/50 {
  --tw-ring-offset-color: #ecfdf580;
}

.ring-offset-emerald-50\/60 {
  --tw-ring-offset-color: #ecfdf599;
}

.ring-offset-emerald-50\/70 {
  --tw-ring-offset-color: #ecfdf5b3;
}

.ring-offset-emerald-50\/75 {
  --tw-ring-offset-color: #ecfdf5bf;
}

.ring-offset-emerald-50\/80 {
  --tw-ring-offset-color: #ecfdf5cc;
}

.ring-offset-emerald-50\/90 {
  --tw-ring-offset-color: #ecfdf5e6;
}

.ring-offset-emerald-50\/95 {
  --tw-ring-offset-color: #ecfdf5f2;
}

.ring-offset-emerald-500 {
  --tw-ring-offset-color: #10b981;
}

.ring-offset-emerald-500\/0 {
  --tw-ring-offset-color: #10b98100;
}

.ring-offset-emerald-500\/10 {
  --tw-ring-offset-color: #10b9811a;
}

.ring-offset-emerald-500\/100 {
  --tw-ring-offset-color: #10b981;
}

.ring-offset-emerald-500\/20 {
  --tw-ring-offset-color: #10b98133;
}

.ring-offset-emerald-500\/25 {
  --tw-ring-offset-color: #10b98140;
}

.ring-offset-emerald-500\/30 {
  --tw-ring-offset-color: #10b9814d;
}

.ring-offset-emerald-500\/40 {
  --tw-ring-offset-color: #10b98166;
}

.ring-offset-emerald-500\/5 {
  --tw-ring-offset-color: #10b9810d;
}

.ring-offset-emerald-500\/50 {
  --tw-ring-offset-color: #10b98180;
}

.ring-offset-emerald-500\/60 {
  --tw-ring-offset-color: #10b98199;
}

.ring-offset-emerald-500\/70 {
  --tw-ring-offset-color: #10b981b3;
}

.ring-offset-emerald-500\/75 {
  --tw-ring-offset-color: #10b981bf;
}

.ring-offset-emerald-500\/80 {
  --tw-ring-offset-color: #10b981cc;
}

.ring-offset-emerald-500\/90 {
  --tw-ring-offset-color: #10b981e6;
}

.ring-offset-emerald-500\/95 {
  --tw-ring-offset-color: #10b981f2;
}

.ring-offset-emerald-600 {
  --tw-ring-offset-color: #059669;
}

.ring-offset-emerald-600\/0 {
  --tw-ring-offset-color: #05966900;
}

.ring-offset-emerald-600\/10 {
  --tw-ring-offset-color: #0596691a;
}

.ring-offset-emerald-600\/100 {
  --tw-ring-offset-color: #059669;
}

.ring-offset-emerald-600\/20 {
  --tw-ring-offset-color: #05966933;
}

.ring-offset-emerald-600\/25 {
  --tw-ring-offset-color: #05966940;
}

.ring-offset-emerald-600\/30 {
  --tw-ring-offset-color: #0596694d;
}

.ring-offset-emerald-600\/40 {
  --tw-ring-offset-color: #05966966;
}

.ring-offset-emerald-600\/5 {
  --tw-ring-offset-color: #0596690d;
}

.ring-offset-emerald-600\/50 {
  --tw-ring-offset-color: #05966980;
}

.ring-offset-emerald-600\/60 {
  --tw-ring-offset-color: #05966999;
}

.ring-offset-emerald-600\/70 {
  --tw-ring-offset-color: #059669b3;
}

.ring-offset-emerald-600\/75 {
  --tw-ring-offset-color: #059669bf;
}

.ring-offset-emerald-600\/80 {
  --tw-ring-offset-color: #059669cc;
}

.ring-offset-emerald-600\/90 {
  --tw-ring-offset-color: #059669e6;
}

.ring-offset-emerald-600\/95 {
  --tw-ring-offset-color: #059669f2;
}

.ring-offset-emerald-700 {
  --tw-ring-offset-color: #047857;
}

.ring-offset-emerald-700\/0 {
  --tw-ring-offset-color: #04785700;
}

.ring-offset-emerald-700\/10 {
  --tw-ring-offset-color: #0478571a;
}

.ring-offset-emerald-700\/100 {
  --tw-ring-offset-color: #047857;
}

.ring-offset-emerald-700\/20 {
  --tw-ring-offset-color: #04785733;
}

.ring-offset-emerald-700\/25 {
  --tw-ring-offset-color: #04785740;
}

.ring-offset-emerald-700\/30 {
  --tw-ring-offset-color: #0478574d;
}

.ring-offset-emerald-700\/40 {
  --tw-ring-offset-color: #04785766;
}

.ring-offset-emerald-700\/5 {
  --tw-ring-offset-color: #0478570d;
}

.ring-offset-emerald-700\/50 {
  --tw-ring-offset-color: #04785780;
}

.ring-offset-emerald-700\/60 {
  --tw-ring-offset-color: #04785799;
}

.ring-offset-emerald-700\/70 {
  --tw-ring-offset-color: #047857b3;
}

.ring-offset-emerald-700\/75 {
  --tw-ring-offset-color: #047857bf;
}

.ring-offset-emerald-700\/80 {
  --tw-ring-offset-color: #047857cc;
}

.ring-offset-emerald-700\/90 {
  --tw-ring-offset-color: #047857e6;
}

.ring-offset-emerald-700\/95 {
  --tw-ring-offset-color: #047857f2;
}

.ring-offset-emerald-800 {
  --tw-ring-offset-color: #065f46;
}

.ring-offset-emerald-800\/0 {
  --tw-ring-offset-color: #065f4600;
}

.ring-offset-emerald-800\/10 {
  --tw-ring-offset-color: #065f461a;
}

.ring-offset-emerald-800\/100 {
  --tw-ring-offset-color: #065f46;
}

.ring-offset-emerald-800\/20 {
  --tw-ring-offset-color: #065f4633;
}

.ring-offset-emerald-800\/25 {
  --tw-ring-offset-color: #065f4640;
}

.ring-offset-emerald-800\/30 {
  --tw-ring-offset-color: #065f464d;
}

.ring-offset-emerald-800\/40 {
  --tw-ring-offset-color: #065f4666;
}

.ring-offset-emerald-800\/5 {
  --tw-ring-offset-color: #065f460d;
}

.ring-offset-emerald-800\/50 {
  --tw-ring-offset-color: #065f4680;
}

.ring-offset-emerald-800\/60 {
  --tw-ring-offset-color: #065f4699;
}

.ring-offset-emerald-800\/70 {
  --tw-ring-offset-color: #065f46b3;
}

.ring-offset-emerald-800\/75 {
  --tw-ring-offset-color: #065f46bf;
}

.ring-offset-emerald-800\/80 {
  --tw-ring-offset-color: #065f46cc;
}

.ring-offset-emerald-800\/90 {
  --tw-ring-offset-color: #065f46e6;
}

.ring-offset-emerald-800\/95 {
  --tw-ring-offset-color: #065f46f2;
}

.ring-offset-emerald-900 {
  --tw-ring-offset-color: #064e3b;
}

.ring-offset-emerald-900\/0 {
  --tw-ring-offset-color: #064e3b00;
}

.ring-offset-emerald-900\/10 {
  --tw-ring-offset-color: #064e3b1a;
}

.ring-offset-emerald-900\/100 {
  --tw-ring-offset-color: #064e3b;
}

.ring-offset-emerald-900\/20 {
  --tw-ring-offset-color: #064e3b33;
}

.ring-offset-emerald-900\/25 {
  --tw-ring-offset-color: #064e3b40;
}

.ring-offset-emerald-900\/30 {
  --tw-ring-offset-color: #064e3b4d;
}

.ring-offset-emerald-900\/40 {
  --tw-ring-offset-color: #064e3b66;
}

.ring-offset-emerald-900\/5 {
  --tw-ring-offset-color: #064e3b0d;
}

.ring-offset-emerald-900\/50 {
  --tw-ring-offset-color: #064e3b80;
}

.ring-offset-emerald-900\/60 {
  --tw-ring-offset-color: #064e3b99;
}

.ring-offset-emerald-900\/70 {
  --tw-ring-offset-color: #064e3bb3;
}

.ring-offset-emerald-900\/75 {
  --tw-ring-offset-color: #064e3bbf;
}

.ring-offset-emerald-900\/80 {
  --tw-ring-offset-color: #064e3bcc;
}

.ring-offset-emerald-900\/90 {
  --tw-ring-offset-color: #064e3be6;
}

.ring-offset-emerald-900\/95 {
  --tw-ring-offset-color: #064e3bf2;
}

.ring-offset-emerald-950 {
  --tw-ring-offset-color: #022c22;
}

.ring-offset-emerald-950\/0 {
  --tw-ring-offset-color: #022c2200;
}

.ring-offset-emerald-950\/10 {
  --tw-ring-offset-color: #022c221a;
}

.ring-offset-emerald-950\/100 {
  --tw-ring-offset-color: #022c22;
}

.ring-offset-emerald-950\/20 {
  --tw-ring-offset-color: #022c2233;
}

.ring-offset-emerald-950\/25 {
  --tw-ring-offset-color: #022c2240;
}

.ring-offset-emerald-950\/30 {
  --tw-ring-offset-color: #022c224d;
}

.ring-offset-emerald-950\/40 {
  --tw-ring-offset-color: #022c2266;
}

.ring-offset-emerald-950\/5 {
  --tw-ring-offset-color: #022c220d;
}

.ring-offset-emerald-950\/50 {
  --tw-ring-offset-color: #022c2280;
}

.ring-offset-emerald-950\/60 {
  --tw-ring-offset-color: #022c2299;
}

.ring-offset-emerald-950\/70 {
  --tw-ring-offset-color: #022c22b3;
}

.ring-offset-emerald-950\/75 {
  --tw-ring-offset-color: #022c22bf;
}

.ring-offset-emerald-950\/80 {
  --tw-ring-offset-color: #022c22cc;
}

.ring-offset-emerald-950\/90 {
  --tw-ring-offset-color: #022c22e6;
}

.ring-offset-emerald-950\/95 {
  --tw-ring-offset-color: #022c22f2;
}

.ring-offset-lime-100 {
  --tw-ring-offset-color: #ecfccb;
}

.ring-offset-lime-100\/0 {
  --tw-ring-offset-color: #ecfccb00;
}

.ring-offset-lime-100\/10 {
  --tw-ring-offset-color: #ecfccb1a;
}

.ring-offset-lime-100\/100 {
  --tw-ring-offset-color: #ecfccb;
}

.ring-offset-lime-100\/20 {
  --tw-ring-offset-color: #ecfccb33;
}

.ring-offset-lime-100\/25 {
  --tw-ring-offset-color: #ecfccb40;
}

.ring-offset-lime-100\/30 {
  --tw-ring-offset-color: #ecfccb4d;
}

.ring-offset-lime-100\/40 {
  --tw-ring-offset-color: #ecfccb66;
}

.ring-offset-lime-100\/5 {
  --tw-ring-offset-color: #ecfccb0d;
}

.ring-offset-lime-100\/50 {
  --tw-ring-offset-color: #ecfccb80;
}

.ring-offset-lime-100\/60 {
  --tw-ring-offset-color: #ecfccb99;
}

.ring-offset-lime-100\/70 {
  --tw-ring-offset-color: #ecfccbb3;
}

.ring-offset-lime-100\/75 {
  --tw-ring-offset-color: #ecfccbbf;
}

.ring-offset-lime-100\/80 {
  --tw-ring-offset-color: #ecfccbcc;
}

.ring-offset-lime-100\/90 {
  --tw-ring-offset-color: #ecfccbe6;
}

.ring-offset-lime-100\/95 {
  --tw-ring-offset-color: #ecfccbf2;
}

.ring-offset-lime-200 {
  --tw-ring-offset-color: #d9f99d;
}

.ring-offset-lime-200\/0 {
  --tw-ring-offset-color: #d9f99d00;
}

.ring-offset-lime-200\/10 {
  --tw-ring-offset-color: #d9f99d1a;
}

.ring-offset-lime-200\/100 {
  --tw-ring-offset-color: #d9f99d;
}

.ring-offset-lime-200\/20 {
  --tw-ring-offset-color: #d9f99d33;
}

.ring-offset-lime-200\/25 {
  --tw-ring-offset-color: #d9f99d40;
}

.ring-offset-lime-200\/30 {
  --tw-ring-offset-color: #d9f99d4d;
}

.ring-offset-lime-200\/40 {
  --tw-ring-offset-color: #d9f99d66;
}

.ring-offset-lime-200\/5 {
  --tw-ring-offset-color: #d9f99d0d;
}

.ring-offset-lime-200\/50 {
  --tw-ring-offset-color: #d9f99d80;
}

.ring-offset-lime-200\/60 {
  --tw-ring-offset-color: #d9f99d99;
}

.ring-offset-lime-200\/70 {
  --tw-ring-offset-color: #d9f99db3;
}

.ring-offset-lime-200\/75 {
  --tw-ring-offset-color: #d9f99dbf;
}

.ring-offset-lime-200\/80 {
  --tw-ring-offset-color: #d9f99dcc;
}

.ring-offset-lime-200\/90 {
  --tw-ring-offset-color: #d9f99de6;
}

.ring-offset-lime-200\/95 {
  --tw-ring-offset-color: #d9f99df2;
}

.ring-offset-lime-300 {
  --tw-ring-offset-color: #bef264;
}

.ring-offset-lime-300\/0 {
  --tw-ring-offset-color: #bef26400;
}

.ring-offset-lime-300\/10 {
  --tw-ring-offset-color: #bef2641a;
}

.ring-offset-lime-300\/100 {
  --tw-ring-offset-color: #bef264;
}

.ring-offset-lime-300\/20 {
  --tw-ring-offset-color: #bef26433;
}

.ring-offset-lime-300\/25 {
  --tw-ring-offset-color: #bef26440;
}

.ring-offset-lime-300\/30 {
  --tw-ring-offset-color: #bef2644d;
}

.ring-offset-lime-300\/40 {
  --tw-ring-offset-color: #bef26466;
}

.ring-offset-lime-300\/5 {
  --tw-ring-offset-color: #bef2640d;
}

.ring-offset-lime-300\/50 {
  --tw-ring-offset-color: #bef26480;
}

.ring-offset-lime-300\/60 {
  --tw-ring-offset-color: #bef26499;
}

.ring-offset-lime-300\/70 {
  --tw-ring-offset-color: #bef264b3;
}

.ring-offset-lime-300\/75 {
  --tw-ring-offset-color: #bef264bf;
}

.ring-offset-lime-300\/80 {
  --tw-ring-offset-color: #bef264cc;
}

.ring-offset-lime-300\/90 {
  --tw-ring-offset-color: #bef264e6;
}

.ring-offset-lime-300\/95 {
  --tw-ring-offset-color: #bef264f2;
}

.ring-offset-lime-400 {
  --tw-ring-offset-color: #a3e635;
}

.ring-offset-lime-400\/0 {
  --tw-ring-offset-color: #a3e63500;
}

.ring-offset-lime-400\/10 {
  --tw-ring-offset-color: #a3e6351a;
}

.ring-offset-lime-400\/100 {
  --tw-ring-offset-color: #a3e635;
}

.ring-offset-lime-400\/20 {
  --tw-ring-offset-color: #a3e63533;
}

.ring-offset-lime-400\/25 {
  --tw-ring-offset-color: #a3e63540;
}

.ring-offset-lime-400\/30 {
  --tw-ring-offset-color: #a3e6354d;
}

.ring-offset-lime-400\/40 {
  --tw-ring-offset-color: #a3e63566;
}

.ring-offset-lime-400\/5 {
  --tw-ring-offset-color: #a3e6350d;
}

.ring-offset-lime-400\/50 {
  --tw-ring-offset-color: #a3e63580;
}

.ring-offset-lime-400\/60 {
  --tw-ring-offset-color: #a3e63599;
}

.ring-offset-lime-400\/70 {
  --tw-ring-offset-color: #a3e635b3;
}

.ring-offset-lime-400\/75 {
  --tw-ring-offset-color: #a3e635bf;
}

.ring-offset-lime-400\/80 {
  --tw-ring-offset-color: #a3e635cc;
}

.ring-offset-lime-400\/90 {
  --tw-ring-offset-color: #a3e635e6;
}

.ring-offset-lime-400\/95 {
  --tw-ring-offset-color: #a3e635f2;
}

.ring-offset-lime-50 {
  --tw-ring-offset-color: #f7fee7;
}

.ring-offset-lime-50\/0 {
  --tw-ring-offset-color: #f7fee700;
}

.ring-offset-lime-50\/10 {
  --tw-ring-offset-color: #f7fee71a;
}

.ring-offset-lime-50\/100 {
  --tw-ring-offset-color: #f7fee7;
}

.ring-offset-lime-50\/20 {
  --tw-ring-offset-color: #f7fee733;
}

.ring-offset-lime-50\/25 {
  --tw-ring-offset-color: #f7fee740;
}

.ring-offset-lime-50\/30 {
  --tw-ring-offset-color: #f7fee74d;
}

.ring-offset-lime-50\/40 {
  --tw-ring-offset-color: #f7fee766;
}

.ring-offset-lime-50\/5 {
  --tw-ring-offset-color: #f7fee70d;
}

.ring-offset-lime-50\/50 {
  --tw-ring-offset-color: #f7fee780;
}

.ring-offset-lime-50\/60 {
  --tw-ring-offset-color: #f7fee799;
}

.ring-offset-lime-50\/70 {
  --tw-ring-offset-color: #f7fee7b3;
}

.ring-offset-lime-50\/75 {
  --tw-ring-offset-color: #f7fee7bf;
}

.ring-offset-lime-50\/80 {
  --tw-ring-offset-color: #f7fee7cc;
}

.ring-offset-lime-50\/90 {
  --tw-ring-offset-color: #f7fee7e6;
}

.ring-offset-lime-50\/95 {
  --tw-ring-offset-color: #f7fee7f2;
}

.ring-offset-lime-500 {
  --tw-ring-offset-color: #84cc16;
}

.ring-offset-lime-500\/0 {
  --tw-ring-offset-color: #84cc1600;
}

.ring-offset-lime-500\/10 {
  --tw-ring-offset-color: #84cc161a;
}

.ring-offset-lime-500\/100 {
  --tw-ring-offset-color: #84cc16;
}

.ring-offset-lime-500\/20 {
  --tw-ring-offset-color: #84cc1633;
}

.ring-offset-lime-500\/25 {
  --tw-ring-offset-color: #84cc1640;
}

.ring-offset-lime-500\/30 {
  --tw-ring-offset-color: #84cc164d;
}

.ring-offset-lime-500\/40 {
  --tw-ring-offset-color: #84cc1666;
}

.ring-offset-lime-500\/5 {
  --tw-ring-offset-color: #84cc160d;
}

.ring-offset-lime-500\/50 {
  --tw-ring-offset-color: #84cc1680;
}

.ring-offset-lime-500\/60 {
  --tw-ring-offset-color: #84cc1699;
}

.ring-offset-lime-500\/70 {
  --tw-ring-offset-color: #84cc16b3;
}

.ring-offset-lime-500\/75 {
  --tw-ring-offset-color: #84cc16bf;
}

.ring-offset-lime-500\/80 {
  --tw-ring-offset-color: #84cc16cc;
}

.ring-offset-lime-500\/90 {
  --tw-ring-offset-color: #84cc16e6;
}

.ring-offset-lime-500\/95 {
  --tw-ring-offset-color: #84cc16f2;
}

.ring-offset-lime-600 {
  --tw-ring-offset-color: #65a30d;
}

.ring-offset-lime-600\/0 {
  --tw-ring-offset-color: #65a30d00;
}

.ring-offset-lime-600\/10 {
  --tw-ring-offset-color: #65a30d1a;
}

.ring-offset-lime-600\/100 {
  --tw-ring-offset-color: #65a30d;
}

.ring-offset-lime-600\/20 {
  --tw-ring-offset-color: #65a30d33;
}

.ring-offset-lime-600\/25 {
  --tw-ring-offset-color: #65a30d40;
}

.ring-offset-lime-600\/30 {
  --tw-ring-offset-color: #65a30d4d;
}

.ring-offset-lime-600\/40 {
  --tw-ring-offset-color: #65a30d66;
}

.ring-offset-lime-600\/5 {
  --tw-ring-offset-color: #65a30d0d;
}

.ring-offset-lime-600\/50 {
  --tw-ring-offset-color: #65a30d80;
}

.ring-offset-lime-600\/60 {
  --tw-ring-offset-color: #65a30d99;
}

.ring-offset-lime-600\/70 {
  --tw-ring-offset-color: #65a30db3;
}

.ring-offset-lime-600\/75 {
  --tw-ring-offset-color: #65a30dbf;
}

.ring-offset-lime-600\/80 {
  --tw-ring-offset-color: #65a30dcc;
}

.ring-offset-lime-600\/90 {
  --tw-ring-offset-color: #65a30de6;
}

.ring-offset-lime-600\/95 {
  --tw-ring-offset-color: #65a30df2;
}

.ring-offset-lime-700 {
  --tw-ring-offset-color: #4d7c0f;
}

.ring-offset-lime-700\/0 {
  --tw-ring-offset-color: #4d7c0f00;
}

.ring-offset-lime-700\/10 {
  --tw-ring-offset-color: #4d7c0f1a;
}

.ring-offset-lime-700\/100 {
  --tw-ring-offset-color: #4d7c0f;
}

.ring-offset-lime-700\/20 {
  --tw-ring-offset-color: #4d7c0f33;
}

.ring-offset-lime-700\/25 {
  --tw-ring-offset-color: #4d7c0f40;
}

.ring-offset-lime-700\/30 {
  --tw-ring-offset-color: #4d7c0f4d;
}

.ring-offset-lime-700\/40 {
  --tw-ring-offset-color: #4d7c0f66;
}

.ring-offset-lime-700\/5 {
  --tw-ring-offset-color: #4d7c0f0d;
}

.ring-offset-lime-700\/50 {
  --tw-ring-offset-color: #4d7c0f80;
}

.ring-offset-lime-700\/60 {
  --tw-ring-offset-color: #4d7c0f99;
}

.ring-offset-lime-700\/70 {
  --tw-ring-offset-color: #4d7c0fb3;
}

.ring-offset-lime-700\/75 {
  --tw-ring-offset-color: #4d7c0fbf;
}

.ring-offset-lime-700\/80 {
  --tw-ring-offset-color: #4d7c0fcc;
}

.ring-offset-lime-700\/90 {
  --tw-ring-offset-color: #4d7c0fe6;
}

.ring-offset-lime-700\/95 {
  --tw-ring-offset-color: #4d7c0ff2;
}

.ring-offset-lime-800 {
  --tw-ring-offset-color: #3f6212;
}

.ring-offset-lime-800\/0 {
  --tw-ring-offset-color: #3f621200;
}

.ring-offset-lime-800\/10 {
  --tw-ring-offset-color: #3f62121a;
}

.ring-offset-lime-800\/100 {
  --tw-ring-offset-color: #3f6212;
}

.ring-offset-lime-800\/20 {
  --tw-ring-offset-color: #3f621233;
}

.ring-offset-lime-800\/25 {
  --tw-ring-offset-color: #3f621240;
}

.ring-offset-lime-800\/30 {
  --tw-ring-offset-color: #3f62124d;
}

.ring-offset-lime-800\/40 {
  --tw-ring-offset-color: #3f621266;
}

.ring-offset-lime-800\/5 {
  --tw-ring-offset-color: #3f62120d;
}

.ring-offset-lime-800\/50 {
  --tw-ring-offset-color: #3f621280;
}

.ring-offset-lime-800\/60 {
  --tw-ring-offset-color: #3f621299;
}

.ring-offset-lime-800\/70 {
  --tw-ring-offset-color: #3f6212b3;
}

.ring-offset-lime-800\/75 {
  --tw-ring-offset-color: #3f6212bf;
}

.ring-offset-lime-800\/80 {
  --tw-ring-offset-color: #3f6212cc;
}

.ring-offset-lime-800\/90 {
  --tw-ring-offset-color: #3f6212e6;
}

.ring-offset-lime-800\/95 {
  --tw-ring-offset-color: #3f6212f2;
}

.ring-offset-lime-900 {
  --tw-ring-offset-color: #365314;
}

.ring-offset-lime-900\/0 {
  --tw-ring-offset-color: #36531400;
}

.ring-offset-lime-900\/10 {
  --tw-ring-offset-color: #3653141a;
}

.ring-offset-lime-900\/100 {
  --tw-ring-offset-color: #365314;
}

.ring-offset-lime-900\/20 {
  --tw-ring-offset-color: #36531433;
}

.ring-offset-lime-900\/25 {
  --tw-ring-offset-color: #36531440;
}

.ring-offset-lime-900\/30 {
  --tw-ring-offset-color: #3653144d;
}

.ring-offset-lime-900\/40 {
  --tw-ring-offset-color: #36531466;
}

.ring-offset-lime-900\/5 {
  --tw-ring-offset-color: #3653140d;
}

.ring-offset-lime-900\/50 {
  --tw-ring-offset-color: #36531480;
}

.ring-offset-lime-900\/60 {
  --tw-ring-offset-color: #36531499;
}

.ring-offset-lime-900\/70 {
  --tw-ring-offset-color: #365314b3;
}

.ring-offset-lime-900\/75 {
  --tw-ring-offset-color: #365314bf;
}

.ring-offset-lime-900\/80 {
  --tw-ring-offset-color: #365314cc;
}

.ring-offset-lime-900\/90 {
  --tw-ring-offset-color: #365314e6;
}

.ring-offset-lime-900\/95 {
  --tw-ring-offset-color: #365314f2;
}

.ring-offset-lime-950 {
  --tw-ring-offset-color: #1a2e05;
}

.ring-offset-lime-950\/0 {
  --tw-ring-offset-color: #1a2e0500;
}

.ring-offset-lime-950\/10 {
  --tw-ring-offset-color: #1a2e051a;
}

.ring-offset-lime-950\/100 {
  --tw-ring-offset-color: #1a2e05;
}

.ring-offset-lime-950\/20 {
  --tw-ring-offset-color: #1a2e0533;
}

.ring-offset-lime-950\/25 {
  --tw-ring-offset-color: #1a2e0540;
}

.ring-offset-lime-950\/30 {
  --tw-ring-offset-color: #1a2e054d;
}

.ring-offset-lime-950\/40 {
  --tw-ring-offset-color: #1a2e0566;
}

.ring-offset-lime-950\/5 {
  --tw-ring-offset-color: #1a2e050d;
}

.ring-offset-lime-950\/50 {
  --tw-ring-offset-color: #1a2e0580;
}

.ring-offset-lime-950\/60 {
  --tw-ring-offset-color: #1a2e0599;
}

.ring-offset-lime-950\/70 {
  --tw-ring-offset-color: #1a2e05b3;
}

.ring-offset-lime-950\/75 {
  --tw-ring-offset-color: #1a2e05bf;
}

.ring-offset-lime-950\/80 {
  --tw-ring-offset-color: #1a2e05cc;
}

.ring-offset-lime-950\/90 {
  --tw-ring-offset-color: #1a2e05e6;
}

.ring-offset-lime-950\/95 {
  --tw-ring-offset-color: #1a2e05f2;
}

.ring-offset-pink-100 {
  --tw-ring-offset-color: #fce7f3;
}

.ring-offset-pink-100\/0 {
  --tw-ring-offset-color: #fce7f300;
}

.ring-offset-pink-100\/10 {
  --tw-ring-offset-color: #fce7f31a;
}

.ring-offset-pink-100\/100 {
  --tw-ring-offset-color: #fce7f3;
}

.ring-offset-pink-100\/20 {
  --tw-ring-offset-color: #fce7f333;
}

.ring-offset-pink-100\/25 {
  --tw-ring-offset-color: #fce7f340;
}

.ring-offset-pink-100\/30 {
  --tw-ring-offset-color: #fce7f34d;
}

.ring-offset-pink-100\/40 {
  --tw-ring-offset-color: #fce7f366;
}

.ring-offset-pink-100\/5 {
  --tw-ring-offset-color: #fce7f30d;
}

.ring-offset-pink-100\/50 {
  --tw-ring-offset-color: #fce7f380;
}

.ring-offset-pink-100\/60 {
  --tw-ring-offset-color: #fce7f399;
}

.ring-offset-pink-100\/70 {
  --tw-ring-offset-color: #fce7f3b3;
}

.ring-offset-pink-100\/75 {
  --tw-ring-offset-color: #fce7f3bf;
}

.ring-offset-pink-100\/80 {
  --tw-ring-offset-color: #fce7f3cc;
}

.ring-offset-pink-100\/90 {
  --tw-ring-offset-color: #fce7f3e6;
}

.ring-offset-pink-100\/95 {
  --tw-ring-offset-color: #fce7f3f2;
}

.ring-offset-pink-200 {
  --tw-ring-offset-color: #fbcfe8;
}

.ring-offset-pink-200\/0 {
  --tw-ring-offset-color: #fbcfe800;
}

.ring-offset-pink-200\/10 {
  --tw-ring-offset-color: #fbcfe81a;
}

.ring-offset-pink-200\/100 {
  --tw-ring-offset-color: #fbcfe8;
}

.ring-offset-pink-200\/20 {
  --tw-ring-offset-color: #fbcfe833;
}

.ring-offset-pink-200\/25 {
  --tw-ring-offset-color: #fbcfe840;
}

.ring-offset-pink-200\/30 {
  --tw-ring-offset-color: #fbcfe84d;
}

.ring-offset-pink-200\/40 {
  --tw-ring-offset-color: #fbcfe866;
}

.ring-offset-pink-200\/5 {
  --tw-ring-offset-color: #fbcfe80d;
}

.ring-offset-pink-200\/50 {
  --tw-ring-offset-color: #fbcfe880;
}

.ring-offset-pink-200\/60 {
  --tw-ring-offset-color: #fbcfe899;
}

.ring-offset-pink-200\/70 {
  --tw-ring-offset-color: #fbcfe8b3;
}

.ring-offset-pink-200\/75 {
  --tw-ring-offset-color: #fbcfe8bf;
}

.ring-offset-pink-200\/80 {
  --tw-ring-offset-color: #fbcfe8cc;
}

.ring-offset-pink-200\/90 {
  --tw-ring-offset-color: #fbcfe8e6;
}

.ring-offset-pink-200\/95 {
  --tw-ring-offset-color: #fbcfe8f2;
}

.ring-offset-pink-300 {
  --tw-ring-offset-color: #f9a8d4;
}

.ring-offset-pink-300\/0 {
  --tw-ring-offset-color: #f9a8d400;
}

.ring-offset-pink-300\/10 {
  --tw-ring-offset-color: #f9a8d41a;
}

.ring-offset-pink-300\/100 {
  --tw-ring-offset-color: #f9a8d4;
}

.ring-offset-pink-300\/20 {
  --tw-ring-offset-color: #f9a8d433;
}

.ring-offset-pink-300\/25 {
  --tw-ring-offset-color: #f9a8d440;
}

.ring-offset-pink-300\/30 {
  --tw-ring-offset-color: #f9a8d44d;
}

.ring-offset-pink-300\/40 {
  --tw-ring-offset-color: #f9a8d466;
}

.ring-offset-pink-300\/5 {
  --tw-ring-offset-color: #f9a8d40d;
}

.ring-offset-pink-300\/50 {
  --tw-ring-offset-color: #f9a8d480;
}

.ring-offset-pink-300\/60 {
  --tw-ring-offset-color: #f9a8d499;
}

.ring-offset-pink-300\/70 {
  --tw-ring-offset-color: #f9a8d4b3;
}

.ring-offset-pink-300\/75 {
  --tw-ring-offset-color: #f9a8d4bf;
}

.ring-offset-pink-300\/80 {
  --tw-ring-offset-color: #f9a8d4cc;
}

.ring-offset-pink-300\/90 {
  --tw-ring-offset-color: #f9a8d4e6;
}

.ring-offset-pink-300\/95 {
  --tw-ring-offset-color: #f9a8d4f2;
}

.ring-offset-pink-400 {
  --tw-ring-offset-color: #f472b6;
}

.ring-offset-pink-400\/0 {
  --tw-ring-offset-color: #f472b600;
}

.ring-offset-pink-400\/10 {
  --tw-ring-offset-color: #f472b61a;
}

.ring-offset-pink-400\/100 {
  --tw-ring-offset-color: #f472b6;
}

.ring-offset-pink-400\/20 {
  --tw-ring-offset-color: #f472b633;
}

.ring-offset-pink-400\/25 {
  --tw-ring-offset-color: #f472b640;
}

.ring-offset-pink-400\/30 {
  --tw-ring-offset-color: #f472b64d;
}

.ring-offset-pink-400\/40 {
  --tw-ring-offset-color: #f472b666;
}

.ring-offset-pink-400\/5 {
  --tw-ring-offset-color: #f472b60d;
}

.ring-offset-pink-400\/50 {
  --tw-ring-offset-color: #f472b680;
}

.ring-offset-pink-400\/60 {
  --tw-ring-offset-color: #f472b699;
}

.ring-offset-pink-400\/70 {
  --tw-ring-offset-color: #f472b6b3;
}

.ring-offset-pink-400\/75 {
  --tw-ring-offset-color: #f472b6bf;
}

.ring-offset-pink-400\/80 {
  --tw-ring-offset-color: #f472b6cc;
}

.ring-offset-pink-400\/90 {
  --tw-ring-offset-color: #f472b6e6;
}

.ring-offset-pink-400\/95 {
  --tw-ring-offset-color: #f472b6f2;
}

.ring-offset-pink-50 {
  --tw-ring-offset-color: #fdf2f8;
}

.ring-offset-pink-50\/0 {
  --tw-ring-offset-color: #fdf2f800;
}

.ring-offset-pink-50\/10 {
  --tw-ring-offset-color: #fdf2f81a;
}

.ring-offset-pink-50\/100 {
  --tw-ring-offset-color: #fdf2f8;
}

.ring-offset-pink-50\/20 {
  --tw-ring-offset-color: #fdf2f833;
}

.ring-offset-pink-50\/25 {
  --tw-ring-offset-color: #fdf2f840;
}

.ring-offset-pink-50\/30 {
  --tw-ring-offset-color: #fdf2f84d;
}

.ring-offset-pink-50\/40 {
  --tw-ring-offset-color: #fdf2f866;
}

.ring-offset-pink-50\/5 {
  --tw-ring-offset-color: #fdf2f80d;
}

.ring-offset-pink-50\/50 {
  --tw-ring-offset-color: #fdf2f880;
}

.ring-offset-pink-50\/60 {
  --tw-ring-offset-color: #fdf2f899;
}

.ring-offset-pink-50\/70 {
  --tw-ring-offset-color: #fdf2f8b3;
}

.ring-offset-pink-50\/75 {
  --tw-ring-offset-color: #fdf2f8bf;
}

.ring-offset-pink-50\/80 {
  --tw-ring-offset-color: #fdf2f8cc;
}

.ring-offset-pink-50\/90 {
  --tw-ring-offset-color: #fdf2f8e6;
}

.ring-offset-pink-50\/95 {
  --tw-ring-offset-color: #fdf2f8f2;
}

.ring-offset-pink-500 {
  --tw-ring-offset-color: #ec4899;
}

.ring-offset-pink-500\/0 {
  --tw-ring-offset-color: #ec489900;
}

.ring-offset-pink-500\/10 {
  --tw-ring-offset-color: #ec48991a;
}

.ring-offset-pink-500\/100 {
  --tw-ring-offset-color: #ec4899;
}

.ring-offset-pink-500\/20 {
  --tw-ring-offset-color: #ec489933;
}

.ring-offset-pink-500\/25 {
  --tw-ring-offset-color: #ec489940;
}

.ring-offset-pink-500\/30 {
  --tw-ring-offset-color: #ec48994d;
}

.ring-offset-pink-500\/40 {
  --tw-ring-offset-color: #ec489966;
}

.ring-offset-pink-500\/5 {
  --tw-ring-offset-color: #ec48990d;
}

.ring-offset-pink-500\/50 {
  --tw-ring-offset-color: #ec489980;
}

.ring-offset-pink-500\/60 {
  --tw-ring-offset-color: #ec489999;
}

.ring-offset-pink-500\/70 {
  --tw-ring-offset-color: #ec4899b3;
}

.ring-offset-pink-500\/75 {
  --tw-ring-offset-color: #ec4899bf;
}

.ring-offset-pink-500\/80 {
  --tw-ring-offset-color: #ec4899cc;
}

.ring-offset-pink-500\/90 {
  --tw-ring-offset-color: #ec4899e6;
}

.ring-offset-pink-500\/95 {
  --tw-ring-offset-color: #ec4899f2;
}

.ring-offset-pink-600 {
  --tw-ring-offset-color: #db2777;
}

.ring-offset-pink-600\/0 {
  --tw-ring-offset-color: #db277700;
}

.ring-offset-pink-600\/10 {
  --tw-ring-offset-color: #db27771a;
}

.ring-offset-pink-600\/100 {
  --tw-ring-offset-color: #db2777;
}

.ring-offset-pink-600\/20 {
  --tw-ring-offset-color: #db277733;
}

.ring-offset-pink-600\/25 {
  --tw-ring-offset-color: #db277740;
}

.ring-offset-pink-600\/30 {
  --tw-ring-offset-color: #db27774d;
}

.ring-offset-pink-600\/40 {
  --tw-ring-offset-color: #db277766;
}

.ring-offset-pink-600\/5 {
  --tw-ring-offset-color: #db27770d;
}

.ring-offset-pink-600\/50 {
  --tw-ring-offset-color: #db277780;
}

.ring-offset-pink-600\/60 {
  --tw-ring-offset-color: #db277799;
}

.ring-offset-pink-600\/70 {
  --tw-ring-offset-color: #db2777b3;
}

.ring-offset-pink-600\/75 {
  --tw-ring-offset-color: #db2777bf;
}

.ring-offset-pink-600\/80 {
  --tw-ring-offset-color: #db2777cc;
}

.ring-offset-pink-600\/90 {
  --tw-ring-offset-color: #db2777e6;
}

.ring-offset-pink-600\/95 {
  --tw-ring-offset-color: #db2777f2;
}

.ring-offset-pink-700 {
  --tw-ring-offset-color: #be185d;
}

.ring-offset-pink-700\/0 {
  --tw-ring-offset-color: #be185d00;
}

.ring-offset-pink-700\/10 {
  --tw-ring-offset-color: #be185d1a;
}

.ring-offset-pink-700\/100 {
  --tw-ring-offset-color: #be185d;
}

.ring-offset-pink-700\/20 {
  --tw-ring-offset-color: #be185d33;
}

.ring-offset-pink-700\/25 {
  --tw-ring-offset-color: #be185d40;
}

.ring-offset-pink-700\/30 {
  --tw-ring-offset-color: #be185d4d;
}

.ring-offset-pink-700\/40 {
  --tw-ring-offset-color: #be185d66;
}

.ring-offset-pink-700\/5 {
  --tw-ring-offset-color: #be185d0d;
}

.ring-offset-pink-700\/50 {
  --tw-ring-offset-color: #be185d80;
}

.ring-offset-pink-700\/60 {
  --tw-ring-offset-color: #be185d99;
}

.ring-offset-pink-700\/70 {
  --tw-ring-offset-color: #be185db3;
}

.ring-offset-pink-700\/75 {
  --tw-ring-offset-color: #be185dbf;
}

.ring-offset-pink-700\/80 {
  --tw-ring-offset-color: #be185dcc;
}

.ring-offset-pink-700\/90 {
  --tw-ring-offset-color: #be185de6;
}

.ring-offset-pink-700\/95 {
  --tw-ring-offset-color: #be185df2;
}

.ring-offset-pink-800 {
  --tw-ring-offset-color: #9d174d;
}

.ring-offset-pink-800\/0 {
  --tw-ring-offset-color: #9d174d00;
}

.ring-offset-pink-800\/10 {
  --tw-ring-offset-color: #9d174d1a;
}

.ring-offset-pink-800\/100 {
  --tw-ring-offset-color: #9d174d;
}

.ring-offset-pink-800\/20 {
  --tw-ring-offset-color: #9d174d33;
}

.ring-offset-pink-800\/25 {
  --tw-ring-offset-color: #9d174d40;
}

.ring-offset-pink-800\/30 {
  --tw-ring-offset-color: #9d174d4d;
}

.ring-offset-pink-800\/40 {
  --tw-ring-offset-color: #9d174d66;
}

.ring-offset-pink-800\/5 {
  --tw-ring-offset-color: #9d174d0d;
}

.ring-offset-pink-800\/50 {
  --tw-ring-offset-color: #9d174d80;
}

.ring-offset-pink-800\/60 {
  --tw-ring-offset-color: #9d174d99;
}

.ring-offset-pink-800\/70 {
  --tw-ring-offset-color: #9d174db3;
}

.ring-offset-pink-800\/75 {
  --tw-ring-offset-color: #9d174dbf;
}

.ring-offset-pink-800\/80 {
  --tw-ring-offset-color: #9d174dcc;
}

.ring-offset-pink-800\/90 {
  --tw-ring-offset-color: #9d174de6;
}

.ring-offset-pink-800\/95 {
  --tw-ring-offset-color: #9d174df2;
}

.ring-offset-pink-900 {
  --tw-ring-offset-color: #831843;
}

.ring-offset-pink-900\/0 {
  --tw-ring-offset-color: #83184300;
}

.ring-offset-pink-900\/10 {
  --tw-ring-offset-color: #8318431a;
}

.ring-offset-pink-900\/100 {
  --tw-ring-offset-color: #831843;
}

.ring-offset-pink-900\/20 {
  --tw-ring-offset-color: #83184333;
}

.ring-offset-pink-900\/25 {
  --tw-ring-offset-color: #83184340;
}

.ring-offset-pink-900\/30 {
  --tw-ring-offset-color: #8318434d;
}

.ring-offset-pink-900\/40 {
  --tw-ring-offset-color: #83184366;
}

.ring-offset-pink-900\/5 {
  --tw-ring-offset-color: #8318430d;
}

.ring-offset-pink-900\/50 {
  --tw-ring-offset-color: #83184380;
}

.ring-offset-pink-900\/60 {
  --tw-ring-offset-color: #83184399;
}

.ring-offset-pink-900\/70 {
  --tw-ring-offset-color: #831843b3;
}

.ring-offset-pink-900\/75 {
  --tw-ring-offset-color: #831843bf;
}

.ring-offset-pink-900\/80 {
  --tw-ring-offset-color: #831843cc;
}

.ring-offset-pink-900\/90 {
  --tw-ring-offset-color: #831843e6;
}

.ring-offset-pink-900\/95 {
  --tw-ring-offset-color: #831843f2;
}

.ring-offset-pink-950 {
  --tw-ring-offset-color: #500724;
}

.ring-offset-pink-950\/0 {
  --tw-ring-offset-color: #50072400;
}

.ring-offset-pink-950\/10 {
  --tw-ring-offset-color: #5007241a;
}

.ring-offset-pink-950\/100 {
  --tw-ring-offset-color: #500724;
}

.ring-offset-pink-950\/20 {
  --tw-ring-offset-color: #50072433;
}

.ring-offset-pink-950\/25 {
  --tw-ring-offset-color: #50072440;
}

.ring-offset-pink-950\/30 {
  --tw-ring-offset-color: #5007244d;
}

.ring-offset-pink-950\/40 {
  --tw-ring-offset-color: #50072466;
}

.ring-offset-pink-950\/5 {
  --tw-ring-offset-color: #5007240d;
}

.ring-offset-pink-950\/50 {
  --tw-ring-offset-color: #50072480;
}

.ring-offset-pink-950\/60 {
  --tw-ring-offset-color: #50072499;
}

.ring-offset-pink-950\/70 {
  --tw-ring-offset-color: #500724b3;
}

.ring-offset-pink-950\/75 {
  --tw-ring-offset-color: #500724bf;
}

.ring-offset-pink-950\/80 {
  --tw-ring-offset-color: #500724cc;
}

.ring-offset-pink-950\/90 {
  --tw-ring-offset-color: #500724e6;
}

.ring-offset-pink-950\/95 {
  --tw-ring-offset-color: #500724f2;
}

.ring-offset-primary {
  --tw-ring-offset-color: hsl(var(--primary) / 1);
}

.ring-offset-primary\/0 {
  --tw-ring-offset-color: hsl(var(--primary) / 0);
}

.ring-offset-primary\/10 {
  --tw-ring-offset-color: hsl(var(--primary) / .1);
}

.ring-offset-primary\/100 {
  --tw-ring-offset-color: hsl(var(--primary) / 1);
}

.ring-offset-primary\/20 {
  --tw-ring-offset-color: hsl(var(--primary) / .2);
}

.ring-offset-primary\/25 {
  --tw-ring-offset-color: hsl(var(--primary) / .25);
}

.ring-offset-primary\/30 {
  --tw-ring-offset-color: hsl(var(--primary) / .3);
}

.ring-offset-primary\/40 {
  --tw-ring-offset-color: hsl(var(--primary) / .4);
}

.ring-offset-primary\/5 {
  --tw-ring-offset-color: hsl(var(--primary) / .05);
}

.ring-offset-primary\/50 {
  --tw-ring-offset-color: hsl(var(--primary) / .5);
}

.ring-offset-primary\/60 {
  --tw-ring-offset-color: hsl(var(--primary) / .6);
}

.ring-offset-primary\/70 {
  --tw-ring-offset-color: hsl(var(--primary) / .7);
}

.ring-offset-primary\/75 {
  --tw-ring-offset-color: hsl(var(--primary) / .75);
}

.ring-offset-primary\/80 {
  --tw-ring-offset-color: hsl(var(--primary) / .8);
}

.ring-offset-primary\/90 {
  --tw-ring-offset-color: hsl(var(--primary) / .9);
}

.ring-offset-primary\/95 {
  --tw-ring-offset-color: hsl(var(--primary) / .95);
}

.ring-offset-purple-100 {
  --tw-ring-offset-color: #f3e8ff;
}

.ring-offset-purple-100\/0 {
  --tw-ring-offset-color: #f3e8ff00;
}

.ring-offset-purple-100\/10 {
  --tw-ring-offset-color: #f3e8ff1a;
}

.ring-offset-purple-100\/100 {
  --tw-ring-offset-color: #f3e8ff;
}

.ring-offset-purple-100\/20 {
  --tw-ring-offset-color: #f3e8ff33;
}

.ring-offset-purple-100\/25 {
  --tw-ring-offset-color: #f3e8ff40;
}

.ring-offset-purple-100\/30 {
  --tw-ring-offset-color: #f3e8ff4d;
}

.ring-offset-purple-100\/40 {
  --tw-ring-offset-color: #f3e8ff66;
}

.ring-offset-purple-100\/5 {
  --tw-ring-offset-color: #f3e8ff0d;
}

.ring-offset-purple-100\/50 {
  --tw-ring-offset-color: #f3e8ff80;
}

.ring-offset-purple-100\/60 {
  --tw-ring-offset-color: #f3e8ff99;
}

.ring-offset-purple-100\/70 {
  --tw-ring-offset-color: #f3e8ffb3;
}

.ring-offset-purple-100\/75 {
  --tw-ring-offset-color: #f3e8ffbf;
}

.ring-offset-purple-100\/80 {
  --tw-ring-offset-color: #f3e8ffcc;
}

.ring-offset-purple-100\/90 {
  --tw-ring-offset-color: #f3e8ffe6;
}

.ring-offset-purple-100\/95 {
  --tw-ring-offset-color: #f3e8fff2;
}

.ring-offset-purple-200 {
  --tw-ring-offset-color: #e9d5ff;
}

.ring-offset-purple-200\/0 {
  --tw-ring-offset-color: #e9d5ff00;
}

.ring-offset-purple-200\/10 {
  --tw-ring-offset-color: #e9d5ff1a;
}

.ring-offset-purple-200\/100 {
  --tw-ring-offset-color: #e9d5ff;
}

.ring-offset-purple-200\/20 {
  --tw-ring-offset-color: #e9d5ff33;
}

.ring-offset-purple-200\/25 {
  --tw-ring-offset-color: #e9d5ff40;
}

.ring-offset-purple-200\/30 {
  --tw-ring-offset-color: #e9d5ff4d;
}

.ring-offset-purple-200\/40 {
  --tw-ring-offset-color: #e9d5ff66;
}

.ring-offset-purple-200\/5 {
  --tw-ring-offset-color: #e9d5ff0d;
}

.ring-offset-purple-200\/50 {
  --tw-ring-offset-color: #e9d5ff80;
}

.ring-offset-purple-200\/60 {
  --tw-ring-offset-color: #e9d5ff99;
}

.ring-offset-purple-200\/70 {
  --tw-ring-offset-color: #e9d5ffb3;
}

.ring-offset-purple-200\/75 {
  --tw-ring-offset-color: #e9d5ffbf;
}

.ring-offset-purple-200\/80 {
  --tw-ring-offset-color: #e9d5ffcc;
}

.ring-offset-purple-200\/90 {
  --tw-ring-offset-color: #e9d5ffe6;
}

.ring-offset-purple-200\/95 {
  --tw-ring-offset-color: #e9d5fff2;
}

.ring-offset-purple-300 {
  --tw-ring-offset-color: #d8b4fe;
}

.ring-offset-purple-300\/0 {
  --tw-ring-offset-color: #d8b4fe00;
}

.ring-offset-purple-300\/10 {
  --tw-ring-offset-color: #d8b4fe1a;
}

.ring-offset-purple-300\/100 {
  --tw-ring-offset-color: #d8b4fe;
}

.ring-offset-purple-300\/20 {
  --tw-ring-offset-color: #d8b4fe33;
}

.ring-offset-purple-300\/25 {
  --tw-ring-offset-color: #d8b4fe40;
}

.ring-offset-purple-300\/30 {
  --tw-ring-offset-color: #d8b4fe4d;
}

.ring-offset-purple-300\/40 {
  --tw-ring-offset-color: #d8b4fe66;
}

.ring-offset-purple-300\/5 {
  --tw-ring-offset-color: #d8b4fe0d;
}

.ring-offset-purple-300\/50 {
  --tw-ring-offset-color: #d8b4fe80;
}

.ring-offset-purple-300\/60 {
  --tw-ring-offset-color: #d8b4fe99;
}

.ring-offset-purple-300\/70 {
  --tw-ring-offset-color: #d8b4feb3;
}

.ring-offset-purple-300\/75 {
  --tw-ring-offset-color: #d8b4febf;
}

.ring-offset-purple-300\/80 {
  --tw-ring-offset-color: #d8b4fecc;
}

.ring-offset-purple-300\/90 {
  --tw-ring-offset-color: #d8b4fee6;
}

.ring-offset-purple-300\/95 {
  --tw-ring-offset-color: #d8b4fef2;
}

.ring-offset-purple-400 {
  --tw-ring-offset-color: #c084fc;
}

.ring-offset-purple-400\/0 {
  --tw-ring-offset-color: #c084fc00;
}

.ring-offset-purple-400\/10 {
  --tw-ring-offset-color: #c084fc1a;
}

.ring-offset-purple-400\/100 {
  --tw-ring-offset-color: #c084fc;
}

.ring-offset-purple-400\/20 {
  --tw-ring-offset-color: #c084fc33;
}

.ring-offset-purple-400\/25 {
  --tw-ring-offset-color: #c084fc40;
}

.ring-offset-purple-400\/30 {
  --tw-ring-offset-color: #c084fc4d;
}

.ring-offset-purple-400\/40 {
  --tw-ring-offset-color: #c084fc66;
}

.ring-offset-purple-400\/5 {
  --tw-ring-offset-color: #c084fc0d;
}

.ring-offset-purple-400\/50 {
  --tw-ring-offset-color: #c084fc80;
}

.ring-offset-purple-400\/60 {
  --tw-ring-offset-color: #c084fc99;
}

.ring-offset-purple-400\/70 {
  --tw-ring-offset-color: #c084fcb3;
}

.ring-offset-purple-400\/75 {
  --tw-ring-offset-color: #c084fcbf;
}

.ring-offset-purple-400\/80 {
  --tw-ring-offset-color: #c084fccc;
}

.ring-offset-purple-400\/90 {
  --tw-ring-offset-color: #c084fce6;
}

.ring-offset-purple-400\/95 {
  --tw-ring-offset-color: #c084fcf2;
}

.ring-offset-purple-50 {
  --tw-ring-offset-color: #faf5ff;
}

.ring-offset-purple-50\/0 {
  --tw-ring-offset-color: #faf5ff00;
}

.ring-offset-purple-50\/10 {
  --tw-ring-offset-color: #faf5ff1a;
}

.ring-offset-purple-50\/100 {
  --tw-ring-offset-color: #faf5ff;
}

.ring-offset-purple-50\/20 {
  --tw-ring-offset-color: #faf5ff33;
}

.ring-offset-purple-50\/25 {
  --tw-ring-offset-color: #faf5ff40;
}

.ring-offset-purple-50\/30 {
  --tw-ring-offset-color: #faf5ff4d;
}

.ring-offset-purple-50\/40 {
  --tw-ring-offset-color: #faf5ff66;
}

.ring-offset-purple-50\/5 {
  --tw-ring-offset-color: #faf5ff0d;
}

.ring-offset-purple-50\/50 {
  --tw-ring-offset-color: #faf5ff80;
}

.ring-offset-purple-50\/60 {
  --tw-ring-offset-color: #faf5ff99;
}

.ring-offset-purple-50\/70 {
  --tw-ring-offset-color: #faf5ffb3;
}

.ring-offset-purple-50\/75 {
  --tw-ring-offset-color: #faf5ffbf;
}

.ring-offset-purple-50\/80 {
  --tw-ring-offset-color: #faf5ffcc;
}

.ring-offset-purple-50\/90 {
  --tw-ring-offset-color: #faf5ffe6;
}

.ring-offset-purple-50\/95 {
  --tw-ring-offset-color: #faf5fff2;
}

.ring-offset-purple-500 {
  --tw-ring-offset-color: #a855f7;
}

.ring-offset-purple-500\/0 {
  --tw-ring-offset-color: #a855f700;
}

.ring-offset-purple-500\/10 {
  --tw-ring-offset-color: #a855f71a;
}

.ring-offset-purple-500\/100 {
  --tw-ring-offset-color: #a855f7;
}

.ring-offset-purple-500\/20 {
  --tw-ring-offset-color: #a855f733;
}

.ring-offset-purple-500\/25 {
  --tw-ring-offset-color: #a855f740;
}

.ring-offset-purple-500\/30 {
  --tw-ring-offset-color: #a855f74d;
}

.ring-offset-purple-500\/40 {
  --tw-ring-offset-color: #a855f766;
}

.ring-offset-purple-500\/5 {
  --tw-ring-offset-color: #a855f70d;
}

.ring-offset-purple-500\/50 {
  --tw-ring-offset-color: #a855f780;
}

.ring-offset-purple-500\/60 {
  --tw-ring-offset-color: #a855f799;
}

.ring-offset-purple-500\/70 {
  --tw-ring-offset-color: #a855f7b3;
}

.ring-offset-purple-500\/75 {
  --tw-ring-offset-color: #a855f7bf;
}

.ring-offset-purple-500\/80 {
  --tw-ring-offset-color: #a855f7cc;
}

.ring-offset-purple-500\/90 {
  --tw-ring-offset-color: #a855f7e6;
}

.ring-offset-purple-500\/95 {
  --tw-ring-offset-color: #a855f7f2;
}

.ring-offset-purple-600 {
  --tw-ring-offset-color: #9333ea;
}

.ring-offset-purple-600\/0 {
  --tw-ring-offset-color: #9333ea00;
}

.ring-offset-purple-600\/10 {
  --tw-ring-offset-color: #9333ea1a;
}

.ring-offset-purple-600\/100 {
  --tw-ring-offset-color: #9333ea;
}

.ring-offset-purple-600\/20 {
  --tw-ring-offset-color: #9333ea33;
}

.ring-offset-purple-600\/25 {
  --tw-ring-offset-color: #9333ea40;
}

.ring-offset-purple-600\/30 {
  --tw-ring-offset-color: #9333ea4d;
}

.ring-offset-purple-600\/40 {
  --tw-ring-offset-color: #9333ea66;
}

.ring-offset-purple-600\/5 {
  --tw-ring-offset-color: #9333ea0d;
}

.ring-offset-purple-600\/50 {
  --tw-ring-offset-color: #9333ea80;
}

.ring-offset-purple-600\/60 {
  --tw-ring-offset-color: #9333ea99;
}

.ring-offset-purple-600\/70 {
  --tw-ring-offset-color: #9333eab3;
}

.ring-offset-purple-600\/75 {
  --tw-ring-offset-color: #9333eabf;
}

.ring-offset-purple-600\/80 {
  --tw-ring-offset-color: #9333eacc;
}

.ring-offset-purple-600\/90 {
  --tw-ring-offset-color: #9333eae6;
}

.ring-offset-purple-600\/95 {
  --tw-ring-offset-color: #9333eaf2;
}

.ring-offset-purple-700 {
  --tw-ring-offset-color: #7e22ce;
}

.ring-offset-purple-700\/0 {
  --tw-ring-offset-color: #7e22ce00;
}

.ring-offset-purple-700\/10 {
  --tw-ring-offset-color: #7e22ce1a;
}

.ring-offset-purple-700\/100 {
  --tw-ring-offset-color: #7e22ce;
}

.ring-offset-purple-700\/20 {
  --tw-ring-offset-color: #7e22ce33;
}

.ring-offset-purple-700\/25 {
  --tw-ring-offset-color: #7e22ce40;
}

.ring-offset-purple-700\/30 {
  --tw-ring-offset-color: #7e22ce4d;
}

.ring-offset-purple-700\/40 {
  --tw-ring-offset-color: #7e22ce66;
}

.ring-offset-purple-700\/5 {
  --tw-ring-offset-color: #7e22ce0d;
}

.ring-offset-purple-700\/50 {
  --tw-ring-offset-color: #7e22ce80;
}

.ring-offset-purple-700\/60 {
  --tw-ring-offset-color: #7e22ce99;
}

.ring-offset-purple-700\/70 {
  --tw-ring-offset-color: #7e22ceb3;
}

.ring-offset-purple-700\/75 {
  --tw-ring-offset-color: #7e22cebf;
}

.ring-offset-purple-700\/80 {
  --tw-ring-offset-color: #7e22cecc;
}

.ring-offset-purple-700\/90 {
  --tw-ring-offset-color: #7e22cee6;
}

.ring-offset-purple-700\/95 {
  --tw-ring-offset-color: #7e22cef2;
}

.ring-offset-purple-800 {
  --tw-ring-offset-color: #6b21a8;
}

.ring-offset-purple-800\/0 {
  --tw-ring-offset-color: #6b21a800;
}

.ring-offset-purple-800\/10 {
  --tw-ring-offset-color: #6b21a81a;
}

.ring-offset-purple-800\/100 {
  --tw-ring-offset-color: #6b21a8;
}

.ring-offset-purple-800\/20 {
  --tw-ring-offset-color: #6b21a833;
}

.ring-offset-purple-800\/25 {
  --tw-ring-offset-color: #6b21a840;
}

.ring-offset-purple-800\/30 {
  --tw-ring-offset-color: #6b21a84d;
}

.ring-offset-purple-800\/40 {
  --tw-ring-offset-color: #6b21a866;
}

.ring-offset-purple-800\/5 {
  --tw-ring-offset-color: #6b21a80d;
}

.ring-offset-purple-800\/50 {
  --tw-ring-offset-color: #6b21a880;
}

.ring-offset-purple-800\/60 {
  --tw-ring-offset-color: #6b21a899;
}

.ring-offset-purple-800\/70 {
  --tw-ring-offset-color: #6b21a8b3;
}

.ring-offset-purple-800\/75 {
  --tw-ring-offset-color: #6b21a8bf;
}

.ring-offset-purple-800\/80 {
  --tw-ring-offset-color: #6b21a8cc;
}

.ring-offset-purple-800\/90 {
  --tw-ring-offset-color: #6b21a8e6;
}

.ring-offset-purple-800\/95 {
  --tw-ring-offset-color: #6b21a8f2;
}

.ring-offset-purple-900 {
  --tw-ring-offset-color: #581c87;
}

.ring-offset-purple-900\/0 {
  --tw-ring-offset-color: #581c8700;
}

.ring-offset-purple-900\/10 {
  --tw-ring-offset-color: #581c871a;
}

.ring-offset-purple-900\/100 {
  --tw-ring-offset-color: #581c87;
}

.ring-offset-purple-900\/20 {
  --tw-ring-offset-color: #581c8733;
}

.ring-offset-purple-900\/25 {
  --tw-ring-offset-color: #581c8740;
}

.ring-offset-purple-900\/30 {
  --tw-ring-offset-color: #581c874d;
}

.ring-offset-purple-900\/40 {
  --tw-ring-offset-color: #581c8766;
}

.ring-offset-purple-900\/5 {
  --tw-ring-offset-color: #581c870d;
}

.ring-offset-purple-900\/50 {
  --tw-ring-offset-color: #581c8780;
}

.ring-offset-purple-900\/60 {
  --tw-ring-offset-color: #581c8799;
}

.ring-offset-purple-900\/70 {
  --tw-ring-offset-color: #581c87b3;
}

.ring-offset-purple-900\/75 {
  --tw-ring-offset-color: #581c87bf;
}

.ring-offset-purple-900\/80 {
  --tw-ring-offset-color: #581c87cc;
}

.ring-offset-purple-900\/90 {
  --tw-ring-offset-color: #581c87e6;
}

.ring-offset-purple-900\/95 {
  --tw-ring-offset-color: #581c87f2;
}

.ring-offset-purple-950 {
  --tw-ring-offset-color: #3b0764;
}

.ring-offset-purple-950\/0 {
  --tw-ring-offset-color: #3b076400;
}

.ring-offset-purple-950\/10 {
  --tw-ring-offset-color: #3b07641a;
}

.ring-offset-purple-950\/100 {
  --tw-ring-offset-color: #3b0764;
}

.ring-offset-purple-950\/20 {
  --tw-ring-offset-color: #3b076433;
}

.ring-offset-purple-950\/25 {
  --tw-ring-offset-color: #3b076440;
}

.ring-offset-purple-950\/30 {
  --tw-ring-offset-color: #3b07644d;
}

.ring-offset-purple-950\/40 {
  --tw-ring-offset-color: #3b076466;
}

.ring-offset-purple-950\/5 {
  --tw-ring-offset-color: #3b07640d;
}

.ring-offset-purple-950\/50 {
  --tw-ring-offset-color: #3b076480;
}

.ring-offset-purple-950\/60 {
  --tw-ring-offset-color: #3b076499;
}

.ring-offset-purple-950\/70 {
  --tw-ring-offset-color: #3b0764b3;
}

.ring-offset-purple-950\/75 {
  --tw-ring-offset-color: #3b0764bf;
}

.ring-offset-purple-950\/80 {
  --tw-ring-offset-color: #3b0764cc;
}

.ring-offset-purple-950\/90 {
  --tw-ring-offset-color: #3b0764e6;
}

.ring-offset-purple-950\/95 {
  --tw-ring-offset-color: #3b0764f2;
}

.ring-offset-transparent {
  --tw-ring-offset-color: transparent;
}

.ring-offset-transparent\/0 {
  --tw-ring-offset-color: #0000;
}

.ring-offset-transparent\/10 {
  --tw-ring-offset-color: #0000001a;
}

.ring-offset-transparent\/100 {
  --tw-ring-offset-color: #000;
}

.ring-offset-transparent\/20 {
  --tw-ring-offset-color: #0003;
}

.ring-offset-transparent\/25 {
  --tw-ring-offset-color: #00000040;
}

.ring-offset-transparent\/30 {
  --tw-ring-offset-color: #0000004d;
}

.ring-offset-transparent\/40 {
  --tw-ring-offset-color: #0006;
}

.ring-offset-transparent\/5 {
  --tw-ring-offset-color: #0000000d;
}

.ring-offset-transparent\/50 {
  --tw-ring-offset-color: #00000080;
}

.ring-offset-transparent\/60 {
  --tw-ring-offset-color: #0009;
}

.ring-offset-transparent\/70 {
  --tw-ring-offset-color: #000000b3;
}

.ring-offset-transparent\/75 {
  --tw-ring-offset-color: #000000bf;
}

.ring-offset-transparent\/80 {
  --tw-ring-offset-color: #000c;
}

.ring-offset-transparent\/90 {
  --tw-ring-offset-color: #000000e6;
}

.ring-offset-transparent\/95 {
  --tw-ring-offset-color: #000000f2;
}

.blur-md {
  --tw-blur: blur(12px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-sm {
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-none {
  --tw-drop-shadow: drop-shadow(0 0 #0000);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.sepia {
  --tw-sepia: sepia(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.sepia-0 {
  --tw-sepia: sepia(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-0 {
  --tw-backdrop-blur: blur(0);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-3xl {
  --tw-backdrop-blur: blur(64px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-none {
  --tw-backdrop-blur: blur(0);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-0 {
  --tw-backdrop-brightness: brightness(0);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-100 {
  --tw-backdrop-brightness: brightness(1);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-105 {
  --tw-backdrop-brightness: brightness(1.05);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-110 {
  --tw-backdrop-brightness: brightness(1.1);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-125 {
  --tw-backdrop-brightness: brightness(1.25);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-150 {
  --tw-backdrop-brightness: brightness(1.5);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-200 {
  --tw-backdrop-brightness: brightness(2);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-50 {
  --tw-backdrop-brightness: brightness(.5);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-75 {
  --tw-backdrop-brightness: brightness(.75);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-90 {
  --tw-backdrop-brightness: brightness(.9);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-95 {
  --tw-backdrop-brightness: brightness(.95);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-0 {
  --tw-backdrop-contrast: contrast(0);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-100 {
  --tw-backdrop-contrast: contrast(1);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-125 {
  --tw-backdrop-contrast: contrast(1.25);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-150 {
  --tw-backdrop-contrast: contrast(1.5);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-200 {
  --tw-backdrop-contrast: contrast(2);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-50 {
  --tw-backdrop-contrast: contrast(.5);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-contrast-75 {
  --tw-backdrop-contrast: contrast(.75);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-grayscale {
  --tw-backdrop-grayscale: grayscale(100%);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-grayscale-0 {
  --tw-backdrop-grayscale: grayscale(0);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(-0deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.-backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(0deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(15deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(180deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(30deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(60deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(90deg);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-invert {
  --tw-backdrop-invert: invert(100%);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-invert-0 {
  --tw-backdrop-invert: invert(0);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-0 {
  --tw-backdrop-opacity: opacity(0);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-10 {
  --tw-backdrop-opacity: opacity(.1);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-100 {
  --tw-backdrop-opacity: opacity(1);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-20 {
  --tw-backdrop-opacity: opacity(.2);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-25 {
  --tw-backdrop-opacity: opacity(.25);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-30 {
  --tw-backdrop-opacity: opacity(.3);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-40 {
  --tw-backdrop-opacity: opacity(.4);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-5 {
  --tw-backdrop-opacity: opacity(.05);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-50 {
  --tw-backdrop-opacity: opacity(.5);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-60 {
  --tw-backdrop-opacity: opacity(.6);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-70 {
  --tw-backdrop-opacity: opacity(.7);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-75 {
  --tw-backdrop-opacity: opacity(.75);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-80 {
  --tw-backdrop-opacity: opacity(.8);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-90 {
  --tw-backdrop-opacity: opacity(.9);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-95 {
  --tw-backdrop-opacity: opacity(.95);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-0 {
  --tw-backdrop-saturate: saturate(0);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-100 {
  --tw-backdrop-saturate: saturate(1);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-150 {
  --tw-backdrop-saturate: saturate(1.5);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-200 {
  --tw-backdrop-saturate: saturate(2);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-50 {
  --tw-backdrop-saturate: saturate(.5);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-sepia {
  --tw-backdrop-sepia: sepia(100%);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-sepia-0 {
  --tw-backdrop-sepia: sepia(0);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter-none {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.will-change-transform {
  will-change: transform;
}

.text-body {
  --tw-text-opacity: 1 !important;
  color: rgb(32 33 36 / var(--tw-text-opacity)) !important;
}

:is([data-mode="dark"] .text-body) {
  --tw-text-opacity: 1 !important;
  color: rgb(218 220 224 / var(--tw-text-opacity)) !important;
}

.text-soft {
  --tw-text-opacity: 1 !important;
  color: rgb(60 64 67 / var(--tw-text-opacity)) !important;
}

:is([data-mode="dark"] .text-soft) {
  --tw-text-opacity: 1 !important;
  color: rgb(218 220 224 / var(--tw-text-opacity)) !important;
}

.text-muted {
  --tw-text-opacity: 1 !important;
  color: rgb(95 99 104 / var(--tw-text-opacity)) !important;
}

:is([data-mode="dark"] .text-muted) {
  --tw-text-opacity: 1 !important;
  color: rgb(154 160 166 / var(--tw-text-opacity)) !important;
}

.bg-body {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

:is([data-mode="dark"] .bg-body) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(32 33 36 / var(--tw-bg-opacity)) !important;
}

.bg-light {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity)) !important;
}

:is([data-mode="dark"] .bg-light) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(40 41 44 / var(--tw-bg-opacity)) !important;
}

.focus\:not-sr-only:focus {
  clip: auto;
  white-space: normal;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  position: static;
  overflow: visible;
}

@media (width >= 768px) {
  .md\:flex {
    display: flex;
  }
}

@media (width >= 1024px) {
  .lg\:block {
    display: block;
  }

  .lg\:columns-2 {
    columns: 2;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }
}

@media (width >= 1280px) {
  .xl\:gap-x-16 {
    column-gap: 4rem;
  }
}

/*# sourceMappingURL=index.9dab4df1.css.map */
