/* base */

@import "tailwindcss/base";
@import "./base/root";
@import "./base/fonts";
@import "./base/icons";
@import "./base/global";
@import "./base/container";
@import "./base/layout";

/* components */

@import "tailwindcss/components";

/* components: bootstrap */

@import "./bootstrap/backdrop";
@import "./bootstrap/dropdown";
@import "./bootstrap/modal";
@import "./bootstrap/offcanvas";

/* components: custom */

@import "./components/ckeditor";
@import "./components/buttons";
@import "./components/head";
@import "./components/breadcrumb";
@import "./components/page-category";
@import "./components/main-nav";
@import "./components/widget";
@import "./components/post";
@import "./components/c-grid";
@import "./components/pagination";
@import "./components/foot";
@import "./components/dialog";
@import "./components/dialog-preferences";
@import "./components/dialog-cookies";

/* utilities */

@import "tailwindcss/utilities";
@import "./utilities/custom";
